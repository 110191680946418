import { useEffect, useState } from "react";
import { TourialApiCalls } from "../helpers/tourial-api-calls";
import { convertTourialFormToFormV3Data } from "../../../shared/types/tool-data-types/form-v3-data";
import { useActiveView } from "../redux/selectors/redux-selectors";
import { useFormOnStep } from "../redux/selectors/builder-selectors";
import { usePageForm } from "../redux/selectors/product-page-selectors";
export function useFetchFormOnStep() {
    const currentStep = useActiveView();
    const formOnStep = useFormOnStep(currentStep);
    const [formData, setFormData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        if (formOnStep === null || formOnStep === void 0 ? void 0 : formOnStep.isEnabled) {
            setIsLoading(true);
            TourialApiCalls.Forms.getFormById(formOnStep.formId)
                .then((f) => {
                setIsLoading(false);
                setFormData(convertTourialFormToFormV3Data(f, formOnStep.isGated, formOnStep.isEnabled));
            })
                .catch(() => {
                setIsLoading(false);
                setHasError(true);
            });
        }
        return () => setFormData(undefined);
    }, [formOnStep, currentStep.pageId, currentStep.zoomId]);
    return { isLoading, hasError, formData, formOnStep };
}
export function useFetchPageForm() {
    const currentStep = useActiveView();
    const pageForm = usePageForm();
    const [formData, setFormData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        if (pageForm === null || pageForm === void 0 ? void 0 : pageForm.isEnabled) {
            setIsLoading(true);
            TourialApiCalls.Forms.getFormById(pageForm.formId)
                .then((f) => {
                setIsLoading(false);
                setFormData(convertTourialFormToFormV3Data(f, pageForm.isGated, pageForm.isEnabled));
            })
                .catch(() => {
                setIsLoading(false);
                setHasError(true);
            });
        }
        return () => setFormData(undefined);
    }, [pageForm === null || pageForm === void 0 ? void 0 : pageForm.formId, currentStep.pageId, currentStep.zoomId]);
    return { isLoading, hasError, formData };
}
