import React from "react";
import styled from "styled-components";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import TModal, { TModalButtonsContainer } from "../../../styled/t-modal";
import { newTheme } from "../../../styled/theme";
export default function UpdateLiveVersionModal({ setIsUpdateLiveVersionModalOpen, saveTourial, }) {
    return (React.createElement(TModal, { title: "Your live tour will change", onClose: () => setIsUpdateLiveVersionModalOpen(false) },
        React.createElement(React.Fragment, null,
            React.createElement(Span, null, "You will not be able to access the previous live version of your tour once you do this."),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => {
                        setIsUpdateLiveVersionModalOpen(false);
                    } }, "Cancel"),
                React.createElement(TButton, { onClick: () => {
                        saveTourial();
                        setIsUpdateLiveVersionModalOpen(false);
                    } }, "Publish Changes")))));
}
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  height: 68px;
  width: 510px;
`;
