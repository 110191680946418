import React, { useState } from "react";
import { ContentContainer } from "./workspace-create-modal";
import { TToggleSwitch } from "../../styled/t-toggle-switch";
import styled from "styled-components";
import { newTheme } from "../../styled/theme";
import { useFeatureFlags } from "../../../redux/selectors/redux-selectors";
import { TDropdownSelect } from "../../styled/t-dropdown-select";
import { useAsync } from "react-async";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import TInput from "../../styled/t-input";
import TButton, { TButtonVariants } from "../../styled/t-button";
export function ShareLinkFormGate({ formGate, setFormGate }) {
    const { passwordProtect } = useFeatureFlags();
    const [forms, setForms] = useState([]);
    const [whiteLabelInput, setWhiteLabelInput] = useState("");
    const [showCreateInput, setShowCreateInput] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingInput, setEditingInput] = useState("");
    useAsync({
        promiseFn: TourialApiCalls.Forms.getForms,
        onResolve: data => {
            setForms(data
                .filter(form => form.formGateAllowed)
                .map(form => {
                return { label: form.name, value: form._id };
            }));
        },
    });
    if (!passwordProtect) {
        return null;
    }
    return (React.createElement(ContentContainer, null,
        React.createElement(FormGateContainer, null,
            React.createElement("h3", null, "Add Gate"),
            React.createElement(TToggleSwitch, { enabled: formGate === null || formGate === void 0 ? void 0 : formGate.enabled, size: "small", onChange: enabled => setFormGate(Object.assign(Object.assign({}, formGate), { enabled })), id: "_test_share_link_form_gate_toggle" })),
        React.createElement(FormGateDescription, null, "Collect visitor emails at the start of their session. You can add a filter to whitelist specific domains."),
        (formGate === null || formGate === void 0 ? void 0 : formGate.enabled) && (React.createElement(TDropdownSelect, { options: forms, onChange: e => setFormGate(Object.assign(Object.assign({}, formGate), { formId: e.value })), isSearchable: true, selectedValue: formGate === null || formGate === void 0 ? void 0 : formGate.formId, placeholder: "(select form)" })),
        (formGate === null || formGate === void 0 ? void 0 : formGate.enabled) && (formGate === null || formGate === void 0 ? void 0 : formGate.whiteLabelDomains) && (React.createElement("div", null, formGate === null || formGate === void 0 ? void 0 : formGate.whiteLabelDomains.map((domain, i) => {
            if (editingIndex === i) {
                return (React.createElement(FormInputContainer, { key: i },
                    "@ ",
                    React.createElement(FormInput, { value: editingInput, onChange: e => {
                            setEditingInput(e.target.value);
                        }, "data-cy": "id=_test_share_link_white_label_input" }),
                    React.createElement(TButton, { disabled: editingInput.length < 4, size: "small", variant: TButtonVariants.SECONDARY, "data-cy": "_test_share_link_white_label_enter", onClick: () => {
                            formGate.whiteLabelDomains[i] = editingInput;
                            setFormGate(Object.assign(Object.assign({}, formGate), { whiteLabelDomains: [...formGate.whiteLabelDomains] }));
                            setEditingInput("");
                            setEditingIndex(null);
                        } }, "Save")));
            }
            return (React.createElement(FormInputContainer, { key: i },
                React.createElement(EmailContainer, null,
                    "@ ",
                    domain),
                React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY, onClick: () => {
                        // Edit the domain here
                        setEditingInput(domain);
                        setEditingIndex(i);
                    } }, "Edit"),
                React.createElement(TButton, { size: "small", variant: TButtonVariants.DANGER, onClick: () => {
                        // Edit the domain here
                        formGate.whiteLabelDomains.splice(i, 1);
                        setFormGate(Object.assign(Object.assign({}, formGate), { whiteLabelDomains: [...formGate.whiteLabelDomains] }));
                    } }, "Delete")));
        }))),
        (formGate === null || formGate === void 0 ? void 0 : formGate.enabled) && (formGate === null || formGate === void 0 ? void 0 : formGate.formId) && !showCreateInput && (React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => {
                setShowCreateInput(true);
            }, style: { width: 200 } }, "+ Whitelist domains")),
        showCreateInput && (React.createElement(FormInputContainer, null,
            "@ ",
            React.createElement(FormInput, { value: whiteLabelInput, onChange: e => {
                    setWhiteLabelInput(e.target.value);
                }, "data-cy": "id=_test_share_link_white_label_input" }),
            React.createElement(TButton, { disabled: whiteLabelInput.length < 4, size: "small", variant: TButtonVariants.SECONDARY, "data-cy": "_test_share_link_white_label_enter", onClick: () => {
                    if (!formGate.whiteLabelDomains) {
                        setFormGate(Object.assign(Object.assign({}, formGate), { whiteLabelDomains: [whiteLabelInput] }));
                    }
                    else {
                        setFormGate(Object.assign(Object.assign({}, formGate), { whiteLabelDomains: [...formGate.whiteLabelDomains, whiteLabelInput] }));
                    }
                    setWhiteLabelInput("");
                    setShowCreateInput(false);
                } }, "Enter")))));
}
const FormGateContainer = styled.div `
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  //height: 24px;
  > h3 {
    font: ${newTheme.fonts.bold};
    white-space: nowrap;
  }
`;
const FormGateDescription = styled.div `
  display: block;
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey600};
`;
const FormInputContainer = styled.div `
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;
const FormInput = styled(TInput) `
  width: 100%;
`;
const EmailContainer = styled.div `
  width: 100%;
`;
