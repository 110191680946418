import { cloneDeep } from "lodash";
import React from "react";
import { StyledFormSelect } from "./styled-form-select";
import { FormField } from "../subcomponents/styled-main-view";
import { StyledLabel } from "./styled-label";
export function DropdownField(props) {
    const { setFormState, theme, field, fieldIndex, formState, labelText } = props;
    return (React.createElement(FormField, null,
        React.createElement(StyledLabel, { labelText: labelText },
            field.label,
            React.createElement(StyledFormSelect, { theme: theme, name: field.role, onChange: e => {
                    if (setFormState) {
                        const nextState = cloneDeep(formState);
                        nextState[fieldIndex].value = e.target.value;
                        setFormState(nextState);
                    }
                } },
                React.createElement("option", { hidden: true, value: undefined }, "Select..."),
                field.dropdownOptions.map((o, i) => (React.createElement("option", { key: `option-${i}`, value: o.tourialOptionName }, o.tourialOptionName)))))));
}
