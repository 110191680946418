import { useHistory } from "react-router";
import { useEffect, useRef, useState } from "react";
import { usePromptBeforeUnload } from "./use-prompt-before-unload";
export function useBlockNavigation(isEnabled, onCancelButtonClick) {
    const history = useHistory();
    const [shouldRender, setShouldRender] = useState(false);
    const [currentPath, setCurrentPath] = useState("");
    usePromptBeforeUnload(isEnabled);
    const unblockRef = useRef();
    useEffect(() => {
        // @ts-expect-error
        unblockRef.current = history.block(location => {
            if (isEnabled) {
                setCurrentPath(location.pathname);
                setShouldRender(true);
                return false;
            }
            return true;
        });
        return () => {
            var _a;
            (_a = unblockRef === null || unblockRef === void 0 ? void 0 : unblockRef.current) === null || _a === void 0 ? void 0 : _a.call(unblockRef);
        };
    }, [isEnabled]);
    function onContinue() {
        var _a;
        (_a = unblockRef === null || unblockRef === void 0 ? void 0 : unblockRef.current) === null || _a === void 0 ? void 0 : _a.call(unblockRef);
        setShouldRender(false);
        history.push(currentPath);
    }
    function onClose() {
        setShouldRender(false);
    }
    function onCancel() {
        onCancelButtonClick();
        setShouldRender(false);
    }
    return { shouldRender, onContinue, onClose, onCancel };
}
