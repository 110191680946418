import React from "react";
import { Rnd } from "react-rnd";
import styled from "styled-components";
import { ViewportMode } from "../../../types/definitions";
import { useResizeDetails, useUpdateTool, useViewportMode } from "../../../redux/selectors/redux-selectors";
import { userShadows } from "../builder/tools/shape/editor";
import { withToolWrapper } from "../with-tool-wrapper";
const TooltipBox = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  display: inline;
  background: ${props => (props.hasBackground ? props.backgroundColor : null)};
  box-shadow: ${props => props.containerBoxShadow};
  box-sizing: border-box;
  border: ${props => (props.enableBorder ? `${props.borderWidth}px solid ${props.borderColor}` : "0px")};
  border-radius: ${props => props.borderRadius}px;
  ${props => props.animation && props.viewportMode !== ViewportMode.EDIT && `animation: ${props.animation};`}
  ${props => props.animationDuration &&
    props.viewportMode !== ViewportMode.EDIT &&
    `animation-duration: ${props.animationDuration};`}
`;
const TooltipArrow = styled.div `
  position: absolute;
  width: ${props => props.calculatedTooltipSize}px;
  height: ${props => props.calculatedTooltipSize}px;
  box-sizing: border-box;
  background: ${props => (props.hasBackground ? props.backgroundColor : null)};
  border: ${props => (props.enableBorder ? `1px solid ${props.borderColor}` : "0px")};
  border-width: ${props => props.borderWidth}px;
  border-color: ${props => props.afterBorderColor[props.tooltipDirection]};
  transform-origin: 0 0;
  transform: ${props => (props.isBeingResized && props.translateDirections ? props.translateDirections : "")}
    rotate(-45deg);
`;
const TooltipArrowParent = styled.div `
  position: absolute;
  ${props => props.verticalPosition};
  ${props => props.horizontalPosition};
  width: ${props => props.calculatedTooltipSize}px;
  height: ${props => props.calculatedTooltipSize}px;
  ${props => props.animation && props.viewportMode !== ViewportMode.EDIT && `animation: ${props.animation};`}
  ${props => props.animationDuration &&
    props.viewportMode !== ViewportMode.EDIT &&
    `animation-duration: ${props.animationDuration};`}
`;
function TourialTooltip(props) {
    var _a, _b, _c, _d, _f;
    const viewportMode = useViewportMode();
    const resizeDetails = useResizeDetails();
    const updateTool = useUpdateTool();
    const { backgroundColor, enableBackground, enableShadow, enableBorder, borderRadius, borderWidth, borderColor, tooltipDirection, tooltipPosition, tooltipSize, enableArrow, } = props.toolData;
    const { setIsArrowDragging } = props;
    const onArrowDragStop = (_e, draggableData) => {
        const { x, y } = draggableData;
        let newTooltipPosition;
        if (tooltipDirection === "left" || tooltipDirection === "right") {
            newTooltipPosition =
                (((tooltipPosition / 100) * props.viewportDimensions.height + y) / props.viewportDimensions.height) * 100;
        }
        else {
            newTooltipPosition =
                (((tooltipPosition / 100) * props.viewportDimensions.width + x) / props.viewportDimensions.width) * 100;
        }
        updateTool(Object.assign(Object.assign({}, props.toolData), { tooltipPosition: newTooltipPosition }));
    };
    const calculatedTooltipSize = tooltipSize * props.viewportDimensions.width;
    // thanks Pythagoras
    const tipDiagonal = Math.sqrt(Math.pow(calculatedTooltipSize, 2) + Math.pow(calculatedTooltipSize, 2));
    const containerBoxShadow = enableShadow ? userShadows.default : "";
    const horizontalPositions = {
        top: `left: ${(tooltipPosition / 100) * props.viewportDimensions.width}px`,
        bottom: `left: ${(tooltipPosition / 100) * props.viewportDimensions.width}px`,
        left: `left: ${-(tipDiagonal / 2 + 1) + (borderWidth + 1 || 0)}px`,
        right: `left: ${(props.toolData.sizeAndPosition.width * props.viewportDimensions.width) / 100 -
            tipDiagonal / 2 +
            1 -
            (borderWidth + 1 || 0)}px`,
    };
    const verticalPositions = {
        top: `top: ${-1 + (borderWidth + 1 || 0)}px`,
        bottom: `bottom: ${-(calculatedTooltipSize + (enableBorder ? 1 : 0)) + (borderWidth + 1 || 0)}px`,
        left: `top: ${(tooltipPosition / 100) * props.viewportDimensions.height}px`,
        right: `top: ${(tooltipPosition / 100) * props.viewportDimensions.height}px`,
    };
    const horizontalPosition = horizontalPositions[tooltipDirection];
    const verticalPosition = verticalPositions[tooltipDirection];
    const afterBorderColor = {
        top: `${borderColor} ${borderColor} ${backgroundColor} ${backgroundColor}`,
        bottom: `${backgroundColor} ${backgroundColor} ${borderColor} ${borderColor}`,
        left: `${borderColor} ${backgroundColor} ${backgroundColor} ${borderColor}`,
        right: `${backgroundColor} ${borderColor} ${borderColor} ${backgroundColor}`,
    };
    const { animation, animationDuration } = props.toolData;
    const isBeingResized = (resizeDetails === null || resizeDetails === void 0 ? void 0 : resizeDetails.resizeToolId) === props.toolData.id ? true : false;
    let translateDirections;
    if (isBeingResized) {
        const resizeDirection = ((_a = resizeDetails === null || resizeDetails === void 0 ? void 0 : resizeDetails.dir) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || "";
        if (resizeDirection.includes("left") && (tooltipDirection === "bottom" || tooltipDirection === "top")) {
            translateDirections = `translate(${((_b = resizeDetails === null || resizeDetails === void 0 ? void 0 : resizeDetails.delta) === null || _b === void 0 ? void 0 : _b.width) || 0}px, 0px)`;
        }
        else if (resizeDirection.includes("top") && tooltipDirection === "left") {
            translateDirections = `translate(0px, ${((_c = resizeDetails === null || resizeDetails === void 0 ? void 0 : resizeDetails.delta) === null || _c === void 0 ? void 0 : _c.height) || 0}px)`;
        }
        else if (tooltipDirection === "right") {
            translateDirections = `translate(${((_d = resizeDetails === null || resizeDetails === void 0 ? void 0 : resizeDetails.delta) === null || _d === void 0 ? void 0 : _d.width) || 0}px, ${resizeDirection.includes("bottom") ? 0 : ((_f = resizeDetails === null || resizeDetails === void 0 ? void 0 : resizeDetails.delta) === null || _f === void 0 ? void 0 : _f.height) || 0}px)`;
        }
    }
    return (React.createElement("div", { className: "_test_tooltip-viewtool" },
        React.createElement(TooltipBox, { hasBackground: enableBackground, backgroundColor: backgroundColor, containerBoxShadow: containerBoxShadow, enableBorder: enableBorder, borderColor: borderColor, borderRadius: borderRadius, borderWidth: borderWidth, viewportMode: viewportMode, animation: animation, animationDuration: animationDuration }),
        enableArrow && (React.createElement(TooltipArrowParent, { verticalPosition: verticalPosition, horizontalPosition: horizontalPosition, calculatedTooltipSize: calculatedTooltipSize, viewportMode: viewportMode, animation: animation, animationDuration: animationDuration },
            React.createElement(Rnd, { size: { width: `100%`, height: `100%` }, position: {
                    x: 0,
                    y: 0,
                }, enableResizing: false, onDragStart: e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsArrowDragging(true);
                }, onDragStop: (e, draggableData) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onArrowDragStop(e, draggableData);
                    setIsArrowDragging(false);
                }, dragAxis: ["left", "right"].includes(tooltipDirection) ? "y" : "x", bounds: "#tourial-viewport", disableDragging: viewportMode !== ViewportMode.EDIT },
                React.createElement(TooltipArrow, { hasBackground: enableBackground, backgroundColor: backgroundColor, calculatedTooltipSize: calculatedTooltipSize, enableBorder: enableBorder, borderColor: borderColor, afterBorderColor: afterBorderColor, tooltipDirection: tooltipDirection, borderWidth: borderWidth, isBeingResized: isBeingResized, translateDirections: translateDirections }))))));
}
export const Tooltip = React.memo(withToolWrapper(TourialTooltip));
