import React from "react";
import { DCTourPlayer } from "./players/dc-tour-player";
import { DCImagePlayer } from "./players/dc-image-player";
import { DCVideoPlayer } from "./players/dc-video-player";
import { DCPDFPlayer } from "./players/dc-pdf-player";
import { DCHTMLPlayer } from "./players/dc-html-player";
import { DCItemType } from "../../../../../../shared/types/demo-center";
import { DCDOMTourPlayer } from "./players/dc-dom-tour-player";
export const DCRenderPlayer = (props) => {
    const { item } = props;
    switch (item.type) {
        case DCItemType.TOUR:
            return React.createElement(DCTourPlayer, Object.assign({}, props));
        case DCItemType.DOM_TOUR:
            return React.createElement(DCDOMTourPlayer, Object.assign({}, props));
        case DCItemType.YOUTUBE:
        case DCItemType.VIMEO:
        case DCItemType.WISTIA:
        case DCItemType.VIDEO:
            return React.createElement(DCVideoPlayer, Object.assign({}, props)); // If additional DCItemTypes are added to DCVideoPlayer, be sure to update the session as well
        case DCItemType.IMAGE:
            return React.createElement(DCImagePlayer, Object.assign({}, props));
        case DCItemType.PDF:
            return React.createElement(DCPDFPlayer, Object.assign({}, props));
        case DCItemType.HTML:
        case DCItemType.LOOM:
        case DCItemType.VIDYARD:
        case DCItemType.VOCALVIDEO:
            return React.createElement(DCHTMLPlayer, Object.assign({}, props));
        default:
            return null;
    }
};
