import React, { useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import CaretIcon from "../../../../../assets/images/v3/big-caret-up.svg";
import { TToggleSwitch } from "../../../styled/t-toggle-switch";
export const ModalAccordion = (props) => {
    const { title, icon, children, onToggle, enabled } = props;
    const [isModalAccordionOpen, setIsModalAccordionOpen] = useState(false);
    return (React.createElement(ModalAccordionSection, null,
        React.createElement(ModalAccordionHeader, { onClick: () => {
                setIsModalAccordionOpen(!isModalAccordionOpen);
            } },
            React.createElement(ModalAccordionTitle, null,
                React.createElement("img", { src: icon, alt: title }),
                React.createElement("span", null, title)),
            onToggle && (React.createElement(TToggleSwitch, { size: "small", enabled: enabled, onChange: boolean => {
                    onToggle(boolean);
                } })),
            React.createElement(CollapseExpandButton, { onClick: () => {
                    setIsModalAccordionOpen(!isModalAccordionOpen);
                } },
                React.createElement(CaretImg, { style: { transform: isModalAccordionOpen ? "none" : "rotate(180deg)" }, draggable: "false", src: CaretIcon, alt: "collapse/expand accordion button" }))),
        isModalAccordionOpen && React.createElement(ModalAccordionContent, null, children)));
};
const ModalAccordionSection = styled.div `
  border-bottom: 1px solid ${newTheme.colors.grey400};
  &:last-child {
    border-bottom: none;
  }
`;
const ModalAccordionHeader = styled.div `
  display: flex;
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
  background-color: ${newTheme.colors.grey100};
`;
const ModalAccordionTitle = styled.div `
  display: flex;
  flex: 1;
  align-items: center;
  > img {
    width: 24px;
    height: 24px;
  }
  > span {
    font: ${newTheme.fonts.bold};
    padding-left: 8px;
  }
`;
const CollapseExpandButton = styled.button `
  margin-left: 8px;
  cursor: pointer;
`;
const CaretImg = styled.img `
  transition: transform 0.2s linear;
`;
const ModalAccordionContent = styled.div `
  background-color: ${newTheme.colors.grey200};
  padding: 8px 16px 24px 16px;
`;
export const ModalAccordionContainer = styled.div `
  background-color: ${newTheme.colors.white};
  border: 1px solid ${newTheme.colors.grey400};
  border-radius: 4px;
  margin: 16px 0;
  overflow: hidden;
`;
export const ModalAccordionContentTitle = styled.span `
  display: flex;
  width: 100%;
  font: ${newTheme.fonts.normal};
  padding-bottom: 2px;
  margin-top: 16px;
`;
export const ModalAccordionLabel = styled.div `
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${newTheme.colors.white};
  border-bottom: 1px solid ${newTheme.colors.grey400};
  padding: 8px 16px;
  > span {
    font: ${newTheme.fonts.bold};
  }
  > img {
    width: 24px;
    height: 24px;
  }
`;
