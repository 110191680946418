import React, { useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { TDropdownSelect } from "../../../../styled/t-dropdown-select";
export function LabelledDropdown(props) {
    const { name } = props;
    const [labelColor, setLabelColor] = useState(newTheme.colors.grey700);
    return (React.createElement(Label, { style: { color: labelColor } },
        React.createElement("span", null, name),
        React.createElement(TDropdownSelect, Object.assign({}, props, { style: { margin: 0 }, onFocus: (e) => {
                var _a;
                setLabelColor(newTheme.colors.blue400);
                (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props, e);
            }, onBlur: (e) => {
                var _a;
                setLabelColor(newTheme.colors.grey700);
                (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, e);
            } }))));
}
const Label = styled.label `
  font: ${newTheme.fonts.normal};
  padding-bottom: 4px;
  user-select: none;
`;
