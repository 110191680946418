import React from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import WhiteTrashCan from "../../../../../assets/images/v3/white-trashcan.svg";
export const ColorSwatch = ({ color, onDelete, isActive, onClick }) => {
    return (React.createElement(ColorSwatchContainer, { className: `${onClick ? "button" : ""} ${isActive ? "active" : ""}`, onClick: () => onClick === null || onClick === void 0 ? void 0 : onClick() },
        React.createElement(Color, { color: color }),
        onDelete && (React.createElement(Overlay, { onClick: () => onDelete === null || onDelete === void 0 ? void 0 : onDelete() },
            React.createElement("img", { src: WhiteTrashCan })))));
};
const ColorSwatchContainer = styled.div `
  display: flex;
  width: 32px;
  height: 32px;
  border: 1px solid ${newTheme.colors.grey600};
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  &:hover {
    > span {
      display: flex;
    }
  }
  &.active {
    border-color: ${newTheme.colors.blue500};
  }
  &.button {
    cursor: pointer;
  }
`;
const Color = styled.div `
  width: 30px;
  height: 30px;
  background-color: ${p => p.color};
`;
const Overlay = styled.span `
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  display: none;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
  cursor: pointer;
`;
