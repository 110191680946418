import React, { useEffect } from "react";
import styled from "styled-components";
import { useDCCreateUrl, useDCConfig, useDCSidebar, useDCSlug, useDCTheme, useDCIsLiveMode } from "../hooks/dc-hooks";
import { Redirect, useParams } from "react-router-dom";
import { DCModuleType, } from "../../../../../../shared/types/demo-center";
import { DCTrackSelector } from "./dc-track-selector";
import { dcGetFirstItemIdFromSelectedTracks, dcGetRGBColorStringFromHex, dcGetRoot } from "../helpers/dc-helpers";
import { DCHeader } from "./dc-header";
import { useDCActivateModule, useDCGetModule, useDCGetPathForNextModule, useDCConfirmTrackSelectionsForModule, } from "../hooks/dc-module-hooks";
import { dcFadeIn } from "../helpers/dc-style-helpers";
import { useDCModuleLoadEvent } from "../hooks/dc-event-hooks";
import { useDCConfigGatingFormID, useDCShouldShowUpfrontQuestions, } from "../../../../redux/selectors/demo-center-selectors";
import { useSetPageForm } from "../../../../redux/selectors/product-page-selectors";
import { wasFormSubmitted } from "../../../../helpers/form-helpers";
import { newTheme } from "../../../styled/theme";
import { DCControls } from "./dc-controls";
import UpfrontQuestions from "./dc-upfront-questions";
export const DCModuleView = () => {
    var _a;
    const { module: slug } = useParams();
    const isLive = useDCIsLiveMode();
    const dcSlug = useDCSlug();
    const { moduleDetails } = useDCConfig();
    const theme = useDCTheme();
    const createUrl = useDCCreateUrl();
    const getModule = useDCGetModule();
    const confirmTrackSelectionsForModule = useDCConfirmTrackSelectionsForModule();
    const getPathForNextModule = useDCGetPathForNextModule();
    const module = getModule(slug);
    const firstItemId = (_a = module === null || module === void 0 ? void 0 : module.tracks[0]) === null || _a === void 0 ? void 0 : _a.items[0];
    const isNoPrompt = (module === null || module === void 0 ? void 0 : module.type) === DCModuleType.NO_PROMPT;
    const gatingFormId = useDCConfigGatingFormID();
    const setPageForm = useSetPageForm();
    const root = dcGetRoot();
    useDCActivateModule(slug);
    useDCModuleLoadEvent(module === null || module === void 0 ? void 0 : module.id);
    useDCSidebar();
    const shouldShowUpfrontQuestions = useDCShouldShowUpfrontQuestions();
    const onTrackSelect = (_track, _isSelected, selectedTracks) => {
        confirmTrackSelectionsForModule(slug, selectedTracks);
    };
    const onSelectionConfirm = (selectedTracks) => {
        let forwardPath = "";
        const firstItemId = dcGetFirstItemIdFromSelectedTracks(module, selectedTracks);
        if (firstItemId) {
            forwardPath = `/${slug}/view/${firstItemId}`;
        }
        else {
            forwardPath = getPathForNextModule(slug);
        }
        confirmTrackSelectionsForModule(slug, selectedTracks, forwardPath);
    };
    const onSkip = () => {
        const forwardPath = getPathForNextModule(slug);
        confirmTrackSelectionsForModule(slug, [], forwardPath);
    };
    useEffect(() => {
        if (module === null || module === void 0 ? void 0 : module.isGated) {
            if (!wasFormSubmitted(gatingFormId)) {
                setPageForm({
                    formId: gatingFormId,
                    isGated: false,
                    isEnabled: true,
                    isCTA: false,
                });
            }
        }
    }, [module]);
    if (!module) {
        return React.createElement(Redirect, { to: `/${root}/${dcSlug}` });
    }
    if (isNoPrompt && firstItemId) {
        return React.createElement(Redirect, { to: createUrl(`/${slug}/view/${firstItemId}`) });
    }
    return (React.createElement(React.Fragment, null,
        shouldShowUpfrontQuestions && React.createElement(UpfrontQuestions, null),
        React.createElement(DCHeader, { showBrowseButton: true, transparent: true }),
        React.createElement(LayoutContainer, null,
            React.createElement("div", null,
                React.createElement(ResizeContainer, { className: isLive ? "live" : "" },
                    React.createElement(AspectRatioContainer, null,
                        React.createElement(DCModuleViewContainer, { config: moduleDetails, theme: theme, className: isLive ? "animate" : "" }, !isNoPrompt && (React.createElement(DCTrackSelector, { module: module, onSelectionConfirm: onSelectionConfirm, onTrackSelect: onTrackSelect, onSkip: onSkip })))),
                    React.createElement(DCControls, null))))));
};
const LayoutContainer = styled.div `
  display: flex;
  flex-direction: column;
  height: calc(100vh - 82px);
  height: calc(100dvh - 82px);
  margin: 0 24px 0 0;
  padding: 0 0 24px 348px;
`;
const ResizeContainer = styled.div `
  margin: 0 auto;
  &.live {
    max-width: min(calc(((100vh - 214px) * (16 / 9))), 1920px);
  }
`;
const AspectRatioContainer = styled.div `
  position: relative;
  max-height: 100vh;
  width: 100%;
  padding-top: 56.25%;
`;
export const DCModuleViewContainer = styled.div `
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  border-radius: ${p => p.config.borderRadius}px;
  background-color: ${p => dcGetRGBColorStringFromHex(p.theme.neutralTranslucentColor, p.theme.neutralTranslucentOpacity)};
  box-shadow: ${newTheme.shadows.demoCenter};
  backdrop-filter: blur(50px);
  &.animate {
    animation: ${dcFadeIn} 0.2s ease-in;
  }
`;
