import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isAuthorized } from "../../../../shared/functions/auth-functions";
import styled from "styled-components";
import TP from "../../legacy/components/styled/t-p";
import { TLoadingSpinner } from "../styled/t-loading-spinner";
import { useIsLoading } from "../../legacy/selectors";
import { useUserAccess } from "../../redux/selectors/redux-selectors";
export function GeneralRoute({ component: C, exact = false, path, key }) {
    return React.createElement(Route, { exact: exact, path: path, key: key, render: (props) => React.createElement(C, Object.assign({}, props)) });
}
export function UnauthenticatedRoute({ component: C, exact = false, path, key }) {
    const isLoading = useIsLoading();
    const userAccess = useUserAccess();
    if (isLoading) {
        return (React.createElement(LoadingContainer, null,
            React.createElement(TLoadingSpinner, null)));
    }
    return (React.createElement(Route, { exact: exact, path: path, key: key, render: (props) => (!userAccess ? React.createElement(C, Object.assign({}, props)) : React.createElement(Redirect, { to: "/" })) }));
}
export function AuthenticatedRoute({ component: C, exact = false, path, key, authorization, reloadOnRedirect, }) {
    const isLoading = useIsLoading();
    const userAccess = useUserAccess();
    if (isLoading) {
        return (React.createElement(LoadingContainer, null,
            React.createElement(TLoadingSpinner, null)));
    }
    if (!userAccess) {
        if (reloadOnRedirect)
            window.location.href = "/login";
        return React.createElement(Redirect, { to: `/login` });
    }
    if (!isAuthorized(authorization, userAccess)) {
        return (React.createElement(Div, null,
            React.createElement(TP, null, "Sorry, you don't have sufficient privileges to view this page.")));
    }
    return React.createElement(Route, { exact: exact, path: path, key: key, render: (props) => React.createElement(C, Object.assign({}, props)) });
}
const Div = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin-top: 50px;
`;
const LoadingContainer = styled.div `
  height: 100vh;
`;
