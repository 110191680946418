import axios from "axios";
import React from "react";
import TLabel from "../../../styled/t-label";
import TToggle from "../../../styled/t-toggle";
import { EditorToolSizeAndPosition } from "../../editor-tool-size-and-position";
import { useTourialId, useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { ToolColorPicker } from "../../editor-tool-color-picker";
import { BorderControl } from "../../editor-border-control";
import TTextInput from "../../../styled/t-text-input";
import TrashIcon from "../../../../../../assets/images/rounded-grey-trash-can.svg";
import UpArrowIcon from "../../../../../../assets/images/box-arrow-up.svg";
import DownArrowIcon from "../../../../../../assets/images/box-arrow-down.svg";
import { Async } from "react-async";
import TP from "../../../styled/t-p";
import { Link } from "react-router-dom";
import TButton from "../../../styled/t-button";
import { fontWeightOptions } from "../../../../../types/tools";
import TSelect from "../../../styled/t-select";
import TNumberInput from "../../../styled/t-number-input";
import TH4 from "../../../styled/t-h4";
import { cloneDeep } from "lodash";
import { theme } from "../../../../../../../shared/legacy/legacy-tool-data";
import { fontFamilyOptions } from "../../../../../types/tools";
export const getAllIntegrationsForForm = (tourId, formId) => axios
    .post(`/integrations/getAllIntegrationsForForm`, {
    tourId,
    formId,
})
    .then(r => r.data)
    .catch(e => console.error(e));
export function trimAndMakeUnique(newString, existingStrings) {
    newString = newString.trim();
    if (!existingStrings) {
        return newString;
    }
    if (existingStrings === null || existingStrings === void 0 ? void 0 : existingStrings.includes(newString)) {
        const dashNumberPostfix = new RegExp(/-\d+$/);
        if (newString.match(dashNumberPostfix)) {
            const endNumber = new RegExp(/\d+$/);
            const match = newString.match(endNumber);
            if (match) {
                const numString = match[0];
                let num = Number.parseInt(numString);
                return trimAndMakeUnique(newString.replace(endNumber, `${++num}`), existingStrings);
            }
        }
        else {
            return trimAndMakeUnique(`${newString}-1`, existingStrings);
        }
        return trimAndMakeUnique(newString, existingStrings);
    }
    else {
        return newString;
    }
}
export function FormInputs({ toolData }) {
    var _a;
    const updateTool = useUpdateTool();
    const tourialId = useTourialId();
    const { fields } = toolData;
    return (React.createElement("div", { style: { padding: 8 } },
        React.createElement(EditorToolSizeAndPosition, { toolId: toolData.id, x: toolData.sizeAndPosition.x, y: toolData.sizeAndPosition.y, width: toolData.sizeAndPosition.width, height: toolData.sizeAndPosition.height }),
        React.createElement(TLabel, null,
            "Background",
            React.createElement(TToggle, { value: toolData.enableBackground, onClick: () => {
                    updateTool(Object.assign(Object.assign({}, toolData), { enableBackground: !toolData.enableBackground }));
                } })),
        React.createElement(ToolColorPicker, { toolId: toolData.id, property: "backgroundColor", label: "Background color" }),
        React.createElement(BorderControl, { toolData: toolData }),
        React.createElement(Async, { promiseFn: () => getAllIntegrationsForForm(tourialId, toolData.id) }, ({ data, error }) => {
            let hasIntegrations = true;
            if (error)
                return `Something went wrong: ${error.message}`;
            if (data)
                hasIntegrations = !!data.length;
            if (hasIntegrations) {
                return (React.createElement(TP, null,
                    "Cannot customize form inputs.",
                    " ",
                    React.createElement(Link, { to: "/integrations", style: {
                            color: theme.colors.cobalt.medium,
                            textDecoration: "underline",
                        } }, "There is already an integration attached to this form.")));
            }
            return (React.createElement("div", { style: { display: "flex", flexDirection: "column", width: "50%" } },
                fields.map((f, i) => (React.createElement("div", { key: i, style: { display: "flex" } },
                    React.createElement(TTextInput, { value: f.role, disabled: f.role === "Email", style: { width: "128px", height: "26px", marginTop: "3px" }, onChange: e => {
                            const newTool = cloneDeep(toolData);
                            newTool.fields[i].role = e.target.value;
                            updateTool(newTool);
                        } }),
                    React.createElement("img", { width: 28, src: UpArrowIcon, draggable: false, style: { cursor: "pointer" }, onClick: () => {
                            const newTool = cloneDeep(toolData);
                            if (i >= 1) {
                                const tmp = newTool.fields[i];
                                newTool.fields[i] = newTool.fields[i - 1];
                                newTool.fields[i - 1] = tmp;
                                updateTool(newTool);
                            }
                        } }),
                    React.createElement("img", { width: 28, src: DownArrowIcon, draggable: false, style: { cursor: "pointer" }, onClick: () => {
                            const newTool = cloneDeep(toolData);
                            if (i < newTool.fields.length - 1) {
                                const tmp = newTool.fields[i];
                                newTool.fields[i] = newTool.fields[i + 1];
                                newTool.fields[i + 1] = tmp;
                                updateTool(newTool);
                            }
                        } }),
                    f.role !== "Email" && (React.createElement("img", { width: 28, src: TrashIcon, draggable: false, style: { cursor: "pointer" }, onClick: () => {
                            const newTool = cloneDeep(toolData);
                            if (newTool.fields[i].role !== "Email") {
                                newTool.fields = newTool.fields.filter((f, ii) => ii !== i);
                                updateTool(newTool);
                            }
                        } }))))),
                React.createElement(TButton, { onClick: () => {
                        const newField = {
                            role: trimAndMakeUnique(`New Field ${fields.length + 1}`, fields.map(t => t.role)),
                            value: "",
                        };
                        const newTool = cloneDeep(toolData);
                        newTool.fields = [...newTool.fields, newField];
                        updateTool(newTool);
                    } }, "Add Field")));
        }),
        React.createElement(TLabel, null,
            "Email to",
            React.createElement(TTextInput, { value: ((_a = toolData === null || toolData === void 0 ? void 0 : toolData.notificationEmailAddresses) === null || _a === void 0 ? void 0 : _a[0]) || "", style: { width: "145px", height: "26px", marginTop: "3px" }, onChange: e => {
                    const newTool = cloneDeep(toolData);
                    newTool.notificationEmailAddresses = [e.target.value];
                    updateTool(newTool);
                } })),
        React.createElement(TH4, null, "Field Font"),
        React.createElement(TLabel, null,
            "Family",
            React.createElement(TSelect, { value: toolData === null || toolData === void 0 ? void 0 : toolData.fontFamily, onChange: e => {
                    const newTool = cloneDeep(toolData);
                    newTool.fontFamily = e.target.value;
                    updateTool(newTool);
                }, style: { width: 400 } },
                React.createElement("option", { value: "" }, "- "),
                fontFamilyOptions.map((o, i) => (React.createElement("option", { key: i, value: o }, o))))),
        React.createElement(TLabel, null,
            "Weight",
            React.createElement(TSelect, { value: toolData === null || toolData === void 0 ? void 0 : toolData.fontWeight, onChange: e => {
                    const newTool = cloneDeep(toolData);
                    newTool.fontWeight = e.target.value;
                    updateTool(newTool);
                }, style: { width: 400 } },
                React.createElement("option", { value: "" }, "- "),
                fontWeightOptions.map(o => (React.createElement("option", { key: o, value: o }, o))))),
        React.createElement(TLabel, null,
            "Size",
            React.createElement(TNumberInput, { style: { color: theme.colors.grey.darkest }, value: toolData.fontSize, min: 1, max: 50, onChange: e => {
                    const newTool = cloneDeep(toolData);
                    updateTool(Object.assign(Object.assign({}, newTool), { fontSize: Number(e.target.value) }));
                } })),
        React.createElement(ToolColorPicker, { toolId: toolData.id, property: "fontColor", label: "Font color" })));
}
