import { cloneDeep, isEqual } from "lodash";
import { DCCMPageType, DCItemType, DCModuleType, DC_LIVE_PREVIEW_ROOT, DC_ROOT, DC_SHARE_LINK_ROOT, } from "../../../../../../shared/types/demo-center";
import { hexToRGB } from "../../../../helpers/misc";
import { sanitizeMongoDocumentForFS } from "../../../../../../shared/functions/firebase";
export const dcGetStateFromCurrentURLParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tags = urlParams.get("tags");
    const trackSelections = urlParams.get("o");
    return { tags, trackSelections };
};
export const dcGetReadableItemType = (type) => {
    switch (type) {
        case DCItemType.HTML:
            return "Article";
        case DCItemType.IMAGE:
            return "Image";
        case DCItemType.PDF:
            return "Document";
        case DCItemType.TOUR:
            return "Tour";
        case DCItemType.DOM_TOUR:
            return "HTML Tour";
        case DCItemType.VIDEO:
        case DCItemType.YOUTUBE:
        case DCItemType.VIMEO:
        case DCItemType.VIDYARD:
        case DCItemType.VOCALVIDEO:
        case DCItemType.LOOM:
        case DCItemType.WISTIA:
            return "Video";
        default:
            return type;
    }
};
export const dcGetReadableModuleType = (type) => {
    switch (type) {
        case DCModuleType.SINGLE:
            return "Single select";
        case DCModuleType.MULTIPLE:
            return "Multi select";
        case DCModuleType.BOOLEAN:
            return "Opt-in";
        case DCModuleType.NO_PROMPT:
            return "No prompt";
        case DCModuleType.SCALE:
            return "Interest Scale";
        default:
            return type;
    }
};
export const dcGetRGBColorStringFromHex = (hex, opacity) => {
    if (hex === "transparent")
        return "transparent";
    const rgb = hexToRGB(hex);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${String(opacity)})`;
};
export const dcGetItemDurationLabel = (duration) => {
    if (!duration)
        return "";
    const mins = Math.floor(duration / 60);
    const secs = duration % 60;
    if (duration < 60) {
        return `${duration} sec`;
    }
    else if (secs === 0) {
        return `${mins} min`;
    }
    else {
        return `${mins}m ${secs}s`;
    }
};
export const dcEncodeTrackSelections = (selections) => {
    const pairs = [];
    selections.forEach(s => {
        pairs.push(`${s.module}:${s.tracks.join(",")}`);
    });
    return btoa(pairs.join("|"));
};
export const dcDecodeTrackSelections = (data) => {
    const trackSelections = [];
    try {
        const pairs = atob(data).split("|");
        pairs.forEach(p => {
            const selection = p.split(":");
            trackSelections.push({
                module: selection[0],
                tracks: selection[1].split(","),
            });
        });
    }
    catch (e) {
        console.error(e);
    }
    return trackSelections;
};
export const dcGetFirstItemIdFromSelectedTracks = (module, selectedTracks) => {
    let firstItemId = null;
    module.tracks.forEach(t => {
        if (selectedTracks.includes(t.slug) && !firstItemId) {
            firstItemId = t.items[0];
        }
    });
    return firstItemId;
};
export const dcGenerateSlugFromText = (text = "") => {
    return text
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
};
export const dcEnforceUniqueSlug = (slug, existsFn) => {
    if (existsFn(slug)) {
        let count = 1;
        let newSlug = `${slug}-${count}`;
        while (existsFn(newSlug)) {
            count++;
            newSlug = `${slug}-${count}`;
        }
        return newSlug;
    }
    return slug;
};
export const dcReplaceFileExtenstionInUrl = (url, newExtension) => {
    const splitUrl = url.split(".");
    splitUrl[splitUrl.length - 1] = newExtension;
    return splitUrl.join(".");
};
export const dcGetFileNameFromUrl = (url) => {
    const splitUrl = url.split("/");
    const fileName = splitUrl[splitUrl.length - 1];
    return fileName;
};
export const dcGetItemTypeFromUrl = (url) => {
    const domain = url.split("/")[2];
    if (domain === "www.youtube.com" || domain === "youtu.be")
        return DCItemType.YOUTUBE;
    /**
     * https://vimeo.com/347119375
     * https://player.vimeo.com/video/347119375
     */
    if (domain.includes("vimeo.com"))
        return DCItemType.VIMEO;
    /**
     * https://salesloft.wistia.com/medias/cqtia2caw9
     * https://fast.wistia.net/embed/iframe/ydmdu2olpc? => ReactPlayer is able to handle this secondhand embed link even though media id is missing
     * Below is not intended for sharing(must be associated with a project first):
     * https://embed-ssl.wistia.com/deliveries/94cf05e2e96bfc0c0e9eab05bc20a38be6dac17f.bin?disposition=attachment&filename=09%2F29%2F2023+2%3A04+PM+%28Camera%29.mp4
     */
    if (url.includes("wistia.com/medias") || url.includes("fast.wistia.net/embed/iframe/"))
        return DCItemType.WISTIA;
    /**
     * https://www.loom.com/embed/ee99523564bf4688bb6f6bdb690e5c6d?sid=613e7202-da0c-42c6-91e7-db46df2a15a6
     * https://www.loom.com/share/817fab1fff7647b3977e6f269262ab03?sid=e8fc78e6-eaab-4e78-975a-b077cfc87dc9
     */
    if (url.includes("loom.com/embed") || url.includes("loom.com/share"))
        return DCItemType.LOOM;
    /**
     * note: embed must be enabled on video => reference https://knowledge.vidyard.com/hc/en-us/articles/360033244534
     * https://play.vidyard.com/XCniFKqLd993pEWtyTMfMc
     * https://share.vidyard.com/watch/xZjS2fdXQanCphRQn1UHyD
     */
    if (url.includes("play.vidyard.com") || url.includes("share.vidyard.com/watch"))
        return DCItemType.VIDYARD;
    /**
     * notes: shrink-to-fit must be enabled for correct sizing without scroll
     * manually extracted from embed => https://vocalvideo.com/embed/v1/videos/2182/popover?shadow=large
     * https://vocalvideo.com/v/vocal-video-whistle-kayla-social-media
     *
     */
    if (url.includes("vocalvideo.com/embed") || url.includes("vocalvideo.com/v/"))
        return DCItemType.VOCALVIDEO;
    return dcGetItemTypeFromFileName(dcGetFileNameFromUrl(url));
};
export const dcGetThumbnailFromUrl = (url) => {
    const type = dcGetItemTypeFromUrl(url);
    // video platform thumbnails are generic unless retrieved from server
    switch (type) {
        case DCItemType.YOUTUBE:
            return `https://res.cloudinary.com/dim9has1z/image/upload/v1696020023/qnhbmvqfairy3ee8v9lh.png`;
        case DCItemType.VIMEO:
            return `https://res.cloudinary.com/dim9has1z/image/upload/v1695144944/wwbouapvxgea0gloylae.png`;
        case DCItemType.LOOM:
            return `https://res.cloudinary.com/dim9has1z/image/upload/v1696012321/loqsiqv7z16fcnydv9bi.png`;
        case DCItemType.WISTIA:
            return `https://res.cloudinary.com/dim9has1z/image/upload/v1696012322/nrfrqd4ivbmqx1oojb4h.png`;
        case DCItemType.VIDYARD:
            return `https://res.cloudinary.com/dim9has1z/image/upload/v1696012321/yjffipsgmrwddha5drly.png`;
        case DCItemType.VOCALVIDEO:
            return `https://res.cloudinary.com/dim9has1z/image/upload/w_400,h_225,c_scale/v1696012322/zy0hdmkwjkgcy2nbiwdj.png`;
        case DCItemType.IMAGE:
            return url;
        case DCItemType.PDF:
            return dcReplaceFileExtenstionInUrl(url, "jpg");
        default:
            return "";
    }
};
export const dcGetItemTypeFromFileName = (fileName) => {
    const splitFileName = fileName.split(".");
    const extension = splitFileName[splitFileName.length - 1].toLowerCase();
    switch (extension) {
        case "pdf":
            return DCItemType.PDF;
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
            return DCItemType.IMAGE;
        case "mov":
        case "mpg":
        case "mpeg":
        case "wmv":
        case "avi":
        case "flv":
        case "webm":
        case "mp4":
            return DCItemType.VIDEO;
        default:
            return DCItemType.HTML;
    }
};
export const dcApplyThemeAndStylesToConfig = (config, theme, styles, modifyButtonFn) => {
    const { cardBorderRadius, containerBorderRadius } = styles;
    const { neutralPrimary, neutralSecondary, textMain, textSecondary } = theme;
    const c = cloneDeep(config);
    c.welcome.title.style.color = textMain;
    c.welcome.message.style.color = textMain;
    c.welcome.browseButton = modifyButtonFn(c.welcome.browseButton, { applyTheme: true });
    c.itemCard.borderRadius = cardBorderRadius;
    c.itemCard.viewButton = modifyButtonFn(c.itemCard.viewButton);
    c.itemCard.label.color = textSecondary;
    c.itemCard.title.color = textMain;
    c.itemCard.description.color = textSecondary;
    c.gallerySection.borderRadius = containerBorderRadius;
    c.gallerySection.titleStyle.color = textMain;
    c.gallerySection.toggleStyle.color = textMain;
    c.sidebar.borderRadius = containerBorderRadius;
    c.sidebar.moduleTitleStyle.color = textMain;
    c.sidebar.emptyStateTextStyle.color = textSecondary;
    c.sidebar.itemTitleStyle.color = textSecondary;
    c.sidebar.itemTitleStyle.activeColor = textMain;
    c.sidebar.itemTypeStyle.color = textSecondary;
    c.moduleDetails.borderRadius = containerBorderRadius;
    c.moduleDetails.titleTextStyle.color = textMain;
    c.moduleDetails.promptTextStyle.color = textMain;
    c.moduleDetails.descriptionTextStyle.color = textMain;
    c.cardButton.borderRadius = cardBorderRadius;
    c.cardButton.labelStyle.color = textMain;
    c.badge.defaultBackgroundColor = neutralSecondary;
    c.badge.text.color = textMain;
    c.controls.labelStyle.color = textMain;
    c.controls.next.style.color = textSecondary;
    c.controls.titleStyle.color = textMain;
    c.controls.typeStyle.color = textSecondary;
    c.controls.placeholder.style.color = textSecondary;
    c.share.backgroundColor = neutralPrimary;
    c.share.inputStyle.color = textMain;
    c.share.inputStyle.backgroundColor = neutralPrimary;
    c.share.inputStyle.borderColor = neutralSecondary;
    c.share.title.style.color = textMain;
    c.share.description.style.color = textSecondary;
    return c;
};
export const dcGetSlugFromLocation = () => {
    return location.pathname
        .replace(`/${DC_ROOT}/`, "")
        .replace(`/${DC_LIVE_PREVIEW_ROOT}/`, "")
        .replace(`/${DC_SHARE_LINK_ROOT}/`, "")
        .split("/")[0];
};
export const dcGetLiveUrl = (baseUrl, slug) => {
    return `${baseUrl}${DC_ROOT}/${slug}`;
};
export const dcIsLivePreview = () => {
    return location.pathname.includes(`/${DC_LIVE_PREVIEW_ROOT}/`);
};
export const dcIsShareLink = () => {
    return location.pathname.includes(`/${DC_SHARE_LINK_ROOT}/`);
};
export const dcGetRoot = () => {
    if (dcIsLivePreview()) {
        return DC_LIVE_PREVIEW_ROOT;
    }
    else if (dcIsShareLink()) {
        return DC_SHARE_LINK_ROOT;
    }
    return DC_ROOT;
};
export const dcIsPathABuilderCMPage = (path) => {
    return Object.values(DCCMPageType).includes(path) && path !== DCCMPageType.WELCOME;
};
export const dcFsAndMongoDocsAreEqual = (doc1, doc2) => {
    return isEqual(sanitizeMongoDocumentForFS(doc1), sanitizeMongoDocumentForFS(doc2));
};
export const dcItemIsUploadedToTourial = (item) => {
    var _a;
    return (_a = item === null || item === void 0 ? void 0 : item.url) === null || _a === void 0 ? void 0 : _a.includes("cloudinary.com");
};
