import React from "react";
import { useTourialStatus } from "../../../../redux/selectors/redux-selectors";
import styled from "styled-components";
import TStatusIndicator from "../../../styled/t-status-indicator";
export function BuilderIsLiveBadge() {
    const tourialStatus = useTourialStatus();
    return (React.createElement(Div, null,
        React.createElement(TStatusIndicator, { status: tourialStatus })));
}
export function IsLiveBadge({ tourialStatus }) {
    return (React.createElement(Div, null,
        React.createElement(TStatusIndicator, { status: tourialStatus })));
}
const Div = styled.div `
  padding-bottom: 4px;
`;
