import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { EditorAccordion } from "../../../../pages/builder/editor/editor-accordion";
import React from "react";
import styled from "styled-components";
import ButtonIcon from "../../../../../../assets/images/v3/editor-accordian-button-icon.svg";
import { cloneDeep } from "lodash";
export function GlobalButtonsConfigureSection(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    return (React.createElement(Section, { id: "_test_editor-advanced-section" },
        React.createElement(EditorAccordion, { icon: ButtonIcon, title: "Button 2", isAlwaysOpen: true, isEnabled: tool.buttons.button2.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.buttons.button2.isEnabled = !t.buttons.button2.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { icon: ButtonIcon, title: "Button 3", isAlwaysOpen: true, isEnabled: tool.buttons.button3.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.buttons.button3.isEnabled = !t.buttons.button3.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { icon: ButtonIcon, title: "Button 4", isAlwaysOpen: true, isEnabled: tool.buttons.button4.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.buttons.button4.isEnabled = !t.buttons.button4.isEnabled;
                updateTool(t);
            } })));
}
const Section = styled.div `
  max-height: 65vh;
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
