import React, { useRef, useState } from "react";
import styled from "styled-components";
import BlueCaretDown from "../../../../../../assets/images/v3/blue-caret-down.svg";
import { DCIcon } from "../../components/dc-icon";
import { newTheme } from "../../../../styled/theme";
import { useOutsideClick } from "../../../../../hooks/use-outside-click";
export const DCStylesDropdown = ({ children, label, icon, isCloseOnOutsideClickDisabled }) => {
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    useOutsideClick([dropdownRef, buttonRef], () => {
        if (isOpen && !isCloseOnOutsideClickDisabled) {
            setIsOpen(false);
        }
    });
    return (React.createElement(DropdownWrapper, null,
        React.createElement(DropdownButton, { onClick: () => setIsOpen(!isOpen), className: isOpen ? "open" : "", ref: buttonRef },
            React.createElement(DCIcon, { type: icon }),
            React.createElement(Label, null, label),
            React.createElement("img", { src: BlueCaretDown, style: isOpen ? { transform: "rotate(-180deg)" } : null })),
        isOpen && React.createElement(DropdownContainer, { ref: dropdownRef }, children)));
};
const DropdownWrapper = styled.div `
  position: relative;
`;
const DropdownButton = styled.div `
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
  padding: 4px 8px;
  height: 32px;
  border: 1px solid ${newTheme.colors.grey600};
  background-color: ${newTheme.colors.white};
  border-radius: 4px;
  &.open,
  &:hover {
    border-color: ${newTheme.colors.blue300};
  }
  img {
    transition: transform 0.2s ease-in-out;
  }
  cursor: pointer;
`;
const DropdownContainer = styled.div `
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 16px;
  border: 1px solid ${newTheme.colors.grey400};
  background-color: ${newTheme.colors.grey100};
  box-shadow: ${newTheme.shadows.demoCenter};
`;
const Label = styled.div `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.black};
`;
