import React from "react";
import styled from "styled-components";
import { useActiveVariantName, useActiveView, useActiveZoom, useChangeStep, useCurrentPage, useIsAutomatedScreen, useMediaSize, usePushHistory, useScreen, useUpdatePage, useUpdateZoom, } from "../../../redux/selectors/redux-selectors";
import { newTheme } from "../../styled/theme";
import { ZoomOffsetController } from "../../pages/builder/editor/atomic/zoom-offset-controller";
import { NumberInput } from "../../pages/builder/editor/atomic/number-input";
import { TCheckbox } from "../../styled/t-checkbox";
import TButton, { TButtonVariants } from "../../styled/t-button";
import { getDefaultZoom } from "../../../../../shared/functions/tourial";
import { EditorTabs } from "../../pages/builder/editor/editor-tabs";
import { FormV3StepInteractionEditor } from "../form-v3/editor/form-v3-step-interaction-editor";
import { getDimensionToGrow } from "../../../helpers/tourial-helpers/tourial-viewport-helpers";
import { isImageFileExtension } from "../../../helpers/media";
import VideoSettings from "./video-settings";
import TimingSettings from "./timing-settings";
import { StepNameInput } from "./step-name-input";
import { useDeletePage, useDeleteZoom, useDeselect, useDuplicateView, useSetCurrentStepName, } from "../../../redux/selectors/builder-selectors";
import EditIcon from "../../../../assets/images/v3/blue-pencil.svg";
import DuplicateScreenIcon from "../../../../assets/images/v3/dropdown-duplicate-screen.svg";
import DeleteScreenIcon from "../../../../assets/images/v3/dropdown-delete-screen.svg";
import TTooltip from "../../styled/t-tooltip";
function ZoomEditor() {
    const activePage = useCurrentPage();
    const updatePage = useUpdatePage();
    const activeZoom = useActiveZoom();
    const updateZoom = useUpdateZoom();
    const activeVariantName = useActiveVariantName();
    const srcSize = useMediaSize();
    const dimensionToGrow = getDimensionToGrow(srcSize === null || srcSize === void 0 ? void 0 : srcSize.width, srcSize === null || srcSize === void 0 ? void 0 : srcSize.height, activeVariantName);
    let disabledAxis;
    if (srcSize && activeZoom.z === 1) {
        disabledAxis = dimensionToGrow;
    }
    return (React.createElement(ContentContainer, null,
        React.createElement(ZoomOffsetController, { zoomData: activeZoom, disabledAxis: disabledAxis, onZAxisUpdate: z => {
                const newZoom = Object.assign(Object.assign({}, activeZoom), { z });
                // 16:9 images with default zoom must reset zoom offsets for transition check
                if (z === 1) {
                    if (dimensionToGrow === "xy") {
                        newZoom.y = 100;
                        newZoom.x = 0;
                    }
                    else if (dimensionToGrow === "x") {
                        newZoom.x = 0;
                    }
                    else if (dimensionToGrow === "y") {
                        newZoom.y = 100;
                    }
                }
                updateZoom(newZoom, activePage.id);
            }, onYAxisUpdate: y => {
                const newZoom = Object.assign(Object.assign({}, activeZoom), { y });
                updateZoom(newZoom, activePage.id);
            }, onXAxisUpdate: x => {
                const newZoom = Object.assign(Object.assign({}, activeZoom), { x });
                updateZoom(newZoom, activePage.id);
            } }),
        React.createElement(Hr, null),
        React.createElement(AdditionalControls, null,
            activeZoom.id === "MAIN" && (React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                React.createElement("div", { style: { width: "auto" } }, "Add zoom animation"),
                React.createElement(TCheckbox, { checked: activePage.autoStartZoomId === activeZoom.id, onChange: () => {
                        updatePage(activePage.id, {
                            autoStartZoomId: activePage.autoStartZoomId === activeZoom.id ? "" : activeZoom.id,
                        });
                    } }))),
            (activePage.autoStartZoomId || activeZoom.id !== "MAIN") && (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { display: "flex", height: 32 } },
                    React.createElement("div", { style: { minWidth: 110, lineHeight: "32px" } }, "Duration"),
                    React.createElement(NumberInput, { value: activeZoom.transition.durationSecs, min: 0, max: 100, step: 0.5, onChange: (e) => {
                            const newZoom = Object.assign(Object.assign({}, activeZoom), { transition: Object.assign(Object.assign({}, activeZoom.transition), { durationSecs: +e.target.value }) });
                            updateZoom(newZoom, activePage.id);
                        }, unit: "secs" })),
                React.createElement("div", { style: { display: "flex", height: 32 } },
                    React.createElement("div", { style: { minWidth: 110, lineHeight: "32px" } }, "Delay"),
                    React.createElement(NumberInput, { value: activeZoom.transition.delaySecs, min: 0, max: 100, step: 0.5, onChange: (e) => {
                            const newZoom = Object.assign(Object.assign({}, activeZoom), { transition: Object.assign(Object.assign({}, activeZoom.transition), { delaySecs: +e.target.value }) });
                            updateZoom(newZoom, activePage.id);
                        }, unit: "secs" })))),
            React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => {
                    const newZoom = getDefaultZoom(3);
                    newZoom.id = activeZoom.id;
                    newZoom.name = activeZoom.name;
                    updateZoom(newZoom, activePage.id);
                } }, "Reset All Changes"))));
}
export function ZoomAndFormStepEditor({ tab }) {
    const activeView = useActiveView();
    const screen = useScreen();
    const currentSrc = screen.media[activeView.pageId];
    const isImage = isImageFileExtension(currentSrc);
    const isAutomatedScreen = useIsAutomatedScreen();
    const setStepName = useSetCurrentStepName();
    const pushHistory = usePushHistory();
    const duplicateStep = useDuplicateView();
    const deleteStep = useDeleteZoom();
    const deletePage = useDeletePage();
    const changeStep = useChangeStep();
    const deselect = useDeselect();
    const page = useCurrentPage();
    const tabs = [
        { title: "Zoom", contents: React.createElement(ZoomEditor, null) },
        { title: "Form", contents: React.createElement(FormV3StepInteractionEditor, null) },
    ];
    if (isAutomatedScreen) {
        tabs.pop();
        tabs.unshift({ title: "Timing", contents: React.createElement(TimingSettings, null) });
    }
    if (activeView.zoomId === "MAIN" && currentSrc && !isImage) {
        tabs.push({ title: "Video", contents: React.createElement(VideoSettings, null) });
    }
    return (React.createElement(Div, null,
        React.createElement(TitlebarContainer, null,
            React.createElement(StepNameInput, { name: activeView.zoomName, setName: setStepName }),
            React.createElement(IconButtonContainer, null,
                React.createElement(TTooltip, { position: "bottom", content: "Rename" },
                    React.createElement(IconButton, { src: EditIcon, onClick: () => {
                            const el = document.querySelector("#editor-step-name-input");
                            if (!el)
                                return;
                            el.focus();
                            el.setSelectionRange(0, el.value.length);
                        } })),
                React.createElement(TTooltip, { position: "bottom", content: "Duplicate" },
                    React.createElement(IconButton, { src: DuplicateScreenIcon, onClick: () => {
                            pushHistory();
                            duplicateStep(activeView.pageId, activeView.zoomId);
                        } })),
                React.createElement(TTooltip, { position: "bottom", content: "Delete" }, !(activeView.zoomId === "MAIN" && page.zooms.length > 1) && (React.createElement(IconButton, { src: DeleteScreenIcon, onClick: () => {
                        deselect();
                        pushHistory();
                        if (activeView.zoomId === "MAIN") {
                            deletePage(activeView.pageId);
                        }
                        else {
                            deleteStep(activeView.zoomId);
                            changeStep({
                                pageId: activeView.pageId,
                                zoomId: "MAIN",
                            });
                        }
                    } }))))),
        React.createElement(EditorTabs, { initiallyOpenTab: tab, tabs: tabs })));
}
const ContentContainer = styled.div `
  height: calc(100% - 31px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px;
  background-color: ${newTheme.colors.grey100};
`;
const Hr = styled.hr `
  width: 100%;
  border-top: 1px solid ${newTheme.colors.grey400};
  margin: 8px 0px 16px 0px;
`;
const AdditionalControls = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${newTheme.colors.grey700};
`;
const TitlebarContainer = styled.div `
  width: 100%;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
`;
const Div = styled.div `
  font: ${newTheme.fonts.normal};
  height: 100%;
`;
export const IconButtonContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;
export const IconButton = styled.img `
  width: 20px;
  height: 20px;
  filter: grayscale(1);
  &:hover {
    cursor: pointer;
    filter: grayscale(0);
  }
`;
