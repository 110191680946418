import React from "react";
import { Async } from "react-async";
import styled from "styled-components";
import { CtaInteraction } from "../../../../../../shared/types/product-page";
import { bodyFontSizeDropdownOptions, fontOptions, mapGroupedCalendlyOptions, } from "../../../../helpers/dropdown-options";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
import { useFeatureFlags } from "../../../../redux/selectors/redux-selectors";
import { LoadingPlaceholderDropdown, TDropdownSelect } from "../../../styled/t-dropdown-select";
import { newTheme } from "../../../styled/theme";
import { ColorPicker } from "../editor/atomic/color-picker";
import { NumberInput } from "../editor/atomic/number-input";
import { ShapeSelector } from "../editor/atomic/shape-selector";
import { TextInput } from "../editor/atomic/text-input";
import { ModalAccordionContentTitle } from "./modal-accordion";
import { getRadiusFromShape, getShapeFromRadius } from "../../../../../../shared/functions/helpers";
import { TextStyleSelector } from "../editor/atomic/text-style-selector";
export const ProductPageCtaConfiguration = ({ ctaButton, updateFn }) => {
    var _a, _b, _c, _d, _e;
    const { scheduling } = useFeatureFlags();
    const dropdownStyles = { marginTop: 0, width: "280px" };
    const ctaOptions = [
        { label: "External Link", value: CtaInteraction.EXTERNAL_LINK_SAME_WINDOW },
        { label: "Restart Tour", value: CtaInteraction.RESTART_TOUR },
        { label: "Trigger Form", value: CtaInteraction.OPEN_FORM_V3 },
    ];
    if (scheduling) {
        ctaOptions.push({ label: "Calendly Scheduler", value: CtaInteraction.OPEN_CALENDLY_SCHEDULER });
        ctaOptions.push({ label: "Hubspot Scheduler", value: CtaInteraction.OPEN_HUBSPOT_SCHEDULER });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: "flex" } },
            React.createElement("div", { style: { marginRight: "16px" } },
                React.createElement(ModalAccordionContentTitle, null, "Text"),
                React.createElement(TextInput, { style: { width: "280px" }, name: "Text", value: ctaButton.text, onChange: (e) => {
                        updateFn(Object.assign(Object.assign({}, ctaButton), { text: e.target.value }));
                    } }))),
        React.createElement("div", { style: { display: "flex" } },
            React.createElement("div", null,
                React.createElement(ModalAccordionContentTitle, null, "On Click"),
                React.createElement(TDropdownSelect, { isSearchable: false, style: { marginTop: 0, marginRight: 16, width: "280px" }, selectedValue: (_a = ctaButton === null || ctaButton === void 0 ? void 0 : ctaButton.interaction) === null || _a === void 0 ? void 0 : _a.type, options: ctaOptions, onChange: (e) => {
                        updateFn(Object.assign(Object.assign({}, ctaButton), { interaction: Object.assign(Object.assign({}, ctaButton.interaction), { type: e.value }) }));
                    } })),
            ((_b = ctaButton === null || ctaButton === void 0 ? void 0 : ctaButton.interaction) === null || _b === void 0 ? void 0 : _b.type) === CtaInteraction.EXTERNAL_LINK_SAME_WINDOW && (React.createElement("div", null,
                React.createElement(ModalAccordionContentTitle, null, "URL"),
                React.createElement(TextInput, { style: { width: "280px" }, name: "Text", value: ctaButton.interaction.href, onChange: (e) => {
                        updateFn(Object.assign(Object.assign({}, ctaButton), { interaction: Object.assign(Object.assign({}, ctaButton.interaction), { href: e.target.value }) }));
                    } }))),
            ((_c = ctaButton === null || ctaButton === void 0 ? void 0 : ctaButton.interaction) === null || _c === void 0 ? void 0 : _c.type) === CtaInteraction.OPEN_FORM_V3 && (React.createElement("div", null,
                React.createElement(ModalAccordionContentTitle, null, "Form"),
                React.createElement(Async, { promiseFn: TourialApiCalls.Forms.getForms }, ({ data, error, isPending }) => {
                    var _a;
                    if (isPending)
                        return React.createElement(LoadingPlaceholderDropdown, { style: dropdownStyles });
                    if (error)
                        return `Something went wrong: ${error.message}`;
                    return (React.createElement(TDropdownSelect, { isSearchable: true, style: dropdownStyles, selectedValue: (_a = ctaButton === null || ctaButton === void 0 ? void 0 : ctaButton.interaction) === null || _a === void 0 ? void 0 : _a.formId, options: data.map(f => ({ label: f.name, value: f._id })), onChange: (e) => {
                            updateFn(Object.assign(Object.assign({}, ctaButton), { interaction: Object.assign(Object.assign({}, ctaButton.interaction), { formId: e.value }) }));
                        } }));
                }))),
            ((_d = ctaButton === null || ctaButton === void 0 ? void 0 : ctaButton.interaction) === null || _d === void 0 ? void 0 : _d.type) === CtaInteraction.OPEN_CALENDLY_SCHEDULER && (React.createElement("div", null,
                React.createElement(ModalAccordionContentTitle, null, "Calendly Link"),
                React.createElement(Async, { promiseFn: TourialApiCalls.Integrations.getCalendlyLinks }, ({ data, error, isPending }) => {
                    var _a;
                    if (isPending)
                        return React.createElement(LoadingPlaceholderDropdown, { style: dropdownStyles });
                    if (error)
                        return `Something went wrong: ${error.message}`;
                    return data.length > 0 ? (React.createElement(TDropdownSelect, { isSearchable: true, style: dropdownStyles, selectedValue: (_a = ctaButton === null || ctaButton === void 0 ? void 0 : ctaButton.interaction) === null || _a === void 0 ? void 0 : _a.href, options: mapGroupedCalendlyOptions(data), onChange: (e) => {
                            updateFn(Object.assign(Object.assign({}, ctaButton), { interaction: Object.assign(Object.assign({}, ctaButton.interaction), { href: e.value }) }));
                        } })) : (React.createElement(ConnectLink, { href: "/integrations", target: "_blank" }, "Connect Calendly"));
                }))),
            ((_e = ctaButton === null || ctaButton === void 0 ? void 0 : ctaButton.interaction) === null || _e === void 0 ? void 0 : _e.type) === CtaInteraction.OPEN_HUBSPOT_SCHEDULER && (React.createElement("div", null,
                React.createElement(ModalAccordionContentTitle, null, "Hubspot Meeting"),
                React.createElement(Async, { promiseFn: TourialApiCalls.Integrations.getHubspotMeetings }, ({ data, error, isPending }) => {
                    var _a;
                    if (isPending)
                        return React.createElement(LoadingPlaceholderDropdown, { style: dropdownStyles });
                    if (error)
                        return `Something went wrong: ${error.message}`;
                    return data.length > 0 ? (React.createElement(TDropdownSelect, { isSearchable: true, style: dropdownStyles, selectedValue: (_a = ctaButton === null || ctaButton === void 0 ? void 0 : ctaButton.interaction) === null || _a === void 0 ? void 0 : _a.href, options: data.map(l => ({ label: l.name, value: l.link })), onChange: (e) => {
                            updateFn(Object.assign(Object.assign({}, ctaButton), { interaction: Object.assign(Object.assign({}, ctaButton.interaction), { href: e.value }) }));
                        } })) : (React.createElement(ConnectLink, { href: "/integrations/hubspot", target: "_blank" }, "Add Hubspot Meetings"));
                })))),
        React.createElement(ModalAccordionContentTitle, null, "Font"),
        React.createElement("div", { style: { display: "flex", height: "38px" } },
            React.createElement(TDropdownSelect, { isSearchable: true, style: { marginTop: 0, width: "164px" }, selectedValue: ctaButton.fontFamily, onChange: (e) => {
                    const fontFamily = e.value;
                    updateFn(Object.assign(Object.assign({}, ctaButton), { fontFamily }));
                }, options: fontOptions }),
            React.createElement(TDropdownSelect, { isSearchable: false, style: { margin: "0 16px", width: "100px" }, selectedValue: ctaButton.size, onChange: (e) => {
                    updateFn(Object.assign(Object.assign({}, ctaButton), { size: e.value }));
                }, options: bodyFontSizeDropdownOptions }),
            React.createElement(TextStyleSelector, { style: ctaButton.textStyle, onChange: e => {
                    updateFn(Object.assign(Object.assign({}, ctaButton), { textStyle: e }));
                } }),
            React.createElement("div", { style: { flex: 1, padding: "0 0 0 16px" } },
                React.createElement(ColorPicker, { color: ctaButton.color, onChange: color => {
                        updateFn(Object.assign(Object.assign({}, ctaButton), { color }));
                    } }))),
        React.createElement(ModalAccordionContentTitle, null, "Border"),
        React.createElement("div", { style: { display: "flex", width: "auto" } },
            React.createElement(NumberInput, { min: 0, max: 5, step: 1, value: ctaButton.borderWidth, onChange: (e) => {
                    const borderWidth = e.target.value;
                    updateFn(Object.assign(Object.assign({}, ctaButton), { borderWidth }));
                }, style: { width: "65px" } }),
            React.createElement("div", { style: { flex: 1, padding: "2px 0 0 16px" } },
                React.createElement(ColorPicker, { color: ctaButton.borderColor, onChange: borderColor => {
                        updateFn(Object.assign(Object.assign({}, ctaButton), { borderColor }));
                    } }))),
        React.createElement(ModalAccordionContentTitle, null, "Shape"),
        React.createElement(ShapeSelector, { shape: getShapeFromRadius(ctaButton.borderRadius), onChange: (e) => {
                const borderRadius = getRadiusFromShape(e);
                updateFn(Object.assign(Object.assign({}, ctaButton), { borderRadius }));
            } }),
        React.createElement(ModalAccordionContentTitle, null, "Background"),
        React.createElement("div", { style: { flex: 1 } },
            React.createElement(ColorPicker, { color: ctaButton.backgroundColor, onChange: backgroundColor => updateFn(Object.assign(Object.assign({}, ctaButton), { backgroundColor })) }))));
};
export const ConnectLink = styled.a `
  display: block;
  padding-top: 4px;
  color: ${newTheme.colors.blue};
  text-decoration: underline;
  &:visited {
    color: ${newTheme.colors.blue};
  }
`;
