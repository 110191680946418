import { useSetViewportMode, useVersionWasModified, useViewportMode, } from "../../../../redux/selectors/redux-selectors";
import { ViewportMode } from "../../../../types/definitions";
import React from "react";
import PreviewButtonIcon from "../../../../../assets/images/preview-icon.svg";
import EditButtonIcon from "../../../../../assets/images/v3/blue-pencil.svg";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { useCheckedOutVersion, useDeselect } from "../../../../redux/selectors/builder-selectors";
export function ViewportModeToggle() {
    const viewportMode = useViewportMode();
    const setViewportMode = useSetViewportMode();
    const deselect = useDeselect();
    const versionWasModified = useVersionWasModified();
    const checkedOutVersion = useCheckedOutVersion();
    const hasUnmodifiedVersion = checkedOutVersion && !versionWasModified;
    if (viewportMode === ViewportMode.EDIT) {
        return (React.createElement(TButton, { id: "_test_viewport-mode-toggle-preview-button", onClick: () => {
                deselect();
                setViewportMode(ViewportMode.PREVIEW);
            }, variant: TButtonVariants.OUTLINE, icon: PreviewButtonIcon, size: "medium" }, "Preview"));
    }
    else {
        return (React.createElement(TButton, { id: "_test_viewport-mode-toggle-edit-button", onClick: () => setViewportMode(ViewportMode.EDIT), variant: TButtonVariants.SECONDARY, icon: hasUnmodifiedVersion ? undefined : EditButtonIcon, size: "medium" }, hasUnmodifiedVersion ? "Restore version" : "Edit"));
    }
}
