import React from "react";
import styled from "styled-components";
import { DCIconType } from "../../../../../../../shared/types/demo-center";
import { DCStylesDropdown } from "./dc-styles-dropdown";
import { NumberInput } from "../../../builder/editor/atomic/number-input";
import { newTheme } from "../../../../styled/theme";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
export const DCStylesBorderRadiusEditor = ({ styles, tagConfig, onChange, reset, onTagConfigChange, }) => {
    const { cardBorderRadius, containerBorderRadius, buttonBorderRadius, largeButtonBorderRadius } = styles;
    const inputs = [
        {
            label: "Small Buttons",
            value: buttonBorderRadius,
            onChange: (e) => {
                onChange(Object.assign(Object.assign({}, styles), { buttonBorderRadius: Number(e.target.value) }));
            },
        },
        {
            label: "Large Buttons",
            value: largeButtonBorderRadius,
            onChange: (e) => {
                onChange(Object.assign(Object.assign({}, styles), { largeButtonBorderRadius: Number(e.target.value) }));
            },
        },
        {
            label: "Cards",
            value: cardBorderRadius,
            onChange: (e) => {
                onChange(Object.assign(Object.assign({}, styles), { cardBorderRadius: Number(e.target.value) }));
            },
        },
        {
            label: "Containers",
            value: containerBorderRadius,
            onChange: (e) => {
                onChange(Object.assign(Object.assign({}, styles), { containerBorderRadius: Number(e.target.value) }));
            },
        },
        {
            label: "Tags",
            value: tagConfig.borderRadius,
            onChange: (e) => {
                onTagConfigChange(Object.assign(Object.assign({}, tagConfig), { borderRadius: Number(e.target.value) }));
            },
        },
    ];
    return (React.createElement(DCStylesDropdown, { label: "Borders", icon: DCIconType.EMPTY_ITEM },
        React.createElement(DropdownContainer, null,
            inputs.map(i => (React.createElement(Row, { key: i.label },
                React.createElement(Label, null, i.label),
                React.createElement(NumberInput, { value: i.value, onChange: i.onChange, min: 0, max: 100, step: 1, unit: "px" })))),
            React.createElement(TButton, { variant: TButtonVariants.SECONDARY, size: "small", onClick: reset }, "Reset to Defaults"))));
};
const DropdownContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 200px;
`;
const Row = styled.div `
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  input {
    width: 100px;
  }
`;
const Label = styled.div `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey700};
  flex: 1;
`;
