import React from "react";
import styled from "styled-components";
import { DCIconType, DCModuleType, } from "../../../../../../shared/types/demo-center";
import { DCIcon } from "./dc-icon";
import { newTheme } from "../../../styled/theme";
export const DCMiniCardButton = ({ config, option, isSelected, type, index, onCardClick, theme, }) => {
    const isBoolean = type === DCModuleType.BOOLEAN;
    const isScale = type === DCModuleType.SCALE;
    return (React.createElement(CardContainer, { theme: theme, config: config, isSelected: isSelected, onClick: onCardClick, className: isBoolean ? `boolean-${index}` : isScale && `scale-${index}`, style: isBoolean || isScale ? { minWidth: 150, justifyContent: "center" } : {} },
        isScale && React.createElement(ScaleIcon, { isSelected: isSelected, index: index, config: config, theme: theme }),
        type === DCModuleType.MULTIPLE && React.createElement(Checkbox, { config: config, isSelected: isSelected, theme: theme }),
        type === DCModuleType.BOOLEAN && (React.createElement(ThumbIcon, { isSelected: isSelected, index: index, config: config, theme: theme })),
        React.createElement("div", null, option.title || "Option name")));
};
const Checkbox = ({ isSelected, config, theme }) => {
    return (React.createElement(CheckboxContainer, { theme: theme, config: config, className: isSelected ? "selected" : null },
        React.createElement(DCIcon, { type: DCIconType.MINI_CHECK_CIRCLE })));
};
const ThumbIcon = ({ index, isSelected, config, theme }) => {
    const { activeColor } = config;
    const style = {
        color: isSelected ? activeColor : theme.neutralSecondary,
        transition: "color, 0.2s ease-in",
    };
    return index === 0 ? (React.createElement(DCIcon, { type: DCIconType.MINI_THUMBS_DOWN, style: style })) : (React.createElement(DCIcon, { type: DCIconType.MINI_THUMBS_UP, style: style }));
};
const ScaleIcon = ({ index, isSelected, config, theme }) => {
    const { activeColor } = config;
    const style = {
        color: isSelected ? activeColor : theme.neutralSecondary,
        transition: "color, 0.2s ease-in",
    };
    const icons = [DCIconType.MINI_SCALE_LOW, DCIconType.MINI_SCALE_MEDIUM, DCIconType.MINI_SCALE_HIGH];
    return React.createElement(DCIcon, { type: icons[index], style: style });
};
const CardContainer = styled.div `
  display: flex;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
  background-color: ${p => (p.isSelected ? p.theme.neutralPrimary : "transparent")};
  border-width: ${p => (p.isSelected ? 1.5 : 1)}px;
  border-style: solid;
  border-color: ${p => (p.isSelected ? p.config.activeColor : p.theme.neutralSecondary)};
  font: ${newTheme.fonts.medium};
  color: ${p => (p.isSelected ? p.theme.textMain : p.theme.textSecondary)};
  cursor: pointer;
  transition: border-color 0.1s linear;
  &.boolean-0 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: ${p => (p.isSelected ? 1 : 0)};
  }
  &.boolean-1 {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: -9px;
    z-index: ${p => (p.isSelected ? 1 : 0)};
  }
  &.scale-0 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: ${p => (p.isSelected ? 1 : 0)};
  }
  &.scale-1 {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-left: -9px;
    z-index: ${p => (p.isSelected ? 1 : 0)};
  }
  &.scale-2 {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: -9px;
    z-index: ${p => (p.isSelected ? 1 : 0)};
  }
  &:hover {
    border-color: ${p => p.config.activeColor};
    z-index: 10;
  }
`;
const CheckboxContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  color: ${p => p.theme.neutralSecondary};
  transition: color 0.2s ease-in;
  &.selected {
    color: ${p => p.config.activeColor};
  }
`;
