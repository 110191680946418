import { useEffect, useRef, useState } from "react";
import { useCaptureEvents } from "../helpers/dom-tour-helpers";
import rrwebPlayer from "rrweb-player";
import { useHTIsResizingTool, useHTStep, useHTSteps } from "../../../../redux/selectors/ht-selectors";
import { SCREEN_ID } from "../config";
import { PLAYER_ID } from "../components/screen/screen";
import { useQueryParam } from "../../../../hooks/use-query-param";
import { removeParam } from "../../../../../../shared/functions/helpers";
import { useHistory } from "react-router-dom";
export default function useHTPlayer(captures, captureIds, setIsLoading, setLoadedCaptureId) {
    const history = useHistory();
    const playerRef = useRef(null);
    const steps = useHTSteps();
    const [rebuilt, setRebuilt] = useState(false);
    const { captureId: activeCaptureId } = useHTStep();
    const { captureTimestamps, allEvents: captureEvents } = useCaptureEvents(captures, captureIds);
    const firstCapture = captures === null || captures === void 0 ? void 0 : captures.find(c => steps[0].captureId === c.id);
    const reload = useQueryParam("reload");
    const isResizingTool = useHTIsResizingTool();
    // handle disabling pointer events on iframe during clickzone resizing
    useEffect(() => {
        if (playerRef.current) {
            const replayer = playerRef.current.getReplayer();
            replayer.iframe.style.setProperty("pointer-events", isResizingTool ? "none" : "auto", "important");
        }
    }, [isResizingTool]);
    // handle force reload (related to editing html)
    useEffect(() => {
        if (reload) {
            removeParam(history, "reload");
            seek();
        }
    }, [reload]);
    // handle player creation and re-instantiation
    useEffect(() => {
        try {
            if (captureEvents)
                createPlayer();
        }
        catch (err) {
            console.error(err);
        }
    }, [captureEvents]);
    // handle player seeking when capture id changes
    useEffect(seek, [activeCaptureId]);
    function seek() {
        try {
            if (!playerRef.current)
                return;
            const startTime = captureTimestamps[activeCaptureId] - captureEvents[0].timestamp + 1;
            playerRef.current.playRange(startTime, startTime + 1000, false, () => { });
        }
        catch (err) {
            console.error(err);
        }
    }
    // sets loaded capture id to prompt tool rendering
    useEffect(() => {
        rebuilt ? setLoadedCaptureId(activeCaptureId) : setLoadedCaptureId(null);
    }, [rebuilt]);
    // handle cleanup
    useEffect(() => {
        return () => destroyPlayer();
    }, []);
    function destroyPlayer() {
        if (playerRef.current) {
            playerRef.current.getReplayer().destroy();
        }
    }
    function createPlayer() {
        if (playerRef.current) {
            playerRef.current.getReplayer().destroy();
            const playerElement = document.getElementById(PLAYER_ID);
            playerElement.innerHTML = "";
        }
        playerRef.current = new rrwebPlayer({
            target: document.getElementById(PLAYER_ID),
            props: {
                events: captureEvents,
                width: firstCapture.width,
                height: firstCapture.height,
                autoPlay: false,
                showController: false,
            },
        });
        const replayer = playerRef.current.getReplayer();
        replayer.enableInteract();
        replayer.iframe.id = SCREEN_ID;
        setIsLoading(false);
        // ReplayerEvents here => https://github.com/rrweb-io/rrweb/blob/master/packages/types/src/index.ts
        playerRef.current.addEventListener("start", () => {
            setRebuilt(false);
        });
        playerRef.current.addEventListener("custom-event", () => {
            setRebuilt(true);
        });
        // one of => 'ui-update-current-time', 'ui-update-progress', 'ui-update-player-state' or custom event
        // playerRef.current.addEventListener("ui-update-player-state", e => console.log(e));
        const startTime = captureTimestamps[activeCaptureId] - captureEvents[0].timestamp + 1;
        playerRef.current.playRange(startTime, startTime + 1000, false, () => { });
    }
}
