var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isEqual } from "lodash";
import { sanitizeMongoDocumentForFS } from "../../../../../../shared/functions/firebase";
import { BASE_URL_CLIENT } from "../../../../../../shared/types/client-base-url";
import { DC_LIVE_PREVIEW_ROOT } from "../../../../../../shared/types/demo-center";
import { HTMode } from "../../../../../../shared/types/dom-tour";
import { SCREEN_ID } from "../config";
import { fetchWithRetry } from "../../../../../../shared/functions/helpers";
import { useEffect, useState } from "react";
export function makeCaptureLink(id, baseUrlWithSubdomain) {
    return `${baseUrlWithSubdomain || BASE_URL_CLIENT + "/"}dom-capture/${id}`;
}
export function getCaptureHtmlText(captureLink) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetchWithRetry(captureLink);
        if (!(response === null || response === void 0 ? void 0 : response.ok))
            return { captureText: null, isHtmlString: false };
        const captureText = yield response.text();
        return {
            isHtmlString: captureText.startsWith("<html"),
            captureText,
        };
    });
}
export function updateTooltipPosition(setX, setY, anchor) {
    const { anchorX, anchorY } = getAnchorPosition(anchor);
    setX(anchorX + anchor.xOffset);
    setY(anchorY + anchor.yOffset);
}
export function getAnchorPosition(anchor) {
    try {
        const el = selectAnchor(anchor);
        const rect = el.getBoundingClientRect();
        return { anchorX: rect.x, anchorY: rect.y };
    }
    catch (_a) {
        console.error("failed to get anchor position - bad selector");
        return { anchorX: 10, anchorY: 10 };
    }
}
export function selectAnchor(anchor) {
    if (!anchor)
        throw new Error("no anchor");
    const { document } = selectScreen();
    const el = document.querySelector(anchor.selector);
    // comment out for now - spams console if it has a bad anchor selector
    // if (!el) throw new Error("failed to select anchor");
    return el;
}
export function makeSelector(node) {
    var _a;
    const selectorPath = [];
    while (node.tagName) {
        let i = 0;
        if (node.parentNode) {
            const children = (_a = node.parentNode) === null || _a === void 0 ? void 0 : _a.children;
            while (i < children.length && children[i] !== node) {
                i++;
            }
        }
        selectorPath.unshift(node.nodeName + (i > 0 && node.tagName !== "BODY" ? `:nth-child(${i + 1})` : ""));
        node = node.parentNode;
    }
    return selectorPath.join(" > ").toLowerCase();
}
export function selectScreen() {
    const iframe = document.querySelector(`#${SCREEN_ID}`);
    if (!iframe)
        throw new Error("failed to select screen iframe");
    if (!iframe.contentWindow)
        throw new Error("failed to select screen iframe contentWindow");
    if (!iframe.contentWindow.document)
        throw new Error("failed to select screen iframe contentWindow document");
    return { element: iframe, contentWindow: iframe.contentWindow, document: iframe.contentWindow.document };
}
export function modifyHTModeForDCLivePreview(mode) {
    if (window.location.pathname.includes(DC_LIVE_PREVIEW_ROOT) && mode === HTMode.PREVIEW)
        return HTMode.LIVE;
    return mode;
}
export function htBubbleAnimationBuilderOpen(side, { top, left }) {
    if (typeof top !== "number" || typeof left !== "number")
        return;
    const shiftAmount = 30;
    const baseStyle = {
        opacity: 1,
        transition: `transform .2s ease-in, opacity .2s ease-in`,
    };
    switch (side) {
        case "top":
            return Object.assign(Object.assign({}, baseStyle), { top: top - shiftAmount, transform: `translateY(${shiftAmount}px)` });
        case "bottom":
            return Object.assign(Object.assign({}, baseStyle), { top: top + shiftAmount, transform: `translateY(-${shiftAmount}px)` });
        case "left":
            return Object.assign(Object.assign({}, baseStyle), { left: left - shiftAmount, transform: `translateX(${shiftAmount}px)` });
        case "right":
            return Object.assign(Object.assign({}, baseStyle), { left: left + shiftAmount, transform: `translateX(-${shiftAmount}px)` });
        default:
            return {};
    }
}
export const htFsAndMongoDocsAreEqual = (doc1, doc2) => {
    return isEqual(sanitizeMongoDocumentForFS(doc1), sanitizeMongoDocumentForFS(doc2));
};
export function extractIframeData(serializedObject) {
    const iframeData = [];
    function traverseNodes(node) {
        // end if not applicable node type
        if (node.type !== 0 && node.type !== 2)
            return;
        // if applicable, check for the iframe link on iframe tags and store
        if (node.tagName === "iframe" && (node === null || node === void 0 ? void 0 : node.attributes["tourial-frame-link"])) {
            iframeData.push({
                url: node.attributes["tourial-frame-link"],
                id: node.id,
            });
        }
        // traverse node children if applicable
        if (node.childNodes && node.childNodes.length > 0) {
            node.childNodes.forEach(childNode => traverseNodes(childNode));
        }
        // otherwise end
        return;
    }
    traverseNodes(serializedObject);
    return iframeData;
}
export function createAddonEvents(iframeData, timestamp) {
    return __awaiter(this, void 0, void 0, function* () {
        const addonEventsPromises = iframeData.map(({ url, id }, i) => __awaiter(this, void 0, void 0, function* () {
            const { captureText } = yield getCaptureHtmlText(url);
            if (!captureText)
                return null;
            return {
                timestamp: timestamp + i + 1,
                type: 3,
                data: {
                    source: 0,
                    adds: [
                        {
                            parentId: id,
                            nextId: null,
                            node: JSON.parse(captureText),
                        },
                    ],
                    removes: [],
                    texts: [],
                    attributes: [],
                    isAttachIframe: true,
                },
            };
        }));
        return (yield Promise.all(addonEventsPromises)).filter(event => event !== null);
    });
}
/**
 *
 * @param captures - an array of DTCapturePreview
 * @param captureIds - is a uniq list of captureIds in step order
 * @returns
 * returns an array of events which consist of the full snapshots and iframe snapshots with metadata events between each capture
 * converting to an acceptable input for rrweb-player, which efficiently handles switching between captures
 */
export const useCaptureEvents = (captures, captureIds) => {
    const [allEvents, setAllEvents] = useState(null);
    const [captureTimestamps, setCaptureTimestamps] = useState({});
    useEffect(() => {
        if (!captures)
            return;
        const links = captureIds.map(cid => { var _a; return ((_a = captures.find(c => cid === c.id)) === null || _a === void 0 ? void 0 : _a.html) || ""; });
        const promises = links.map((l) => __awaiter(void 0, void 0, void 0, function* () { return yield getCaptureHtmlText(l); }));
        const allCaptureEvents = []; // index corresponds with captureId index in captureIds
        // @ts-expect-error
        Promise.allSettled(promises)
            .then((result) => __awaiter(void 0, void 0, void 0, function* () {
            const allCaptureTimestamps = {};
            const timestamp = Date.now();
            for (let i = 0; i < result.length; i++) {
                const thisTimestamp = timestamp + (i + 1) * 10000;
                const thisCapture = captures.find(c => c.id === captureIds[i]);
                const events = [
                    {
                        type: 4,
                        data: { href: "", width: thisCapture.width, height: thisCapture.height },
                        timestamp: thisTimestamp - 1,
                    },
                ];
                const capture = result[i];
                const serializedObject = JSON.parse(capture.value.captureText); // only works with rrweb snapshots, not standard serialized html
                events.push({
                    type: 2,
                    timestamp: thisTimestamp,
                    data: {
                        initialOffset: { left: 0, top: 0 },
                        node: serializedObject,
                    },
                });
                const iframeData = extractIframeData(serializedObject);
                if (iframeData.length) {
                    const nestedIframeEvents = yield createAddonEvents(iframeData, thisTimestamp);
                    events.push(...nestedIframeEvents);
                }
                // custom-event to track load status
                events.push({
                    type: 5,
                    timestamp: thisTimestamp + 500,
                    tag: "content-loaded",
                    data: {},
                });
                allCaptureEvents.push(events);
                allCaptureTimestamps[captureIds[i]] = thisTimestamp;
            }
            setCaptureTimestamps(allCaptureTimestamps);
            // @ts-expect-error
            setAllEvents(allCaptureEvents.flat());
        }))
            .catch(e => console.error(e));
    }, [captures]);
    return { allEvents, captureTimestamps };
};
