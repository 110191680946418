import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DCTagComponent } from "./dc-tag";
export const DCTagBox = ({ tags, selected, tagConfig, theme, onTagSelect }) => {
    const [selectedTags, setSelectedTags] = useState([]);
    const [isShowingAll, setIsShowingAll] = useState(selected.length === 0);
    const tagBoxRef = useRef(null);
    const showAllTag = { id: "0", label: "Show All" };
    useEffect(() => {
        setSelectedTags(selected);
    }, [selected]);
    useEffect(() => {
        setIsShowingAll(!(selectedTags.length > 0));
    }, [selectedTags]);
    const handleTagChange = (tag, selected) => {
        const newTags = [...selectedTags];
        if (selected) {
            newTags.push(tag.id);
        }
        else {
            newTags.splice(newTags.findIndex(t => t === tag.id), 1);
        }
        onTagSelect(tag, selected, newTags);
        setSelectedTags(newTags);
    };
    return (React.createElement(TagBox, { ref: tagBoxRef },
        React.createElement(DCTagComponent, { theme: theme, tag: showAllTag, config: tagConfig, isSelected: isShowingAll, onChange: () => {
                if (!isShowingAll) {
                    setIsShowingAll(true);
                    setSelectedTags([]);
                    onTagSelect(showAllTag, !isShowingAll, []);
                }
            } }),
        tags.map((tag, i) => (React.createElement(DCTagComponent, { theme: theme, key: `item-${tag.id || i}`, tag: tag, config: tagConfig, isSelected: !!selectedTags.find(t => t === tag.id), onChange: handleTagChange })))));
};
const TagBox = styled.div `
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
`;
