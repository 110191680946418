import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { BorderShape, GraphicType, TextStyle } from "../../../../../../shared/types/tool-data-types/common";
import { ModalButtonType, } from "../../../../../../shared/types/tool-data-types/modal-data";
import XIcon from "../../../../../assets/images/v3/x.svg";
import { useInteraction } from "../../../../redux/selectors/interaction-selectors";
import { SelectionWrapper } from "../../../renderer/edit/selection-wrapper";
import { newTheme } from "../../../styled/theme";
import FeatureImagePlaceholder from "../../../../../assets/images/v3/feature-image-placeholder.svg";
import FeatureVideoPlaceholder from "../../../../../assets/images/v3/feature-video-placeholder.svg";
import { InteractionName, InteractionType, makeInteraction, } from "../../../../../../shared/types/tool-data-types/interactions";
import { useHiddenTools, useSetBuilderFocusField, useShowTools, useViewportIsTransitioning, } from "../../../../redux/selectors/redux-selectors";
import { TourialEditorFocusFields } from "../../../../types/definitions";
import { modifyVideoFormatForSafari } from "../../../../helpers/misc";
import { getBodyFontSize, getHeadlineFontSize, textAlignments } from "../../../../helpers/text-helpers";
import { CLOUDINARY_ZERO } from "../../../../hooks/use-video-player";
export const ModalView = (props) => {
    const { toolData, isInEditViewport } = props;
    const { overlay } = toolData;
    const isTransitioning = useViewportIsTransitioning();
    const [selectionStyle, setSelectionStyle] = useState({});
    const ref = useRef(null);
    useResetInteractionEffects(toolData, isInEditViewport);
    const isModalHidden = useHiddenTools()[toolData.id];
    if (!isInEditViewport && (isModalHidden || isTransitioning))
        return null;
    return (React.createElement(SelectionContainer, { style: { pointerEvents: isInEditViewport ? "none" : "all" } }, isInEditViewport ? (React.createElement(SelectionWrapper, { toolId: toolData.id, toolName: toolData.name, traits: {
            showTab: true,
            selectable: true,
            dashedOutline: false,
            tabContainer: ref,
        }, overloads: {
            setStyle: setSelectionStyle,
        } },
        React.createElement(ModalBackdrop, { style: {
                pointerEvents: "none",
                backgroundColor: overlay.isEnabled ? `rgba(0,0,0,${overlay.opacity})` : "transparent",
            } },
            React.createElement(InnerModal, Object.assign({}, props, { style: selectionStyle, ref: ref }))))) : (React.createElement(ModalBackdrop, { style: {
            pointerEvents: overlay.isEnabled ? "all" : "none",
            backgroundColor: overlay.isEnabled ? `rgba(0,0,0,${overlay.opacity})` : "transparent",
        } },
        React.createElement(InnerModal, Object.assign({}, props))))));
};
const InnerModal = React.forwardRef((props, ref) => {
    const { toolData, style, isInEditViewport, viewportDimensions } = props;
    const { buttons, background, headline, body, logo, featureGraphic, size } = toolData;
    const { button1, button2, button3, button4, shape } = buttons;
    const { width: viewportWidth } = viewportDimensions;
    const isFourButtons = button1.isEnabled && button2.isEnabled && button3.isEnabled && button4.isEnabled;
    const button1Click = useInteraction(props, toolData.buttons.button1.interaction);
    const button2Click = useInteraction(props, toolData.buttons.button2.interaction);
    const button3Click = useInteraction(props, toolData.buttons.button3.interaction);
    const button4Click = useInteraction(props, toolData.buttons.button4.interaction);
    const closeButtonClick = useInteraction(props, makeInteraction(InteractionType.CLICK, InteractionName.CLOSE_MODAL));
    const setBuilderFocusField = useSetBuilderFocusField();
    const button1DoubleClick = () => setBuilderFocusField(TourialEditorFocusFields.MODAL_BUTTON_1);
    const button2DoubleClick = () => setBuilderFocusField(TourialEditorFocusFields.MODAL_BUTTON_2);
    const button3DoubleClick = () => setBuilderFocusField(TourialEditorFocusFields.MODAL_BUTTON_3);
    const button4DoubleClick = () => setBuilderFocusField(TourialEditorFocusFields.MODAL_BUTTON_4);
    const headlineDoubleClick = () => setBuilderFocusField(TourialEditorFocusFields.MODAL_HEADLINE_TEXT);
    const bodyDoubleClick = () => setBuilderFocusField(TourialEditorFocusFields.MODAL_BODY_TEXT);
    return (React.createElement(BasicModalView, { ref: ref, style: style, isInEditViewport: isInEditViewport, background: background, headline: headline, body: body, logo: logo, featureGraphic: featureGraphic, size: size, button1: button1, button2: button2, button3: button3, button4: button4, buttonShape: shape, buttonType: buttons.type, isFourButtons: isFourButtons, viewportWidth: viewportWidth, button1Click: button1Click, button2Click: button2Click, button3Click: button3Click, button4Click: button4Click, closeButtonClick: closeButtonClick, button1DoubleClick: button1DoubleClick, button2DoubleClick: button2DoubleClick, button3DoubleClick: button3DoubleClick, button4DoubleClick: button4DoubleClick, headlineDoubleClick: headlineDoubleClick, bodyDoubleClick: bodyDoubleClick }));
});
export const BasicModalView = React.forwardRef((props, ref) => {
    const videoRef = useRef(null);
    const { style, isInEditViewport, background, headline, body, logo, featureGraphic, size, button1, button2, button3, button4, buttonShape, buttonType, isFourButtons, viewportWidth, button1Click, button2Click, button3Click, button4Click, closeButtonClick, button1DoubleClick, button2DoubleClick, button3DoubleClick, button4DoubleClick, headlineDoubleClick, bodyDoubleClick, } = props;
    return (React.createElement(ModalContainer, { ref: ref, style: Object.assign({ backgroundColor: background.backgroundColor, borderColor: background.borderColor, borderRadius: borderRadii[background.shape], width: (size === null || size === void 0 ? void 0 : size.width) || 480 }, style) },
        featureGraphic.isEnabled && buttonType === ModalButtonType.NORMAL && (React.createElement(FeatureGraphicContainer, { style: { marginBottom: !headline.isEnabled && !body.isEnabled && !logo.isEnabled ? "0" : "24px" } },
            featureGraphic.type === GraphicType.IMAGE && featureGraphic.src && React.createElement("img", { src: featureGraphic.src }),
            featureGraphic.type === GraphicType.VIDEO && featureGraphic.src && (React.createElement("video", { ref: videoRef, src: modifyVideoFormatForSafari(featureGraphic.src), controls: true, playsInline: true, onCanPlay: () => {
                    var _a;
                    if (isInEditViewport)
                        return;
                    if (videoRef.current.paused &&
                        videoRef.current.currentTime < CLOUDINARY_ZERO &&
                        ((_a = featureGraphic.videoControls) === null || _a === void 0 ? void 0 : _a.autoPlay)) {
                        // if autoPlay is on and video has not kicked off, manually attempt play()
                        videoRef.current.play().catch(err => {
                            // all major browsers will return a promise error if browser has rejected
                            // one common reason is that the video is unmuted, so mute and attempt again
                            console.log(err);
                            videoRef.current.muted = true;
                            // and no, unmuting after playing here is blocked
                            videoRef.current.play();
                        });
                    }
                } })),
            !featureGraphic.src && (React.createElement(FeatureGraphicPlaceholder, null,
                React.createElement("img", { src: featureGraphic.type === GraphicType.VIDEO ? FeatureVideoPlaceholder : FeatureImagePlaceholder }))))),
        React.createElement(HeadlineContainer, { config: headline },
            logo.isEnabled && logo.src && (React.createElement(HeadlineLogo, { shape: logo.shape, style: { marginRight: headline.isEnabled ? 8 : 0 } },
                React.createElement("img", { src: logo.src }))),
            headline.isEnabled && (React.createElement(Headline, { config: headline, viewportWidth: viewportWidth, onDoubleClick: headlineDoubleClick }, headline.text))),
        body.isEnabled && (React.createElement(BodyText, { config: body, viewportWidth: viewportWidth, onDoubleClick: bodyDoubleClick }, parse(body.text))),
        React.createElement(ModalSpacer, null),
        buttonType === ModalButtonType.NORMAL ? (React.createElement(ButtonContainer, { font: body.font },
            button1.isEnabled && (React.createElement(NormalModalButton, { shape: buttonShape, config: button1, onClick: () => button1Click(), onDoubleClick: button1DoubleClick }, button1.text)),
            button2.isEnabled && (React.createElement(NormalModalButton, { shape: buttonShape, config: button2, onClick: () => button2Click(), onDoubleClick: button2DoubleClick }, button2.text)),
            button3.isEnabled && (React.createElement(NormalModalButton, { shape: buttonShape, config: button3, onClick: () => button3Click(), onDoubleClick: button3DoubleClick }, button3.text)),
            button4.isEnabled && (React.createElement(NormalModalButton, { shape: buttonShape, config: button4, onClick: () => button4Click(), onDoubleClick: button4DoubleClick }, button4.text)))) : (React.createElement(ButtonContainer, { font: body.font, style: {
                flexWrap: "wrap",
                paddingTop: !headline.isEnabled && !body.isEnabled && !logo.isEnabled ? "0" : "24px",
                margin: "0 -8px",
            } },
            button1.isEnabled && (React.createElement(JumboModalButton, { shape: buttonShape, config: button1, onClick: () => button1Click(), onDoubleClick: () => button1DoubleClick }, button1.text)),
            button2.isEnabled && (React.createElement(JumboModalButton, { shape: buttonShape, config: button2, onClick: () => button2Click(), onDoubleClick: () => button2DoubleClick }, button2.text)),
            isFourButtons && React.createElement(ButtonBreaker, null),
            button3.isEnabled && (React.createElement(JumboModalButton, { shape: buttonShape, config: button3, onClick: () => button3Click(), onDoubleClick: () => button3DoubleClick }, button3.text)),
            button4.isEnabled && (React.createElement(JumboModalButton, { shape: buttonShape, config: button4, onClick: () => button4Click(), onDoubleClick: () => button4DoubleClick }, button4.text)))),
        React.createElement(CloseButton, { onClick: () => closeButtonClick() },
            React.createElement("img", { src: XIcon, alt: "close" }))));
});
const JumboModalButton = (props) => {
    const { children, style, config, onClick, onDoubleClick } = props;
    const { src } = config;
    return (React.createElement(JumboModalButtonContainer, Object.assign({}, props, { style: style, onClick: onClick, onDoubleClick: onDoubleClick }),
        src && (React.createElement(JumboButtonImage, null,
            React.createElement("img", { src: src }))),
        React.createElement("span", null, children)));
};
function useResetInteractionEffects(tool, isInEditViewport) {
    const showTools = useShowTools();
    useEffect(() => {
        showTools([tool.id]);
    }, [isInEditViewport]);
}
const SelectionContainer = styled.div `
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
`;
const ModalBackdrop = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${newTheme.animations.fadeIn} 0.2s ease-in;
`;
const ModalContainer = styled.div `
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  position: relative;
  pointer-events: all;
`;
const borderRadii = {
    [BorderShape.RECTANGLE]: 4,
    [BorderShape.ROUNDED]: 24,
    [BorderShape.SQUIRCLE]: 56,
};
const logoBorderRadii = {
    [BorderShape.RECTANGLE]: "0px",
    [BorderShape.ROUNDED]: "8px",
    [BorderShape.SQUIRCLE]: "20px",
};
const HeadlineContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: ${p => (p.config.isEnabled ? textAlignments[p.config.align] : "center")};
`;
const HeadlineLogo = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  border-radius: ${p => logoBorderRadii[p.shape]};
  width: 40px;
  height: 40px;
  > img {
    max-width: 40px;
    max-height: 40px;
  }
`;
const Headline = styled.div `
  color: ${p => p.config.color};
  font-size: ${p => getHeadlineFontSize(p.viewportWidth, p.config.size)}px;
  text-align: ${p => textAlignments[p.config.align]};
  font-family: ${p => p.config.font};
  text-decoration: ${p => (p.config.style === TextStyle.UNDERLINE ? "underline" : "none")};
  font-weight: ${p => (p.config.style === TextStyle.BOLD ? "bold" : "none")};
  font-style: ${p => (p.config.style === TextStyle.ITALIC ? "italic" : "none")};
`;
const FeatureGraphicContainer = styled.div `
  align-self: center;
  display: flex;
  justify-content: center;
  width: 100%;
  > img {
    max-width: 100%;
    max-height: 216px;
  }
  > video {
    max-width: 100%;
    max-height: 216px;
  }
`;
const FeatureGraphicPlaceholder = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 216px;
  background-color: ${newTheme.colors.blue100};
  border: 1px solid ${newTheme.colors.blue400};
  > img {
  }
`;
const BodyText = styled.div `
  padding-top: 8px;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: ${p => p.config.color};
  font-size: ${p => getBodyFontSize(p.viewportWidth, p.config.size)}px;
  text-align: ${p => textAlignments[p.config.align]};
  font-family: ${p => p.config.font};
  text-decoration: ${p => (p.config.style === TextStyle.UNDERLINE ? "underline" : "none")};
  font-weight: ${p => (p.config.style === TextStyle.BOLD ? "bold" : "none")};
  font-style: ${p => (p.config.style === TextStyle.ITALIC ? "italic" : "none")};
  ul,
  ol {
    margin-left: 16px;
  }
`;
const ModalSpacer = styled.div `
  display: flex;
  flex: 1;
`;
const ButtonContainer = styled.div `
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-family: ${p => p.font};
`;
const ButtonBreaker = styled.div `
  flex-basis: 100%;
  height: 0px;
`;
const JumboModalButtonContainer = styled.div `
  font-family: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${p => p.config.backgroundColor};
  color: ${p => p.config.textColor};
  border-style: solid;
  border-width: 1px;
  border-color: ${p => p.config.borderColor};
  border-radius: ${p => jumboBorderRadii[p.shape]};
  padding: 32px 16px;
  margin: 8px;
  flex: 1;
  min-width: 126px;
  min-height: 136px;

  > span {
    width: 100%;
    text-align: center;
  }
`;
const JumboButtonImage = styled.div `
  min-width: 40px;
  min-height: 40px;
  max-width: 80px;
  max-height: 80px;
  margin-bottom: 8px;
  > img {
    max-width: 80px;
    max-height: 80px;
  }
`;
const jumboBorderRadii = {
    [BorderShape.RECTANGLE]: "2px",
    [BorderShape.ROUNDED]: "16px",
    [BorderShape.SQUIRCLE]: "100px",
};
const normalBorderRadii = {
    [BorderShape.RECTANGLE]: "1px",
    [BorderShape.ROUNDED]: "8px",
    [BorderShape.SQUIRCLE]: "20px",
};
const NormalModalButton = styled.button `
  margin: 12px 12px 0 12px;
  padding: 8px 16px;
  background-color: ${p => p.config.backgroundColor};
  color: ${p => p.config.textColor};
  font-family: inherit;
  border-style: solid;
  border-width: 1px;
  border-color: ${p => p.config.borderColor};
  border-radius: ${p => normalBorderRadii[p.shape]};
  cursor: pointer;
`;
const CloseButton = styled.div `
  display: none; //hiding close button for now
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  > img {
    width: 24px;
    height: 24px;
  }
  cursor: pointer;
`;
