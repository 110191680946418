import React, { useState } from "react";
import styled from "styled-components";
import { useFocusTourialEditorField } from "../../../../../hooks/use-focus-field";
import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { TourialEditorFocusFields } from "../../../../../types/definitions";
import { EditorAccordion } from "../../../../pages/builder/editor/editor-accordion";
import { FontSelector } from "../../../tooltip-v2/editor/components/font-selector";
import BodyIcon from "../../../../../../assets/images/v3/editor-accordian-body-icon.svg";
import AnimationIcon from "../../../../../../assets/images/v3/animation-settings-icon.svg";
import { bodyFontSizeDropdownOptions } from "../../../../../helpers/dropdown-options";
import { cloneDeep } from "lodash";
import { ColorPicker } from "../../../../pages/builder/editor/atomic/color-picker";
import { AdditionalControls, ControlContainer, ControlLabel, QuillContainer, } from "../../../../styled/editor-components";
import { newTheme } from "../../../../styled/theme";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TCheckbox } from "../../../../styled/t-checkbox";
export function AutoTypewriterStyleSection(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    const [openId, setOpenId] = useState(TourialEditorFocusFields.AUTO_TYPEWRITER_TEXT);
    useFocusTourialEditorField(setOpenId);
    return (React.createElement(Section, null,
        React.createElement(EditorAccordion, { id: TourialEditorFocusFields.AUTO_TYPEWRITER_TEXT, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: BodyIcon, title: "Body", isEnabled: true, isHidden: false },
            React.createElement(QuillContainer, null,
                React.createElement(ControlLabel, null, "Body text"),
                React.createElement(ReactQuill, { key: tool.id + "quill-editor", theme: "snow", value: tool.body.text, onChange: value => {
                        value = value.replace('<span class="ql-cursor">﻿</span>', ""); // ql cursor markup bug
                        const t = cloneDeep(tool);
                        t.body.text = value;
                        updateTool(t);
                    }, style: { backgroundColor: newTheme.colors.white }, modules: {
                        toolbar: [
                            ["bold", "italic", "underline"],
                            [{ list: "ordered" }, { list: "bullet" }],
                        ],
                    } })),
            React.createElement(AdditionalControls, null,
                React.createElement(FontSelector, { hideTextStyle: true, text: Object.assign({}, tool.body), sizeOptions: bodyFontSizeDropdownOptions, updateFn: (e) => {
                        const t = cloneDeep(tool);
                        t.body = e;
                        updateTool(t);
                    }, isNumberInput: true }),
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Background Color"),
                    React.createElement(ColorPicker, { color: tool.backgroundColor, onChange: (color) => {
                            const t = cloneDeep(tool);
                            t.backgroundColor = color;
                            updateTool(t);
                        } })))),
        React.createElement(EditorAccordion, { id: "animation-style", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: AnimationIcon, title: "Animation settings", isEnabled: true, isHidden: false },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Clear at end"),
                React.createElement(TCheckbox, { checked: tool.clearOnComplete, onChange: e => {
                        const t = cloneDeep(tool);
                        t.clearOnComplete = e;
                        updateTool(t);
                    } })))));
}
const Section = styled.div `
  max-height: 65vh;
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
