import { HiddenFieldDataOptions } from "../../../shared/types/integrations";
import { useIntegrations, useLoadEvent, useSessionId, useTourialName, useTourialSlug, useVisitorId, } from "../redux/selectors/redux-selectors";
import { useHTName, useHTSlug } from "../redux/selectors/ht-selectors";
export function useHiddenFieldData() {
    const searchParams = new URLSearchParams(window.location.search);
    const sessionId = useSessionId();
    const userId = useVisitorId();
    const tourialName = useTourialName();
    const tourialSlug = useTourialSlug();
    const tourialLoadEvent = useLoadEvent();
    return {
        [HiddenFieldDataOptions.tourialSlug]: tourialSlug,
        [HiddenFieldDataOptions.sessionId]: sessionId,
        [HiddenFieldDataOptions.userId]: userId,
        [HiddenFieldDataOptions.tourialName]: tourialName,
        [HiddenFieldDataOptions.parentUrl]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.parentUrl) || "",
        [HiddenFieldDataOptions.referringUrl]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.referringUrl) || "",
        [HiddenFieldDataOptions.utm_campaign]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.utm_campaign) || searchParams.get(HiddenFieldDataOptions.utm_campaign) || "",
        [HiddenFieldDataOptions.utm_source]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.utm_source) || searchParams.get(HiddenFieldDataOptions.utm_source) || "",
        [HiddenFieldDataOptions.utm_medium]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.utm_medium) || searchParams.get(HiddenFieldDataOptions.utm_medium) || "",
        [HiddenFieldDataOptions.utm_term]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.utm_term) || searchParams.get(HiddenFieldDataOptions.utm_term) || "",
        [HiddenFieldDataOptions.utm_content]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.utm_content) || searchParams.get(HiddenFieldDataOptions.utm_content) || "",
        [HiddenFieldDataOptions.email]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.email) || searchParams.get(HiddenFieldDataOptions.email) || "",
        [HiddenFieldDataOptions.href]: window.location.href || "",
    };
}
export function useHiddenFieldDataDT() {
    const searchParams = new URLSearchParams(window.location.search);
    const sessionId = useSessionId();
    const userId = useVisitorId();
    const tourialName = useHTName();
    const tourialSlug = useHTSlug();
    const tourialLoadEvent = useLoadEvent();
    return {
        [HiddenFieldDataOptions.tourialSlug]: tourialSlug,
        [HiddenFieldDataOptions.sessionId]: sessionId,
        [HiddenFieldDataOptions.userId]: userId,
        [HiddenFieldDataOptions.tourialName]: tourialName,
        [HiddenFieldDataOptions.parentUrl]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.parentUrl) || "",
        [HiddenFieldDataOptions.referringUrl]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.referringUrl) || "",
        [HiddenFieldDataOptions.utm_campaign]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.utm_campaign) || searchParams.get(HiddenFieldDataOptions.utm_campaign) || "",
        [HiddenFieldDataOptions.utm_source]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.utm_source) || searchParams.get(HiddenFieldDataOptions.utm_source) || "",
        [HiddenFieldDataOptions.utm_medium]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.utm_medium) || searchParams.get(HiddenFieldDataOptions.utm_medium) || "",
        [HiddenFieldDataOptions.utm_term]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.utm_term) || searchParams.get(HiddenFieldDataOptions.utm_term) || "",
        [HiddenFieldDataOptions.utm_content]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.utm_content) || searchParams.get(HiddenFieldDataOptions.utm_content) || "",
        [HiddenFieldDataOptions.email]: (tourialLoadEvent === null || tourialLoadEvent === void 0 ? void 0 : tourialLoadEvent.email) || searchParams.get(HiddenFieldDataOptions.email) || "",
        [HiddenFieldDataOptions.href]: window.location.href || "",
    };
}
export function useIntegrationsCookies() {
    const integrations = useIntegrations();
    return {
        hubspotCookie: integrations === null || integrations === void 0 ? void 0 : integrations.hutk,
        marketoCookie: integrations === null || integrations === void 0 ? void 0 : integrations.munchkinCookie,
    };
}
