import { useEffect, useState } from "react";
import { useFetchWorkspaces } from "./use-fetch-workspaces";
import { useUserData } from "../legacy/selectors";
import { isAuthorized } from "../../../shared/functions/auth-functions";
import { AccessLevel } from "../../../shared/types/auth-types";
import { useAsync } from "react-async";
import { TourialApiCalls } from "../helpers/tourial-api-calls";
export const useUsersWorkspaceIds = () => {
    const { workspaces } = useFetchWorkspaces();
    const { email, userAccess } = useUserData();
    const [usersWorkspaceIds, setUsersWorkspaceIds] = useState([]);
    useEffect(() => {
        const accessibleWorkspaces = [];
        workspaces.forEach(workspace => {
            var _a;
            if (workspace.isPublic) {
                accessibleWorkspaces.push(workspace._id);
            }
            else if (((_a = workspace.userEmails) === null || _a === void 0 ? void 0 : _a.includes(email)) || isAuthorized(AccessLevel.ADMIN, userAccess)) {
                accessibleWorkspaces.push(workspace._id);
            }
        });
        setUsersWorkspaceIds(accessibleWorkspaces);
    }, [workspaces]);
    return usersWorkspaceIds;
};
export const useWorkspaceUserData = (workspace) => {
    const [userCount, setUserCount] = useState(0);
    const [userEmails, setUserEmails] = useState([]);
    const [admins, setAdmins] = useState([]);
    const { data: allUsers } = useAsync({
        promiseFn: TourialApiCalls.Users.getAllAccountUsers,
        onResolve(data) {
            if (data) {
                const admins = [];
                data.forEach(user => {
                    if (user.userAccess === AccessLevel.ADMIN) {
                        admins.push(user.email);
                    }
                });
                setAdmins(admins);
            }
        },
    });
    // ensure admins are included in the count and remove non-active users in userEmails array
    useEffect(() => {
        if (!allUsers || !workspace)
            return;
        const list = workspace.userEmails || admins;
        const activeUsers = list.filter(email => allUsers.find(user => user.email === email));
        for (const admin of admins) {
            if (!list.includes(admin))
                activeUsers.push(admin);
        }
        setUserEmails(activeUsers);
        setUserCount(activeUsers.length);
    }, [admins, workspace]);
    return { userCount, userEmails };
};
