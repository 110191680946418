import React, { useState } from "react";
import { SketchPicker } from "react-color";
import TLabel from "../styled/t-label";
import { useSavedColors } from "../../../redux/selectors/redux-selectors";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
import { reactColorRgbaToString } from "../../../helpers/misc";
export function EmbedButtonColorPicker({ property, label, button, setButton, }) {
    const savedColors = useSavedColors();
    const [color, setColor] = useState({ r: 54, g: 79, b: 167, a: 1 });
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement("div", null,
        React.createElement(TLabel, { style: { width: "100%" } },
            label,
            React.createElement("button", { style: {
                    width: 25,
                    height: 25,
                    border: `1px solid ${theme.colors.grey.dark}`,
                    borderRadius: 4,
                    backgroundColor: button[property],
                    cursor: "pointer",
                }, onClick: () => setIsOpen(!isOpen) })),
        isOpen && (React.createElement("div", { style: { margin: "0 auto", zIndex: 4, position: "absolute" } },
            React.createElement(SketchPicker, { presetColors: savedColors, color: color, onChange: ({ rgb }) => {
                    setColor(rgb);
                    const newButton = Object.assign(Object.assign({}, button), { [property]: reactColorRgbaToString(rgb) });
                    setButton(newButton);
                } })))));
}
