import { Colors } from "../objects/default-theme";
import { BorderShape, ShirtSize, TextAlign, TextStyle } from "./tool-data-types/common";
import { TourialStatus } from "./tourial-model";
import { v4 as uuidv4 } from "uuid";
[];
[];
export var DTStepType;
(function (DTStepType) {
    DTStepType["TOOLTIP"] = "TOOLTIP";
    DTStepType["INTRO"] = "INTRO";
    /** can be a scriptable form */
    DTStepType["OUTRO"] = "OUTRO";
})(DTStepType || (DTStepType = {}));
export var HTArrowPosition;
(function (HTArrowPosition) {
    HTArrowPosition["LEFT"] = "left";
    HTArrowPosition["RIGHT"] = "right";
    HTArrowPosition["BOTTOM"] = "bottom";
    HTArrowPosition["TOP"] = "top";
    HTArrowPosition["AUTO"] = "auto";
})(HTArrowPosition || (HTArrowPosition = {}));
export var DTInteraction;
(function (DTInteraction) {
    DTInteraction["LINK"] = "LINK";
    DTInteraction["FORM"] = "FORM";
})(DTInteraction || (DTInteraction = {}));
// doesn't require optional params
export var DTGenericInteraction;
(function (DTGenericInteraction) {
    DTGenericInteraction["NEXT"] = "NEXT";
    DTGenericInteraction["RESTART"] = "RESTART";
})(DTGenericInteraction || (DTGenericInteraction = {}));
export var DTStyle;
(function (DTStyle) {
    DTStyle["DEFAULT"] = "DEFAULT";
    DTStyle["INVERTED"] = "INVERTED";
    DTStyle["TRANSPARENT"] = "TRANSPARENT";
})(DTStyle || (DTStyle = {}));
export var HTMode;
(function (HTMode) {
    HTMode["PREVIEW"] = "PREVIEW";
    HTMode["EDIT"] = "EDIT";
    HTMode["LIVE"] = "LIVE";
})(HTMode || (HTMode = {}));
export const HT_PLACEHOLDER_HEADER_TEXT = "Enter your header";
export const HT_PLACEHOLDER_BODY_TEXT = "Guide viewers to a specific feature of your software or to an action you'd like them to take.";
export var HTIconTypes;
(function (HTIconTypes) {
    HTIconTypes["DOWN_CHEVRON"] = "DOWN_CHEVRON";
    HTIconTypes["LEFT_ARROW"] = "LEFT_ARROW";
    HTIconTypes["RIGHT_ARROW"] = "RIGHT_ARROW";
    HTIconTypes["UP_CHEVRON"] = "UP_CHEVRON";
    HTIconTypes["PROGRESS_LEFT_ARROW"] = "PROGRESS_LEFT_ARROW";
    HTIconTypes["TOOLTIP_LEFT_ARROW"] = "TOOLTIP_LEFT_ARROW";
    HTIconTypes["TOOLTIP_RIGHT_ARROW"] = "TOOLTIP_RIGHT_ARROW";
    HTIconTypes["TOOLTIP_BOTTOM_ARROW"] = "TOOLTIP_BOTTOM_ARROW";
    HTIconTypes["TOOLTIP_TOP_ARROW"] = "TOOLTIP_TOP_ARROW";
})(HTIconTypes || (HTIconTypes = {}));
export function createDefaultHT({ tourName, accountId, firstCapture, theme, steps, workspaceId }) {
    return Object.assign(Object.assign({}, (!!workspaceId && { workspaceId })), { name: tourName, accountId, slug: uuidv4(), status: TourialStatus.DRAFT, steps, width: firstCapture.width, height: firstCapture.height, theme: {
            tooltip: {
                backgroundColor: theme.bubble.background.backgroundColor || "#0a54ff",
                shape: theme.bubble.background.shape || BorderShape.ROUNDED,
                headline: {
                    font: theme.bubble.headline.font || "Open Sans",
                    size: ShirtSize.SMALL,
                    style: theme.bubble.headline.style || TextStyle.NORMAL,
                    color: theme.bubble.headline.color || "#FFFFFF",
                    align: theme.bubble.headline.align || TextAlign.CENTER,
                },
                body: {
                    font: theme.bubble.body.font || "Open Sans",
                    size: ShirtSize.SMALL,
                    color: theme.bubble.body.color || "#FFFFFF",
                    align: theme.bubble.body.align || TextAlign.CENTER,
                },
                stepCounter: {
                    isEnabled: theme.bubble.stepCounter.isEnabled || false,
                    color: theme.bubble.stepCounter.color || "#000000",
                },
                buttonShape: theme.bubble.nextButton.shape || BorderShape.ROUNDED,
                nextButton: {
                    isEnabled: theme.bubble.nextButton.isEnabled || true,
                    backgroundColor: theme.bubble.nextButton.backgroundColor || Colors.PRIMARY,
                    textColor: theme.bubble.nextButton.textColor || Colors.WHITE,
                },
                previousButton: {
                    isEnabled: theme.bubble.previousButton.isEnabled || true,
                    backgroundColor: theme.bubble.previousButton.backgroundColor || Colors.WHITE,
                    textColor: theme.bubble.previousButton.textColor || Colors.PRIMARY,
                },
                hotspot: {
                    isEnabled: false,
                },
            },
            overlay: theme.overlay,
            button: {
                shape: theme.bubble.nextButton.shape || BorderShape.ROUNDED,
                body: {
                    font: theme.font.body || "Open Sans",
                    size: ShirtSize.MEDIUM,
                    color: theme.bubble.nextButton.textColor || "#FFFFFF",
                },
                backgroundColor: theme.bubble.nextButton.backgroundColor || "#0a54ff",
            },
            progressBar: {
                backgroundColor: theme.page.progressBar.backgroundColor || "#000000",
                progressColor: theme.page.progressBar.barColor || "#0055FF",
                arrowColor: theme.page.progressBar.arrowColor || "#E4E5E9",
            },
            backgroundColor: "#FFFFFF",
        } });
}
// only used for polyfill
export function createDefaultNextButton(tourialTheme) {
    return {
        isEnabled: false,
        backgroundColor: tourialTheme.bubble.nextButton.backgroundColor || Colors.PRIMARY,
        textColor: tourialTheme.bubble.nextButton.textColor || Colors.WHITE,
    };
}
export function createDefaultPrevButton(tourialTheme) {
    return {
        isEnabled: false,
        backgroundColor: tourialTheme.bubble.previousButton.backgroundColor || Colors.WHITE,
        textColor: tourialTheme.bubble.previousButton.textColor || Colors.PRIMARY,
    };
}
export function createHTDefaultTooltip(name, captureId, anchor) {
    return {
        id: uuidv4(),
        name,
        type: DTStepType.TOOLTIP,
        tooltip: Object.assign({ header: HT_PLACEHOLDER_HEADER_TEXT, body: HT_PLACEHOLDER_BODY_TEXT, prevButtonText: "back", nextButtonText: "next" }, (anchor && { anchor })),
        captureId,
    };
}
