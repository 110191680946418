import React from "react";
import styled from "styled-components";
export const DCHTMLPlayer = ({ item }) => {
    const { url } = item;
    return (React.createElement(HTMLContainer, null,
        React.createElement("iframe", { src: url })));
};
const HTMLContainer = styled.div `
  position: relative;
  width: 100%;
  height: calc(100vh - 210px);
  iframe {
    width: 100%;
    height: 100%;
  }
`;
