import React, { useContext, useEffect, useState } from "react";
import { useHTStep, useHTTheme } from "../../../../../redux/selectors/ht-selectors";
import { ColorPicker } from "../../../builder/editor/atomic/color-picker";
import { EditorContainer, htBodyFontSizeDropdownOptions, htHeadlineFontSizeDropdownOptions, htOverlayHeadlineFontSizeDropdownOptions, } from "../shared";
import { DraftContext } from "../dom-tour-builder-page";
import { DEBOUNCE_MILLISECONDS } from "../../config";
import { cloneDeep, debounce } from "lodash";
import { EditorAccordion } from "../../../builder/editor/editor-accordion";
import { ControlContainer, ControlLabel } from "../../../../styled/editor-components";
import OverlayIcon from "../../../../../../assets/images/v3/overlay-icon.svg";
import TooltipIcon from "../../../../../../assets/images/v3/editor-accordian-tooltip-icon.svg";
import NextButtonIcon from "../../../../../../assets/images/v3/editor-accordian-next-button-icon.svg";
import ProgressIcon from "../../../../../../assets/images/v3/editor-accordion-ht-progress.svg";
import LightMode from "../../../../../../assets/images/v3/light-mode.svg";
import DarkMode from "../../../../../../assets/images/v3/dark-mode.svg";
import LogoIcon from "../../../../../../assets/images/v3/editor-accordian-logo-icon.svg";
import { FontSelector } from "../../../../tools/tooltip-v2/editor/components/font-selector";
import styled from "styled-components";
import { ShapeSelector } from "../../../builder/editor/atomic/shape-selector";
import { HTInfo } from "./ht-html-editor";
import { HTShapeSelectorLarge } from "../atomic/ht-shape-selector-large";
import { VariantButton } from "../navbar/ht-device-selector";
import { Divider } from "../../../../../legacy/components/builder/tools/form-v2/editor";
import TTooltip from "../../../../styled/t-tooltip";
import { DTStepType, createDefaultNextButton, createDefaultPrevButton, } from "../../../../../../../shared/types/dom-tour";
import { TToggleSwitch } from "../../../../styled/t-toggle-switch";
import { useFetchTheme } from "../../../../../hooks/use-fetch-theme";
import { BorderShape } from "../../../../../../../shared/types/tool-data-types/common";
import { Colors } from "../../../../../../../shared/objects/default-theme";
export function StyleEditor() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const draft = useContext(DraftContext);
    // const tour = useHTTour();
    const theme = useHTTheme();
    const { tooltip, overlay, button, progressBar, backgroundColor } = theme;
    const { theme: tourialTheme } = useFetchTheme();
    const step = useHTStep();
    const [openId, setOpenId] = useState("bubble-accordion");
    useEffect(() => {
        setOpenId(step.type === DTStepType.TOOLTIP ? "bubble-accordion" : "overlay-accordion");
    }, [step.id]);
    // // comment in if enabling tour dimensions to be changed
    // const [screenWidth, setScreenWidth] = useState(tour?.width);
    // const [screenHeight, setScreenHeight] = useState(tour?.height);
    return (React.createElement(TourStylesContainer, null,
        React.createElement(EditorAccordion, { id: "bubble-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: TooltipIcon, title: "Tooltip", isEnabled: true, isInitiallyOpen: true },
            React.createElement(HTShapeSelectorLarge, { shape: tooltip.shape, onChange: debounce(handleTooltipShapeChange, DEBOUNCE_MILLISECONDS) }),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Background color"),
                React.createElement(ColorPicker, { color: tooltip.backgroundColor, onChange: debounce(handleTooltipBackgroundColorChange, DEBOUNCE_MILLISECONDS) })),
            React.createElement(HTDivider, null),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Step counter"),
                React.createElement(TToggleSwitch, { enabled: !!((_a = tooltip.stepCounter) === null || _a === void 0 ? void 0 : _a.isEnabled), onChange: handleStepCounterIsEnabledChange })),
            !!((_b = tooltip.stepCounter) === null || _b === void 0 ? void 0 : _b.isEnabled) && (React.createElement(React.Fragment, null,
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Step text color"),
                    React.createElement(ColorPicker, { color: (_c = tooltip.stepCounter) === null || _c === void 0 ? void 0 : _c.color, onChange: debounce(handleStepCounterColorChange, DEBOUNCE_MILLISECONDS) })))),
            React.createElement(HTDivider, null),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Hotspot"),
                React.createElement(TToggleSwitch, { enabled: !!((_d = tooltip.hotspot) === null || _d === void 0 ? void 0 : _d.isEnabled), onChange: handleHotspotIsEnabledChange })),
            React.createElement(HTDivider, null),
            React.createElement(ControlLabel, null, "Headline"),
            React.createElement(FontSelector, { text: tooltip.headline, sizeOptions: htHeadlineFontSizeDropdownOptions, updateFn: debounce(handleHeadlineChange, DEBOUNCE_MILLISECONDS) }),
            React.createElement(HTDivider, null),
            React.createElement(ControlLabel, null, "Body"),
            React.createElement(FontSelector, { text: tooltip.body, sizeOptions: htBodyFontSizeDropdownOptions, hideTextStyle: true, updateFn: debounce(handleBodyTextChange, DEBOUNCE_MILLISECONDS) })),
        React.createElement(EditorAccordion, { id: "bubble-tooltip-buttons-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: NextButtonIcon, title: "Tooltip Buttons", isEnabled: true },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Shape"),
                React.createElement(ShapeSelector, { shape: tooltip.buttonShape || BorderShape.ROUNDED, onChange: debounce(handleTooltipButtonShapeChange, DEBOUNCE_MILLISECONDS) })),
            React.createElement(HTDivider, null),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Next Button"),
                React.createElement(TToggleSwitch, { enabled: !!((_e = tooltip.nextButton) === null || _e === void 0 ? void 0 : _e.isEnabled), onChange: handleNextButtonIsEnabledChange })),
            !!((_f = tooltip.nextButton) === null || _f === void 0 ? void 0 : _f.isEnabled) && (React.createElement(React.Fragment, null,
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Background Color"),
                    React.createElement(ColorPicker, { color: ((_g = tooltip.nextButton) === null || _g === void 0 ? void 0 : _g.backgroundColor) || Colors.PRIMARY, onChange: debounce(handleNextButtonBackgroundColorChange, DEBOUNCE_MILLISECONDS) })),
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Text Color"),
                    React.createElement(ColorPicker, { color: ((_h = tooltip.nextButton) === null || _h === void 0 ? void 0 : _h.textColor) || Colors.WHITE, onChange: debounce(handleNextButtonTextColorChange, DEBOUNCE_MILLISECONDS) })))),
            React.createElement(HTDivider, null),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Back Button"),
                React.createElement(TToggleSwitch, { enabled: !!((_j = tooltip.previousButton) === null || _j === void 0 ? void 0 : _j.isEnabled), onChange: handlePrevButtonIsEnabledChange })),
            !!((_k = tooltip.previousButton) === null || _k === void 0 ? void 0 : _k.isEnabled) && (React.createElement(React.Fragment, null,
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Background Color"),
                    React.createElement(ColorPicker, { color: ((_l = tooltip.previousButton) === null || _l === void 0 ? void 0 : _l.backgroundColor) || Colors.WHITE, onChange: debounce(handlePrevButtonBackgroundColorChange, DEBOUNCE_MILLISECONDS) })),
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Text Color"),
                    React.createElement(ColorPicker, { color: ((_m = tooltip.previousButton) === null || _m === void 0 ? void 0 : _m.textColor) || Colors.PRIMARY, onChange: debounce(handlePrevButtonTextColorChange, DEBOUNCE_MILLISECONDS) }))))),
        React.createElement(EditorAccordion, { id: "overlay-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: OverlayIcon, title: "Overlays", isEnabled: true },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Overlay color"),
                React.createElement(TTooltip, { content: "Light", placement: "bottom" },
                    React.createElement(VariantButton, { disabled: overlay.backgroundColor === "white", isActive: overlay.backgroundColor === "white", isLeft: true, onClick: () => handleOverlayColorChange("white"), style: { width: 40 } },
                        React.createElement("img", { src: LightMode }))),
                React.createElement(TTooltip, { content: "Dark", placement: "bottom" },
                    React.createElement(VariantButton, { disabled: overlay.backgroundColor === "black", isActive: overlay.backgroundColor === "black", isLeft: false, onClick: () => handleOverlayColorChange("black"), style: { width: 40 } },
                        React.createElement("img", { src: DarkMode })))),
            React.createElement(HTDivider, null),
            React.createElement(ControlLabel, null, "Header"),
            React.createElement(FontSelector, { text: overlay.headline, sizeOptions: htOverlayHeadlineFontSizeDropdownOptions, hideTextAlign: true, updateFn: debounce(handleOverlayHeadlineTextChange, DEBOUNCE_MILLISECONDS) }),
            React.createElement(HTDivider, null),
            React.createElement(ControlLabel, null, "Subheader"),
            React.createElement(FontSelector, { text: overlay.body, sizeOptions: htBodyFontSizeDropdownOptions, hideTextStyle: true, hideTextAlign: true, updateFn: debounce(handleOverlayBodyTextChange, DEBOUNCE_MILLISECONDS) })),
        React.createElement(EditorAccordion, { id: "overlay-button-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: NextButtonIcon, title: "Overlay Buttons", isEnabled: true },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Shape"),
                React.createElement(ShapeSelector, { shape: button.shape, onChange: debounce(handleButtonShapeChange, DEBOUNCE_MILLISECONDS) })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Background Color"),
                React.createElement(ColorPicker, { color: button.backgroundColor, onChange: debounce(handleButtonBackgroundColorChange, DEBOUNCE_MILLISECONDS) })),
            React.createElement(FontSelector, { text: button.body, sizeOptions: htBodyFontSizeDropdownOptions, hideTextStyle: true, hideTextAlign: true, updateFn: debounce(handleButtonBodyTextChange, DEBOUNCE_MILLISECONDS) })),
        React.createElement(EditorAccordion, { id: "progress-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: ProgressIcon, title: "Progress", isEnabled: true },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Progress background"),
                React.createElement(ColorPicker, { color: (progressBar === null || progressBar === void 0 ? void 0 : progressBar.backgroundColor) || "#000000", onChange: debounce(handleProgressBarBackgroundColorChange, DEBOUNCE_MILLISECONDS) })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Progress bar"),
                React.createElement(ColorPicker, { color: (progressBar === null || progressBar === void 0 ? void 0 : progressBar.progressColor) || "#0055FF", onChange: debounce(handleProgressBarProgressColorChange, DEBOUNCE_MILLISECONDS) })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Arrow color"),
                React.createElement(ColorPicker, { color: (progressBar === null || progressBar === void 0 ? void 0 : progressBar.arrowColor) || "#E4E5E9", onChange: debounce(handleProgressBarArrowColorChange, DEBOUNCE_MILLISECONDS) }))),
        React.createElement(EditorAccordion, { id: "screen-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: LogoIcon, title: "Screen", isEnabled: true },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Screen background"),
                React.createElement(ColorPicker, { color: backgroundColor || "#FFFFFF", onChange: debounce(handleScreenBackgroundColorChange, DEBOUNCE_MILLISECONDS) }))),
        React.createElement(HTInfo, { style: { margin: 16, width: "calc(100% - 32px)" } }, "Style changes are applied to all tools in the project.")));
    function handleTooltipShapeChange(e) {
        void draft.update({ theme: Object.assign(Object.assign({}, theme), { tooltip: Object.assign(Object.assign({}, tooltip), { shape: e }) }) });
    }
    function handleTooltipBackgroundColorChange(e) {
        void draft.update({ theme: Object.assign(Object.assign({}, theme), { tooltip: Object.assign(Object.assign({}, tooltip), { backgroundColor: e }) }) });
    }
    function handleOverlayColorChange(e) {
        void draft.update({ theme: Object.assign(Object.assign({}, theme), { overlay: Object.assign(Object.assign({}, overlay), { backgroundColor: e }) }) });
    }
    function handleHeadlineChange(e) {
        const themeCopy = cloneDeep(theme);
        themeCopy.tooltip.headline = Object.assign(Object.assign({}, themeCopy.tooltip.headline), e);
        void draft.update({ theme: themeCopy });
    }
    function handleBodyTextChange(e) {
        const themeCopy = cloneDeep(theme);
        themeCopy.tooltip.body = Object.assign(Object.assign({}, themeCopy.tooltip.body), e);
        void draft.update({ theme: themeCopy });
    }
    function handleButtonShapeChange(e) {
        void draft.update({ theme: Object.assign(Object.assign({}, theme), { button: Object.assign(Object.assign({}, button), { shape: e }) }) });
    }
    function handleButtonBodyTextChange(e) {
        const themeCopy = cloneDeep(theme);
        themeCopy.button.body = Object.assign(Object.assign({}, themeCopy.button.body), e);
        void draft.update({ theme: themeCopy });
    }
    function handleButtonBackgroundColorChange(e) {
        void draft.update({ theme: Object.assign(Object.assign({}, theme), { button: Object.assign(Object.assign({}, button), { backgroundColor: e }) }) });
    }
    function handleOverlayHeadlineTextChange(e) {
        const themeCopy = cloneDeep(theme);
        themeCopy.overlay.headline = Object.assign(Object.assign({}, themeCopy.overlay.headline), e);
        void draft.update({ theme: themeCopy });
    }
    function handleOverlayBodyTextChange(e) {
        const themeCopy = cloneDeep(theme);
        themeCopy.overlay.body = Object.assign(Object.assign({}, themeCopy.overlay.body), e);
        void draft.update({ theme: themeCopy });
    }
    function handleProgressBarBackgroundColorChange(e) {
        void draft.update({ theme: Object.assign(Object.assign({}, theme), { progressBar: Object.assign(Object.assign({}, progressBar), { backgroundColor: e }) }) });
    }
    function handleProgressBarProgressColorChange(e) {
        void draft.update({ theme: Object.assign(Object.assign({}, theme), { progressBar: Object.assign(Object.assign({}, progressBar), { progressColor: e }) }) });
    }
    function handleProgressBarArrowColorChange(e) {
        void draft.update({ theme: Object.assign(Object.assign({}, theme), { progressBar: Object.assign(Object.assign({}, progressBar), { arrowColor: e }) }) });
    }
    function handleStepCounterColorChange(e) {
        var _a;
        const themeCopy = cloneDeep(theme);
        themeCopy.tooltip.stepCounter = Object.assign(Object.assign({}, (_a = themeCopy.tooltip) === null || _a === void 0 ? void 0 : _a.stepCounter), { color: e });
        void draft.update({ theme: themeCopy });
    }
    function handleStepCounterIsEnabledChange(e) {
        var _a;
        const themeCopy = cloneDeep(theme);
        themeCopy.tooltip.stepCounter = Object.assign(Object.assign({ color: "#000000" }, (_a = themeCopy.tooltip) === null || _a === void 0 ? void 0 : _a.stepCounter), { isEnabled: e });
        void draft.update({ theme: themeCopy });
    }
    function handleScreenBackgroundColorChange(e) {
        void draft.update({ theme: Object.assign(Object.assign({}, theme), { backgroundColor: e }) });
    }
    // function handleScreenSizeChange(e) {
    //   if (screenWidth && screenHeight) {
    //     void draft.update({ width: screenWidth, height: screenHeight });
    //   }
    // }
    function handleHotspotIsEnabledChange(e) {
        var _a;
        const themeCopy = cloneDeep(theme);
        themeCopy.tooltip.hotspot = Object.assign(Object.assign({}, (_a = themeCopy.tooltip) === null || _a === void 0 ? void 0 : _a.hotspot), { isEnabled: e });
        void draft.update({ theme: themeCopy });
    }
    function handleTooltipButtonShapeChange(e) {
        const themeCopy = cloneDeep(theme);
        themeCopy.tooltip.buttonShape = e;
        void draft.update({ theme: themeCopy });
    }
    function handleNextButtonBackgroundColorChange(e) {
        updateNextButton("backgroundColor", e);
    }
    function handleNextButtonTextColorChange(e) {
        updateNextButton("textColor", e);
    }
    function handleNextButtonIsEnabledChange(e) {
        updateNextButton("isEnabled", e);
    }
    function updateNextButton(key, value) {
        var _a;
        const themeCopy = cloneDeep(theme);
        themeCopy.tooltip.nextButton = Object.assign(Object.assign(Object.assign({}, createDefaultNextButton(tourialTheme)), (_a = themeCopy.tooltip) === null || _a === void 0 ? void 0 : _a.nextButton), { [key]: value });
        void draft.update({ theme: themeCopy });
    }
    function handlePrevButtonIsEnabledChange(e) {
        updatePrevButton("isEnabled", e);
    }
    function handlePrevButtonBackgroundColorChange(e) {
        updatePrevButton("backgroundColor", e);
    }
    function handlePrevButtonTextColorChange(e) {
        updatePrevButton("textColor", e);
    }
    function updatePrevButton(key, value) {
        var _a;
        const themeCopy = cloneDeep(theme);
        themeCopy.tooltip.previousButton = Object.assign(Object.assign(Object.assign({}, createDefaultPrevButton(tourialTheme)), (_a = themeCopy.tooltip) === null || _a === void 0 ? void 0 : _a.previousButton), { [key]: value });
        void draft.update({ theme: themeCopy });
    }
}
const TourStylesContainer = styled(EditorContainer) `
  gap: 0px;
  align-items: unset;
  padding: 0px;
  overflow-y: scroll;
  height: calc(100% - 80px);
`;
const HTDivider = styled(Divider) `
  margin: 16px -10px;
`;
