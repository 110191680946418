import { HTMode } from "../../../../shared/types/dom-tour";
import { HTActionType } from "../actions/ht-actions";
import { DTBuilderModal } from "../../components/pages/dom-tour/config";
import { cloneDeep } from "lodash";
import { getBaseUrlWithSubdomain } from "../../helpers/misc";
export var HTViewportPresetSizes;
(function (HTViewportPresetSizes) {
    HTViewportPresetSizes["SMALL"] = "small";
    HTViewportPresetSizes["AUTO"] = "auto";
})(HTViewportPresetSizes || (HTViewportPresetSizes = {}));
const defaultDTState = {
    mode: HTMode.EDIT,
    modal: DTBuilderModal.NONE,
    stepIndex: 0,
    scale: 1,
    isMobile: false,
    viewportPresetSize: HTViewportPresetSizes.AUTO,
    isStepsPanelOpened: true,
    isEditingHtml: false,
    isResizingTool: false,
    loadedCaptureId: "",
    pageForm: null,
};
function getEmptyProviderState() {
    return Object.assign({}, defaultDTState);
}
export function getInitialDomTourialRootState(initialSavedTourial, initialViewportMode, liveModeAccount) {
    return {
        tour: cloneDeep(initialSavedTourial),
        mode: initialViewportMode,
        modal: DTBuilderModal.NONE,
        stepIndex: 0,
        baseUrlWithSubdomain: initialViewportMode === HTMode.LIVE ? getBaseUrlWithSubdomain(liveModeAccount) : "",
        // intially unset
        scale: 1,
        isMobile: false,
        viewportPresetSize: HTViewportPresetSizes.AUTO,
        isStepsPanelOpened: true,
        isEditingHtml: false,
        isResizingTool: false,
        loadedCaptureId: "",
        pageForm: null,
        domTourSessionId: undefined,
        shareLink: undefined,
    };
}
export function DTReducer(prevState = defaultDTState, action) {
    var _a, _b, _c, _d;
    switch (action.type) {
        case HTActionType.HT_CLEAR_TOURIAL_ROOT_STATE: {
            return getEmptyProviderState();
        }
        case HTActionType.HT_SET_INITIAL_TOURIAL_ROOT_STATE: {
            return action.initialProviderState;
        }
        case HTActionType.HT_SET_TOUR: {
            const { tour, shareLink } = action;
            if (!prevState.tour) {
                return Object.assign(Object.assign({}, prevState), { tour, shareLink });
            }
            // stay on same step, even if steps data changes
            // if a step is deleted, then go to the first step
            const activeStepId = (_b = (_a = prevState.tour.steps) === null || _a === void 0 ? void 0 : _a[prevState.stepIndex]) === null || _b === void 0 ? void 0 : _b.id;
            let stepIndex = prevState.stepIndex;
            if (activeStepId !== ((_d = (_c = tour.steps) === null || _c === void 0 ? void 0 : _c[prevState.stepIndex]) === null || _d === void 0 ? void 0 : _d.id)) {
                stepIndex = Math.max(0, tour.steps.findIndex(s => s.id === activeStepId));
            }
            return Object.assign(Object.assign({}, prevState), { tour, stepIndex, shareLink });
        }
        case HTActionType.HT_SET_PUBLISHED_TOUR: {
            const { publishedTour } = action;
            return Object.assign(Object.assign({}, prevState), { publishedTour });
        }
        case HTActionType.HT_SET_PAGE_FORM: {
            const { pageForm } = action;
            return Object.assign(Object.assign({}, prevState), { pageForm });
        }
        case HTActionType.HT_SET_BASE_URL_WITH_SUBDOMAIN: {
            const { baseUrlWithSubdomain } = action;
            return Object.assign(Object.assign({}, prevState), { baseUrlWithSubdomain });
        }
        case HTActionType.HT_SET_MODAL: {
            const { modal } = action;
            return Object.assign(Object.assign({}, prevState), { modal });
        }
        case HTActionType.HT_SET_IS_MOBILE: {
            const { isMobile } = action;
            return Object.assign(Object.assign({}, prevState), { isMobile });
        }
        case HTActionType.HT_SET_SCALE: {
            const { scale } = action;
            return Object.assign(Object.assign({}, prevState), { scale });
        }
        case HTActionType.HT_GOTO_NEXT_STEP: {
            const stepCount = prevState.tour.steps.length - 1;
            const stepIndex = Math.min(prevState.stepIndex + 1, stepCount);
            return Object.assign(Object.assign({}, prevState), { stepIndex });
        }
        case HTActionType.HT_GOTO_PREV_STEP: {
            const stepIndex = Math.max(prevState.stepIndex - 1, 0);
            return Object.assign(Object.assign({}, prevState), { stepIndex });
        }
        case HTActionType.HT_GOTO_STEP: {
            const { stepIndex } = action;
            return Object.assign(Object.assign({}, prevState), { stepIndex });
        }
        case HTActionType.HT_SET_MODE: {
            const { mode } = action;
            return Object.assign(Object.assign({}, prevState), { mode });
        }
        case HTActionType.HT_SET_SESSION_ID: {
            const { domTourSessionId } = action;
            return Object.assign(Object.assign({}, prevState), { domTourSessionId });
        }
        case HTActionType.HT_SET_VIEWPORT_PRESET_SIZE: {
            const { viewportPresetSize } = action;
            return Object.assign(Object.assign({}, prevState), { viewportPresetSize });
        }
        case HTActionType.HT_SET_IS_STEPS_PANEL_OPENED: {
            const { isStepsPanelOpened } = action;
            return Object.assign(Object.assign({}, prevState), { isStepsPanelOpened });
        }
        case HTActionType.HT_SET_IS_RESIZING_TOOL: {
            const { isResizingTool } = action;
            return Object.assign(Object.assign({}, prevState), { isResizingTool });
        }
        case HTActionType.HT_SET_LOADED_CAPTURE_ID: {
            const { captureId } = action;
            return Object.assign(Object.assign({}, prevState), { loadedCaptureId: captureId });
        }
        case HTActionType.HT_SET_IS_EDITING_HTML: {
            const { isEditingHtml } = action;
            return Object.assign(Object.assign({}, prevState), { isEditingHtml });
        }
        case HTActionType.NULL:
        default:
            return prevState;
    }
}
