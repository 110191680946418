import { datadogRum } from "@datadog/browser-rum";
export function initDatadogRum(serviceName) {
    let applicationId;
    let clientToken;
    let service;
    let allowedTracingUrls;
    let sessionSampleRate = 25;
    if (serviceName === "app") {
        applicationId = "7e264914-85b5-4fc4-8ac9-6af50aaef50f";
        clientToken = "pubce04415fd793025a87d453af08c7129d";
        service = "tourial-app";
        sessionSampleRate = 100;
        allowedTracingUrls = [
            "https://app.tourial.com",
            url => url.startsWith("https://app.tourial.com"),
            url => url.startsWith(window.location.origin),
        ];
    }
    else if (serviceName === "live" ||
        serviceName === "product" ||
        serviceName === "microsite" ||
        serviceName === "democenter" ||
        serviceName === "dom-tour") {
        applicationId = "b9234c5b-bab4-471b-a92d-d6cbac189541";
        clientToken = "pub9b0e00e90b67382b6e069c3445d75531";
        service = "tourial-live";
    }
    if (!location.host.includes("localhost") && !location.host.includes("staging.tourial.com")) {
        datadogRum.init({
            applicationId,
            clientToken,
            service,
            site: "datadoghq.com",
            env: "production",
            version: process.env.npm_package_version,
            sessionSampleRate,
            sessionReplaySampleRate: 0,
            trackResources: true,
            trackLongTasks: true,
            trackUserInteractions: true,
            useCrossSiteSessionCookie: true,
            allowedTracingUrls,
        });
    }
}
