import React, { useContext, useEffect, useRef, useState } from "react";
import { newTheme } from "../../../../styled/theme";
import styled from "styled-components";
import { ControlContainer } from "../../../../styled/editor-components";
import { HTClassesAndIds, useHTSelectedElementData } from "../../hooks/ht-editor-hook";
import { makeSelector, selectScreen } from "../../helpers/dom-tour-helpers";
import { EditorContainer } from "../shared";
import { useHTStepIframeLoadedStatus } from "../../hooks/ht-hooks";
import { useHTIsEditingHtml, useHTSetIsEditingHtml, useHTStep, useHTTour, } from "../../../../../redux/selectors/ht-selectors";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import { HTIcon } from "../atomic/ht-icon";
import { HTIconTypes } from "../../../../../../../shared/types/dom-tour";
import { Divider } from "../../../../../legacy/components/builder/tools/form-v2/editor";
import TrashIcon from "../../../../../../assets/images/v3/trash-grey-icon.svg";
import HideIcon from "../../../../../../assets/images/v3/hide-eye-icon.svg";
import ShowIcon from "../../../../../../assets/images/v3/show-eye-icon.svg";
import RecoverIcon from "../../../../../../assets/images/v3/recover-icon.svg";
import { TCheckbox } from "../../../../styled/t-checkbox";
import { imageTypes, uploadMedia } from "../../../../../helpers/media";
import TModal, { TModalButtonsContainer, TModalSpan } from "../../../../styled/t-modal";
import { snapshot } from "rrweb-snapshot";
import { DraftContext } from "../dom-tour-builder-page";
import { TourialApiCalls } from "../../../../../helpers/tourial-api-calls";
import { cloneDeep } from "lodash";
import { useAddToast } from "../../../../../legacy/selectors";
import { ToastModes } from "../../../../../types/definitions";
import { appendParam } from "../../../../../../../shared/functions/helpers";
import { useHistory } from "react-router-dom";
import { sanitizeTextareas } from "../../../../../helpers/dom-helpers";
export function HTHtmlEditor() {
    var _a, _b;
    const draft = useContext(DraftContext);
    const history = useHistory();
    const { selectedElement, selectedElementSelector, setSelectedElementSelector, selectedElementTagName, setSelectedElementTagName, setIsBlurred, selectedElementIsBlurred, setIsHidden, selectedElementIsHidden, setIsRemoved, selectedElementIsRemoved, hiddenCount, removedCount, viewIsHidden, viewIsRemoved, toggleViewIsHidden, toggleViewIsRemoved, clearTemporaryClasses, setImageSrc, } = useHTSelectedElementData();
    const addToast = useAddToast();
    const loaded = useHTStepIframeLoadedStatus();
    const setIsEditing = useHTSetIsEditingHtml();
    const isEditing = useHTIsEditingHtml();
    const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const inputFile = useRef(null);
    const [editedHtml, setEditedHtml] = useState("");
    const step = useHTStep();
    const tour = useHTTour();
    // unmounts on step change - automatically disable isEditing
    useEffect(() => {
        return () => {
            setIsEditing(false);
        };
    }, []);
    // TODO: test this!!
    useEffect(() => {
        if (editedHtml)
            handleSave();
    }, [editedHtml]);
    return (React.createElement(EditorContainer, null,
        loaded ? (React.createElement(React.Fragment, null,
            React.createElement(ToggleContainer, null,
                React.createElement(ToggleLabel, null, "Edit screen mode"),
                isEditing && (React.createElement(TButton, { size: "small", variant: isRevertModalOpen ? TButtonVariants.DANGER : TButtonVariants.SECONDARY, onClick: () => setIsRevertModalOpen(true) }, "Revert changes")),
                React.createElement(TButton, { size: "small", variant: isEditing ? TButtonVariants.DEFAULT : TButtonVariants.SECONDARY, onClick: handleToggleClick, style: { width: isEditing ? 50 : 80 } }, isEditing ? "Save" : "Edit HTML")),
            isEditing && (React.createElement(React.Fragment, null,
                React.createElement(HTControlContainer, null,
                    React.createElement(ToggleLabel, null, `Hidden elements (${hiddenCount})`),
                    React.createElement(ViewToggle, { on: viewIsHidden, onClick: () => toggleViewIsHidden(!viewIsHidden) }, viewIsHidden ? "Done" : "View")),
                React.createElement(HTControlContainer, null,
                    React.createElement(ToggleLabel, null, `Removed elements (${removedCount})`),
                    React.createElement(ViewToggle, { on: viewIsRemoved, onClick: () => toggleViewIsRemoved(!viewIsRemoved) }, viewIsRemoved ? "Done" : "View")))),
            React.createElement(Divider, null),
            selectedElement.current && (React.createElement(React.Fragment, null,
                React.createElement(ArrowsContainer, null,
                    React.createElement("div", { style: { display: "flex", gap: 8 } },
                        React.createElement(ToggleLabel, { style: { lineHeight: "24px" } }, "Selected element"),
                        React.createElement(SelectedTagBadge, null, selectedElementTagName.toLowerCase())),
                    React.createElement("div", { style: { display: "flex", gap: 4 } },
                        React.createElement(SelectorArrow, { onClick: traverseUp, disabled: !selectedElementSelector },
                            React.createElement(HTIcon, { type: HTIconTypes.DOWN_CHEVRON, style: { cursor: "pointer", transform: `rotate(-180deg)` }, fill: "black" })),
                        React.createElement(SelectorArrow, { onClick: traverseDown, disabled: !selectedElementSelector },
                            React.createElement(HTIcon, { type: HTIconTypes.DOWN_CHEVRON, style: { cursor: "pointer" }, fill: "black" })))),
                React.createElement(HTCodeTextarea, null,
                    React.createElement("code", null, selectedElementSelector)),
                React.createElement("div", { style: { display: "flex", gap: 5 } },
                    React.createElement(TButton, { icon: selectedElementIsHidden ? ShowIcon : HideIcon, variant: selectedElementIsHidden ? TButtonVariants.DEFAULT : TButtonVariants.SECONDARY, size: "small", onClick: () => setIsHidden(!selectedElementIsHidden), style: { width: 63 } }, selectedElementIsHidden ? "Show" : "Hide"),
                    React.createElement(TButton, { icon: selectedElementIsRemoved ? RecoverIcon : TrashIcon, variant: selectedElementIsRemoved ? TButtonVariants.DEFAULT : TButtonVariants.SECONDARY, size: "small", onClick: () => setIsRemoved(!selectedElementIsRemoved) }, selectedElementIsRemoved ? "Recover" : "Remove")),
                React.createElement(HTControlContainer, null,
                    React.createElement(ToggleLabel, null, "Blur"),
                    React.createElement(TCheckbox, { checked: selectedElementIsBlurred, onChange: setIsBlurred })),
                React.createElement("input", { multiple: false, type: "file", accept: `${imageTypes.join("|")}`, ref: inputFile, style: { display: "none" }, onChange: e => {
                        void uploadMedia(e.target.files, (media) => {
                            var _a, _b;
                            setImageSrc((_a = media[0]) === null || _a === void 0 ? void 0 : _a.src);
                            document.getElementById("ht-image-preview").src = (_b = media[0]) === null || _b === void 0 ? void 0 : _b.src;
                        });
                    } }),
                ((_a = selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.current) === null || _a === void 0 ? void 0 : _a.tagName) === "IMG" && (React.createElement(ImageContainer, null,
                    React.createElement(Img, { id: "ht-image-preview", src: (_b = selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.current) === null || _b === void 0 ? void 0 : _b.src }),
                    React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY, onClick: () => inputFile.current.click() }, "Replace image"))))),
            React.createElement(HTInfo, null, "Toggle on edit screen mode to make text edits to the HTML."))) : (React.createElement(ToggleLabel, null, "Loading HTML...")),
        isRevertModalOpen && (React.createElement(TModal, { title: "Are you sure?", onClose: () => setIsRevertModalOpen(false) },
            React.createElement(React.Fragment, null,
                React.createElement(TModalSpan, null, "This will undo all the changes and restore the screen to its original HTML."),
                React.createElement(TModalButtonsContainer, null,
                    React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => setIsRevertModalOpen(false) }, "Cancel"),
                    React.createElement(TButton, { variant: TButtonVariants.DANGER, onClick: handleRevert }, "Revert"))))),
        isSaveModalOpen && (React.createElement(TModal, { title: "Are you sure?", onClose: () => setIsSaveModalOpen(false) },
            React.createElement(React.Fragment, null,
                React.createElement(TModalSpan, null, "This will save the updates you made to the original HTML."),
                React.createElement(TModalButtonsContainer, null,
                    React.createElement(TButton, { variant: TButtonVariants.SECONDARY, disabled: !!editedHtml, onClick: () => setIsSaveModalOpen(false) }, "Cancel"),
                    React.createElement(TButton, { onClick: handleSaveButtonClick, disabled: !!editedHtml }, editedHtml ? "Saving..." : "Save")))))));
    function handleRevert() {
        appendParam(history, "reload", true);
        setIsEditing(false);
        setIsRevertModalOpen(false);
    }
    function handleSave() {
        const handleCompletion = () => {
            setIsSaveModalOpen(false);
            setEditedHtml("");
        };
        if (step.captureId) {
            TourialApiCalls.DomCapture.postEditedDomCapture(step.captureId, editedHtml)
                .then((newCaptureId) => {
                function setNewCaptureId() {
                    const stepsCopy = cloneDeep(tour.steps);
                    const stepIndex = tour.steps.findIndex(s => s.id === step.id);
                    stepsCopy[stepIndex].captureId = newCaptureId;
                    void draft.update({ steps: stepsCopy });
                }
                setNewCaptureId();
                addToast({
                    message: "Edit saved!",
                    mode: ToastModes.SUCCESS,
                });
                handleCompletion();
            })
                .catch(() => {
                addToast({
                    message: "Oops. Failed to save.",
                    mode: ToastModes.ERROR,
                });
                handleCompletion();
            });
        }
        else {
            handleCompletion();
        }
    }
    function handleSaveButtonClick() {
        toggleContentEditable(false);
        setIsEditing(false);
        const { element } = selectScreen();
        sanitizeTextareas(element.contentDocument);
        const serializedSnapshot = JSON.stringify(snapshot(element.contentDocument));
        setEditedHtml(serializedSnapshot);
    }
    // opens the save modal if editing, otherwise enables editing
    function handleToggleClick() {
        if (isEditing) {
            clearTemporaryClasses();
            setIsSaveModalOpen(true);
        }
        else {
            handleEditModeOn();
        }
    }
    function handleEditModeOn() {
        setIsEditing(true);
        toggleContentEditable(true);
    }
    function toggleContentEditable(on) {
        const { contentWindow } = selectScreen();
        const nodes = contentWindow.document.body.getElementsByTagName("*");
        for (const node of nodes) {
            if (on) {
                node.setAttribute("contenteditable", "true");
            }
            else {
                node.removeAttribute("contenteditable");
            }
        }
    }
    function toggleElementHighlight(on) {
        if (!selectedElement.current)
            return;
        if (on) {
            selectedElement.current.classList.add(HTClassesAndIds.SELECTED_CLASS);
        }
        else {
            selectedElement.current.classList.remove(HTClassesAndIds.SELECTED_CLASS);
        }
    }
    function traverseUp() {
        const parent = selectedElement.current.parentElement;
        if (parent && parent.tagName.toLowerCase() !== "body") {
            selectedElement.current.classList.add(HTClassesAndIds.BREADCRUMB_CLASS);
            toggleElementHighlight(false);
            setNextSelectedElement(parent);
        }
    }
    function traverseDown() {
        const child = selectedElement.current.querySelector(`.${HTClassesAndIds.BREADCRUMB_CLASS}`);
        if (child) {
            selectedElement.current.classList.remove(HTClassesAndIds.BREADCRUMB_CLASS);
            toggleElementHighlight(false);
            setNextSelectedElement(child);
        }
    }
    function setNextSelectedElement(element) {
        selectedElement.current = element;
        setSelectedElementSelector(makeSelector(element));
        setSelectedElementTagName(element.tagName);
        setIsBlurred();
        setIsHidden();
        setIsRemoved();
        toggleElementHighlight(true);
    }
}
const ToggleContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ToggleLabel = styled.div `
  color: ${newTheme.colors.grey600};
  font: ${newTheme.fonts.medium};
`;
export const HTInfo = styled.div `
  width: 100%;
  padding: 24px;
  background: ${newTheme.colors.grey200};
  border-radius: 4px;
  color: ${newTheme.colors.grey600};
  font: ${newTheme.fonts.medium};
`;
const ArrowsContainer = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const SelectedTagBadge = styled.div `
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${newTheme.colors.grey300};
  background: ${newTheme.colors.grey100};
  color: ${newTheme.colors.grey900};
  font: ${newTheme.fonts.small};
  max-width: 108px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  line-height: 18px;
`;
const SelectorArrow = styled.button `
  display: flex;
  width: 24px;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${newTheme.colors.grey400};
  background: ${newTheme.colors.white};
  box-shadow:
    2px 2px 8px 0px rgba(48, 49, 51, 0.02),
    0px 0px 20px 0px rgba(48, 49, 51, 0.05);
`;
const HTCodeTextarea = styled.div `
  width: 100%;
  height: 140px;
  background-color: ${newTheme.colors.grey100};
  border: 1px solid ${newTheme.colors.grey300};
  border-radius: 4px;
  padding: 8px 16px 4px 16px;
  overflow-y: scroll;
  > code {
    font: ${newTheme.fonts.medium};
    color: ${newTheme.colors.grey500};
  }
`;
const HTControlContainer = styled(ControlContainer) `
  justify-content: space-between;
  width: 100%;
  margin: 0px;
`;
const ViewToggle = styled.div `
  font: ${newTheme.fonts.medium};
  color: ${({ on }) => (on ? newTheme.colors.blue500 : newTheme.colors.grey900)};
  text-decoration: underline;
  cursor: pointer;
`;
const ImageContainer = styled.div `
  position: relative;
  width: 286px;
  height: 162px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${newTheme.colors.grey300};
  ${newTheme.patterns.polkaDot};
  button {
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 45.5px);
    visibility: hidden;
  }
  &:hover {
    button {
      visibility: visible;
    }
  }
`;
const Img = styled.img `
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
`;
