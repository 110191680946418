import { css, keyframes } from "styled-components";
import { ShirtSize } from "../../../../../../shared/types/tool-data-types/common";
import { newTheme } from "../../../styled/theme";
import tinycolor from "tinycolor2";
// import { hexToRGB } from "../../../../helpers/misc";
function hexToRGB(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null;
}
export const dcBodyFontSizes = {
    [ShirtSize.SMALL]: 12,
    [ShirtSize.MEDIUM]: 14,
    [ShirtSize.LARGE]: 16,
    [ShirtSize.XLARGE]: 21,
};
export const dcButtonFontSizes = {
    [ShirtSize.SMALL]: 12,
    [ShirtSize.MEDIUM]: 14,
    [ShirtSize.LARGE]: 16,
    [ShirtSize.XLARGE]: 20,
};
export const dcTitleFontSizes = {
    [ShirtSize.XXSMALL]: 14,
    [ShirtSize.XSMALL]: 16,
    [ShirtSize.SMALL]: 18,
    [ShirtSize.MEDIUM]: 22,
    [ShirtSize.LARGE]: 26,
    [ShirtSize.XLARGE]: 32,
    [ShirtSize.XXLARGE]: 48,
};
export const dcCardSizes = {
    [ShirtSize.SMALL]: [240, 230],
    [ShirtSize.MEDIUM]: [240, 230],
    [ShirtSize.LARGE]: [240, 230],
};
export const dcCardThumbnailSizes = {
    [ShirtSize.SMALL]: [240, 135],
    [ShirtSize.MEDIUM]: [240, 135],
    [ShirtSize.LARGE]: [240, 135],
};
export const dcFadeIn = keyframes `
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const dcFadeToGrey = keyframes `
  from {
    filter: grayscale(0);
  }
  to {
    filter: grayscale(1);
  }
`;
export const dcPulseAnimation = (pulseColor) => {
    const color = hexToRGB(pulseColor);
    const animate = keyframes `
    0% {
      box-shadow: 0 0 2px 0px
        rgba(${color.r}, ${color.g}, ${color.b}, 0.85);
    }
    100% {
      box-shadow: 0 0 2px 8px
        rgba(${color.r}, ${color.g}, ${color.b}, 0);
    }
  `;
    return css `
    animation: ${animate} 1s infinite;
  `;
};
export const dcGradientBackground = (gradient) => {
    const { color1, color2, percent } = gradient;
    return css `
    background: linear-gradient(81deg, ${color1} ${percent}%, ${color2} 100%);
  `;
};
const isLight = (color) => (tinycolor(color).getBrightness() / 255) * 100 > 85;
export const dcGetTextColorFromHighlight = (color) => {
    const { white, black } = newTheme.colors;
    return isLight(color) ? black : white;
};
export const dcGetTextColorFromGradient = (gradient) => {
    const { color1, color2, percent } = gradient;
    const color1IsLight = isLight(color1);
    const color2IsLight = isLight(color2);
    const { white, black } = newTheme.colors;
    if (color1IsLight && color2IsLight)
        return black;
    if (!color1IsLight && !color2IsLight)
        return white;
    if (color1IsLight && !color2IsLight) {
        if (percent >= 35) {
            return black;
        }
        else {
            return white;
        }
    }
    if (!color1IsLight && color2IsLight) {
        if (percent >= 35) {
            return white;
        }
        else {
            return black;
        }
    }
};
export const dcLightTheme = {
    textMain: "#212229",
    textSecondary: "#747681",
    neutralPrimary: "#FFFFFF",
    neutralTranslucentColor: "#FAFAFA",
    neutralTranslucentOpacity: 0.8,
    neutralSecondary: "#E4E5E9",
    shadow: "0px 0px 1px 0px rgba(33, 34, 41, 0.50) inset; filter: drop-shadow(1px 1px 12px rgba(33, 34, 41, 0.12)) drop-shadow(-2px -2px 32px rgba(48, 49, 51, 0.02));",
};
export const dcDarkTheme = {
    textMain: "#FFFFFF",
    textSecondary: "#E4E5E9",
    neutralPrimary: "#404248",
    neutralTranslucentColor: "#3F4146",
    neutralTranslucentOpacity: 0.4,
    neutralSecondary: "#62636A",
    shadow: "0px 0px 1px 0px #747681 inset; filter: drop-shadow(1px 1px 12px rgba(0, 0, 0, 0.24)) drop-shadow(-2px -2px 32px rgba(0, 0, 0, 0.04));",
};
