import { isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { DCCMPageType, DCModuleStatus, DCModuleType, DC_DEFAULT_MODULE_NAME, } from "../../../../../../shared/types/demo-center";
import { useDCTrackSelections, useDCSetActiveModuleSlug, useDCSetTrackSelections, useDemoCenter, useUpdateDemoCenter, useDCViewedItems, useDCActiveModuleSlug, useDCConfigGatingFormID, } from "../../../../redux/selectors/demo-center-selectors";
import { dcEnforceUniqueSlug, dcGenerateSlugFromText } from "../helpers/dc-helpers";
import { useDCGetItemsForSelectedTracks } from "./dc-item-hooks";
import { wasFormSubmitted } from "../../../../helpers/form-helpers";
import { useDCBuilderGoToPage } from "./dc-builder-hooks";
export const useDCModules = () => {
    const demoCenter = useDemoCenter();
    return demoCenter.modules;
};
export const useDCModule = (slug) => {
    const modules = useDCModules();
    return modules.find(m => m.slug === slug);
};
export const useDCFirstModule = () => {
    const modules = useDCModules();
    return modules[0];
};
export const useDCTracks = (slug) => {
    const module = useDCModule(slug);
    return module === null || module === void 0 ? void 0 : module.tracks;
};
export const useDCGetModule = () => {
    const modules = useDCModules();
    return (slug) => {
        return modules.find(m => m.slug === slug);
    };
};
export const useDCGetNextModule = () => {
    const activeModule = useDCActiveModuleSlug();
    const modules = useDCModules();
    return () => {
        const i = modules.findIndex(m => m.slug === activeModule);
        if (i >= 0 && i < modules.length - 1) {
            return modules[i + 1];
        }
        else {
            return modules[0];
        }
    };
};
export const useDCGetPreviousModule = () => {
    const activeModule = useDCActiveModuleSlug();
    const modules = useDCModules();
    return () => {
        const i = modules.findIndex(m => m.slug === activeModule);
        if (i >= 0 && i > 0) {
            return modules[i - 1];
        }
        else {
            return modules[modules.length - 1];
        }
    };
};
export const useDCSetModules = () => {
    const updateDemoCenter = useUpdateDemoCenter();
    return (modules) => {
        updateDemoCenter({ modules });
    };
};
export const useDCSetModule = () => {
    const modules = useDCModules();
    const setModules = useDCSetModules();
    const getNewModule = useDCGetNewModule();
    const goToBuilderPage = useDCBuilderGoToPage();
    return (module, template) => {
        const newModules = [...modules];
        const i = newModules.findIndex(m => m.slug === module.slug);
        if (i >= 0) {
            newModules[i] = template ? getNewModule(template) : module;
            newModules[i].slug = module.slug;
            setModules(newModules);
            if (template)
                goToBuilderPage(`module/${newModules[i].slug}`);
        }
    };
};
export const useDCSetTracks = (slug) => {
    const modules = useDCModules();
    const setModules = useDCSetModules();
    return (tracks) => {
        const newModules = [...modules];
        const i = newModules.findIndex(m => m.slug === slug);
        if (i >= 0) {
            newModules[i].tracks = tracks;
            setModules(newModules);
        }
    };
};
export const useDCDeleteModule = () => {
    const modules = useDCModules();
    const setModules = useDCSetModules();
    return (slug) => {
        const newModules = [...modules];
        const i = newModules.findIndex(m => m.slug === slug);
        if (i >= 0) {
            newModules.splice(i, 1);
            setModules(newModules);
        }
    };
};
export const useDCSetModuleSlug = () => {
    const modules = useDCModules();
    const setModules = useDCSetModules();
    const generateSlug = useDCGenerateModuleSlug();
    const goToBuilderPage = useDCBuilderGoToPage();
    return (slug, title) => {
        const newSlug = generateSlug(title);
        const newModules = [...modules];
        const i = newModules.findIndex(m => m.slug === slug);
        if (i >= 0 && slug !== newSlug) {
            newModules[i].slug = newSlug;
            setModules(newModules);
            goToBuilderPage(`module/${newModules[i].slug}`);
        }
    };
};
export const useDCAddModule = () => {
    const modules = useDCModules();
    const setModules = useDCSetModules();
    const newModule = useDCGetNewModule();
    return (module) => {
        setModules([...modules, module || newModule()]);
    };
};
export const useDCReorderModulesByDragAndDrop = () => {
    const modules = useDCModules();
    const setModules = useDCSetModules();
    return (result) => {
        if (!result.destination)
            return;
        if (modules[0].slug === DCCMPageType.WELCOME && result.destination.index === 0)
            return;
        const newModules = [...modules];
        const [removed] = newModules.splice(result.source.index, 1);
        newModules.splice(result.destination.index, 0, removed);
        setModules(newModules);
    };
};
export const useDCReorderTracksByDragAndDrop = (module) => {
    const setModule = useDCSetModule();
    const tracks = useDCTracks(module === null || module === void 0 ? void 0 : module.slug);
    return (result) => {
        if (!result.destination)
            return;
        const newTracks = [...tracks];
        const [removed] = newTracks.splice(result.source.index, 1);
        newTracks.splice(result.destination.index, 0, removed);
        setModule(Object.assign(Object.assign({}, module), { tracks: newTracks }));
    };
};
export const useDCConfirmTrackSelectionsForModule = () => {
    const trackSelections = useDCTrackSelections();
    const setTrackSelections = useDCSetTrackSelections();
    return (slug, selections, forwardPath) => {
        const newTrackSelections = [...trackSelections];
        const i = newTrackSelections.findIndex(s => s.module === slug);
        if (i >= 0) {
            newTrackSelections[i].tracks = selections;
        }
        else if (slug && selections.length > 0) {
            newTrackSelections.push({
                module: slug,
                tracks: selections,
            });
        }
        setTrackSelections(newTrackSelections, forwardPath);
    };
};
export const useDCRemoveSelectionsForModule = () => {
    const trackSelections = useDCTrackSelections();
    const setTrackSelections = useDCSetTrackSelections();
    return (slug) => {
        const newSelections = [...trackSelections];
        const i = newSelections.findIndex(s => s.module === slug);
        if (i >= 0) {
            newSelections.splice(i, 1);
            setTrackSelections(newSelections);
        }
    };
};
export const useDCGetTrackSelectionsForModule = () => {
    const trackSelections = useDCTrackSelections();
    return (slug) => {
        var _a;
        return (_a = trackSelections.find(s => s.module === slug)) === null || _a === void 0 ? void 0 : _a.tracks;
    };
};
export const useDCActivateModule = (slug) => {
    const setActiveModuleSlug = useDCSetActiveModuleSlug();
    useEffect(() => {
        if (slug) {
            setActiveModuleSlug(slug);
        }
        return () => {
            setActiveModuleSlug(null);
        };
    }, [slug]);
};
export const useDCGetPathForNextModule = () => {
    const modules = useDCModules();
    return (currentModuleSlug) => {
        const currentModuleIndex = modules.findIndex(m => m.slug === currentModuleSlug);
        const nextModuleIndex = currentModuleIndex + 1 < modules.length ? currentModuleIndex + 1 : 0;
        const nextModule = modules[nextModuleIndex];
        return `/${nextModule.slug}`;
    };
};
export const useDCGenerateModuleSlug = () => {
    const modules = useDCModules();
    return (title) => {
        const slug = dcGenerateSlugFromText(title);
        const exists = (slug) => !!modules.find(m => m.slug === slug);
        return dcEnforceUniqueSlug(slug, exists);
    };
};
export const useDCGetNewModule = () => {
    const generateSlug = useDCGenerateModuleSlug();
    return (module) => {
        const title = (module === null || module === void 0 ? void 0 : module.title) || "";
        return Object.assign({ id: uuidv4(), title, prompt: "", description: "", slug: generateSlug(title || DC_DEFAULT_MODULE_NAME), items: [], tracks: [], type: null }, module);
    };
};
export const useDCGetNewTrack = (module) => {
    const generateTrackSlug = useDCGenerateTrackSlug(module);
    return (track) => {
        const title = (track === null || track === void 0 ? void 0 : track.title) || "New Track";
        return Object.assign({ id: uuidv4(), title, slug: generateTrackSlug(title), items: [] }, track);
    };
};
export const useDCCreateWelcomeModule = () => {
    const getNewModule = useDCGetNewModule();
    const getNewTrack = useDCGetNewTrack();
    return () => {
        return getNewModule({
            title: "Welcome",
            slug: DCCMPageType.WELCOME,
            type: DCModuleType.NO_PROMPT,
            tracks: [getNewTrack({ title: "Default" })],
        });
    };
};
export const useDCGetDefaultTracks = () => {
    const getNewTrack = useDCGetNewTrack();
    return (type) => {
        switch (type) {
            case DCModuleType.BOOLEAN:
                return [getNewTrack({ title: "Yes" }), getNewTrack({ title: "No" })];
            case DCModuleType.SCALE:
                return [
                    getNewTrack({ title: "Very important" }),
                    getNewTrack({ title: "Somewhat important" }),
                    getNewTrack({ title: "Not important" }),
                ];
            case DCModuleType.NO_PROMPT:
                return [getNewTrack({ title: "Default" })];
            default:
                return [];
        }
    };
};
export const useWelcomeModuleFirstItemId = () => {
    var _a;
    const modules = useDCModules();
    const welcomeModule = modules[0];
    return (welcomeModule === null || welcomeModule === void 0 ? void 0 : welcomeModule.items[0]) || ((_a = welcomeModule === null || welcomeModule === void 0 ? void 0 : welcomeModule.tracks[0]) === null || _a === void 0 ? void 0 : _a.items[0]) || null;
};
export const useDCGenerateTrackSlug = (module) => {
    return (title) => {
        const slug = dcGenerateSlugFromText(title);
        const exists = (slug) => !!module.tracks.find(t => t.slug === slug);
        return !module ? slug : dcEnforceUniqueSlug(slug, exists);
    };
};
export const useDCAddTrackToModule = (module) => {
    const tracks = useDCTracks(module === null || module === void 0 ? void 0 : module.slug);
    const setTracks = useDCSetTracks(module === null || module === void 0 ? void 0 : module.slug);
    const getNewTrack = useDCGetNewTrack(module);
    return (title) => {
        const newTracks = [...tracks];
        newTracks.push(getNewTrack({ title }));
        setTracks(newTracks);
    };
};
export const useDCRemoveTrackFromModule = (module) => {
    const tracks = useDCTracks(module === null || module === void 0 ? void 0 : module.slug);
    const setTracks = useDCSetTracks(module === null || module === void 0 ? void 0 : module.slug);
    return (slug) => {
        const newTracks = [...tracks];
        const i = newTracks.findIndex(t => t.slug === slug);
        if (i >= 0) {
            newTracks.splice(i, 1);
            setTracks(newTracks);
        }
    };
};
export const useDCUpdateTrackInModule = (module) => {
    const tracks = useDCTracks(module === null || module === void 0 ? void 0 : module.slug);
    const setTracks = useDCSetTracks(module === null || module === void 0 ? void 0 : module.slug);
    return (track) => {
        const newTracks = [...tracks];
        const i = newTracks.findIndex(t => t.slug === track.slug);
        const newSlug = dcGenerateSlugFromText(track.title);
        if (i >= 0) {
            if (newSlug !== track.slug) {
                track.slug = dcEnforceUniqueSlug(newSlug, (slug) => !!newTracks.find(t => t.slug === slug));
            }
            newTracks[i] = track;
            setTracks(newTracks);
        }
    };
};
export const useDCAddItemsToTrack = (module) => {
    const { items, modules } = useDemoCenter();
    const updateDemoCenter = useUpdateDemoCenter();
    const exists = (id) => !!items.find(i => i.id === id);
    return (trackSlug, itemsToAdd) => {
        const newModules = [...modules];
        const newModule = newModules.find(m => m.slug === module.slug);
        const newItems = [...itemsToAdd].filter(i => !exists(i.id));
        const track = newModule.tracks.find(t => t.slug === trackSlug);
        if (track && itemsToAdd) {
            itemsToAdd.forEach(item => {
                if (!track.items.find(i => i === item.id)) {
                    track.items.push(item.id);
                }
            });
        }
        updateDemoCenter({ items: [...items, ...newItems], modules: newModules });
    };
};
// this needs to also remove the item if no other references to the item
export const useDCRemoveItemFromTrack = (module) => {
    const tracks = useDCTracks(module === null || module === void 0 ? void 0 : module.slug);
    const setTracks = useDCSetTracks(module === null || module === void 0 ? void 0 : module.slug);
    return (trackSlug, itemId) => {
        const newTracks = [...tracks];
        const track = newTracks.find(t => t.slug === trackSlug);
        if (track) {
            const i = track.items.findIndex(id => id === itemId);
            if (i >= 0) {
                track.items.splice(i, 1);
                setTracks(newTracks);
            }
        }
    };
};
export const useDCGetModuleItemCount = () => {
    return (module) => {
        if (!module)
            return;
        let count = 0;
        module.tracks.forEach(t => {
            count += t.items.length;
        });
        return count;
    };
};
export const useDCDefaultModuleTypes = (module) => {
    var _a;
    const { type, tracks, slug } = module || {};
    const [previousModule, setPreviousModule] = useState(module);
    const setTracks = useDCSetTracks(module === null || module === void 0 ? void 0 : module.slug);
    const itemCount = useDCGetModuleItemCount();
    const getDefaultTracks = useDCGetDefaultTracks();
    const hasTracks = ((_a = module === null || module === void 0 ? void 0 : module.tracks) === null || _a === void 0 ? void 0 : _a.length) > 0;
    const isBooleanOrScale = (type) => [DCModuleType.BOOLEAN, DCModuleType.SCALE].includes(type);
    const moduleHasChanged = slug !== (previousModule === null || previousModule === void 0 ? void 0 : previousModule.slug);
    const hasNoItems = !itemCount(module);
    const tracksAreUnchanged = hasTracks && isEqual(tracks, getDefaultTracks(previousModule === null || previousModule === void 0 ? void 0 : previousModule.type));
    const changingToOrFromBooleanOrScale = type !== (previousModule === null || previousModule === void 0 ? void 0 : previousModule.type) &&
        !moduleHasChanged &&
        (isBooleanOrScale(previousModule === null || previousModule === void 0 ? void 0 : previousModule.type) || isBooleanOrScale(type));
    useEffect(() => {
        const defaultTracks = getDefaultTracks(type);
        if (hasNoItems && (!hasTracks || tracksAreUnchanged || changingToOrFromBooleanOrScale)) {
            setTracks(defaultTracks);
        }
        setPreviousModule(module);
    }, [type, slug]);
};
export const useDCGetModuleStatus = () => {
    const getItems = useDCGetItemsForSelectedTracks();
    const getTrackSelections = useDCGetTrackSelectionsForModule();
    const activeModuleSlug = useDCActiveModuleSlug();
    const viewedItems = useDCViewedItems();
    const modules = useDCModules();
    const getModule = useDCGetModule();
    const gatingFormId = useDCConfigGatingFormID();
    return (slug) => {
        const module = getModule(slug);
        const items = getItems(slug);
        const trackSelections = getTrackSelections(slug);
        const viewedCount = items.filter(i => viewedItems.includes(i.id)).length;
        const isModuleComplete = items.length > 0 && viewedCount === items.length;
        const isBooleanOrScale = [DCModuleType.BOOLEAN, DCModuleType.SCALE].includes(module === null || module === void 0 ? void 0 : module.type);
        const isModuleRejected = !!trackSelections && items.length === 0 && isBooleanOrScale;
        const isModuleStarted = viewedCount > 0 || !!trackSelections;
        const activeModuleIndex = modules.findIndex(m => m.slug === activeModuleSlug);
        const moduleIndex = modules.findIndex(m => m.slug === slug);
        const isModuleActive = activeModuleSlug === slug;
        const isModuleBeforeActive = moduleIndex < activeModuleIndex;
        const isSkipped = isModuleBeforeActive && !isModuleStarted;
        if (gatingFormId && module.isGated && !wasFormSubmitted(gatingFormId))
            return DCModuleStatus.LOCKED;
        if (isModuleComplete)
            return DCModuleStatus.COMPLETE;
        if (isModuleRejected)
            return DCModuleStatus.REJECTED;
        if ((isModuleActive && !!trackSelections) || isModuleStarted)
            return DCModuleStatus.IN_PROGRESS;
        if (isModuleActive && !trackSelections)
            return DCModuleStatus.AWAITING_SELECTION;
        if (module.type === DCModuleType.NO_PROMPT)
            return DCModuleStatus.IN_PROGRESS;
        if (isSkipped)
            return DCModuleStatus.SKIPPED;
        return DCModuleStatus.AWAITING_SELECTION;
    };
};
