import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { EditorAccordion } from "../../../../pages/builder/editor/editor-accordion";
import React from "react";
import { TooltipTriggerComponent } from "../../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import styled from "styled-components";
import TooltipIcon from "../../../../../../assets/images/v3/editor-accordian-tooltip-icon.svg";
import LogoIcon from "../../../../../../assets/images/v3/editor-accordian-logo-icon.svg";
import HeadlineIcon from "../../../../../../assets/images/v3/editor-accordian-headline-icon.svg";
import BodyIcon from "../../../../../../assets/images/v3/editor-accordian-body-icon.svg";
import NextButtonIcon from "../../../../../../assets/images/v3/editor-accordian-next-button-icon.svg";
import PreviousButtonIcon from "../../../../../../assets/images/v3/editor-accordian-previous-button-icon.svg";
import HotspotIcon from "../../../../../../assets/images/hotspot-icon.svg";
import ClickzoneIcon from "../../../../../../assets/images/clickzone-icon.svg";
import SpotlightIcon from "../../../../../../assets/images/v3/editor-accordian-spotlight-icon.svg";
import StepCounterIcon from "../../../../../../assets/images/v3/step-counter-icon.svg";
import { cloneDeep } from "lodash";
import { InteractionName, InteractionType } from "../../../../../../../shared/types/tool-data-types/interactions";
export function ConfigureSection(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    return (React.createElement(Section, { id: "_test_editor-advanced-section" },
        tool.trigger.triggerComponent !== TooltipTriggerComponent.NONE && (React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: TooltipIcon, title: "Tooltip", isEnabled: tool.bubble.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.bubble.isEnabled = !t.bubble.isEnabled;
                updateTool(t);
            } })),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: LogoIcon, title: "Logo", isEnabled: tool.bubble.logo.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.bubble.logo.isEnabled = !t.bubble.logo.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: HeadlineIcon, title: "Headline", isEnabled: tool.bubble.headline.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.bubble.headline.isEnabled = !t.bubble.headline.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: BodyIcon, title: "Body", isEnabled: tool.bubble.body.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.bubble.body.isEnabled = !t.bubble.body.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: NextButtonIcon, title: "Next Button", isEnabled: tool.bubble.nextButton.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.bubble.nextButton.isEnabled = !t.bubble.nextButton.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: PreviousButtonIcon, title: "Previous button", isEnabled: tool.bubble.previousButton.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.bubble.previousButton.isEnabled = !t.bubble.previousButton.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: StepCounterIcon, title: "Step counter", isEnabled: tool.bubble.stepCounter.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.bubble.stepCounter.isEnabled = !t.bubble.stepCounter.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: HotspotIcon, title: "Hotspot", isEnabled: tool.trigger.triggerComponent === TooltipTriggerComponent.HOTSPOT, onToggle: () => {
                const t = cloneDeep(tool);
                if (tool.trigger.triggerComponent !== TooltipTriggerComponent.HOTSPOT) {
                    t.trigger.triggerComponent = TooltipTriggerComponent.HOTSPOT;
                    t.trigger.interaction.type = InteractionType.HOVER;
                    t.trigger.interaction.name = InteractionName.SHOW_TOOLTIP_BUBBLE_AND_SPOTLIGHT;
                }
                else {
                    t.trigger.triggerComponent = TooltipTriggerComponent.NONE;
                    if (!tool.bubble.isEnabled)
                        t.bubble.isEnabled = true;
                }
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: ClickzoneIcon, title: "Clickzone", isEnabled: tool.trigger.triggerComponent === TooltipTriggerComponent.CLICKZONE, onToggle: () => {
                const t = cloneDeep(tool);
                if (tool.trigger.triggerComponent !== TooltipTriggerComponent.CLICKZONE) {
                    t.trigger.triggerComponent = TooltipTriggerComponent.CLICKZONE;
                    t.trigger.interaction.type = InteractionType.CLICK;
                    t.trigger.interaction.name = InteractionName.NEXT_STEP;
                }
                else {
                    t.trigger.triggerComponent = TooltipTriggerComponent.NONE;
                    if (!tool.bubble.isEnabled)
                        t.bubble.isEnabled = true;
                }
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: SpotlightIcon, title: "Spotlight style", isEnabled: tool.spotlight.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.spotlight.isEnabled = !t.spotlight.isEnabled;
                updateTool(t);
            } })));
}
const Section = styled.div `
  height: calc(100% - 31px);
  overflow: auto;
`;
