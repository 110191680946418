import React from "react";
import TButton, { TButtonVariants } from "../../styled/t-button";
import styled from "styled-components";
import TModal, { TModalButtonsContainer } from "../../styled/t-modal";
import { newTheme } from "../../styled/theme";
export default function DeleteModal({ setIsDeleteModalOpen, onConfirmation }) {
    return (React.createElement(TModal, { title: "Delete your tourial?", onClose: () => setIsDeleteModalOpen(false) },
        React.createElement(React.Fragment, null,
            React.createElement(Span, null, "This tourial will no longer appear on websites where you\u2019ve shared it."),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => setIsDeleteModalOpen(false) }, "Keep Tourial"),
                React.createElement(TButton, { variant: TButtonVariants.DANGER, onClick: onConfirmation }, "Delete Tourial")))));
}
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  width: 100%;
  height: 68px;
`;
