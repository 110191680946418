import { debounce } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import { useTourialSlug } from "../../../../redux/selectors/redux-selectors";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { TDropdownSelect } from "../../../styled/t-dropdown-select";
import { newTheme } from "../../../styled/theme";
var EmailDestination;
(function (EmailDestination) {
    EmailDestination["HUBSPOT"] = "hubspot";
    EmailDestination["MARKETO"] = "marketo";
    EmailDestination["PARDOT"] = "pardot";
    EmailDestination["SALESLOFT"] = "salesloft";
    EmailDestination["APOLLO"] = "apollo";
})(EmailDestination || (EmailDestination = {}));
export const EmailTab = ({ baseUrlWithSubdomain }) => {
    const tourialSlug = useTourialSlug();
    const [outboundDestination, setOutboundDestination] = useState(EmailDestination.HUBSPOT);
    const [emailLinkWasCopied, setEmailLinkWasCopied] = useState(false);
    const copyEmailLinkToClipboard = debounce(() => {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(`${baseUrlWithSubdomain}pages/${tourialSlug}?email=${getEmailParamForDestination(outboundDestination)}&tourialOutbound=${outboundDestination}`).then(function () {
            setEmailLinkWasCopied(true);
        }, function (err) {
            console.error("Failed to copy text to clipboard: ", err);
        });
    }, 500);
    return (React.createElement(EmailTabContent, null,
        React.createElement(EmailNote, null, "Generate a URL and paste it in your email campaigns to track engagement data."),
        React.createElement("span", null, "Email Provider:"),
        React.createElement(TDropdownSelect, { isSearchable: false, style: { width: "100%" }, selectedValue: outboundDestination, onChange: (e) => {
                setOutboundDestination(e.value);
            }, options: [
                { value: EmailDestination.HUBSPOT, label: "Hubspot" },
                { value: EmailDestination.MARKETO, label: "Marketo" },
                { value: EmailDestination.PARDOT, label: "Pardot" },
                { value: EmailDestination.SALESLOFT, label: "SalesLoft" },
                { value: EmailDestination.APOLLO, label: "Apollo" },
            ] }),
        React.createElement(OutboundLink, null, `${baseUrlWithSubdomain}pages/${tourialSlug}?email=${getEmailParamForDestination(outboundDestination)}&tourialOutbound=${outboundDestination}`),
        React.createElement(CopyButtonContainer, null,
            emailLinkWasCopied && React.createElement("span", { id: "_test_email-link-copied" }, "Copied!"),
            React.createElement(TButton, { id: "_test_email-link-copy-button", variant: TButtonVariants.SECONDARY, size: "medium", onClick: () => {
                    copyEmailLinkToClipboard();
                } }, "Copy Email Link"))));
};
const getEmailParamForDestination = (destination) => {
    switch (destination) {
        case EmailDestination.HUBSPOT:
            return "{{contact.email}}";
        case EmailDestination.MARKETO:
            return "{{lead.Email Address:default=noemail}}";
        case EmailDestination.PARDOT:
            return "{{Recipient.Email}}";
        case EmailDestination.SALESLOFT:
            return "{{email_address}}";
        case EmailDestination.APOLLO:
            return "{{email}}";
    }
};
const EmailTabContent = styled.div `
  padding: 24px 16px;
  background-color: ${newTheme.colors.grey100};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  border-top: 1px solid ${newTheme.colors.grey300};
  font: ${newTheme.fonts.medium};
`;
const EmailNote = styled.p `
  margin-bottom: 8px;
`;
const OutboundLink = styled.div `
  padding: 8px 4px;
  background-color: ${newTheme.colors.blue50};
  border: 1px solid ${newTheme.colors.blue500};
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${newTheme.colors.blue500};
`;
const CopyButtonContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 16px;
  > span {
    padding-right: 16px;
  }
`;
