import React, { useState } from "react";
import { useAsync } from "react-async";
import GearIcon from "../../../../../../assets/images/v3/gear-outline.svg";
import TModal, { TModalButtonsContainer } from "../../../../styled/t-modal";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import { EmbedTab } from "./embed-tab";
import { MetadataTab } from "./metadata-tab";
import { ToastModes } from "../../../../../types/definitions";
import { useAddToast } from "../../../../../legacy/selectors";
import { useHTSlug } from "../../../../../redux/selectors/ht-selectors";
import { getCurrentUser } from "../../../../../helpers/misc";
var SettingsModalTabs;
(function (SettingsModalTabs) {
    SettingsModalTabs["EMBED"] = "Embed";
    SettingsModalTabs["SOCIAL"] = "Social settings";
})(SettingsModalTabs || (SettingsModalTabs = {}));
export default function SettingsModal() {
    const addToast = useAddToast();
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(SettingsModalTabs.EMBED);
    const [hasEmbedChanged, setHasEmbedChanged] = useState(false);
    const domTourSlug = useHTSlug();
    const { data: currentUserData, error: currentUserError } = useAsync({
        promiseFn: getCurrentUser,
    });
    if (currentUserError) {
        console.error("Error getting current user", currentUserError);
    }
    const baseUrlWithSubdomain = currentUserData;
    const addEmbedCodeHasChangedToast = () => {
        if (!hasEmbedChanged) {
            addToast({
                message: "Your embed code has changed.",
                mode: ToastModes.WARNING,
            });
        }
        setHasEmbedChanged(true);
    };
    const tabs = [SettingsModalTabs.EMBED, SettingsModalTabs.SOCIAL];
    return (React.createElement(React.Fragment, null,
        React.createElement(TButton, { onClick: () => {
                setIsSettingsModalOpen(true);
            }, size: "medium", variant: TButtonVariants.OUTLINE, icon: GearIcon }),
        isSettingsModalOpen && (React.createElement(TModal, { title: "Settings", tabs: tabs, icon: GearIcon, onTabSelect: tab => {
                setSelectedTab(tab);
            }, onClose: () => {
                setIsSettingsModalOpen(false);
                setSelectedTab(SettingsModalTabs.EMBED);
            }, showAlertForTabName: hasEmbedChanged ? SettingsModalTabs.EMBED : null },
            React.createElement(React.Fragment, null,
                selectedTab === SettingsModalTabs.EMBED && (React.createElement(React.Fragment, null,
                    React.createElement(EmbedTab, { baseUrlWithSubdomain: baseUrlWithSubdomain, tourialSlug: domTourSlug, embedCodeHasChanged: addEmbedCodeHasChangedToast }))),
                selectedTab === SettingsModalTabs.SOCIAL && React.createElement(MetadataTab, null),
                React.createElement(TModalButtonsContainer, null,
                    React.createElement(TButton, { onClick: () => {
                            setIsSettingsModalOpen(false);
                        } }, "Done")))))));
}
