import React, { useEffect, useState } from "react";
import { ViewportMode } from "../../../../../../types/definitions";
import { useUpdateTool, useViewportMode } from "../../../../../../redux/selectors/redux-selectors";
import { cloneDeep } from "lodash";
import { extractTextStyle } from "../../../../tool-views/form-v2-view";
export function HeaderText({ toolData, viewportDimensions, isConfirmation, customStyle, }) {
    const { headerText } = toolData.text;
    const [textData, setTextData] = useState(isConfirmation ? headerText.confirmationTextData : headerText.textData);
    const viewportMode = useViewportMode();
    const updateTool = useUpdateTool();
    const style = Object.assign(Object.assign(Object.assign({ width: "100%", maxHeight: "100%", left: 0, top: 0, textAlign: "left", overflow: "hidden", position: "static", display: "block", verticalAlign: "top", cursor: viewportMode === ViewportMode.EDIT ? "text" : "inherit" }, extractTextStyle(headerText)), { fontSize: `${(viewportDimensions.height * headerText.fontSize) / 100}px` }), customStyle);
    let textRef = null;
    function onChange(value) {
        try {
            setTextData(value);
            const newTool = cloneDeep(toolData);
            const initialHeight = textRef.style.height;
            textRef.style.height = "1px";
            const { scrollHeight } = textRef;
            textRef.style.height = initialHeight;
            if (isConfirmation) {
                if (scrollHeight && viewportDimensions.height)
                    newTool.text.headerText.confirmationHeight = scrollHeight / viewportDimensions.height;
                newTool.text.headerText.confirmationTextData = value;
            }
            else {
                if (scrollHeight && viewportDimensions.height)
                    newTool.text.headerText.height = scrollHeight / viewportDimensions.height;
                newTool.text.headerText.textData = value;
            }
            updateTool(newTool);
        }
        catch (_a) {
            return;
        }
    }
    useEffect(() => {
        if (isConfirmation) {
            onChange(toolData.text.headerText.confirmationTextData);
        }
        else if (toolData.layout === 4) {
            onChange(toolData.text.headerText.textData);
        }
    }, [headerText.fontFamily, headerText.fontSize, headerText.fontWeight]);
    return toolData.layout !== 4 && !isConfirmation ? (React.createElement("textarea", { rows: 1, style: style, value: textData, readOnly: viewportMode !== ViewportMode.EDIT, onChange: e => {
            setTextData(e.target.value);
            const newTool = cloneDeep(toolData);
            if (isConfirmation) {
                newTool.text.headerText.confirmationTextData = e.target.value;
            }
            else {
                newTool.text.headerText.textData = e.target.value;
            }
            updateTool(newTool);
        } })) : (React.createElement("textarea", { rows: 1, ref: text => (textRef = text), style: Object.assign(Object.assign({}, style), { height: `${(isConfirmation ? headerText.confirmationHeight : headerText.height) * viewportDimensions.height}px`, margin: "1% 0px" }), value: textData, readOnly: viewportMode !== ViewportMode.EDIT, onChange: e => onChange(e.target.value) }));
}
