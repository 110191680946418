import React from "react";
import { Button } from "../tool-views/button-view";
import { Group } from "../tool-views/group-view";
import { Hotspot } from "../tool-views/hotspot-view";
import { Background } from "../tool-views/background-view";
import { Screen } from "../tool-views/screen-view";
import { Shape } from "../tool-views/shape-view";
import { Textbox } from "../tool-views/textbox-view";
import { Image } from "../tool-views/image-view";
import { Video } from "../tool-views/video-view";
import { Breadcrumbs } from "../tool-views/breadcrumbs-view";
import { ClickZone } from "../tool-views/clickzone-view";
import { FormInputs } from "../tool-views/form-inputs-view";
import { Tooltip } from "../tool-views/tooltip-view";
import { Embed } from "../tool-views/embed-view";
import { adjustPxValuesForThumbnail } from "../../../helpers/misc";
import { ViewportMode } from "../../../types/definitions";
import { useActivePageId, useBuilderStyle, useBuilderToolOnClick, useLiveAndPreviewVisibleToolIds, usePushHistory, useSetEditDetailsId, useToolData, useToolOnMouseEnter, useToolOnMouseLeave, useToolOnHover, useUpdateBuilder, useViewportMode, } from "../../../redux/selectors/redux-selectors";
import { FormV2 } from "../tool-views/form-v2-view";
import { ToolType } from "../../../../../shared/types/tool-data-types/common";
export function RenderBuilderTool({ toolId, viewportDimensions, disableAnimations, thumbnailPageId, thumbnailZoomId, }) {
    const toolData = useToolData(toolId);
    const viewportMode = useViewportMode();
    const activePageId = useActivePageId();
    const liveAndPreviewVisibleToolIds = useLiveAndPreviewVisibleToolIds();
    const CommonToolWrapperProps = {
        viewportMode,
        builderStyle: useBuilderStyle(toolId, toolData.type, liveAndPreviewVisibleToolIds.includes(toolId)),
        toolOnClick: useBuilderToolOnClick(toolId),
        toolOnMouseEnter: useToolOnMouseEnter(),
        toolOnMouseLeave: useToolOnMouseLeave(),
        toolOnHover: useToolOnHover(toolId),
        updateBuilder: useUpdateBuilder(),
        setEditDetailsId: useSetEditDetailsId(),
        pushHistory: usePushHistory(),
        viewportDimensions,
        disableAnimations: !!disableAnimations,
    };
    if (!toolData)
        return null;
    const toolShouldRender = true; // true
    if (!toolShouldRender)
        return null;
    const baseToolViewProps = Object.assign(Object.assign({ viewportDimensions }, CommonToolWrapperProps), { toolData: thumbnailPageId ? adjustPxValuesForThumbnail(toolData) : toolData });
    switch (toolData.type) {
        case ToolType.Button:
            return React.createElement(Button, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.Hotspot:
            return React.createElement(Hotspot, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.Shape:
            return React.createElement(Shape, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.Textbox:
            return React.createElement(Textbox, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.Group:
            return viewportMode === ViewportMode.EDIT ? React.createElement(Group, Object.assign({ key: toolData.id }, baseToolViewProps)) : null;
        case ToolType.Spotlight:
            // NOTE(Dar): spotlights are rendered within the Screen component
            return null;
        case ToolType.Image:
            return React.createElement(Image, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.Video:
            return React.createElement(Video, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.Breadcrumbs:
            return React.createElement(Breadcrumbs, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.ClickZone:
            return React.createElement(ClickZone, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.FormInputs:
            return React.createElement(FormInputs, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.Tooltip:
            return React.createElement(Tooltip, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.FormV2:
            return React.createElement(FormV2, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.Embed:
            return React.createElement(Embed, Object.assign({ key: toolData.id }, baseToolViewProps));
        case ToolType.Background:
            return (
            // @ts-expect-error
            React.createElement(Background, Object.assign({ key: toolData.id }, baseToolViewProps, { pageId: thumbnailPageId })));
        case ToolType.Screen:
            return (React.createElement(Screen, Object.assign({ key: activePageId }, baseToolViewProps, { 
                // @ts-expect-error
                pageId: thumbnailPageId, zoomId: thumbnailZoomId })));
        default:
            console.error("Attempted to render unimplemented view for ToolType:", toolData.type);
            return null;
    }
}
