import React from "react";
import styled from "styled-components";
import LogoIcon from "../../../../public/icons/logo-final-darkblue-freight-sans.png";
import { TLoadingSpinner } from "../../../components/styled/t-loading-spinner";
export default function IsLoading({ style }) {
    if (style === "poweredByTourial") {
        return (React.createElement(LoadingContainer, { className: "loading-page" },
            React.createElement("div", { className: "powered-by-tourial-loading-container" },
                React.createElement("p", null, "Powered by"),
                React.createElement("img", { src: LogoIcon, height: "50" })),
            React.createElement(TLoadingSpinner, null)));
    }
    else {
        return (React.createElement(LoadingContainer, { className: "loading-page" },
            React.createElement(TLoadingSpinner, null)));
    }
}
const LoadingContainer = styled.div `
  height: 100vh;
`;
