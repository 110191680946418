import React, { useEffect, useState } from "react";
import { useAsync } from "react-async";
import TButton from "../../../styled/t-button";
import styled from "styled-components";
import TModal, { TModalButtonsContainer } from "../../../styled/t-modal";
import { useIsFullyAutomated, useSetTourialLoop, useTourialLoop, useTourialProductTourPage, useTourialSlug, useUpdateTourialPartial, } from "../../../../redux/selectors/redux-selectors";
import GearIcon from "../../../../../assets/images/v3/gear-outline.svg";
import ColorSwatchIcon from "../../../../../assets/images/v3/color-swatch-icon.svg";
import ImageIcon from "../../../../../assets/images/v3/image-icon.svg";
import HandPointIcon from "../../../../../assets/images/v3/hand-point-icon.svg";
import UploadIcon from "../../../../../assets/images/upload-icon-blue.svg";
import DeleteIcon from "../../../../../assets/images/v3/red-trashcan.svg";
import NavbarOutlineIcon from "../../../../../assets/images/v3/navbar-outline-icon.svg";
import PageOutlineIcon from "../../../../../assets/images/v3/page-outline-icon.svg";
import NavbarOutlineWhite from "../../../../../assets/images/v3/navbar-outline-icon-white.svg";
import PageOutlineWhite from "../../../../../assets/images/v3/page-outline-icon-white.svg";
import RefreshIcon from "../../../../../assets/images/v3/refresh-icon.svg";
import Arrows from "../../../../../assets/images/v3/arrows.svg";
import ProgressBar from "../../../../../assets/images/v3/progress-bar.svg";
import { newTheme } from "../../../styled/theme";
import { MetadataTab } from "./metadata-tab";
import { EmbedTab } from "./embed-tab";
import { ProductPageEditPreview } from "../navbar/productPageSettings/product-page-edit-preview";
import { ModalAccordion, ModalAccordionContainer, ModalAccordionContentTitle } from "./modal-accordion";
import { ColorPicker } from "../editor/atomic/color-picker";
import { NumberInput } from "../editor/atomic/number-input";
import { imageTypes, uploadMedia } from "../../../../helpers/media";
import SearchIcon from "../../../../../assets/images/v3/search-icon-blue.svg";
import MediaSearchModal from "./media-search-modal";
import { useAddToast } from "../../../../legacy/selectors";
import { ToastModes } from "../../../../types/definitions";
import { getProductPageTemplate } from "../../../../../../shared/functions/tourial";
import { cloneDeep, isEqual } from "lodash";
import { ProductPageCtaConfiguration } from "./product-page-cta-configuration";
import { useFetchTheme } from "../../../../hooks/use-fetch-theme";
import { MobileTab } from "./mobile-tab";
import { TToggleSwitch } from "../../../styled/t-toggle-switch";
import { getCurrentUser } from "../../../../helpers/misc";
var PublishModalTabs;
(function (PublishModalTabs) {
    PublishModalTabs["APPEARANCE"] = "Appearance";
    PublishModalTabs["MOBILE"] = "Mobile";
    PublishModalTabs["EMBED"] = "Embed";
    PublishModalTabs["SOCIAL"] = "Social settings";
    PublishModalTabs["SHARE_LINKS"] = "Links";
})(PublishModalTabs || (PublishModalTabs = {}));
var AppearanceTabs;
(function (AppearanceTabs) {
    AppearanceTabs["TOUR_CONTROLS"] = "Tour controls";
    AppearanceTabs["NAVBAR"] = "Navbar";
    AppearanceTabs["PAGE"] = "page";
})(AppearanceTabs || (AppearanceTabs = {}));
export default function SettingsModal({ setIsSettingsModalOpen }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const updateTourialPartial = useUpdateTourialPartial();
    const isFullyAutomated = useIsFullyAutomated();
    const setTourialLoop = useSetTourialLoop();
    const tourialLoop = useTourialLoop();
    const addToast = useAddToast();
    const { theme } = useFetchTheme();
    const [selectedTab, setSelectedTab] = useState(PublishModalTabs.APPEARANCE);
    const [isMediaSearchModalOpen, setIsMediaSearchModalOpen] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState("");
    const productTourPage = useTourialProductTourPage();
    const [selectedAppearanceTab, setSelectedAppearanceTab] = useState(AppearanceTabs.NAVBAR);
    const [unsavedProductPage, setUnsavedProductPage] = useState(cloneDeep(productTourPage) || getProductPageTemplate(theme));
    const [hasEmbedChanged, setHasEmbedChanged] = useState(false);
    useEffect(() => {
        if (!productTourPage) {
            setUnsavedProductPage(getProductPageTemplate(theme));
        }
    }, [theme]);
    useEffect(() => {
        if (backgroundImage) {
            setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { backgroundImage }));
        }
    }, [backgroundImage]);
    const tourialSlug = useTourialSlug();
    const { data: currentUserData, error: currentUserError } = useAsync({
        promiseFn: getCurrentUser,
    });
    const onComplete = () => updateTourialPartial({ productTourPage: unsavedProductPage });
    if (currentUserError) {
        console.error("Error getting current user", currentUserError);
    }
    const baseUrlWithSubdomain = currentUserData;
    const addEmbedCodeHasChangedToast = () => {
        if (!hasEmbedChanged) {
            addToast({
                message: "Your embed code has changed.",
                mode: ToastModes.WARNING,
            });
        }
        setHasEmbedChanged(true);
    };
    const showToastIfPTPChanged = () => {
        if (!isEqual(productTourPage, unsavedProductPage)) {
            addToast({
                message: "Save your page changes.",
                mode: ToastModes.WARNING,
            });
        }
    };
    const tabs = [PublishModalTabs.APPEARANCE];
    tabs.push(PublishModalTabs.MOBILE);
    tabs.push(PublishModalTabs.EMBED);
    tabs.push(PublishModalTabs.SOCIAL);
    // if (featureFlags.shareLinks) {
    //   tabs.push(PublishModalTabs.SHARE_LINKS);
    // }
    return (React.createElement(TModal, { title: "Settings", id: "_test_publish-modal", tabs: tabs, icon: GearIcon, onTabSelect: tab => {
            setSelectedTab(tab);
            onComplete();
        }, onClose: () => setIsSettingsModalOpen(false), showAlertForTabName: hasEmbedChanged ? PublishModalTabs.EMBED : null },
        React.createElement(React.Fragment, null,
            selectedTab === PublishModalTabs.APPEARANCE && (React.createElement(ShareTab, null,
                React.createElement(PublishSection, null,
                    isFullyAutomated && (React.createElement(LoopToggleContainer, null,
                        React.createElement(LoopToggle, null,
                            React.createElement("img", { src: RefreshIcon, style: { alignSelf: "center" } }),
                            React.createElement("div", null, "Restart tour on complete"),
                            React.createElement(TToggleSwitch, { onChange: value => {
                                    setTourialLoop(value);
                                    addToast({
                                        message: "Save your change.",
                                        mode: ToastModes.WARNING,
                                    });
                                }, size: "small", enabled: tourialLoop })),
                        React.createElement(LoopText, null, "Returns the viewer to first step after they complete the last step. Creates a looping effect on tours that only have animated steps."))),
                    React.createElement(ProductTourPagePreviewContainer, { style: { height: isFullyAutomated ? 156 : "auto" } },
                        React.createElement(ProductPageEditPreview, { productPage: unsavedProductPage, width: 280, scale: isFullyAutomated ? 0.7 : 1 })),
                    React.createElement(ModalAccordionContainer, null,
                        React.createElement(Tab, null,
                            React.createElement(Button, { selected: selectedAppearanceTab === AppearanceTabs.NAVBAR, onClick: () => {
                                    setSelectedAppearanceTab(AppearanceTabs.NAVBAR);
                                } },
                                React.createElement("img", { src: selectedAppearanceTab === AppearanceTabs.NAVBAR ? NavbarOutlineWhite : NavbarOutlineIcon }),
                                React.createElement("span", null, "Navbar")),
                            React.createElement(Button, { selected: selectedAppearanceTab === AppearanceTabs.PAGE, onClick: () => {
                                    setSelectedAppearanceTab(AppearanceTabs.PAGE);
                                } },
                                React.createElement("img", { src: selectedAppearanceTab === AppearanceTabs.PAGE ? PageOutlineWhite : PageOutlineIcon }),
                                React.createElement("span", null, "Page"))),
                        selectedAppearanceTab === AppearanceTabs.TOUR_CONTROLS && (React.createElement(React.Fragment, null,
                            React.createElement(ModalAccordion, { title: "Arrows", icon: Arrows, enabled: (_b = (_a = unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.progressBarStyle) === null || _a === void 0 ? void 0 : _a.isArrowEnabled) !== null && _b !== void 0 ? _b : false, onToggle: enabled => {
                                    var _a, _b;
                                    setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { progressBarStyle: Object.assign(Object.assign({}, unsavedProductPage.progressBarStyle), { isArrowEnabled: (_b = !((_a = unsavedProductPage.progressBarStyle) === null || _a === void 0 ? void 0 : _a.isArrowEnabled)) !== null && _b !== void 0 ? _b : enabled }) }));
                                } },
                                React.createElement(ModalAccordionContentTitle, null, "Arrows"),
                                React.createElement("div", { style: { flex: 1 } },
                                    React.createElement(ColorPicker, { color: (_c = unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.progressBarStyle) === null || _c === void 0 ? void 0 : _c.arrowColor, onChange: arrowColor => setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { progressBarStyle: Object.assign(Object.assign({}, unsavedProductPage.progressBarStyle), { arrowColor }) })) }))),
                            React.createElement(ModalAccordion, { title: "Progress bar", icon: ProgressBar, enabled: (_e = (_d = unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.progressBarStyle) === null || _d === void 0 ? void 0 : _d.isBarEnabled) !== null && _e !== void 0 ? _e : false, onToggle: enabled => {
                                    var _a, _b;
                                    setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { progressBarStyle: Object.assign(Object.assign({}, unsavedProductPage.progressBarStyle), { isBarEnabled: (_b = !((_a = unsavedProductPage.progressBarStyle) === null || _a === void 0 ? void 0 : _a.isBarEnabled)) !== null && _b !== void 0 ? _b : enabled }) }));
                                } },
                                React.createElement(ModalAccordionContentTitle, null, "Progress background"),
                                React.createElement("div", { style: { flex: 1 } },
                                    React.createElement(ColorPicker, { color: (_f = unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.progressBarStyle) === null || _f === void 0 ? void 0 : _f.backgroundColor, onChange: backgroundColor => setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { progressBarStyle: Object.assign(Object.assign({}, unsavedProductPage.progressBarStyle), { backgroundColor }) })) })),
                                React.createElement(ModalAccordionContentTitle, null, "Progress bar"),
                                React.createElement("div", { style: { flex: 1 } },
                                    React.createElement(ColorPicker, { color: (_g = unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.progressBarStyle) === null || _g === void 0 ? void 0 : _g.barColor, onChange: barColor => setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { progressBarStyle: Object.assign(Object.assign({}, unsavedProductPage.progressBarStyle), { barColor }) })) }))))),
                        selectedAppearanceTab === AppearanceTabs.NAVBAR && (React.createElement(React.Fragment, null,
                            React.createElement(ModalAccordion, { title: "Styles", icon: ColorSwatchIcon },
                                React.createElement(ModalAccordionContentTitle, null, "Nav"),
                                React.createElement("div", { style: { flex: 1 } },
                                    React.createElement(ColorPicker, { color: unsavedProductPage.headerColor, onChange: headerColor => setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { headerColor })) })),
                                React.createElement(ModalAccordionContentTitle, null, "Nav Border"),
                                React.createElement("div", { style: { display: "flex" } },
                                    React.createElement(NumberInput, { min: 0, max: 5, step: 1, value: unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.headerBorderWidth, onChange: (e) => {
                                            const borderWidth = e.target.value;
                                            setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { headerBorderWidth: borderWidth }));
                                        }, style: { width: "65px" } }),
                                    React.createElement("div", { style: { flex: 1, padding: "2px 0 0 16px" } },
                                        React.createElement(ColorPicker, { color: unsavedProductPage.headerBorderColor, onChange: headerBorderColor => setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { headerBorderColor })) })))),
                            React.createElement(ModalAccordion, { title: "Logo", icon: ImageIcon },
                                React.createElement(UploadLogoContainer, null,
                                    unsavedProductPage.logo && (React.createElement(UploadLogoImageContainer, null,
                                        React.createElement("img", { style: { maxHeight: 64 }, src: unsavedProductPage.logo }))),
                                    React.createElement(UploadButtonsContainer, null,
                                        React.createElement(UploadLogoButton, null,
                                            React.createElement("img", { src: UploadIcon }),
                                            unsavedProductPage.logo ? "Upload New" : "Upload Image",
                                            React.createElement("input", { multiple: false, type: "file", accept: `${imageTypes.join("|")}`, onClick: e => {
                                                    //@ts-expect-error
                                                    e.target.value = null;
                                                }, onChange: e => {
                                                    void uploadMedia(e.target.files, (media) => {
                                                        var _a;
                                                        setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { logo: (_a = media[0]) === null || _a === void 0 ? void 0 : _a.src }));
                                                    });
                                                } })),
                                        unsavedProductPage.logo && (React.createElement(DeleteLogoButton, { onClick: () => setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { logo: "" })) },
                                            React.createElement("img", { src: DeleteIcon }),
                                            "Delete"))))),
                            React.createElement(ModalAccordion, { title: "CTA 1", icon: HandPointIcon, enabled: (_h = unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.ctaPrimary.isEnabled) !== null && _h !== void 0 ? _h : true, onToggle: () => {
                                    setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { ctaPrimary: Object.assign(Object.assign({}, unsavedProductPage.ctaPrimary), { isEnabled: !unsavedProductPage.ctaPrimary.isEnabled }) }));
                                } },
                                React.createElement(ProductPageCtaConfiguration, { ctaButton: unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.ctaPrimary, updateFn: e => {
                                        setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { ctaPrimary: e }));
                                    } })),
                            React.createElement(ModalAccordion, { title: "CTA 2", icon: HandPointIcon, enabled: (_k = (_j = unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.ctaSecondary) === null || _j === void 0 ? void 0 : _j.isEnabled) !== null && _k !== void 0 ? _k : false, onToggle: enabled => {
                                    var _a, _b;
                                    setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { ctaSecondary: Object.assign(Object.assign({}, unsavedProductPage.ctaSecondary), { isEnabled: (_b = !((_a = unsavedProductPage.ctaSecondary) === null || _a === void 0 ? void 0 : _a.isEnabled)) !== null && _b !== void 0 ? _b : enabled }) }));
                                } },
                                React.createElement(ProductPageCtaConfiguration, { ctaButton: unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.ctaSecondary, updateFn: e => {
                                        setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { ctaSecondary: e }));
                                    } })),
                            React.createElement(ModalAccordion, { title: "CTA 3", icon: HandPointIcon, enabled: (_m = (_l = unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.ctaTertiary) === null || _l === void 0 ? void 0 : _l.isEnabled) !== null && _m !== void 0 ? _m : false, onToggle: enabled => {
                                    var _a, _b;
                                    setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { ctaTertiary: Object.assign(Object.assign({}, unsavedProductPage.ctaTertiary), { isEnabled: (_b = !((_a = unsavedProductPage.ctaTertiary) === null || _a === void 0 ? void 0 : _a.isEnabled)) !== null && _b !== void 0 ? _b : enabled }) }));
                                } },
                                React.createElement(ProductPageCtaConfiguration, { ctaButton: unsavedProductPage === null || unsavedProductPage === void 0 ? void 0 : unsavedProductPage.ctaTertiary, updateFn: e => {
                                        setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { ctaTertiary: e }));
                                    } })))),
                        selectedAppearanceTab === AppearanceTabs.PAGE && (React.createElement(ModalAccordion, { title: "Background", icon: ColorSwatchIcon },
                            React.createElement(ModalAccordionContentTitle, null, "Background image"),
                            unsavedProductPage.backgroundImage && (React.createElement("img", { src: unsavedProductPage.backgroundImage, style: { width: 239, height: 145, objectFit: "fill", marginBottom: 6 } })),
                            React.createElement(UploadButtonsContainer, { style: { margin: "8px auto" } },
                                React.createElement(UploadLogoButton, null,
                                    React.createElement("img", { src: UploadIcon }),
                                    unsavedProductPage.backgroundImage ? "Upload New" : "Upload Image",
                                    React.createElement("input", { multiple: false, type: "file", accept: `${imageTypes.join("|")}`, onClick: e => {
                                            //@ts-expect-error
                                            e.target.value = null;
                                        }, onChange: e => {
                                            void uploadMedia(e.target.files, (media) => {
                                                var _a;
                                                setBackgroundImage((_a = media[0]) === null || _a === void 0 ? void 0 : _a.src);
                                            });
                                        } })),
                                React.createElement(UploadLogoButton, { id: "upload_widget", onClick: () => setIsMediaSearchModalOpen(true), style: { paddingLeft: 16, marginLeft: 16, borderLeft: `1px solid ${newTheme.colors.grey500}` } },
                                    React.createElement("img", { src: SearchIcon }),
                                    "Search Unsplash"),
                                unsavedProductPage.backgroundImage && (React.createElement(DeleteLogoButton, { onClick: () => setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { backgroundImage: "" })) },
                                    React.createElement("img", { src: DeleteIcon }),
                                    "Delete")),
                                isMediaSearchModalOpen && (React.createElement(MediaSearchModal, { setIsMediaSearchModalOpen: setIsMediaSearchModalOpen, setBackgroundImage: setBackgroundImage }))),
                            React.createElement(ModalAccordionContentTitle, null, "Background color"),
                            React.createElement("div", { style: { flex: 1 } },
                                React.createElement(ColorPicker, { color: unsavedProductPage.bodyColor, onChange: bodyColor => setUnsavedProductPage(Object.assign(Object.assign({}, unsavedProductPage), { bodyColor })) })))))))),
            selectedTab === PublishModalTabs.MOBILE && React.createElement(MobileTab, { embedCodeHasChanged: addEmbedCodeHasChangedToast }),
            selectedTab === PublishModalTabs.EMBED && (React.createElement(React.Fragment, null,
                React.createElement(EmbedTab, { baseUrlWithSubdomain: baseUrlWithSubdomain, tourialSlug: tourialSlug, embedCodeHasChanged: addEmbedCodeHasChangedToast }))),
            selectedTab === PublishModalTabs.SOCIAL && React.createElement(MetadataTab, null),
            selectedTab !== PublishModalTabs.SHARE_LINKS && (React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { onClick: () => {
                        showToastIfPTPChanged();
                        onComplete();
                        setIsSettingsModalOpen(false);
                    } }, "Done"))))));
}
const Button = styled.button `
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: ${props => (props.selected ? newTheme.colors.white : newTheme.colors.grey600)};
  background-color: ${props => (props.selected ? newTheme.colors.blue : newTheme.colors.white)};
  border-style: solid;
  border-color: ${newTheme.colors.grey400};
  height: 32px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${props => (props.selected ? newTheme.colors.blue400 : newTheme.colors.grey200)};
  }
  &:first-child {
    border-width: 0px 1px 0px 0px;
  }
  &:last-child {
    border-width: 0px 0px 0px 1px;
  }
  > span {
    padding: 0 4px;
    font: ${newTheme.fonts.bold};
  }
`;
const Tab = styled.div `
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${newTheme.colors.white};
  border-bottom: 1px solid ${newTheme.colors.grey400};
`;
const ProductTourPagePreviewContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${newTheme.colors.grey200};
  border: 1px solid ${newTheme.colors.grey400};
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const UploadButtonsContainer = styled.div `
  display: flex;
  margin: 0px auto;
`;
const UploadLogoButton = styled.label `
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${newTheme.colors.blue500};
  &:hover {
    cursor: pointer;
  }
  > img {
    cursor: pointer;
    width: 20px;
    position: relative;
    margin-right: 5px;
  }
`;
const DeleteLogoButton = styled.div `
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-left: 16px;
  border-left: 1px solid ${newTheme.colors.grey500};
  cursor: pointer;
  > img {
    width: 20px;
    position: relative;
    margin-right: 5px;
  }
`;
const UploadLogoContainer = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
`;
const UploadLogoImageContainer = styled.div `
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 16px;
`;
const ShareTab = styled.div `
  width: 658px;
`;
const PublishSection = styled.div `
  padding: 24px;
  background-color: ${newTheme.colors.grey100};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  color: ${newTheme.colors.grey800};
`;
const LoopToggleContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
const LoopText = styled.div `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey800};
`;
const LoopToggle = styled.div `
  display: flex;
  justify-content: start;
  gap: 8px;
  font: ${newTheme.fonts.bold};
  color: ${newTheme.colors.grey800};
  margin-bottom: 8px;
`;
