import React from "react";
import { BodyText } from "../subcomponents/body-text";
import { HeaderText } from "../subcomponents/header-text";
import { Logo } from "../subcomponents/logo";
export function LayoutConfirmation({ toolData, viewportDimensions }) {
    return (React.createElement("div", { style: {
            padding: `0% 7% 2% 7%`,
            display: "flex",
            flexFlow: "row nowrap",
            height: "100%",
            width: "100%",
        } },
        toolData.logo.isVisible && (React.createElement("div", { style: {
                maxHeight: "100%",
                maxWidth: "25%",
                position: "absolute",
            } },
            React.createElement(Logo, { toolData: toolData, viewportDimensions: viewportDimensions }))),
        React.createElement("div", { style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "20px",
            } },
            React.createElement("div", { style: {
                    marginBottom: "2%",
                    height: "45%",
                    minHeight: "45%",
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                } },
                React.createElement(HeaderText, { customStyle: {
                        textAlign: "center",
                    }, toolData: toolData, viewportDimensions: viewportDimensions, isConfirmation: true })),
            React.createElement(BodyText, { customStyle: { textAlign: "center", flexGrow: 1 }, toolData: toolData, viewportDimensions: viewportDimensions, isConfirmation: true }))));
}
