import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TextAlignLeftIcon from "../../../../../../assets/images/text-align-left-icon.svg";
import TextAlignCenterIcon from "../../../../../../assets/images/text-align-center-icon.svg";
import TextAlignRightIcon from "../../../../../../assets/images/text-align-right-icon.svg";
import { newTheme } from "../../../../styled/theme";
import { TextAlign } from "../../../../../../../shared/types/tool-data-types/common";
export const AlignmentSelector = (props) => {
    const { onChange, align } = props;
    const [selectedAlign, setSelectedAlign] = useState(TextAlign.LEFT);
    useEffect(() => {
        setSelectedAlign(align);
    }, [align]);
    return (React.createElement("div", null,
        React.createElement(LeftAlignButton, { selected: selectedAlign === TextAlign.LEFT, onClick: () => {
                setSelectedAlign(TextAlign.LEFT);
                onChange(TextAlign.LEFT);
            } },
            React.createElement("img", { src: TextAlignLeftIcon })),
        React.createElement(CenterAlignButton, { selected: selectedAlign === TextAlign.CENTER, onClick: () => {
                setSelectedAlign(TextAlign.CENTER);
                onChange(TextAlign.CENTER);
            } },
            React.createElement("img", { src: TextAlignCenterIcon })),
        React.createElement(RightAlignButton, { selected: selectedAlign === TextAlign.RIGHT, onClick: () => {
                setSelectedAlign(TextAlign.RIGHT);
                onChange(TextAlign.RIGHT);
            } },
            React.createElement("img", { src: TextAlignRightIcon }))));
};
const BaseButton = styled.button `
  color: ${newTheme.colors.grey600};
  background-color: ${props => (props.selected ? newTheme.colors.grey300 : newTheme.colors.white)};
  border-style: solid;
  border-width: 1px;
  width: 32px;
  height: 32px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.grey200};
  }
  img {
    width: 18px;
    height: 18px;
    margin: 2px;
    opacity: ${props => (props.selected ? "1" : "0.6")};
  }
`;
const LeftAlignButton = styled(BaseButton) `
  border-radius: 4px 0 0 4px;
`;
const CenterAlignButton = styled(BaseButton) `
  border-width: 1px 0;
  border-radius: 0;
`;
const RightAlignButton = styled(BaseButton) `
  border-radius: 0 4px 4px 0;
`;
