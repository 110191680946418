import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { SketchPicker } from "react-color";
import TLabel from "../styled/t-label";
import { useSavedColors, useToolColor, useToolData, useUpdateTool } from "../../../redux/selectors/redux-selectors";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
import { reactColorRgbaToString } from "../../../helpers/misc";
export function ToolColorPicker({ toolId, property, propertyParentName, label, backgroundPageId, }) {
    const tool = useToolData(toolId);
    const updateTool = useUpdateTool();
    const toolColor = useToolColor({
        toolId,
        propertyParentName,
        backgroundPageId,
        property,
    });
    const savedColors = useSavedColors();
    const [color, setColor] = useState({ r: 54, g: 79, b: 167, a: 1 });
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement("div", null,
        React.createElement(TLabel, null,
            label,
            React.createElement("button", { style: {
                    width: 25,
                    height: 25,
                    border: `1px solid ${theme.colors.grey.dark}`,
                    borderRadius: 4,
                    backgroundColor: toolColor,
                    cursor: "pointer",
                }, onClick: () => setIsOpen(!isOpen) })),
        isOpen && (React.createElement("div", { style: { margin: "0 auto" } },
            React.createElement(SketchPicker, { presetColors: savedColors, color: color, onChange: ({ rgb }) => {
                    setColor(rgb);
                    if (backgroundPageId) {
                        // We are updating BACKGROUND color
                        const backgroundTool = tool;
                        const newBackgroundData = Object.assign(Object.assign({}, backgroundTool), { backgrounds: Object.assign(Object.assign({}, backgroundTool.backgrounds), { [backgroundPageId]: Object.assign(Object.assign({}, backgroundTool.backgrounds[backgroundPageId]), { [property]: reactColorRgbaToString(rgb), src: "" }) }) });
                        return updateTool(newBackgroundData);
                    }
                    updateTool(addColorToTool(tool, rgb, property, propertyParentName));
                } })))));
}
const addColorToTool = (tool, rgbColor, property, propertyParentName) => {
    const updatedTool = cloneDeep(tool);
    const colorString = reactColorRgbaToString(rgbColor);
    if (propertyParentName) {
        updatedTool[propertyParentName] = updatedTool[propertyParentName] || {};
        updatedTool[propertyParentName][property] = colorString;
    }
    else {
        updatedTool[property] = colorString;
    }
    return updatedTool;
};
