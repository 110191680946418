import React from "react";
import { EditorTitlebar } from "../../../pages/builder/editor/editor-titlebar";
import { EditorTabs } from "../../../pages/builder/editor/editor-tabs";
import { GlobalButtonsStyleSection } from "./components/global-buttons-style-section";
import { GlobalButtonsConfigureSection } from "./components/global-buttons-configure-section";
export function GlobalButtonsEditor(props) {
    const { toolData: tool } = props;
    return (React.createElement("div", { id: "_test_global-buttons-editor" },
        React.createElement(EditorTitlebar, { title: tool.name, toolId: tool.id, tool: tool }),
        React.createElement(EditorTabs, { tabs: [
                { title: "Style", contents: React.createElement(GlobalButtonsStyleSection, Object.assign({}, props)) },
                { title: "Configure", contents: React.createElement(GlobalButtonsConfigureSection, Object.assign({}, props)) },
            ] })));
}
