import React, { useState } from "react";
import styled from "styled-components";
import { DCFileUploader } from "../dc-file-uploader";
import { LogoContainer, RemoveImage, ReplaceLogoContainer, } from "../../../../tools/modal/editor/components/logo-upload-section";
import DeleteIcon from "../../../../../../assets/images/v3/red-trashcan.svg";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import { newTheme } from "../../../../styled/theme";
import { DCIconType } from "../../../../../../../shared/types/demo-center";
import { ColorPicker } from "../../../builder/editor/atomic/color-picker";
import { DCStylesDropdown } from "./dc-styles-dropdown";
export const DCStylesBackgroundEditor = ({ body, onChange }) => {
    const { backgroundColor, backgroundImage } = body;
    const [isChangingBackgroundColor, setIsChangingBackgroundColor] = useState(false);
    return (React.createElement(DCStylesDropdown, { label: "Background", icon: DCIconType.SWATCHES, isCloseOnOutsideClickDisabled: isChangingBackgroundColor },
        React.createElement(DropdownContainer, null,
            React.createElement(BackgroundColorContainer, null,
                React.createElement(Label, null, "Background color"),
                React.createElement(ColorPicker, { color: backgroundColor, onChange: color => onChange(Object.assign(Object.assign({}, body), { backgroundColor: color })), onOpened: () => setIsChangingBackgroundColor(true), onClosed: () => setIsChangingBackgroundColor(false) })),
            React.createElement(DCFileUploader, { showSpinner: true, onUploadComplete: url => onChange(Object.assign(Object.assign({}, body), { backgroundImage: url })) }, backgroundImage ? (React.createElement(BackgroundContainer, null,
                React.createElement("img", { src: backgroundImage, alt: "background Image" }),
                React.createElement(ReplaceBackgroundContainer, null,
                    React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY }, "Replace Background Image")))) : (React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY }, "Add Background Image"))),
            React.createElement(Recommended, null, "Recommended size: 1920 x 980px"),
            backgroundImage && (React.createElement(RemoveImage, { onClick: () => onChange(Object.assign(Object.assign({}, body), { backgroundImage: "" })) },
                React.createElement("img", { src: DeleteIcon }),
                "Remove Background Image")))));
};
const DropdownContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 250px;
`;
const BackgroundColorContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
`;
const Label = styled.div `
  display: flex;
  flex: 1;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey700};
`;
const BackgroundContainer = styled(LogoContainer) `
  width: 180px;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;
const ReplaceBackgroundContainer = styled(ReplaceLogoContainer) `
  width: 180px;
  height: 100px;
`;
const Recommended = styled.span `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
`;
