import { DELETE_CUSTOM_DOMAIN, GET_DOMAIN_DATA, SAVE_CUSTOM_DOMAIN } from "../../actions/legacy-action-types";
const initialState = {
    whiteLabelDomain: "",
    subdomain: "",
};
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DOMAIN_DATA:
            return Object.assign(Object.assign({}, state), { customDomain: action.customDomain, subdomain: action.subdomain, whiteLabelDomain: action.whiteLabelDomain });
        case SAVE_CUSTOM_DOMAIN:
            return Object.assign(Object.assign({}, state), { whiteLabelDomain: action.whiteLabelDomain });
        case DELETE_CUSTOM_DOMAIN:
            return Object.assign(Object.assign({}, state), { whiteLabelDomain: null });
        default:
            return state;
    }
}
