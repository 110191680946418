import React, { useEffect, useState } from "react";
import { useAutoUpdateTool, useSetBuilderFocusField, useUpdateTool, } from "../../../../../redux/selectors/redux-selectors";
import { cloneDeep } from "lodash";
import { withFlexibleToolWrapper } from "../../../../renderer/wrapper/with-flexible-tool-wrapper";
import { WrapperChild } from "../../../../renderer/wrapper/wrapper-child";
import { getBubblePosition, getTriggerPosition, positionEq, } from "../../../../../helpers/tooltip-v2-size-and-position-helpers";
import { TextAlign } from "../../../../../../../shared/types/tool-data-types/common";
import styled from "styled-components";
import { BubbleArrow } from "../subtool-components/bubble-arrow";
import { BubbleBodyText } from "../subtool-components/bubble-body-text";
import { BubbleHeadlineText } from "../subtool-components/bubble-headline-text";
import { useInteraction } from "../../../../../redux/selectors/interaction-selectors";
import { StepCounter } from "../subtool-components/step-counter";
import { TooltipArrowPosition } from "../../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import { BubbleButtonLeft } from "../subtool-components/bubble-button-left";
import { BubbleButtonRight } from "../subtool-components/bubble-button-right";
import { TourialEditorFocusFields } from "../../../../../types/definitions";
import { bubbleAnimationBuilder } from "../../../../../helpers/dom-helpers";
import { BubbleButtonContainer, BubbleButtonWrapper, BubbleHeadlineContainer, BubbleHeadlineLogo, bubbleBorderRadii, bubbleBoxShadow, bubbleContainerBase, } from "../subtool-components/bubble-styled";
import { useStepNumbers } from "../../../../../helpers/tourial-helpers/tourial-viewport-helpers";
export function Bubble(props) {
    const { toolData: tool, viewportDimensions } = props;
    const updateTool = useUpdateTool();
    const [bubblePosition, setBubblePosition] = useState(getBubblePosition(tool, viewportDimensions));
    function drag(position) {
        if (!positionEq(bubblePosition, position)) {
            const t = cloneDeep(tool);
            setBubblePosition(position);
            t.trigger.position = getTriggerPosition(t, viewportDimensions, position);
            updateTool(t);
        }
    }
    return withFlexibleToolWrapper(Inner, {
        isEditing: props.isInEditViewport,
        isSelectable: true,
        isResizable: false,
        isDraggable: true,
        isAspectRatioLocked: false,
        isStaticSize: true,
        shouldShowSelectionTab: true,
    }, {
        bounds: ".tourial-viewport",
        size: tool.bubble.size,
        position: bubblePosition,
    }, {
        onDrag: drag,
        onDragStop: drag,
        onResize: () => { },
        onResizeStop: () => { },
    })(Object.assign(Object.assign({}, props), { bubblePosition, setBubblePosition }));
}
function Inner(props) {
    const { toolData: tool, viewportDimensions, disableAnimations, bubblePosition, setBubblePosition } = props;
    const { arrow, headline, body, previousButton, nextButton, stepCounter, background, logo, buttonAlign } = tool.bubble;
    const previousButtonOnClick = useInteraction(props, previousButton.interaction);
    const nextButtonOnClick = useInteraction(props, nextButton.interaction);
    const setBuilderFocusField = useSetBuilderFocusField();
    const id = `${tool.id}-bubble`;
    const [hasBubbleInitiallyResized, setHasBubbleInitiallyResized] = useState(false);
    const autoUpdateTool = useAutoUpdateTool();
    const { currentStepNumber, totalStepCount } = useStepNumbers();
    useEffect(() => {
        var _a;
        const height = (_a = document === null || document === void 0 ? void 0 : document.getElementById(id)) === null || _a === void 0 ? void 0 : _a.clientHeight;
        if (height && height !== tool.bubble.size.height) {
            const next = cloneDeep(tool);
            next.bubble.size.height = height;
            setBubblePosition(getBubblePosition(next, viewportDimensions));
            autoUpdateTool(next);
        }
    });
    useEffect(() => {
        setBubblePosition(getBubblePosition(tool, viewportDimensions));
    }, [
        viewportDimensions,
        tool.trigger.position,
        tool.trigger.clickzone.size,
        tool.trigger.triggerComponent,
        tool.bubble.arrow,
    ]);
    useEffect(() => {
        let isMounted = true;
        const readyHandler = () => {
            setHasBubbleInitiallyResized(true);
        };
        void document.fonts.ready.then(() => {
            if (isMounted)
                readyHandler();
        });
        return () => {
            isMounted = false;
        };
    }, []);
    const getAnimationDirection = (arrowPosition) => {
        switch (arrowPosition) {
            case TooltipArrowPosition.BOTTOM_CENTER:
            case TooltipArrowPosition.BOTTOM_LEFT:
            case TooltipArrowPosition.BOTTOM_RIGHT:
                return "pop-in-bottom";
            case TooltipArrowPosition.TOP_CENTER:
            case TooltipArrowPosition.TOP_LEFT:
            case TooltipArrowPosition.TOP_RIGHT:
                return "pop-in-top";
            case TooltipArrowPosition.LEFT_BOTTOM:
            case TooltipArrowPosition.LEFT_CENTER:
            case TooltipArrowPosition.LEFT_TOP:
                return "pop-in-left";
            case TooltipArrowPosition.RIGHT_BOTTOM:
            case TooltipArrowPosition.RIGHT_CENTER:
            case TooltipArrowPosition.RIGHT_TOP:
                return "pop-in-right";
        }
    };
    const SC = ({ style }) => {
        return (React.createElement(StepCounter, { color: stepCounter.color, currentStepNumber: currentStepNumber, totalStepCount: totalStepCount, style: style }));
    };
    return (React.createElement(BubbleContainer, { id: id, disableAnimations: disableAnimations, animationDirection: getAnimationDirection(arrow.position), style: {
            visibility: !hasBubbleInitiallyResized ? "hidden" : "inherit",
            borderColor: background.borderColor,
            backgroundColor: background.backgroundColor,
            borderRadius: bubbleBorderRadii[tool.bubble.background.shape],
            boxShadow: background.isShadowEnabled ? bubbleBoxShadow : "none",
        } },
        arrow.isEnabled && React.createElement(BubbleArrow, Object.assign({}, Object.assign(Object.assign({}, props), { bubblePosition }))),
        (logo.isEnabled || headline.isEnabled) && (React.createElement(BubbleHeadlineContainer, { config: headline },
            logo.isEnabled && logo.src && (React.createElement(BubbleHeadlineLogo, { shape: logo.shape, style: { marginRight: headline.isEnabled ? 8 : 0 } },
                React.createElement("img", { src: logo.src }))),
            headline.isEnabled && (React.createElement(BubbleHeadlineText, { headline: headline, viewportDimensions: viewportDimensions, onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.BUBBLE_HEADLINE_TEXT) })))),
        body.isEnabled && (React.createElement(BubbleBodyText, { body: body, viewportDimensions: viewportDimensions, onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.BUBBLE_BODY_TEXT) })),
        (previousButton.isEnabled || nextButton.isEnabled || stepCounter.isEnabled) && (React.createElement(BubbleButtonContainer, { buttonAlign: buttonAlign },
            stepCounter.isEnabled && buttonAlign === TextAlign.RIGHT && (React.createElement(SC, { style: { flex: 1, justifyContent: "left" } })),
            (stepCounter.isEnabled || previousButton.isEnabled) && (React.createElement(BubbleButtonWrapper, { shouldFillSpace: stepCounter.isEnabled && buttonAlign === TextAlign.CENTER, isButtonEnabled: previousButton.isEnabled, style: { justifyContent: "flex-start" } },
                React.createElement(BubbleButtonLeft, { onClick: () => previousButtonOnClick(), body: body, button: previousButton, onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.BUBBLE_PREVIOUS_BUTTON_TEXT) }))),
            stepCounter.isEnabled && buttonAlign === TextAlign.CENTER && (React.createElement(SC, { style: { flex: !nextButton.isEnabled && !previousButton.isEnabled ? 1 : 0 } })),
            (stepCounter.isEnabled || nextButton.isEnabled) && (React.createElement(BubbleButtonWrapper, { shouldFillSpace: (stepCounter.isEnabled && buttonAlign === TextAlign.CENTER) ||
                    (!stepCounter.isEnabled && !previousButton.isEnabled), isButtonEnabled: nextButton.isEnabled, style: { justifyContent: buttonAlign === TextAlign.LEFT ? "flex-start" : "flex-end" } },
                React.createElement(BubbleButtonRight, { onClick: () => nextButtonOnClick(), body: body, button: nextButton, onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.BUBBLE_NEXT_BUTTON_TEXT) }))),
            stepCounter.isEnabled && buttonAlign === TextAlign.LEFT && (React.createElement(SC, { style: { flex: 1, justifyContent: "right" } }))))));
}
const BubbleContainer = styled(WrapperChild) `
  ${bubbleContainerBase}
  animation: ${props => (props.disableAnimations ? "none" : bubbleAnimationBuilder(props.animationDirection))};
  > *:not(.bubble-arrow) {
    position: relative;
    z-index: 1;
  }
`;
