import { TDropdownSelect } from "../../../../styled/t-dropdown-select";
import { InteractionName, InteractionType } from "../../../../../../../shared/types/tool-data-types/interactions";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { usePages, useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { getAllPagesAsActiveViews, getAllStepsAsActiveViews, } from "../../../../../helpers/tourial-helpers/tourial-viewport-helpers";
import { TextInput } from "../../../../pages/builder/editor/atomic/text-input";
export function TriggerInteractionSelector({ tool }) {
    var _a, _b;
    const pages = usePages();
    const updateTool = useUpdateTool();
    const [stepOptions, setStepOptions] = useState(getTertiarySelectorOptions(tool.trigger.interaction.name, pages));
    useEffect(() => {
        setStepOptions(getTertiarySelectorOptions(tool.trigger.interaction.name, pages));
    }, [tool.trigger.interaction.name]);
    return (React.createElement(Div, null,
        React.createElement(InteractionSelectorContainer, null,
            React.createElement(TDropdownSelect, { isSearchable: false, style: { width: "40%" }, selectedValue: tool.trigger.interaction.type, onChange: (e) => {
                    const t = cloneDeep(tool);
                    t.trigger.interaction.type = e.value;
                    const opts = getInteractionNameOptions(t);
                    if (!opts.find(o => o.value === t.trigger.interaction.name)) {
                        t.trigger.interaction.name = opts[0].value;
                    }
                    updateTool(t);
                }, options: [
                    { label: "On hover", value: InteractionType.HOVER },
                    { label: "On click", value: InteractionType.CLICK },
                ] }),
            React.createElement(TDropdownSelect, { isSearchable: false, style: { width: "55%" }, selectedValue: tool.trigger.interaction.name, onChange: (e) => {
                    const t = cloneDeep(tool);
                    t.trigger.interaction.name = e.value;
                    updateTool(t);
                }, options: getInteractionNameOptions(tool) })),
        getTertiaryInteractionSelectorComponentType(tool.trigger.interaction.name) === "selector" && (React.createElement(TDropdownSelect, { isSearchable: true, options: stepOptions, selectedValue: (_a = stepOptions.find(so => {
                var _a, _b, _c, _d;
                return so.value.pageId === ((_b = (_a = tool.trigger.interaction.target) === null || _a === void 0 ? void 0 : _a.targetView) === null || _b === void 0 ? void 0 : _b.pageId) &&
                    so.value.zoomId === ((_d = (_c = tool.trigger.interaction.target) === null || _c === void 0 ? void 0 : _c.targetView) === null || _d === void 0 ? void 0 : _d.zoomId);
            })) === null || _a === void 0 ? void 0 : _a.value, onChange: (e) => {
                const t = cloneDeep(tool);
                t.trigger.interaction.target = { targetView: e.value };
                updateTool(t);
            } })),
        getTertiaryInteractionSelectorComponentType(tool.trigger.interaction.name) === "input" && (React.createElement(TextInput, { placeholder: "https://", value: ((_b = tool.trigger.interaction.target) === null || _b === void 0 ? void 0 : _b.targetExternalLink) || "", onChange: (e) => {
                const t = cloneDeep(tool);
                t.trigger.interaction.target = { targetExternalLink: e.target.value };
                updateTool(t);
            } }))));
}
const Div = styled.div `
  width: 100%;
`;
const InteractionSelectorContainer = styled.div `
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export function getTertiarySelectorOptions(interactionName, pages) {
    function viewsToSteps(views) {
        return views.map(view => ({ label: `${view.pageName} - ${view.zoomName}`, value: view }));
    }
    switch (interactionName) {
        case InteractionName.JUMP_TO_STEP:
            return viewsToSteps(getAllStepsAsActiveViews(pages));
        case InteractionName.JUMP_TO_PAGE:
            return viewsToSteps(getAllPagesAsActiveViews(pages));
        default:
            return [];
    }
}
export function getTertiaryInteractionSelectorComponentType(interactionName) {
    switch (interactionName) {
        case InteractionName.EXTERNAL_LINK:
            return "input";
        case InteractionName.JUMP_TO_PAGE:
        case InteractionName.JUMP_TO_STEP:
            return "selector";
        case InteractionName.TRIGGER_FORM:
            return "form";
        default:
            return "none";
    }
}
function getInteractionNameOptions(tool) {
    const hoverBaseOptions = [
        { label: "Next step", value: InteractionName.NEXT_STEP },
        { label: "Previous step", value: InteractionName.PREVIOUS_STEP },
        { label: "Jump to step", value: InteractionName.JUMP_TO_STEP },
    ];
    const hoverBubbleOptions = [
        { label: "Toggle tooltip", value: InteractionName.TOGGLE_TOOLTIP_BUBBLE_AND_SPOTLIGHT },
        { label: "Show tooltip", value: InteractionName.SHOW_TOOLTIP_BUBBLE_AND_SPOTLIGHT },
    ];
    const clickBaseOptions = [
        ...hoverBaseOptions,
        { label: "Next page", value: InteractionName.NEXT_PAGE },
        { label: "Jump to page", value: InteractionName.JUMP_TO_PAGE },
        { label: "External link", value: InteractionName.EXTERNAL_LINK },
    ];
    const clickBubbleOptions = [
        ...hoverBubbleOptions,
        { label: "Hide tooltip", value: InteractionName.HIDE_TOOLTIP_BUBBLE_AND_SPOTLIGHT },
    ];
    if (tool.trigger.interaction.type === InteractionType.HOVER) {
        if (tool.bubble.isEnabled) {
            return [...hoverBaseOptions, ...hoverBubbleOptions];
        }
        else {
            return hoverBaseOptions;
        }
    }
    else if (tool.trigger.interaction.type === InteractionType.CLICK) {
        if (tool.bubble.isEnabled) {
            return [...clickBaseOptions, ...clickBubbleOptions];
        }
        else {
            return clickBaseOptions;
        }
    }
}
