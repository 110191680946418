import React, { useState } from "react";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
export default function TA({ children, style, href, onClick, target }) {
    const baseStyle = Object.assign(Object.assign(Object.assign({}, theme.typography.a), { color: theme.colors.cobalt.medium, textDecoration: "underline", cursor: "pointer" }), style);
    const hoverStyle = Object.assign(Object.assign({}, baseStyle), { color: theme.colors.cobalt.light });
    const activeStyle = Object.assign(Object.assign({}, baseStyle), { color: theme.colors.gold.dark });
    const [currentStyle, setCurrentStyle] = useState(baseStyle);
    return (React.createElement("a", { onClick: e => {
            if (onClick) {
                e.preventDefault();
                onClick();
                window.location.replace(href);
            }
        }, style: currentStyle, href: onClick ? null : href, onMouseDown: () => setCurrentStyle(activeStyle), onMouseUp: () => setCurrentStyle(baseStyle), onMouseEnter: () => setCurrentStyle(hoverStyle), onMouseLeave: () => setCurrentStyle(baseStyle), target: target }, children));
}
