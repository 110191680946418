import React from "react";
import styled from "styled-components";
import { TDropdownSelect } from "../../../../styled/t-dropdown-select";
import { newTheme } from "../../../../styled/theme";
export const DCCMDropdownSelect = ({ title, options, selectedValue, onChange }) => {
    return (React.createElement(Editor, null,
        React.createElement(Title, null, title),
        React.createElement(TDropdownSelect, { options: options, selectedValue: selectedValue, onChange: onChange, isSearchable: false })));
};
const Title = styled.div `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
  margin-bottom: 4px;
`;
const Editor = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
`;
