import { ViewportMode } from "../../types/definitions";
import { getActiveVariant, getActiveVariantName } from "../redux";
import { getDefaultVisibleToolIds } from "../../redux/reducers/tourial-root-reducer";
import { ActiveVariantName } from "../../../../shared/types/tool-data-types/common";
import { useActiveView, usePages } from "../../redux/selectors/redux-selectors";
import { isImageFileExtension } from "../media";
import { getVideoThumbnail, transformImageToWebp } from "../../../../shared/functions/helpers";
import { getBaseMedia } from "../video-trimming";
export function getAllStepsAsActiveViews(pages) {
    const steps = [];
    for (const p of pages) {
        for (const z of p.zooms) {
            steps.push({ pageId: p.id, zoomId: z.id, pageName: p.name, zoomName: z.name });
        }
    }
    return steps;
}
export function getAllPagesAsActiveViews(pages) {
    const steps = [];
    for (const p of pages) {
        steps.push({ pageId: p.id, zoomId: p.zooms[0].id, pageName: p.name, zoomName: p.zooms[0].name });
    }
    return steps;
}
export function getNextStep(pages, currentStep, loop = false) {
    const pi = pages.findIndex(p => p.id === currentStep.pageId);
    const p = pages[pi];
    const zi = p.zooms.findIndex(z => z.id === currentStep.zoomId);
    if (pi === -1 || zi === -1) {
        console.error("getNextStep could not find current zoom or page index");
        return currentStep;
    }
    const isLastZoom = zi >= p.zooms.length - 1;
    const isLastPage = pi >= pages.length - 1;
    if (isLastZoom && isLastPage) {
        return loop ? { pageId: pages[0].id, zoomId: "MAIN" } : currentStep;
    }
    if (isLastZoom)
        return { pageId: pages[pi + 1].id, zoomId: pages[pi + 1].zooms[0].id };
    return { pageId: p.id, zoomId: p.zooms[zi + 1].id };
}
export function getPreviousStep(pages, currentStep, skipAutomated = true
// mode?: ViewportMode
) {
    const pi = pages.findIndex(p => p.id === currentStep.pageId);
    const p = pages[pi];
    const zi = p.zooms.findIndex(z => z.id === currentStep.zoomId);
    /** checks for the closest previous interactive page OR start of tour */
    function checkIfViewIsInteractiveOrStart(pageIndex, zoomIndex) {
        const p = pages[pageIndex];
        const pp = pages[pageIndex - 1];
        const z = p.zooms[zoomIndex];
        // handle if an automated page is encountered
        if (p.isAutomated) {
            // if this is the first page, skip to the beginning zoom
            if (pageIndex === 0) {
                return { pageId: p.id, zoomId: "MAIN" };
            }
            // otherwise repeat process from the last zoom of the next earlier page
            return checkIfViewIsInteractiveOrStart(pageIndex - 1, pp.zooms.length - 1);
        }
        // otherwise we have reached an interactive view
        return { pageId: p.id, zoomId: z.id };
    }
    if (pi === -1 || zi === -1) {
        console.error("getPreviousStep could not find current zoom or page index");
        return currentStep;
    }
    const isFirstZoom = zi === 0;
    const isFirstPage = pi === 0;
    // do nothing if called from start of tour
    if (isFirstZoom && isFirstPage) {
        return currentStep;
    }
    // this case is no longer achievable, autuStartZoomId can only be set to MAIN in the current ui.
    if (
    // mode !== ViewportMode.EDIT &&
    p.autoStartZoomId === currentStep.zoomId &&
        !isFirstZoom &&
        p.zooms[zi - 1].id === "MAIN") {
        console.error("getPreviousStep would auto zoom back to currentStep");
        return currentStep;
    }
    if (isFirstZoom) {
        const pp = pages[pi - 1];
        if (pp.isAutomated && skipAutomated) {
            return checkIfViewIsInteractiveOrStart(pi - 1, pp.zooms.length - 1);
        }
        return {
            pageId: pp.id,
            zoomId: pp.zooms[pp.zooms.length - 1].id,
        };
    }
    return { pageId: p.id, zoomId: p.zooms[zi - 1].id };
}
export function getNextScreen(pages, currentStep) {
    const pi = pages.findIndex(p => p.id === currentStep.pageId);
    if (pi === -1) {
        console.error("getNextScreen could not find current screen");
        return currentStep;
    }
    const nextPage = pages[pi + 1];
    if (!nextPage) {
        console.error("getNextScreen interaction triggered from the final screen");
        return currentStep;
    }
    return { pageId: nextPage.id, zoomId: nextPage.zooms[0].id };
}
export function getPreviousScreen(pages, currentStep) {
    const pi = pages.findIndex(p => p.id === currentStep.pageId);
    if (pi === -1) {
        console.error("getPreviousScreen could not find current screen");
        return currentStep;
    }
    const nextPage = pages[pi - 1];
    if (!nextPage) {
        console.error("getPreviousScreen interaction triggered from the first screen");
        return currentStep;
    }
    function checkIfPageIsInteractiveOrStart(pageIndex) {
        const p = pages[pageIndex];
        if (p.isAutomated && pageIndex !== 0) {
            return checkIfPageIsInteractiveOrStart(pageIndex - 1);
        }
        return { pageId: p.id, zoomId: "MAIN" };
    }
    if (nextPage.isAutomated) {
        return checkIfPageIsInteractiveOrStart(pi - 1);
    }
    return { pageId: nextPage.id, zoomId: "MAIN" };
}
// legacy
export function getNextViewportState(state, nextStep) {
    const { viewport, tourial } = state;
    const activeVariantName = getActiveVariantName(state);
    return Object.assign(Object.assign({}, viewport), { previousView: viewport.activeView, activeView: nextStep, visibleToolIds: getDefaultVisibleToolIds(tourial[activeVariantName].viewTools, nextStep, viewport.mode), isTransitioning: legacyShouldTransition(tourial[activeVariantName], viewport.mode, viewport.activeView, nextStep, false, 3) });
}
export function findZoom(tourialVariant, pageId, zoomId) {
    return tourialVariant.pages.find(p => p.id === pageId).zooms.find(z => z.id === zoomId);
}
export function legacyShouldTransition(tourialVariant, mode, prevView, currentView, triggeredFromAutostart, version) {
    if (mode === ViewportMode.EDIT)
        return false;
    if (!prevView)
        return false;
    const prevZoom = findZoom(tourialVariant, prevView.pageId, prevView.zoomId);
    const nextZoom = findZoom(tourialVariant, currentView.pageId, currentView.zoomId);
    const hasAutoStart = !!tourialVariant.pages[tourialVariant.pages.findIndex(p => p.id === prevView.pageId)].autoStartZoomId;
    if (!prevZoom || !nextZoom)
        return false;
    // did not change page and main view points back to the old view
    // in v3, transition in preview regardless of whether you toggled the mode on main
    if (version !== 3 && prevView.pageId === currentView.pageId && currentView.zoomId === "MAIN" && hasAutoStart)
        return false;
    // auto-started zoom with non-0 transition duration
    if (triggeredFromAutostart && (nextZoom.transition.durationSecs > 0 || nextZoom.transition.delaySecs > 0))
        return true;
    // did not change view
    if (prevView.pageId === currentView.pageId && currentView.zoomId === prevView.zoomId)
        return false;
    // changed page
    if (prevView.pageId !== currentView.pageId)
        return false;
    // no transition times
    if (nextZoom.transition.durationSecs <= 0 && nextZoom.transition.delaySecs <= 0)
        return false;
    // zoom positions are identical
    return nextZoom.x !== prevZoom.x || nextZoom.y !== prevZoom.y || nextZoom.z !== prevZoom.z;
}
export function getDimensionToGrow(width, height, activeVariantName) {
    if (!width || !height)
        return;
    const isDesktop = activeVariantName !== ActiveVariantName.VARIANT_MOBILE;
    const perfectRatio = isDesktop ? 9 / 16 : 16 / 9;
    const imgAspectRatio = height / width;
    function isWithinMarginOfError() {
        const marginOfError = 0.005;
        return imgAspectRatio >= perfectRatio - marginOfError && imgAspectRatio <= perfectRatio + marginOfError;
    }
    if (isWithinMarginOfError())
        return "xy";
    return imgAspectRatio > perfectRatio ? "x" : "y";
}
export const useStepNumbers = () => {
    const { pageId: activePageId, zoomId: activeZoomId } = useActiveView();
    const pages = usePages();
    const orderedZooms = pages.reduce((result, page) => {
        // exclude automated steps from count
        if (page.isAutomated)
            return result;
        return [
            ...result,
            ...page.zooms.map(z => {
                return { pageId: page.id, zoomId: z.id };
            }),
        ];
    }, []);
    return {
        currentStepNumber: orderedZooms.findIndex(zoom => zoom.zoomId === activeZoomId && zoom.pageId === activePageId) + 1,
        totalStepCount: orderedZooms.length,
    };
};
export const getScreenTransform = ({ activeZoom, vph, vpw, srcSize, dimensionToGrow, }) => {
    if (!srcSize)
        return;
    if (dimensionToGrow === "x") {
        const offsetWidth = (vpw * (activeZoom === null || activeZoom === void 0 ? void 0 : activeZoom.z) - vpw) / 2;
        const offsetHeight = (vpw * (srcSize.height / srcSize.width) * (activeZoom === null || activeZoom === void 0 ? void 0 : activeZoom.z) - vph) / 2;
        return `translate(${offsetWidth * (activeZoom.x / 100)}px, ${offsetHeight * (activeZoom.y / 100)}px) scale(${activeZoom === null || activeZoom === void 0 ? void 0 : activeZoom.z})`;
    }
    else if (dimensionToGrow === "y") {
        const offsetHeight = (vph * (activeZoom === null || activeZoom === void 0 ? void 0 : activeZoom.z) - vph) / 2;
        const offsetWidth = (vph * (srcSize.width / srcSize.height) * (activeZoom === null || activeZoom === void 0 ? void 0 : activeZoom.z) - vpw) / 2;
        return `translate(${offsetWidth * (activeZoom.x / 100)}px, ${offsetHeight * (activeZoom.y / 100)}px) scale(${activeZoom === null || activeZoom === void 0 ? void 0 : activeZoom.z})`;
    }
    else if (dimensionToGrow === "xy") {
        const offsetWidth = (vpw * (activeZoom === null || activeZoom === void 0 ? void 0 : activeZoom.z) - vpw) / 2;
        const offsetHeight = (vph * (activeZoom === null || activeZoom === void 0 ? void 0 : activeZoom.z) - vph) / 2;
        return `translate(${offsetWidth * (activeZoom.x / 100)}px, ${offsetHeight * (activeZoom.y / 100)}px) scale(${activeZoom === null || activeZoom === void 0 ? void 0 : activeZoom.z})`;
    }
};
export function shouldAutoStart(prevState, nextStep, initialRender) {
    const { mode: viewportMode } = prevState.viewport;
    const variant = getActiveVariant(prevState);
    const nextPage = variant.pages.find(p => p.id === nextStep.pageId);
    // const prevZoom = findZoom(variant, prevState.viewport.activeView.pageId, prevState.viewport.activeView.zoomId);
    const nextZoom = findZoom(variant, nextStep.pageId, nextStep.zoomId);
    const pageHasAutoStart = nextZoom.id === "MAIN" && nextPage.autoStartZoomId === "MAIN";
    const isChangingPage = prevState.viewport.activeView.pageId !== nextStep.pageId;
    const zoomExists = nextZoom.transition;
    const zoomHasDuration = !!nextZoom.transition.delaySecs || !!nextZoom.transition.durationSecs;
    // const zoomPositionsAreEqual = prevZoom
    //   ? nextZoom.x === prevZoom.x && nextZoom.y === prevZoom.y && nextZoom.z === prevZoom.z
    //   : false;
    return (viewportMode !== ViewportMode.EDIT &&
        nextZoom &&
        pageHasAutoStart &&
        zoomExists &&
        zoomHasDuration &&
        (initialRender || isChangingPage));
}
export function shouldTransition(prevState, nextStep) {
    var _a;
    const { tourial, viewport } = prevState;
    const { mode: viewportMode, activeView: { pageId }, } = viewport;
    const willAutoStart = shouldAutoStart(prevState, nextStep);
    const variant = getActiveVariant(prevState);
    const prevZoom = findZoom(variant, viewport.activeView.pageId, viewport.activeView.zoomId);
    const nextZoom = findZoom(variant, nextStep.pageId, nextStep.zoomId);
    const isChangingPage = viewport.activeView.pageId !== nextStep.pageId;
    const zoomExists = nextZoom.transition;
    const zoomHasDuration = !!nextZoom.transition.delaySecs || !!nextZoom.transition.durationSecs;
    let preventTransitionForIdealAspectRatioWithoutZoom = false;
    const activeVariantName = getActiveVariantName(prevState);
    if ((prevZoom === null || prevZoom === void 0 ? void 0 : prevZoom.z) === 1 && nextZoom.z === 1 && !isChangingPage) {
        const screenMedia = tourial[activeVariantName].tools.screen.media;
        const srcSize = (_a = getBaseMedia(tourial.media, screenMedia[pageId])) === null || _a === void 0 ? void 0 : _a.size;
        const dimensionToGrow = getDimensionToGrow(srcSize === null || srcSize === void 0 ? void 0 : srcSize.width, srcSize === null || srcSize === void 0 ? void 0 : srcSize.height, activeVariantName);
        if (dimensionToGrow === "xy")
            preventTransitionForIdealAspectRatioWithoutZoom = true;
    }
    const zoomPositionsAreEqual = prevZoom
        ? nextZoom.x === prevZoom.x && nextZoom.y === prevZoom.y && nextZoom.z === prevZoom.z
        : false;
    return (viewportMode !== ViewportMode.EDIT &&
        !willAutoStart &&
        !isChangingPage &&
        nextZoom &&
        prevZoom &&
        zoomExists &&
        zoomHasDuration &&
        !zoomPositionsAreEqual &&
        !preventTransitionForIdealAspectRatioWithoutZoom);
}
export function getPrevSrcData(vph, vpw, screenMedia, tourialMedia, activeVariantName, prevZoom, prevStep) {
    var _a;
    try {
        let prevSrcAsImageOrThumbnail;
        let prevTransform;
        let prevDimensionToGrowStyle;
        if (prevStep) {
            const prevSrc = screenMedia[prevStep.pageId];
            const isImage = isImageFileExtension(prevSrc);
            prevSrcAsImageOrThumbnail = isImage ? transformImageToWebp(prevSrc) : getVideoThumbnail(prevSrc);
            const prevSrcSize = ((_a = getBaseMedia(tourialMedia, prevSrc)) === null || _a === void 0 ? void 0 : _a.size) || { width: 0, height: 0 };
            const prevDimensionToGrow = getDimensionToGrow(prevSrcSize.width, prevSrcSize.height, activeVariantName);
            prevDimensionToGrowStyle = Object.assign(Object.assign({}, ((prevDimensionToGrow === null || prevDimensionToGrow === void 0 ? void 0 : prevDimensionToGrow.includes("x")) && { width: "100.01%" })), ((prevDimensionToGrow === null || prevDimensionToGrow === void 0 ? void 0 : prevDimensionToGrow.includes("y")) && { height: "100.01%" }));
            prevTransform = getScreenTransform({
                vph,
                vpw,
                dimensionToGrow: prevDimensionToGrow,
                srcSize: prevSrcSize,
                activeZoom: prevZoom,
            });
        }
        return { prevTransform, prevSrcAsImageOrThumbnail, prevDimensionToGrowStyle };
    }
    catch (_b) {
        return;
    }
}
