import styled from "styled-components";
import { newTheme } from "./theme";
// These are used in the thumbnail components
export const thumbnailWidth = 271;
export const thumbnailHeight = 152.4375;
export const mobileThumbnailWidth = thumbnailHeight * 0.5625;
export const AdditionalControls = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${newTheme.colors.grey700};
  font: ${newTheme.fonts.normal};
`;
export const ControlContainer = styled.div `
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 32px;
  align-items: center;
`;
export const ControlLabel = styled.div `
  font: ${newTheme.fonts.normal};
  color: ${newTheme.colors.grey700};
  flex: 1;
`;
export const FontDropdownContainer = styled.div `
  display: flex;
  margin-top: -8px;
`;
export const TextModificationContainer = styled.div `
  padding-top: 8px;
  display: flex;
`;
export const QuillContainer = styled.div `
  margin-bottom: 8px;
  .ql-toolbar {
    border-radius: 4px 4px 0 0 !important;
    border-color: ${newTheme.colors.grey600} !important;
  }
  .ql-container {
    border-radius: 0 0 4px 4px !important;
    border-color: ${newTheme.colors.grey600} !important;
  }
`;
