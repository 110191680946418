import React, { useState } from "react";
import TButton, { TButtonVariants } from "../../styled/t-button";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import TModal, { TModalButtonsContainer } from "../../styled/t-modal";
import { newTheme } from "../../styled/theme";
import { ToastModes } from "../../../types/definitions";
import TInput from "../../styled/t-input";
import { useHistory } from "react-router-dom";
import { useAddToast } from "../../../legacy/selectors";
import { appendParam } from "../../../../../shared/functions/helpers";
import { TToggleSwitch } from "../../styled/t-toggle-switch";
import { useAsync } from "react-async";
import { uniqBy } from "lodash";
import { ContentContainer, PRIVATE_MESSAGE, PUBLIC_MESSAGE, PublicToggleContainer, UsersSection, } from "./workspace-create-modal";
import { AccessLevel } from "../../../../../shared/types/auth-types";
export default function EditOrDeleteWorkspace({ workspace, onClose, onDelete }) {
    const history = useHistory();
    const [newWorkspaceName, setNewWorkspaceName] = useState(workspace.name);
    const [isPublic, setIsPublic] = useState(workspace.isPublic);
    const [selectedUsers, setSelectedUsers] = useState([]); // temporarily selected items in dropdown list
    const [addedUsers, setAddedUsers] = useState((workspace.userEmails || []).map(email => ({ label: email, value: email }))); // confirmed items
    const { data } = useAsync({
        promiseFn: TourialApiCalls.Users.getAllAccountUsers,
        onResolve(data) {
            if (data) {
                const defaultUsers = [];
                data.forEach(user => {
                    if (user.userAccess === AccessLevel.ADMIN) {
                        defaultUsers.push({ label: user.email, value: user.email });
                    }
                });
                setAddedUsers(uniqBy([...defaultUsers, ...addedUsers], "value"));
            }
        },
    });
    const dropdownOptions = (data === null || data === void 0 ? void 0 : data.map(user => ({
        label: user.email,
        value: user.email,
    }))) || [];
    const addToast = useAddToast();
    return (React.createElement(TModal, { title: "Edit or Delete Workspace", onClose: onClose },
        React.createElement(React.Fragment, null,
            React.createElement(ContentContainer, null,
                React.createElement("div", null,
                    React.createElement("div", { style: { marginBottom: 4 } }, "Edit name"),
                    React.createElement(TInput, { style: { width: "100%" }, placeholder: "Workspace name", value: newWorkspaceName, onChange: e => setNewWorkspaceName(e.target.value) })),
                React.createElement("div", { style: { display: "flex", justifyContent: "space-between", alignItems: "center" } },
                    React.createElement(PublicToggleContainer, null,
                        React.createElement("div", null, "Public"),
                        React.createElement(TToggleSwitch, { enabled: isPublic, size: "small", onChange: () => setIsPublic(!isPublic) })),
                    React.createElement("div", { style: { color: newTheme.colors.grey600, font: newTheme.fonts.small } }, isPublic ? PUBLIC_MESSAGE : PRIVATE_MESSAGE)),
                !isPublic &&
                    UsersSection({ addedUsers, data, setSelectedUsers, selectedUsers, dropdownOptions, setAddedUsers })),
            React.createElement(TModalButtonsContainer, { style: { position: "relative" } },
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: onClose }, "Cancel"),
                React.createElement(TButton, { variant: TButtonVariants.DEFAULT, disabled: !newWorkspaceName, onClick: () => {
                        TourialApiCalls.Workspaces.updateWorkspace(workspace._id, newWorkspaceName, isPublic ? [] : addedUsers.map(user => user.value), isPublic)
                            .then(() => {
                            addToast({ message: "Workspace name updated successfully", mode: ToastModes.SUCCESS });
                            appendParam(history, "reload-list", true);
                            onClose();
                        })
                            .catch(e => {
                            console.error(e);
                            addToast({ message: "Update workspace name failed", mode: ToastModes.ERROR });
                        });
                    } }, "Update"),
                React.createElement(TButton, { style: { position: "absolute", color: newTheme.colors.red500, left: 24 }, variant: TButtonVariants.OUTLINE, onClick: () => {
                        onDelete();
                        onClose();
                    } }, "Delete Workspace")))));
}
