import React from "react";
import styled from "styled-components";
import TModal, { TModalButtonsContainer } from "../../../../styled/t-modal";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import { newTheme } from "../../../../styled/theme";
import { useDCBuilderWarnings } from "../../../../../redux/selectors/demo-center-selectors";
import { dcIsPathABuilderCMPage } from "../../helpers/dc-helpers";
import { useDCModules } from "../../hooks/dc-module-hooks";
import { TMessageBox, TMessageBoxVariants } from "../../../../styled/t-message-box";
export const DCPublishOrDiscardModal = ({ title, message, close, confirm, confirmText, confirmVariant, showWarnings, }) => {
    const warnings = useDCBuilderWarnings();
    const hasWarnings = warnings.length > 0 && showWarnings;
    const modules = useDCModules();
    const getModuleTitle = (key) => {
        const module = modules.find((m) => key.startsWith(m.slug));
        return module.title;
    };
    const Warning = ({ warning }) => {
        const { key, message } = warning;
        const title = dcIsPathABuilderCMPage(key) ? key : getModuleTitle(key);
        return (React.createElement(TMessageBox, { variant: TMessageBoxVariants.WARNING, size: "medium", message: `${title}: ${message}`, style: { width: "100%" } }));
    };
    return (React.createElement(TModal, { title: title, onClose: close },
        React.createElement(React.Fragment, null,
            React.createElement(Span, null, hasWarnings ? "You must fix the following issues before you can publish:" : message),
            hasWarnings && (React.createElement(WarningList, null, warnings.map(w => (React.createElement(Warning, { key: w.key, warning: w }))))),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: close }, "Cancel"),
                React.createElement(TButton, { variant: confirmVariant, onClick: confirm, disabled: hasWarnings }, confirmText || "Publish")))));
};
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  width: 100%;
  height: 68px;
`;
const WarningList = styled.div `
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
