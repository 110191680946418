import React from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import TooltipIcon from "../../../../../../assets/images/tooltip-icon.svg";
import HotspotIcon from "../../../../../../assets/images/hotspot-icon.svg";
import ClickzoneIcon from "../../../../../../assets/images/clickzone-icon.svg";
import NoToolIcon from "../../../../../../assets/images/v3/no-tool-icon.svg";
import ModalIcon from "../../../../../../assets/images/v3/modal-toolpanel-icon.svg";
export var ToolTypes;
(function (ToolTypes) {
    ToolTypes["TOOLTIP"] = "TOOLTIP";
    ToolTypes["HOTSPOT"] = "HOTSPOT";
    ToolTypes["CLICKZONE"] = "CLICKZONE";
    ToolTypes["MODAL"] = "MODAL";
    ToolTypes["NO_TOOL"] = "NO_TOOL";
})(ToolTypes || (ToolTypes = {}));
const icons = {
    [ToolTypes.TOOLTIP]: TooltipIcon,
    [ToolTypes.HOTSPOT]: HotspotIcon,
    [ToolTypes.CLICKZONE]: ClickzoneIcon,
    [ToolTypes.MODAL]: ModalIcon,
    [ToolTypes.NO_TOOL]: NoToolIcon,
};
export const ToolPanelIcon = (props) => {
    const { type, subType, storyboard, isAutomated, isDragging } = props;
    return (React.createElement(ToolPanelIconContainer, { className: type, storyboard: storyboard, draggable: "true", isAutomated: isAutomated, isDragging: isDragging },
        React.createElement("img", { src: icons[type], draggable: false }),
        subType && (React.createElement(InnerToolIcon, { className: `${subType}` }, typeof subType === "number" ? subType : React.createElement("img", { src: icons[subType], draggable: false })))));
};
const ToolPanelIconContainer = styled.div `
  background-color: ${newTheme.colors.white};
  border: 1px solid ${newTheme.colors.grey400};
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & img {
    width: 20px;
    height: 20px;
  }
  &.HOTSPOT {
    border-radius: 20px;
  }
  cursor: pointer;
  ${props => typeof props.storyboard === "boolean"
    ? props.storyboard
        ? `
          cursor: grab;
          ${props.isDragging
            ? `
            border: 1px solid ${newTheme.colors[props.isAutomated ? "yellow500" : "blue400"]};
            box-shadow: ${newTheme.shadows.outside80};
          `
            : `
            &:hover { 
            border: 1px solid ${newTheme.colors[props.isAutomated ? "yellow400" : "blue300"]};
            box-shadow: ${newTheme.shadows.outside60};
            }
          `}
        `
        : ""
    : `&:hover {
        background-color: ${newTheme.colors.blue100};
        border: 1px solid ${newTheme.colors.blue300};

        >div {
          background-color: ${newTheme.colors.blue100};
          border: 1px solid ${newTheme.colors.blue300};
          }
        }`}
`;
const InnerToolIcon = styled.div `
  background-color: ${newTheme.colors.white};
  border: 1px solid ${newTheme.colors.grey400};
  font: ${newTheme.fonts.small};
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -10px;
  right: -10px;
  border-radius: 5px;
  & img {
    width: 14px;
    height: 14px;
  }
  &.HOTSPOT {
    border-radius: 11px;
  }
`;
