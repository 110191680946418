import React from "react";
import TButton, { TButtonVariants } from "../../styled/t-button";
import styled from "styled-components";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import TModal, { TModalButtonsContainer } from "../../styled/t-modal";
import { newTheme } from "../../styled/theme";
import { ToastModes } from "../../../types/definitions";
import ArchiveIcon from "../../../../assets/images/v3/archive-icon-large.svg";
export default function ArchiveModal({ tourialData, setIsArchiveModalOpen, setIsDropdownOpen, reload, addToast, }) {
    return (React.createElement(TModal, { title: "Archive tour", onClose: () => setIsArchiveModalOpen(false), icon: ArchiveIcon },
        React.createElement(React.Fragment, null,
            React.createElement(Span, null, "You will no longer be able to make edits to archived tours."),
            React.createElement(Span, null, "Archived tours are available to view in analytics."),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => setIsArchiveModalOpen(false) }, "Cancel"),
                React.createElement(TButton, { variant: TButtonVariants.DEFAULT, onClick: () => {
                        TourialApiCalls.Tourials.postTourialArchive(tourialData.tourId)
                            .then(() => {
                            setIsArchiveModalOpen(false);
                            setIsDropdownOpen(false);
                            addToast({ message: "Archive successful", mode: ToastModes.SUCCESS });
                            reload();
                        })
                            .catch(e => {
                            console.error(e);
                            addToast({ message: "Archive failed", mode: ToastModes.ERROR });
                        });
                    } }, "Archive")))));
}
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  width: 100%;
  height: 68px;
  text-align: center;
`;
