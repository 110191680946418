import React, { useState } from "react";
import styled from "styled-components";
import { useDCConfirmTrackSelectionsForModule, useDCModules } from "../hooks/dc-module-hooks";
import { useDCSetShouldShowUpfrontQuestions, useDCTrackSelections, useDCUpfrontQuestions, } from "../../../../redux/selectors/demo-center-selectors";
import { newTheme } from "../../../styled/theme";
import { DCButtonAction, DCModuleType, } from "../../../../../../shared/types/demo-center";
import { DCMiniCardButton } from "../components/dc-mini-card-button";
import { useDCConfig, useDCModifyButtonConfig, useDCTheme } from "../hooks/dc-hooks";
import { cloneDeep } from "lodash";
import { DCButton } from "../components/dc-button";
import { dcGetFirstItemIdFromSelectedTracks, dcGetRGBColorStringFromHex } from "../helpers/dc-helpers";
import { useDCUpfrontQuestionsIntentEvent } from "../hooks/dc-event-hooks";
export default function UpfrontQuestions() {
    const modules = useDCModules();
    const upfrontQuestions = useDCUpfrontQuestions();
    const { cardButton, header } = useDCConfig();
    const theme = useDCTheme();
    const { cta } = header;
    const modifyButtonConfig = useDCModifyButtonConfig();
    // handle track selections across all modules with prompts
    const setShouldShowUpfrontQuestions = useDCSetShouldShowUpfrontQuestions();
    const confirmTrackSelectionsForModule = useDCConfirmTrackSelectionsForModule();
    const postIntentEvent = useDCUpfrontQuestionsIntentEvent();
    const trackSelections = useDCTrackSelections();
    const onTrackSelect = (_track, _isSelected, selectedTracks, slug, id) => {
        postIntentEvent(trackSelections, id);
        confirmTrackSelectionsForModule(slug, selectedTracks);
    };
    const onCreateDemo = () => {
        var _a;
        let forwardPath = "";
        const firstModule = modules[0];
        const isNoPrompt = firstModule.type === DCModuleType.NO_PROMPT;
        const firstModuleTrackSelections = ((_a = trackSelections.find(ts => ts.module === firstModule.slug)) === null || _a === void 0 ? void 0 : _a.tracks) || [];
        const firstItemId = isNoPrompt
            ? firstModule.tracks[0].items[0]
            : dcGetFirstItemIdFromSelectedTracks(firstModule, firstModuleTrackSelections);
        forwardPath = `/${modules[0].slug}` + (firstItemId ? `/view/${firstItemId}` : "");
        confirmTrackSelectionsForModule(firstModule.slug, firstModuleTrackSelections, forwardPath);
        setShouldShowUpfrontQuestions(false);
    };
    return (React.createElement(Overlay, null,
        React.createElement(UpfrontQuestionsContainer, { theme: theme },
            React.createElement(Header, { theme: theme }, upfrontQuestions.headerText),
            React.createElement(Body, { theme: theme }, modules
                .filter(m => m.type !== DCModuleType.NO_PROMPT)
                .map(m => (React.createElement(MiniTrack, { module: m, config: cardButton, key: m.id, theme: theme, onTrackSelect: onTrackSelect })))),
            React.createElement(Footer, { theme: theme },
                React.createElement(DCButton, { config: Object.assign(Object.assign({}, modifyButtonConfig(cta, { applyHighlight: true })), { text: upfrontQuestions.buttonText, action: DCButtonAction.VIEW }), onClick: onCreateDemo })))));
}
const MiniTrack = ({ module, config, theme, onTrackSelect, }) => {
    const { title, tracks, type, slug: moduleSlug, id } = module;
    const [selectedTracks, setSelectedTracks] = useState([]);
    const trackClone = cloneDeep(tracks);
    const isBooleanOrScale = [DCModuleType.SCALE, DCModuleType.BOOLEAN].includes(type);
    isBooleanOrScale ? trackClone.reverse() : trackClone;
    const handleCardClick = (t) => {
        const { slug } = t;
        let tracks = [...selectedTracks];
        if (type === DCModuleType.MULTIPLE) {
            if (isSelected(slug)) {
                tracks.splice(selectedTracks.findIndex(t => t === slug), 1);
            }
            else {
                tracks.push(slug);
            }
        }
        else {
            tracks = [slug];
        }
        setSelectedTracks(tracks);
        onTrackSelect === null || onTrackSelect === void 0 ? void 0 : onTrackSelect(t, isSelected(t.slug), tracks, moduleSlug, id);
    };
    const isSelected = (slug) => {
        return !!selectedTracks.find(t => t === slug);
    };
    return (React.createElement(TrackContainer, { theme: theme },
        React.createElement("div", { style: { fontWeight: "bold" } }, title),
        React.createElement(OptionsContainer, { style: { flexWrap: isBooleanOrScale ? "nowrap" : "wrap" } }, trackClone.map((t, i) => {
            return (React.createElement(DCMiniCardButton, { key: t.id, option: t, isSelected: isSelected(t.slug), config: config, onCardClick: () => handleCardClick(t), type: type, index: i, theme: theme }));
        }))));
};
const OptionsContainer = styled.div `
  width: 100%;
  display: flex;
  gap: 8px;
`;
const TrackContainer = styled.div `
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  font: ${newTheme.fonts.medium};
  color: ${p => p.theme.textMain};
`;
const Overlay = styled.div `
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  backdrop-filter: blur(12.5px);
`;
const UpfrontQuestionsContainer = styled.div `
  box-shadow:
    -2px -2px 32px 0px rgba(48, 49, 51, 0.02),
    1px 1px 12px 0px rgba(33, 34, 41, 0.12),
    0px 0px 1px 0px rgba(33, 34, 41, 0.5) inset;
  max-height: calc(100vh - 200px);
  height: fit-content;
  width: 744px;
  background: ${p => p.theme.neutralPrimary};
`;
const Header = styled.div `
  font: ${newTheme.fonts.large};
  padding: 28px 0px;
  color: ${p => p.theme.textMain};
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${p => p.theme.neutralSecondary};
`;
const Body = styled.div `
  background-color: ${p => dcGetRGBColorStringFromHex(p.theme.neutralSecondary, 0.25)};
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const Footer = styled(Header) `
  justify-content: end;
  display: flex;
  padding: 24px;
  border-top: 1px solid ${p => p.theme.neutralSecondary};
  border-bottom: none;
`;
