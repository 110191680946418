import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { newTheme } from "../../styled/theme";
import { SideBarIcon } from "./side-bar-icon";
import { MainAppPagePaths } from "../../../../../shared/types/app-events";
export default function SideBarLink({ label, to, href, icon, iconStyle }) {
    const windowPathName = window.location.pathname;
    let active = windowPathName.includes(to) && label !== "Tours";
    if (to === MainAppPagePaths.ANALYTICS && windowPathName.includes("/outbound"))
        active = false;
    const [isHovered, setIsHovered] = useState(false);
    return (React.createElement(SideBarLinkContainer, { active: active, onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false) },
        React.createElement(TLink, { onClick: () => href && window.open(href, "_blank"), to: to, className: "_test_sidebar-link" },
            React.createElement(SideBarIcon, { style: iconStyle, type: icon, fill: active || isHovered ? newTheme.colors.blue500 : "black" }),
            label)));
}
export const SideBarLinkContainer = styled.div `
  cursor: pointer;
  width: calc(100% + 32px);
  margin: 0px -16px;
  height: 28px;
  display: flex;
  background: ${({ active }) => (active ? newTheme.colors.blue100 : "none")};
  color: ${({ active }) => (active ? newTheme.colors.blue500 : newTheme.colors.grey700)};
  border-left: ${({ active }) => (active ? `4px solid ${newTheme.colors.blue500}` : "none")};
  &:hover {
    background-color: ${newTheme.colors.blue50} !important;
    color: ${newTheme.colors.blue500};
  }
`;
const TLink = styled(Link) `
  text-decoration: none;
  color: inherit;
  font: ${newTheme.fonts.medium};
  display: flex;
  align-items: center;
  flex: 1;
`;
