import React, { useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
export function LabelledTextInput(props) {
    const { value, name } = props;
    const [labelColor, setLabelColor] = useState(newTheme.colors.grey700);
    return (React.createElement(Label, { style: { color: labelColor } },
        React.createElement("span", null, name),
        React.createElement(Input, Object.assign({}, props, { value: value, name: name, className: "focusable-input", onFocus: (e) => {
                var _a;
                setLabelColor(newTheme.colors.blue400);
                (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props, e);
            }, onBlur: (e) => {
                var _a;
                setLabelColor(newTheme.colors.grey700);
                (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, e);
            } }))));
}
const Input = styled.input `
  font: ${newTheme.fonts.normal};
  background-color: ${newTheme.colors.white};
  border-color: ${newTheme.colors.grey600};
  color: ${newTheme.colors.grey600};
  border-style: solid;
  border-width: 1px;
  width: 100%;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  &:focus {
    color: ${newTheme.colors.blue400};
    border-color: ${newTheme.colors.blue400};
    box-shadow: ${newTheme.shadows.textInputSelect};
  }
`;
const Label = styled.label `
  font: ${newTheme.fonts.normal};
  padding-bottom: 4px;
  user-select: none;
`;
