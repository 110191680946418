import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DCItemCard } from "./dc-item-card";
import { useRefSize } from "../../../../hooks/viewport-hooks";
import { dcCardSizes, dcFadeIn } from "../helpers/dc-style-helpers";
export const DCGridView = ({ items, style, theme, itemCardConfig, onCardSelect, isLocked }) => {
    const [cardWidth, cardHeight] = dcCardSizes[itemCardConfig.size];
    const [gridHeight, setGridHeight] = useState(cardHeight);
    const containerRef = useRef(null);
    const { width } = useRefSize(containerRef);
    const gapSize = 24;
    const numberOfColumns = Math.floor(width / cardWidth);
    const numberOfRows = Math.floor(items.length / numberOfColumns) + (items.length % numberOfColumns > 0 ? 1 : 0);
    const gapHeight = numberOfRows > 1 ? gapSize * (numberOfRows - 1) : 0;
    useEffect(() => {
        setGridHeight(cardHeight * numberOfRows + gapHeight || cardHeight);
    }, [items, width]);
    const getPositionForIndex = (i) => {
        let x = 0;
        let y = 0;
        const rowNumber = Math.floor(i / numberOfColumns);
        const colNumber = i % numberOfColumns;
        x = colNumber * cardWidth + colNumber * gapSize;
        y = rowNumber * cardHeight + rowNumber * gapSize;
        return { x, y };
    };
    return (React.createElement(GridContainer, { style: Object.assign(Object.assign({}, style), { height: gridHeight }), ref: containerRef }, items.map((item, i) => (React.createElement(CardPositioner, { key: `item-${item.id || i}`, itemCardConfig: itemCardConfig, position: getPositionForIndex(i) },
        React.createElement(DCItemCard, { item: item, config: itemCardConfig, theme: theme, onClick: onCardSelect, isLocked: isLocked }))))));
};
const CardPositioner = ({ itemCardConfig, children, position }) => {
    const [width, height] = dcCardSizes[itemCardConfig.size];
    const { x, y } = position;
    return (React.createElement(CardContainer, { style: { width, height, left: x, top: y }, itemCardConfig: itemCardConfig }, children));
};
const GridContainer = styled.div `
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  transition: height 0.2s ease-in;
  overflow: hidden;
`;
const CardContainer = styled.div `
  position: absolute;
  transition:
    left 0.2s ease-in,
    top 0.2s ease-in;
  animation: ${dcFadeIn} 0.2s ease-in;
`;
