import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { DCFileUploader } from "../dc-file-uploader";
import { DCCMTextEditor } from "../components/dc-cm-text-editor";
import { DCIconType } from "../../../../../../../shared/types/demo-center";
import { DCCMTrackItem } from "../components/dc-cm-track-item";
import { useDCCreateNewItemFromFileUpload, useDCCreateNewItemFromUrl } from "../../hooks/dc-item-hooks";
import { defaultDemoCenter } from "../../../../../../../shared/objects/default-demo-center";
import { TMessageBox, TMessageBoxVariants } from "../../../../styled/t-message-box";
import { useAddToast } from "../../../../../legacy/selectors";
import { ToastModes } from "../../../../../types/definitions";
export const DCCMAddContentUploadTab = ({ setUploadedItems }) => {
    const [error, setError] = useState("");
    const [url, setUrl] = useState("");
    const { sidebar, badge } = defaultDemoCenter.config;
    const [items, setItems] = useState([]);
    const createNewItemFromFileUpload = useDCCreateNewItemFromFileUpload();
    const createNewItemFromUrl = useDCCreateNewItemFromUrl();
    const addToast = useAddToast();
    const addItem = (item) => {
        const newItems = [...items];
        newItems.push(item);
        setItems(newItems);
    };
    const updateItem = (item) => {
        const newItems = [...items];
        const i = newItems.findIndex(i => i.id === item.id);
        newItems[i] = item;
        setItems(newItems);
    };
    const removeItem = (item) => {
        const newItems = [...items];
        const i = newItems.findIndex(i => i.id === item.id);
        newItems.splice(i, 1);
        setItems(newItems);
    };
    const handleUpload = (src, originalFileName) => {
        addItem(createNewItemFromFileUpload(src, originalFileName));
    };
    useEffect(() => {
        setUploadedItems(items);
    }, [items]);
    return (React.createElement(UploadTabContainer, null,
        items.length === 0 && (React.createElement(React.Fragment, null,
            React.createElement(DCFileUploader, { onUploadComplete: handleUpload, onError: setError, showSpinner: true },
                React.createElement(UploadPlaceholder, null,
                    React.createElement("span", null,
                        React.createElement("span", { className: "underline" }, "Click to upload"),
                        " from your device"))),
            error && React.createElement(TMessageBox, { variant: TMessageBoxVariants.DANGER, message: error }),
            React.createElement(Separator, null,
                React.createElement(Line, null),
                React.createElement("span", null, "Or"),
                React.createElement(Line, null)),
            React.createElement(DCCMTextEditor, { title: "Import via URL", placeholder: "Paste your URL", onChange: e => setUrl(e.target.value), onSubmit: url => {
                    createNewItemFromUrl(url)
                        .then(newItem => {
                        if (!newItem) {
                            addToast({
                                mode: ToastModes.ERROR,
                                message: "Unable to embed the url due to header restrictions.",
                            });
                            return;
                        }
                        addItem(newItem);
                    })
                        .catch(e => console.log(e));
                }, submitButtonText: "Import", submitButtonIcon: DCIconType.UPLOAD, value: url }))),
        items.map(i => (React.createElement(DCCMTrackItem, { key: i.id, item: i, preview: true, badgeConfig: badge, sidebarConfig: sidebar, activeItemId: i.id, setItem: updateItem, onDelete: removeItem })))));
};
const UploadTabContainer = styled.div `
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  gap: 10px;
`;
const UploadPlaceholder = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  background-color: ${newTheme.colors.grey100};
  border-radius: 4px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
  .underline {
    text-decoration: underline;
    margin-right: 8px;
  }
  cursor: pointer;
`;
const Separator = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 100%;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
  span {
    background-color: ${newTheme.colors.white};
    padding: 4px 28px;
  }
`;
const Line = styled.div `
  display: flex;
  flex: 1;
  height: 1px;
  width: 100%;
  background-color: ${newTheme.colors.grey500};
`;
