import React from "react";
import TLabel from "../styled/t-label";
import TNumberInput from "../styled/t-number-input";
import TToggle from "../styled/t-toggle";
import { useUpdateTool } from "../../../redux/selectors/redux-selectors";
import { ToolColorPicker } from "./editor-tool-color-picker";
import { theme, } from "../../../../../shared/legacy/legacy-tool-data";
export function BorderControl({ toolData, disableBorderRadius, }) {
    const updateTool = useUpdateTool();
    return (React.createElement("div", { style: { margin: "0 auto" } },
        React.createElement(TLabel, null,
            "Border",
            React.createElement(TToggle, { value: toolData.enableBorder, onClick: () => {
                    updateTool(Object.assign(Object.assign({}, toolData), { enableBorder: !toolData.enableBorder }));
                } })),
        React.createElement(ToolColorPicker, { toolId: toolData.id, property: "borderColor", label: "Border color" }),
        React.createElement(TLabel, null,
            "Border width",
            React.createElement(TNumberInput, { style: { color: theme.colors.grey.darkest }, value: toolData.borderWidth, min: 1, max: 100, onChange: e => {
                    updateTool(Object.assign(Object.assign({}, toolData), { borderWidth: Number(e.target.value) }));
                } })),
        !disableBorderRadius && (React.createElement(TLabel, null,
            "Border radius",
            React.createElement(TNumberInput, { style: { color: theme.colors.grey.darkest }, value: toolData.borderRadius, min: 1, max: 1000, onChange: e => {
                    updateTool(Object.assign(Object.assign({}, toolData), { borderRadius: Number(e.target.value) }));
                } })))));
}
