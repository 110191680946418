import React from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
export function TextInput(props) {
    const { value } = props;
    return React.createElement(Input, Object.assign({ className: "focusable-input" }, props, { value: value }));
}
const Input = styled.input `
  font: ${newTheme.fonts.normal};
  background-color: ${newTheme.colors.white};
  border-color: ${p => (p.errored ? newTheme.colors.red500 : newTheme.colors.grey600)};
  color: ${newTheme.colors.grey600};
  border-style: solid;
  border-width: 1px;
  width: 100%;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  &:focus {
    color: ${newTheme.colors.blue400};
    border-color: ${newTheme.colors.blue400};
    box-shadow: ${newTheme.shadows.textInputSelect};
  }
`;
