import React from "react";
import styled from "styled-components";
import { LoadingPlaceholderDropdown, TDropdownSelect } from "../../../../styled/t-dropdown-select";
import { TourialApiCalls } from "../../../../../helpers/tourial-api-calls";
import { Async } from "react-async";
import { newTheme } from "../../../../styled/theme";
export const DCCMFormSelector = ({ formId, onChange }) => {
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Form"),
        React.createElement(Async, { promiseFn: TourialApiCalls.Forms.getForms }, ({ data, error, isPending }) => {
            if (isPending)
                return React.createElement(LoadingPlaceholderDropdown, null);
            if (error)
                return `Something went wrong: ${error.message}`;
            return (React.createElement(TDropdownSelect, { isSearchable: true, selectedValue: formId, options: data.map(f => ({ label: f.name, value: f._id })), onChange: (e) => onChange(e.value) }));
        })));
};
const Title = styled.div `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
  margin-bottom: 4px;
`;
const Container = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
`;
