import React from "react";
import styled from "styled-components";
import { TextStyle } from "../../../../../../../shared/types/tool-data-types/common";
import parse from "html-react-parser";
import { getBodyFontSize, textAlignments } from "../../../../../helpers/text-helpers";
export function BubbleBodyText({ body, onDoubleClick, viewportDimensions }) {
    const { width } = viewportDimensions || {};
    const style = {
        fontFamily: body.font,
        fontSize: getBodyFontSize(width, body.size),
        textAlign: textAlignments[body.align],
        textDecoration: body.style === TextStyle.UNDERLINE ? "underline" : "none",
        fontStyle: body.style === TextStyle.ITALIC ? "italic" : "normal",
        fontWeight: body.style === TextStyle.BOLD ? "bold" : 400,
        lineHeight: `${getBodyFontSize(width, body.size) * 1.5}px`,
        minHeight: 45,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: body.color,
    };
    return (React.createElement(BodyContainer, { style: style, onDoubleClick: onDoubleClick }, parse(body.text)));
}
const BodyContainer = styled.div `
  ul,
  ol {
    margin-left: 16px;
  }
`;
