import { RESET_ERROR_MESSAGE, SHOW_ERROR_MESSAGE } from "../../actions/legacy-action-types";
const initialState = {
    message: "",
    showError: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_ERROR_MESSAGE:
            console.log("showing error message");
            return Object.assign(Object.assign({}, state), { message: action.message, showError: true });
        case RESET_ERROR_MESSAGE:
            return Object.assign(Object.assign({}, state), { message: "", showError: false });
        default:
            return state;
    }
}
