import React, { useState } from "react";
import TModal from "../../../styled/t-modal";
import addScreenIcon from "../../../../../assets/images/v3/add-screen-icon.svg";
import swapScreenIcon from "../../../../../assets/images/v3/swap-screen-icon.svg";
import UploadIcon from "../../../../../assets/images/upload-icon-blue.svg";
import styled, { css } from "styled-components";
import { newTheme } from "../../../styled/theme";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { useAddMedia, useAddPageWithScreen, useAddStarterScreens, usePushHistory, useScreenMedia, useSetScreenImageById, useTourialMedia, useTourialName, useUpdateTourialPartial, } from "../../../../redux/selectors/redux-selectors";
import TrashCanIcon from "../../../../../assets/images/v3/red-trashcan.svg";
import CheckCircleIcon from "../../../../../assets/images/v3/check-with-circle.svg";
import DownloadIcon from "../../../../../assets/images/v3/download.svg";
import { DynamicWidthInput } from "../navbar/dynamic-width-input";
import { isImageFileExtension, uploadMedia, validUploadableMediaTypes } from "../../../../helpers/media";
import { ImportScreen } from "./import-screen";
import { useGotoNextScreen } from "../../../../redux/selectors/builder-selectors";
import TTooltip from "../../../styled/t-tooltip";
import { downloadSingleFile, downloadZip } from "../../../../helpers/download-zip";
import { useFetchTheme } from "../../../../hooks/use-fetch-theme";
var AddScreenModalTabs;
(function (AddScreenModalTabs) {
    AddScreenModalTabs["MEDIA_LBRARY"] = "Media library";
    AddScreenModalTabs["IMPORT_SCREEN"] = "Import screen";
})(AddScreenModalTabs || (AddScreenModalTabs = {}));
export var AddScreenModalVariants;
(function (AddScreenModalVariants) {
    AddScreenModalVariants["ADD_SCREEN"] = "Add screen";
    AddScreenModalVariants["SWAP_SCREEN"] = "Swap screen";
    AddScreenModalVariants["START_SCREENS"] = "Add screens";
})(AddScreenModalVariants || (AddScreenModalVariants = {}));
export default function AddScreenModal({ onClose, variant, pageId }) {
    const tourialMedia = useTourialMedia();
    const screenMedia = useScreenMedia();
    const screenMediaUrls = Object.keys(screenMedia).map(pageId => screenMedia[pageId]);
    const setScreenImageById = useSetScreenImageById();
    const addMedia = useAddMedia();
    const updateTourialPartial = useUpdateTourialPartial();
    const addPageWithScreen = useAddPageWithScreen();
    const addStarterScreens = useAddStarterScreens();
    const pushHistory = usePushHistory();
    const goToNextScreen = useGotoNextScreen();
    const { theme } = useFetchTheme();
    const tourialName = useTourialName();
    const [selectedTab, setSelectedTab] = useState(AddScreenModalTabs.MEDIA_LBRARY);
    const [isUploading, setIsUploading] = useState(false);
    const [selected, setSelected] = useState(screenMedia === null || screenMedia === void 0 ? void 0 : screenMedia[pageId]);
    const isAddScreen = variant === AddScreenModalVariants.ADD_SCREEN || variant === AddScreenModalVariants.START_SCREENS;
    const uploader = (React.createElement(React.Fragment, null,
        React.createElement(TButton, { icon: UploadIcon, variant: TButtonVariants.TRANSPARENT, onClick: () => document.getElementById("drawer-file-input").click() }, isUploading ? "Uploading..." : "Upload"),
        React.createElement("input", { id: "drawer-file-input", type: "file", multiple: true, accept: `${validUploadableMediaTypes.join("|")}`, onClick: e => {
                //@ts-expect-error
                e.target.value = null;
            }, onChange: e => {
                setIsUploading(true);
                uploadMedia(e.target.files, addMedia)
                    .then(() => setIsUploading(false))
                    .catch(err => console.log(err));
            } })));
    const mediaCards = tourialMedia === null || tourialMedia === void 0 ? void 0 : tourialMedia.map((media, i) => (React.createElement(MediaCard, { media: media, key: i, i: i, updateTourialPartial: updateTourialPartial, tourialMedia: tourialMedia, selected: variant !== AddScreenModalVariants.START_SCREENS && selected === media.src, setSelected: setSelected, inUse: !!screenMediaUrls.includes(media.src) })));
    return (React.createElement(TModal, Object.assign({ id: `_test_${isAddScreen ? "add-screen" : "swap-screen"}-modal`, title: variant, icon: isAddScreen ? addScreenIcon : swapScreenIcon }, (isAddScreen && {
        tabs: [AddScreenModalTabs.MEDIA_LBRARY, AddScreenModalTabs.IMPORT_SCREEN],
        onTabSelect: (tab) => setSelectedTab(tab),
    }), { onClose: onClose }), isAddScreen ? (React.createElement(ContentContainer, null,
        selectedTab === AddScreenModalTabs.MEDIA_LBRARY && (React.createElement(React.Fragment, null,
            React.createElement(ContentBody, null, mediaCards),
            React.createElement(Footer, null,
                variant === AddScreenModalVariants.ADD_SCREEN && (React.createElement(TButton, { variant: TButtonVariants.OUTLINE, onClick: () => void downloadZip(tourialName, tourialMedia), disabled: !tourialMedia.length }, "Download .zip")),
                uploader,
                variant === AddScreenModalVariants.ADD_SCREEN && (React.createElement(TButton, { id: "_test_add-to-tour-button", onClick: () => {
                        if (selected) {
                            pushHistory();
                            addPageWithScreen(selected);
                            goToNextScreen();
                        }
                        onClose();
                    }, disabled: !selected }, "Add to tour")),
                variant === AddScreenModalVariants.START_SCREENS && (React.createElement(TButton, { onClick: () => {
                        if (tourialMedia === null || tourialMedia === void 0 ? void 0 : tourialMedia.length) {
                            pushHistory();
                            addStarterScreens(tourialMedia.map(tm => tm.src), theme);
                        }
                        onClose();
                    }, disabled: !(tourialMedia === null || tourialMedia === void 0 ? void 0 : tourialMedia.length) }, "Add screens to tour"))))),
        selectedTab === AddScreenModalTabs.IMPORT_SCREEN && (React.createElement(ImportScreen, { onClose: onClose, isEmptyTourial: variant === AddScreenModalVariants.START_SCREENS })))) : (React.createElement(ContentContainer, null,
        React.createElement(ContentBody, null, mediaCards),
        React.createElement(Footer, null,
            uploader,
            React.createElement(TButton, { id: "_test_change-screen-button", onClick: () => {
                    if (selected) {
                        pushHistory();
                        setScreenImageById(selected, pageId);
                    }
                    onClose();
                }, disabled: !selected }, "Change screen"))))));
}
const MediaCard = ({ media, updateTourialPartial, tourialMedia, i, selected, setSelected, inUse }) => {
    var _a, _b;
    const selectHandler = () => setSelected(selected ? null : media.src);
    return (React.createElement(MediaCardContainer, { selected: selected },
        React.createElement(MediaContainer, null, isImageFileExtension(media.src) ? (React.createElement(Img, { className: "_test_add-screen-media", src: media.src, onClick: selectHandler })) : (React.createElement(Video, { src: `${media.src}#t=0.5`, onClick: selectHandler, muted: true, controls: false, autoPlay: false, preload: "metadata" }))),
        React.createElement(MediaCardFooter, null,
            React.createElement("div", { style: { maxWidth: 128, display: "flex", font: newTheme.fonts.small } },
                React.createElement(DynamicWidthInput, { value: media.name, onChange: (e) => {
                        const newTourialMedia = [...tourialMedia];
                        newTourialMedia[i].name = e.target.value;
                        updateTourialPartial({ media: newTourialMedia });
                    }, style: {
                        textAlign: "left",
                        font: newTheme.fonts.small,
                        color: newTheme.colors.black,
                        maxWidth: 128,
                        display: "block",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                    } }),
                React.createElement(FileExtension, { extension: "." + (((_b = (_a = media.src) === null || _a === void 0 ? void 0 : _a.split(".")) === null || _b === void 0 ? void 0 : _b.pop()) || "") })),
            React.createElement("div", { style: { display: "flex", width: 36 } },
                React.createElement(ActionIcon, { src: DownloadIcon, alt: "download media", width: 16, height: 20, selected: false, draggable: false, onClick: () => void downloadSingleFile(media), style: { marginRight: 4 } }),
                React.createElement(TTooltip, { content: inUse || selected ? "Disabled: in use or selected" : "Delete", placement: "bottom" },
                    React.createElement(ActionIcon, { src: TrashCanIcon, alt: "trash can delete", width: 16, height: 20, selected: inUse || selected, draggable: false, onClick: () => {
                            if (inUse || selected)
                                return;
                            const newTourialMedia = [...tourialMedia];
                            newTourialMedia.splice(i, 1);
                            updateTourialPartial({ media: newTourialMedia });
                        } })))),
        selected && (React.createElement("img", { src: CheckCircleIcon, alt: "checkmark in circle", width: 24, style: { position: "absolute", top: 12, right: 12 } }))));
};
export const MediaCardContainer = styled.div `
  width: 200.89px;
  height: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 8px;
  ${props => (props.selected ? `border: 1px solid ${newTheme.colors.blue300}` : "")};
  border-radius: 4px;
  position: relative;
`;
const ActionIcon = styled.img `
  cursor: ${props => (props.selected ? "not-allowed" : "pointer")};
  background-color: ${newTheme.colors.grey100};
`;
export const MediaContainer = styled.div `
  width: 184.89px;
  height: 104px;
`;
export const MediaCardFooter = styled.div `
  width: 184.89px;
  height: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;
const MediaCss = css `
  cursor: pointer;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;
export const Img = styled.img `
  ${MediaCss}
`;
const Video = styled.video `
  ${MediaCss}
`;
const FileExtension = styled.div `
  width: 0px;
  &:after {
    content: "${props => props.extension}";
  }
`;
export const ContentContainer = styled.div `
  width: 658.66px;
  height: 428px;
`;
export const ContentBody = styled.div `
  width: 658.66px;
  height: 360px;
  display: flex;
  gap: 4px;
  overflow-y: scroll;
  padding: 24px;
  flex-wrap: wrap;
  background-color: ${newTheme.colors.grey100};
`;
export const Footer = styled.div `
  width: 100%;
  height: 68px;
  padding: 16px;
  border-top: 1px solid ${newTheme.colors.grey300};
  display: flex;
  gap: 10px;
  justify-content: end;
  align-items: center;
`;
