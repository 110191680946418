import React from "react";
import styled from "styled-components";
import { DCIconType } from "../../../../../../shared/types/demo-center";
import { dcCardSizes, dcCardThumbnailSizes } from "../helpers/dc-style-helpers";
import { dcGetRGBColorStringFromHex, dcGetReadableItemType } from "../helpers/dc-helpers";
import { DCStyledBodyText } from "./dc-styled-text";
import { DCButton } from "./dc-button";
import { DCIcon } from "./dc-icon";
export const DCItemCard = ({ item, config, theme, onClick, isLocked }) => {
    const { type, title, thumbnail, description } = item;
    return (React.createElement(CardContainer, { config: config, theme: theme, onClick: () => onClick(item), isLocked: isLocked },
        isLocked && (React.createElement(React.Fragment, null,
            React.createElement(LockedOverlay, { config: config, theme: theme },
                React.createElement(PadlockContainer, { theme: theme },
                    React.createElement(DCIcon, { type: DCIconType.PADLOCK, style: { color: theme.textMain } }))))),
        React.createElement(ThumbnailContainer, { config: config, onClick: () => onClick(item), style: { backgroundImage: `url(${thumbnail})` }, className: "thumbnail" }),
        React.createElement(InfoContainer, { config: config },
            React.createElement(DCStyledBodyText, { config: config.label }, dcGetReadableItemType(type)),
            React.createElement(DCStyledBodyText, { config: config.title, className: "title" }, title),
            React.createElement(Description, { config: config.description, className: "description" }, description),
            React.createElement(ButtonContainer, { className: "buttons" },
                React.createElement(DCButton, { config: config.viewButton, onClick: () => onClick(item) })))));
};
const InfoContainer = styled.div `
  position: relative;
  padding: 16px;
  width: 100%;
  height: ${p => dcCardSizes[p.config.size][1]}px;
  .title {
    overflow: hidden;
    height: 46px;
  }
`;
const CardContainer = styled.div `
  background-color: ${p => p.theme.neutralPrimary};
  width: ${p => dcCardSizes[p.config.size][0]}px;
  height: ${p => dcCardSizes[p.config.size][1]}px;
  border-radius: ${p => p.config.borderRadius}px;
  overflow: hidden;
  cursor: ${p => (p.isLocked ? "not-allowed" : "pointer")};
  box-shadow: ${p => (p.config.shadow ? p.theme.shadow : "")};
  :hover {
    ${p => p.isLocked
    ? ``
    : `.thumbnail {
          height: 0;
        }
        .description,
        .buttons {
          opacity: 1;
        }
        ${InfoContainer} {
          .title {
            height: auto;
          }
        }`}
  }
  transition: box-shadow 0.2s linear;
`;
const ThumbnailContainer = styled.div `
  position: relative;
  background-size: cover;
  background-position: top;
  z-index: 0;
  width: ${p => dcCardThumbnailSizes[p.config.size][0]}px;
  height: ${p => dcCardThumbnailSizes[p.config.size][1]}px;
  box-shadow: inset 0px 0px 1px rgba(33, 34, 41, 0.4);
  transition: height 0.2s ease-out;
`;
const Description = styled(DCStyledBodyText) `
  margin-top: 8px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
`;
const ButtonContainer = styled.div `
  display: flex;
  justify-content: right;
  position: absolute;
  padding: 16px;
  width: 100%;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out;
`;
const LockedOverlay = styled.div `
  position: absolute;
  width: 100%;
  height: ${p => dcCardThumbnailSizes[p.config.size][1]}px; // Same height as ThumbnailContainer
  background: ${p => dcGetRGBColorStringFromHex(p.theme.neutralTranslucentColor, p.theme.neutralTranslucentOpacity)};
  z-index: 1;
`;
const PadlockContainer = styled.div `
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.neutralPrimary};
  border-radius: 50%; // Makes it a circle
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); // Adds a shadow
  svg {
    height: 24px;
    width: 24px;
    margin-left: 4px;
  }
`;
