import React from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import TTextarea from "../../../../styled/t-textarea";
import TInput from "../../../../styled/t-input";
import { DCIcon } from "../../components/dc-icon";
export const DCCMTextEditor = ({ title, value, textarea, placeholder, onChange, onSubmit, submitButtonText, submitButtonIcon, labelStyle, style, disabled, }) => {
    return (React.createElement(Editor, null,
        React.createElement(Title, { style: labelStyle }, title),
        textarea ? (React.createElement(TTextarea, { value: value, onChange: onChange, placeholder: placeholder, disabled: disabled, style: Object.assign(Object.assign({}, style), { padding: "8px" }) })) : (React.createElement(TInput, { value: value, onChange: onChange, placeholder: placeholder, disabled: disabled, style: Object.assign(Object.assign({}, style), { padding: "4px 8px" }) })),
        onSubmit ? (React.createElement(Button, { disabled: !value, onClick: value ? () => onSubmit(value) : null },
            React.createElement(DCIcon, { type: submitButtonIcon }),
            React.createElement("span", null, submitButtonText))) : null));
};
const Title = styled.div `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
  margin-bottom: 4px;
`;
const Editor = styled.div `
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const Button = styled.button `
  position: absolute;
  bottom: 4px;
  right: 4px;
  padding: 4px;
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: ${newTheme.colors.blue100};
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.blue500};
  cursor: pointer;
  &:hover:not(:disabled) {
    background: ${newTheme.colors.blue200};
  }
  &:disabled {
    background: ${newTheme.colors.grey200};
    color: ${newTheme.colors.grey500};
    cursor: not-allowed;
  }
`;
