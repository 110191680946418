import React, { useRef, useEffect } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import styled from "styled-components";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useRefSize } from "../../../../../hooks/viewport-hooks";
import { useDCActiveItemSubNavigation, useDCSetActiveItemSubNavigation, } from "../../../../../redux/selectors/demo-center-selectors";
import { debounce } from "lodash";
import { usePostDCEvent } from "../../../../../helpers/events";
import { TourialEvent } from "../../../../../../../shared/types/events";
// important: pdf worker must come from pdfjs-dist with matching version to pdfjs.version
pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString(); // `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
export const DCPDFPlayer = ({ item }) => {
    const { url, config } = item;
    const { pagination } = config || {};
    const { currentPage, totalPages } = useDCActiveItemSubNavigation();
    const setSubNavigation = useDCSetActiveItemSubNavigation();
    const postDCEvent = usePostDCEvent();
    const pdfRef = useRef(null);
    const { width } = useRefSize(pdfRef);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setSubNavigation({ enabled: pagination, currentPage: 1, totalPages: numPages, lastPageNumberVisited: 1 });
    };
    const AllPages = Array.from(new Array(totalPages), (el, index) => (React.createElement(Page, { key: `page_${index + 1}`, pageNumber: index + 1, width: width, loading: "" })));
    const handleScroll = debounce(() => {
        // const position = window.scrollY;
        void postDCEvent({ eventType: TourialEvent.DEMOCENTER_SCROLL, democenterContentId: item.uuid });
    });
    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (React.createElement(PDFContainer, { ref: pdfRef },
        React.createElement(Document, { file: url, onLoadSuccess: onDocumentLoadSuccess, loading: "" }, pagination && currentPage > 0 ? React.createElement(Page, { pageNumber: currentPage, width: width, loading: "" }) : AllPages)));
};
const PDFContainer = styled.div `
  margin-bottom: 106px;
  .react-pdf__Page {
    margin-bottom: 24px;
  }
`;
