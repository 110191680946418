var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import TButton, { TButtonVariants } from "../../styled/t-button";
import styled from "styled-components";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import TModal from "../../styled/t-modal";
import { newTheme } from "../../styled/theme";
import { EmbedButtonContainer, EmbedCodeContainer, EmbedTextarea } from "../builder/modals/embed-tab";
import ShareIconGrey from "../../../../assets/images/v3/share-icon-grey.svg";
import { debounce } from "lodash";
import { usePostAppEvent } from "../../../hooks/use-post-app-event";
import { makeEmbedCode } from "../../../legacy/components/builder/navbar/embed-modal";
import { TourialAppEventTypes } from "../../../../../shared/types/app-events";
import { useAsync } from "react-async";
import { getBaseUrlWithSubdomain } from "../../../helpers/misc";
import { TDropdownSelect } from "../../styled/t-dropdown-select";
import { useFeatureFlags } from "../../../redux/selectors/redux-selectors";
import { ShareLinkViewTab } from "../../share-links/share-links-viewer/share-link-view-tab";
import { TLoadingSpinner } from "../../styled/t-loading-spinner";
import { LinkContainer } from "../../share-links/share-links-editor/tourial-slug-editor";
import { ToastModes } from "../../../types/definitions";
import { useAddToast } from "../../../legacy/selectors";
function getFullTourialData({ tourId, version }) {
    return __awaiter(this, void 0, void 0, function* () {
        const { tourial } = yield TourialApiCalls.Tourials.getTourial(tourId);
        const currentUser = yield TourialApiCalls.Users.getTourialAuthData();
        const baseUrlWithSubdomain = getBaseUrlWithSubdomain(currentUser.account);
        let hasProductPage = true;
        // if the featureFlag is enabled, check that a PTP was created if legacy
        if (version !== 3) {
            hasProductPage = !!tourial.productTourPage;
        }
        return [tourial, baseUrlWithSubdomain, hasProductPage];
    });
}
var Tabs;
(function (Tabs) {
    Tabs["PUBLIC_LINK"] = "Public Link";
    Tabs["SHARE_LINKS"] = "1:1 Links";
    Tabs["EMBED"] = "Embed";
})(Tabs || (Tabs = {}));
export default function TourShareModal({ tourialData, setIsShareModalOpen, setIsDropdownOpen, }) {
    const { data, isLoading, error } = useAsync({
        promiseFn: getFullTourialData,
        tourId: tourialData.tourId,
        version: tourialData.version,
    });
    const [fullTourialData, baseUrlWithSubdomain, hasProductPage] = data || [];
    const { shareLinks } = useFeatureFlags();
    const postAppEvent = usePostAppEvent();
    const addToast = useAddToast();
    const [wasCopied, setWasCopied] = useState(false);
    const [isLightboxEnabled, setIsLightboxEnabled] = useState(false);
    const [selectedTab, setSelectedTab] = useState(Tabs.PUBLIC_LINK);
    const copyToClipboard = data &&
        debounce(() => {
            var _a;
            (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(makeEmbedCode(baseUrlWithSubdomain, fullTourialData.slug, fullTourialData.isVariantMobileEnabled, false, fullTourialData.mobileEmbedButton, fullTourialData.isEmbedHeaderEnabled, fullTourialData.isMobileButtonDisabled)).then(function () {
                postAppEvent(isLightboxEnabled ? TourialAppEventTypes.LIGHTBOX_CODE_COPIED : TourialAppEventTypes.EMBED_CODE_COPIED, { tourialId: tourialData.tourId });
                setWasCopied(true);
            }, function (err) {
                console.error("Failed to copy text to clipboard: ", err);
            });
        }, 500);
    if (error)
        return null;
    let tabs = [];
    if (shareLinks) {
        tabs = [Tabs.PUBLIC_LINK, Tabs.SHARE_LINKS, Tabs.EMBED];
    }
    else {
        tabs = [Tabs.PUBLIC_LINK, Tabs.EMBED];
    }
    const liveLink = `${baseUrlWithSubdomain}${hasProductPage ? "pages/" : ""}${fullTourialData === null || fullTourialData === void 0 ? void 0 : fullTourialData.slug}`;
    return (React.createElement(TModal, { tabs: tabs, icon: ShareIconGrey, title: "Publish & share", onClose: () => setIsShareModalOpen(false), onTabSelect: tab => {
            setSelectedTab(tab);
        } },
        React.createElement(Div, null,
            selectedTab === Tabs.PUBLIC_LINK && (React.createElement(React.Fragment, null,
                React.createElement(Body, { isLightboxEnabled: isLightboxEnabled },
                    isLoading && React.createElement(TLoadingSpinner, null),
                    data && (React.createElement(React.Fragment, null,
                        React.createElement("div", { style: { font: newTheme.fonts.bold, margin: "16px 0px" } },
                            "Mobile tour enabled:",
                            " ",
                            React.createElement("span", { style: {
                                    color: fullTourialData.isVariantMobileEnabled
                                        ? newTheme.colors.green600
                                        : newTheme.colors.red500,
                                } }, fullTourialData.isVariantMobileEnabled ? "ON" : "OFF")),
                        React.createElement(LinkContainer, null,
                            React.createElement("a", { href: liveLink, target: "_blank" }, liveLink),
                            React.createElement(TButton, { variant: TButtonVariants.OUTLINE, size: "medium", onClick: () => {
                                    var _a;
                                    (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(liveLink).then(() => {
                                        addToast({
                                            message: "link copied",
                                            mode: ToastModes.SUCCESS,
                                        });
                                    }).catch(() => console.error("failed to copy link"));
                                } }, "Copy"))))))),
            selectedTab === Tabs.SHARE_LINKS && (React.createElement(ShareLinkViewTab, { tourialId: fullTourialData._id, tourialSlug: fullTourialData.slug, baseUrlWithSubdomain: baseUrlWithSubdomain })),
            selectedTab === Tabs.EMBED && (React.createElement(Container, null,
                React.createElement(EmbedCodeContainer, { style: { borderTop: 0, paddingTop: 0 } },
                    React.createElement(TDropdownSelect, { isSearchable: false, selectedValue: isLightboxEnabled, style: { width: 185 }, onChange: (e) => {
                            setIsLightboxEnabled(e.value);
                        }, options: [
                            { value: false, label: "Embed in Page" },
                            { value: true, label: "Tourial Lightbox" },
                        ] }),
                    isLightboxEnabled && (React.createElement(React.Fragment, null,
                        React.createElement("span", null, "Add the class name below on the HTML tag that will trigger the lightbox."),
                        React.createElement(EmbedTextarea, { style: { height: 35, marginBottom: 8 } },
                            React.createElement("code", null,
                                "tourial-lightbox-trigger-",
                                fullTourialData.slug)))),
                    React.createElement("span", null, "Paste into your webpage where you want the Tourial to display:"),
                    React.createElement(EmbedTextarea, null,
                        React.createElement("code", null, makeEmbedCode(baseUrlWithSubdomain, fullTourialData.slug, fullTourialData.isVariantMobileEnabled, isLightboxEnabled, fullTourialData.mobileEmbedButton, fullTourialData.isEmbedHeaderEnabled, fullTourialData.isMobileButtonDisabled))),
                    React.createElement(EmbedButtonContainer, null,
                        wasCopied && React.createElement("span", { id: "_test_embed-code-copied" }, "Copied!"),
                        React.createElement(TButton, { id: "_test_embed-code-copy-button", variant: TButtonVariants.SECONDARY, size: "medium", onClick: () => {
                                copyToClipboard();
                                setWasCopied(true);
                            } }, "Copy Embed Code"))))),
            React.createElement(ButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.DEFAULT, onClick: () => {
                        setIsShareModalOpen(false);
                        setIsDropdownOpen(false);
                    } }, "Done")))));
}
const Container = styled.div `
  padding: 24px;
`;
const ButtonsContainer = styled.div `
  border-top: 1px solid ${newTheme.colors.grey300};
  height: 88px;
  width: 658px;
  display: flex;
  gap: 16px;
  padding: 24px;
  align-items: center;
  justify-content: right;
`;
const Body = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  width: 658px;
  //height: ${props => (props.isLightboxEnabled ? 536 : 456)}px;
  background-color: ${newTheme.colors.grey100};
`;
const Div = styled.div `
  width: 658px;
`;
