import React, { useState } from "react";
import styled from "styled-components";
import { useHTStep, useHTScale, useHTDimensions, useHTMode, useHTIsMobile, useHTIsEditingHtml, useHTSteps, useHTLoadedCaptureId, } from "../../../../../redux/selectors/ht-selectors";
import { DTStepType, HTMode } from "../../../../../../../shared/types/dom-tour";
import { EDITING_Z_INDEX } from "../../config";
import { useHTUpdateIsMobile, useHTUpdateScale, useHTViewportData } from "../../hooks/ht-hooks";
import { Screen } from "./screen";
import { Intro } from "../tools/intro";
import { Outro } from "../tools/outro";
import { DTPageFormV3 } from "../../../../renderer/fetch-and-render-form-v3";
import { HTProgressBar } from "./ht-progress-bar";
import { useDemoCenter } from "../../../../../redux/selectors/demo-center-selectors";
import { HTTooltip } from "../tools/ht-tooltip";
import { uniq } from "lodash";
import { MobileToolContainer } from "../tools/mobile-tool-container";
export function HTTour() {
    const dimensions = useHTDimensions();
    const step = useHTStep();
    const steps = useHTSteps();
    const scale = useHTScale();
    const isMobile = useHTIsMobile();
    const demoCenter = useDemoCenter();
    const captureIds = uniq(steps.map(s => s.captureId));
    const mode = useHTMode();
    const viewportData = useHTViewportData();
    const isEditing = useHTIsEditingHtml();
    const [isLoading, setIsLoading] = useState(true);
    const loadedCaptureId = useHTLoadedCaptureId();
    useHTUpdateScale();
    useHTUpdateIsMobile();
    const renderDesktopTool = () => {
        const isLoaded = loadedCaptureId === step.captureId;
        if (!isLoaded)
            return null;
        switch (step.type) {
            case DTStepType.TOOLTIP:
                return React.createElement(HTTooltip, { key: step.id });
            case DTStepType.INTRO:
                return React.createElement(Intro, null);
            case DTStepType.OUTRO:
                return React.createElement(Outro, null);
            default:
                return null;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Root, { dimensions: dimensions, scale: scale, isEditing: isEditing, viewportData: viewportData },
            !isLoading && renderDesktopTool(),
            React.createElement(Screen, { setIsLoading: setIsLoading, captureIds: captureIds }),
            mode !== HTMode.EDIT && !isLoading && !demoCenter && React.createElement(DTPageFormV3, null)),
        !isMobile ? React.createElement(HTProgressBar, null) : React.createElement(MobileToolContainer, null)));
}
const Root = styled.div `
  top: ${({ viewportData }) => viewportData.top}px;
  left: ${({ viewportData }) => viewportData.left}px;
  width: ${({ dimensions }) => dimensions.width}px;
  height: ${({ dimensions }) => dimensions.height}px;
  aspect-ratio: ${({ dimensions }) => dimensions.width / dimensions.height};
  position: absolute;
  background-color: white;
  overflow: hidden;
  transform: scale(${({ scale }) => scale});
  transform-origin: 0 0;
  z-index: ${({ isEditing }) => (isEditing ? EDITING_Z_INDEX : "inherit")};
  > #tooltip {
    z-index: 2;
  }
`;
