import React from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import { useDCConfig, useDCDefaultFont, useDCLogo, useDCSetBodyConfig, useDCSetLogo, useDCUpdateFont, useDCStyles, useDCSetStyles, useDCSetTagConfig, useDCThemeType, useDCSetThemeType, useDCResetBorderStyles, useDCUpdateCustomTheme, } from "../hooks/dc-hooks";
import { TDropdownSelect } from "../../../styled/t-dropdown-select";
import { fontOptions } from "../../../../helpers/dropdown-options";
import TextIcon from "../../../../../assets/images/dropdown-text-icon.svg";
import { DCStylesLogoEditor } from "./components/dc-styles-logo-editor";
import { DCStylesBackgroundEditor } from "./components/dc-styles-background-editor";
import { DCStylesBorderRadiusEditor } from "./components/dc-styles-border-radius-editor";
import { DCStylesThemeSelector } from "./components/dc-styles-theme-selector";
import { DCStylesColorEditor } from "./components/dc-styles-color-editor";
export const DCStylesToolbar = () => {
    const logo = useDCLogo();
    const { body, tag } = useDCConfig();
    const defaultFont = useDCDefaultFont();
    const styles = useDCStyles();
    const themeType = useDCThemeType();
    const setBodyConfig = useDCSetBodyConfig();
    const setTagConfig = useDCSetTagConfig();
    const updateCustomTheme = useDCUpdateCustomTheme();
    const updateFont = useDCUpdateFont();
    const setLogo = useDCSetLogo();
    const setStyles = useDCSetStyles();
    const resetBorders = useDCResetBorderStyles();
    const setThemeType = useDCSetThemeType();
    return (React.createElement(Toolbar, null,
        React.createElement(DCStylesLogoEditor, { logo: logo, onChange: setLogo }),
        React.createElement(TDropdownSelect, { placeholder: "Select Font", options: fontOptions.map(o => (Object.assign(Object.assign({}, o), { icon: TextIcon }))), selectedValue: defaultFont, onChange: e => updateFont(e.value), isSearchable: true, style: { width: "200px" } }),
        React.createElement(Spacer, null),
        React.createElement(DCStylesThemeSelector, { themeType: themeType, onChange: setThemeType }),
        React.createElement(DCStylesColorEditor, { customTheme: styles.customTheme, onChange: (color, field) => updateCustomTheme(color, field) }),
        React.createElement(Spacer, null),
        React.createElement(DCStylesBackgroundEditor, { body: body, onChange: setBodyConfig }),
        React.createElement(Spacer, null),
        React.createElement(DCStylesBorderRadiusEditor, { styles: styles, onChange: setStyles, reset: resetBorders, tagConfig: tag, onTagConfigChange: setTagConfig })));
};
const Toolbar = styled.div `
  position: fixed;
  top: 56px;
  left: 0px;
  height: 48px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  height: 48px;
  border-bottom: 1px solid ${newTheme.colors.grey400};
  background-color: ${newTheme.colors.grey100};
  box-shadow:
    2px 2px 8px 0px rgba(48, 49, 51, 0.02),
    0px 0px 20px 0px rgba(48, 49, 51, 0.05);
  z-index: 20;
`;
const Spacer = styled.div `
  width: 1px;
  height: 32px;
  background-color: ${newTheme.colors.grey400};
  margin: 0px 8px;
`;
