import React from "react";
import { useHTGotoNextStep, useHTMode, useHTTheme } from "../../../../../redux/selectors/ht-selectors";
import { HTMode } from "../../../../../../../shared/types/dom-tour";
import styled, { css } from "styled-components";
import { HOTSPOT_SIZE } from "../../config";
import { useHTHandleClickEvent } from "../../hooks/ht-hooks";
import { hotspotAnimationBuilder } from "../../../../../helpers/dom-helpers";
import { hexToRGB } from "../../../../../helpers/misc";
import { newTheme } from "../../../../styled/theme";
export function Hotspot({ isHotspotVisible }) {
    var _a, _b, _c;
    const theme = useHTTheme();
    const mode = useHTMode();
    const gotoNextStep = useHTGotoNextStep();
    const htHandleClickEvent = useHTHandleClickEvent();
    return (React.createElement(Outer, { isHotspotVisible: isHotspotVisible, theme: theme, onClick: handleClick, mode: mode, isEnabled: ((_a = theme.tooltip.hotspot) === null || _a === void 0 ? void 0 : _a.isEnabled) ||
            (!((_b = theme.tooltip.nextButton) === null || _b === void 0 ? void 0 : _b.isEnabled) && !((_c = theme.tooltip.previousButton) === null || _c === void 0 ? void 0 : _c.isEnabled)) },
        React.createElement(Inner, { theme: theme })));
    function handleClick() {
        if (mode === HTMode.EDIT)
            return;
        htHandleClickEvent({ clickAction: "NEXT_STEP" });
        gotoNextStep();
    }
}
const animation = () => css `
  ${newTheme.animations.bounce} 1.5s ease-in-out;
`;
const Outer = styled.div `
  overflow: visible;
  background-color: ${({ theme }) => theme.tooltip.backgroundColor};
  border-radius: 50%;
  width: ${HOTSPOT_SIZE}px;
  height: ${HOTSPOT_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${({ isEnabled }) => (isEnabled ? "all" : "none")};
  visibility: ${({ isEnabled }) => (isEnabled ? "visible" : "hidden")};
  animation: ${({ isHotspotVisible }) => (isHotspotVisible ? animation() : "none")};
`;
const Inner = styled.div `
  overflow: visible;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  border-color: ${({ theme }) => theme.tooltip.backgroundColor};
  animation: ${({ theme }) => theme.tooltip.backgroundColor === "transparent"
    ? "none"
    : hotspotAnimationBuilder(hexToRGB(theme.tooltip.backgroundColor))};
`;
