import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DCModuleType, DCModuleStatus, } from "../../../../../../shared/types/demo-center";
import { dcGetRGBColorStringFromHex } from "../helpers/dc-helpers";
import { DCStyledBodyText, DCStyledTitleText } from "./dc-styled-text";
import { DCGridView } from "./dc-grid-view";
import { DCTagBox } from "./dc-tag-box";
import { useDCGetModuleStatus } from "../hooks/dc-module-hooks";
import { useDCConfigGatingFormID } from "../../../../redux/selectors/demo-center-selectors";
import { useSetPageForm } from "../../../../redux/selectors/product-page-selectors";
export const DCGallerySection = ({ gallerySectionConfig, itemCardConfig, tagConfig, theme, module, items, onCardSelect, onFilter, }) => {
    const { titleStyle, toggleStyle } = gallerySectionConfig;
    const { title, tracks, type } = module;
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const dcGetModuleStatus = useDCGetModuleStatus();
    const dcModuleStatus = dcGetModuleStatus(module.slug);
    const gatingFormId = useDCConfigGatingFormID();
    const setPageForm = useSetPageForm();
    useEffect(() => {
        const filteredItems = [];
        const ids = [];
        tracks.forEach(t => {
            t.items.forEach(id => {
                if (selectedFilters.includes(t.slug)) {
                    ids.push(id);
                }
            });
        });
        items.forEach(i => {
            if ([...new Set(ids)].includes(i.id)) {
                filteredItems.push(i);
            }
        });
        setFilteredItems(selectedFilters.length > 0 ? [...filteredItems] : [...items]);
    }, [selectedFilters]);
    const getRemainder = () => {
        return filteredItems.length - 4;
    };
    const filter = (tag, isSelected, selectedTags) => {
        setSelectedFilters(selectedTags);
        onFilter === null || onFilter === void 0 ? void 0 : onFilter(selectedTags);
    };
    return (React.createElement(SectionContainer, { config: gallerySectionConfig, theme: theme },
        React.createElement(SectionHeader, null,
            React.createElement(DCStyledTitleText, { config: titleStyle, style: { width: "100%" } }, title),
            tracks.length > 0 && type !== DCModuleType.BOOLEAN && type !== DCModuleType.NO_PROMPT && (React.createElement(DCTagBox, { theme: theme, tags: tracks.map(t => ({ id: t.slug, label: t.title })), selected: selectedFilters, tagConfig: tagConfig, onTagSelect: filter }))),
        React.createElement(Spacer, { color: theme.neutralSecondary }),
        React.createElement(DCGridView, { theme: theme, items: isExpanded ? filteredItems : filteredItems.slice(0, 4), itemCardConfig: itemCardConfig, onCardSelect: item => {
                if (dcModuleStatus === DCModuleStatus.LOCKED) {
                    setPageForm({
                        formId: gatingFormId,
                        isGated: false,
                        isEnabled: true,
                    });
                }
                else {
                    onCardSelect(item);
                }
            }, isLocked: dcModuleStatus === DCModuleStatus.LOCKED }),
        getRemainder() > 0 && (React.createElement(React.Fragment, null,
            React.createElement(Spacer, { color: theme.neutralSecondary }),
            React.createElement(SectionFooter, null,
                React.createElement(ToggleButton, { onClick: () => setIsExpanded(!isExpanded) },
                    React.createElement(DCStyledBodyText, { config: toggleStyle }, isExpanded ? "Show Less" : `+ ${getRemainder()} More`)))))));
};
const SectionContainer = styled.div `
  display: flex;
  width: 1082px;
  padding: 16px 24px;
  margin: 0px auto;
  flex-direction: column;
  gap: 16px;
  border-radius: ${p => p.config.borderRadius}px;
  border: 1px solid ${p => p.theme.neutralSecondary};
  background-color: ${p => dcGetRGBColorStringFromHex(p.theme.neutralTranslucentColor, p.theme.neutralTranslucentOpacity)};
  backdrop-filter: blur(10px);
  transform: translate3d(0, 0, 0);
  transition: height 0.2s ease-in;
`;
const SectionHeader = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;
const Spacer = styled.div `
  height: 0px;
  width: 100%;
  border-top: 1px solid ${p => p.color};
`;
const SectionFooter = styled.div `
  display: flex;
  justify-content: right;
`;
const ToggleButton = styled.div `
  cursor: pointer;
`;
