import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import TableSortIcon from "../../../../../assets/images/v3/table-sort-icon.svg";
import TableSortIconDesc from "../../../../../assets/images/v3/table-sort-icon-desc.svg";
import TableSortIconAsc from "../../../../../assets/images/v3/table-sort-icon-asc.svg";
import { useDCModules } from "../hooks/dc-module-hooks";
import { useDCGetItem } from "../hooks/dc-item-hooks";
import { DCBadgeType, DCItemType } from "../../../../../../shared/types/demo-center";
import { dcGenerateSlugFromText, dcGetItemDurationLabel, dcGetReadableItemType } from "../helpers/dc-helpers";
import { ensureHttpsInUrl } from "../../../../../../shared/functions/helpers";
import { DCBadge } from "../components/dc-badge";
import { defaultDemoCenter } from "../../../../../../shared/objects/default-demo-center";
import { useDCGoToTourV3Builder } from "../hooks/dc-builder-hooks";
var SortTypes;
(function (SortTypes) {
    SortTypes["TITLE"] = "title";
    SortTypes["SOURCE"] = "source";
    SortTypes["TRACK"] = "track";
})(SortTypes || (SortTypes = {}));
const columns = [
    { title: "Title", sort: SortTypes.TITLE },
    { title: "Source", sort: SortTypes.SOURCE },
    { title: "Content Track", sort: SortTypes.TRACK },
];
export const DCOverviewItemTable = () => {
    const [data, setData] = useState();
    const [sortedBy, setSortedBy] = useState(SortTypes.TITLE);
    const [sortOrderDesc, setSortOrderDesc] = useState(true);
    const [sortedData, setSortedData] = useState([]);
    const modules = useDCModules();
    const getItem = useDCGetItem();
    useEffect(() => {
        const items = [];
        if (!data) {
            modules.forEach(m => {
                m.tracks.forEach(t => {
                    t.items.forEach(i => {
                        const item = getItem(i);
                        items.push(Object.assign(Object.assign({}, item), { trackName: `${m.title} > ${t.title}` }));
                    });
                });
            });
            setData(items);
        }
    }, [modules]);
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.length) {
            const newData = [...data];
            switch (sortedBy) {
                case SortTypes.TITLE:
                    newData.sort((a, b) => (a.title < b.title ? 1 : -1));
                    break;
                case SortTypes.SOURCE:
                    newData.sort((a, b) => {
                        if (a.type === DCItemType.TOUR && b.type !== DCItemType.TOUR)
                            return 1;
                        if (a.type !== DCItemType.TOUR && b.type === DCItemType.TOUR)
                            return -1;
                        return a.url < b.url ? 1 : -1;
                    });
                    break;
                case SortTypes.TRACK:
                    newData.sort((a, b) => (a.trackName < b.trackName ? 1 : -1));
                    break;
            }
            setSortedData(!sortOrderDesc ? newData : newData.reverse());
        }
    }, [sortedBy, sortOrderDesc, data]);
    const sortBy = (type) => {
        setSortOrderDesc(type === sortedBy ? !sortOrderDesc : true);
        setSortedBy(type);
    };
    return (React.createElement(OuterTableContainer, null,
        React.createElement(TableHeader, null, columns.map((c, i) => (React.createElement(HeaderCell, { key: `column-${i}`, column: c, sortBy: sortBy, sortedBy: sortedBy, sortOrderDesc: sortOrderDesc })))),
        React.createElement(TableBody, null, sortedData.map(i => (React.createElement(RowItem, { key: dcGenerateSlugFromText(`${i.trackName}-${i.id}`), item: i }))))));
};
const HeaderCell = ({ column, sortBy, sortedBy, sortOrderDesc }) => {
    const { title, sort } = column;
    const icon = sortedBy === sort ? (sortOrderDesc ? TableSortIconDesc : TableSortIconAsc) : TableSortIcon;
    return (React.createElement(HeaderCellContainer, { style: { flex: 1 }, className: sort ? "sortable" : null, onClick: () => {
            if (sort) {
                sortBy(sort);
            }
        } },
        React.createElement("div", null,
            React.createElement("span", null, title),
            sort && React.createElement("img", { src: icon }))));
};
const RowItem = ({ item }) => {
    const goToV3Builder = useDCGoToTourV3Builder();
    const { id, title, url, type, trackName, duration } = item;
    const isTour = type === DCItemType.TOUR || type === DCItemType.DOM_TOUR;
    return (React.createElement(TableRowContainer, null,
        React.createElement(InnerRowContainer, null,
            React.createElement(RowCell, null,
                React.createElement(TitleContainer, { className: isTour ? "tour" : null, onClick: () => (isTour ? goToV3Builder(id, type) : null) },
                    React.createElement(Title, null, title),
                    React.createElement(ItemType, null,
                        dcGetReadableItemType(type),
                        duration && (React.createElement(DCBadge, { type: DCBadgeType.DURATION, config: defaultDemoCenter.config.badge, content: dcGetItemDurationLabel(duration) }))))),
            React.createElement(RowCell, null, isTour ? ("Tourial") : (React.createElement("a", { target: "_blank", href: ensureHttpsInUrl(url) }, url))),
            React.createElement(RowCell, null, trackName))));
};
const OuterTableContainer = styled.div `
  width: 100%;
`;
const TableHeader = styled.div `
  padding: 0 12px;
  display: flex;
  background-color: ${newTheme.colors.grey100};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  gap: 24px;
`;
const HeaderCellContainer = styled.div `
  padding: 8px 12px 8px 12px;
  font: ${newTheme.fonts.bold};
  color: ${newTheme.colors.grey800};
  > div {
    display: flex;
    align-items: center;
    height: 24px;
    img {
      margin-left: 8px;
    }
  }
  &.sortable {
    cursor: pointer;
    &:hover {
      background-color: ${newTheme.colors.grey200};
    }
  }
`;
const TableBody = styled.div `
  background-color: ${newTheme.colors.grey200};
  padding: 8px;
`;
const TableRowContainer = styled.div `
  display: flex;
  flex-direction: column;
  background-color: ${newTheme.colors.white};
  border: 1px solid ${newTheme.colors.grey300};
  border-radius: 4px;
  margin-bottom: 6px;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  overflow: hidden;
`;
const InnerRowContainer = styled.div `
  padding: 8px 0px;
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 58px;
  gap: 24px;
  align-items: center;
`;
const RowCell = styled.div `
  align-items: center;
  margin: 0 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
  a {
    color: ${newTheme.colors.blue500};
  }
`;
const TitleContainer = styled.div `
  display: flex;
  flex-direction: column;
  &.tour {
    cursor: pointer;
    &:hover > div {
      color: ${newTheme.colors.blue500};
    }
  }
`;
const Title = styled.div `
  color: ${newTheme.colors.black};
  font: ${newTheme.fonts.mediumBold};
`;
const ItemType = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey600};
  text-transform: uppercase;
`;
