import styled from "styled-components";
import React, { useEffect } from "react";
import { useEditDetailsId, useTool } from "../../../redux/selectors/redux-selectors";
import { RenderEditMenu } from "./render-edit-menu";
import { newTheme } from "../../styled/theme";
import { useDeselect } from "../../../redux/selectors/builder-selectors";
import { NAVBAR_HEIGHT } from "../../pages/builder/builder-page";
export function EditMenuContainer({ viewportDimensions }) {
    const toolId = useEditDetailsId();
    const deselect = useDeselect();
    const tool = useTool(toolId || "screen");
    useEffect(() => {
        if (!tool)
            deselect();
    }, [tool]);
    return (React.createElement(Div, { style: { height: `calc(100% - ${NAVBAR_HEIGHT}px - 1px)` } }, tool && React.createElement(RenderEditMenu, { toolData: tool, viewportDimensions: viewportDimensions })));
}
const Div = styled.div `
  transition: height ${newTheme.transitions.layoutShift};
  position: fixed;
  overflow: hidden;
  background-color: ${newTheme.colors.grey100};
  right: 0px;
  width: 320px;
  border-left: 1px solid ${newTheme.colors.grey300};
`;
