import { TooltipButtonStyle, } from "../../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import React from "react";
import { BubbleButtonIconContainer, bubbleButtonBorderRadii, bubbleButtonSidePadding } from "./bubble-styled";
export function BubbleButtonRight({ body, button, onClick, onDoubleClick }) {
    return (React.createElement("button", { style: {
            fontFamily: body.font,
            color: button.textColor,
            backgroundColor: button.backgroundColor,
            borderColor: button.borderColor,
            borderRadius: bubbleButtonBorderRadii[button.shape],
            justifyContent: "flex-end",
            paddingLeft: button.style === TooltipButtonStyle.ARROWS ? 6 : 12,
            paddingRight: bubbleButtonSidePadding[button.style],
        }, onClick: onClick, onDoubleClick: onDoubleClick },
        (button.style === TooltipButtonStyle.TEXT || button.style === TooltipButtonStyle.TEXT_AND_ARROWS) && button.text,
        (button.style === TooltipButtonStyle.ARROWS || button.style === TooltipButtonStyle.TEXT_AND_ARROWS) && (React.createElement(RightChevronIcon, { style: { marginLeft: button.style === TooltipButtonStyle.TEXT_AND_ARROWS ? 2 : 0 } }))));
}
const RightChevronIcon = ({ style }) => {
    return (React.createElement(BubbleButtonIconContainer, { style: style },
        React.createElement("svg", { width: "6", height: "9", viewBox: "0 0 6 9", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.834321 8.19318C0.521902 7.88076 0.521902 7.37422 0.834321 7.06181L3.46864 4.42749L0.834321 1.79318C0.521901 1.48076 0.521901 0.974224 0.834321 0.661805C1.14674 0.349385 1.65327 0.349385 1.96569 0.661805L5.16569 3.8618C5.47811 4.17422 5.47811 4.68076 5.16569 4.99318L1.96569 8.19318C1.65327 8.5056 1.14674 8.5056 0.834321 8.19318Z", fill: "currentColor" }))));
};
