import { cloneDeep } from "lodash";
import React from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { FormField } from "../subcomponents/styled-main-view";
export function CheckboxField(props) {
    var _a;
    const { setFormState, field, fieldIndex, formState, labelText } = props;
    return (React.createElement(FormField, { style: { display: "flex", alignItems: "center" } },
        React.createElement(CheckboxLabel, { style: { color: labelText.color } },
            React.createElement("input", { type: "checkbox", name: field.role, style: { marginRight: "8px" }, checked: ((_a = formState === null || formState === void 0 ? void 0 : formState[fieldIndex]) === null || _a === void 0 ? void 0 : _a.value) === true, onChange: e => {
                    if (setFormState) {
                        const nextState = cloneDeep(formState);
                        nextState[fieldIndex].value = e.target.checked;
                        setFormState(nextState);
                    }
                } }),
            field.label)));
}
const CheckboxLabel = styled.div `
  font: ${newTheme.fonts.small};
  display: flex;
  align-items: center;
`;
