import React, { useEffect } from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import { DCCMMenu } from "./components/dc-cm-menu";
import FullScreenIcon from "../../../../../assets/images/v3/full-screen.svg";
import { useDCAddModule, useDCGetModule, useDCGetNewModule, useDCModules, useDCReorderModulesByDragAndDrop, } from "../hooks/dc-module-hooks";
import { DCBuilderMode, DCCMPageType, DCModuleType, DCView, } from "../../../../../../shared/types/demo-center";
import { DCCMIntroPageEditor } from "./dc-cm-intro-page-editor";
import { useDCBuilderIsCollapsed, useDCLivePreviewPath, useDCSetBuilderMode, } from "../../../../redux/selectors/demo-center-selectors";
import { DCCMNavigationEditor } from "./dc-cm-navigation-editor";
import { DCCMModuleEditor } from "./dc-cm-module-editor";
import { Redirect, Route, Switch } from "react-router-dom";
import { MainAppPagePaths } from "../../../../../../shared/types/app-events";
import { useDCBuilderGoToPage, useDCBuilderToggleLivePreviewMode, useDCCMHomePath, useDCCMResizeMonitor, useDCGetBuilderWarning, } from "../hooks/dc-builder-hooks";
import { dcGenerateSlugFromText } from "../helpers/dc-helpers";
import { useDCConfig, useDCId, useDCUpdateFeaturedContentId } from "../hooks/dc-hooks";
import { DCCMGatedModulesEditor } from "./dc-cm-gated-modules-editor";
import { DCPreview } from "../preview/dc-preview";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { DCCMAdvancedSettings } from "./dc-cm-advanced-settings";
export const DCContentManager = () => {
    const setMode = useDCSetBuilderMode();
    const isCollapsed = useDCBuilderIsCollapsed();
    useDCUpdateFeaturedContentId();
    useDCCMResizeMonitor();
    useEffect(() => setMode(DCBuilderMode.MANAGE), []);
    return (React.createElement(ContentManagerContainer, null,
        React.createElement(Sidebar, null),
        React.createElement(ContentContainer, { className: isCollapsed ? "collapsed" : null },
            React.createElement(DCCMRouter, null)),
        React.createElement(Preview, null)));
};
const Sidebar = () => {
    const activePage = window.location.pathname.split("/").pop();
    const modules = useDCModules();
    const getWarning = useDCGetBuilderWarning(true);
    const addModule = useDCAddModule();
    const getNewModule = useDCGetNewModule();
    const reorder = useDCReorderModulesByDragAndDrop();
    const goToPage = useDCBuilderGoToPage();
    const handleAddButtonClick = () => {
        const module = getNewModule();
        addModule(module);
        goToPage(`module/${module.slug}`);
    };
    return (React.createElement(SidebarContainer, null,
        React.createElement(DCCMMenu, { modules: modules, activePage: activePage, onModuleClick: m => goToPage(`module/${m.slug}`), onPageClick: p => goToPage(`manage/${dcGenerateSlugFromText(p)}`), onAddButtonClick: handleAddButtonClick, onDragEnd: reorder, getWarning: getWarning })));
};
const DCCMRouter = () => {
    const id = useDCId();
    const introPath = `${DCBuilderMode.MANAGE}/${dcGenerateSlugFromText(DCCMPageType.INTRO_PAGE)}`;
    const homePath = useDCCMHomePath();
    const DCCMRoot = `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/${DCBuilderMode.MANAGE}`;
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: DCCMRoot, component: () => React.createElement(Redirect, { to: `${MainAppPagePaths.DEMOCENTER_BUILDER}/${id}/${homePath}` }) }),
        React.createElement(Route, { path: `${DCCMRoot}/${dcGenerateSlugFromText(DCCMPageType.NAVIGATION)}`, component: DCCMNavigationEditor }),
        React.createElement(Route, { path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/${introPath}`, component: DCCMIntroPageEditor }),
        React.createElement(Route, { path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/module/:moduleId`, component: DCCMModuleEditor }),
        React.createElement(Route, { path: `${DCCMRoot}/${dcGenerateSlugFromText(DCCMPageType.GATED_MODULES)}`, component: DCCMGatedModulesEditor }),
        React.createElement(Route, { path: `${DCCMRoot}/${dcGenerateSlugFromText(DCCMPageType.ADVANCED_SETTINGS)}`, component: DCCMAdvancedSettings })));
};
const Preview = () => {
    const livePreviewPath = useDCLivePreviewPath();
    const goToPage = useDCBuilderGoToPage();
    const getModule = useDCGetModule();
    const isCollapsed = useDCBuilderIsCollapsed();
    const toggleLivePreview = useDCBuilderToggleLivePreviewMode();
    const { welcome } = useDCConfig();
    let module;
    let itemId;
    const getView = () => {
        var _a;
        switch (livePreviewPath) {
            case DCCMPageType.INTRO_PAGE:
                return DCView.WELCOME;
            case DCCMPageType.NAVIGATION:
                return DCView.HEADER_ONLY;
            default:
                module = getModule(livePreviewPath);
                if ((module === null || module === void 0 ? void 0 : module.type) === DCModuleType.NO_PROMPT) {
                    itemId = (_a = module.tracks[0]) === null || _a === void 0 ? void 0 : _a.items[0];
                    return DCView.ITEM;
                }
                return DCView.MODULE;
        }
    };
    const isPreviewDisabled = () => {
        if ((module === null || module === void 0 ? void 0 : module.type) === DCModuleType.NO_PROMPT) {
            return !itemId;
        }
        if (livePreviewPath === DCCMPageType.INTRO_PAGE) {
            return !welcome.isEnabled;
        }
        return false;
    };
    return (React.createElement(PreviewContainer, { className: isCollapsed ? "collapsed" : null },
        React.createElement(PreviewWrapper, null,
            React.createElement(DCPreview, { view: getView(), module: module, itemId: itemId, scaledDown: true })),
        React.createElement(PreviewButtonContainer, null,
            React.createElement(TButton, { variant: TButtonVariants.OUTLINE, size: "medium", onClick: toggleLivePreview, icon: FullScreenIcon, iconPlacement: "left", disabled: isPreviewDisabled() }, "Fullscreen preview"),
            React.createElement(TButton, { variant: TButtonVariants.OUTLINE, size: "medium", onClick: () => goToPage(DCBuilderMode.STYLES) }, "Go to edit styles"))));
};
const ContentManagerContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: ${newTheme.colors.grey100};
  min-height: calc(100vh - 82px);
`;
const SidebarContainer = styled.div `
  position: fixed;
  top: 56px;
  left: 0;
  width: 332px;
  height: calc(100vh - 56px);
  padding: 24px 24px 84px 24px;
  background-color: ${newTheme.colors.white};
  border-right: 1px solid ${newTheme.colors.grey300};
  overflow-y: auto;
`;
const ContentContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 56px 70px 160px 332px;
  background-color: ${newTheme.colors.white};
  &.collapsed {
    padding-top: 24px;
    padding-right: 24px;
  }
`;
const PreviewContainer = styled.div `
  position: fixed;
  top: 56px;
  right: 0;
  width: calc(100vw - 332px - 687px - 72px);
  margin-left: 72px;
  padding: 70px 24px 0 24px;
  &.collapsed {
    width: calc(100vw - 940px);
    margin-left: 0;
    padding-top: 24px;
  }
`;
const PreviewWrapper = styled.div `
  border: 1px solid ${newTheme.colors.grey400};
  box-shadow:
    0px 0px 1px 0px rgba(33, 34, 41, 0.4) inset,
    2px 2px 8px 0px rgba(33, 34, 41, 0.02),
    0px 0px 20px 0px rgba(48, 49, 51, 0.05);
`;
const PreviewButtonContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
  z-index: 10;
`;
