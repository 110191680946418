import React from "react";
import { usePushHistory, useSetTourialName, useTourialName, useViewportMode, } from "../../../../redux/selectors/redux-selectors";
import { NameInput } from "./name-input";
import { ViewportMode } from "../../../../types/definitions";
export function TourNameInput() {
    const tourialName = useTourialName();
    const setTourialName = useSetTourialName();
    const pushHistory = usePushHistory();
    const viewportMode = useViewportMode();
    const setTourialNameAndPushHistory = (name) => {
        pushHistory();
        setTourialName(name);
    };
    return (React.createElement(NameInput, { id: "_test_navbar-tourial-name-input", disabled: viewportMode === ViewportMode.PREVIEW, name: tourialName, setName: setTourialNameAndPushHistory }));
}
