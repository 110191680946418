import React from "react";
export function ResizeHandle({ dir }) {
    const commonHandleStyle = {
        zIndex: 1,
        width: 9,
        height: 9,
        position: "relative",
        backgroundColor: "white",
        border: "1px solid black",
    };
    const horizontalContainerStyle = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };
    const verticalContainerStyle = {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };
    switch (dir) {
        case "top":
            return (React.createElement("div", { style: horizontalContainerStyle },
                React.createElement("div", { style: Object.assign(Object.assign({}, commonHandleStyle), { top: 0 }) })));
        case "bottom":
            return (React.createElement("div", { style: horizontalContainerStyle },
                React.createElement("div", { style: Object.assign(Object.assign({}, commonHandleStyle), { bottom: -1 }) })));
        case "left":
            return (React.createElement("div", { style: verticalContainerStyle },
                React.createElement("div", { style: Object.assign(Object.assign({}, commonHandleStyle), { left: -1 }) })));
        case "right":
            return (React.createElement("div", { style: verticalContainerStyle },
                React.createElement("div", { style: Object.assign(Object.assign({}, commonHandleStyle), { right: 0 }) })));
    }
}
export function resizeHandles(isDisabled) {
    if (isDisabled) {
        return null;
    }
    else {
        return {
            top: React.createElement(ResizeHandle, { dir: "top" }),
            bottom: React.createElement(ResizeHandle, { dir: "bottom" }),
            left: React.createElement(ResizeHandle, { dir: "left" }),
            right: React.createElement(ResizeHandle, { dir: "right" }),
        };
    }
}
