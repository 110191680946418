import React, { useState } from "react";
import { useUpdateTool } from "../../../redux/selectors/redux-selectors";
import { getBorderStylesFromProps, validateEmail } from "../../../helpers/misc";
import { withToolWrapper } from "../with-tool-wrapper";
import { cloneDeep } from "lodash";
export function isFieldValid(field) {
    return field.role === "Email" ? validateEmail(field.value) : !!field.value;
}
function TourialFormInputs(props) {
    const updateTool = useUpdateTool();
    const { toolData } = props;
    const { fields, enableBackground, backgroundColor, borderColor, borderWidth, fontFamily, fontWeight, fontColor, fontSize, id, } = toolData;
    const [wasInitiallyValidated, setWasInitiallyValidated] = useState(false);
    const [validationMap, setValidationMap] = useState(fields.map(() => false));
    function getInputWidth(numFields, i) {
        if (numFields % 2 !== 0) {
            if (i === fields.length - 1) {
                return "calc(100% - 10px)";
            }
        }
        return "calc(50% - 10px)";
    }
    function getInputHeight(numFields) {
        return `calc(${167 / numFields}% - 10px)`;
    }
    return (React.createElement("div", { key: id, style: { width: `100%`, height: `100%`, position: "absolute" }, id: id },
        React.createElement("div", { style: {
                width: `100%`,
                height: `100%`,
                overflow: "visible",
                margin: "0 auto",
            } }, fields.map((f, i) => {
            return (React.createElement("input", { type: "text", key: i, placeholder: f.role, value: f.value, autoComplete: "off", onChange: e => {
                    const newTool = cloneDeep(toolData);
                    newTool.fields[i] = { role: f.role, value: e.target.value };
                    updateTool(newTool);
                }, onBlur: () => {
                    const newValidationMap = [...validationMap];
                    newValidationMap[i] = isFieldValid(fields[i]);
                    setValidationMap(newValidationMap);
                    setWasInitiallyValidated(true);
                }, style: Object.assign(Object.assign({}, getBorderStylesFromProps(props)), { borderColor: wasInitiallyValidated && !validationMap[i] ? "red" : borderColor, borderWidth: wasInitiallyValidated && !validationMap[i] ? 2 : borderWidth, backgroundColor: enableBackground ? backgroundColor : null, height: getInputHeight(fields.length), width: getInputWidth(fields.length, i), margin: 5, padding: 5, fontFamily, fontWeight: +fontWeight, fontSize, color: fontColor }) }));
        }))));
}
export const FormInputs = React.memo(withToolWrapper(TourialFormInputs));
