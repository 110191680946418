import { useFetchFormOnStep, useFetchPageForm } from "../../hooks/use-fetch-form";
import { FormV3TourView, FormV3PageView, FormViewFullscreenContainer, FormV3PageViewDT, } from "../tools/form-v3/view/form-v3-view";
import React from "react";
import { useViewportIsTransitioning } from "../../redux/selectors/redux-selectors";
import { TLoadingSpinner } from "../styled/t-loading-spinner";
import { useHTFetchPageForm } from "../../redux/selectors/ht-selectors";
let hasShownError = false;
export function TourFormV3({ baseViewProps }) {
    const formOnStep = useFetchFormOnStep();
    const pageForm = useFetchPageForm();
    const isViewportTransitioning = useViewportIsTransitioning();
    if (isViewportTransitioning)
        return null;
    if (formOnStep.isLoading) {
        return (React.createElement(FormViewFullscreenContainer, null,
            React.createElement(TLoadingSpinner, null)));
    }
    else if (formOnStep.hasError && !hasShownError) {
        console.error("failed to fetch form");
        hasShownError = true;
        return null;
    }
    else if (formOnStep.formData) {
        return React.createElement(FormV3TourView, Object.assign({}, Object.assign(Object.assign({}, baseViewProps), { toolData: formOnStep.formData })));
    }
    else if (pageForm.formData) {
        return null;
    }
    return null;
}
export function PageFormV3() {
    const pageForm = useFetchPageForm();
    if (pageForm.isLoading) {
        return (React.createElement(FormViewFullscreenContainer, null,
            React.createElement(TLoadingSpinner, null)));
    }
    else if (pageForm.hasError && !hasShownError) {
        console.error("failed to fetch form");
        hasShownError = true;
        return null;
    }
    else if (pageForm.formData) {
        const baseViewProps = {
            disableInteractions: false,
            disableAnimations: false,
            disableEventTracking: true,
            isInEditViewport: false,
        };
        return React.createElement(FormV3PageView, Object.assign({}, Object.assign(Object.assign(Object.assign({}, baseViewProps), { toolData: pageForm.formData }), { isPageForm: true })));
    }
    return null;
}
export function DTPageFormV3() {
    const pageForm = useHTFetchPageForm();
    if (pageForm.isLoading) {
        return (React.createElement(FormViewFullscreenContainer, null,
            React.createElement(TLoadingSpinner, null)));
    }
    else if (pageForm.hasError && !hasShownError) {
        console.error("failed to fetch dt form");
        hasShownError = true;
        return null;
    }
    else if (pageForm.formData) {
        const baseViewProps = {
            disableInteractions: false,
            disableAnimations: false,
            disableEventTracking: true,
            isInEditViewport: false,
        };
        return React.createElement(FormV3PageViewDT, Object.assign({}, Object.assign(Object.assign(Object.assign({}, baseViewProps), { toolData: pageForm.formData }), { isPageForm: true })));
    }
    return null;
}
