var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { TourialApiCalls } from "../helpers/tourial-api-calls";
import { Colors, defaultTheme } from "../../../shared/objects/default-theme";
export const useFetchTheme = () => {
    const [theme, setTheme] = useState(defaultTheme);
    const { error, isLoading } = useAsync({
        promiseFn: TourialApiCalls.Themes.getDefaultTheme,
        onResolve: data => {
            if (data) {
                setTheme(data);
            }
        },
    });
    return { theme, error, isLoading };
};
export const useFetchThemePrimaryColor = (accountId) => {
    const [primaryColor, setPrimaryColor] = useState(Colors.PRIMARY);
    const { run } = useAsync({
        deferFn: ([accountId]) => __awaiter(void 0, void 0, void 0, function* () {
            const color = yield TourialApiCalls.Themes.getThemePrimaryColor(accountId);
            if (color) {
                setPrimaryColor(color);
            }
        }),
    });
    useEffect(() => {
        if (accountId) {
            run(accountId);
        }
    }, [accountId]);
    return primaryColor;
};
