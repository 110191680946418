import React, { useState } from "react";
import { useEditDetailsId, useIsToolEditingDetails, useIsToolHovered, useSetEditDetailsId, useUpdateBuilder, } from "../../../redux/selectors/redux-selectors";
import { useDeleteTool, useDeselect, useIsToolSelected } from "../../../redux/selectors/builder-selectors";
import { useSelectionWrapperStyle } from "../../../hooks/use-selection-wrapper-style";
import { ContextMenu } from "./context-menu";
export function SelectionWrapper({ toolId, children, traits, overloads }) {
    const editingToolId = useEditDetailsId();
    const openEditPanel = useSetEditDetailsId();
    const updateBuilder = useUpdateBuilder();
    const isHovered = useIsToolHovered(toolId);
    const isSelected = useIsToolSelected(toolId);
    const isEditing = useIsToolEditingDetails(toolId);
    const deleteTool = useDeleteTool();
    const deselect = useDeselect();
    const [style, setStyle] = useState({});
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [contextMenuParams, setContextMenuParams] = useState({
        id: null,
        x: null,
        y: null,
        onDelete: () => { },
        onEdit: () => { },
    });
    const openContextMenu = ({ toolId, x, y, onDelete, onEdit }) => {
        setIsContextMenuOpen(true);
        setContextMenuParams({ id: toolId, x, y, onDelete, onEdit });
    };
    useSelectionWrapperStyle(toolId, (overloads === null || overloads === void 0 ? void 0 : overloads.setStyle) || setStyle, traits.dashedOutline);
    return (React.createElement("div", { style: { width: "100%", height: "100%", position: "absolute" }, onClick: e => {
            e.stopPropagation();
            if (traits.selectable && !isSelected)
                updateBuilder({ selectedToolId: toolId });
            if (traits.selectable && !isEditing)
                editingToolId === toolId ? deselect() : openEditPanel(toolId);
        }, onMouseOver: e => {
            e.stopPropagation();
            if (traits.selectable && !isHovered)
                updateBuilder({ hoveredToolId: toolId });
        }, onMouseOut: e => {
            e.stopPropagation();
            if (traits.selectable && isHovered)
                updateBuilder({ hoveredToolId: "" });
        }, onContextMenu: e => {
            e.preventDefault();
            openContextMenu({
                toolId,
                x: e.pageX,
                y: e.pageY,
                onDelete: deleteTool,
                onEdit: openEditPanel,
            });
        } },
        React.createElement("div", { style: Object.assign(Object.assign({}, ((overloads === null || overloads === void 0 ? void 0 : overloads.setStyle) ? {} : style)), { width: "100%", height: "100%" }) }, children),
        isContextMenuOpen && (React.createElement(ContextMenu, { params: contextMenuParams, onClose: () => {
                setIsContextMenuOpen(false);
            } }))));
}
