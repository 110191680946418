import React from "react";
import Select, { components as ReactSelectComponents } from "react-select";
import styled from "styled-components";
import { newTheme } from "./theme";
import { PAGE_HEADER_Z_INDEX } from "../../types/definitions";
export function TDropdownSelect(props) {
    const { options, selectedValue, style, id, placeholder, singleValueStyle, controlStyle, isSearchable = true, isMulti = false, disabled, components, selectByLabel, errored = false, } = props;
    return (React.createElement(SelectWrapper, { style: style, id: id },
        React.createElement(Select, Object.assign({ isMulti: isMulti, closeMenuOnSelect: isMulti ? false : true, components: Object.assign({ Option }, components), placeholder: placeholder, isDisabled: disabled, options: options, styles: customStyles({ singleValueStyle, controlStyle, isMulti, errored, disabled }), value: findSelectedValueInOptions(selectedValue, options, selectByLabel, isMulti), classNamePrefix: "tourial-dropdown", 
            // don't append to body, introduces hard to trace bugs.
            // But need to portal for z-indexing against non-siblings & overriding overflow hidden
            // using any wrapper not the viewport size doesn't work, see => https://github.com/JedWatson/react-select/pull/3086#issuecomment-428443568
            menuPortalTarget: document.querySelector("#app"), menuPlacement: "auto", isSearchable: isSearchable }, props, { theme: selectTheme => (Object.assign(Object.assign({}, selectTheme), { colors: Object.assign(Object.assign({}, selectTheme.colors), { primary25: newTheme.colors.blue100, primary50: newTheme.colors.blue200, primary75: newTheme.colors.blue400, primary: newTheme.colors.blue500 }) })), 
            // only workaround to hide broken position with portals
            closeMenuOnScroll: (event) => {
                try {
                    return event.target.id.substr(-8) !== "-listbox";
                }
                catch (_a) {
                    return false;
                }
            }, blurInputOnSelect: isMulti ? false : true }))));
}
const Option = (props) => {
    const { label, data, isSelected } = props;
    return (React.createElement(ReactSelectComponents.Option, Object.assign({}, props),
        data.icon && (React.createElement(OptionIconContainer, null,
            React.createElement("img", { src: isSelected && data.selectedIcon ? data.selectedIcon : data.icon }))),
        React.createElement(OptionLabel, null, label)));
};
const findSelectedValueInOptions = (selectedValue, options, selectByLabel, isMulti) => {
    let foundOption;
    if (isMulti) {
        return selectedValue;
    }
    if (selectByLabel) {
        foundOption = options.find(obj => obj.label === selectedValue.label);
    }
    else {
        options.forEach(opt => {
            var _a;
            if (((_a = opt.options) === null || _a === void 0 ? void 0 : _a.length) && !foundOption) {
                foundOption = opt.options.find(obj => obj.value === selectedValue);
            }
            else if (opt.value === selectedValue) {
                foundOption = opt;
            }
        });
    }
    if (selectedValue === undefined || selectedValue === null) {
        return null;
    }
    return foundOption;
};
export const LoadingPlaceholderDropdown = ({ style }) => {
    return (React.createElement(TDropdownSelect, { isSearchable: false, disabled: true, style: style, placeholder: "Loading...", options: [], onChange: () => { } }));
};
const SelectWrapper = styled.div `
  margin: 8px 0 8px 0;
`;
const icon = icon => icon
    ? {
        backgroundImage: icon ? `url(${icon})` : "none",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundSize: "20px",
        paddingLeft: "24px",
        marginLeft: "-4px",
    }
    : {};
const customStyles = ({ singleValueStyle, controlStyle, isMulti, errored, disabled }) => ({
    control: (provided, state) => (Object.assign(Object.assign(Object.assign({}, provided), { borderColor: errored ? newTheme.colors.red500 : state.isFocused ? newTheme.colors.blue : newTheme.colors.grey600, boxShadow: state.isFocused ? `0 0 4px ${newTheme.colors.blue}` : "none", minHeight: 32, height: isMulti ? "auto" : 32, alignContent: "center", justifyContent: "center" }), controlStyle)),
    indicatorSeparator: () => ({
        display: "none",
    }),
    dropdownIndicator: provided => (Object.assign(Object.assign({}, provided), { color: newTheme.colors.blue, alignContent: "center", justifyContent: "center", opacity: disabled ? 0 : 1 })),
    clearIndicator: provided => (Object.assign(Object.assign({}, provided), { alignContent: "center", justifyContent: "center" })),
    input: provided => (Object.assign(Object.assign({}, provided), { font: newTheme.fonts.normal, minHeight: 32, height: 32, alignContent: "center" })),
    singleValue: (provided, { data }) => (Object.assign(Object.assign(Object.assign(Object.assign({}, provided), { font: newTheme.fonts.normal }), icon(data.icon)), singleValueStyle)),
    multiValue: provided => {
        return Object.assign(Object.assign({}, provided), { font: newTheme.fonts.normal, backgroundColor: newTheme.colors.blue50, maxWidth: 80, minHeight: 26 });
    },
    option: (provided, { data }) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, provided), { display: "flex", font: newTheme.fonts.normal }), (data.fontSize && { fontSize: data.fontSize })), (data.fontFamily && { fontFamily: data.fontFamily })), { cursor: "pointer", alignContent: "center", justifyContent: "center", overflowWrap: "anywhere" })),
    menuPortal: provided => (Object.assign(Object.assign({}, provided), { zIndex: PAGE_HEADER_Z_INDEX + 101 })),
    valueContainer: provided => (Object.assign(Object.assign({}, provided), { padding: "2px 0px 2px 8px" })),
});
const OptionIconContainer = styled.div `
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-left: -4px;
  width: 20px;
  img {
    width: 20px;
    height: 20px;
  }
`;
const OptionLabel = styled.span `
  display: flex;
  flex: 1;
  /* white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word; */
`;
