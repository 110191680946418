import React, { useContext, useRef } from "react";
import styled from "styled-components";
import { EDIT_PANEL_WIDTH, STEPS_PANEL_CARD_WIDTH, STEPS_PANEL_HEIGHT } from "../../config";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import { useHTSetIsStepsPanelOpened, useHTStepsPanelHeight, useHTSteps, useHTTour, } from "../../../../../redux/selectors/ht-selectors";
import { useHTFetchCaptureLibrary, useHTHasIntro, useHTHasOutro } from "../../hooks/ht-hooks";
import { newTheme } from "../../../../styled/theme";
import { HTStepPreview } from "./ht-step-preview";
import { DraftContext } from "../dom-tour-builder-page";
import { DTGenericInteraction, DTInteraction, DTStepType, DTStyle, } from "../../../../../../../shared/types/dom-tour";
import { v4 as uuidv4 } from "uuid";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { moveArrayElement } from "../../../../../../../shared/functions/helpers";
import { cloneDeep } from "lodash";
import { AddScreenButton } from "../../../builder/storyboard-mini/add-screen-button";
import { HorizontalScrollArrows } from "../../../builder/storyboard-mini/horizontal-scroll-arrows";
import { HTStepsPanelStub } from "./ht-steps-panel-stub";
export function HTStepsPanel({ setLibraryModalOpen }) {
    const draft = useContext(DraftContext);
    const tour = useHTTour();
    const library = useHTFetchCaptureLibrary();
    const hasIntro = useHTHasIntro();
    const hasOutro = useHTHasOutro();
    const steps = useHTSteps();
    const ref = useRef();
    const setIsStepsPanelOpened = useHTSetIsStepsPanelOpened();
    const stepsPanelHeight = useHTStepsPanelHeight();
    if (library.error)
        throw new Error(`failed to fetch libary: ${library.error}`);
    if (!library.data) {
        return null;
    }
    const renderModalStep = (type) => {
        if (type === DTStepType.INTRO && !hasIntro)
            return null;
        if (type === DTStepType.OUTRO && !hasOutro)
            return null;
        if (!steps.length)
            return null;
        const modalStepIndex = type === DTStepType.INTRO ? 0 : steps.length - 1;
        const modalStep = type === DTStepType.INTRO ? steps.slice(modalStepIndex, 1) : steps.slice(modalStepIndex);
        return (React.createElement(React.Fragment, null, modalStep.map(s => {
            const preview = library.data.find(c => c.id === s.captureId);
            return (React.createElement(Container, { key: s.id },
                React.createElement(HTStepPreview, { index: modalStepIndex, data: preview, setName: setName, step: s })));
            function setName(newName) {
                const stepsCopy = cloneDeep(steps);
                stepsCopy[0].name = newName;
                void draft.update({ steps: stepsCopy });
            }
        })));
    };
    const nonModalSteps = () => {
        let startIndex = 0;
        let endIndex = undefined;
        if (hasIntro)
            startIndex = 1;
        if (hasOutro)
            endIndex = (steps === null || steps === void 0 ? void 0 : steps.length) - 1;
        return steps.slice(startIndex, endIndex);
    };
    return (React.createElement(Root, { stepsPanelHeight: stepsPanelHeight, id: "ht-steps-panel" },
        React.createElement(HTStepsPanelStub, { stepsPanelHeight: stepsPanelHeight, setIsStepsPanelOpened: setIsStepsPanelOpened, setIsAddScreenModalOpen: openLibrary }),
        React.createElement(HorizontalScrollArrows, { sidebarWidth: -40, storyboardHeight: stepsPanelHeight, storyboardRef: ref, editPanelWidth: EDIT_PANEL_WIDTH, zIndex: 2 }),
        React.createElement(ScrollWrapper, { ref: ref },
            !hasIntro && (React.createElement(TButton, { onClick: handleAddIntro, variant: TButtonVariants.TERTIARY, size: "small" }, "Add Intro")),
            renderModalStep(DTStepType.INTRO),
            React.createElement(DragDropContext, { onDragStart: () => { }, onDragEnd: onDragEnd },
                React.createElement(Droppable, { droppableId: "all-html-tours", direction: "horizontal" }, provided => (React.createElement(DroppableContainer, Object.assign({}, provided.droppableProps, { ref: provided.innerRef }),
                    nonModalSteps().map((s, i) => {
                        const preview = library.data.find(c => c.id === s.captureId);
                        const index = hasIntro ? i + 1 : i;
                        return (React.createElement(Draggable, { draggableId: s.id, index: index, key: s.id }, provided => (React.createElement(Container, Object.assign({}, provided.draggableProps, provided.dragHandleProps, { ref: provided.innerRef }),
                            React.createElement(HTStepPreview, { key: s.id, index: index, data: preview, setName: setName, step: s, setIsAddScreenModalOpen: openLibrary })))));
                        function setName(newName) {
                            const stepsCopy = cloneDeep(tour.steps);
                            stepsCopy[index].name = newName;
                            void draft.update({ steps: stepsCopy });
                        }
                    }),
                    provided.placeholder)))),
            renderModalStep(DTStepType.OUTRO),
            React.createElement(AddScreenButton, { setIsAddScreenModalOpen: openLibrary }),
            !hasOutro && (React.createElement(TButton, { onClick: handleAddOutro, variant: TButtonVariants.TERTIARY, size: "small" }, "Add Outro")))));
    function handleAddIntro() {
        var _a;
        if (!((_a = steps === null || steps === void 0 ? void 0 : steps[0]) === null || _a === void 0 ? void 0 : _a.captureId)) {
            throw new Error("addIntro requires a first step with a captureId");
        }
        const step = {
            id: uuidv4(),
            name: "Intro",
            type: DTStepType.INTRO,
            captureId: steps[0].captureId,
            intro: {
                header: "Welcome to my tour",
                body: "This will be the best tour you have ever seen!",
                buttonText: "Let's get started",
            },
        };
        void draft.update({ steps: [step, ...steps] });
    }
    function handleAddOutro() {
        if (!steps.length || !steps[steps.length - 1].captureId) {
            throw new Error("addOutro requires a last step with a captureId");
        }
        const step = {
            id: uuidv4(),
            name: "Outro",
            type: DTStepType.OUTRO,
            captureId: steps[steps.length - 1].captureId,
            outro: {
                header: "Tour completed!",
                body: "Select an option.",
                buttons: [
                    {
                        text: "CTA",
                        style: DTStyle.DEFAULT,
                        action: DTInteraction.LINK,
                        href: "",
                    },
                    {
                        text: "Restart",
                        style: DTStyle.DEFAULT,
                        action: DTGenericInteraction.RESTART,
                    },
                ],
            },
        };
        void draft.update({ steps: [...steps, step] });
    }
    function openLibrary() {
        setLibraryModalOpen(true);
    }
    function onDragEnd(result) {
        const { destination, source } = result;
        if (!destination)
            return;
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;
        const updatedSteps = moveArrayElement(steps, source.index, destination.index);
        void draft.update({ steps: updatedSteps });
    }
}
const DroppableContainer = styled.div `
  display: flex;
`;
const Container = styled.div `
  height: ${STEPS_PANEL_HEIGHT - 11}px;
  width: ${STEPS_PANEL_CARD_WIDTH - 9}px;
  margin: 0 2.5px;
  position: relative;
`;
const Root = styled.div `
  background-color: ${newTheme.colors.white};
  border-top: 1px solid ${newTheme.colors.grey200};
  padding: 5px;
  width: calc(100% - ${EDIT_PANEL_WIDTH}px);
  height: ${({ stepsPanelHeight }) => stepsPanelHeight}px;
  transition: height ${newTheme.transitions.layoutShift};
  position: absolute;
  margin: 0;
  bottom: 0;
  overflow: hidden;
`;
const ScrollWrapper = styled.div `
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  padding: 0px 44px;
`;
