import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { usePromptBeforeUnload } from "../../hooks/use-prompt-before-unload";
import TButton, { TButtonVariants } from "./t-button";
import TModal, { TModalButtonsContainer } from "./t-modal";
import { newTheme } from "./theme";
export function TPrompt({ isEnabled, headerText, bodyText, cancelButtonText, continueButtonText, onCancelButtonClick, }) {
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");
    usePromptBeforeUnload(isEnabled);
    const unblockRef = useRef();
    useEffect(() => {
        // @ts-expect-error
        unblockRef.current = history.block(location => {
            if (isEnabled) {
                setCurrentPath(location.pathname);
                setShowPrompt(true);
                return false;
            }
            return true;
        });
        return () => {
            var _a;
            (_a = unblockRef === null || unblockRef === void 0 ? void 0 : unblockRef.current) === null || _a === void 0 ? void 0 : _a.call(unblockRef);
        };
    }, [isEnabled]);
    const continueToRoute = () => {
        var _a;
        (_a = unblockRef === null || unblockRef === void 0 ? void 0 : unblockRef.current) === null || _a === void 0 ? void 0 : _a.call(unblockRef);
        setShowPrompt(false);
        history.push(currentPath);
    };
    return showPrompt ? (React.createElement(TModal, { title: headerText, onClose: () => {
            setShowPrompt(false);
        } },
        React.createElement("div", null,
            React.createElement(Span, null, bodyText),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: continueToRoute }, continueButtonText),
                React.createElement(TButton, { onClick: () => {
                        onCancelButtonClick === null || onCancelButtonClick === void 0 ? void 0 : onCancelButtonClick();
                        setShowPrompt(false);
                    } }, cancelButtonText))))) : null;
}
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  height: 68px;
  width: 510px;
`;
