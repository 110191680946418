import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../actions/redux-actions";
export const useSetPageForm = () => {
    const dispatch = useDispatch();
    return (pageForm) => {
        dispatch(Object.assign({ type: ActionType.SET_PAGE_FORM }, pageForm));
    };
};
export const usePageForm = () => {
    return useSelector(state => state.tourialRootReducer.viewport.pageForm);
};
