import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDCMode, useDemoCenter, useSetDCSessionId } from "../../../../redux/selectors/demo-center-selectors";
import { usePostDCEvent } from "../../../../helpers/events";
import { DCMode } from "../../../../../../shared/types/demo-center";
import { useIsDCSessionStarted } from "../../../../redux/selectors/redux-selectors";
import { TourialEvent } from "../../../../../../shared/types/events";
export function useDCSetLiveSessionId() {
    const set = useSetDCSessionId();
    useEffect(() => {
        set(uuidv4());
    });
}
export const useDCPostEventInLiveMode = () => {
    const postEvent = usePostDCEvent();
    const mode = useDCMode();
    return (event) => {
        if (mode === DCMode.LIVE) {
            return postEvent(event);
        }
    };
};
export const useDCItemLoadEvent = (item) => {
    const postEvent = useDCPostEventInLiveMode();
    useEffect(() => {
        if (item) {
            void postEvent({
                eventType: TourialEvent.DEMOCENTER_CONTENT_LOAD,
                democenterContentType: item.type,
                democenterContentId: item.uuid,
            });
        }
    }, [item]);
};
export const useDCModuleLoadEvent = (id) => {
    const postEvent = useDCPostEventInLiveMode();
    useEffect(() => {
        if (id) {
            void postEvent({
                eventType: TourialEvent.DEMOCENTER_MODULE_LOAD,
                democenterModuleId: id,
            });
        }
    }, [id]);
};
export const useDCClickEvent = () => {
    const postEvent = useDCPostEventInLiveMode();
    return ({ action, formId, url }) => {
        if (action) {
            void postEvent({
                eventType: TourialEvent.DEMOCENTER_BUTTON_CLICK,
                democenterButtonInteractionType: { action, formId, url },
            });
        }
    };
};
export const useDCFilterIntentEvent = () => {
    const postEvent = useDCPostEventInLiveMode();
    return (democenterModuleId, democenterModuleFilters) => {
        void postEvent({
            eventType: TourialEvent.DEMOCENTER_INTENT_CHANGE,
            democenterModuleId,
            democenterModuleFilters,
        });
    };
};
export const useDCModuleIntentEvent = () => {
    const postEvent = useDCPostEventInLiveMode();
    return (democenterTrackSelections) => {
        void postEvent({
            eventType: TourialEvent.DEMOCENTER_INTENT_CHANGE,
            democenterTrackSelections,
        });
    };
};
export const useDCUpfrontQuestionsIntentEvent = () => {
    const postEvent = useDCPostEventInLiveMode();
    return (democenterTrackSelections, democenterModuleId) => {
        void postEvent({
            eventType: TourialEvent.DEMOCENTER_INTENT_CHANGE,
            democenterTrackSelections,
            democenterModuleId,
        });
    };
};
export const useDCItemPlayEvent = () => {
    const postEvent = useDCPostEventInLiveMode();
    return (item) => {
        void postEvent({
            eventType: TourialEvent.DEMOCENTER_CONTENT_PLAY,
            democenterContentType: item.type,
            democenterContentId: item.uuid,
        });
    };
};
export const useDCItemPauseEvent = () => {
    const postEvent = useDCPostEventInLiveMode();
    return (item) => {
        void postEvent({
            eventType: TourialEvent.DEMOCENTER_CONTENT_PAUSE,
            democenterContentType: item.type,
            democenterContentId: item.uuid,
        });
    };
};
export const useDCItemStopEvent = () => {
    const postEvent = useDCPostEventInLiveMode();
    return (item) => {
        void postEvent({
            eventType: TourialEvent.DEMOCENTER_CONTENT_STOP,
            democenterContentType: item.type,
            democenterContentId: item.uuid,
        });
    };
};
export const useDCItemProgressEvent = () => {
    const postEvent = useDCPostEventInLiveMode();
    return (item, progress) => {
        void postEvent({
            eventType: TourialEvent.DEMOCENTER_CONTENT_PROGRESS,
            democenterContentType: item.type,
            democenterContentId: item.uuid,
            democenterContentProgress: progress,
        });
    };
};
export function useDCLoadEvent() {
    const postEvent = useDCPostEventInLiveMode();
    useEffect(() => {
        void postEvent({ eventType: "DEMOCENTER_LOAD" });
    }, []);
}
export function useDCSessionEndOnUnload() {
    const democenterId = useDemoCenter()._id;
    const postEvent = useDCPostEventInLiveMode();
    const isDCSessionStarted = useIsDCSessionStarted();
    const postDemocenterSessionEnd = () => {
        if (democenterId) {
            void postEvent({ eventType: "DEMOCENTER_SESSION_END", triggeredByTimeout: false });
            removeEndEventListeners();
        }
    };
    function removeEndEventListeners() {
        window.removeEventListener("beforeunload", postDemocenterSessionEnd);
        window.removeEventListener("unload", postDemocenterSessionEnd);
    }
    useEffect(() => {
        if (isDCSessionStarted) {
            window.addEventListener("beforeunload", postDemocenterSessionEnd, { once: true });
            window.addEventListener("unload", postDemocenterSessionEnd, { once: true });
        }
        return () => {
            removeEndEventListeners();
        };
    }, [democenterId, isDCSessionStarted]);
}
export const useDCEvents = () => {
    useDCLoadEvent();
    useDCSessionEndOnUnload();
};
