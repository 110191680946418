import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DCIconType, } from "../../../../../../../shared/types/demo-center";
import { newTheme } from "../../../../styled/theme";
import { DCIcon } from "../../components/dc-icon";
import { Popover } from "@blueprintjs/core";
import { DropdownContainer, DropdownOption } from "../../../home-page/tourial-list";
import BluePencilIcon from "../../../../../../assets/images/v3/blue-pencil.svg";
import RedTrashcanIcon from "../../../../../../assets/images/v3/red-trashcan.svg";
import { useOutsideClick } from "../../../../../hooks/use-outside-click";
import { dcFadeIn } from "../../helpers/dc-style-helpers";
import { DCCMDragHandle, DCCMTrackItem } from "./dc-cm-track-item";
import { DCCMEditableText } from "./dc-cm-editable-text";
import { DragDropContext, Droppable, Draggable, } from "react-beautiful-dnd";
import { DCFileUploader } from "../dc-file-uploader";
import TTooltip from "../../../../styled/t-tooltip";
export const DCCMTrackManager = React.forwardRef(({ track, onTrackUpdate, onTrackDelete, onRemoveItem, onImageRemove, onItemCommit, onAddItem, getItem, setItem, badgeConfig, sidebarConfig, canDelete, canModify, disabled, dragHandleProps, draggableProps, }, ref) => {
    const { title, items, img } = track;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [localName, setLocalName] = useState(title);
    const [isEditing, setIsEditing] = useState(false);
    const [activeItemId, setActiveItemId] = useState(null);
    const dropdownRef = useRef();
    useOutsideClick([dropdownRef], () => {
        if (isDropdownOpen)
            setIsDropdownOpen(false);
    });
    useEffect(() => {
        const lastItem = items[items.length - 1];
        if (initialized && lastItem) {
            setActiveItemId(lastItem);
        }
        setInitialized(true);
    }, [items.length]);
    const onDragEnd = (result) => {
        if (!result.destination)
            return;
        const newItems = [...items];
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        onTrackUpdate(Object.assign(Object.assign({}, track), { items: newItems }));
    };
    const handleItemCommit = (item) => {
        const updatedItem = onItemCommit(item);
        setActiveItemId(updatedItem.id);
    };
    return (React.createElement(TrackManagerContainer, Object.assign({ style: disabled ? { gap: 0 } : null }, draggableProps, { ref: ref }),
        React.createElement(TrackHeader, null,
            dragHandleProps && (React.createElement(DCCMDragHandle, Object.assign({}, dragHandleProps),
                React.createElement(DCIcon, { type: DCIconType.DRAG_HANDLE }))),
            canModify ? (React.createElement(React.Fragment, null,
                React.createElement(DCFileUploader, { onUploadComplete: url => onTrackUpdate(Object.assign(Object.assign({}, track), { img: url })), showSpinner: true, disabled: !!img }, !img ? (React.createElement(TTooltip, { content: `Add image`, position: "right" },
                    React.createElement(AddImage, null,
                        React.createElement(DCIcon, { type: DCIconType.IMAGE }),
                        React.createElement(AddIcon, null,
                            React.createElement(DCIcon, { type: DCIconType.PLUS_CIRCLE }))))) : (React.createElement(ImgThumbnailContainer, null,
                    React.createElement(Thumbnail, null,
                        React.createElement("img", { src: img })),
                    React.createElement(RemoveIcon, { onClick: e => {
                            e.stopPropagation();
                            onImageRemove(track);
                        } },
                        React.createElement(TTooltip, { content: `Remove image`, position: "right" },
                            React.createElement(DCIcon, { type: DCIconType.REMOVE_CIRCLE })))))),
                React.createElement(DCCMEditableText, { value: localName, onChange: e => setLocalName(e), onCommit: () => onTrackUpdate(Object.assign(Object.assign({}, track), { title: localName })), style: {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        font: newTheme.fonts.mediumBold,
                        color: newTheme.colors.grey900,
                    }, selected: isEditing }))) : (React.createElement(TrackTitlePlaceholder, null, "Default Track")),
            !disabled && (React.createElement(IconButton, { onClick: onAddItem },
                React.createElement(DCIcon, { type: DCIconType.PLUS_LARGE }))),
            canModify && (React.createElement(Popover, { minimal: true, content: React.createElement(Dropdown, { onDelete: () => onTrackDelete(), onRename: () => setIsEditing(true), setIsDropdownOpen: setIsDropdownOpen, canDelete: canDelete }), position: "bottom-right", isOpen: isDropdownOpen },
                React.createElement(IconButton, { onClick: () => setIsDropdownOpen(!isDropdownOpen), ref: dropdownRef },
                    React.createElement(DCIcon, { type: DCIconType.ELLIPSES }))))),
        !disabled && React.createElement(Spacer, null),
        items.length === 0 && !disabled && (React.createElement(Placeholder, { onClick: onAddItem },
            React.createElement("span", null, "Click here"),
            "\u00A0 or press the + sign to add related tours, videos, PDFs, and more!")),
        React.createElement(DragDropContext, { onDragEnd: onDragEnd },
            React.createElement(Droppable, { droppableId: "items" }, provided => (React.createElement("div", Object.assign({ ref: provided.innerRef }, provided.droppableProps),
                items.map((id, i) => {
                    const item = getItem(id);
                    return item ? (React.createElement(Draggable, { key: `item-${id}`, draggableId: `item-${id}`, index: i }, provided => (React.createElement(DCCMTrackItem, { draggableProps: provided.draggableProps, dragHandleProps: provided.dragHandleProps, ref: provided.innerRef, key: id, item: item, activeItemId: activeItemId, setActiveItemId: setActiveItemId, badgeConfig: badgeConfig, sidebarConfig: sidebarConfig, setItem: setItem, onDelete: onRemoveItem, onItemCommit: handleItemCommit })))) : null;
                }),
                provided.placeholder))))));
});
const Dropdown = ({ onDelete, onRename, setIsDropdownOpen, canDelete }) => {
    const dropdownOptions = [
        {
            src: BluePencilIcon,
            label: "Rename",
            onClick: () => {
                onRename();
                setIsDropdownOpen(false);
            },
        },
        {
            src: RedTrashcanIcon,
            label: "Delete",
            onClick: () => {
                onDelete();
                setIsDropdownOpen(false);
            },
        },
    ];
    if (!canDelete)
        dropdownOptions.pop();
    return (React.createElement(DropdownContainer, null, dropdownOptions.map(option => {
        const { src, label, style, onClick } = option;
        return (React.createElement(DropdownOption, { key: label, style: style, onClick: onClick },
            React.createElement("img", { src: src, style: { margin: "8px 8px 8px 16px", display: "inline" } }),
            " ",
            label));
    })));
};
const TrackManagerContainer = styled.div `
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background: ${newTheme.colors.white};
  border: 1px solid ${newTheme.colors.grey300};
  border-radius: 8px;
  box-shadow: ${newTheme.shadows.demoCenter};
  animation: ${dcFadeIn} 0.2s ease-in;
`;
const TrackHeader = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;
const TrackTitlePlaceholder = styled.div `
  display: flex;
  justify-content: flex-start;
  flex: 1;
  font: ${newTheme.fonts.mediumItalic};
  color: ${newTheme.colors.grey400};
`;
const AddImage = styled.div `
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${newTheme.colors.blue50};
  color: ${newTheme.colors.black};
  &:hover {
    background-color: ${newTheme.colors.blue100};
    color: ${newTheme.colors.blue500};
  }
  cursor: pointer;
`;
const ImgThumbnailContainer = styled.div `
  position: relative;
  margin-right: 8px;
`;
const Thumbnail = styled.div `
  background-color: ${newTheme.colors.grey200};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const AddIcon = styled.div `
  display: flex;
  position: absolute;
  bottom: -4px;
  right: -8px;
  z-index: 1;
  cursor: pointer;
  color: ${newTheme.colors.blue500};
  svg {
    width: 16px;
    height: 16px;
  }
`;
const RemoveIcon = styled(AddIcon) `
  color: ${newTheme.colors.red500};
`;
const Spacer = styled.div `
  height: 0px;
  border-bottom: 1px solid ${newTheme.colors.grey300};
  width: 100%;
`;
const Placeholder = styled.div `
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 64px;
  background-color: ${newTheme.colors.grey100};
  color: ${newTheme.colors.grey600};
  font: ${newTheme.fonts.small};
  span {
    text-decoration: underline;
  }
`;
const IconButton = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${newTheme.colors.grey500};
  &:hover {
    color: ${newTheme.colors.blue500};
  }
  transition: color 0.2s ease-in-out;
`;
