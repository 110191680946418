import React, { useEffect, useState } from "react";
import TButton from "../styled/t-button";
import TP from "../styled/t-p";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
import { usePostAppEvent } from "../../../hooks/use-post-app-event";
import { TourialAppEventTypes } from "../../../../../shared/types/app-events";
import { debounce } from "lodash";
export function CopyToClipboardButton({ textToCopy, buttonText, isLightboxEnabled }) {
    const [wasCopied, setWasCopied] = useState(false);
    const copyToClipboard = debounce(() => {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(textToCopy).then(function () {
            postAppEvent(isLightboxEnabled ? TourialAppEventTypes.LIGHTBOX_CODE_COPIED : TourialAppEventTypes.EMBED_CODE_COPIED);
            setWasCopied(true);
        }, function (err) {
            console.error("Failed to copy text to clipboard: ", err);
        });
    }, 500);
    const postAppEvent = usePostAppEvent();
    useEffect(() => {
        setWasCopied(false);
    }, [textToCopy]);
    return (React.createElement("div", { style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 15,
        } },
        React.createElement(TP, { style: {
                color: theme.colors.cobalt.light,
                visibility: wasCopied ? "inherit" : "hidden",
                padding: 0,
                lineHeight: 0,
                fontSize: 14,
                height: 20,
                textAlign: "center",
            } }, "Copied!"),
        React.createElement(TButton, { onClick: copyToClipboard }, buttonText)));
}
