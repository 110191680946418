import React from "react";
import styled from "styled-components";
import { textAlignments } from "../../../../helpers/text-helpers";
import { TextStyle } from "../../../../../../shared/types/tool-data-types/common";
import { dcBodyFontSizes } from "../helpers/dc-style-helpers";
export const DCTagComponent = ({ tag, config, theme, isSelected, onChange }) => {
    return (React.createElement(Tag, { config: config, theme: theme, selected: isSelected, onClick: () => {
            onChange(tag, !isSelected);
        } }, tag.label));
};
const Tag = styled.div `
  display: inline;
  height: 30px;
  padding: 4px 12px;
  border: 1px solid ${p => (p.selected ? p.config.activeBorderColor : p.theme.neutralSecondary)};
  background-color: ${p => (p.selected ? p.config.activeBackgroundColor : p.theme.neutralPrimary)};
  overflow: hidden;
  border-radius: ${p => p.config.borderRadius}px;
  font-family: ${p => p.config.text.font};
  color: ${p => (p.selected ? p.config.text.activeColor : p.theme.textSecondary)};
  font-size: ${p => dcBodyFontSizes[p.config.text.size]}px;
  line-height: 20px;
  font-weight: ${p => (p.config.text.style === TextStyle.BOLD ? "bold" : "normal")};
  text-decoration: ${p => (p.config.text.style === TextStyle.UNDERLINE ? "underline" : "none")};
  font-style: ${p => (p.config.text.style === TextStyle.ITALIC ? "italic" : "normal")};
  text-align: ${p => textAlignments[p.config.text.align]};
  cursor: pointer;
  transition:
    color 0.2s linear,
    background-color 0.2s linear,
    border 0.2s linear;
`;
