import React from "react";
import { ViewportMode } from "../../../types/definitions";
import { useActivePageId, useLegacyChangeView, usePages, useViewportMode, } from "../../../redux/selectors/redux-selectors";
import { withToolWrapper } from "../with-tool-wrapper";
function TourialBreadcrumbs(props) {
    const { backgroundColor, enableBackground, activeColor, sizeAndPosition, borderStyle, borderWidth, borderColor, enableBorder, } = props.toolData;
    const { viewportDimensions } = props;
    const activePageId = useActivePageId();
    const pages = usePages();
    const viewportMode = useViewportMode();
    const changeView = useLegacyChangeView();
    const sharedStyle = {
        borderRadius: "50%",
        borderStyle: enableBorder ? borderStyle : null,
        borderWidth: enableBorder ? borderWidth : null,
        borderColor: enableBorder ? borderColor : null,
        width: `${(sizeAndPosition.height * viewportDimensions.height) / 100}px`,
        height: `${(sizeAndPosition.height * viewportDimensions.height) / 100}px`,
    };
    return (React.createElement("div", { className: "_test_breadcrumbs-viewtool", style: {
            width: `100%`,
            height: `100%`,
            position: "absolute",
            display: "inline-flex",
            alignItems: "center",
            flexGrow: 0,
            justifyContent: "space-around",
        }, id: props.toolData.id }, pages.map((p, i) => {
        if (p.id === activePageId) {
            return (React.createElement("div", { key: i, style: Object.assign(Object.assign({}, sharedStyle), { backgroundColor: activeColor }) }));
        }
        return (React.createElement("div", { key: i, onClick: () => {
                if (viewportMode !== ViewportMode.EDIT) {
                    changeView({ pageId: p.id, zoomId: "MAIN" });
                }
            }, style: Object.assign(Object.assign({}, sharedStyle), { backgroundColor: enableBackground ? backgroundColor : null, cursor: viewportMode !== ViewportMode.EDIT ? "pointer" : "inherit" }) }));
    })));
}
export const Breadcrumbs = React.memo(withToolWrapper(TourialBreadcrumbs));
