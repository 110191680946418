var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useActivePageId, useActiveVariantName, useActiveZoomId, useHasActiveVariantNameBeenSet, useLoadEvent, useSessionId, useTourId, useIsSessionStarted, useIsMicrositeSessionStarted, useSetInitialTourialRootState, useTourial, useShareLink, } from "../redux/selectors/redux-selectors";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSetSessionId } from "../legacy/selectors";
import { isEmbedded, isInLightbox } from "../helpers/live";
import { getShareLinkByPathname, usePostEvent, usePostMicrositeEvent } from "../helpers/events";
import { useMicrositeActiveTourId, useMicrositeId, useSetMicrositeSessionId, } from "../redux/selectors/microsite-selectors";
import { useDCActiveItemId } from "../redux/selectors/demo-center-selectors";
import { useHTSetSessionId } from "../redux/selectors/ht-selectors";
import axios from "axios";
import { getInitialTourialRootState } from "../redux/reducers/tourial-root-reducer";
import { ViewportMode } from "../types/definitions";
import { useHasTourEnded } from "./use-has-tour-ended";
import { ToDemoChatbotEvents } from "../../../shared/types/demo-chatbot";
export function useSetLiveSessionId() {
    const sessionId = useSessionId();
    const setSessionId = useSetSessionId();
    useEffect(() => {
        if (!sessionId) {
            setSessionId(uuidv4());
        }
        else {
            console.error("Found pre-existing sessionId in redux.");
        }
    }, []);
}
export function usePostLoadEvent() {
    const postEvent = usePostEvent();
    const hasActiveVariantNameBeenSet = useHasActiveVariantNameBeenSet();
    const activeVariantName = useActiveVariantName();
    useEffect(() => {
        if (!hasActiveVariantNameBeenSet)
            return;
        const attributionHandler = ({ data }) => {
            if (data.type === "TOURIAL_ATTRIBUTION" && data.payload) {
                if (data.payload.parentParams) {
                    delete data.payload.parentParams;
                }
                // we want to not store the device width if it is a falsey value (0, undefined, etc...)
                if (!data.payload.deviceWidth) {
                    delete data.payload.deviceWidth;
                }
                void postEvent(Object.assign({ eventType: "TOURIAL_LOAD_UPDATE" }, data.payload));
                window.parent.postMessage({ type: "ATTRIBUTION_CONFIRMATION", payload: true }, "*");
                window.removeEventListener("message", attributionHandler);
            }
        };
        void postEvent({ eventType: "TOURIAL_LOAD" });
        if (isEmbedded() && !isInLightbox()) {
            window.addEventListener("message", attributionHandler);
        }
        return () => {
            window.removeEventListener("message", attributionHandler);
        };
    }, [hasActiveVariantNameBeenSet, activeVariantName]);
}
export function usePostViewEvent() {
    const postEvent = usePostEvent();
    const pageId = useActivePageId();
    const zoomId = useActiveZoomId();
    const loadEvent = useLoadEvent();
    const sessionId = useSessionId();
    useEffect(() => {
        if ((loadEvent === null || loadEvent === void 0 ? void 0 : loadEvent.sessionId) === sessionId) {
            void postEvent({
                eventType: "TOURIAL_VIEW",
            });
        }
    }, [pageId, zoomId, loadEvent === null || loadEvent === void 0 ? void 0 : loadEvent.sessionId]);
}
export function useSetLiveDomTourSessionId() {
    const set = useHTSetSessionId();
    useEffect(() => {
        set(uuidv4());
    }, []);
}
export function useSetLiveMicrositeSessionId() {
    const set = useSetMicrositeSessionId();
    useEffect(() => {
        set(uuidv4());
    }, []);
}
export function useSetTourSessionIdWithinMicrosite() {
    const setSessionId = useSetSessionId();
    const tourId = useMicrositeActiveTourId();
    useEffect(() => {
        setSessionId(uuidv4());
    }, [tourId]);
}
export function useSetTourSessionIdWithinDemoCenter() {
    const setSessionId = useSetSessionId();
    const tourId = useDCActiveItemId();
    useEffect(() => {
        setSessionId(uuidv4());
    }, [tourId]);
}
export function useSetDomTourSessionIdWithinDemoCenter() {
    const setSessionId = useHTSetSessionId();
    const tourId = useDCActiveItemId();
    useEffect(() => {
        setSessionId(uuidv4());
    }, [tourId]);
}
export function usePostMicrositeLoadEvent() {
    const postMicrositeEvent = usePostMicrositeEvent();
    useEffect(() => {
        void postMicrositeEvent({ eventType: "MICROSITE_LOAD" });
    }, []);
}
export function usePostSessionEndOnUnload() {
    const tourId = useTourId();
    const postEvent = usePostEvent();
    const isSessionStarted = useIsSessionStarted();
    const postSessionEnd = () => {
        void postEvent({ eventType: "SESSION_END", triggeredByTimeout: false });
        removeEndEventListeners();
    };
    function removeEndEventListeners() {
        window.removeEventListener("beforeunload", postSessionEnd);
        window.removeEventListener("unload", postSessionEnd);
    }
    useEffect(() => {
        if (isSessionStarted) {
            window.addEventListener("beforeunload", postSessionEnd, { once: true });
            window.addEventListener("unload", postSessionEnd, { once: true });
        }
        return () => {
            removeEndEventListeners();
        };
    }, [tourId, isSessionStarted]);
}
export function usePostMicrositeSessionEndOnUnload() {
    const micrositeId = useMicrositeId();
    const postMicrositeEvent = usePostMicrositeEvent();
    const isMicrositeSessionStarted = useIsMicrositeSessionStarted();
    const postMicrositeSessionEnd = () => {
        if (micrositeId) {
            void postMicrositeEvent({ eventType: "MICROSITE_SESSION_END", triggeredByTimeout: false });
            removeEndEventListeners();
        }
    };
    function removeEndEventListeners() {
        window.removeEventListener("beforeunload", postMicrositeSessionEnd);
        window.removeEventListener("unload", postMicrositeSessionEnd);
    }
    useEffect(() => {
        if (isMicrositeSessionStarted) {
            window.addEventListener("beforeunload", postMicrositeSessionEnd, { once: true });
            window.addEventListener("unload", postMicrositeSessionEnd, { once: true });
        }
        return () => {
            removeEndEventListeners();
        };
    }, [micrositeId, isMicrositeSessionStarted]);
}
export function useFetchTourial() {
    const setInitialTourialRootState = useSetInitialTourialRootState();
    const tourial = useTourial();
    const shareLink = useShareLink();
    const [error, setError] = useState(null);
    useEffect(() => {
        if (!tourial) {
            const pathname = location.pathname.replace("/pages/", "/");
            axios
                .get(`/v2/slug${pathname}`, {})
                .then(({ data }) => __awaiter(this, void 0, void 0, function* () {
                const { tourial, account } = data;
                const shareLinkSlug = location.pathname.replace("/pages", "").split("/")[1];
                let shareLink;
                if (shareLinkSlug) {
                    shareLink = yield getShareLinkByPathname(location.pathname, tourial._id, account._id);
                }
                const initialProviderState = getInitialTourialRootState(tourial, ViewportMode.LIVE, account, shareLink);
                setInitialTourialRootState(initialProviderState);
            }))
                .catch((e) => {
                console.log(`Error getting tour data in getTourFromSlug action`, e);
                setError(e);
            });
        }
    }, []);
    return { tourial, shareLink, error };
}
export function usePostTourEndEvent() {
    const tourHasEnded = useHasTourEnded();
    const tourId = useTourId();
    useEffect(() => {
        if (process.env.DAR_ENV !== "production" && tourHasEnded) {
            window.parent.postMessage({ type: ToDemoChatbotEvents.TOURIAL_END, payload: { tourId } }, "*");
        }
    }, [tourHasEnded]);
}
