import React from "react";
import TButton, { TButtonVariants } from "../../styled/t-button";
import styled from "styled-components";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import TModal, { TModalButtonsContainer } from "../../styled/t-modal";
import { newTheme } from "../../styled/theme";
import { ToastModes } from "../../../types/definitions";
export default function PauseModal({ tourialData, setIsPauseModalOpen, setIsDropdownOpen, reload, addToast, }) {
    return (React.createElement(TModal, { title: "Are you sure you want to unpublish?", onClose: () => setIsPauseModalOpen(false) },
        React.createElement(React.Fragment, null,
            React.createElement(Span, null, "This tourial will no longer appear on websites where you\u2019ve shared it."),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => setIsPauseModalOpen(false) }, "Cancel"),
                React.createElement(TButton, { variant: TButtonVariants.WARNING, onClick: () => {
                        TourialApiCalls.Tourials.postTourialPublish(tourialData.tourId, false)
                            .then(() => {
                            setIsPauseModalOpen(false);
                            setIsDropdownOpen(false);
                            addToast({ message: "Unpublish successful", mode: ToastModes.SUCCESS });
                            reload();
                        })
                            .catch(e => {
                            console.error(e);
                            addToast({ message: "Unpublish failed", mode: ToastModes.ERROR });
                        });
                    } }, "Unpublish Tourial")))));
}
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  width: 100%;
  height: 68px;
`;
