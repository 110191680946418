import React from "react";
import styled from "styled-components";
export function Four04Page() {
    return (React.createElement(Div, { id: "_test_404-page" },
        React.createElement("p", null, "Oops! Looks like that page isn't available.")));
}
const Div = styled.div `
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
`;
