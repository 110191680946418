export const NAV_HEIGHT = 48;
export const TOOL_PANEL_WIDTH = 40;
export const STEPS_PANEL_STUB_HEIGHT = 32;
export const STEPS_PANEL_HEIGHT = 102;
export const STEPS_PANEL_CARD_WIDTH = 122;
export const STEP_PANEL_INPUT_HEIGHT = 19;
export const PREVIEW_STEPPER_HEIGHT = 60;
export const PREVIEW_STEPPER_WIDTH = 320;
export const SCREEN_MARGIN = 40;
export const EDIT_PANEL_WIDTH = 325;
export const MOBILE_BREAKPOINT = 600;
export const MIN_SCALE_WIDTH = 200;
export const MAX_DESKTOP_SCREEN_WIDTH = 1080;
export const SCREEN_ID = "tourial-screen";
export const HOTSPOT_SIZE = 30;
export const TOOLTIP_ID = "tooltip";
export const ASPECT_RATIO = "16 / 9";
export const SCREEN_CONTAINER_ID = "tourial-screen-container";
export const EDITING_Z_INDEX = 3;
export const DEBOUNCE_MILLISECONDS = 250;
export const MOBILE_TOOLTIP_HEIGHT = 60;
export const TOOLTIP_MAX_WIDTH = 400;
export const PROGRESS_BAR_HEIGHT = 36;
export const EDITOR_TAB_HEIGHT = 31;
export const EDITOR_STEP_NAME = 41;
export var DTBuilderModal;
(function (DTBuilderModal) {
    DTBuilderModal["NONE"] = "NONE";
    DTBuilderModal["LIBRARY"] = "LIBRARY";
})(DTBuilderModal || (DTBuilderModal = {}));
export class NotImplementedError extends Error {
    constructor() {
        super("NotImplementerError");
    }
}
export function throwNotImplemented() {
    throw new NotImplementedError();
}
