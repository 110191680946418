var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from "react";
import { useAsync } from "react-async";
import { useClearDemoCenter, useDCClearFowardPath, useDCGetForwardPath, useDCMode, useDCSetMode, useDCSetSelectedTags, useDCSetShouldShowWelcomeScreen, useDCSetSidebarIsOpen, useDCSetTrackSelections, useDCSetViewedItems, useDCShouldShowUpfrontQuestions, useDCTrackSelections, useDemoCenter, useSavedDemoCenter, useSetDemoCenters, useSetSavedDemoCenter, useSetShareLink, useUpdateDemoCenter, useUpdateDemoCenterRedux, } from "../../../../redux/selectors/demo-center-selectors";
import { useHistory, useLocation } from "react-router-dom";
import { getFromStorage } from "../../../../helpers/storage";
import { DC_SETTINGS_KEY, DCButtonType, DCMode, DCThemeType, } from "../../../../../../shared/types/demo-center";
import { dcApplyThemeAndStylesToConfig, dcDecodeTrackSelections, dcEncodeTrackSelections, dcFsAndMongoDocsAreEqual, dcGetLiveUrl, dcGetRoot, dcGetSlugFromLocation, dcGetStateFromCurrentURLParams, dcIsLivePreview, dcIsShareLink, } from "../helpers/dc-helpers";
import { useDCModuleIntentEvent } from "./dc-event-hooks";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
import { defaultDemoCenter } from "../../../../../../shared/objects/default-demo-center";
import { useDCModules } from "./dc-module-hooks";
import { getBaseUrlWithSubdomain } from "../../../../helpers/misc";
import { dcDarkTheme, dcGetTextColorFromGradient, dcGetTextColorFromHighlight, dcLightTheme, } from "../helpers/dc-style-helpers";
import { TourialStatus } from "../../../../../../shared/types/tourial-model";
import { useDCGoToBuilder } from "./dc-builder-hooks";
import { FSDocument } from "../../../../addons/firebase";
import { cloneDeep, debounce } from "lodash";
import { useWindowSize } from "../../../../hooks/viewport-hooks";
import { IContentType } from "../../../../../../shared/types/events";
export const useCreateDemoCenter = () => {
    const goToBuilder = useDCGoToBuilder();
    return (name, workspaceId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield TourialApiCalls.DemoCenters.createDemoCenter(defaultDemoCenter, name, workspaceId)
            .then(id => goToBuilder(id))
            .catch((e) => {
            console.error(`Error creating Demo Center`);
            throw e;
        });
    });
};
export const useDeleteDemoCenter = () => {
    return (id) => __awaiter(void 0, void 0, void 0, function* () {
        return yield TourialApiCalls.DemoCenters.deleteDemoCenter(id).catch((e) => {
            console.error(`Error deleting Demo Center: ${id}`);
            throw e;
        });
    });
};
export const usePublishDemoCenter = () => {
    const demoCenter = useDemoCenter();
    demoCenter.status = TourialStatus.LIVE;
    const setSavedDemoCenter = useSetSavedDemoCenter();
    return () => __awaiter(void 0, void 0, void 0, function* () {
        return yield TourialApiCalls.DemoCenters.updateDemoCenter(demoCenter)
            .then(() => setSavedDemoCenter(demoCenter))
            .catch((e) => {
            console.error(`Error updating Demo Center: ${demoCenter._id}`);
            throw e;
        });
    });
};
export const useFetchDemoCenterByIdForContext = (id) => {
    const [fetchState, setFetchState] = useState({
        fsDemoCenter: null,
        error: null,
        demoCenter: null,
    });
    const demoCenter = useDemoCenter();
    const setDemoCenters = useSetDemoCenters();
    const clearDemoCenter = useClearDemoCenter();
    const fsDemoCenter = new FSDocument("democenters", id);
    const updateDemoCenterRedux = useUpdateDemoCenterRedux();
    const debounceUpdateDemoCenterRedux = debounce(updateDemoCenterRedux, 200, {
        leading: false,
        trailing: true,
    });
    useEffect(() => {
        const setupDraftAndSavedStates = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const savedDemoCenter = yield TourialApiCalls.DemoCenters.getDemoCenterById(id);
                const draftDemoCenter = yield fsDemoCenter.fetch(savedDemoCenter);
                setDemoCenters(draftDemoCenter, savedDemoCenter);
                setFetchState(Object.assign(Object.assign({}, fetchState), { fsDemoCenter, demoCenter: draftDemoCenter }));
                fsDemoCenter.subscribe((demoCenterSnap) => debounceUpdateDemoCenterRedux(demoCenterSnap));
            }
            catch (error) {
                setFetchState(Object.assign(Object.assign({}, fetchState), { error }));
            }
        });
        void setupDraftAndSavedStates();
        return () => {
            var _a;
            (_a = fsDemoCenter === null || fsDemoCenter === void 0 ? void 0 : fsDemoCenter.unsubscribe) === null || _a === void 0 ? void 0 : _a.call(fsDemoCenter);
            clearDemoCenter();
        };
    }, []);
    useEffect(() => {
        setFetchState(Object.assign(Object.assign({}, fetchState), { demoCenter, fsDemoCenter }));
    }, [demoCenter]);
    return fetchState;
};
export const useDCDraft = (savedDemoCenter) => {
    const id = savedDemoCenter._id;
    const [demoCenter, setDemoCenter] = useState(savedDemoCenter);
    const draft = new FSDocument("democenters", id);
    useEffect(() => {
        const setupDraftAndSavedStates = () => {
            try {
                void draft
                    .fetch()
                    .then(ogDemoCenterSnap => {
                    if (!dcFsAndMongoDocsAreEqual(ogDemoCenterSnap, savedDemoCenter) &&
                        savedDemoCenter.status === TourialStatus.LIVE) {
                        setDemoCenter(Object.assign(Object.assign({}, ogDemoCenterSnap), { status: TourialStatus.PENDING_CHANGES }));
                    }
                    draft.subscribe((demoCenterSnap) => {
                        if (!dcFsAndMongoDocsAreEqual(demoCenterSnap, savedDemoCenter) &&
                            savedDemoCenter.status === TourialStatus.LIVE) {
                            setDemoCenter(Object.assign(Object.assign({}, demoCenterSnap), { status: TourialStatus.PENDING_CHANGES }));
                        }
                        else {
                            setDemoCenter(Object.assign(Object.assign({}, demoCenterSnap), { status: savedDemoCenter.status }));
                        }
                    });
                })
                    .catch(err => console.error(err, id));
            }
            catch (error) {
                console.error("issue with FS draft status");
            }
        };
        setupDraftAndSavedStates();
        return () => {
            var _a;
            (_a = draft === null || draft === void 0 ? void 0 : draft.unsubscribe) === null || _a === void 0 ? void 0 : _a.call(draft);
        };
    }, []);
    return demoCenter;
};
export const useDCUpdateDraft = (id) => {
    const [demoCenter, setDemoCenter] = useState(null);
    const draft = new FSDocument("democenters", id);
    useEffect(() => {
        const setupInitialDemoCenter = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const initialDemoCenter = yield draft.fetch();
                setDemoCenter(initialDemoCenter);
            }
            catch (error) {
                console.error("Issue with fetching initial FS draft:", error);
            }
        });
        void setupInitialDemoCenter();
        return () => {
            var _a;
            (_a = draft === null || draft === void 0 ? void 0 : draft.unsubscribe) === null || _a === void 0 ? void 0 : _a.call(draft);
        };
    }, []);
    const updateDraft = useCallback((update) => __awaiter(void 0, void 0, void 0, function* () {
        if (!demoCenter) {
            console.error("DemoCenter is not initialized.");
            return;
        }
        if (update._id && update._id !== demoCenter._id) {
            console.error("DemoCenter id does not match update id");
            return;
        }
        try {
            const updatedDemoCenter = Object.assign(Object.assign({}, demoCenter), update);
            setDemoCenter(updatedDemoCenter);
            yield draft.update(updatedDemoCenter);
        }
        catch (error) {
            console.error("Issue with updating FS draft:", error);
        }
    }), [demoCenter, draft]);
    return updateDraft;
};
export const useFetchDemoCenterByLiveUrl = () => {
    const [hasRealtimeConnection, setHasRealtimeConnection] = useState(false);
    const [error, setError] = useState("");
    const slug = dcGetSlugFromLocation();
    const demoCenter = useDemoCenter();
    const updateDemoCenterRedux = useUpdateDemoCenterRedux();
    const setSavedDemoCenter = useSetSavedDemoCenter();
    const setShareLink = useSetShareLink();
    let fetchFn;
    if (dcIsLivePreview()) {
        fetchFn = TourialApiCalls.DemoCenters.getDemoCenterBySlugWithAuthorization;
    }
    else if (dcIsShareLink()) {
        fetchFn = TourialApiCalls.DemoCenters.getPublicDemoCenterByShareSlug;
    }
    else {
        fetchFn = TourialApiCalls.DemoCenters.getPublicDemoCenterBySlug;
    }
    useEffect(() => {
        if (!demoCenter) {
            void Promise.resolve()
                .then(() => {
                if (dcIsShareLink()) {
                    return TourialApiCalls.ShareLinks.getShareLink({
                        slug,
                        contentType: IContentType.DEMOCENTER,
                    }).then(shareLink => {
                        setShareLink(shareLink);
                    });
                }
                else {
                    return Promise.resolve();
                }
            })
                .then(() => {
                return fetchFn(slug).then(demoCenter => {
                    if (dcIsLivePreview()) {
                        updateDemoCenterRedux(demoCenter);
                        const fsDemoCenter = new FSDocument("democenters", demoCenter._id);
                        const debounceUpdateDemoCenterRedux = debounce(updateDemoCenterRedux, 200, {
                            leading: false,
                            trailing: true,
                        });
                        fsDemoCenter.subscribe((demoCenterSnap) => debounceUpdateDemoCenterRedux(demoCenterSnap));
                    }
                    else {
                        setSavedDemoCenter(demoCenter);
                    }
                });
            })
                .catch((e) => {
                const msg = `Error getting Demo Center by slug: ${slug}`;
                setError(msg);
                console.error(msg);
                throw e;
            });
        }
    }, []);
    // This is for realtime updates with Preview mode
    useEffect(() => {
        const id = demoCenter === null || demoCenter === void 0 ? void 0 : demoCenter._id;
        if (!id)
            return;
        if (hasRealtimeConnection)
            return;
        if (!dcIsLivePreview())
            return;
        const draft = new FSDocument("democenters", demoCenter._id);
        const setupDraftAndSavedStates = () => {
            try {
                void draft.fetch().then(demoCenter => {
                    updateDemoCenterRedux(demoCenter);
                });
                draft.subscribe((demoCenterSnap) => updateDemoCenterRedux(demoCenterSnap));
                setHasRealtimeConnection(true);
            }
            catch (e) {
                console.error(e);
            }
        };
        setupDraftAndSavedStates();
        return () => {
            var _a;
            (_a = draft === null || draft === void 0 ? void 0 : draft.unsubscribe) === null || _a === void 0 ? void 0 : _a.call(draft);
        };
    }, [demoCenter]);
    return { error, demoCenter };
};
export const useFetchDemoCenters = () => {
    const { data: demoCenters = [], error, isLoading, reload, } = useAsync({
        promiseFn: TourialApiCalls.DemoCenters.getDemoCenters,
    });
    return {
        demoCenters,
        error,
        isLoading,
        reload,
    };
};
export const useDCLiveMode = () => {
    const setMode = useDCSetMode();
    useEffect(() => {
        setMode(dcIsLivePreview() ? DCMode.LIVE_PREVIEW : DCMode.LIVE);
    }, []);
};
export const useDCIsMobile = () => {
    const MOBILE_BREAKPOINT = 768;
    const [browserWidth, setBrowserWidth] = useState(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
    useEffect(() => {
        const updateWidth = () => {
            const newWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            setBrowserWidth(newWidth);
        };
        // Attach event listener
        window.addEventListener("resize", updateWidth);
        // Cleanup: remove event listener
        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, []);
    return browserWidth <= MOBILE_BREAKPOINT;
};
export const useDCIsLiveMode = () => {
    const mode = useDCMode();
    return mode === DCMode.LIVE || mode === DCMode.LIVE_PREVIEW;
};
export const useDCId = () => {
    const demoCenter = useDemoCenter();
    return demoCenter === null || demoCenter === void 0 ? void 0 : demoCenter._id;
};
export const useDCConfig = () => {
    const { config } = useDemoCenter();
    const styles = useDCStyles();
    const theme = useDCTheme();
    const modifyButtonConfig = useDCModifyButtonConfig();
    const useGlobalStyles = true;
    if (useGlobalStyles) {
        return dcApplyThemeAndStylesToConfig(config, theme, styles, modifyButtonConfig);
    }
    else {
        return config;
    }
};
export const useDCSetConfig = () => {
    const updateDemoCenter = useUpdateDemoCenter();
    return (config) => {
        updateDemoCenter({ config });
    };
};
export const useDCSaveMetadata = () => {
    const id = useDCId();
    const updateDemoCenter = useUpdateDemoCenter();
    const setSavedDemoCenter = useSetSavedDemoCenter();
    return (htmlMetadata) => __awaiter(void 0, void 0, void 0, function* () {
        return yield TourialApiCalls.DemoCenters.updateDemoCenter({ _id: id, htmlMetadata })
            .then(savedDemoCenter => {
            updateDemoCenter({ htmlMetadata });
            setSavedDemoCenter(savedDemoCenter);
        })
            .catch((e) => {
            console.error(`Error updating Demo Center htmlMetadata: ${htmlMetadata}`);
            throw e;
        });
    });
};
export const useDCDiscardChanges = () => {
    const { _id } = useDemoCenter();
    const setSavedDemoCenter = useSetSavedDemoCenter();
    const updateDemoCenter = useUpdateDemoCenter();
    return () => {
        return TourialApiCalls.DemoCenters.getDemoCenterById(_id).then(savedDemoCenter => {
            updateDemoCenter(savedDemoCenter);
            setSavedDemoCenter(savedDemoCenter);
        });
    };
};
export const useDCSetConfigGatingFormId = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (gatingFormId) => {
        setConfig(Object.assign(Object.assign({}, config), { gatingFormId }));
    };
};
export const useDCStyles = () => {
    const demoCenter = useDemoCenter();
    return demoCenter.styles;
};
export const useDCSetStyles = () => {
    const updateDemoCenter = useUpdateDemoCenter();
    return (styles) => {
        updateDemoCenter({ styles });
    };
};
export const useDCResetBorderStyles = () => {
    const demoCenter = useDemoCenter();
    const updateDemoCenter = useUpdateDemoCenter();
    const { styles: defaultStyles, config: defaultConfig } = defaultDemoCenter;
    return () => {
        updateDemoCenter({
            styles: Object.assign(Object.assign({}, demoCenter.styles), { cardBorderRadius: defaultStyles.cardBorderRadius, containerBorderRadius: defaultStyles.containerBorderRadius, buttonBorderRadius: defaultStyles.buttonBorderRadius, largeButtonBorderRadius: defaultStyles.largeButtonBorderRadius }),
            config: Object.assign(Object.assign({}, demoCenter.config), { tag: Object.assign(Object.assign({}, demoCenter.config.tag), { borderRadius: defaultConfig.tag.borderRadius }) }),
        });
    };
};
export const useDCHighlightColor = () => {
    const styles = useDCStyles();
    return styles.highlightColor;
};
export const useDCDefaultGradient = () => {
    const styles = useDCStyles();
    const { gradientColor1, gradientColor2, gradientPercent } = styles;
    return {
        color1: gradientColor1,
        color2: gradientColor2,
        percent: gradientPercent,
    };
};
export const useDCUpdateHighlightColor = () => {
    const democenter = useDemoCenter();
    const updateDemoCenter = useUpdateDemoCenter();
    const { styles, config } = democenter;
    return (color) => {
        const newStyles = Object.assign({}, styles);
        const newConfig = Object.assign({}, config);
        newStyles.highlightColor = color;
        newConfig.moduleDetails.continueButton.style.backgroundColor = color;
        newConfig.moduleDetails.continueButton.style.borderColor = color;
        newConfig.moduleDetails.continueButton.style.color = dcGetTextColorFromHighlight(color);
        newConfig.moduleDetails.skipButton.style.hoverColor = color;
        newConfig.cardButton.activeColor = color;
        newConfig.itemCard.activeBorderColor = color;
        newConfig.itemCard.viewButton.style.backgroundColor = color;
        newConfig.itemCard.viewButton.style.borderColor = color;
        newConfig.itemCard.viewButton.style.color = dcGetTextColorFromHighlight(color);
        newConfig.controls.activeBackgroundColor = color;
        newConfig.controls.activeColor = dcGetTextColorFromHighlight(color);
        newConfig.sidebar.activeIconColor = color;
        newConfig.tag.activeBackgroundColor = color;
        newConfig.tag.activeBorderColor = color;
        newConfig.tag.text.activeColor = dcGetTextColorFromHighlight(color);
        newConfig.share.copyButtonColor = color;
        updateDemoCenter({ styles: newStyles, config: newConfig });
    };
};
export const useDCUpdateCustomTheme = () => {
    const democenter = useDemoCenter();
    const updateDemoCenter = useUpdateDemoCenter();
    const { styles } = democenter;
    return (color, field) => {
        const newCustomTheme = Object.assign({}, styles.customTheme);
        newCustomTheme[field] = color;
        const newStyles = Object.assign(Object.assign({}, styles), { customTheme: newCustomTheme });
        updateDemoCenter({ styles: newStyles });
    };
};
export const useDCUpdateGradient = () => {
    const democenter = useDemoCenter();
    const updateDemoCenter = useUpdateDemoCenter();
    const { styles } = democenter;
    return (gradient) => {
        const { color1, color2, percent } = gradient;
        const newStyles = Object.assign({}, styles);
        newStyles.gradientColor1 = color1;
        newStyles.gradientColor2 = color2;
        newStyles.gradientPercent = percent;
        updateDemoCenter({ styles: newStyles });
    };
};
export const useDCDefaultFont = () => {
    const config = useDCConfig();
    let defaultFont = "Lato";
    const loopThroughKeys = (obj) => {
        if (!obj)
            return;
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] === "object") {
                loopThroughKeys(obj[key]);
            }
            else if (key === "font") {
                defaultFont = obj[key];
            }
        });
    };
    loopThroughKeys(config);
    return defaultFont;
};
export const useDCUpdateFont = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    const newConfig = Object.assign({}, config);
    return (font) => {
        const loopThroughKeys = (obj) => {
            if (!obj)
                return;
            Object.keys(obj).forEach(key => {
                if (typeof obj[key] === "object") {
                    loopThroughKeys(obj[key]);
                }
                else if (key === "font") {
                    obj[key] = font;
                }
            });
        };
        loopThroughKeys(newConfig);
        setConfig(newConfig);
    };
};
export const useDCModifyButtonConfig = () => {
    const styles = useDCStyles();
    const theme = useDCTheme();
    return (buttonConfig, options) => {
        const { applyGradient, applyTheme, applyHighlight, shrink } = options || {};
        const { gradientColor1, gradientColor2, gradientPercent, buttonBorderRadius, largeButtonBorderRadius, highlightColor, } = styles;
        const newConfig = Object.assign({}, buttonConfig);
        if (applyGradient) {
            newConfig.style = Object.assign(Object.assign({}, buttonConfig.style), { backgroundColor: gradientColor1, backgroundColor2: gradientColor2, gradientPercent: gradientPercent, borderColor: "", color: dcGetTextColorFromGradient({
                    color1: gradientColor1,
                    color2: gradientColor2,
                    percent: gradientPercent,
                }) });
        }
        if (applyHighlight) {
            newConfig.style = Object.assign(Object.assign({}, buttonConfig.style), { backgroundColor: highlightColor, backgroundColor2: highlightColor, borderColor: highlightColor, color: dcGetTextColorFromHighlight(highlightColor) });
        }
        if (applyTheme) {
            newConfig.style = Object.assign(Object.assign({}, buttonConfig.style), { backgroundColor: theme.neutralPrimary, backgroundColor2: "", borderColor: theme.neutralSecondary, color: newConfig.type === DCButtonType.BORDERLESS ? theme.textSecondary : theme.textMain });
        }
        if (shrink) {
            newConfig.type = DCButtonType.DEFAULT;
            delete newConfig.icon;
        }
        if (newConfig.type === DCButtonType.LARGE) {
            newConfig.style.borderRadius = largeButtonBorderRadius;
        }
        else if (newConfig.type !== DCButtonType.BORDERLESS) {
            newConfig.style.borderRadius = buttonBorderRadius;
        }
        return newConfig;
    };
};
export const useDCSetWelcomeConfig = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (welcome) => {
        setConfig(Object.assign(Object.assign({}, config), { welcome }));
    };
};
export const useDCSetUpfrontQuestionsConfig = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (upfrontQuestions) => {
        setConfig(Object.assign(Object.assign({}, config), { upfrontQuestions }));
    };
};
export const useDCSetWelcomePageIsEnabled = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (welcomePageIsEnabled) => {
        const c = cloneDeep(config);
        c.welcome.isEnabled = welcomePageIsEnabled;
        setConfig(c);
    };
};
export const useDCSetUpfrontQuestionsIsEnabled = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (upfrontQuestionsIsEnabled) => {
        const c = cloneDeep(config);
        c.upfrontQuestions.isEnabled = upfrontQuestionsIsEnabled;
        setConfig(c);
    };
};
export const useDCSetGallerySectionIsEnabled = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (galleryViewIsEnabled) => {
        const c = cloneDeep(config);
        c.gallerySection.isEnabled = galleryViewIsEnabled;
        setConfig(c);
    };
};
export const useDCSetAutoPlayIsEnabled = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (autoPlayIsEnabled) => {
        const c = cloneDeep(config);
        c.autoplay = autoPlayIsEnabled;
        setConfig(c);
    };
};
export const useDCSetCTAConfig = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (cta) => {
        setConfig(Object.assign(Object.assign({}, config), { header: Object.assign(Object.assign({}, config.header), { cta }) }));
    };
};
export const useDCSetSelfGuidedButtonText = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (buttonText) => {
        const c = cloneDeep(config);
        c.header.demoButton.text = buttonText;
        c.welcome.demoButton.text = buttonText;
        setConfig(c);
    };
};
export const useDCSetBodyConfig = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (body) => {
        setConfig(Object.assign(Object.assign({}, config), { body }));
    };
};
export const useDCSetTagConfig = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (tag) => {
        setConfig(Object.assign(Object.assign({}, config), { tag }));
    };
};
export const useDCSetSidebarConfig = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (sidebar) => {
        setConfig(Object.assign(Object.assign({}, config), { sidebar }));
    };
};
export const useDCName = () => {
    const demoCenter = useDemoCenter();
    return demoCenter.name;
};
export const useDCSetName = () => {
    const updateDemoCenter = useUpdateDemoCenter();
    return (name) => {
        updateDemoCenter({ name });
    };
};
export const useDCStatus = () => {
    const demoCenter = useDemoCenter();
    const savedDemoCenter = useSavedDemoCenter();
    if (!dcFsAndMongoDocsAreEqual(demoCenter, savedDemoCenter) && savedDemoCenter.status === TourialStatus.LIVE) {
        return TourialStatus.PENDING_CHANGES;
    }
    else {
        return savedDemoCenter.status;
    }
};
export const useDCIsLive = () => {
    const status = useDCStatus();
    return [TourialStatus.LIVE, TourialStatus.PENDING_CHANGES].includes(status);
};
export const useDCSetStatus = () => {
    const updateDemoCenter = useUpdateDemoCenter();
    return (status) => {
        updateDemoCenter({ status });
    };
};
export const useDCUpdatePublishStatus = () => {
    const id = useDCId();
    const setSavedDemoCenter = useSetSavedDemoCenter();
    return (status) => {
        return TourialApiCalls.DemoCenters.updateDemoCenter({ _id: id, status })
            .then(updatedDemoCenter => {
            setSavedDemoCenter(updatedDemoCenter);
        })
            .catch((e) => {
            console.error(`Error updating Demo Center status: ${status}`);
            throw e;
        });
    };
};
export const useDCSlug = () => {
    const demoCenter = useDemoCenter();
    return demoCenter === null || demoCenter === void 0 ? void 0 : demoCenter.slug;
};
export const useDCLogo = () => {
    const demoCenter = useDemoCenter();
    return demoCenter.logo;
};
export const useDCLogoUrl = () => {
    const demoCenter = useDemoCenter();
    return demoCenter.logoUrl;
};
export const useDCSetLogo = () => {
    const updateDemoCenter = useUpdateDemoCenter();
    return (logo) => {
        updateDemoCenter({ logo });
    };
};
export const useDCMetadata = () => {
    const demoCenter = useDemoCenter();
    return demoCenter.htmlMetadata || [];
};
export const useDCSetSlug = () => {
    const updateDemoCenter = useUpdateDemoCenter();
    return (slug) => {
        updateDemoCenter({ slug });
    };
};
export const useDCSaveSlug = () => {
    const democenter = useDemoCenter();
    const updateDemoCenter = useUpdateDemoCenter();
    const setSavedDemoCenter = useSetSavedDemoCenter();
    return (slug) => __awaiter(void 0, void 0, void 0, function* () {
        return yield TourialApiCalls.DemoCenters.updateDemoCenter({ _id: democenter._id, slug })
            .then(demoCenter => {
            updateDemoCenter({ slug });
            setSavedDemoCenter(demoCenter);
        })
            .catch((e) => {
            console.error(`Error updating Demo Center slug: ${slug}`);
            throw e;
        });
    });
};
export const useDCSetLogoUrl = () => {
    const updateDemoCenter = useUpdateDemoCenter();
    return (logoUrl) => {
        updateDemoCenter({ logoUrl });
    };
};
export const useDCThemeType = () => {
    const { config } = useDemoCenter();
    return config.theme;
};
export const useDCSetThemeType = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    return (theme) => {
        setConfig(Object.assign(Object.assign({}, config), { theme }));
    };
};
export const useDCTheme = () => {
    const themeType = useDCThemeType();
    const styles = useDCStyles();
    const themeMap = {
        [DCThemeType.LIGHT]: dcLightTheme,
        [DCThemeType.DARK]: dcDarkTheme,
        [DCThemeType.CUSTOM]: styles.customTheme,
    };
    return themeMap[themeType];
};
export const useDCScreenSizeMonitor = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { width } = useWindowSize();
    const breakpoint = 1600;
    useEffect(() => {
        setIsCollapsed(width < breakpoint);
    }, [width]);
    return isCollapsed;
};
export const useDCUpdateFeaturedContentId = () => {
    var _a;
    const { welcome } = useDCConfig();
    const modules = useDCModules();
    const welcomeModule = modules[0];
    const setWelcomeConfig = useDCSetWelcomeConfig();
    const firstItem = (_a = welcomeModule === null || welcomeModule === void 0 ? void 0 : welcomeModule.tracks[0]) === null || _a === void 0 ? void 0 : _a.items[0];
    useEffect(() => {
        if (welcome.showFeaturedContent) {
            setWelcomeConfig(Object.assign(Object.assign({}, welcome), { featuredContentId: firstItem || welcome.featuredContentId }));
        }
        if (!firstItem) {
            setWelcomeConfig(Object.assign(Object.assign({}, welcome), { featuredContentId: "" }));
        }
    }, [welcomeModule, firstItem]);
};
export const useDCSetStateFromURLParams = () => {
    const setSelectedTags = useDCSetSelectedTags();
    const setTrackSelections = useDCSetTrackSelections();
    useEffect(() => {
        const { tags, trackSelections } = dcGetStateFromCurrentURLParams();
        if (tags) {
            setSelectedTags(tags.split(",").map(t => Number(t)));
        }
        if (trackSelections) {
            setTrackSelections(dcDecodeTrackSelections(trackSelections));
        }
    }, []);
};
export const useDCLoadStateIntoURLParams = () => {
    const history = useHistory();
    const location = useLocation();
    const trackSelections = useDCTrackSelections();
    const createUrl = useDCCreateUrl();
    const forwardPath = useDCGetForwardPath();
    const clearForwardPath = useDCClearFowardPath();
    const moduleIntentEvent = useDCModuleIntentEvent();
    const shouldShowUpfrontQuestions = useDCShouldShowUpfrontQuestions();
    useEffect(() => {
        if (!shouldShowUpfrontQuestions) {
            moduleIntentEvent(trackSelections);
        }
        const params = new URLSearchParams(window.location.search);
        params.set("o", dcEncodeTrackSelections(trackSelections));
        history.replace({ pathname: location.pathname, search: params.toString() });
        if (forwardPath) {
            history.push(createUrl(forwardPath));
            clearForwardPath();
        }
    }, [trackSelections]);
};
export const useDCSetStateFromLocalStorage = () => {
    const setViewedItems = useDCSetViewedItems();
    const dcSettings = getFromStorage(DC_SETTINGS_KEY) || {};
    const { viewedItems } = dcSettings;
    useEffect(() => {
        if (viewedItems) {
            setViewedItems(viewedItems);
        }
    }, []);
};
export const useDCBaseUrl = () => {
    const [baseUrl, setBaseUrl] = useState("");
    useEffect(() => {
        if (!baseUrl) {
            TourialApiCalls.Users.getTourialAuthData()
                .then(data => {
                setBaseUrl(getBaseUrlWithSubdomain(data.account));
            })
                .catch(() => {
                console.error(`Error getting base url`);
            });
        }
    }, []);
    return baseUrl;
};
export const useDCLiveUrl = (opt) => {
    const slug = useDCSlug() || (opt === null || opt === void 0 ? void 0 : opt.slug);
    const baseUrl = useDCBaseUrl();
    return baseUrl ? dcGetLiveUrl(baseUrl, slug) : "";
};
export const useDCCreateUrl = () => {
    return (path) => {
        const params = new URLSearchParams(window.location.search);
        return `/${dcGetRoot()}/${dcGetSlugFromLocation()}${path}?${params.toString()}`;
    };
};
export const useDCEmbedCode = (opts) => {
    const baseUrl = useDCBaseUrl() || (opts === null || opts === void 0 ? void 0 : opts.baseUrl);
    const slug = useDCSlug() || (opts === null || opts === void 0 ? void 0 : opts.slug);
    const liveUrl = useDCLiveUrl({ slug }) || (opts === null || opts === void 0 ? void 0 : opts.liveUrl);
    let s = `<script type="text/javascript" src="${baseUrl}dc-embed.js" dcSlug="${slug}"></script>`;
    s += `<div style="position: relative; width: 100%; height:100%; padding: 0;" id="dc-${slug}">`;
    s += `<iframe allowfullscreen style="display: block; position: absolute; min-height: 100%; min-width: 100%; padding: 0; margin: 0" frameborder="0" scrolling="no" id="dc-iframe" ></iframe>`;
    s += `<script>document.getElementById("dc-${slug}").getElementsByTagName("iframe")[0].src = "${liveUrl}?isEmbedded=true"</script>`;
    s += `</div>`;
    return s;
};
export const useDCOnKeyDown = (key, fn, watchVar) => {
    useEffect(() => {
        const handler = (e) => (e.key === key ? fn() : null);
        document.body.addEventListener("keydown", handler);
        return () => {
            document.body.removeEventListener("keydown", handler);
        };
    }, [watchVar]);
};
export const useDCOnEscKey = (escFn) => {
    useDCOnKeyDown("Escape", escFn);
};
export const useDCOnEnterKey = (enterFn, watchVar) => {
    useDCOnKeyDown("Enter", enterFn, watchVar);
};
export const useDCSidebar = () => {
    const setSidebarIsOpen = useDCSetSidebarIsOpen();
    useEffect(() => {
        setSidebarIsOpen(true);
        return () => setSidebarIsOpen(false);
    }, []);
};
export const useDCWelcomeScreenTimer = () => {
    const setShouldShowWelcomeScreen = useDCSetShouldShowWelcomeScreen();
    useEffect(() => {
        window.setTimeout(() => setShouldShowWelcomeScreen(false), 1000);
    }, []);
};
