import React, { useEffect, useState } from "react";
import { useLegacyHideTool, useViewportMode } from "../../../redux/selectors/redux-selectors";
import { getBorderStyles, validateEmail } from "../../../helpers/misc";
import { withToolWrapper } from "../with-tool-wrapper";
import { ViewportMode } from "../../../types/definitions";
import { Layout1 } from "../builder/tools/form-v2/layouts/layout1";
import { Layout2 } from "../builder/tools/form-v2/layouts/layout2";
import { Layout3 } from "../builder/tools/form-v2/layouts/layout3";
import { Layout4 } from "../builder/tools/form-v2/layouts/layout4";
import { LayoutConfirmation } from "../builder/tools/form-v2/layouts/layout-confirmation";
import { CloseButton } from "../builder/tools/form-v2/subcomponents/close-button";
import { initLocalStorageFormState, wasFormSubmitted } from "../../../helpers/form-helpers";
export function isFieldValid(field) {
    if (field.type === "Email")
        return validateEmail(field.value);
    if (field.type === "Phone")
        return field.value.length === 10;
    if (field.type === "Dropdown")
        return field.value !== "";
    return !!field.value;
}
export function extractTextStyle(text) {
    return {
        fontFamily: text.fontFamily,
        fontWeight: +text.fontWeight,
        fontSize: +text.fontSize,
        color: text.color,
    };
}
export function extractThemeStyle(theme) {
    return {
        backgroundColor: theme.backgroundColor,
        borderColor: theme.borderColor,
        borderRadius: +theme.borderRadius,
        borderWidth: +theme.borderWidth,
    };
}
export var FormV2Tab;
(function (FormV2Tab) {
    FormV2Tab[FormV2Tab["Form"] = 0] = "Form";
    FormV2Tab[FormV2Tab["Confirmation"] = 1] = "Confirmation";
})(FormV2Tab || (FormV2Tab = {}));
export const formPercentageOfViewport = 0.6; // hardcoded for now, will likely implement editable form dimensions
function TourialFormV2(props) {
    const { toolData, viewportDimensions: vpd } = props;
    const { isDismissable, id } = toolData;
    const [tab, setTab] = useState(FormV2Tab.Form);
    const viewportMode = useViewportMode();
    const hideTool = useLegacyHideTool();
    useEffect(() => {
        initLocalStorageFormState(id);
        if (wasFormSubmitted(id))
            setTab(FormV2Tab.Confirmation);
    }, []);
    const activeTabStyle = {
        cursor: "pointer",
        background: "rgba(47, 128, 237, 1)",
        color: "white",
        width: "100px",
        height: "100%",
    };
    const inactiveTabStyle = Object.assign(Object.assign({}, activeTabStyle), { background: "rgba(47, 128, 237, 0.5)" });
    const { backgroundTheme } = toolData.theme;
    const runAfterSubmissionFn = () => {
        toolData.disableConfirmation ? hideTool(id) : setTab(FormV2Tab.Confirmation);
    };
    return (React.createElement("div", { className: "_test_form-viewtool", id: id, key: id, style: {
            width: vpd.width,
            height: vpd.height,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            flexDirection: "column",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            paddingBottom: "10%",
        } },
        viewportMode === ViewportMode.EDIT && (React.createElement("div", { id: id + "tabs", style: {
                height: 40,
                width: vpd.width * formPercentageOfViewport,
                display: "flex",
                alignItems: "flex-start",
            } },
            React.createElement("button", { style: tab === FormV2Tab.Form
                    ? Object.assign(Object.assign({}, activeTabStyle), { borderTopLeftRadius: 6 }) : Object.assign(Object.assign({}, inactiveTabStyle), { borderTopLeftRadius: 6 }), onClick: () => setTab(FormV2Tab.Form) }, "Form"),
            React.createElement("button", { style: tab === FormV2Tab.Confirmation
                    ? Object.assign(Object.assign({}, activeTabStyle), { borderTopRightRadius: 6 }) : Object.assign(Object.assign({}, inactiveTabStyle), { borderTopRightRadius: 6 }), onClick: () => setTab(FormV2Tab.Confirmation) }, "Confirmation"))),
        React.createElement("div", { style: Object.assign(Object.assign(Object.assign({ width: vpd.width * formPercentageOfViewport, height: vpd.height * formPercentageOfViewport, overflow: "hidden", borderStyle: "solid" }, (viewportMode !== ViewportMode.EDIT && {
                animation: "fadeInDown",
                animationDuration: "0.5s",
            })), { backgroundColor: backgroundTheme.backgroundColor }), getBorderStyles(true, backgroundTheme.borderRadius, backgroundTheme.borderWidth, backgroundTheme.borderColor, "solid", vpd)) },
            (isDismissable || tab === FormV2Tab.Confirmation) && (React.createElement("div", { style: {
                    width: "100%",
                    height: "calc(100% * .08)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                } },
                React.createElement(CloseButton, { id: id, vpd: vpd, tab: tab, viewportMode: viewportMode }))),
            React.createElement("div", { style: {
                    width: "100%",
                    height: `calc(100% * ${isDismissable || tab === FormV2Tab.Confirmation ? 0.92 : 1})`,
                } },
                tab === FormV2Tab.Form && toolData.layout === 1 && (React.createElement(Layout1, { toolData: toolData, viewportDimensions: props.viewportDimensions, runAfterSubmissionFn: runAfterSubmissionFn })),
                tab === FormV2Tab.Form && toolData.layout === 2 && (React.createElement(Layout2, { toolData: toolData, viewportDimensions: props.viewportDimensions, runAfterSubmissionFn: runAfterSubmissionFn })),
                tab === FormV2Tab.Form && toolData.layout === 3 && (React.createElement(Layout3, { toolData: toolData, viewportDimensions: props.viewportDimensions, runAfterSubmissionFn: runAfterSubmissionFn })),
                tab === FormV2Tab.Form && toolData.layout === 4 && (React.createElement(Layout4, { toolData: toolData, viewportDimensions: props.viewportDimensions, runAfterSubmissionFn: runAfterSubmissionFn })),
                tab === FormV2Tab.Confirmation && (React.createElement(LayoutConfirmation, { toolData: toolData, viewportDimensions: props.viewportDimensions }))))));
}
export const FormV2 = React.memo(withToolWrapper(TourialFormV2));
