import React, { useEffect, useState } from "react";
import TModal, { TModalButtonsContainer } from "../../../../styled/t-modal";
import { DCItemType } from "../../../../../../../shared/types/demo-center";
import TButton from "../../../../styled/t-button";
import { DCCMAddCOntentToursTab } from "./dc-cm-add-content-tours-tab";
import { DCCMAddContentItemsTab } from "./dc-cm-add-content-items-tab";
import { DCCMAddContentUploadTab } from "./dc-cm-add-content-upload-tab";
import { useDCOnEnterKey } from "../../hooks/dc-hooks";
import { DCCMAddCOntentDomToursTab } from "./dc-cm-add-content-dom-tours-tab";
import { useFeatureFlags } from "../../../../../redux/selectors/redux-selectors";
import { useDCGetNewItem } from "../../hooks/dc-item-hooks";
var Tab;
(function (Tab) {
    Tab["TOURS"] = "Tours";
    Tab["IMPORTED_CONTENT"] = "Imported Content";
    Tab["UPLOAD_NEW"] = "Upload New";
    Tab["DOM_TOURS"] = "HTML Tours";
})(Tab || (Tab = {}));
export const DCCMAddContentModal = ({ onClose, onAddItems, currentItems }) => {
    const [activeTab, setActiveTab] = useState(Tab.TOURS);
    const [selectedTours, setSelectedTours] = useState([]);
    const [selectedDomTours, setSelectedDomTours] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [uploadedItems, setUploadedItems] = useState([]);
    const [itemsToAdd, setItemsToAdd] = React.useState([]);
    const getNewItem = useDCGetNewItem();
    const { htmlTours } = useFeatureFlags();
    useDCOnEnterKey(() => {
        handleAddButton();
    }, [itemsToAdd]);
    useEffect(() => {
        const newItems = [];
        selectedTours.forEach(t => {
            newItems.push(getNewItem({
                id: t.id,
                title: t.name,
                thumbnail: t.thumbnail,
                type: DCItemType.TOUR,
                duration: t.estimatedDuration,
            }));
        });
        selectedItems.forEach(i => {
            newItems.push(i);
        });
        uploadedItems.forEach(i => {
            newItems.push(i);
        });
        selectedDomTours.forEach(t => {
            newItems.push(getNewItem({
                id: t.id,
                title: t.name,
                thumbnail: t.thumbnail,
                type: DCItemType.DOM_TOUR,
                duration: t.estimatedDuration,
            }));
        });
        setItemsToAdd(newItems);
    }, [selectedTours, selectedDomTours, selectedItems, uploadedItems]);
    useEffect(() => {
        setSelectedItems([]);
        setSelectedTours([]);
        setUploadedItems([]);
        setItemsToAdd([]);
    }, [activeTab]);
    const handleAddButton = () => {
        if (itemsToAdd.length > 0) {
            onAddItems(itemsToAdd);
            onClose();
        }
    };
    const tabs = [Tab.TOURS, Tab.IMPORTED_CONTENT, Tab.UPLOAD_NEW];
    if (htmlTours)
        tabs.splice(1, 0, Tab.DOM_TOURS);
    return (React.createElement(TModal, { title: "Add Content", onClose: onClose, tabs: tabs, onTabSelect: (tab) => setActiveTab(tab) },
        activeTab === Tab.TOURS && (React.createElement(DCCMAddCOntentToursTab, { currentItems: currentItems, setSelectedTours: setSelectedTours })),
        activeTab === Tab.DOM_TOURS && (React.createElement(DCCMAddCOntentDomToursTab, { currentItems: currentItems, setSelectedTours: setSelectedDomTours })),
        activeTab === Tab.IMPORTED_CONTENT && (React.createElement(DCCMAddContentItemsTab, { currentItems: currentItems, setSelectedItems: setSelectedItems })),
        activeTab === Tab.UPLOAD_NEW && React.createElement(DCCMAddContentUploadTab, { setUploadedItems: setUploadedItems }),
        React.createElement(TModalButtonsContainer, null,
            React.createElement(TButton, { disabled: itemsToAdd.length === 0, onClick: handleAddButton }, "+ Add to track"))));
};
