import { ToolType } from "./common";
export function convertTourialFormToFormV3Data(mongoForm, isGated, isEnabled) {
    return {
        type: ToolType.FormV3,
        isGated,
        isEnabled,
        /** not actually a toolId, represents the form's mongo _id */
        id: mongoForm._id,
        name: mongoForm.name,
        fields: mongoForm.fields,
        validation: mongoForm.fields.map(() => true),
        layout: mongoForm.layout,
        enableConfirmation: mongoForm.enableConfirmation,
        logo: mongoForm.logo,
        text: mongoForm.text,
        theme: mongoForm.theme,
        /** legacy */
        sizeAndPosition: { x: 0, y: 0, width: 0, height: 0 },
        chiliPiper: mongoForm.chiliPiper,
        captchaEnabled: mongoForm.captchaEnabled || false,
        honeyPotEnabled: mongoForm.honeyPotEnabled || false,
        optIn: mongoForm.optIn || {
            enabled: false,
            text: "",
            location: "below",
        },
    };
}
