import { FieldDataType } from "../../../../../../../shared/types/form";
import { EmailField } from "./email-field";
import React from "react";
import { CheckboxField } from "./checkbox-field";
import { DropdownField } from "./dropdown-field";
import { TextField } from "./text-field";
import { PhoneField } from "./phone-field";
import { NumberField } from "./number-field";
import { TextareaField } from "./multiline-text-field";
export function GetField(props) {
    const fields = {
        [FieldDataType.EMAIL]: React.createElement(EmailField, Object.assign({ key: props.fieldIndex }, props)),
        [FieldDataType.EMAILNONGENERIC]: React.createElement(EmailField, Object.assign({ key: props.fieldIndex, nongeneric: true }, props)),
        [FieldDataType.CHECKBOX]: React.createElement(CheckboxField, Object.assign({ key: props.fieldIndex }, props)),
        [FieldDataType.DROPDOWN]: React.createElement(DropdownField, Object.assign({ key: props.fieldIndex }, props)),
        [FieldDataType.TEXT]: React.createElement(TextField, Object.assign({ key: props.fieldIndex }, props)),
        [FieldDataType.MULTILINETEXT]: React.createElement(TextareaField, Object.assign({ key: props.fieldIndex }, props)),
        [FieldDataType.PHONE]: React.createElement(PhoneField, Object.assign({ key: props.fieldIndex }, props)),
        [FieldDataType.NUMBER]: React.createElement(NumberField, Object.assign({ key: props.fieldIndex }, props)),
    };
    return fields[props.field.type];
}
