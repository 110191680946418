import { BrandingType } from "./theme";
export var TourialAppEventTypes;
(function (TourialAppEventTypes) {
    TourialAppEventTypes["BUILDER_ENTERED"] = "BUILDER_ENTERED";
    TourialAppEventTypes["BUILDER_ENTERED_V3"] = "BUILDER_ENTERED_V3";
    TourialAppEventTypes["LIVE_URL_COPIED"] = "LIVE_URL_COPIED";
    TourialAppEventTypes["EMBED_CODE_COPIED"] = "EMBED_CODE_COPIED";
    TourialAppEventTypes["LIGHTBOX_CODE_COPIED"] = "LIGHTBOX_CODE_COPIED";
    TourialAppEventTypes["APP_PAGE_VIEWED"] = "APP_PAGE_VIEWED";
    TourialAppEventTypes["TOURIAL_PUBLISH_CHANGED"] = "TOURIAL_PUBLISH_CHANGED";
    TourialAppEventTypes["TOURIAL_DELETED"] = "TOURIAL_DELETED";
    TourialAppEventTypes["TOURIAL_CREATED"] = "TOURIAL_CREATED";
    TourialAppEventTypes["TOURIAL_CLONED"] = "TOURIAL_CLONED";
    TourialAppEventTypes["ANALYTICS_VIEWED"] = "ANALYTICS_VIEWED";
    TourialAppEventTypes["ADMIN_USER_CREATED"] = "ADMIN_USER_CREATED";
    TourialAppEventTypes["SUPERADMIN_ACCOUNT_CREATED"] = "SUPERADMIN_ACCOUNT_CREATED";
    TourialAppEventTypes["SUPERADMIN_ACCOUNT_ACTIVE_STATUS_CHANGED"] = "SUPERADMIN_ACCOUNT_ACTIVE_STATUS_CHANGED";
    TourialAppEventTypes["SUPERADMIN_USER_CREATED"] = "SUPERADMIN_USER_CREATED";
    TourialAppEventTypes["SUPERADMIN_FEATURE_FLAG_CHANGED"] = "SUPERADMIN_FEATURE_FLAG_CHANGED";
    TourialAppEventTypes["SUPERADMIN_TOUR_LIMIT_CHANGED"] = "SUPERADMIN_TOUR_LIMIT_CHANGED";
    TourialAppEventTypes["LOGIN_STATUS_CHANGED"] = "LOGIN_STATUS_CHANGED";
    TourialAppEventTypes["INTEGRATION_AUTH_CHANGED"] = "INTEGRATION_AUTH_CHANGED";
    TourialAppEventTypes["INTEGRATION_FORM_CHANGED"] = "INTEGRATION_FORM_CHANGED";
    TourialAppEventTypes["WORKSPACE_CREATED"] = "WORKSPACE_CREATED";
    TourialAppEventTypes["DEMOCENTER_CREATED"] = "DEMOCENTER_CREATED";
    TourialAppEventTypes["DEMOCENTER_DELETED"] = "DEMOCENTER_DELETED";
    TourialAppEventTypes["DEMOCENTER_ARCHIVED"] = "DEMOCENTER_ARCHIVED";
    TourialAppEventTypes["DEMOCENTER_PUBLISHED"] = "DEMOCENTER_PUBLISHED";
    TourialAppEventTypes["DEMOCENTER_REPUBLISHED"] = "DEMOCENTER_REPUBLISHED";
    TourialAppEventTypes["DEMOCENTER_UNPUBLISHED"] = "DEMOCENTER_UNPUBLISHED";
    TourialAppEventTypes["DEMOCENTER_CLONED"] = "DEMOCENTER_CLONED";
    TourialAppEventTypes["DEMOCENTER_DISCARD_CHANGES"] = "DEMOCENTER_DISCARD_CHANGES";
    TourialAppEventTypes["PROJECT_ROOM_LINK_CLICKED"] = "PROJECT_ROOM_LINK_CLICKED";
})(TourialAppEventTypes || (TourialAppEventTypes = {}));
export var PersonType;
(function (PersonType) {
    PersonType["TOURIAL_EMPLOYEE"] = "TOURIAL_EMPLOYEE";
    PersonType["TOURIAL_CUSTOMER"] = "TOURIAL_CUSTOMER";
    PersonType["TOURIAL_VISITOR"] = "TOURIAL_VISITOR";
})(PersonType || (PersonType = {}));
export var MainAppPagePaths;
(function (MainAppPagePaths) {
    MainAppPagePaths["HOMEPAGE"] = "/";
    MainAppPagePaths["BUILDER"] = "/v2/editor";
    MainAppPagePaths["V3_BUILDER"] = "/editor";
    MainAppPagePaths["DOM_TOUR_BUILDER"] = "/dt/builder/:id";
    MainAppPagePaths["PROFILE"] = "/settings/profile";
    MainAppPagePaths["SETTINGS_USERS"] = "/settings/users";
    MainAppPagePaths["SETTINGS_SCRIPTS"] = "/settings/scripts";
    MainAppPagePaths["SETTINGS_DOMAIN"] = "/settings/domain";
    MainAppPagePaths["SETTINGS"] = "/settings";
    MainAppPagePaths["ADMIN_ACTIVITY"] = "/admin/activity";
    MainAppPagePaths["ADMIN_INSIGHTS"] = "/admin/insights";
    MainAppPagePaths["ADMIN_JOBS"] = "/admin/jobs";
    MainAppPagePaths["ADMIN_USERS"] = "/admin/users";
    MainAppPagePaths["ADMIN_ACCOUNTS"] = "/admin/accounts";
    MainAppPagePaths["ADMIN"] = "/admin";
    MainAppPagePaths["FORGOT_PASSWORD"] = "/forgot-password";
    MainAppPagePaths["LOGIN"] = "/login";
    MainAppPagePaths["INTEGRATIONS"] = "/integrations";
    MainAppPagePaths["INTEGRATIONS_MARKETO"] = "/integrations/marketo";
    MainAppPagePaths["INTEGRATIONS_MARKETO_SETUP"] = "/integrations/marketo/setup";
    MainAppPagePaths["INTEGRATIONS_SALESFORCE"] = "/integrations/salesforce";
    MainAppPagePaths["INTEGRATIONS_HUBSPOT"] = "/integrations/hubspot";
    MainAppPagePaths["INTEGRATIONS_PARDOT"] = "/integrations/pardot";
    MainAppPagePaths["INTEGRATIONS_PARDOT_SETUP"] = "/integrations/pardot/setup";
    MainAppPagePaths["INTEGRATIONS_CALENDLY"] = "/integrations/calendly";
    MainAppPagePaths["INTEGRATIONS_CHILIPIPER"] = "/integrations/chilipiper";
    MainAppPagePaths["INTEGRATIONS_ZAPIER"] = "/integrations/zapier";
    MainAppPagePaths["INTEGRATIONS_SLACK"] = "/integrations/slack";
    MainAppPagePaths["INSIGHTS"] = "/insights";
    MainAppPagePaths["ANALYTICS"] = "/analytics";
    MainAppPagePaths["ANALYTICS_OUTBOUND"] = "/analytics/outbound";
    MainAppPagePaths["GET_STARTED"] = "/get-started";
    MainAppPagePaths["FORMS"] = "/forms";
    MainAppPagePaths["FORM_EDITOR"] = "/forms/editor";
    MainAppPagePaths["BRANDING"] = "/branding";
    MainAppPagePaths["BRANDING_EDITOR_TOOLTIP"] = "/branding/editor/tooltip";
    MainAppPagePaths["BRANDING_EDITOR_TYPEWRITER"] = "/branding/editor/typewriter";
    MainAppPagePaths["BRANDING_EDITOR_MOUSE"] = "/branding/editor/mouse";
    MainAppPagePaths["BRANDING_EDITOR_MODAL"] = "/branding/editor/modal";
    MainAppPagePaths["BRANDING_EDITOR_OVERLAY"] = "/branding/editor/overlay";
    MainAppPagePaths["BRANDING_EDITOR_NAV"] = "/branding/editor/nav";
    MainAppPagePaths["BRANDING_EDITOR_PAGE"] = "/branding/editor/page";
    MainAppPagePaths["BRANDING_EDITOR_FORM"] = "/branding/editor/form";
    MainAppPagePaths["MICROSITES_EDITOR_LEGACY"] = "/microsites/editor";
    MainAppPagePaths["MICROSITES_EDITOR"] = "/tourplayer/editor";
    MainAppPagePaths["USER_MANAGEMENT"] = "/user-management";
    MainAppPagePaths["DEMOCENTER_BUILDER"] = "/democenter/builder";
    MainAppPagePaths["LINK_ACTIVITY"] = "/link-activity";
    MainAppPagePaths["WARMLYIFRAME"] = "/warmly";
    MainAppPagePaths["PLANS"] = "/plans";
    MainAppPagePaths["PLAN"] = "/plans/plan";
    MainAppPagePaths["PLAN_WIZARD"] = "/plans/wizard";
})(MainAppPagePaths || (MainAppPagePaths = {}));
export const brandingPrefix = "Your brand";
export const integrationsPrefix = "Integrations";
export const PAGE_TITLES = {
    [MainAppPagePaths.HOMEPAGE]: "Home",
    [MainAppPagePaths.LINK_ACTIVITY]: "Link activity",
    [MainAppPagePaths.ANALYTICS]: "Analytics",
    [MainAppPagePaths.ANALYTICS_OUTBOUND]: "Outbound activity",
    [MainAppPagePaths.INTEGRATIONS]: integrationsPrefix,
    [MainAppPagePaths.INTEGRATIONS_MARKETO]: integrationsPrefix + "Marketo",
    [MainAppPagePaths.INTEGRATIONS_MARKETO_SETUP]: integrationsPrefix + "Marketo",
    [MainAppPagePaths.INTEGRATIONS_SALESFORCE]: integrationsPrefix + "Salesforce",
    [MainAppPagePaths.INTEGRATIONS_HUBSPOT]: integrationsPrefix + "Hubspot",
    [MainAppPagePaths.INTEGRATIONS_PARDOT]: integrationsPrefix + "Pardot",
    [MainAppPagePaths.INTEGRATIONS_PARDOT_SETUP]: integrationsPrefix + "Pardot",
    [MainAppPagePaths.INTEGRATIONS_CALENDLY]: integrationsPrefix + "Calendly",
    [MainAppPagePaths.INTEGRATIONS_CHILIPIPER]: integrationsPrefix + "Chilipiper",
    [MainAppPagePaths.INTEGRATIONS_ZAPIER]: integrationsPrefix + "Zapier",
    [MainAppPagePaths.INTEGRATIONS_SLACK]: integrationsPrefix + "Slack",
    [MainAppPagePaths.ADMIN_ACCOUNTS]: "Super admin accounts",
    [MainAppPagePaths.ADMIN]: "Super admin settings",
    [MainAppPagePaths.ADMIN_INSIGHTS]: "Super admin insights",
    [MainAppPagePaths.ADMIN_JOBS]: "Super admin jobs",
    [MainAppPagePaths.ADMIN_ACTIVITY]: "User activity",
    [MainAppPagePaths.SETTINGS_SCRIPTS]: "Script manager",
    [MainAppPagePaths.SETTINGS_DOMAIN]: "Domain setup",
    [MainAppPagePaths.PROFILE]: "Profile",
    [MainAppPagePaths.FORMS]: "Forms",
    [MainAppPagePaths.FORM_EDITOR]: "Form editor",
    [MainAppPagePaths.USER_MANAGEMENT]: "Users",
    [MainAppPagePaths.WARMLYIFRAME]: "Reveal",
    [MainAppPagePaths.BRANDING]: brandingPrefix,
    [MainAppPagePaths.BRANDING_EDITOR_TOOLTIP]: brandingPrefix + BrandingType.TOOLTIP,
    [MainAppPagePaths.BRANDING_EDITOR_TYPEWRITER]: brandingPrefix + BrandingType.TYPEWRITER,
    [MainAppPagePaths.BRANDING_EDITOR_MOUSE]: brandingPrefix + BrandingType.MOUSE,
    [MainAppPagePaths.BRANDING_EDITOR_MODAL]: brandingPrefix + BrandingType.MODAL,
    [MainAppPagePaths.BRANDING_EDITOR_OVERLAY]: brandingPrefix + BrandingType.OVERLAY,
    [MainAppPagePaths.BRANDING_EDITOR_NAV]: brandingPrefix + BrandingType.NAV,
    [MainAppPagePaths.BRANDING_EDITOR_PAGE]: brandingPrefix + BrandingType.PAGE,
    [MainAppPagePaths.BRANDING_EDITOR_FORM]: brandingPrefix + BrandingType.FORM,
    [MainAppPagePaths.PLANS]: "Tour Planner",
    [MainAppPagePaths.PLAN]: "Tour Planner",
    [MainAppPagePaths.PLAN_WIZARD]: "Tour Planner Wizard",
};
