import React, { useEffect } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { DCIcon } from "../../components/dc-icon";
import TTooltip from "../../../../styled/t-tooltip";
export const DCStylesFloatingMenu = ({ buttons, activeButtonName }) => {
    const [activeButton, setActiveButton] = React.useState("");
    useEffect(() => {
        setActiveButton(activeButtonName || buttons[0].name);
    }, [activeButtonName]);
    return (React.createElement(Menu, null, buttons.map(button => (React.createElement(MenuItem, { key: button.name, button: button, selected: activeButton === button.name, setActiveButton: setActiveButton })))));
};
const MenuItem = ({ button, setActiveButton, selected }) => {
    const { name, icon, onClick, disabled } = button;
    const disabledStyle = {
        cursor: "not-allowed",
        backgroundColor: newTheme.colors.grey100,
        color: newTheme.colors.grey500,
    };
    const handleClick = () => {
        if (disabled)
            return;
        setActiveButton(name);
        onClick();
    };
    return (React.createElement(TTooltip, { placement: "right", content: name, disabled: disabled },
        React.createElement(MenuItemContainer, { onClick: handleClick, className: selected ? "selected" : null, style: disabled ? disabledStyle : null },
            React.createElement(DCIcon, { type: icon }))));
};
const Menu = styled.ul `
  position: fixed;
  top: 132px;
  left: 16px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${newTheme.colors.grey400};
  overflow: hidden;
  > div:last-child {
    border-bottom: none;
  }
  z-index: 10;
`;
const MenuItemContainer = styled.li `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${newTheme.colors.grey100};
  cursor: pointer;
  color: ${newTheme.colors.grey500};
  &:hover {
    background-color: ${newTheme.colors.grey200};
  }
  &.selected {
    background-color: ${newTheme.colors.white};
    color: ${newTheme.colors.black};
  }
  border-bottom: 1px solid ${newTheme.colors.grey400};
`;
