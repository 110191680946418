import React, { useState } from "react";
import TButton from "../../styled/t-button";
import TInput from "../../styled/t-input";
import TModal, { TModalButtonsContainer } from "../../styled/t-modal";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import { IContentType } from "../../../../../shared/types/events";
import { ContentContainer } from "./workspace-create-modal";
import { newTheme } from "../../styled/theme";
import { TDropdownSelect } from "../../styled/t-dropdown-select";
import { useAsync } from "react-async";
import ShareLinkCreateDemoSelector from "./sharelink-create-demo-selector";
import { ShareLinkPassword } from "./sharelink-password";
import { ShareLinkFormGate } from "./sharelink-form-gate";
export default function CreateShareLinkModal({ setIsCreateShareLinkModalOpen, contentType, tourialId, demoCenterId, reload, }) {
    const [linkName, setLinkName] = useState("");
    const [accounts, setAccounts] = useState([]);
    const [opps, setOpps] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState();
    const [selectedOpps, setSelectedOpps] = useState();
    const [selectedDemoId, setSelectedDemoId] = useState("");
    const [formGate, setFormGate] = useState({ enabled: false });
    const [selectedDemoType, setSelectedDemoType] = useState();
    const [password, setPassword] = useState(null);
    useAsync({
        promiseFn: TourialApiCalls.Integrations.getSalesforceAccounts,
        onResolve(data) {
            if (data === null || data === void 0 ? void 0 : data.length) {
                const accounts = [{ label: "-- Select an account --", value: "" }];
                data.forEach(account => {
                    accounts.push({ label: account.Name, value: account.Id });
                });
                setAccounts(accounts);
            }
        },
    });
    const hasSelection = tourialId || demoCenterId || selectedDemoId;
    return (React.createElement(TModal, { onClose: () => setIsCreateShareLinkModalOpen(false), title: "Create share link" },
        React.createElement(ContentContainer, null,
            !demoCenterId && !tourialId && (React.createElement(ShareLinkCreateDemoSelector, { setSelectedDemoId: setSelectedDemoId, setSelectedDemoType: setSelectedDemoType, selectedDemoId: selectedDemoId })),
            React.createElement("div", null,
                React.createElement("div", { style: { marginBottom: 4 } }, "Link name"),
                React.createElement(TInput, { style: { width: "100%" }, placeholder: "Link name", value: linkName, onChange: e => setLinkName(e.target.value), id: "_test_share_link_create_link_name" })),
            React.createElement("div", { style: { display: "flex", gap: 16 } },
                !!(accounts === null || accounts === void 0 ? void 0 : accounts.length) && (React.createElement("div", null,
                    React.createElement("div", { style: { marginBottom: 4 } },
                        "Salesforce Account",
                        " ",
                        React.createElement("span", { style: { fontStyle: "italic", color: newTheme.colors.grey500 } }, "(optional)")),
                    React.createElement(TDropdownSelect, { options: accounts, selectedValue: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.value, placeholder: "[None selected]", isSearchable: true, style: { width: 212 }, onChange: (option) => {
                            setSelectedAccount(option);
                            setSelectedOpps(null);
                            setOpps([]);
                            if (option.value) {
                                TourialApiCalls.Integrations.getSalesforceAccountOpps(option.value)
                                    .then(data => {
                                    var _a;
                                    if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.length) {
                                        const opps = [{ label: "-- Select an opp --", value: "" }];
                                        data.data.forEach(opp => {
                                            opps.push({ label: opp.Name, value: opp.Id });
                                        });
                                        setOpps(opps);
                                    }
                                })
                                    .catch(err => console.log(err));
                            }
                        } }))),
                !!(selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.value) && (React.createElement("div", null,
                    React.createElement("div", { style: { marginBottom: 4 } },
                        "Opp ",
                        React.createElement("span", { style: { fontStyle: "italic", color: newTheme.colors.grey500 } }, "(optional)")),
                    React.createElement(TDropdownSelect, { options: opps, selectedValue: selectedOpps === null || selectedOpps === void 0 ? void 0 : selectedOpps.value, placeholder: "[None selected]", isSearchable: true, style: { width: 212 }, onChange: (option) => {
                            setSelectedOpps(option);
                        } }))))),
        React.createElement(ShareLinkFormGate, { formGate: formGate, setFormGate: setFormGate }),
        React.createElement(ShareLinkPassword, { password: password, setPassword: setPassword }),
        React.createElement(TModalButtonsContainer, null,
            React.createElement(TButton, { disabled: !(linkName === null || linkName === void 0 ? void 0 : linkName.length) || !hasSelection, onClick: () => {
                    TourialApiCalls.ShareLinks.createShareLink({
                        contentType: contentType || selectedDemoType,
                        tourialId: tourialId ||
                            (selectedDemoType === IContentType.TOUR || selectedDemoType === IContentType.HTML_TOUR
                                ? selectedDemoId
                                : ""),
                        demoCenterId: demoCenterId || (selectedDemoType === IContentType.DEMOCENTER ? selectedDemoId : ""),
                        name: linkName,
                        sfdcAccountId: (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.value) || "",
                        sfdcAccountName: (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.label) || "",
                        sfdcOpportunityId: (selectedOpps === null || selectedOpps === void 0 ? void 0 : selectedOpps.value) || "",
                        sfdcOpportunityName: (selectedOpps === null || selectedOpps === void 0 ? void 0 : selectedOpps.label) || "",
                        password,
                        formGate,
                    })
                        .then(() => {
                        setIsCreateShareLinkModalOpen(false);
                        reload();
                    })
                        .catch(() => {
                        setIsCreateShareLinkModalOpen(false);
                    });
                }, id: "_test_share_link_create_link" }, "Create link"))));
}
