import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Four04Page } from "../../../404-page";
import IsLoading from "../../../../../legacy/components/is-loading/is-loading";
import { useDCMode, useDCSetDomTourialRootState } from "../../../../../redux/selectors/demo-center-selectors";
import { useSetDomTourSessionIdWithinDemoCenter } from "../../../../../hooks/live-hooks";
import { DomTourLiveViewport } from "../../../dom-tour/live/live-dom-tourial";
import { useHTClearRootState, useHTDimensions, useHTTour, useHTIsMobile, } from "../../../../../redux/selectors/ht-selectors";
import { HTMode } from "../../../../../../../shared/types/dom-tour";
import { DCMode } from "../../../../../../../shared/types/demo-center";
import { MOBILE_TOOLTIP_HEIGHT } from "../../../dom-tour/config";
import { useHTHasTourEnded } from "../../../../../hooks/use-has-tour-ended";
import { useDCItemHasBeenCompletedWhen } from "../../hooks/dc-item-hooks";
export const DCDOMTourPlayer = ({ item }) => {
    const { id } = item;
    const [isTourialErrored, setIsTourialErrored] = useState(false);
    const setTourialRootState = useDCSetDomTourialRootState();
    const clearTourialRootState = useHTClearRootState();
    const mode = useDCMode();
    const domTourMode = mode === DCMode.LIVE ? HTMode.LIVE : HTMode.PREVIEW;
    const tourHasEnded = useHTHasTourEnded();
    useDCItemHasBeenCompletedWhen(tourHasEnded);
    useSetDomTourSessionIdWithinDemoCenter();
    useEffect(() => {
        if (id) {
            setTourialRootState(id, domTourMode)
                .then(() => {
                window.dispatchEvent(new Event("resize"));
            })
                .catch(() => setIsTourialErrored(true));
        }
        return () => {
            clearTourialRootState();
        };
    }, [id]);
    const isMobile = useHTIsMobile();
    const dimensions = useHTDimensions();
    const ref = useRef();
    const [containerHeightPercent, setContainerHeightPercent] = useState(56.25);
    useEffect(() => {
        if (ref.current && dimensions) {
            const heightPercentage = dimensions.height / dimensions.width;
            // @ts-expect-error
            const containerWidth = ref.current.getBoundingClientRect().width;
            const mobilePercentage = isMobile ? MOBILE_TOOLTIP_HEIGHT / containerWidth : 0;
            const finalPercentage = dimensions ? (heightPercentage + mobilePercentage) * 100 : 56.25;
            setContainerHeightPercent(finalPercentage);
        }
    }, [ref, dimensions]);
    return (React.createElement(TourialContainer, null,
        React.createElement(Embed, { ref: ref, id: "demo-center-dom-tour-container", heightPercentage: containerHeightPercent },
            React.createElement(TourialWindow, { isTourialErrored: isTourialErrored }))));
};
export const TourialWindow = ({ isTourialErrored }) => {
    const isLoaded = useHTTour();
    if (isLoaded) {
        return React.createElement(LiveDomTourialContainer, null);
    }
    if (isTourialErrored) {
        return React.createElement(Four04Page, null);
    }
    return React.createElement(IsLoading, null);
};
const LiveDomTourialContainer = () => {
    return (React.createElement("div", null,
        React.createElement(DomTourLiveViewport, null)));
};
const TourialContainer = styled.div `
  margin: auto;
`;
const Embed = styled.div `
  position: relative;
  width: 100%;
  padding: 0px;
  padding-bottom: ${({ heightPercentage }) => heightPercentage}%;
  overflow: hidden;
`;
