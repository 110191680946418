import React from "react";
import styled from "styled-components";
import MobileCircleIcon from "../../../assets/images/v3/mobile-circle-icon.svg";
import NoIntegrationIcon from "../../../assets/images/v3/no-integration-circle-icon.svg";
import HubspotIcon from "../../../assets/images/v3/hubspot-circle-icon.svg";
import MarketoIcon from "../../../assets/images/v3/marketo-circle-icon.svg";
import PardotIcon from "../../../assets/images/v3/pardot-circle-icon.svg";
import SalesforceIcon from "../../../assets/images/v3/salesforce-circle-icon.svg";
import TourPlayerIcon from "../../../assets/images/v3/tour-player-circle-icon.svg";
import TTooltip from "../styled/t-tooltip";
export function TourialIcons({ hasMobileVariant, isInMicrosite, isInDemoCenter, formIntegrations, isHomePage, }) {
    return (React.createElement(React.Fragment, null,
        isInMicrosite && (React.createElement(TTooltip, { content: "Featured in TourPlayer(s)", placement: "bottom" },
            React.createElement(IconAlignmentContainer, null,
                React.createElement(CircleIconContainer, null,
                    React.createElement("img", { src: TourPlayerIcon }))))),
        isInDemoCenter && (React.createElement(TTooltip, { content: "Featured in DemoCenter(s)", placement: "bottom" },
            React.createElement(IconAlignmentContainer, null,
                React.createElement(CircleIconContainer, null,
                    React.createElement("img", { src: TourPlayerIcon }))))),
        hasMobileVariant && (React.createElement(TTooltip, { content: "Mobile version enabled", placement: "bottom", secondaryContent: isHomePage ? "" : "Filter by mobile to see performance" },
            React.createElement(IconAlignmentContainer, null,
                React.createElement(CircleIconContainer, null,
                    React.createElement("img", { src: MobileCircleIcon }))))),
        formIntegrations && !Object.keys(formIntegrations).length && (React.createElement(TTooltip, { content: "No form connected", placement: "bottom", secondaryContent: "Use an integrated form to track conversions" },
            React.createElement(IconAlignmentContainer, null,
                React.createElement(CircleIconContainer, null,
                    React.createElement("img", { src: NoIntegrationIcon }))))),
        (formIntegrations === null || formIntegrations === void 0 ? void 0 : formIntegrations.HUBSPOT) && (React.createElement(TTooltip, { content: "Hubspot form connected", placement: "bottom" },
            React.createElement(IconAlignmentContainer, null,
                React.createElement(CircleIconContainer, null,
                    React.createElement("img", { src: HubspotIcon }))))),
        (formIntegrations === null || formIntegrations === void 0 ? void 0 : formIntegrations.MARKETO) && (React.createElement(TTooltip, { content: "Marketo form connected", placement: "bottom" },
            React.createElement(IconAlignmentContainer, null,
                React.createElement(CircleIconContainer, null,
                    React.createElement("img", { src: MarketoIcon }))))),
        (formIntegrations === null || formIntegrations === void 0 ? void 0 : formIntegrations.PARDOT) && (React.createElement(TTooltip, { content: "Pardot form connected", placement: "bottom" },
            React.createElement(IconAlignmentContainer, null,
                React.createElement(CircleIconContainer, null,
                    React.createElement("img", { src: PardotIcon }))))),
        (formIntegrations === null || formIntegrations === void 0 ? void 0 : formIntegrations.SALESFORCE) && (React.createElement(TTooltip, { content: "Salesforce form connected", placement: "bottom" },
            React.createElement(IconAlignmentContainer, null,
                React.createElement(CircleIconContainer, null,
                    React.createElement("img", { src: SalesforceIcon })))))));
}
const IconAlignmentContainer = styled.span `
  position: relative;
  top: 2px;
`;
const CircleIconContainer = styled.div `
  width: 20px;
  height: 20px;
  background: #ffffff;
  box-shadow:
    2px 2px 10px rgba(48, 49, 51, 0.05),
    inset 0px 0px 2px rgba(48, 49, 51, 0.5);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0px;
`;
