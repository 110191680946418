import { useDispatch, useSelector } from "react-redux";
import { TourialApiCalls } from "../../helpers/tourial-api-calls";
import { ActionType } from "../actions/redux-actions";
import { DCMode, } from "../../../../shared/types/demo-center";
import { getInitialTourialRootState } from "../reducers/tourial-root-reducer";
import { HTActionType } from "../actions/ht-actions";
import { getInitialDomTourialRootState } from "../reducers/dom-tour-reducer";
import { DemoCenterDraftContext } from "../../components/pages/demo-center/builder/dc-builder";
import { useContext } from "react";
import { TourialStatus } from "../../../../shared/types/tourial-model";
export const useDemoCenter = () => {
    const mode = useSelector(state => state.demoCenterReducer.mode);
    const demoCenter = useSelector(state => state.demoCenterReducer.demoCenter);
    const savedDemoCenter = useSavedDemoCenter();
    return mode === DCMode.LIVE ? savedDemoCenter : demoCenter;
};
export const useSavedDemoCenter = () => useSelector(state => state.demoCenterReducer.savedDemoCenter);
export const useSetDemoCenters = () => {
    const dispatch = useDispatch();
    return (draftDemoCenter, savedDemoCenter) => {
        dispatch({ type: ActionType.SET_DEMOCENTERS, draftDemoCenter, savedDemoCenter });
    };
};
export const useUpdateDemoCenterRedux = () => {
    const dispatch = useDispatch();
    return (demoCenter) => {
        if (demoCenter)
            dispatch({ type: ActionType.UPDATE_DEMOCENTER, demoCenter });
    };
};
export const useUpdateDemoCenter = () => {
    const draft = useContext(DemoCenterDraftContext);
    const updateDemoCenterRedux = useUpdateDemoCenterRedux();
    return (demoCenter) => {
        // Note: Firebase updates Redux asynchronously after draft.update completes.
        // To prevent UX glitches, we want to update Redux immediately.
        // This keeps a fast UX while the Firebase update is in progress.
        updateDemoCenterRedux(demoCenter);
        void draft.update(demoCenter);
    };
};
export const useSetSavedDemoCenter = () => {
    const dispatch = useDispatch();
    return (demoCenter) => {
        dispatch({ type: ActionType.SET_SAVED_DEMOCENTER, demoCenter });
    };
};
export const useSetShareLink = () => {
    const dispatch = useDispatch();
    return (shareLink) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_SHARE_LINK, shareLink });
    };
};
export const useClearDemoCenter = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch({ type: ActionType.SET_DEMOCENTER_DEFAULT_STATE });
    };
};
export const useDCSetTourialRootState = () => {
    const dispatch = useDispatch();
    return (tourialId, tourialViewportMode) => {
        return TourialApiCalls.Tourials.getTourial(tourialId)
            .then(({ tourial, account }) => {
            const initialProviderState = getInitialTourialRootState(tourial, tourialViewportMode, account);
            return dispatch({
                type: ActionType.SET_INITIAL_TOURIAL_ROOT_STATE,
                initialProviderState,
            });
        })
            .catch((e) => {
            console.log(`Error getting tour data in useDCSetTourialRootState action`, e);
            throw e;
        });
    };
};
export const useDCSetDomTourialRootState = () => {
    const dispatch = useDispatch();
    return (tourialId, tourialViewportMode) => {
        return TourialApiCalls.DomTours.getPublishedDomTourById(tourialId)
            .then(({ tour: tourial, account }) => {
            const initialProviderState = getInitialDomTourialRootState(tourial, tourialViewportMode, account);
            return dispatch({
                type: HTActionType.HT_SET_INITIAL_TOURIAL_ROOT_STATE,
                initialProviderState,
            });
        })
            .catch((e) => {
            console.log(`Error getting tour data in useDCSetDomTourialRootState action`, e);
            throw e;
        });
    };
};
export const useDCSetSelectedTags = () => {
    const dispatch = useDispatch();
    return (selectedTags) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_SELECTED_TAGS, selectedTags });
    };
};
export const useDCSelectedTags = () => useSelector(state => state.demoCenterReducer.selectedTags);
export const useDCSetTrackSelections = () => {
    const dispatch = useDispatch();
    return (trackSelections, forwardPath) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_TRACK_SELECTIONS, trackSelections, forwardPath });
    };
};
export const useDCTrackSelections = () => useSelector(state => state.demoCenterReducer.trackSelections);
export const useDCSetMode = () => {
    const dispatch = useDispatch();
    return (mode) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_MODE, mode });
    };
};
export const useDCMode = () => useSelector(state => state.demoCenterReducer.mode);
export const useDCSetActiveItemId = () => {
    const dispatch = useDispatch();
    return (id) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_ACTIVE_ITEM_ID, id });
    };
};
export const useDCActiveItemId = () => useSelector(state => state.demoCenterReducer.activeItemId);
export const useDCActiveItemHasBeenCompleted = () => useSelector(state => state.demoCenterReducer.activeItemHasBeenCompleted);
export const useDCSetActiveItemHasBeenCompleted = () => {
    const dispatch = useDispatch();
    return (hasBeenCompleted) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_ACTIVE_ITEM_HAS_BEEN_COMPLETED, hasBeenCompleted });
    };
};
export const useDCActiveItemSubNavigation = () => useSelector(state => state.demoCenterReducer.activeItemSubNavigation);
export const useDCSetActiveItemSubNavigation = () => {
    const dispatch = useDispatch();
    return (activeItemSubNavigation) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_ACTIVE_ITEM_SUB_NAVIGATION, activeItemSubNavigation });
    };
};
export const useDCSetActiveModuleSlug = () => {
    const dispatch = useDispatch();
    return (slug) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_ACTIVE_MODULE_SLUG, slug });
    };
};
export const useDCActiveModuleSlug = () => useSelector(state => state.demoCenterReducer.activeModuleSlug);
export const useDCSetViewedItems = () => {
    const dispatch = useDispatch();
    return (items) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_VIEWED_ITEMS, items });
    };
};
export const useDCViewedItems = () => useSelector(state => state.demoCenterReducer.viewedItems);
export const useDCGetForwardPath = () => useSelector(state => state.demoCenterReducer.forwardPath);
export const useDCClearFowardPath = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch({ type: ActionType.SET_DEMOCENTER_FORWARD_PATH, forwardPath: null });
    };
};
export const useDCShouldShowWelcomeScreen = () => useSelector(state => state.demoCenterReducer.shouldShowWelcomeScreen);
export const useDCSetShouldShowWelcomeScreen = () => {
    const dispatch = useDispatch();
    return (shouldShowWelcomeScreen) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_SHOW_WELCOME_SCREEN, shouldShowWelcomeScreen });
    };
};
export const useDCShouldShowUpfrontQuestions = () => useSelector(state => state.demoCenterReducer.shouldShowUpfrontQuestions);
export const useDCSetShouldShowUpfrontQuestions = () => {
    const dispatch = useDispatch();
    return (shouldShowUpfrontQuestions) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_SHOW_UPFRONT_QUESTIONS, shouldShowUpfrontQuestions });
    };
};
export const useDCSidebarIsOpen = () => useSelector(state => state.demoCenterReducer.sidebarIsOpen);
export const useDCSetSidebarIsOpen = () => {
    const dispatch = useDispatch();
    return (sidebarIsOpen) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_SIDEBAR_IS_OPEN, sidebarIsOpen });
    };
};
export const useSetDCSessionId = () => {
    const dispatch = useDispatch();
    return (democenterSessionId) => dispatch({
        type: ActionType.SET_DEMOCENTER_SESSION_ID,
        democenterSessionId,
    });
};
export const useDCSessionId = () => useSelector(state => state.demoCenterReducer.democenterSessionId);
export const useDCBuilderMode = () => useSelector(state => state.demoCenterReducer.builderMode);
export const useDCSetBuilderMode = () => {
    const dispatch = useDispatch();
    return (builderMode) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_BUILDER_MODE, builderMode });
    };
};
export const useDCBuilderWarnings = () => useSelector(state => state.demoCenterReducer.builderWarnings);
export const useDCSetBuilderWarnings = () => {
    const dispatch = useDispatch();
    return (builderWarnings) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_BUILDER_WARNINGS, builderWarnings });
    };
};
export const useDCBuilderIsCollapsed = () => useSelector(state => state.demoCenterReducer.builderIsCollapsed);
export const useDCSetBuilderIsCollapsed = () => {
    const dispatch = useDispatch();
    return (isCollapsed) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_BUILDER_IS_COLLAPSED, isCollapsed });
    };
};
export const useDCLivePreviewPath = () => useSelector(state => state.demoCenterReducer.livePreviewPath);
export const useDCSetLivePreviewPath = () => {
    const dispatch = useDispatch();
    return (path) => {
        dispatch({ type: ActionType.SET_DEMOCENTER_LIVE_PREVIEW_PATH, path });
    };
};
export const useDCConfigGatingFormID = () => {
    var _a;
    const demoCenter = useDemoCenter();
    return (_a = demoCenter === null || demoCenter === void 0 ? void 0 : demoCenter.config) === null || _a === void 0 ? void 0 : _a.gatingFormId;
};
export const useDCUpfrontQuestionsIsEnabled = () => useSelector(state => { var _a, _b; return ((_b = (_a = state.demoCenterReducer.savedDemoCenter.config) === null || _a === void 0 ? void 0 : _a.upfrontQuestions) === null || _b === void 0 ? void 0 : _b.isEnabled) || false; });
export const useDCUpfrontQuestions = () => {
    var _a;
    const demoCenter = useDemoCenter();
    return (_a = demoCenter === null || demoCenter === void 0 ? void 0 : demoCenter.config) === null || _a === void 0 ? void 0 : _a.upfrontQuestions;
};
export const useDCIsPublished = () => useSelector(state => state.demoCenterReducer.savedDemoCenter.status === TourialStatus.LIVE);
