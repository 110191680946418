export var NavModal;
(function (NavModal) {
    NavModal[NavModal["NULL"] = 0] = "NULL";
    NavModal[NavModal["SHARE"] = 1] = "SHARE";
    NavModal[NavModal["EMBED"] = 2] = "EMBED";
    NavModal[NavModal["METADATA"] = 3] = "METADATA";
    NavModal[NavModal["ACTIONS"] = 4] = "ACTIONS";
    NavModal[NavModal["SET_LIVE"] = 5] = "SET_LIVE";
    NavModal[NavModal["UPDATE_LIVE"] = 6] = "UPDATE_LIVE";
    NavModal[NavModal["REVERT_DRAFT"] = 7] = "REVERT_DRAFT";
})(NavModal || (NavModal = {}));
export var MicrositeEditorFocusFields;
(function (MicrositeEditorFocusFields) {
    MicrositeEditorFocusFields["CTA_BUTTON_1"] = "microsite-header-cta-button-1";
    MicrositeEditorFocusFields["CTA_BUTTON_2"] = "microsite-header-cta-button-2";
    MicrositeEditorFocusFields["HEADER"] = "microsite-header";
    MicrositeEditorFocusFields["LOGO"] = "microsite-header-logo";
    MicrositeEditorFocusFields["PROGRESS_BAR"] = "microsite-progress-bar";
    MicrositeEditorFocusFields["BACKGROUND"] = "microsite-background";
    MicrositeEditorFocusFields["ACTIVE_CARD_STYLE"] = "microsite-active-card-style";
    MicrositeEditorFocusFields["CARD_INDICATOR"] = "microsite-card-indicator";
})(MicrositeEditorFocusFields || (MicrositeEditorFocusFields = {}));
export var TourialEditorFocusFields;
(function (TourialEditorFocusFields) {
    TourialEditorFocusFields["BUBBLE_HEADLINE_TEXT"] = "bubble-headline-accordion";
    TourialEditorFocusFields["BUBBLE_BODY_TEXT"] = "bubble-body-accordion";
    TourialEditorFocusFields["BUBBLE_NEXT_BUTTON_TEXT"] = "bubble-next-button-accordion";
    TourialEditorFocusFields["BUBBLE_PREVIOUS_BUTTON_TEXT"] = "bubble-previous-button-accordion";
    TourialEditorFocusFields["GLOBAL_BUTTON_1"] = "button1-is-enabled-accordion";
    TourialEditorFocusFields["GLOBAL_BUTTON_2"] = "button2-is-enabled-accordion";
    TourialEditorFocusFields["GLOBAL_BUTTON_3"] = "button3-is-enabled-accordion";
    TourialEditorFocusFields["GLOBAL_BUTTON_4"] = "button4-is-enabled-accordion";
    TourialEditorFocusFields["MODAL_HEADLINE_TEXT"] = "headline-accordion";
    TourialEditorFocusFields["MODAL_BODY_TEXT"] = "background-shape-accordion";
    TourialEditorFocusFields["MODAL_BUTTON_1"] = "button1-accordion";
    TourialEditorFocusFields["MODAL_BUTTON_2"] = "button2-accordion";
    TourialEditorFocusFields["MODAL_BUTTON_3"] = "button3-accordion";
    TourialEditorFocusFields["MODAL_BUTTON_4"] = "button4-accordion";
    TourialEditorFocusFields["AUTO_TYPEWRITER_TEXT"] = "auto-typewriter-text";
    TourialEditorFocusFields["HT_OVERLAY_BODY_TEXT"] = "ht-overlay-body-text";
    TourialEditorFocusFields["HT_OVERLAY_HEADLINE_TEXT"] = "ht-overlay-headline-text";
    TourialEditorFocusFields["HT_OVERLAY_BUTTON_1_TEXT"] = "ht-overlay-button1-text";
    TourialEditorFocusFields["HT_OVERLAY_BUTTON_2_TEXT"] = "ht-overlay-button2-text";
})(TourialEditorFocusFields || (TourialEditorFocusFields = {}));
export var ViewportPresets;
(function (ViewportPresets) {
    ViewportPresets[ViewportPresets["LARGE"] = 1080] = "LARGE";
    ViewportPresets[ViewportPresets["MEDIUM"] = 978] = "MEDIUM";
    ViewportPresets[ViewportPresets["SMALL"] = 720] = "SMALL";
    ViewportPresets[ViewportPresets["MOBILE"] = 400] = "MOBILE";
})(ViewportPresets || (ViewportPresets = {}));
export var ViewportPresetSize;
(function (ViewportPresetSize) {
    ViewportPresetSize["LARGE"] = "LARGE";
    ViewportPresetSize["MEDIUM"] = "MEDIUM";
    ViewportPresetSize["SMALL"] = "SMALL";
    ViewportPresetSize["MOBILE"] = "MOBILE";
})(ViewportPresetSize || (ViewportPresetSize = {}));
export var ViewportMode;
(function (ViewportMode) {
    ViewportMode["EDIT"] = "EDIT";
    ViewportMode["PREVIEW"] = "PREVIEW";
    ViewportMode["LIVE"] = "LIVE";
})(ViewportMode || (ViewportMode = {}));
export var MediaType;
(function (MediaType) {
    MediaType["IMG"] = "img";
    MediaType["VIDEO"] = "video";
})(MediaType || (MediaType = {}));
export const toastWidth = 400;
export const autoCloseInMs = 3000;
export const PAGE_HEADER_Z_INDEX = 9999;
export const PAGE_HEADER_HEIGHT = 58;
export var ToastModes;
(function (ToastModes) {
    ToastModes["INFO"] = "INFO";
    ToastModes["SUCCESS"] = "SUCCESS";
    ToastModes["WARNING"] = "WARNING";
    ToastModes["ERROR"] = "ERROR";
})(ToastModes || (ToastModes = {}));
