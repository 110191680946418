import styled from "styled-components";
import { FormWrapper } from "./styled-main-view";
import { FormLayout } from "../../../../../../../shared/types/form";
import { BorderShape, ShirtSize, TextStyle } from "../../../../../../../shared/types/tool-data-types/common";
import { bodyFontSizes, headlineFontSizes, textAlignments } from "../../../../../helpers/text-helpers";
export const ConfirmationWrapper = styled(FormWrapper) `
  height: 302px;
`;
export const InnerWrapper = styled.div `
  display: flex;
  padding-top: 24px;
  flex-direction: ${p => (p.layout === FormLayout.HORIZONTAL ? "row" : "column")};
`;
export const LogoContainer = styled.div `
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: inherit;
  margin-bottom: ${p => (p.layout === FormLayout.VERTICAL ? 24 : 0)};
`;
export const LogoSizes = {
    [ShirtSize.SMALL]: "60px",
    [ShirtSize.MEDIUM]: "80px",
    [ShirtSize.LARGE]: "100px",
};
export const Logo = styled.div `
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${p => LogoSizes[p.size]};
  height: ${p => LogoSizes[p.size]};
  overflow: hidden;
  img {
    max-height: ${p => LogoSizes[p.size]};
    max-width: ${p => LogoSizes[p.size]};
  }
  border-radius: ${p => (p.shape === BorderShape.SQUIRCLE ? "100px" : p.shape === BorderShape.ROUNDED ? "8px" : "0")};
`;
export const Headline = styled.div `
  width: 100%;
  font-size: ${p => headlineFontSizes[p.size]}px;
  line-height: ${p => headlineFontSizes[p.size] * 1.5}px;
  margin-top: 16px;
  text-align: ${p => textAlignments[p.align]};
  font-family: ${p => p.font};
  text-decoration: ${p => (p.textStyle === TextStyle.UNDERLINE ? "underline" : "none")};
  font-weight: ${p => (p.textStyle === TextStyle.BOLD ? "bold" : "none")};
  font-style: ${p => (p.textStyle === TextStyle.ITALIC ? "italic" : "none")};
  color: ${p => p.color};
`;
export const Description = styled.div `
  width: 100%;
  margin-top: 16px;
  font-size: ${p => bodyFontSizes[p.size]}px;
  line-height: ${p => bodyFontSizes[p.size] * 1.5}px;
  text-align: ${p => textAlignments[p.align]};
  font-family: ${p => p.font};
  text-decoration: ${p => (p.textStyle === TextStyle.UNDERLINE ? "underline" : "none")};
  font-weight: ${p => (p.textStyle === TextStyle.BOLD ? "bold" : "none")};
  font-style: ${p => (p.textStyle === TextStyle.ITALIC ? "italic" : "none")};
  color: ${p => p.color};
`;
