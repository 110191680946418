import React, { useState } from "react";
import TButton, { TButtonVariants } from "../../styled/t-button";
import TInput from "../../styled/t-input";
import TModal, { TModalButtonsContainer } from "../../styled/t-modal";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import { useAsync } from "react-async";
import { TToggleSwitch } from "../../styled/t-toggle-switch";
import { newTheme } from "../../styled/theme";
import styled from "styled-components";
import { TDropdownSelect } from "../../styled/t-dropdown-select";
import { uniqBy } from "lodash";
import { AccessLevel, getReadableAuthNameFor } from "../../../../../shared/types/auth-types";
import RedTrashIcon from "../../../../assets/images/v3/red-trashcan.svg";
import { appendParam } from "../../../../../shared/functions/helpers";
import { useHistory } from "react-router-dom";
export const PUBLIC_MESSAGE = "Everyone in your organization may access this workspace.";
export const PRIVATE_MESSAGE = "Only invited members may access this workspace.";
export default function CreateWorkspaceModal({ setIsCreateWorkspaceModalOpen, }) {
    const history = useHistory();
    const [workspaceName, setWorkspaceName] = useState("");
    const [isPublic, setIsPublic] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]); // temporarily selected items in dropdown list
    const [addedUsers, setAddedUsers] = useState([]); // confirmed items
    const { data } = useAsync({
        promiseFn: TourialApiCalls.Users.getAllAccountUsers,
        onResolve(data) {
            if (data) {
                const defaultUsers = [];
                data.forEach(user => {
                    if (user.userAccess === AccessLevel.ADMIN) {
                        defaultUsers.push({ label: user.email, value: user.email });
                    }
                });
                setAddedUsers(defaultUsers);
            }
        },
    });
    const dropdownOptions = (data === null || data === void 0 ? void 0 : data.map(user => ({
        label: user.email,
        value: user.email,
    }))) || [];
    return (React.createElement(TModal, { onClose: () => setIsCreateWorkspaceModalOpen(false), title: "Create a new workspace" },
        React.createElement(ContentContainer, null,
            React.createElement("div", null,
                React.createElement("div", { style: { marginBottom: 4 } }, "Workspace name"),
                React.createElement(TInput, { style: { width: "100%" }, placeholder: "Workspace name", value: workspaceName, onChange: e => setWorkspaceName(e.target.value) })),
            React.createElement("div", { style: { display: "flex", justifyContent: "space-between", alignItems: "center" } },
                React.createElement(PublicToggleContainer, null,
                    React.createElement("div", null, "Public"),
                    React.createElement(TToggleSwitch, { enabled: isPublic, size: "small", onChange: () => setIsPublic(!isPublic) })),
                React.createElement("div", { style: { color: newTheme.colors.grey600, font: newTheme.fonts.small } }, isPublic ? PUBLIC_MESSAGE : PRIVATE_MESSAGE)),
            !isPublic &&
                UsersSection({ addedUsers, data, setSelectedUsers, selectedUsers, dropdownOptions, setAddedUsers })),
        React.createElement(TModalButtonsContainer, null,
            React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => {
                    setIsCreateWorkspaceModalOpen(false);
                } }, "Cancel"),
            React.createElement(TButton, { disabled: !(workspaceName === null || workspaceName === void 0 ? void 0 : workspaceName.length), onClick: () => {
                    TourialApiCalls.Workspaces.createWorkspace(workspaceName, isPublic ? [] : addedUsers.map(user => user.value), isPublic)
                        .then(() => {
                        setIsCreateWorkspaceModalOpen(false);
                        appendParam(history, "reload-list", true);
                    })
                        .catch(() => {
                        setIsCreateWorkspaceModalOpen(false);
                    });
                } }, "Create"))));
}
export const UsersSection = ({ addedUsers, data, setSelectedUsers, selectedUsers, dropdownOptions, setAddedUsers }) => {
    return (React.createElement(UsersContainer, null,
        addedUsers.map(user => {
            var _a;
            const role = (_a = data === null || data === void 0 ? void 0 : data.find(userData => userData.email === user.value)) === null || _a === void 0 ? void 0 : _a.userAccess;
            const isRemovable = [AccessLevel.EDITOR, AccessLevel.VIEWER, AccessLevel.COLLABORATOR].includes(role);
            return (React.createElement("div", { key: user.value, style: { display: "flex", gap: 16, alignItems: "center", position: "relative" } },
                isRemovable && (React.createElement("img", { draggable: "false", src: RedTrashIcon, onClick: () => {
                        setAddedUsers(addedUsers.filter(listUser => user.value !== listUser.value));
                    }, style: { cursor: "pointer", position: "absolute", left: "calc(100% - 26px)", width: 18 } })),
                React.createElement("div", { style: { font: newTheme.fonts.smallItalic, color: newTheme.colors.grey500 } }, getReadableAuthNameFor(role)),
                React.createElement("div", null, user.value)));
        }),
        React.createElement("div", { style: { display: "flex", gap: 16, alignItems: "center" } },
            React.createElement(TDropdownSelect, { isMulti: true, isSearchable: true, onChange: (selection) => {
                    const selectedUsers = [];
                    selection.forEach(selection => {
                        if (selection.value)
                            selectedUsers.push(selection);
                    });
                    setSelectedUsers(selectedUsers);
                }, selectedValue: selectedUsers, style: { margin: "0", width: "100%" }, options: [{ label: "-- Select team members --", value: "" }, ...dropdownOptions], placeholder: "-- Select team members --" }),
            " ",
            React.createElement(TButton, { size: "small", variant: TButtonVariants.OUTLINE, onClick: () => {
                    setSelectedUsers([]);
                    setAddedUsers(uniqBy([...addedUsers, ...selectedUsers], "value"));
                } }, "Add"))));
};
export const ContentContainer = styled.div `
  font: ${newTheme.fonts.normal};
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;
export const PublicToggleContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 11px;
`;
export const UsersContainer = styled.div `
  background-color: ${newTheme.colors.grey100};
  padding: 12px;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;
