var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// do not import dependencies that the server or client don't both have installed
import { cloneDeep, isEqual, memoize, uniq } from "lodash";
import { getButtonTemplate } from "../legacy/components/builder/tools/button/template";
import { getGroupTemplate } from "../legacy/components/builder/tools/group/template";
import { getHotspotTemplate } from "../legacy/components/builder/tools/hotspot/template";
import { getShapeTemplate } from "../legacy/components/builder/tools/shape/template";
import { getClickZoneTemplate } from "../legacy/components/builder/tools/clickzone/template";
import { getTextboxTemplate } from "../legacy/components/builder/tools/textbox/template";
import { getSpotlightTemplate } from "../legacy/components/builder/tools/spotlight/template";
import { getVideoTemplate } from "../legacy/components/builder/tools/video/template";
import { getImageTemplate } from "../legacy/components/builder/tools/image/template";
import { v4 as uuidv4 } from "uuid";
import { getEmbedTemplate } from "../legacy/components/builder/tools/embed/template";
import { TriggerDispatchEvent, } from "../../../shared/legacy/legacy-tool-data";
import { objectToArray } from "../../../shared/functions/helpers";
import { ActiveVariantName, ToolType } from "../../../shared/types/tool-data-types/common";
import { genericEmails } from "../../../shared/functions/forms";
import { TourialApiCalls } from "./tourial-api-calls";
const getActiveVariantName = (state) => {
    var _a;
    return (_a = state.viewport.activeVariantName) !== null && _a !== void 0 ? _a : ActiveVariantName.VARIANT_DESKTOP;
};
export function getUniqueToolCopy(tool, assignCopyName = false) {
    // this needs to be filled out for all non-true-global tool types and probably refactored with how we're getting the templates for the toolpanel
    if (!tool)
        return;
    function getToolCopyData(tool) {
        const toolData = Object.assign({}, tool);
        if (assignCopyName)
            toolData.name = `${toolData.name} copy`;
        delete toolData.id;
        delete toolData.groupToolId;
        return toolData;
    }
    switch (tool.type) {
        case ToolType.Button: {
            return getButtonTemplate({ toolData: getToolCopyData(tool) });
        }
        case ToolType.Hotspot: {
            return getHotspotTemplate({ toolData: getToolCopyData(tool) });
        }
        case ToolType.Shape: {
            return getShapeTemplate({ toolData: getToolCopyData(tool) });
        }
        case ToolType.ClickZone: {
            return getClickZoneTemplate({ toolData: getToolCopyData(tool) });
        }
        case ToolType.Textbox: {
            return getTextboxTemplate({ toolData: getToolCopyData(tool) });
        }
        case ToolType.Group: {
            return getGroupTemplate({ toolData: getToolCopyData(tool) });
        }
        case ToolType.Spotlight: {
            return getSpotlightTemplate({ toolData: getToolCopyData(tool) });
        }
        case ToolType.Image: {
            return getImageTemplate({ toolData: getToolCopyData(tool) });
        }
        case ToolType.Video: {
            return getVideoTemplate({ toolData: getToolCopyData(tool) });
        }
        case ToolType.Tooltip: {
            const toolData = cloneDeep(Object.assign(Object.assign({}, tool), { id: uuidv4() }));
            if (assignCopyName)
                toolData.name = `${toolData.name} copy`;
            delete toolData.groupToolId;
            return toolData;
        }
        case ToolType.FormInputs: {
            const toolData = cloneDeep(Object.assign(Object.assign({}, tool), { id: uuidv4() }));
            if (assignCopyName)
                toolData.name = `${toolData.name} copy`;
            delete toolData.groupToolId;
            return toolData;
        }
        case ToolType.Embed: {
            return getEmbedTemplate({ toolData: getToolCopyData(tool) });
        }
        default:
            console.error("Attempted to clone unsupported ToolType", tool.type);
    }
}
export function deepCloneAllPageTools(fromPageId, toPageId, oldViewTools, oldTools) {
    const getReplacementUUID = memoize(_oldUUID => (_oldUUID ? uuidv4() : null));
    const newTools = {};
    const oldToNewToolIds = new Map();
    const relevantOldViewTools = uniq(oldViewTools.map(vt => {
        if (vt.toolId && vt.pageId === fromPageId) {
            return vt;
        }
    })).filter(vt => vt);
    const newViewTools = relevantOldViewTools.map(vt => {
        var _a, _b;
        const isDefaultGlobalTool = oldTools[vt.toolId].type === ToolType.FormV2 || oldTools[vt.toolId].type === ToolType.Breadcrumbs;
        if ((_a = oldTools[vt.toolId]) === null || _a === void 0 ? void 0 : _a.id) {
            // do not create a new copy of FormV2 or breadcrumbs for page copies
            if (!isDefaultGlobalTool) {
                newTools[getReplacementUUID(vt.toolId)] = cloneDeep(Object.assign(Object.assign({}, oldTools[vt.toolId]), { id: getReplacementUUID(vt.toolId), groupToolId: getReplacementUUID((_b = oldTools[vt.toolId]) === null || _b === void 0 ? void 0 : _b.groupToolId) }));
            }
            // default global tools don't have trigger listeners, so this is fine
            if (!oldToNewToolIds.has(vt.toolId)) {
                oldToNewToolIds.set(vt.toolId, getReplacementUUID(vt.toolId));
            }
        }
        return Object.assign(Object.assign({}, vt), { toolId: !isDefaultGlobalTool ? getReplacementUUID(vt.toolId) : vt.toolId, pageId: toPageId });
    });
    const tools = Object.assign(Object.assign(Object.assign({}, oldTools), newTools), { screen: Object.assign(Object.assign({}, oldTools.screen), { media: Object.assign(Object.assign({}, oldTools.screen.media), { [toPageId]: oldTools.screen.media[fromPageId] }) }) });
    const oldMedia = oldTools.screen.media[fromPageId];
    if (oldMedia) {
        tools.screen.media[toPageId] = oldMedia;
    }
    return {
        tools,
        viewTools: [...oldViewTools, ...newViewTools],
        oldToNewToolIds,
    };
}
export function deepCloneAllImportedPageTools(fromPageId, toPage, fromViewTools, fromTools, oldViewTools, oldTools, oldPages) {
    const getReplacementUUID = memoize(_oldUUID => (_oldUUID ? uuidv4() : null));
    const newTools = {};
    const fromToNewToolIds = new Map();
    const breadcrumbs = objectToArray(oldTools).find(t => {
        var _a;
        return ((_a = t === null || t === void 0 ? void 0 : t.value) === null || _a === void 0 ? void 0 : _a.type) === ToolType.Breadcrumbs;
    });
    const oldToolsHasBreadcrumbs = !!(breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.value);
    // filter for only relevant viewtools being imported
    const relevantFromViewTools = uniq(fromViewTools.map(vt => {
        if (vt.toolId && vt.pageId === fromPageId) {
            return vt;
        }
    })).filter(vt => vt);
    const newGlobalTools = [];
    const newViewTools = relevantFromViewTools
        .map(vt => {
        var _a, _b;
        const vtIsBreadcrumbs = fromTools[vt.toolId].type === ToolType.Breadcrumbs;
        const vtIsFormV2 = fromTools[vt.toolId].type === ToolType.FormV2;
        const newId = vtIsFormV2 ? vt.toolId : vtIsBreadcrumbs ? "BREADCRUMBS" : getReplacementUUID(vt.toolId);
        // handle new tool and viewtool creation below
        if ((_a = fromTools[vt.toolId]) === null || _a === void 0 ? void 0 : _a.id) {
            // only create breadcrumbs tool if it doesn't already exist, and only once
            if (vtIsBreadcrumbs && !oldToolsHasBreadcrumbs && !newTools.BREADCRUMBS) {
                newTools.BREADCRUMBS = cloneDeep(Object.assign(Object.assign({}, fromTools[vt.toolId]), { id: "BREADCRUMBS" }));
                newGlobalTools.push(newTools.BREADCRUMBS);
            }
            // only create the FormV2 tool once
            // maintain the tool id to check if it has already been imported
            else if (vtIsFormV2 && !newTools[vt.toolId] && !oldTools[vt.toolId]) {
                newTools[vt.toolId] = cloneDeep(fromTools[vt.toolId]);
                newGlobalTools.push(newTools[vt.toolId]);
            }
            // otherwise if it's neither global tools, create a new tool
            else if (!vtIsBreadcrumbs && !vtIsFormV2 && !newTools[getReplacementUUID(vt.toolId)]) {
                newTools[getReplacementUUID(vt.toolId)] = cloneDeep(Object.assign(Object.assign({}, fromTools[vt.toolId]), { id: getReplacementUUID(vt.toolId), groupToolId: getReplacementUUID((_b = fromTools[vt.toolId]) === null || _b === void 0 ? void 0 : _b.groupToolId) }));
            }
            // default global tools don't have trigger listeners, so this is fine
            if (!fromToNewToolIds.has(vt.toolId))
                fromToNewToolIds.set(vt.toolId, newId);
        }
        // skip viewtool creation for breadcrumbs and forms that already exist
        if (oldToolsHasBreadcrumbs && vtIsBreadcrumbs)
            return;
        if (vtIsFormV2 && oldTools[vt.toolId])
            return;
        return Object.assign(Object.assign({}, vt), { toolId: newId, pageId: toPage.id });
    })
        .filter(Boolean);
    // backfill all old views with newly imported global tools
    for (const p of oldPages) {
        for (const z of p.zooms) {
            newGlobalTools.forEach(gt => {
                const visibleOnLoad = gt.type !== ToolType.FormV2;
                newViewTools.push({ zoomId: z.id, pageId: p.id, visibleOnLoad, toolId: gt.id });
            });
        }
    }
    // apply old global tools to all new views
    objectToArray(oldTools).forEach(({ key, value }) => {
        if ((value === null || value === void 0 ? void 0 : value.type) === ToolType.FormV2) {
            for (const z of toPage.zooms) {
                newViewTools.push({ zoomId: z.id, pageId: toPage.id, visibleOnLoad: false, toolId: key });
            }
        }
    });
    if (oldToolsHasBreadcrumbs) {
        for (const z of toPage.zooms) {
            newViewTools.push({
                zoomId: z.id,
                pageId: toPage.id,
                visibleOnLoad: true,
                toolId: "BREADCRUMBS",
            });
        }
    }
    const tools = Object.assign(Object.assign(Object.assign({}, oldTools), newTools), { screen: Object.assign(Object.assign({}, oldTools.screen), { media: Object.assign(Object.assign({}, oldTools.screen.media), { [toPage.id]: fromTools.screen.media[fromPageId] }) }) });
    return {
        tools,
        viewTools: [...oldViewTools, ...newViewTools],
        fromToNewToolIds,
    };
}
export function getDuplicatePageTriggerListeners(oldToNewToolIds, oldTriggerListeners, newPageId, isImportedPage) {
    const duplicatePageTriggerListeners = [];
    if (!oldToNewToolIds.size || !oldTriggerListeners.length)
        return duplicatePageTriggerListeners;
    oldTriggerListeners.forEach(oldTriggerListener => {
        const newBaseTriggerListener = Object.assign(Object.assign({}, oldTriggerListener), { listenerId: uuidv4(), toolId: oldToNewToolIds.get(oldTriggerListener.toolId) });
        if (oldToNewToolIds.has(oldTriggerListener.toolId)) {
            switch (oldTriggerListener.dispatchEvent) {
                case TriggerDispatchEvent.HIDE_TOOL:
                case TriggerDispatchEvent.TOGGLE_TOOL:
                case TriggerDispatchEvent.DISPLAY_TOOL: {
                    duplicatePageTriggerListeners.push(Object.assign(Object.assign({}, newBaseTriggerListener), { displayToolId: oldToNewToolIds.get(oldTriggerListener.displayToolId) }));
                    break;
                }
                case TriggerDispatchEvent.SUBMIT_FORM: {
                    duplicatePageTriggerListeners.push(Object.assign(Object.assign({}, newBaseTriggerListener), { formId: oldToNewToolIds.get(oldTriggerListener.formId) }));
                    break;
                }
                case TriggerDispatchEvent.CHANGE_ZOOM: {
                    duplicatePageTriggerListeners.push(Object.assign(Object.assign({}, newBaseTriggerListener), { view: Object.assign(Object.assign({}, oldTriggerListener.view), { pageId: newPageId }) }));
                    break;
                }
                // exclude 'jump to page' for imported pages
                case TriggerDispatchEvent.CHANGE_PAGE: {
                    if (isImportedPage)
                        break;
                    duplicatePageTriggerListeners.push(cloneDeep(newBaseTriggerListener));
                    break;
                }
                default:
                    duplicatePageTriggerListeners.push(cloneDeep(newBaseTriggerListener));
            }
        }
    });
    return duplicatePageTriggerListeners;
}
// currently only applies to nextpage and jumppage actions on paste tool
export function getCopyToolTriggerListeners(oldTriggerListeners, newToolIds, correspondingOldToolIds) {
    const newTriggerListeners = cloneDeep(oldTriggerListeners);
    if (!(oldTriggerListeners === null || oldTriggerListeners === void 0 ? void 0 : oldTriggerListeners.length) || !(newToolIds === null || newToolIds === void 0 ? void 0 : newToolIds.length) || !(correspondingOldToolIds === null || correspondingOldToolIds === void 0 ? void 0 : correspondingOldToolIds.length))
        return newTriggerListeners;
    newToolIds.forEach((newToolId, i) => {
        const correspondingOldToolId = correspondingOldToolIds[i];
        oldTriggerListeners.forEach(oldTriggerListener => {
            if (oldTriggerListener.toolId === correspondingOldToolId) {
                if (oldTriggerListener.dispatchEvent === TriggerDispatchEvent.NEXT_PAGE ||
                    oldTriggerListener.dispatchEvent === TriggerDispatchEvent.NEXT_ZOOM ||
                    oldTriggerListener.dispatchEvent === TriggerDispatchEvent.PREVIOUS_ZOOM) {
                    newTriggerListeners.push({
                        toolId: newToolId,
                        listenerId: uuidv4(),
                        triggeredByAction: oldTriggerListener.triggeredByAction,
                        dispatchEvent: oldTriggerListener.dispatchEvent,
                    });
                }
                if (oldTriggerListener.dispatchEvent === TriggerDispatchEvent.CHANGE_PAGE) {
                    newTriggerListeners.push({
                        toolId: newToolId,
                        listenerId: uuidv4(),
                        triggeredByAction: oldTriggerListener.triggeredByAction,
                        dispatchEvent: oldTriggerListener.dispatchEvent,
                        view: {
                            pageId: oldTriggerListener.view.pageId,
                            zoomId: "MAIN",
                        },
                    });
                }
            }
        });
    });
    return newTriggerListeners;
}
export function getBorderStylesFromProps(toolViewProps) {
    const { enableBorder, borderRadius, borderWidth, borderColor, borderStyle } = toolViewProps.toolData;
    return {
        // borderRadius and borderWidth multiplied by viewportDimensions.width
        // so they remain visually consistent at various viewport widths
        borderRadius: `${borderRadius * (toolViewProps.viewportDimensions.width / 1080)}px`,
        borderWidth: enableBorder ? `${borderWidth * (toolViewProps.viewportDimensions.width / 1080)}px` : null,
        borderColor: enableBorder ? borderColor : null,
        borderStyle: enableBorder ? borderStyle : null,
    };
}
export function getBorderStyles(enableBorder, borderRadius, borderWidth, borderColor, borderStyle, viewportDimensions) {
    return {
        // borderRadius and borderWidth multiplied by viewportDimensions.width
        // so they remain visually consistent at various viewport widths
        borderRadius: `${borderRadius * (viewportDimensions.width / 1080)}px`,
        borderWidth: enableBorder ? `${borderWidth * (viewportDimensions.width / 1080)}px` : null,
        borderColor: enableBorder ? borderColor : null,
        borderStyle: enableBorder ? borderStyle : null,
    };
}
// TODO move to shared functions
export function validateEmail(email, nongeneric = false) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validBaseEmail = re.test(email.toLowerCase());
    if (!nongeneric || !validBaseEmail) {
        return validBaseEmail;
    }
    else {
        const domain = email.toLowerCase().split("@")[1];
        return !genericEmails.includes(domain);
    }
}
export function validatePhone(phone) {
    // supports international phone numbers
    // if no country code is provided, then it is assumed to be +1 (US)
    const international = /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;
    const usCountryCode = "+1";
    if (phone.startsWith(usCountryCode)) {
        phone = phone.slice(usCountryCode.length).trim();
    }
    if (!phone.startsWith("+")) {
        const usaNumbers = /^\d{10}$/;
        const usaDashes = /^\d{3}-\d{3}-\d{4}$/;
        const usaParens = /^\(\d{3}\)\d{3}-\d{4}$/;
        return usaNumbers.test(phone) || usaDashes.test(phone) || usaParens.test(phone);
    }
    else {
        return international.test(phone);
    }
}
export function rgbaToHex(r, g, b, a) {
    let rr = r.toString(16);
    let gg = g.toString(16);
    let bb = b.toString(16);
    let aa = Math.round(a * 255).toString(16);
    if (rr.length == 1)
        rr = "0" + rr;
    if (gg.length == 1)
        gg = "0" + gg;
    if (bb.length == 1)
        bb = "0" + bb;
    if (aa.length == 1)
        aa = "0" + aa;
    return "#" + rr + gg + bb + aa;
}
export function hexToRGB(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null;
}
export function cloneSwap(arr, i1, i2) {
    const newArr = cloneDeep(arr);
    const tmp = newArr[i1];
    newArr[i1] = newArr[i2];
    newArr[i2] = tmp;
    return newArr;
}
export function swapInPlace(arr, i1, i2) {
    const tmp = arr[i1];
    arr[i1] = arr[i2];
    arr[i2] = tmp;
}
export function adjustPxValuesForThumbnail(toolData) {
    const tool = Object.assign({}, toolData);
    // @ts-expect-error duck-typing is actually useful here
    if (tool.borderRadius) {
        // @ts-expect-error
        tool.borderRadius *= 0.3;
    }
    // @ts-expect-error
    if (tool.borderWidth) {
        // @ts-expect-error
        tool.borderWidth *= 0.3;
    }
    return tool;
}
export function rebuildTools(tools, viewTools) {
    const newTools = {
        background: tools.background,
        screen: tools.screen,
    };
    viewTools.forEach(vt => {
        const viewToolId = vt.toolId;
        if (tools[viewToolId])
            newTools[viewToolId] = tools[viewToolId];
    });
    return newTools;
}
export function rebuildHiddenTools(hiddenTools, tools) {
    const nextHiddenTools = {};
    if (!hiddenTools)
        return nextHiddenTools;
    for (const toolId of Object.keys(hiddenTools)) {
        if (tools[toolId]) {
            nextHiddenTools[toolId] = hiddenTools[toolId];
        }
    }
    return nextHiddenTools;
}
export function rebuildTimers(timers, tools) {
    if (!timers)
        return [];
    const rebuiltTimers = [];
    for (const timer of timers) {
        if (objectToArray(tools).find((o) => { var _a; return ((_a = o === null || o === void 0 ? void 0 : o.value) === null || _a === void 0 ? void 0 : _a.id) === timer.toolId; })) {
            rebuiltTimers.push(timer);
        }
    }
    return rebuiltTimers;
}
export function getToolsWithReplacedIds(tools) {
    const getReplacementUUID = memoize(_oldUUID => (_oldUUID ? uuidv4() : null));
    return tools.map(tool => (Object.assign(Object.assign({}, tool), { id: getReplacementUUID(tool.id), groupToolId: tool.groupToolId && getReplacementUUID(tool.groupToolId), name: tool.groupToolId ? tool.name : `${tool.name} copy` })));
}
export function deleteViewTool(prevState, viewTool) {
    const activeVariantName = getActiveVariantName(prevState);
    let viewTools = prevState.tourial[activeVariantName].viewTools.filter(vt => !isEqual(vt, viewTool));
    const { type } = prevState.tourial[activeVariantName].tools[viewTool.toolId];
    if (type === ToolType.Breadcrumbs || type === ToolType.FormV2) {
        // global delete
        viewTools = prevState.tourial[activeVariantName].viewTools.filter(vt => vt.toolId !== viewTool.toolId);
    }
    else if (type === ToolType.Group) {
        viewTools = prevState.tourial[activeVariantName].viewTools
            .filter(vt => !isEqual(vt, viewTool))
            .filter(vt => {
            var _a;
            if (vt.pageId === viewTool.pageId) {
                return ((_a = prevState.tourial[activeVariantName].tools[vt.toolId]) === null || _a === void 0 ? void 0 : _a.groupToolId) !== viewTool.toolId;
            }
            return true;
        });
    }
    // Rebuild new tools object with only remaining viewTools
    const tools = rebuildTools(prevState.tourial[activeVariantName].tools, viewTools);
    const timers = rebuildTimers(prevState.tourial[activeVariantName].timers, tools);
    return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { tools,
                viewTools,
                timers }) }) });
}
export function arrayInsert(arr, element, index) {
    return [...arr.slice(0, index), element, ...arr.slice(index)];
}
export function arrayInsertMulti(arr, elements, index) {
    return [...arr.slice(0, index), ...elements, ...arr.slice(index)];
}
export function arrayDeleteAt(arr, index) {
    if (index === arr.length - 1)
        return [...arr.slice(0, index)];
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
}
export function getGroupAnimationDetails(tools, groupToolId) {
    const groupedTools = objectToArray(tools).filter((t) => t.value.groupToolId === groupToolId);
    for (const gt of groupedTools) {
        // we currently only support inheriting the tooltip animation
        if (gt.value.type === ToolType.Tooltip) {
            const tooltipData = gt.value;
            return {
                animation: tooltipData.animation,
                animationDuration: tooltipData.animationDuration,
            };
        }
    }
    return { animation: null, animationDuration: null };
}
export function getNewTooltipPosition(toolData, position, viewportWidth, viewportHeight) {
    let newTooltipPosition;
    if (toolData.tooltipDirection === "bottom" || toolData.tooltipDirection === "top") {
        const oldX = (toolData.sizeAndPosition.x * viewportWidth) / 100;
        const transitionArrow = (toolData.tooltipPosition * viewportWidth) / 100;
        const oldArrowPos = oldX + transitionArrow;
        newTooltipPosition = ((oldArrowPos - position.x) / viewportWidth) * 100;
    }
    else if (toolData.tooltipDirection === "left" || toolData.tooltipDirection === "right") {
        const oldY = (toolData.sizeAndPosition.y * viewportHeight) / 100;
        const transitionArrow = (toolData.tooltipPosition * viewportHeight) / 100;
        const oldArrowPos = oldY + transitionArrow;
        newTooltipPosition = ((oldArrowPos - position.y) / viewportHeight) * 100;
    }
    return newTooltipPosition;
}
export function calculateContainedToolIdsAndBorders(viewToolsAndTools, viewportDimensions, multiselectToolIds) {
    let containedTools = [];
    const containedToolIds = [];
    const finalContainerBorders = {
        top: Infinity,
        bottom: -Infinity,
        left: Infinity,
        right: -Infinity,
    };
    const { y: vpTop, x: vpLeft, width: vpWidth, height: vpHeight } = viewportDimensions;
    // click/drag select logic to determine contained tools
    if (!multiselectToolIds) {
        const { top: boxTop, left: boxLeft, bottom: boxBottom, right: boxRight, } = document.getElementById("drag-select-box").getBoundingClientRect();
        viewToolsAndTools.forEach(({ tool }) => {
            if ((tool === null || tool === void 0 ? void 0 : tool.groupToolId) || (tool === null || tool === void 0 ? void 0 : tool.type) === ToolType.Spotlight || (tool === null || tool === void 0 ? void 0 : tool.type) === ToolType.FormV2)
                return;
            const { x, y, width, height } = tool.sizeAndPosition;
            const top = (y * vpHeight) / 100 + vpTop;
            const bottom = (height * vpHeight) / 100 + top;
            const left = (x * vpWidth) / 100 + vpLeft;
            const right = (width * vpWidth) / 100 + left;
            // detect intersections
            const leftContained = left < boxRight && right > boxLeft;
            const rightContained = right > boxLeft && left < boxRight;
            const topContained = top < boxBottom && bottom > boxTop;
            const bottomContained = bottom > boxTop && top < boxBottom;
            const verticallyContained = topContained || bottomContained;
            const horizontallyContained = rightContained || leftContained;
            if (verticallyContained && horizontallyContained) {
                containedTools.push(tool);
            }
        });
    }
    // shift additions/subtractions provide multiselectToolIds, no need to detect contained
    else {
        containedTools = multiselectToolIds
            .map(id => { var _a; return (_a = viewToolsAndTools.find(({ tool }) => tool.id === id)) === null || _a === void 0 ? void 0 : _a.tool; })
            .filter(Boolean);
    }
    containedTools.forEach((tool) => {
        if (tool === null || tool === void 0 ? void 0 : tool.groupToolId)
            return;
        containedToolIds.push(tool.id);
        const { x, y, width, height } = tool.sizeAndPosition;
        const top = (y * vpHeight) / 100 + vpTop;
        const bottom = (height * vpHeight) / 100 + top;
        const left = (x * vpWidth) / 100 + vpLeft;
        const right = (width * vpWidth) / 100 + left;
        finalContainerBorders.top = Math.min(top, finalContainerBorders.top);
        finalContainerBorders.bottom = Math.max(bottom, finalContainerBorders.bottom);
        finalContainerBorders.left = Math.min(left, finalContainerBorders.left);
        finalContainerBorders.right = Math.max(right, finalContainerBorders.right);
    });
    return [containedToolIds, containedTools.length ? finalContainerBorders : null];
}
export function alphabetizeArrayByKey(data, key) {
    return [...data].sort((a, b) => ((a === null || a === void 0 ? void 0 : a[key]) || "").localeCompare((b === null || b === void 0 ? void 0 : b[key]) || ""));
}
export function deepCloneAllViewTools(zoomId, newZoomId, pageId, oldViewTools, oldTools) {
    const getReplacementUUID = memoize(_oldUUID => (_oldUUID ? uuidv4() : null));
    const newTools = {};
    const oldToNewToolIds = new Map();
    const relevantOldViewTools = uniq(oldViewTools.map(vt => {
        if (vt.toolId && vt.pageId === pageId && vt.zoomId === zoomId) {
            return vt;
        }
    })).filter(vt => vt);
    const newViewTools = relevantOldViewTools.map(vt => {
        var _a, _b;
        const vtIsBreadcrumbs = oldTools[vt.toolId].type === ToolType.Breadcrumbs;
        const vtIsFormV2 = oldTools[vt.toolId].type === ToolType.FormV2;
        const newId = vtIsFormV2 ? vt.toolId : vtIsBreadcrumbs ? "BREADCRUMBS" : getReplacementUUID(vt.toolId);
        if (((_a = oldTools[vt.toolId]) === null || _a === void 0 ? void 0 : _a.id) && !vtIsFormV2 && !vtIsBreadcrumbs) {
            newTools[newId] = cloneDeep(Object.assign(Object.assign({}, oldTools[vt.toolId]), { id: newId, groupToolId: getReplacementUUID((_b = oldTools[vt.toolId]) === null || _b === void 0 ? void 0 : _b.groupToolId) }));
            // default global tools don't have trigger listeners, so this is fine
            if (!oldToNewToolIds.has(vt.toolId)) {
                oldToNewToolIds.set(vt.toolId, newId);
            }
        }
        return Object.assign(Object.assign({}, vt), { toolId: newId, pageId, zoomId: newZoomId });
    });
    const tools = Object.assign(Object.assign({}, oldTools), newTools);
    return {
        tools,
        viewTools: [...oldViewTools, ...newViewTools],
        oldToNewToolIds,
    };
}
/**
 * checks for existence of a nested property,
 * pass keys in order after obj
 * @param obj
 * @param args
 */
export function checkNested(obj, ...args) {
    for (const arg of args) {
        if (!obj.hasOwnProperty(arg)) {
            return false;
        }
        obj = obj[arg];
    }
    return true;
}
export function getAllV2FormIds(tools) {
    return objectToArray(tools)
        .filter(t => t.value.type === ToolType.FormV2)
        .map(v => v.value);
}
export var PublishedStatus;
(function (PublishedStatus) {
    PublishedStatus["CURRENT"] = "CURRENT";
    PublishedStatus["BEHIND"] = "BEHIND";
    PublishedStatus["NOT_LIVE"] = "NOT_LIVE";
})(PublishedStatus || (PublishedStatus = {}));
export function getPublishedStatus(isPublished, hasChangesToSave) {
    if (isPublished) {
        if (hasChangesToSave) {
            return PublishedStatus.BEHIND;
        }
        else {
            return PublishedStatus.CURRENT;
        }
    }
    return PublishedStatus.NOT_LIVE;
}
export function getBaseUrlWithSubdomain(account) {
    return (account === null || account === void 0 ? void 0 : account.whiteLabelDomain)
        ? `https://${account.whiteLabelDomain}/`
        : location.href.includes("localhost")
            ? `http://${account.subdomain}.localhost:5050/`
            : `https://${account.subdomain}.tourial.com/`;
}
export const reactColorRgbaToString = ({ r, g, b, a }) => `rgba(${r}, ${g}, ${b}, ${a})`;
export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
export function isLocalOrStagingUrl() {
    return (location.host.startsWith("localhost") ||
        location.host.startsWith("staging") ||
        location.host.startsWith("tsandbox-pr-"));
}
export function getIsSafari() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isSafari = userAgent.includes("safari/") && !userAgent.includes("chrome/");
    return isSafari;
}
export function modifyVideoFormatForSafari(src) {
    try {
        const isSafari = getIsSafari();
        const splitSrc = src.split(".");
        const format = splitSrc.pop();
        if (isSafari && format === "webm")
            return (splitSrc === null || splitSrc === void 0 ? void 0 : splitSrc.join(".")) + ".mp4";
        return src;
    }
    catch (_a) {
        return src;
    }
}
export const getCurrentUser = () => __awaiter(void 0, void 0, void 0, function* () {
    const currentUser = yield TourialApiCalls.Users.getTourialAuthData();
    return getBaseUrlWithSubdomain(currentUser.account);
});
