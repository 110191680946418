import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { getBorderStyles } from "../../../../../../helpers/misc";
import { ViewportMode } from "../../../../../../types/definitions";
import { useUpdateTool, useViewportMode } from "../../../../../../redux/selectors/redux-selectors";
import { extractTextStyle } from "../../../../tool-views/form-v2-view";
import { isFieldValid } from "../../../../tool-views/form-v2-view";
export function Inputs({ toolData, viewportDimensions, }) {
    const { height } = viewportDimensions;
    const { fields, inputSizing } = toolData;
    return (React.createElement("div", { style: {
            display: "grid",
            gridTemplateColumns: getGridTemplateColumns(toolData.columns),
            columnGap: `${(inputSizing.horizontalSpacing / 100) * height}px`,
            rowGap: `${(inputSizing.verticalSpacing / 100) * height}px`,
        } }, fields.map((f, i) => {
        return React.createElement(Field, { key: i, i: i, toolData: toolData, viewportDimensions: viewportDimensions, fieldType: f.type });
    })));
}
function getGridTemplateColumns(numColumns) {
    return numColumns === 1 ? "1fr" : "1fr 1fr";
}
function Field({ i, toolData, viewportDimensions, fieldType, }) {
    const [wasInteracted, setWasInteracted] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const viewportMode = useViewportMode();
    const updateTool = useUpdateTool();
    const { bodyText } = toolData.text;
    const f = toolData.fields[i];
    const validStyle = Object.assign(Object.assign(Object.assign(Object.assign({}, extractTextStyle(bodyText)), { fontSize: `${(viewportDimensions.height * bodyText.fontSize) / 100}px`, width: "100%", height: `${(toolData.inputSizing.height / 100) * viewportDimensions.height}px`, pointerEvents: viewportMode === ViewportMode.EDIT ? "none" : "inherit", paddingLeft: 4 }), getBorderStyles(true, toolData.theme.inputsTheme.borderRadius, toolData.theme.inputsTheme.borderWidth, toolData.theme.inputsTheme.borderColor, "solid", viewportDimensions)), { fontFamily: bodyText.fontFamily, fontWeight: bodyText.fontWeight, color: bodyText.color, backgroundColor: toolData.theme.inputsTheme.backgroundColor });
    if (toolData.columns === 2 && i === toolData.fields.length - 1 && toolData.fields.length % 2 !== 0) {
        validStyle.gridColumn = "1 / 3";
    }
    const invalidStyle = Object.assign(Object.assign({}, validStyle), { borderColor: "red", borderWidth: 2 });
    let style = validStyle;
    if (wasInteracted && !isValid) {
        style = invalidStyle;
    }
    if (fieldType === "Phone") {
        return (React.createElement("input", { type: "tel", pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}", value: f.value, placeholder: f.role, autoComplete: "off", onBlur: () => {
                if (!wasInteracted)
                    setWasInteracted(true);
            }, onChange: e => {
                if (e.target.value.length && isNaN(Number(e.target.value[e.target.value.length - 1])))
                    return;
                if (e.target.value.length > 10)
                    return;
                const newTool = cloneDeep(toolData);
                newTool.fields[i] = {
                    type: f.type,
                    role: f.role,
                    value: e.target.value,
                };
                updateTool(newTool);
                // console.log("isFieldValid(f)", isFieldValid(f));
                setIsValid(isFieldValid(newTool.fields[i]));
            }, style: style }));
    }
    else if (fieldType === "Dropdown") {
        return (React.createElement("select", { style: Object.assign(Object.assign({}, style), (f.value === "" && { padding: 0, color: "rgb(117,117,117)" })), value: f.value, onBlur: () => {
                if (!wasInteracted)
                    setWasInteracted(true);
            }, onChange: e => {
                const newTool = cloneDeep(toolData);
                newTool.fields[i] = Object.assign(Object.assign({}, newTool.fields[i]), { value: e.target.value });
                updateTool(newTool);
                setIsValid(isFieldValid(newTool.fields[i]));
                if (!wasInteracted)
                    setWasInteracted(true);
            } },
            React.createElement("option", { hidden: true, value: "" }, f.role),
            f.options.map((o, i) => (React.createElement("option", { key: i, value: o }, o)))));
    }
    return (React.createElement("input", { type: "text", key: i, placeholder: f.role, value: f.value, autoComplete: "off", onChange: e => {
            const newTool = cloneDeep(toolData);
            newTool.fields[i] = {
                type: f.type,
                role: f.role,
                value: e.target.value,
            };
            updateTool(newTool);
            setIsValid(isFieldValid(newTool.fields[i]));
        }, onBlur: () => {
            if (!wasInteracted)
                setWasInteracted(true);
        }, style: style }));
}
