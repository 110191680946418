import RedTrashIcon from "../../../../../assets/images/v3/red-trashcan.svg";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import { useDeleteTool, useDeselect } from "../../../../redux/selectors/builder-selectors";
import { usePushHistory, useUpdateTool } from "../../../../redux/selectors/redux-selectors";
import { DynamicWidthInput } from "../navbar/dynamic-width-input";
import { cloneDeep } from "lodash";
export function EditorTitlebar({ title, toolId, tool }) {
    const deleteTool = useDeleteTool();
    const deselect = useDeselect();
    const pushHistory = usePushHistory();
    const updateTool = useUpdateTool();
    const [localToolName, setLocalToolName] = useState(title);
    useEffect(() => {
        setLocalToolName(title);
    }, [title]);
    return (React.createElement(EditorHeader, null,
        React.createElement(H2, { className: "text-ellipsis", toolId: toolId }, tool ? (React.createElement(DynamicWidthInput, { id: "_test_editor-tool-name-input", name: "tool-name-input", value: localToolName, onChange: (e) => {
                setLocalToolName(e.target.value);
            }, onBlur: () => {
                pushHistory();
                setLocalToolName(localToolName.trim());
                const t = cloneDeep(tool);
                t.name = localToolName;
                updateTool(t);
            }, style: {
                height: 24,
                color: newTheme.colors.black,
                font: newTheme.fonts.normal,
                textAlign: "left",
                minWidth: 50,
            } })) : (title)),
        toolId && (React.createElement("img", { draggable: "false", src: RedTrashIcon, alt: "delete editor tool", onClick: () => {
                pushHistory();
                deleteTool(toolId);
                deselect();
            }, style: { cursor: "pointer" } }))));
}
const EditorHeader = styled.span `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
`;
const H2 = styled.h2 `
  font: ${newTheme.fonts.normal};
  width: ${props => (props.toolId ? 260 : 287)}px;
`;
