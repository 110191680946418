import React from "react";
import ReactQuill, { Quill } from "react-quill";
import { newTheme } from "../components/styled/theme";
const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");
class PlainClipboard extends Clipboard {
    onPaste(e) {
        e.preventDefault();
        const range = this.quill.getSelection();
        const text = e.clipboardData.getData("text/plain");
        const delta = new Delta().retain(range.index).delete(range.length).insert(text);
        const index = text.length + range.index;
        const length = 0;
        this.quill.updateContents(delta, "silent");
        this.quill.setSelection(index, length, "silent");
        this.quill.scrollIntoView();
    }
}
Quill.register("modules/clipboard", PlainClipboard, true);
export const RichTextEditor = React.forwardRef(({ id, value, onChange }, ref) => {
    return (React.createElement(ReactQuill, { key: id + "quill-editor", theme: "snow", value: value, onChange: onChange, ref: ref, style: {
            backgroundColor: newTheme.colors.white,
        }, modules: {
            toolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
        } }));
});
