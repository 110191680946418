import React from "react";
import { BodyText } from "../subcomponents/body-text";
import { HeaderText } from "../subcomponents/header-text";
import { Inputs } from "../subcomponents/inputs";
import { Logo } from "../subcomponents/logo";
import { SubmitButton } from "../subcomponents/submit-button";
export function Layout3({ toolData, viewportDimensions, runAfterSubmissionFn }) {
    return (React.createElement("div", { style: {
            padding: `${toolData.isDismissable ? "0%" : "2%"} 7% 0% 7%`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            height: "100%",
        } },
        React.createElement("div", { style: {
                display: "flex",
                flexDirection: "column",
                maxHeight: "20%",
                height: "fit-content",
            } },
            React.createElement(HeaderText, { toolData: toolData, viewportDimensions: viewportDimensions }),
            React.createElement(BodyText, { toolData: toolData, viewportDimensions: viewportDimensions })),
        React.createElement("div", { className: "hide-scrollbar", style: {
                margin: "2% 0%",
                overflowY: "scroll",
            } },
            React.createElement(Inputs, { toolData: toolData, viewportDimensions: viewportDimensions })),
        React.createElement("div", { style: {
                display: "flex",
                justifyContent: "space-between",
                flexFlow: "row nowrap",
                maxHeight: "20%",
                height: "fit-content",
                width: "100%",
            } },
            React.createElement("div", { style: {
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "top",
                    maxWidth: "80%",
                    width: "fit-content",
                    height: "100%",
                    minHeight: "100%",
                    paddingBottom: "2%",
                } },
                React.createElement(SubmitButton, { toolData: toolData, viewportDimensions: viewportDimensions, customStyle: { width: "100%" }, runAfterSubmissionFn: runAfterSubmissionFn })),
            toolData.logo.isVisible && (React.createElement("div", { style: {
                    marginBottom: "-1%",
                } },
                React.createElement(Logo, { toolData: toolData, viewportDimensions: viewportDimensions }))))));
}
