import { useActivePageId, useActiveZoomId, usePrefetchAndSetCloudinaryUrls } from "../redux/selectors/redux-selectors";
import { useEffect, useState } from "react";
import { ViewportMode } from "../types/definitions";
export function usePrefetchCloudinaryUrls() {
    const pageId = useActivePageId();
    const zoomId = useActiveZoomId();
    const prefetchAndSetCloudinaryUrls = usePrefetchAndSetCloudinaryUrls(ViewportMode.LIVE);
    const [prefetchedCloudinaryUrls, setPrefetchedCloudinaryUrls] = useState({});
    const [isInit, setIsInit] = useState(true);
    useEffect(() => {
        prefetchAndSetCloudinaryUrls({ pageId, zoomId }, prefetchedCloudinaryUrls, setPrefetchedCloudinaryUrls, isInit);
        if (isInit)
            setIsInit(false);
    }, [pageId, zoomId]);
}
