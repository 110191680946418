export var Direction;
(function (Direction) {
    Direction["UP"] = "UP";
    Direction["DOWN"] = "DOWN";
    Direction["LEFT"] = "LEFT";
    Direction["RIGHT"] = "RIGHT";
})(Direction || (Direction = {}));
export var BorderShape;
(function (BorderShape) {
    BorderShape["RECTANGLE"] = "RECTANGLE";
    BorderShape["ROUNDED"] = "ROUNDED";
    BorderShape["SQUIRCLE"] = "SQUIRCLE";
})(BorderShape || (BorderShape = {}));
export var ShirtSize;
(function (ShirtSize) {
    ShirtSize["XXSMALL"] = "XXSMALL";
    ShirtSize["XSMALL"] = "XSMALL";
    ShirtSize["SMALL"] = "SMALL";
    ShirtSize["MEDIUM"] = "MEDIUM";
    ShirtSize["LARGE"] = "LARGE";
    ShirtSize["XLARGE"] = "XLARGE";
    ShirtSize["XXLARGE"] = "XXLARGE";
})(ShirtSize || (ShirtSize = {}));
export var TextStyle;
(function (TextStyle) {
    TextStyle["BOLD"] = "BOLD";
    TextStyle["ITALIC"] = "ITALIC";
    TextStyle["UNDERLINE"] = "UNDERLINE";
    TextStyle["NORMAL"] = "NORMAL";
})(TextStyle || (TextStyle = {}));
export var TextAlign;
(function (TextAlign) {
    TextAlign["LEFT"] = "LEFT";
    TextAlign["CENTER"] = "CENTER";
    TextAlign["RIGHT"] = "RIGHT";
})(TextAlign || (TextAlign = {}));
export var GraphicType;
(function (GraphicType) {
    GraphicType["IMAGE"] = "IMAGE";
    GraphicType["VIDEO"] = "VIDEO";
})(GraphicType || (GraphicType = {}));
export var ActiveVariantName;
(function (ActiveVariantName) {
    ActiveVariantName["VARIANT_DESKTOP"] = "variantDesktop";
    ActiveVariantName["VARIANT_MOBILE"] = "variantMobile";
})(ActiveVariantName || (ActiveVariantName = {}));
export var ToolType;
(function (ToolType) {
    ToolType["Button"] = "BUTTON";
    ToolType["Hotspot"] = "HOTSPOT";
    ToolType["Screen"] = "SCREEN";
    ToolType["Textbox"] = "TEXTBOX";
    ToolType["Shape"] = "SHAPE";
    ToolType["Group"] = "GROUP";
    ToolType["Zoom"] = "ZOOM";
    ToolType["Background"] = "BACKGROUND";
    ToolType["Spotlight"] = "SPOTLIGHT";
    ToolType["Image"] = "IMAGE";
    ToolType["Video"] = "VIDEO";
    ToolType["Breadcrumbs"] = "BREADCRUMBS";
    ToolType["ClickZone"] = "CLICKZONE";
    ToolType["FormInputs"] = "FORMINPUTS";
    ToolType["Form"] = "FORM";
    ToolType["FormV2"] = "FORMV2";
    ToolType["Sidebar"] = "SIDEBAR";
    ToolType["Embed"] = "EMBED";
    ToolType["Tooltip"] = "TOOLTIP";
    ToolType["TooltipV2"] = "TOOLTIPV2";
    ToolType["FormV3"] = "FORMV3";
    ToolType["Modal"] = "MODAL";
    ToolType["GlobalButtons"] = "GLOBALBUTTONS";
    ToolType["Sandbox"] = "SANDBOX";
    ToolType["AutoMouse"] = "AUTOMOUSE";
    ToolType["AutoTypewriter"] = "AUTOTYPEWRITER";
    ToolType["AutoTooltip"] = "AUTOTOOLTIP";
})(ToolType || (ToolType = {}));
