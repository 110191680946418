import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDCIsItemInUse, useDCItems, useDCRemoveItem } from "../../hooks/dc-item-hooks";
import { DCIconType, DCItemType } from "../../../../../../../shared/types/demo-center";
import { TourialListContainer } from "../../../microsites/modals/add-tourial-modal";
import { newTheme } from "../../../../styled/theme";
import TInput from "../../../../styled/t-input";
import { TCheckbox } from "../../../../styled/t-checkbox";
import { dcGetReadableItemType, dcItemIsUploadedToTourial } from "../../helpers/dc-helpers";
import { DCIcon } from "../../components/dc-icon";
import TTooltip from "../../../../styled/t-tooltip";
import { DCConfirmDeleteModal } from "./dc-confirm-delete-modal";
export const DCCMAddContentItemsTab = ({ currentItems, setSelectedItems }) => {
    const [filter, setFilter] = useState("");
    const [itemList, setItemList] = useState([]);
    const items = useDCItems();
    const exists = (id) => {
        return !!currentItems.find(i => i === id);
    };
    const handleClick = (item, checked) => {
        const newItemList = [...itemList];
        newItemList.forEach(i => {
            if (i.id === item.id) {
                i.checked = checked;
            }
        });
        setItemList(newItemList);
    };
    useEffect(() => {
        setSelectedItems(itemList.filter(i => i.checked));
    }, [itemList]);
    useEffect(() => {
        const itemList = [];
        items
            .filter(i => i.type !== DCItemType.TOUR && i.type !== DCItemType.DOM_TOUR)
            .forEach(i => {
            itemList.push(Object.assign(Object.assign({}, i), { checked: exists(i.id) }));
        });
        setItemList(itemList);
    }, [items]);
    return (React.createElement(TourialListContainer, null,
        React.createElement(FilterContainer, null,
            React.createElement(TInput, { placeholder: "Search content", value: filter, onChange: e => setFilter(e.target.value), autoFocus: true })),
        itemList
            .filter(t => t.title.toLowerCase().includes(filter.toLowerCase()))
            .map(i => (React.createElement(ImportedItem, { key: i.id, item: i, onClick: handleClick, exists: exists(i.id) })))));
};
const ImportedItem = ({ item, onClick, exists }) => {
    const { id, checked, title, thumbnail, type, url } = item;
    const [deleteModal, setDeleteModal] = useState(null);
    const isItemInUse = useDCIsItemInUse();
    const removeItem = useDCRemoveItem();
    const titleStyle = { textDecoration: exists && !checked ? "line-through" : "inherit" };
    const info = dcItemIsUploadedToTourial(item) ? "Uploaded to Tourial" : url;
    const canDelete = !isItemInUse(id);
    const handleDelete = (e) => {
        e.stopPropagation();
        if (canDelete) {
            setDeleteModal({
                title: "Remove Content",
                message: `Are you sure you want to remove '${title}'?`,
                close: () => setDeleteModal(null),
                confirm: () => {
                    removeItem(item.id);
                    setDeleteModal(null);
                },
                confirmText: "Remove",
            });
        }
    };
    return (React.createElement(ItemContainer, { onClick: () => {
            onClick(item, !checked);
        } },
        React.createElement(Thumbnail, { src: thumbnail }),
        React.createElement(ItemInfo, null,
            React.createElement(ItemTitle, { style: titleStyle }, title),
            React.createElement(ItemType, null, `${dcGetReadableItemType(type)} (${info})`)),
        React.createElement(TTooltip, { content: "Content used in demo center may not be deleted.", disabled: canDelete, placement: "left" },
            React.createElement(DeleteIcon, { onClick: handleDelete, className: !canDelete ? "disabled" : "" },
                React.createElement(DCIcon, { type: DCIconType.TRASH }))),
        React.createElement(TCheckbox, { style: { marginLeft: "8px" }, checked: checked, onChange: e => {
                onClick(item, e);
            } }),
        deleteModal && React.createElement(DCConfirmDeleteModal, Object.assign({}, deleteModal))));
};
const ItemContainer = styled.div `
  display: flex;
  align-items: center;
  padding: 12px 16px;
  &:hover {
    background-color: ${newTheme.colors.grey200};
    cursor: pointer;
  }
`;
const Thumbnail = styled.img `
  width: 54px;
  height: 30px;
  object-fit: cover;
  padding-right: 8px;
`;
const ItemInfo = styled.div `
  display: flex;
  flex-direction: column;
  width: 410px;
  flex: 1;
`;
const ItemTitle = styled.div `
  padding-left: 8px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.black};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;
const ItemType = styled.div `
  padding-left: 8px;
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey500};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const DeleteIcon = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${newTheme.colors.grey800};
  &:hover:not(.disabled) {
    color: ${newTheme.colors.red500};
  }
  &.disabled {
    opacity: 0.2;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;
const FilterContainer = styled.div `
  display: flex;
  flex: 1;
  padding: 12px 12px;
  input {
    width: 100%;
  }
`;
