import { ActionType } from "../actions/redux-actions";
export function liveHelperReducer(prevState = {}, action) {
    switch (action.type) {
        case ActionType.SET_HUBSPOT_SCHEDULER: {
            return { hubspotScheduler: action.hubspotScheduler };
        }
        default:
            return prevState;
    }
}
