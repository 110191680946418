import React from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import ModalTextIcon from "../../../../../../assets/images/v3/modal-icon-text.svg";
import ModalImageIcon from "../../../../../../assets/images/v3/modal-icon-image.svg";
import ModalVideoIcon from "../../../../../../assets/images/v3/modal-icon-video.svg";
import ModalPersonasIcon from "../../../../../../assets/images/v3/modal-icon-personas.svg";
import { ModalType } from "../../../../../../../shared/types/tool-data-types/modal-data";
const icons = {
    [ModalType.TEXT]: ModalTextIcon,
    [ModalType.IMAGE]: ModalImageIcon,
    [ModalType.VIDEO]: ModalVideoIcon,
    [ModalType.PERSONAS]: ModalPersonasIcon,
};
export const ModalPanelIcon = (props) => {
    const { type } = props;
    return (React.createElement(ModalPanelIconContainer, { draggable: "true" },
        React.createElement("img", { src: icons[type], draggable: false })));
};
const ModalPanelIconContainer = styled.div `
  background-color: ${newTheme.colors.white};
  border: 1px solid ${newTheme.colors.grey400};
  width: 64px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & img {
    width: 64px;
    height: 48px;
  }
  cursor: pointer;
  &:hover {
    border: 1px solid ${newTheme.colors.blue300};
  }
  overflow: hidden;
`;
