var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";
export function downloadZip(tourialName, tourialMedia) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const zip = new JSZip();
            const fetchImage = (url) => __awaiter(this, void 0, void 0, function* () {
                const response = yield axios.get(url, { responseType: "blob" });
                const fileNameFromUrl = url.split("/").pop();
                const blob = new Blob([response.data]);
                zip.file(fileNameFromUrl, blob);
            });
            const fetchPromises = tourialMedia
                .map(m => m.src)
                .filter(Boolean)
                .map(fetchImage);
            yield Promise.all(fetchPromises);
            const blob = yield zip.generateAsync({ type: "blob" });
            saveAs(blob, `${tourialName}.zip`);
        }
        catch (err) {
            console.error("Error generating zip file:", err);
        }
    });
}
export function downloadSingleFile(media) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(media.src, { responseType: "blob" });
            const downloadLink = document.createElement("a");
            const objectUrl = URL.createObjectURL(response.data);
            downloadLink.href = objectUrl;
            downloadLink.download = media.name;
            downloadLink.click();
            URL.revokeObjectURL(objectUrl);
        }
        catch (err) {
            console.error("Error downloading file:", err);
        }
    });
}
