export var TooltipButtonStyle;
(function (TooltipButtonStyle) {
    TooltipButtonStyle["TEXT"] = "TEXT";
    TooltipButtonStyle["TEXT_AND_ARROWS"] = "TEXT_AND_ARROWS";
    TooltipButtonStyle["ARROWS"] = "ARROWS";
})(TooltipButtonStyle || (TooltipButtonStyle = {}));
export var TooltipArrowPosition;
(function (TooltipArrowPosition) {
    TooltipArrowPosition[TooltipArrowPosition["TOP_LEFT"] = 0] = "TOP_LEFT";
    TooltipArrowPosition[TooltipArrowPosition["TOP_CENTER"] = 1] = "TOP_CENTER";
    TooltipArrowPosition[TooltipArrowPosition["TOP_RIGHT"] = 2] = "TOP_RIGHT";
    TooltipArrowPosition[TooltipArrowPosition["RIGHT_TOP"] = 3] = "RIGHT_TOP";
    TooltipArrowPosition[TooltipArrowPosition["RIGHT_CENTER"] = 4] = "RIGHT_CENTER";
    TooltipArrowPosition[TooltipArrowPosition["RIGHT_BOTTOM"] = 5] = "RIGHT_BOTTOM";
    TooltipArrowPosition[TooltipArrowPosition["BOTTOM_RIGHT"] = 6] = "BOTTOM_RIGHT";
    TooltipArrowPosition[TooltipArrowPosition["BOTTOM_CENTER"] = 7] = "BOTTOM_CENTER";
    TooltipArrowPosition[TooltipArrowPosition["BOTTOM_LEFT"] = 8] = "BOTTOM_LEFT";
    TooltipArrowPosition[TooltipArrowPosition["LEFT_BOTTOM"] = 9] = "LEFT_BOTTOM";
    TooltipArrowPosition[TooltipArrowPosition["LEFT_CENTER"] = 10] = "LEFT_CENTER";
    TooltipArrowPosition[TooltipArrowPosition["LEFT_TOP"] = 11] = "LEFT_TOP";
    TooltipArrowPosition[TooltipArrowPosition["NONE"] = 12] = "NONE";
})(TooltipArrowPosition || (TooltipArrowPosition = {}));
export var TooltipTriggerComponent;
(function (TooltipTriggerComponent) {
    TooltipTriggerComponent["HOTSPOT"] = "HOTSPOT";
    TooltipTriggerComponent["CLICKZONE"] = "CLICKZONE";
    TooltipTriggerComponent["NONE"] = "NONE";
})(TooltipTriggerComponent || (TooltipTriggerComponent = {}));
