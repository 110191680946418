export const CURRENT_EVENT_VERSION = 2;
export var TourialEvent;
(function (TourialEvent) {
    TourialEvent["TOURIAL_VIEW"] = "TOURIAL_VIEW";
    TourialEvent["TOURIAL_LOAD"] = "TOURIAL_LOAD";
    TourialEvent["PRODUCT_PAGE_LOAD"] = "PRODUCT_PAGE_LOAD";
    TourialEvent["CLICK"] = "CLICK";
    TourialEvent["HOVER_IN"] = "HOVER_IN";
    TourialEvent["HOVER_OUT"] = "HOVER_OUT";
    TourialEvent["FORM_SUBMIT"] = "FORM_SUBMIT";
    TourialEvent["WATERMARK_OPEN"] = "WATERMARK_OPEN";
    TourialEvent["WATERMARK_CLOSE"] = "WATERMARK_CLOSE";
    TourialEvent["WATERMARK_RESTART"] = "WATERMARK_RESTART";
    TourialEvent["WATERMARK_SHARE"] = "WATERMARK_SHARE";
    TourialEvent["BAR_LEFT_ARROW_CLICKED"] = "BAR_LEFT_ARROW_CLICKED";
    TourialEvent["BAR_RIGHT_ARROW_CLICKED"] = "BAR_RIGHT_ARROW_CLICKED";
    TourialEvent["INTEGRATION_LOADED"] = "INTEGRATION_LOADED";
    TourialEvent["TRIGGER_LISTENER_INTERACTION_TRIGGERED"] = "TRIGGER_LISTENER_INTERACTION_TRIGGERED";
    TourialEvent["SESSION_START"] = "SESSION_START";
    TourialEvent["SESSION_END"] = "SESSION_END";
    TourialEvent["TIMER_ELAPSED"] = "TIMER_ELAPSED";
    TourialEvent["MICROSITE_SESSION_START"] = "MICROSITE_SESSION_START";
    TourialEvent["MICROSITE_SESSION_END"] = "MICROSITE_SESSION_END";
    TourialEvent["MICROSITE_LOAD"] = "MICROSITE_LOAD";
    TourialEvent["MICROSITE_TOUR_CARD_CLICKED"] = "MICROSITE_TOUR_CARD_CLICKED";
    TourialEvent["MICROSITE_NEXT_TOUR_BUTTON_CLICKED"] = "MICROSITE_NEXT_TOUR_BUTTON_CLICKED";
    TourialEvent["MICROSITE_RESTART_TOUR_BUTTON_CLICKED"] = "MICROSITE_RESTART_TOUR_BUTTON_CLICKED";
    TourialEvent["MICROSITE_PROGRESS_BAR_LEFT_ARROW_CLICKED"] = "MICROSITE_PROGRESS_BAR_LEFT_ARROW_CLICKED";
    TourialEvent["MICROSITE_PROGRESS_BAR_RIGHT_ARROW_CLICKED"] = "MICROSITE_PROGRESS_BAR_RIGHT_ARROW_CLICKED";
    TourialEvent["MICROSITE_CTA_CLICK"] = "MICROSITE_CTA_CLICK";
    TourialEvent["MICROSITE_FORM_SUBMIT"] = "MICROSITE_FORM_SUBMIT";
    TourialEvent["DEMOCENTER_FORM_SUBMIT"] = "DEMOCENTER_FORM_SUBMIT";
    TourialEvent["SCHEDULING_PROFILE_PAGE_VIEWED"] = "SCHEDULING_PROFILE_PAGE_VIEWED";
    TourialEvent["SCHEDULING_EVENT_TYPE_VIEWED"] = "SCHEDULING_EVENT_TYPE_VIEWED";
    TourialEvent["SCHEDULING_DATE_AND_TIME_SELECTED"] = "SCHEDULING_DATE_AND_TIME_SELECTED";
    TourialEvent["SCHEDULING_EVENT_SCHEDULED"] = "SCHEDULING_EVENT_SCHEDULED";
    TourialEvent["DEMOCENTER_CONTENT_LOAD"] = "DEMOCENTER_CONTENT_LOAD";
    TourialEvent["DEMOCENTER_CONTENT_PLAY"] = "DEMOCENTER_CONTENT_PLAY";
    TourialEvent["DEMOCENTER_CONTENT_PAUSE"] = "DEMOCENTER_CONTENT_PAUSE";
    TourialEvent["DEMOCENTER_CONTENT_STOP"] = "DEMOCENTER_CONTENT_STOP";
    TourialEvent["DEMOCENTER_CONTENT_PROGRESS"] = "DEMOCENTER_CONTENT_PROGRESS";
    TourialEvent["DEMOCENTER_MODULE_LOAD"] = "DEMOCENTER_MODULE_LOAD";
    TourialEvent["DEMOCENTER_LOAD"] = "DEMOCENTER_LOAD";
    TourialEvent["DEMOCENTER_SESSION_START"] = "DEMOCENTER_SESSION_START";
    TourialEvent["DEMOCENTER_SESSION_END"] = "DEMOCENTER_SESSION_END";
    TourialEvent["DEMOCENTER_BUTTON_CLICK"] = "DEMOCENTER_BUTTON_CLICK";
    TourialEvent["DEMOCENTER_INTENT_CHANGE"] = "DEMOCENTER_INTENT_CHANGE";
    TourialEvent["DEMOCENTER_SCROLL"] = "DEMOCENTER_SCROLL";
    TourialEvent["DEMOCENTER_USER"] = "DEMOCENTER_USER";
    TourialEvent["TOURIAL_USER"] = "TOURIAL_USER";
})(TourialEvent || (TourialEvent = {}));
export const utmParams = [
    "utm_campaign",
    "utm_source",
    "utm_medium",
    "utm_term",
    "utm_content",
    "email",
    "tourialOutbound",
];
export const tourialParams = [
    "isEmbedded",
    "isLightbox",
    "tourId",
    "fullScreen",
    "userId",
    "token",
    "isOgImage",
    "tourialReferring",
    "deviceWidth",
    "parentUrl",
    "viewportHeader",
];
export var IContentType;
(function (IContentType) {
    IContentType["TOUR"] = "TOUR";
    IContentType["HTML_TOUR"] = "HTML_TOUR";
    IContentType["MICROSITE"] = "MICROSITE";
    IContentType["DEMOCENTER"] = "DEMOCENTER";
})(IContentType || (IContentType = {}));
export var IElasticIndexes;
(function (IElasticIndexes) {
    //TODO: Move other indexs here and replace all references with pointers to here
    IElasticIndexes["DEMOCENTER_SUMMARIES"] = "democenter-summaries-2";
})(IElasticIndexes || (IElasticIndexes = {}));
