import { ToolType } from "../../../../../shared/types/tool-data-types/common";
import { TooltipV2Editor } from "../../tools/tooltip-v2/editor/tooltip-v2-editor";
import React from "react";
import { ZoomAndFormStepEditor } from "../../tools/screen/zoom-and-form-step-editor";
import { ModalEditor } from "../../tools/modal/editor/modal-editor";
import { GlobalButtonsEditor } from "../../tools/global-buttons/editor/global-buttons-editor";
import { SandboxEditor } from "../../tools/sandbox/sandbox-editor";
import { AutoTypewriterEditor } from "../../tools/auto-typewriter/editor/auto-typewriter-editor";
import { AutoMouseEditor } from "../../tools/auto-mouse/editor/auto-mouse-editor";
import { AutoTooltipEditor } from "../../tools/auto-tooltip/editor/auto-tooltip-editor";
export function RenderEditMenu({ toolData, viewportDimensions }) {
    switch (toolData.type) {
        case ToolType.TooltipV2:
            return React.createElement(TooltipV2Editor, { toolData: toolData, viewportDimensions: viewportDimensions });
        case ToolType.Screen:
            return React.createElement(ZoomAndFormStepEditor, { tab: 0 });
        case ToolType.Modal:
            return React.createElement(ModalEditor, { toolData: toolData });
        case ToolType.GlobalButtons:
            return React.createElement(GlobalButtonsEditor, { toolData: toolData });
        case ToolType.Sandbox:
            return React.createElement(SandboxEditor, { toolData: toolData });
        case ToolType.AutoTypewriter:
            return React.createElement(AutoTypewriterEditor, { toolData: toolData });
        case ToolType.AutoMouse:
            return React.createElement(AutoMouseEditor, { toolData: toolData });
        case ToolType.AutoTooltip:
            return React.createElement(AutoTooltipEditor, { toolData: toolData, viewportDimensions: viewportDimensions });
        default:
            throw new Error("attempt to render edit tool panel for unsupported tool type");
    }
}
