import { useEffect, useState } from "react";
import { TourialApiCalls } from "../helpers/tourial-api-calls";
export function useTourialList() {
    const [tourialList, setTourialList] = useState(null);
    useEffect(() => {
        TourialApiCalls.Tourials.getTourialList()
            .then(r => {
            setTourialList(r.tourialList);
        })
            .catch(e => {
            console.error("failed to fetch tourial list", e);
        });
    }, []);
    return tourialList;
}
export function useDemoCenterList() {
    const [demoCenterList, setDemoCenterList] = useState(null);
    useEffect(() => {
        TourialApiCalls.DemoCenters.getDemoCenters()
            .then(r => {
            setDemoCenterList(r);
        })
            .catch(e => {
            console.error("failed to fetch democenter overview list", e);
            setDemoCenterList([]);
        });
    }, []);
    return demoCenterList;
}
export function useHTList() {
    const [htmlTourList, setHtmlTourList] = useState(null);
    useEffect(() => {
        TourialApiCalls.DomTours.getDomTourList()
            .then(r => {
            setHtmlTourList(r.domTourList);
        })
            .catch(e => {
            console.error("failed to fetch html tours for overview list", e);
            setHtmlTourList([]);
        });
    }, []);
    return htmlTourList;
}
