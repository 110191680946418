import React from "react";
import { useEditableTextarea, useUpdateEditableTextarea } from "../../../hooks/editable-text-area-hooks";
import { useActiveVariantName, useEditingTextToolId, usePushHistory, useToolData, useUpdateBuilder, useViewportMode, } from "../../../redux/selectors/redux-selectors";
import { ViewportMode } from "../../../types/definitions";
import { ActiveVariantName } from "../../../../../shared/types/tool-data-types/common";
export const getTextAreaCursor = (mode, isToolEditingText) => {
    if (mode !== ViewportMode.EDIT)
        return "inherit";
    return isToolEditingText ? "text" : "move";
};
function EditableTextarea(props) {
    const textData = useEditableTextarea(props.toolId);
    const mode = useViewportMode();
    const updateText = useUpdateEditableTextarea(props.toolId);
    const updateBuilder = useUpdateBuilder();
    const pushHistory = usePushHistory();
    const isToolEditingText = useEditingTextToolId(props.toolId);
    const toolData = useToolData(props.toolId);
    const activeVariantName = useActiveVariantName();
    const { sizeAndPosition } = toolData;
    const { text, fontFamily, fontWeight, color, textAlign, isUnderlined, isItalic, percentFontSize } = textData;
    //Possibly make these editable in future
    const percentLineHeight = 1.5;
    const lineHeight = (props.viewportDimensions.height * percentFontSize * percentLineHeight) / 100;
    const style = {
        width: "100%",
        maxHeight: "100%",
        left: 0,
        top: 0,
        textAlign,
        fontSize: `${(props.viewportDimensions.height * percentFontSize) / 100}px`,
        fontWeight,
        lineHeight: `${mode !== ViewportMode.EDIT ? Math.floor(lineHeight) : Math.ceil(lineHeight)}px`,
        fontFamily,
        overflow: "hidden",
        position: "static",
        display: "block",
        color,
        fontStyle: isItalic ? "italic" : "normal",
        textDecoration: isUnderlined ? "underline" : "none",
        verticalAlign: props.isButton ? "middle" : "top",
        cursor: getTextAreaCursor(mode, isToolEditingText),
    };
    if (props.isButton && !isToolEditingText) {
        return (React.createElement("p", { style: style, onDoubleClick: () => {
                if (mode !== ViewportMode.EDIT)
                    return;
                updateBuilder({
                    editingTextToolId: props.toolId,
                });
            } }, text));
    }
    if (mode !== ViewportMode.EDIT) {
        const aspectRatio = activeVariantName === ActiveVariantName.VARIANT_DESKTOP ? 16 / 9 : 9 / 16;
        const svgHeight = (sizeAndPosition.height * 100) / percentFontSize;
        const svgWidth = ((sizeAndPosition.width * 100) / percentFontSize) * aspectRatio;
        return (React.createElement("svg", { style: { verticalAlign: props.isButton ? "middle" : "top" }, viewBox: `0 0 ${svgWidth} ${svgHeight}` },
            React.createElement("foreignObject", { x: "0", y: "0", width: "100%", height: "100%" },
                React.createElement("div", { style: Object.assign(Object.assign({}, style), { fontSize: 100, lineHeight: `${percentLineHeight * 100}px`, maxHeight: "none", wordWrap: "break-word", whiteSpace: "pre-wrap" }) }, text))));
    }
    return (React.createElement("textarea", { style: style, value: text, rows: 9999, onFocus: e => {
            const temp_value = e.target.value;
            e.target.value = "";
            e.target.value = temp_value;
        }, onChange: e => updateText(e.target.value), readOnly: mode !== ViewportMode.EDIT || !isToolEditingText, onDoubleClick: () => updateBuilder({
            editingTextToolId: props.toolId,
        }), onBlur: () => {
            pushHistory();
            updateBuilder({ editingTextToolId: "" });
        } }));
}
export { EditableTextarea };
