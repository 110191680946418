import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Four04Page } from "../../../404-page";
import IsLoading from "../../../../../legacy/components/is-loading/is-loading";
import { LiveTourial } from "../../../live/live-tourial";
import { useActiveVariantName, useClearTourialRootState, useTourialId, } from "../../../../../redux/selectors/redux-selectors";
import { useDCSetTourialRootState } from "../../../../../redux/selectors/demo-center-selectors";
import { ViewportMode } from "../../../../../types/definitions";
import { ActiveVariantName } from "../../../../../../../shared/types/tool-data-types/common";
import { useSetTourSessionIdWithinDemoCenter } from "../../../../../hooks/live-hooks";
import { useHasTourEnded } from "../../../../../hooks/use-has-tour-ended";
import { useDCItemHasBeenCompletedWhen } from "../../hooks/dc-item-hooks";
import { newTheme } from "../../../../styled/theme";
export const DCTourPlayer = ({ item }) => {
    const { id } = item;
    const [isTourialErrored, setIsTourialErrored] = useState(false);
    const activeVariantName = useActiveVariantName();
    const setTourialRootState = useDCSetTourialRootState();
    const clearTourialRootState = useClearTourialRootState();
    const tourHasEnded = useHasTourEnded();
    useDCItemHasBeenCompletedWhen(tourHasEnded);
    useSetTourSessionIdWithinDemoCenter();
    useEffect(() => {
        if (id) {
            setTourialRootState(id, ViewportMode.LIVE)
                .then(() => {
                window.dispatchEvent(new Event("resize"));
            })
                .catch(() => setIsTourialErrored(true));
        }
        return () => {
            clearTourialRootState();
        };
    }, [id]);
    return (React.createElement(TourialContainer, null,
        React.createElement(Embed, { activeVariantName: activeVariantName },
            React.createElement(TourialWindow, { isTourialErrored: isTourialErrored }))));
};
export const TourialWindow = ({ isTourialErrored }) => {
    const isLoaded = useTourialId();
    if (isLoaded) {
        // Only render after Tourial loaded because the usePostEvent requires a loaded Tourial
        return React.createElement(LiveTourialContainer, null);
    }
    if (isTourialErrored) {
        return React.createElement(Four04Page, null);
    }
    return React.createElement(IsLoading, null);
};
const LiveTourialContainer = () => {
    return (React.createElement("div", null,
        React.createElement(LiveTourial, null)));
};
const TourialContainer = styled.div `
  margin: auto;
  ${newTheme.shadows.dcTourViewport}
`;
const Embed = styled.div `
  position: relative;
  width: 100%;
  padding: 0px;
  padding-bottom: ${p => (p.activeVariantName === ActiveVariantName.VARIANT_MOBILE ? "177.77%" : "56.25%")};
`;
