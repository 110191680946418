import React from "react";
import TLabel from "../styled/t-label";
import TNumberInput from "../styled/t-number-input";
import { useToolData, useUpdateTool } from "../../../redux/selectors/redux-selectors";
import { viewportAspectRatio } from "../../../types/viewport-aspect-ratio";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
import { ToolType } from "../../../../../shared/types/tool-data-types/common";
export function EditorToolSizeAndPosition({ toolId, x, y, width, height, }) {
    const updateTool = useUpdateTool();
    const toolData = useToolData(toolId);
    function roundToHundredths(n) {
        return Math.round(n * 100) / 100;
    }
    return (React.createElement("div", null,
        React.createElement(TLabel, null,
            "X",
            React.createElement(TNumberInput, { style: { color: theme.colors.grey.darkest }, value: roundToHundredths(x), min: 0, max: 100 - width, onChange: e => {
                    const v = Math.min(100 - width, e.target.value);
                    updateTool(Object.assign(Object.assign({}, toolData), { sizeAndPosition: Object.assign(Object.assign({}, toolData.sizeAndPosition), { x: v }) }));
                } })),
        React.createElement(TLabel, null,
            "Y",
            React.createElement(TNumberInput, { style: { color: theme.colors.grey.darkest }, value: roundToHundredths(y), min: 0, max: 100 - height, onChange: e => {
                    const v = Math.min(100 - height, e.target.value);
                    updateTool(Object.assign(Object.assign({}, toolData), { sizeAndPosition: Object.assign(Object.assign({}, toolData.sizeAndPosition), { y: v }) }));
                } })),
        toolData.type === ToolType.Hotspot ? (React.createElement(TLabel, null,
            "Size",
            React.createElement(TNumberInput, { style: { color: theme.colors.grey.darkest }, value: roundToHundredths(width), min: 1, max: 9, onChange: e => {
                    updateTool(Object.assign(Object.assign({}, toolData), { sizeAndPosition: Object.assign(Object.assign({}, toolData.sizeAndPosition), { width: +e.target.value, height: e.target.value * (viewportAspectRatio.desktop.width / viewportAspectRatio.desktop.height) }) }));
                } }))) : (React.createElement("div", null,
            React.createElement(TLabel, null,
                "Width",
                React.createElement(TNumberInput, { style: { color: theme.colors.grey.darkest }, value: roundToHundredths(width), min: 1, max: 100, onChange: e => {
                        updateTool(Object.assign(Object.assign({}, toolData), { sizeAndPosition: Object.assign(Object.assign({}, toolData.sizeAndPosition), { width: +e.target.value }) }));
                    } })),
            React.createElement(TLabel, null,
                "Height",
                React.createElement(TNumberInput, { style: { color: theme.colors.grey.darkest }, value: roundToHundredths(height), min: 1, max: 100, onChange: e => {
                        updateTool(Object.assign(Object.assign({}, toolData), { sizeAndPosition: Object.assign(Object.assign({}, toolData.sizeAndPosition), { height: +e.target.value }) }));
                    } }))))));
}
