import { useEffect, useState } from "react";
import { useIsMicrositePublished, useIsPublished, useUserAccess } from "../redux/selectors/redux-selectors";
import { AccessLevel } from "../../../shared/types/auth-types";
import { useHTIsPublished } from "../redux/selectors/ht-selectors";
import { useDCIsPublished } from "../redux/selectors/demo-center-selectors";
export function useIsEditModeBlocked() {
    const userAccess = useUserAccess();
    const isPublished = useIsPublished();
    const [isBlocked, setIsBlocked] = useState(false);
    useEffect(() => {
        const isCollaboratorAndPublished = userAccess === AccessLevel.COLLABORATOR && isPublished;
        const isViewer = userAccess === AccessLevel.VIEWER;
        if (isCollaboratorAndPublished || isViewer) {
            setIsBlocked(true);
        }
        else {
            setIsBlocked(false);
        }
    }, [userAccess, isPublished]);
    return isBlocked;
}
export function useMicrositeIsEditModeBlocked() {
    const userAccess = useUserAccess();
    const isPublished = useIsMicrositePublished();
    const [isBlocked, setIsBlocked] = useState(false);
    useEffect(() => {
        const isCollaboratorAndPublished = userAccess === AccessLevel.COLLABORATOR && isPublished;
        const isViewer = userAccess === AccessLevel.VIEWER;
        if (isCollaboratorAndPublished || isViewer) {
            setIsBlocked(true);
        }
        else {
            setIsBlocked(false);
        }
    }, [userAccess, isPublished]);
    return isBlocked;
}
export function useHTIsEditModeBlocked() {
    const userAccess = useUserAccess();
    const isPublished = useHTIsPublished();
    const [isBlocked, setIsBlocked] = useState(false);
    useEffect(() => {
        const isCollaboratorAndPublished = userAccess === AccessLevel.COLLABORATOR && isPublished;
        const isViewer = userAccess === AccessLevel.VIEWER;
        if (isCollaboratorAndPublished || isViewer) {
            setIsBlocked(true);
        }
        else {
            setIsBlocked(false);
        }
    }, [userAccess, isPublished]);
    return isBlocked;
}
export function useDCIsEditModeBlocked() {
    const userAccess = useUserAccess();
    const isPublished = useDCIsPublished();
    const [isBlocked, setIsBlocked] = useState(false);
    useEffect(() => {
        const isCollaboratorAndPublished = userAccess === AccessLevel.COLLABORATOR && isPublished;
        const isViewer = userAccess === AccessLevel.VIEWER;
        if (isCollaboratorAndPublished || isViewer) {
            setIsBlocked(true);
        }
        else {
            setIsBlocked(false);
        }
    }, [userAccess, isPublished]);
    return isBlocked;
}
