import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BoldIcon from "../../../../../../assets/images/bold-icon.svg";
import ItalicIcon from "../../../../../../assets/images/italic-icon.svg";
import UnderlineIcon from "../../../../../../assets/images/underline-icon.svg";
import { newTheme } from "../../../../styled/theme";
import { TextStyle } from "../../../../../../../shared/types/tool-data-types/common";
export const TextStyleSelector = (props) => {
    const { onChange, style } = props;
    const [isBold, setIsBold] = useState(false);
    const [isItalics, setIsItalics] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    useEffect(() => {
        switch (style) {
            case TextStyle.BOLD:
                setIsBold(true);
                break;
            case TextStyle.ITALIC:
                setIsItalics(true);
                break;
            case TextStyle.UNDERLINE:
                setIsUnderline(true);
                break;
            case TextStyle.NORMAL:
            default:
                setIsBold(false);
                setIsItalics(false);
                setIsUnderline(false);
        }
    }, [style]);
    return (React.createElement("div", null,
        React.createElement(BoldButton, { selected: isBold, onClick: () => {
                setIsBold(!isBold);
                setIsItalics(false);
                setIsUnderline(false);
                onChange(!isBold ? TextStyle.BOLD : TextStyle.NORMAL);
            } },
            React.createElement("img", { src: BoldIcon, style: { opacity: isBold ? "1" : "0.6" } })),
        React.createElement(ItalicsButton, { selected: isItalics, onClick: () => {
                setIsItalics(!isItalics);
                setIsBold(false);
                setIsUnderline(false);
                onChange(!isItalics ? TextStyle.ITALIC : TextStyle.NORMAL);
            } },
            React.createElement("img", { src: ItalicIcon, style: { opacity: isItalics ? "1" : "0.6" } })),
        React.createElement(UnderlineButton, { selected: isUnderline, onClick: () => {
                setIsUnderline(!isUnderline);
                setIsBold(false);
                setIsItalics(false);
                onChange(!isUnderline ? TextStyle.UNDERLINE : TextStyle.NORMAL);
            } },
            React.createElement("img", { src: UnderlineIcon, style: { opacity: isUnderline ? "1" : "0.6" } }))));
};
const BoldButton = styled.button `
  color: ${newTheme.colors.grey600};
  background-color: ${props => (props.selected ? newTheme.colors.grey300 : newTheme.colors.white)};
  border-style: solid;
  border-width: 1px;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.grey200};
  }
`;
const ItalicsButton = styled.button `
  color: ${newTheme.colors.grey600};
  background-color: ${props => (props.selected ? newTheme.colors.grey300 : newTheme.colors.white)};
  border-style: solid;
  border-width: 1px 0;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 0;
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.grey200};
  }
`;
const UnderlineButton = styled.button `
  color: ${newTheme.colors.grey600};
  background-color: ${props => (props.selected ? newTheme.colors.grey300 : newTheme.colors.white)};
  border-style: solid;
  border-width: 1px;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.grey200};
  }
`;
