import React from "react";
import { ViewportMode } from "../../../../../../types/definitions";
import { useLegacyHideTool } from "../../../../../../redux/selectors/redux-selectors";
import { formPercentageOfViewport, FormV2Tab } from "../../../../tool-views/form-v2-view";
import CloseButtonIcon from "../../../../../../../assets/images/close-button.svg";
import { setFormWasDismissed } from "../../../../../../helpers/form-helpers";
export function CloseButton({ id, vpd, tab, viewportMode }) {
    const hideTool = useLegacyHideTool();
    const headerHeight = vpd.height * formPercentageOfViewport * 0.08;
    return (React.createElement("div", { style: {
            padding: `${(headerHeight * 0.625) / 2}px`,
            cursor: viewportMode === ViewportMode.EDIT ? "default" : "pointer",
        }, onClick: () => {
            hideTool(id);
            if (tab === FormV2Tab.Form) {
                setFormWasDismissed(id, true);
            }
        } },
        React.createElement("img", { src: CloseButtonIcon, style: {
                height: `${headerHeight * 0.375}px`,
            } })));
}
