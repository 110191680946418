import React, { useState } from "react";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { VersionsModal } from "../modals/versions-modal";
import VersionControlIcon from "../../../../../assets/images/v3/version-control-black.svg";
import { useTourialHasChangesToSave, useVersionWasModified } from "../../../../redux/selectors/redux-selectors";
import { useCheckedOutVersion } from "../../../../redux/selectors/builder-selectors";
import SaveVersionModal from "../modals/save-version-modal";
export function VersionsButton() {
    const [isOpen, setIsOpen] = useState(false);
    const [isSaveVersionModalOpen, setIsSaveVersionModalOpen] = useState(false);
    const versionWasModified = useVersionWasModified();
    const checkedOutVersion = useCheckedOutVersion();
    const tourialHasChangesToSave = useTourialHasChangesToSave();
    const requiresAction = checkedOutVersion ? versionWasModified : tourialHasChangesToSave;
    return (React.createElement(React.Fragment, null,
        React.createElement(TButton, { onClick: () => {
                requiresAction ? setIsSaveVersionModalOpen(true) : setIsOpen(true);
            }, size: "medium", variant: TButtonVariants.OUTLINE, icon: VersionControlIcon, dataCy: "versions-button" }),
        isOpen && React.createElement(VersionsModal, { onClose: () => setIsOpen(false) }),
        isSaveVersionModalOpen && (React.createElement(SaveVersionModal, { onClose: () => setIsSaveVersionModalOpen(false), onActionTaken: () => setIsOpen(true) }))));
}
