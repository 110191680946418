import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { dcFadeIn } from "../helpers/dc-style-helpers";
import { dcGetRGBColorStringFromHex } from "../helpers/dc-helpers";
import { DCStyledTitleText } from "../components/dc-styled-text";
import { DCFeaturedContent } from "./dc-featured-content";
import { useDCConfig, useDCIsLiveMode, useDCModifyButtonConfig, useDCTheme } from "../hooks/dc-hooks";
import { DCMode } from "../../../../../../shared/types/demo-center";
import { TextAlign } from "../../../../../../shared/types/tool-data-types/common";
import { DCTrackedButton } from "./dc-tracked-button";
import { useDCGoToSelfDemo } from "../hooks/dc-navigation-hooks";
import { useDCMode } from "../../../../redux/selectors/demo-center-selectors";
import { isEmbedded } from "../../../../helpers/live";
export const DCWelcome = ({ browseAction, setShouldShowDemoButton }) => {
    const isLiveMode = useDCIsLiveMode();
    const { welcome, gallerySection } = useDCConfig();
    const mode = useDCMode();
    const theme = useDCTheme();
    const welcomeRef = useRef(null);
    const goToDemo = useDCGoToSelfDemo();
    const modifyButtonConfig = useDCModifyButtonConfig();
    const { title, message, browseButton, demoButton, featuredContentId } = welcome;
    const checkPosition = () => {
        if (welcomeRef.current) {
            const { offsetTop, clientHeight } = welcomeRef.current;
            setShouldShowDemoButton(window.scrollY > offsetTop + clientHeight - 82);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", checkPosition);
        return () => window.removeEventListener("scroll", checkPosition);
    }, []);
    const align = (textStyle) => {
        return Object.assign(Object.assign({}, textStyle), { align: featuredContentId ? TextAlign.LEFT : TextAlign.CENTER });
    };
    return (React.createElement(WelcomeContainer, { className: `${isLiveMode ? "fade-in" : ""} ${isEmbedded() ? "embedded" : ""}`, theme: theme, ref: welcomeRef, style: {
            minHeight: mode === DCMode.IN_APP_PREVIEW ? "calc(100vh - 158px)" : "100%",
        } },
        React.createElement(HeroOuter, null,
            React.createElement(HeroInner, null,
                React.createElement(HeroContent, null,
                    React.createElement(DCStyledTitleText, { config: align(title.style) }, title.text),
                    React.createElement(DCStyledTitleText, { config: align(message.style) }, message.text),
                    React.createElement(ButtonSection, { style: !featuredContentId ? { justifyContent: "center" } : {} },
                        React.createElement(ButtonContainer, { style: !featuredContentId ? { alignItems: "center" } : {} },
                            React.createElement(DCTrackedButton, { config: modifyButtonConfig(demoButton, { applyGradient: true }), onClick: goToDemo }),
                            gallerySection.isEnabled && !isEmbedded() && (React.createElement(DCTrackedButton, { config: modifyButtonConfig(browseButton, { applyTheme: true }), onClick: () => {
                                    browseAction();
                                } }))))),
                !!featuredContentId && React.createElement(DCFeaturedContent, { id: featuredContentId })))));
};
const WelcomeContainer = styled.div `
  background-color: ${p => dcGetRGBColorStringFromHex(p.theme.neutralTranslucentColor, p.theme.neutralTranslucentOpacity)};
  backdrop-filter: blur(50px);
  display: flex;
  flex-direction: column;
  min-height: 600px;
  height: 100%;
  flex: 1;
  margin-top: -82px;
  .fade-in {
    animation: ${dcFadeIn} 0.2s ease-in;
  }
  &.embedded {
    margin-top: -24px;
  }
`;
const HeroOuter = styled.div `
  display: flex;
  justify-content: center;
  height: 100%;
  flex: 1;
  padding: 82px 96px 82px 96px;
`;
const HeroInner = styled.div `
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 48px;
`;
const HeroContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 600px;
`;
const ButtonSection = styled.div `
  display: flex;
  flex-direction: row;
  margin-top: 6px;
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;
