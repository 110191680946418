import React, { useState } from "react";
import styled from "styled-components";
import ArrowLeftIcon from "../../../../../../assets/images/v3/arrow-left.svg";
import ArrowRightIcon from "../../../../../../assets/images/v3/arrow-right.svg";
import { StubPanelButton, StubPanelIcon } from "../../../builder/storyboard-mini/storyboard-mini-stub";
import { newTheme } from "../../../../styled/theme";
import { useHTIsFirstStep, useHTIsLastStep } from "../../hooks/ht-hooks";
import { useHTGotoNextStep, useHTGotoPrevStep, useHTMode, useHTStep, } from "../../../../../redux/selectors/ht-selectors";
import { HTMode } from "../../../../../../../shared/types/dom-tour";
export function HTStepsPanelStepper() {
    const goToPreviousStep = useHTGotoPrevStep();
    const goToNextStep = useHTGotoNextStep();
    const step = useHTStep();
    const mode = useHTMode();
    const isPreviewMode = mode === HTMode.PREVIEW;
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [isLastStep, setIsLastStep] = useState(false);
    useHTIsFirstStep(setIsFirstStep);
    useHTIsLastStep(setIsLastStep);
    return (React.createElement(CurrentStepPanelContainer, null,
        React.createElement(StubPanelButton, { onClick: goToPreviousStep, disabled: isFirstStep },
            React.createElement(StubPanelIcon, { src: ArrowLeftIcon })),
        React.createElement(Span, { isPreviewMode: isPreviewMode }, `${step.name}`),
        React.createElement(StubPanelButton, { onClick: goToNextStep, disabled: isLastStep },
            React.createElement(StubPanelIcon, { src: ArrowRightIcon }))));
}
const CurrentStepPanelContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  height: 100%;
  min-width: 200px;
  gap: 10px;
`;
const Span = styled.h4 `
  font: ${newTheme.fonts.small};
  text-align: center;
  user-select: none;
  ${({ isPreviewMode }) => isPreviewMode
    ? `
  width: 123px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
    : ""}
`;
