import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDCActiveModuleSlug, useDCTrackSelections, useDCSidebarIsOpen, useDCMode, } from "../../../../redux/selectors/demo-center-selectors";
import { DCMode } from "../../../../../../shared/types/demo-center";
import { DCPlaylist } from "./dc-playlist";
import { DCShareDialog } from "./dc-share-dialog";
import { useDCConfig, useDCModifyButtonConfig } from "../hooks/dc-hooks";
import { DCTrackedButton } from "./dc-tracked-button";
import { isEmbedded } from "../../../../helpers/live";
import { DCCTA } from "./dc-header";
export const DCSidebar = () => {
    const listRef = useRef(null);
    const shareRef = useRef(null);
    const [isShareFixed, setIsShareFixed] = useState(false);
    const [showShareDialog, setShowShareDialog] = useState(false);
    const sidebarIsOpen = useDCSidebarIsOpen();
    const trackSelections = useDCTrackSelections();
    const activeModuleSlug = useDCActiveModuleSlug();
    const modifyButtonConfig = useDCModifyButtonConfig();
    const { sidebar, header } = useDCConfig();
    const { shareButton, displayCTAWhileEmbedded } = sidebar;
    const mode = useDCMode();
    const isPreviewMode = mode === DCMode.IN_APP_PREVIEW;
    const showHeaderCta = isEmbedded() && displayCTAWhileEmbedded;
    const updateSharePosition = () => {
        const { clientHeight: shareHeight } = shareRef.current || {};
        const { clientHeight: listHeight } = listRef.current || {};
        if (listHeight && shareHeight) {
            setIsShareFixed(listHeight + shareHeight + 82 + 24 + (isPreviewMode ? 180 : 0) > window.innerHeight);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", updateSharePosition);
        return () => {
            window.removeEventListener("resize", updateSharePosition);
        };
    }, []);
    useEffect(() => {
        updateSharePosition();
    }, [trackSelections, activeModuleSlug]);
    const Share = () => {
        return (React.createElement(ShareContainer, { ref: shareRef, className: `${isPreviewMode ? "preview" : ""} ${isShareFixed ? "fixed" : ""}` },
            showHeaderCta && React.createElement(DCCTA, { config: modifyButtonConfig(header.cta, { applyHighlight: true }) }),
            React.createElement(DCTrackedButton, { config: modifyButtonConfig(shareButton, { applyGradient: true, shrink: showHeaderCta }), onClick: () => setShowShareDialog(true) }),
            showShareDialog && (React.createElement(DCShareDialog, { onClose: () => setShowShareDialog(false), style: {
                    bottom: showHeaderCta ? 42 : 72,
                    left: 0,
                } }))));
    };
    return (React.createElement(SidebarContainer, { config: sidebar, className: `${isPreviewMode ? "preview" : ""} ${sidebarIsOpen ? "open" : ""}`, style: {
            height: `calc(100vh - ${isPreviewMode ? 262 : isEmbedded() ? 24 : 82}px`,
        } },
        React.createElement(SidebarContent, { config: sidebar, onScroll: updateSharePosition, className: showShareDialog && isShareFixed ? "dim" : isShareFixed ? "fade" : "", style: { overflow: isPreviewMode ? "hidden" : "auto" } },
            React.createElement(PlaylistContainer, { className: showShareDialog && !isShareFixed ? "dim" : "", ref: listRef },
                React.createElement(DCPlaylist, null)),
            !isShareFixed && React.createElement(Share, null)),
        isShareFixed && React.createElement(Share, null)));
};
const SidebarContainer = styled.div `
  position: fixed;
  overflow: hidden;
  top: 82;
  left: -348px;
  z-index: 0;
  width: 348px;
  opacity: 0;
  transition:
    opacity 0.2s ease-out,
    left 0.2s ease-out;
  &.open {
    left: 0;
    opacity: 1;
    z-index: 200;
  }
  &.preview {
    top: 82px;
    position: absolute;
  }
`;
const SidebarContent = styled.div `
  position: relative;
  height: 100%;
  padding: 0 24px 24px 24px;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 8px;
  opacity: 1;
  transition: opacity 0.1s ease-in;
  &.dim {
    opacity: 0.2;
  }
  &.fade {
    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 83%, rgba(0, 0, 0, 0) 89%);
    padding: 0 24px 146px 24px;
  }
`;
const PlaylistContainer = styled.div `
  opacity: 1;
  transition: opacity 0.1s ease-in;
  &.dim {
    opacity: 0.2;
  }
`;
const ShareContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  &.fixed {
    position: fixed;
    bottom: 24px;
    left: 24px;
    width: 300px;
    &.preview {
      position: absolute;
    }
  }
  button {
    flex: 1;
  }
`;
