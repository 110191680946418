import React from "react";
import styled from "styled-components";
import ReactPortal from "../../../helpers/react-portal";
import { DropdownContainer, DropdownOption } from "../../pages/home-page/tourial-list";
import BluePencilIcon from "../../../../assets/images/v3/blue-pencil.svg";
import RedTrashcanIcon from "../../../../assets/images/v3/red-trashcan.svg";
export const ContextMenu = ({ params, onClose }) => {
    const { id, x, y, onDelete, onEdit } = params;
    return (React.createElement(ReactPortal, { wrapperId: "tourial-portal-contextmenu-container" },
        React.createElement(ContextMenuOverlay, { id: "t-overlay", onMouseDown: e => {
                if (document.elementFromPoint(e.clientX, e.clientY).id === "t-overlay")
                    onClose();
            } },
            React.createElement(ContextMenuContainer, { style: { left: x, top: y } },
                React.createElement(DropdownContainer, { className: "_test_tourial-context-menu", onClick: () => { } },
                    React.createElement(DropdownOption, { onClick: () => {
                            onEdit(id);
                            onClose();
                        } },
                        React.createElement(Icon, { src: BluePencilIcon }),
                        " ",
                        "Edit"),
                    id !== "screen" && (React.createElement(DropdownOption, { onClick: () => {
                            onDelete(id);
                            onClose();
                        } },
                        React.createElement(Icon, { src: RedTrashcanIcon }),
                        " ",
                        "Delete")))))));
};
const Icon = styled.img `
  display: inline;
  margin: 8px 8px 8px 16px;
`;
const ContextMenuOverlay = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;
const ContextMenuContainer = styled.div `
  position: absolute;
`;
