export var HTActionType;
(function (HTActionType) {
    HTActionType["NULL"] = "NULL";
    HTActionType["HT_SET_TOUR"] = "HT_SET_TOUR";
    HTActionType["HT_SET_PUBLISHED_TOUR"] = "HT_SET_PUBLISHED_TOUR";
    HTActionType["HT_SET_MODAL"] = "HT_SET_MODAL";
    HTActionType["HT_SET_IS_SIDEBAR_OPEN"] = "HT_SET_IS_SIDEBAR_OPEN";
    HTActionType["HT_SET_SCALE"] = "HT_SET_SCALE";
    HTActionType["HT_SET_IS_MOBILE"] = "HT_SET_IS_MOBILE";
    HTActionType["HT_GOTO_NEXT_STEP"] = "HT_GOTO_NEXT_STEP";
    HTActionType["HT_GOTO_PREV_STEP"] = "HT_GOTO_PREV_STEP";
    HTActionType["HT_GOTO_STEP"] = "HT_GOTO_STEP";
    HTActionType["HT_SET_MODE"] = "HT_SET_MODE";
    HTActionType["HT_SET_PAGE_FORM"] = "HT_SET_PAGE_FORM";
    HTActionType["HT_SET_SESSION_ID"] = "HT_SET_SESSION_ID";
    HTActionType["HT_SET_BASE_URL_WITH_SUBDOMAIN"] = "HT_SET_BASE_URL_WITH_SUBDOMAIN";
    HTActionType["HT_SET_INITIAL_TOURIAL_ROOT_STATE"] = "HT_SET_INITIAL_TOURIAL_ROOT_STATE";
    HTActionType["HT_CLEAR_TOURIAL_ROOT_STATE"] = "HT_CLEAR_TOURIAL_ROOT_STATE";
    HTActionType["HT_SET_VIEWPORT_PRESET_SIZE"] = "HT_SET_VIEWPORT_PRESET_SIZE";
    HTActionType["HT_SET_IS_STEPS_PANEL_OPENED"] = "HT_SET_IS_STEPS_PANEL_OPENED";
    HTActionType["HT_SET_LOADED_CAPTURE_ID"] = "HT_SET_LOADED_CAPTURE_ID";
    HTActionType["HT_SET_IS_EDITING_HTML"] = "HT_SET_IS_EDITING_HTML";
    HTActionType["HT_SET_IS_RESIZING_TOOL"] = "HT_SET_IS_RESIZING_TOOL";
})(HTActionType || (HTActionType = {}));
