var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { DCItemType, DCModuleType } from "../../../../../../shared/types/demo-center";
import { useDCActiveItemId, useDCActiveItemSubNavigation, useDCActiveModuleSlug, useDCSelectedTags, useDCSetActiveItemHasBeenCompleted, useDCSetActiveItemId, useDCSetActiveItemSubNavigation, useDCSetViewedItems, useDCViewedItems, useDemoCenter, useUpdateDemoCenter, } from "../../../../redux/selectors/demo-center-selectors";
import { useDCGetModule, useDCGetTrackSelectionsForModule, useDCModules, useDCSetModules } from "./dc-module-hooks";
import { dcEnforceUniqueSlug, dcGenerateSlugFromText, dcGetFileNameFromUrl, dcGetItemTypeFromFileName, dcGetItemTypeFromUrl, dcGetThumbnailFromUrl, dcReplaceFileExtenstionInUrl, } from "../helpers/dc-helpers";
import { isImageFileExtension } from "../../../../helpers/media";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
export const useDCItems = () => {
    const demoCenter = useDemoCenter();
    return demoCenter.items;
};
export const useDCSetItems = () => {
    const updateDemoCenter = useUpdateDemoCenter();
    return (items) => {
        updateDemoCenter({ items });
    };
};
export const useDCSetItem = () => {
    const items = useDCItems();
    const setItems = useDCSetItems();
    return (item) => {
        const newItems = [...items];
        const i = newItems.findIndex(i => i.id === item.id);
        if (i >= 0) {
            newItems[i] = item;
            setItems(newItems);
        }
    };
};
export const useDCUpdateItemId = () => {
    const items = useDCItems();
    const setItems = useDCSetItems();
    const modules = useDCModules();
    const setModules = useDCSetModules();
    const updateItemsInTracks = (oldId, newId) => {
        const newModules = [...modules];
        newModules.forEach(m => {
            m.tracks.forEach(t => {
                const i = t.items.findIndex((id) => id === oldId);
                if (i >= 0) {
                    t.items[i] = newId;
                }
            });
        });
        setModules(newModules);
    };
    return (item) => {
        const { id, title, type } = item;
        const newId = dcGenerateSlugFromText(title);
        const newItems = [...items];
        const i = newItems.findIndex(i => i.id === id);
        if (i >= 0) {
            if (newId !== id && type !== DCItemType.TOUR && type !== DCItemType.YOUTUBE && type !== DCItemType.DOM_TOUR) {
                const uniqueId = dcEnforceUniqueSlug(newId, (id) => {
                    return !!items.find(i => i.id === id);
                });
                item.id = uniqueId;
                updateItemsInTracks(id, uniqueId);
            }
            newItems[i] = item;
            setItems(newItems);
        }
        return item;
    };
};
export const useDCAddItem = () => {
    const items = useDCItems();
    const setItems = useDCSetItems();
    return (item) => {
        if (!items.find(i => i.id === item.id)) {
            setItems([...items, item]);
        }
    };
};
export const useDCRemoveItem = () => {
    const items = useDCItems();
    const setItems = useDCSetItems();
    return (id) => {
        const newItems = [...items];
        const i = newItems.findIndex(i => i.id === id);
        if (i >= 0) {
            newItems.splice(i, 1);
            setItems(newItems);
        }
    };
};
export const useDCIsItemInUse = () => {
    const modules = useDCModules();
    return (id) => {
        let isInUse = false;
        modules.forEach(m => {
            m.tracks.forEach(t => {
                if (t.items.includes(id)) {
                    isInUse = true;
                }
            });
        });
        return isInUse;
    };
};
export const useDCGetNewItem = () => {
    const items = useDCItems();
    const generateId = (item) => {
        const slug = dcGenerateSlugFromText(item.title);
        return dcEnforceUniqueSlug(slug, (slug) => {
            return !!items.find(i => i.id === slug);
        });
    };
    const defaultItem = {
        uuid: null,
        title: "New Item",
        description: "",
        type: null,
        id: "",
        thumbnail: "",
        tags: [],
    };
    return (item) => {
        const newItem = Object.assign(Object.assign(Object.assign({}, defaultItem), item), { uuid: uuidv4() });
        if (!newItem.id)
            newItem.id = generateId(newItem);
        return newItem;
    };
};
export const useDCCreateNewItemFromFileUpload = () => {
    const getNewItem = useDCGetNewItem();
    return (url, originalFileName) => {
        const newItem = getNewItem({
            url,
            title: originalFileName.split(".")[0],
            thumbnail: !!url && isImageFileExtension(url) ? url : dcReplaceFileExtenstionInUrl(url, "jpg"),
            type: dcGetItemTypeFromFileName(originalFileName),
        });
        return newItem;
    };
};
export const useDCCreateNewItemFromUrl = () => {
    const getNewItem = useDCGetNewItem();
    return (url) => __awaiter(void 0, void 0, void 0, function* () {
        let hostedVideoUrl = url;
        let thumbnail = dcGetThumbnailFromUrl(url);
        let title = dcGetFileNameFromUrl(url);
        const type = dcGetItemTypeFromUrl(url);
        if ([
            DCItemType.YOUTUBE,
            DCItemType.VIMEO,
            DCItemType.WISTIA,
            DCItemType.LOOM,
            DCItemType.VIDYARD,
            DCItemType.VOCALVIDEO,
        ].includes(type)) {
            const response = yield TourialApiCalls.DemoCenters.getVideoDetails(url, type);
            hostedVideoUrl = response.url;
            thumbnail = response.thumbnail || thumbnail;
            title = response.title || title;
        }
        else if (DCItemType.HTML === type) {
            const response = yield TourialApiCalls.DemoCenters.getIsEmbeddable(url);
            if (!response)
                return;
        }
        const newItem = getNewItem({
            url: hostedVideoUrl,
            title,
            thumbnail,
            type,
        });
        return newItem;
    });
};
export const useDCItem = (id) => {
    const items = useDCItems();
    return items.find(i => i.id === id);
};
export const useDCGetItem = () => {
    const items = useDCItems();
    return (id) => {
        return items.find(i => i.id === id);
    };
};
export const useDCFirstItemInPlaylist = () => {
    const modules = useDCModules();
    const module = modules[0];
    const getItems = useDCGetItemsForSelectedTracks();
    const items = getItems(module.slug);
    return items[0];
};
export const useDCLastItemInPlaylist = () => {
    const modules = useDCModules();
    const module = modules[modules.length - 1];
    const getItems = useDCGetItemsForSelectedTracks();
    const items = getItems(module.slug);
    return items[items.length - 1];
};
export const useDCCurrentItem = () => {
    const activeItemId = useDCActiveItemId();
    const getItem = useDCGetItem();
    return getItem(activeItemId);
};
export const useDCGetNextItem = () => {
    const getItems = useDCGetItemsForSelectedTracks();
    const activeModule = useDCActiveModuleSlug();
    const activeItemId = useDCActiveItemId();
    return () => {
        const items = getItems(activeModule);
        const i = items.findIndex(i => i.id === activeItemId);
        if (i >= 0 && i < items.length - 1) {
            return items[i + 1];
        }
        else if (i < 0 && items.length > 0) {
            return items[0];
        }
        return null;
    };
};
export const useDCGetPreviousItem = () => {
    const getItems = useDCGetItemsForSelectedTracks();
    const activeModule = useDCActiveModuleSlug();
    const activeItemId = useDCActiveItemId();
    return () => {
        const items = getItems(activeModule);
        const i = items.findIndex(i => i.id === activeItemId);
        if (i > 0) {
            return items[i - 1];
        }
        return null;
    };
};
export const useDCGetLastItemFromModule = () => {
    const getItems = useDCGetItemsForSelectedTracks();
    return (module) => {
        const items = getItems(module.slug);
        return items[items.length - 1];
    };
};
export const useDCGetItemsFromIds = () => {
    const items = useDCItems();
    return (ids) => {
        const newItems = [];
        if (ids) {
            ids.forEach(id => {
                const item = items.find(i => i.id === id);
                if (item)
                    newItems.push(item);
            });
        }
        return newItems;
    };
};
export const useDCGetItemsForSelectedTracks = () => {
    const getModule = useDCGetModule();
    const getTrackSelections = useDCGetTrackSelectionsForModule();
    const getItemsFromIds = useDCGetItemsFromIds();
    return (slug) => {
        var _a;
        const trackSelections = getTrackSelections(slug);
        const module = getModule(slug);
        if ((module === null || module === void 0 ? void 0 : module.type) === DCModuleType.NO_PROMPT) {
            return getItemsFromIds((_a = module === null || module === void 0 ? void 0 : module.tracks[0]) === null || _a === void 0 ? void 0 : _a.items);
        }
        else if ((trackSelections === null || trackSelections === void 0 ? void 0 : trackSelections.length) > 0) {
            let ids = [];
            module.tracks.forEach(t => {
                if (trackSelections.includes(t.slug)) {
                    ids = [...ids, ...t.items];
                }
            });
            return getItemsFromIds([...new Set(ids)]);
        }
        return [];
    };
};
export const useDCGetItemsForAllModuleTracks = () => {
    const getItemsFromIds = useDCGetItemsFromIds();
    return (module) => {
        let ids = [];
        let items = [];
        if (module.items.length > 0) {
            items = getItemsFromIds(module.items);
        }
        else {
            module.tracks.forEach(t => (ids = [...ids, ...t.items]));
            items = getItemsFromIds([...new Set(ids)]);
        }
        return items;
    };
};
export const useDCItemsFilteredByTags = () => {
    const items = useDCItems();
    const selectedTags = useDCSelectedTags();
    if (selectedTags.length === 0) {
        return items;
    }
    return items.filter(item => {
        let isTagged = false;
        selectedTags.forEach(tag => {
            if (item.tags.includes(tag)) {
                isTagged = true;
            }
        });
        return isTagged;
    });
};
export const useDCItemsSortedByTagCount = () => {
    const filteredItems = useDCItemsFilteredByTags();
    const selectedTags = useDCSelectedTags();
    const countNumberOfSelectedTags = (item) => {
        let count = 0;
        item.tags.forEach(tag => {
            if (selectedTags.includes(tag)) {
                count++;
            }
        });
        return count;
    };
    const sorted = [...filteredItems].sort((a, b) => {
        return countNumberOfSelectedTags(b) - countNumberOfSelectedTags(a);
    });
    return sorted;
};
export const useDCItemHasBeenViewed = (id) => {
    const viewedItems = useDCViewedItems();
    return !!viewedItems.find(i => i === id);
};
export const useDCItemHasBeenCompletedWhen = (completed) => {
    const setHasBeenCompleted = useDCSetActiveItemHasBeenCompleted();
    useEffect(() => {
        if (completed)
            setHasBeenCompleted(true);
    }, [completed]);
};
export const useDCSetItemAsViewed = () => {
    const setViewedItems = useDCSetViewedItems();
    const viewedItems = useDCViewedItems();
    return (id) => {
        if (!viewedItems.find(i => i === id)) {
            setViewedItems([...viewedItems, id]);
        }
    };
};
export const useDCGetTotalDurationForItemIds = () => {
    const getItemsFromIds = useDCGetItemsFromIds();
    return (ids) => {
        const items = getItemsFromIds(ids);
        let duration = 0;
        items.forEach(i => (duration += i.duration || 0));
        return duration;
    };
};
export const useDCItemViewTimer = (id) => {
    const setItemAsViewed = useDCSetItemAsViewed();
    useEffect(() => {
        const viewedTimer = window.setTimeout(() => {
            setItemAsViewed(id);
        }, 10000);
        return () => {
            window.clearTimeout(viewedTimer);
        };
    }, [id]);
};
export const useDCActivateItem = (id) => {
    const setActiveItemId = useDCSetActiveItemId();
    useEffect(() => {
        if (id) {
            setActiveItemId(id);
        }
        return () => {
            setActiveItemId(null);
        };
    }, [id]);
};
export const useDCEnableSubNavigation = () => {
    const subNavigation = useDCActiveItemSubNavigation();
    const setSubNavigation = useDCSetActiveItemSubNavigation();
    return () => setSubNavigation(Object.assign(Object.assign({}, subNavigation), { enabled: true }));
};
export const useDCDisableSubNavigation = () => {
    const setSubNavigation = useDCSetActiveItemSubNavigation();
    return (lastPageNumberVisited) => setSubNavigation({ enabled: false, currentPage: 0, totalPages: 0, lastPageNumberVisited });
};
export const useDCItemSubNavigationMonitor = (item) => {
    const { type, config } = item;
    const [lastPageNumberVisited, setLastPageNumberVisited] = useState(0);
    const { currentPage } = useDCActiveItemSubNavigation();
    const enable = useDCEnableSubNavigation();
    const disable = useDCDisableSubNavigation();
    useEffect(() => {
        if (currentPage > 0)
            setLastPageNumberVisited(currentPage);
    }, [currentPage]);
    useEffect(() => {
        if (type === DCItemType.PDF && (config === null || config === void 0 ? void 0 : config.pagination)) {
            enable();
        }
        else
            disable(lastPageNumberVisited);
        return () => disable(lastPageNumberVisited);
    }, [item]);
};
