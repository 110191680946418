var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { ActionType } from "../redux/actions/redux-actions";
import { objectToArray } from "../../../shared/functions/helpers";
import store from "../legacy/store";
import { TourialStatus } from "../../../shared/types/tourial-model";
export function fetchSavedTourial(tourialId, dispatch) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const r = yield axios.get(`/v2/tourial/${tourialId}`);
            const tourial = (_a = r === null || r === void 0 ? void 0 : r.data) === null || _a === void 0 ? void 0 : _a.tourial;
            if (!tourial) {
                throw new Error("empty response from server when fetching saved tourial");
            }
            if (tourial) {
                dispatch({
                    type: ActionType.UPDATE_LOCAL_SAVED_TOURIAL,
                    tourial,
                });
            }
            return tourial;
        }
        catch (err) {
            console.error("error fetching saved tourial", err);
        }
    });
}
export function isToolInActiveVariant(activeVariantName, toolId) {
    return objectToArray(store.getState().tourialRootReducer.tourial[activeVariantName].tools).find((o) => { var _a; return ((_a = o === null || o === void 0 ? void 0 : o.value) === null || _a === void 0 ? void 0 : _a.id) === toolId; });
}
export function getTourialStatus(tourialListItem) {
    if (tourialListItem.isPublished)
        return TourialStatus.LIVE;
    if (tourialListItem.archivedAt)
        return TourialStatus.ARCHIVED;
    if (tourialListItem.interactedAt)
        return TourialStatus.UNPUBLISHED;
    return TourialStatus.DRAFT;
}
export function getMicrositeStatus(micrositeListItem) {
    if (micrositeListItem.isPublished)
        return TourialStatus.LIVE;
    if (micrositeListItem.interactedAt)
        return TourialStatus.UNPUBLISHED;
    return TourialStatus.DRAFT;
}
