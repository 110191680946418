import React, { useEffect, useState } from "react";
import { useDCTrackSelections } from "../../../../redux/selectors/demo-center-selectors";
import styled from "styled-components";
import { DCCardButton } from "../components/dc-card-button";
import { DCModuleType } from "../../../../../../shared/types/demo-center";
import { DCStyledBodyText, DCStyledTitleText } from "../components/dc-styled-text";
import { useDCConfig, useDCModifyButtonConfig, useDCTheme } from "../hooks/dc-hooks";
import { DCTrackedButton } from "./dc-tracked-button";
import { newTheme } from "../../../styled/theme";
export const DCTrackSelector = ({ module, previewMode, onTrackSelect, onSelectionConfirm, onSkip, }) => {
    const [selectedTracks, setSelectedTracks] = useState([]);
    const trackSelections = useDCTrackSelections();
    const modifyButtonConfig = useDCModifyButtonConfig();
    const getClassName = (value) => (!value && previewMode ? "placeholder" : "");
    const getTitle = () => title || (previewMode ? "Topic Title" : "");
    const getPrompt = () => prompt || (previewMode ? "What part of our tool do you want to explore?" : "");
    const { moduleDetails, cardButton } = useDCConfig();
    const { tracks, prompt, description, type, title } = module || {};
    const { titleTextStyle, promptTextStyle, descriptionTextStyle, continueButton, skipButton } = moduleDetails;
    const theme = useDCTheme();
    useEffect(() => {
        var _a, _b;
        let defaultSelectedTracks = [];
        const currentlySelectedTracks = ((_a = trackSelections.find(s => s.module === module.slug)) === null || _a === void 0 ? void 0 : _a.tracks) || [];
        if (currentlySelectedTracks.length > 0) {
            defaultSelectedTracks = [...currentlySelectedTracks];
        }
        else if (previewMode) {
            const slug = (_b = tracks[0]) === null || _b === void 0 ? void 0 : _b.slug;
            if (slug) {
                defaultSelectedTracks.push(slug);
                onTrackSelect === null || onTrackSelect === void 0 ? void 0 : onTrackSelect(tracks[0], true, defaultSelectedTracks);
            }
        }
        setSelectedTracks(defaultSelectedTracks);
    }, [module, trackSelections]);
    const handleCardClick = (t) => {
        const { slug } = t;
        let tracks = [...selectedTracks];
        if (type === DCModuleType.MULTIPLE) {
            if (isSelected(slug)) {
                tracks.splice(selectedTracks.findIndex(t => t === slug), 1);
            }
            else {
                tracks.push(slug);
            }
        }
        else {
            tracks = [slug];
        }
        setSelectedTracks(tracks);
        onTrackSelect === null || onTrackSelect === void 0 ? void 0 : onTrackSelect(t, isSelected(t.slug), tracks);
    };
    const isSelected = (slug) => {
        return !!selectedTracks.find(t => t === slug);
    };
    return (React.createElement(SelectorContainer, null,
        React.createElement(Title, { config: titleTextStyle, className: getClassName(title) }, getTitle()),
        (!!prompt || previewMode) && (React.createElement(Prompt, { config: promptTextStyle, className: getClassName(prompt) }, getPrompt())),
        description && React.createElement(Description, { config: descriptionTextStyle }, description),
        React.createElement(TrackContainer, { className: type === DCModuleType.MULTIPLE ? "multi" : "single" }, tracks === null || tracks === void 0 ? void 0 : tracks.map((t, i) => (React.createElement(DCCardButton, { key: t.slug, label: t.title, imageUrl: t.img, isSelected: isSelected(t.slug), config: cardButton, theme: theme, type: type, index: i, onCardClick: () => handleCardClick(t) })))),
        !previewMode && (React.createElement(ButtonContainer, null,
            React.createElement(DCTrackedButton, { config: modifyButtonConfig(continueButton), onClick: () => onSelectionConfirm(selectedTracks), disabled: !selectedTracks.length }),
            React.createElement(DCTrackedButton, { config: modifyButtonConfig(skipButton, { applyTheme: true }), onClick: onSkip })))));
};
const Title = styled(DCStyledBodyText) `
  max-width: 680px;
  margin: 0px auto;
  &.placeholder {
    font-style: italic;
    color: ${newTheme.colors.grey500};
  }
`;
const Prompt = styled(DCStyledTitleText) `
  max-width: 680px;
  margin: 0px auto;
  &.placeholder {
    font-style: italic;
    color: ${newTheme.colors.grey500};
  }
`;
const Description = styled(DCStyledBodyText) `
  max-width: 680px;
  margin: 0px auto 24px;
`;
const SelectorContainer = styled.div `
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const TrackContainer = styled.div `
  max-width: 1024px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px auto;
  gap: 16px;
  &.multi {
    max-width: 680px;
    gap: 8px;
  }
`;
const ButtonContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;
