import styled from "styled-components";
import { BorderShape } from "../../../../../../../shared/types/tool-data-types/common";
import { FormInputBoxStyle } from "../../../../../../../shared/types/form";
export const StyledFormInput = styled.input `
  width: 100%;
  height: 32px;
  padding: ${p => (p.theme.boxStyle === FormInputBoxStyle.UNDERLINE ? "4px 0px" : "4px 8px")};
  margin: 4px 0 8px 0;
  color: ${p => p.theme.color};
  background-color: ${p => p.theme.backgroundColor};
  border: 1px solid ${p => p.theme.borderColor};
  border-width: ${p => (p.theme.boxStyle === FormInputBoxStyle.UNDERLINE ? "0 0 1px 0" : "1px")};
  border-radius: ${p => p.theme.boxStyle === FormInputBoxStyle.UNDERLINE
    ? "0"
    : p.theme.shape === BorderShape.SQUIRCLE
        ? "100px"
        : p.theme.shape === BorderShape.ROUNDED
            ? "4px"
            : "0"};
`;
