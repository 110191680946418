import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetErrorMessage } from "../../../actions/error-message";
class ErrorMessage extends React.Component {
    render() {
        const { resetErrorMessage } = this.props;
        const { errorMessage } = this.props;
        const { showError, message } = errorMessage;
        if (showError) {
            return (React.createElement("div", { id: "error-message-container" },
                React.createElement("div", { id: "close-error-message", onClick: resetErrorMessage }, "X"),
                React.createElement("div", { id: "error-message-content-container" },
                    React.createElement("div", { id: "error-message-content" },
                        React.createElement("p", null, message)))));
        }
        else {
            return null;
        }
    }
}
const mapStateToProps = (state) => ({
    errorMessage: state.errorMessage,
});
resetErrorMessage.propTypes = {
    resetErrorMessage: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { resetErrorMessage })(ErrorMessage);
