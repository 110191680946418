import React from "react";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
export default function TSelect({ children, value, onChange, style, disabled, image }) {
    const baseStyle = Object.assign(Object.assign(Object.assign({}, theme.typography.p), { fontSize: 12, background: theme.colors.white, border: `2px solid ${theme.colors.sky.light}`, borderRadius: "4px", height: 25, width: 160, boxSizing: "border-box", 
        // boxShadow: theme.shadows.shallowest,
        color: theme.colors.grey.darkest, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", padding: "2px", cursor: "pointer", margin: "4px 8px 4px 8px" }), style);
    return (React.createElement("select", { style: Object.assign(Object.assign({}, baseStyle), (image && {
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: 5,
            paddingLeft: 21,
        })), value: value, onChange: onChange, disabled: disabled || null }, children));
}
