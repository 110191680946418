import React from "react";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
export default function TTextInput({ image, placeholder, value, onChange, style, onBlur, onKeyPress, onClick, disabled, id, autoFocus, onFocus, name, className, }) {
    const baseStyle = Object.assign(Object.assign(Object.assign({}, theme.typography.p), { borderWidth: 2, borderStyle: "solid", borderColor: theme.colors.cobalt.light, borderRadius: "4px", height: 25, width: 120, boxSizing: "border-box", boxShadow: theme.shadows.shallow, color: theme.colors.grey.darkest, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", cursor: "text", padding: 0 }), style);
    const disabledStyle = Object.assign(Object.assign(Object.assign({}, baseStyle), { cursor: "not-allowed", backgroundColor: theme.colors.grey.medium, borderColor: theme.colors.grey.darkest, color: theme.colors.grey.darkest, boxShadow: theme.shadows.shallowest }), style);
    return (React.createElement("input", { id: id, className: className || "", autoComplete: "off", placeholder: placeholder, type: "text", style: Object.assign(Object.assign({}, (disabled ? disabledStyle : baseStyle)), (image && {
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: 5,
            paddingLeft: 21,
        })), disabled: disabled, value: value, name: name, onChange: e => {
            e.preventDefault();
            onChange(e);
        }, onBlur: e => {
            if (!onBlur)
                return;
            e.preventDefault();
            onBlur(e);
        }, onKeyPress: e => {
            if (!onKeyPress)
                return;
            onKeyPress(e);
        }, onClick: e => {
            if (!onClick)
                return;
            onClick(e);
        }, autoFocus: autoFocus, onFocus: e => {
            if (!onFocus)
                return;
            onFocus(e);
        } }));
}
