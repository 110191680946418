import React, { useState } from "react";
import { newTheme } from "../../../styled/theme";
import CaretIcon from "../../../../../assets/images/v3/big-caret-up.svg";
import styled from "styled-components";
export function HorizontalScrollArrows({ sidebarWidth, storyboardHeight, storyboardRef, editPanelWidth, zIndex, }) {
    const [intervalId, setIntervalId] = useState(null);
    const rate = 65;
    const amount = 120;
    function scrollLeft() {
        if (storyboardRef === null || storyboardRef === void 0 ? void 0 : storyboardRef.current) {
            storyboardRef.current.scrollBy({
                top: 0,
                left: -amount,
                behavior: "auto",
            });
        }
    }
    function scrollRight() {
        if (storyboardRef === null || storyboardRef === void 0 ? void 0 : storyboardRef.current) {
            storyboardRef.current.scrollBy({
                top: 0,
                left: amount,
                behavior: "auto",
            });
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(LeftArrow, { style: { left: 40 + sidebarWidth, height: storyboardHeight, zIndex: zIndex || "auto" }, onMouseDown: (event) => {
                if (event.which === 3 || event.button === 2) {
                    return;
                }
                setIntervalId(setInterval(scrollLeft, rate));
            }, onMouseUp: () => {
                setIntervalId(clearInterval(intervalId));
            }, onMouseLeave: () => {
                setIntervalId(clearInterval(intervalId));
            }, onMouseOut: () => {
                setIntervalId(clearInterval(intervalId));
            } },
            React.createElement("img", { src: CaretIcon, draggable: false, style: { transform: "rotate(-90deg)", width: 20, cursor: "pointer" } })),
        React.createElement(RightArrow, { style: { height: storyboardHeight, right: editPanelWidth, zIndex: zIndex || "auto" }, onMouseDown: (event) => {
                if (event.which === 3 || event.button === 2) {
                    return;
                }
                setIntervalId(setInterval(scrollRight, rate));
            }, onMouseUp: () => {
                setIntervalId(clearInterval(intervalId));
            }, onMouseLeave: () => {
                setIntervalId(clearInterval(intervalId));
            }, onMouseOut: () => {
                setIntervalId(clearInterval(intervalId));
            } },
            React.createElement("img", { src: CaretIcon, draggable: false, style: { transform: "rotate(90deg)", width: 20, cursor: "pointer" } }))));
}
const Arrow = styled.div `
  position: fixed;
  transition:
    bottom ${newTheme.transitions.layoutShift},
    height ${newTheme.transitions.layoutShift};
  width: 40px;
  bottom: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${newTheme.colors.white};
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.grey200};
  }
`;
const LeftArrow = styled(Arrow) ``;
const RightArrow = styled(Arrow) `
  right: 0px;
`;
