import React, { useState } from "react";
import { useDeselect } from "../../../../redux/selectors/builder-selectors";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import PlusIcon from "../../../../../assets/images/v3/plus-sign.svg";
export function AddScreenButton({ setIsAddScreenModalOpen }) {
    const [isHovered, setIsHovered] = useState(false);
    const deselect = useDeselect();
    return (React.createElement(Container, { onMouseEnter: () => {
            setIsHovered(true);
        }, onMouseLeave: () => {
            setIsHovered(false);
        }, onClick: () => {
            setIsAddScreenModalOpen(true);
            deselect();
        } },
        React.createElement(TitleContainer, { isHovered: isHovered }),
        React.createElement(ThumbnailContainer, { isHovered: isHovered },
            React.createElement("img", { src: PlusIcon, draggable: false }),
            "Add Screen")));
}
const Container = styled.div `
  display: flex;
  flex-direction: column;
  height: 96px;
  justify-content: space-around;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  gap: 4px;
`;
const TitleContainer = styled.div `
  display: flex;
  font: ${newTheme.fonts.small};
  text-align: center;
  align-items: center;
  justify-content: left;
  padding-left: 8px;
  height: 20px;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ isHovered }) => {
    if (isHovered) {
        return newTheme.colors.white;
    }
    else {
        return newTheme.colors.grey100;
    }
}};
  border: ${({ isHovered }) => {
    if (isHovered) {
        return `1px solid ${newTheme.colors.grey300}`;
    }
    else {
        return `1px dashed ${newTheme.colors.grey400}`;
    }
}};
`;
const ThumbnailContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font: ${newTheme.fonts.small};
  gap: 4px;
  width: 114px;
  height: 64px;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ isHovered }) => {
    if (isHovered) {
        return newTheme.colors.white;
    }
    else {
        return newTheme.colors.grey100;
    }
}};
  border: ${({ isHovered }) => {
    if (isHovered) {
        return `1px solid ${newTheme.colors.grey300}`;
    }
    else {
        return `1px dashed ${newTheme.colors.grey400}`;
    }
}};
  filter: ${({ isHovered }) => {
    if (isHovered) {
        return "grayscale(0)";
    }
    else {
        return "grayscale(1)";
    }
}};
`;
