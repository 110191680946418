import React, { useEffect } from "react";
import styled from "styled-components";
import { useActiveZoom, useCurrentPage, usePushHistory, useTools, useTourialMediaDurationAndType, useUpdateTool, useUpdateZoom, useVideoProgress, useViewToolsAndToolsInActiveView, } from "../../../redux/selectors/redux-selectors";
import { newTheme } from "../../styled/theme";
import { cloneDeep } from "lodash";
import { NumberInput } from "../../pages/builder/editor/atomic/number-input";
import { TRangeHorizontalSlider } from "../../styled/t-range-horizontal-slider";
import { ToolType } from "../../../../../shared/types/tool-data-types/common";
import { AdditionalControls } from "../../styled/editor-components";
import TButton from "../../styled/t-button";
import { roundToHundredths } from "../../../../../shared/functions/helpers";
import { TimingSettingsTitlebar } from "./timing-settings-titlebar";
export default function TimingSettings() {
    const activePage = useCurrentPage();
    const activeZoom = useActiveZoom();
    const updateZoom = useUpdateZoom();
    const tools = useTools();
    const updateTool = useUpdateTool();
    const videoProgress = useVideoProgress();
    const toolIds = useViewToolsAndToolsInActiveView().map(vtat => vtat.tool.id);
    const { duration, type, hasVideoDuration } = useTourialMediaDurationAndType();
    const pushHistory = usePushHistory();
    const hasProgress = type === "video" && hasVideoDuration && !!videoProgress;
    const updateHandler = (tool, value) => {
        const t = cloneDeep(tool);
        t.delay = value[0];
        t.duration = value[1] - value[0];
        updateTool(t);
    };
    // update tool settings when video is trimmed
    useEffect(() => {
        toolIds.map(id => {
            const tool = tools[id];
            if (!tool || tool.type === ToolType.Screen || tool.type === ToolType.Background)
                return null;
            if (tool.duration > duration || tool.delay > duration) {
                const t = cloneDeep(tool);
                if (tool.duration > duration) {
                    t.duration = duration;
                }
                if (tool.delay > duration) {
                    t.delay = 0;
                }
                updateTool(t);
            }
        });
    }, [duration]);
    return (React.createElement(ContentContainer, null,
        React.createElement(AdditionalControls, null,
            React.createElement("div", { style: { display: "flex", justifyContent: "space-between", alignItems: "center" } },
                React.createElement("div", { style: { width: "auto" } }, "Total time"),
                React.createElement(NumberInput, { disabled: type === "video" && hasVideoDuration, value: roundToHundredths(duration), min: 0, max: 1000, step: 1, style: { width: 180 }, onChange: (e) => {
                        pushHistory();
                        const automationDuration = e.target.valueAsNumber;
                        const newZoom = Object.assign(Object.assign({}, activeZoom), { automationDuration });
                        updateZoom(newZoom, activePage.id);
                        for (const id of toolIds) {
                            const tool = tools[id];
                            if (tool.delay > automationDuration) {
                                updateHandler(tool, [automationDuration, automationDuration]);
                            }
                            else if (tool.delay + tool.duration > automationDuration) {
                                updateHandler(tool, [tool.delay, automationDuration]);
                            }
                        }
                    }, unit: "secs" })),
            type === "video" && !hasVideoDuration && (React.createElement(VideoWarning, null, "Re-upload this video to sync the total time with the video length to avoid unexpected behavior")),
            React.createElement(Hr, null),
            hasProgress && !!toolIds.length && (React.createElement(ControlContainer, { key: "video-progress-text" },
                React.createElement("div", { style: { width: "100%", textAlign: "right" } }, `Apply current progress of ${videoProgress}secs to:`))),
            toolIds.map((id, i) => {
                const tool = tools[id];
                if (!tool || tool.type === ToolType.Screen || tool.type === ToolType.Background)
                    return null;
                return (React.createElement(ControlContainer, { key: id },
                    React.createElement("div", { style: { display: "flex", justifyContent: "space-between", height: 24 } },
                        React.createElement("div", { style: { display: "flex", color: newTheme.colors.black } },
                            React.createElement("div", { style: { paddingRight: 4 } },
                                i + 1,
                                ":"),
                            React.createElement(TimingSettingsTitlebar, { tool: tool, title: tool.name, style: { width: hasProgress ? 154 : 241 } })),
                        hasProgress && (React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                            React.createElement(TButton, { size: "small", style: { height: 24, lineHeight: "24px" }, onClick: () => {
                                    pushHistory();
                                    const t = cloneDeep(tool);
                                    t.duration = videoProgress > t.delay + t.duration ? 0 : t.delay + t.duration - videoProgress;
                                    t.delay = videoProgress;
                                    updateTool(t);
                                } }, "Start"),
                            React.createElement(TButton, { size: "small", onClick: () => {
                                    pushHistory();
                                    const t = cloneDeep(tool);
                                    if (videoProgress < t.delay)
                                        t.delay = videoProgress;
                                    t.duration = videoProgress - t.delay;
                                    updateTool(t);
                                }, style: { height: 24, marginLeft: 8, lineHeight: "24px" } }, "End")))),
                    React.createElement(TRangeHorizontalSlider, { value: [tool.delay, tool.delay + tool.duration], min: 0, max: duration, onChange: (value) => updateHandler(tool, value), style: { width: "calc(100% - 32px)" } })));
            }))));
}
const ContentContainer = styled.div `
  height: calc(100% - 31px);
  overflow-y: scroll;
  padding: 16px;
  background-color: ${newTheme.colors.grey100};
`;
const ControlContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Hr = styled.hr `
  width: 100%;
  border-top: 1px solid ${newTheme.colors.grey400};
  margin: 8px 0px 16px 0px;
`;
const VideoWarning = styled.p `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.red500};
`;
