import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DCIcon } from "../../components/dc-icon";
import { DCIconType, DCModuleType } from "../../../../../../../shared/types/demo-center";
import TInput from "../../../../styled/t-input";
import { newTheme } from "../../../../styled/theme";
import TTextarea from "../../../../styled/t-textarea";
import { DCCMModuleTypeSelector } from "./dc-cm-module-type-selector";
import { useDCGetDefaultTracks, useDCGetNewTrack } from "../../hooks/dc-module-hooks";
import { dcGenerateSlugFromText } from "../../helpers/dc-helpers";
import { TMessageBox, TMessageBoxVariants } from "../../../../styled/t-message-box";
export const DCWizardModuleEditor = ({ module, onChange }) => {
    var _a, _b;
    const { title, type, prompt, tracks } = module || {};
    const [previousModule, setPreviousModule] = useState(module);
    const moduleHasChanged = previousModule.title !== title && previousModule.prompt !== prompt;
    const lastTrackTitle = (_a = tracks === null || tracks === void 0 ? void 0 : tracks[(tracks === null || tracks === void 0 ? void 0 : tracks.length) - 1]) === null || _a === void 0 ? void 0 : _a.title;
    const isSingleOrMultiple = [DCModuleType.SINGLE, DCModuleType.MULTIPLE].includes(type);
    const isNoPrompt = type === DCModuleType.NO_PROMPT;
    const previousTypeWasMultipleOrSingle = [DCModuleType.SINGLE, DCModuleType.MULTIPLE].includes(previousModule.type);
    const isChangingTypes = !moduleHasChanged && type !== previousModule.type;
    const hasTracks = (tracks === null || tracks === void 0 ? void 0 : tracks.length) > 1 || ((tracks === null || tracks === void 0 ? void 0 : tracks.length) === 1 && !!((_b = tracks === null || tracks === void 0 ? void 0 : tracks[0]) === null || _b === void 0 ? void 0 : _b.title));
    const changingBetweenMultipleAndSingle = isSingleOrMultiple && previousTypeWasMultipleOrSingle && hasTracks && !moduleHasChanged;
    const getDefaultTracks = useDCGetDefaultTracks();
    const getNewTrack = useDCGetNewTrack();
    const defaultTrack = getNewTrack({ title: "", slug: "new-track" });
    const createNewTrack = () => updateTracks([...tracks, defaultTrack]);
    const updateName = (e) => onChange(Object.assign(Object.assign({}, module), { title: e.target.value }));
    const updatePrompt = (e) => onChange(Object.assign(Object.assign({}, module), { prompt: e.target.value }));
    const updateType = (type) => onChange(Object.assign(Object.assign({}, module), { type }));
    const updateTracks = (tracks) => onChange(Object.assign(Object.assign({}, module), { tracks }));
    const updateTrack = (index, track) => {
        const newTracks = [...tracks];
        newTracks[index] = track;
        updateTracks(newTracks);
    };
    const removeTrack = (i) => {
        if (i === 0)
            return;
        const newTracks = [...tracks];
        newTracks.splice(i, 1);
        updateTracks(newTracks);
    };
    const getTrackPlaceholder = (i) => {
        const scaleDefaults = ["Very important", "Somewhat important", "Not important"];
        const booleanDefaults = ["Yes", "No"];
        switch (type) {
            case DCModuleType.SCALE:
                return `Ex: "${scaleDefaults[i]}"`;
            case DCModuleType.BOOLEAN:
                return `Ex: "${booleanDefaults[i]}"`;
        }
        return "Add an option for your visitors to learn about...";
    };
    const applyDefaultTracks = () => {
        const defaultTracks = getDefaultTracks(type);
        if (isSingleOrMultiple)
            defaultTracks.push(defaultTrack);
        updateTracks(defaultTracks);
    };
    useEffect(() => {
        if (!(tracks === null || tracks === void 0 ? void 0 : tracks.length) || (isChangingTypes && !changingBetweenMultipleAndSingle)) {
            applyDefaultTracks();
        }
        setPreviousModule(module);
    }, [module]);
    return (React.createElement(ModuleEditorContainer, null,
        React.createElement(Row, null,
            React.createElement(Icon, null,
                React.createElement(DCIcon, { type: DCIconType.RIGHT_ARROW })),
            React.createElement(TInput, { value: title, onChange: updateName, placeholder: "Topic Title" })),
        React.createElement(Spacer, null),
        !isNoPrompt && (React.createElement(Row, null,
            React.createElement(Icon, null,
                React.createElement(DCIcon, { type: DCIconType.PROMPT })),
            React.createElement(TTextarea, { value: prompt, onChange: updatePrompt, placeholder: "Prompt e.g. 'What part of our tool do you want to explore?" }))),
        React.createElement(Row, { style: { paddingLeft: "48px" } },
            React.createElement(DCCMModuleTypeSelector, { size: "small", onClick: updateType, selectedType: type, areBooleanAndScaleDisabled: isSingleOrMultiple && hasTracks, isNoPromptDisabled: isSingleOrMultiple && hasTracks })),
        React.createElement(Row, null,
            React.createElement(Icon, null,
                React.createElement(DCIcon, { type: DCIconType.NETWORK })),
            React.createElement(TracksContainer, null,
                isNoPrompt ? (React.createElement(TMessageBox, { variant: TMessageBoxVariants.SECONDARY, message: "With 'No Prompt' selected, users will be taken directly to the first item in the topic.", size: "small" })) : (tracks === null || tracks === void 0 ? void 0 : tracks.map((t, i) => (React.createElement(TrackEditor, { key: `track-${i}`, type: type, track: t, onChange: track => updateTrack(i, track), autoFocus: isSingleOrMultiple && i === tracks.length - 1, placeholder: getTrackPlaceholder(i), createNewTrack: createNewTrack, deleteTrack: () => removeTrack(i) })))),
                isSingleOrMultiple && (!(tracks === null || tracks === void 0 ? void 0 : tracks.length) || !!lastTrackTitle) && (React.createElement(TInput, { placeholder: getTrackPlaceholder(), onFocus: createNewTrack }))))));
};
const TrackEditor = ({ track, disabled, autoFocus, placeholder, type, onChange, createNewTrack, deleteTrack, }) => {
    const { title } = track;
    const setTitle = (title) => onChange(Object.assign(Object.assign({}, track), { title, slug: dcGenerateSlugFromText(title) }));
    const isSingleOrMultiple = [DCModuleType.SINGLE, DCModuleType.MULTIPLE].includes(type);
    const inputRef = useRef(null);
    const handleBlur = () => {
        if (!title && isSingleOrMultiple) {
            deleteTrack();
        }
    };
    useEffect(() => {
        var _a;
        if (autoFocus && !title)
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [autoFocus]);
    return (React.createElement(TInput, { value: title, ref: inputRef, disabled: disabled, onChange: e => setTitle(e.target.value), placeholder: placeholder, autoFocus: autoFocus, onBlur: handleBlur, onKeyDown: e => {
            if (e.key === "Enter" && !!title && isSingleOrMultiple) {
                e.preventDefault();
                createNewTrack();
            }
        } }));
};
const ModuleEditorContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: ${newTheme.colors.white};
  border-radius: 4px;
  box-shadow: ${newTheme.shadows.demoCenter};
  margin-top: 32px;
`;
const Row = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  textarea,
  input {
    padding: 4px 8px;
    width: 100%;
    &:focus {
      border-color: ${newTheme.colors.blue300};
    }
    &::placeholder {
      font-style: italic;
      color: ${newTheme.colors.grey500};
    }
  }
`;
const Spacer = styled.div `
  display: flex;
  height: 1px;
  width: 100%;
  background-color: ${newTheme.colors.grey400};
`;
const Icon = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding-top: 8px;
  svg {
    width: 16px;
    height: 16px;
  }
`;
const TracksContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
