import { TimerTrigger } from "../../../shared/legacy/legacy-tool-data";
import { getFromStorage } from "./storage";
import store from "../legacy/store";
import { isToolInActiveVariant } from "./tourial";
import { TourialEvent } from "../../../shared/types/events";
export function startTimers(triggerType, timers, setTimer, pageId, zoomId, showTool, postEvent) {
    let relevantTimers = [];
    if (triggerType === TimerTrigger.SessionStart) {
        relevantTimers = (timers === null || timers === void 0 ? void 0 : timers.filter(t => t.trigger === triggerType)) || [];
    }
    else if (triggerType === TimerTrigger.View) {
        relevantTimers =
            (timers === null || timers === void 0 ? void 0 : timers.filter(t => t.trigger === triggerType && t.view.pageId === pageId && t.view.zoomId === zoomId)) || [];
    }
    // filter out timers attached to forms that were submitted or dismissed
    const localStorage = getFromStorage("tourialLive");
    if (localStorage) {
        relevantTimers = relevantTimers.filter(t => { var _a, _b, _c, _d; return !(((_b = (_a = localStorage === null || localStorage === void 0 ? void 0 : localStorage.formStates) === null || _a === void 0 ? void 0 : _a[t.toolId]) === null || _b === void 0 ? void 0 : _b.wasSubmitted) || ((_d = (_c = localStorage === null || localStorage === void 0 ? void 0 : localStorage.formStates) === null || _c === void 0 ? void 0 : _c[t.toolId]) === null || _d === void 0 ? void 0 : _d.wasDismissed)); });
    }
    for (const t of relevantTimers) {
        startTimer(t, setTimer, pageId, zoomId, showTool, postEvent);
    }
}
export function startTimer(timer, setTimer, pageId, zoomId, showTool, postEvent) {
    function delayForTransitionEnd() {
        setTimeout(onTimerCompletion, 1000);
    }
    function onTimerCompletion() {
        const activeVariantName = store.getState().tourialRootReducer.viewport.activeVariantName;
        if (!isToolInActiveVariant(activeVariantName, t.toolId))
            return;
        const isViewportTransitioning = store.getState().tourialRootReducer.viewport.isTransitioning;
        const toolType = store.getState().tourialRootReducer.tourial[activeVariantName].tools[t.toolId].type;
        if (!isViewportTransitioning) {
            showTool(t.toolId);
            setTimer(Object.assign(Object.assign({}, t), { isCompleted: true }));
            void postEvent({
                eventType: TourialEvent.TIMER_ELAPSED,
                pageId,
                zoomId,
                toolId: t.toolId,
                toolType,
            });
        }
        else {
            delayForTransitionEnd();
        }
    }
    const t = Object.assign({}, timer);
    if (t.isStarted || !t.isEnabled || t.isCompleted)
        return;
    setTimer(Object.assign(Object.assign({}, t), { isStarted: true }));
    setTimeout(onTimerCompletion, t.durationSecs * 1000);
    return t;
}
