import { v4 as uuidv4 } from "uuid";
import { getEditableTextareaTemplate } from "../editable-textarea/template";
import { ActiveVariantName, ToolType } from "../../../../../../../shared/types/tool-data-types/common";
export const getButtonTemplate = ({ toolData, sizeAndPosition, text, initialStyles, activeVariantName, }) => {
    var _a, _b, _c;
    return Object.assign({ id: uuidv4(), type: ToolType.Button, sizeAndPosition: Object.assign({ x: 10, y: 10, width: activeVariantName === ActiveVariantName.VARIANT_MOBILE ? 40 : 10, height: 6 }, sizeAndPosition), borderRadius: 5, enableBackground: true, backgroundColor: (_a = toolData === null || toolData === void 0 ? void 0 : toolData.backgroundColor) !== null && _a !== void 0 ? _a : "#0066CC", borderColor: (_b = toolData === null || toolData === void 0 ? void 0 : toolData.borderColor) !== null && _b !== void 0 ? _b : "#000000", borderStyle: "solid", borderWidth: 0, enableBorder: true, name: "button", textData: getEditableTextareaTemplate({
            text: (_c = text !== null && text !== void 0 ? text : toolData === null || toolData === void 0 ? void 0 : toolData.name) !== null && _c !== void 0 ? _c : "Text",
            initialStyles: Object.assign({ color: "white", textAlign: "center", fontFamily: "Open Sans", percentFontSize: 3.5 }, initialStyles),
        }) }, toolData);
};
