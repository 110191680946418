import React from "react";
import { getBorderStyles } from "../../../helpers/misc";
import { userShadows } from "../../../legacy/components/builder/tools/shape/editor";
import { isImageFileExtension } from "../../../helpers/media";
import { Link } from "react-router-dom";
import PlaceholderThumbnail from "../../../../assets/images/v3/placeholder-thumbnail.svg";
import { mobileThumbnailWidth, thumbnailHeight, thumbnailWidth as thumbnailWidthImported, } from "../../styled/editor-components";
import { newTheme } from "../../styled/theme";
import styled from "styled-components";
import { getBuilderUrl } from "../../../helpers/tourial-helpers/tourial-version-helpers";
import { useActiveVariantName, useUserAccess } from "../../../redux/selectors/redux-selectors";
import { ActiveVariantName } from "../../../../../shared/types/tool-data-types/common";
import { AccessLevel } from "../../../../../shared/types/auth-types";
export default function Thumbnail({ tourialData, isLink = true, scale = 1, style, hasHoverEffects = false, baseUrlWithSubdomain, }) {
    const userAccess = useUserAccess();
    const liveUrl = `${baseUrlWithSubdomain}${tourialData.slug}`;
    const isLegacyEditModeBlocked = (() => {
        if (!tourialData)
            return true;
        if (tourialData.isPublished && userAccess === AccessLevel.COLLABORATOR)
            return true;
        return false;
    })();
    const { screen, background: bg } = tourialData.firstPageData;
    const imageBackgroundStyle = {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: `url("${bg.src}")`,
        backgroundRepeat: "no-repeat",
    };
    const nonImageBackgroundStyle = {
        background: `linear-gradient(135deg, ${bg.colorPrimary} 0%, ${bg.colorSecondary || bg.colorPrimary} 100%)`,
    };
    const activeVariantName = useActiveVariantName();
    const isDesktop = activeVariantName === ActiveVariantName.VARIANT_DESKTOP;
    const thumbnailWidth = isDesktop ? thumbnailWidthImported : mobileThumbnailWidth;
    const ThumbnailContent = () => {
        return (React.createElement(React.Fragment, null, hasTourialScreenOrBackground(tourialData.firstPageData) ? (React.createElement(ThumbnailBackground, { isDesktop: isDesktop, style: Object.assign({}, (bg.src ? imageBackgroundStyle : nonImageBackgroundStyle)) },
            React.createElement("div", { style: Object.assign(Object.assign({ width: `${screen.sizeAndPosition.width}%`, height: `${screen.sizeAndPosition.height}%`, 
                    // position: "absolute",
                    overflow: "hidden", transform: `translate(${thumbnailWidth * (screen.sizeAndPosition.x / 100)}px, ${thumbnailHeight * (screen.sizeAndPosition.y / 100)}px)` }, getBorderStyles(screen === null || screen === void 0 ? void 0 : screen.enableBorder, screen === null || screen === void 0 ? void 0 : screen.borderRadius, screen === null || screen === void 0 ? void 0 : screen.borderWidth, screen === null || screen === void 0 ? void 0 : screen.borderColor, screen === null || screen === void 0 ? void 0 : screen.borderStyle, { width: thumbnailWidth, height: null, x: null, y: null })), { backgroundColor: (screen === null || screen === void 0 ? void 0 : screen.enableBackground)
                        ? screen === null || screen === void 0 ? void 0 : screen.backgroundColor
                        : isDesktop
                            ? null
                            : newTheme.colors.white, boxShadow: (screen === null || screen === void 0 ? void 0 : screen.enableShadow) ? userShadows[screen === null || screen === void 0 ? void 0 : screen.shadowStyle] : null }) }, screen.src &&
                (isImageFileExtension(screen.src) ? (React.createElement("img", { className: "_test_storyboard-thumbnail-img", src: screen.src, style: { width: "100%" } })) : (React.createElement("video", { controls: false, preload: "metadata", autoPlay: false, src: `${screen.src}#t=0.5`, style: { width: "100%" } })))))) : (React.createElement("img", { className: "_test_placeholder-thumbnail", src: PlaceholderThumbnail, style: { width: "100%", height: "100%" } }))));
    };
    const linkStyle = {
        opacity: 0.85,
        pointerEvents: isLink ? "auto" : "none",
    };
    return (React.createElement(ThumbnailContainer, { isLink: isLink, scale: scale, style: style, hasHoverEffects: hasHoverEffects, isDesktop: isDesktop }, isLegacyEditModeBlocked && baseUrlWithSubdomain ? (React.createElement("a", { href: liveUrl, target: "_blank", rel: "noreferrer", style: linkStyle },
        React.createElement(ThumbnailContent, null))) : (React.createElement(Link, { to: getBuilderUrl(tourialData.tourId, tourialData.version), style: linkStyle },
        React.createElement(ThumbnailContent, null)))));
}
function hasTourialScreenOrBackground(firstPageData) {
    if (!firstPageData)
        return false;
    const hasBackground = !!(firstPageData.background.src || firstPageData.background.colorPrimary);
    const hasScreen = !!(firstPageData.screen.src ||
        firstPageData.screen.enableBackground ||
        firstPageData.screen.enableBorder ||
        firstPageData.screen.enableShadow);
    return hasBackground || hasScreen;
}
const ThumbnailContainer = styled.div `
  ${props => props.isDesktop
    ? `
        width: ${thumbnailWidthImported}px;
        background-color: ${newTheme.colors.white};
      `
    : `
    display: flex;
    justify-content: center;
    background-color: ${newTheme.colors.grey300};
    width: 271px;
  `}
  height: ${thumbnailHeight}px;
  margin: 12.56px 11.2px;
  filter: drop-shadow(0px 0px 1px rgba(48, 49, 51, 0.05)) drop-shadow(0px 8px 16px rgba(48, 49, 51, 0.1));
  border-radius: 5px;
  overflow: hidden;
  transform: ${props => `scale(${props.scale})`};
  &:hover {
    border: ${props => props.hasHoverEffects && `2px solid ${newTheme.colors.blue200}`};
  }
`;
const ThumbnailBackground = styled.div `
  height: 100%;
  background-color: ${newTheme.colors.white};
  width: ${props => (props.isDesktop ? "100%" : `${mobileThumbnailWidth}px`)};
`;
