import React from "react";
import { DCCMSection, DCCMSectionSpacer } from "./components/dc-cm-section";
import { DCCMPageType, DCIconType } from "../../../../../../shared/types/demo-center";
import { DCCMFormSelector } from "./components/dc-cm-form-selector";
import { useDCModules, useDCSetModule } from "../hooks/dc-module-hooks";
import { TCheckbox } from "../../../styled/t-checkbox";
import styled from "styled-components";
import { useDCConfigGatingFormID } from "../../../../redux/selectors/demo-center-selectors";
import { useDCSetConfigGatingFormId } from "../hooks/dc-hooks";
import { useDCLivePreviewHelper } from "../hooks/dc-builder-hooks";
export const DCCMGatedModulesEditor = () => {
    const modules = useDCModules();
    const dcSetModule = useDCSetModule();
    const gatingFormId = useDCConfigGatingFormID();
    const setGatingFormId = useDCSetConfigGatingFormId();
    useDCLivePreviewHelper(DCCMPageType.GATED_MODULES);
    return (React.createElement(React.Fragment, null,
        React.createElement(DCCMSection, { icon: DCIconType.PADLOCK, title: "Gated topics", description: "Gated topics require a visitor to fill out a form before accessing the topic. Select a form and check the topics you want to gate." },
            React.createElement(DCCMFormSelector, { formId: gatingFormId, onChange: formId => setGatingFormId(formId) })),
        React.createElement(DCCMSectionSpacer, null),
        React.createElement(CheckboxRowsContainer, null, modules
            .filter(m => m.title !== "Welcome")
            .map(m => {
            return (React.createElement(ModuleCheckboxRow, { key: m.title },
                React.createElement(TCheckbox, { checked: m.isGated, onChange: () => dcSetModule(Object.assign(Object.assign({}, m), { isGated: !m.isGated })) }),
                React.createElement(Title, null, m.title)));
        }))));
};
const CheckboxRowsContainer = styled.div `
  display: flex;
  flex-direction: column;
  margin-left: 174px;
  gap: 24px;
`;
const ModuleCheckboxRow = styled.div `
  display: flex;
`;
const Title = styled.div `
  margin-left: 12px;
`;
