import React, { useContext, useState } from "react";
import TModal from "../../../../styled/t-modal";
import { useHTStepIndex, useHTSteps } from "../../../../../redux/selectors/ht-selectors";
import { TLoadingSpinner } from "../../../../styled/t-loading-spinner";
import { DTStepType, createHTDefaultTooltip, } from "../../../../../../../shared/types/dom-tour";
import { useHTFetchCaptureLibrary } from "../../hooks/ht-hooks";
import { newTheme } from "../../../../styled/theme";
import { DraftContext } from "../dom-tour-builder-page";
import addScreenIcon from "../../../../../../assets/images/v3/add-screen-icon.svg";
import { debounce, uniqBy } from "lodash";
import { ContentBody, ContentContainer, Footer, Img, MediaCardContainer, MediaCardFooter, MediaContainer, } from "../../../builder/storyboard-mini/add-screen-modal";
import TButton from "../../../../styled/t-button";
import { DynamicWidthInput } from "../../../builder/navbar/dynamic-width-input";
import CheckCircleIcon from "../../../../../../assets/images/v3/check-with-circle.svg";
import { TourialApiCalls } from "../../../../../helpers/tourial-api-calls";
import { ImportScreen } from "./import-tab";
var AddScreenModalTabs;
(function (AddScreenModalTabs) {
    AddScreenModalTabs["HTML_LBRARY"] = "HTML library";
    AddScreenModalTabs["IMPORT_SCREEN"] = "Import screen";
})(AddScreenModalTabs || (AddScreenModalTabs = {}));
const MODAL_TITLE = "Add screen";
export function LibraryModal({ setLibraryModalOpen }) {
    const draft = useContext(DraftContext);
    const library = useHTFetchCaptureLibrary();
    const steps = useHTSteps();
    const stepIndex = useHTStepIndex();
    const [selectedTab, setSelectedTab] = useState(AddScreenModalTabs.HTML_LBRARY);
    const [selected, setSelected] = useState();
    if (library.error) {
        console.error("error fetching dom capture library", library.error);
        return React.createElement("div", null, library.error);
    }
    if (!library.data) {
        return (React.createElement(TModal, { title: MODAL_TITLE, onClose: handleClose, tabs: [AddScreenModalTabs.HTML_LBRARY, AddScreenModalTabs.IMPORT_SCREEN] },
            React.createElement(TLoadingSpinner, null)));
    }
    const dedupedLibrary = uniqBy(library.data, "html");
    const mediaCards = dedupedLibrary === null || dedupedLibrary === void 0 ? void 0 : dedupedLibrary.map((captureData, i) => (React.createElement(MediaCard, { captureData: captureData, key: i, selected: (selected === null || selected === void 0 ? void 0 : selected.id) === captureData.id, setSelected: setSelected })));
    return (React.createElement(TModal, { title: MODAL_TITLE, icon: addScreenIcon, tabs: [AddScreenModalTabs.HTML_LBRARY, AddScreenModalTabs.IMPORT_SCREEN], onTabSelect: (tab) => setSelectedTab(tab), onClose: handleClose },
        React.createElement(ContentContainer, null,
            selectedTab === AddScreenModalTabs.HTML_LBRARY && (React.createElement(React.Fragment, null,
                React.createElement(ContentBody, null, mediaCards),
                React.createElement(Footer, null,
                    React.createElement(TButton, { onClick: handleAddStep, disabled: !selected }, "Add to tour")))),
            selectedTab === AddScreenModalTabs.IMPORT_SCREEN && React.createElement(ImportScreen, { onClose: handleClose }))));
    function handleClose() {
        setLibraryModalOpen(false);
    }
    function handleAddStep() {
        const step = createHTDefaultTooltip(selected.name, selected.id);
        if (!steps.length) {
            void draft.update({ width: selected.width, height: selected.height, steps: [step] });
        }
        else {
            const isAddingAfterOutro = steps[stepIndex].type === DTStepType.OUTRO;
            void draft.update({
                steps: [
                    ...steps.slice(0, stepIndex + (isAddingAfterOutro ? 0 : 1)),
                    step,
                    ...steps.slice(stepIndex + (isAddingAfterOutro ? 0 : 1)),
                ],
            });
        }
        handleClose();
    }
}
function saveName(name, prevName, id) {
    if (name && name !== prevName) {
        void TourialApiCalls.DomCapture.updateDomCapture(id, { name });
    }
}
const delaySaveName = debounce(saveName, 1000);
const MediaCard = ({ captureData, selected, setSelected }) => {
    const selectHandler = () => setSelected(selected ? null : captureData);
    const [currentName, setCurrentName] = useState(captureData.name);
    return (React.createElement(MediaCardContainer, { selected: selected },
        React.createElement(MediaContainer, null,
            React.createElement(Img, { src: captureData.thumbnail, onClick: selectHandler })),
        React.createElement(MediaCardFooter, null,
            React.createElement("div", { style: { maxWidth: 185, display: "flex", font: newTheme.fonts.small } },
                React.createElement(DynamicWidthInput, { value: currentName, onChange: (e) => {
                        setCurrentName(e.target.value);
                        delaySaveName(e.target.value, captureData.name, captureData.id);
                    }, style: {
                        textAlign: "left",
                        font: newTheme.fonts.small,
                        color: newTheme.colors.black,
                        maxWidth: 185,
                        display: "block",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                    } }))),
        selected && (React.createElement("img", { src: CheckCircleIcon, alt: "checkmark in circle", width: 24, style: { position: "absolute", top: 12, right: 12 } }))));
};
