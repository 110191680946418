import React, { useEffect, useRef, useState } from "react";
import { useActiveVariantName, useIsMobileVariantEnabled, useSetActiveVariant, useStoryboardHeight, useUpdateBuilder, useViewportPresetAsNumber, useViewportPresetShirtSize, } from "../../../redux/selectors/redux-selectors";
import { ActiveVariantName } from "../../../../../shared/types/tool-data-types/common";
import styled from "styled-components";
import { ViewportPresets, ViewportPresetSize } from "../../../types/definitions";
import { newTheme } from "../../styled/theme";
import ViewportIconBlue from "../../../../assets/images/v3/viewport-icon-blue.svg";
import ViewportIconGrey from "../../../../assets/images/v3/viewport-icon-grey.svg";
import InfoGrey from "../../../../assets/images/v3/info-grey.svg";
import { useOutsideClick } from "../../../hooks/use-outside-click";
export function VerticalFrameSelector({ viewportContainerDimensions }) {
    const variant = useActiveVariantName();
    const updateBuilder = useUpdateBuilder();
    const viewportPreset = useViewportPresetAsNumber();
    const viewportPresetShirtSize = useViewportPresetShirtSize();
    const isMobileResponsive = useIsMobileVariantEnabled();
    const storyBoardHeight = useStoryboardHeight();
    const setVariant = useSetActiveVariant();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef();
    const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
    const [doesViewportExist, setDoesViewportExist] = useState(false);
    useOutsideClick([dropdownRef], () => {
        if (isDropdownOpen) {
            setIsDropdownOpen(false);
        }
    });
    // set viewport preset size initially
    useEffect(() => {
        if (variant === ActiveVariantName.VARIANT_MOBILE) {
            updateBuilder({ viewportPresetShirtSize: ViewportPresetSize.MOBILE });
        }
        else {
            updateBuilder({ viewportPresetShirtSize: ViewportPresetSize.MEDIUM });
        }
    }, []);
    // hide button if viewport has not rendered (like for an empty tourial)
    useEffect(() => {
        const viewportEl = document.querySelector(".tourial-viewport");
        if (!viewportEl && doesViewportExist) {
            setDoesViewportExist(false);
        }
        else if (viewportEl && !doesViewportExist) {
            setDoesViewportExist(true);
        }
    });
    // move button to edge of viewport
    useEffect(() => {
        setTimeout(() => {
            const viewportEl = document.querySelector(".tourial-viewport");
            const buttonEl = document.querySelector("#frame-selector-button");
            const viewportRect = viewportEl === null || viewportEl === void 0 ? void 0 : viewportEl.getBoundingClientRect();
            const buttonRect = buttonEl === null || buttonEl === void 0 ? void 0 : buttonEl.getBoundingClientRect();
            if (!viewportRect || !buttonRect)
                return;
            const x = viewportRect.right;
            const y = viewportRect.top + viewportRect.height / 2 - buttonRect.height;
            setButtonPosition({ x, y });
        }, 0);
    }, [viewportContainerDimensions, storyBoardHeight, viewportPresetShirtSize, variant]);
    // switch to mobile/desktop frame when mobile variant is toggled
    useEffect(() => {
        if (variant === ActiveVariantName.VARIANT_MOBILE && isMobileResponsive) {
            if (viewportPresetShirtSize !== ViewportPresetSize.MOBILE) {
                updateBuilder({ viewportPresetShirtSize: ViewportPresetSize.MOBILE });
            }
        }
        else if (variant === ActiveVariantName.VARIANT_DESKTOP && isMobileResponsive) {
            if (viewportPresetShirtSize === ViewportPresetSize.MOBILE) {
                updateBuilder({ viewportPresetShirtSize: ViewportPresetSize.MEDIUM });
            }
        }
    }, [variant]);
    // downsize frame
    useEffect(() => {
        downsizeFrameAndUpdateMenu();
    }, [viewportContainerDimensions, storyBoardHeight, viewportPresetShirtSize]);
    const [dropdownOptions, setDropdownOptions] = useState([
        {
            label: "Large (1080 x 608px)",
            value: ViewportPresetSize.LARGE,
            chipLabel: "Large frame",
            isDisabled: false,
        },
        {
            label: "Medium (978 x 550px)",
            value: ViewportPresetSize.MEDIUM,
            chipLabel: "Medium frame",
            isDisabled: false,
        },
        {
            label: "Small (720 x 405px)",
            value: ViewportPresetSize.SMALL,
            chipLabel: "Small frame",
            isDisabled: false,
        },
        {
            label: "Mobile (<600)",
            value: ViewportPresetSize.MOBILE,
            chipLabel: "Mobile",
            isDisabled: false,
        },
    ]);
    const getLabelForSelectedValue = () => {
        const selected = dropdownOptions.find(option => option.value === viewportPresetShirtSize);
        return selected.chipLabel;
    };
    if (!doesViewportExist)
        return null;
    return (React.createElement(Div, { id: "frame-selector-button", position: buttonPosition, isDropdownOpen: isDropdownOpen },
        React.createElement(DropdownButton, { onClick: () => {
                setIsDropdownOpen(!isDropdownOpen);
            } },
            React.createElement("img", { src: isDropdownOpen ? ViewportIconBlue : ViewportIconGrey }),
            React.createElement(Label, null, getLabelForSelectedValue())),
        isDropdownOpen && (React.createElement(DropdownContainer, { ref: dropdownRef },
            React.createElement(Header, null, "Frame size previews how your tour may appear at different sizes:"),
            React.createElement(DropdownList, null, dropdownOptions.map((o, i) => (React.createElement(ListItem, { key: `options-${i}`, className: `${o.isDisabled ? "disabled" : ""} ${o.value === viewportPresetShirtSize ? "selected" : ""}`, onClick: () => {
                    if (o.isDisabled) {
                        return;
                    }
                    updateBuilder({ viewportPresetShirtSize: o.value });
                    // change variant based on dropdown selection
                    if (o.value === ViewportPresetSize.MOBILE &&
                        isMobileResponsive &&
                        variant !== ActiveVariantName.VARIANT_MOBILE) {
                        setVariant(ActiveVariantName.VARIANT_MOBILE);
                    }
                    else if (o.value !== ViewportPresetSize.MOBILE && variant === ActiveVariantName.VARIANT_MOBILE) {
                        setVariant(ActiveVariantName.VARIANT_DESKTOP);
                    }
                    setIsDropdownOpen(false);
                } }, o.label)))),
            React.createElement(Footer, null,
                React.createElement("img", { src: InfoGrey }),
                React.createElement("span", null,
                    "Read our",
                    " ",
                    React.createElement("a", { target: "_blank", rel: "noreferrer", href: "https://tourial.gitbook.io/tourial-customer-product-documentation/sharing/embeds" }, "best practices"),
                    "."))))));
    function downsizeFrameAndUpdateMenu() {
        let { height, width } = viewportContainerDimensions;
        if (!width && !height)
            return;
        // adjusted for padding
        height -= 40;
        width -= 96;
        // getMaxWidth
        const expand = height / width < 9 / 16 ? "height" : "width";
        const maxWidth = expand === "height" ? (16 * height) / 9 : width;
        // update available options
        setDropdownOptions(dropdownOptions.map(option => {
            return Object.assign(Object.assign({}, option), { isDisabled: ViewportPresets[option.value] > maxWidth });
        }));
        // Down sizing if necessary
        if (!isMobileResponsive || viewportPresetShirtSize !== ViewportPresetSize.MOBILE) {
            if (viewportPreset > maxWidth) {
                const nextAvailable = dropdownOptions.find(option => {
                    return option.value !== ViewportPresetSize.MOBILE && ViewportPresets[option.value] <= maxWidth;
                }) || dropdownOptions[2];
                updateBuilder({ viewportPresetShirtSize: nextAvailable.value });
            }
        }
    }
}
const Div = styled.div `
  cursor: pointer;
  position: absolute;
  left: ${({ position }) => position.x}px;
  top: ${({ position }) => position.y}px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 28px;
  padding-left: 8px;
  padding-right: 12px;
  border-radius: 4px 4px 0 0;
  background-color: ${({ isDropdownOpen }) => (isDropdownOpen ? newTheme.colors.grey100 : newTheme.colors.white)};
  box-shadow: ${({ isDropdownOpen }) => isDropdownOpen ? "0px 0px 2px rgba(48, 49, 54, 0.5)" : "inset 0px 0px 1px rgba(33, 34, 41, 0.4)}"};
  transform-origin: bottom left;
  transform: rotate(90deg);
  border: ${({ isDropdownOpen }) => isDropdownOpen ? `1px solid ${newTheme.colors.blue500}` : `1px solid ${newTheme.colors.white}`};
  animation: ${newTheme.animations.fadeIn} ${newTheme.transitions.layoutShift};
  &:hover {
    border: ${({ isDropdownOpen }) => isDropdownOpen ? `1px solid ${newTheme.colors.blue500}` : `1px solid ${newTheme.colors.grey300}`};
    background-color: ${newTheme.colors.grey100};
  }
`;
const DropdownButton = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  cursor: pointer;
  padding-top: 4px;
  gap: 4px;
  text-wrap: nowrap;
`;
const Label = styled.span `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey900};
`;
const DropdownContainer = styled.div `
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 0;
  width: 237px;
  background-color: ${newTheme.colors.white};
  box-shadow: ${newTheme.shadows.outside60};
  border: 1px solid ${newTheme.colors.grey200};
  transform: rotate(-90deg);
`;
const Header = styled.div `
  background-color: ${newTheme.colors.grey100};
  border-bottom: 1px solid ${newTheme.colors.grey200};
  padding: 8px;
  color: ${newTheme.colors.grey800};
  font: ${newTheme.fonts.small};
`;
const Footer = styled.div `
  display: flex;
  align-items: center;
  background-color: ${newTheme.colors.grey100};
  border-top: 1px solid ${newTheme.colors.grey200};
  padding: 8px;
  color: ${newTheme.colors.grey800};
  font: ${newTheme.fonts.small};
  img {
    margin-right: 6px;
  }
  a {
    color: ${newTheme.colors.grey800};
    text-decoration: underline;
  }
`;
const DropdownList = styled.ul `
  list-style: none;
`;
const ListItem = styled.li `
  padding: 8px;
  font: ${newTheme.fonts.bold};
  color: ${newTheme.colors.grey800};
  &.selected {
    color: ${newTheme.colors.white};
    background-color: ${newTheme.colors.blue500};
  }
  &.disabled {
    color: ${newTheme.colors.grey400};
    font: ${newTheme.fonts.normal};
  }
  &:not(.disabled, .selected) {
    &:hover {
      background-color: ${newTheme.colors.blue100};
      cursor: pointer;
    }
  }
`;
