import React, { useState } from "react";
import TH5 from "./t-h5";
import ExpandedIcon from "../../../../assets/images/chevron-down.svg";
import CollapsedIcon from "../../../../assets/images/chevron-up.svg";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
export function TAccordion({ children, title, isInitiallyOpen, alignItems = "center", hideBorder, iconLeft, leftGreyBar, }) {
    const [isOpen, setIsOpen] = useState(isInitiallyOpen);
    return (React.createElement("div", { style: {
            borderBottom: hideBorder ? 0 : `1px solid ${theme.colors.grey.dark}`,
        } },
        React.createElement("div", { onClick: () => setIsOpen(!isOpen), style: {
                display: "flex",
                justifyContent: alignItems,
                alignItems,
                cursor: "pointer",
            } },
            iconLeft && (React.createElement("img", { src: isOpen ? ExpandedIcon : CollapsedIcon, style: {
                    marginRight: 4,
                    color: theme.colors.cobalt.dark,
                    width: 14,
                    justifySelf: "space-between",
                } })),
            React.createElement(TH5, { style: {
                    textAlign: alignItems,
                    color: theme.colors.grey.darkest,
                    padding: 8,
                } }, title),
            !iconLeft && (React.createElement("img", { src: isOpen ? ExpandedIcon : CollapsedIcon, style: {
                    marginRight: 4,
                    color: theme.colors.cobalt.dark,
                    width: 14,
                    justifySelf: "space-between",
                } }))),
        isOpen && (React.createElement("div", { style: {
                borderLeft: leftGreyBar ? `4px solid ${theme.colors.grey.light}` : 0,
                paddingLeft: leftGreyBar ? 10 : 0,
            } }, children))));
}
