import React, { useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { DCIconType, DCThemeType } from "../../../../../../../shared/types/demo-center";
import { ColorPicker } from "../../../builder/editor/atomic/color-picker";
import { DCStylesDropdown } from "./dc-styles-dropdown";
import { useDCDefaultGradient, useDCHighlightColor, useDCThemeType, useDCUpdateGradient, useDCUpdateHighlightColor, } from "../../hooks/dc-hooks";
import { DCStylesGradientPicker } from "./dc-styles-gradient-picker";
export const DCStylesColorEditor = ({ customTheme, onChange }) => {
    const { textMain, textSecondary, neutralPrimary, neutralSecondary, neutralTranslucentColor } = customTheme;
    const [isChangingCustomColors, setIsChangingCustomColors] = useState(false);
    const updateHighlightColor = useDCUpdateHighlightColor();
    const highlightColor = useDCHighlightColor();
    const themeType = useDCThemeType();
    const gradient = useDCDefaultGradient();
    const updateGradient = useDCUpdateGradient();
    return (React.createElement(DCStylesDropdown, { label: "Custom colors", icon: DCIconType.COLOR_PALETTE, isCloseOnOutsideClickDisabled: isChangingCustomColors },
        React.createElement(DropdownContainer, null,
            React.createElement(CustomThemeColorContainer, null,
                React.createElement(Label, null, "Highlight"),
                React.createElement(ColorPicker, { color: highlightColor, onChange: updateHighlightColor, onOpened: () => setIsChangingCustomColors(true), onClosed: () => setIsChangingCustomColors(false) })),
            React.createElement(CustomThemeColorContainer, null,
                React.createElement(Label, null, "Button gradient"),
                React.createElement(DCStylesGradientPicker, { gradient: gradient, onChange: updateGradient, onOpen: () => setIsChangingCustomColors(true), onClose: () => setIsChangingCustomColors(false) })),
            themeType === DCThemeType.CUSTOM && (React.createElement(React.Fragment, null,
                " ",
                React.createElement(CustomThemeColorContainer, null,
                    React.createElement(Label, null, "Text main"),
                    React.createElement(ColorPicker, { color: textMain, onChange: color => onChange(color, "textMain"), onOpened: () => setIsChangingCustomColors(true), onClosed: () => setIsChangingCustomColors(false) })),
                React.createElement(CustomThemeColorContainer, null,
                    React.createElement(Label, null, "Text secondary"),
                    React.createElement(ColorPicker, { color: textSecondary, onChange: color => onChange(color, "textSecondary"), onOpened: () => setIsChangingCustomColors(true), onClosed: () => setIsChangingCustomColors(false) })),
                React.createElement(CustomThemeColorContainer, null,
                    React.createElement(Label, null, "Neutral primary"),
                    React.createElement(ColorPicker, { color: neutralPrimary, onChange: color => onChange(color, "neutralPrimary"), onOpened: () => setIsChangingCustomColors(true), onClosed: () => setIsChangingCustomColors(false) })),
                React.createElement(CustomThemeColorContainer, null,
                    React.createElement(Label, null, "Neutral secondary"),
                    React.createElement(ColorPicker, { color: neutralSecondary, onChange: color => onChange(color, "neutralSecondary"), onOpened: () => setIsChangingCustomColors(true), onClosed: () => setIsChangingCustomColors(false) })),
                React.createElement(CustomThemeColorContainer, null,
                    React.createElement(Label, null, "Neutral translucent"),
                    React.createElement(ColorPicker, { color: neutralTranslucentColor, onChange: color => onChange(color, "neutralTranslucentColor"), onOpened: () => setIsChangingCustomColors(true), onClosed: () => setIsChangingCustomColors(false) })))))));
};
const DropdownContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 250px;
`;
const CustomThemeColorContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
`;
const Label = styled.div `
  display: flex;
  flex: 1;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey700};
`;
