import { cloneDeep, isEqual, memoize, uniq } from "lodash";
import { arrayInsert, arrayInsertMulti, cloneSwap, deepCloneAllImportedPageTools, deepCloneAllPageTools, deepCloneAllViewTools, deleteViewTool, getCopyToolTriggerListeners, getDuplicatePageTriggerListeners, getGroupAnimationDetails, getToolsWithReplacedIds, getUniqueToolCopy, rebuildHiddenTools, rebuildTimers, rebuildTools, } from "../../helpers/misc";
import { deleteTool, getActiveVariant, getActiveVariantName, getPreviousActiveView, nudgeTool, } from "../../helpers/redux";
import { ActionType } from "../actions/redux-actions";
import { NavModal, ViewportMode, ViewportPresetSize, } from "../../types/definitions";
import { v4 as uuidv4 } from "uuid";
import { groupChildrenWithParent } from "../../hooks/use-edit-tool-z-index";
import { isImageFileExtension } from "../../helpers/media";
import { VisibilityOptions } from "../../legacy/components/builder/editor-tool-display-by-page";
import { TriggerDispatchEvent, TriggeredByAction, } from "../../../../shared/legacy/legacy-tool-data";
import { getDefaultVideoControls, getDefaultZoom, getTourialVariantTemplate, } from "../../../../shared/functions/tourial";
import { ActiveVariantName, ToolType } from "../../../../shared/types/tool-data-types/common";
import { pushHistory, redoHistory, undoHistory } from "../../helpers/tourial-helpers/tourial-history-helpers";
import { findZoom, legacyShouldTransition, shouldAutoStart, shouldTransition, } from "../../helpers/tourial-helpers/tourial-viewport-helpers";
import { makeStepFormInteractionKey } from "../selectors/builder-selectors";
import { wasFormSubmitted } from "../../helpers/form-helpers";
import { maxStoryboardMiniHeight } from "../../components/pages/builder/storyboard-mini/storyboard-mini-stub";
import { getBaseMedia, getBaseMediaIndex, getVideoDuration } from "../../helpers/video-trimming";
export function getInitialBuilderState() {
    return {
        isDeleteConfirmationModalOpen: false,
        multiselectToolIds: [],
        isDrawerOpen: false,
        isSettingsOpen: false,
        editingTextToolId: "",
        activeNavModal: NavModal.NULL,
        hiddenGroupId: "",
        isAnyToolDragging: false,
        copyBuffer: {},
        storyboardHeight: maxStoryboardMiniHeight,
        isPublishModalOpen: false,
        viewportPresetShirtSize: ViewportPresetSize.MEDIUM,
    };
}
export function getInitialTourialRootState(initialSavedTourial, initialViewportMode, liveModeAccount, shareLink) {
    const initialPageId = initialSavedTourial.variantDesktop.pages[0].id;
    const tourialView = {
        pageId: initialPageId,
        zoomId: "MAIN",
    };
    return {
        // Must cloneDeep in order to have different references from savedTouial.
        // Otherwise nested changes happen on both objects and break the SAVE button.
        tourial: cloneDeep(initialSavedTourial),
        liveModeAccount,
        savedTourial: initialSavedTourial,
        viewport: {
            activeView: tourialView,
            visibleToolIds: getDefaultVisibleToolIds(initialSavedTourial.variantDesktop.viewTools, tourialView, initialViewportMode || ViewportMode.EDIT),
            mode: initialViewportMode || ViewportMode.EDIT,
            isTransitioning: false,
            hiddenTools: {},
        },
        builder: getInitialBuilderState(),
        history: {
            past: [],
            future: [],
            pastIndex: -1,
            futureIndex: -1,
        },
        shareLink,
    };
}
const getGroupChildToolIds = (tools, groupToolId) => Object.keys(tools).filter(id => tools[id].groupToolId === groupToolId);
const getFittedGroupSizeAndPosition = (tools, groupToolId, oldSizeAndPosition) => {
    const groupChildToolIds = getGroupChildToolIds(tools, groupToolId);
    if (!groupChildToolIds.length)
        return oldSizeAndPosition;
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    groupChildToolIds.forEach(toolId => {
        const { sizeAndPosition: { x, y, width, height }, } = tools[toolId];
        minX = Math.min(x, minX);
        minY = Math.min(y, minY);
        maxX = Math.max(+width + x, maxX);
        maxY = Math.max(+height + y, maxY);
    });
    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
    };
};
const getViewportAfterListener = (prevViewport, listener, prevTourial, activeVariantName) => {
    var _a, _b, _c;
    switch (listener.dispatchEvent) {
        case TriggerDispatchEvent.CHANGE_ZOOM:
        case TriggerDispatchEvent.CHANGE_PAGE: {
            if (prevViewport.mode !== ViewportMode.EDIT) {
                const nextPage = prevTourial[activeVariantName].pages.find(p => p.id === listener.view.pageId);
                if (listener.dispatchEvent === TriggerDispatchEvent.CHANGE_ZOOM) {
                    const nextZoom = findZoom(prevTourial[activeVariantName], listener.view.pageId, listener.view.zoomId);
                    if (!nextZoom) {
                        return prevViewport;
                    }
                    if (nextPage.autoStartZoomId === prevViewport.activeView.zoomId && nextZoom.id === "MAIN") {
                        return prevViewport;
                    }
                }
                if (listener.dispatchEvent === TriggerDispatchEvent.CHANGE_PAGE) {
                    if (!nextPage) {
                        return prevViewport;
                    }
                    if (nextPage.id === prevViewport.activeView.pageId &&
                        nextPage.autoStartZoomId === prevViewport.activeView.zoomId) {
                        return prevViewport;
                    }
                }
            }
            return Object.assign(Object.assign({}, prevViewport), { previousView: prevViewport.activeView, activeView: listener.view, visibleToolIds: getDefaultVisibleToolIds(prevTourial[activeVariantName].viewTools, listener.view, prevViewport.mode), isTransitioning: legacyShouldTransition(prevTourial[activeVariantName], prevViewport.mode, prevViewport.activeView, listener.view) });
        }
        case TriggerDispatchEvent.NEXT_ZOOM: {
            if (prevViewport.mode !== ViewportMode.EDIT) {
                const { pageId: prevPageId, zoomId: prevZoomId } = prevViewport.activeView;
                const prevPages = prevTourial[activeVariantName].pages;
                let prevZoomIndex = -1;
                let prevPageIndex = -1;
                let nextActiveView;
                // find the next zoom
                prevPages
                    .find(p => {
                    prevPageIndex++;
                    return p.id === prevPageId;
                })
                    .zooms.find(z => {
                    prevZoomIndex++;
                    return z.id === prevZoomId;
                });
                if (prevZoomIndex < prevPages[prevPageIndex].zooms.length - 1) {
                    const nextZoomId = prevPages[prevPageIndex].zooms[prevZoomIndex + 1].id;
                    nextActiveView = {
                        pageId: prevPageId,
                        zoomId: nextZoomId,
                    };
                }
                // if it does not exist, find the next page MAIN
                else if (prevPageIndex < prevPages.length - 1) {
                    const nextPageId = prevPages[prevPageIndex + 1].id;
                    nextActiveView = {
                        pageId: nextPageId,
                        zoomId: "MAIN",
                    };
                }
                // if neither exist, return the prevViewport
                else
                    return prevViewport;
                return Object.assign(Object.assign({}, prevViewport), { previousView: prevViewport.activeView, activeView: nextActiveView, visibleToolIds: getDefaultVisibleToolIds(prevTourial[activeVariantName].viewTools, nextActiveView, prevViewport.mode), isTransitioning: legacyShouldTransition(prevTourial[activeVariantName], prevViewport.mode, prevViewport.activeView, nextActiveView) });
            }
            break;
        }
        case TriggerDispatchEvent.PREVIOUS_ZOOM: {
            if (prevViewport.mode !== ViewportMode.EDIT) {
                const previousActiveView = getPreviousActiveView(prevViewport, prevTourial, activeVariantName);
                // if either are falsy, return the old state
                if (!(previousActiveView === null || previousActiveView === void 0 ? void 0 : previousActiveView.pageId) || !(previousActiveView === null || previousActiveView === void 0 ? void 0 : previousActiveView.zoomId)) {
                    return prevViewport;
                }
                const { pageId: prevPageId, zoomId: prevZoomId } = prevViewport.activeView;
                const prevPages = (_a = prevTourial[activeVariantName]) === null || _a === void 0 ? void 0 : _a.pages;
                const isSamePage = (previousActiveView === null || previousActiveView === void 0 ? void 0 : previousActiveView.pageId) === prevPageId;
                const autoStartZoomId = prevPages[prevPages.findIndex(p => p.id === (previousActiveView === null || previousActiveView === void 0 ? void 0 : previousActiveView.pageId))].autoStartZoomId;
                // if an autostart points back to the old view, do nothing
                if (isSamePage && autoStartZoomId && autoStartZoomId === prevZoomId && previousActiveView.zoomId === "MAIN") {
                    return prevViewport;
                }
                return Object.assign(Object.assign({}, prevViewport), { previousView: prevViewport.activeView, activeView: previousActiveView, visibleToolIds: getDefaultVisibleToolIds(prevTourial[activeVariantName].viewTools, previousActiveView, prevViewport.mode), isTransitioning: legacyShouldTransition(prevTourial[activeVariantName], prevViewport.mode, prevViewport.activeView, previousActiveView) });
            }
            break;
        }
        case TriggerDispatchEvent.NEXT_PAGE: {
            const prevPageIndex = prevTourial[activeVariantName].pages.findIndex(p => p.id === prevViewport.activeView.pageId);
            const nextPageId = (_b = prevTourial[activeVariantName].pages[prevPageIndex + 1]) === null || _b === void 0 ? void 0 : _b.id;
            if (!nextPageId)
                return prevViewport;
            const nextPageActiveView = {
                pageId: nextPageId,
                zoomId: "MAIN",
            };
            return Object.assign(Object.assign({}, prevViewport), { activeView: nextPageActiveView, visibleToolIds: getDefaultVisibleToolIds(prevTourial[activeVariantName].viewTools, nextPageActiveView, prevViewport.mode), isTransitioning: legacyShouldTransition(prevTourial[activeVariantName], prevViewport.mode, prevViewport.activeView, nextPageActiveView) });
        }
        case TriggerDispatchEvent.DISPLAY_TOOL: {
            return Object.assign(Object.assign({}, prevViewport), { visibleToolIds: Object.assign(Object.assign({}, prevViewport.visibleToolIds), { [listener.displayToolId]: true }) });
        }
        case TriggerDispatchEvent.HIDE_TOOL: {
            return Object.assign(Object.assign({}, prevViewport), { visibleToolIds: Object.assign(Object.assign({}, prevViewport.visibleToolIds), { [listener.displayToolId]: false }) });
        }
        case TriggerDispatchEvent.TOGGLE_TOOL: {
            const visibleToolIds = Object.assign(Object.assign({}, prevViewport.visibleToolIds), { [listener.displayToolId]: !prevViewport.visibleToolIds[listener.displayToolId] });
            return Object.assign(Object.assign({}, prevViewport), { visibleToolIds });
        }
        case TriggerDispatchEvent.SUBMIT_FORM: {
            const groupId = (_c = prevTourial[activeVariantName].tools[listener.formId]) === null || _c === void 0 ? void 0 : _c.groupToolId;
            return Object.assign(Object.assign({}, prevViewport), { visibleToolIds: Object.assign(Object.assign({}, prevViewport.visibleToolIds), { [groupId]: false }) });
        }
        case TriggerDispatchEvent.EXTERNAL_LINK: {
            const { href } = listener;
            window.open(`https://${href}`, "_blank").focus();
            return prevViewport;
        }
        default: {
            return prevViewport;
        }
    }
};
function makeNewGlobalTools(pageId, zoomId, viewTools, tools) {
    const toolCount = viewTools.reduce((acc, vt) => {
        acc[vt.toolId] ? acc[vt.toolId]++ : (acc[vt.toolId] = 1);
        return acc;
    }, {});
    const newViewTools = [];
    Object.keys(toolCount).forEach(toolId => {
        var _a, _b;
        if (toolCount[toolId] > 1 ||
            ((_a = tools === null || tools === void 0 ? void 0 : tools[toolId]) === null || _a === void 0 ? void 0 : _a.type) === ToolType.FormV2 ||
            ((_b = tools === null || tools === void 0 ? void 0 : tools[toolId]) === null || _b === void 0 ? void 0 : _b.type) === ToolType.Breadcrumbs) {
            newViewTools.push({
                pageId,
                zoomId,
                toolId,
                visibleOnLoad: tools[toolId].type !== ToolType.FormV2,
            });
        }
    });
    return newViewTools;
}
export const getDefaultVisibleToolIds = (viewTools, view, viewportMode) => {
    const visibleToolIds = viewTools === null || viewTools === void 0 ? void 0 : viewTools.reduce((visibleToolIds, viewTool) => {
        visibleToolIds[viewTool.toolId] =
            visibleToolIds[viewTool.toolId] ||
                (viewTool.visibleOnLoad &&
                    viewTool.pageId === view.pageId &&
                    viewTool.zoomId === view.zoomId &&
                    // if id is a form and form is submitted, then filter out. this prevents it from showing on load if submitted in live mode
                    !(wasFormSubmitted(viewTool.toolId) ? viewportMode === ViewportMode.LIVE : false));
        return visibleToolIds;
    }, {});
    return visibleToolIds;
};
function getEmptyProviderState() {
    return {
        viewport: {
            activeView: { pageId: "null", zoomId: "null" },
            visibleToolIds: {},
            mode: ViewportMode.EDIT,
            isTransitioning: false,
            hiddenTools: {},
        },
        builder: {
            activeNavModal: NavModal.NULL,
            isSettingsOpen: false,
            multiselectToolIds: [],
            isDrawerOpen: false,
            editingTextToolId: "",
            hiddenGroupId: "",
            isDeleteConfirmationModalOpen: false,
            isAnyToolDragging: false,
            copyBuffer: {},
        },
        history: {
            past: [],
            future: [],
            pastIndex: -1,
            futureIndex: -1,
        },
    };
}
function isHoverHandled(listener, direction) {
    return (
    // handle all non hover actions
    listener.triggeredByAction !== TriggeredByAction.HOVER ||
        // handle hover enter
        (listener.triggeredByAction === TriggeredByAction.HOVER && direction === "HOVER_IN") ||
        // handle hover leave toggle event only
        (listener.triggeredByAction === TriggeredByAction.HOVER &&
            listener.dispatchEvent === TriggerDispatchEvent.TOGGLE_TOOL));
}
export function tourialRootReducer(prevState = getEmptyProviderState(), action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    switch (action.type) {
        case ActionType.CLEAR_TOURIAL_ROOT_STATE: {
            return getEmptyProviderState();
        }
        case ActionType.SET_INITIAL_TOURIAL_ROOT_STATE: {
            return Object.assign(Object.assign({}, action.initialProviderState), { viewport: Object.assign(Object.assign({}, action.initialProviderState.viewport), { pageForm: (_a = prevState.viewport) === null || _a === void 0 ? void 0 : _a.pageForm }) });
        }
        case ActionType.UNDO: {
            if (prevState.history.pastIndex < 0)
                return prevState;
            const nextState = Object.assign(Object.assign({}, prevState), { tourial: prevState.history.past[prevState.history.pastIndex].tourial, viewport: prevState.history.past[prevState.history.pastIndex].viewport, builder: prevState.builder, history: undoHistory(prevState) });
            return cloneDeep(nextState);
        }
        case ActionType.REDO: {
            if (prevState.history.futureIndex < 0)
                return prevState;
            const nextState = Object.assign(Object.assign({}, prevState), { tourial: prevState.history.future[prevState.history.futureIndex].tourial, viewport: prevState.history.future[prevState.history.futureIndex].viewport, builder: prevState.builder, history: redoHistory(prevState) });
            return cloneDeep(nextState);
        }
        case ActionType.REFRESH_VISIBLE_TOOL_IDS: {
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { visibleToolIds: getDefaultVisibleToolIds(prevState.tourial[getActiveVariantName(prevState)].viewTools, prevState.viewport.activeView, prevState.viewport.mode) }) });
        }
        case ActionType.LEGACY_CHANGE_VIEW: {
            const { activeView, triggeredFromAutostart } = action;
            if (activeView.pageId === prevState.viewport.activeView.pageId &&
                activeView.zoomId === prevState.viewport.activeView.zoomId) {
                // in v3, process change view to same view if triggered by autostart
                if (!(prevState.tourial.version >= 3 && triggeredFromAutostart))
                    return prevState;
            }
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { activeView, previousView: prevState.viewport.activeView, visibleToolIds: getDefaultVisibleToolIds(prevState.tourial[getActiveVariantName(prevState)].viewTools, action.activeView, prevState.viewport.mode), isTransitioning: legacyShouldTransition(prevState.tourial[getActiveVariantName(prevState)], prevState.viewport.mode, prevState.viewport.activeView, action.activeView, triggeredFromAutostart, prevState.tourial.version) }) });
        }
        case ActionType.PUSH_HISTORY: {
            const nextState = Object.assign(Object.assign({}, prevState), { history: pushHistory(prevState) });
            return cloneDeep(nextState);
        }
        case ActionType.ADD_TOOL: {
            const { tool } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const activeVariant = getActiveVariant(prevState);
            const isAutoTool = tool.type === ToolType.AutoMouse || tool.type === ToolType.AutoTooltip || tool.type === ToolType.AutoTypewriter;
            const activePageId = prevState.viewport.activeView.pageId;
            const activePage = prevState.tourial[activeVariantName].pages.find(p => p.id === activePageId);
            const isAutomatedAssigned = typeof activePage.isAutomated === "boolean";
            let pages = prevState.tourial[activeVariantName].pages;
            if (isAutoTool && !isAutomatedAssigned) {
                pages = cloneDeep(prevState.tourial[activeVariantName].pages);
                pages.find(p => p.id === activePageId).isAutomated = true;
            }
            const tourial = Object.assign({}, prevState.tourial);
            tourial[activeVariantName] = Object.assign(Object.assign({}, activeVariant), { pages, tools: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools), { [tool.id]: tool }), viewTools: [
                    ...prevState.tourial[activeVariantName].viewTools,
                    Object.assign({ toolId: tool.id, visibleOnLoad: true }, prevState.viewport.activeView),
                ] });
            return Object.assign(Object.assign({}, prevState), { tourial, viewport: Object.assign(Object.assign({}, prevState.viewport), { visibleToolIds: getDefaultVisibleToolIds(tourial[getActiveVariantName(prevState)].viewTools, prevState.viewport.activeView, prevState.viewport.mode) }) });
        }
        case ActionType.COPY_TOOL: {
            const activeVariantName = getActiveVariantName(prevState);
            const toolToCopy = prevState.tourial[activeVariantName].tools[prevState.builder.editDetailsId];
            if (!toolToCopy)
                return prevState;
            if (toolToCopy.type === ToolType.Breadcrumbs || toolToCopy.type === ToolType.Screen)
                return prevState;
            const originalIds = [toolToCopy.id];
            const uniqueBaseTool = getUniqueToolCopy(toolToCopy, true);
            let copyTools = [uniqueBaseTool];
            const newIdsToOldIds = {
                [uniqueBaseTool.id]: toolToCopy.id,
            };
            if (uniqueBaseTool.type === ToolType.Group) {
                const tools = cloneDeep(prevState.tourial[activeVariantName].tools);
                const viewTools = prevState.tourial[activeVariantName].viewTools;
                const childToolIds = getGroupChildToolIds(prevState.tourial[activeVariantName].tools, toolToCopy.id);
                originalIds.push(...childToolIds);
                const oldIdsToPositions = {};
                for (const oldId of [...childToolIds, toolToCopy.id]) {
                    oldIdsToPositions[oldId] = viewTools.findIndex(vt => vt.toolId === oldId);
                }
                childToolIds.forEach(oldChildToolId => {
                    const childTool = getUniqueToolCopy(tools[oldChildToolId]);
                    childTool.groupToolId = uniqueBaseTool.id;
                    copyTools.push(childTool);
                    newIdsToOldIds[childTool.id] = oldChildToolId;
                });
                copyTools = copyTools.sort((a, b) => {
                    const positionA = oldIdsToPositions[newIdsToOldIds[a.id]];
                    const positionB = oldIdsToPositions[newIdsToOldIds[b.id]];
                    return positionA < positionB ? -1 : 1;
                });
            }
            return Object.assign(Object.assign({}, prevState), { builder: Object.assign(Object.assign({}, prevState.builder), { copyTools, copyToolsOriginalIds: originalIds }) });
        }
        case ActionType.PASTE_TOOL: {
            const activeVariantName = getActiveVariantName(prevState);
            if (!((_c = (_b = prevState.builder) === null || _b === void 0 ? void 0 : _b.copyTools) === null || _c === void 0 ? void 0 : _c.length))
                return prevState;
            const tools = cloneDeep(prevState.tourial[activeVariantName].tools);
            const viewTools = cloneDeep(prevState.tourial[activeVariantName].viewTools);
            const copyTools = cloneDeep((_d = prevState.builder) === null || _d === void 0 ? void 0 : _d.copyTools);
            const pastedToolIds = [];
            const groupTool = (_e = prevState.builder) === null || _e === void 0 ? void 0 : _e.copyTools.find(t => t.type === ToolType.Group);
            let maxXOffset = Infinity, maxYOffset = Infinity;
            if (groupTool) {
                const { width, height, x, y } = groupTool.sizeAndPosition;
                maxXOffset = Math.max(100 - (x + width), 0);
                maxYOffset = Math.max(100 - (y + height), 0);
            }
            const pastedTools = copyTools.map(oldTool => {
                var _a;
                const t = getUniqueToolCopy(oldTool);
                const { width, height, x, y } = t.sizeAndPosition;
                t.groupToolId = (_a = oldTool.groupToolId) !== null && _a !== void 0 ? _a : null;
                // unshift the group tool id because it is the first index in copyToolsOriginalIds
                if (t.type === ToolType.Group) {
                    t.id = oldTool.id;
                    pastedToolIds.unshift(t.id);
                }
                else
                    pastedToolIds.push(t.id);
                // Offset new tool by 5%, unless the offset will take the tool off viewport
                // Or the parent group tool cannot be offset without going off the viewport
                const tWithOffset = Object.assign(Object.assign({}, t), { sizeAndPosition: Object.assign(Object.assign({}, t.sizeAndPosition), { 
                        // Don't go out of viewport
                        x: Math.min(100 - width, x + 5, x + maxXOffset), y: Math.min(100 - height, y + 5, y + maxYOffset) }) });
                return tWithOffset;
            });
            const triggerListeners = getCopyToolTriggerListeners(prevState.tourial[activeVariantName].triggerListeners, pastedToolIds, prevState.builder.copyToolsOriginalIds);
            pastedTools.forEach(pastedTool => {
                tools[pastedTool.id] = pastedTool;
            });
            pastedTools.forEach(pastedTool => {
                viewTools.push(Object.assign({ toolId: pastedTool.id, visibleOnLoad: true }, prevState.viewport.activeView));
            });
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { tools,
                        viewTools,
                        triggerListeners }) }), viewport: Object.assign(Object.assign({}, prevState.viewport), { visibleToolIds: getDefaultVisibleToolIds(viewTools, prevState.viewport.activeView, prevState.viewport.mode) }), builder: Object.assign(Object.assign({}, prevState.builder), { copyTools: getToolsWithReplacedIds(pastedTools) }) });
        }
        case ActionType.ADD_TOOL_GLOBALLY: {
            const activeVariantName = getActiveVariantName(prevState);
            const { tool, visibleOnLoad } = action;
            const allZoomIdsAndTheirPages = [];
            for (const p of prevState.tourial[activeVariantName].pages) {
                for (const z of p.zooms) {
                    allZoomIdsAndTheirPages.push({ zoomId: z.id, pageId: p.id });
                }
            }
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: {
                    tools: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools), { [tool.id]: tool }),
                    viewTools: [
                        ...prevState.tourial[activeVariantName].viewTools,
                        ...allZoomIdsAndTheirPages.map(ids => ({
                            toolId: tool.id,
                            visibleOnLoad,
                            zoomId: ids.zoomId,
                            pageId: ids.pageId,
                        })),
                    ],
                    triggerListeners: prevState.tourial[activeVariantName].triggerListeners,
                    pages: prevState.tourial[activeVariantName].pages,
                } });
            return Object.assign(Object.assign({}, prevState), { tourial, builder: Object.assign(Object.assign({}, prevState.builder), { editDetailsId: tool.id }), viewport: Object.assign(Object.assign({}, prevState.viewport), { visibleToolIds: getDefaultVisibleToolIds(tourial[getActiveVariantName(prevState)].viewTools, prevState.viewport.activeView, prevState.viewport.mode) }) });
        }
        case ActionType.AUTO_UPDATE_TOOL:
        case ActionType.UPDATE_TOOL: {
            const activeVariantName = getActiveVariantName(prevState);
            // TODO: Group should resize on any update tool
            const { tool } = action;
            const newTools = Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools), { [tool.id]: tool });
            if (tool.groupToolId) {
                const groupTool = prevState.tourial[activeVariantName].tools[tool.groupToolId];
                newTools[tool.groupToolId] = Object.assign(Object.assign({}, groupTool), { sizeAndPosition: getFittedGroupSizeAndPosition(newTools, tool.groupToolId, groupTool.sizeAndPosition) });
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { tools: Object.assign({}, newTools) }) }) });
        }
        case ActionType.ADD_TOOL_TO_GROUP: {
            const activeVariantName = getActiveVariantName(prevState);
            const { toolId, groupToolId } = action;
            const nextTools = Object.assign({}, prevState.tourial[activeVariantName].tools);
            nextTools[toolId].groupToolId = groupToolId;
            const animationDetails = getGroupAnimationDetails(nextTools, groupToolId);
            nextTools[toolId].animation = animationDetails.animation;
            nextTools[toolId].animationDuration = animationDetails.animationDuration;
            nextTools[groupToolId] = Object.assign(Object.assign({}, nextTools[groupToolId]), { sizeAndPosition: getFittedGroupSizeAndPosition(nextTools, groupToolId, nextTools[groupToolId].sizeAndPosition) });
            const nextTourial = Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { tools: Object.assign({}, nextTools) }) });
            const nextViewTools = groupChildrenWithParent(nextTourial[activeVariantName].viewTools, nextTourial, activeVariantName);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, nextTourial), { [activeVariantName]: Object.assign(Object.assign({}, nextTourial[activeVariantName]), { viewTools: nextViewTools }) }) });
        }
        case ActionType.ADD_PAGE: {
            const pageId = uuidv4();
            const activeVariantName = getActiveVariantName(prevState);
            const prevPage = prevState.viewport.activeView.pageId;
            const prevIndex = prevState.tourial[activeVariantName].pages.findIndex(page => page.id === prevPage);
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages: [
                        ...prevState.tourial[activeVariantName].pages.slice(0, prevIndex + 1),
                        {
                            id: pageId,
                            name: `Page ${prevState.tourial[activeVariantName].pages.length + 1}`,
                            zooms: [getDefaultZoom()],
                            autoStartZoomId: "",
                        },
                        ...(prevState.tourial[activeVariantName].pages.slice(prevIndex + 1) || []),
                    ], viewTools: [
                        ...prevState.tourial[activeVariantName].viewTools,
                        ...makeNewGlobalTools(pageId, "MAIN", prevState.tourial[activeVariantName].viewTools, prevState.tourial[activeVariantName].tools),
                    ] }) });
            return Object.assign(Object.assign({}, prevState), { tourial });
        }
        case ActionType.ADD_STARTER_SCREENS: {
            const activeVariantName = getActiveVariantName(prevState);
            const firstPageId = prevState.tourial[activeVariantName].pages[0].id;
            const { srcs } = action;
            const perPageVideoControls = Object.assign({}, prevState.tourial[activeVariantName].tools.screen.perPageVideoControls);
            const media = {};
            const pages = srcs.map((src, i) => {
                const pageId = !i ? firstPageId : uuidv4();
                media[pageId] = src;
                if (!isImageFileExtension(src)) {
                    perPageVideoControls[pageId] = getDefaultVideoControls(3);
                }
                return {
                    id: pageId,
                    name: `Page ${i + 1}`,
                    zooms: [getDefaultZoom(3)],
                    autoStartZoomId: "",
                };
            });
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages, tools: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools), { screen: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools.screen), { media,
                            perPageVideoControls }) }), viewTools: [] }) });
            return Object.assign(Object.assign({}, prevState), { tourial });
        }
        case ActionType.ADD_PAGE_WITH_SCREEN: {
            const pageId = uuidv4();
            const activeVariantName = getActiveVariantName(prevState);
            const prevPage = prevState.viewport.activeView.pageId;
            const prevIndex = prevState.tourial[activeVariantName].pages.findIndex(page => page.id === prevPage);
            const { src } = action;
            const perPageVideoControls = Object.assign({}, prevState.tourial[activeVariantName].tools.screen.perPageVideoControls);
            if (!isImageFileExtension(src)) {
                perPageVideoControls[pageId] = getDefaultVideoControls(3);
            }
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages: [
                        ...prevState.tourial[activeVariantName].pages.slice(0, prevIndex + 1),
                        {
                            id: pageId,
                            name: `Page ${prevState.tourial[activeVariantName].pages.length + 1}`,
                            zooms: [getDefaultZoom(3)],
                            autoStartZoomId: "",
                        },
                        ...(prevState.tourial[activeVariantName].pages.slice(prevIndex + 1) || []),
                    ], viewTools: [
                        ...prevState.tourial[activeVariantName].viewTools,
                        ...makeNewGlobalTools(pageId, "MAIN", prevState.tourial[activeVariantName].viewTools, prevState.tourial[activeVariantName].tools),
                    ], tools: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools), { screen: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools.screen), { media: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools.screen.media), { [`${pageId}`]: src }), perPageVideoControls }) }) }) });
            return Object.assign(Object.assign({}, prevState), { tourial });
        }
        case ActionType.DUPLICATE_PAGE: {
            const { pageId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            let newPageIndex = 0;
            const newPage = cloneDeep(prevState.tourial[activeVariantName].pages.find(p => {
                newPageIndex++;
                return p.id === pageId;
            }));
            newPage.id = uuidv4();
            newPage.name = `${newPage.name} copy`;
            const newBackground = cloneDeep(prevState.tourial[activeVariantName].tools.background.backgrounds[pageId]);
            const newPageVideoControls = cloneDeep(prevState.tourial[activeVariantName].tools.screen.perPageVideoControls[pageId]);
            const { tools, viewTools } = deepCloneAllPageTools(pageId, newPage.id, cloneDeep(prevState.tourial[activeVariantName].viewTools), cloneDeep(prevState.tourial[activeVariantName].tools));
            const pages = arrayInsert(cloneDeep(prevState.tourial[activeVariantName].pages), newPage, newPageIndex);
            const activeView = {
                pageId: newPage.id,
                zoomId: "MAIN",
            };
            const backgrounds = Object.assign({}, tools.background.backgrounds);
            if (newBackground) {
                backgrounds[newPage.id] = newBackground;
            }
            const perPageVideoControls = Object.assign({}, tools.screen.perPageVideoControls);
            if (newPageVideoControls) {
                perPageVideoControls[newPage.id] = newPageVideoControls;
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages,
                        viewTools, tools: Object.assign(Object.assign({}, tools), { background: Object.assign(Object.assign({}, tools.background), { backgrounds }), screen: Object.assign(Object.assign({}, tools.screen), { perPageVideoControls }) }) }) }), viewport: Object.assign(Object.assign({}, prevState.viewport), { activeView, hiddenTools: rebuildHiddenTools(prevState.viewport.hiddenTools, tools) }) });
        }
        case ActionType.LEGACY_DUPLICATE_PAGE: {
            const { pageId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            let newPageIndex = 0;
            const newPage = cloneDeep(prevState.tourial[activeVariantName].pages.find(p => {
                newPageIndex++;
                return p.id === pageId;
            }));
            newPage.id = uuidv4();
            newPage.name = `${newPage.name} copy`;
            const newBackground = cloneDeep(prevState.tourial[activeVariantName].tools.background.backgrounds[pageId]);
            const newPageVideoControls = cloneDeep(prevState.tourial[activeVariantName].tools.screen.perPageVideoControls[pageId]);
            const { tools, viewTools, oldToNewToolIds } = deepCloneAllPageTools(pageId, newPage.id, cloneDeep(prevState.tourial[activeVariantName].viewTools), cloneDeep(prevState.tourial[activeVariantName].tools));
            const duplicatePageTriggerListeners = getDuplicatePageTriggerListeners(oldToNewToolIds, cloneDeep(prevState.tourial[activeVariantName].triggerListeners), newPage.id);
            const pages = arrayInsert(cloneDeep(prevState.tourial[activeVariantName].pages), newPage, newPageIndex);
            const activeView = {
                pageId: newPage.id,
                zoomId: "MAIN",
            };
            const backgrounds = Object.assign({}, tools.background.backgrounds);
            if (newBackground) {
                backgrounds[newPage.id] = newBackground;
            }
            const perPageVideoControls = Object.assign({}, tools.screen.perPageVideoControls);
            if (newPageVideoControls) {
                perPageVideoControls[newPage.id] = newPageVideoControls;
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages,
                        viewTools, tools: Object.assign(Object.assign({}, tools), { background: Object.assign(Object.assign({}, tools.background), { backgrounds }), screen: Object.assign(Object.assign({}, tools.screen), { perPageVideoControls }) }), triggerListeners: [
                            ...prevState.tourial[activeVariantName].triggerListeners,
                            ...duplicatePageTriggerListeners,
                        ] }) }), viewport: Object.assign(Object.assign({}, prevState.viewport), { activeView, visibleToolIds: getDefaultVisibleToolIds(viewTools, activeView, prevState.viewport.mode) }) });
        }
        case ActionType.IMPORT_PAGE: {
            const activeVariantName = getActiveVariantName(prevState);
            const { addFromPageId, addToPageId, addFromTourial } = action;
            const newPageIndex = prevState.tourial[activeVariantName].pages.findIndex(p => p.id === addToPageId) + 1;
            const newPage = Object.assign({}, addFromTourial.pages.find(p => {
                return p.id === addFromPageId;
            }));
            const oldBackground = addFromTourial.tools.background.backgrounds[addFromPageId];
            const oldPageVideoControls = Object.assign({}, addFromTourial.tools.screen.perPageVideoControls[addFromPageId]);
            newPage.id = uuidv4();
            newPage.name = `${newPage.name} copy`;
            const { tools, viewTools, fromToNewToolIds } = deepCloneAllImportedPageTools(addFromPageId, newPage, cloneDeep(addFromTourial.viewTools), cloneDeep(addFromTourial.tools), cloneDeep(prevState.tourial[activeVariantName].viewTools), cloneDeep(prevState.tourial[activeVariantName].tools), prevState.tourial[activeVariantName].pages);
            const importedPageTriggerListeners = getDuplicatePageTriggerListeners(fromToNewToolIds, addFromTourial.triggerListeners, newPage.id, true);
            const pages = arrayInsert([...prevState.tourial[activeVariantName].pages], newPage, newPageIndex);
            // add screen to media library
            const newScreenUrl = addFromTourial.tools.screen.media[addFromPageId];
            let newScreen;
            if (newScreenUrl) {
                newScreen = {
                    src: newScreenUrl,
                    name: newPage.name,
                };
            }
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { media: newScreen ? [...prevState.tourial.media, newScreen] : prevState.tourial.media, [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages,
                    viewTools, tools: Object.assign(Object.assign({}, tools), { background: Object.assign(Object.assign({}, tools.background), { backgrounds: Object.assign(Object.assign({}, tools.background.backgrounds), { [newPage.id]: oldBackground }) }), screen: Object.assign(Object.assign({}, tools.screen), { perPageVideoControls: Object.assign(Object.assign({}, tools.screen.perPageVideoControls), { [newPage.id]: oldPageVideoControls }) }) }), triggerListeners: [...prevState.tourial[activeVariantName].triggerListeners, ...importedPageTriggerListeners] }) });
            const activeView = {
                pageId: newPage.id,
                zoomId: "MAIN",
            };
            return Object.assign(Object.assign({}, prevState), { tourial, viewport: Object.assign(Object.assign({}, prevState.viewport), { activeView, visibleToolIds: getDefaultVisibleToolIds(viewTools, activeView, prevState.viewport.mode) }) });
        }
        case ActionType.IMPORT_PAGES_V3: {
            const activeVariantName = getActiveVariantName(prevState);
            const prevTourial = prevState.tourial[activeVariantName];
            const prevMediaSrcs = prevState.tourial.media.map(m => m.src);
            const { addFromPageIds, addToPageId, addFromTourial, isEmptyTourial } = action;
            const addFromTourialVariant = addFromTourial[activeVariantName];
            const addFromMedia = addFromTourial.media;
            const getReplacementUUID = memoize(_oldUUID => (_oldUUID ? uuidv4() : null));
            const newPerPageVideoControlsToAdd = {};
            const newScreensToAdd = [];
            const newScreenToolMedia = {};
            const newViewToolsToAdd = [];
            const newToolsToAdd = {};
            const newPages = [];
            const newPageIndex = prevTourial.pages.findIndex(p => p.id === addToPageId) + 1;
            for (let i = 0; i < addFromPageIds.length; i++) {
                // add page
                const addFromPageId = addFromPageIds[i];
                const newPage = cloneDeep(addFromTourialVariant.pages.find(p => p.id === addFromPageId));
                newPage.id = isEmptyTourial && i === 0 ? prevTourial.pages[0].id : uuidv4();
                newPage.name = `${newPage.name} copy`;
                newPages.push(newPage);
                // add video controls
                newPerPageVideoControlsToAdd[newPage.id] = Object.assign({}, addFromTourialVariant.tools.screen.perPageVideoControls[addFromPageId]);
                // add screen to media library and screen tool
                // we have to get it from the media library, so it doesn't include any cloudinary trims in the url
                const newScreenUrl = addFromTourialVariant.tools.screen.media[addFromPageId];
                if (newScreenUrl) {
                    const baseMedia = getBaseMedia(addFromMedia, newScreenUrl);
                    // ensure no duplicates in media
                    if (!prevMediaSrcs.includes(baseMedia.src)) {
                        const screenToAdd = { src: baseMedia.src, name: newPage.name, size: baseMedia.size };
                        if (!isImageFileExtension(newScreenUrl)) {
                            screenToAdd.duration = getVideoDuration(addFromMedia, screenToAdd.src);
                        }
                        newScreensToAdd.push(screenToAdd);
                    }
                    newScreenToolMedia[newPage.id] = newScreenUrl;
                }
                newViewToolsToAdd.push(...addFromTourialVariant.viewTools.reduce((acc, vt) => {
                    if (vt.pageId === addFromPageId) {
                        const newId = getReplacementUUID(vt.toolId);
                        acc.push(Object.assign(Object.assign({}, vt), { pageId: newPage.id, toolId: newId }));
                        newToolsToAdd[newId] = Object.assign(Object.assign({}, addFromTourialVariant.tools[vt.toolId]), { id: newId });
                    }
                    return acc;
                }, []));
            }
            const pages = isEmptyTourial ? newPages : arrayInsertMulti([...prevTourial.pages], newPages, newPageIndex);
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { media: [...prevState.tourial.media, ...newScreensToAdd], [activeVariantName]: Object.assign(Object.assign({}, prevTourial), { pages, viewTools: [...prevTourial.viewTools, ...newViewToolsToAdd], tools: Object.assign(Object.assign(Object.assign({}, prevTourial.tools), { screen: Object.assign(Object.assign({}, prevTourial.tools.screen), { media: Object.assign(Object.assign({}, prevTourial.tools.screen.media), newScreenToolMedia), perPageVideoControls: Object.assign(Object.assign({}, prevTourial.tools.screen.perPageVideoControls), newPerPageVideoControlsToAdd) }) }), newToolsToAdd) }) });
            const activeView = {
                pageId: newPages[0].id,
                zoomId: "MAIN",
            };
            return Object.assign(Object.assign({}, prevState), { tourial, viewport: Object.assign(Object.assign({}, prevState.viewport), { activeView, visibleToolIds: getDefaultVisibleToolIds([...prevTourial.viewTools, ...newViewToolsToAdd], activeView, prevState.viewport.mode) }) });
        }
        case ActionType.DUPLICATE_VIEW: {
            const { pageId, zoomId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            let newZoomIndex = 0;
            let pageIndex = -1;
            const pageContainingZoom = prevState.tourial[activeVariantName].pages.find(p => {
                pageIndex++;
                return p.id === pageId;
            });
            const newZoom = Object.assign({}, pageContainingZoom.zooms.find(z => {
                newZoomIndex++;
                return z.id === zoomId;
            }));
            newZoom.id = uuidv4();
            newZoom.name = `${newZoom.name} copy`;
            const zooms = arrayInsert([...pageContainingZoom.zooms], newZoom, newZoomIndex);
            const pageWithUpdatedZooms = Object.assign(Object.assign({}, pageContainingZoom), { zooms });
            const pages = [...prevState.tourial[activeVariantName].pages];
            pages.splice(pageIndex, 1, pageWithUpdatedZooms);
            const { viewTools: oldViewTools, tools: oldTools } = prevState.tourial[activeVariantName];
            const { tools, viewTools } = deepCloneAllViewTools(zoomId, newZoom.id, pageId, oldViewTools, oldTools);
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages,
                    viewTools,
                    tools }) });
            const activeView = {
                pageId,
                zoomId: newZoom.id,
            };
            return Object.assign(Object.assign({}, prevState), { tourial, viewport: Object.assign(Object.assign({}, prevState.viewport), { activeView, hiddenTools: rebuildHiddenTools(prevState.viewport.hiddenTools, tools) }) });
        }
        case ActionType.LEGACY_DUPLICATE_VIEW: {
            const { pageId, zoomId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            let newZoomIndex = 0;
            let pageIndex = -1;
            const pageContainingZoom = prevState.tourial[activeVariantName].pages.find(p => {
                pageIndex++;
                return p.id === pageId;
            });
            const newZoom = Object.assign({}, pageContainingZoom.zooms.find(z => {
                newZoomIndex++;
                return z.id === zoomId;
            }));
            newZoom.id = uuidv4();
            newZoom.name = `${newZoom.name} copy`;
            const zooms = arrayInsert([...pageContainingZoom.zooms], newZoom, newZoomIndex);
            const pageWithUpdatedZooms = Object.assign(Object.assign({}, pageContainingZoom), { zooms });
            const pages = [...prevState.tourial[activeVariantName].pages];
            pages.splice(pageIndex, 1, pageWithUpdatedZooms);
            const { viewTools: oldViewTools, tools: oldTools } = prevState.tourial[activeVariantName];
            const { tools, viewTools, oldToNewToolIds } = deepCloneAllViewTools(zoomId, newZoom.id, pageId, oldViewTools, oldTools);
            const duplicateViewTriggerListeners = getDuplicatePageTriggerListeners(oldToNewToolIds, prevState.tourial[activeVariantName].triggerListeners, pageId);
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages,
                    viewTools,
                    tools, triggerListeners: [
                        ...prevState.tourial[activeVariantName].triggerListeners,
                        ...duplicateViewTriggerListeners,
                    ] }) });
            const activeView = {
                pageId,
                zoomId: newZoom.id,
            };
            return Object.assign(Object.assign({}, prevState), { tourial, viewport: Object.assign(Object.assign({}, prevState.viewport), { activeView, visibleToolIds: getDefaultVisibleToolIds(tourial[activeVariantName].viewTools, activeView, prevState.viewport.mode) }) });
        }
        case ActionType.LEGACY_DELETE_PAGE: {
            const activeVariantName = getActiveVariantName(prevState);
            const { pageId } = action;
            if (prevState.tourial[activeVariantName].pages.length <= 1)
                return prevState;
            let viewTools = cloneDeep(prevState.tourial[activeVariantName].viewTools);
            viewTools = viewTools.filter(vt => {
                return vt.pageId !== pageId;
            });
            const tools = cloneDeep(rebuildTools(prevState.tourial[activeVariantName].tools, viewTools));
            // remove screen media for deleted page
            (_f = tools.screen.media) === null || _f === void 0 ? true : delete _f[pageId];
            const timers = rebuildTimers(prevState.tourial[activeVariantName].timers, tools);
            const pages = prevState.tourial[activeVariantName].pages.filter(p => p.id !== pageId);
            const nextViewPageId = pages[pages.length - 1].id;
            const nextActiveView = prevState.viewport.activeView.pageId === pageId
                ? { pageId: nextViewPageId, zoomId: "MAIN" }
                : prevState.viewport.activeView;
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages,
                    tools,
                    viewTools,
                    timers }) });
            return Object.assign(Object.assign({}, prevState), { tourial, viewport: Object.assign(Object.assign({}, prevState.viewport), { activeView: nextActiveView, visibleToolIds: getDefaultVisibleToolIds(tourial[activeVariantName].viewTools, nextActiveView, prevState.viewport.mode) }) });
        }
        case ActionType.ADD_ZOOM: {
            const { page } = action;
            const newZoom = getDefaultZoom(prevState.tourial.version);
            const activeVariantName = getActiveVariantName(prevState);
            newZoom.name = prevState.tourial.version === 3 ? `Step ${page.zooms.length + 1}` : `View ${page.zooms.length}`;
            newZoom.id = uuidv4();
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages: [
                            ...prevState.tourial[activeVariantName].pages.map(p => p.id === page.id
                                ? Object.assign(Object.assign({}, p), { zooms: [...p.zooms, newZoom] }) : p),
                        ], viewTools: [
                            ...prevState.tourial[activeVariantName].viewTools,
                            ...makeNewGlobalTools(page.id, newZoom.id, prevState.tourial[activeVariantName].viewTools, prevState.tourial[activeVariantName].tools),
                        ] }) }), viewport: Object.assign(Object.assign({}, prevState.viewport), { activeView: {
                        pageId: prevState.viewport.activeView.pageId,
                        zoomId: newZoom.id,
                    } }), builder: Object.assign(Object.assign({}, prevState.builder), { editDetailsId: newZoom.id }) });
        }
        case ActionType.LEGACY_DELETE_ZOOM: {
            const activeVariantName = getActiveVariantName(prevState);
            const { zoomId } = action;
            const pages = cloneDeep(prevState.tourial[activeVariantName].pages);
            const activePageIndex = pages.findIndex(p => p.id === prevState.viewport.activeView.pageId);
            pages[activePageIndex].zooms = pages[activePageIndex].zooms.filter(z => z.id !== zoomId);
            let viewTools = cloneDeep(prevState.tourial[activeVariantName].viewTools);
            viewTools = viewTools.filter(vt => {
                if (vt.pageId === pages[activePageIndex].id) {
                    if (vt.zoomId === zoomId) {
                        return false;
                    }
                }
                return true;
            });
            const tools = rebuildTools(prevState.tourial[activeVariantName].tools, viewTools);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages,
                        viewTools,
                        tools }) }) });
        }
        case ActionType.ADD_MEDIA: {
            const { media } = action;
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { media: [...prevState.tourial.media, ...media] });
            return Object.assign(Object.assign({}, prevState), { tourial });
        }
        case ActionType.LEGACY_SET_SCREEN_IMAGE: {
            const activeVariantName = getActiveVariantName(prevState);
            const { src } = action;
            const perPageVideoControls = Object.assign({}, prevState.tourial[activeVariantName].tools.screen.perPageVideoControls);
            if (!isImageFileExtension(src)) {
                perPageVideoControls[prevState.viewport.activeView.pageId] = getDefaultVideoControls();
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: {
                        tools: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools), { screen: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools.screen), { media: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools.screen.media), { [`${prevState.viewport.activeView.pageId}`]: src }), perPageVideoControls }) }),
                        pages: prevState.tourial[activeVariantName].pages,
                        viewTools: prevState.tourial[activeVariantName].viewTools,
                        triggerListeners: prevState.tourial[activeVariantName].triggerListeners,
                    } }) });
        }
        case ActionType.SET_SCREEN_IMAGE_BY_ID: {
            const activeVariantName = getActiveVariantName(prevState);
            const { src, pageId } = action;
            const perPageVideoControls = Object.assign({}, prevState.tourial[activeVariantName].tools.screen.perPageVideoControls);
            if (!isImageFileExtension(src)) {
                perPageVideoControls[pageId] = getDefaultVideoControls(3);
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: {
                        tools: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools), { screen: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools.screen), { media: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools.screen.media), { [pageId]: src }), perPageVideoControls }) }),
                        pages: prevState.tourial[activeVariantName].pages,
                        viewTools: prevState.tourial[activeVariantName].viewTools,
                        triggerListeners: prevState.tourial[activeVariantName].triggerListeners,
                    } }) });
        }
        case ActionType.UPDATE_BUILDER: {
            const { newFields } = action;
            return Object.assign(Object.assign({}, prevState), { builder: Object.assign(Object.assign({}, prevState.builder), newFields) });
        }
        case ActionType.UPDATE_TOURIAL_PARTIAL: {
            // TODO: Make sure this recursively overrites
            const { newFields } = action;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), newFields) });
        }
        case ActionType.SET_TOURIAL_LOOP: {
            const { loop } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { loop }) }) });
        }
        case ActionType.ADD_LISTENER: {
            // TODO: this should be renamed to ADD_DISPLAY_TOOL_LISTENER
            const { toolId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { triggerListeners: [
                            ...prevState.tourial[activeVariantName].triggerListeners,
                            {
                                toolId,
                                listenerId: uuidv4(),
                                triggeredByAction: TriggeredByAction.CLICK,
                                dispatchEvent: TriggerDispatchEvent.DISPLAY_TOOL,
                                displayToolId: (_g = prevState.tourial[activeVariantName].viewTools[0]) === null || _g === void 0 ? void 0 : _g.toolId,
                            },
                        ] }) }) });
        }
        case ActionType.ADD_HOVER_LISTENER: {
            const { toolId } = action;
            const defaultTriggerListener = {
                toolId,
                listenerId: uuidv4(),
                triggeredByAction: TriggeredByAction.HOVER,
                dispatchEvent: TriggerDispatchEvent.TOGGLE_TOOL,
            };
            // group buttons default to 'Next View'
            const toolData = prevState.tourial.variantDesktop.tools[toolId];
            const hasGroupToolId = !!toolData.groupToolId;
            const isGroupButton = toolData.type === ToolType.Button;
            if (hasGroupToolId && isGroupButton) {
                defaultTriggerListener.dispatchEvent = TriggerDispatchEvent.NEXT_ZOOM;
            }
            else {
                defaultTriggerListener.displayToolId = (_h = prevState.tourial.variantDesktop.viewTools[0]) === null || _h === void 0 ? void 0 : _h.toolId;
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { variantDesktop: Object.assign(Object.assign({}, prevState.tourial.variantDesktop), { triggerListeners: [...prevState.tourial.variantDesktop.triggerListeners, defaultTriggerListener] }) }) });
        }
        case ActionType.ADD_CLICK_LISTENER: {
            const { toolId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const defaultTriggerListener = {
                toolId,
                listenerId: uuidv4(),
                triggeredByAction: TriggeredByAction.CLICK,
                dispatchEvent: TriggerDispatchEvent.DISPLAY_TOOL,
                displayToolId: (_j = prevState.tourial[activeVariantName].viewTools[0]) === null || _j === void 0 ? void 0 : _j.toolId,
            };
            // group buttons default to 'Next View'
            const toolData = prevState.tourial[activeVariantName].tools[toolId];
            const hasGroupToolId = !!toolData.groupToolId;
            const isGroupButton = toolData.type === ToolType.Button;
            if (hasGroupToolId && isGroupButton) {
                delete defaultTriggerListener.displayToolId;
                defaultTriggerListener.dispatchEvent = TriggerDispatchEvent.NEXT_ZOOM;
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { triggerListeners: [...prevState.tourial[activeVariantName].triggerListeners, defaultTriggerListener] }) }) });
        }
        case ActionType.ADD_SUBMIT_FORM_ONCLICK_LISTENER: {
            const { triggerToolId, formInputsToolId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { triggerListeners: [
                            ...prevState.tourial[activeVariantName].triggerListeners,
                            {
                                toolId: triggerToolId,
                                listenerId: uuidv4(),
                                triggeredByAction: TriggeredByAction.CLICK,
                                dispatchEvent: TriggerDispatchEvent.SUBMIT_FORM,
                                formId: formInputsToolId,
                            },
                        ] }) }) });
        }
        case ActionType.ADD_CLICKZONE_AND_BUTTON_DEFAULT_TRIGGER_LISTENER: {
            const { triggerToolId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { triggerListeners: [
                            ...prevState.tourial[activeVariantName].triggerListeners,
                            {
                                toolId: triggerToolId,
                                listenerId: uuidv4(),
                                triggeredByAction: TriggeredByAction.CLICK,
                                dispatchEvent: TriggerDispatchEvent.NEXT_PAGE,
                            },
                        ] }) }) });
        }
        case ActionType.ADD_TOOLTIP_BUTTON_DEFAULT_TRIGGER_LISTENER: {
            const { triggerToolId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { triggerListeners: [
                            ...prevState.tourial[activeVariantName].triggerListeners,
                            {
                                toolId: triggerToolId,
                                listenerId: uuidv4(),
                                triggeredByAction: TriggeredByAction.CLICK,
                                dispatchEvent: TriggerDispatchEvent.NEXT_ZOOM,
                            },
                        ] }) }) });
        }
        case ActionType.UPDATE_LISTENER: {
            const { updatedListener } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { triggerListeners: prevState.tourial[activeVariantName].triggerListeners.map(l => l.listenerId === updatedListener.listenerId ? updatedListener : l) }) }) });
        }
        case ActionType.DELETE_LISTENER: {
            const { listenerId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { triggerListeners: prevState.tourial[activeVariantName].triggerListeners.filter(l => l.listenerId !== listenerId) }) }) });
        }
        case ActionType.ADD_VIEW_TOOL: {
            const { viewTool } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { viewTools: [...prevState.tourial[activeVariantName].viewTools, viewTool] }) }) });
        }
        case ActionType.ADD_VIEW_TOOL_BY_TOOL_ID: {
            const activeVariantName = getActiveVariantName(prevState);
            const { pageId, zoomId, toolId } = action;
            const viewTools = [];
            const tools = (_k = prevState.tourial[activeVariantName]) === null || _k === void 0 ? void 0 : _k.tools;
            // Get group childTools (if applicable) in correct order
            (_m = (_l = prevState.tourial[activeVariantName]) === null || _l === void 0 ? void 0 : _l.viewTools) === null || _m === void 0 ? void 0 : _m.forEach(oldViewTool => {
                if (tools[oldViewTool.toolId].groupToolId === toolId) {
                    const newViewTool = {
                        pageId,
                        zoomId,
                        toolId: oldViewTool.toolId,
                        visibleOnLoad: true,
                    };
                    const alreadyExists = viewTools.find(vt => isEqual(vt, newViewTool));
                    if (!alreadyExists) {
                        viewTools.push(newViewTool);
                    }
                }
            });
            // Primary or group viewtool
            viewTools.push({
                pageId,
                zoomId,
                toolId,
                visibleOnLoad: true,
            });
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { viewTools: [...prevState.tourial[activeVariantName].viewTools, ...viewTools] }) }) });
        }
        case ActionType.DELETE_VIEW_TOOL_BY_TOOL_ID: {
            const activeVariantName = getActiveVariantName(prevState);
            const { pageId, zoomId, toolId } = action;
            const tools = (_o = prevState.tourial[activeVariantName]) === null || _o === void 0 ? void 0 : _o.tools;
            const newViewTools = prevState.tourial[activeVariantName].viewTools.filter(vt => {
                var _a;
                return !(vt.pageId === pageId &&
                    vt.zoomId === zoomId &&
                    (vt.toolId === toolId || ((_a = tools[vt.toolId]) === null || _a === void 0 ? void 0 : _a.groupToolId) === toolId));
            });
            const rebuiltTools = rebuildTools(tools, newViewTools);
            const rebuiltTimers = rebuildTimers(prevState.tourial[activeVariantName].timers, rebuiltTools);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { viewTools: newViewTools, tools: rebuiltTools, timers: rebuiltTimers }) }) });
        }
        case ActionType.EDIT_VIEW_TOOL_BY_TOOL_ID: {
            const activeVariantName = getActiveVariantName(prevState);
            const { pageId, zoomId, toolId, visibilityOption } = action;
            const visibleOnLoad = visibilityOption === VisibilityOptions.Visible;
            const tools = (_p = prevState.tourial[activeVariantName]) === null || _p === void 0 ? void 0 : _p.tools;
            let nextViewTools = [];
            if (visibilityOption === VisibilityOptions.Removed) {
                nextViewTools = prevState.tourial[activeVariantName].viewTools.filter(vt => {
                    var _a;
                    return !(vt.pageId === pageId &&
                        vt.zoomId === zoomId &&
                        (vt.toolId === toolId || ((_a = tools[vt.toolId]) === null || _a === void 0 ? void 0 : _a.groupToolId) === toolId));
                });
            }
            else {
                // Adding or editing existing viewTools
                const viewToolAlreadyExists = !!prevState.tourial[activeVariantName].viewTools.find(vt => vt.pageId === pageId && vt.zoomId === zoomId && vt.toolId === toolId);
                if (viewToolAlreadyExists) {
                    // Edit existing viewTools
                    nextViewTools = prevState.tourial[activeVariantName].viewTools.map(vt => {
                        if (vt.pageId === pageId && vt.zoomId === zoomId && vt.toolId === toolId) {
                            return Object.assign(Object.assign({}, vt), { visibleOnLoad: visibilityOption === VisibilityOptions.Visible });
                        }
                        return vt;
                    });
                }
                else {
                    // Add new viewTools
                    const additionalViewTools = [];
                    // Handle group tools
                    (_r = (_q = prevState.tourial[activeVariantName]) === null || _q === void 0 ? void 0 : _q.viewTools) === null || _r === void 0 ? void 0 : _r.forEach(oldViewTool => {
                        if (tools[oldViewTool.toolId].groupToolId === toolId) {
                            const newViewTool = {
                                pageId,
                                zoomId,
                                toolId: oldViewTool.toolId,
                                visibleOnLoad,
                            };
                            const alreadyExists = additionalViewTools.find(vt => isEqual(vt, newViewTool));
                            if (!alreadyExists) {
                                additionalViewTools.push(newViewTool);
                            }
                        }
                    });
                    // Primary or group viewtool
                    additionalViewTools.push({
                        pageId,
                        zoomId,
                        toolId,
                        visibleOnLoad,
                    });
                    nextViewTools = [...prevState.tourial[activeVariantName].viewTools, ...additionalViewTools];
                }
            }
            const rebuiltTools = rebuildTools(tools, nextViewTools);
            const rebuiltTimers = rebuildTimers(prevState.tourial[activeVariantName].timers, rebuiltTools);
            const newTourial = Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { viewTools: nextViewTools, tools: rebuiltTools, timers: rebuiltTimers }) });
            return Object.assign(Object.assign({}, prevState), { tourial: newTourial, viewport: Object.assign(Object.assign({}, prevState.viewport), { visibleToolIds: getDefaultVisibleToolIds(newTourial[getActiveVariantName(prevState)].viewTools, prevState.viewport.activeView, prevState.viewport.mode) }) });
        }
        case ActionType.DELETE_VIEW_TOOL: {
            const { viewTool, hasConfirmation } = action;
            const needsConfirmation = function () {
                var _a;
                const activeVariantName = getActiveVariantName(prevState);
                const activeVariantTools = (_a = prevState.tourial[activeVariantName]) === null || _a === void 0 ? void 0 : _a.tools;
                const tool = activeVariantTools[viewTool.toolId];
                return tool.type === ToolType.FormV2;
            };
            if (needsConfirmation() && !hasConfirmation) {
                prevState.builder.isDeleteConfirmationModalOpen = true;
                return prevState;
            }
            return deleteViewTool(prevState, viewTool);
        }
        case ActionType.TOGGLE_SHOW_ON_MULTIPLE_VIEWS: {
            const { toolId, isGlobal } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const { activeView } = prevState.viewport;
            const tools = (_s = prevState.tourial[activeVariantName]) === null || _s === void 0 ? void 0 : _s.tools;
            const tool = tools[toolId];
            let prevViewTools = prevState.tourial[activeVariantName].viewTools;
            const newViewTools = [];
            const groupId = tools[toolId].type === "GROUP" ? toolId : null;
            const groupTools = groupId
                ? Object.keys(tools)
                    .filter(tid => tools[tid].groupToolId === groupId && tid !== toolId)
                    .map(tid => tools[tid])
                : [];
            if (isGlobal) {
                // TODO: better name for this? This determines if it was toggled on or off.
                const existingViewTools = prevViewTools.filter(vt => vt.toolId === toolId);
                prevState.tourial[activeVariantName].pages.forEach(page => {
                    page.zooms.forEach(zoom => {
                        if (existingViewTools.filter(vt => vt.pageId === page.id && vt.zoomId === zoom.id).length === 0) {
                            groupTools.forEach(gt => newViewTools.push({
                                pageId: page.id,
                                zoomId: zoom.id,
                                toolId: gt.id,
                                visibleOnLoad: tool.type !== ToolType.FormV2,
                            }));
                            newViewTools.push({
                                pageId: page.id,
                                zoomId: zoom.id,
                                toolId: toolId,
                                visibleOnLoad: tool.type !== ToolType.FormV2,
                            });
                        }
                    });
                });
            }
            else {
                if (!groupId) {
                    prevViewTools = prevViewTools.filter(vt => vt.toolId !== toolId || (vt.pageId === activeView.pageId && vt.zoomId === activeView.zoomId));
                }
                else {
                    prevViewTools = prevViewTools.filter(vt => (vt.toolId !== toolId && tools[vt.toolId].groupToolId !== groupId) ||
                        (vt.pageId === activeView.pageId && vt.zoomId === activeView.zoomId));
                }
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { viewTools: [...prevViewTools, ...newViewTools] }) }) });
        }
        case ActionType.DELETE_CURRENT_EDIT_DETAILS_VIEW_TOOL: {
            const { hasConfirmation } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const currentlySelectedTool = prevState.tourial[activeVariantName].tools[prevState.builder.editDetailsId];
            if (!currentlySelectedTool)
                return prevState;
            const needsConfirmation = function () {
                return currentlySelectedTool.type == ToolType.FormV2;
            };
            if (needsConfirmation() && !hasConfirmation) {
                prevState.builder.isDeleteConfirmationModalOpen = true;
                return prevState;
            }
            const activePageId = prevState.viewport.activeView.pageId;
            const activeZoomId = prevState.viewport.activeView.zoomId;
            const currentlySelectedViewTool = prevState.tourial[activeVariantName].viewTools.find(vt => vt.toolId === currentlySelectedTool.id && vt.pageId === activePageId && vt.zoomId === activeZoomId);
            return deleteViewTool(prevState, currentlySelectedViewTool);
        }
        case ActionType.DELETE_CURRENT_VIEW_TOOL_TOOL: {
            const activeVariantName = getActiveVariantName(prevState);
            // TODO: Delete entire tool if last viewTool instance
            const { toolId } = action;
            const toolToDelete = prevState.tourial[activeVariantName].tools[toolId];
            if (!toolToDelete)
                return prevState;
            const activePageId = prevState.viewport.activeView.pageId;
            const activeZoomId = prevState.viewport.activeView.zoomId;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: {
                        viewTools: prevState.tourial[activeVariantName].viewTools.filter(vt => !(vt.toolId === toolId && vt.pageId === activePageId && vt.zoomId === activeZoomId)),
                        tools: toolToDelete.type === ToolType.Group
                            ? // Must remove groupToolIds if deleting a groupTool
                                Object.keys(prevState.tourial[activeVariantName].tools).reduce((tools, prevToolId) => {
                                    const tool = prevState.tourial[activeVariantName].tools[prevToolId];
                                    if (tool.groupToolId === toolId)
                                        delete tool.groupToolId;
                                    tools[prevToolId] = tool;
                                    return tools;
                                }, {
                                    screen: prevState.tourial[activeVariantName].tools.screen,
                                    background: prevState.tourial[activeVariantName].tools.background,
                                })
                            : prevState.tourial[activeVariantName].tools,
                        triggerListeners: prevState.tourial[activeVariantName].triggerListeners,
                        pages: prevState.tourial[activeVariantName].pages,
                    } }), viewport: Object.assign(Object.assign({}, prevState.viewport), { visibleToolIds: Object.assign(Object.assign({}, prevState.viewport.visibleToolIds), { [toolId]: false }) }) });
        }
        case ActionType.SET_DEFAULT_FONT_FAMILY: {
            const { fontFamily } = action;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { defaultFontFamily: fontFamily }) });
        }
        case ActionType.TOGGLE_TOOL_SHOW_ON_LOAD: {
            const { toolId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: {
                    viewTools: prevState.tourial[activeVariantName].viewTools.map(viewTool => {
                        if (viewTool.toolId === toolId &&
                            viewTool.pageId === prevState.viewport.activeView.pageId &&
                            viewTool.zoomId === prevState.viewport.activeView.zoomId) {
                            return Object.assign(Object.assign({}, viewTool), { visibleOnLoad: !viewTool.visibleOnLoad });
                        }
                        return viewTool;
                    }),
                    pages: prevState.tourial[activeVariantName].pages,
                    tools: prevState.tourial[activeVariantName].tools,
                    triggerListeners: prevState.tourial[activeVariantName].triggerListeners,
                } });
            return Object.assign(Object.assign({}, prevState), { tourial, viewport: Object.assign(Object.assign({}, prevState.viewport), { visibleToolIds: getDefaultVisibleToolIds(tourial[getActiveVariantName(prevState)].viewTools, prevState.viewport.activeView, prevState.viewport.mode) }) });
        }
        case ActionType.TOOL_ON_DISPATCH_EVENT: {
            const activeVariantName = getActiveVariantName(prevState);
            const listenersToHandle = getListenersToHandle(action, (_t = prevState.tourial[activeVariantName]) === null || _t === void 0 ? void 0 : _t.triggerListeners);
            const viewport = listenersToHandle.reduce((currentViewport, listener) => getViewportAfterListener(currentViewport, listener, prevState.tourial, activeVariantName), prevState.viewport);
            return Object.assign(Object.assign({}, prevState), { viewport });
        }
        case ActionType.UPDATE_GROUP_SIZE_AND_POSITION: {
            const activeVariantName = getActiveVariantName(prevState);
            const { groupId, updateDeltaDetails } = action;
            const oldGroupSizeAndPosition = prevState.tourial[activeVariantName].tools[groupId].sizeAndPosition;
            const nextTools = cloneDeep(prevState.tourial[activeVariantName].tools);
            const { draggableData, viewportDimensions } = updateDeltaDetails;
            const childToolIds = getGroupChildToolIds(prevState.tourial[activeVariantName].tools, groupId);
            if (draggableData) {
                const { x, y } = draggableData;
                const deltaXPercent = oldGroupSizeAndPosition.x - (x / viewportDimensions.width) * 100;
                const deltaYPercent = oldGroupSizeAndPosition.y - (y / viewportDimensions.height) * 100;
                childToolIds.forEach(childToolId => {
                    const newChildTool = cloneDeep(prevState.tourial[activeVariantName].tools[childToolId]);
                    const oldChildToolSizeAndPosition = newChildTool.sizeAndPosition;
                    newChildTool.sizeAndPosition.x = oldChildToolSizeAndPosition.x - deltaXPercent;
                    newChildTool.sizeAndPosition.y = oldChildToolSizeAndPosition.y - deltaYPercent;
                    nextTools[childToolId] = newChildTool;
                });
                nextTools[groupId].sizeAndPosition = getFittedGroupSizeAndPosition(nextTools, groupId, nextTools[groupId].sizeAndPosition);
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { tools: nextTools }) }) });
            // if (resizableDelta) {
            // TODO: In future handle resizing of group
            // e.g. If you resize tooltip it keeps button in lower right
            // }
        }
        case ActionType.UPDATE_TOOL_SIZE_AND_POSITION: {
            const { toolId, sizeAndPosition } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const { groupToolId } = prevState.tourial[activeVariantName].tools[toolId];
            const nextTools = Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools), { [toolId]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools[toolId]), { sizeAndPosition }) });
            if (groupToolId) {
                const nextGroupSizeAndPosition = getFittedGroupSizeAndPosition(nextTools, groupToolId, nextTools[groupToolId].sizeAndPosition);
                nextTools[groupToolId] = Object.assign(Object.assign({}, nextTools[groupToolId]), { sizeAndPosition: nextGroupSizeAndPosition });
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { tools: nextTools }) }) });
        }
        case ActionType.UPDATE_TOOL_MEDIA_SOURCE: {
            const activeVariantName = getActiveVariantName(prevState);
            const { tool, src, isLoading } = action;
            const newTool = cloneDeep(prevState.tourial[activeVariantName].tools[tool.id]);
            newTool.src = src.srcString;
            newTool.srcDimensions.width = src.srcWidth;
            newTool.srcDimensions.height = src.srcHeight;
            newTool.isLoading = isLoading;
            const tools = Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools), { [tool.id]: newTool });
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { tools }) }) });
        }
        case ActionType.UPDATE_VIEWPORT_MODE: {
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { mode: action.mode, visibleToolIds: getDefaultVisibleToolIds(prevState.tourial[getActiveVariantName(prevState)].viewTools, prevState.viewport.activeView, prevState.viewport.mode) }), builder: action.mode === ViewportMode.EDIT
                    ? prevState.builder
                    : Object.assign(Object.assign({}, prevState.builder), { isDrawerOpen: prevState.builder.isDrawerOpen }) });
        }
        case ActionType.SET_TOOL_IS_MULTISELECTED: {
            let multiselectToolIds = [...prevState.builder.multiselectToolIds];
            const { toolIds, isSelected } = action;
            if (isSelected) {
                multiselectToolIds = uniq([...multiselectToolIds, ...toolIds]);
            }
            else if (toolIds.includes("CLEAR_MULTISELECT")) {
                multiselectToolIds = [];
            }
            else {
                multiselectToolIds = multiselectToolIds.filter(toolId => !toolIds.includes(toolId));
            }
            return Object.assign(Object.assign({}, prevState), { builder: Object.assign(Object.assign({}, prevState.builder), { multiselectToolIds }) });
        }
        case ActionType.UPDATE_VIEWPORT: {
            const { patch } = action;
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), patch) });
        }
        case ActionType.UPDATE_PAGE: {
            const { pageId, patch } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages: prevState.tourial[activeVariantName].pages.map(p => {
                            if (p.id === pageId) {
                                return Object.assign(Object.assign({}, p), patch);
                            }
                            return p;
                        }) }) }) });
        }
        case ActionType.UPDATE_PAGES: {
            const { pages } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages }) }), viewport: Object.assign(Object.assign({}, prevState.viewport), { activeView: {
                        pageId: pages[0].id,
                        zoomId: "MAIN",
                    } }) });
        }
        case ActionType.REORDER_PAGE: {
            const { direction, pageIndex } = action;
            const activeVariantName = getActiveVariantName(prevState);
            if ((direction === "down" && pageIndex === prevState.tourial[activeVariantName].pages.length - 1) ||
                (direction === "up" && pageIndex === 0)) {
                return prevState;
            }
            const pages = [
                ...cloneSwap(prevState.tourial[activeVariantName].pages, pageIndex, direction === "down" ? pageIndex + 1 : pageIndex - 1),
            ];
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages }) }) });
        }
        case ActionType.UPDATE_PAGE_INDEX: {
            const { pageIndex, updatedPageIndex } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const pagesCopy = cloneDeep(prevState.tourial[activeVariantName].pages);
            const page = pagesCopy.splice(pageIndex, 1)[0];
            const updatedPages = arrayInsert(pagesCopy, page, updatedPageIndex);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages: updatedPages }) }) });
        }
        case ActionType.REORDER_ZOOM: {
            const { pageIndex, zoomIndex, updatedZoomIndex } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const currPage = prevState.tourial[activeVariantName].pages[pageIndex];
            const zoomsCopy = [...currPage.zooms];
            const zoom = zoomsCopy.splice(zoomIndex, 1)[0];
            const updatedZooms = arrayInsert(zoomsCopy, zoom, updatedZoomIndex);
            const updatedPages = cloneDeep(prevState.tourial[activeVariantName].pages);
            updatedPages[pageIndex].zooms = updatedZooms;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages: updatedPages }) }) });
        }
        case ActionType.UPDATE_ZOOM: {
            const { zoom, pageId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages: prevState.tourial[activeVariantName].pages.map(p => {
                            if (p.id !== pageId)
                                return p;
                            return Object.assign(Object.assign({}, p), { zooms: p.zooms.map(z => {
                                    if (z.id === zoom.id) {
                                        return zoom;
                                    }
                                    return z;
                                }) });
                        }) }) }), viewport: Object.assign(Object.assign({}, prevState.viewport), { mode: ViewportMode.EDIT }) });
        }
        case ActionType.UPDATE_ZOOM_BY_ID: {
            const { zoomId, patch, pageId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages: prevState.tourial[activeVariantName].pages.map(p => {
                            if (p.id !== pageId)
                                return p;
                            return Object.assign(Object.assign({}, p), { zooms: p.zooms.map(z => {
                                    if (z.id === zoomId) {
                                        return Object.assign(Object.assign({}, z), patch);
                                    }
                                    return z;
                                }) });
                        }) }) }), viewport: Object.assign(Object.assign({}, prevState.viewport), { mode: ViewportMode.EDIT }) });
        }
        case ActionType.SET_LOCAL_TOURIAL: {
            const { tourial } = action;
            return Object.assign(Object.assign({}, prevState), { tourial: cloneDeep(tourial) });
        }
        case ActionType.SET_VIDEO_DURATION: {
            const { duration, src } = action;
            const updatedMedia = cloneDeep(prevState.tourial.media);
            const mediaIndex = getBaseMediaIndex(updatedMedia, src);
            if (mediaIndex < 0)
                return prevState;
            if (!updatedMedia[mediaIndex].duration && duration) {
                updatedMedia[mediaIndex].duration = duration;
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { media: updatedMedia }) });
        }
        case ActionType.UPDATE_LOCAL_SAVED_TOURIAL: {
            const { tourial } = action;
            return Object.assign(Object.assign({}, prevState), { savedTourial: cloneDeep(tourial), version: null });
        }
        case ActionType.SAVE_COLOR: {
            const { color } = action;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { savedColors: [...(prevState.tourial.savedColors || []), { color, title: uuidv4() }] }) });
        }
        case ActionType.DELETE_COLOR: {
            const { title } = action;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { savedColors: [
                        ...(prevState.tourial.savedColors.filter(
                        // @ts-expect-error
                        c => c.title !== title) || []),
                    ] }) });
        }
        case ActionType.APPLY_BACKGROUND_TO_ALL_PAGES: {
            const activeVariantName = getActiveVariantName(prevState);
            const { sourcePageId } = action;
            const sourceToCopy = prevState.tourial[activeVariantName].tools.background.backgrounds[sourcePageId];
            if (!sourceToCopy)
                return;
            const newBackgrounds = {};
            prevState.tourial[activeVariantName].pages.forEach(p => (newBackgrounds[p.id] = sourceToCopy));
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { tools: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools), { background: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].tools.background), { backgrounds: newBackgrounds }) }) }) }) });
        }
        case ActionType.CREATE_VARIANT_MOBILE_TEMPLATE: {
            const version = prevState.tourial.version;
            const variantMobile = getTourialVariantTemplate();
            const screenData = Object.assign({}, variantMobile.tools.screen);
            const newPages = (_v = (_u = prevState.tourial) === null || _u === void 0 ? void 0 : _u.variantDesktop) === null || _v === void 0 ? void 0 : _v.pages.map((p) => {
                var _a, _b, _c, _d;
                const id = uuidv4();
                let autoStartZoomId = "";
                const mediaUrl = (_d = (_c = (_b = (_a = prevState.tourial) === null || _a === void 0 ? void 0 : _a.variantDesktop) === null || _b === void 0 ? void 0 : _b.tools) === null || _c === void 0 ? void 0 : _c.screen) === null || _d === void 0 ? void 0 : _d.media[p.id];
                if (mediaUrl) {
                    screenData.media[id] = mediaUrl;
                    if (!isImageFileExtension(mediaUrl)) {
                        screenData.perPageVideoControls[id] = getDefaultVideoControls(version);
                    }
                }
                const zooms = [getDefaultZoom(version)];
                if (version !== 3) {
                    autoStartZoomId = uuidv4();
                    zooms.push({
                        id: autoStartZoomId,
                        name: "View 1",
                        x: 0,
                        y: 25,
                        z: 3,
                        transition: {
                            delaySecs: 0,
                            durationSecs: 0,
                            timingFunction: "ease-in-out",
                            isFinished: false,
                        },
                    });
                }
                return { id, name: p.name, zooms, autoStartZoomId };
            });
            variantMobile.pages = newPages;
            variantMobile.tools.screen = screenData;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { variantMobile, isVariantMobileEnabled: true }), viewport: Object.assign(Object.assign({}, prevState.viewport), { activeVariantName: ActiveVariantName.VARIANT_MOBILE, activeView: {
                        pageId: variantMobile.pages[0].id,
                        zoomId: "MAIN",
                    } }) });
        }
        case ActionType.CREATE_MOBILE_VARIANT_FROM_DESKTOP: {
            const variantMobile = cloneDeep(prevState.tourial.variantDesktop);
            // minify modals
            for (const k in variantMobile.tools) {
                const v = variantMobile.tools[k];
                if (v.type === ToolType.Modal) {
                    if (!v.size) {
                        v.size = { width: 300, height: 0 };
                    }
                    else {
                        v.size.width = 300;
                    }
                }
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { variantMobile, isVariantMobileEnabled: true }), viewport: Object.assign(Object.assign({}, prevState.viewport), { activeVariantName: ActiveVariantName.VARIANT_MOBILE, activeView: {
                        pageId: variantMobile.pages[0].id,
                        zoomId: "MAIN",
                    } }) });
        }
        case ActionType.SET_ACTIVE_VARIANT: {
            const prevActiveVariantName = getActiveVariantName(prevState);
            let { activeVariantName } = action;
            // If mobile isn't enabled, don't switch to it in LIVE mode
            if (!prevState.tourial.isVariantMobileEnabled && prevState.viewport.mode === ViewportMode.LIVE) {
                activeVariantName = ActiveVariantName.VARIANT_DESKTOP;
            }
            const activeVariant = prevState.tourial[activeVariantName];
            const activeView = {
                pageId: activeVariant.pages[0].id,
                zoomId: "MAIN",
            };
            // if the variant is not changing from prevState, return prevState to avoid tour reset
            if (prevState.viewport.activeVariantName &&
                prevState.viewport.mode === ViewportMode.LIVE &&
                activeVariantName === prevActiveVariantName) {
                return prevState;
            }
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { activeVariantName,
                    activeView, visibleToolIds: getDefaultVisibleToolIds(prevState.tourial[activeVariantName].viewTools, activeView, prevState.viewport.mode) }) });
        }
        case ActionType.LEGACY_HIDE_TOOL: {
            const { toolId } = action;
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { visibleToolIds: Object.assign(Object.assign({}, prevState.viewport.visibleToolIds), { [toolId]: false }) }) });
        }
        case ActionType.LEGACY_SHOW_TOOL: {
            const { toolId } = action;
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { visibleToolIds: Object.assign(Object.assign({}, prevState.viewport.visibleToolIds), { [toolId]: true }) }) });
        }
        case ActionType.SET_HUBSPOT_INTEGRATION: {
            const { hutk } = action;
            return Object.assign(Object.assign({}, prevState), { integrations: Object.assign(Object.assign({}, prevState.integrations), { hutk }) });
        }
        case ActionType.SET_MARKETO_INTEGRATION: {
            const { munchkinCookie } = action;
            return Object.assign(Object.assign({}, prevState), { integrations: Object.assign(Object.assign({}, prevState.integrations), { munchkinCookie }) });
        }
        case ActionType.SET_TIMER: {
            const activeVariantName = getActiveVariantName(prevState);
            const { timer } = action;
            let timers = [];
            if ((_w = prevState.tourial[activeVariantName].timers) === null || _w === void 0 ? void 0 : _w.length) {
                timers = (_x = prevState.tourial[activeVariantName].timers) === null || _x === void 0 ? void 0 : _x.filter(t => t.toolId !== timer.toolId);
                timers.push(timer);
            }
            else {
                timers = [timer];
            }
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { timers }) }) });
        }
        case ActionType.ADD_PATCH: {
            const { syncTimeoutId } = action;
            return Object.assign(Object.assign({}, prevState), { syncTimeoutId });
        }
        case ActionType.SET_IS_PUBLISHED: {
            const { isPublished } = action;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { isPublished }) });
        }
        case ActionType.SET_VIEWPORT_MODE: {
            const { mode } = action;
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { mode, previousView: undefined }) });
        }
        case ActionType.SET_SCREEN_DID_LOAD: {
            const { screenDidLoad } = action;
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { screenDidLoad }) });
        }
        case ActionType.SET_TOURIAL_NAME: {
            const { name } = action;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { name }) });
        }
        case ActionType.DELETE_TOOL: {
            const { toolId } = action;
            return deleteTool(toolId, prevState);
        }
        case ActionType.NUDGE_TOOL: {
            const { toolId, direction } = action;
            return nudgeTool(toolId, direction, prevState);
        }
        case ActionType.CHANGE_STEP: {
            const { nextStep } = action;
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { previousView: prevState.viewport.activeView, activeView: nextStep, isTransitioning: shouldTransition(prevState, nextStep), shouldAutoStart: shouldAutoStart(prevState, nextStep) }) });
        }
        case ActionType.COPY_SELECTED_TOOL: {
            const activeVariantName = getActiveVariantName(prevState);
            const { tools } = prevState.tourial[activeVariantName];
            const toolToCopy = tools[prevState.builder.selectedToolId];
            if (!toolToCopy)
                return prevState;
            const copy = cloneDeep(toolToCopy);
            return Object.assign(Object.assign({}, prevState), { builder: Object.assign(Object.assign({}, prevState.builder), { copyBuffer: copy }) });
        }
        case ActionType.PASTE_TOOL_FROM_COPY_BUFFER_TO_CURRENT_STEP: {
            const newTool = cloneDeep(prevState.builder.copyBuffer);
            // make sure it's a tool
            if (!(newTool.id && newTool.type))
                return prevState;
            const activeVariantName = getActiveVariantName(prevState);
            const { tools } = prevState.tourial[activeVariantName];
            const { activeView } = prevState.viewport;
            const activePage = prevState.tourial[activeVariantName].pages.find(p => p.id === activeView.pageId);
            // check interactive vs. automated
            if (newTool.type.includes("AUTO") !== !!activePage.isAutomated)
                return prevState;
            const { viewTools } = prevState.tourial[activeVariantName];
            newTool.id = uuidv4();
            const newViewTool = {
                toolId: newTool.id,
                zoomId: activeView.zoomId,
                pageId: activeView.pageId,
                visibleOnLoad: true,
            };
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { tools: Object.assign(Object.assign({}, tools), { [newTool.id]: newTool }), viewTools: [...viewTools, newViewTool] }) }), viewport: Object.assign(Object.assign({}, prevState.viewport), { visibleToolIds: Object.assign(Object.assign({}, prevState.viewport.visibleToolIds), { [newTool.id]: true }) }) });
        }
        case ActionType.SET_FORM_ON_STEP: {
            const { formId, isGated, isEnabled } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const activeView = prevState.viewport.activeView;
            const pages = prevState.tourial[activeVariantName].pages;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages, stepForms: Object.assign(Object.assign({}, prevState.tourial[activeVariantName].stepForms), { [makeStepFormInteractionKey(activeView)]: {
                                formId,
                                isGated,
                                isEnabled,
                            } }) }) }) });
        }
        case ActionType.SET_PAGE_FORM: {
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { pageForm: action.formId ? action : null }) });
        }
        case ActionType.SET_SHOULD_RENDER_FORM: {
            const { shouldRender } = action;
            return Object.assign(Object.assign({}, prevState), { builder: Object.assign(Object.assign({}, prevState.builder), { shouldRenderForm: shouldRender }) });
        }
        case ActionType.SET_IS_TRANSITIONING: {
            const { isTransitioning } = action;
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { isTransitioning }) });
        }
        case ActionType.SET_SHOULD_AUTO_START: {
            const { shouldAutoStart } = action;
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { shouldAutoStart }) });
        }
        case ActionType.SET_INITIAL_TRANSITION_AND_AUTO_START_STATE: {
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { shouldAutoStart: shouldAutoStart(prevState, prevState.viewport.activeView, true), isTransitioning: shouldTransition(prevState, prevState.viewport.activeView) }) });
        }
        case ActionType.TOGGLE_HIDDEN_TOOLS: {
            const { hiddenToolIds } = action;
            const hiddenTools = cloneDeep(prevState.viewport.hiddenTools);
            for (const id of hiddenToolIds) {
                if (!hiddenTools[id]) {
                    hiddenTools[id] = true;
                }
                else {
                    hiddenTools[id] = !hiddenTools[id];
                }
            }
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { hiddenTools }) });
        }
        case ActionType.SHOW_TOOLS: {
            const { hiddenToolIds } = action;
            const hiddenTools = cloneDeep(prevState.viewport.hiddenTools);
            for (const id of hiddenToolIds) {
                if (!hiddenTools[id]) {
                    continue;
                }
                else {
                    hiddenTools[id] = false;
                }
            }
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { hiddenTools }) });
        }
        case ActionType.HIDE_TOOLS: {
            const { hiddenToolIds } = action;
            const hiddenTools = cloneDeep(prevState.viewport.hiddenTools);
            for (const id of hiddenToolIds) {
                hiddenTools[id] = true;
            }
            return Object.assign(Object.assign({}, prevState), { viewport: Object.assign(Object.assign({}, prevState.viewport), { hiddenTools }) });
        }
        case ActionType.REVERT_TO_PUBLISHED_TOUR: {
            const tourial = cloneDeep(prevState.savedTourial);
            const activeVariantName = ActiveVariantName.VARIANT_DESKTOP;
            return Object.assign(Object.assign({}, prevState), { tourial, viewport: Object.assign(Object.assign({}, prevState.viewport), { activeVariantName, activeView: { pageId: tourial[activeVariantName].pages[0].id, zoomId: "MAIN" } }), version: null });
        }
        case ActionType.DELETE_PAGE: {
            const { pageId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            if (prevState.tourial[activeVariantName].pages.length <= 1)
                return prevState;
            let viewTools = cloneDeep(prevState.tourial[activeVariantName].viewTools);
            viewTools = viewTools.filter(vt => {
                return vt.pageId !== pageId;
            });
            const tools = cloneDeep(rebuildTools(prevState.tourial[activeVariantName].tools, viewTools));
            // remove screen media for deleted page
            (_y = tools.screen.media) === null || _y === void 0 ? true : delete _y[pageId];
            const prevPages = prevState.tourial[activeVariantName].pages;
            const pages = prevPages.filter(p => p.id !== pageId);
            const deleteIndex = prevPages.findIndex(p => p.id === pageId);
            const nextViewPageId = prevPages[deleteIndex ? deleteIndex - 1 : deleteIndex + 1].id;
            const nextActiveView = prevState.viewport.activeView.pageId === pageId
                ? { pageId: nextViewPageId, zoomId: "MAIN" }
                : prevState.viewport.activeView;
            const tourial = Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages,
                    tools,
                    viewTools }) });
            return Object.assign(Object.assign({}, prevState), { tourial, viewport: Object.assign(Object.assign({}, prevState.viewport), { activeView: nextActiveView, hiddenTools: rebuildHiddenTools(prevState.viewport.hiddenTools, tools) }) });
        }
        case ActionType.DELETE_ZOOM: {
            const { zoomId } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const pages = cloneDeep(prevState.tourial[activeVariantName].pages);
            const activePageIndex = pages.findIndex(p => p.id === prevState.viewport.activeView.pageId);
            pages[activePageIndex].zooms = pages[activePageIndex].zooms.filter(z => z.id !== zoomId);
            let viewTools = cloneDeep(prevState.tourial[activeVariantName].viewTools);
            viewTools = viewTools.filter(vt => {
                if (vt.pageId === pages[activePageIndex].id) {
                    if (vt.zoomId === zoomId) {
                        return false;
                    }
                }
                return true;
            });
            const tools = rebuildTools(prevState.tourial[activeVariantName].tools, viewTools);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages,
                        viewTools,
                        tools }) }), viewport: Object.assign(Object.assign({}, prevState.viewport), { hiddenTools: rebuildHiddenTools(prevState.viewport.hiddenTools, tools) }) });
        }
        case ActionType.CHECK_OUT_VERSION: {
            const { version } = action;
            const nextPageId = version.tour.variantDesktop.pages[0].id;
            return Object.assign(Object.assign({}, prevState), { version: version, tourial: version.tour, viewport: Object.assign(Object.assign({}, prevState.viewport), { activeVariantName: ActiveVariantName.VARIANT_DESKTOP, activeView: {
                        pageId: nextPageId,
                        zoomId: "MAIN",
                    } }), builder: getInitialBuilderState() });
        }
        case ActionType.SET_IS_VIDEO_TRIM_MODAL_OPEN: {
            const { isOpen } = action;
            return Object.assign(Object.assign({}, prevState), { builder: Object.assign(Object.assign({}, prevState.builder), { isVideoTrimModalOpen: isOpen }) });
        }
        case ActionType.SET_SCREEN_MEDIA: {
            const { pageId, src } = action;
            const variant = getActiveVariantName(prevState);
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [variant]: Object.assign(Object.assign({}, prevState.tourial[variant]), { tools: Object.assign(Object.assign({}, prevState.tourial[variant].tools), { screen: Object.assign(Object.assign({}, prevState.tourial[variant].tools.screen), { media: Object.assign(Object.assign({}, prevState.tourial[variant].tools.screen.media), { [pageId]: src }) }) }) }) }) });
        }
        case ActionType.SET_TRIM_TIMER: {
            const { src, timer } = action;
            return Object.assign(Object.assign({}, prevState), { builder: Object.assign(Object.assign({}, prevState.builder), { trimTimers: Object.assign(Object.assign({}, prevState.builder.trimTimers), { [src]: timer }) }) });
        }
        case ActionType.SET_CURRENT_PAGE_NAME: {
            const { name } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const pages = cloneDeep(prevState.tourial[activeVariantName].pages);
            const activePageIndex = pages.findIndex(p => p.id === prevState.viewport.activeView.pageId);
            pages[activePageIndex].name = name;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages }) }) });
        }
        case ActionType.SET_PAGE_NAME_BY_ID: {
            const { name, id } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const pages = cloneDeep(prevState.tourial[activeVariantName].pages);
            const selectedPageIndex = pages.findIndex(p => p.id === id);
            pages[selectedPageIndex].name = name;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages }) }) });
        }
        case ActionType.SET_CURRENT_STEP_NAME: {
            const { name } = action;
            const activeVariantName = getActiveVariantName(prevState);
            const pages = cloneDeep(prevState.tourial[activeVariantName].pages);
            const activePageIndex = pages.findIndex(p => p.id === prevState.viewport.activeView.pageId);
            const zooms = pages[activePageIndex].zooms;
            const activeZoomIndex = zooms.findIndex(z => z.id === prevState.viewport.activeView.zoomId);
            zooms[activeZoomIndex].name = name;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [activeVariantName]: Object.assign(Object.assign({}, prevState.tourial[activeVariantName]), { pages }) }) });
        }
        case ActionType.SET_IS_MOBILE_BUTTON_DISABLED: {
            const { isDisabled } = action;
            return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { isMobileButtonDisabled: isDisabled }) });
        }
        case ActionType.NULL:
        default:
            return prevState;
    }
}
export const getListenersToHandle = (action, activeVariantListeners) => {
    const { toolId, triggeredByAction, direction } = action;
    return activeVariantListeners.filter(listener => {
        if (listener.triggeredByAction !== triggeredByAction)
            return false;
        if (listener.toolId !== toolId)
            return false;
        return isHoverHandled(listener, direction);
    });
};
