import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TourialItemContainer, TourialListContainer, } from "../../../microsites/modals/add-tourial-modal";
import { useAsync } from "react-async";
import { TourialApiCalls } from "../../../../../helpers/tourial-api-calls";
import TInput from "../../../../styled/t-input";
import { TourialStatus } from "../../../../../../../shared/types/tourial-model";
import TStatusIndicator from "../../../../styled/t-status-indicator";
import { TCheckbox } from "../../../../styled/t-checkbox";
import PlaceholderThumbnail from "../../../../../../assets/images/v3/placeholder-thumbnail.svg";
import { getHTDurationEstimationInSeconds } from "../../../../../helpers/dom-helpers";
import { useHTDraft } from "../../../dom-tour/hooks/ht-hooks";
export const DCCMAddCOntentDomToursTab = ({ setSelectedTours, currentItems }) => {
    const [tourialList, setTourialList] = useState([]);
    const [filter, setFilter] = useState("");
    const { data } = useAsync({
        promiseFn: TourialApiCalls.DomTours.getDomTourList,
    });
    useEffect(() => {
        const tourialList = [];
        data === null || data === void 0 ? void 0 : data.domTourList.filter(t => t.status === TourialStatus.LIVE).forEach((t) => {
            var _a, _b;
            tourialList.push({
                id: t._id,
                thumbnail: ((_b = (_a = t === null || t === void 0 ? void 0 : t.htmlMetadata) === null || _a === void 0 ? void 0 : _a.find(m => m.property === "og:image")) === null || _b === void 0 ? void 0 : _b.content) || "",
                name: t.name,
                checked: checkIfTourExists(t._id),
                isPublished: true,
                interactedAt: t.interactedAt,
                archivedAt: t.archivedAt,
                estimatedDuration: getHTDurationEstimationInSeconds(t),
            });
        });
        setTourialList(tourialList);
    }, [data]);
    useEffect(() => {
        setSelectedTours(tourialList.filter(t => t.checked));
    }, [tourialList]);
    const checkIfTourExists = (id) => {
        return !!currentItems.find(i => i === id);
    };
    const handleClick = (tourial, checked) => {
        const newTourialList = [...tourialList];
        newTourialList.forEach(t => {
            if (t.id === tourial.id) {
                t.checked = checked;
            }
        });
        setTourialList(newTourialList);
    };
    return (React.createElement(TourialListContainer, null,
        React.createElement(FilterContainer, null,
            React.createElement(TInput, { placeholder: "Search tours", value: filter, onChange: e => setFilter(e.target.value), autoFocus: true })),
        tourialList
            .filter(t => t.name.toLowerCase().includes(filter.toLowerCase()))
            .map(tourial => {
            return (React.createElement(HTAddContentItem, { key: tourial.id, handleClick: handleClick, checkIfTourExists: checkIfTourExists, htmlTour: data.domTourList.find(ht => ht._id === tourial.id), tourial: tourial }));
        })));
};
const HTAddContentItem = ({ tourial, htmlTour, handleClick, checkIfTourExists }) => {
    const { status, name: draftName } = useHTDraft(htmlTour);
    return (React.createElement(TourialItemContainer, { key: tourial.id, onClick: () => {
            handleClick(tourial, !tourial.checked);
        } },
        React.createElement("img", { className: "thumbnail", src: tourial.thumbnail || PlaceholderThumbnail, style: { paddingRight: 8 } }),
        React.createElement(TStatusIndicator, { status: status }),
        React.createElement("span", { style: {
                textDecoration: checkIfTourExists(tourial.id) && !tourial.checked ? "line-through" : "inherit",
            } }, draftName),
        React.createElement("div", null,
            React.createElement(TCheckbox, { checked: tourial.checked, onChange: e => {
                    handleClick(tourial, e);
                } }))));
};
const FilterContainer = styled.div `
  display: flex;
  flex: 1;
  padding: 12px 12px;
  input {
    width: 100%;
  }
`;
