import React, { useEffect, useState } from "react";
import { useAsync } from "react-async";
import styled from "styled-components";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
import { isImageFileExtension } from "../../../../helpers/media";
import { useMicrositeTours } from "../../../../redux/selectors/microsite-selectors";
import TButton from "../../../styled/t-button";
import { TCheckbox } from "../../../styled/t-checkbox";
import TModal, { TModalButtonsContainer } from "../../../styled/t-modal";
import { newTheme } from "../../../styled/theme";
import { getVideoThumbnail } from "../../../../../../shared/functions/helpers";
import PlaceholderThumbnail from "../../../../../assets/images/v3/placeholder-thumbnail.svg";
import TStatusIndicator from "../../../styled/t-status-indicator";
import { getTourialStatus } from "../../../../helpers/tourial";
export default function AddTourialModal({ setIsOpen, addTours }) {
    const [tourialList, setTourialList] = useState([]);
    const existingTours = useMicrositeTours();
    const { data, error } = useAsync({
        promiseFn: TourialApiCalls.Tourials.getTourialList,
    });
    useEffect(() => {
        const tourialList = [];
        data === null || data === void 0 ? void 0 : data.tourialList.filter(t => t.version === 3).forEach((t) => {
            tourialList.push({
                id: t.tourId,
                thumbnail: t.firstPageData.screen.src,
                name: t.tourName,
                checked: checkIfTourExists(t.tourId),
                isPublished: t.isPublished,
                interactedAt: t.interactedAt,
                archivedAt: t.archivedAt,
            });
        });
        setTourialList(tourialList);
    }, [data]);
    const checkIfTourExists = (id) => {
        let exists = false;
        existingTours.forEach(t => {
            if (t.id === id) {
                exists = true;
            }
        });
        return exists;
    };
    const handleSubmit = () => {
        addTours(tourialList.filter(t => t.checked));
        setIsOpen(false);
    };
    const handleCheck = (tourial, checked) => {
        const newTourialList = [...tourialList];
        newTourialList.forEach(t => {
            if (t.id === tourial.id) {
                t.checked = checked;
            }
        });
        setTourialList(newTourialList);
    };
    return (React.createElement(TModal, { title: "Select Tourials", onClose: () => setIsOpen(false) },
        React.createElement(React.Fragment, null,
            error && React.createElement("div", null, "Could not load Tourials"),
            React.createElement(TourialListContainer, null, tourialList.map(t => (React.createElement(AddTourialItem, { key: t.id, tourial: t, onClick: handleCheck, exists: checkIfTourExists(t.id) })))),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { onClick: handleSubmit }, "Add Tours")))));
}
export const AddTourialItem = ({ tourial, onClick, exists }) => {
    const img = tourial.thumbnail;
    const tourialStatus = getTourialStatus(tourial);
    return (React.createElement(TourialItemContainer, { onClick: () => {
            onClick(tourial, !tourial.checked);
        } },
        React.createElement("img", { className: "thumbnail", src: img ? (isImageFileExtension(img) ? img : getVideoThumbnail(img)) : PlaceholderThumbnail, style: { paddingRight: 8 } }),
        React.createElement(TStatusIndicator, { status: tourialStatus }),
        React.createElement("span", { style: { textDecoration: exists && !tourial.checked ? "line-through" : "inherit" } }, tourial.name),
        React.createElement("div", null,
            React.createElement(TCheckbox, { checked: tourial.checked, onChange: e => {
                    onClick(tourial, e);
                } }))));
};
export const TourialListContainer = styled.div `
  margin: 24px;
  height: 258px;
  width: 552px;
  background-color: ${newTheme.colors.grey100};
  overflow-y: scroll;
`;
export const TourialItemContainer = styled.div `
  display: flex;
  align-items: center;
  padding: 12px 16px;
  .thumbnail {
    width: 54px;
    height: 30px;
  }
  span {
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }
  &:hover {
    background-color: ${newTheme.colors.grey200};
    cursor: pointer;
  }
`;
