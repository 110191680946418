import React from "react";
import styled from "styled-components";
import { newTheme } from "./theme";
export var TButtonVariants;
(function (TButtonVariants) {
    TButtonVariants["DEFAULT"] = "default";
    TButtonVariants["SECONDARY"] = "secondary";
    TButtonVariants["TERTIARY"] = "tertiary";
    TButtonVariants["WARNING"] = "warning";
    TButtonVariants["DANGER"] = "danger";
    TButtonVariants["TRANSPARENT"] = "transparent";
    TButtonVariants["OUTLINE"] = "outline";
})(TButtonVariants || (TButtonVariants = {}));
export default function TButton(props) {
    const { onClick, children, size, icon, variant, disabled, fullWidth, iconPlacement, dataCy } = props;
    const RenderIcon = () => React.createElement(Icon, { src: icon, size: size || "default", iconOnly: !children });
    const iconIsOnRight = iconPlacement === "right";
    return (React.createElement(Button, Object.assign({}, props, { onClick: onClick, size: size || "default", variant: disabled ? "disabled" : variant, fullWidth: fullWidth, "data-cy": dataCy }),
        icon && !iconIsOnRight && React.createElement(RenderIcon, null),
        children && React.createElement(ButtonText, null, children),
        icon && iconIsOnRight && React.createElement(RenderIcon, null)));
}
const ButtonBackgroundColors = {
    default: newTheme.colors.blue500,
    secondary: newTheme.colors.grey200,
    tertiary: newTheme.colors.blue50,
    danger: newTheme.colors.red500,
    warning: newTheme.colors.yellow500,
    disabled: newTheme.colors.grey200,
    transparent: "transparent",
    outline: newTheme.colors.white,
};
const ButtonHoverColors = {
    default: newTheme.colors.blue400,
    secondary: newTheme.colors.grey300,
    tertiary: newTheme.colors.blue100,
    danger: newTheme.colors.red400,
    warning: newTheme.colors.yellow400,
    disabled: newTheme.colors.grey200,
    transparent: newTheme.colors.grey100,
    outline: newTheme.colors.grey100,
};
const ButtonTextColors = {
    default: newTheme.colors.white,
    secondary: newTheme.colors.black,
    tertiary: newTheme.colors.blue500,
    danger: newTheme.colors.white,
    warning: newTheme.colors.black,
    disabled: newTheme.colors.grey500,
    transparent: newTheme.colors.blue500,
    outline: newTheme.colors.grey900,
};
const ButtonSizes = {
    default: "40px",
    medium: "36px",
    small: "32px",
    smaller: "26px",
};
const ButtonFontSizes = {
    default: newTheme.fonts.normal,
    medium: newTheme.fonts.medium,
    small: newTheme.fonts.small,
};
const ButtonPadding = {
    default: "8px 16px;",
    medium: "8px 12px;",
    small: "8px 8px;",
    smaller: "8px 8px;",
};
const ButtonGap = {
    default: "8px",
    medium: "8px",
    small: "4px",
    smaller: "4px",
};
const IconSize = {
    default: "20px",
    medium: "16px",
    small: "14px",
    smaller: "14px",
};
const Button = styled.button `
  display: flex;
  justify-content: center;
  width: ${props => (props.fullWidth ? "100%" : "auto")};
  font: ${props => ButtonFontSizes[props.size || "default"]};
  color: ${props => ButtonTextColors[props.variant || "default"]};
  gap: ${props => ButtonGap[props.size || "default"]};
  align-items: center;
  border-radius: 4px;
  border-color: ${props => (props.variant === TButtonVariants.OUTLINE ? newTheme.colors.grey300 : "none")};
  border-style: ${props => (props.variant === TButtonVariants.OUTLINE ? "solid" : "none")};
  border-width: ${props => (props.variant === TButtonVariants.OUTLINE ? "1px" : "0")};
  background-color: ${props => ButtonBackgroundColors[props.variant || "default"]};
  padding: ${props => ButtonPadding[props.size || "default"]};
  height: ${props => ButtonSizes[props.size || "default"]};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${props => ButtonHoverColors[props.variant || "default"]} !important;
  }
`;
const ButtonText = styled.div `
  display: flex;
  white-space: nowrap;
`;
const Icon = styled.img `
  width: ${props => IconSize[props.size || "default"]};
  height: ${props => IconSize[props.size || "default"]};
  display: flex;
  margin: ${props => (props.iconOnly ? "2px 0 0 0" : "0")};
`;
