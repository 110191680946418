import React from "react";
import { ActiveVariantName, TextAlign } from "../../../../../../shared/types/tool-data-types/common";
import { positionEq, sizeEq } from "../../../../helpers/tooltip-v2-size-and-position-helpers";
import { useActiveVariantName, useSetBuilderFocusField, useUpdateTool, } from "../../../../redux/selectors/redux-selectors";
import { cloneDeep } from "lodash";
import { withFlexibleToolWrapper } from "../../../renderer/wrapper/with-flexible-tool-wrapper";
import { TourialEditorFocusFields } from "../../../../types/definitions";
import parse from "html-react-parser";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import { useSelectedToolId } from "../../../../redux/selectors/builder-selectors";
export function AutoTypewriter(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    function drag(position) {
        position = {
            x: position.x + tool.size.width / 2,
            y: position.y + tool.size.height / 2,
        };
        if (!positionEq(tool.position, position)) {
            const t = cloneDeep(tool);
            t.position = position;
            updateTool(t);
        }
    }
    function resize(size, position) {
        position = {
            x: position.x + tool.size.width / 2,
            y: position.y + tool.size.height / 2,
        };
        if (!positionEq(tool.position, position) || !sizeEq(tool.size, size)) {
            const t = cloneDeep(tool);
            t.position = position;
            t.size = size;
            updateTool(t);
        }
    }
    // make trigger.position.x & y center of trigger
    const position = {
        x: tool.position.x - tool.size.width / 2,
        y: tool.position.y - tool.size.height / 2,
    };
    return withFlexibleToolWrapper(Inner, {
        isEditing: props.isInEditViewport,
        isSelectable: true,
        isResizable: true,
        isDraggable: true,
        isAspectRatioLocked: false,
        isStaticSize: false,
        shouldShowSelectionTab: false,
        isAutoTool: true,
    }, {
        bounds: ".tourial-viewport",
        minSize: { width: 16, height: 16 },
        size: tool.size,
        position,
    }, {
        onDrag: drag,
        onDragStop: drag,
        onResize: resize,
        onResizeStop: resize,
    })(props);
}
function Inner(props) {
    const activeVariantName = useActiveVariantName();
    const setBuilderFocusField = useSetBuilderFocusField();
    const selectedToolId = useSelectedToolId();
    const { toolData: tool, isInEditViewport, index } = props;
    const { body, size } = tool;
    const percentLineHeight = 1.5;
    const standardHeightInPx = 607.52;
    const alignments = {
        [TextAlign.LEFT]: "left",
        [TextAlign.CENTER]: "center",
        [TextAlign.RIGHT]: "right",
    };
    const percentFontSize = (body.size / standardHeightInPx) * 100; // convert px in standard dim to percent based
    const aspectRatio = activeVariantName === ActiveVariantName.VARIANT_DESKTOP ? 16 / 9 : 9 / 16;
    const svgHeight = (size.height * 100) / percentFontSize;
    const svgWidth = ((size.width * 100) / percentFontSize) * aspectRatio;
    const style = {
        width: svgWidth,
        height: svgHeight,
        position: "static",
        overflow: "hidden",
        display: "block",
        fontFamily: body.font,
        textAlign: alignments[body.align],
        color: body.color,
        fontSize: 100,
        lineHeight: `${percentLineHeight * 100}px`,
        maxHeight: "none",
        wordWrap: "break-word",
        // whiteSpace: "pre-wrap", // leaving as normal, consider expected behavior if changing
        backgroundColor: tool.backgroundColor,
        fontKerning: "none",
    };
    return (React.createElement(React.Fragment, null,
        isInEditViewport && selectedToolId === "screen" && React.createElement(Label, { index: index }),
        React.createElement("svg", { viewBox: `0 0 ${svgWidth} ${svgHeight}`, onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.AUTO_TYPEWRITER_TEXT), style: { verticalAlign: "top", width: "100%", height: "100%" } },
            React.createElement("foreignObject", { x: "0", y: "0", width: svgWidth, height: svgHeight },
                React.createElement(BodyContainer, { id: `${tool.id}-auto-typewriter`, style: style, isInEditViewport: isInEditViewport, textAlign: alignments[body.align] }, parse(body.text))))));
}
const Label = styled.div `
  position: absolute;
  &:before {
    font: ${newTheme.fonts.small};
    content: "${p => p.index}:";
    position: absolute;
    line-height: 24px;
    padding: 0px 4px;
    font-size: 14px;
    top: -28px;
    background-color: ${newTheme.colors.white};
    border-radius: 4px;
    border: 1px solid ${newTheme.colors.grey300};
  }
`;
const BodyContainer = styled.div `
  ${p => p.isInEditViewport
    ? `ul, ol {
        margin-left: 2em;
      };`
    : `
        .char, .word { position: static !important; };
        u > div { 
          text-decoration-line: underline;
          .char { text-decoration-line: underline; } 
        };
        ul, ol { 
          list-style: none;
          grid-template-columns: 2em auto;
          display: grid;
          li {
            ${p.textAlign === "center" ? "margin: auto" : p.textAlign === "right" ? "margin-left: auto" : ""}
          }
        };
        `}
`;
