import React from "react";
import styled from "styled-components";
import Slider from "rc-slider";
import { newTheme } from "./theme";
export const THorizontalSlider = (props) => {
    const { onChange, min, max, defaultValue, disabled, value, style, className } = props;
    const handleChange = (e) => {
        onChange(e);
    };
    return (React.createElement(SliderContainer, { style: style },
        React.createElement(Slider, { className: className, min: min, max: max, onChange: handleChange, defaultValue: defaultValue || 0, disabled: disabled || false, value: value, railStyle: {
                position: "absolute",
                height: "2px",
                backgroundColor: disabled ? newTheme.colors.grey300 : newTheme.colors.blue200,
                backgroundPosition: "center",
                width: "100%",
            }, trackStyle: {
                position: "absolute",
                height: "2px",
            }, handleStyle: {
                cursor: disabled ? "not-allowed" : "grab",
                height: "18px",
                width: "18px",
                marginTop: "-8px",
                borderRadius: "9px",
                backgroundColor: disabled ? newTheme.colors.grey500 : newTheme.colors.blue,
                position: "absolute",
                boxShadow: newTheme.shadows.outside80,
            } })));
};
const SliderContainer = styled.div `
  position: relative;
  margin: 16px;
  width: 156px;
`;
