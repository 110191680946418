import { HorizontalScrollArrows } from "./horizontal-scroll-arrows";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import { useActiveView, useIsEmptyTourial, usePages, usePushHistory, useUpdatePageIndex, } from "../../../../redux/selectors/redux-selectors";
import { StoryboardMiniContentsEmpty } from "./storyboard-mini-contents-empty";
import { StoryboardMiniScreenGroup } from "./storyboard-mini-screen-group";
import { useDeselect } from "../../../../redux/selectors/builder-selectors";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { AddScreenButton } from "./add-screen-button";
export function StoryboardMiniContents({ editPanelWidth, sidebarWidth, storyboardHeight, setIsAddScreenModalOpen, setIsSwapScreenModalOpen, }) {
    const ref = useRef();
    const pages = usePages();
    const activeView = useActiveView();
    const isEmptyTourial = useIsEmptyTourial();
    const deselect = useDeselect();
    const pushHistory = usePushHistory();
    const updatePageIndex = useUpdatePageIndex();
    const [draggingId, setDraggingId] = useState("");
    const onDragStart = event => {
        const { draggableId } = event;
        setDraggingId(draggableId);
    };
    const onDragEnd = (result) => {
        setDraggingId("");
        const { destination, source } = result;
        // dropped outside context
        if (!destination)
            return;
        // dropped in the same location
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;
        deselect();
        pushHistory();
        updatePageIndex(source.index, destination.index);
    };
    return (React.createElement(React.Fragment, null,
        !isEmptyTourial && (React.createElement(HorizontalScrollArrows, { sidebarWidth: sidebarWidth, storyboardHeight: storyboardHeight, storyboardRef: ref, editPanelWidth: editPanelWidth })),
        React.createElement(PrimaryContainer, { ref: ref, id: "storyboard-container", className: "hide-scrollbar", style: {
                left: (isEmptyTourial ? 40 : 80) + sidebarWidth,
                height: storyboardHeight,
                width: `calc(100% - ${(isEmptyTourial ? 40 : 120) + sidebarWidth}px - ${editPanelWidth}px)`,
            } },
            isEmptyTourial ? (React.createElement(StoryboardMiniContentsEmpty, null)) : (React.createElement(SecondaryContainer, null,
                React.createElement(DragDropContext, { onDragStart: onDragStart, onDragEnd: onDragEnd },
                    React.createElement(Droppable, { droppableId: "all-screen-groups", direction: "horizontal" }, provided => (React.createElement(DroppableContainer, Object.assign({}, provided.droppableProps, { ref: provided.innerRef }),
                        pages.map((page, i) => (React.createElement(StoryboardMiniScreenGroup, { key: page.id, index: i, page: page, draggingId: draggingId, isSelected: activeView.pageId === page.id, setIsSwapScreenModalOpen: setIsSwapScreenModalOpen, editorPanelWidth: editPanelWidth }))),
                        provided.placeholder)))))),
            !isEmptyTourial && React.createElement(AddScreenButton, { setIsAddScreenModalOpen: setIsAddScreenModalOpen }))));
}
const PrimaryContainer = styled.div `
  background-color: ${newTheme.colors.white};
  position: fixed;
  bottom: 0;
  transition:
    left ${newTheme.transitions.layoutShift},
    height ${newTheme.transitions.layoutShift};
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  align-items: center;
`;
const SecondaryContainer = styled.div `
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
  gap: 10px;
`;
const DroppableContainer = styled.div `
  display: flex;
`;
