import React, { useState, useEffect } from "react";
import TButton, { TButtonVariants } from "../../styled/t-button";
import styled from "styled-components";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import TModal, { TModalButtonsContainer } from "../../styled/t-modal";
import { newTheme } from "../../styled/theme";
import { ToastModes } from "../../../types/definitions";
import { TDropdownSelect } from "../../styled/t-dropdown-select";
import { TourialType } from "../../../../../shared/types/tourial-model";
export default function MoveTourialToWorkspace({ tourialData, setIsMoveToWorkspaceModalOpen, reload, addToast, setIsDropdownOpen, }) {
    const [workspaces, setWorkspaces] = useState([]);
    const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(tourialData.workspaceId || "");
    useEffect(() => {
        // Fetch workspaces on modal open
        TourialApiCalls.Workspaces.listWorkspaces()
            .then(data => {
            setWorkspaces(data.map(workspace => {
                return {
                    label: workspace.name,
                    value: workspace._id,
                };
            }));
        })
            .catch(e => {
            console.error(e);
        });
    }, []);
    return (React.createElement(TModal, { title: "Moving to workspace", onClose: () => {
            setIsMoveToWorkspaceModalOpen(false);
            setIsDropdownOpen(false);
        } },
        React.createElement(React.Fragment, null,
            React.createElement(Span, null,
                React.createElement("div", { style: { marginBottom: 10 } }, "Where do you want to move your demo?"),
                React.createElement(TDropdownSelect, { options: workspaces, onChange: e => setSelectedWorkspaceId(e.value), selectedValue: selectedWorkspaceId, isSearchable: false, placeholder: "Select workspace" })),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => {
                        setIsMoveToWorkspaceModalOpen(false);
                        setIsDropdownOpen(false);
                    } }, "Cancel"),
                React.createElement(TButton, { variant: TButtonVariants.DEFAULT, disabled: tourialData.workspaceId === selectedWorkspaceId, onClick: () => {
                        TourialApiCalls.Workspaces.updateTourWorkspace(selectedWorkspaceId, tourialData.tourId, TourialType.MEDIA)
                            .then(() => {
                            setIsMoveToWorkspaceModalOpen(false);
                            setIsDropdownOpen(false);
                            addToast({ message: "Moved to workspace successfully", mode: ToastModes.SUCCESS });
                            reload();
                        })
                            .catch(e => {
                            console.error(e);
                            addToast({ message: "Move to workspace failed", mode: ToastModes.ERROR });
                        });
                    } }, "Move")))));
}
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  width: 100%;
`;
