import React from "react";
import { EditorTitlebar } from "../../../pages/builder/editor/editor-titlebar";
import { EditorTabs } from "../../../pages/builder/editor/editor-tabs";
import { StyleSection } from "./components/style-section";
import { ConfigureSection } from "./components/configure-section";
export function TooltipV2Editor(props) {
    const { toolData: tool } = props;
    return (React.createElement("div", { id: "_test_tooltip-v2-editor", style: { height: "100%" } },
        React.createElement(EditorTitlebar, { title: tool.name, toolId: tool.id, tool: tool }),
        React.createElement(EditorTabs, { tabs: [
                { title: "Style", contents: React.createElement(StyleSection, Object.assign({}, props)) },
                { title: "Configure", contents: React.createElement(ConfigureSection, Object.assign({}, props)) },
            ] })));
}
