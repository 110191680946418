import React, { useRef } from "react";
import styled from "styled-components";
import { imageTypes, uploadMedia } from "../../../../../helpers/media";
import { ShapeSelector } from "../../../../pages/builder/editor/atomic/shape-selector";
import { ControlLabel } from "../../../../styled/editor-components";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import { newTheme } from "../../../../styled/theme";
import DeleteIcon from "../../../../../../assets/images/v3/red-trashcan.svg";
export const LogoUploadSection = ({ updateFn, logo, size = "80" }) => {
    const inputFile = useRef(null);
    return (React.createElement("div", null,
        React.createElement("input", { multiple: false, type: "file", accept: `${imageTypes.join("|")}`, ref: inputFile, style: { display: "none" }, onChange: e => {
                void uploadMedia(e.target.files, (media) => {
                    var _a;
                    updateFn(Object.assign(Object.assign({}, logo), { src: (_a = media[0]) === null || _a === void 0 ? void 0 : _a.src, isEnabled: true }));
                });
            } }),
        logo.src ? (React.createElement(React.Fragment, null,
            React.createElement(LogoControlsContainer, null,
                React.createElement(LogoContainer, null,
                    React.createElement("img", { src: logo.src, alt: "logo" }),
                    React.createElement(ReplaceLogoContainer, null,
                        React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY, onClick: () => {
                                inputFile.current.click();
                            } }, "Replace Logo")))),
            React.createElement(RemoveImage, { onClick: () => {
                    updateFn(Object.assign(Object.assign({}, logo), { src: "" }));
                } },
                React.createElement("img", { src: DeleteIcon }),
                "Remove Image"),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Shape"),
                React.createElement(ShapeSelector, { square: true, shape: logo.shape, onChange: e => {
                        updateFn(Object.assign(Object.assign({}, logo), { shape: e }));
                    } })))) : (React.createElement(LogoUploadContainer, null,
            React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY, onClick: () => {
                    inputFile.current.click();
                } }, "Add Logo"),
            React.createElement("span", null,
                "Recommended size: ",
                size,
                " x ",
                size,
                "px")))));
};
const ControlContainer = styled.div `
  display: flex;
  margin-top: 16px;
  height: 32px;
  align-items: center;
`;
export const LogoControlsContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LogoContainer = styled.div `
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100px;
  height: 100px;
  overflow: hidden;
  box-shadow: ${newTheme.shadows.outside60};
  > img {
    max-height: 100px;
    max-width: 100px;
  }
  > video {
    max-height: 100px;
    max-width: 100px;
  }
  &:hover {
    > div {
      display: flex;
    }
  }
`;
export const ReplaceLogoContainer = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
`;
export const LogoUploadContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 0;
  > span {
    margin-top: 8px;
    font: ${newTheme.fonts.small};
  }
`;
export const RemoveImage = styled.div `
  padding: 8px 0 4px 0;
  img {
    width: 20px;
    margin-right: 5px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${newTheme.colors.red500};
  cursor: pointer;
`;
