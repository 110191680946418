import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { BorderShape } from "../../../../../../../shared/types/tool-data-types/common";
export const HTShapeSelectorLarge = (props) => {
    const { onChange, shape } = props;
    const [selectedShape, setSelectedShape] = useState(BorderShape.RECTANGLE);
    useEffect(() => {
        setSelectedShape(shape);
    }, [shape]);
    const handleClick = (shape) => {
        setSelectedShape(shape);
        onChange(shape);
    };
    return (React.createElement(ShapeContainer, { className: "shape-selector-large" },
        React.createElement(StyledShape, { selected: selectedShape === BorderShape.RECTANGLE, onClick: () => {
                handleClick(BorderShape.RECTANGLE);
            } }, "Rectangle"),
        React.createElement(StyledShape, { selected: selectedShape === BorderShape.ROUNDED, style: { borderRadius: "7px" }, onClick: () => {
                handleClick(BorderShape.ROUNDED);
            } }, "Rounded")));
};
const ShapeContainer = styled.div `
  display: flex;
  width: 75%;
  padding-bottom: 16px;
  margin: auto;
  gap: 16px;
`;
const StyledShape = styled.div `
  flex: 1;
  font: ${newTheme.fonts.small};
  color: ${props => (props.selected ? newTheme.colors.blue : newTheme.colors.grey600)};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background-color: ${props => (props.selected ? newTheme.colors.blue100 : newTheme.colors.white)};
  border: ${props => (props.selected ? "2px" : "1px")} solid
    ${props => (props.selected ? newTheme.colors.blue : newTheme.colors.grey500)};
  box-shadow: 0px 0px 4px ${props => (props.selected ? newTheme.colors.blue300 : newTheme.colors.grey100)};
  cursor: pointer;
`;
