import { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { TourialApiCalls } from "../helpers/tourial-api-calls";
import { cloneDeep } from "lodash";
import { HOME_WORKSPACE_PARAM } from "../../../shared/types/account";
import { useQueryParam } from "./use-query-param";
export const useFetchWorkspaces = () => {
    const [workspaces, setWorkspaces] = useState([]);
    const reload = useQueryParam("reload-list");
    const asyncFetchWorkspaces = useAsync({
        deferFn: TourialApiCalls.Workspaces.listWorkspaces,
        onResolve: data => {
            if (data && Array.isArray(data)) {
                // update default _id to {HOME_WORKSPACE_PARAM}
                const updatedData = cloneDeep(data);
                updatedData.find(ws => !ws._id)._id = HOME_WORKSPACE_PARAM;
                setWorkspaces(updatedData);
            }
        },
    });
    const refreshWorkspaces = () => {
        asyncFetchWorkspaces.reload(); // use reload instead of run
    };
    // Initial fetch
    useEffect(() => {
        asyncFetchWorkspaces.run();
    }, []);
    useEffect(() => {
        if (reload) {
            refreshWorkspaces();
        }
    }, [reload]);
    return {
        workspaces,
        error: asyncFetchWorkspaces.error,
        isLoading: asyncFetchWorkspaces.isLoading,
    };
};
