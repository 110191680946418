export var ModalButtonType;
(function (ModalButtonType) {
    ModalButtonType["NORMAL"] = "NORMAL";
    ModalButtonType["JUMBO"] = "JUMBO";
})(ModalButtonType || (ModalButtonType = {}));
export var ModalType;
(function (ModalType) {
    ModalType["TEXT"] = "Text";
    ModalType["PERSONAS"] = "Personas";
    ModalType["VIDEO"] = "Video";
    ModalType["IMAGE"] = "Image";
})(ModalType || (ModalType = {}));
