import { cloneDeep } from "lodash";
import React, { useEffect } from "react";
import styled from "styled-components";
import { BorderShape } from "../../../../../../shared/types/tool-data-types/common";
import { hexToRGB } from "../../../../helpers/misc";
import { positionEq } from "../../../../helpers/tooltip-v2-size-and-position-helpers";
import { useInteraction } from "../../../../redux/selectors/interaction-selectors";
import { useAutoUpdateTool, useSetBuilderFocusField, useUpdateTool } from "../../../../redux/selectors/redux-selectors";
import { TourialEditorFocusFields } from "../../../../types/definitions";
import { withFlexibleToolWrapper } from "../../../renderer/wrapper/with-flexible-tool-wrapper";
export function GlobalButtonsView(props) {
    const { toolData: tool, isInEditViewport } = props;
    const updateTool = useUpdateTool();
    function drag(position) {
        if (!positionEq(tool.position, position)) {
            const t = cloneDeep(tool);
            t.position = position;
            updateTool(t);
        }
    }
    return withFlexibleToolWrapper(Inner, {
        isEditing: isInEditViewport,
        isSelectable: true,
        isResizable: false,
        isDraggable: true,
        isAspectRatioLocked: false,
        isStaticSize: true,
        shouldShowSelectionTab: true,
    }, {
        bounds: ".tourial-viewport",
        size: tool.size,
        position: tool.position,
    }, {
        onDrag: drag,
        onDragStop: drag,
        onResize: () => { },
        onResizeStop: () => { },
    })(props);
}
const Inner = (props) => {
    const { toolData, style } = props;
    const { buttons, background } = toolData;
    const { button1, button2, button3, button4, shape } = buttons;
    const id = `${toolData.id}-global-buttons`;
    const button1Click = useInteraction(props, toolData.buttons.button1.interaction);
    const button2Click = useInteraction(props, toolData.buttons.button2.interaction);
    const button3Click = useInteraction(props, toolData.buttons.button3.interaction);
    const button4Click = useInteraction(props, toolData.buttons.button4.interaction);
    useResizeGlobalButtonContainerToFitContents(toolData, id);
    const bg = hexToRGB(background.backgroundColor);
    const bdr = hexToRGB(background.borderColor);
    const setBuilderFocusField = useSetBuilderFocusField();
    return (React.createElement(GlobalButtonsContainer, { id: id, style: Object.assign({ backgroundColor: bg ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, ${background.opacity})` : `rgba(0,0,0,0)`, borderColor: bdr ? `rgba(${bdr.r}, ${bdr.g}, ${bdr.b}, ${background.opacity})` : `rgba(0,0,0,0)`, borderRadius: borderRadii[background.shape], boxShadow: background.isShadowEnabled ? boxShadow : "none" }, style) },
        React.createElement(ButtonContainer, null,
            button1.isEnabled && (React.createElement(Button, { shape: shape, config: button1, onClick: () => button1Click(), onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.GLOBAL_BUTTON_1) }, button1.text)),
            button2.isEnabled && (React.createElement(Button, { shape: shape, config: button2, onClick: () => button2Click(), onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.GLOBAL_BUTTON_2) }, button2.text)),
            button3.isEnabled && (React.createElement(Button, { shape: shape, config: button3, onClick: () => button3Click(), onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.GLOBAL_BUTTON_3) }, button3.text)),
            button4.isEnabled && (React.createElement(Button, { shape: shape, config: button4, onClick: () => button4Click(), onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.GLOBAL_BUTTON_4) }, button4.text)))));
};
const GlobalButtonsContainer = styled.div `
  position: absolute;
  border-style: solid;
  border-width: 1px;
  min-height: 30px;
  min-width: 30px;
`;
const ButtonContainer = styled.div `
  display: flex;
  justify-content: center;
`;
const boxShadow = "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1), 0px 0px 2px rgba(48, 49, 51, 0.5)";
const borderRadii = {
    [BorderShape.RECTANGLE]: 2,
    [BorderShape.ROUNDED]: 14,
    [BorderShape.SQUIRCLE]: 56,
};
const buttonBorderRadii = {
    [BorderShape.RECTANGLE]: "1px",
    [BorderShape.ROUNDED]: "8px",
    [BorderShape.SQUIRCLE]: "20px",
};
const Button = styled.button `
  margin: 8px;
  padding: 8px 16px;
  background-color: ${p => p.config.backgroundColor};
  color: ${p => p.config.textColor};
  border-style: solid;
  border-width: 1px;
  border-color: ${p => p.config.borderColor};
  border-radius: ${p => buttonBorderRadii[p.shape]};
  cursor: pointer;
  white-space: nowrap;
`;
function useResizeGlobalButtonContainerToFitContents(tool, id) {
    const autoUpdateTool = useAutoUpdateTool();
    useEffect(() => {
        var _a, _b;
        const height = (_a = document === null || document === void 0 ? void 0 : document.getElementById(id)) === null || _a === void 0 ? void 0 : _a.clientHeight;
        const width = (_b = document === null || document === void 0 ? void 0 : document.getElementById(id)) === null || _b === void 0 ? void 0 : _b.clientWidth;
        if (height && height !== tool.size.height) {
            const next = cloneDeep(tool);
            next.size.height = height;
            autoUpdateTool(next);
        }
        if (width && width !== tool.size.width) {
            const next = cloneDeep(tool);
            next.size.width = width;
            autoUpdateTool(next);
        }
    });
}
