import React from "react";
import { BodyText } from "../subcomponents/body-text";
import { HeaderText } from "../subcomponents/header-text";
import { Inputs } from "../subcomponents/inputs";
import { Logo } from "../subcomponents/logo";
import { SubmitButton } from "../subcomponents/submit-button";
export function Layout1({ toolData, viewportDimensions, runAfterSubmissionFn }) {
    return (React.createElement("div", { style: {
            padding: `${toolData.isDismissable ? "0%" : "2%"} 7% 2% 7%`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            height: "100%",
        } },
        React.createElement("div", { style: {
                display: "flex",
                maxHeight: "20%",
            } },
            toolData.logo.isVisible && React.createElement(Logo, { toolData: toolData, viewportDimensions: viewportDimensions }),
            React.createElement("div", { style: {
                    paddingLeft: toolData.logo.isVisible ? "2%" : 0,
                    flexGrow: 1,
                    overflow: "hidden",
                } },
                React.createElement(HeaderText, { toolData: toolData, viewportDimensions: viewportDimensions }),
                React.createElement(BodyText, { toolData: toolData, viewportDimensions: viewportDimensions, customStyle: { width: "95%" } }))),
        React.createElement("div", { className: "hide-scrollbar", style: {
                margin: "2% 0%",
                overflowY: "scroll",
            } },
            React.createElement(Inputs, { toolData: toolData, viewportDimensions: viewportDimensions })),
        React.createElement("div", { style: { display: "flex", width: "100%", justifyContent: "center" } },
            React.createElement("div", { style: { width: "fit-content", maxWidth: "100%" } },
                React.createElement(SubmitButton, { toolData: toolData, viewportDimensions: viewportDimensions, customStyle: { width: "100%" }, runAfterSubmissionFn: runAfterSubmissionFn })))));
}
