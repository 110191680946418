import React, { useRef } from "react";
import { useRefDimensions } from "../../../hooks/viewport-hooks";
import styled from "styled-components";
import { newTheme } from "../../styled/theme";
import { useIsEmbedHeaderEnabled, useSetViewportMode, useTools } from "../../../redux/selectors/redux-selectors";
import { RenderTools } from "../render-tools";
import { ScreenV2View } from "../../tools/screen/screen-v2-view";
import { PageFormV3, TourFormV3 } from "../fetch-and-render-form-v3";
import { ViewportMode } from "../../../types/definitions";
import useTimeline from "../../../hooks/use-timeline-hook";
import { StoryboardMiniStubStepPanel } from "../../pages/builder/storyboard-mini/storyboard-mini-stub-step-panel";
import { ViewportHeader } from "../live/viewport-header";
import { useIsEditModeBlocked } from "../../../hooks/use-is-collaborator-blocked";
import TimelineProgressBar from "../timeline-progress-bar";
export function PreviewViewport() {
    const isEditModeBlocked = useIsEditModeBlocked();
    const viewportRef = useRef(null);
    const viewportDimensions = useRefDimensions(viewportRef);
    const setViewportMode = useSetViewportMode();
    const isEmbedHeaderEnabled = useIsEmbedHeaderEnabled();
    const tools = useTools();
    const baseViewProps = {
        viewportDimensions,
        disableInteractions: false,
        disableAnimations: false,
        disableEventTracking: true,
        isInEditViewport: false,
    };
    const timeline = useTimeline({ baseViewProps });
    return (React.createElement(React.Fragment, null,
        isEmbedHeaderEnabled && (React.createElement(ViewportHeader, { style: { marginTop: 20, width: viewportDimensions.width ? viewportDimensions.width : "auto" } })),
        React.createElement(Div, { id: "tourial-preview-viewport", className: "tourial-viewport", ref: viewportRef, onDragOver: e => e.preventDefault(), style: {
                height: viewportDimensions.height ? viewportDimensions.height : "auto",
                width: viewportDimensions.width ? viewportDimensions.width : "auto",
            } },
            React.createElement(ScreenV2View, Object.assign({}, Object.assign(Object.assign({}, baseViewProps), { toolData: tools.screen, timeline }))),
            React.createElement(RenderTools, { baseViewProps: baseViewProps }),
            React.createElement(TimelineProgressBar, { timeline: timeline }),
            React.createElement(TourFormV3, { baseViewProps: baseViewProps }),
            !isEmbedHeaderEnabled && React.createElement(PageFormV3, null)),
        React.createElement(CurrentStepPanelContainer, null,
            React.createElement(StoryboardMiniStubStepPanel, null),
            !isEditModeBlocked && (React.createElement(ExitPreviewLink, { onClick: () => setViewportMode(ViewportMode.EDIT) }, "Exit preview")))));
}
const Div = styled.div `
  background-color: ${newTheme.colors.white};
  position: relative;
  overflow: hidden;
`;
const CurrentStepPanelContainer = styled.div `
  position: fixed;
  bottom: 0px;
  margin-bottom: 20px;
  padding: 10px 16px;
  height: 40px;
  background: ${newTheme.colors.white};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  box-shadow: ${newTheme.shadows.outside60};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ExitPreviewLink = styled.div `
  cursor: pointer;
  font: ${newTheme.fonts.small};
  line-height: 20px;
  border-left: 1px solid ${newTheme.colors.grey500};
  margin-left: 10px;
  padding-left: 10px;
  color: ${newTheme.colors.blue500};
`;
