import React from "react";
import { getArrowOffsetPx } from "../../../../../helpers/tooltip-v2-size-and-position-helpers";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ShirtSize } from "../../../../../../../shared/types/tool-data-types/common";
export function BubbleArrow(props) {
    const { toolData: tool, bubblePosition } = props;
    const [arrowXYR, setArrowXYR] = useState(getArrowOffsetPx(tool));
    useEffect(() => {
        setArrowXYR(getArrowOffsetPx(tool));
    }, [
        bubblePosition,
        tool.bubble.size,
        tool.trigger.position,
        tool.trigger.clickzone.size,
        tool.bubble.arrow.position,
    ]);
    return (React.createElement(Arrow, { className: "bubble-arrow", style: {
            backgroundColor: tool.bubble.background.backgroundColor,
            borderColor: `${tool.bubble.background.borderColor} ${tool.bubble.background.borderColor} ${tool.bubble.background.backgroundColor} ${tool.bubble.background.backgroundColor} `,
            height: arrowSizes[tool.bubble.arrow.size],
            width: arrowSizes[tool.bubble.arrow.size],
            transform: `rotate(${arrowXYR.r}deg)`,
            left: arrowXYR.x,
            top: arrowXYR.y,
        } }));
}
export const arrowSizes = {
    [ShirtSize.SMALL]: 16,
    [ShirtSize.MEDIUM]: 20,
    [ShirtSize.LARGE]: 24,
};
const Arrow = styled.div `
  position: absolute;
  border-width: 1px;
  border-style: solid;
`;
