var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useEffect, useRef, useState } from "react";
import { HTArrowPosition, HTMode } from "../../../../../../../shared/types/dom-tour";
import { useHTGotoNextStep, useHTGotoPrevStep, useHTIsMobile, useHTIsResizingTool, useHTMode, useHTScale, useHTSetIsResizingTool, useHTStep, useHTStepCount, useHTStepIndex, useHTTheme, useHTTour, } from "../../../../../redux/selectors/ht-selectors";
import { Rnd } from "react-rnd";
import { resizeHandles } from "../../../../../legacy/components/builder/resize-handle";
import { DraftContext } from "../dom-tour-builder-page";
import { Hotspot } from "./hotspot";
import { 
// htBubbleAnimationBuilderOpen,
makeSelector, selectAnchor, selectScreen, updateTooltipPosition, } from "../../helpers/dom-tour-helpers";
import styled from "styled-components";
import { HOTSPOT_SIZE, TOOLTIP_MAX_WIDTH } from "../../config";
import { cloneDeep, debounce } from "lodash";
import { htBodyFontSizes, htHeadlineFontSizes, htTooltipBorderRadii } from "../shared";
import { TextAlign, TextStyle } from "../../../../../../../shared/types/tool-data-types/common";
import { textAlignments } from "../../../../../helpers/text-helpers";
import { FloatingArrow, arrow, detectOverflow, flip, offset, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole, useTransitionStyles, } from "@floating-ui/react";
import { useSetBuilderFocusField } from "../../../../../redux/selectors/redux-selectors";
import { isMarkupTextEmpty } from "../../../../../helpers/dom-helpers";
import { TourialEditorFocusFields } from "../../../../../types/definitions";
import parse from "html-react-parser";
import { BubbleButtonContainer, BubbleButtonWrapper, bubbleButtonBorderRadii, bubbleButtonSidePadding, } from "../../../../tools/tooltip-v2/view/subtool-components/bubble-styled";
import { TooltipButtonStyle } from "../../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import HTClickzone from "./ht-clickzone";
const TOOLTIP_GAP = 25; // the pixel distance of tooltip from hotspot/clickzone
export function HTTooltip() {
    var _a;
    const mode = useHTMode();
    const draft = useContext(DraftContext);
    const step = useHTStep();
    const stepIndex = useHTStepIndex();
    const tour = useHTTour();
    const scale = useHTScale();
    const isClickzone = (_a = step.tooltip.clickzone) === null || _a === void 0 ? void 0 : _a.isEnabled;
    // handle resize
    const isResizing = useHTIsResizingTool();
    const setIsResizing = useHTSetIsResizingTool();
    const [toolWidth, setToolWidth] = useState(isClickzone ? step.tooltip.clickzone.size.width : HOTSPOT_SIZE);
    const [toolHeight, setToolHeight] = useState(isClickzone ? step.tooltip.clickzone.size.height : HOTSPOT_SIZE);
    useEffect(() => {
        setToolWidth(isClickzone ? step.tooltip.clickzone.size.width : HOTSPOT_SIZE);
        setToolHeight(isClickzone ? step.tooltip.clickzone.size.height : HOTSPOT_SIZE);
    }, [isClickzone]);
    // handle dragging
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    // tooltip actions
    const stepCount = useHTStepCount();
    const gotoNextStep = useHTGotoNextStep();
    const goToPrevStep = useHTGotoPrevStep();
    const setBuilderFocusField = useSetBuilderFocusField();
    const theme = useHTTheme();
    const { previousButton, nextButton, stepCounter, backgroundColor } = theme.tooltip;
    const isMobile = useHTIsMobile();
    // handle floating tooltip
    const arrowRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isHotspotVisible, setIsHotspotVisible] = useState(true);
    const delaySetIsOpen = debounce(setIsOpen, 500);
    useEffect(() => {
        return () => {
            delaySetIsOpen.cancel();
        };
    }, [delaySetIsOpen]);
    const { refs, floatingStyles, context, update } = useFloating({
        transform: false,
        open: isOpen,
        placement: step.tooltip.arrowPosition !== HTArrowPosition.AUTO ? step.tooltip.arrowPosition : "top",
        middleware: [
            offset(TOOLTIP_GAP),
            flip({
                fallbackAxisSideDirection: "start",
            }),
            shift(),
            arrow({ element: arrowRef }),
            {
                name: "checkOverflow",
                fn(state) {
                    return __awaiter(this, void 0, void 0, function* () {
                        const overflow = yield detectOverflow(state, { elementContext: "reference" });
                        const isHidden = overflow.bottom > toolHeight ||
                            overflow.top > toolHeight ||
                            overflow.left > toolWidth ||
                            overflow.right > toolWidth;
                        setIsHotspotVisible(!isHidden);
                        // TODO: fix warning here! - it is updating a param to this hook
                        delaySetIsOpen(!isHidden);
                        return state;
                    });
                },
                options: [isClickzone, toolWidth, toolHeight],
            },
        ],
    });
    const hover = useHover(context, { move: false, enabled: !isMobile });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: "tooltip" });
    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);
    // handle tooltip positioning relative to anchor
    useEffect(() => {
        if (step.tooltip.anchor) {
            const { contentWindow } = selectScreen();
            const handleScroll = () => {
                updateTooltipPosition(setX, setY, step.tooltip.anchor);
                update();
            };
            handleScroll(); // set initial position
            contentWindow.addEventListener("scroll", handleScroll, true);
            const timedScroll = setTimeout(() => {
                var _a;
                (_a = selectAnchor(step.tooltip.anchor)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "nearest",
                });
            }, 250);
            return () => {
                clearTimeout(timedScroll);
                contentWindow.removeEventListener("scroll", handleScroll, true);
            };
        }
        else {
            // default anchor position
            setX(10);
            setY(10);
        }
    }, [step]);
    useEffect(() => update(), [step.tooltip]);
    const { styles: transitionStyles } = useTransitionStyles(context, {
        close: () => {
            return {
                opacity: 0,
                transition: `opacity .2s ease-out`,
                pointerEvents: "none",
            };
        },
        // TODO: low priority - fix this for tooltip bounce-in animation, it currently breaks vertical resizing of clickzone
        // open: ({ side }) => {
        //   return htBubbleAnimationBuilderOpen(side, floatingStyles);
        // },
    });
    const isBodyEmpty = isMarkupTextEmpty(step.tooltip.body);
    return (React.createElement(Rnd, { "data-cy": "ht-tooltip", scale: scale, bounds: "parent", position: { x: getCenterX(x, true), y: getCenterY(y, true) }, size: {
            width: toolWidth,
            height: toolHeight,
        }, style: { zIndex: 2 }, enableResizing: mode === HTMode.EDIT && isClickzone ? true : false, disableDragging: mode === HTMode.EDIT ? false : true, resizeHandleComponent: resizeHandles(false), onResizeStart: () => {
            setIsResizing(true);
        }, onResize: (_, __, ref) => {
            setToolWidth(ref.offsetWidth);
            setToolHeight(ref.offsetHeight);
        }, onResizeStop: (_e, direction, ref) => {
            handleClickzoneResize(ref, direction);
            setIsResizing(false);
        }, onDragStart: (_, d) => {
            setIsDragging(true);
            const centerX = getCenterX(d.x);
            const centerY = getCenterY(d.y);
            setX(centerX);
            setY(centerY);
        }, onDrag: (e, d) => {
            e.preventDefault();
            e.stopPropagation();
            const centerX = getCenterX(d.x);
            const centerY = getCenterY(d.y);
            setX(centerX);
            setY(centerY);
        }, onDragStop: (_, d) => {
            const centerX = getCenterX(d.x);
            const centerY = getCenterY(d.y);
            setX(centerX);
            setY(centerY);
            setAnchorAt(tour, stepIndex, centerX, centerY);
            setIsDragging(false);
        } },
        React.createElement(Container, Object.assign({ mode: mode, isDragging: isDragging, ref: refs.setReference }, getReferenceProps()),
            isClickzone ? (React.createElement(HTClickzone, { size: { width: toolWidth, height: toolHeight } })) : (React.createElement(Hotspot, { isHotspotVisible: isHotspotVisible })),
            !isMobile && !isResizing && (React.createElement(Bubble, Object.assign({ mode: mode, className: "Tooltip", theme: theme, ref: refs.setFloating, style: Object.assign(Object.assign({}, floatingStyles), transitionStyles) }, getFloatingProps()),
                !!step.tooltip.header && (React.createElement(Header, { theme: theme, isBodyEmpty: isBodyEmpty, onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.BUBBLE_HEADLINE_TEXT) }, step.tooltip.header)),
                !isBodyEmpty && (React.createElement(Body, { theme: theme, onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.BUBBLE_BODY_TEXT) }, parse(step.tooltip.body))),
                ((previousButton === null || previousButton === void 0 ? void 0 : previousButton.isEnabled) || (nextButton === null || nextButton === void 0 ? void 0 : nextButton.isEnabled) || (stepCounter === null || stepCounter === void 0 ? void 0 : stepCounter.isEnabled)) && (React.createElement(BubbleButtonContainer, { buttonAlign: TextAlign.CENTER },
                    ((stepCounter === null || stepCounter === void 0 ? void 0 : stepCounter.isEnabled) || (previousButton === null || previousButton === void 0 ? void 0 : previousButton.isEnabled)) && (React.createElement(BubbleButtonWrapper, { shouldFillSpace: stepCounter === null || stepCounter === void 0 ? void 0 : stepCounter.isEnabled, isButtonEnabled: previousButton === null || previousButton === void 0 ? void 0 : previousButton.isEnabled, style: {
                            justifyContent: "flex-start",
                            marginTop: 8,
                            height: `${htBodyFontSizes[theme.tooltip.body.size] * 2}px`,
                        } },
                        React.createElement(BubbleButtonLeft, { onClick: goToPrevStep, theme: theme, button: previousButton, text: step.tooltip.prevButtonText }))),
                    (stepCounter === null || stepCounter === void 0 ? void 0 : stepCounter.isEnabled) && (React.createElement(StepcounterContainer, { style: { flex: !(nextButton === null || nextButton === void 0 ? void 0 : nextButton.isEnabled) && !(previousButton === null || previousButton === void 0 ? void 0 : previousButton.isEnabled) ? 1 : 0 } },
                        React.createElement(StepCounterNumbers, { theme: theme }, `${stepIndex + 1}/${stepCount}`))),
                    ((stepCounter === null || stepCounter === void 0 ? void 0 : stepCounter.isEnabled) || (nextButton === null || nextButton === void 0 ? void 0 : nextButton.isEnabled)) && (React.createElement(BubbleButtonWrapper, { shouldFillSpace: (stepCounter === null || stepCounter === void 0 ? void 0 : stepCounter.isEnabled) || (!(stepCounter === null || stepCounter === void 0 ? void 0 : stepCounter.isEnabled) && !(previousButton === null || previousButton === void 0 ? void 0 : previousButton.isEnabled)), isButtonEnabled: nextButton === null || nextButton === void 0 ? void 0 : nextButton.isEnabled, style: {
                            justifyContent: "flex-end",
                            marginTop: 8,
                            height: `${htBodyFontSizes[theme.tooltip.body.size] * 2}px`,
                        } },
                        React.createElement(BubbleButtonRight, { onClick: gotoNextStep, theme: theme, button: nextButton, text: step.tooltip.nextButtonText }))))),
                React.createElement(FloatingArrow, { ref: arrowRef, context: context, fill: backgroundColor, height: 12, width: 22 }))))));
    function setAnchorAt(tour, stepIndex, x, y, ref) {
        const screen = selectScreen();
        const anchor = screen.document.elementFromPoint(x, y);
        const selector = makeSelector(anchor);
        const anchorRect = anchor.getBoundingClientRect();
        const xOffset = x - anchorRect.x;
        const yOffset = y - anchorRect.y;
        const steps = cloneDeep(tour.steps);
        steps[stepIndex].tooltip.anchor = { selector, xOffset, yOffset };
        if (ref) {
            steps[stepIndex].tooltip.clickzone = Object.assign(Object.assign({}, steps[stepIndex].tooltip.clickzone), { size: { width: ref.offsetWidth, height: ref.offsetHeight } });
        }
        void draft.update({ steps: steps });
    }
    function getCenterX(x, remove = false) {
        if (remove) {
            return x - toolWidth / 2;
        }
        else {
            return x + toolWidth / 2;
        }
    }
    function getCenterY(y, remove = false) {
        if (remove) {
            return y - toolHeight / 2;
        }
        else {
            return y + toolHeight / 2;
        }
    }
    function handleClickzoneResize(ref, direction) {
        direction = direction.toLowerCase();
        const deltaWidth = ref.offsetWidth - step.tooltip.clickzone.size.width;
        const deltaHeight = ref.offsetHeight - step.tooltip.clickzone.size.height;
        let newCenterX = x, newCenterY = y;
        // depending on the resize handle, adjust position to maintain the center
        if (deltaWidth !== 0) {
            if (direction.includes("right")) {
                newCenterX = x + deltaWidth / 2;
            }
            else if (direction.includes("left")) {
                newCenterX = x - deltaWidth / 2;
            }
        }
        if (deltaHeight !== 0) {
            if (direction.includes("bottom")) {
                newCenterY = y + deltaHeight / 2;
            }
            else if (direction.includes("top")) {
                newCenterY = y - deltaHeight / 2;
            }
        }
        // Update local state and anchor/size data
        setX(newCenterX);
        setY(newCenterY);
        setAnchorAt(tour, stepIndex, newCenterX, newCenterY, ref);
    }
}
function BubbleButtonRight({ theme, button, onClick, text }) {
    return (React.createElement("button", { style: {
            fontFamily: theme.tooltip.body.font,
            fontSize: htBodyFontSizes[theme.tooltip.body.size],
            color: button === null || button === void 0 ? void 0 : button.textColor,
            backgroundColor: button === null || button === void 0 ? void 0 : button.backgroundColor,
            borderRadius: bubbleButtonBorderRadii[theme.tooltip.buttonShape],
            justifyContent: "flex-end",
            paddingLeft: 12,
            paddingRight: bubbleButtonSidePadding[TooltipButtonStyle.TEXT],
            height: "100%",
        }, onClick: onClick }, text || ""));
}
function BubbleButtonLeft({ theme, button, onClick, text }) {
    return (React.createElement("button", { style: {
            fontFamily: theme.tooltip.body.font,
            fontSize: htBodyFontSizes[theme.tooltip.body.size],
            color: button === null || button === void 0 ? void 0 : button.textColor,
            backgroundColor: button === null || button === void 0 ? void 0 : button.backgroundColor,
            borderRadius: bubbleButtonBorderRadii[theme.tooltip.buttonShape],
            paddingRight: 12,
            paddingLeft: bubbleButtonSidePadding[TooltipButtonStyle.TEXT],
            height: "100%",
        }, onClick: onClick }, text || ""));
}
const Container = styled.div `
  position: absolute;
  cursor: ${({ mode, isDragging }) => (mode === HTMode.EDIT ? (isDragging ? "grabbing" : "grab") : "pointer")};
`;
const Bubble = styled.div `
  cursor: ${({ mode }) => (mode === HTMode.EDIT ? "inherit" : "default")};
  background-color: ${({ theme }) => theme.tooltip.backgroundColor};
  border-radius: ${({ theme }) => htTooltipBorderRadii[theme.tooltip.shape]}px;
  min-width: 192px;
  min-height: 50px;
  max-width: ${TOOLTIP_MAX_WIDTH}px;
  padding: 20px;
  position: absolute;
  width: max-content;
  top: 0;
  left: 0;
  box-shadow:
    1px 1px 12px 0px rgba(33, 34, 41, 0.12),
    -2px -2px 32px 0px rgba(48, 49, 51, 0.02),
    0px 0px 1px 0px rgba(33, 34, 41, 0.5) inset;
`;
const Header = styled.h3 `
  font-family: ${({ theme }) => theme.tooltip.headline.font};
  color: ${({ theme }) => theme.tooltip.headline.color};
  font-size: ${({ theme }) => htHeadlineFontSizes[theme.tooltip.headline.size]}px;
  font-weight: ${({ theme }) => (theme.tooltip.headline.style === TextStyle.BOLD ? "bold" : "normal")};
  text-decoration: ${({ theme }) => (theme.tooltip.headline.style === TextStyle.UNDERLINE ? "underline" : "none")};
  font-style: ${({ theme }) => (theme.tooltip.headline.style === TextStyle.ITALIC ? "italic" : "normal")};
  margin-bottom: ${({ isBodyEmpty }) => (isBodyEmpty ? 0 : 8)}px;
  text-align: ${({ theme }) => textAlignments[theme.tooltip.headline.align]};
`;
const Body = styled.div `
  font-family: ${({ theme }) => theme.tooltip.body.font};
  color: ${({ theme }) => theme.tooltip.body.color};
  font-size: ${({ theme }) => htBodyFontSizes[theme.tooltip.body.size]}px;
  line-height: 1.5;
  text-align: ${({ theme }) => textAlignments[theme.tooltip.body.align]};
  ul,
  ol {
    margin-left: 20px;
  }
`;
const StepcounterContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StepCounterNumbers = styled.p `
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.tooltip.body.font};
  color: ${({ theme }) => theme.tooltip.stepCounter.color};
  font-size: ${({ theme }) => htBodyFontSizes[theme.tooltip.body.size]}px;
  margin-top: 8px;
`;
