import { cloneDeep } from "lodash";
import React from "react";
import { useUpdateTool, useViewportMode } from "../../../redux/selectors/redux-selectors";
import { withFlexibleToolWrapper } from "../../renderer/wrapper/with-flexible-tool-wrapper";
import { positionEq } from "../../../helpers/tooltip-v2-size-and-position-helpers";
import parse from "html-react-parser";
import { ViewportMode } from "../../../types/definitions";
export const SandboxView = (props) => {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    const viewportMode = useViewportMode();
    function drag(position) {
        if (!positionEq(tool.position, position)) {
            const t = cloneDeep(tool);
            t.position = position;
            updateTool(t);
        }
    }
    return withFlexibleToolWrapper(() => (React.createElement("div", null, parse(props.toolData.innerHtml, {
        replace: function (domNode) {
            if (domNode.type === "script") {
                // @ts-expect-error
                viewportMode !== ViewportMode.EDIT && eval(domNode === null || domNode === void 0 ? void 0 : domNode.children[0].data);
            }
        },
    }))), {
        isEditing: props.isInEditViewport,
        isSelectable: true,
        isResizable: true,
        isDraggable: true,
        isAspectRatioLocked: false,
        isStaticSize: false,
        shouldShowSelectionTab: false,
    }, {
        bounds: ".tourial-viewport",
        minSize: { width: 16, height: 16 },
        size: tool.sizeAndPosition,
        position: tool.position,
    }, {
        onDrag: drag,
        onDragStop: drag,
        onResize: () => { },
        onResizeStop: () => { },
    })(props);
};
