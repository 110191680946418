import React, { useEffect, useState } from "react";
import ToggleOnIcon from "../../../../../assets/images/v3/small-toggle-on.svg";
import ToggleOffIcon from "../../../../../assets/images/v3/small-toggle-off.svg";
import CaretIcon from "../../../../../assets/images/v3/big-caret-up.svg";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
export function EditorAccordion(props) {
    const { children, title, isEnabled, onToggle, isHidden, icon, id, exclusiveOpenId, setExclusiveOpenId, isDisabled } = props;
    const [isOpen, setIsOpen] = useState(props.isInitiallyOpen || props.isAlwaysOpen);
    useEffect(() => {
        if (id && exclusiveOpenId) {
            setIsOpen(exclusiveOpenId === id);
        }
    }, [exclusiveOpenId]);
    const toggleOpen = () => {
        if (props.isAlwaysOpen)
            return;
        if (setExclusiveOpenId === undefined) {
            setIsOpen(!isOpen);
        }
        else if (exclusiveOpenId === id) {
            setExclusiveOpenId("");
            setIsOpen(false);
        }
        else {
            setExclusiveOpenId(id);
            setIsOpen(true);
        }
    };
    if (isHidden)
        return null;
    return (React.createElement("div", { id: id },
        React.createElement(HeaderContainer, { onClick: toggleOpen, style: { cursor: props.isAlwaysOpen ? "inherit" : "pointer" } },
            React.createElement(TitleAndIconContainer, null,
                icon && React.createElement(Img, { src: icon }),
                React.createElement(H3, null, title)),
            React.createElement(Span, null,
                onToggle && !isDisabled && (React.createElement(ToggleButton, { onClick: e => {
                        e.stopPropagation();
                        if (!props.isAlwaysOpen) {
                            if ((isOpen && isEnabled) || (!isOpen && !isEnabled))
                                toggleOpen();
                        }
                        onToggle();
                    } },
                    React.createElement("img", { src: isEnabled ? ToggleOnIcon : ToggleOffIcon, alt: "toggle section on/off" }))),
                !props.isAlwaysOpen && (React.createElement(CollapseExpandButton, { onClick: toggleOpen },
                    React.createElement(CaretImg, { style: { transform: isOpen ? "none" : "rotate(180deg)" }, draggable: "false", src: CaretIcon, alt: "collapse/expand accordion button" }))))),
        isOpen && children && React.createElement(ChildrenContainer, null, children)));
}
const Img = styled.img `
  max-width: 18px;
  margin-right: 8px;
`;
const TitleAndIconContainer = styled.span `
  display: flex;
`;
const HeaderContainer = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  background-color: ${newTheme.colors.white};
  border-bottom: 1px solid ${newTheme.colors.grey300};
`;
const H3 = styled.h3 `
  font: ${newTheme.fonts.normal};
`;
const ChildrenContainer = styled.div `
  background-color: ${newTheme.colors.grey100};
  width: 100%;
  overflow: hidden;
  padding: 10px;
  border-bottom: 1px solid ${newTheme.colors.grey300};
`;
const CaretImg = styled.img `
  transition: transform 0.2s linear;
`;
const CollapseExpandButton = styled.button `
  cursor: pointer;
`;
const ToggleButton = styled.button `
  cursor: pointer;
  margin-right: 16px;
`;
const Span = styled.span `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
