import { debounce } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import { EmbedButtonContainer, EmbedCodeContainer, EmbedTextarea } from "../../../builder/modals/embed-tab";
import { TDropdownSelect } from "../../../../styled/t-dropdown-select";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import { newTheme } from "../../../../styled/theme";
export const EmbedTab = (props) => {
    const { tourialSlug, baseUrlWithSubdomain } = props;
    const [isLightboxEnabled, setIsLightboxEnabled] = useState(false);
    const [wasCopied, setWasCopied] = useState(false);
    const copyToClipboard = debounce(() => {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(makeEmbedCode(baseUrlWithSubdomain, tourialSlug, isLightboxEnabled)).then(function () {
            // TODO: post app event here
            setWasCopied(true);
        }, function (err) {
            console.error("Failed to copy text to clipboard: ", err);
        });
    }, 500);
    return (React.createElement(EmbedTabContainer, { id: "_test_publish-embed-tab" },
        React.createElement(EmbedCodeContainer, null,
            React.createElement(TDropdownSelect, { isSearchable: false, selectedValue: isLightboxEnabled, style: { width: 185 }, onChange: (e) => {
                    setIsLightboxEnabled(e.value);
                }, options: [
                    { value: false, label: "Embed in Page" },
                    { value: true, label: "Tourial Lightbox" },
                ] }),
            isLightboxEnabled && (React.createElement(React.Fragment, null,
                React.createElement("span", null, "Add the class name below on the HTML tag that will trigger the lightbox."),
                React.createElement(EmbedTextarea, { style: { height: 35, marginBottom: 8 } },
                    React.createElement("code", null,
                        "tourial-lightbox-trigger-",
                        tourialSlug)))),
            React.createElement("span", null, "Paste into your webpage where you want the Tourial to display:"),
            React.createElement(EmbedTextarea, null,
                React.createElement("code", null, makeEmbedCode(baseUrlWithSubdomain, tourialSlug, isLightboxEnabled))),
            React.createElement(EmbedButtonContainer, null,
                wasCopied && React.createElement("span", { id: "_test_embed-code-copied" }, "Copied!"),
                React.createElement(TButton, { id: "_test_embed-code-copy-button", variant: TButtonVariants.SECONDARY, size: "medium", onClick: () => {
                        copyToClipboard();
                        setWasCopied(true);
                    } }, "Copy Embed Code")))));
};
export function makeEmbedCode(baseUrlWithSubdomain, tourialSlug, isLightboxEnabled) {
    const embedScriptSrc = `${baseUrlWithSubdomain}dt-embed.js`;
    const lightboxScriptSrc = `${baseUrlWithSubdomain}dt-lightbox.js`;
    if (isLightboxEnabled) {
        return `
        <script 
          type="text/javascript" 
          src="${lightboxScriptSrc}" 
          tourialSlug="${tourialSlug}"
          baseUrlWithSubdomain="${baseUrlWithSubdomain}" 
          id="tourial-lightbox-script"
        ></script>
      `;
    }
    return `
        <script type="text/javascript" src="${embedScriptSrc}" tourialSlug="${tourialSlug}"></script>
        <div
          style="position: relative; width: 100%; padding: 0; padding-bottom: 56.25%"
          id="tourial-${tourialSlug}"
        >
          <iframe
            allowfullscreen
            style="display: block; position: absolute; min-height: 100%; min-width: 100%; padding: 0; margin: 0;"
            frameborder="0"
            scrolling="no"
            id="tourial-desktop-iframe"
          ></iframe>
            <script>
          document.getElementById('tourial-${tourialSlug}').getElementsByTagName('iframe')[0].src = \`${baseUrlWithSubdomain}dt/${tourialSlug}?\$\{window.location.search.slice(1)\}&isEmbedded=true\`
        </script>
        </div>
        `;
}
const EmbedTabContainer = styled.div `
  width: 658px;
  padding: 24px;
  background-color: ${newTheme.colors.grey100};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  color: ${newTheme.colors.grey800};
`;
