import React from "react";
import styled from "styled-components";
import Spinner from "../../../assets/images/v3/spinner.svg";
export const TLoadingSpinner = () => {
    return (React.createElement(LoadingContainer, null,
        React.createElement("img", { src: Spinner, className: "_test_loading_spinner" })));
};
const LoadingContainer = styled.div `
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
