import React, { useState } from "react";
import styled from "styled-components";
import { LabelledTextInput } from "../editor/atomic/labelled-text-input";
import SocialSearchIcon from "../../../../../assets/images/v3/search-icon.svg";
import { newTheme } from "../../../styled/theme";
import { useHtmlMetadata, useUpdateTourialPartial } from "../../../../redux/selectors/redux-selectors";
export const MetadataTab = () => {
    var _a, _b;
    const updateTourialPartial = useUpdateTourialPartial();
    const htmlMetadata = useHtmlMetadata();
    const [ogTitle, setOgTitle] = useState(((_a = htmlMetadata.find(m => m.property === "og:title")) === null || _a === void 0 ? void 0 : _a.content) || "");
    const [ogDescription, setOgDescription] = useState(((_b = htmlMetadata.find(m => m.property === "og:description")) === null || _b === void 0 ? void 0 : _b.content) || "");
    const saveMetadata = () => {
        const htmlMetadata = [];
        const titleIndex = htmlMetadata.findIndex(m => m.property === "og:title");
        const descriptionIndex = htmlMetadata.findIndex(m => m.property === "og:description");
        if (titleIndex > -1) {
            htmlMetadata[titleIndex].content = ogTitle;
        }
        else {
            htmlMetadata.push({
                property: "og:title",
                content: ogTitle,
            });
        }
        if (descriptionIndex > -1) {
            htmlMetadata[descriptionIndex].content = ogDescription;
        }
        else {
            htmlMetadata.push({
                property: "og:description",
                content: ogDescription,
            });
        }
        updateTourialPartial({
            htmlMetadata: [...htmlMetadata],
        });
    };
    return (React.createElement(SocialTab, { id: "_test_publish-social-tab" },
        React.createElement(TabHeader, null,
            React.createElement("img", { src: SocialSearchIcon }),
            React.createElement("h2", null, "Social tags")),
        React.createElement("div", { style: { marginBottom: "16px" } }, "Add your Open Graph Title and Description tags for sharing on social media. The preview image will be generated from the first screen of your Tourial."),
        React.createElement(LabelledTextInput, { name: "Title tag", value: ogTitle, placeholder: "og: title", style: { marginBottom: "16px" }, onChange: (e) => {
                setOgTitle(e.target.value);
            }, onBlur: saveMetadata }),
        React.createElement(LabelledTextInput, { name: "Description tag", value: ogDescription, placeholder: "og: description", onChange: (e) => {
                setOgDescription(e.target.value);
            }, onBlur: saveMetadata })));
};
const SocialTab = styled.div `
  width: 658px;
  padding: 24px;
  background-color: ${newTheme.colors.grey100};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  color: ${newTheme.colors.grey800};
`;
const TabHeader = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  > h2 {
    margin: 0 16px 0 8px;
  }
`;
