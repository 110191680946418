import React from "react";
import styled from "styled-components";
import { DCIcon } from "../../components/dc-icon";
import { newTheme } from "../../../../styled/theme";
import { DCCMEditableText } from "./dc-cm-editable-text";
export const DCCMInfoEditor = ({ value, icons, onChange, textarea, isBold, placeholder, autoFocus, }) => {
    const [isEditing, setIsEditing] = React.useState(autoFocus);
    return (React.createElement(EditorContainer, { onClick: () => !isEditing && setIsEditing(true) },
        !!icons && (React.createElement(IconContainer, null,
            React.createElement(DCIcon, { type: value ? icons[1] : icons[0] }))),
        React.createElement(DCCMEditableText, { value: value, onChange: onChange, onCommit: () => setIsEditing(false), isEditing: isEditing, style: {
                font: isBold ? newTheme.fonts.mediumBold : newTheme.fonts.medium,
                color: newTheme.colors.grey900,
            }, textarea: textarea, placeholder: placeholder })));
};
const EditorContainer = styled.div `
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
  cursor: pointer;
`;
const IconContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;
