import { useHistory } from "react-router-dom";
import HomeGreyIcon from "../../../../../assets/images/v3/home-grey.svg";
import React from "react";
import styled from "styled-components";
import { HomePageTabs } from "../../../../../../shared/types/tourial-model";
export function BackButton() {
    const history = useHistory();
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { id: "_test_builder-back-to-homepage-button", onClick: () => {
                history.push(`/?selectedTab=${HomePageTabs.MEDIA}`);
            } },
            React.createElement("img", { draggable: "false", src: HomeGreyIcon, alt: "back button" }))));
}
const Button = styled.button `
  width: 45px;
  height: 45px;
  cursor: pointer;
`;
