import React from "react";
import styled from "styled-components";
import GreyInfoIcon from "../../../assets/images/v3/info-grey.svg";
import BlueInfoIcon from "../../../assets/images/v3/blue-tooltip-info.svg";
import GreenCheckIcon from "../../../assets/images/v3/green-check-circle.svg";
import YellowInfoIcon from "../../../assets/images/v3/yellow-attention.svg";
import RedInfoIcon from "../../../assets/images/v3/red-attention.svg";
import { newTheme } from "./theme";
export var TMessageBoxVariants;
(function (TMessageBoxVariants) {
    TMessageBoxVariants["DEFAULT"] = "default";
    TMessageBoxVariants["SECONDARY"] = "secondary";
    TMessageBoxVariants["SUCCESS"] = "success";
    TMessageBoxVariants["WARNING"] = "warning";
    TMessageBoxVariants["DANGER"] = "danger";
})(TMessageBoxVariants || (TMessageBoxVariants = {}));
export const TMessageBox = (props) => {
    const { message, variant, size } = props;
    return (React.createElement(MessageContainer, Object.assign({}, props),
        React.createElement(IconBox, null,
            React.createElement("img", { src: icons[variant || TMessageBoxVariants.DEFAULT] })),
        React.createElement(Message, { size: size }, message)));
};
const icons = {
    [TMessageBoxVariants.DEFAULT]: GreyInfoIcon,
    [TMessageBoxVariants.SECONDARY]: BlueInfoIcon,
    [TMessageBoxVariants.SUCCESS]: GreenCheckIcon,
    [TMessageBoxVariants.WARNING]: YellowInfoIcon,
    [TMessageBoxVariants.DANGER]: RedInfoIcon,
};
const backgroundColors = {
    [TMessageBoxVariants.DEFAULT]: newTheme.colors.grey100,
    [TMessageBoxVariants.SECONDARY]: newTheme.colors.blue100,
    [TMessageBoxVariants.SUCCESS]: newTheme.colors.green100,
    [TMessageBoxVariants.WARNING]: newTheme.colors.yellow100,
    [TMessageBoxVariants.DANGER]: newTheme.colors.red100,
};
const textColors = {
    [TMessageBoxVariants.DEFAULT]: newTheme.colors.grey700,
    [TMessageBoxVariants.SECONDARY]: newTheme.colors.blue700,
    [TMessageBoxVariants.SUCCESS]: newTheme.colors.green700,
    [TMessageBoxVariants.WARNING]: newTheme.colors.yellow700,
    [TMessageBoxVariants.DANGER]: newTheme.colors.red700,
};
const MessageContainer = styled.div `
  display: flex;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  border-radius: 4px;
  background-color: ${p => backgroundColors[p.variant || TMessageBoxVariants.DEFAULT]};
  color: ${p => textColors[p.variant || TMessageBoxVariants.DEFAULT]};
`;
const IconBox = styled.div `
  display: flex;
  margin-right: 12px;
  width: 18px;
  height: 18px;
  img {
    width: 18px;
    height: 18px;
  }
`;
const Message = styled.p `
  display: flex;
  flex: 1;
  font: ${p => p.size === "small" ? newTheme.fonts.small : p.size === "medium" ? newTheme.fonts.medium : newTheme.fonts.normal};
`;
