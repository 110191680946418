import { useDispatch, useSelector } from "react-redux";
import { HTActionType } from "../actions/ht-actions";
import { STEPS_PANEL_HEIGHT } from "../../components/pages/dom-tour/config";
import { TourialApiCalls } from "../../helpers/tourial-api-calls";
import { useEffect, useState } from "react";
import { convertTourialFormToFormV3Data } from "../../../../shared/types/tool-data-types/form-v3-data";
import { TourialStatus } from "../../../../shared/types/tourial-model";
export const useHTClearRootState = () => {
    const dispatch = useDispatch();
    return () => dispatch({
        type: HTActionType.HT_CLEAR_TOURIAL_ROOT_STATE,
    });
};
export const useHTSetTour = () => {
    const dispatch = useDispatch();
    return (tour, shareLink) => dispatch({ type: HTActionType.HT_SET_TOUR, tour, shareLink });
};
export const useHTSetPublishedTour = () => {
    const dispatch = useDispatch();
    return (publishedTour) => dispatch({ type: HTActionType.HT_SET_PUBLISHED_TOUR, publishedTour });
};
export const useHTPublishedTour = () => useSelector(state => state.domTourBuilderReducer.publishedTour);
export const useHTTour = () => useSelector(state => state.domTourBuilderReducer.tour);
export const useHTSteps = () => useSelector(state => state.domTourBuilderReducer.tour.steps);
export const useHTSetModal = () => {
    const dispatch = useDispatch();
    return (modal) => dispatch({ type: HTActionType.HT_SET_MODAL, modal });
};
export const useHTModal = () => useSelector(state => state.domTourBuilderReducer.modal);
export const useHTStep = () => useSelector(state => { var _a, _b, _c, _d; return (_c = (_b = (_a = state.domTourBuilderReducer) === null || _a === void 0 ? void 0 : _a.tour) === null || _b === void 0 ? void 0 : _b.steps) === null || _c === void 0 ? void 0 : _c[(_d = state.domTourBuilderReducer) === null || _d === void 0 ? void 0 : _d.stepIndex]; });
export const useHTNextStep = () => useSelector(state => {
    var _a;
    const { stepIndex } = state.domTourBuilderReducer;
    const { steps } = state.domTourBuilderReducer.tour;
    if (stepIndex + 1 < steps.length - 1)
        return null;
    return (_a = state.domTourBuilderReducer.tour.steps) === null || _a === void 0 ? void 0 : _a[state.domTourBuilderReducer.stepIndex];
});
export const useHTTheme = () => useSelector(state => { var _a; return (_a = state.domTourBuilderReducer.tour) === null || _a === void 0 ? void 0 : _a.theme; });
export const useHTSetIsMobile = () => {
    const dispatch = useDispatch();
    return (isMobile) => dispatch({ type: HTActionType.HT_SET_IS_MOBILE, isMobile });
};
export const useHTIsMobile = () => useSelector(state => state.domTourBuilderReducer.isMobile);
export const useHTSetScale = () => {
    const dispatch = useDispatch();
    return (scale) => dispatch({ type: HTActionType.HT_SET_SCALE, scale });
};
export const useHTScale = () => useSelector(state => state.domTourBuilderReducer.scale);
export const useHTGotoNextStep = () => {
    const dispatch = useDispatch();
    return () => dispatch({ type: HTActionType.HT_GOTO_NEXT_STEP });
};
export const useHTGotoPrevStep = () => {
    const dispatch = useDispatch();
    return () => dispatch({ type: HTActionType.HT_GOTO_PREV_STEP });
};
export const useHTGotoStep = () => {
    const dispatch = useDispatch();
    return (stepIndex) => dispatch({ type: HTActionType.HT_GOTO_STEP, stepIndex });
};
export const useHTCaptureIds = () => useSelector(state => state.domTourBuilderReducer.tour.steps.map(s => s.captureId));
export const useHTStepIndex = () => useSelector(state => { var _a; return (_a = state.domTourBuilderReducer) === null || _a === void 0 ? void 0 : _a.stepIndex; });
export const useHTMode = () => useSelector(state => state.domTourBuilderReducer.mode);
export const useHTId = () => useSelector(state => { var _a; return (_a = state.domTourBuilderReducer.tour) === null || _a === void 0 ? void 0 : _a._id; });
export const useHTSlug = () => useSelector(state => state.domTourBuilderReducer.tour.slug);
export const useHTName = () => useSelector(state => state.domTourBuilderReducer.tour.name);
export const useHTIsPublished = () => useSelector(state => state.domTourBuilderReducer.tour.status === TourialStatus.LIVE);
export const useHTHtmlMetadata = () => useSelector(state => state.domTourBuilderReducer.tour.htmlMetadata || []);
export const useHTSetMode = () => {
    const dispatch = useDispatch();
    return (mode) => dispatch({ type: HTActionType.HT_SET_MODE, mode });
};
export const useHTSetBaseUrlWithSubdomain = () => {
    const dispatch = useDispatch();
    return (baseUrlWithSubdomain) => dispatch({ type: HTActionType.HT_SET_BASE_URL_WITH_SUBDOMAIN, baseUrlWithSubdomain });
};
export const useHTBaseUrlWithSubdomain = () => useSelector(state => state.domTourBuilderReducer.baseUrlWithSubdomain);
export const useHTStepCount = () => useSelector(state => { var _a, _b, _c; return (_c = (_b = (_a = state.domTourBuilderReducer) === null || _a === void 0 ? void 0 : _a.tour) === null || _b === void 0 ? void 0 : _b.steps) === null || _c === void 0 ? void 0 : _c.length; });
export const useHTDimensions = () => useSelector(state => {
    const { width, height } = state.domTourBuilderReducer.tour || {};
    return { width, height };
});
export const useHTSetPageForm = () => {
    const dispatch = useDispatch();
    return (pageForm) => dispatch({ type: HTActionType.HT_SET_PAGE_FORM, pageForm });
};
export const useHTPageForm = () => {
    return useSelector(state => state.domTourBuilderReducer.pageForm);
};
export function useHTFetchPageForm() {
    const currentStep = useHTStep();
    const pageForm = useHTPageForm();
    const [formData, setFormData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        if (pageForm === null || pageForm === void 0 ? void 0 : pageForm.isEnabled) {
            setIsLoading(true);
            TourialApiCalls.Forms.getFormById(pageForm.formId)
                .then((f) => {
                setIsLoading(false);
                setFormData(convertTourialFormToFormV3Data(f, pageForm.isGated, pageForm.isEnabled));
            })
                .catch(() => {
                setIsLoading(false);
                setHasError(true);
            });
        }
        return () => {
            setFormData(undefined);
        };
    }, [pageForm === null || pageForm === void 0 ? void 0 : pageForm.formId, currentStep.id]);
    return { isLoading, hasError, formData };
}
export const useHTSessionId = () => useSelector(state => state.domTourBuilderReducer.domTourSessionId);
export const useHTSetSessionId = () => {
    const dispatch = useDispatch();
    return (domTourSessionId) => dispatch({
        type: HTActionType.HT_SET_SESSION_ID,
        domTourSessionId,
    });
};
export const useHTSetViewportPresetSize = () => {
    const dispatch = useDispatch();
    return (viewportPresetSize) => dispatch({ type: HTActionType.HT_SET_VIEWPORT_PRESET_SIZE, viewportPresetSize });
};
export const useHTViewportPresetSize = () => {
    return useSelector(state => state.domTourBuilderReducer.viewportPresetSize);
};
export const useHTSetIsStepsPanelOpened = () => {
    const dispatch = useDispatch();
    return (isStepsPanelOpened) => dispatch({ type: HTActionType.HT_SET_IS_STEPS_PANEL_OPENED, isStepsPanelOpened });
};
export const useHTStepsPanelHeight = () => {
    return useSelector(state => state.domTourBuilderReducer.isStepsPanelOpened ? STEPS_PANEL_HEIGHT : 0);
};
export const useHTSetIsResizingTool = () => {
    const dispatch = useDispatch();
    return (isResizingTool) => dispatch({ type: HTActionType.HT_SET_IS_RESIZING_TOOL, isResizingTool });
};
export const useHTIsResizingTool = () => {
    return useSelector(state => state.domTourBuilderReducer.isResizingTool);
};
export const useHTSetLoadedCaptureId = () => {
    const dispatch = useDispatch();
    return (captureId) => dispatch({ type: HTActionType.HT_SET_LOADED_CAPTURE_ID, captureId });
};
export const useHTLoadedCaptureId = () => {
    return useSelector(state => state.domTourBuilderReducer.loadedCaptureId);
};
export const useHTSetIsEditingHtml = () => {
    const dispatch = useDispatch();
    return (isEditingHtml) => dispatch({ type: HTActionType.HT_SET_IS_EDITING_HTML, isEditingHtml });
};
export const useHTIsEditingHtml = () => {
    return useSelector(state => state.domTourBuilderReducer.isEditingHtml);
};
