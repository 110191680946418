import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { HelperBubble } from "../builder/helper-bubble";
import { getBorderStylesFromProps } from "../../../helpers/misc";
import { ViewportMode } from "../../../types/definitions";
import { useEditingTextToolId, useLegacyHideTool, usePushHistory, useUpdateBuilder, useUpdateTool, useViewportMode, } from "../../../redux/selectors/redux-selectors";
import { getTextAreaCursor } from "./editable-text-area-view";
import { userShadows } from "../builder/tools/shape/editor";
import { withToolWrapper } from "../with-tool-wrapper";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
import CloseButtonIcon from "../../../../assets/images/close-button.svg";
function TourialEmbed(props) {
    const viewportMode = useViewportMode();
    const pushHistory = usePushHistory();
    const updateBuilder = useUpdateBuilder();
    const hideTool = useLegacyHideTool();
    const updateTool = useUpdateTool();
    const { toolData, viewportDimensions } = props;
    const { animation, animationDuration, sizeAndPosition, code, id, enableBackground, enableShadow, shadowStyle, backgroundColor, isDismissable, enableBorder, borderWidth, } = toolData;
    const isToolEditingText = useEditingTextToolId(id);
    const containerWidth = viewportDimensions.width * (sizeAndPosition.width / 100);
    const containerHeight = viewportDimensions.height * (sizeAndPosition.height / 100);
    const style = {
        width: "100%",
        height: "calc(100% - 33.75px)",
        left: 0,
        top: 0,
        textAlign: "left",
        fontSize: "14px",
        overflow: "hidden",
        position: "static",
        display: "block",
        border: `1px solid ${theme.colors.grey.dark}`,
        cursor: getTextAreaCursor(viewportMode, isToolEditingText),
        padding: 5,
    };
    const [isInfoHovered, setIsInfoHovered] = useState(false);
    const [tooltipDirection, setTooltipDirection] = useState("right");
    const [fontColor, setFontColor] = useState("black");
    useEffect(() => setFontColor(getHeaderFontColor(backgroundColor)), [backgroundColor, enableBackground]);
    const getTooltipDirection = () => {
        try {
            const bubblePosition = document.getElementById(`bubble-${toolData.id}`).getBoundingClientRect();
            const { width, x: vpx, y: vpy } = viewportDimensions;
            const right = bubblePosition.x + 300;
            const top = bubblePosition.y;
            if (right < width + vpx)
                return "right";
            if (top > 138 + vpy)
                return "top";
            return "left";
        }
        catch (_a) {
            return "right";
        }
    };
    const getHeaderFontColor = (hexstring) => {
        try {
            const rgba = hexstring
                .substring(5, hexstring.length - 1)
                .replace(/ /g, "")
                .split(",");
            if (+rgba[0] * 0.299 + +rgba[1] * 0.587 + +rgba[2] * 0.114 > 186) {
                return "black";
            }
            return "white";
        }
        catch (_a) {
            return "black";
        }
    };
    return viewportMode === ViewportMode.EDIT ? (React.createElement(React.Fragment, null,
        React.createElement("div", { style: Object.assign(Object.assign({ backgroundColor: enableBackground ? backgroundColor : `${theme.colors.white}`, width: "100%", height: "100%", top: 0, left: 0, position: "absolute", lineHeight: "normal", padding: "0 5px 5px", cursor: getTextAreaCursor(viewportMode, isToolEditingText), boxShadow: enableShadow ? userShadows[shadowStyle] : null }, getBorderStylesFromProps(props)), { borderWidth: enableBorder
                    ? `${Math.max(Math.trunc(borderWidth * (viewportDimensions.width / 1080)), 1)}px`
                    : null }), id: props.toolData.id },
            React.createElement("div", { style: {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 5px",
                    margin: "0 -5px",
                    height: 33.75,
                } },
                React.createElement("div", { style: Object.assign(Object.assign({ whiteSpace: "nowrap", overflow: "hidden", color: enableBackground ? fontColor : "black" }, theme.typography.p), { fontSize: "14px", fontWeight: "bold", lineHeight: "33.75px" }) }, "Embed Code:"),
                React.createElement("div", { style: { display: "flex" } },
                    React.createElement("img", { src: require(`../../../../assets/images/info-${enableBackground ? fontColor : "black"}.svg`), style: {
                            height: 20,
                            alignSelf: "center",
                        }, onMouseEnter: () => {
                            setTooltipDirection(getTooltipDirection);
                            setIsInfoHovered(true);
                        }, onMouseLeave: () => setIsInfoHovered(false) }),
                    React.createElement("div", { id: `bubble-${toolData.id}`, style: { width: 0, height: 0 } }, isInfoHovered && (React.createElement(HelperBubble, { top: tooltipDirection === "top" ? -145 : -5, style: {
                            width: 284,
                            whiteSpace: "pre-line",
                            marginLeft: 15,
                            right: tooltipDirection === "top" ? -2.6 : tooltipDirection === "left" ? 35 : null,
                        }, text: "• The Embed tool is recommended for use with visual media, like forms, images and videos.\n• This tool is not compatible with Engagement Activity." }))))),
            React.createElement("textarea", { style: Object.assign(Object.assign({}, style), { color: enableBackground ? fontColor : "black" }), value: code, rows: 9999, onChange: e => {
                    const newTool = cloneDeep(toolData);
                    newTool.code = e.target.value;
                    updateTool(newTool);
                }, onDoubleClick: () => updateBuilder({
                    editingTextToolId: id,
                }), onBlur: () => {
                    pushHistory();
                    updateBuilder({ editingTextToolId: "" });
                } })))) : (React.createElement("div", { style: Object.assign(Object.assign({ width: `${containerWidth}px`, height: `${containerHeight}px`, padding: 2 }, getBorderStylesFromProps(props)), { backgroundColor: enableBackground ? backgroundColor : null, boxShadow: enableShadow ? userShadows[shadowStyle] : null, animation,
            animationDuration, overflow: "hidden" }) },
        isDismissable && (React.createElement("div", { style: {
                width: "100%",
                height: "25px",
                display: "flex",
                justifyContent: "flex-end",
            } },
            React.createElement("img", { src: CloseButtonIcon, style: {
                    height: `100%`,
                    padding: "1% 1% 1% 0",
                    cursor: "pointer",
                }, onClick: () => hideTool(id) }))),
        React.createElement("div", { style: {
                width: "100%",
                height: isDismissable ? "calc(100% - 25px)" : "100%",
            } },
            React.createElement(MemoizedIframe, { content: code }))));
}
export const Embed = React.memo(withToolWrapper(TourialEmbed));
const Iframe = props => {
    const writeHTML = frame => {
        if (!frame)
            return;
        const doc = frame.contentDocument;
        doc.open();
        doc.write("<!DOCTYPE html><html><body>" + props.content + "</body></html>");
        doc.close();
        frame.style.width = "100%";
        frame.style.height = "100%";
    };
    return React.createElement("iframe", { src: "about:blank", scrolling: "yes", frameBorder: "0", ref: writeHTML, className: "hide-scrollbar" });
};
const MemoizedIframe = React.memo(Iframe);
