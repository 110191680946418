import { useDispatch, useSelector } from "react-redux";
import store from "../../legacy/store";
import { ActionType } from "../actions/redux-actions";
import { TourialApiCalls } from "../../helpers/tourial-api-calls";
import { isEqual } from "lodash";
import { getInitialTourialRootState } from "../reducers/tourial-root-reducer";
import { TourialStatus } from "../../../../shared/types/tourial-model";
export const useMicrositeId = () => useSelector(state => { var _a; return (_a = state.micrositeReducer.microsite) === null || _a === void 0 ? void 0 : _a._id; });
export const useMicrosite = () => useSelector(state => state.micrositeReducer.microsite);
export const useMicrositeStatus = () => {
    return useSelector(state => {
        var _a, _b, _c, _d;
        if ((_b = (_a = state.micrositeReducer) === null || _a === void 0 ? void 0 : _a.microsite) === null || _b === void 0 ? void 0 : _b.isPublished)
            return TourialStatus.LIVE;
        if ((_d = (_c = state.micrositeReducer) === null || _c === void 0 ? void 0 : _c.microsite) === null || _d === void 0 ? void 0 : _d.interactedAt)
            return TourialStatus.UNPUBLISHED;
        return TourialStatus.DRAFT;
    });
};
export const useMicrositeTours = () => useSelector(state => { var _a; return (_a = state.micrositeReducer.microsite) === null || _a === void 0 ? void 0 : _a.tours; });
export const useMicrositeSlug = () => useSelector(state => { var _a; return (_a = state.micrositeReducer.microsite) === null || _a === void 0 ? void 0 : _a.slug; });
export const useMicrositeMetadata = () => {
    return useSelector(state => { var _a, _b; return (_b = (_a = state.micrositeReducer) === null || _a === void 0 ? void 0 : _a.microsite) === null || _b === void 0 ? void 0 : _b.htmlMetadata; });
};
export const useUpdateMicrositeTours = () => {
    const dispatch = useDispatch();
    return (tours) => {
        dispatch({ type: ActionType.UPDATE_MICROSITE_TOURS, tours });
    };
};
export const useUpdateMicrosite = () => {
    const dispatch = useDispatch();
    return (microsite) => {
        dispatch({ type: ActionType.UPDATE_MICROSITE, microsite });
    };
};
export const useSetMicrosite = () => {
    const dispatch = useDispatch();
    return (microsite) => {
        dispatch({ type: ActionType.SET_MICROSITE, microsite });
    };
};
export const useSetMicrositeActiveTourId = () => {
    const dispatch = useDispatch();
    return (id) => {
        dispatch({ type: ActionType.SET_MICROSITE_ACTIVE_TOUR_ID, id });
    };
};
export const useSetMicrositeEditorMode = () => {
    const dispatch = useDispatch();
    return (mode) => {
        dispatch({ type: ActionType.SET_MICROSITE_EDITOR_MODE, mode });
    };
};
export const useMicrositeEditorMode = () => useSelector(state => state.micrositeReducer.editorMode);
export const useSetMicrositeEditorLayout = () => {
    const dispatch = useDispatch();
    return (layout) => {
        dispatch({ type: ActionType.SET_MICROSITE_EDITOR_LAYOUT, layout });
        window.setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 50);
    };
};
export const useMicrositeEditorLayout = () => useSelector(state => state.micrositeReducer.editorLayout);
export const useSetMicrositeCanPublish = () => {
    const dispatch = useDispatch();
    return (canPublish) => {
        dispatch({ type: ActionType.SET_MICROSITE_CAN_PUBLISH, canPublish });
    };
};
export const useMicrositeCanPublish = () => useSelector(state => state.micrositeReducer.canPublish);
export const useMicrositeIsPublished = () => useSelector(state => { var _a; return (_a = state.micrositeReducer.microsite) === null || _a === void 0 ? void 0 : _a.isPublished; });
export const useMicrositeActiveTourId = () => useSelector(state => state.micrositeReducer.activeTourId);
export const useMicrositeVersion = () => useSelector(state => { var _a; return (_a = state.micrositeReducer.microsite) === null || _a === void 0 ? void 0 : _a.version; });
export const useMicrositeAccountId = () => useSelector(state => { var _a, _b; return ((_a = state.micrositeReducer.microsite) === null || _a === void 0 ? void 0 : _a.accountId) || ((_b = state.demoCenterReducer.demoCenter) === null || _b === void 0 ? void 0 : _b.accountId); });
export const useSaveMicrosite = () => {
    const dispatch = useDispatch();
    return () => {
        const microsite = store.getState().micrositeReducer.microsite;
        return TourialApiCalls.Microsites.updateMicrosite(microsite)
            .then(() => {
            dispatch({ type: ActionType.SET_SAVED_MICROSITE, microsite });
        })
            .catch(e => {
            console.error("failed to save microsite", e);
        });
    };
};
export const useMicrositeHasChangesToSave = () => useSelector(state => {
    const { microsite, savedMicrosite } = state.micrositeReducer;
    return !isEqual(microsite, savedMicrosite);
});
export const useSetMicrositeRootState = () => {
    const dispatch = useDispatch();
    return (tourialId, tourialViewportMode) => {
        return TourialApiCalls.Tourials.getTourial(tourialId)
            .then(({ tourial, account }) => {
            const initialProviderState = getInitialTourialRootState(tourial, tourialViewportMode, account);
            return dispatch({
                type: ActionType.SET_INITIAL_TOURIAL_ROOT_STATE,
                initialProviderState,
            });
        })
            .catch((e) => {
            console.log(`Error getting tour data in setProductPageRootState action`, e);
            throw e;
        });
    };
};
export const useGetMicrositeFromSlug = () => {
    const dispatch = useDispatch();
    return () => {
        const pathname = location.pathname.replace("/tp/", "");
        return TourialApiCalls.Microsites.getMicrositeBySlug(pathname)
            .then(microsite => {
            return dispatch({ type: ActionType.SET_MICROSITE, microsite });
        })
            .catch((e) => {
            console.error("Error getting microsite by slug", pathname);
            throw e;
        });
    };
};
export const useSetMicrositeSessionId = () => {
    const dispatch = useDispatch();
    return (micrositeSessionId) => dispatch({
        type: ActionType.SET_MICROSITE_SESSION_ID,
        micrositeSessionId,
    });
};
export const useMicrositeSessionId = () => useSelector(state => state.micrositeReducer.micrositeSessionId);
export const useMicrositeEditorOpenMenu = () => useSelector(state => state.micrositeReducer.editorOpenMenu);
export const useSetMicrositeEditorOpenMenu = () => {
    const dispatch = useDispatch();
    return (editorOpenMenu) => dispatch({
        type: ActionType.SET_MICROSITE_EDITOR_OPEN_MENU,
        editorOpenMenu,
    });
};
export const useMicrositeEditorFocusField = () => useSelector(state => state.micrositeReducer.editorFocusField);
export const useSetMicrositeEditorFocusField = () => {
    const dispatch = useDispatch();
    return (editorFocusField) => dispatch({
        type: ActionType.SET_MICROSITE_EDITOR_FOCUS_FIELD,
        editorFocusField,
    });
};
export const useMicrositeActiveTourIndex = () => useSelector(state => state.micrositeReducer.activeTourIndex);
export const useSetMicrositeActiveTourIndex = () => {
    const dispatch = useDispatch();
    return (activeTourIndex) => dispatch({
        type: ActionType.SET_ACTIVE_TOUR_INDEX,
        activeTourIndex,
    });
};
export const useSaveMicrositeHtmlMetadata = () => {
    const dispatch = useDispatch();
    return (htmlMetadata) => {
        dispatch({
            type: ActionType.SAVE_MICROSITE_HTML_METADATA,
            htmlMetadata,
        });
        const { savedMicrosite } = store.getState().micrositeReducer;
        // we update based on the saved microsite so that we only update the htmlMetadata field
        return TourialApiCalls.Microsites.updateMicrosite(savedMicrosite).catch(e => {
            console.error("failed to save microsite htmlMetadata", e);
        });
    };
};
export const useSaveMicrositeSlug = () => {
    const dispatch = useDispatch();
    return (slug) => {
        dispatch({
            type: ActionType.SAVE_MICROSITE_SLUG,
            slug,
        });
        const { savedMicrosite } = store.getState().micrositeReducer;
        // we update based on the saved microsite so that we only update the slug field
        return TourialApiCalls.Microsites.updateMicrosite(savedMicrosite).catch(e => {
            console.error("failed to save microsite slug", e);
        });
    };
};
export const useSaveMicrositeIsPublished = () => {
    const dispatch = useDispatch();
    return (isPublished) => {
        dispatch({
            type: ActionType.SAVE_MICROSITE_IS_PUBLISHED,
            isPublished,
        });
        const { savedMicrosite } = store.getState().micrositeReducer;
        // we update based on the saved microsite so that we only update the isPublished field
        return TourialApiCalls.Microsites.updateMicrosite(savedMicrosite).catch(e => {
            console.error("failed to save microsite isPublished", e);
        });
    };
};
