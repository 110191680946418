import React from "react";
import { DCCMSection } from "./components/dc-cm-section";
import { DCCMPageType, DCIconType } from "../../../../../../shared/types/demo-center";
import { useDCConfig, useDCSetConfig } from "../hooks/dc-hooks";
import { cloneDeep, set, get } from "lodash";
import { DCCMTextEditor } from "./components/dc-cm-text-editor";
import { DCCMSettingToggleContainer, DCCMToggleLabel } from "./dc-cm-intro-page-editor";
import { TToggleSwitch } from "../../../styled/t-toggle-switch";
import { useDCLivePreviewHelper } from "../hooks/dc-builder-hooks";
export const DCCMAdvancedSettings = () => {
    const config = useDCConfig();
    const setConfig = useDCSetConfig();
    useDCLivePreviewHelper(DCCMPageType.ADVANCED_SETTINGS);
    const updateConfig = (path, value) => {
        const newConfig = cloneDeep(config);
        set(newConfig, path, value);
        setConfig(newConfig);
    };
    const toggleFields = [
        ["Display estimated item duration in sidebar", "sidebar.displayEstimatedDuration"],
        ["Automatically progress to next piece of content", "autoAdvance"],
    ];
    const textFields = [
        ["Header Browse Button", "header.browseButton.text"],
        ["Welcome Browse Button", "welcome.browseButton.text"],
        ["Continue Button", "moduleDetails.continueButton.text"],
        ["Skip Button", "moduleDetails.skipButton.text"],
        ["Share Button", "sidebar.shareButton.text"],
        ["Share Title", "share.title.text"],
        ["Share Description", "share.description.text"],
        ["Topic Placeholder", "sidebar.itemPlaceholderText"],
        ["Quick View Close Button", "quickView.closeButton.text"],
        ["Item Card View Button", "itemCard.viewButton.text"],
        ["Next Item", "controls.next.text"],
        ["Control Bar Placeholder", "controls.placeholder.text"],
    ];
    return (React.createElement(DCCMSection, { icon: DCIconType.GEAR, title: "Advanced Settings", description: "Advanced settings allow you to customize your demo center even further." },
        toggleFields.map(([title, path]) => {
            const value = get(config, path);
            return (React.createElement(DCCMSettingToggleContainer, { key: title },
                React.createElement(DCCMToggleLabel, null, title),
                React.createElement(TToggleSwitch, { onChange: () => updateConfig(path, !value), enabled: value })));
        }),
        textFields.map(([title, path]) => (React.createElement(DCCMTextEditor, { key: title, title: title, value: get(config, path), onChange: e => updateConfig(path, e.target.value) })))));
};
