import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { ViewportMode } from "../../../../../../types/definitions";
import { submitFormV2 } from "../../../../../../helpers/redux";
import { useViewportMode, useUpdateTool, useTourialId, useTourialName, useTourialAccountId, useActivePageId, useActiveZoomId, useActiveVariantName, useSessionId, useTourialSlug, useIntegrations, useVisitorId, useIsSessionStarted, useSetIsSessionStarted, useTimers, useSetTimer, useShowTool, } from "../../../../../../redux/selectors/redux-selectors";
import { extractTextStyle, formPercentageOfViewport, isFieldValid } from "../../../../tool-views/form-v2-view";
import { useEffect } from "react";
import { getBorderStyles } from "../../../../../../helpers/misc";
import { setFormWasSubmitted } from "../../../../../../helpers/form-helpers";
import { usePostEvent } from "../../../../../../helpers/events";
export function SubmitButton({ toolData, viewportDimensions, customStyle, runAfterSubmissionFn, }) {
    var _a, _b;
    const { buttonText } = toolData.text;
    const [textData, setTextData] = useState(buttonText.textData);
    const mode = useViewportMode();
    const viewportMode = useViewportMode();
    const updateTool = useUpdateTool();
    const tourialId = useTourialId();
    const tourialName = useTourialName();
    const tourialSlug = useTourialSlug();
    const tourialAccountId = useTourialAccountId();
    const pageId = useActivePageId();
    const zoomId = useActiveZoomId();
    const activeVariantName = useActiveVariantName();
    const sessionId = useSessionId();
    const userId = useVisitorId();
    const integrations = useIntegrations();
    const isSessionStarted = useIsSessionStarted();
    const setIsSessionStarted = useSetIsSessionStarted();
    const timers = useTimers();
    const setTimer = useSetTimer();
    const showTool = useShowTool();
    const postEvent = usePostEvent();
    const style = Object.assign(Object.assign(Object.assign({ borderStyle: "solid", borderRadius: `${((_b = (_a = toolData === null || toolData === void 0 ? void 0 : toolData.theme) === null || _a === void 0 ? void 0 : _a.buttonTheme) === null || _b === void 0 ? void 0 : _b.borderRadius) * (viewportDimensions.width / 1080)}px`, width: `calc(${Math.max(toolData.text.buttonText.width * viewportDimensions.width, 0.14 * viewportDimensions.width)}px + 10px)`, padding: "0px 5px", height: viewportDimensions.height * formPercentageOfViewport * 0.1, left: 0, top: 0, textAlign: "center", overflow: "hidden", position: "static", display: "block", verticalAlign: "top", cursor: viewportMode === ViewportMode.EDIT ? "text" : "pointer" }, extractTextStyle(buttonText)), { fontSize: `${(viewportDimensions.height * buttonText.fontSize) / 100}px` }), customStyle);
    let inputRef = null;
    const [isInit, setIsInit] = useState(true);
    function onChange(value) {
        try {
            setTextData(value);
            const newTool = cloneDeep(toolData);
            const initialWidth = inputRef.style.width;
            inputRef.style.width = "1px";
            const { scrollWidth } = inputRef;
            inputRef.style.width = initialWidth;
            if (scrollWidth && viewportDimensions.width)
                newTool.text.buttonText.width = scrollWidth / viewportDimensions.width;
            newTool.text.buttonText.textData = value;
            updateTool(newTool);
        }
        catch (_a) {
            return;
        }
    }
    useEffect(() => {
        if (isInit)
            setIsInit(false);
        else
            onChange(toolData.text.buttonText.textData);
    }, [buttonText.fontFamily, buttonText.fontSize, buttonText.fontWeight]);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { onClick: () => {
                if (viewportMode === ViewportMode.EDIT)
                    return;
                const isValid = submitFormV2(isFieldValid, tourialId, tourialName, tourialSlug, tourialAccountId, toolData, pageId, zoomId, viewportMode, toolData.id, activeVariantName, sessionId, userId, isSessionStarted, setIsSessionStarted, timers, setTimer, showTool, postEvent, integrations === null || integrations === void 0 ? void 0 : integrations.hutk, integrations === null || integrations === void 0 ? void 0 : integrations.munchkinCookie);
                if (!isValid)
                    return;
                setFormWasSubmitted(toolData.id, true);
                runAfterSubmissionFn();
            }, style: Object.assign(Object.assign(Object.assign({}, style), { backgroundColor: toolData.theme.buttonTheme.backgroundColor }), getBorderStyles(true, toolData.theme.buttonTheme.borderRadius, toolData.theme.buttonTheme.borderWidth, toolData.theme.buttonTheme.borderColor, "solid", viewportDimensions)) },
            React.createElement("input", { ref: input => (inputRef = input), style: Object.assign(Object.assign({}, style), { padding: 0, height: "100%", width: `${Math.max(toolData.text.buttonText.width * viewportDimensions.width, 0.14 * viewportDimensions.width)}px` }), value: textData, readOnly: mode !== ViewportMode.EDIT, onChange: e => mode === ViewportMode.EDIT && onChange(e.target.value) }))));
}
