import { useDCConfig, useDCId } from "./dc-hooks";
import { useHistory, useParams } from "react-router-dom";
import { useDCBuilderMode, useDCBuilderWarnings, useDCLivePreviewPath, useDCSetBuilderIsCollapsed, useDCSetBuilderMode, useDCSetBuilderWarnings, useDCSetLivePreviewPath, useDemoCenter, useSavedDemoCenter, } from "../../../../redux/selectors/demo-center-selectors";
import { MainAppPagePaths } from "../../../../../../shared/types/app-events";
import { useEffect } from "react";
import { DCBuilderMode, DCButtonAction, DCCMPageType, DCItemType, DCModuleType, } from "../../../../../../shared/types/demo-center";
import { useDCGetModuleItemCount, useDCModules } from "./dc-module-hooks";
import { dcGenerateSlugFromText, dcFsAndMongoDocsAreEqual, dcIsPathABuilderCMPage } from "../helpers/dc-helpers";
import { useWindowSize } from "../../../../hooks/viewport-hooks";
import { HomePageTabs } from "../../../../../../shared/types/tourial-model";
import { useDCIsEditModeBlocked } from "../../../../hooks/use-is-collaborator-blocked";
export const useDCBuilderWarningMonitor = () => {
    const setWarnings = useDCSetBuilderWarnings();
    const modules = useDCModules();
    const moduleItemCount = useDCGetModuleItemCount();
    const { header, welcome } = useDCConfig();
    const { cta } = header;
    const { featuredContentId, showFeaturedContent } = welcome;
    useEffect(() => {
        var _a;
        const navKey = DCCMPageType.NAVIGATION;
        const introKey = DCCMPageType.INTRO_PAGE;
        const warnings = [];
        const addWarning = (key, message) => {
            warnings.push({ key, message });
        };
        if (!cta.url && cta.action === DCButtonAction.LINK) {
            addWarning(navKey, "CTA URL is missing.");
        }
        else if (cta.action === DCButtonAction.CALENDLY_SCHEDULER && !cta.url) {
            addWarning(navKey, "Calendly URL is missing.");
        }
        else if (cta.action === DCButtonAction.HUBSPOT_SCHEDULER && !cta.url) {
            addWarning(navKey, "Hubspot URL is missing.");
        }
        else if (cta.action === DCButtonAction.FORM && !cta.formId) {
            addWarning(navKey, "Form is missing.");
        }
        if (showFeaturedContent && welcome.isEnabled && !featuredContentId) {
            addWarning(introKey, `Content missing from '${((_a = modules[0]) === null || _a === void 0 ? void 0 : _a.title) || "first"}' content track.`);
        }
        modules.forEach(module => {
            const itemCount = moduleItemCount(module);
            if (itemCount === 0) {
                addWarning(module.slug, "Content missing from topic.");
            }
            else {
                module.tracks.forEach((track, i) => {
                    if ((track.items.length === 0 && ![DCModuleType.BOOLEAN, DCModuleType.SCALE].includes(module.type)) ||
                        (module.type === DCModuleType.SCALE && !track.items.length && i !== module.tracks.length - 1)) {
                        addWarning(`${module.slug}-${track.slug}`, "Content missing from track.");
                    }
                });
            }
        });
        setWarnings(warnings);
        return () => setWarnings([]);
    }, [cta, showFeaturedContent, featuredContentId, modules]);
};
export const useDCCMHomePath = () => {
    var _a;
    const modules = useDCModules();
    const firstModuleSlug = (_a = modules[0]) === null || _a === void 0 ? void 0 : _a.slug;
    const isNewDemoCenter = !modules.length;
    const path = isNewDemoCenter
        ? "wizard"
        : firstModuleSlug
            ? `module/${firstModuleSlug}`
            : `${DCBuilderMode.MANAGE}/${dcGenerateSlugFromText(DCCMPageType.INTRO_PAGE)}`;
    return path;
};
export const useDCRedirectOnEditModeBlocked = () => {
    const id = useParams().id;
    const history = useHistory();
    const isEditModeBlocked = useDCIsEditModeBlocked();
    useEffect(() => {
        if (isEditModeBlocked && id) {
            history.push(`${MainAppPagePaths.DEMOCENTER_BUILDER}/${id}/preview`);
        }
    }, [isEditModeBlocked]);
};
export const useDCCMResizeMonitor = () => {
    const setIsCollapsed = useDCSetBuilderIsCollapsed();
    const { width } = useWindowSize();
    const breakpoint = 1600;
    useEffect(() => {
        setIsCollapsed(width < breakpoint);
    }, [width]);
};
export const useDCGoToBuilder = () => {
    const goToPage = useDCBuilderGoToPage();
    return (id) => goToPage("", id);
};
export const useDCBuilderGoToWizard = () => {
    const goToPage = useDCBuilderGoToPage();
    return (id) => goToPage("wizard", id);
};
export const useDCGoToTourialHome = () => {
    const history = useHistory();
    return () => {
        history.push(`/?selectedTab=${HomePageTabs.DEMOCENTER}`);
    };
};
export const useDCBuilderGoToPage = () => {
    const history = useHistory();
    const demoCenterId = useDCId();
    return (path, id) => {
        history.push(`${MainAppPagePaths.DEMOCENTER_BUILDER}/${id || demoCenterId}/${path}`);
    };
};
export const useDCBuilderToggleLivePreviewMode = () => {
    const livePreviewPath = useDCLivePreviewPath();
    const goToPage = useDCBuilderGoToPage();
    const mode = useDCBuilderMode();
    const createPath = () => {
        const prefix = dcIsPathABuilderCMPage(livePreviewPath) ? "manage" : "module";
        if (livePreviewPath) {
            return `${prefix}/${dcGenerateSlugFromText(livePreviewPath)}`;
        }
        else {
            return mode;
        }
    };
    return () => {
        if (mode === DCBuilderMode.PREVIEW) {
            goToPage(createPath());
        }
        else {
            goToPage(DCBuilderMode.PREVIEW);
        }
    };
};
export const useDCGoToTourV3Builder = () => {
    const history = useHistory();
    return (id, type) => {
        if (type === DCItemType.DOM_TOUR) {
            history.push(`${MainAppPagePaths.DOM_TOUR_BUILDER.split(":id")[0]}${id}`);
        }
        else {
            history.push(`${MainAppPagePaths.V3_BUILDER}?tourId=${id}`);
        }
    };
};
export const useDCBuilderResetOnExit = () => {
    const setDCBuilderMode = useDCSetBuilderMode();
    const setWarnings = useDCSetBuilderWarnings();
    useEffect(() => {
        return () => {
            setDCBuilderMode(DCBuilderMode.MANAGE);
            setWarnings([]);
        };
    }, []);
};
export const useDCBuilderHasChangesToPublish = () => {
    const democenter = useDemoCenter();
    const savedDemoCenter = useSavedDemoCenter();
    return !dcFsAndMongoDocsAreEqual(democenter, savedDemoCenter);
};
export const useDCBuilderWarning = (key) => {
    var _a;
    const warnings = useDCBuilderWarnings();
    return ((_a = warnings.find(w => w.key === key)) === null || _a === void 0 ? void 0 : _a.message) || "";
};
export const useDCGetBuilderWarning = (parent) => {
    const warnings = useDCBuilderWarnings();
    return (key) => {
        let warning = warnings.find(w => w.key === key);
        if (parent && !warning) {
            warning = warnings.find(w => w.key.startsWith(key));
        }
        return (warning === null || warning === void 0 ? void 0 : warning.message) || "";
    };
};
export const useDCLivePreviewHelper = (path) => {
    const setLivePreviewPath = useDCSetLivePreviewPath();
    useEffect(() => {
        setLivePreviewPath(path);
    }, [path]);
};
