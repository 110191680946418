import React from "react";
import styled from "styled-components";
import { newTheme } from "../styled/theme";
import { TourialStatus } from "../../../../shared/types/tourial-model";
import { tourialStatusReadable } from "../../../../shared/functions/helpers";
const StatusContainer = styled.p `
  font: ${newTheme.fonts.extraSmall};
  margin-top: 4px;
  padding: 4px 8px;
  height: 21px;
  border-radius: 2px;
  display: inline;
  white-space: nowrap;
  &.${TourialStatus.LIVE} {
    background: ${newTheme.colors.green100};
    color: ${newTheme.colors.green600};
  }
  &.${TourialStatus.PENDING_CHANGES} {
    background: ${newTheme.colors.yellow100};
    color: ${newTheme.colors.yellow600};
  }
  &.${TourialStatus.DRAFT} {
    background: #eff0f3;
    color: #747681;
  }
  &.${TourialStatus.UNPUBLISHED} {
    background: #ccfff7;
    color: #007cb7;
  }
  &.${TourialStatus.ARCHIVED} {
    background: ${newTheme.colors.yellow50};
    color: ${newTheme.colors.yellow700};
  }
`;
const TStatusIndicator = ({ status }) => {
    return (React.createElement(StatusContainer, { className: `${status} _test_status-indicator` }, tourialStatusReadable[status]));
};
export default React.memo(TStatusIndicator);
