import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { usePushHistory, useUpdateTool } from "../../../redux/selectors/redux-selectors";
import { newTheme } from "../../styled/theme";
export function TimingSettingsTitlebar({ title, tool, style }) {
    const pushHistory = usePushHistory();
    const updateTool = useUpdateTool();
    const [localToolName, setLocalToolName] = useState(title);
    useEffect(() => {
        setLocalToolName(title);
    }, [title]);
    return (React.createElement("input", { className: "text-ellipsis", value: localToolName, onChange: (e) => {
            setLocalToolName(e.target.value);
        }, onBlur: () => {
            pushHistory();
            setLocalToolName(localToolName.trim());
            const t = cloneDeep(tool);
            t.name = localToolName;
            updateTool(t);
        }, style: Object.assign({ height: 24, font: newTheme.fonts.normal, textAlign: "left", width: 154 }, style) }));
}
