import React from "react";
import XIcon from "../../../../assets/images/x.svg";
import { rgbaToHex } from "../../../helpers/misc";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
export default function TModal({ style, children, onClose, hasBackdrop, isVisible }) {
    const width = Number((style === null || style === void 0 ? void 0 : style.width) || 600);
    if (!isVisible)
        return null;
    return (React.createElement("div", null,
        React.createElement("div", { style: {
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                backgroundColor: rgbaToHex(0, 0, 0, 0.5),
                display: hasBackdrop ? "block" : "none",
                zIndex: 1001,
            }, onClick: onClose }),
        React.createElement("div", { style: Object.assign({ display: "inherit", position: "absolute", right: 200, top: 40, width: width, height: "auto", backgroundColor: theme.colors.white, boxShadow: theme.shadows.deep, zIndex: 1002 }, style) },
            React.createElement("div", { style: {
                    height: 24,
                    backgroundColor: theme.colors.cobalt.medium,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                } },
                React.createElement("img", { src: XIcon, style: {
                        position: "relative",
                        left: width / 2 - 13,
                        cursor: "pointer",
                        height: 11,
                        width: 11,
                    }, onClick: onClose })),
            children)));
}
