import React from "react";
import { DCSidebarModule } from "../components/dc-sidebar-module";
import { DCModuleType } from "../../../../../../shared/types/demo-center";
import { useDCActiveItemId, useDCActiveModuleSlug, useDCViewedItems, } from "../../../../redux/selectors/demo-center-selectors";
import { useDCGetItemsFromIds, useDCGetItemsForSelectedTracks } from "../hooks/dc-item-hooks";
import { useDCConfig, useDCIsLiveMode, useDCTheme } from "../hooks/dc-hooks";
import { useDCGetModuleStatus, useDCModules } from "../hooks/dc-module-hooks";
import { useDCGoToItem, useDCGoToModule } from "../hooks/dc-navigation-hooks";
import { isEmbedded } from "../../../../helpers/live";
export const DCPlaylist = () => {
    const isLiveMode = useDCIsLiveMode();
    const theme = useDCTheme();
    const goToItem = useDCGoToItem();
    const goToModule = useDCGoToModule();
    const id = useDCActiveItemId();
    const activeModuleSlug = useDCActiveModuleSlug();
    const getItemsFromModuleSlug = useDCGetItemsForSelectedTracks();
    const getItemsFromIds = useDCGetItemsFromIds();
    const viewedItems = useDCViewedItems();
    const { sidebar, badge } = useDCConfig();
    const modules = useDCModules();
    const getModuleStatus = useDCGetModuleStatus();
    const getItems = (module) => {
        var _a;
        const { type, tracks, slug } = module;
        if (type === DCModuleType.NO_PROMPT) {
            return getItemsFromIds((_a = tracks[0]) === null || _a === void 0 ? void 0 : _a.items);
        }
        else {
            return getItemsFromModuleSlug(slug);
        }
    };
    return modules.length > 0 ? (React.createElement(React.Fragment, null, modules.map(m => (React.createElement(DCSidebarModule, { key: m.slug, title: m.title, slug: m.slug, items: getItems(m), theme: theme, status: getModuleStatus(m.slug), sidebarConfig: sidebar, badgeConfig: badge, viewedItems: viewedItems, activeItemId: id, activeModuleSlug: activeModuleSlug, shouldScrollToActiveModule: isLiveMode && !isEmbedded(), onItemSelect: (item) => goToItem(item.id, m.slug), onTitleSelect: () => goToModule(m.slug) }))))) : null;
};
