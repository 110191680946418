import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactPortal from "../../helpers/react-portal";
import YellowInfoIcon from "../../../assets/images/v3/yellow-attention.svg";
import { newTheme } from "./theme";
import { PAGE_HEADER_Z_INDEX } from "../../types/definitions";
export default function TModal({ children, title, tabs, icon, id, onClose, onTabSelect, postfixTitle, postfixTitleStyle, showAlertForTabName, style, }) {
    const [selectedTab, setSelectedTab] = useState(tabs ? tabs[0] : "");
    useEffect(() => {
        const closeOnEscapeKey = e => (e.key === "Escape" ? onClose() : null);
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [onClose]);
    return (React.createElement(ReactPortal, { wrapperId: "tourial-portal-modal-container" },
        React.createElement(Overlay, { id: "t-overlay", onMouseDown: e => {
                if (document.elementFromPoint(e.clientX, e.clientY).id === "t-overlay")
                    onClose();
            } },
            React.createElement(ModalPadding, null,
                React.createElement(ModalContainer, { className: "_test_t-overlay-modal", id: id, style: style },
                    React.createElement(Header, null,
                        React.createElement(TitleContainer, null,
                            icon ? (React.createElement(Icon, null,
                                React.createElement("img", { src: icon }))) : null,
                            title,
                            React.createElement("span", { style: postfixTitleStyle }, postfixTitle)),
                        React.createElement("span", { onClick: onClose, style: { cursor: "pointer" } }, "\u2715")),
                    React.createElement(TabContainer, null, tabs
                        ? tabs.map((tab, i) => (React.createElement(Tab, { className: selectedTab === tab ? "selected" : null, id: `_test_modal-tab-${i}`, key: `tab-${i}-${tab}`, onClick: () => {
                                setSelectedTab(tab);
                                onTabSelect === null || onTabSelect === void 0 ? void 0 : onTabSelect(tab);
                            } },
                            tab,
                            showAlertForTabName === tab && React.createElement(AlertIcon, { src: YellowInfoIcon }))))
                        : null),
                    children)))));
}
const Overlay = styled.div `
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${PAGE_HEADER_Z_INDEX + 100};
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;
const Header = styled.div `
  height: 76px;
  width: 100%;
  font: ${newTheme.fonts.header};
  display: flex;
  justify-content: space-between;
  padding: 24px;
  color: ${newTheme.colors.grey800};
`;
const TitleContainer = styled.div `
  display: flex;
  align-items: center;
`;
const Icon = styled.span `
  display: flex;
  margin-right: 8px;
  & img {
    width: 24px;
    height: 24px;
  }
`;
const AlertIcon = styled.img `
  margin-left: 2px;
  width: 16px;
  height: 16px;
`;
const ModalContainer = styled.div `
  background-color: ${newTheme.colors.white};
  min-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: ${newTheme.shadows.outside80};
`;
const ModalPadding = styled.div `
  padding: 64px;
  height: fit-content;
`;
const TabContainer = styled.ul `
  border-bottom: 1px solid ${newTheme.colors.grey300};
  list-style: none;
  width: 100%;
  display: flex;
  padding: 0 12px;
`;
const Tab = styled.li `
  display: flex;
  flex-wrap: no-wrap;
  cursor: pointer;
  margin: 0 12px;
  padding-bottom: 4px;
  font: ${newTheme.fonts.normal};
  color: ${newTheme.colors.grey600};
  &.selected {
    color: ${newTheme.colors.black};
    border-bottom: 4px solid ${newTheme.colors.blue300};
    font-weight: bold;
  }
`;
export const TModalButtonsContainer = styled.div `
  height: 88px;
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 24px;
  align-items: center;
  justify-content: right;
`;
export const TModalSpan = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  height: 68px;
  width: 510px;
`;
