import React, { useEffect } from "react";
import styled from "styled-components";
import { DCBadgeType, DCIconType, DCModuleStatus, } from "../../../../../../shared/types/demo-center";
import { DCIcon } from "./dc-icon";
import { dcGetItemDurationLabel, dcGetRGBColorStringFromHex, dcGetReadableItemType } from "../helpers/dc-helpers";
import { DCStyledBodyText } from "./dc-styled-text";
import { DCBadge } from "./dc-badge";
import { newTheme } from "../../../styled/theme";
import { dcPulseAnimation } from "../helpers/dc-style-helpers";
export const DCSidebarModule = ({ title, items, slug, activeItemId, theme, status, activeModuleSlug, sidebarConfig, badgeConfig, viewedItems, shouldScrollToActiveModule, onItemSelect, onTitleSelect, }) => {
    const isModuleActive = slug === activeModuleSlug || (!slug && !!items.find(i => i.id === activeItemId));
    const { moduleTitleStyle, emptyStateTextStyle, activeIconColor, itemPlaceholderText } = sidebarConfig;
    const containerRef = React.useRef(null);
    useEffect(() => {
        if (isModuleActive && shouldScrollToActiveModule) {
            containerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [isModuleActive]);
    return (React.createElement(ModuleContainer, { ref: containerRef, className: isModuleActive ? "active" : null, theme: theme, config: sidebarConfig, onClick: () => (items.length > 0 ? null : onTitleSelect()) },
        React.createElement(TitleBox, { onClick: onTitleSelect, config: sidebarConfig, theme: theme, style: items.length > 0 ? { marginBottom: "8px" } : null },
            React.createElement(ModuleStatusIcon, { status: status, activeIconColor: activeIconColor }),
            React.createElement(Title, { config: moduleTitleStyle, className: "module-title" }, title),
            [DCModuleStatus.SKIPPED, DCModuleStatus.REJECTED].includes(status) && (React.createElement(Skipped, { theme: theme, config: emptyStateTextStyle }, status === DCModuleStatus.SKIPPED ? "(skipped)" : "(dismissed)"))),
        items.length > 0 ? (items.map((item, i) => (React.createElement(DCSidebarItem, { key: item.id, item: item, sidebarConfig: sidebarConfig, badgeConfig: badgeConfig, hideTimeline: i + 1 === items.length, isActive: item.id === activeItemId, hasViewed: !!viewedItems.find(i => i === item.id), isExpanded: isModuleActive, onItemSelect: onItemSelect })))) : isModuleActive && status !== DCModuleStatus.REJECTED ? (React.createElement(Placeholder, { theme: theme }, itemPlaceholderText)) : null));
};
const DCSidebarItem = ({ item, badgeConfig, sidebarConfig, hideTimeline, isActive, hasViewed, isExpanded, onItemSelect, }) => {
    const { itemTitleStyle, itemTypeStyle, activeIconColor, inactiveIconColor, displayEstimatedDuration } = sidebarConfig;
    return (React.createElement(ItemContainer, { onClick: () => onItemSelect(item), config: sidebarConfig },
        React.createElement(Progress, null,
            React.createElement(ItemStatusIcon, { isActive: isActive, hasViewed: hasViewed, activeIconColor: activeIconColor, inactiveIconColor: inactiveIconColor }),
            !hideTimeline && React.createElement(Timeline, { style: { borderColor: inactiveIconColor } })),
        React.createElement(ItemInfo, null,
            React.createElement(DCStyledBodyText, { config: itemTitleStyle, isActive: isExpanded, className: "item-title" }, item.title),
            isExpanded && (React.createElement(ItemType, null,
                React.createElement(DCBadge, { config: badgeConfig, type: DCBadgeType.ITEM_TYPE, content: dcGetReadableItemType(item.type) }),
                item.duration && displayEstimatedDuration && (React.createElement(DCStyledBodyText, { config: itemTypeStyle }, dcGetItemDurationLabel(item.duration))))))));
};
const ModuleStatusIcon = ({ status, activeIconColor }) => {
    const icons = {
        [DCModuleStatus.AWAITING_SELECTION]: DCIconType.PROMPT,
        [DCModuleStatus.IN_PROGRESS]: DCIconType.CIRCLE_IN_PROGRESS,
        [DCModuleStatus.COMPLETE]: DCIconType.MODULE_COMPLETE,
        [DCModuleStatus.SKIPPED]: DCIconType.FORWARD_ARROW,
        [DCModuleStatus.REJECTED]: DCIconType.MODULE_COMPLETE,
        [DCModuleStatus.LOCKED]: DCIconType.PADLOCK,
    };
    return React.createElement(DCIcon, { type: icons[status], style: { color: activeIconColor } });
};
const ItemStatusIcon = ({ hasViewed, isActive, activeIconColor, inactiveIconColor }) => {
    const getIcon = () => {
        if (isActive) {
            return DCIconType.ACTIVE_ITEM;
        }
        else if (hasViewed) {
            return DCIconType.CHECKMARK;
        }
        else {
            return DCIconType.EMPTY_ITEM;
        }
    };
    const style = {
        width: 16,
        height: 21,
        alignItems: "center",
        justifyContent: "center",
        color: isActive ? activeIconColor : inactiveIconColor,
    };
    return isActive ? React.createElement(ActiveIcon, { activeIconColor: activeIconColor }) : React.createElement(DCIcon, { type: getIcon(), style: style });
};
const ActiveIcon = ({ activeIconColor }) => {
    return (React.createElement(ActiveIconContainer, null,
        React.createElement(Beacon, { style: { backgroundColor: activeIconColor }, pulseColor: activeIconColor })));
};
const ActiveIconContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 21px;
`;
const Beacon = styled.div `
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${p => p.theme.neutralPrimary};
  ${p => dcPulseAnimation(p.pulseColor)};
`;
const ModuleContainer = styled.div `
  display: flex;
  flex-direction: column;
  padding: 12px;
  overflow: hidden;
  border-radius: ${p => p.config.borderRadius}px;
  background-color: ${p => dcGetRGBColorStringFromHex(p.theme.neutralTranslucentColor, p.theme.neutralTranslucentOpacity)};
  backdrop-filter: blur(50px);
  margin-bottom: 8px;
  &.active {
    background-color: ${p => p.theme.neutralPrimary};
    box-shadow: ${p => (p.config.activeModuleShadow ? p.theme.shadow : "")};
  }
  cursor: pointer;
`;
const TitleBox = styled.div `
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  color: ${p => p.theme.textMain};
  .module-title {
    transition: color 0.1s linear;
  }
  &:hover {
    .module-title {
      color: ${p => p.config.activeIconColor};
    }
  }
`;
const Title = styled(DCStyledBodyText) `
  margin-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Skipped = styled(DCStyledBodyText) `
  color: ${p => p.theme.textSecondary};
`;
const Placeholder = styled.div `
  text-align: center;
  margin-top: 8px;
  padding: 12px;
  border-radius: 8px;
  background-color: ${p => p.theme.neutralSecondary};
  font: ${newTheme.fonts.small};
  color: ${p => p.theme.textSecondary};
`;
const ItemInfo = styled.div `
  transition: margin 0.2s linear;
  margin: 0 0 8px 8px;
`;
const ItemContainer = styled.div `
  display: flex;
  cursor: pointer;
  .item-title {
    transition: color 0.1s linear;
  }
  &:hover {
    ${ItemInfo} {
      margin: 0 0 8px 12px;
    }
    .item-title {
      color: ${p => p.config.activeIconColor};
    }
  }
`;
const Progress = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 16px;
`;
const ItemType = styled.div `
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: 1px;
`;
const Timeline = styled.div `
  width: 0px;
  margin: 0 7.5px;
  border-width: 0 0 0 1px;
  border-style: dashed;
  flex: 1;
`;
