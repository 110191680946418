import { TooltipArrowPosition, TooltipTriggerComponent, } from "../../../shared/types/tool-data-types/tooltip-v2-data";
import { arrowSizes } from "../components/tools/tooltip-v2/view/subtool-components/bubble-arrow";
import { BorderShape } from "../../../shared/types/tool-data-types/common";
export function sizeEq(a, b) {
    return a.width === b.width && a.height === b.height;
}
export function positionEq(a, b) {
    return a.x === b.x && a.y === b.y;
}
export function pxToPercent(v, max) {
    return (v / max) * 100;
}
export function percentToPx(v, max) {
    return (v * max) / 100;
}
export function floorSize(s, floor) {
    return { width: Math.max(s.width, floor.width), height: Math.max(s.height, floor.height) };
}
export const arrowEdgePaddingPx = 30;
export function getArrowOffsetPx(t) {
    const { width: bubbleWidth, height: bubbleHeight } = t.bubble.size;
    const arrowSize = arrowSizes[t.bubble.arrow.size];
    const halfArrowSize = arrowSize / 2;
    let x, y, r;
    switch (t.bubble.arrow.position) {
        case TooltipArrowPosition.BOTTOM_LEFT:
            x = arrowEdgePaddingPx;
            y = bubbleHeight - halfArrowSize;
            r = -225;
            break;
        case TooltipArrowPosition.BOTTOM_CENTER:
            x = bubbleWidth / 2 - halfArrowSize;
            y = bubbleHeight - halfArrowSize;
            r = -225;
            break;
        case TooltipArrowPosition.BOTTOM_RIGHT:
            x = bubbleWidth - arrowSize - arrowEdgePaddingPx;
            y = bubbleHeight - halfArrowSize;
            r = -225;
            break;
        case TooltipArrowPosition.TOP_LEFT:
            x = arrowEdgePaddingPx;
            y = -halfArrowSize;
            r = -45;
            break;
        case TooltipArrowPosition.TOP_CENTER:
            x = bubbleWidth / 2 - halfArrowSize;
            y = -halfArrowSize;
            r = -45;
            break;
        case TooltipArrowPosition.TOP_RIGHT:
            x = bubbleWidth - arrowSize - arrowEdgePaddingPx;
            y = halfArrowSize - arrowSize;
            r = -45;
            break;
        case TooltipArrowPosition.RIGHT_TOP:
            x = bubbleWidth - halfArrowSize - 2;
            y = arrowEdgePaddingPx;
            r = 45;
            break;
        case TooltipArrowPosition.RIGHT_CENTER:
            x = bubbleWidth - halfArrowSize - 2;
            y = bubbleHeight / 2 - halfArrowSize;
            r = 45;
            break;
        case TooltipArrowPosition.RIGHT_BOTTOM:
            x = bubbleWidth - halfArrowSize - 2;
            y = bubbleHeight - arrowSize - arrowEdgePaddingPx;
            r = 45;
            break;
        case TooltipArrowPosition.LEFT_TOP:
            x = -halfArrowSize;
            y = arrowEdgePaddingPx;
            r = -135;
            break;
        case TooltipArrowPosition.LEFT_CENTER:
            x = -halfArrowSize;
            y = bubbleHeight / 2 - halfArrowSize;
            r = -135;
            break;
        case TooltipArrowPosition.LEFT_BOTTOM:
            x = -halfArrowSize;
            y = bubbleHeight - arrowSize - arrowEdgePaddingPx;
            r = -135;
            break;
    }
    return { x, y, r };
}
/** important: this function should only be used for bubble dragging */
export function getTriggerPosition(t, vd, bubblePosition) {
    const { x: bubbleX, y: bubbleY } = bubblePosition;
    const bubbleWidth = pxToPercent(t.bubble.size.width, vd.width);
    const bubbleHeight = pxToPercent(t.bubble.size.height, vd.height);
    let { width: triggerWidth, height: triggerHeight } = t.trigger.clickzone.size;
    if (t.trigger.triggerComponent === TooltipTriggerComponent.HOTSPOT) {
        triggerWidth = pxToPercent(34, vd.width);
        triggerHeight = pxToPercent(34, vd.height);
    }
    const arrowSize = arrowSizes[t.bubble.arrow.size];
    const halfArrowSize = arrowSize / 2;
    const edgePaddingX = pxToPercent(arrowEdgePaddingPx + halfArrowSize, vd.width);
    const edgePaddingY = pxToPercent(arrowEdgePaddingPx + halfArrowSize, vd.height);
    const triggerMarginX = pxToPercent(arrowSize + 8, vd.width);
    const triggerMarginY = pxToPercent(arrowSize + 8, vd.height);
    let x, y;
    switch (t.bubble.arrow.position) {
        case TooltipArrowPosition.BOTTOM_LEFT:
            x = bubbleX + edgePaddingX;
            y = bubbleY + bubbleHeight + triggerMarginY + triggerHeight / 2;
            break;
        case TooltipArrowPosition.BOTTOM_CENTER:
            x = bubbleX + bubbleWidth / 2;
            y = bubbleY + bubbleHeight + triggerMarginY + triggerHeight / 2;
            break;
        case TooltipArrowPosition.BOTTOM_RIGHT:
            x = bubbleX + bubbleWidth - edgePaddingX;
            y = bubbleY + bubbleHeight + triggerMarginY + triggerHeight / 2;
            break;
        case TooltipArrowPosition.TOP_LEFT:
            x = bubbleX + edgePaddingX;
            y = bubbleY - triggerHeight / 2 - triggerMarginY;
            break;
        case TooltipArrowPosition.TOP_CENTER:
            x = bubbleX + bubbleWidth / 2;
            y = bubbleY - triggerHeight / 2 - triggerMarginY;
            break;
        case TooltipArrowPosition.TOP_RIGHT:
            x = bubbleX + bubbleWidth - edgePaddingX;
            y = bubbleY - triggerHeight / 2 - triggerMarginY;
            break;
        case TooltipArrowPosition.RIGHT_TOP:
            x = bubbleX + bubbleWidth + triggerMarginX + triggerWidth / 2;
            y = bubbleY + edgePaddingY;
            break;
        case TooltipArrowPosition.RIGHT_CENTER:
            x = bubbleX + bubbleWidth + triggerMarginX + triggerWidth / 2;
            y = bubbleY + bubbleHeight / 2;
            break;
        case TooltipArrowPosition.RIGHT_BOTTOM:
            x = bubbleX + bubbleWidth + triggerMarginX + triggerWidth / 2;
            y = bubbleY + bubbleHeight - edgePaddingY;
            break;
        case TooltipArrowPosition.LEFT_TOP:
            x = bubbleX - triggerMarginX - triggerWidth / 2;
            y = bubbleY + edgePaddingY;
            break;
        case TooltipArrowPosition.LEFT_CENTER:
            x = bubbleX - triggerMarginX - triggerWidth / 2;
            y = bubbleY + bubbleHeight / 2;
            break;
        case TooltipArrowPosition.LEFT_BOTTOM:
            x = bubbleX - triggerMarginX - triggerWidth / 2;
            y = bubbleY + bubbleHeight - edgePaddingY;
            break;
    }
    if (x > -Infinity && y > -Infinity) {
        return { x, y };
    }
    else {
        return t.trigger.position;
    }
}
export function getBubblePosition(t, vd) {
    const bubbleWidth = pxToPercent(t.bubble.size.width, vd.width);
    const bubbleHeight = pxToPercent(t.bubble.size.height, vd.height);
    const { x: triggerX, y: triggerY } = t.trigger.position;
    let { width: triggerWidth, height: triggerHeight } = t.trigger.clickzone.size;
    if (t.trigger.triggerComponent === TooltipTriggerComponent.HOTSPOT) {
        triggerWidth = pxToPercent(34, vd.width);
        triggerHeight = pxToPercent(34, vd.height);
    }
    const arrowSize = arrowSizes[t.bubble.arrow.size];
    const halfArrowSize = arrowSize / 2;
    const edgePaddingX = pxToPercent(arrowEdgePaddingPx + halfArrowSize, vd.width);
    const edgePaddingY = pxToPercent(arrowEdgePaddingPx + halfArrowSize, vd.height);
    const triggerMarginX = pxToPercent(arrowSize + 8, vd.width);
    const triggerMarginY = pxToPercent(arrowSize + 8, vd.height);
    let x, y;
    switch (t.bubble.arrow.position) {
        case TooltipArrowPosition.BOTTOM_LEFT:
            x = triggerX - edgePaddingX;
            y = triggerY - bubbleHeight - triggerMarginY - triggerHeight / 2;
            break;
        case TooltipArrowPosition.BOTTOM_CENTER:
            x = triggerX - bubbleWidth / 2;
            y = triggerY - bubbleHeight - triggerMarginY - triggerHeight / 2;
            break;
        case TooltipArrowPosition.BOTTOM_RIGHT:
            x = triggerX - bubbleWidth + edgePaddingX;
            y = triggerY - bubbleHeight - triggerMarginY - triggerHeight / 2;
            break;
        case TooltipArrowPosition.TOP_LEFT:
            x = triggerX - edgePaddingX;
            y = triggerY + triggerHeight / 2 + triggerMarginY;
            break;
        case TooltipArrowPosition.TOP_CENTER:
            x = triggerX - bubbleWidth / 2;
            y = triggerY + triggerHeight / 2 + triggerMarginY;
            break;
        case TooltipArrowPosition.TOP_RIGHT:
            x = triggerX - bubbleWidth + edgePaddingX;
            y = triggerY + triggerHeight / 2 + triggerMarginY;
            break;
        case TooltipArrowPosition.RIGHT_TOP:
            x = triggerX - bubbleWidth - triggerMarginX - triggerWidth / 2;
            y = triggerY - edgePaddingY;
            break;
        case TooltipArrowPosition.RIGHT_CENTER:
            x = triggerX - bubbleWidth - triggerMarginX - triggerWidth / 2;
            y = triggerY - bubbleHeight / 2;
            break;
        case TooltipArrowPosition.RIGHT_BOTTOM:
            x = triggerX - bubbleWidth - triggerMarginX - triggerWidth / 2;
            y = triggerY - bubbleHeight + edgePaddingY;
            break;
        case TooltipArrowPosition.LEFT_TOP:
            x = triggerX + triggerMarginX + triggerWidth / 2;
            y = triggerY - edgePaddingY;
            break;
        case TooltipArrowPosition.LEFT_CENTER:
            x = triggerX + triggerMarginX + triggerWidth / 2;
            y = triggerY - bubbleHeight / 2;
            break;
        case TooltipArrowPosition.LEFT_BOTTOM:
            x = triggerX + triggerMarginX + triggerWidth / 2;
            y = triggerY - bubbleHeight + edgePaddingY;
            break;
    }
    if (x > -Infinity && y > -Infinity) {
        return { x, y };
    }
    else {
        return { x: 0, y: 0 };
    }
}
export function getScaledBorderRadiusPx(shape, viewportWidth) {
    const borderRadii = {
        [BorderShape.RECTANGLE]: 1,
        [BorderShape.ROUNDED]: 8,
        [BorderShape.SQUIRCLE]: 32,
    };
    return `${borderRadii[shape] * (viewportWidth / 1080)}px`;
}
