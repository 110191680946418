import React, { useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import MobileIcon from "../../../../../assets/images/v3/mobile-variant-dark.svg";
import LinkIcon from "../../../../../assets/images/v3/external-link-icon.svg";
import SyncWarningIcon from "../../../../../assets/images/v3/synchronize-warning.svg";
import { useHasVariantMobile, useIsMobileVariantEnabled, useUpdateTourialPartial, } from "../../../../redux/selectors/redux-selectors";
import { TToggleSwitch } from "../../../styled/t-toggle-switch";
import { useCreateMobileVariantFromDesktop, useIsMobileButtonDisabled, useSetIsMobileButtonDisabled, } from "../../../../redux/selectors/builder-selectors";
import { MobileEmbedButtonCustomizationEditor } from "./embed-tab";
import { TMessageBox, TMessageBoxVariants } from "../../../styled/t-message-box";
export const MobileTab = ({ embedCodeHasChanged }) => {
    const isMobileVariantEnabled = useIsMobileVariantEnabled();
    const hasVariantMobile = useHasVariantMobile();
    const updateTourial = useUpdateTourialPartial();
    const createMobile = useCreateMobileVariantFromDesktop();
    const isMobileButtonDisabled = useIsMobileButtonDisabled();
    const setIsMobileButtonEnabled = useSetIsMobileButtonDisabled();
    const [showEmbedChangedNotification, setShowEmbedChangedNotification] = useState(false);
    const showEmbedCodeHasChangedWarning = () => {
        setShowEmbedChangedNotification(true);
        embedCodeHasChanged();
    };
    return (React.createElement(Container, null,
        hasVariantMobile && (React.createElement(React.Fragment, null,
            React.createElement(MobileSelectContainer, null,
                React.createElement("h3", null, "Sync to Desktop"),
                React.createElement(TButton, { onClick: () => {
                        createMobile();
                    }, size: "small", variant: TButtonVariants.OUTLINE, icon: SyncWarningIcon }, "Sync")),
            React.createElement(MobileSelectDescription, null, "NOTE: Clicking sync will delete your current mobile version and create a new version from your desktop tour."))),
        React.createElement(MobileSelectContainer, null,
            React.createElement("img", { src: MobileIcon }),
            React.createElement("h3", null, "Mobile responsive version"),
            React.createElement(TToggleSwitch, { enabled: isMobileVariantEnabled, size: "small", onChange: () => {
                    if (hasVariantMobile) {
                        updateTourial({
                            isVariantMobileEnabled: !isMobileVariantEnabled,
                        });
                    }
                    else {
                        createMobile();
                    }
                    showEmbedCodeHasChangedWarning();
                } })),
        React.createElement(MobileSelectDescription, null, "Creates a copy of your desktop tour using the mobile defaults you set in branding. NOTE: you will need to edit the mobile tour for an optimal viewer experience. See mobile tour best practices."),
        React.createElement(OpacityContainer, { isDisabled: !isMobileVariantEnabled },
            React.createElement(MobileSelectContainer, null,
                React.createElement("img", { src: LinkIcon }),
                React.createElement("h3", null, "Mobile tour button"),
                React.createElement(TToggleSwitch, { disabled: !isMobileVariantEnabled, enabled: !isMobileButtonDisabled, size: "small", onChange: () => {
                        if (!hasVariantMobile)
                            return;
                        setIsMobileButtonEnabled(!isMobileButtonDisabled);
                        showEmbedCodeHasChangedWarning();
                    } }),
                showEmbedChangedNotification && (React.createElement(TMessageBox, { variant: TMessageBoxVariants.WARNING, message: "Embed code changed. Update code on your site." })))),
        React.createElement(MobileSelectDescription, null, "Displays a button on the mobile site in place of the tour embed. Viewers will be redirected to the mobile version via the mobile tour button."),
        hasVariantMobile && !isMobileButtonDisabled && (React.createElement(MobileEmbedButtonCustomizationEditor, { notifyEmbedCodeChange: showEmbedCodeHasChangedWarning }))));
};
const Container = styled.div `
  width: 658px;
  padding: 24px;
  background-color: ${newTheme.colors.grey100};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  color: ${newTheme.colors.grey800};
`;
const MobileSelectContainer = styled.div `
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  height: 24px;
  > h3 {
    font: ${newTheme.fonts.bold};
    white-space: nowrap;
  }
  > img {
    width: 24px;
    height: 24px;
  }
`;
const MobileSelectDescription = styled.div `
  display: block;
  font: ${newTheme.fonts.small};
  margin: 8px 0 24px 0;
`;
const OpacityContainer = styled.div `
  filter: ${({ isDisabled }) => (isDisabled ? "opacity(0.5)" : "opacity(1)")};
`;
