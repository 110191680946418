import React from "react";
import { DCCMSection } from "./components/dc-cm-section";
import { DCButtonAction, DCCMPageType, DCIconType } from "../../../../../../shared/types/demo-center";
import { useDCConfig, useDCLogoUrl, useDCSetAutoPlayIsEnabled, useDCSetCTAConfig, useDCSetGallerySectionIsEnabled, useDCSetLogoUrl, } from "../hooks/dc-hooks";
import { DCCMTextEditor } from "./components/dc-cm-text-editor";
import { DCCMDropdownSelect } from "./components/dc-cm-dropdown-select";
import { DCCMCalendlySelector } from "./components/dc-cm-calendly-selector";
import { DCCMFormSelector } from "./components/dc-cm-form-selector";
import { DCCMHubspotSelector } from "./components/dc-cm-hubspot-selector";
import { TMessageBox, TMessageBoxVariants } from "../../../styled/t-message-box";
import { useDCBuilderWarning, useDCLivePreviewHelper } from "../hooks/dc-builder-hooks";
import { DCCMSettingToggleContainer, DCCMToggleLabel } from "./dc-cm-intro-page-editor";
import { TToggleSwitch } from "../../../styled/t-toggle-switch";
export const DCCMNavigationEditor = () => {
    const { header, gallerySection, autoplay } = useDCConfig();
    const { cta } = header;
    const logoUrl = useDCLogoUrl();
    const setLogoUrl = useDCSetLogoUrl();
    const setCTAConfig = useDCSetCTAConfig();
    const setGallerySectionIsEnabled = useDCSetGallerySectionIsEnabled();
    const setAutoPlayIsEnabled = useDCSetAutoPlayIsEnabled();
    const warning = useDCBuilderWarning(DCCMPageType.NAVIGATION);
    useDCLivePreviewHelper(DCCMPageType.NAVIGATION);
    const ctaOptions = [
        { label: "External Link", value: DCButtonAction.LINK },
        { label: "Calendly", value: DCButtonAction.CALENDLY_SCHEDULER },
        { label: "Hubspot Meeting", value: DCButtonAction.HUBSPOT_SCHEDULER },
        { label: "Open Form", value: DCButtonAction.FORM },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(DCCMSection, { icon: DCIconType.IMAGE, title: "Logo URL", description: "Link back to your marketing site when a visitor clicks on your logo." },
            React.createElement(DCCMTextEditor, { title: "Logo URL", value: logoUrl, onChange: e => setLogoUrl(e.target.value) })),
        React.createElement(DCCMSection, { icon: DCIconType.MEGAPHONE, title: "CTA", description: "Encourage visitors towards requesting a live demo." },
            warning && React.createElement(TMessageBox, { variant: TMessageBoxVariants.WARNING, message: warning, size: "medium" }),
            React.createElement(DCCMTextEditor, { title: "CTA Text", value: cta.text, onChange: e => setCTAConfig(Object.assign(Object.assign({}, cta), { text: e.target.value })) }),
            React.createElement(DCCMDropdownSelect, { title: "On CTA Click", options: ctaOptions, selectedValue: cta.action, onChange: e => setCTAConfig(Object.assign(Object.assign({}, cta), { action: e.value })) }),
            cta.action === DCButtonAction.LINK && (React.createElement(DCCMTextEditor, { title: "CTA URL", value: cta.url, onChange: e => setCTAConfig(Object.assign(Object.assign({}, cta), { url: e.target.value })) })),
            cta.action === DCButtonAction.CALENDLY_SCHEDULER && (React.createElement(DCCMCalendlySelector, { cta: cta, onChange: url => setCTAConfig(Object.assign(Object.assign({}, cta), { url })) })),
            cta.action === DCButtonAction.FORM && (React.createElement(DCCMFormSelector, { formId: cta === null || cta === void 0 ? void 0 : cta.formId, onChange: formId => setCTAConfig(Object.assign(Object.assign({}, cta), { formId })) })),
            cta.action === DCButtonAction.HUBSPOT_SCHEDULER && (React.createElement(DCCMHubspotSelector, { cta: cta, onChange: url => setCTAConfig(Object.assign(Object.assign({}, cta), { url })) }))),
        React.createElement(DCCMSection, { icon: DCIconType.LIBRARY, title: "Gallery View", description: "Allow visitors to browse your demo center content." },
            React.createElement(DCCMSettingToggleContainer, null,
                React.createElement(DCCMToggleLabel, null, "Enable the gallery view"),
                React.createElement(TToggleSwitch, { onChange: () => setGallerySectionIsEnabled(!gallerySection.isEnabled), enabled: gallerySection.isEnabled }))),
        React.createElement(DCCMSection, { icon: DCIconType.VIDEO, title: "Autoplay Video Content", description: "Automatically start video playback when a user visits a piece of video content." },
            React.createElement(DCCMSettingToggleContainer, null,
                React.createElement(DCCMToggleLabel, null, "Enable video autoplay"),
                React.createElement(TToggleSwitch, { onChange: () => setAutoPlayIsEnabled(!autoplay), enabled: autoplay })))));
};
