import React from "react";
import UndoIcon from "../../../../../assets/images/v3/undo.svg";
import RedoIcon from "../../../../../assets/images/v3/redo.svg";
import styled from "styled-components";
import { useRedo, useUndo } from "../../../../redux/selectors/redux-selectors";
import { newTheme } from "../../../styled/theme";
export function UndoRedoButtons() {
    const undo = useUndo();
    const redo = useRedo();
    return (React.createElement(Div, null,
        React.createElement(Button, { id: "_test_undo-button", onClick: undo },
            React.createElement("img", { draggable: "false", src: UndoIcon, alt: "undo button" })),
        React.createElement(Button, { id: "_test_redo-button", onClick: redo },
            React.createElement("img", { draggable: "false", src: RedoIcon, alt: "redo button" }))));
}
const Button = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  cursor: pointer;
`;
const Div = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  animation: ${newTheme.animations.fadeIn} ${newTheme.transitions.layoutShift};
`;
