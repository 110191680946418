import { ActionType, ArchiveActions } from "../actions/redux-actions";
import { ADD_TOAST } from "../../legacy/actions/legacy-action-types";
import { ToastModes } from "../../types/definitions";
export const noOpMiddleware = (store) => (next) => (action) => {
    var _a, _b, _c;
    const isArchived = (_c = (_b = (_a = store === null || store === void 0 ? void 0 : store.getState()) === null || _a === void 0 ? void 0 : _a.tourialRootReducer) === null || _b === void 0 ? void 0 : _b.tourial) === null || _c === void 0 ? void 0 : _c.archivedAt;
    // only display no-op toast if it is an tourial edit action
    if (isArchived && !ArchiveActions.find(a => action.type === a) && Object.keys(ActionType).includes(action.type)) {
        return next({
            type: ADD_TOAST,
            toast: {
                mode: ToastModes.ERROR,
                message: NO_OP_MESSAGE,
            },
        });
    }
    return next(action);
};
export const NO_OP_MESSAGE = "Cannot edit an archived tour";
