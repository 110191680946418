import React, { useContext } from "react";
import { useHTStep, useHTStepIndex, useHTSteps, useHTTheme } from "../../../../../redux/selectors/ht-selectors";
import { EditorContainer, HTDraftTextArea, HTLabelledTextInput } from "../shared";
import { DraftContext } from "../dom-tour-builder-page";
import { cloneDeep } from "lodash";
import { RichTextEditorContainer } from "../../../../tools/tooltip-v2/editor/components/style-section";
import { ControlContainer, ControlLabel } from "../../../../styled/editor-components";
import { Divider } from "../../../../../legacy/components/builder/tools/form-v2/editor";
import { HTArrowPosition, HTIconTypes } from "../../../../../../../shared/types/dom-tour";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { HTIcon } from "../atomic/ht-icon";
import { TourialEditorFocusFields } from "../../../../../types/definitions";
import { useFocusTourialEditorField } from "../../../../../hooks/use-focus-field";
import { ColorPicker } from "../../../builder/editor/atomic/color-picker";
import { ShapeSelector } from "../../../builder/editor/atomic/shape-selector";
import { BorderShape } from "../../../../../../../shared/types/tool-data-types/common";
import { TCheckbox } from "../../../../styled/t-checkbox";
import { OpacitySlider } from "../../../builder/editor/atomic/opacity-slider";
const arrowPositionOptions = {
    [HTArrowPosition.AUTO]: "Auto",
    [HTArrowPosition.RIGHT]: HTIconTypes.TOOLTIP_LEFT_ARROW,
    [HTArrowPosition.LEFT]: HTIconTypes.TOOLTIP_RIGHT_ARROW,
    [HTArrowPosition.TOP]: HTIconTypes.TOOLTIP_BOTTOM_ARROW,
    [HTArrowPosition.BOTTOM]: HTIconTypes.TOOLTIP_TOP_ARROW,
};
export function TooltipEditor() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const draft = useContext(DraftContext);
    const step = useHTStep();
    const steps = useHTSteps();
    const stepIndex = useHTStepIndex();
    const theme = useHTTheme();
    useFocusTourialEditorField();
    return (React.createElement(EditorContainer, null,
        React.createElement(HTLabelledTextInput, { id: TourialEditorFocusFields.BUBBLE_HEADLINE_TEXT, name: "Headline text", value: step.tooltip.header, commit: commitHeaderText }),
        React.createElement(RichTextEditorContainer, { style: { width: "100%" }, id: TourialEditorFocusFields.BUBBLE_BODY_TEXT },
            React.createElement(ControlLabel, null, "Body text"),
            React.createElement(HTDraftTextArea, { id: step.id, value: step.tooltip.body, commit: commitBodyText })),
        React.createElement(HTLabelledTextInput, { name: "Next button text", value: step.tooltip.nextButtonText || "", commit: commitNextButtonText }),
        React.createElement(HTLabelledTextInput, { name: "Back button text", value: step.tooltip.prevButtonText || "", commit: commitPrevButtonText }),
        React.createElement(Divider, { style: { marginLeft: -16, width: "calc(100% + 32px)" } }),
        React.createElement(ArrowPositionContainer, null, Object.keys(arrowPositionOptions).map(ap => {
            const isSelected = step.tooltip.arrowPosition === ap || (ap === HTArrowPosition.AUTO && !step.tooltip.arrowPosition);
            return (React.createElement(VariantButton, { key: ap, disabled: isSelected, isActive: isSelected, onClick: () => updateArrowPosition(ap) }, ap === HTArrowPosition.AUTO ? (React.createElement("span", null, "Auto")) : (React.createElement(HTIcon, { type: arrowPositionOptions[ap], fill: isSelected ? "black" : "#90939D", style: { cursor: "pointer" } }))));
        })),
        step.tooltip.arrowPosition && step.tooltip.arrowPosition !== HTArrowPosition.AUTO && (React.createElement(Info, null, "The tooltip may not overflow from the tour and may reposition on smaller screen sizes.")),
        React.createElement(Divider, { style: { marginLeft: -16, width: "calc(100% + 32px)" } }),
        React.createElement("div", { style: { width: "100%" } },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Clickzone"),
                React.createElement(TCheckbox, { checked: (_a = step.tooltip.clickzone) === null || _a === void 0 ? void 0 : _a.isEnabled, onChange: (isChecked) => {
                        commitClickzoneUpdate("isEnabled", isChecked);
                    } })),
            ((_b = step.tooltip.clickzone) === null || _b === void 0 ? void 0 : _b.isEnabled) && (React.createElement(React.Fragment, null,
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Color"),
                    React.createElement(ColorPicker, { color: (_c = step.tooltip.clickzone) === null || _c === void 0 ? void 0 : _c.color, onChange: (color) => {
                            commitClickzoneUpdate("color", color);
                        } })),
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Shape"),
                    React.createElement(ShapeSelector, { shape: (_d = step.tooltip.clickzone) === null || _d === void 0 ? void 0 : _d.shape, onChange: (shape) => {
                            commitClickzoneUpdate("shape", shape);
                        } })),
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Border"),
                    React.createElement(TCheckbox, { checked: (_e = step.tooltip.clickzone) === null || _e === void 0 ? void 0 : _e.isBorderEnabled, onChange: (isChecked) => {
                            commitClickzoneUpdate("isBorderEnabled", isChecked);
                        } })),
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Pulse Effect"),
                    React.createElement(TCheckbox, { checked: (_f = step.tooltip.clickzone) === null || _f === void 0 ? void 0 : _f.isPulseEnabled, onChange: (isChecked) => {
                            commitClickzoneUpdate("isPulseEnabled", isChecked);
                        } })),
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Spotlight"),
                    React.createElement(TCheckbox, { checked: (_g = step.tooltip.clickzone) === null || _g === void 0 ? void 0 : _g.isSpotlightEnabled, onChange: (isChecked) => {
                            commitClickzoneUpdate("isSpotlightEnabled", isChecked);
                        } })),
                ((_h = step.tooltip.clickzone) === null || _h === void 0 ? void 0 : _h.isSpotlightEnabled) && (React.createElement(ControlContainer, { style: { marginBottom: 20 } },
                    React.createElement(ControlLabel, null, "Opacity"),
                    React.createElement(OpacitySlider, { value: (_j = step.tooltip.clickzone) === null || _j === void 0 ? void 0 : _j.spotlightOpacity, onChange: (e) => {
                            commitClickzoneUpdate("spotlightOpacity", e);
                        } }))))))));
    function updateArrowPosition(value) {
        commitUpdate("arrowPosition", value);
    }
    function commitBodyText(value) {
        commitUpdate("body", value);
    }
    function commitHeaderText(value) {
        commitUpdate("header", value);
    }
    function commitNextButtonText(value) {
        commitUpdate("nextButtonText", value);
    }
    function commitPrevButtonText(value) {
        commitUpdate("prevButtonText", value);
    }
    function commitUpdate(key, value) {
        const tt = cloneDeep(step.tooltip);
        tt[key] = value;
        const s = cloneDeep(steps);
        s[stepIndex] = Object.assign(Object.assign({}, step), { tooltip: tt });
        void draft.update({ steps: s });
    }
    function commitClickzoneUpdate(key, value) {
        const clickzone = cloneDeep(step.tooltip.clickzone || createDefaultClickzone(theme));
        clickzone[key] = value;
        const s = cloneDeep(steps);
        s[stepIndex] = Object.assign(Object.assign({}, step), { tooltip: Object.assign(Object.assign({}, step.tooltip), { clickzone }) });
        void draft.update({ steps: s });
    }
}
function createDefaultClickzone(theme) {
    return {
        isEnabled: false,
        size: { width: 50, height: 50 },
        shape: BorderShape.RECTANGLE,
        color: theme.tooltip.backgroundColor,
        isBorderEnabled: false,
        isPulseEnabled: true,
        // spotlight fields
        isSpotlightEnabled: false,
        spotlightOpacity: 0.4,
    };
}
export const VariantButton = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57.6px;
  height: 32px;
  cursor: ${({ isActive }) => (isActive ? "inherit" : "pointer")};
  background-color: ${({ isActive }) => (isActive ? newTheme.colors.white : newTheme.colors.grey100)};
  outline: ${({ isActive }) => isActive ? `1px solid ${newTheme.colors.grey500}` : `1px solid ${newTheme.colors.grey400}`};
  box-shadow: ${({ isActive }) => (isActive ? "2px 2px 10px rgba(33, 34, 41, 0.05)" : "none")};
  font: ${newTheme.fonts.medium};
  z-index: ${({ isActive }) => (isActive ? 1 : "auto")};
  color: ${({ isActive }) => (isActive ? "black" : newTheme.colors.grey500)};
`;
const ArrowPositionContainer = styled.div `
  display: flex;
  button:nth-child(1) {
    border-radius: 4px 0 0 4px;
  }
  button:nth-child(5) {
    border-radius: 0 4px 4px 0;
  }
`;
const Info = styled.div `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey500};
`;
