import styled from "styled-components";
import React from "react";
import { newTheme } from "../../../../styled/theme";
import { HTStepsPanelStepper } from "./ht-steps-panel-stepper";
import { useHTSetMode } from "../../../../../redux/selectors/ht-selectors";
import { HTMode } from "../../../../../../../shared/types/dom-tour";
import { PREVIEW_STEPPER_WIDTH } from "../../config";
import { useHTIsEditModeBlocked } from "../../../../../hooks/use-is-collaborator-blocked";
export function HTPreviewStepper() {
    const setMode = useHTSetMode();
    const isEditModeBlocked = useHTIsEditModeBlocked();
    return (React.createElement(CurrentStepPanelContainer, null,
        React.createElement(HTStepsPanelStepper, null),
        !isEditModeBlocked && React.createElement(ExitPreviewLink, { onClick: () => setMode(HTMode.EDIT) }, "Exit preview")));
}
const CurrentStepPanelContainer = styled.div `
  position: fixed;
  bottom: 0px;
  margin-bottom: 20px;
  padding: 10px 16px;
  height: 40px;
  background: ${newTheme.colors.white};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  box-shadow: ${newTheme.shadows.outside60};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(50vw - ${PREVIEW_STEPPER_WIDTH / 2}px);
  width: ${PREVIEW_STEPPER_WIDTH}px;
`;
const ExitPreviewLink = styled.div `
  cursor: pointer;
  font: ${newTheme.fonts.small};
  line-height: 20px;
  border-left: 1px solid ${newTheme.colors.grey500};
  margin-left: 10px;
  padding-left: 10px;
  color: ${newTheme.colors.blue500};
  min-width: 78px;
`;
