export var ToDemoChatbotEvents;
(function (ToDemoChatbotEvents) {
    ToDemoChatbotEvents["TOURIAL_VIEW"] = "TOURIAL_VIEW";
    ToDemoChatbotEvents["TOURIAL_CLOSE"] = "TOURIAL_CLOSE";
    ToDemoChatbotEvents["NUDGE_SELECTION"] = "NUDGE_SELECTION";
    ToDemoChatbotEvents["TOURIAL_END"] = "TOURIAL_END";
})(ToDemoChatbotEvents || (ToDemoChatbotEvents = {}));
export var FromDemoChatbotEvents;
(function (FromDemoChatbotEvents) {
    FromDemoChatbotEvents["VIEW_NUDGES"] = "VIEW_NUDGES";
    FromDemoChatbotEvents["DISPLAY_MEDIA_DEMO"] = "DISPLAY_MEDIA_DEMO";
    FromDemoChatbotEvents["OPEN_SCHEDULER"] = "OPEN_SCHEDULER";
})(FromDemoChatbotEvents || (FromDemoChatbotEvents = {}));
