var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import GreyPlusIcon from "../../../../../assets/images/v3/plus-sign-grey.svg";
import EyedropperIcon from "../../../../../assets/images/eye-dropper-icon.svg";
import { SketchPicker } from "react-color";
import { Popover } from "@blueprintjs/core";
import { ColorSwatch } from "./color-swatch";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { useOutsideClick } from "../../../../hooks/use-outside-click";
import { EyedropperButton } from "../../builder/editor/atomic/color-picker";
export const EmptyColorSwatch = (props) => {
    const { onAddColor } = props;
    const popoverRef = useRef();
    const buttonRef = useRef();
    const [selectedColor, setSelectedColor] = useState(newTheme.colors.blue500);
    const [popoverIsOpen, setPopoverIsOpen] = useState(false);
    const [eyedropperAPIEnabled, setEyedropperAPIEnabled] = useState(false);
    useOutsideClick([popoverRef, buttonRef], () => {
        if (popoverIsOpen)
            setPopoverIsOpen(false);
    });
    useEffect(() => {
        if ("EyeDropper" in window) {
            setEyedropperAPIEnabled(true);
        }
    }, []);
    const activateEyedropperAPI = () => __awaiter(void 0, void 0, void 0, function* () {
        // @ts-expect-error
        const eyeDropper = new EyeDropper();
        try {
            const { sRGBHex } = yield eyeDropper.open();
            if (sRGBHex) {
                setSelectedColor(sRGBHex);
            }
        }
        catch (err) {
            console.log("User Cancelled Color Select");
        }
    });
    return (React.createElement(EmptyColorSwatchContainer, null,
        React.createElement(Popover, { minimal: true, isOpen: popoverIsOpen, hasBackdrop: true, popoverRef: popoverRef, content: React.createElement(ColorPickerContainer, null,
                React.createElement(SketchPicker, { disableAlpha: true, presetColors: [], color: selectedColor, onChange: color => {
                        setSelectedColor(color.hex);
                    } }),
                React.createElement(ColorPickerFooter, null,
                    React.createElement(ColorSwatch, { color: selectedColor }),
                    eyedropperAPIEnabled && React.createElement(CustomEyeDropperButton, { onClick: activateEyedropperAPI }),
                    React.createElement(Spacer, null),
                    React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY, onClick: () => {
                            setPopoverIsOpen(false);
                            onAddColor(selectedColor);
                        } }, "Add Color"))) },
            React.createElement(AddColorButton, { ref: buttonRef, onClick: () => {
                    setPopoverIsOpen(true);
                } },
                React.createElement("img", { src: GreyPlusIcon })))));
};
export const CustomEyeDropperButton = ({ onClick }) => {
    return (React.createElement(EyedropperButton, { onClick: onClick, style: { borderLeftWidth: "1px", borderRadius: "4px" } },
        React.createElement("img", { src: EyedropperIcon })));
};
const ColorPickerContainer = styled.div `
  background-color: ${newTheme.colors.grey100};
  border-radius: 4px;
  padding: 6px;
  .sketch-picker {
    background: none !important;
    box-shadow: none !important;
  }
`;
const Spacer = styled.div `
  display: flex;
  flex: 1;
`;
const ColorPickerFooter = styled.div `
  display: flex;
  padding: 10px 10px 0 10px;
`;
const EmptyColorSwatchContainer = styled.div `
  display: flex;
  width: 32px;
  height: 32px;
  border: 1px dashed ${newTheme.colors.grey600};
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
`;
const AddColorButton = styled.button `
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
  cursor: pointer;
`;
