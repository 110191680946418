import HomeGreyIcon from "../../../../../../assets/images/v3/home-grey.svg";
import React from "react";
import styled from "styled-components";
import { HomePageTabs } from "../../../../../../../shared/types/tourial-model";
export function Back() {
    return (React.createElement(Button, { onClick: handleClick },
        React.createElement("img", { draggable: "false", src: HomeGreyIcon })));
    function handleClick() {
        window.location.href = `/?selectedTab=${HomePageTabs.HTML}`;
    }
}
const Button = styled.button `
  width: 45px;
  height: 45px;
  cursor: pointer;
`;
