import styled from "styled-components";
import CollapseArrowIcon from "../../../../../../assets/images/v3/collapse.svg";
import ExpandArrowIcon from "../../../../../../assets/images/v3/expand.svg";
import FolderIcon from "../../../../../../assets/images/v3/folder-icon.svg";
import React from "react";
import { EDIT_PANEL_WIDTH, STEPS_PANEL_STUB_HEIGHT } from "../../config";
import { StubPanelButton, StubPanelIcon } from "../../../builder/storyboard-mini/storyboard-mini-stub";
import { newTheme } from "../../../../styled/theme";
import { HTStepsPanelStepper } from "./ht-steps-panel-stepper";
export function HTStepsPanelStub({ stepsPanelHeight, setIsStepsPanelOpened, setIsAddScreenModalOpen, }) {
    const collapseClickHandler = () => setIsStepsPanelOpened(stepsPanelHeight === 0 ? true : false);
    return (React.createElement(StoryboardStubContainer, { style: {
            bottom: stepsPanelHeight,
            left: 0,
            width: `calc(100% - ${EDIT_PANEL_WIDTH}px)`,
        } },
        React.createElement(StubPanelButton, { onClick: collapseClickHandler, style: { marginLeft: 8, color: newTheme.colors.black } },
            React.createElement(StubPanelIcon, { src: stepsPanelHeight > 0 ? CollapseArrowIcon : ExpandArrowIcon }),
            stepsPanelHeight > 0 ? "Collapse storyboard " : "Expand storyboard"),
        React.createElement(HTStepsPanelStepper, null),
        React.createElement(StubPanelButton, { onClick: () => {
                setIsAddScreenModalOpen(true);
            }, style: { marginRight: 8, color: newTheme.colors.black } },
            React.createElement(StubPanelIcon, { src: FolderIcon }),
            "Media library")));
}
const StoryboardStubContainer = styled.div `
  display: flex;
  align-items: center;
  align-content: center;
  background-color: ${newTheme.colors.white};
  position: fixed;
  height: ${STEPS_PANEL_STUB_HEIGHT}px;
  transition:
    left ${newTheme.transitions.layoutShift},
    bottom ${newTheme.transitions.layoutShift},
    width ${newTheme.transitions.layoutShift};
  border-top: 1px solid ${newTheme.colors.grey};
  border-bottom: 1px solid ${newTheme.colors.grey};
`;
