import React from "react";
import styled, { keyframes } from "styled-components";
import { DCButtonAction } from "../../../../../../shared/types/demo-center";
import { useSetHubspotScheduler } from "../../../../redux/selectors/live-helper-selectors";
import { useDCConfig, useDCIsLiveMode, useDCLogo, useDCLogoUrl, useDCModifyButtonConfig, useDCTheme, } from "../hooks/dc-hooks";
import { useSetPageForm } from "../../../../redux/selectors/product-page-selectors";
import { useDCClickEvent } from "../hooks/dc-event-hooks";
import { DCTrackedButton } from "./dc-tracked-button";
import { dcGetRGBColorStringFromHex } from "../helpers/dc-helpers";
import { useDCGoToGallery, useDCGoToHome, useDCGoToSelfDemo } from "../hooks/dc-navigation-hooks";
import { isEmbedded } from "../../../../helpers/live";
export const DCHeader = ({ showBrowseButton, showDemoButton, transparent }) => {
    const logo = useDCLogo();
    const logoUrl = useDCLogoUrl();
    const goToHome = useDCGoToHome();
    const goToGallery = useDCGoToGallery();
    const goToDemo = useDCGoToSelfDemo();
    const clickEvent = useDCClickEvent();
    const modifyButtonConfig = useDCModifyButtonConfig();
    const isLiveMode = useDCIsLiveMode();
    const theme = useDCTheme();
    const { header, gallerySection } = useDCConfig();
    const { demoButton, browseButton, cta } = header;
    const goToUrlOrHome = () => {
        clickEvent({
            action: DCButtonAction.LOGO,
            url: logoUrl,
        });
        if (logoUrl) {
            window.open(logoUrl, "_blank");
            return;
        }
        goToHome();
    };
    if (isEmbedded())
        return null;
    return (React.createElement(HeaderArea, { theme: theme, transparent: transparent, style: isLiveMode ? { position: "fixed", zIndex: "1000" } : { position: "absolute" } },
        logo && React.createElement(Logo, { src: logo, onClick: goToUrlOrHome }),
        React.createElement(ButtonContainer, null,
            showBrowseButton && gallerySection.isEnabled && (React.createElement(DCTrackedButton, { config: modifyButtonConfig(browseButton, { applyTheme: true }), onClick: goToGallery })),
            showDemoButton && (React.createElement(DemoButton, null,
                React.createElement(DCTrackedButton, { config: modifyButtonConfig(demoButton, { applyTheme: true }), onClick: goToDemo }))),
            React.createElement(DCCTA, { config: modifyButtonConfig(cta, { applyGradient: true }) }))));
};
export const DCCTA = ({ config }) => {
    const { action, url, formId } = config;
    const setHubspotScheduler = useSetHubspotScheduler();
    const setPageForm = useSetPageForm();
    const ctaClick = () => {
        switch (action) {
            case DCButtonAction.CALENDLY_SCHEDULER:
                // @ts-expect-error
                Calendly.initPopupWidget({ url: url });
                break;
            case DCButtonAction.HUBSPOT_SCHEDULER:
                setHubspotScheduler(url);
                break;
            case DCButtonAction.FORM:
                setPageForm({
                    formId,
                    isGated: false,
                    isEnabled: true,
                    isCTA: true,
                });
                break;
            default:
                return;
        }
    };
    return React.createElement(DCTrackedButton, { config: config, onClick: ctaClick });
};
const HeaderArea = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 82px;
  width: 100%;
  padding: 22px;
  background-color: ${p => !p.transparent
    ? dcGetRGBColorStringFromHex(p.theme.neutralTranslucentColor, p.theme.neutralTranslucentOpacity)
    : "transparent"};
  backdrop-filter: ${p => (!p.transparent ? "blur(50px)" : "none")};
  border-color: ${p => p.theme.neutralSecondary};
  border-style: solid;
  border-bottom-width: ${p => (p.transparent ? 0 : "1px")};
`;
const Logo = styled.img `
  max-height: 32px;
  cursor: pointer;
`;
const ButtonContainer = styled.div `
  flex: 1;
  display: flex;
  justify-content: right;
  gap: 16px;
`;
export const demoButtonAppear = keyframes `
  from {
    opacity: 0;
    margin-right: 300px;
  }
  to {
    opacity: 1;
    margin-right: 0;
  }
`;
const DemoButton = styled.div `
  animation: ${demoButtonAppear} 0.2s ease-in;
`;
