import React from "react";
import styled from "styled-components";
import DesktopInactiveIcon from "../../../../../../assets/images/v3/desktop-variant-dark.svg";
import DesktopActiveIcon from "../../../../../../assets/images/v3/desktop-variant-light.svg";
import MobileInactiveIcon from "../../../../../../assets/images/v3/mobile-variant-dark.svg";
import MobileActiveIcon from "../../../../../../assets/images/v3/mobile-variant-light.svg";
import { useActiveVariantName, useSetActiveVariant } from "../../../../../redux/selectors/redux-selectors";
import { ActiveVariantName } from "../../../../../../../shared/types/tool-data-types/common";
import { newTheme } from "../../../../styled/theme";
import TTooltip from "../../../../styled/t-tooltip";
export function VariantSelectorMini() {
    const variant = useActiveVariantName();
    const setVariant = useSetActiveVariant();
    const isDesktop = variant === ActiveVariantName.VARIANT_DESKTOP;
    return (React.createElement(Div, null,
        React.createElement(TTooltip, { disabled: isDesktop, position: "bottom", content: "Edit desktop version" },
            React.createElement(VariantButton, { disabled: isDesktop, isActive: isDesktop, isLeft: true, onClick: () => setVariant(ActiveVariantName.VARIANT_DESKTOP) },
                React.createElement(Icon, { src: isDesktop ? DesktopActiveIcon : DesktopInactiveIcon }),
                "Desktop")),
        React.createElement(TTooltip, { disabled: !isDesktop, position: "bottom", content: "Edit mobile version" },
            React.createElement(VariantButton, { disabled: !isDesktop, isActive: !isDesktop, isLeft: false, onClick: () => setVariant(ActiveVariantName.VARIANT_MOBILE) },
                React.createElement(Icon, { src: isDesktop ? MobileInactiveIcon : MobileActiveIcon }),
                "Mobile"))));
}
const Div = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
const VariantButton = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 32px;
  cursor: ${({ isActive }) => (isActive ? "inherit" : "pointer")};
  border-radius: ${({ isLeft }) => (isLeft ? "4px 0 0 4px" : "0 4px 4px 0")};
  background-color: ${({ isActive }) => (isActive ? newTheme.colors.grey100 : newTheme.colors.white)};
  outline: ${({ isActive }) => isActive ? `1px solid ${newTheme.colors.grey200}` : `1px solid ${newTheme.colors.grey300}`};
  box-shadow: ${({ isActive }) => (isActive ? "2px 2px 10px rgba(33, 34, 41, 0.05)" : "none")};
  font: ${newTheme.fonts.small};
`;
const Icon = styled.img `
  width: 16px;
  height: 16px;
  margin-right: 3px;
`;
