export var IntegrationTarget;
(function (IntegrationTarget) {
    IntegrationTarget["HUBSPOT"] = "HUBSPOT";
    IntegrationTarget["SALESFORCE"] = "SALESFORCE";
    IntegrationTarget["MARKETO"] = "MARKETO";
    IntegrationTarget["PARDOT"] = "PARDOT";
    IntegrationTarget["CALENDLY"] = "CALENDLY";
    IntegrationTarget["CHILIPIPER"] = "CHILIPIPER";
})(IntegrationTarget || (IntegrationTarget = {}));
export var HiddenFieldDataOptions;
(function (HiddenFieldDataOptions) {
    HiddenFieldDataOptions["sessionId"] = "sessionId";
    HiddenFieldDataOptions["userId"] = "userId";
    HiddenFieldDataOptions["tourialName"] = "tourialName";
    HiddenFieldDataOptions["tourialSlug"] = "tourialSlug";
    HiddenFieldDataOptions["parentUrl"] = "parentUrl";
    HiddenFieldDataOptions["referringUrl"] = "referringUrl";
    HiddenFieldDataOptions["utm_campaign"] = "utm_campaign";
    HiddenFieldDataOptions["utm_source"] = "utm_source";
    HiddenFieldDataOptions["utm_medium"] = "utm_medium";
    HiddenFieldDataOptions["utm_term"] = "utm_term";
    HiddenFieldDataOptions["utm_content"] = "utm_content";
    HiddenFieldDataOptions["email"] = "email";
    HiddenFieldDataOptions["TOURIAL_SOURCE"] = "TOURIAL_SOURCE__c";
    HiddenFieldDataOptions["CUSTOM_VALUE"] = "CUSTOM_VALUE";
    HiddenFieldDataOptions["CUSTOM_QUERY"] = "CUSTOM_QUERY";
    HiddenFieldDataOptions["href"] = "href";
})(HiddenFieldDataOptions || (HiddenFieldDataOptions = {}));
export var HiddenFieldMapType;
(function (HiddenFieldMapType) {
    HiddenFieldMapType["DYNAMIC"] = "DYNAMIC";
    HiddenFieldMapType["STATIC"] = "STATIC";
})(HiddenFieldMapType || (HiddenFieldMapType = {}));
