import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/legacy-reducers";
import { noOpMiddleware } from "../redux/middleware/noOpMiddleware";
const initialState = {};
const middleware = [thunk, noOpMiddleware];
const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
    : (f) => f));
export default store;
