import React from "react";
import styled from "styled-components";
import { dcBodyFontSizes, dcTitleFontSizes } from "../helpers/dc-style-helpers";
import { TextStyle } from "../../../../../../shared/types/tool-data-types/common";
import { textAlignments } from "../../../../helpers/text-helpers";
const StyledText = styled.div `
  color: ${p => (p.isActive ? p.config.activeColor : p.config.color)};
  font-family: ${p => p.config.font};
  font-weight: ${p => (p.config.style === TextStyle.BOLD ? "bold" : "normal")};
  text-decoration: ${p => (p.config.style === TextStyle.UNDERLINE ? "underline" : "none")};
  font-style: ${p => (p.config.style === TextStyle.ITALIC ? "italic" : "normal")};
  text-transform: ${p => (p.config.isUppercase ? "uppercase" : "none")};
  text-align: ${p => textAlignments[p.config.align]};
`;
export const DCStyledBodyText = styled(StyledText) `
  font-size: ${p => dcBodyFontSizes[p.config.size]}px;
  line-height: ${p => dcBodyFontSizes[p.config.size] * 1.5}px;
`;
export const DCStyledTitleText = styled(StyledText) `
  font-size: ${p => dcTitleFontSizes[p.config.size]}px;
`;
export const DCBodyText = ({ config, style, onClick }) => {
    return (React.createElement(DCStyledBodyText, { config: config.style, style: style, onClick: onClick }, config.text));
};
export const DCTitleText = ({ config, style, onClick }) => {
    return (React.createElement(DCStyledTitleText, { config: config.style, style: style, onClick: onClick }, config.text));
};
