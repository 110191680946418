import React from "react";
import { useActiveVariantName, useCreateVariantMobileTemplate, useHasVariantMobile, useIsMobileVariantEnabled, useSetActiveVariant, useUpdateTourialPartial, } from "../../../redux/selectors/redux-selectors";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
import { ActiveVariantName } from "../../../../../shared/types/tool-data-types/common";
export const EnableMobileToggle = React.memo(function () {
    const activeVariantName = useActiveVariantName();
    const hasVariantMobile = useHasVariantMobile();
    const createVariantMobileTemplate = useCreateVariantMobileTemplate();
    const setActiveVariant = useSetActiveVariant();
    const updateTourial = useUpdateTourialPartial();
    const isVariantMobileEnabled = useIsMobileVariantEnabled();
    const isMobileEnabled = () => hasVariantMobile && isVariantMobileEnabled;
    const baseButtonStyle = Object.assign(Object.assign({}, theme.typography.p), { cursor: "pointer", width: "50%", background: theme.colors.grey.lightest, border: `1px solid ${theme.colors.grey.light}`, color: theme.colors.grey.medium, fontSize: "12px" });
    const activeButtonStyle = {
        color: theme.colors.cobalt.darkest,
        background: theme.colors.sky.lightest,
        border: `1px solid ${theme.colors.cobalt.darkest}`,
    };
    const onClickHandler = () => {
        if (!hasVariantMobile) {
            createVariantMobileTemplate();
        }
        else {
            if (isVariantMobileEnabled && activeVariantName === ActiveVariantName.VARIANT_MOBILE) {
                setActiveVariant(ActiveVariantName.VARIANT_DESKTOP);
            }
            updateTourial({
                isVariantMobileEnabled: !isVariantMobileEnabled,
            });
        }
    };
    return (
    // <div style={{ width: "100%", margin: 0 }}>
    //   <div
    //     style={{
    //       width: 140,
    //       textAlign: "left"
    //     }}
    //   >
    //     <TLabel
    //       style={{
    //         ...theme.typography.p,
    //         fontSize: 14,
    //         color: theme.colors.grey.darkest
    //       }}
    //     >
    //       Enable Mobile
    //       <TToggle
    //         style={{ position: "relative", top: 1, left: -5, width: "32px" }}
    //         value={hasVariantMobile && isVariantMobileEnabled}
    //         onClick={() => {
    //           if (!hasVariantMobile) {
    //             createVariantMobileTemplate();
    //           } else {
    //             if (
    //               isVariantMobileEnabled &&
    //               activeVariantName === ActiveVariantName.VARIANT_MOBILE
    //             ) {
    //               setActiveVariant(ActiveVariantName.VARIANT_DESKTOP);
    //             }
    //             updateTourial({
    //               isVariantMobileEnabled: !isVariantMobileEnabled
    //             });
    //           }
    //         }}
    //       />
    //     </TLabel>
    //   </div>
    // </div>
    React.createElement("div", { style: {
            display: "flex",
            width: "100%",
            height: 32,
            margin: "23px 0 10px",
        } },
        React.createElement("button", { style: Object.assign(Object.assign({ borderRadius: "5px 0 0 5px" }, baseButtonStyle), (!isMobileEnabled() && activeButtonStyle)), disabled: !isMobileEnabled(), onClick: onClickHandler }, "Display Desktop Version Only"),
        React.createElement("button", { style: Object.assign(Object.assign({ borderRadius: "0 5px 5px 0" }, baseButtonStyle), (isMobileEnabled() && activeButtonStyle)), disabled: isMobileEnabled(), onClick: onClickHandler }, "Mobile Tourial Enabled")));
});
