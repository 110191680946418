import React from "react";
import { useUserAccess } from "../../../../redux/selectors/redux-selectors";
import { AccessLevel, getReadableAuthNameFor } from "../../../../../../shared/types/auth-types";
import TTooltip from "../../../styled/t-tooltip";
import { Icon } from "../../dom-tour/components/shared";
import InfoIcon from "../../../../../assets/images/v3/blue-tooltip-info.svg";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
export function EditModeDisabled({ style }) {
    const userAccess = useUserAccess();
    const message = userAccess === AccessLevel.COLLABORATOR
        ? `${getReadableAuthNameFor(userAccess)}s may not edit in live mode`
        : `${getReadableAuthNameFor(userAccess)}s may only preview projects`;
    return (React.createElement(Container, { style: style },
        React.createElement(TTooltip, { content: message },
            React.createElement(Icon, { src: InfoIcon, width: 16 })),
        React.createElement("div", null, "Edit mode disabled")));
}
const Container = styled.div `
  display: flex;
  gap: 8px;
  font: ${newTheme.fonts.small};
  font-style: italic;
  color: ${newTheme.colors.grey500};
  align-items: center;
  cursor: default;
`;
