import React, { useEffect, useRef, useState } from "react";
import copy from "copy-to-clipboard";
import { useOutsideClick } from "../../../hooks/use-outside-click";
import { ViewportMode } from "../../../types/definitions";
import { useGoToFirstPage, useViewportMode, useFeatureFlags } from "../../../redux/selectors/redux-selectors";
import WatermarkLogo from "../../../../assets/images/tourial-logo-white-vector.svg";
import { usePostEvent } from "../../../helpers/events";
import { useGoToFirstStep } from "../../../redux/selectors/builder-selectors";
import { useTourialVersion } from "../../../redux/selectors/live-selectors";
import { useDemoCenter } from "../../../redux/selectors/demo-center-selectors";
export default function TourialWatermark({ viewportDimensions }) {
    const viewportMode = useViewportMode();
    const [isOpen, setIsOpen] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const goToFirstPage = useGoToFirstPage();
    const goToFirstStep = useGoToFirstStep();
    const postEvent = usePostEvent();
    const tourialVersion = useTourialVersion();
    const isInDemoCenter = !!useDemoCenter();
    const { hideWatermark } = useFeatureFlags();
    const outerRef = useRef();
    const shareRef = useRef();
    useOutsideClick([outerRef, shareRef], () => {
        void postEvent({
            eventType: "WATERMARK_CLOSE",
        });
        setIsOpen(false);
    });
    const [scaleFactor, setScaleFactor] = useState(1);
    const buttonStyle = {
        backgroundColor: "white",
        color: "rgba(0,0,0,0.7)",
        cursor: "pointer",
        height: 25 * scaleFactor,
        margin: `0px ${10 * scaleFactor}px`,
        borderRadius: 10 * scaleFactor,
        fontWeight: "bold",
        fontSize: 12 * scaleFactor,
    };
    const standardWidth = 1366;
    useEffect(() => {
        if (viewportDimensions.width) {
            setScaleFactor(Math.max(Math.min(viewportDimensions.width / standardWidth, 1), 0.5));
        }
    }, [viewportDimensions]);
    if (viewportMode !== ViewportMode.LIVE || hideWatermark || isInDemoCenter)
        return null;
    return (React.createElement("div", { style: {
            position: "fixed",
            top: `calc(100vh - ${isOpen ? 125 * scaleFactor + "px" : 50 * scaleFactor + "px"})`,
            left: 20 * scaleFactor,
        } },
        isOpen && (React.createElement("div", { style: {
                cursor: "auto",
                backgroundColor: "rgba(0,0,0,0.5)",
                height: 75 * scaleFactor,
                width: 130 * scaleFactor,
                // minWidth: 130,
                position: "relative",
                top: -10 * scaleFactor,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                borderRadius: 10 * scaleFactor,
            } },
            React.createElement("button", { key: "restart", style: buttonStyle, onClick: () => {
                    setIsOpen(false);
                    void postEvent({
                        eventType: "WATERMARK_RESTART",
                    });
                    tourialVersion === 3 ? goToFirstStep() : goToFirstPage();
                } },
                "Restart Tour",
                React.createElement("i", { className: "fas fa-undo", style: {
                        color: "rgba(0,0,0,0.7)",
                        fontSize: 12 * scaleFactor,
                        marginLeft: 10 * scaleFactor,
                    } })),
            React.createElement("button", { ref: shareRef, key: "copy", style: buttonStyle, onClick: () => {
                    copy(document.URL);
                    setIsCopied(true);
                    setIsOpen(true);
                    void postEvent({
                        eventType: "WATERMARK_SHARE",
                    });
                } },
                isCopied ? "Link copied!" : "Share Tour",
                !isCopied && (React.createElement("i", { className: "fas fa-share-alt", style: {
                        color: "rgba(0,0,0,0.7)",
                        fontSize: 12 * scaleFactor,
                        marginLeft: 10 * scaleFactor,
                    } }))))),
        React.createElement("button", { ref: outerRef, style: {
                backgroundColor: "rgba(0,0,0,0.5)",
                border: "1px solid rgba(255,255,255,0.4)",
                height: 35 * scaleFactor,
                // maxHeight: 35,
                width: 130 * scaleFactor,
                display: "flex",
                flexDirection: "row",
                borderRadius: 20 * scaleFactor,
                cursor: "pointer",
                justifyContent: "center",
                alignItems: "center",
            }, onClick: () => {
                void postEvent({
                    eventType: isOpen ? "WATERMARK_CLOSE" : "WATERMARK_OPEN",
                });
                setIsOpen(!isOpen);
            } },
            React.createElement("img", { style: { height: 20 * scaleFactor, padding: 2 * scaleFactor }, src: WatermarkLogo }),
            React.createElement("i", { className: "fas fa-share-alt", style: {
                    color: "white",
                    fontSize: 20 * scaleFactor,
                    marginRight: 6 * scaleFactor,
                    marginLeft: 2 * scaleFactor,
                } }))));
}
