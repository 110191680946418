import { TDropdownSelect } from "../../../../styled/t-dropdown-select";
import React from "react";
import styled from "styled-components";
import { TextStyleSelector } from "../../../../pages/builder/editor/atomic/text-style-selector";
import { ColorPicker } from "../../../../pages/builder/editor/atomic/color-picker";
import { AlignmentSelector } from "../../../../pages/builder/editor/atomic/alignment-selector";
import { fontOptions } from "../../../../../helpers/dropdown-options";
import { NumberInput } from "../../../../pages/builder/editor/atomic/number-input";
function isTooltipText(tool) {
    return "style" in tool;
}
function hasAlign(tool) {
    return "align" in tool;
}
export function FontSelector({ text, sizeOptions, hideTextStyle, hideTextAlign, updateFn, isNumberInput, }) {
    return (React.createElement("div", null,
        React.createElement(DropdownContainer, null,
            React.createElement(TDropdownSelect, { isSearchable: true, style: { flex: 1, marginRight: "16px" }, selectedValue: text.font, onChange: (e) => {
                    updateFn(Object.assign(Object.assign({}, text), { font: e.value }));
                }, options: fontOptions }),
            !isNumberInput ? (React.createElement(TDropdownSelect, { isSearchable: false, style: { width: "94px" }, selectedValue: text.size, onChange: (e) => {
                    updateFn(Object.assign(Object.assign({}, text), { size: e.value }));
                }, options: sizeOptions })) : (React.createElement(NumberInput, { style: { width: "94px" }, value: +text.size, min: 10, max: 100, step: 1, onChange: (e) => {
                    updateFn(Object.assign(Object.assign({}, text), { size: e.target.valueAsNumber }));
                }, unit: "px" }))),
        React.createElement(TextModificationContainer, null,
            !hideTextStyle && isTooltipText(text) && (React.createElement(TextStyleSelector, { style: text.style, onChange: (e) => {
                    updateFn(Object.assign(Object.assign({}, text), { style: e }));
                } })),
            React.createElement(ColorPicker, { color: text.color, onChange: (color) => {
                    updateFn(Object.assign(Object.assign({}, text), { color }));
                } }),
            !hideTextAlign && hasAlign(text) && (React.createElement("div", null,
                React.createElement(AlignmentSelector, { align: text.align, onChange: (e) => {
                        updateFn(Object.assign(Object.assign({}, text), { align: e }));
                    } }))))));
}
const DropdownContainer = styled.div `
  display: flex;
`;
const TextModificationContainer = styled.div `
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  gap: 16px;
`;
