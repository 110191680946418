import React, { useEffect, useState } from "react";
import { newTheme } from "../../../styled/theme";
import styled from "styled-components";
import { STEP_PANEL_INPUT_HEIGHT } from "../../dom-tour/config";
export function StoryboardPageNameInput({ id, disabled, name, setName }) {
    const [localName, setLocalName] = useState(name);
    // needed for compatibility with undo/redo
    useEffect(() => {
        if (name !== localName)
            setLocalName(name);
    }, [name]);
    let shouldCommitOnBlur = true;
    const commit = () => {
        setLocalName(localName.trim());
        setName(localName.trim());
    };
    const decommit = () => {
        setLocalName(name);
    };
    useEffect(() => {
        // save the name if the card is de-selected during update
        if (disabled && name !== localName) {
            commit();
        }
    }, [disabled]);
    if (disabled)
        return React.createElement(SmallText, null, name);
    return (React.createElement("input", { id: id, value: localName, disabled: disabled, onChange: (e) => {
            setLocalName(e.target.value);
        }, onBlur: () => {
            if (shouldCommitOnBlur) {
                commit();
            }
        }, onKeyDown: e => {
            if (e.code === "Enter") {
                e.preventDefault();
                commit();
                shouldCommitOnBlur = false;
                //@ts-expect-error
                e.target.blur();
            }
            else if (e.code === "Escape") {
                e.preventDefault();
                e.stopPropagation();
                decommit();
                shouldCommitOnBlur = false;
                //@ts-expect-error
                e.target.blur();
            }
        }, style: { font: newTheme.fonts.small, width: "100%", lineHeight: `${STEP_PANEL_INPUT_HEIGHT}px` } }));
}
const SmallText = styled.p `
  font: ${newTheme.fonts.small};
  max-width: 100px;
  white-space: nowrap;
`;
