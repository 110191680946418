import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ImportScreenIcon from "../../../../assets/images/v3/upload-black.svg";
import ChromeIcon from "../../../../assets/images/v3/chrome-icon.svg";
import { newTheme } from "../../styled/theme";
import TButton, { TButtonVariants } from "../../styled/t-button";
import AddScreenModal, { AddScreenModalVariants } from "../../pages/builder/storyboard-mini/add-screen-modal";
import { useActivePageId, useStoryboardHeight } from "../../../redux/selectors/redux-selectors";
import ChromeExtensionModal from "../../pages/home-page/chrome-extension-modal";
export default function EmptyEditViewport() {
    const [swapScreenModalOpen, setSwapScreenModalOpen] = useState(false);
    const activePageId = useActivePageId();
    const storyboardHeight = useStoryboardHeight();
    const [availableHeight, setAvailableHeight] = useState(0);
    const [isChromeExtensionModalOpen, setIsChromeExtensionModalOpen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            const browserHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            const availableHeight = browserHeight - storyboardHeight - 40 - 96;
            setAvailableHeight(availableHeight);
        };
        handleResize(); // Set initial size
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [storyboardHeight]);
    return (React.createElement(EmptyViewport, { id: "_test_empty-edit-viewport", availableHeight: availableHeight },
        React.createElement(CardContainer, null,
            React.createElement(Card, null,
                React.createElement(Img, { src: ImportScreenIcon }),
                React.createElement(TButton, { size: "small", variant: TButtonVariants.OUTLINE, onClick: () => setSwapScreenModalOpen(true) }, "Import screenshots")),
            React.createElement(Card, null,
                React.createElement(Img, { src: ChromeIcon }),
                React.createElement(TButton, { size: "small", variant: TButtonVariants.OUTLINE, onClick: () => setIsChromeExtensionModalOpen(true) }, "Capture from Chrome"))),
        swapScreenModalOpen && (React.createElement(AddScreenModal, { onClose: () => setSwapScreenModalOpen(false), variant: AddScreenModalVariants.START_SCREENS, pageId: activePageId })),
        isChromeExtensionModalOpen && (React.createElement(ChromeExtensionModal, { onClose: () => setIsChromeExtensionModalOpen(false), openNewTourModal: () => setSwapScreenModalOpen(true), hideCheckbox: true }))));
}
const EmptyViewport = styled.div `
  width: 100%;
  height: ${props => (props.availableHeight ? props.availableHeight + "px" : "fit-content")};
  justify-content: center;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
`;
const CardContainer = styled.div `
  display: flex;
  gap: 24px;
  width: 524px;
  height: 212px;
`;
const Card = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
  gap: 24px;
  border-radius: 4px;
  background-color: ${newTheme.colors.white};
  border: 1px solid ${newTheme.colors.grey300};
  box-shadow:
    0px 0px 20px 0px rgba(48, 49, 51, 0.05),
    2px 2px 8px 0px rgba(33, 34, 41, 0.02),
    0px 0px 1px 0px rgba(33, 34, 41, 0.4) inset;
  width: 280px;
`;
const Img = styled.img `
  width: 32px;
  height: 32px;
`;
