import { TooltipArrowPosition, TooltipTriggerComponent, } from "../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import { BorderShape, ShirtSize, ToolType } from "../../../../../../shared/types/tool-data-types/common";
import { InteractionName, InteractionType, makeInteraction, } from "../../../../../../shared/types/tool-data-types/interactions";
export function getTooltipV2Template(toolData, theme) {
    const t = Object.assign({ id: "", name: "Tooltip", type: ToolType.TooltipV2, sizeAndPosition: { x: 0, y: 0, width: 0, height: 0 }, bubble: {
            isEnabled: true,
            stepCounter: theme.bubble.stepCounter,
            size: { width: theme.bubble.width, height: 218 },
            background: theme.bubble.background,
            logo: theme.bubble.logo,
            headline: Object.assign(Object.assign({}, theme.bubble.headline), { text: "Headline" }),
            body: Object.assign(Object.assign({}, theme.bubble.body), { isEnabled: true, text: `Guide viewers to a specific feature of your software or to an action you'd like them to take.` }),
            nextButton: Object.assign(Object.assign({}, theme.bubble.nextButton), { interaction: makeInteraction(InteractionType.CLICK, InteractionName.NEXT_STEP) }),
            previousButton: Object.assign(Object.assign({}, theme.bubble.previousButton), { interaction: makeInteraction(InteractionType.CLICK, InteractionName.PREVIOUS_STEP) }),
            arrow: {
                isEnabled: true,
                position: TooltipArrowPosition.BOTTOM_CENTER,
                size: theme.bubble.arrow.size,
            },
            buttonAlign: theme.bubble.buttonAlign,
        }, trigger: {
            triggerComponent: TooltipTriggerComponent.NONE,
            position: { x: 25, y: 55 },
            clickzone: Object.assign(Object.assign({}, theme.clickzone), { size: { width: 8, height: 6 } }),
            hotspot: theme.hotspot,
            interaction: makeInteraction(InteractionType.HOVER, InteractionName.NULL),
        }, spotlight: {
            shape: BorderShape.RECTANGLE,
            isEnabled: false,
            size: { width: 20, height: 20 },
            position: { x: 20, y: 20 },
            opacity: 0.4,
        } }, toolData);
    return t;
}
export const getMobileTooltipHeadlineSize = (size) => {
    if (size === ShirtSize.LARGE || size === ShirtSize.MEDIUM) {
        return ShirtSize.SMALL;
    }
    return size;
};
