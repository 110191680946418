import "../styles/main-app.css";
import React, { useEffect } from "react";
import styled from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "../legacy/store";
import { useIsLoading, useFetchAndSetTourialAuthData } from "../legacy/selectors";
import IsLoading from "../legacy/components/is-loading/is-loading";
import ErrorMessage from "../legacy/components/templates/error-message/error-message";
import { AppRouter } from "./router/app-router";
import { MainAppTracker } from "./main-app-tracker";
import ToastPortal from "./toast/toast-portal";
import { initDatadogRum } from "../addons/datadog-rum/init";
export const AppContainer = styled.div `
  height: 100vh;
`;
function MainApp() {
    // useSyncUserAuthRedux();
    const isLoading = useIsLoading();
    const fetchAndSetTourialAuthData = useFetchAndSetTourialAuthData();
    useEffect(() => {
        void fetchAndSetTourialAuthData();
    }, []);
    if (isLoading) {
        return (React.createElement("div", null,
            React.createElement(IsLoading, { style: "poweredByTourial" }),
            React.createElement(ErrorMessage, null)));
    }
    else {
        return (React.createElement(AppContainer, null,
            React.createElement(ErrorMessage, null),
            React.createElement(AppRouter, null),
            React.createElement(MainAppTracker, null),
            React.createElement(ToastPortal, null)));
    }
}
initDatadogRum("app");
const container = document.getElementById("app");
const root = createRoot(container);
root.render(React.createElement(BrowserRouter, null,
    React.createElement(Provider, { store: store },
        React.createElement(MainApp, null))));
module.hot.accept();
const originConsoleError = console.error;
console.error = error => {
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
        window.location.reload();
    }
    else {
        originConsoleError(error);
    }
};
