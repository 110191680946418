import React, { useEffect, useState } from "react";
import { DynamicWidthInput } from "./dynamic-width-input";
import { newTheme } from "../../../styled/theme";
export function NameInput({ id, disabled, name, setName }) {
    const [localName, setLocalName] = useState(name);
    // needed for compatibility with undo/redo
    useEffect(() => {
        if (name !== localName)
            setLocalName(name);
    }, [name]);
    let shouldCommitOnBlur = true;
    const commit = () => {
        const n = localName.trim();
        if (n) {
            setLocalName(n);
            setName(n);
        }
        else {
            decommit();
        }
    };
    const decommit = () => {
        setLocalName(name);
    };
    return (React.createElement(DynamicWidthInput, { id: id, value: localName, disabled: disabled, onChange: (e) => {
            setLocalName(e.target.value);
        }, onBlur: () => {
            if (shouldCommitOnBlur) {
                commit();
            }
        }, onKeyDown: e => {
            if (e.code === "Enter") {
                e.preventDefault();
                commit();
                shouldCommitOnBlur = false;
                //@ts-expect-error
                e.target.blur();
            }
            else if (e.code === "Escape") {
                e.preventDefault();
                e.stopPropagation();
                decommit();
                shouldCommitOnBlur = false;
                //@ts-expect-error
                e.target.blur();
            }
        }, style: { height: 24, color: newTheme.colors.black, font: newTheme.fonts.normal, textAlign: "left", minWidth: 10 } }));
}
