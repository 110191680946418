import React from "react";
import styled from "styled-components";
import Slider from "rc-slider";
import { newTheme } from "./theme";
import { roundToHundredths } from "../../../../shared/functions/helpers";
export const TRangeHorizontalSlider = (props) => {
    const { onChange, min, max, disabled, value, style, step } = props;
    const handleRender = (node, { value, index }) => {
        return (React.createElement(React.Fragment, null,
            node,
            React.createElement("div", { style: {
                    position: "absolute",
                    pointerEvents: "none",
                    left: (value / max) * (typeof style.width === "number" && !isNaN(style.width) ? style.width : 255) - 9 + "px",
                    top: index ? "8px" : "-19px",
                    font: newTheme.fonts.extraSmall,
                } },
                roundToHundredths(value),
                "s")));
    };
    return (React.createElement(SliderContainer, { style: style },
        React.createElement(Slider, { range: true, min: min, max: max, onChange: onChange, defaultValue: [0, 0], step: step || 0.25, value: value, allowCross: false, railStyle: {
                position: "absolute",
                height: "2px",
                backgroundColor: disabled ? newTheme.colors.grey300 : newTheme.colors.blue200,
                backgroundPosition: "center",
                width: "100%",
            }, trackStyle: {
                position: "absolute",
                height: "2px",
            }, handleStyle: {
                cursor: disabled ? "not-allowed" : "grab",
                height: "18px",
                width: "18px",
                marginTop: "-8px",
                borderRadius: "9px",
                backgroundColor: disabled ? newTheme.colors.grey500 : newTheme.colors.blue,
                position: "absolute",
                boxShadow: newTheme.shadows.outside80,
            }, handleRender: handleRender })));
};
const SliderContainer = styled.div `
  position: relative;
  margin: 16px;
  width: 156px;
`;
