import { useEffect } from "react";
import { useActiveView, usePages } from "../redux/selectors/redux-selectors";
export function useIsLastStep(setIsLastStep) {
    const pages = usePages();
    const activeView = useActiveView();
    useEffect(() => {
        if (!pages)
            return;
        if (pages.findIndex(p => p.id === activeView.pageId) === pages.length - 1) {
            const p = pages.find(p => p.id === activeView.pageId);
            if (p.zooms.findIndex(z => z.id === activeView.zoomId) === p.zooms.length - 1) {
                setIsLastStep(true);
            }
            else {
                setIsLastStep(false);
            }
        }
        else {
            setIsLastStep(false);
        }
    }, [activeView.pageId, activeView.zoomId]);
}
