import React from "react";
import styled from "styled-components";
import { LeftArrow, RightArrow } from "../../../microsites/components/microsite-progress-bar";
import { ShirtSize, TextStyle } from "../../../../../../../shared/types/tool-data-types/common";
export function ProductPageEditPreview({ productPage, width = 320, scale = 1, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return (React.createElement(PreviewMain, { width: width, productPage: productPage, scale: scale },
        React.createElement(PreviewHeader, { productPage: productPage },
            React.createElement(PreviewLogo, { src: productPage.logo }),
            React.createElement(ButtonContainer, null,
                (((_a = productPage.ctaPrimary) === null || _a === void 0 ? void 0 : _a.isEnabled) || ((_b = productPage.ctaPrimary) === null || _b === void 0 ? void 0 : _b.isEnabled) === undefined) && (React.createElement(PreviewCta, Object.assign({}, productPage.ctaPrimary), productPage.ctaPrimary.text)),
                ((_c = productPage.ctaSecondary) === null || _c === void 0 ? void 0 : _c.isEnabled) && (React.createElement(PreviewCta, Object.assign({}, productPage.ctaSecondary), productPage.ctaSecondary.text)),
                ((_d = productPage.ctaTertiary) === null || _d === void 0 ? void 0 : _d.isEnabled) && (React.createElement(PreviewCta, Object.assign({}, productPage.ctaTertiary), productPage.ctaTertiary.text)))),
        React.createElement(PreviewBody, null,
            React.createElement(YourTourial, null, "Your Tourial"),
            !!(((_e = productPage === null || productPage === void 0 ? void 0 : productPage.progressBarStyle) === null || _e === void 0 ? void 0 : _e.isBarEnabled) || ((_f = productPage === null || productPage === void 0 ? void 0 : productPage.progressBarStyle) === null || _f === void 0 ? void 0 : _f.isArrowEnabled)) && (React.createElement(ProgressBarContainer, null,
                ((_g = productPage === null || productPage === void 0 ? void 0 : productPage.progressBarStyle) === null || _g === void 0 ? void 0 : _g.isArrowEnabled) && (React.createElement(ArrowContainer, { style: { color: productPage.progressBarStyle.arrowColor } },
                    React.createElement(LeftArrow, null))),
                ((_h = productPage === null || productPage === void 0 ? void 0 : productPage.progressBarStyle) === null || _h === void 0 ? void 0 : _h.isBarEnabled) && (React.createElement(OuterProgressBar, { style: { backgroundColor: productPage.progressBarStyle.backgroundColor } },
                    React.createElement(InnerProgressBar, { style: { width: "40%", backgroundColor: productPage.progressBarStyle.barColor } }))),
                ((_j = productPage === null || productPage === void 0 ? void 0 : productPage.progressBarStyle) === null || _j === void 0 ? void 0 : _j.isArrowEnabled) && (React.createElement(ArrowContainer, { style: { color: productPage.progressBarStyle.arrowColor } },
                    React.createElement(RightArrow, null))))))));
}
const ProgressBarContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  top: 140px;
  width: 125px;
  padding: 0px 8px 8px 8px;
  margin-top: -22px;
  height: 22px;
`;
const ArrowContainer = styled.div `
  width: 18px;
  height: 14px;
`;
const OuterProgressBar = styled.div `
  display: flex;
  flex: 1;
  height: 8px;
  border-radius: 20px;
  margin: 4px 0 0;
  padding: 1px;
`;
const InnerProgressBar = styled.div `
  height: 6px;
  border-radius: 20px;
`;
const PreviewMain = styled.div `
  background-color: ${props => props.productPage.bodyColor};
  ${props => props.productPage.backgroundImage
    ? `{
    background-image: url(${props.productPage.backgroundImage});
    background-size: 100% 100%;
  }`
    : ""}
  width: ${props => props.width || 320}px;
  height: 200px;
  display: flex;
  flex-direction: column;
  transform: scale(${props => props.scale});
`;
const PreviewHeader = styled.div `
  background-color: ${props => props.productPage.headerColor};
  height: 28px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px 24px;
  border-bottom: ${p => p.productPage.headerBorderWidth}px solid ${p => p.productPage.headerBorderColor};
`;
const ButtonContainer = styled.div `
  display: flex;
  justify-content: right;
`;
const PreviewLogo = styled.img `
  max-height: 25px;
`;
const fontSizes = {
    [ShirtSize.SMALL]: "6px",
    [ShirtSize.MEDIUM]: "8px",
    [ShirtSize.LARGE]: "10px",
};
const PreviewCta = styled.button `
  background-color: ${p => p.backgroundColor};
  color: ${p => p.color};
  font-family: ${p => p.fontFamily};
  padding: 0px 10px;
  border-radius: ${p => p.borderRadius / 2}px;
  border: ${p => p.borderWidth}px solid ${p => p.borderColor};
  cursor: pointer;
  font-size: ${p => fontSizes[p.size || ShirtSize.MEDIUM]};
  font-weight: ${p => (p.textStyle === TextStyle.BOLD ? "bold" : "normal")};
  text-decoration: ${p => (p.textStyle === TextStyle.UNDERLINE ? "underline" : "none")};
  font-style: ${p => (p.textStyle === TextStyle.ITALIC ? "italic" : "normal")};
  margin-left: 4px;
`;
const PreviewBody = styled.div `
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const YourTourial = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #6a6a6a;
  width: 250px;
  height: 140px;
  text-align: center;
`;
