import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LargeToggleOn from "../../../assets/images/v3/large-toggle-on.svg";
import LargeToggleOff from "../../../assets/images/v3/large-toggle-off.svg";
import SmallToggleOn from "../../../assets/images/v3/small-toggle-on.svg";
import SmallToggleOff from "../../../assets/images/v3/small-toggle-off.svg";
export const TToggleSwitch = (props) => {
    const { enabled, onChange, size = "large", id, style, override, disabled } = props;
    const [switchEnabled, setSwitchEnabled] = useState(enabled || false);
    const disabledStyle = disabled ? { opacity: 0.5, cursor: "not-allowed", filter: "grayscale(100%)" } : null;
    useEffect(() => {
        setSwitchEnabled(enabled);
    }, [enabled]);
    return (React.createElement(ToggleSwitchContainer, { id: id, size: size, onClick: e => {
            e.stopPropagation();
            if (disabled)
                return;
            if (override) {
                onChange(!enabled);
            }
            setSwitchEnabled(!switchEnabled);
            onChange(!switchEnabled);
        }, style: Object.assign(Object.assign({}, style), disabledStyle) }, switchEnabled ? (React.createElement("img", { src: SwitchOnIcon[size], alt: "Toggle" })) : (React.createElement("img", { src: SwitchOffIcon[size], alt: "Toggle" }))));
};
const SwitchOnIcon = {
    large: LargeToggleOn,
    small: SmallToggleOn,
};
const SwitchOffIcon = {
    large: LargeToggleOff,
    small: SmallToggleOff,
};
const SwitchHeight = {
    large: "20px",
    small: "16px",
};
const SwitchWidth = {
    large: "36px",
    small: "28px",
};
const ToggleSwitchContainer = styled.div `
  cursor: pointer;
  height: ${props => SwitchHeight[props.size]};
  width: ${props => SwitchWidth[props.size]};
`;
