import { v4 as uuidv4 } from "uuid";
// import objectHash from "object-hash";
import oo from "json8";
import { ActiveVariantName, BorderShape, GraphicType, ShirtSize, ToolType } from "../types/tool-data-types/common";
import { cloneDeep, isEqual, uniq } from "lodash";
import { CtaInteraction } from "../types/product-page";
import { InteractionName, InteractionType, makeInteraction } from "../types/tool-data-types/interactions";
import { ModalButtonType, ModalType } from "../types/tool-data-types/modal-data";
import { getRadiusFromShape } from "./helpers";
import { TooltipArrowPosition, TooltipTriggerComponent } from "../types/tool-data-types/tooltip-v2-data";
export const getBackgroundTemplate = () => {
    return {
        id: "background",
        name: "Background",
        type: ToolType.Background,
        backgrounds: {},
        // sizeAndPosition isn't used but needed as we are extending the BaseTool
        sizeAndPosition: {
            x: 0,
            y: 0,
            width: 100,
            height: 100,
        },
    };
};
export const getScreenTemplate = (toolData, sizeAndPosition, perPageVideoControls) => {
    return Object.assign({ id: "screen", type: ToolType.Screen, sizeAndPosition: Object.assign({ x: 0, y: 0, width: 100, height: 100 }, sizeAndPosition), name: "Screen", media: {}, enableBackground: false, enableBorder: false, enableShadow: false, borderWidth: 1, borderColor: "black", borderStyle: "solid", borderRadius: 0, backgroundColor: "white", shadowStyle: "default", isLocked: true, shouldFadeIn: false, perPageVideoControls: Object.assign({}, perPageVideoControls) }, toolData);
};
export const getTourialVariantTemplate = (version) => {
    const pageId = uuidv4();
    return {
        pages: [
            {
                id: pageId,
                name: "Page 1",
                zooms: [getDefaultZoom(version)],
                autoStartZoomId: "",
            },
        ],
        tools: {
            background: getBackgroundTemplate(),
            screen: getScreenTemplate(),
        },
        viewTools: [],
        triggerListeners: [], // legacy V2
    };
};
export const isEmptyTourial = (variant) => {
    const emptyTourial = getTourialVariantTemplate(3);
    delete emptyTourial.pages[0].id;
    const testingTourial = cloneDeep(variant);
    delete testingTourial.pages[0].id;
    delete testingTourial.timers;
    return isEqual(emptyTourial, testingTourial);
};
export const getTourialTemplate = (tourData, theme) => {
    return Object.assign({ name: "New Tourial", variantDesktop: getTourialVariantTemplate(tourData === null || tourData === void 0 ? void 0 : tourData.version), defaultFontFamily: "Open Sans", savedColors: [], media: [], slug: uuidv4(), isPublished: (tourData === null || tourData === void 0 ? void 0 : tourData.isPublished) || false, accountId: "", subdomain: "", whiteLabelDomain: "", version: undefined, productTourPage: getProductPageTemplate(theme), customerUpdatedAt: new Date(), isEmbedHeaderEnabled: true }, tourData);
};
export const getProductPageTemplate = (theme) => ({
    logo: theme.page.logo,
    bodyColor: theme.page.body.backgroundColor,
    backgroundImage: theme.page.body.backgroundImage,
    headerColor: theme.page.navbar.backgroundColor,
    headerBorderColor: theme.page.navbar.borderColor,
    headerBorderWidth: 1,
    ctaPrimary: getCTATemplate(theme.page.primaryCta),
    ctaSecondary: getCTATemplate(theme.page.secondaryCta),
    ctaTertiary: Object.assign(Object.assign({}, getCTATemplate(theme.page.secondaryCta)), { isEnabled: false }),
    progressBarStyle: getProgressBarStyleTemplate(theme.page.progressBar),
});
export const getCTATemplate = (buttonTheme) => {
    return {
        isEnabled: buttonTheme.isEnabled,
        backgroundColor: buttonTheme.backgroundColor,
        borderColor: buttonTheme.borderColor,
        color: buttonTheme.color,
        borderRadius: getRadiusFromShape(buttonTheme.shape),
        borderWidth: 1,
        text: "GET IN TOUCH",
        size: buttonTheme.size,
        fontFamily: buttonTheme.font,
        textStyle: buttonTheme.style,
        interaction: {
            type: CtaInteraction.EXTERNAL_LINK_SAME_WINDOW,
            href: "",
        },
    };
};
export const getProgressBarStyleTemplate = (progressBarTheme) => {
    return {
        arrowColor: progressBarTheme.arrowColor,
        backgroundColor: progressBarTheme.backgroundColor,
        barColor: progressBarTheme.barColor,
        isBarEnabled: progressBarTheme.isEnabled,
        isArrowEnabled: progressBarTheme.isEnabled,
    };
};
/** pass version of 3 if invoking for V3 builder */
export function getDefaultZoom(version) {
    return {
        id: "MAIN",
        name: version === 3 ? "Step 1" : "Main",
        x: 0,
        y: version === 3 ? 100 : 0,
        z: 1,
        transition: {
            // property: "transform",
            durationSecs: 1.5,
            timingFunction: "ease-in-out",
            delaySecs: 0.5,
            isFinished: false,
        },
        automationDuration: 5,
    };
}
export function getDefaultVideoControls(version) {
    return version === 3
        ? {
            isMuted: false,
            autoJump: true,
            controls: false,
            autoPlay: true,
        }
        : {
            isMuted: true,
            autoPlay: false,
            controls: true,
        };
}
export function sanitizeTourial(tourial) {
    var _a;
    if (!tourial)
        return tourial;
    const r = oo.clone(tourial);
    // delete all keys with undefined values... this messes up our hashing/patching libraries
    Object.keys(r).forEach(key => (r[key] === undefined ? delete r[key] : {}));
    r._id = tourial._id.toString();
    // for some reason media has an ObjectId on it sometimes which would break this fn
    if ((_a = r.media) === null || _a === void 0 ? void 0 : _a.length) {
        r.media = r.media.map((m) => {
            delete m._id;
            return m;
        });
    }
    delete r.__v;
    delete r.updatedAt;
    delete r.createdAt;
    if (tourial.interactedAt)
        r.interactedAt = tourial.interactedAt;
    if (tourial.archivedAt)
        r.archivedAt = tourial.archivedAt;
    if (tourial.customerUpdatedAt)
        r.customerUpdatedAt = tourial.customerUpdatedAt;
    return r;
}
export function sharedGetDefaultModalTemplate(toolData, theme) {
    const t = Object.assign({ id: "", name: "Modal", type: ToolType.Modal, modalType: ModalType.TEXT, sizeAndPosition: { x: 0, y: 0, width: 0, height: 0 }, size: { width: theme.modal.width, height: 0 }, headline: Object.assign(Object.assign({}, theme.modal.headline), { isEnabled: true, text: "Headline" }), body: Object.assign(Object.assign({}, theme.modal.body), { isEnabled: true, text: "Let's take a look at a finished product and break it down to see just how easy it is!" }), buttons: {
            type: ModalButtonType.NORMAL,
            number: 2,
            shape: theme.modal.buttonShape,
            button1: Object.assign(Object.assign({}, theme.modal.button1), { isEnabled: true, text: "Back", interaction: makeInteraction(InteractionType.CLICK, InteractionName.PREVIOUS_STEP) }),
            button2: Object.assign(Object.assign({}, theme.modal.button2), { isEnabled: true, text: "Next", interaction: makeInteraction(InteractionType.CLICK, InteractionName.NEXT_STEP) }),
            button3: Object.assign(Object.assign({}, theme.modal.button2), { isEnabled: false, text: "Button", interaction: makeInteraction(InteractionType.CLICK, InteractionName.NULL) }),
            button4: Object.assign(Object.assign({}, theme.modal.button2), { isEnabled: false, text: "Button", interaction: makeInteraction(InteractionType.CLICK, InteractionName.NULL) }),
        }, background: theme.modal.background, overlay: theme.modal.overlay, logo: theme.modal.logo, featureGraphic: {
            isEnabled: false,
            src: "",
            type: GraphicType.IMAGE,
        } }, toolData);
    return t;
}
export function getAllFormIdsFromTourial(tourial) {
    const formIds = [];
    if (tourial.version !== 3)
        return formIds;
    // 1) check for modal buttons & step enter interactions connected to forms
    function addVariantFormIds(variant) {
        if (!tourial[variant])
            return;
        for (const toolId in tourial[variant].tools) {
            const tool = tourial[variant].tools[toolId];
            if (tool.type === ToolType.Modal) {
                [1, 2, 3, 4].forEach(n => {
                    var _a, _b, _c, _d;
                    const formId = (_d = (_c = (_b = (_a = tool === null || tool === void 0 ? void 0 : tool.buttons) === null || _a === void 0 ? void 0 : _a[`button${n}`]) === null || _b === void 0 ? void 0 : _b.interaction) === null || _c === void 0 ? void 0 : _c.target) === null || _d === void 0 ? void 0 : _d.targetFormId;
                    if (formId)
                        formIds.push(formId);
                });
            }
        }
        if (tourial[variant].stepForms) {
            for (const stepFormKey in tourial[variant].stepForms) {
                formIds.push(tourial[variant].stepForms[stepFormKey].formId);
            }
        }
    }
    addVariantFormIds(ActiveVariantName.VARIANT_DESKTOP);
    addVariantFormIds(ActiveVariantName.VARIANT_MOBILE);
    // 2) check for PTP buttons connected to forms
    if (tourial.productTourPage) {
        ["Primary", "Secondary", "Tertiary"].forEach(type => {
            var _a, _b, _c;
            const formId = (_c = (_b = (_a = tourial.productTourPage) === null || _a === void 0 ? void 0 : _a[`cta${type}`]) === null || _b === void 0 ? void 0 : _b.interaction) === null || _c === void 0 ? void 0 : _c.formId;
            if (formId)
                formIds.push(formId);
        });
    }
    return uniq(formIds);
}
// export function hashTourial(tourial: ITourial): string {
//   if (!tourial) return null;
//   const tourialToHash = sanitizeTourial(tourial);
//   return objectHash(tourialToHash);
// }
// export function generatePatch(a: ITourial, b: ITourial): JsonPatch {
//   return ooPatch.diff(sanitizeTourial(a), sanitizeTourial(b));
// }
export function getSharedHotspotTemplate(toolData, theme, coordinates) {
    const defaultTooltip = Object.assign({ id: "", name: "Tooltip", type: ToolType.TooltipV2, sizeAndPosition: { x: 0, y: 0, width: 0, height: 0 }, bubble: {
            isEnabled: true,
            stepCounter: Object.assign(Object.assign({}, theme.bubble.stepCounter), { isEnabled: false }),
            size: { width: 208, height: 218 },
            background: theme.bubble.background,
            logo: Object.assign(Object.assign({}, theme.bubble.logo), { isEnabled: false }),
            headline: Object.assign(Object.assign({}, theme.bubble.headline), { text: "Headline", isEnabled: false }),
            body: Object.assign(Object.assign({}, theme.bubble.body), { isEnabled: true, text: `Enter a description for the action here.`, size: ShirtSize.SMALL }),
            nextButton: Object.assign(Object.assign({}, theme.bubble.nextButton), { interaction: makeInteraction(InteractionType.CLICK, InteractionName.NEXT_STEP), isEnabled: false }),
            previousButton: Object.assign(Object.assign({}, theme.bubble.previousButton), { interaction: makeInteraction(InteractionType.CLICK, InteractionName.PREVIOUS_STEP), isEnabled: false }),
            arrow: {
                isEnabled: true,
                position: coordinates ? getArrowPosition(coordinates) : TooltipArrowPosition.BOTTOM_CENTER,
                size: ShirtSize.SMALL,
            },
            buttonAlign: theme.bubble.buttonAlign,
        }, trigger: {
            triggerComponent: TooltipTriggerComponent.HOTSPOT,
            position: coordinates || { x: 25, y: 55 },
            clickzone: Object.assign(Object.assign({}, theme.clickzone), { size: { width: 8, height: 6 } }),
            hotspot: Object.assign(Object.assign({}, theme.hotspot), { size: ShirtSize.MEDIUM }),
            interaction: makeInteraction(InteractionType.CLICK, InteractionName.NEXT_STEP),
        }, spotlight: {
            shape: BorderShape.RECTANGLE,
            isEnabled: false,
            size: { width: 20, height: 20 },
            position: { x: 20, y: 20 },
            opacity: 0.4,
        } }, toolData);
    const t = Object.assign(Object.assign({}, defaultTooltip), toolData);
    return t;
}
export function getTooltipV2Template(toolData, theme, coordinates) {
    const requiresHotspot = !theme.bubble.nextButton.isEnabled;
    if (coordinates && !requiresHotspot)
        coordinates.x += coordinates.x <= 50 ? -4 : 4;
    const t = Object.assign({ id: "", name: "Tooltip", type: ToolType.TooltipV2, sizeAndPosition: { x: 0, y: 0, width: 0, height: 0 }, bubble: {
            isEnabled: true,
            stepCounter: theme.bubble.stepCounter,
            size: { width: theme.bubble.width, height: 218 },
            background: theme.bubble.background,
            logo: theme.bubble.logo,
            headline: Object.assign(Object.assign({}, theme.bubble.headline), { text: "Headline" }),
            body: Object.assign(Object.assign({}, theme.bubble.body), { isEnabled: true, text: `Guide viewers to a specific feature of your software or to an action you'd like them to take.` }),
            nextButton: Object.assign(Object.assign({}, theme.bubble.nextButton), { interaction: makeInteraction(InteractionType.CLICK, InteractionName.NEXT_STEP) }),
            previousButton: Object.assign(Object.assign({}, theme.bubble.previousButton), { interaction: makeInteraction(InteractionType.CLICK, InteractionName.PREVIOUS_STEP) }),
            arrow: {
                isEnabled: true,
                position: coordinates ? getArrowPosition(coordinates) : TooltipArrowPosition.BOTTOM_CENTER,
                size: theme.bubble.arrow.size,
            },
            buttonAlign: theme.bubble.buttonAlign,
        }, trigger: {
            triggerComponent: requiresHotspot ? TooltipTriggerComponent.HOTSPOT : TooltipTriggerComponent.NONE,
            position: coordinates || { x: 25, y: 55 },
            clickzone: Object.assign(Object.assign({}, theme.clickzone), { size: { width: 8, height: 6 } }),
            hotspot: theme.hotspot,
            interaction: makeInteraction(InteractionType.HOVER, InteractionName.NULL),
        }, spotlight: {
            shape: BorderShape.RECTANGLE,
            isEnabled: false,
            size: { width: 20, height: 20 },
            position: { x: 20, y: 20 },
            opacity: 0.4,
        } }, toolData);
    return t;
}
// TODO: future improvement - add better placement
function getArrowPosition({ x }) {
    return x <= 50 ? TooltipArrowPosition.LEFT_CENTER : TooltipArrowPosition.RIGHT_CENTER;
}
