import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDCSetBuilderMode, useDemoCenter, useUpdateDemoCenter, } from "../../../../redux/selectors/demo-center-selectors";
import { DCBuilderMode, DC_ROOT } from "../../../../../../shared/types/demo-center";
import { newTheme } from "../../../styled/theme";
import { useDCBaseUrl, useDCId, useDCSlug, useDCSaveSlug, useDCLiveUrl, useDCUpdatePublishStatus, useDCStatus, useDCIsLive, useDCEmbedCode, useDCConfig, useDCSetSidebarConfig, } from "../hooks/dc-hooks";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { TextInput } from "../../builder/editor/atomic/text-input";
import { TMessageBox, TMessageBoxVariants } from "../../../styled/t-message-box";
import BluePencil from "../../../../../assets/images/v3/blue-pencil.svg";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
import { DCOverviewItemTable } from "./dc-overview-item-table";
import { useDCItems } from "../hooks/dc-item-hooks";
import { useDCLivePreviewHelper } from "../hooks/dc-builder-hooks";
import { DCFileUploader } from "./dc-file-uploader";
import { DCCMThumbnail, DCCMUploadLink } from "./components/dc-cm-track-item";
import { DCCMTextEditor } from "./components/dc-cm-text-editor";
import { cloneDeep } from "lodash";
import { TourialStatus } from "../../../../../../shared/types/tourial-model";
import { DCConfirmDeleteModal } from "./modals/dc-confirm-delete-modal";
import TTextarea from "../../../styled/t-textarea";
import { TToggleSwitch } from "../../../styled/t-toggle-switch";
import { usePostAppEvent } from "../../../../hooks/use-post-app-event";
import { TourialAppEventTypes } from "../../../../../../shared/types/app-events";
import { DCShareLinkViewTab } from "../../../share-links/share-links-viewer/dc-share-link-view-tab";
var DCShareTabs;
(function (DCShareTabs) {
    DCShareTabs["PUBLIC_LINK"] = "Public link";
    DCShareTabs["SHARE_LINKS"] = "Share links";
    DCShareTabs["EMBED_CODE"] = "Embed code";
})(DCShareTabs || (DCShareTabs = {}));
export const DCOverview = () => {
    const setMode = useDCSetBuilderMode();
    const items = useDCItems();
    const status = useDCStatus();
    const { sidebar } = useDCConfig();
    const [isUnpublishModalOpen, setIsUnpublishModalOpen] = useState(false);
    const [wasCopied, setWasCopied] = useState(false);
    const updatePublishStatus = useDCUpdatePublishStatus();
    const embedCode = useDCEmbedCode();
    const setSidebarConfig = useDCSetSidebarConfig();
    const demoCenterId = useDCId();
    const postAppEvent = usePostAppEvent();
    const baseUrl = useDCBaseUrl();
    const copyEmbedCode = () => {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(embedCode).then(() => {
            postAppEvent(TourialAppEventTypes.EMBED_CODE_COPIED, { tourialId: "", demoCenterId });
            setWasCopied(true);
        }, err => {
            console.error("Failed to copy text to clipboard: ", err);
        });
    };
    const toggleSidebarCTA = (isEnabled) => {
        setSidebarConfig(Object.assign(Object.assign({}, sidebar), { displayCTAWhileEmbedded: isEnabled }));
    };
    useEffect(() => setMode(DCBuilderMode.OVERVIEW), []);
    useEffect(() => {
        if (wasCopied) {
            const timeout = setTimeout(() => {
                setWasCopied(false);
            }, 2000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [wasCopied]);
    const tabs = [DCShareTabs.PUBLIC_LINK, DCShareTabs.SHARE_LINKS, DCShareTabs.EMBED_CODE];
    const [selectedTab, setSelectedTab] = useState(DCShareTabs.PUBLIC_LINK);
    return (React.createElement(OverviewContainer, null,
        React.createElement(Header, null, "Share"),
        React.createElement(SectionContainer, null,
            React.createElement(TabsContainer, null, tabs.map(tab => {
                const selected = tab === selectedTab;
                return (React.createElement(Tab, { key: tab, selected: selected, onClick: () => {
                        setSelectedTab(tab);
                    } }, tab));
            })),
            selectedTab === DCShareTabs.PUBLIC_LINK && React.createElement(DCLinkEditor, null),
            selectedTab === DCShareTabs.SHARE_LINKS && (React.createElement(DCShareLinkViewTab, { democenterId: demoCenterId, baseUrlWithSubdomain: baseUrl })),
            selectedTab === DCShareTabs.EMBED_CODE && (React.createElement(React.Fragment, null,
                React.createElement(ToggleSetting, { name: "Keep top nav CTA", description: "The top nav is disabled in the embed widget. The top nav CTA will show in left column.", enabled: sidebar.displayCTAWhileEmbedded, onChange: toggleSidebarCTA }),
                React.createElement(Spacer, null),
                React.createElement("span", null, "Paste into your webpage where you want the Demo Center to display:"),
                React.createElement(TTextarea, { value: embedCode, readOnly: true, onClick: copyEmbedCode, className: wasCopied ? "highlight" : "" }),
                React.createElement(EmbedButtonContainer, null,
                    wasCopied && React.createElement("span", null, "Copied!"),
                    React.createElement(TButton, { variant: TButtonVariants.SECONDARY, size: "small", onClick: copyEmbedCode }, "Copy Embed Code"))))),
        items.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement(Header, null, "Content"),
            React.createElement(SectionContainer, { className: "table" },
                React.createElement(DCOverviewItemTable, null)))),
        React.createElement(Header, null, "Metadata"),
        React.createElement(SectionContainer, { style: { width: "100%", height: 329, position: "relative", gap: 27 } },
            React.createElement(DCMetadataEditor, null)),
        (status === TourialStatus.LIVE || status === TourialStatus.PENDING_CHANGES) && (React.createElement(ButtonContainer, null,
            React.createElement(TButton, { size: "medium", onClick: () => setIsUnpublishModalOpen(true), variant: TButtonVariants.DANGER }, "Unpublish Demo Center"))),
        isUnpublishModalOpen && (React.createElement(DCConfirmDeleteModal, { title: "Unpublish Demo Center", message: "Are you sure?", close: () => setIsUnpublishModalOpen(false), confirm: () => {
                void updatePublishStatus(TourialStatus.UNPUBLISHED);
                setIsUnpublishModalOpen(false);
            }, confirmText: "Unpublish" }))));
};
const DCMetadataEditor = () => {
    var _a, _b, _c, _d, _e, _f;
    const { htmlMetadata: metadata } = useDemoCenter();
    const updateDemoCenter = useUpdateDemoCenter();
    const [ogTitle, setOgTitle] = useState(((_a = metadata.find(m => m.property === "og:title")) === null || _a === void 0 ? void 0 : _a.content) || "");
    const [ogDescription, setOgDescription] = useState(((_b = metadata.find(m => m.property === "og:description")) === null || _b === void 0 ? void 0 : _b.content) || "");
    const [ogImage, setOgImage] = useState(((_c = metadata.find(m => m.property === "og:image")) === null || _c === void 0 ? void 0 : _c.content) || "");
    useEffect(() => {
        const htmlMetadata = cloneDeep(metadata);
        const titleIndex = metadata.findIndex(m => m.property === "og:title");
        const descriptionIndex = metadata.findIndex(m => m.property === "og:description");
        const ogImageIndex = metadata.findIndex(m => m.property === "og:image");
        if (titleIndex > -1) {
            htmlMetadata[titleIndex].content = ogTitle;
        }
        else {
            htmlMetadata.push({
                property: "og:title",
                content: ogTitle,
            });
        }
        if (descriptionIndex > -1) {
            htmlMetadata[descriptionIndex].content = ogDescription;
        }
        else {
            htmlMetadata.push({
                property: "og:description",
                content: ogDescription,
            });
        }
        if (ogImageIndex > -1) {
            htmlMetadata[ogImageIndex].content = ogImage;
        }
        else {
            htmlMetadata.push({
                property: "og:image",
                content: ogImage,
            });
        }
        updateDemoCenter({ htmlMetadata });
    }, [ogTitle, ogDescription, ogImage]);
    return (React.createElement(React.Fragment, null,
        React.createElement(InputContainer, null,
            React.createElement(DCCMTextEditor, { title: "Title tag", placeholder: "og: title", value: ((_d = metadata.find(m => m.property === "og:title")) === null || _d === void 0 ? void 0 : _d.content) || "", onChange: e => setOgTitle(e.target.value), style: { backgroundColor: "white", borderColor: newTheme.colors.grey400 }, labelStyle: { font: newTheme.fonts.mediumBold, color: newTheme.colors.grey700 } })),
        React.createElement(InputContainer, null,
            React.createElement(DCCMTextEditor, { title: "Description tag", placeholder: "og: description", value: ((_e = metadata.find(m => m.property === "og:description")) === null || _e === void 0 ? void 0 : _e.content) || "", onChange: e => setOgDescription(e.target.value), style: { backgroundColor: "white", borderColor: newTheme.colors.grey400 }, labelStyle: { font: newTheme.fonts.mediumBold, color: newTheme.colors.grey700 } })),
        React.createElement(ThumbnailContainer, null,
            React.createElement("span", null, "Thumbnail image"),
            React.createElement(DCCMThumbnail, null, ogImage && React.createElement("img", { src: ((_f = metadata.find(m => m.property === "og:image")) === null || _f === void 0 ? void 0 : _f.content) || "" })),
            React.createElement(DCFileUploader, { onUploadComplete: url => setOgImage(url) },
                React.createElement(DCCMUploadLink, null, "Change Thumbnail")))));
};
const ThumbnailContainer = styled.div `
  font: ${newTheme.fonts.mediumBold};
  padding-bottom: 4px;
  user-select: none;
  color: ${newTheme.colors.grey700};
  width: fit-content;
`;
const InputContainer = styled.div `
  width: 224px;
`;
const DCLinkEditor = () => {
    const democenterId = useDCId();
    const isLive = useDCIsLive();
    const slug = useDCSlug();
    const saveSlug = useDCSaveSlug();
    const baseUrl = useDCBaseUrl();
    const liveUrl = useDCLiveUrl();
    const [newSlug, setNewSlug] = useState(slug);
    const [isSlugEditing, setIsSlugEditing] = useState(false);
    const [slugError, setSlugError] = useState("");
    useDCLivePreviewHelper("");
    const changeHandler = (e) => {
        var _a;
        const slug = (_a = e.target.value) === null || _a === void 0 ? void 0 : _a.replace(/[^a-zA-Z0-9\-]/g, "");
        setSlugError("");
        setNewSlug(slug);
    };
    const saveSlugHandler = () => {
        if (newSlug) {
            TourialApiCalls.DemoCenters.getDemoCenterIdBySlug(newSlug)
                .then(id => {
                if (id === "" || id === democenterId) {
                    saveSlug(newSlug)
                        .then(() => {
                        setIsSlugEditing(false);
                    })
                        .catch(() => {
                        console.error("Error saving Demo Center");
                    });
                }
                else {
                    setSlugError("Slug already exists. Please try a different URL.");
                }
            })
                .catch(() => {
                console.error("Error finding Demo Center");
            });
        }
        else {
            setSlugError("Slug cannot be empty");
        }
    };
    return baseUrl ? (React.createElement(React.Fragment, null,
        React.createElement(SlugEditArea, null,
            isSlugEditing ? (React.createElement(Url, null,
                `${baseUrl}${DC_ROOT}/`,
                React.createElement(TextInput, { value: newSlug, style: { marginLeft: "4px", marginRight: "16px" }, autoFocus: true, onFocus: e => e.target.select(), onChange: changeHandler, onKeyDown: e => {
                        if (e.code === "Enter") {
                            e.preventDefault();
                            saveSlugHandler();
                        }
                    } }))) : (React.createElement(LiveLink, { href: isLive ? liveUrl : null, target: "_blank", className: !isLive ? "disabled" : "" }, liveUrl)),
            isSlugEditing ? (React.createElement(TButton, { size: "small", variant: TButtonVariants.TERTIARY, onClick: () => {
                    saveSlugHandler();
                } }, "Save")) : (React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY, icon: BluePencil, onClick: () => {
                    setIsSlugEditing(true);
                } }, "Edit Slug"))),
        slugError && React.createElement(TMessageBox, { variant: TMessageBoxVariants.DANGER, message: slugError }))) : null;
};
const ToggleSetting = ({ name, description, enabled, onChange }) => {
    return (React.createElement(ToggleSettingContainer, null,
        React.createElement(ToggleName, null,
            React.createElement("span", null, name),
            React.createElement(TToggleSwitch, { onChange: onChange, enabled: enabled, size: "small" })),
        React.createElement(ToggleDescription, null, description)));
};
const OverviewContainer = styled.div `
  max-width: 900px;
  margin: 0 auto 100px auto;
`;
const Header = styled.div `
  font: ${newTheme.fonts.header};
  margin-bottom: 12px;
  margin-top: 40px;
`;
const SectionContainer = styled.div `
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid ${newTheme.colors.grey300};
  background-color: ${newTheme.colors.white};
  box-shadow: ${newTheme.shadows.demoCenter};
  &.table {
    padding: 0;
  }
  overflow: hidden;
  textarea {
    cursor: pointer;
    color: ${newTheme.colors.grey600};
    height: 140px;
    font-family: "Courier New", Courier, monospace;
    &.highlight {
      color: ${newTheme.colors.blue300};
      background-color: ${newTheme.colors.blue50};
      border-color: ${newTheme.colors.blue300};
    }
    transition: all 0.2s ease-in-out;
  }
  span {
    font: ${newTheme.fonts.medium};
  }
`;
const Spacer = styled.div `
  width: 100%;
  height: 1px;
  background-color: ${newTheme.colors.grey400};
`;
const EmbedButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
`;
const ButtonContainer = styled.div `
  padding-top: 24px;
`;
const Url = styled.div `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.blue500};
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
`;
const LiveLink = styled.a `
  &.disabled {
    color: ${newTheme.colors.grey500};
    font-style: italic;
    cursor: not-allowed;
  }
`;
const SlugEditArea = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  a {
    font: ${newTheme.fonts.medium};
  }
  a,
  a:visited {
    color: ${newTheme.colors.blue500};
  }
`;
const ToggleSettingContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const ToggleName = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  span {
    font: ${newTheme.fonts.medium};
    color: ${newTheme.colors.black};
  }
`;
const ToggleDescription = styled.div `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey800};
`;
const TabsContainer = styled.div `
  display: flex;
  gap: 24px;
  border-bottom: 1px solid ${newTheme.colors.grey300};
  width: calc(100% + 32px);
  margin-left: -16px;
  padding-left: 16px;
`;
const Tab = styled.div `
  font: ${({ selected }) => (selected ? newTheme.fonts.mediumBold : newTheme.fonts.medium)};
  color: ${({ selected }) => (selected ? "black" : newTheme.colors.grey500)};
  border-bottom: ${({ selected }) => (selected ? `2px solid ${newTheme.colors.blue500}` : "none")};
  cursor: pointer;
  line-height: 24px;
`;
