import React, { useEffect, useRef, useState } from "react";
import { TDropdownSelect } from "../../../styled/t-dropdown-select";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
import { useActivePageId, useActiveVariantName, useImportPagesV3, usePushHistory, useTourId, } from "../../../../redux/selectors/redux-selectors";
import { useAsync } from "react-async";
import { ContentBody, Footer } from "./add-screen-modal";
import TButton from "../../../styled/t-button";
export function ImportScreen({ onClose, isEmptyTourial }) {
    var _a;
    const pushHistory = usePushHistory();
    const activeVariantName = useActiveVariantName();
    const activePageId = useActivePageId();
    const tourialId = useTourId();
    const importPages = useImportPagesV3();
    const defaultImportPageData = () => ({
        addFromTourialList: [],
        addFromPageList: [],
        tourialDropdownValue: "",
        pageDropdownValues: [],
        addFromTourial: null,
        addFromPageIds: [],
    });
    const [importPageData, setImportPageData] = useState(defaultImportPageData());
    const importPageDataRef = useRef();
    importPageDataRef.current = importPageData; // the below update function will always use the most up-to-date state
    const updateImportPageData = (updatedFields) => {
        setImportPageData(Object.assign(Object.assign({}, importPageDataRef.current), updatedFields));
    };
    const { data: tourialListV3 } = useAsync({
        promiseFn: TourialApiCalls.Tourials.getTourialListV3,
    });
    useEffect(() => {
        if (!tourialListV3)
            return;
        updateImportPageData({ addFromTourialList: tourialListV3.filter(t => t.id !== tourialId) });
    }, [tourialListV3]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ContentBody, null,
            React.createElement(ImportScreenContainer, null,
                React.createElement(Label, null, "Select tourial"),
                React.createElement(TDropdownSelect, { isSearchable: true, onChange: ({ value }) => {
                        updateImportPageData({
                            tourialDropdownValue: value,
                            pageDropdownValues: [],
                            addFromPageIds: [],
                            addFromPageList: [],
                            addFromTourial: null,
                        });
                        if (!value)
                            return;
                        const tIndex = importPageData.addFromTourialList.findIndex(t => t.id === value);
                        const selectedTourial = importPageData.addFromTourialList[tIndex];
                        TourialApiCalls.Tourials.getTourial(selectedTourial.id)
                            .then(({ tourial }) => {
                            var _a;
                            if (!((_a = tourial === null || tourial === void 0 ? void 0 : tourial[activeVariantName]) === null || _a === void 0 ? void 0 : _a.pages)) {
                                console.log("The selected tourial does not have a matching variant or pages!");
                                return;
                            }
                            const pages = tourial[activeVariantName].pages;
                            updateImportPageData({
                                addFromTourial: tourial,
                                addFromPageList: pages,
                            });
                        })
                            .catch(err => console.log(err));
                    }, style: { margin: "0px 0px 40px 0px", width: "419px" }, options: [
                        { label: "-- Select a Tourial --", value: "" },
                        ...importPageData.addFromTourialList.map((t) => ({
                            label: t.tourName,
                            value: t.id,
                        })),
                    ], selectedValue: importPageData.tourialDropdownValue }),
                React.createElement(Label, null, "Select screen"),
                React.createElement(TDropdownSelect, { isMulti: true, isSearchable: true, onChange: (selection) => {
                        const pageDropdownValues = [];
                        const addFromPageIds = [];
                        for (const item of selection) {
                            if (!item.value)
                                continue;
                            const pIndex = importPageData.addFromPageList.findIndex(p => p.id === item.value);
                            addFromPageIds.push(importPageData.addFromPageList[pIndex].id);
                            pageDropdownValues.push(item);
                        }
                        updateImportPageData({ addFromPageIds, pageDropdownValues });
                    }, selectedValue: importPageData.pageDropdownValues, style: { margin: "0", width: "419px" }, options: [
                        { label: "-- Select a Screen --", value: "" },
                        ...importPageData.addFromPageList.map((p) => ({
                            label: p.name,
                            value: p.id,
                        })),
                    ], placeholder: "-- Select a Screen --" }))),
        React.createElement(Footer, null,
            React.createElement(TButton, { id: "_test_import-to-tour-button", disabled: !((_a = importPageData.addFromPageIds) === null || _a === void 0 ? void 0 : _a.length) || !importPageData.addFromTourial, onClick: () => {
                    pushHistory();
                    importPages(importPageData.addFromPageIds, activePageId, importPageData.addFromTourial, isEmptyTourial);
                    onClose();
                } }, "Add to tour"))));
}
export const ImportScreenContainer = styled.div `
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const Label = styled.div `
  font: ${newTheme.fonts.normal};
  color: ${newTheme.colors.grey700};
  margin-bottom: 8px;
`;
