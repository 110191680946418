import React from "react";
import styled from "styled-components";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import TModal, { TModalButtonsContainer } from "../../../styled/t-modal";
import { newTheme } from "../../../styled/theme";
import { useRevertToPublishedTour, useSaveTourial } from "../../../../redux/selectors/redux-selectors";
import { useCheckedOutVersion } from "../../../../redux/selectors/builder-selectors";
import { getDateStringWithTime } from "../../../../../../shared/functions/helpers";
import { useAddToast } from "../../../../legacy/selectors";
import { ToastModes } from "../../../../types/definitions";
export default function SaveVersionModal({ onClose, onActionTaken }) {
    const revertToPublishedTour = useRevertToPublishedTour();
    const checkedOutVersion = useCheckedOutVersion();
    const saveTourial = useSaveTourial();
    const addToast = useAddToast();
    return (React.createElement(TModal, { title: "Save as new version", onClose: onClose },
        React.createElement(React.Fragment, null,
            React.createElement(Span, null, `You have unpublished changes to ${checkedOutVersion ? `version ${getDateStringWithTime(new Date(checkedOutVersion.savedAt).toLocaleString())}` : "your tourial"}. You may lose these changes if you view a different version without saving.`),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => {
                        revertToPublishedTour();
                        onClose();
                        onActionTaken();
                        addToast({
                            message: "Discarded changes.",
                            mode: ToastModes.SUCCESS,
                        });
                    } }, "Discard changes"),
                React.createElement(TButton, { onClick: () => {
                        saveTourial();
                        onClose();
                        onActionTaken();
                        addToast({
                            message: "Saved changes.",
                            mode: ToastModes.SUCCESS,
                        });
                    } }, "Save version")))));
}
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  height: 68px;
  width: 510px;
`;
