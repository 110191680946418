import React from "react";
import styled from "styled-components";
import { DCIconType, } from "../../../../../../shared/types/demo-center";
import { DCIcon } from "./dc-icon";
import { DCBodyText, DCStyledBodyText, DCStyledTitleText } from "./dc-styled-text";
import { dcGetRGBColorStringFromHex, dcGetReadableItemType } from "../helpers/dc-helpers";
import { dcPulseAnimation } from "../helpers/dc-style-helpers";
export const DCControlStrip = ({ onPrevious, onNext, config, theme, nextItem, currentItem, nextModule, styles, showCountdownTimer, highlightNextButton, }) => {
    const { titleStyle, typeStyle, labelStyle, next, placeholder } = config;
    return (React.createElement(ControlStripContainer, { styles: styles, theme: theme },
        React.createElement(ItemInfo, null, currentItem ? (React.createElement(React.Fragment, null,
            React.createElement(DCStyledTitleText, { config: titleStyle }, currentItem === null || currentItem === void 0 ? void 0 : currentItem.title),
            React.createElement(DCStyledBodyText, { config: typeStyle }, dcGetReadableItemType(currentItem === null || currentItem === void 0 ? void 0 : currentItem.type)))) : (React.createElement(DCBodyText, { config: placeholder }))),
        React.createElement(ButtonContainer, { styles: styles, theme: theme, config: config },
            React.createElement("button", { onClick: onPrevious, disabled: !onPrevious },
                React.createElement(DCIcon, { type: DCIconType.LEFT_ARROW })),
            React.createElement("button", { onClick: onNext, className: highlightNextButton || showCountdownTimer ? "pulse" : "", disabled: !onNext },
                React.createElement(DCIcon, { type: DCIconType.RIGHT_ARROW }))),
        React.createElement(NextContent, null,
            React.createElement(DCBodyText, { config: next }),
            React.createElement(DCStyledBodyText, { config: labelStyle }, nextItem ? nextItem.title : nextModule.title),
            showCountdownTimer && React.createElement(AnimatedBar, { styles: styles }))));
};
const ControlStripContainer = styled.div `
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  gap: 24px;
  border-radius: ${p => p.styles.containerBorderRadius}px;
  background-color: ${p => dcGetRGBColorStringFromHex(p.theme.neutralTranslucentColor, p.theme.neutralTranslucentOpacity)};
  box-shadow: ${p => p.theme.shadow};
  backdrop-filter: blur(50px);
`;
const ItemInfo = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 50px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: center;
  > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  padding-right: 24px;
  border-right: 1px solid ${p => p.theme.neutralSecondary};
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: ${p => p.styles.containerBorderRadius}px;
    padding: 8px;
    min-height: 40px;
    min-width: 40px;
    cursor: pointer;
    gap: 16px;
    background-color: ${p => p.theme.neutralPrimary};
    color: ${p => p.theme.textMain};
    box-shadow: ${p => p.theme.shadow};
    transition:
      background-color 0.1s ease-out,
      color 0.1s ease-out;
    &:hover:not(:disabled) {
      background-color: ${p => p.config.activeBackgroundColor};
      color: ${p => p.config.activeColor};
    }
    &.pulse {
      ${p => dcPulseAnimation(p.styles.highlightColor)};
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;
const NextContent = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 164px;
  gap: 4px;
  cursor: pointer;
  > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const AnimatedBar = styled.div `
  height: 4px;
  width: 164px;
  background-color: ${p => p.styles.highlightColor};
  animation:
    bar 5s linear,
    grow 100ms linear;
  border-radius: 2px;
  @keyframes bar {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes grow {
    0% {
      height: 0;
    }
    100% {
      height: 4px;
    }
  }
`;
