var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import GlobeIcon from "../../../../../../assets/images/v3/publish-globe-icon.svg";
import ClockIcon from "../../../../../../assets/images/v3/clock-icon.svg";
import GreenCheckmark from "../../../../../../assets/images/v3/green-checkmark.svg";
import { useAsync } from "react-async";
import { cloneDeep, debounce } from "lodash";
import { useHTStatus, useHTUpdatePublishedTour } from "../../hooks/ht-hooks";
import { TourialStatus } from "../../../../../../../shared/types/tourial-model";
import ReactPortal from "../../../../../helpers/react-portal";
// import { TourialAppEventTypes } from "../../../../../../../shared/types/app-events";
import { makeEmbedCode } from "./embed-tab";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import TStatusIndicator from "../../../../styled/t-status-indicator";
import { TToggleSwitch } from "../../../../styled/t-toggle-switch";
import { useHTIsEditingHtml, useHTPublishedTour, useHTSlug, useHTTour, } from "../../../../../redux/selectors/ht-selectors";
import { TDropdownSelect } from "../../../../styled/t-dropdown-select";
import { newTheme } from "../../../../styled/theme";
import { EmbedButtonContainer, EmbedTextarea } from "../../../builder/modals/embed-tab";
import { UpdateLiveVersionModal } from "./publish-modal";
import { DraftContext } from "../dom-tour-builder-page";
import { TourialApiCalls } from "../../../../../helpers/tourial-api-calls";
import { useAddToast } from "../../../../../legacy/selectors";
import { ToastModes } from "../../../../../types/definitions";
import { ShareLinkViewTab } from "../../../../share-links/share-links-viewer/share-link-view-tab";
import { IContentType } from "../../../../../../../shared/types/events";
import { SlugEditor } from "./slug-editor";
import { getCurrentUser } from "../../../../../helpers/misc";
import { useUserAccess } from "../../../../../redux/selectors/redux-selectors";
import { AccessLevel } from "../../../../../../../shared/types/auth-types";
export function Share() {
    const isEditing = useHTIsEditingHtml();
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
    const status = useHTStatus();
    const tourialHasChangesToSave = status === TourialStatus.PENDING_CHANGES;
    const isDraft = [TourialStatus.DRAFT, TourialStatus.UNPUBLISHED].includes(status);
    const [displayAutosave, setDisplayAutosave] = useState(false);
    const [timer, setTimer] = useState();
    const publishedTour = useHTPublishedTour();
    const addToast = useAddToast();
    const draft = useContext(DraftContext);
    return (React.createElement(React.Fragment, null,
        tourialHasChangesToSave && (React.createElement(UnpublishedChangesButton, { onClick: () => !isEditing && setIsDiscardModalOpen(true), isEditing: isEditing },
            React.createElement("img", { src: ClockIcon, alt: "clock" }),
            React.createElement(UnpublishedChangesText, null, "Unpublished changes"))),
        displayAutosave && (React.createElement(UnpublishedChangesButton, null,
            React.createElement("img", { src: GreenCheckmark, alt: "green-checkmark" }),
            React.createElement(UnpublishedChangesText, { style: { margin: "0px 18px 0px 8px" } }, "Auto-saved"))),
        React.createElement(TButton, { size: "medium", onClick: () => {
                setIsShareModalOpen(true);
                if (isDraft) {
                    setDisplayAutosave(true);
                    if (timer)
                        clearTimeout(timer);
                    const newTimer = setTimeout(() => setDisplayAutosave(false), 10000);
                    setTimer(newTimer);
                }
            }, disabled: isEditing }, "Share"),
        isShareModalOpen && React.createElement(ShareModal, { onClose: () => setIsShareModalOpen(false) }),
        isDiscardModalOpen && (React.createElement(UpdateLiveVersionModal, { saveHtmlTour: () => {
                void revertPublishTour();
            }, isDiscard: true, setIsUpdateLiveVersionModalOpen: setIsDiscardModalOpen }))));
    function revertPublishTour() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!publishedTour)
                    throw new Error("failed to find a prevous published version.");
                yield draft.update(publishedTour);
                addToast({
                    message: "Unpublished changes have been discarded.",
                    mode: ToastModes.SUCCESS,
                });
            }
            catch (err) {
                addToast({
                    message: "Failed to discard unpublished changes.",
                    mode: ToastModes.ERROR,
                });
            }
        });
    }
}
var ModalTabs;
(function (ModalTabs) {
    ModalTabs["PUBLIC_LINK"] = "Public Link";
    ModalTabs["EMBED"] = "Embed";
    ModalTabs["ADDITIONAL_LINKS"] = "Additional Links";
})(ModalTabs || (ModalTabs = {}));
export default function ShareModal({ onClose }) {
    const status = useHTStatus();
    const userAccess = useUserAccess();
    const isPublished = [TourialStatus.LIVE, TourialStatus.PENDING_CHANGES].includes(status);
    const tourialHasChangesToSave = status === TourialStatus.PENDING_CHANGES;
    // const postAppEvent = usePostAppEvent();
    const tabs = [ModalTabs.PUBLIC_LINK, ModalTabs.EMBED, ModalTabs.ADDITIONAL_LINKS];
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const { data, error } = useAsync({
        promiseFn: getCurrentUser,
    });
    if (error) {
        console.error("Error getting current user", error);
    }
    const baseUrlWithSubdomain = data;
    useEffect(() => {
        const closeOnEscapeKey = e => (e.key === "Escape" ? onClose() : null);
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [onClose]);
    const [embedCodeWasCopied, setEmbedCodeWasCopied] = useState(false);
    const slug = useHTSlug();
    const copyEmbedCodeToClipboard = debounce(() => {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(makeEmbedCode(baseUrlWithSubdomain, slug, isLightboxEnabled)).then(function () {
            // TODO: post app event here
            setEmbedCodeWasCopied(true);
        }, function (err) {
            console.error("Failed to copy text to clipboard: ", err);
        });
    }, 500);
    const [isUpdateLiveVersionModalOpen, setIsUpdateLiveVersionModalOpen] = useState(false);
    const [isLightboxEnabled, setIsLightboxEnabled] = useState(false);
    const addToast = useAddToast();
    const updatePublishedDomTour = useHTUpdatePublishedTour();
    const tour = useHTTour();
    const draft = useContext(DraftContext);
    return (React.createElement(ReactPortal, { wrapperId: "tourial-portal-modal-container" },
        React.createElement(Overlay, { id: "t-overlay", onMouseDown: e => {
                if (document.elementFromPoint(e.clientX, e.clientY).id === "t-overlay")
                    onClose();
            } },
            React.createElement(SaveModalContainer, null,
                React.createElement(Header, null,
                    React.createElement(HeaderIcon, null,
                        React.createElement("img", { src: GlobeIcon })),
                    React.createElement(HeaderText, null, "Publish & share"),
                    React.createElement(TToggleSwitch, { disabled: status === TourialStatus.ARCHIVED || userAccess === AccessLevel.COLLABORATOR, enabled: isPublished, onChange: e => void publishTour(e) }),
                    React.createElement("div", { style: { marginLeft: "16px", width: "auto" } },
                        React.createElement(TStatusIndicator, { status: status }))),
                isPublished && (React.createElement(TabContainer, null, tabs.map((tab, i) => (React.createElement(Tab, { className: selectedTab === tab ? "selected" : null, key: `tab-${i}-${tab}`, onClick: () => {
                        setSelectedTab(tab);
                    } }, tab))))),
                selectedTab === ModalTabs.PUBLIC_LINK && (React.createElement(PublicLinkTabContent, null,
                    React.createElement("div", null,
                        React.createElement(BodyText, null, isPublished
                            ? "Your Tourial is accessible to anyone on the web with this link"
                            : "Toggle your Tourial to publish and make it accessible to anyone on the web.")),
                    isPublished && React.createElement(SlugEditor, { baseUrlWithSubdomain: baseUrlWithSubdomain }))),
                selectedTab === ModalTabs.EMBED && (React.createElement(EmbedTabContent, null,
                    React.createElement("span", null, "Embed Type:"),
                    React.createElement(TDropdownSelect, { isSearchable: false, selectedValue: isLightboxEnabled, style: { width: "100%" }, onChange: (e) => {
                            setIsLightboxEnabled(e.value);
                        }, options: [
                            { value: false, label: "Embed in Page" },
                            { value: true, label: "Tourial Lightbox" },
                        ] }),
                    isLightboxEnabled && (React.createElement(React.Fragment, null,
                        React.createElement("span", null, "Add the class name below on the HTML tag that will trigger the lightbox."),
                        React.createElement(EmbedTextarea, { style: { height: 35, marginBottom: 8 } },
                            React.createElement("code", null,
                                "tourial-lightbox-trigger-",
                                slug)))),
                    React.createElement("span", null, "Paste into your webpage where you want the Tourial to display:"),
                    React.createElement(EmbedTextarea, null,
                        React.createElement("code", null, makeEmbedCode(baseUrlWithSubdomain, slug, isLightboxEnabled))),
                    React.createElement(EmbedButtonContainer, null,
                        embedCodeWasCopied && React.createElement("span", null, "Copied!"),
                        React.createElement(TButton, { variant: TButtonVariants.SECONDARY, size: "medium", onClick: () => {
                                copyEmbedCodeToClipboard();
                                setEmbedCodeWasCopied(true);
                            } }, "Copy Embed Code")))),
                selectedTab === ModalTabs.ADDITIONAL_LINKS && (React.createElement(ShareLinkViewTab, { tourialId: tour._id, tourialSlug: slug, baseUrlWithSubdomain: baseUrlWithSubdomain, type: IContentType.HTML_TOUR })),
                React.createElement(ButtonContainer, null,
                    tourialHasChangesToSave && isPublished && (React.createElement(PublishChangesContainer, null,
                        React.createElement(TButton, { variant: TButtonVariants.TERTIARY, onClick: () => setIsUpdateLiveVersionModalOpen(true) }, "Update live"),
                        isUpdateLiveVersionModalOpen && (React.createElement(UpdateLiveVersionModal, { setIsUpdateLiveVersionModalOpen: setIsUpdateLiveVersionModalOpen, saveHtmlTour: publishTour })))),
                    React.createElement(TButton, { onClick: () => {
                            onClose();
                        } }, "Done"))))));
    function publishTour(e) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!tour.steps.length)
                    throw new Error("Cannot publish tour with no steps.");
                // publishing
                if (e) {
                    const htmlMetadata = yield getHtmlMetadata();
                    yield draft.update({ htmlMetadata, status: TourialStatus.LIVE });
                    void updatePublishedDomTour(Object.assign(Object.assign({}, tour), { htmlMetadata, status: TourialStatus.LIVE }));
                    addToast({
                        message: "Published tour updated.",
                        mode: ToastModes.SUCCESS,
                    });
                }
                // unpublishing
                else {
                    yield draft.update({ status: TourialStatus.UNPUBLISHED });
                    void updatePublishedDomTour({ status: TourialStatus.UNPUBLISHED });
                    addToast({
                        message: "Unpublished tour.",
                        mode: ToastModes.SUCCESS,
                    });
                }
            }
            catch (err) {
                addToast({
                    message: (err === null || err === void 0 ? void 0 : err.message) || "Failed to update tour.",
                    mode: ToastModes.ERROR,
                });
            }
        });
    }
    function getHtmlMetadata() {
        return __awaiter(this, void 0, void 0, function* () {
            const content = yield TourialApiCalls.DomCapture.getDomCaptureThumbnail(tour.steps[0].captureId);
            const oldHtmlMetadata = tour.htmlMetadata || [];
            const ogImageIndex = oldHtmlMetadata.findIndex(m => m.property === "og:image");
            if (ogImageIndex > -1) {
                const newHtmlMetadata = cloneDeep(oldHtmlMetadata);
                newHtmlMetadata[ogImageIndex].content = content;
                return newHtmlMetadata;
            }
            return [...oldHtmlMetadata, { property: "og:image", content }];
        });
    }
}
const Overlay = styled.div `
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const SaveModalContainer = styled.div `
  position: absolute;
  top: 57px;
  right: 5px;
  width: 480px;
  background-color: ${newTheme.colors.white};
  box-shadow: ${newTheme.shadows.outside60};
`;
const Header = styled.div `
  display: flex;
  padding: 16px;
  align-items: center;
`;
const HeaderIcon = styled.div `
  margin: 0 4px 0 0;
  width: 32px;
  height: 32px;
  img {
    width: 32px;
    height: 32px;
  }
`;
const BodyText = styled.div `
  font: ${newTheme.fonts.normal};
  color: ${newTheme.colors.grey800};
`;
const HeaderText = styled.div `
  display: flex;
  flex: 1;
  font: ${newTheme.fonts.bold};
  color: ${newTheme.colors.grey800};
`;
const TabContainer = styled.ul `
  list-style: none;
  width: 100%;
  display: flex;
  padding: 0 12px;
`;
const Tab = styled.li `
  cursor: pointer;
  margin: 0 12px;
  padding-bottom: 4px;
  font: ${newTheme.fonts.normal};
  color: ${newTheme.colors.grey600};
  &.selected {
    color: ${newTheme.colors.black};
    border-bottom: 4px solid ${newTheme.colors.blue300};
    font-weight: bold;
  }
`;
const PublicLinkTabContent = styled.div `
  padding: 24px 16px;
  background-color: ${newTheme.colors.grey100};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  border-top: 1px solid ${newTheme.colors.grey300};
`;
const EmbedTabContent = styled.div `
  padding: 24px 16px;
  background-color: ${newTheme.colors.grey100};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  border-top: 1px solid ${newTheme.colors.grey300};
`;
const ButtonContainer = styled.div `
  padding: 16px;
  display: flex;
  justify-content: right;
`;
const PublishChangesContainer = styled.div `
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
`;
const UnpublishedChangesButton = styled.span `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  cursor: ${props => (props.isEditing ? "not-allowed" : "pointer")};
  animation: ${newTheme.animations.fadeIn};
  animation-duration: 0.3s;
  animation-timing-function: linear;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;
const UnpublishedChangesText = styled.p `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
  user-select: none;
  white-space: nowrap;
  > span {
    color: ${newTheme.colors.grey500};
    text-decoration: underline;
  }
`;
