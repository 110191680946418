import React from "react";
import styled from "styled-components";
import { THorizontalSlider } from "../../../../styled/t-horizontal-slider";
import { TVerticalSlider } from "../../../../styled/t-vertical-slider";
import { newTheme } from "../../../../styled/theme";
import { ZoomLevelSelector } from "./zoom-level-selector";
import DPadIcon from "../../../../../../assets/images/dpad-chevron-icon.svg";
export const ZoomOffsetController = ({ zoomData, disabledAxis, onYAxisUpdate, onXAxisUpdate, onZAxisUpdate, }) => {
    const xDisabled = disabledAxis === null || disabledAxis === void 0 ? void 0 : disabledAxis.includes("x");
    const yDisabled = disabledAxis === null || disabledAxis === void 0 ? void 0 : disabledAxis.includes("y");
    const OFFSET = 1;
    const handleZoomChange = (z) => {
        onZAxisUpdate(z);
    };
    const handleVerticalSlider = (y) => {
        onYAxisUpdate(-y);
    };
    const handleHorizontalSlider = (x) => {
        onXAxisUpdate(x);
    };
    const handleUpButton = () => {
        const y = zoomData.y - OFFSET;
        onYAxisUpdate(y);
    };
    const handleDownButton = () => {
        const y = zoomData.y + OFFSET;
        onYAxisUpdate(y);
    };
    const handleLeftButton = () => {
        const x = zoomData.x - OFFSET;
        onXAxisUpdate(x);
    };
    const handleRightButton = () => {
        const x = zoomData.x + OFFSET;
        onXAxisUpdate(x);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ZoomLevelSelector, { level: zoomData.z, onChange: handleZoomChange }),
        React.createElement(ZoomOffsetControllerContainer, null,
            React.createElement(DPadContainer, null,
                React.createElement(UpButton, { onClick: handleUpButton, disabled: yDisabled },
                    React.createElement("img", { src: DPadIcon, alt: "up" })),
                React.createElement(DownButton, { onClick: handleDownButton, disabled: yDisabled },
                    React.createElement("img", { src: DPadIcon, alt: "down" })),
                React.createElement(LeftButton, { onClick: handleLeftButton, disabled: xDisabled },
                    React.createElement("img", { src: DPadIcon, alt: "left" })),
                React.createElement(RightButton, { onClick: handleRightButton, disabled: xDisabled },
                    React.createElement("img", { src: DPadIcon, alt: "right" }))),
            React.createElement(VerticalSliderContainer, null,
                React.createElement(TVerticalSlider, { min: -100, max: 100, defaultValue: 0, value: yDisabled ? 0 : -zoomData.y, onChange: handleVerticalSlider, disabled: yDisabled })),
            React.createElement(HorizontalSliderContainer, null,
                React.createElement(THorizontalSlider, { min: -100, max: 100, defaultValue: 0, value: xDisabled ? 0 : zoomData.x, onChange: handleHorizontalSlider, disabled: xDisabled })))));
};
const ZoomOffsetControllerContainer = styled.div `
  display: flex;
  background-color: ${newTheme.colors.grey200};
  width: 288px;
  height: 205px;
  position: relative;
`;
const DPadContainer = styled.div `
  position: relative;
  width: 152px;
  height: 120px;
  margin: 26px auto;
`;
const DPadButton = styled.button `
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background-color: ${newTheme.colors.white};
  border: 1px solid ${newTheme.colors.grey500};
  box-shadow: ${newTheme.shadows.outside80};
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.blue100};
    border: 1px solid ${newTheme.colors.blue300};
  }
  &:active {
    box-shadow: none;
    border: 1px solid ${newTheme.colors.blue};
    transform: translate(1px, 1px);
  }
  &:disabled {
    background-color: ${newTheme.colors.grey100};
    border: 1px solid ${newTheme.colors.grey400};
    box-shadow: none;
    transform: none;
    cursor: not-allowed;
    img {
      opacity: 0.5;
    }
  }
`;
const UpButton = styled(DPadButton) `
  top: 8px;
  left: 64px;
`;
const DownButton = styled(DPadButton) `
  bottom: 8px;
  left: 64px;
  img {
    transform: rotate(180deg);
  }
`;
const LeftButton = styled(DPadButton) `
  top: 46px;
  left: 16px;
  img {
    transform: rotate(-90deg);
  }
`;
const RightButton = styled(DPadButton) `
  top: 46px;
  right: 16px;
  img {
    transform: rotate(90deg);
  }
`;
const VerticalSliderContainer = styled.div `
  position: absolute;
  top: 16px;
  right: 40px;
`;
const HorizontalSliderContainer = styled.div `
  position: absolute;
  bottom: 32px;
  left: 50px;
`;
