import { ShirtSize, TextAlign } from "../../../shared/types/tool-data-types/common";
export const headlineFontSizes = {
    [ShirtSize.XXSMALL]: 14,
    [ShirtSize.XSMALL]: 16,
    [ShirtSize.SMALL]: 18,
    [ShirtSize.MEDIUM]: 22,
    [ShirtSize.LARGE]: 26,
};
export const bodyFontSizes = {
    [ShirtSize.SMALL]: 12,
    [ShirtSize.MEDIUM]: 14,
    [ShirtSize.LARGE]: 16,
};
export const textAlignments = {
    [TextAlign.LEFT]: "left",
    [TextAlign.CENTER]: "center",
    [TextAlign.RIGHT]: "right",
};
export const getHeadlineFontSize = (width, size) => {
    return getFontSizeFromList(width, size, headlineFontSizes);
};
export const getBodyFontSize = (width, size) => {
    return getFontSizeFromList(width, size, bodyFontSizes);
};
const getFontSizeFromList = (width, size, list) => {
    if (width && width < 720 && (size === ShirtSize.LARGE || size === ShirtSize.MEDIUM)) {
        return list[ShirtSize.SMALL];
    }
    else {
        return list[size];
    }
};
