import React, { useEffect, useRef, useState } from "react";
import TInput from "./t-input";
import DropdownCaret from "../../../assets/images/v3/dropdown-caret.svg";
import styled from "styled-components";
import { newTheme } from "../styled/theme";
import { useOutsideClick } from "../../hooks/use-outside-click";
export default function TSearchableDropdown({ width, list, selectedValue, parentSetter }) {
    const [textInput, setTextInput] = useState("");
    const [listExpanded, setListExpanded] = useState(false);
    const [filteredList, setFilteredList] = useState(list || []);
    const dropdownRef = useRef();
    useOutsideClick([dropdownRef], () => setListExpanded(false));
    useEffect(() => setFilteredList(list), [list]);
    // apply case-insensitive search filter
    function filterBySearch(searchString) {
        const regex = new RegExp(`${searchString.toLowerCase()}`);
        setFilteredList(list.filter(listItem => regex.test(listItem.display.toLowerCase())));
    }
    return (React.createElement("div", { ref: dropdownRef },
        React.createElement(TInput, { placeholder: "Select account", value: textInput, onChange: e => {
                if (selectedValue)
                    parentSetter("");
                setTextInput(e.target.value);
                filterBySearch(e.target.value);
            }, onFocus: () => setListExpanded(true), onClick: () => setListExpanded(true), style: { width } }),
        React.createElement(DropdownCaretImg, { src: DropdownCaret, alt: "dropdown-caret", style: { transform: `rotate(${listExpanded ? 180 : 0}deg)` }, onClick: () => setListExpanded(!listExpanded) }),
        listExpanded && !!(filteredList === null || filteredList === void 0 ? void 0 : filteredList.length) && (React.createElement(DropdownDiv, { style: { width } },
            React.createElement("table", { style: { width: "100%" } },
                React.createElement("tbody", { className: "_test_searchable-dropdown", style: { position: "relative" } }, filteredList.map((dropdownItem, i) => {
                    const itemDisplay = dropdownItem.display;
                    const itemValue = dropdownItem.value;
                    return (React.createElement(TR, { onClick: e => {
                            e.preventDefault();
                            parentSetter(itemValue);
                            setListExpanded(false);
                            setTextInput(itemDisplay);
                            filterBySearch(itemDisplay);
                        }, key: i },
                        React.createElement(TD, { style: { backgroundColor: newTheme.colors[selectedValue === itemValue ? "grey200" : "grey100"] } }, itemDisplay)));
                })))))));
}
const DropdownCaretImg = styled.img `
  position: absolute;
  cursor: pointer;
  margin-left: -25px;
  margin-top: 7px;
`;
const DropdownDiv = styled.div `
  position: absolute;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  font: ${newTheme.fonts.medium};
  background: ${newTheme.colors.grey100};
  border: 1px solid ${newTheme.colors.grey200};
  color: ${newTheme.colors.black};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;
const TR = styled.tr `
  line-height: 30px;
  cursor: pointer;
`;
const TD = styled.td `
  padding-left: 12px;
  border-bottom: 1px solid ${newTheme.colors.grey200};
  color: ${newTheme.colors.grey600};
`;
