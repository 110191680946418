import React from "react";
import styled from "styled-components";
import { DCTrackSelector } from "../../live/dc-track-selector";
import { useDCGetNewModule } from "../../hooks/dc-module-hooks";
import { DCIconType, DCModuleType } from "../../../../../../../shared/types/demo-center";
import { TMessageBox, TMessageBoxVariants } from "../../../../styled/t-message-box";
import { DCIcon } from "../../components/dc-icon";
import { newTheme } from "../../../../styled/theme";
export const DCWizardModulePreview = ({ module }) => {
    const getNewModule = useDCGetNewModule();
    const { type } = module || {};
    return type !== DCModuleType.NO_PROMPT && !!module ? (React.createElement(PreviewContainer, null,
        React.createElement(DCTrackSelector, { module: getNewModule(module), onSelectionConfirm: () => { }, onTrackSelect: () => { }, onSkip: () => { }, previewMode: true }),
        React.createElement(TMessageBox, { variant: TMessageBoxVariants.SECONDARY, size: "small", message: "Worried you won\u2019t have enough tours for your content tracks? In the Demo Center you can embed content from nearly anywhere.", style: { maxWidth: "416px" } }))) : (React.createElement(EmptyIcon, null,
        React.createElement(DCIcon, { type: DCIconType.LIBRARY })));
};
const PreviewContainer = styled.div `
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  width: 100%;
`;
const EmptyIcon = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${newTheme.colors.grey300};
  svg {
    width: 32px;
    height: 32px;
  }
`;
