import React, { useEffect } from "react";
import { TooltipTriggerComponent } from "../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import { Bubble } from "./subtools/bubble";
import { Clickzone } from "./subtools/clickzone";
import { Spotlight } from "./subtools/spotlight";
import { Hotspot } from "./subtools/hotspot";
import { InteractionName } from "../../../../../../shared/types/tool-data-types/interactions";
import { useHiddenTools, useHideTools, useShowTools } from "../../../../redux/selectors/redux-selectors";
export var TooltipSubtools;
(function (TooltipSubtools) {
    TooltipSubtools["CLICKZONE"] = "CLICKZONE";
    TooltipSubtools["HOTSPOT"] = "HOTSPOT";
    TooltipSubtools["SPOTLIGHT"] = "SPOTLIGHT";
    TooltipSubtools["BUBBLE"] = "BUBBLE";
})(TooltipSubtools || (TooltipSubtools = {}));
export function makeSubtoolId(toolId, subtool) {
    return `${toolId}-${subtool}`;
}
export function TooltipV2View(props) {
    const { toolData: tool, isInEditViewport } = props;
    useResetInteractionEffects(tool, isInEditViewport);
    const subtools = [];
    if (tool.trigger.triggerComponent === TooltipTriggerComponent.CLICKZONE) {
        subtools.push(React.createElement(Clickzone, Object.assign({ key: makeSubtoolId(tool.id, TooltipSubtools.CLICKZONE) }, props)));
    }
    else if (tool.trigger.triggerComponent === TooltipTriggerComponent.HOTSPOT) {
        subtools.push(React.createElement(Hotspot, Object.assign({ key: makeSubtoolId(tool.id, TooltipSubtools.HOTSPOT) }, props)));
    }
    return subtools;
}
export function TooltipV2ViewSpotlight(props) {
    const { toolData: tool, isInEditViewport } = props;
    const isSpotlightHidden = useHiddenTools()[makeSubtoolId(tool.id, TooltipSubtools.SPOTLIGHT)];
    if (tool.spotlight.isEnabled && (isInEditViewport || (!isInEditViewport && !isSpotlightHidden))) {
        return React.createElement(Spotlight, Object.assign({ key: makeSubtoolId(tool.id, TooltipSubtools.SPOTLIGHT) }, props));
    }
    return null;
}
export function TooltipV2ViewBubble(props) {
    const { toolData: tool, isInEditViewport } = props;
    const isBubbleHidden = useHiddenTools()[makeSubtoolId(tool.id, TooltipSubtools.BUBBLE)];
    if (tool.bubble.isEnabled && (isInEditViewport || (!isInEditViewport && !isBubbleHidden))) {
        return React.createElement(Bubble, Object.assign({ key: makeSubtoolId(tool.id, TooltipSubtools.BUBBLE) }, props));
    }
    return null;
}
function useResetInteractionEffects(tool, isInEditViewport) {
    const hiddenTools = useHiddenTools();
    const spotlight = makeSubtoolId(tool.id, TooltipSubtools.SPOTLIGHT);
    const bubble = makeSubtoolId(tool.id, TooltipSubtools.BUBBLE);
    const hideTools = useHideTools();
    const showTools = useShowTools();
    useEffect(() => {
        if (tool.trigger.triggerComponent !== TooltipTriggerComponent.NONE &&
            (tool.trigger.interaction.name === InteractionName.SHOW_TOOLTIP_BUBBLE_AND_SPOTLIGHT ||
                tool.trigger.interaction.name === InteractionName.TOGGLE_TOOLTIP_BUBBLE_AND_SPOTLIGHT)) {
            if (!hiddenTools[spotlight] || !hiddenTools[bubble]) {
                hideTools([bubble, spotlight]);
            }
        }
        else if (tool.trigger.interaction.name === InteractionName.HIDE_TOOLTIP_BUBBLE_AND_SPOTLIGHT) {
            if (hiddenTools[spotlight] || hiddenTools[bubble]) {
                showTools([bubble, spotlight]);
            }
        }
        else if (hiddenTools[spotlight] || hiddenTools[bubble]) {
            showTools([bubble, spotlight]);
        }
    }, [isInEditViewport]);
}
