import React from "react";
import { ViewportMode } from "../../../types/definitions";
import { withToolWrapper } from "../with-tool-wrapper";
function TourialHotspot(props) {
    const { viewportMode } = props;
    return (React.createElement("div", { className: "_test_hotspot-viewtool", style: {
            overflow: "visible",
        } },
        React.createElement("div", { style: {
                overflow: "visible",
                backgroundColor: props.toolData.color,
                zIndex: 1,
                borderRadius: "50%",
                position: "absolute",
                width: "100%",
                height: "100%",
                cursor: viewportMode === ViewportMode.EDIT ? "move" : "pointer",
                animationName: viewportMode === ViewportMode.EDIT ? null : "bounceInV2",
                animationDuration: "0.5s",
                animationTimingFunction: "linear",
                animationFillMode: "revert",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }, id: props.toolData.id },
            React.createElement("div", { style: {
                    overflow: "visible",
                    zIndex: -1,
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    animationName: props.disableAnimations
                        ? null
                        : viewportMode === ViewportMode.LIVE || viewportMode === ViewportMode.PREVIEW
                            ? "hotspot-pulsate-default"
                            : null,
                    borderStyle: "solid",
                    borderWidth: `${Math.min(Math.max(Math.floor(1 / (props.toolData.sizeAndPosition.width / 2)), 2), 4)}px`,
                    borderColor: `${props.toolData.color}`,
                    animationDuration: "2s",
                    animationDelay: "1.5s",
                    animationIterationCount: "infinite",
                    animationTimingFunction: "ease-in-out",
                    pointerEvents: "none",
                } }))));
}
export const Hotspot = React.memo(withToolWrapper(TourialHotspot));
