// Login actions
export const SET_TOURIAL_AUTH_DATA = "SET_TOURIAL_AUTH_DATA";
export const SET_LOADING_BOOL = "SET_LOADING_BOOL";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const SET_SESSION_ID = "SET_SESSION_ID";
// Public url
export const SET_PUBLIC_URL_TRUE = "SET_PUBLIC_URL_TRUE";
export const RESET_PUBLIC_URL = "RESET_PUBLIC_URL";
// Admin
export const GET_DOMAIN_DATA = "GET_DOMAIN_DATA";
export const SAVE_CUSTOM_DOMAIN = "SAVE_CUSTOM_DOMAIN";
export const DELETE_CUSTOM_DOMAIN = "DELETE_CUSTOM_DOMAIN";
export const GET_ALL_ACCOUNTS = "GET_ALL_ACCOUNTS";
export const GET_ALL_USERS_AND_ACCOUNTS = "GET_ALL_USERS_AND_ACCOUNTS";
export const RENDER_SUPER_ADMIN = "RENDER_SUPER_ADMIN";
export const RESET_SUPER_ADMIN = "RESET_SUPER_ADMIN";
export const UPDATE_VIEWING_ACCOUNT = "UPDATE_VIEWING_ACCOUNT";
export const SET_PROJECT_ROOM_LINK = "SET_PROJECT_ROOM_LINK";
export const SET_DEMO_CHATBOT_URL = "SET_DEMO_CHATBOT_URL";
export const SET_DEMO_CHATBOT_BACKGROUND_IMAGE = "SET_DEMO_CHATBOT_BACKGROUND_IMAGE";
export const SET_WORKSPACE_ID = "SET_WORKSPACE_ID";
// error message
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";
export const RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE";
// ?????? fml
// hacky: this is not actually legacy
export const SET_FULL_SCREEN_TRUE = "SET_FULL_SCREEN_TRUE";
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
export const SET_LOAD_EVENT = "SET_LOAD_EVENT";
export const SET_IS_MICROSITE_SESSION_STARTED = "SET_IS_MICROSITE_SESSION_STARTED";
export const SET_IS_DEMOCENTER_SESSION_STARTED = "SET_IS_DEMOCENTER_SESSION_STARTED";
export const SET_IS_SESSION_STARTED = "SET_IS_SESSION_STARTED";
export const SET_LATEST_TOURIAL_VIEW_EVENT = "SET_LATEST_TOURIAL_VIEW_EVENT";
export const SET_SHOW_CHROME_EXTENSION_MODAL = "SET_SHOW_CHROME_EXTENSION_MODAL";
