export var TourialMediaType;
(function (TourialMediaType) {
    TourialMediaType["IMAGE"] = "IMAGE";
    TourialMediaType["VIDEO"] = "VIDEO";
    TourialMediaType["HTML"] = "HTML";
})(TourialMediaType || (TourialMediaType = {}));
export var TourialStatus;
(function (TourialStatus) {
    TourialStatus["LIVE"] = "live";
    TourialStatus["DRAFT"] = "draft";
    TourialStatus["UNPUBLISHED"] = "unpublished";
    TourialStatus["ARCHIVED"] = "archived";
    TourialStatus["PENDING_CHANGES"] = "pendingChanges";
})(TourialStatus || (TourialStatus = {}));
export var TourialType;
(function (TourialType) {
    TourialType["MEDIA"] = "Media";
    TourialType["HTML"] = "HTML";
})(TourialType || (TourialType = {}));
export var HomePageTabs;
(function (HomePageTabs) {
    HomePageTabs["MEDIA"] = "Media Demos";
    HomePageTabs["HTML"] = "HTML Demos";
    HomePageTabs["DEMOCENTER"] = "Demo Centers";
    HomePageTabs["TOURPLAYER"] = "TourPlayers";
})(HomePageTabs || (HomePageTabs = {}));
