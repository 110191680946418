var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { useAsync } from "react-async";
import styled from "styled-components";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
import TTextInput from "../../../../legacy/components/styled/t-text-input";
import TButton from "../../../styled/t-button";
import TModal from "../../../styled/t-modal";
import { newTheme } from "../../../styled/theme";
import CheckCircleIcon from "../../../../../assets/images/v3/check-with-circle.svg";
const getMediaSearchResults = ({ searchTerm, pageNumber }) => __awaiter(void 0, void 0, void 0, function* () {
    const data = yield TourialApiCalls.Builder.getMediaSearchResults(searchTerm, pageNumber);
    return data;
});
export default function MediaSearchModal({ setIsMediaSearchModalOpen, setBackgroundImage, }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [selected, setSelected] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [allResults, setAllResults] = useState([]);
    const { data, run: search } = useAsync({
        promiseFn: getMediaSearchResults,
        deferFn: ([searchTerm, pageNumber]) => getMediaSearchResults({ searchTerm, pageNumber }),
    });
    useEffect(() => {
        var _a;
        if ((_a = data === null || data === void 0 ? void 0 : data.searchResults) === null || _a === void 0 ? void 0 : _a.length) {
            setAllResults([...allResults, ...data.searchResults]);
        }
    }, [data === null || data === void 0 ? void 0 : data.searchResults]);
    return (React.createElement(TModal, { title: "Unsplash", onClose: () => setIsMediaSearchModalOpen(false) },
        React.createElement(React.Fragment, null,
            React.createElement(ModalContainer, null,
                React.createElement(SearchContainer, null,
                    React.createElement(TTextInput, { style: { flexGrow: 1, height: 40, paddingLeft: 4 }, autoFocus: true, value: searchTerm, onChange: e => {
                            setSearchTerm(e.target.value);
                        } }),
                    React.createElement(TButton, { onClick: () => {
                            setAllResults([]);
                            setSelected("");
                            setPageNumber(1);
                            search(searchTerm, 1);
                        } }, "Search")),
                React.createElement(ResultsContainer, null,
                    allResults.map((sr, i) => {
                        return (React.createElement(MediaCard, { setSelected: setSelected, key: i, src: sr.src, selected: selected === sr.src, username: sr.username, name: sr.name }));
                    }),
                    !!allResults.length && allResults.length < (data === null || data === void 0 ? void 0 : data.total) && allResults.length < 120 && (React.createElement(LoadMore, { key: "loadmore", onClick: () => {
                            setPageNumber(pageNumber + 1);
                            search(searchTerm, pageNumber + 1);
                        } }, "Load more...")))),
            React.createElement(ButtonsContainer, null,
                React.createElement("span", null,
                    "Photos by",
                    " ",
                    React.createElement("a", { target: "_blank", rel: "noreferrer", href: "https://unsplash.com/?utm_source=tourial&utm_medium=referral" }, "Unsplash")),
                React.createElement(TButton, { disabled: !selected, onClick: () => {
                        var _a;
                        setBackgroundImage(selected);
                        TourialApiCalls.Builder.postMediaSearchDownload((_a = allResults.find(r => r.src === selected)) === null || _a === void 0 ? void 0 : _a.downloadLocation);
                        setIsMediaSearchModalOpen(false);
                    } }, "Done")))));
}
const MediaCard = ({ src, selected, setSelected, username, name }) => {
    const selectHandler = () => setSelected(selected ? null : src);
    return (React.createElement(MediaCardContainer, { selected: selected },
        React.createElement(Img, { src: src, onClick: selectHandler }),
        selected && (React.createElement("img", { src: CheckCircleIcon, alt: "checkmark in circle", width: 24, style: { position: "absolute", top: 12, right: 12 } })),
        React.createElement(A, { target: "_blank", rel: "noreferrer", href: `https://unsplash.com/@${username}?utm_source=tourial&utm_medium=referral` }, name)));
};
const A = styled.a `
  visibility: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: white;
  :hover,
  :visited,
  :active {
    color: white;
  }
`;
const MediaCardContainer = styled.div `
  width: 200.89px;
  height: 144px;
  ${props => (props.selected ? `border: 1px solid ${newTheme.colors.blue300}` : "")};
  border-radius: 4px;
  position: relative;
  &:hover > a {
    visibility: visible;
  }
`;
const Img = styled.img `
  cursor: pointer;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;
const ModalContainer = styled.div `
  width: 767px;
  height: 468px;
  padding: 24px;
`;
const ResultsContainer = styled.div `
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  justify-content: center;
  overflow-y: scroll;
  height: calc(100% - 41px);
`;
const SearchContainer = styled.div `
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  align-items: center;
  margin-bottom: 24px;
`;
const ButtonsContainer = styled.div `
  height: 88px;
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${newTheme.colors.grey300};
`;
const LoadMore = styled.div `
  width: 100%;
  cursor: pointer;
  color: ${newTheme.colors.blue500};
  height: 40px;
`;
