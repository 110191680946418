import { useFormOnStep, useSetFormOnStep, useSetShouldRenderForm } from "../../../../redux/selectors/builder-selectors";
import { useActiveView, usePushHistory } from "../../../../redux/selectors/redux-selectors";
import React, { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
import { EditorAccordion } from "../../../pages/builder/editor/editor-accordion";
import { TDropdownSelect } from "../../../styled/t-dropdown-select";
import { TCheckbox } from "../../../styled/t-checkbox";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import ShareExportIcon from "../../../../../assets/images/v3/share-export.svg";
import { BASE_URL_CLIENT } from "../../../../../../shared/types/client-base-url";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import { ControlContainer, ControlLabel } from "../../../styled/editor-components";
export function FormV3StepInteractionEditor() {
    const setFormOnStep = useSetFormOnStep();
    const pushHistory = usePushHistory();
    const activeView = useActiveView();
    const formOnStep = useFormOnStep(activeView);
    const [formOptions, setFormOptions] = useState([]);
    const [formId, setFormId] = useState((formOnStep === null || formOnStep === void 0 ? void 0 : formOnStep.formId) || "");
    const [isGated, setIsGated] = useState((formOnStep === null || formOnStep === void 0 ? void 0 : formOnStep.isGated) || false);
    const [isEnabled, setIsEnabled] = useState((formOnStep === null || formOnStep === void 0 ? void 0 : formOnStep.isEnabled) || false);
    const [init, setInit] = useState(true);
    const setShouldRenderForm = useSetShouldRenderForm();
    // update form interaction on step
    useEffect(() => {
        setShouldRenderForm(true);
        if (init) {
            setInit(false);
        }
        else {
            pushHistory();
            setFormOnStep(formId, isGated, isEnabled);
        }
        return () => setShouldRenderForm(false);
    }, [isGated, isEnabled, formId]);
    const { isLoading } = useAsync({
        promiseFn: TourialApiCalls.Forms.getForms,
        onResolve: (forms) => {
            setFormOptions(forms.map(f => {
                return { label: f.name, value: f._id };
            }));
        },
    });
    return (React.createElement("div", { style: { height: "calc(100% - 31px)", overflowY: "scroll" } },
        React.createElement(EditorAccordion, { title: "Add form", isEnabled: isEnabled, isHidden: false, isAlwaysOpen: true, onToggle: () => {
                if (isEnabled) {
                    setIsEnabled(!isEnabled);
                }
                else if (formId) {
                    // get the form here to make sure it hasn't been deleted
                    TourialApiCalls.Forms.getFormById(formId)
                        .then((form) => {
                        if (form) {
                            setIsEnabled(true);
                        }
                    })
                        .catch(e => {
                        console.error("failed to get form by id when attempting to enable in builder. form was probably deleted", "formId:", formId, e);
                    });
                }
            } },
            !isLoading && (React.createElement(TDropdownSelect, { isSearchable: true, selectedValue: formId, options: formOptions, onChange: (e) => {
                    setFormId(e.value);
                } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Gated"),
                React.createElement(TCheckbox, { checked: isGated, onChange: () => setIsGated(!isGated) })),
            React.createElement(FinePrint, null, "Potential buyers may not proceed with the rest of the tourial unless they fill out this form."),
            React.createElement(CreateFormButtonContainer, null,
                React.createElement(TButton, { style: { width: "100%" }, variant: TButtonVariants.TERTIARY, icon: ShareExportIcon, onClick: () => {
                        window.open(`${BASE_URL_CLIENT}/forms`, "_blank");
                    } }, "Create new form")))));
}
const FinePrint = styled.p `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey800};
  margin-bottom: 16px;
`;
const CreateFormButtonContainer = styled.div `
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
