import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDCAddTrackToModule, useDCModule, useDCDeleteModule, useDCRemoveTrackFromModule, useDCSetModule, useDCSetModuleSlug, useDCUpdateTrackInModule, useDCDefaultModuleTypes, useDCGetModuleItemCount, useDCRemoveItemFromTrack, useDCAddItemsToTrack, useDCReorderTracksByDragAndDrop, } from "../hooks/dc-module-hooks";
import { DCCMSection, DCCMSectionSpacer } from "./components/dc-cm-section";
import { DCBuilderMode, DCCMPageType, DCIconType, DCModuleType, } from "../../../../../../shared/types/demo-center";
import { Redirect, useParams } from "react-router-dom";
import { TMessageBox, TMessageBoxVariants } from "../../../styled/t-message-box";
import { DCCMNewTrackInput } from "./components/dc-cm-new-track-input";
import { DCCMTrackManager } from "./components/dc-cm-track-manager";
import { useDCGetItem, useDCSetItem, useDCUpdateItemId } from "../hooks/dc-item-hooks";
import { MainAppPagePaths } from "../../../../../../shared/types/app-events";
import { useDCId } from "../hooks/dc-hooks";
import { DCCMInfoEditor } from "./components/dc-cm-info-editor";
import styled from "styled-components";
import { DCConfirmDeleteModal } from "./modals/dc-confirm-delete-modal";
import { DCCMModuleTypeSelector } from "./components/dc-cm-module-type-selector";
import { dcFadeIn } from "../helpers/dc-style-helpers";
import { DCCMAddContentModal } from "./modals/dc-cm-add-content-modal";
import { useDCGetBuilderWarning, useDCLivePreviewHelper, useDCBuilderGoToPage } from "../hooks/dc-builder-hooks";
import { defaultDemoCenter } from "../../../../../../shared/objects/default-demo-center";
import { DCCMModuleTemplateChooser } from "./components/dc-cm-module-template-chooser";
export const DCCMModuleEditor = () => {
    var _a;
    const [hasBeenTouched, setHasBeenTouched] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [addModalTrackSlug, setAddModalTrackSlug] = useState(null);
    const { moduleId } = useParams();
    const democenterId = useDCId();
    const { sidebar, badge } = defaultDemoCenter.config;
    const module = useDCModule(moduleId);
    const goToPage = useDCBuilderGoToPage();
    const getItem = useDCGetItem();
    const setItem = useDCSetItem();
    const setModule = useDCSetModule();
    const deleteModule = useDCDeleteModule();
    const setSlug = useDCSetModuleSlug();
    const updateItemId = useDCUpdateItemId();
    const addTrack = useDCAddTrackToModule(module);
    const deleteTrack = useDCRemoveTrackFromModule(module);
    const updateTrack = useDCUpdateTrackInModule(module);
    const addItemsToTrack = useDCAddItemsToTrack(module);
    const removeItemFromTrack = useDCRemoveItemFromTrack(module);
    const reorderTracks = useDCReorderTracksByDragAndDrop(module);
    const itemCount = useDCGetModuleItemCount();
    const changeType = (type) => setModule(Object.assign(Object.assign({}, module), { type }));
    const applyTemplate = (template) => setModule(module, template);
    const isWelcomeModule = moduleId === DCCMPageType.WELCOME;
    const { title, description, tracks, type, prompt, slug } = module || {};
    const isSingleOrMultiple = [DCModuleType.SINGLE, DCModuleType.MULTIPLE].includes(type);
    const isBooleanOrScale = [DCModuleType.BOOLEAN, DCModuleType.SCALE].includes(type);
    const isNoPrompt = type === DCModuleType.NO_PROMPT;
    const isNewModule = !title;
    const hasItems = !!itemCount(module);
    const getWarning = useDCGetBuilderWarning();
    const warning = getWarning(module === null || module === void 0 ? void 0 : module.slug);
    useDCDefaultModuleTypes(module);
    useDCLivePreviewHelper(slug);
    useEffect(() => {
        setHasBeenTouched(!isNewModule);
    }, [slug]);
    const commit = () => {
        if (title) {
            setHasBeenTouched(true);
            setSlug(slug, title);
        }
    };
    const askToDeleteModule = () => {
        setDeleteModal({
            title: "Delete Topic",
            message: "Are you sure you want to delete this topic?",
            close: () => setDeleteModal(null),
            confirm: () => {
                deleteModule(slug);
                goToPage(DCBuilderMode.MANAGE);
            },
        });
    };
    const askToDeleteTrack = (track) => {
        setDeleteModal({
            title: "Delete Track",
            message: `Are you sure you want to delete '${track.title}'?`,
            close: () => setDeleteModal(null),
            confirm: () => {
                deleteTrack(track.slug);
                setDeleteModal(null);
            },
        });
    };
    const askToRemoveItem = (track, item) => {
        setDeleteModal({
            title: "Remove Item",
            message: `Are you sure you want to remove '${item.title}'?`,
            close: () => setDeleteModal(null),
            confirm: () => {
                removeItemFromTrack(track.slug, item.id);
                setDeleteModal(null);
            },
        });
    };
    const askToRemoveImageFromTrack = (track) => {
        setDeleteModal({
            title: "Remove Image From Track",
            message: `Are you sure you want to remove the image from '${track.title}'?`,
            confirmText: "Remove Image",
            close: () => setDeleteModal(null),
            confirm: () => {
                updateTrack(Object.assign(Object.assign({}, track), { img: null }));
                setDeleteModal(null);
            },
        });
    };
    return module ? (React.createElement(React.Fragment, null,
        React.createElement(DCCMSection, { icon: isWelcomeModule ? DCIconType.PIN : DCIconType.RIGHT_ARROW, title: title, onTitleChange: title => setModule(Object.assign(Object.assign({}, module), { title })), titlePlaceholder: "Name your new topic or choose a template", onTitleCommit: commit, isEditingTitle: isNewModule, onDelete: askToDeleteModule }, isNewModule && React.createElement(DCCMModuleTemplateChooser, { onClick: applyTemplate })),
        hasBeenTouched && !isWelcomeModule && (React.createElement(FadeContainer, null,
            React.createElement(DCCMSectionSpacer, null),
            React.createElement(DCCMSection, { icon: DCIconType.PROMPT, noTitle: true },
                type !== DCModuleType.NO_PROMPT && (React.createElement(React.Fragment, null,
                    React.createElement(DCCMInfoEditor, { isBold: true, value: prompt, autoFocus: !prompt, onChange: e => setModule(Object.assign(Object.assign({}, module), { prompt: e })), placeholder: "Add a prompt e.g. 'What part of our tool do you want to explore?'" }),
                    React.createElement(DCCMInfoEditor, { textarea: true, value: description, onChange: e => setModule(Object.assign(Object.assign({}, module), { description: e })), placeholder: "Add a description (optional)" }))),
                React.createElement(DCCMModuleTypeSelector, { size: !type ? "large" : "small", onClick: type => changeType(type), selectedType: type, areBooleanAndScaleDisabled: hasItems || isWelcomeModule || (isSingleOrMultiple && tracks.length > 0), isNoPromptDisabled: (isSingleOrMultiple && tracks.length > 1) || (isBooleanOrScale && hasItems), areSingleAndMultipleDisabled: isWelcomeModule || (isBooleanOrScale && hasItems) })))),
        type && (React.createElement(FadeContainer, null,
            React.createElement(DCCMSectionSpacer, null),
            React.createElement(DCCMSection, { icon: DCIconType.NETWORK, noTitle: true },
                isWelcomeModule && (React.createElement(TMessageBox, { variant: TMessageBoxVariants.SECONDARY, message: "We recommend you keep your Welcome content brief to orient visitors. You may show a preview of your first piece of content on the 'Intro Page.'", size: "small" })),
                React.createElement(DragDropContext, { onDragEnd: reorderTracks },
                    React.createElement(Droppable, { droppableId: "tracks" }, provided => (React.createElement(TracksContainer, Object.assign({ ref: provided.innerRef }, provided.droppableProps),
                        tracks.map((t, i) => {
                            const key = `${slug}-${t.slug}`;
                            const warning = getWarning(key);
                            return (React.createElement(Draggable, { key: key, draggableId: key, index: i, isDragDisabled: !isSingleOrMultiple }, provided => (React.createElement(TracksContainer, null,
                                React.createElement(DCCMTrackManager, { track: t, onTrackDelete: () => askToDeleteTrack(t), onTrackUpdate: t => updateTrack(t), onAddItem: () => setAddModalTrackSlug(t.slug), onRemoveItem: i => askToRemoveItem(t, i), onImageRemove: () => askToRemoveImageFromTrack(t), getItem: getItem, disabled: isBooleanOrScale && i === tracks.length - 1 && t.items.length === 0, canDelete: !isBooleanOrScale && !isNoPrompt, canModify: !isNoPrompt, badgeConfig: badge, sidebarConfig: sidebar, setItem: setItem, onItemCommit: i => updateItemId(i), draggableProps: provided.draggableProps, dragHandleProps: provided.dragHandleProps, ref: provided.innerRef }),
                                warning && (React.createElement(TMessageBox, { variant: TMessageBoxVariants.WARNING, message: warning, size: "medium" }))))));
                        }),
                        provided.placeholder)))),
                warning && !!tracks.length && (React.createElement(TMessageBox, { variant: TMessageBoxVariants.WARNING, message: warning, size: "medium" })),
                !!type && (type === DCModuleType.MULTIPLE || type === DCModuleType.SINGLE) && (React.createElement(DCCMNewTrackInput, { onAddTrack: addTrack, autoFocus: !tracks.length }))))),
        deleteModal && React.createElement(DCConfirmDeleteModal, Object.assign({}, deleteModal)),
        addModalTrackSlug && (React.createElement(DCCMAddContentModal, { currentItems: (_a = tracks.find(t => t.slug === addModalTrackSlug)) === null || _a === void 0 ? void 0 : _a.items, onClose: () => setAddModalTrackSlug(null), onAddItems: items => addItemsToTrack(addModalTrackSlug, items) })))) : (React.createElement(Redirect, { to: `${MainAppPagePaths.DEMOCENTER_BUILDER}/${democenterId}` }));
};
const FadeContainer = styled.div `
  display: flex;
  gap: 24px;
  flex-direction: column;
  opacity: 1;
  animation: ${dcFadeIn} 0.3s ease-in-out;
`;
const TracksContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
