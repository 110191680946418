import { TooltipArrowPosition } from "../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import { ToolType } from "../../../../../../shared/types/tool-data-types/common";
export function getAutoTooltipTemplate(toolData, theme) {
    const t = Object.assign({ id: "", name: "Timed Tooltip", type: ToolType.AutoTooltip, sizeAndPosition: { x: 0, y: 0, width: 0, height: 0 }, duration: 5, delay: 0, position: { x: 25, y: 55 }, bubble: {
            size: { width: 208, height: 218 },
            background: theme.bubble.background,
            logo: Object.assign(Object.assign({}, theme.bubble.logo), { isEnabled: false }),
            headline: Object.assign(Object.assign({}, theme.bubble.headline), { isEnabled: false, text: "Headline" }),
            body: Object.assign(Object.assign({}, theme.bubble.body), { isEnabled: true, text: `Guide viewers to a specific feature of your software or to an action you'd like them to take.` }),
            arrow: {
                isEnabled: true,
                position: TooltipArrowPosition.BOTTOM_CENTER,
                size: theme.bubble.arrow.size,
            },
        } }, toolData);
    return t;
}
