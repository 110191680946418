import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { EditorAccordion } from "../../../../pages/builder/editor/editor-accordion";
import { cloneDeep } from "lodash";
import { LabelledTextInput } from "../../../../pages/builder/editor/atomic/labelled-text-input";
import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ArrowPositionSelector } from "../../../../pages/builder/editor/atomic/arrow-position-selector";
import { TooltipArrowPosition, } from "../../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import TooltipIcon from "../../../../../../assets/images/v3/editor-accordian-tooltip-icon.svg";
import LogoIcon from "../../../../../../assets/images/v3/editor-accordian-logo-icon.svg";
import HeadlineIcon from "../../../../../../assets/images/v3/editor-accordian-headline-icon.svg";
import BodyIcon from "../../../../../../assets/images/v3/editor-accordian-body-icon.svg";
import { ShapeSelectorLarge } from "../../../../pages/builder/editor/atomic/shape-selector-large";
import { ColorPicker } from "../../../../pages/builder/editor/atomic/color-picker";
import { TCheckbox } from "../../../../styled/t-checkbox";
import { ShirtSizeSelector } from "../../../../pages/builder/editor/atomic/shirt-size-selector";
import { LogoUploadSection } from "../../../modal/editor/components/logo-upload-section";
import { bodyFontSizeDropdownOptions, headlineFontSizeDropdownOptions } from "../../../../../helpers/dropdown-options";
import { useFocusTourialEditorField } from "../../../../../hooks/use-focus-field";
import { TourialEditorFocusFields } from "../../../../../types/definitions";
import { FontSelector } from "../../../tooltip-v2/editor/components/font-selector";
import { THorizontalSlider } from "../../../../styled/t-horizontal-slider";
import { ControlContainer, ControlLabel, QuillContainer } from "../../../../styled/editor-components";
export function AutoTooltipStyleSection(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    const [openId, setOpenId] = useState("bubble-accordion");
    useFocusTourialEditorField(setOpenId);
    const reactQuillRef = useRef();
    return (React.createElement(Section, null,
        React.createElement(EditorAccordion, { id: "bubble-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: TooltipIcon, title: "Tooltip", isEnabled: true, isInitiallyOpen: true },
            React.createElement(ShapeSelectorLarge, { shape: tool.bubble.background.shape, onChange: (shape) => {
                    const t = cloneDeep(tool);
                    t.bubble.background.shape = shape;
                    updateTool(t);
                }, disableSquircle: true }),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Background Color"),
                React.createElement(ColorPicker, { color: tool.bubble.background.backgroundColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.bubble.background.backgroundColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Border Color"),
                React.createElement(ColorPicker, { color: tool.bubble.background.borderColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.bubble.background.borderColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Shadow"),
                React.createElement(TCheckbox, { checked: tool.bubble.background.isShadowEnabled, onChange: (isChecked) => {
                        const t = cloneDeep(tool);
                        t.bubble.background.isShadowEnabled = isChecked;
                        updateTool(t);
                    } })),
            React.createElement(LineSpacer, { style: { marginTop: 8 } }),
            React.createElement(ArrowPositionSelector, { selected: tool.bubble.arrow.position, arrowEnabled: tool.bubble.arrow.isEnabled, onChange: (pos) => {
                    const t = cloneDeep(tool);
                    if (pos === TooltipArrowPosition.NONE) {
                        t.bubble.arrow.isEnabled = false;
                    }
                    else {
                        t.bubble.arrow.isEnabled = true;
                        t.bubble.arrow.position = pos;
                    }
                    updateTool(t);
                } }),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Arrow Size"),
                React.createElement(ShirtSizeSelector, { size: tool.bubble.arrow.size, onChange: (size) => {
                        const t = cloneDeep(tool);
                        t.bubble.arrow.size = size;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Tooltip Width"),
                React.createElement(THorizontalSlider, { min: 208, max: 410, value: tool.bubble.size.width, onChange: e => {
                        const t = cloneDeep(tool);
                        t.bubble.size.width = e;
                        updateTool(t);
                    } }))),
        React.createElement(EditorAccordion, { id: "bubble-logo-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: LogoIcon, title: "Logo", isEnabled: true, isHidden: !tool.bubble.logo.isEnabled },
            React.createElement(LogoUploadSection, { logo: tool.bubble.logo, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.bubble.logo = e;
                    updateTool(t);
                } })),
        tool.bubble.headline.isEnabled && (React.createElement(EditorAccordion, { id: TourialEditorFocusFields.BUBBLE_HEADLINE_TEXT, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: HeadlineIcon, title: "Headline", isEnabled: tool.bubble.headline.isEnabled },
            React.createElement(LabelledTextInput, { name: "Headline text", value: tool.bubble.headline.text, style: { marginBottom: 8 }, onChange: (e) => {
                    const t = cloneDeep(tool);
                    t.bubble.headline.text = e.target.value;
                    updateTool(t);
                } }),
            React.createElement(FontSelector, { text: tool.bubble.headline, sizeOptions: headlineFontSizeDropdownOptions, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.bubble.headline = e;
                    updateTool(t);
                } }))),
        tool.bubble.body.isEnabled && (React.createElement(EditorAccordion, { id: TourialEditorFocusFields.BUBBLE_BODY_TEXT, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: BodyIcon, title: "Body", isEnabled: tool.bubble.body.isEnabled },
            React.createElement(QuillContainer, null,
                React.createElement(ControlLabel, null, "Body text"),
                React.createElement(ReactQuill, { key: tool.id + "quill-editor", theme: "snow", value: tool.bubble.body.text, onChange: value => {
                        const t = cloneDeep(tool);
                        t.bubble.body.text = value;
                        updateTool(t);
                    }, ref: reactQuillRef, style: {
                        backgroundColor: newTheme.colors.white,
                    }, modules: {
                        toolbar: [
                            ["bold", "italic", "underline"],
                            [{ list: "ordered" }, { list: "bullet" }],
                        ],
                    } })),
            React.createElement(FontSelector, { text: tool.bubble.body, sizeOptions: bodyFontSizeDropdownOptions, hideTextStyle: true, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.bubble.body = e;
                    updateTool(t);
                } })))));
}
const LineSpacer = styled.div `
  border-top: 1px solid ${newTheme.colors.grey300};
  margin-bottom: 20px;
`;
const Section = styled.div `
  height: calc(100% - 31px);
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
