import React from "react";
import styled from "styled-components";
export const DCImagePlayer = ({ item }) => {
    const { url } = item;
    return (React.createElement(ImageContainer, null,
        React.createElement("img", { src: url })));
};
const ImageContainer = styled.div `
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
  }
`;
