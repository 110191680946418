import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { ShirtSize } from "../../../../../../../shared/types/tool-data-types/common";
export const ShirtSizeSelector = (props) => {
    const { onChange, size } = props;
    const [selectedSize, setSelectedSize] = useState(ShirtSize.MEDIUM);
    const handleClick = (size) => {
        setSelectedSize(size);
        onChange(size);
    };
    useEffect(() => {
        setSelectedSize(size);
    }, [size]);
    return (React.createElement(Div, null,
        React.createElement(ShirtSizeSelectorContainer, null,
            React.createElement(SmallBubble, { selected: true, onClick: () => {
                    handleClick(ShirtSize.SMALL);
                } }),
            React.createElement(Spacer, { selected: selectedSize === ShirtSize.MEDIUM || selectedSize === ShirtSize.LARGE }),
            React.createElement(MediumBubble, { selected: selectedSize === ShirtSize.MEDIUM || selectedSize === ShirtSize.LARGE, onClick: () => {
                    handleClick(ShirtSize.MEDIUM);
                } }),
            React.createElement(Spacer, { selected: selectedSize === ShirtSize.LARGE }),
            React.createElement(LargeBubble, { selected: selectedSize === ShirtSize.LARGE, onClick: () => {
                    handleClick(ShirtSize.LARGE);
                } }))));
};
const Div = styled.div `
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  align-items: center;
`;
const ShirtSizeSelectorContainer = styled.div `
  display: flex;
  width: 180px;
`;
const SmallBubble = styled.button `
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${props => (props.selected ? newTheme.colors.blue : newTheme.colors.blue100)};
  border: 1px solid ${newTheme.colors.blue};
  margin-top: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.blue400};
  }
`;
const MediumBubble = styled.button `
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${props => (props.selected ? newTheme.colors.blue : newTheme.colors.blue100)};
  border: 1px solid ${newTheme.colors.blue};
  margin-top: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.blue400};
  }
`;
const LargeBubble = styled.button `
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${props => (props.selected ? newTheme.colors.blue : newTheme.colors.blue100)};
  border: 1px solid ${newTheme.colors.blue};
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.blue400};
  }
`;
const Spacer = styled.div `
  flex: 1;
  height: 4px;
  background-color: ${props => (props.selected ? newTheme.colors.blue : newTheme.colors.blue100)};
  border: 1px solid ${newTheme.colors.blue};
  border-left: none;
  border-right: none;
  margin: 14px -1px 0 -1px;
  z-index: 1;
`;
