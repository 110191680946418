import { debounce } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import { TourialAppEventTypes } from "../../../../../../shared/types/app-events";
import { usePostAppEvent } from "../../../../hooks/use-post-app-event";
import { useIsEmbedHeaderEnabled, useIsMobileVariantEnabled, useMobileEmbedButton, useUpdateTourialPartial, } from "../../../../redux/selectors/redux-selectors";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { TToggleSwitch } from "../../../styled/t-toggle-switch";
import { newTheme } from "../../../styled/theme";
import ColorSwatchIcon from "../../../../../assets/images/v3/color-swatch-icon.svg";
import NavbarIcon from "../../../../../assets/images/v3/navbar-outline-icon.svg";
import { TextInput } from "../editor/atomic/text-input";
import { TDropdownSelect } from "../../../styled/t-dropdown-select";
import { fontOptions, fontWeightOptions, mobileButtonShadowTypes } from "../../../../helpers/dropdown-options";
import { ColorPicker } from "../editor/atomic/color-picker";
import { NumberInput } from "../editor/atomic/number-input";
import { ShapeSelector } from "../editor/atomic/shape-selector";
import { ModalAccordion, ModalAccordionContainer, ModalAccordionContentTitle } from "./modal-accordion";
import { makeEmbedCode } from "../../../../legacy/components/builder/navbar/embed-modal";
import { getRadiusFromShape, getShapeFromRadius } from "../../../../../../shared/functions/helpers";
import { useIsMobileButtonDisabled } from "../../../../redux/selectors/builder-selectors";
export const EmbedTab = (props) => {
    const { tourialSlug, baseUrlWithSubdomain, embedCodeHasChanged } = props;
    const isMobileVariantEnabled = useIsMobileVariantEnabled();
    const isMobileButtonDisabled = useIsMobileButtonDisabled();
    const isEmbedHeaderEnabled = useIsEmbedHeaderEnabled();
    const updateTourial = useUpdateTourialPartial();
    const [isLightboxEnabled, setIsLightboxEnabled] = useState(false);
    const mobileEmbedButton = useMobileEmbedButton();
    const postAppEvent = usePostAppEvent();
    const [wasCopied, setWasCopied] = useState(false);
    const copyToClipboard = debounce(() => {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(makeEmbedCode(baseUrlWithSubdomain, tourialSlug, isMobileVariantEnabled, isLightboxEnabled, mobileEmbedButton, isEmbedHeaderEnabled, isMobileButtonDisabled)).then(function () {
            postAppEvent(isLightboxEnabled ? TourialAppEventTypes.LIGHTBOX_CODE_COPIED : TourialAppEventTypes.EMBED_CODE_COPIED);
            setWasCopied(true);
        }, function (err) {
            console.error("Failed to copy text to clipboard: ", err);
        });
    }, 500);
    return (React.createElement(EmbedTabContainer, { id: "_test_publish-embed-tab" },
        React.createElement(MobileSelectContainer, null,
            React.createElement("img", { src: NavbarIcon }),
            React.createElement("h3", null, "Show Navbar"),
            React.createElement(TToggleSwitch, { id: "_test_embed-header-switch", enabled: isEmbedHeaderEnabled, size: "small", onChange: () => {
                    updateTourial({
                        isEmbedHeaderEnabled: !isEmbedHeaderEnabled,
                    });
                    embedCodeHasChanged();
                } })),
        React.createElement(MobileSelectDescription, null, "Toggling this on will display the navbar in your embedded Tourials. Your navbar may be edited in the appearance tab."),
        React.createElement(EmbedCodeContainer, null,
            React.createElement(TDropdownSelect, { isSearchable: false, selectedValue: isLightboxEnabled, style: { width: 185 }, onChange: (e) => {
                    setIsLightboxEnabled(e.value);
                }, options: [
                    { value: false, label: "Embed in Page" },
                    { value: true, label: "Tourial Lightbox" },
                ] }),
            isLightboxEnabled && (React.createElement(React.Fragment, null,
                React.createElement("span", null, "Add the class name below on the HTML tag that will trigger the lightbox."),
                React.createElement(EmbedTextarea, { style: { height: 35, marginBottom: 8 } },
                    React.createElement("code", null,
                        "tourial-lightbox-trigger-",
                        tourialSlug)))),
            React.createElement("span", null, "Paste into your webpage where you want the Tourial to display:"),
            React.createElement(EmbedTextarea, null,
                React.createElement("code", null, makeEmbedCode(baseUrlWithSubdomain, tourialSlug, isMobileVariantEnabled, isLightboxEnabled, mobileEmbedButton, isEmbedHeaderEnabled, isMobileButtonDisabled))),
            React.createElement(EmbedButtonContainer, null,
                wasCopied && React.createElement("span", { id: "_test_embed-code-copied" }, "Copied!"),
                React.createElement(TButton, { id: "_test_embed-code-copy-button", variant: TButtonVariants.SECONDARY, size: "medium", onClick: () => {
                        copyToClipboard();
                        setWasCopied(true);
                    } }, "Copy Embed Code")))));
};
export const MobileEmbedButtonCustomizationEditor = ({ notifyEmbedCodeChange, }) => {
    const updateTourial = useUpdateTourialPartial();
    const button = useMobileEmbedButton() || getDefaultMobileButton();
    const setButton = (button) => {
        updateTourial({ mobileEmbedButton: button });
        notifyEmbedCodeChange === null || notifyEmbedCodeChange === void 0 ? void 0 : notifyEmbedCodeChange();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MobileEmbedButtonContainer, { id: "_test_mobile-button-customization" },
            React.createElement(MobileEmbedButtonComponent, { button: button })),
        React.createElement(ModalAccordionContainer, null,
            React.createElement(ModalAccordion, { title: "Button style", icon: ColorSwatchIcon },
                React.createElement(ModalAccordionContentTitle, null, "Text"),
                React.createElement(TextInput, { style: { width: "280px" }, name: "Text", value: button.text, onChange: (e) => {
                        const newButton = Object.assign(Object.assign({}, button), { text: e.target.value });
                        setButton(newButton);
                    } }),
                React.createElement(ModalAccordionContentTitle, null, "Font"),
                React.createElement("div", { style: { display: "flex", height: "38px" } },
                    React.createElement(TDropdownSelect, { isSearchable: true, style: { marginTop: 0, width: "280px" }, selectedValue: button.fontFamily, onChange: (e) => {
                            const fontFamily = e.value;
                            const newButton = Object.assign(Object.assign({}, button), { fontFamily });
                            setButton(newButton);
                        }, options: fontOptions }),
                    React.createElement("div", { style: { flex: 1, padding: "2px 0 0 16px" } },
                        React.createElement(ColorPicker, { color: button.fontColor, onChange: e => {
                                const newButton = Object.assign(Object.assign({}, button), { fontColor: e });
                                setButton(newButton);
                            } }))),
                React.createElement("div", { style: { display: "flex" } },
                    React.createElement("div", { style: { marginRight: "16px" } },
                        React.createElement(ModalAccordionContentTitle, null, "Size"),
                        React.createElement(NumberInput, { min: 1, max: 100, step: 1, value: button.fontSize, onChange: (e) => {
                                const fontSize = e.target.value;
                                const newButton = Object.assign(Object.assign({}, button), { fontSize });
                                setButton(newButton);
                            }, style: { height: "38px" } })),
                    React.createElement("div", null,
                        React.createElement(ModalAccordionContentTitle, null, "Weight"),
                        React.createElement(TDropdownSelect, { isSearchable: false, style: { marginTop: 0, width: "100px" }, selectedValue: button.fontWeight, onChange: (e) => {
                                const fontWeight = e.value;
                                const newButton = Object.assign(Object.assign({}, button), { fontWeight });
                                setButton(newButton);
                            }, options: fontWeightOptions }))),
                React.createElement("div", { style: { display: "flex" } },
                    React.createElement("div", { style: { marginRight: "16px" } },
                        React.createElement(ModalAccordionContentTitle, null, "Width"),
                        React.createElement(NumberInput, { min: 1, max: 500, step: 1, value: button.width, onChange: (e) => {
                                const width = e.target.value;
                                const newButton = Object.assign(Object.assign({}, button), { width });
                                setButton(newButton);
                            }, style: { height: "38px" } })),
                    React.createElement("div", null,
                        React.createElement(ModalAccordionContentTitle, null, "Height"),
                        React.createElement(NumberInput, { min: 1, max: 500, step: 1, value: button.height, onChange: (e) => {
                                const height = e.target.value;
                                const newButton = Object.assign(Object.assign({}, button), { height });
                                setButton(newButton);
                            }, style: { height: "38px" } }))),
                React.createElement(ModalAccordionContentTitle, null, "Background"),
                React.createElement("div", { style: { flex: 1 } },
                    React.createElement(ColorPicker, { color: button.backgroundColor, onChange: e => {
                            const newButton = Object.assign(Object.assign({}, button), { backgroundColor: e });
                            setButton(newButton);
                        } })),
                React.createElement(ModalAccordionContentTitle, null, "Border"),
                React.createElement("div", { style: { display: "flex" } },
                    React.createElement(NumberInput, { min: 0, max: 100, step: 1, value: button.borderWidth, onChange: (e) => {
                            const borderWidth = e.target.value;
                            const newButton = Object.assign(Object.assign({}, button), { borderWidth });
                            setButton(newButton);
                        }, style: { width: "65px" } }),
                    React.createElement("div", { style: { flex: 1, padding: "2px 0 0 16px" } },
                        React.createElement(ColorPicker, { color: button.borderColor, onChange: e => {
                                const newButton = Object.assign(Object.assign({}, button), { borderColor: e });
                                setButton(newButton);
                            } }))),
                React.createElement(ModalAccordionContentTitle, null, "Shape"),
                React.createElement(ShapeSelector, { onChange: (e) => {
                        const borderRadius = getRadiusFromShape(e);
                        const newButton = Object.assign(Object.assign({}, button), { borderRadius });
                        setButton(newButton);
                    }, shape: getShapeFromRadius(button.borderRadius) }),
                React.createElement(ModalAccordionContentTitle, null, "Shadow"),
                React.createElement(TDropdownSelect, { isSearchable: false, style: { marginTop: 0, width: "280px" }, selectedValue: button.shadowType, onChange: (e) => {
                        const shadowType = e.value;
                        const newButton = Object.assign(Object.assign({}, button), { shadowType });
                        setButton(newButton);
                    }, options: mobileButtonShadowTypes })))));
};
const MobileEmbedButtonComponent = ({ button }) => {
    return (React.createElement("button", { style: {
            fontFamily: button.fontFamily,
            fontWeight: button.fontWeight,
            fontSize: button.fontSize + "px",
            color: button.fontColor,
            backgroundColor: button.enableBackground ? button.backgroundColor : null,
            borderStyle: "solid",
            borderWidth: button.enableBorder ? button.borderWidth + "px" : null,
            borderColor: button.enableBorder ? button.borderColor : null,
            borderRadius: button.borderRadius + "px",
            boxShadow: userShadows[button.shadowType],
            width: button.width + "px",
            height: button.height + "px",
        } }, button.text));
};
function getDefaultMobileButton() {
    return {
        text: "Insert Text",
        fontFamily: "Open Sans",
        fontWeight: null,
        fontSize: 15,
        fontColor: "black",
        enableBackground: true,
        backgroundColor: "white",
        enableBorder: true,
        borderWidth: 2,
        borderColor: "black",
        borderRadius: 8,
        width: 150,
        height: 50,
        shadowType: "none",
    };
}
export const userShadows = {
    none: "0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0)",
    shallowest: "0px 1px 1.5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.24)",
    shallow: "0px 3px 3px rgba(0, 0, 0, 0.16), 0px 3px 3px rgba(0, 0, 0, 0.23)",
    medium: "0px 10px 10px rgba(0, 0, 0, 0.19), 0px 6px 3px rgba(0, 0, 0, 0.23)",
    deep: "0px 14px 14px rgba(0, 0, 0, 0.25), 0px 10px 5px rgba(0, 0, 0, 0.22)",
    deepest: "0px 19px 19px rgba(0, 0, 0, 0.3), 0px 15px 6px rgba(0, 0, 0, 0.22)",
};
const EmbedTabContainer = styled.div `
  width: 658px;
  padding: 24px;
  background-color: ${newTheme.colors.grey100};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  color: ${newTheme.colors.grey800};
`;
const MobileSelectContainer = styled.div `
  display: flex;
  align-items: center;
  > h3 {
    font: ${newTheme.fonts.bold};
    margin-right: 16px;
    margin-left: 8px;
  }
  > img {
    width: 24px;
    height: 24px;
  }
`;
const MobileEmbedButtonContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${newTheme.colors.grey200};
  border: 1px solid ${newTheme.colors.grey400};
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 16px;
`;
const MobileSelectDescription = styled.span `
  display: block;
  font: ${newTheme.fonts.small};
  margin: 8px 0 24px 0;
`;
export const EmbedCodeContainer = styled.div `
  border-top: 1px solid ${newTheme.colors.grey400};
  padding-top: 24px;
  padding-bottom: 8px;
  > span {
    font: ${newTheme.fonts.bold};
  }
`;
export const EmbedButtonContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 12px;
  > span {
    padding-right: 16px;
  }
`;
export const EmbedTextarea = styled.div `
  width: 100%;
  height: 140px;
  background-color: ${newTheme.colors.blue50};
  border: 1px solid ${newTheme.colors.blue300};
  border-radius: 4px;
  margin-top: 12px;
  padding: 8px 16px 4px 16px;
  overflow-y: scroll;
  > code {
    font: ${newTheme.fonts.medium};
  }
`;
