export var FieldDataType;
(function (FieldDataType) {
    FieldDataType["PHONE"] = "Phone";
    FieldDataType["DROPDOWN"] = "Dropdown";
    FieldDataType["TEXT"] = "Text";
    FieldDataType["MULTILINETEXT"] = "Multiline Text";
    FieldDataType["EMAIL"] = "Email";
    FieldDataType["EMAILNONGENERIC"] = "Email (Non Generic)";
    FieldDataType["CHECKBOX"] = "Checkbox";
    FieldDataType["NUMBER"] = "Number";
    FieldDataType["HIDDEN"] = "HIDDEN";
    FieldDataType["UNSUPPORTED"] = "Unsupported";
})(FieldDataType || (FieldDataType = {}));
export var IntegrationTarget;
(function (IntegrationTarget) {
    IntegrationTarget["HUBSPOT"] = "HUBSPOT";
    IntegrationTarget["SALESFORCE"] = "SALESFORCE";
    IntegrationTarget["MARKETO"] = "MARKETO";
    IntegrationTarget["PARDOT"] = "PARDOT";
})(IntegrationTarget || (IntegrationTarget = {}));
export var FormLayout;
(function (FormLayout) {
    FormLayout["HORIZONTAL"] = "HORIZONTAL";
    FormLayout["VERTICAL"] = "VERTICAL";
})(FormLayout || (FormLayout = {}));
export var FormInputBoxStyle;
(function (FormInputBoxStyle) {
    FormInputBoxStyle["BOX"] = "BOX";
    FormInputBoxStyle["UNDERLINE"] = "UNDERLINE";
})(FormInputBoxStyle || (FormInputBoxStyle = {}));
