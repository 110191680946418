import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DynamicWidthInput } from "../../pages/builder/navbar/dynamic-width-input";
import { newTheme } from "../../styled/theme";
export function StepNameInput({ disabled, name, setName }) {
    const id = "editor-step-name-input";
    const [localName, setLocalName] = useState(name);
    // needed for compatibility with undo/redo
    useEffect(() => {
        if (name !== localName)
            setLocalName(name);
    }, [name]);
    let shouldCommitOnBlur = true;
    const commit = () => {
        setLocalName(localName.trim());
        setName(localName.trim());
    };
    const decommit = () => {
        setLocalName(name);
    };
    if (disabled)
        return React.createElement(Text, null, name);
    return (React.createElement("div", { onClick: e => {
            const el = document.querySelector(`#${id}`);
            if (!el)
                return;
            el.focus();
            el.setSelectionRange(0, name.length);
            e.stopPropagation();
        } },
        React.createElement(DynamicWidthInput, { id: id, value: localName, disabled: disabled, onChange: (e) => {
                setLocalName(e.target.value);
            }, onBlur: () => {
                if (shouldCommitOnBlur) {
                    commit();
                }
            }, onKeyDown: e => {
                if (e.code === "Enter") {
                    e.preventDefault();
                    commit();
                    shouldCommitOnBlur = false;
                    //@ts-expect-error
                    e.target.blur();
                }
                else if (e.code === "Escape") {
                    e.preventDefault();
                    e.stopPropagation();
                    decommit();
                    shouldCommitOnBlur = false;
                    //@ts-expect-error
                    e.target.blur();
                }
            }, style: { font: newTheme.fonts.normal, minWidth: 80 } })));
}
const Text = styled.p `
  font: ${newTheme.fonts.normal};
`;
