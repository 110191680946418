import React from "react";
import styled from "styled-components";
import { DCIconType, DCModuleType } from "../../../../../../../shared/types/demo-center";
import { newTheme } from "../../../../styled/theme";
import { DCIcon } from "../../components/dc-icon";
import TTooltip from "../../../../styled/t-tooltip";
import { dcGetReadableModuleType } from "../../helpers/dc-helpers";
const buttons = [
    DCModuleType.SINGLE,
    DCModuleType.MULTIPLE,
    DCModuleType.BOOLEAN,
    DCModuleType.SCALE,
    DCModuleType.NO_PROMPT,
];
export const icons = {
    [DCModuleType.SINGLE]: DCIconType.SINGLE_SELECT,
    [DCModuleType.MULTIPLE]: DCIconType.MULTI_SELECT,
    [DCModuleType.BOOLEAN]: DCIconType.YES_NO,
    [DCModuleType.SCALE]: DCIconType.SPEEDOMETER,
    [DCModuleType.NO_PROMPT]: DCIconType.NO_PROMPT,
};
export const DCCMModuleTypeSelector = ({ size, onClick, selectedType, areBooleanAndScaleDisabled, areSingleAndMultipleDisabled, isNoPromptDisabled, }) => {
    const isDisabled = (type) => {
        if (selectedType === type)
            return false;
        return ((areBooleanAndScaleDisabled && [DCModuleType.BOOLEAN, DCModuleType.SCALE].includes(type)) ||
            (areSingleAndMultipleDisabled && [DCModuleType.MULTIPLE, DCModuleType.SINGLE].includes(type)) ||
            (isNoPromptDisabled && type === DCModuleType.NO_PROMPT));
    };
    return size === "large" ? (React.createElement(SelectorContainer, null,
        React.createElement(Message, null, "Select a prompt type:"),
        React.createElement(ButtonContainer, null, buttons.map(b => (React.createElement(LargeButton, { key: b, onClick: () => (!isDisabled(b) ? onClick(b) : null) },
            React.createElement(DCIcon, { type: icons[b], style: { height: 16 } }),
            React.createElement(Label, null, dcGetReadableModuleType(b)))))))) : (React.createElement(Toolbar, null, buttons.map(b => (React.createElement(TTooltip, { key: b, content: dcGetReadableModuleType(b), position: "top", disabled: isDisabled(b) },
        React.createElement(IconButton, { onClick: () => (!isDisabled(b) ? onClick(b) : null), className: isDisabled(b) ? "disabled" : selectedType === b ? "selected" : null },
            React.createElement(DCIcon, { type: icons[b] })))))));
};
const SelectorContainer = styled.div `
  display: flex;
  padding: 4px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  background-color: ${newTheme.colors.grey100};
  box-shadow: ${newTheme.shadows.demoCenter};
`;
const Message = styled.div `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey600};
  display: flex;
  height: 32px;
  flex-direction: column;
  justify-content: center;
`;
const ButtonContainer = styled.div `
  width: 100%;
  display: flex;
  gap: 8px;
`;
const LargeButton = styled.button `
  display: flex;
  height: 72px;
  padding: 16px 8px 8px 8px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid ${newTheme.colors.grey300};
  background-color: ${newTheme.colors.white};
  cursor: pointer;
  color: ${newTheme.colors.grey600};
  &:hover {
    color: ${newTheme.colors.blue300};
    border-color: ${newTheme.colors.blue300};
  }
`;
const Label = styled.div `
  font: ${newTheme.fonts.small};
`;
const Toolbar = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 1;
  justify-content: flex-start;
`;
const IconButton = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  svg {
    width: 24px;
    height: 24px;
  }
  transition: color 0.1s ease-in;
  &:hover {
    color: ${newTheme.colors.blue300};
  }
  color: ${newTheme.colors.grey500};
  &.selected {
    color: ${newTheme.colors.blue500};
  }
  &.disabled {
    color: ${newTheme.colors.grey300};
    cursor: not-allowed;
  }
  cursor: pointer;
`;
