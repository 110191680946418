import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AddTourialItem, TourialListContainer } from "../../../microsites/modals/add-tourial-modal";
import { useAsync } from "react-async";
import { TourialApiCalls } from "../../../../../helpers/tourial-api-calls";
import TInput from "../../../../styled/t-input";
export const DCCMAddCOntentToursTab = ({ setSelectedTours, currentItems }) => {
    const [tourialList, setTourialList] = useState([]);
    const [filter, setFilter] = useState("");
    const { data } = useAsync({
        promiseFn: TourialApiCalls.Tourials.getTourialList,
    });
    useEffect(() => {
        const tourialList = [];
        data === null || data === void 0 ? void 0 : data.tourialList.forEach((t) => {
            tourialList.push({
                id: t.tourId,
                thumbnail: t.firstPageData.screen.src,
                name: t.tourName,
                checked: checkIfTourExists(t.tourId),
                isPublished: t.isPublished,
                interactedAt: t.interactedAt,
                archivedAt: t.archivedAt,
                estimatedDuration: t.estimatedDuration,
            });
        });
        setTourialList(tourialList);
    }, [data]);
    useEffect(() => {
        setSelectedTours(tourialList.filter(t => t.checked));
    }, [tourialList]);
    const checkIfTourExists = (id) => {
        return !!currentItems.find(i => i === id);
    };
    const handleClick = (tourial, checked) => {
        const newTourialList = [...tourialList];
        newTourialList.forEach(t => {
            if (t.id === tourial.id) {
                t.checked = checked;
            }
        });
        setTourialList(newTourialList);
    };
    return (React.createElement(TourialListContainer, null,
        React.createElement(FilterContainer, null,
            React.createElement(TInput, { placeholder: "Search tours", value: filter, onChange: e => setFilter(e.target.value), autoFocus: true })),
        tourialList
            .filter(t => t.name.toLowerCase().includes(filter.toLowerCase()))
            .map(t => (React.createElement(AddTourialItem, { key: t.id, tourial: t, onClick: handleClick, exists: checkIfTourExists(t.id) })))));
};
const FilterContainer = styled.div `
  display: flex;
  flex: 1;
  padding: 12px 12px;
  input {
    width: 100%;
  }
`;
