import { useEffect } from "react";
import { USER_META_TAG_NAME } from "../../../shared/types/chrome-extension-v2";
import { usePostEvent } from "../helpers/events";
import { useDCPostEventInLiveMode } from "../components/pages/demo-center/hooks/dc-event-hooks";
export const useDetectMetaTag = (isDemoCenter) => {
    const postEvent = usePostEvent();
    const postDCEvent = useDCPostEventInLiveMode();
    useEffect(() => {
        const cleanup = () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
        const checkForUserMetaTag = () => {
            const userIdMetaTag = document.querySelector(`meta[name="${USER_META_TAG_NAME}"]`);
            if (userIdMetaTag) {
                const tourialUserId = userIdMetaTag.getAttribute("data-t-user");
                if (tourialUserId) {
                    if (isDemoCenter)
                        void postDCEvent({ eventType: "DEMOCENTER_USER", tourialUserId });
                    else
                        void postEvent({ eventType: "TOURIAL_USER", tourialUserId });
                    cleanup();
                }
            }
        };
        const interval = setInterval(checkForUserMetaTag, 1000);
        const timeout = setTimeout(() => clearInterval(interval), 10000);
        return () => {
            cleanup();
        };
    }, []);
};
