import React, { useState } from "react";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
export default function TButton({ id, children, onClick, disabled, style, red, secondaryButton, modalBlue, invertColors, onMouseDown, onMouseUp, onMouseLeave, onMouseOut, onMouseEnter, className, }) {
    let baseStyle = Object.assign({ padding: "12px 16px", backgroundColor: theme.colors.cobalt.medium, color: theme.colors.white, borderColor: theme.colors.white, borderWidth: "2px", borderStyle: "solid", borderRadius: "4px", cursor: "pointer", fontFamily: "LatoBold", fontStyle: "normal", fontWeight: "bold", boxShadow: theme.shadows.shallow, textAlign: "center" }, style);
    let hoverStyle = Object.assign(Object.assign({}, baseStyle), { backgroundColor: theme.colors.cobalt.dark, boxShadow: theme.shadows.shallow });
    let activeStyle = Object.assign(Object.assign({}, baseStyle), { backgroundColor: theme.colors.cobalt.light, boxShadow: theme.shadows.shallowest });
    let disabledStyle = Object.assign(Object.assign({}, baseStyle), { cursor: "not-allowed", backgroundColor: theme.colors.grey.medium, color: theme.colors.grey.darkest, boxShadow: theme.shadows.shallowest });
    const invertedStyle = Object.assign(Object.assign({}, baseStyle), { color: theme.colors.cobalt.medium, backgroundColor: null, borderColor: theme.colors.cobalt.medium });
    const invertedDisabledStyle = Object.assign(Object.assign({}, hoverStyle), { color: theme.colors.grey.dark, backgroundColor: theme.colors.grey.lightest, borderColor: theme.colors.grey.dark });
    const invertedHoverStyle = Object.assign(Object.assign({}, hoverStyle), { color: theme.colors.cobalt.dark, backgroundColor: null, borderColor: theme.colors.cobalt.dark });
    const invertedActiveStyle = Object.assign(Object.assign({}, hoverStyle), { color: theme.colors.cobalt.light, backgroundColor: null, borderColor: theme.colors.cobalt.light });
    if (invertColors) {
        baseStyle = Object.assign(Object.assign({}, baseStyle), invertedStyle);
        hoverStyle = Object.assign(Object.assign({}, baseStyle), invertedHoverStyle);
        activeStyle = Object.assign(Object.assign({}, baseStyle), invertedActiveStyle);
        disabledStyle = Object.assign(Object.assign({}, baseStyle), invertedDisabledStyle);
    }
    if (secondaryButton) {
        baseStyle.backgroundColor = theme.colors.sky.lightest;
        hoverStyle.backgroundColor = theme.colors.sky.light;
        activeStyle.backgroundColor = theme.colors.sky.extremeLightest;
        baseStyle.borderColor = theme.colors.sky.darkest;
        hoverStyle.borderColor = theme.colors.sky.darkest;
        activeStyle.borderColor = theme.colors.sky.darkest;
        baseStyle.color = theme.colors.sky.darkest;
        hoverStyle.color = theme.colors.sky.darkest;
        activeStyle.color = theme.colors.sky.darkest;
    }
    if (modalBlue) {
        baseStyle.backgroundColor = theme.colors.sky.dark;
        hoverStyle.backgroundColor = theme.colors.sky.darkest;
        activeStyle.backgroundColor = theme.colors.sky.medium;
    }
    if (red) {
        baseStyle.backgroundColor = theme.colors.error.medium;
        hoverStyle.backgroundColor = theme.colors.error.dark;
        activeStyle.backgroundColor = theme.colors.error.light;
    }
    const [currentStyle, setCurrentStyle] = useState(baseStyle);
    const [currentDisabledStyle, setCurrentDisabledStyle] = useState(disabledStyle);
    return (React.createElement("button", { id: id, style: disabled ? currentDisabledStyle : currentStyle, onClick: onClick, className: className || "", disabled: disabled, onMouseDown: () => {
            setCurrentStyle(activeStyle);
            setCurrentDisabledStyle(disabledStyle);
            if (onMouseDown)
                onMouseDown();
        }, onMouseUp: () => {
            setCurrentStyle(baseStyle);
            setCurrentDisabledStyle(disabledStyle);
            if (onMouseUp)
                onMouseUp();
        }, onMouseEnter: () => {
            setCurrentStyle(hoverStyle);
            setCurrentDisabledStyle(disabledStyle);
            if (onMouseEnter)
                onMouseEnter();
        }, onMouseLeave: () => {
            setCurrentStyle(baseStyle);
            setCurrentDisabledStyle(disabledStyle);
            if (onMouseLeave)
                onMouseLeave();
        }, onMouseOut: () => {
            if (onMouseOut)
                onMouseOut();
        } }, children));
}
