import React from "react";
import styled from "styled-components";
import { DCButtonAction, DCButtonIconPlacement, DCButtonType, } from "../../../../../../shared/types/demo-center";
import { TextStyle } from "../../../../../../shared/types/tool-data-types/common";
import { dcButtonFontSizes, dcGradientBackground } from "../helpers/dc-style-helpers";
import { DCIcon } from "./dc-icon";
import { ensureHttpsInUrl, openUrlInNewTab } from "../../../../../../shared/functions/helpers";
export const DCButton = ({ config, disabled, onClick }) => {
    const { isEnabled, text, style, action, url, icon, iconPlacement, type } = config;
    const handleClick = () => {
        onClick === null || onClick === void 0 ? void 0 : onClick();
        if (action === DCButtonAction.LINK) {
            openUrlInNewTab(ensureHttpsInUrl(url));
        }
    };
    return isEnabled ? (React.createElement(Button, { config: style, onClick: () => (disabled ? null : handleClick()), className: `${type === DCButtonType.LARGE ? "large " : ""}${type === DCButtonType.BORDERLESS ? "borderless " : ""}${disabled ? "disabled" : ""}` },
        icon && iconPlacement === DCButtonIconPlacement.LEFT && React.createElement(DCIcon, { type: icon, style: { marginRight: 10 } }),
        React.createElement("span", null, text),
        icon && (!iconPlacement || iconPlacement === DCButtonIconPlacement.RIGHT) && (React.createElement(DCIcon, { type: icon, style: { marginLeft: 10 } })))) : null;
};
const Button = styled.button `
  display: flex;
  align-items: center;
  background-color: ${p => p.config.backgroundColor};
  ${p => p.config.backgroundColor2
    ? dcGradientBackground({
        color1: p.config.backgroundColor,
        color2: p.config.backgroundColor2,
        percent: p.config.gradientPercent,
    })
    : ""}
  color: ${p => p.config.color};
  font-family: ${p => p.config.font};
  font-size: ${p => dcButtonFontSizes[p.config.size]}px;
  padding: 8px 18px;
  border-radius: ${p => p.config.borderRadius}px;
  ${p => (p.config.borderColor ? "border: 1px solid " + p.config.borderColor : "")};
  font-weight: ${p => (p.config.style === TextStyle.BOLD ? "bold" : "normal")};
  text-decoration: ${p => (p.config.style === TextStyle.UNDERLINE ? "underline" : "none")};
  font-style: ${p => (p.config.style === TextStyle.ITALIC ? "italic" : "normal")};
  text-transform: ${p => (p.config.isUppercase ? "uppercase" : "none")};
  justify-content: center;
  transition:
    opacity 0.1s linear,
    color 0.1s linear,
    background-color 0.1s linear,
    border-color 0.1s linear;
  &:not(.disabled):hover {
    opacity: ${p => (!p.config.hoverColor ? "0.7" : "1")};
    color: ${p => p.config.hoverColor};
    cursor: pointer;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &.large {
    padding: 16px 32px;
    width: 100%;
  }
  &.borderless {
    padding: 0;
    border: 0;
    background-color: transparent;
    text-decoration: underline;
  }
`;
