import React, { useRef } from "react";
import { ModalType } from "../../../../../../../shared/types/tool-data-types/modal-data";
import { imageTypes, uploadMedia, videoTypes } from "../../../../../helpers/media";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import { LogoControlsContainer, LogoUploadContainer, RemoveImage, ReplaceLogoContainer } from "./logo-upload-section";
import { GraphicType } from "../../../../../../../shared/types/tool-data-types/common";
import styled from "styled-components";
import DeleteIcon from "../../../../../../assets/images/v3/red-trashcan.svg";
export const GraphicUploadSection = ({ updateFn, graphic, modalType }) => {
    const inputFile = useRef(null);
    return (React.createElement("div", null,
        React.createElement("input", { multiple: false, type: "file", accept: `${[...imageTypes, ...videoTypes].join("|")}`, ref: inputFile, style: { display: "none" }, onChange: e => {
                void uploadMedia(e.target.files, (media) => {
                    var _a, _b;
                    const type = determineFileType((_a = media[0]) === null || _a === void 0 ? void 0 : _a.src);
                    updateFn(Object.assign(Object.assign({}, graphic), { src: (_b = media[0]) === null || _b === void 0 ? void 0 : _b.src, isEnabled: true, type }));
                });
            } }),
        graphic.src ? (React.createElement(React.Fragment, null,
            React.createElement(LogoControlsContainer, null,
                React.createElement(GraphicContainer, null,
                    graphic.type === GraphicType.IMAGE && React.createElement("img", { src: graphic.src, alt: "graphic" }),
                    graphic.type === GraphicType.VIDEO && (React.createElement("video", { controls: false, preload: "metadata", autoPlay: false, src: `${graphic.src}#t=0.5`, style: { objectFit: "cover" } })),
                    React.createElement(ReplaceLogoContainer, { style: { width: "177px" } },
                        React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY, onClick: () => {
                                inputFile.current.click();
                            } },
                            "Replace ",
                            modalType === ModalType.VIDEO ? "video" : "image")))),
            React.createElement(RemoveImage, { onClick: () => {
                    updateFn(Object.assign(Object.assign({}, graphic), { src: "" }));
                } },
                React.createElement("img", { src: DeleteIcon }),
                "Remove ",
                modalType === ModalType.VIDEO ? "video" : "image"))) : (React.createElement(LogoUploadContainer, null,
            React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY, onClick: () => {
                    inputFile.current.click();
                } },
                "Add ",
                modalType === ModalType.VIDEO ? "video" : "image"),
            React.createElement("span", null, "Supported file types: jpeg, png, gif, mov, mp4")))));
};
export const GraphicContainer = styled.div `
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 177px;
  height: 100px;
  overflow: hidden;
  > img {
    max-height: 100px;
    max-width: 177px;
  }
  > video {
    max-height: 100px;
    max-width: 177px;
  }
  &:hover {
    > div {
      display: flex;
    }
  }
`;
const determineFileType = (url) => {
    const ext = url.split(".").pop();
    if (["mov", "mp4", "avi", "webm"].includes(ext))
        return GraphicType.VIDEO;
    return GraphicType.IMAGE;
};
