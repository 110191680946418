export var TriggeredByAction;
(function (TriggeredByAction) {
    TriggeredByAction["CLICK"] = "CLICK";
    TriggeredByAction["HOVER_IN"] = "HOVER_IN";
    TriggeredByAction["HOVER_OUT"] = "HOVER_OUT";
    TriggeredByAction["HOVER"] = "HOVER";
})(TriggeredByAction || (TriggeredByAction = {}));
// For any new events make sure to:
// 1) add case in getDuplicatePageTriggerListeners()
export var TriggerDispatchEvent;
(function (TriggerDispatchEvent) {
    TriggerDispatchEvent["DISPLAY_TOOL"] = "DISPLAY_TOOL";
    TriggerDispatchEvent["HIDE_TOOL"] = "HIDE_TOOL";
    TriggerDispatchEvent["TOGGLE_TOOL"] = "TOGGLE_TOOL";
    TriggerDispatchEvent["EXTERNAL_LINK"] = "EXTERNAL_LINK";
    TriggerDispatchEvent["SUBMIT_FORM"] = "SUBMIT_FORM";
    TriggerDispatchEvent["TRIGGER_FORM"] = "TRIGGER_FORM";
    // We need NEXT_PAGE in addition to CHANGE_VIEW for global buttons
    TriggerDispatchEvent["NEXT_PAGE"] = "NEXT_PAGE";
    TriggerDispatchEvent["NEXT_ZOOM"] = "NEXT_ZOOM";
    TriggerDispatchEvent["CHANGE_PAGE"] = "CHANGE_PAGE";
    TriggerDispatchEvent["CHANGE_ZOOM"] = "CHANGE_ZOOM";
    TriggerDispatchEvent["PREVIOUS_ZOOM"] = "PREVIOUS_ZOOM";
    // this was just added for backwards compatibility of new ux interactions. it's a lie
    TriggerDispatchEvent["PREVIOUS_PAGE"] = "PREVIOUS_PAGE";
})(TriggerDispatchEvent || (TriggerDispatchEvent = {}));
// Update Font here to be updated everywhere except storybook
// For font to work in storybook, you have to update it in .storybook/preview-head.html
export const googleFonts = [
    "Alegreya",
    "Aleo",
    "Barlow",
    "BioRhyme",
    "Bitter",
    "Cairo",
    "Concert One",
    "Crimson Text",
    "Domine",
    "DM Sans",
    "Fira Sans",
    "Fraunces",
    "IBM Plex Sans",
    "Inter",
    "Karla",
    "Lato",
    "Lexend",
    "Lora",
    "Manrope",
    "Merriweather",
    "Montserrat",
    "Muli",
    "Noto Sans",
    "Noto Serif",
    "Noto Serif Georgian",
    "Nunito",
    "Nunito Sans",
    "Outfit",
    "Open Sans",
    "Oswald",
    "Plus Jakarta Sans",
    "Poppins",
    "Prompt",
    "PT Sans",
    "Public Sans",
    "Quicksand",
    "Raleway",
    "Roboto",
    "Roboto Slab",
    "Rubik",
    "Sora",
    "Source Sans 3",
    "Source Sans Pro",
    "Source Serif Pro",
    "Space Mono",
    "Titillium Web",
    "Ubuntu",
    "Varela",
    "Vollkorn",
    "Work Sans",
];
const systemFonts = [
    "Arial",
    "Calibri",
    "Open Sans",
    "Comic Sans MS",
    "Courier New",
    "Garamond",
    "Geneva",
    "Georgia",
    "Tahoma",
    "Times",
    "Trebuchet MS",
    "Verdana",
];
export const theme = {
    colors: {
        cobalt: {
            lightest: "#C3D0FF",
            light: "#7D90D5",
            medium: "#0055FF",
            dark: "#203479",
            darkest: "#132257",
            700: "#203479",
            500: "#0055FF",
        },
        sky: {
            extremeLightest: "#EBF4FD",
            lightest: "#E0F0FF",
            light: "#B2DAFF",
            medium: "#8CC1F2",
            dark: "#529CE0",
            darkest: "#2968A3",
            100: "#E0F0FF",
            500: "#8CC1F2",
        },
        gold: {
            lightest: "#FFECD1",
            light: "#FFD9A1",
            medium: "#EBB564",
            dark: "#B8812E",
            darkest: "#704B13",
            700: "#B8812E",
        },
        forrest: {
            lightest: "#D7F0E1",
            light: "#9AC8AC",
            medium: "#5C9472",
            dark: "#295D3E",
            darkest: "#124426",
            700: "#295D3E",
            100: "#D7F0E1",
        },
        copper: {
            lightest: "#D9BFBF",
            light: "#BB8C8C",
            medium: "#854F4F",
            dark: "#473333",
            darkest: "#271717",
        },
        white: "#F9FAFA",
        grey: {
            lightest: "#EDF3F5",
            light: "#D2DCE0",
            medium: "#B3B9BC",
            dark: "#7B8184",
            darkest: "#4A4C4E",
            900: "#101828",
            700: "#7B8184",
            500: "#667085",
            300: "#D2DCE0",
            200: "#EAECF0",
            100: "#EDF3F5",
            50: "#F9FAFB",
        },
        black: "#171717",
        error: {
            light: "#EDC9CE",
            medium: "#DD4056",
            dark: "#77222E",
        },
        success: {
            medium: "#509557",
            dark: "#1B9D48",
        },
    },
    shadows: {
        none: "0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0)",
        shallowest: "0px 1px 1.5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.24)",
        shallow: "0px 3px 3px rgba(0, 0, 0, 0.16), 0px 3px 3px rgba(0, 0, 0, 0.23)",
        shallowSky: "0px 3px 3px rgba(178, 218, 255, 0.16), 0px 3px 3px rgba(178, 218, 255, 0.23)",
        medium: "0px 10px 10px rgba(0, 0, 0, 0.19), 0px 6px 3px rgba(0, 0, 0, 0.23)",
        mediumSky: "0px 10px 10px rgba(178, 218, 255, 0.19), 0px 6px 3px rgba(178, 218, 255, 0.23)",
        deep: "0px 14px 14px rgba(0, 0, 0, 0.25), 0px 10px 5px rgba(0, 0, 0, 0.22)",
        deepest: "0px 19px 19px rgba(0, 0, 0, 0.3), 0px 15px 6px rgba(0, 0, 0, 0.22)",
    },
    typography: {
        h1Hero: {
            fontFamily: "LatoBold",
            fontStyle: "normal",
            //   fontWeight: "bold" as "bold",
            fontSize: "64px",
            lineHeight: "72px",
            lineSpacing: "0px",
            textTransform: "capitalize",
            fontFeatureSettings: "'liga' off",
        },
        h1Title: {
            fontFamily: "LatoBlack",
            fontStyle: "normal",
            //   fontWeight: "bold" as "bold",
            fontSize: "48px",
            lineHeight: "72px",
            lineSpacing: "0px",
            textTransform: "capitalize",
            fontFeatureSettings: "'liga' off",
        },
        h2: {
            fontFamily: "LatoBold",
            fontStyle: "normal",
            //   fontWeight: "bold" as "bold",
            fontSize: "24px",
            lineHeight: "32px",
            lineSpacing: "10%",
            fontFeatureSettings: "'liga' off",
            textTransform: "uppercase",
            letterSpacing: "0.1em",
        },
        h3: {
            fontFamily: "LatoBold",
            fontStyle: "normal",
            //   fontWeight: "bold" as "bold",
            fontSize: "24px",
            lineHeight: "32px",
            lineSpacing: "0",
            textTransform: "capitalize",
            fontFeatureSettings: "'liga' off",
        },
        h4: {
            fontFamily: "LatoBlack",
            fontStyle: "normal",
            //   fontWeight: "black" as "black",
            fontSize: "16px",
            lineHeight: "24px",
            lineSpacing: "20%",
            fontFeatureSettings: "'liga' off",
            textTransform: "uppercase",
            letterSpacing: "0.2em",
        },
        h5: {
            fontFamily: "LatoBold",
            fontStyle: "normal",
            //   fontWeight: "bold" as "bold",
            fontSize: "16px",
            lineHeight: "24px",
            lineSpacing: "5%",
            fontFeatureSettings: "'liga' off",
            textTransform: "capitalize",
            letterSpacing: "0.05em",
        },
        h6: {
            fontFamily: "LatoBold",
            fontStyle: "normal",
            //   fontWeight: "bold" as "bold",
            fontSize: "14px",
            lineHeight: "20px",
            lineSpacing: "10%",
            fontFeatureSettings: "'liga' off",
            textTransform: "capitalize",
            letterSpacing: "0.05em",
        },
        p: {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "24px",
            lineSpacing: "0px",
            fontFeatureSettings: "'liga' off",
        },
        label: {
            fontFamily: "Lato",
            fontStyle: "normal",
            //   fontWeight: "bold" as "bold",
            fontSize: "16px",
            lineHeight: "24px",
            lineSpacing: "0px",
            fontFeatureSettings: "'liga' off",
        },
        help: {
            fontFamily: "Lato",
            fontStyle: "normal",
            //   fontWeight: "regular" as "regular",
            fontSize: "14px",
            lineHeight: "20px",
            lineSpacing: "0px",
            fontFeatureSettings: "'liga' off",
        },
        a: {
            fontFamily: "Lato",
            fontStyle: "normal",
            //   fontWeight: "medium" as "medium",
            fontSize: "16px",
            lineHeight: "24px",
            lineSpacing: "0px",
            fontFeatureSettings: "'liga' off",
        },
        modalMainText: {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontSize: "28px",
            lineHeight: "32px",
            fontFeatureSettings: "'liga' off",
        },
        modalSecondaryText: {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontSize: "18px",
            lineHeight: "22px",
            fontFeatureSettings: "'liga' off",
        },
        smallModalMainText: {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontSize: "24px",
            lineHeight: "32px",
            fontFeatureSettings: "'liga' off",
        },
        smallModalSecondaryText: {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontSize: "16px",
            lineHeight: "24px",
            fontFeatureSettings: "'liga' off",
        },
    },
    fontList: [...systemFonts, ...googleFonts],
};
export const fontFamilyOptions = [...theme.fontList];
export var TimerTrigger;
(function (TimerTrigger) {
    TimerTrigger["View"] = "VIEW";
    TimerTrigger["SessionStart"] = "SESSION_START";
})(TimerTrigger || (TimerTrigger = {}));
