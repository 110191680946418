import React, { useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
export const DCCMNewTrackInput = ({ onAddTrack, autoFocus }) => {
    const [title, setTitle] = useState("");
    const [active, setActive] = useState(false);
    const inputRef = React.useRef(null);
    const scrollTargetRef = React.useRef(null);
    const addTrack = () => {
        if (title) {
            onAddTrack(title);
            setTitle("");
            scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(NewTrackInputContainer, { onClick: () => inputRef.current.focus(), className: active ? "active" : null },
            React.createElement("input", { ref: inputRef, type: "text", placeholder: "Add an option for your visitors to learn about...", value: title, onKeyDown: e => {
                    if (e.key === "Enter") {
                        addTrack();
                    }
                }, autoFocus: autoFocus, onChange: e => setTitle(e.target.value), onFocus: () => setActive(true), onBlur: () => setActive(false) }),
            React.createElement(TButton, { onClick: addTrack, variant: TButtonVariants.SECONDARY, size: "medium", disabled: !title }, "Add Track")),
        React.createElement(Spacer, null),
        React.createElement(ScrollTarget, { ref: scrollTargetRef })));
};
const NewTrackInputContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  background: ${newTheme.colors.white};
  border: 1px solid ${newTheme.colors.grey300};
  border-radius: 8px;
  gap: 16px;
  box-shadow: ${newTheme.shadows.demoCenter};
  input {
    flex: 1;
    color: ${newTheme.colors.grey900};
    font: ${newTheme.fonts.medium};
    border: 0;
    padding: 0;
    &::placeholder {
      font: ${newTheme.fonts.mediumItalic};
      color: ${newTheme.colors.grey600};
    }
  }
  &.active {
    border: 1px solid ${newTheme.colors.blue300};
  }
  transition: border 0.2s ease-in-out;
`;
const Spacer = styled.div `
  height: 80px;
`;
const ScrollTarget = styled.div `
  height: 0;
  width: 100%;
`;
