import React from "react";
import { cloneDeep } from "lodash";
import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { withFlexibleToolWrapper } from "../../../../renderer/wrapper/with-flexible-tool-wrapper";
import { WrapperChild } from "../../../../renderer/wrapper/wrapper-child";
import styled from "styled-components";
import { getScaledBorderRadiusPx, positionEq, sizeEq, } from "../../../../../helpers/tooltip-v2-size-and-position-helpers";
import { spotlightAnimationBuilder } from "../../../../../helpers/dom-helpers";
export function Spotlight(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    function drag(position) {
        if (!positionEq(tool.spotlight.position, position)) {
            const t = cloneDeep(tool);
            t.spotlight.position = position;
            updateTool(t);
        }
    }
    function resize(size, position) {
        if (!sizeEq(tool.spotlight.size, size) || !positionEq(tool.spotlight.position, position)) {
            const t = cloneDeep(tool);
            t.spotlight.position = position;
            t.spotlight.size = size;
            updateTool(t);
        }
    }
    return withFlexibleToolWrapper(Inner, {
        isEditing: props.isInEditViewport,
        isSelectable: true,
        isResizable: true,
        isDraggable: true,
        isAspectRatioLocked: false,
        isStaticSize: false,
        shouldShowSelectionTab: false,
    }, {
        bounds: ".tourial-viewport",
        minSize: { width: 16, height: 16 },
        size: tool.spotlight.size,
        position: tool.spotlight.position,
    }, {
        onDrag: () => { },
        onDragStop: drag,
        onResize: () => { },
        onResizeStop: resize,
    })(props);
}
function Inner(props) {
    const { toolData: tool, disableAnimations, viewportDimensions } = props;
    return (React.createElement(Div, { id: `${tool.id}-spotlight`, disableAnimations: disableAnimations, style: {
            borderRadius: getScaledBorderRadiusPx(tool.spotlight.shape, viewportDimensions.width),
            boxShadow: `0px 0px 0px 10000px rgba(0, 0, 0, ${tool.spotlight.opacity})`,
        } }));
}
const Div = styled(WrapperChild) `
  animation: ${props => (props.disableAnimations ? "none" : spotlightAnimationBuilder())};
`;
