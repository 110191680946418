import React, { useEffect } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
export const DCCMEditableText = ({ value, onChange, onCommit, onEditChange, style, textarea, selected, disabled, placeholder, isEditing: defaultIsEditing, }) => {
    const [isEditing, setIsEditing] = React.useState(defaultIsEditing || false);
    const inputRef = React.useRef(null);
    const textareaRef = React.useRef(null);
    useEffect(() => {
        if (defaultIsEditing) {
            setIsEditing(true);
        }
    }, [defaultIsEditing]);
    useEffect(() => {
        var _a, _b, _c, _d;
        if (isEditing) {
            (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            (_b = textareaRef === null || textareaRef === void 0 ? void 0 : textareaRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            if (selected) {
                (_c = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _c === void 0 ? void 0 : _c.select();
                (_d = textareaRef === null || textareaRef === void 0 ? void 0 : textareaRef.current) === null || _d === void 0 ? void 0 : _d.select();
            }
        }
        if (textareaRef.current) {
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
        onEditChange === null || onEditChange === void 0 ? void 0 : onEditChange(isEditing);
    }, [isEditing]);
    useEffect(() => {
        if (selected) {
            setIsEditing(true);
        }
    }, [selected]);
    return !isEditing ? (React.createElement(TextWrapper, { style: Object.assign(Object.assign({}, style), { cursor: !disabled ? "pointer" : "default", flex: !disabled ? 1 : null }), className: !value ? "placeholder" : null, onClick: e => {
            if (!disabled) {
                e.stopPropagation();
                setIsEditing(true);
            }
        } }, value ? value : placeholder)) : textarea ? (React.createElement(InputWrapper, null,
        React.createElement("textarea", { style: style, onClick: e => e.stopPropagation(), ref: textareaRef, value: value, placeholder: placeholder, onChange: e => onChange(e.target.value), onBlur: () => {
                setIsEditing(false);
                onCommit === null || onCommit === void 0 ? void 0 : onCommit(value);
            }, onKeyDown: e => {
                if (e.key === "Enter") {
                    setIsEditing(false);
                    onCommit === null || onCommit === void 0 ? void 0 : onCommit(value);
                }
            } }))) : (React.createElement(InputWrapper, null,
        React.createElement("input", { onClick: e => e.stopPropagation(), style: style, ref: inputRef, type: "text", value: value, placeholder: placeholder, onChange: e => onChange(e.target.value), onBlur: () => {
                setIsEditing(false);
                onCommit === null || onCommit === void 0 ? void 0 : onCommit(value);
            }, onKeyDown: e => {
                if (e.key === "Enter") {
                    setIsEditing(false);
                    onCommit === null || onCommit === void 0 ? void 0 : onCommit(value);
                }
            } })));
};
const TextWrapper = styled.div `
  display: flex;
  height: 100%;
  align-items: center;
  &.placeholder {
    font-style: italic !important;
    color: ${newTheme.colors.grey600} !important;
  }
`;
const InputWrapper = styled.div `
  display: flex;
  flex: 1;
  input,
  textarea {
    flex: 1;
    padding: 4px;
    border: 1px solid ${newTheme.colors.blue300};
    border-radius: 4px;
    background-color: ${newTheme.colors.white};
    &::placeholder {
      color: ${newTheme.colors.grey600};
      font-style: italic;
    }
  }
`;
