var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import TButton from "../../styled/t-button";
import TInput from "../../styled/t-input";
import TModal, { TModalButtonsContainer } from "../../styled/t-modal";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import { useAccount } from "../../../legacy/selectors";
import { ContentContainer } from "./workspace-create-modal";
import { newTheme } from "../../styled/theme";
import { TDropdownSelect } from "../../styled/t-dropdown-select";
import { useAsync } from "react-async";
import { ShareLinkPassword } from "./sharelink-password";
import { ShareLinkFormGate } from "./sharelink-form-gate";
const getSalesforceAccounts = ({ salesforceEnabled }) => __awaiter(void 0, void 0, void 0, function* () {
    if (!salesforceEnabled)
        return null;
    const data = yield TourialApiCalls.Integrations.getSalesforceAccounts();
    return data;
});
export default function EditShareLinkModal({ setIsEditShareLinkModalOpen, sharelinkData, reload, }) {
    var _a;
    const account = useAccount();
    const [accounts, setAccounts] = useState([]);
    const [opps, setOpps] = useState([]);
    const [linkName, setLinkName] = useState(sharelinkData.name);
    const [selectedAccount, setSelectedAccount] = useState(sharelinkData.sfdcAccountId ? { label: sharelinkData.sfdcAccountName, value: sharelinkData.sfdcAccountId } : null);
    const [selectedOpps, setSelectedOpps] = useState(sharelinkData.sfdcOpportunityId
        ? { label: sharelinkData.sfdcOpportunityName, value: sharelinkData.sfdcOpportunityId }
        : null);
    const [password, setPassword] = useState(sharelinkData.password);
    const [formGate, setFormGate] = useState(sharelinkData.formGate || { enabled: false });
    useAsync({
        promiseFn: getSalesforceAccounts,
        salesforceEnabled: (_a = account.integrations) === null || _a === void 0 ? void 0 : _a.salesforce,
        onResolve(data) {
            if (data === null || data === void 0 ? void 0 : data.length) {
                const accounts = [{ label: "-- Select an account --", value: "" }];
                data.forEach(account => {
                    accounts.push({ label: account.Name, value: account.Id });
                });
                if (sharelinkData.sfdcAccountId) {
                    const currAccount = accounts.find(a => a.value === sharelinkData.sfdcAccountId);
                    // ensure currAccount is present in the dropdown list
                    setSelectedAccount(currAccount || { label: sharelinkData.sfdcAccountName, value: sharelinkData.sfdcAccountId });
                    TourialApiCalls.Integrations.getSalesforceAccountOpps(sharelinkData.sfdcAccountId)
                        .then(data => {
                        var _a;
                        if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.length) {
                            const opps = [{ label: "-- Select an opp --", value: "" }];
                            data.data.forEach(opp => {
                                opps.push({ label: opp.Name, value: opp.Id });
                            });
                            if (sharelinkData.sfdcOpportunityId) {
                                const currOpp = opps.find(o => o.value === sharelinkData.sfdcOpportunityId);
                                // ensure currOpp is present in the dropdown list
                                setSelectedOpps(currOpp || { label: sharelinkData.sfdcOpportunityName, value: sharelinkData.sfdcOpportunityId });
                            }
                            setOpps(opps);
                        }
                    })
                        .catch(err => console.log(err));
                }
                setAccounts(accounts);
            }
        },
    });
    return (React.createElement(TModal, { onClose: () => setIsEditShareLinkModalOpen(false), title: "Edit share link" },
        React.createElement(ContentContainer, null,
            React.createElement("div", null,
                React.createElement("div", { style: { marginBottom: 4 } }, "Link name"),
                React.createElement(TInput, { style: { width: "100%" }, placeholder: "Link name", value: linkName, onChange: e => setLinkName(e.target.value) })),
            React.createElement("div", { style: { display: "flex", gap: 16 } },
                !!(accounts === null || accounts === void 0 ? void 0 : accounts.length) && (React.createElement("div", null,
                    React.createElement("div", { style: { marginBottom: 4 } },
                        "Salesforce Account",
                        " ",
                        React.createElement("span", { style: { fontStyle: "italic", color: newTheme.colors.grey500 } }, "(optional)")),
                    React.createElement(TDropdownSelect, { options: accounts, selectedValue: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.value, placeholder: "[None selected]", isSearchable: true, style: { width: 212 }, onChange: (option) => {
                            setSelectedAccount(option);
                            setSelectedOpps(null);
                            setOpps([]);
                            if (option.value) {
                                TourialApiCalls.Integrations.getSalesforceAccountOpps(option.value)
                                    .then(data => {
                                    var _a;
                                    if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.length) {
                                        const opps = [{ label: "-- Select an opp --", value: "" }];
                                        data.data.forEach(opp => {
                                            opps.push({ label: opp.Name, value: opp.Id });
                                        });
                                        setOpps(opps);
                                    }
                                })
                                    .catch(err => console.log(err));
                            }
                        } }))),
                !!(selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.value) && (React.createElement("div", null,
                    React.createElement("div", { style: { marginBottom: 4 } },
                        "Opp ",
                        React.createElement("span", { style: { fontStyle: "italic", color: newTheme.colors.grey500 } }, "(optional)")),
                    React.createElement(TDropdownSelect, { options: opps, selectedValue: selectedOpps === null || selectedOpps === void 0 ? void 0 : selectedOpps.value, placeholder: "[None selected]", isSearchable: true, style: { width: 212 }, onChange: (option) => {
                            setSelectedOpps(option);
                        } }))))),
        React.createElement(ShareLinkFormGate, { formGate: formGate, setFormGate: setFormGate }),
        React.createElement(ShareLinkPassword, { password: password, setPassword: setPassword }),
        React.createElement(TModalButtonsContainer, null,
            React.createElement(TButton, { disabled: !(linkName === null || linkName === void 0 ? void 0 : linkName.length), onClick: () => {
                    TourialApiCalls.ShareLinks.patchShareLink(sharelinkData._id, {
                        name: linkName,
                        sfdcAccountId: (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.value) || "",
                        sfdcAccountName: (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.label) || "",
                        sfdcOpportunityId: (selectedOpps === null || selectedOpps === void 0 ? void 0 : selectedOpps.value) || "",
                        sfdcOpportunityName: (selectedOpps === null || selectedOpps === void 0 ? void 0 : selectedOpps.label) || "",
                        password,
                        formGate,
                    })
                        .then(() => {
                        setIsEditShareLinkModalOpen(false);
                        reload();
                    })
                        .catch(() => {
                        setIsEditShareLinkModalOpen(false);
                    });
                } }, "Update link"))));
}
