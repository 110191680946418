import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import React from "react";
export function StepCounter({ style, color, currentStepNumber, totalStepCount }) {
    return (React.createElement(StepcounterContainer, { style: style },
        React.createElement(StepCounterNumbers, { style: { color: color } }, `${currentStepNumber}/${totalStepCount}`)));
}
const StepcounterContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StepCounterNumbers = styled.p `
  text-align: center;
  font: ${newTheme.fonts.small};
`;
