import React from "react";
import TSelect from "../styled/t-select";
import { useToolData, useGroupTools, useAddToolToGroup } from "../../../redux/selectors/redux-selectors";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
import { ToolType } from "../../../../../shared/types/tool-data-types/common";
export function EditorSelectGroup({ toolId }) {
    const groupTools = useGroupTools();
    const addToolToGroup = useAddToolToGroup();
    const tool = useToolData(toolId);
    if (tool &&
        tool.type !== ToolType.Screen &&
        tool.type !== ToolType.Group &&
        tool.type !== ToolType.FormV2 &&
        tool.type !== ToolType.Breadcrumbs &&
        groupTools.length &&
        !tool.groupToolId) {
        return (React.createElement(TSelect, { style: {
                border: `1px solid ${theme.colors.grey.light}`,
                color: theme.colors.grey.darkest,
                height: 24,
                fontSize: "14px",
                padding: 0,
                display: "inline",
                width: "100%",
                margin: "8px 0px",
            }, key: tool.id, value: tool.groupToolId, onChange: e => {
                addToolToGroup(tool.id, e.target.value);
            } },
            React.createElement("option", { value: undefined }, "Add to group"),
            groupTools.map((gt, i) => (React.createElement("option", { key: i, value: gt.tool.id }, gt.tool.name)))));
    }
    else {
        return null;
    }
}
