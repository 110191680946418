import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHTStep, useHTScale, useHTDimensions, useHTIsMobile, useHTTour, useHTSteps, useHTSetIsMobile, useHTTheme, useHTLoadedCaptureId, } from "../../../../redux/selectors/ht-selectors";
import { DTStepType } from "../../../../../../shared/types/dom-tour";
import { useHTLiveMode, useHTEvents, useHTPostViewEvent, useHTLiveHotkeys, useHTUpdateIsMobile, useHTUpdateScale, useHTFetchCaptureLibrary, htUpdateIsMobile, useHTUpdateScaleLive, } from "../hooks/ht-hooks";
import { Intro } from "../components/tools/intro";
import { Outro } from "../components/tools/outro";
import { DTPageFormV3 } from "../../../renderer/fetch-and-render-form-v3";
import IsLoading from "../../../../legacy/components/is-loading/is-loading";
import { HTProgressBar } from "../components/screen/ht-progress-bar";
import { useDemoCenter } from "../../../../redux/selectors/demo-center-selectors";
import { uniq } from "lodash";
import { MobileToolContainer } from "../components/tools/mobile-tool-container";
import { MOBILE_BREAKPOINT, SCREEN_CONTAINER_ID } from "../config";
import { MobileTool } from "../components/tools/mobile-tool";
import { HTTooltip } from "../components/tools/ht-tooltip";
import { Screen } from "../components/screen/screen";
export const LiveDomTour = () => {
    const htTour = useHTTour();
    const isMobile = useHTIsMobile();
    useHTLiveMode();
    useHTLiveHotkeys();
    useEffect(() => {
        if (!htTour)
            return;
        const dimensionsHandler = ({ data }) => {
            if (data.type === "GET_TOURIAL_DIMENSIONS") {
                window.parent.postMessage({ type: "TOURIAL_DIMENSIONS", payload: { width: htTour.width, height: htTour.height } }, "*");
                window.removeEventListener("message", dimensionsHandler);
            }
        };
        window.addEventListener("message", dimensionsHandler);
        return () => {
            window.removeEventListener("message", dimensionsHandler);
        };
    }, [htTour]);
    if (isMobile) {
        return htTour ? (React.createElement(React.Fragment, null,
            React.createElement(DomTourMobileLiveViewport, null),
            React.createElement(HTEvents, null))) : (React.createElement(IsLoading, null));
    }
    return htTour ? (React.createElement(React.Fragment, null,
        React.createElement(DomTourLiveViewport, null),
        React.createElement(HTEvents, null))) : (React.createElement(IsLoading, null));
};
const HTEvents = () => {
    useHTEvents();
    useHTPostViewEvent();
    return null;
};
export function DomTourLiveViewport() {
    const dimensions = useHTDimensions();
    const step = useHTStep();
    const steps = useHTSteps();
    const scale = useHTScale();
    const isMobile = useHTIsMobile();
    const demoCenter = useDemoCenter();
    const captureIds = uniq(steps.map(s => s.captureId));
    const [isLoading, setIsLoading] = useState(true);
    const loadedCaptureId = useHTLoadedCaptureId();
    useHTUpdateScale();
    useHTUpdateIsMobile();
    const renderDesktopTool = () => {
        const isLoaded = loadedCaptureId === step.captureId;
        if (!isLoaded)
            return null;
        switch (step.type) {
            case DTStepType.TOOLTIP:
                return React.createElement(HTTooltip, { key: step.id });
            case DTStepType.INTRO:
                return React.createElement(Intro, null);
            case DTStepType.OUTRO:
                return React.createElement(Outro, null);
            default:
                return null;
        }
    };
    return (React.createElement(Div, null,
        React.createElement(Root, { dimensions: dimensions, scale: scale },
            !isLoading && renderDesktopTool(),
            React.createElement(Screen, { setIsLoading: setIsLoading, captureIds: captureIds }),
            !isLoading && !demoCenter && React.createElement(DTPageFormV3, null)),
        !isMobile ? React.createElement(HTProgressBar, null) : React.createElement(MobileToolContainer, null)));
}
/** temporary solution until actual mobile support is implemented with rrweb-player */
export function DomTourMobileLiveViewport() {
    const { data: captures } = useHTFetchCaptureLibrary();
    const dimensions = useHTDimensions();
    const theme = useHTTheme();
    const step = useHTStep();
    const steps = useHTSteps();
    const scale = useHTScale();
    const demoCenter = useDemoCenter();
    const captureIds = steps.reduce((acc, s) => {
        if (!acc.includes(s.captureId))
            acc.push(s.captureId);
        return acc;
    }, []);
    const setIsMobile = useHTSetIsMobile();
    useHTUpdateScaleLive();
    useEffect(() => {
        if (!dimensions)
            return;
        const handleResize = () => {
            const screenContainer = document.getElementById("tourial-screen-container");
            const screenWidth = screenContainer.getBoundingClientRect().width;
            if (screenWidth > MOBILE_BREAKPOINT) {
                htUpdateIsMobile(setIsMobile);
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [scale]);
    const renderDesktopTool = () => {
        switch (step.type) {
            case DTStepType.INTRO:
                return React.createElement(Intro, null);
            case DTStepType.OUTRO:
                return React.createElement(Outro, null);
            default:
                return null;
        }
    };
    return (React.createElement(Div, null,
        React.createElement(MobileRoot, { dimensions: dimensions, id: SCREEN_CONTAINER_ID, theme: theme, scale: scale },
            React.createElement(DesktopToolWrapper, { dimensions: dimensions, scale: scale }, renderDesktopTool()),
            captures &&
                captureIds.map((captureId, i) => {
                    var _a;
                    return (React.createElement(MobileThumbnail, { key: captureId + i, src: (_a = captures === null || captures === void 0 ? void 0 : captures.find(c => c.id === captureId)) === null || _a === void 0 ? void 0 : _a.thumbnail, active: step.captureId === captureId }));
                }),
            !demoCenter && React.createElement(DTPageFormV3, null)),
        React.createElement(MobileTool, { dimensions: dimensions, scale: scale })));
}
const DesktopToolWrapper = styled.div `
  position: absolute;
  width: ${({ dimensions }) => dimensions.width}px;
  height: ${({ dimensions }) => dimensions.height}px;
  transform: scale(${({ scale }) => scale});
  transform-origin: 0 0;
  z-index: 2;
`;
const MobileThumbnail = styled.img `
  width: 100%;
  height: 100%;
  opacity: ${({ active }) => (active ? 1 : 0)};
  position: absolute;
  object-fit: contain;
`;
const Root = styled.div `
  width: ${({ dimensions }) => dimensions.width}px;
  height: ${({ dimensions }) => dimensions.height}px;
  aspect-ratio: ${({ dimensions }) => dimensions.width / dimensions.height};
  position: absolute;
  background-color: white;
  overflow: hidden;
  transform: scale(${({ scale }) => scale});
  transform-origin: 0 0;
  > #tooltip {
    z-index: 2;
  }
`;
const MobileRoot = styled.div `
  width: ${({ dimensions, scale }) => dimensions.width * scale}px;
  height: ${({ dimensions, scale }) => dimensions.height * scale}px;
  aspect-ratio: ${({ dimensions }) => dimensions.width / dimensions.height};
  position: absolute;
  overflow: hidden;
  background-color: ${({ theme }) => theme.backgroundColor};
`;
const Div = styled.div `
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;
export const LoadingContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
