import { getFromStorage, setInStorage } from "../../../helpers/storage";
import { v4 as uuidv4 } from "uuid";
import { CREATE_ACCOUNT, SET_LOADING_BOOL, SET_SESSION_ID, SET_TOURIAL_AUTH_DATA, UPDATE_VIEWING_ACCOUNT, ADD_TOAST, REMOVE_TOAST, SET_LOAD_EVENT, SET_IS_MICROSITE_SESSION_STARTED, SET_IS_SESSION_STARTED, SET_LATEST_TOURIAL_VIEW_EVENT, SET_IS_DEMOCENTER_SESSION_STARTED, SET_WORKSPACE_ID, SET_SHOW_CHROME_EXTENSION_MODAL, } from "../../actions/legacy-action-types";
import { ActionType } from "../../../redux/actions/redux-actions";
import { NO_OP_MESSAGE } from "../../../redux/middleware/noOpMiddleware";
let { visitorId } = getFromStorage("tourialLive") || {};
const { userId } = getFromStorage("tourialLive") || {};
if (!visitorId) {
    visitorId = userId || uuidv4();
    setInStorage("tourialLive", { visitorId });
}
const initialState = {
    token: "",
    username: "",
    email: "",
    userId: "",
    visitorId,
    userAccess: "",
    loggedInAccount: "",
    accountId: "",
    viewingAccount: {},
    isActive: true,
    isUserVerified: false,
    verificationMessage: "",
    sessionId: "",
    signInEmail: "",
    signInPassword: "",
    signInError: "",
    signUp: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        account: "",
        error: "",
        success: false,
    },
    isLoading: true,
    subscription: null,
    showSignUpForm: false,
    featureFlags: {},
    brandColors: [],
    toasts: [],
    loadEvent: null,
    isSessionStarted: false,
    isMicrositeSessionStarted: false,
    isDemocenterSessionStarted: false,
    latestTourialViewEvent: null,
    account: null,
    workspaceId: getFromStorage("tourialWorkspaceId") || null,
    showChromeExtensionModal: true,
};
export default function (state = initialState, action) {
    var _a, _b, _c;
    switch (action.type) {
        case CREATE_ACCOUNT: {
            // const { data } = action
            return Object.assign(Object.assign({}, state), { isLoading: false });
        }
        // Other
        case SET_LOAD_EVENT: {
            return Object.assign(Object.assign({}, state), { loadEvent: action.loadEvent });
        }
        case SET_IS_MICROSITE_SESSION_STARTED: {
            return Object.assign(Object.assign({}, state), { isMicrositeSessionStarted: action.isMicrositeSessionStarted });
        }
        case SET_IS_SESSION_STARTED: {
            return Object.assign(Object.assign({}, state), { isSessionStarted: action.isSessionStarted });
        }
        case SET_IS_DEMOCENTER_SESSION_STARTED: {
            return Object.assign(Object.assign({}, state), { isDemocenterSessionStarted: action.isDemocenterSessionStarted });
        }
        case SET_LATEST_TOURIAL_VIEW_EVENT: {
            return Object.assign(Object.assign({}, state), { latestTourialViewEvent: action.latestTourialViewEvent });
        }
        case SET_TOURIAL_AUTH_DATA: {
            const { token, username, email, userId, userAccess, loggedInAccount, accountId, viewingAccount, isActive, isLoading, subscription, featureFlags, brandColors, account, showChromeExtensionModal, } = action;
            return Object.assign(Object.assign({}, state), { token,
                username,
                email,
                userId,
                userAccess,
                loggedInAccount,
                accountId,
                viewingAccount,
                isActive,
                isLoading,
                subscription,
                featureFlags,
                brandColors,
                account,
                showChromeExtensionModal });
        }
        case UPDATE_VIEWING_ACCOUNT: {
            return Object.assign(Object.assign({}, state), { viewingAccount: action.viewingAccount, accountId: action.accountId, loggedInAccount: action.loggedInAccount });
        }
        case ActionType.UPDATE_BRAND_COLORS: {
            return Object.assign(Object.assign({}, state), { brandColors: action.brandColors });
        }
        case SET_LOADING_BOOL: {
            return Object.assign(Object.assign({}, state), { isLoading: action.isLoading });
        }
        case SET_SHOW_CHROME_EXTENSION_MODAL: {
            return Object.assign(Object.assign({}, state), { showChromeExtensionModal: action.show });
        }
        case SET_SESSION_ID: {
            return Object.assign(Object.assign({}, state), { sessionId: action.sessionId });
        }
        case SET_WORKSPACE_ID: {
            return Object.assign(Object.assign({}, state), { workspaceId: action.workspaceId });
        }
        case ADD_TOAST: {
            // dont stack the no-op messages
            if (((_c = (_a = state === null || state === void 0 ? void 0 : state.toasts) === null || _a === void 0 ? void 0 : _a[((_b = state === null || state === void 0 ? void 0 : state.toasts) === null || _b === void 0 ? void 0 : _b.length) - 1]) === null || _c === void 0 ? void 0 : _c.message) === NO_OP_MESSAGE &&
                action.toast.message === NO_OP_MESSAGE) {
                return state;
            }
            const id = uuidv4();
            return Object.assign(Object.assign({}, state), { toasts: [...((state === null || state === void 0 ? void 0 : state.toasts) || []), Object.assign(Object.assign({}, action.toast), { id })] });
        }
        case REMOVE_TOAST: {
            const toasts = ((state === null || state === void 0 ? void 0 : state.toasts) || []).filter(t => t.id !== action.id);
            return Object.assign(Object.assign({}, state), { toasts });
        }
        default:
            return state;
    }
}
