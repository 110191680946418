import React from "react";
import { TDropdownSelect } from "../../styled/t-dropdown-select";
import { IContentType } from "../../../../../shared/types/events";
import { useDemoCenterList, useHTList, useTourialList } from "../../../hooks/demo-list-hooks";
export default function ShareLinkCreateDemoSelector({ setSelectedDemoId, setSelectedDemoType, selectedDemoId, }) {
    const tours = useTourialList();
    const democenters = useDemoCenterList();
    const htmlTours = useHTList();
    function createDropdownOptions() {
        const tourOptions = {
            label: "Media Tours",
            options: (tours || []).map(t => ({ label: t.tourName, value: t.tourId })),
        };
        const htOptions = {
            label: "HTML Tours",
            options: (htmlTours || []).map(ht => ({ label: ht.name, value: ht._id })),
        };
        const dcOptions = {
            label: "Demo Centers",
            options: (democenters || []).map(dc => ({ label: dc.name, value: dc._id })),
        };
        return [tourOptions, htOptions, dcOptions];
    }
    const options = createDropdownOptions();
    return (React.createElement("div", null,
        React.createElement("div", { style: { marginBottom: 4 } }, "Select demo"),
        React.createElement(TDropdownSelect, { options: options, selectedValue: selectedDemoId, placeholder: "[None selected]", isSearchable: true, style: { width: 212 }, onChange: (option) => {
                if (options[0].options.find(o => o.value === option.value)) {
                    setSelectedDemoId(option.value);
                    setSelectedDemoType(IContentType.TOUR);
                }
                if (options[1].options.find(o => o.value === option.value)) {
                    setSelectedDemoId(option.value);
                    setSelectedDemoType(IContentType.HTML_TOUR);
                }
                if (options[2].options.find(o => o.value === option.value)) {
                    setSelectedDemoId(option.value);
                    setSelectedDemoType(IContentType.DEMOCENTER);
                }
                else
                    return;
            }, id: "_test_share_link_create_select_demo" })));
}
