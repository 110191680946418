import { cloneDeep } from "lodash";
import { DCBuilderMode, DCMode, DC_SETTINGS_KEY, } from "../../../../shared/types/demo-center";
import { getFromStorage, setInStorage } from "../../helpers/storage";
import { ActionType } from "../actions/redux-actions";
const defaultDemoCenterState = {
    selectedTags: [],
    trackSelections: [],
    activeItemSubNavigation: {
        enabled: false,
        currentPage: 0,
        totalPages: 0,
        lastPageNumberVisited: 0,
    },
    mode: DCMode.IN_APP_PREVIEW,
    viewedItems: [],
    shouldShowWelcomeScreen: true,
    shouldShowUpfrontQuestions: false,
    sidebarIsOpen: false,
    democenterSessionId: null,
    builderMode: DCBuilderMode.MANAGE,
    builderWarnings: [],
    builderIsCollapsed: false,
};
export function demoCenterReducer(prevState = defaultDemoCenterState, action) {
    switch (action.type) {
        case ActionType.SET_DEMOCENTERS: {
            const { draftDemoCenter, savedDemoCenter } = action;
            return Object.assign(Object.assign({}, prevState), { demoCenter: draftDemoCenter, savedDemoCenter });
        }
        case ActionType.SET_DEMOCENTER_DEFAULT_STATE: {
            return defaultDemoCenterState;
        }
        case ActionType.UPDATE_DEMOCENTER: {
            return Object.assign(Object.assign({}, prevState), { demoCenter: Object.assign(Object.assign({}, prevState.demoCenter), cloneDeep(action.demoCenter)) });
        }
        case ActionType.SET_SAVED_DEMOCENTER: {
            return Object.assign(Object.assign({}, prevState), { savedDemoCenter: cloneDeep(action.demoCenter) });
        }
        case ActionType.SET_DEMOCENTER_SELECTED_TAGS: {
            return Object.assign(Object.assign({}, prevState), { selectedTags: cloneDeep(action.selectedTags) });
        }
        case ActionType.SET_DEMOCENTER_TRACK_SELECTIONS: {
            return Object.assign(Object.assign({}, prevState), { trackSelections: cloneDeep(action.trackSelections), forwardPath: cloneDeep(action.forwardPath) });
        }
        case ActionType.SET_DEMOCENTER_MODE: {
            return Object.assign(Object.assign({}, prevState), { mode: cloneDeep(action.mode) });
        }
        case ActionType.SET_DEMOCENTER_ACTIVE_ITEM_ID: {
            return Object.assign(Object.assign({}, prevState), { activeItemId: cloneDeep(action.id), activeItemHasBeenCompleted: false });
        }
        case ActionType.SET_DEMOCENTER_ACTIVE_ITEM_HAS_BEEN_COMPLETED: {
            return Object.assign(Object.assign({}, prevState), { activeItemHasBeenCompleted: cloneDeep(action.hasBeenCompleted) });
        }
        case ActionType.SET_DEMOCENTER_ACTIVE_ITEM_SUB_NAVIGATION: {
            return Object.assign(Object.assign({}, prevState), { activeItemSubNavigation: cloneDeep(action.activeItemSubNavigation) });
        }
        case ActionType.SET_DEMOCENTER_VIEWED_ITEMS: {
            updateDCSettingsInLocalStorage({ viewedItems: cloneDeep(action.items) });
            return Object.assign(Object.assign({}, prevState), { viewedItems: cloneDeep(action.items) });
        }
        case ActionType.SET_DEMOCENTER_ACTIVE_MODULE_SLUG: {
            return Object.assign(Object.assign({}, prevState), { activeModuleSlug: cloneDeep(action.slug) });
        }
        case ActionType.SET_DEMOCENTER_FORWARD_PATH: {
            return Object.assign(Object.assign({}, prevState), { forwardPath: cloneDeep(action.forwardPath) });
        }
        case ActionType.SET_DEMOCENTER_SHOW_WELCOME_SCREEN: {
            return Object.assign(Object.assign({}, prevState), { shouldShowWelcomeScreen: cloneDeep(action.shouldShowWelcomeScreen) });
        }
        case ActionType.SET_DEMOCENTER_SHOW_UPFRONT_QUESTIONS: {
            return Object.assign(Object.assign({}, prevState), { shouldShowUpfrontQuestions: cloneDeep(action.shouldShowUpfrontQuestions) });
        }
        case ActionType.SET_DEMOCENTER_SESSION_ID: {
            return Object.assign(Object.assign({}, prevState), { democenterSessionId: cloneDeep(action.democenterSessionId) });
        }
        case ActionType.SET_DEMOCENTER_SIDEBAR_IS_OPEN: {
            return Object.assign(Object.assign({}, prevState), { sidebarIsOpen: cloneDeep(action.sidebarIsOpen) });
        }
        case ActionType.SET_DEMOCENTER_BUILDER_MODE: {
            return Object.assign(Object.assign({}, prevState), { builderMode: cloneDeep(action.builderMode) });
        }
        case ActionType.SET_DEMOCENTER_BUILDER_WARNINGS: {
            return Object.assign(Object.assign({}, prevState), { builderWarnings: cloneDeep(action.builderWarnings) });
        }
        case ActionType.SET_DEMOCENTER_LIVE_PREVIEW_PATH: {
            return Object.assign(Object.assign({}, prevState), { livePreviewPath: cloneDeep(action.path) });
        }
        case ActionType.SET_DEMOCENTER_BUILDER_IS_COLLAPSED: {
            return Object.assign(Object.assign({}, prevState), { builderIsCollapsed: cloneDeep(action.isCollapsed) });
        }
        case ActionType.SET_DEMOCENTER_SHARE_LINK: {
            return Object.assign(Object.assign({}, prevState), { shareLink: action.shareLink });
        }
        default:
            return prevState;
    }
}
const updateDCSettingsInLocalStorage = (newSettings) => {
    const currentSettings = getFromStorage(DC_SETTINGS_KEY) || {};
    setInStorage(DC_SETTINGS_KEY, Object.assign(Object.assign({}, currentSettings), newSettings));
};
