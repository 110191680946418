var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
export const imageTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/svg+xml", "image/webp"];
export const videoTypes = ["video/mp4", "video/webm", "video/quicktime"];
export const otherTypes = ["application/pdf"];
export const validUploadableMediaTypes = [...imageTypes, ...videoTypes, ...otherTypes];
export const uploadMedia = (fileList, addMediaFn) => __awaiter(void 0, void 0, void 0, function* () {
    let error = null;
    const responses = [];
    for (const file of fileList) {
        try {
            if (file.size >= 99 * 1024 * 1024) {
                error = "file too large";
                continue;
            }
            if (!validUploadableMediaTypes.includes(file.type)) {
                error = "invalid file type";
                continue;
            }
            const { data } = yield axios.post(`/v2/upload/signed`);
            const formData = new FormData();
            formData.append("file", file);
            const cloudinaryResponse = yield axios.post(`${data.apiUrl}?signature=${data.signature}&timestamp=${data.timestamp}&api_key=${data.api_key}`, formData);
            responses.push(cloudinaryResponse.data);
        }
        catch (err) {
            console.error("an error happened");
            console.error(err);
        }
    }
    addMediaFn(responses === null || responses === void 0 ? void 0 : responses.map(res => (Object.assign({ src: res.secure_url, name: res.original_filename.split(".")[0], size: { height: res.height, width: res.width } }, (res.resource_type === "video" && { duration: res.duration })))), error);
});
export function isImageFileExtension(s) {
    if (!s)
        return false;
    const imageTypeEndings = imageTypes.map(t => t.slice(t.indexOf("/") + 1));
    for (const t of imageTypeEndings) {
        if (s.endsWith(t))
            return true;
    }
    return false;
}
