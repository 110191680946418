import React, { createContext } from "react";
import { Four04Page } from "../../404-page";
import { TLoadingSpinner } from "../../../styled/t-loading-spinner";
import { useHTBuilderHotkeys, useHTFetchDraftAndSubscribe, useHTGetTourId, useHTResetOnExit } from "../hooks/ht-hooks";
import { DomTourBuilder } from "./dom-tour-builder";
export const DraftContext = createContext(null);
export default function DomTourBuilderPage() {
    const id = useHTGetTourId();
    const fetch = useHTFetchDraftAndSubscribe(id);
    useHTBuilderHotkeys();
    useHTResetOnExit();
    if (!id)
        return React.createElement(Four04Page, null);
    if (fetch.error) {
        console.error("error fetching draft dom tour", fetch.error);
        return React.createElement("div", null, fetch.error.message);
    }
    if (fetch.data) {
        return (React.createElement(DraftContext.Provider, { value: fetch.data },
            React.createElement(DomTourBuilder, null)));
    }
    return React.createElement(TLoadingSpinner, null);
}
