import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SCREEN_CONTAINER_ID } from "../../config";
import { useHTDimensions, useHTSetLoadedCaptureId, useHTStep, useHTTheme, } from "../../../../../redux/selectors/ht-selectors";
import { useHTFetchCaptureLibrary } from "../../hooks/ht-hooks";
import useHTPlayer from "../../hooks/ht-player";
export const PLAYER_ID = "t-player";
export function Screen({ captureIds, setIsLoading }) {
    const { captureId: activeCaptureId } = useHTStep();
    const { data: captures } = useHTFetchCaptureLibrary();
    const dimensions = useHTDimensions();
    const theme = useHTTheme();
    const [scaleFactor, setScaleFactor] = useState(1);
    const activeCapture = captures === null || captures === void 0 ? void 0 : captures.find(c => c.id === activeCaptureId);
    const setLoadedCaptureId = useHTSetLoadedCaptureId();
    useHTPlayer(captures, captureIds, setIsLoading, setLoadedCaptureId);
    useEffect(() => {
        if (activeCapture) {
            const scaleWidth = dimensions.width / activeCapture.width;
            const scaleHeight = dimensions.height / activeCapture.height;
            setScaleFactor(Math.min(scaleWidth, scaleHeight));
        }
    }, [activeCapture]);
    return (React.createElement(Container, { id: SCREEN_CONTAINER_ID },
        React.createElement(Div, { theme: theme },
            React.createElement(PlayerContainer, { id: PLAYER_ID, capture: activeCapture, scaleFactor: scaleFactor }))));
}
const Div = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.backgroundColor || "#FFFFFF"};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const PlayerContainer = styled.div `
  width: ${({ capture }) => ((capture === null || capture === void 0 ? void 0 : capture.width) ? capture.width + "px" : "100%")};
  height: ${({ capture }) => ((capture === null || capture === void 0 ? void 0 : capture.height) ? capture.height + "px" : "100%")};
  position: absolute;
  transform: scale(${({ scaleFactor }) => scaleFactor});
`;
const Container = styled.div `
  width: 100%;
  height: 100%;
  pointer-events: none;
  box-shadow: 0px 0px 1px 0px rgba(33, 34, 41, 0.4) inset;
  box-shadow: 2px 2px 15px 0px rgba(33, 34, 41, 0.07);
  .replayer-mouse {
    display: none !important;
  }
  .replayer-mouse-tail {
    display: none !important;
  }
  .replayer-wrapper {
    transform: none !important;
  }
  .replayer-wrapper > iframe {
    pointer-events: auto !important;
  }
  .rr-player,
  .rr-player__frame {
    width: 100% !important;
    height: 100% !important;
  }
`;
