import React from "react";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
export default function TNumberInput({ placeholder, value, onChange, style, min, max, step, disabled, }) {
    const baseStyle = Object.assign(Object.assign(Object.assign({}, theme.typography.p), { backgroundColor: theme.colors.white, borderColor: theme.colors.cobalt.light, borderWidth: "2px", borderStyle: "solid", borderRadius: "4px", height: 25, width: 60, boxSizing: "border-box", boxShadow: theme.shadows.shallow, color: theme.colors.cobalt.light, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", padding: "4px", cursor: "pointer", appearance: "none" }), style);
    const disabledStyle = Object.assign(Object.assign({}, baseStyle), { cursor: "not-allowed", backgroundColor: theme.colors.grey.medium, borderColor: theme.colors.grey.darkest, color: theme.colors.grey.darkest, boxShadow: theme.shadows.shallowest });
    return (React.createElement("input", { min: min, max: max, step: step || 1, placeholder: placeholder, type: "number", style: disabled ? disabledStyle : baseStyle, value: value, onChange: e => {
            e.preventDefault();
            onChange(e);
        }, disabled: disabled }));
}
