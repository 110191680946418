import React, { useEffect, useRef, useState } from "react";
import { useAsync } from "react-async";
import styled from "styled-components";
import { InteractionName } from "../../../../../../../shared/types/tool-data-types/interactions";
import { ModalButtonType } from "../../../../../../../shared/types/tool-data-types/modal-data";
import { TourialApiCalls } from "../../../../../helpers/tourial-api-calls";
import { imageTypes, uploadMedia } from "../../../../../helpers/media";
import { usePages } from "../../../../../redux/selectors/redux-selectors";
import { ColorPicker } from "../../../../pages/builder/editor/atomic/color-picker";
import { TextInput } from "../../../../pages/builder/editor/atomic/text-input";
import { ControlLabel } from "../../../../styled/editor-components";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import { TDropdownSelect } from "../../../../styled/t-dropdown-select";
import { getTertiaryInteractionSelectorComponentType, getTertiarySelectorOptions, } from "../../../tooltip-v2/editor/components/trigger-interaction-selector";
import { LogoContainer, LogoControlsContainer, LogoUploadContainer, ReplaceLogoContainer } from "./logo-upload-section";
export const READABLE_BUTTON_OPTIONS = new Map([
    [InteractionName.NEXT_PAGE, "Next page"],
    [InteractionName.JUMP_TO_PAGE, "Jump to page"],
    [InteractionName.NEXT_STEP, "Next step"],
    [InteractionName.PREVIOUS_STEP, "Previous step"],
    [InteractionName.JUMP_TO_STEP, "Jump to step"],
    [InteractionName.EXTERNAL_LINK, "External link"],
    [InteractionName.TRIGGER_FORM, "Trigger form"],
    [InteractionName.CLOSE_MODAL, "Close modal"],
]);
export const ModalButtonSection = ({ updateFn, button, type }) => {
    var _a, _b, _c;
    const pages = usePages();
    const buttonClickOptions = [...READABLE_BUTTON_OPTIONS.entries()].map(([key, value]) => {
        return { label: value, value: key };
    });
    const inputFile = useRef(null);
    const [stepOptions, setStepOptions] = useState(getTertiarySelectorOptions(button.interaction.name, pages));
    const [formOptions, setFormOptions] = useState([]);
    const { isLoading: isFormListLoading } = useAsync({
        promiseFn: TourialApiCalls.Forms.getForms,
        onResolve: (forms) => {
            setFormOptions(forms.map(f => {
                return { label: f.name, value: f._id };
            }));
        },
    });
    useEffect(() => {
        setStepOptions(getTertiarySelectorOptions(button.interaction.name, pages));
    }, [button.interaction.name]);
    return (React.createElement("div", null,
        type === ModalButtonType.JUMBO && (React.createElement("div", null,
            React.createElement("input", { multiple: false, type: "file", accept: `${imageTypes.join("|")}`, ref: inputFile, style: { display: "none" }, onChange: e => {
                    void uploadMedia(e.target.files, (media) => {
                        var _a;
                        updateFn(Object.assign(Object.assign({}, button), { src: (_a = media[0]) === null || _a === void 0 ? void 0 : _a.src }));
                    });
                } }),
            button.src ? (React.createElement(React.Fragment, null,
                React.createElement(LogoControlsContainer, null,
                    React.createElement(LogoContainer, null,
                        React.createElement("img", { src: button.src, alt: "logo" }),
                        React.createElement(ReplaceLogoContainer, null,
                            React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY, onClick: () => {
                                    inputFile.current.click();
                                } }, "Replace Image")))))) : (React.createElement(LogoUploadContainer, null,
                React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY, onClick: () => {
                        inputFile.current.click();
                    } }, "Add Image"),
                React.createElement("span", null, "Recommended size: 160 x 160px"))))),
        React.createElement(ControlContainer, null,
            React.createElement(ControlLabel, null, "Button Text"),
            React.createElement(TextInput, { style: { width: "178px" }, value: button.text, onChange: (e) => {
                    updateFn(Object.assign(Object.assign({}, button), { text: e.target.value }));
                } })),
        React.createElement(ControlContainer, null,
            React.createElement(ControlLabel, null, "On Click"),
            React.createElement(TDropdownSelect, { isSearchable: false, style: { width: "178px" }, selectedValue: button.interaction.name, onChange: (e) => {
                    updateFn(Object.assign(Object.assign({}, button), { interaction: Object.assign(Object.assign({}, button.interaction), { name: e.value }) }));
                }, options: buttonClickOptions })),
        getTertiaryInteractionSelectorComponentType(button.interaction.name) === "selector" && (React.createElement(TDropdownSelect, { isSearchable: true, style: { marginTop: "16px" }, options: stepOptions, selectedValue: (_a = stepOptions.find(so => {
                var _a, _b, _c, _d;
                return so.value.pageId === ((_b = (_a = button.interaction.target) === null || _a === void 0 ? void 0 : _a.targetView) === null || _b === void 0 ? void 0 : _b.pageId) &&
                    so.value.zoomId === ((_d = (_c = button.interaction.target) === null || _c === void 0 ? void 0 : _c.targetView) === null || _d === void 0 ? void 0 : _d.zoomId);
            })) === null || _a === void 0 ? void 0 : _a.value, onChange: (e) => {
                updateFn(Object.assign(Object.assign({}, button), { interaction: Object.assign(Object.assign({}, button.interaction), { target: { targetView: e.value } }) }));
            } })),
        getTertiaryInteractionSelectorComponentType(button.interaction.name) === "input" && (React.createElement(TextInput, { style: { marginTop: "16px" }, placeholder: "https://", value: ((_b = button.interaction.target) === null || _b === void 0 ? void 0 : _b.targetExternalLink) || "", onChange: (e) => {
                updateFn(Object.assign(Object.assign({}, button), { interaction: Object.assign(Object.assign({}, button.interaction), { target: { targetExternalLink: e.target.value } }) }));
            } })),
        getTertiaryInteractionSelectorComponentType(button.interaction.name) === "form" && !isFormListLoading && (React.createElement(TDropdownSelect, { isSearchable: true, style: { marginTop: "16px" }, selectedValue: (_c = button.interaction.target) === null || _c === void 0 ? void 0 : _c.targetFormId, options: formOptions, onChange: (e) => {
                updateFn(Object.assign(Object.assign({}, button), { interaction: Object.assign(Object.assign({}, button.interaction), { target: { targetFormId: e.value } }) }));
            } })),
        React.createElement(ControlContainer, null,
            React.createElement(ControlLabel, null, "Text Color"),
            React.createElement(ColorPicker, { color: button.textColor, onChange: color => {
                    updateFn(Object.assign(Object.assign({}, button), { textColor: color }));
                } })),
        React.createElement(ControlContainer, null,
            React.createElement(ControlLabel, null, "Background Color"),
            React.createElement(ColorPicker, { color: button.backgroundColor, onChange: color => {
                    updateFn(Object.assign(Object.assign({}, button), { backgroundColor: color }));
                } })),
        React.createElement(ControlContainer, null,
            React.createElement(ControlLabel, null, "Border Color"),
            React.createElement(ColorPicker, { color: button.borderColor, onChange: color => {
                    updateFn(Object.assign(Object.assign({}, button), { borderColor: color }));
                } }))));
};
const ControlContainer = styled.div `
  display: flex;
  margin-top: 16px;
  height: 32px;
  align-items: center;
`;
