import React from "react";
import { ViewportMode } from "../../../types/definitions";
import TP from "../styled/t-p";
import { withToolWrapper } from "../with-tool-wrapper";
import { FileLoadingIndicator } from "../../../components/shared/file-loading-indicator";
export function getMaskDimensions(shape, srcDimensions) {
    if (shape === "circle") {
        if (srcDimensions.width >= srcDimensions.height) {
            return {
                width: 100 / (srcDimensions.width / srcDimensions.height),
                height: 100,
            };
        }
        return {
            width: 100,
            height: 100 / (srcDimensions.height / srcDimensions.width),
        };
    }
    return { width: 100, height: 100 };
}
export function getImageDimensions(shape, srcDimensions) {
    if (shape === "circle") {
        if (srcDimensions.width >= srcDimensions.height) {
            return {
                width: 100 * (srcDimensions.width / srcDimensions.height),
                height: 100,
            };
        }
        return {
            width: 100,
            height: 100 * (srcDimensions.height / srcDimensions.width),
        };
    }
    return { width: 100, height: 100 };
}
export const loadingLabel = () => {
    return React.createElement(TP, null, "Uploading Media ...");
};
export function TourialImage(props) {
    const { toolData, viewportMode } = props;
    const { shape, src, xOffset, yOffset, srcDimensions, id, animation, animationDuration } = toolData;
    if (toolData.isLoading) {
        return (React.createElement(FileLoadingIndicator, { viewportHeight: props.viewportDimensions.height, viewportWidth: props.viewportDimensions.width, toolHeight: toolData.sizeAndPosition.height, toolWidth: toolData.sizeAndPosition.width, id: toolData.id, toolType: toolData.type }));
    }
    return (React.createElement("div", { style: {
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            animation: viewportMode !== ViewportMode.EDIT && animation,
            animationDuration: viewportMode !== ViewportMode.EDIT && animationDuration,
        } },
        React.createElement("div", { style: {
                position: "absolute",
                overflow: "hidden",
                display: "inline",
                height: `${getMaskDimensions(shape, srcDimensions).height}%`,
                width: `${getMaskDimensions(shape, srcDimensions).width}%`,
                borderRadius: shape === "rectangle" ? "0%" : "50%",
            } },
            React.createElement("img", { id: `${id}_img`, draggable: false, src: src, style: {
                    margin: "0 auto",
                    width: `${getImageDimensions(shape, srcDimensions).width}%`,
                    height: `${getImageDimensions(shape, srcDimensions).height}%`,
                    position: "relative",
                    top: `${shape === "circle" ? yOffset : 0}%`,
                    left: `${shape === "circle" ? xOffset : 0}%`,
                    display: "block",
                } }))));
}
export const Image = React.memo(withToolWrapper(TourialImage, null));
