import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
const StepButton = styled.button `
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  font: ${newTheme.fonts.small};
  cursor: pointer;
`;
export const InactiveStepButton = styled(StepButton) `
  color: ${newTheme.colors.black};
  background-color: ${newTheme.colors.white};
  border: 1px solid ${newTheme.colors.grey300};
  &:hover {
    border: 1px solid ${newTheme.colors.grey400};
    box-shadow:
      2px 2px 15px rgba(33, 34, 41, 0.07),
      inset 0px 0px 1px rgba(33, 34, 41, 0.4);
  }
`;
export const ActiveStepButton = styled(StepButton) `
  background-color: ${({ isAutomated }) => (isAutomated ? newTheme.colors.yellow500 : newTheme.colors.blue500)};
  color: ${({ isAutomated }) => (isAutomated ? newTheme.colors.black : newTheme.colors.white)};
  border: 1px solid ${({ isAutomated }) => (isAutomated ? newTheme.colors.yellow500 : newTheme.colors.blue500)};
  &:hover {
    box-shadow:
      2px 2px 15px rgba(33, 34, 41, 0.07),
      inset 0px 0px 1px rgba(33, 34, 41, 0.4);
  }
`;
export const AddStepButton = styled(StepButton) `
  border: 1px dashed ${newTheme.colors.grey300};
  background-color: ${newTheme.colors.grey200};
  &:hover {
    background-color: ${newTheme.colors.white};
    color: ${newTheme.colors.blue400};
    border: 1px solid ${newTheme.colors.grey300};
    box-shadow:
      2px 2px 15px rgba(33, 34, 41, 0.07),
      inset 0px 0px 1px rgba(33, 34, 41, 0.4);
  }
`;
