var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, keyframes } from "styled-components";
import { isImageFileExtension } from "./media";
import { MediaType } from "../types/definitions";
export function getElRect(id) {
    var _a;
    return ((_a = document === null || document === void 0 ? void 0 : document.getElementById(id)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) || null;
}
export function getImageDimensions(src) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            if (!isImageFileExtension(src)) {
                const vid = document.createElement(MediaType.VIDEO);
                vid.onloadedmetadata = () => resolve({ width: vid.videoWidth, height: vid.videoHeight });
                vid.onerror = reject;
                vid.src = `${src}#t=0.1`;
            }
            else {
                const img = new Image();
                img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
                img.onerror = reject;
                img.src = src;
            }
        });
    });
}
export function clickzoneAnimationBuilder(pulseColor) {
    const animate = keyframes `
    0% {
      box-shadow: 0 0 0 0px
        rgba(${pulseColor.r}, ${pulseColor.g}, ${pulseColor.b}, 0.5);
    }
    100% {
      box-shadow: 0 0 0 20px
        rgba(${pulseColor.r}, ${pulseColor.g}, ${pulseColor.b}, 0);
    }
  `;
    return css `
    ${animate} 2s infinite
  `;
}
export function hotspotAnimationBuilder(pulseColor) {
    const animate = keyframes `
    0% {
      box-shadow: 0 0 0 0px
        rgba(${pulseColor.r}, ${pulseColor.g}, ${pulseColor.b}, 0.5);
    }
    100% {
      box-shadow: 0 0 0 20px
        rgba(${pulseColor.r}, ${pulseColor.g}, ${pulseColor.b}, 0);
    }
  `;
    return css `
    ${animate} 2s infinite
  `;
}
export function bubbleAnimationBuilder(direction) {
    const animations = {
        ["pop-in-top"]: keyframes `
    0% {
      opacity: 0;
      top: 30px;
    }
    100% {
      opacity: 1;
      top: 0px;
    }
  `,
        ["pop-in-bottom"]: keyframes `
    0% {
      opacity: 0;
      bottom: 30px;
    }
    100% {
      opacity: 1;
      bottom: 0px;
    }
  `,
        ["pop-in-left"]: keyframes `
    0% {
      opacity: 0;
      left: 30px;
    }
    100% {
      opacity: 1;
      left: 0px;
    }
  `,
        ["pop-in-right"]: keyframes `
    0% {
      opacity: 0;
      right: 30px;
    }
    100% {
      opacity: 1;
      right: 0px;
    }
  `,
    };
    return css `
    ${animations[direction]} 0.15s
  `;
}
export function spotlightAnimationBuilder() {
    const animate = keyframes `
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;
    return css `
    ${animate} 0.1s
  `;
}
export function upsertSearchChanges(params, history) {
    try {
        const newSearchParams = new URLSearchParams();
        for (const key in params) {
            newSearchParams.append(key, params[key]);
        }
        const currentSearchParams = new URLSearchParams(history.location.search);
        newSearchParams.forEach((value, key) => currentSearchParams.set(key, value));
        history.push(`?${currentSearchParams.toString()}`);
    }
    catch (err) {
        console.error(err);
    }
}
export function removeSearchParam(param, history) {
    try {
        const params = new URLSearchParams(history.location.search);
        params.delete(param);
        const url = new URL(window.location.href);
        url.search = params.toString();
        window.history.replaceState(null, null, url.href);
    }
    catch (err) {
        console.error(err);
    }
}
export function isMarkupTextEmpty(html) {
    if (!html)
        return true;
    const visibleContent = html.replace(/<[^>]*>/g, "").trim();
    return !/\S/.test(visibleContent);
}
export const getHTDurationEstimationInSeconds = (tourial) => {
    var _a;
    const SECONDS_PER_SCREEN = 5;
    const screenCount = ((_a = tourial.steps) === null || _a === void 0 ? void 0 : _a.length) || 0;
    return screenCount * SECONDS_PER_SCREEN;
};
/** updates the innerHTML to match the value property for textareas in the input doc */
export const sanitizeTextareas = (doc) => {
    if (!doc)
        return;
    const textareas = doc.getElementsByTagName("textarea");
    if (textareas.length) {
        for (const textarea of textareas) {
            textarea.innerHTML = textarea.value;
        }
    }
};
