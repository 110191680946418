import { MicrositeEditorLayout, MicrositeEditorMode } from "../../../../shared/types/microsite";
import { ActionType } from "../actions/redux-actions";
const defaultMicrositeState = {
    activeTourIndex: null,
    editorMode: MicrositeEditorMode.EDIT,
    editorLayout: MicrositeEditorLayout.DESKTOP,
    canPublish: true,
};
export function micrositeReducer(prevState = defaultMicrositeState, action) {
    switch (action.type) {
        case ActionType.SET_MICROSITE: {
            return Object.assign(Object.assign({}, prevState), { microsite: action.microsite, savedMicrosite: action.microsite });
        }
        case ActionType.UPDATE_MICROSITE: {
            return Object.assign(Object.assign({}, prevState), { microsite: action.microsite });
        }
        case ActionType.SET_SAVED_MICROSITE: {
            return Object.assign(Object.assign({}, prevState), { savedMicrosite: action.microsite });
        }
        case ActionType.UPDATE_MICROSITE_TOURS: {
            return Object.assign(Object.assign({}, prevState), { microsite: Object.assign(Object.assign({}, prevState.microsite), { tours: action.tours }) });
        }
        case ActionType.SET_MICROSITE_ACTIVE_TOUR_ID: {
            return Object.assign(Object.assign({}, prevState), { activeTourId: action.id });
        }
        case ActionType.SET_MICROSITE_EDITOR_MODE: {
            return Object.assign(Object.assign({}, prevState), { editorMode: action.mode });
        }
        case ActionType.SET_MICROSITE_EDITOR_LAYOUT: {
            return Object.assign(Object.assign({}, prevState), { editorLayout: action.layout });
        }
        case ActionType.SET_MICROSITE_CAN_PUBLISH: {
            return Object.assign(Object.assign({}, prevState), { canPublish: action.canPublish });
        }
        case ActionType.SET_MICROSITE_SESSION_ID: {
            const { micrositeSessionId } = action;
            return Object.assign(Object.assign({}, prevState), { micrositeSessionId });
        }
        case ActionType.SET_MICROSITE_EDITOR_OPEN_MENU: {
            const { editorOpenMenu } = action;
            return Object.assign(Object.assign({}, prevState), { editorOpenMenu });
        }
        case ActionType.SET_MICROSITE_EDITOR_FOCUS_FIELD: {
            const { editorFocusField } = action;
            return Object.assign(Object.assign({}, prevState), { editorFocusField });
        }
        case ActionType.SET_ACTIVE_TOUR_INDEX: {
            const { activeTourIndex } = action;
            return Object.assign(Object.assign({}, prevState), { activeTourIndex });
        }
        case ActionType.SAVE_MICROSITE_HTML_METADATA: {
            const { htmlMetadata } = action;
            return Object.assign(Object.assign({}, prevState), { microsite: Object.assign(Object.assign({}, prevState.microsite), { htmlMetadata }), savedMicrosite: Object.assign(Object.assign({}, prevState.savedMicrosite), { htmlMetadata }) });
        }
        case ActionType.SAVE_MICROSITE_SLUG: {
            const { slug } = action;
            return Object.assign(Object.assign({}, prevState), { microsite: Object.assign(Object.assign({}, prevState.microsite), { slug }), savedMicrosite: Object.assign(Object.assign({}, prevState.savedMicrosite), { slug }) });
        }
        case ActionType.SAVE_MICROSITE_IS_PUBLISHED: {
            const { isPublished } = action;
            return Object.assign(Object.assign({}, prevState), { microsite: Object.assign(Object.assign({}, prevState.microsite), { isPublished }), savedMicrosite: Object.assign(Object.assign({}, prevState.savedMicrosite), { isPublished }) });
        }
        default:
            return prevState;
    }
}
