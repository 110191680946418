import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Popover } from "@blueprintjs/core";
import { newTheme } from "../../styled/theme";
import { getDateStringWithOrdinalNum } from "../../../../../shared/functions/helpers";
import VerticalDots from "../../../../assets/images/v3/vertical-dots.svg";
import BluePencilIcon from "../../../../assets/images/v3/blue-pencil.svg";
import BlueCopyIcon from "../../../../assets/images/v3/blue-copy.svg";
import MoveToWorkspaceIcon from "../../../../assets/images/v3/move-to-workspace.svg";
import BluePauseIcon from "../../../../assets/images/v3/blue-pause.svg";
import BluePublishIcon from "../../../../assets/images/v3/blue-publish.svg";
import BlueShareIcon from "../../../../assets/images/v3/share-icon-blue.svg";
import RedTrashcanIcon from "../../../../assets/images/v3/red-trashcan.svg";
import ExternalCloneIcon from "../../../../assets/images/v3/external-clone.svg";
import ExportScreensIcon from "../../../../assets/images/v3/export-screens.svg";
import AnalyticsIcon from "../../../../assets/images/v3/analytics-outline.svg";
import ArchiveIcon from "../../../../assets/images/v3/archive-icon.svg";
import { useOutsideClick } from "../../../hooks/use-outside-click";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import Thumbnail from "./thumbnail";
import DeleteModal from "./delete-modal";
import RenameModal from "./rename-modal";
import PauseModal from "./pause-modal";
import MoveTourialToWorkspace from "./workspace-move-to-modal";
import { useUserAccess, useFeatureFlags } from "../../../redux/selectors/redux-selectors";
import ExternalCloneModal from "./external-clone-modal";
import TStatusIndicator from "../../styled/t-status-indicator";
import ThumbnailViewport from "../../renderer/thumbnail/thumbnail-viewport";
import TourShareModal from "./tour-share-modal";
import { useAddToast } from "../../../legacy/selectors";
import { ToastModes } from "../../../types/definitions";
import { useHistory } from "react-router-dom";
import { isAuthorized } from "../../../../../shared/functions/auth-functions";
import { AccessLevel } from "../../../../../shared/types/auth-types";
import ArchiveModal from "./archive-modal";
import { TourialIcons } from "../../shared/tourial-icons";
import { DropdownContainer, DropdownOption } from "./tourial-list";
import { getTourialStatus } from "../../../helpers/tourial";
import DuplicateTourialToWorkspace from "./duplicate-modal";
export const TOURIAL_CARD_WIDTH = 240;
export var DropdownOptions;
(function (DropdownOptions) {
    DropdownOptions["RENAME"] = "Rename";
    DropdownOptions["SHARE"] = "Share";
    DropdownOptions["PUBLISH_UNPUBLISH"] = "Publish/Unpublish";
    DropdownOptions["ANALYTICS"] = "Analytics";
    DropdownOptions["DUPLICATE"] = "Duplicate";
    DropdownOptions["ARCHIVE"] = "Archive";
    DropdownOptions["EXPORT_SCREENS"] = "Export screens";
    DropdownOptions["DELETE"] = "Delete";
    DropdownOptions["EXTERNAL_CLONE"] = "External clone";
    DropdownOptions["MOVE_TO_WORKSPACE"] = "Move to workspace";
})(DropdownOptions || (DropdownOptions = {}));
export function TourialCard({ tourialData, reload, baseUrlWithSubdomain }) {
    const { version } = tourialData;
    const isV3 = version === 3;
    const formattedDate = getDateStringWithOrdinalNum(`${tourialData.updatedAt || tourialData.createdAt}`);
    const { workspaces } = useFeatureFlags();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
    const [isMoveToWorkspaceModalOpen, setIsMoveToWorkspaceModalOpen] = useState(false);
    const [isDuplicateToWorkspaceModalOpen, setIsDuplicateToWorkspaceModalOpen] = useState(false);
    const [isExternalCloneModalOpen, setIsExternalCloneModalOpen] = useState(false);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const userAccess = useUserAccess();
    const history = useHistory();
    const addToast = useAddToast();
    const dropdownRef = useRef();
    useOutsideClick([dropdownRef], () => {
        if (isDropdownOpen && !isDeleteModalOpen && !isRenameModalOpen && !isPauseModalOpen && !isExternalCloneModalOpen)
            setIsDropdownOpen(false);
    });
    const dropdownOptionsMap = new Map();
    const isEditor = isAuthorized(AccessLevel.EDITOR, userAccess);
    if (isEditor && !tourialData.archivedAt) {
        dropdownOptionsMap.set(DropdownOptions.RENAME, {
            src: BluePencilIcon,
            label: "Rename",
            onClick: () => setIsRenameModalOpen(true),
        });
    }
    if (tourialData.isPublished) {
        dropdownOptionsMap.set(DropdownOptions.SHARE, {
            src: BlueShareIcon,
            label: "Share",
            onClick: () => setIsShareModalOpen(true),
        });
    }
    if (userAccess !== AccessLevel.COLLABORATOR && isEditor && !tourialData.archivedAt) {
        dropdownOptionsMap.set(DropdownOptions.PUBLISH_UNPUBLISH, {
            src: tourialData.isPublished ? BluePauseIcon : BluePublishIcon,
            label: tourialData.isPublished ? "Unpublish" : "Publish",
            onClick: () => {
                if (tourialData.isPublished) {
                    if (tourialData.isInMicrosite) {
                        addToast({ message: "Cannot unpublish a tour featured in a TourPlayer", mode: ToastModes.ERROR });
                        return;
                    }
                    setIsPauseModalOpen(true);
                }
                else {
                    TourialApiCalls.Tourials.postTourialPublish(tourialData.tourId, !tourialData.isPublished)
                        .then(() => {
                        reload();
                        setIsDropdownOpen(false);
                        addToast({ message: "Publish successful", mode: ToastModes.SUCCESS });
                    })
                        .catch(e => {
                        console.error(e);
                        addToast({ message: "Publish failed", mode: ToastModes.ERROR });
                    });
                }
            },
        });
    }
    if (tourialData.isPublished || tourialData.interactedAt || tourialData.archivedAt) {
        dropdownOptionsMap.set(DropdownOptions.ANALYTICS, {
            src: AnalyticsIcon,
            label: "Analytics",
            onClick: () => {
                history.push(`/analytics?tourId=${tourialData.tourId}`);
            },
        });
    }
    if (isEditor) {
        dropdownOptionsMap.set(DropdownOptions.DUPLICATE, {
            src: BlueCopyIcon,
            label: "Duplicate",
            onClick: () => {
                if (workspaces) {
                    setIsDuplicateToWorkspaceModalOpen(true);
                }
                else {
                    TourialApiCalls.Tourials.postTourialClone(tourialData.tourId)
                        .then(() => {
                        reload();
                        setIsDropdownOpen(false);
                        addToast({ message: "Duplicate successful", mode: ToastModes.SUCCESS });
                    })
                        .catch(e => {
                        console.error(e);
                        addToast({ message: "Duplicate failed", mode: ToastModes.ERROR });
                    });
                }
            },
        });
    }
    if (isEditor && workspaces) {
        dropdownOptionsMap.set(DropdownOptions.MOVE_TO_WORKSPACE, {
            src: MoveToWorkspaceIcon,
            label: "Move to Workspace",
            onClick: () => {
                setIsMoveToWorkspaceModalOpen(true);
            },
        });
    }
    if (isEditor && !tourialData.isPublished && tourialData.interactedAt && !tourialData.archivedAt) {
        dropdownOptionsMap.set(DropdownOptions.ARCHIVE, {
            src: ArchiveIcon,
            label: "Archive",
            onClick: () => {
                if (tourialData.isInMicrosite) {
                    addToast({ message: "Cannot archive a tour featured in a TourPlayer", mode: ToastModes.ERROR });
                    return;
                }
                setIsArchiveModalOpen(true);
            },
        });
    }
    if (isEditor && !isV3) {
        dropdownOptionsMap.set(DropdownOptions.EXPORT_SCREENS, {
            src: ExportScreensIcon,
            label: "Export screens to new tour",
            onClick: () => {
                TourialApiCalls.Tourials.postTourialCloneV2toV3(tourialData.tourId)
                    .then(() => {
                    reload();
                    setIsDropdownOpen(false);
                    addToast({ message: "Export successful", mode: ToastModes.SUCCESS });
                })
                    .catch(e => {
                    console.error(e);
                    addToast({ message: "Export failed", mode: ToastModes.ERROR });
                });
            },
        });
    }
    if (isEditor && !tourialData.isPublished) {
        dropdownOptionsMap.set(DropdownOptions.DELETE, {
            src: RedTrashcanIcon,
            label: "Delete",
            style: { color: newTheme.colors.red500 },
            onClick: () => setIsDeleteModalOpen(true),
        });
    }
    if (isEditor && isAuthorized(AccessLevel.SUPERADMIN, userAccess)) {
        dropdownOptionsMap.set(DropdownOptions.EXTERNAL_CLONE, {
            src: ExternalCloneIcon,
            label: "External clone",
            style: {
                background: newTheme.colors.grey100,
                borderTop: `1px solid ${newTheme.colors.grey300}`,
            },
            onClick: () => setIsExternalCloneModalOpen(true),
        });
    }
    const dropdownOptions = [...dropdownOptionsMap.values()];
    const tourialStatus = getTourialStatus(tourialData);
    return (React.createElement(TourialCardContainer, { style: Object.assign({}, (isV3
            ? {
                width: `${TOURIAL_CARD_WIDTH}px`,
                borderRadius: "8px",
            }
            : {
                width: "100%",
                height: 81,
                display: "flex",
                outline: `1px solid ${newTheme.colors.grey200}`,
            })), className: "_test_tourial-card" },
        isV3 ? (React.createElement("div", { style: { height: 135, width: "100%" } },
            React.createElement(ThumbnailViewport, { tourialData: tourialData, isLink: true, style: {
                    margin: 0,
                    transformOrigin: "top left",
                    borderRadius: 8,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }, scale: 0.8856 }))) : (React.createElement("div", { style: { height: "100%", width: 120 } },
            React.createElement(Thumbnail, { tourialData: tourialData, scale: 0.36, style: { transformOrigin: "top left" }, baseUrlWithSubdomain: baseUrlWithSubdomain }))),
        React.createElement(CardBottomContainer, { style: Object.assign({ display: "flex", position: "relative" }, (isV3
                ? {
                    boxShadow: `inset 0px 1px 0px #e8e8e8`,
                }
                : { width: "calc(100% - 120px)", padding: 16 })) },
            React.createElement("div", { style: { display: "flex" } },
                React.createElement("div", { style: { font: newTheme.fonts.normal, flexGrow: 1, fontWeight: 700, width: "100%" } },
                    React.createElement(TitleSpan, { style: { width: isV3 ? `${TOURIAL_CARD_WIDTH - 20}px` : "calc(100% - 25px)" } }, tourialData.tourName),
                    isV3 ? (React.createElement(React.Fragment, null,
                        React.createElement(DateSpan, null, tourialData.updatedAt ? `edited on ${formattedDate}` : `created on ${formattedDate}`),
                        React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                            React.createElement("div", { style: { display: "flex", gap: 8 } },
                                React.createElement(TStatusIndicator, { status: tourialStatus }),
                                React.createElement(TourialIcons, { isInMicrosite: tourialData.isInMicrosite, hasMobileVariant: tourialData.hasMobileVariant, formIntegrations: tourialData.formIntegrations, isHomePage: true, isInDemoCenter: false }))))) : (React.createElement(React.Fragment, null,
                        React.createElement(TStatusIndicator, { status: tourialStatus }),
                        React.createElement("span", { style: { font: newTheme.fonts.small, marginLeft: 8, color: newTheme.colors.grey700 } }, tourialData.updatedAt ? `edited on ${formattedDate}` : `created on ${formattedDate}`),
                        " ")))),
            React.createElement("div", { style: { position: "absolute", bottom: isV3 ? 6 : "auto", right: 12 } },
                React.createElement(Popover, { minimal: true, isOpen: isDropdownOpen, position: "bottom-right", popoverClassName: "tourial-card-dropdown", content: React.createElement(DropdownContainer, { className: "_test_tourial-card-dropdown", onClick: () => setIsDropdownOpen(!isDropdownOpen), style: { width: isV3 ? 180 : 230, marginTop: isV3 ? 0 : 12.5 } }, dropdownOptions.map(option => {
                        const { src, label, style, onClick } = option;
                        return (React.createElement(DropdownOption, { key: label, style: style, onClick: onClick },
                            React.createElement("img", { src: src, style: { margin: "8px 8px 8px 16px", display: "inline" } }),
                            " ",
                            label));
                    })) },
                    React.createElement("img", { style: Object.assign({ minWidth: 24, height: 24, cursor: "pointer" }, (isV3 ? {} : { position: "relative", top: 13 })), src: VerticalDots, ref: dropdownRef, onClick: () => setIsDropdownOpen(!isDropdownOpen), className: "_test_vertical-dots" })))),
        isDeleteModalOpen && (React.createElement(DeleteModal, { setIsDeleteModalOpen: setIsDeleteModalOpen, onConfirmation: () => {
                TourialApiCalls.Tourials.deleteTourial(tourialData.tourId)
                    .then(() => {
                    setIsDeleteModalOpen(false);
                    setIsDropdownOpen(false);
                    addToast({ message: "Delete successful", mode: ToastModes.SUCCESS });
                    reload();
                })
                    .catch(e => {
                    console.error(e);
                    addToast({ message: "Delete failed", mode: ToastModes.ERROR });
                });
            } })),
        isRenameModalOpen && (React.createElement(RenameModal, { reload: reload, setIsRenameModalOpen: setIsRenameModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData, addToast: addToast })),
        isShareModalOpen && (React.createElement(TourShareModal, { setIsShareModalOpen: setIsShareModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData })),
        isPauseModalOpen && (React.createElement(PauseModal, { reload: reload, setIsPauseModalOpen: setIsPauseModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData, addToast: addToast })),
        isExternalCloneModalOpen && (React.createElement(ExternalCloneModal, { reload: reload, setIsExternalCloneModalOpen: setIsExternalCloneModalOpen, setIsDropdownOpen: setIsDropdownOpen, originalId: tourialData.tourId, addToast: addToast, cloneType: "tourial" })),
        isArchiveModalOpen && (React.createElement(ArchiveModal, { reload: reload, setIsArchiveModalOpen: setIsArchiveModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData, addToast: addToast })),
        isMoveToWorkspaceModalOpen && (React.createElement(MoveTourialToWorkspace, { reload: reload, setIsMoveToWorkspaceModalOpen: setIsMoveToWorkspaceModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData, addToast: addToast })),
        isDuplicateToWorkspaceModalOpen && (React.createElement(DuplicateTourialToWorkspace, { reload: reload, setIsDuplicateToWorkspaceModalOpen: setIsDuplicateToWorkspaceModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData, addToast: addToast }))));
}
const TourialCardContainer = styled.div `
  ${newTheme.patterns.polkaDot}
  height: fit-content;
  border: 1px solid ${newTheme.colors.grey300};
  box-sizing: content-box;
  box-shadow:
    2px 2px 10px rgba(33, 34, 41, 0.05),
    inset 0px 0px 1px rgba(33, 34, 41, 0.4);
  &:hover {
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    box-shadow:
      0px 0px 1px rgba(48, 49, 51, 0.05),
      0px 8px 16px rgba(48, 49, 51, 0.1);
  }
`;
const CardBottomContainer = styled.div `
  background: ${newTheme.colors.grey100};
  height: fit-content;
  padding: 12px;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
const TitleSpan = styled.span `
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const DateSpan = styled.span `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey700};
  height: 14px;
  display: block;
  margin: 4px 0 7px;
`;
