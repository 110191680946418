import React from "react";
import styled from "styled-components";
export const MicrositeProgressBar = (props) => {
    const { percent, barColor, backgroundColor, arrowColor, leftAction, rightAction, isArrowEnabled, isBarEnabled, style, } = props;
    return (React.createElement(ProgressBarContainer, { style: style },
        isArrowEnabled && (React.createElement(ArrowContainer, { style: { color: arrowColor }, onClick: leftAction },
            React.createElement(LeftArrow, null))),
        isBarEnabled && (React.createElement(OuterProgressBar, { style: { backgroundColor: backgroundColor } },
            React.createElement(InnerProgressBar, { style: { width: `${percent}%`, backgroundColor: barColor } }))),
        isArrowEnabled && (React.createElement(ArrowContainer, { style: { color: arrowColor }, onClick: rightAction },
            React.createElement(RightArrow, null)))));
};
export const LeftArrow = () => {
    return (React.createElement("svg", { width: "18", height: "14", viewBox: "0 0 18 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M7.22222 13.2227L1 7.00043M1 7.00043L7.22222 0.778212M1 7.00043L17 7.00043", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })));
};
export const RightArrow = () => {
    return (React.createElement("svg", { width: "18", height: "14", viewBox: "0 0 18 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M10.7778 0.777344L17 6.99957M17 6.99957L10.7778 13.2218M17 6.99957L1 6.99957", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })));
};
const ProgressBarContainer = styled.div `
  margin: 12px 0;
  display: flex;
  align-items: center;
`;
const ArrowContainer = styled.div `
  width: 18px;
  height: 14px;
  cursor: pointer;
  pointer-events: auto;
`;
const OuterProgressBar = styled.div `
  display: flex;
  flex: 1;
  height: 8px;
  border-radius: 20px;
  margin: 4px 12px 0 12px;
  padding: 1px;
`;
const InnerProgressBar = styled.div `
  height: 6px;
  border-radius: 20px;
  transition: width 0.5s ease-in-out;
`;
