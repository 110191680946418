import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import { HTStepsPanel } from "./steps/ht-steps-panel";
import { LibraryModal } from "./library/library-modal";
import { HTTour } from "./screen/ht-tour";
import { Navbar } from "./navbar/navbar";
import { EditPanel } from "./edit/edit-panel";
import { useHTIsEditingHtml, useHTMode, useHTSetMode, useHTStepCount } from "../../../../redux/selectors/ht-selectors";
import { HTMode } from "../../../../../../shared/types/dom-tour";
import { HTPreviewStepper } from "./steps/ht-preview-stepper";
import { Overlay } from "./shared";
import { EDIT_PANEL_WIDTH, NAV_HEIGHT } from "../config";
import { useHTIsEditModeBlocked } from "../../../../hooks/use-is-collaborator-blocked";
export function DomTourBuilder() {
    const hasSteps = !!useHTStepCount();
    const mode = useHTMode();
    const isEditMode = mode === HTMode.EDIT;
    const isEditing = useHTIsEditingHtml();
    const [libraryModalOpen, setLibraryModalOpen] = useState(false);
    const isEditModeBlocked = useHTIsEditModeBlocked();
    const setViewportMode = useHTSetMode();
    useEffect(() => {
        if (isEditModeBlocked) {
            setViewportMode(HTMode.PREVIEW);
        }
    }, [isEditModeBlocked]);
    return (React.createElement(Root, { isEditMode: isEditMode },
        React.createElement(Navbar, null),
        isEditing && React.createElement(Overlay, { style: { width: `calc(100% - ${EDIT_PANEL_WIDTH}px)`, top: NAV_HEIGHT } }),
        hasSteps && React.createElement(HTTour, null),
        isEditMode ? (React.createElement(React.Fragment, null,
            hasSteps && React.createElement(EditPanel, null),
            React.createElement(HTStepsPanel, { setLibraryModalOpen: setLibraryModalOpen }),
            libraryModalOpen && React.createElement(LibraryModal, { setLibraryModalOpen: setLibraryModalOpen }))) : (React.createElement(HTPreviewStepper, null))));
}
const Root = styled.div `
  background-color: ${({ isEditMode }) => (isEditMode ? newTheme.colors.grey300 : newTheme.colors.grey700)};
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
`;
