import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TooltipArrowPosition } from "../../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import NoneIcon from "../../../../../../assets/images/none-icon.svg";
import NoneIconBlue from "../../../../../../assets/images/none-icon-blue.svg";
import { newTheme } from "../../../../styled/theme";
export const ArrowPositionSelector = (props) => {
    const { onChange, selected, arrowEnabled } = props;
    const [tooltipPosition, setTooltipPosition] = useState(TooltipArrowPosition.BOTTOM_CENTER);
    const handleClick = (position) => {
        setTooltipPosition(position);
        onChange(position);
    };
    useEffect(() => {
        if (!arrowEnabled) {
            setTooltipPosition(TooltipArrowPosition.NONE);
        }
        else {
            setTooltipPosition(selected);
        }
    }, [selected, arrowEnabled]);
    return (React.createElement(ArrowPositionContainer, null,
        React.createElement(PositioningRow, null,
            tooltipPosition === TooltipArrowPosition.TOP_LEFT ? (React.createElement(ArrowUp, null)) : (React.createElement(Point, { onClick: () => {
                    handleClick(TooltipArrowPosition.TOP_LEFT);
                } })),
            React.createElement(Spacer, null),
            tooltipPosition === TooltipArrowPosition.TOP_CENTER ? (React.createElement(ArrowUp, null)) : (React.createElement(Point, { onClick: () => {
                    handleClick(TooltipArrowPosition.TOP_CENTER);
                } })),
            React.createElement(Spacer, null),
            tooltipPosition === TooltipArrowPosition.TOP_RIGHT ? (React.createElement(ArrowUp, null)) : (React.createElement(Point, { onClick: () => {
                    handleClick(TooltipArrowPosition.TOP_RIGHT);
                } }))),
        React.createElement(ColumnContainer, null,
            React.createElement(PositioningColumn, null,
                tooltipPosition === TooltipArrowPosition.LEFT_TOP ? (React.createElement(ArrowLeft, null)) : (React.createElement(Point, { onClick: () => {
                        handleClick(TooltipArrowPosition.LEFT_TOP);
                    } })),
                React.createElement(Spacer, null),
                tooltipPosition === TooltipArrowPosition.LEFT_CENTER ? (React.createElement(ArrowLeft, null)) : (React.createElement(Point, { onClick: () => {
                        handleClick(TooltipArrowPosition.LEFT_CENTER);
                    } })),
                React.createElement(Spacer, null),
                tooltipPosition === TooltipArrowPosition.LEFT_BOTTOM ? (React.createElement(ArrowLeft, null)) : (React.createElement(Point, { onClick: () => {
                        handleClick(TooltipArrowPosition.LEFT_BOTTOM);
                    } }))),
            React.createElement(Bubble, null,
                React.createElement(NoArrowContainer, { selected: !arrowEnabled, onClick: () => {
                        handleClick(TooltipArrowPosition.NONE);
                    } },
                    React.createElement("img", { src: arrowEnabled ? NoneIcon : NoneIconBlue }),
                    "No Arrow")),
            React.createElement(PositioningColumn, null,
                tooltipPosition === TooltipArrowPosition.RIGHT_TOP ? (React.createElement(ArrowRight, null)) : (React.createElement(Point, { onClick: () => {
                        handleClick(TooltipArrowPosition.RIGHT_TOP);
                    } })),
                React.createElement(Spacer, null),
                tooltipPosition === TooltipArrowPosition.RIGHT_CENTER ? (React.createElement(ArrowRight, null)) : (React.createElement(Point, { onClick: () => {
                        handleClick(TooltipArrowPosition.RIGHT_CENTER);
                    } })),
                React.createElement(Spacer, null),
                tooltipPosition === TooltipArrowPosition.RIGHT_BOTTOM ? (React.createElement(ArrowRight, null)) : (React.createElement(Point, { onClick: () => {
                        handleClick(TooltipArrowPosition.RIGHT_BOTTOM);
                    } })))),
        React.createElement(PositioningRow, null,
            tooltipPosition === TooltipArrowPosition.BOTTOM_LEFT ? (React.createElement(ArrowDown, null)) : (React.createElement(Point, { onClick: () => {
                    handleClick(TooltipArrowPosition.BOTTOM_LEFT);
                } })),
            React.createElement(Spacer, null),
            tooltipPosition === TooltipArrowPosition.BOTTOM_CENTER ? (React.createElement(ArrowDown, null)) : (React.createElement(Point, { onClick: () => {
                    handleClick(TooltipArrowPosition.BOTTOM_CENTER);
                } })),
            React.createElement(Spacer, null),
            tooltipPosition === TooltipArrowPosition.BOTTOM_RIGHT ? (React.createElement(ArrowDown, null)) : (React.createElement(Point, { onClick: () => {
                    handleClick(TooltipArrowPosition.BOTTOM_RIGHT);
                } })))));
};
const Point = ({ onClick }) => {
    return (React.createElement(PointContainer, { onClick: onClick },
        React.createElement(Dot, null)));
};
const ArrowPositionContainer = styled.div `
  display: flex;
  width: 210px;
  flex-direction: column;
  margin: 0px auto;
  margin-bottom: 20px;
`;
const PositioningRow = styled.div `
  display: flex;
  height: 24px;
  margin: 0 48px;
`;
const ColumnContainer = styled.div `
  display: flex;
`;
const PositioningColumn = styled.div `
  width: 24px;
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;
const Bubble = styled.div `
  display: flex;
  width: 162px;
  height: 88px;
  background-color: ${newTheme.colors.white};
  box-shadow:
    0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 4px 8px rgba(48, 49, 51, 0.1),
    inset 0px 0px 2px rgba(48, 49, 51, 0.5);
  border-radius: 4px;
  border: 1px solid ${newTheme.colors.grey500};
  align-items: center;
  justify-content: center;
`;
const NoArrowContainer = styled.div `
  display: flex;
  align-items: center;
  color: ${p => (p.selected ? newTheme.colors.blue500 : newTheme.colors.grey400)};
  font: ${p => (p.selected ? newTheme.fonts.bold : newTheme.fonts.normal)};
  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  cursor: pointer;
`;
const PointContainer = styled.button `
  width: 24px;
  height: 24px;
  cursor: pointer;
  &:hover > div {
    background-color: ${newTheme.colors.blue};
  }
`;
const Spacer = styled.div `
  flex: 1;
`;
const Dot = styled.div `
  width: 8px;
  height: 8px;
  margin: 8px;
  border-radius: 4px;
  border: 1px solid ${newTheme.colors.blue};
  background-color: ${newTheme.colors.blue100};
`;
const Arrow = styled.div `
  width: 16px;
  height: 16px;
  background-color: ${newTheme.colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${newTheme.colors.grey600} ${newTheme.colors.grey600} ${newTheme.colors.white} ${newTheme.colors.white};
`;
const ArrowLeft = styled(Arrow) `
  transform: rotate(-135deg);
  margin-left: 17px;
`;
const ArrowUp = styled(Arrow) `
  transform: rotate(-45deg);
  margin-top: 17px;
`;
const ArrowRight = styled(Arrow) `
  transform: rotate(45deg);
  margin-left: -9px;
`;
const ArrowDown = styled(Arrow) `
  transform: rotate(-225deg);
  margin-top: -9px;
`;
