import React, { useState } from "react";
import TToggle from "../../../styled/t-toggle";
import { usePushHistory, useTourialId, useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import TTextInput from "../../../styled/t-text-input";
import { Async } from "react-async";
import { getAllIntegrationsForForm, trimAndMakeUnique } from "../form-inputs/editor";
import TP from "../../../styled/t-p";
import { Link } from "react-router-dom";
import TButton from "../../../styled/t-button";
import { fontWeightOptions } from "../../../../../types/tools";
import TSelect from "../../../styled/t-select";
import TNumberInput from "../../../styled/t-number-input";
import { cloneDeep } from "lodash";
import LayoutIcon from "../../../../../../assets/images/layout-blue.svg";
import ThemeIcon from "../../../../../../assets/images/theme-blue.svg";
import ImageIcon from "../../../../../../assets/images/image-black.svg";
import ArrowUp from "../../../../../../assets/images/box-arrow-up.svg";
import ArrowDown from "../../../../../../assets/images/box-arrow-down.svg";
import GearIconInactive from "../../../../../../assets/images/gear-grey.svg";
import GearIconActive from "../../../../../../assets/images/gear-blue.svg";
import Layout1 from "../../../../../../assets/images/form-layout-1.svg";
import Layout2 from "../../../../../../assets/images/form-layout-2.svg";
import Layout3 from "../../../../../../assets/images/form-layout-3.svg";
import Layout4 from "../../../../../../assets/images/form-layout-4.svg";
import PhoneIcon from "../../../../../../assets/images/phone-grey.svg";
import TIcon from "../../../../../../assets/images/t-icon.svg";
import DropdownIcon from "../../../../../../assets/images/dropdown-icon.svg";
import EmailIcon from "../../../../../../assets/images/email.svg";
import { Cursors } from "../../editor-image-offset-cursor";
import { ToolColorPickerV2 } from "../../editor-tool-color-picker-v2";
import { checkNested } from "../../../../../helpers/misc";
import FormFieldModal, { FieldDataTypes } from "./form-field-modal";
import { imageTypes, uploadMedia } from "../../../../../helpers/media";
import FormImageModal from "./form-image-modal";
import { theme } from "../../../../../../../shared/legacy/legacy-tool-data";
import { fontFamilyOptions } from "../../../../../types/tools";
var FormV2Tabs;
(function (FormV2Tabs) {
    FormV2Tabs["LAYOUT"] = "Layout";
    FormV2Tabs["THEME"] = "Theme";
})(FormV2Tabs || (FormV2Tabs = {}));
const tabToImageMap = {
    [FormV2Tabs.LAYOUT]: LayoutIcon,
    [FormV2Tabs.THEME]: ThemeIcon,
};
export const fieldTypeToImageMap = {
    [FieldDataTypes.PHONE]: PhoneIcon,
    [FieldDataTypes.TEXT]: TIcon,
    [FieldDataTypes.DROPDOWN]: DropdownIcon,
    [FieldDataTypes.EMAIL]: EmailIcon,
};
export const labelStyle = Object.assign(Object.assign({}, theme.typography.p), { fontSize: "14px", color: theme.colors.grey.darkest, lineHeight: "25px", margin: "8px 0px" });
export const subLabelStyle = Object.assign(Object.assign({}, labelStyle), { fontSize: "12px" });
export const inputStyle = Object.assign(Object.assign({}, subLabelStyle), { width: "100%", margin: 0 });
const activeTabStyle = Object.assign(Object.assign({}, theme.typography.p), { fontWeight: "bold", fontSize: "12px", color: theme.colors.sky.darkest, cursor: "pointer", textAlign: "center", lineHeight: "12px", width: "36%" });
const inactiveTabStyle = Object.assign(Object.assign({}, activeTabStyle), { opacity: 0.5 });
labelStyle.fontWeight = "bold";
subLabelStyle.fontWeight = "bold";
export const tSelectStyle = {
    borderColor: theme.colors.cobalt.light,
    boxShadow: theme.shadows.shallow,
};
export function FormV2({ toolData }) {
    const updateTool = useUpdateTool();
    const { name } = toolData;
    const [activeTab, setActiveTab] = useState(FormV2Tabs.LAYOUT);
    function Tab({ type }) {
        return (React.createElement("button", { style: activeTab === type ? activeTabStyle : inactiveTabStyle, onClick: () => setActiveTab(type) },
            React.createElement("img", { src: tabToImageMap[type], height: "35", width: "35" }),
            React.createElement("br", null),
            type));
    }
    return (React.createElement("div", null,
        React.createElement(TTextInput, { value: name, onChange: e => {
                const newTool = cloneDeep(toolData);
                newTool.name = e.target.value;
                updateTool(newTool);
            }, style: Object.assign(Object.assign({}, inputStyle), { width: "100%", paddingLeft: 4 }) }),
        React.createElement("div", { style: {
                display: "flex",
                justifyContent: "center",
                margin: "8px 0px 15px",
                width: "100%",
            } },
            React.createElement(Tab, { type: FormV2Tabs.LAYOUT }),
            React.createElement(Tab, { type: FormV2Tabs.THEME })),
        React.createElement(Divider, null),
        activeTab === FormV2Tabs.LAYOUT && React.createElement(FormLayoutTab, { toolData: toolData }),
        activeTab === FormV2Tabs.THEME && React.createElement(FormThemeTab, { toolData: toolData })));
}
export function Divider({ style }) {
    return (React.createElement("hr", { style: Object.assign({ width: "calc(100% + 20px)", backgroundColor: theme.colors.grey.light, minHeight: "1px", marginLeft: -10, marginRight: -10 }, style) }));
}
function FormLayoutTab({ toolData }) {
    var _a, _b, _c, _d;
    const [show, setShow] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [fieldIndex, setFieldIndex] = useState();
    const updateTool = useUpdateTool();
    const tourialId = useTourialId();
    const { fields, isDismissable, layout, logo, columns, disableConfirmation } = toolData;
    // image uploader variables
    const pushHistory = usePushHistory();
    const [cursorIntervalId, setCursorIntervalId] = useState(null);
    function addMediaFn(media) {
        const newTool = cloneDeep(toolData);
        newTool.logo.src = media[0].src;
        newTool.logo.originalDimensions = media[0].size;
        updateTool(newTool);
    }
    let logoWidth, logoHeight;
    if (logo.originalDimensions) {
        const { width, height } = logo.originalDimensions;
        logoWidth = width >= height ? 100 : (width / height) * 100;
        logoHeight = width >= height ? (height / width) * 100 : 100;
    }
    return (React.createElement("div", { style: {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
        } },
        React.createElement(FormImageModal, { show: showImageModal, onClose: () => {
                setShowImageModal(false);
            }, toolData: toolData }),
        React.createElement("div", { style: {
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
            } },
            React.createElement("div", { style: labelStyle }, "Upload Logo"),
            React.createElement("div", { style: {
                    display: "flex",
                } },
                React.createElement("img", { src: showImageModal ? GearIconActive : GearIconInactive, width: 20, style: { cursor: "pointer", margin: "0px 5px" }, onClick: () => {
                        setShowImageModal(true);
                    } }),
                React.createElement(TToggle, { value: logo === null || logo === void 0 ? void 0 : logo.isVisible, onClick: () => {
                        const newTool = cloneDeep(toolData);
                        newTool.logo.isVisible = !(logo === null || logo === void 0 ? void 0 : logo.isVisible);
                        updateTool(newTool);
                    } }))),
        React.createElement("div", { style: {
                display: "flex",
                width: "100%",
                justifyContent: "center",
            } },
            React.createElement("div", { style: {
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "auto 15px",
                } },
                React.createElement("div", { style: {
                        backgroundColor: theme.colors.grey.light,
                        width: 60,
                        height: 60,
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    } },
                    React.createElement("img", { src: logo.src || ImageIcon, style: {
                            width: logo.src && logoWidth ? `${logoWidth}%` : 60,
                            height: logo.src && logoHeight ? `${logoHeight}%` : 60,
                            padding: logo.src ? 0 : 15,
                            // position: "relative",
                            // top: `${-logo.yOffset}%`,
                            // left: `${-logo.xOffset}%`
                        } })),
                React.createElement(TButton, { style: { width: 80, height: 25, margin: "8px 0px" } },
                    React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { marginTop: -12.5, marginLeft: -18, color: theme.colors.white }) },
                        React.createElement("label", { style: { padding: "8px 21px", cursor: "pointer" } },
                            "Upload",
                            React.createElement("input", { multiple: false, type: "file", accept: `${imageTypes.filter(t => t !== "image/gif").join("|")}`, onClick: e => {
                                    //@ts-expect-error
                                    e.target.value = null;
                                }, onChange: e => {
                                    pushHistory();
                                    void uploadMedia(e.target.files, addMediaFn);
                                } }))))),
            logo.src && (React.createElement(Cursors, { toolId: toolData.id, xOffset: toolData.logo.xOffset, yOffset: toolData.logo.yOffset, cursorIntervalId: cursorIntervalId, setCursorIntervalId: setCursorIntervalId, updateFn: (toolId, position) => {
                    const newTool = cloneDeep(toolData);
                    newTool.logo.xOffset = isNaN(position.x) ? newTool.logo.xOffset : position.x;
                    newTool.logo.yOffset = isNaN(position.y) ? newTool.logo.yOffset : position.y;
                    updateTool(newTool);
                }, style: { margin: "auto 15px" }, inverted: true }))),
        React.createElement("div", { style: Object.assign(Object.assign({}, labelStyle), { width: "100%" }) }, "Layout"),
        React.createElement(TSelect, { style: Object.assign({ width: "100%", margin: 0 }, tSelectStyle), value: `${columns}`, onChange: e => {
                const newTool = cloneDeep(toolData);
                //@ts-expect-error
                newTool.columns = +e.target.value;
                updateTool(newTool);
            } },
            React.createElement("option", { value: "1" }, "One Column"),
            React.createElement("option", { value: "2" }, "Two Columns")),
        React.createElement("div", { style: {
                display: "flex",
                width: "100%",
                flexFlow: "row wrap",
                justifyContent: "space-between",
                margin: "8px 0px",
            } }, [Layout1, Layout2, Layout3, Layout4].map((img, i) => {
            return (React.createElement("div", { key: i + 1, style: {
                    width: "calc(50% - 2px)",
                    height: 0,
                    paddingBottom: "calc(39% - 2px)",
                    marginBottom: 4,
                    cursor: "pointer",
                    backgroundImage: `url(${img})`,
                    backgroundRepeat: "no-repeat",
                    opacity: layout === i + 1 ? 1 : 0.45,
                    boxSizing: "border-box",
                    boxShadow: layout === i + 1 ? theme.shadows.shallow : "none",
                }, onClick: () => {
                    const newTool = cloneDeep(toolData);
                    newTool.layout = i + 1;
                    updateTool(newTool);
                } }));
        })),
        React.createElement(Divider, null),
        React.createElement("div", { style: {
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
            } },
            React.createElement("div", { style: labelStyle }, "Dismissable?"),
            React.createElement(TToggle, { value: isDismissable, onClick: () => {
                    const newTool = cloneDeep(toolData);
                    newTool.isDismissable = !isDismissable;
                    updateTool(newTool);
                } })),
        React.createElement("div", { style: {
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
            } },
            React.createElement("div", { style: Object.assign(Object.assign({}, labelStyle), { marginTop: 0 }) }, "Disable Confirmation"),
            React.createElement(TToggle, { value: disableConfirmation, onClick: () => {
                    const newTool = cloneDeep(toolData);
                    newTool.disableConfirmation = !disableConfirmation;
                    updateTool(newTool);
                } })),
        React.createElement(Divider, null),
        React.createElement("div", { style: Object.assign(Object.assign({}, labelStyle), { width: "100%", marginBottom: 0 }) }, "Input Fields"),
        React.createElement("div", { style: {
                display: "flex",
                width: "100%",
                flexFlow: "row wrap",
                justifyContent: "space-between",
                marginBottom: 11,
            } },
            React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "100%", margin: 0 }) },
                "Height",
                React.createElement(TNumberInput, { min: 0, max: 50, value: ((_a = toolData === null || toolData === void 0 ? void 0 : toolData.inputSizing) === null || _a === void 0 ? void 0 : _a.height) || 0, onChange: e => {
                        const newTool = cloneDeep(toolData);
                        newTool.inputSizing.height = e.target.value;
                        updateTool(newTool);
                    }, style: inputStyle })),
            React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "calc(50% - 2px)", margin: 0 }) },
                "H Spacing",
                React.createElement(TNumberInput, { min: 0, max: 50, value: ((_b = toolData === null || toolData === void 0 ? void 0 : toolData.inputSizing) === null || _b === void 0 ? void 0 : _b.horizontalSpacing) || 0, onChange: e => {
                        const newTool = cloneDeep(toolData);
                        newTool.inputSizing.horizontalSpacing = e.target.value;
                        updateTool(newTool);
                    }, style: inputStyle })),
            React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "calc(50% - 2px)", margin: 0 }) },
                "V Spacing",
                React.createElement(TNumberInput, { min: 0, max: 50, value: ((_c = toolData === null || toolData === void 0 ? void 0 : toolData.inputSizing) === null || _c === void 0 ? void 0 : _c.verticalSpacing) || 0, onChange: e => {
                        const newTool = cloneDeep(toolData);
                        newTool.inputSizing.verticalSpacing = e.target.value;
                        updateTool(newTool);
                    }, style: inputStyle })),
            React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "100%", margin: 0 }) },
                "Send submitted form to:",
                React.createElement(TTextInput, { value: ((_d = toolData === null || toolData === void 0 ? void 0 : toolData.notificationEmailAddresses) === null || _d === void 0 ? void 0 : _d[0]) || "", onChange: e => {
                        const newTool = cloneDeep(toolData);
                        newTool.notificationEmailAddresses = [e.target.value];
                        updateTool(newTool);
                    }, style: Object.assign(Object.assign({}, inputStyle), { paddingLeft: 4 }) }))),
        React.createElement(FormFieldModal, { show: show, onClose: () => {
                setFieldIndex(null);
                setShow(false);
            }, toolData: toolData, fieldIndex: fieldIndex }),
        React.createElement(Async, { promiseFn: () => getAllIntegrationsForForm(tourialId, toolData.id) }, ({ data, error }) => {
            let hasIntegrations = true;
            if (error)
                return `Something went wrong: ${error.message}`;
            if (data)
                hasIntegrations = !!data.length;
            if (hasIntegrations) {
                return (React.createElement(TP, null,
                    "Cannot customize form inputs.",
                    " ",
                    React.createElement(Link, { to: "/integrations", style: {
                            color: theme.colors.cobalt.medium,
                            textDecoration: "underline",
                        } }, "There is already an integration attached to this form.")));
            }
            return (React.createElement(React.Fragment, null, fields === null || fields === void 0 ? void 0 :
                fields.map((f, i) => {
                    return (React.createElement("div", { key: i, style: {
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 4,
                        } },
                        React.createElement("div", { style: { display: "flex" } },
                            React.createElement("img", { src: ArrowUp, width: 24, style: { cursor: "pointer" }, onClick: () => {
                                    const newTool = cloneDeep(toolData);
                                    if (i >= 1) {
                                        const tmp = newTool.fields[i];
                                        newTool.fields[i] = newTool.fields[i - 1];
                                        newTool.fields[i - 1] = tmp;
                                        updateTool(newTool);
                                    }
                                } }),
                            React.createElement("img", { src: ArrowDown, width: 24, style: { cursor: "pointer" }, onClick: () => {
                                    const newTool = cloneDeep(toolData);
                                    if (i < newTool.fields.length - 1) {
                                        const tmp = newTool.fields[i];
                                        newTool.fields[i] = newTool.fields[i + 1];
                                        newTool.fields[i + 1] = tmp;
                                        updateTool(newTool);
                                    }
                                } })),
                        React.createElement(TTextInput, { style: Object.assign(Object.assign({}, inputStyle), { flexGrow: 1, margin: "0px 4px" }), image: fieldTypeToImageMap[f.type], value: f.role, onChange: e => {
                                const newTool = cloneDeep(toolData);
                                newTool.fields[i].role = e.target.value;
                                updateTool(newTool);
                            } }),
                        React.createElement("img", { src: fieldIndex === i ? GearIconActive : GearIconInactive, width: 16, style: { cursor: "pointer" }, onClick: () => {
                                setFieldIndex(i);
                                setShow(true);
                            } })));
                }),
                React.createElement(TButton, { onClick: () => {
                        const newField = {
                            role: trimAndMakeUnique(`New Field ${fields.length + 1}`, fields.map(t => t.role)),
                            value: "",
                            type: FieldDataTypes.TEXT,
                        };
                        const newTool = cloneDeep(toolData);
                        newTool.fields = [...newTool.fields, newField];
                        updateTool(newTool);
                    }, style: { width: 126, height: 25, marginTop: "8px" } },
                    React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { margin: "-12.5px -10px 0px -10px", color: theme.colors.white }) }, "Add New Input Field"))));
        })));
}
function FormThemeTab({ toolData }) {
    return (React.createElement("div", null,
        React.createElement(ThemeSection, { toolData: toolData, formTheme: "backgroundTheme", formText: "headerText", title: "Form Background", prefix: "Header" }),
        React.createElement(Divider, null),
        React.createElement(ThemeSection, { toolData: toolData, formTheme: "inputsTheme", formText: "bodyText", title: "Input Fields", prefix: "Input & Description" }),
        React.createElement(Divider, null),
        React.createElement(ThemeSection, { toolData: toolData, formTheme: "buttonTheme", formText: "buttonText", title: "Button", prefix: "Button" })));
}
function ThemeSection({ toolData, formTheme, formText, title, prefix }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const updateTool = useUpdateTool();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: labelStyle }, title),
        React.createElement("div", { style: {
                display: "flex",
                width: "100%",
                flexFlow: "row wrap",
                justifyContent: "space-between",
                marginBottom: 11,
                borderLeft: `3px solid ${theme.colors.grey.medium}`,
                paddingLeft: "4px",
            } },
            React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "48%", margin: 0 }) },
                React.createElement(ToolColorPickerV2, { key: toolData.id, toolId: toolData.id, label: "Background Color", property: "backgroundColor", propertyParentName: formTheme, propertyGrandParentName: "theme" })),
            React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "48%", margin: 0 }) },
                React.createElement(ToolColorPickerV2, { key: toolData.id, toolId: toolData.id, label: "Border Color", property: "borderColor", propertyParentName: formTheme, propertyGrandParentName: "theme", style: { marginLeft: -112 } })),
            React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "calc(50% - 2px)", margin: 0 }) },
                "Border Radius",
                React.createElement(TNumberInput, { min: 0, max: 200, style: inputStyle, value: ((_b = (_a = toolData === null || toolData === void 0 ? void 0 : toolData.theme) === null || _a === void 0 ? void 0 : _a[formTheme]) === null || _b === void 0 ? void 0 : _b.borderRadius) || 0, onChange: e => {
                        const newTool = cloneDeep(toolData);
                        if (checkNested(newTool, "theme", formTheme, "borderRadius")) {
                            newTool.theme[formTheme].borderRadius = e.target.value;
                            updateTool(newTool);
                        }
                    } })),
            React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "calc(50% - 2px)", margin: 0 }) },
                "Border Width",
                React.createElement(TNumberInput, { min: 0, max: 20, style: inputStyle, value: ((_d = (_c = toolData === null || toolData === void 0 ? void 0 : toolData.theme) === null || _c === void 0 ? void 0 : _c[formTheme]) === null || _d === void 0 ? void 0 : _d.borderWidth) || 0, onChange: e => {
                        const newTool = cloneDeep(toolData);
                        if (checkNested(newTool, "theme", formTheme, "borderWidth")) {
                            newTool.theme[formTheme].borderWidth = e.target.value;
                            updateTool(newTool);
                        }
                    } })),
            React.createElement(Divider, { style: { width: "calc(100% - 7px)", margin: "12px 0px 8px 7px" } }),
            React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "100%", margin: "0px 0px 4px" }) },
                prefix,
                " Font",
                React.createElement(TSelect, { style: Object.assign({ width: "100%", margin: 0 }, tSelectStyle), value: (_f = (_e = toolData === null || toolData === void 0 ? void 0 : toolData.text) === null || _e === void 0 ? void 0 : _e[formText]) === null || _f === void 0 ? void 0 : _f.fontFamily, onChange: e => {
                        const newTool = cloneDeep(toolData);
                        if (checkNested(newTool, "text", formText, "fontFamily")) {
                            newTool.text[formText].fontFamily = e.target.value;
                            updateTool(newTool);
                        }
                    } },
                    React.createElement("option", { value: "" }, "- "),
                    fontFamilyOptions.map((o, i) => (React.createElement("option", { key: i, value: o }, o))))),
            React.createElement(TSelect, { style: Object.assign({ width: "calc(80% - 4px)", margin: 0 }, tSelectStyle), value: (_h = (_g = toolData === null || toolData === void 0 ? void 0 : toolData.text) === null || _g === void 0 ? void 0 : _g[formText]) === null || _h === void 0 ? void 0 : _h.fontWeight, onChange: e => {
                    const newTool = cloneDeep(toolData);
                    if (checkNested(newTool, "text", formText, "fontWeight")) {
                        newTool.text[formText].fontWeight = e.target.value;
                        updateTool(newTool);
                    }
                } },
                React.createElement("option", { value: "" }, "- "),
                fontWeightOptions.map(o => (React.createElement("option", { key: o, value: o }, o)))),
            React.createElement(TNumberInput, { style: Object.assign(Object.assign({}, inputStyle), { width: "20%", margin: 0, fontSize: "12px" }), min: 1, max: 50, step: 0.1, value: ((_k = (_j = toolData === null || toolData === void 0 ? void 0 : toolData.text) === null || _j === void 0 ? void 0 : _j[formText]) === null || _k === void 0 ? void 0 : _k.fontSize) || 0, onChange: e => {
                    const newTool = cloneDeep(toolData);
                    if (checkNested(newTool, "text", formText, "fontSize")) {
                        newTool.text[formText].fontSize = e.target.value;
                        updateTool(newTool);
                    }
                } }),
            React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "100%" }) },
                React.createElement(ToolColorPickerV2, { key: toolData.id, toolId: toolData.id, label: `${prefix !== "Input & Description" ? prefix : "Input"} Text Color`, property: "color", propertyParentName: formText, propertyGrandParentName: "text" })),
            prefix === "Input & Description" && (React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "100%", marginTop: -8 }) },
                React.createElement(ToolColorPickerV2, { key: toolData.id, toolId: toolData.id, label: "Description Text Color", property: "descriptionColor", propertyParentName: formText, propertyGrandParentName: "text" }))))));
}
