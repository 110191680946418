import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckmarkIcon from "../../../assets/images/checkmark-white.svg";
import { newTheme } from "./theme";
export const TCheckbox = (props) => {
    const { checked, onChange, className, style } = props;
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);
    return (React.createElement(CheckboxContainer, { style: style, checked: isChecked, className: className, onClick: e => {
            e.stopPropagation();
            setIsChecked(!isChecked);
            onChange(!isChecked);
        } }, isChecked && React.createElement("img", { src: CheckmarkIcon, alt: "check mark" })));
};
const CheckboxContainer = styled.div `
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid ${newTheme.colors.grey600};
  background-color: ${props => (props.checked ? newTheme.colors.blue : newTheme.colors.white)};
  &:hover {
    background-color: ${props => (props.checked ? newTheme.colors.blue400 : newTheme.colors.blue100)};
  }
  border-color: ${newTheme.colors.grey400};
  &:hover {
    border-color: ${newTheme.colors.grey600};
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;
