import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { useDCSetActiveItemHasBeenCompleted } from "../../../../../redux/selectors/demo-center-selectors";
import { useDCItemPauseEvent, useDCItemPlayEvent, useDCItemProgressEvent, useDCItemStopEvent, } from "../../hooks/dc-event-hooks";
export const DCVideoPlayer = ({ item, autoplay }) => {
    const { url } = item;
    const markAsCompleted = useDCSetActiveItemHasBeenCompleted();
    const dCItemPlayEvent = useDCItemPlayEvent();
    const dCItemPauseEvent = useDCItemPauseEvent();
    const dCItemStopEvent = useDCItemStopEvent();
    const dCItemProgressEvent = useDCItemProgressEvent();
    return (React.createElement(VideoContainer, null,
        React.createElement(ReactPlayer, { className: "react-player", url: url, width: "100%", height: "100%", controls: true, playing: autoplay, progressInterval: 5000, onEnded: () => {
                markAsCompleted(true);
                dCItemStopEvent(item);
            }, onPlay: () => dCItemPlayEvent(item), onPause: () => dCItemPauseEvent(item), onProgress: progress => {
                dCItemProgressEvent(item, progress.played);
            } })));
};
const VideoContainer = styled.div `
  position: relative;
  padding-top: 56.25%;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
