import { useEffect } from "react";
export function usePromptBeforeUnload(condition) {
    useEffect(() => {
        if (condition) {
            window.removeEventListener("beforeunload", alertUser);
            window.addEventListener("beforeunload", alertUser);
        }
        else {
            window.removeEventListener("beforeunload", alertUser);
        }
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, [condition]);
    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };
}
