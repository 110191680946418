import { cloneDeep } from "lodash";
import { ActionType } from "../actions/redux-actions";
export function tourPlanReducer(prevState = {}, action) {
    switch (action.type) {
        case ActionType.CLEAR_TOUR_PLAN_STATE: {
            return {};
        }
        case ActionType.SET_TOUR_PLAN_INITIAL_STATE: {
            return action.initialProviderState;
        }
        case ActionType.UPDATE_TOUR_PLAN: {
            return Object.assign(Object.assign({}, prevState), { plan: Object.assign(Object.assign({}, prevState.plan), cloneDeep(action.plan)) });
        }
        case ActionType.SET_TOUR_PLAN_ACTIVE_STEP: {
            return Object.assign(Object.assign({}, prevState), { activeStep: action.activeStep });
        }
        default:
            return prevState;
    }
}
