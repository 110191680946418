import React from "react";
import { useIsPublished, useIsPublishModalOpen, useSaveTourial, useSetIsPublished, useSetIsPublishModalOpen, useTourId, } from "../../../../redux/selectors/redux-selectors";
import TButton from "../../../styled/t-button";
import SaveModal from "../modals/save-modal";
import { useAddToast } from "../../../../legacy/selectors";
import { ToastModes } from "../../../../types/definitions";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
export function PublishAndShareButton({ setIsSettingsModalOpen }) {
    const isPublished = useIsPublished();
    const isPublishModalOpen = useIsPublishModalOpen();
    const setPublishModalOpen = useSetIsPublishModalOpen();
    const handlePublish = useHandlePublish();
    return (React.createElement(React.Fragment, null,
        React.createElement(TButton, { id: "_test_publish-button", onClick: () => {
                isPublished ? setPublishModalOpen(true) : handlePublish(true);
            }, size: "medium" }, isPublished ? "Share" : "Publish"),
        isPublishModalOpen && (React.createElement(SaveModal, { onClose: () => setPublishModalOpen(false), setIsSettingsModalOpen: setIsSettingsModalOpen }))));
}
export function useHandlePublish() {
    const setIsPublished = useSetIsPublished();
    const tourId = useTourId();
    const saveTourial = useSaveTourial();
    const addToast = useAddToast();
    return (publish) => {
        setIsPublished(publish);
        TourialApiCalls.Tourials.postTourialPublish(tourId, publish)
            .then(() => {
            saveTourial();
            addToast({
                message: `Successfully ${publish ? "published" : "unpublished"}.`,
                mode: ToastModes.SUCCESS,
            });
        })
            .catch(err => {
            var _a;
            setIsPublished(!publish);
            if ((_a = err === null || err === void 0 ? void 0 : err.message) === null || _a === void 0 ? void 0 : _a.includes("403")) {
                addToast({
                    message: publish
                        ? "Tour max reached. Pause another Tour or upgrade plan."
                        : "A tour featured in a TourPlayer cannot be unpublished.",
                    mode: ToastModes.ERROR,
                });
            }
        });
    };
}
