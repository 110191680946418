import React from "react";
import styled from "styled-components";
import { DCStyledBodyText } from "./dc-styled-text";
export const DCBadge = ({ content, type, config }) => {
    const { text, defaultBackgroundColor } = config;
    const getBackgroundColorForBadgeType = (type) => {
        switch (type) {
            default:
                return defaultBackgroundColor;
        }
    };
    return (React.createElement(BadgeContainer, { style: { backgroundColor: getBackgroundColorForBadgeType(type) } },
        React.createElement(DCStyledBodyText, { config: text }, content)));
};
const BadgeContainer = styled.div `
  display: inline-flex;
  padding: 1px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;
