import React from "react";
import { withToolWrapper } from "../with-tool-wrapper";
function TourialGroup(props) {
    return (React.createElement("div", { style: {
            width: `100%`,
            height: `100%`,
            position: "absolute",
            display: "inline",
        }, id: props.toolData.id }));
}
export const Group = React.memo(withToolWrapper(TourialGroup));
