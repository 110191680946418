import React from "react";
import { getBorderStylesFromProps } from "../../../helpers/misc";
import { useUpdateBuilder } from "../../../redux/selectors/redux-selectors";
import { withToolWrapper } from "../with-tool-wrapper";
function TourialSpotlight(props) {
    const updateBuilder = useUpdateBuilder();
    return (React.createElement("div", { className: `spotlight-${props.toolData.id}`, onClick: () => {
            updateBuilder({ editDetailsId: props.toolData.id });
        }, style: Object.assign(Object.assign({ pointerEvents: "auto", width: `100%`, height: `100%`, position: "absolute", display: "inline", animation: "fadeIn 0.5s", backgroundColor: null }, getBorderStylesFromProps(props)), { boxShadow: `0px 0px 0px 10000px rgba(0, 0, 0, 0.5)` }) }));
}
export const Spotlight = withToolWrapper(TourialSpotlight);
