import { useSelector } from "react-redux";
import { useDefaultFontFamily, useToolData, useTourial, useUpdateTool, useUpdateTourial, } from "../redux/selectors/redux-selectors";
import { getActiveVariantName } from "../helpers/redux";
import { ToolType } from "../../../shared/types/tool-data-types/common";
export const useUpdateEditableTextarea = (toolId) => {
    const tool = useToolData(toolId);
    const updateTool = useUpdateTool();
    return (text) => {
        const newTool = Object.assign({}, tool);
        newTool.textData = Object.assign({}, tool.textData); // Must copy or the selector won't update
        newTool.textData.text = text;
        updateTool(newTool);
    };
};
export const useUpdateEditableTextareaProperties = (toolId) => {
    const tool = useToolData(toolId);
    const updateTool = useUpdateTool();
    return (properties) => {
        const newTool = Object.assign({}, tool);
        newTool.textData = properties;
        updateTool(newTool);
    };
};
export const useEditableTextarea = (toolId) => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    const tool = state.tourialRootReducer.tourial[activeVariantName].tools[toolId];
    if (!tool || (tool.type !== ToolType.Button && tool.type !== ToolType.Textbox))
        return;
    return tool.textData;
});
export const useApplyFontToAllTools = () => {
    const tourial = useTourial();
    const updateTourial = useUpdateTourial();
    const defaultFontFamily = useDefaultFontFamily();
    return () => {
        var _a, _b, _c;
        const variantDesktopTools = {
            background: tourial.variantDesktop.tools.background,
            screen: tourial.variantDesktop.tools.screen,
        };
        Object.keys(tourial.variantDesktop.tools).forEach(toolId => {
            const tool = tourial.variantDesktop.tools[toolId];
            if (tool.type === ToolType.Button || tool.type === ToolType.Textbox) {
                tool.textData.fontFamily = defaultFontFamily;
            }
            variantDesktopTools[toolId] = tool;
        });
        const newTourial = Object.assign(Object.assign({}, tourial), { variantDesktop: Object.assign(Object.assign({}, tourial.variantDesktop), { tools: variantDesktopTools }) });
        if ((_a = tourial.variantMobile) === null || _a === void 0 ? void 0 : _a.tools) {
            const variantMobileTools = {
                background: (_b = tourial.variantMobile) === null || _b === void 0 ? void 0 : _b.tools.background,
                screen: (_c = tourial.variantMobile) === null || _c === void 0 ? void 0 : _c.tools.screen,
            };
            Object.keys(tourial.variantMobile.tools).forEach(toolId => {
                const tool = tourial.variantMobile.tools[toolId];
                if (tool.type === ToolType.Button || tool.type === ToolType.Textbox) {
                    tool.textData.fontFamily = defaultFontFamily;
                }
                variantMobileTools[toolId] = tool;
            });
            newTourial.variantMobile = Object.assign(Object.assign({}, tourial.variantMobile), { tools: variantMobileTools });
        }
        updateTourial(newTourial);
    };
};
