/** Important Note:
 *  - add actions under the appropriate sub-category denoted by comment
 *  - add actions in EditActions array if they modify the tourial(aka the first sub-category below)
 */
export var ActionType;
(function (ActionType) {
    /** actions that edit the tourial - disabled for archived tourials */
    ActionType["ADD_TOOL"] = "ADD_TOOL";
    ActionType["ADD_TOOL_TO_GROUP"] = "ADD_TOOL_TO_GROUP";
    ActionType["ADD_TOOL_GLOBALLY"] = "ADD_TOOL_GLOBALLY";
    ActionType["ADD_PAGE"] = "ADD_PAGE";
    ActionType["ADD_PAGE_WITH_SCREEN"] = "ADD_PAGE_WITH_SCREEN";
    ActionType["ADD_STARTER_SCREENS"] = "ADD_STARTER_SCREENS";
    ActionType["ADD_MEDIA"] = "ADD_MEDIA";
    ActionType["ADD_ZOOM"] = "ADD_ZOOM";
    ActionType["LEGACY_DELETE_ZOOM"] = "LEGACY_DELETE_ZOOM";
    ActionType["DELETE_ZOOM"] = "DELETE_ZOOM";
    ActionType["APPLY_BACKGROUND_TO_ALL_PAGES"] = "APPLY_BACKGROUND_TO_ALL_PAGES";
    ActionType["LEGACY_SET_SCREEN_IMAGE"] = "LEGACY_SET_SCREEN_IMAGE";
    ActionType["SET_SCREEN_IMAGE_BY_ID"] = "SET_SCREEN_IMAGE_BY_ID";
    ActionType["UPDATE_TOURIAL_PARTIAL"] = "UPDATE_TOURIAL_PARTIAL";
    ActionType["ADD_LISTENER"] = "ADD_LISTENER";
    ActionType["ADD_CLICK_LISTENER"] = "ADD_CLICK_LISTENER";
    ActionType["ADD_HOVER_LISTENER"] = "ADD_HOVER_LISTENER";
    ActionType["UPDATE_LISTENER"] = "UPDATE_LISTENER";
    ActionType["DELETE_LISTENER"] = "DELETE_LISTENER";
    ActionType["ADD_VIEW_TOOL"] = "ADD_VIEW_TOOL";
    ActionType["ADD_VIEW_TOOL_BY_TOOL_ID"] = "ADD_VIEW_TOOL_BY_TOOL_ID";
    ActionType["DELETE_VIEW_TOOL"] = "DELETE_VIEW_TOOL";
    ActionType["TOGGLE_SHOW_ON_MULTIPLE_VIEWS"] = "TOGGLE_SHOW_ON_MULTIPLE_VIEWS";
    ActionType["DELETE_VIEW_TOOL_BY_TOOL_ID"] = "DELETE_VIEW_TOOL_BY_TOOL_ID";
    ActionType["EDIT_VIEW_TOOL_BY_TOOL_ID"] = "EDIT_VIEW_TOOL_BY_TOOL_ID";
    ActionType["DELETE_CURRENT_VIEW_TOOL_TOOL"] = "DELETE_CURRENT_VIEW_TOOL_TOOL";
    ActionType["DELETE_CURRENT_EDIT_DETAILS_VIEW_TOOL"] = "DELETE_CURRENT_EDIT_DETAILS_VIEW_TOOL";
    ActionType["TOGGLE_TOOL_SHOW_ON_LOAD"] = "TOGGLE_TOOL_SHOW_ON_LOAD";
    ActionType["UPDATE_TOOL"] = "UPDATE_TOOL";
    ActionType["UPDATE_TOOL_MEDIA_SOURCE"] = "UPDATE_TOOL_MEDIA_SOURCE";
    ActionType["UPDATE_TOOL_SIZE_AND_POSITION"] = "UPDATE_TOOL_SIZE_AND_POSITION";
    ActionType["UPDATE_GROUP_SIZE_AND_POSITION"] = "UPDATE_GROUP_SIZE_AND_POSITION";
    ActionType["UPDATE_ZOOM"] = "UPDATE_ZOOM";
    ActionType["UPDATE_PAGE"] = "UPDATE_PAGE";
    ActionType["UPDATE_PAGES"] = "UPDATE_PAGES";
    ActionType["LEGACY_DUPLICATE_PAGE"] = "LEGACY_DUPLICATE_PAGE";
    ActionType["DUPLICATE_PAGE"] = "DUPLICATE_PAGE";
    ActionType["IMPORT_PAGE"] = "IMPORT_PAGE";
    ActionType["IMPORT_PAGES_V3"] = "IMPORT_PAGES_V3";
    ActionType["LEGACY_DUPLICATE_VIEW"] = "LEGACY_DUPLICATE_VIEW";
    ActionType["DUPLICATE_VIEW"] = "DUPLICATE_VIEW";
    ActionType["LEGACY_DELETE_PAGE"] = "LEGACY_DELETE_PAGE";
    ActionType["DELETE_PAGE"] = "DELETE_PAGE";
    ActionType["UNDO"] = "UNDO";
    ActionType["REDO"] = "REDO";
    ActionType["SET_LOCAL_TOURIAL"] = "SET_LOCAL_TOURIAL";
    ActionType["UPDATE_LOCAL_SAVED_TOURIAL"] = "UPDATE_LOCAL_SAVED_TOURIAL";
    ActionType["COPY_TOOL"] = "COPY_TOOL";
    ActionType["PASTE_TOOL"] = "PASTE_TOOL";
    ActionType["ADD_SUBMIT_FORM_ONCLICK_LISTENER"] = "ADD_SUBMIT_FORM_ONCLICK_LISTENER";
    ActionType["SAVE_COLOR"] = "SAVE_COLOR";
    ActionType["DELETE_COLOR"] = "DELETE_COLOR";
    ActionType["SET_DEFAULT_FONT_FAMILY"] = "SET_DEFAULT_FONT_FAMILY";
    ActionType["REORDER_PAGE"] = "REORDER_PAGE";
    ActionType["UPDATE_PAGE_INDEX"] = "UPDATE_PAGE_INDEX";
    ActionType["REORDER_ZOOM"] = "REORDER_ZOOM";
    ActionType["UPDATE_ZOOM_BY_ID"] = "UPDATE_ZOOM_BY_ID";
    ActionType["CREATE_VARIANT_MOBILE_TEMPLATE"] = "CREATE_VARIANT_MOBILE_TEMPLATE";
    ActionType["CREATE_MOBILE_VARIANT_FROM_DESKTOP"] = "CREATE_MOBILE_VARIANT_FROM_DESKTOP";
    ActionType["ADD_CLICKZONE_AND_BUTTON_DEFAULT_TRIGGER_LISTENER"] = "ADD_CLICKZONE_AND_BUTTON_DEFAULT_TRIGGER_LISTENER";
    ActionType["ADD_TOOLTIP_BUTTON_DEFAULT_TRIGGER_LISTENER"] = "ADD_TOOLTIP_BUTTON_DEFAULT_TRIGGER_LISTENER";
    ActionType["DELETE_TOOL"] = "DELETE_TOOL";
    ActionType["NUDGE_TOOL"] = "NUDGE_TOOL";
    ActionType["COPY_SELECTED_TOOL"] = "COPY_SELECTED_TOOL";
    ActionType["PASTE_TOOL_FROM_COPY_BUFFER_TO_CURRENT_STEP"] = "PASTE_TOOL_FROM_COPY_BUFFER_TO_CURRENT_STEP";
    ActionType["SET_IS_PUBLISHED"] = "SET_IS_PUBLISHED";
    ActionType["SET_TIMER"] = "SET_TIMER";
    ActionType["SET_TOURIAL_NAME"] = "SET_TOURIAL_NAME";
    ActionType["SET_FORM_ON_STEP"] = "SET_FORM_ON_STEP";
    ActionType["REVERT_TO_PUBLISHED_TOUR"] = "REVERT_TO_PUBLISHED_TOUR";
    ActionType["SET_VIDEO_DURATION"] = "SET_VIDEO_DURATION";
    ActionType["AUTO_UPDATE_TOOL"] = "AUTO_UPDATE_TOOL";
    ActionType["SET_TOURIAL_LOOP"] = "SET_TOURIAL_LOOP";
    /** actions that manage what's rendered in the viewport and do not edit the tourial */
    ActionType["TOOL_ON_DISPATCH_EVENT"] = "TOOL_ON_DISPATCH_EVENT";
    ActionType["UPDATE_BUILDER"] = "UPDATE_BUILDER";
    ActionType["PUSH_HISTORY"] = "PUSH_HISTORY";
    ActionType["SET_INITIAL_TOURIAL_ROOT_STATE"] = "SET_INITIAL_TOURIAL_ROOT_STATE";
    ActionType["CLEAR_TOURIAL_ROOT_STATE"] = "CLEAR_TOURIAL_ROOT_STATE";
    ActionType["UPDATE_VIEWPORT_MODE"] = "UPDATE_VIEWPORT_MODE";
    ActionType["UPDATE_VIEWPORT"] = "UPDATE_VIEWPORT";
    ActionType["SET_ACTIVE_VARIANT"] = "SET_ACTIVE_VARIANT";
    ActionType["SET_SHOULD_RENDER_FORM"] = "SET_SHOULD_RENDER_FORM";
    ActionType["SET_IS_TRANSITIONING"] = "SET_IS_TRANSITIONING";
    ActionType["SET_SHOULD_AUTO_START"] = "SET_SHOULD_AUTO_START";
    ActionType["LEGACY_CHANGE_VIEW"] = "LEGACY_CHANGE_VIEW";
    ActionType["CHANGE_STEP"] = "CHANGE_STEP";
    ActionType["SET_INITIAL_TRANSITION_AND_AUTO_START_STATE"] = "SET_INITIAL_TRANSITION_AND_AUTO_START_STATE";
    ActionType["TOGGLE_HIDDEN_TOOLS"] = "TOGGLE_HIDDEN_TOOLS";
    ActionType["LEGACY_HIDE_TOOL"] = "LEGACY_HIDE_TOOL";
    ActionType["LEGACY_SHOW_TOOL"] = "LEGACY_SHOW_TOOL";
    ActionType["HIDE_TOOLS"] = "HIDE_TOOLS";
    ActionType["SHOW_TOOLS"] = "SHOW_TOOLS";
    ActionType["SET_VIEWPORT_MODE"] = "SET_VIEWPORT_MODE";
    ActionType["SET_SCREEN_DID_LOAD"] = "SET_SCREEN_DID_LOAD";
    ActionType["REFRESH_VISIBLE_TOOL_IDS"] = "REFRESH_VISIBLE_TOOL_IDS";
    ActionType["SET_PAGE_FORM"] = "SET_PAGE_FORM";
    ActionType["SET_TOOL_IS_MULTISELECTED"] = "SET_TOOL_IS_MULTISELECTED";
    /** actions that don't fit the other categories */
    ActionType["ADD_PATCH"] = "ADD_PATCH";
    ActionType["SET_TOURIAL_THEME"] = "SET_TOURIAL_THEME";
    ActionType["UPDATE_BRAND_COLORS"] = "UPDATE_BRAND_COLORS";
    ActionType["SET_MICROSITE"] = "SET_MICROSITE";
    ActionType["UPDATE_MICROSITE"] = "UPDATE_MICROSITE";
    ActionType["SET_MICROSITE_ACTIVE_TOUR_ID"] = "SET_MICROSITE_ACTIVE_TOUR_ID";
    ActionType["SET_MICROSITE_EDITOR_MODE"] = "SET_MICROSITE_EDITOR_MODE";
    ActionType["SET_MICROSITE_EDITOR_LAYOUT"] = "SET_MICROSITE_EDITOR_LAYOUT";
    ActionType["SET_MICROSITE_CAN_PUBLISH"] = "SET_MICROSITE_CAN_PUBLISH";
    ActionType["SET_MICROSITE_EDITOR_OPEN_MENU"] = "SET_MICROSITE_EDITOR_OPEN_MENU";
    ActionType["SET_MICROSITE_EDITOR_FOCUS_FIELD"] = "SET_MICROSITE_EDITOR_FOCUS_FIELD";
    ActionType["SET_SAVED_MICROSITE"] = "SET_SAVED_MICROSITE";
    ActionType["UPDATE_MICROSITE_TOURS"] = "UPDATE_MICROSITE_TOURS";
    ActionType["SET_MICROSITE_SESSION_ID"] = "SET_MICROSITE_SESSION_ID";
    ActionType["SET_HUBSPOT_SCHEDULER"] = "SET_HUBSPOT_SCHEDULER";
    ActionType["SET_HUBSPOT_INTEGRATION"] = "SET_HUBSPOT_INTEGRATION";
    ActionType["SET_MARKETO_INTEGRATION"] = "SET_MARKETO_INTEGRATION";
    ActionType["NULL"] = "NULL";
    ActionType["SET_ACTIVE_TOUR_INDEX"] = "SET_ACTIVE_TOUR_INDEX";
    ActionType["SAVE_MICROSITE_HTML_METADATA"] = "SAVE_MICROSITE_HTML_METADATA";
    ActionType["SAVE_MICROSITE_SLUG"] = "SAVE_MICROSITE_SLUG";
    ActionType["SAVE_MICROSITE_IS_PUBLISHED"] = "SAVE_MICROSITE_IS_PUBLISHED";
    ActionType["SET_CURRENT_PAGE_NAME"] = "SET_CURRENT_PAGE_NAME";
    ActionType["SET_PAGE_NAME_BY_ID"] = "SET_PAGE_NAME_BY_ID";
    ActionType["SET_CURRENT_STEP_NAME"] = "SET_CURRENT_STEP_NAME";
    ActionType["SET_IS_MOBILE_BUTTON_DISABLED"] = "SET_IS_MOBILE_BUTTON_DISABLED";
    ActionType["SET_DEMOCENTERS"] = "SET_DEMOCENTERS";
    ActionType["SET_DEMOCENTER_DEFAULT_STATE"] = "SET_DEMOCENTER_DEFAULT_STATE";
    ActionType["UPDATE_DEMOCENTER"] = "UPDATE_DEMOCENTER";
    ActionType["SET_SAVED_DEMOCENTER"] = "SET_SAVED_DEMOCENTER";
    ActionType["SET_DEMOCENTER_SELECTED_TAGS"] = "SET_DEMOCENTER_SELECTED_TAGS";
    ActionType["SET_DEMOCENTER_TRACK_SELECTIONS"] = "SET_DEMOCENTER_TRACK_SELECTIONS";
    ActionType["SET_DEMOCENTER_MODE"] = "SET_DEMOCENTER_MODE";
    ActionType["SET_DEMOCENTER_ACTIVE_ITEM_ID"] = "SET_DEMOCENTER_ACTIVE_ITEM_ID";
    ActionType["SET_DEMOCENTER_ACTIVE_ITEM_HAS_BEEN_COMPLETED"] = "SET_DEMOCENTER_ACTIVE_ITEM_HAS_BEEN_COMPLETED";
    ActionType["SET_DEMOCENTER_ACTIVE_ITEM_SUB_NAVIGATION"] = "SET_DEMOCENTER_ACTIVE_ITEM_SUB_NAVIGATION";
    ActionType["SET_DEMOCENTER_VIEWED_ITEMS"] = "SET_DEMOCENTER_VIEWED_ITEMS";
    ActionType["SET_DEMOCENTER_ACTIVE_MODULE_SLUG"] = "SET_DEMOCENTER_ACTIVE_MODULE_SLUG";
    ActionType["SET_DEMOCENTER_FORWARD_PATH"] = "SET_DEMOCENTER_FORWARD_PATH";
    ActionType["SET_DEMOCENTER_SHOW_WELCOME_SCREEN"] = "SET_DEMOCENTER_SHOW_WELCOME_SCREEN";
    ActionType["SET_DEMOCENTER_SIDEBAR_IS_OPEN"] = "SET_DEMOCENTER_SHOULD_ANIMATE_SIDEBAR";
    ActionType["SET_DEMOCENTER_SESSION_ID"] = "SET_DEMOCENTER_SESSION_ID";
    ActionType["SET_DEMOCENTER_BUILDER_MODE"] = "SET_DEMOCENTER_BUILDER_MODE";
    ActionType["SET_DEMOCENTER_LIVE_PREVIEW_PATH"] = "SET_DEMOCENTER_LIVE_PREVIEW_PATH";
    ActionType["SET_DEMOCENTER_BUILDER_WARNINGS"] = "SET_DEMOCENTER_BUILDER_WARNINGS";
    ActionType["SET_DEMOCENTER_BUILDER_IS_COLLAPSED"] = "SET_DEMOCENTER_BUILDER_IS_COLLAPSED";
    ActionType["SET_DEMOCENTER_SHARE_LINK"] = "SET_DEMOCENTER_SHARE_LINK";
    ActionType["SET_DEMOCENTER_SHOW_UPFRONT_QUESTIONS"] = "SET_DEMOCENTER_SHOW_UPFRONT_QUESTIONS";
    ActionType["SET_IS_VIDEO_TRIM_MODAL_OPEN"] = "SET_IS_VIDEO_TRIM_MODAL_OPEN";
    ActionType["SET_SCREEN_MEDIA"] = "SET_SCREEN_MEDIA";
    ActionType["SET_TRIM_TIMER"] = "SET_TRIM_TIMER";
    ActionType["CHECK_OUT_VERSION"] = "CHECK_OUT_VERSION";
    ActionType["UPDATE_TOUR_PLAN"] = "UPDATE_TOUR_PLAN";
    ActionType["SET_TOUR_PLAN_INITIAL_STATE"] = "SET_TOUR_PLAN_INITIAL_STATE";
    ActionType["CLEAR_TOUR_PLAN_STATE"] = "CLEAR_TOUR_PLAN_STATE";
    ActionType["SET_TOUR_PLAN_ACTIVE_STEP"] = "SET_TOUR_PLAN_ACTIVE_STEP";
})(ActionType || (ActionType = {}));
/** actions allowed on an archived tourial */
export const ArchiveActions = [
    ActionType.TOOL_ON_DISPATCH_EVENT,
    ActionType.UPDATE_BUILDER,
    ActionType.PUSH_HISTORY,
    ActionType.SET_INITIAL_TOURIAL_ROOT_STATE,
    ActionType.CLEAR_TOURIAL_ROOT_STATE,
    ActionType.UPDATE_VIEWPORT_MODE,
    ActionType.UPDATE_VIEWPORT,
    ActionType.SET_ACTIVE_VARIANT,
    ActionType.SET_SHOULD_RENDER_FORM,
    ActionType.SET_IS_TRANSITIONING,
    ActionType.SET_SHOULD_AUTO_START,
    ActionType.LEGACY_CHANGE_VIEW,
    ActionType.CHANGE_STEP,
    ActionType.SET_INITIAL_TRANSITION_AND_AUTO_START_STATE,
    ActionType.TOGGLE_HIDDEN_TOOLS,
    ActionType.LEGACY_HIDE_TOOL,
    ActionType.LEGACY_SHOW_TOOL,
    ActionType.HIDE_TOOLS,
    ActionType.SHOW_TOOLS,
    ActionType.SET_VIEWPORT_MODE,
    ActionType.SET_SCREEN_DID_LOAD,
    ActionType.REFRESH_VISIBLE_TOOL_IDS,
    ActionType.SET_PAGE_FORM,
    ActionType.SET_TOOL_IS_MULTISELECTED,
    ActionType.ADD_PATCH,
    ActionType.SET_TOURIAL_THEME,
    ActionType.UPDATE_BRAND_COLORS,
    ActionType.SET_MICROSITE,
    ActionType.UPDATE_MICROSITE,
    ActionType.SET_MICROSITE_ACTIVE_TOUR_ID,
    ActionType.SET_MICROSITE_EDITOR_MODE,
    ActionType.SET_MICROSITE_EDITOR_LAYOUT,
    ActionType.SET_MICROSITE_CAN_PUBLISH,
    ActionType.SET_MICROSITE_EDITOR_OPEN_MENU,
    ActionType.SET_MICROSITE_EDITOR_FOCUS_FIELD,
    ActionType.SET_SAVED_MICROSITE,
    ActionType.UPDATE_MICROSITE_TOURS,
    ActionType.SET_MICROSITE_SESSION_ID,
    ActionType.SET_HUBSPOT_SCHEDULER,
    ActionType.SET_HUBSPOT_INTEGRATION,
    ActionType.SET_MARKETO_INTEGRATION,
    ActionType.NULL,
    ActionType.SET_ACTIVE_TOUR_INDEX,
    ActionType.AUTO_UPDATE_TOOL,
    ActionType.SET_VIDEO_DURATION,
    ActionType.SAVE_MICROSITE_HTML_METADATA,
    ActionType.SAVE_MICROSITE_SLUG,
    ActionType.SAVE_MICROSITE_IS_PUBLISHED,
];
