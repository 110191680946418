import React from "react";
import { ViewportMode } from "../../../types/definitions";
import { getImageDimensions, getMaskDimensions } from "./image-view";
import { withToolWrapper } from "../with-tool-wrapper";
import { FileLoadingIndicator } from "../../../components/shared/file-loading-indicator";
import { modifyVideoFormatForSafari } from "../../../helpers/misc";
function TourialVideo(props) {
    const { toolData, viewportMode } = props;
    const { shape, src, xOffset, yOffset, srcDimensions, controls, autoPlay, id, isMuted, animation, animationDuration } = toolData;
    if (toolData.isLoading) {
        return (React.createElement(FileLoadingIndicator, { viewportHeight: props.viewportDimensions.height, viewportWidth: props.viewportDimensions.width, toolHeight: toolData.sizeAndPosition.height, toolWidth: toolData.sizeAndPosition.width, toolType: toolData.type, id: toolData.id }));
    }
    if (toolData.uploadFailed) {
        return (React.createElement(FileLoadingIndicator, { id: toolData.id, viewportHeight: props.viewportDimensions.height, viewportWidth: props.viewportDimensions.width, toolHeight: toolData.sizeAndPosition.height, toolWidth: toolData.sizeAndPosition.width, toolType: toolData.type, uploadFailed: toolData.uploadFailed }));
    }
    return (React.createElement("div", { style: {
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
        } },
        React.createElement("div", { style: {
                position: "absolute",
                overflow: "hidden",
                display: "inline",
                height: `${getMaskDimensions(shape, srcDimensions).height}%`,
                width: `${getMaskDimensions(shape, srcDimensions).width}%`,
                borderRadius: shape === "rectangle" ? "0%" : "50%",
                animation: viewportMode !== ViewportMode.EDIT && animation,
                animationDuration: viewportMode !== ViewportMode.EDIT && animationDuration,
            } },
            React.createElement("video", { id: `${id}_video`, src: modifyVideoFormatForSafari(src), style: {
                    margin: "0 auto",
                    width: `${getImageDimensions(shape, srcDimensions).width}%`,
                    height: `${getImageDimensions(shape, srcDimensions).height}%`,
                    position: "relative",
                    top: `${shape === "circle" ? yOffset : 0}%`,
                    left: `${shape === "circle" ? xOffset : 0}%`,
                }, muted: isMuted, controls: controls, autoPlay: autoPlay, playsInline: true }))));
}
export const Video = React.memo(withToolWrapper(TourialVideo, null));
