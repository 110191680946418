import React from "react";
import styled from "styled-components";
import DesktopInactiveIcon from "../../../../../../assets/images/v3/desktop-variant-dark.svg";
import DesktopActiveIcon from "../../../../../../assets/images/v3/desktop-variant-light.svg";
import MobileInactiveIcon from "../../../../../../assets/images/v3/mobile-variant-dark.svg";
import MobileActiveIcon from "../../../../../../assets/images/v3/mobile-variant-light.svg";
import { newTheme } from "../../../../styled/theme";
import TTooltip from "../../../../styled/t-tooltip";
import { useHTIsMobile, useHTSetViewportPresetSize } from "../../../../../redux/selectors/ht-selectors";
import { HTViewportPresetSizes } from "../../../../../redux/reducers/dom-tour-reducer";
export function HTDeviceSelector() {
    const isMobile = useHTIsMobile();
    const setViewportPresetSize = useHTSetViewportPresetSize();
    return (React.createElement(DeviceSelectorContainer, null,
        React.createElement(Prefix, null, "View as"),
        React.createElement(TTooltip, { disabled: !isMobile, position: "bottom", content: "View with max dimensions" },
            React.createElement(VariantButton, { disabled: !isMobile, isActive: !isMobile, isLeft: true, onClick: () => setViewportPresetSize(HTViewportPresetSizes.AUTO) },
                React.createElement(DeviceIcon, { src: !isMobile ? DesktopActiveIcon : DesktopInactiveIcon }))),
        React.createElement(TTooltip, { disabled: isMobile, position: "bottom", content: "View with mobile dimensions" },
            React.createElement(VariantButton, { disabled: isMobile, isActive: isMobile, isLeft: false, onClick: () => setViewportPresetSize(HTViewportPresetSizes.SMALL) },
                React.createElement(DeviceIcon, { src: isMobile ? MobileInactiveIcon : MobileActiveIcon })))));
}
const DeviceSelectorContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Prefix = styled.div `
  margin-right: 16px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey900};
`;
export const VariantButton = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: ${({ isActive }) => (isActive ? "inherit" : "pointer")};
  border-radius: ${({ isLeft }) => (isLeft ? "4px 0 0 4px" : "0 4px 4px 0")};
  background-color: ${({ isActive }) => (isActive ? newTheme.colors.grey100 : newTheme.colors.white)};
  outline: ${({ isActive }) => isActive ? `1px solid ${newTheme.colors.grey200}` : `1px solid ${newTheme.colors.grey300}`};
  box-shadow: ${({ isActive }) => (isActive ? "2px 2px 10px rgba(33, 34, 41, 0.05)" : "none")};
  font: ${newTheme.fonts.small};
`;
const DeviceIcon = styled.img `
  width: 16px;
  height: 16px;
  margin-right: 3px;
`;
