export const getViewportCoords = () => {
    const viewport = document.getElementById("tourial-builder-viewport").getBoundingClientRect();
    return viewport;
};
export const handleDragEnd = (e, callback) => {
    const viewport = getViewportCoords();
    const isDroppedOverViewport = e.clientX >= viewport.left &&
        e.clientX <= viewport.right &&
        e.clientY >= viewport.top &&
        e.clientY <= viewport.bottom;
    const x = e.clientX - viewport.left;
    const y = e.clientY - viewport.top;
    const xPercent = (x / viewport.width) * 100;
    const yPercent = (y / viewport.height) * 100;
    if (!isDroppedOverViewport) {
        return;
    }
    callback(xPercent, yPercent);
};
