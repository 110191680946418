import React, { useMemo } from "react";
import { getDateStringWithOrdinalNum, getVideoThumbnail, transformImageToWebp, } from "../../../../shared/functions/helpers";
import { isImageFileExtension } from "../../helpers/media";
import PlaceholderThumbnail from "../../../assets/images/v3/placeholder-thumbnail.svg";
import styled from "styled-components";
import { newTheme } from "../styled/theme";
import { HomePageTabs, TourialStatus } from "../../../../shared/types/tourial-model";
import TStatusIndicator from "../styled/t-status-indicator";
import { useHistory } from "react-router-dom";
import { MainAppPagePaths } from "../../../../shared/types/app-events";
import { getMicrositeStatus, getTourialStatus } from "../../helpers/tourial";
export default function SearchCard({ data }) {
    const history = useHistory();
    const formattedDate = getDateStringWithOrdinalNum(`${data.updatedAt || data.createdAt}`);
    const src = data.thumbnail
        ? isImageFileExtension(data.thumbnail)
            ? transformImageToWebp(data.thumbnail)
            : getVideoThumbnail(data.thumbnail)
        : PlaceholderThumbnail;
    const handleCardClick = () => {
        if (data.type === HomePageTabs.MEDIA)
            history.push(`${MainAppPagePaths.V3_BUILDER}?tourId=${data._id}`);
        else if (data.type === HomePageTabs.TOURPLAYER)
            history.push(`${MainAppPagePaths.MICROSITES_EDITOR}?micrositeId=${data._id}`);
        else if (data.type === HomePageTabs.DEMOCENTER)
            history.push(`${MainAppPagePaths.DEMOCENTER_BUILDER}/${data._id}`);
        else if (data.type === HomePageTabs.HTML)
            window.location.href = `/dt/builder/${data._id}`;
    };
    const projectStatus = useMemo(() => {
        if (data.type === HomePageTabs.MEDIA)
            return getTourialStatus(data);
        else if (data.type === HomePageTabs.TOURPLAYER)
            return getMicrositeStatus(data);
        else
            return data.status;
    }, [data]);
    return (React.createElement(SearchCardContainer, { onClick: handleCardClick },
        React.createElement(ThumbnailContainer, null,
            React.createElement(Thumbnail, { src: src })),
        React.createElement(MiddleSection, null,
            React.createElement(Title, { className: "text-ellipsis" }, data.name),
            React.createElement(DateSpan, null, data.updatedAt ? `edited on ${formattedDate}` : `created on ${formattedDate}`)),
        React.createElement(RightSection, { style: {
                minWidth: `calc(${CARD_WS_AND_TYPE_MIN_WIDTH}px + ${RIGHT_SECTION_GAP}px + ${STATUS_WIDTHS[projectStatus]}px)`,
            } },
            React.createElement("div", { style: { display: "flex", minWidth: CARD_WS_AND_TYPE_MIN_WIDTH } },
                React.createElement("div", { className: "text-ellipsis" }, data.workspaceName),
                "\u00A0/\u00A0",
                React.createElement("div", { style: { whiteSpace: "nowrap" } }, data.type)),
            React.createElement(TStatusIndicator, { status: projectStatus }))));
}
const STATUS_WIDTHS = {
    [TourialStatus.PENDING_CHANGES]: 111.05,
    [TourialStatus.DRAFT]: 39.55,
    [TourialStatus.LIVE]: 33.94,
    [TourialStatus.ARCHIVED]: 55.45,
    [TourialStatus.UNPUBLISHED]: 71.39,
};
const RIGHT_SECTION_GAP = 10;
const CARD_WS_AND_TYPE_MIN_WIDTH = 102;
const Title = styled.div `
  font: ${newTheme.fonts.medium};
`;
const DateSpan = styled.span `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey700};
  height: 14px;
  display: block;
  margin: 4px 0 7px;
`;
const MiddleSection = styled.div `
  display: flex;
  flex-direction: column;
  margin-right: auto;
  min-width: 0px;
`;
const RightSection = styled.div `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey700};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${RIGHT_SECTION_GAP}px;
  p {
    margin-top: 0px;
  }
  margin-right: 10px;
`;
const ThumbnailContainer = styled.div `
  min-width: 120px;
  width: 120px;
  height: 100%;
  ${newTheme.patterns.polkaDot}
`;
const Thumbnail = styled.img `
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const SearchCardContainer = styled.div `
  cursor: pointer;
  height: 67.5px;
  min-height: 67.5px;
  width: 100%;
  border-bottom: 1px solid ${newTheme.colors.grey200};
  display: flex;
  align-items: center;
  gap: 16px;
  box-sizing: content-box;
`;
