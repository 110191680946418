import { cloneDeep } from "lodash";
import { ActiveVariantName, ShirtSize } from "../../../shared/types/tool-data-types/common";
import { googleFonts } from "../../../shared/legacy/legacy-tool-data";
export const fontOptions = googleFonts.map(font => {
    return {
        value: font,
        label: font,
        fontFamily: font,
    };
});
export const fontWeightOptions = [
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: 300, label: "300" },
    { value: 400, label: "400" },
    { value: 500, label: "500" },
    { value: 600, label: "600" },
    { value: 700, label: "700" },
    { value: 800, label: "800" },
    { value: 900, label: "900" },
];
export const mobileButtonShadowTypes = [
    { value: "none", label: "none" },
    { value: "shallowest", label: "shallowest" },
    { value: "shallow", label: "shallow" },
    { value: "medium", label: "medium" },
    { value: "deep", label: "deep" },
    { value: "deepest", label: "deepest" },
];
export const headlineFontSizeDropdownOptions = [
    { value: ShirtSize.XXSMALL, label: "14px", fontSize: "14px" },
    { value: ShirtSize.XSMALL, label: "16px", fontSize: "16px" },
    { value: ShirtSize.SMALL, label: "18px", fontSize: "18px" },
    { value: ShirtSize.MEDIUM, label: "22px", fontSize: "22px" },
    { value: ShirtSize.LARGE, label: "26px", fontSize: "26px" },
];
export const bodyFontSizeDropdownOptions = [
    { value: ShirtSize.SMALL, label: "12px", fontSize: "12px" },
    { value: ShirtSize.MEDIUM, label: "14px", fontSize: "14px" },
    { value: ShirtSize.LARGE, label: "16px", fontSize: "16px" },
];
export const getHeadlineFontSizeDropdownOptions = (activeVariantName) => {
    return getDisabledFontDropdownOptions(activeVariantName, headlineFontSizeDropdownOptions);
};
export const getBodyFontSizeDropdownOptions = (activeVariantName) => {
    return getDisabledFontDropdownOptions(activeVariantName, bodyFontSizeDropdownOptions);
};
const getDisabledFontDropdownOptions = (activeVariantName, fontOptions) => {
    const options = cloneDeep(fontOptions);
    const isMobile = activeVariantName === ActiveVariantName.VARIANT_MOBILE;
    const sizes = options.map(s => {
        s.isDisabled = isMobile && (s.value === ShirtSize.MEDIUM || s.value === ShirtSize.LARGE);
        return s;
    });
    return sizes;
};
export const mapGroupedCalendlyOptions = (data) => {
    // Calendly links can be event links grouped by users, or a plain list of member links
    const groups = {};
    const options = [];
    data.forEach(l => {
        const option = {
            label: l.name,
            value: l.link,
        };
        if (l.user) {
            if (!groups[l.user])
                groups[l.user] = [];
            groups[l.user].push(option);
        }
        else {
            options.push(option);
        }
    });
    return options.length
        ? options
        : Object.keys(groups).map(g => ({
            label: g,
            options: groups[g],
        }));
};
