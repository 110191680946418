import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { EditorAccordion } from "../../../../pages/builder/editor/editor-accordion";
import { cloneDeep } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import ContainerIcon from "../../../../../../assets/images/v3/editor-accordian-container-icon.svg";
import ButtonIcon from "../../../../../../assets/images/v3/editor-accordian-button-icon.svg";
import { ShapeSelector } from "../../../../pages/builder/editor/atomic/shape-selector";
import { ShapeSelectorLarge } from "../../../../pages/builder/editor/atomic/shape-selector-large";
import { ColorPicker } from "../../../../pages/builder/editor/atomic/color-picker";
import { OpacitySlider } from "../../../../pages/builder/editor/atomic/opacity-slider";
import { TCheckbox } from "../../../../styled/t-checkbox";
import { GlobalButtonSection } from "./global-button-section";
import { useFocusTourialEditorField } from "../../../../../hooks/use-focus-field";
import { TourialEditorFocusFields } from "../../../../../types/definitions";
import { ControlContainer, ControlLabel } from "../../../../styled/editor-components";
export function GlobalButtonsStyleSection(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    const [openId, setOpenId] = useState("background-shape-accordion");
    useFocusTourialEditorField(setOpenId);
    return (React.createElement(Section, null,
        React.createElement(EditorAccordion, { id: "background-shape-accordion", icon: ContainerIcon, title: "Container", isEnabled: true, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId },
            React.createElement(ShapeSelectorLarge, { shape: tool.background.shape, onChange: e => {
                    const t = cloneDeep(tool);
                    t.background.shape = e;
                    updateTool(t);
                } }),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Background Color"),
                React.createElement(ColorPicker, { color: tool.background.backgroundColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.background.backgroundColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Opacity"),
                React.createElement(OpacitySlider, { value: tool.background.opacity, min: 0, max: 100, onChange: (e) => {
                        const t = cloneDeep(tool);
                        t.background.opacity = e;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Border Color"),
                React.createElement(ColorPicker, { color: tool.background.borderColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.background.borderColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Shadow"),
                React.createElement(TCheckbox, { checked: tool.background.isShadowEnabled, onChange: (isChecked) => {
                        const t = cloneDeep(tool);
                        t.background.isShadowEnabled = isChecked;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Button Shape"),
                React.createElement(ShapeSelector, { shape: tool.buttons.shape, onChange: (shape) => {
                        const t = cloneDeep(tool);
                        t.buttons.shape = shape;
                        updateTool(t);
                    } }))),
        React.createElement(EditorAccordion, { id: TourialEditorFocusFields.GLOBAL_BUTTON_1, icon: ButtonIcon, title: "Button 1", isEnabled: tool.buttons.button1.isEnabled, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId },
            React.createElement(GlobalButtonSection, { button: tool.buttons.button1, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.buttons.button1 = e;
                    updateTool(t);
                } })),
        React.createElement(EditorAccordion, { id: TourialEditorFocusFields.GLOBAL_BUTTON_2, icon: ButtonIcon, title: "Button 2", isEnabled: tool.buttons.button2.isEnabled, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, isHidden: !tool.buttons.button2.isEnabled },
            React.createElement(GlobalButtonSection, { button: tool.buttons.button2, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.buttons.button2 = e;
                    updateTool(t);
                } })),
        React.createElement(EditorAccordion, { id: TourialEditorFocusFields.GLOBAL_BUTTON_3, icon: ButtonIcon, title: "Button 3", isEnabled: tool.buttons.button3.isEnabled, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, isHidden: !tool.buttons.button3.isEnabled },
            React.createElement(GlobalButtonSection, { button: tool.buttons.button3, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.buttons.button3 = e;
                    updateTool(t);
                } })),
        React.createElement(EditorAccordion, { id: TourialEditorFocusFields.GLOBAL_BUTTON_4, icon: ButtonIcon, title: "Button 4", isEnabled: tool.buttons.button4.isEnabled, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, isHidden: !tool.buttons.button4.isEnabled },
            React.createElement(GlobalButtonSection, { button: tool.buttons.button4, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.buttons.button4 = e;
                    updateTool(t);
                    console.log(t);
                } }))));
}
const Section = styled.div `
  max-height: 65vh;
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
