// TODO implement app check https://firebase.google.com/docs/app-check
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, doc, getDoc, updateDoc, onSnapshot, setDoc, } from "firebase/firestore";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { TourialApiCalls } from "../helpers/tourial-api-calls";
const firebasePublicConfigs = {
    /** also used for testing environment */
    development: {
        apiKey: "AIzaSyAuX_HyCU3qjPkwhnIUBtRNmvYeBIirk2I",
        authDomain: "tourial-b4e5a.firebaseapp.com",
        projectId: "tourial-b4e5a",
        storageBucket: "tourial-b4e5a.appspot.com",
        messagingSenderId: "505296547641",
        appId: "1:505296547641:web:9208d278398893f04042e8",
    },
    staging: {
        apiKey: "AIzaSyB74wi9GknavQnhST2bdm_bFdlPoqi9bxI",
        authDomain: "tourial-staging.firebaseapp.com",
        projectId: "tourial-staging",
        storageBucket: "tourial-staging.appspot.com",
        messagingSenderId: "484074726762",
        appId: "1:484074726762:web:24aa231a7b7707c8e57113",
        measurementId: "G-NV55S0CNMQ",
    },
    production: {
        apiKey: "AIzaSyAUxYyY25_J1grzCQlDN2esF27bq-jO56w",
        authDomain: "tourial-production.firebaseapp.com",
        projectId: "tourial-production",
        storageBucket: "tourial-production.appspot.com",
        messagingSenderId: "216418602303",
        appId: "1:216418602303:web:09e43fe83f349c5fa0178a",
        measurementId: "G-MVTEC7FJ2C",
    },
    testing: {
        projectId: "tourial-b4e5a",
        apiKey: "AIzaSyAuX_HyCU3qjPkwhnIUBtRNmvYeBIirk2I",
    },
};
function getFirebaseConfig() {
    return firebasePublicConfigs[process.env.DAR_ENV];
}
export function firebaseSignIn(app, token) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const auth = getAuth(app);
            signInWithCustomToken(auth, token)
                .then(userCredential => {
                resolve(userCredential.user);
            })
                .catch(error => {
                reject(error);
            });
        });
    });
}
export class FireSingleton {
    constructor() {
        this.app = initializeApp(getFirebaseConfig());
        if (process.env.DAR_ENV === "testing") {
            const functions = getFunctions(this.app);
            connectFunctionsEmulator(functions, "127.0.0.1", 5001);
        }
        this.db = getFirestore(this.app);
        void TourialApiCalls.Auth.getFirebaseToken().then(token => {
            return firebaseSignIn(this.app, token);
        });
    }
    static getInstance() {
        if (!FireSingleton.instance) {
            FireSingleton.instance = new FireSingleton();
        }
        // @ts-expect-error - for cypress tests
        window.FireSingleton = FireSingleton.instance;
        return FireSingleton.instance;
    }
}
FireSingleton.instance = null; // <-- Singleton instance
export class FSDocument {
    constructor(collection, id) {
        this.fetch = (defaultDoc) => __awaiter(this, void 0, void 0, function* () {
            const snap = yield getDoc(this.doc);
            if (snap.exists()) {
                return snap.data();
            }
            else {
                if (defaultDoc !== undefined) {
                    yield setDoc(this.doc, defaultDoc);
                }
                else {
                    throw new Error("failed to fetch firestore document snapshot");
                }
            }
        });
        this.subscribe = (onChange) => {
            this.unsubscribe = onSnapshot(this.doc, doc => {
                onChange(doc.data());
            });
        };
        this.update = (data) => __awaiter(this, void 0, void 0, function* () {
            yield updateDoc(this.doc, data);
        });
        const { db } = FireSingleton.getInstance();
        this.doc = doc(db, collection, id);
    }
}
