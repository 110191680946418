import React, { Fragment } from "react";
import styled from "styled-components";
import ZoomInIcon from "../../../../../../assets/images/zoom-in-icon.svg";
import ZoomOutIcon from "../../../../../../assets/images/zoom-out-icon.svg";
import { newTheme } from "../../../../styled/theme";
export const ZoomLevelSelector = (props) => {
    const { onChange, level } = props;
    // these must correspond with eachother
    const increment = 0.125;
    const zoomBubbles = [1.125, 1.25, 1.375, 1.5, 1.625, 1.75, 1.875, 2];
    const handleChange = (scale) => {
        onChange(scale);
    };
    const zoomIn = () => {
        if (level < zoomBubbles[zoomBubbles.length - 1])
            handleChange(level + increment);
    };
    const zoomOut = () => {
        if (level > 1)
            handleChange(level - increment);
    };
    return (React.createElement(ZoomLevelSelectorContainer, null,
        React.createElement(ZoomLevelBubbleContainer, null,
            React.createElement(Bubble, { className: "default", selected: true, onClick: () => handleChange(1) }),
            zoomBubbles.map((zb, i) => {
                return (React.createElement(Fragment, { key: i },
                    React.createElement(Spacer, { selected: level >= zb }),
                    React.createElement(Bubble, { className: `level-${i + 1}`, selected: level >= zb, onClick: () => handleChange(zb) })));
            })),
        React.createElement(ZoomControlsContainer, null,
            React.createElement(ZoomIcon, { disabled: level === 1, onClick: zoomOut },
                React.createElement("img", { src: ZoomOutIcon, alt: "Zoom Out" })),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(ZoomIcon, { disabled: level === zoomBubbles[zoomBubbles.length - 1], onClick: zoomIn },
                React.createElement("img", { src: ZoomInIcon, alt: "Zoom In" })))));
};
const ZoomLevelSelectorContainer = styled.div `
  width: 280px;
  margin: 16px 8px 8px 8px;
`;
const ZoomControlsContainer = styled.div `
  display: flex;
  margin-top: 8px;
`;
const ZoomLevelBubbleContainer = styled.div `
  display: flex;
`;
const Bubble = styled.button `
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${props => (props.selected ? newTheme.colors.blue : newTheme.colors.blue100)};
  margin-top: 12px;
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.blue400};
  }
  &.level-1 {
    width: 9px;
    height: 9px;
    margin-top: 11.5px;
    border-radius: 5px;
  }
  &.level-2 {
    width: 10px;
    height: 10px;
    margin-top: 11px;
    border-radius: 5px;
  }
  &.level-3 {
    width: 11px;
    height: 11px;
    margin-top: 10.5px;
    border-radius: 6px;
  }
  &.level-4 {
    width: 12px;
    height: 12px;
    margin-top: 10px;
    border-radius: 6px;
  }
  &.level-5 {
    width: 13px;
    height: 13px;
    margin-top: 9.5px;
    border-radius: 7px;
  }
  &.level-6 {
    width: 14px;
    height: 14px;
    margin-top: 9px;
    border-radius: 7px;
  }
  &.level-7 {
    width: 15px;
    height: 15px;
    margin-top: 8.5px;
    border-radius: 8px;
  }
  &.level-8 {
    width: 16px;
    height: 16px;
    margin-top: 8px;
    border-radius: 8px;
  }
`;
const Spacer = styled.div `
  flex: 1;
  height: 2px;
  background-color: ${props => (props.selected ? newTheme.colors.blue : newTheme.colors.blue100)};
  border-left: none;
  border-right: none;
  margin: 15px 0 0 0;
  z-index: 1;
`;
const ZoomIcon = styled.button `
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;
