import React, { useEffect } from "react";
import styled from "styled-components";
import { useDCLivePreviewPath, useDCSetBuilderMode, useDCSetLivePreviewPath, } from "../../../../redux/selectors/demo-center-selectors";
import { DCBuilderMode, DC_LIVE_PREVIEW_ROOT } from "../../../../../../shared/types/demo-center";
import { useDCSlug } from "../hooks/dc-hooks";
import { dcIsPathABuilderCMPage } from "../helpers/dc-helpers";
export const DCLivePreview = () => {
    const setMode = useDCSetBuilderMode();
    const setLivePreviewPath = useDCSetLivePreviewPath();
    const livePreviewPath = useDCLivePreviewPath();
    const slug = useDCSlug();
    const defaultPath = livePreviewPath && dcIsPathABuilderCMPage(livePreviewPath) ? "" : livePreviewPath || "";
    useEffect(() => {
        setMode(DCBuilderMode.PREVIEW);
        return () => setLivePreviewPath(null);
    }, []);
    return (React.createElement(PreviewContainer, null,
        React.createElement("iframe", { src: `/${DC_LIVE_PREVIEW_ROOT}/${slug}/${defaultPath}`, title: "Live Preview" })));
};
const PreviewContainer = styled.div `
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;
