import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Rnd } from "react-rnd";
import { useUpdateMultitoolPositions } from "../../../redux/selectors/redux-selectors";
import { isScreenComponent } from "../tool-views/background-view";
export function DragSelect(props) {
    var _a, _b;
    const [selectBox, setSelectBox] = useState();
    const [wasDragged, setWasDragged] = useState(false);
    const [prevViewportDimensions, setPrevViewportDimensions] = useState();
    const { dragSelectBoxData, setInsideSelectBox, viewportDimensions, isScreenLocked } = props;
    const updateMultitoolPositions = useUpdateMultitoolPositions();
    // if selectBox exists, scale with viewportDimension changes
    useEffect(() => {
        if (prevViewportDimensions && selectBox) {
            const { height, width } = selectBox;
            const scaledHeight = height * (viewportDimensions.height / prevViewportDimensions.height);
            const scaledWidth = width * (viewportDimensions.width / prevViewportDimensions.width);
            setSelectBox(prev => ({
                topLeft: {
                    x: (prev.topLeft.x / prevViewportDimensions.width) * viewportDimensions.width,
                    y: (prev.topLeft.y / prevViewportDimensions.height) * viewportDimensions.height,
                },
                height: scaledHeight,
                width: scaledWidth,
            }));
        }
        setPrevViewportDimensions(viewportDimensions);
    }, [viewportDimensions]);
    function calculateStartPointAndSize() {
        const { width, height, x: left, y: top } = viewportDimensions;
        const right = left + width;
        const bottom = top + height;
        const { startPoint, currentPoint, mouseDown } = dragSelectBoxData;
        if (startPoint && currentPoint && mouseDown) {
            const startX = Math.max(Math.min(startPoint.x, currentPoint.x), left);
            const startY = Math.max(Math.min(startPoint.y, currentPoint.y), top);
            const width = Math.min(Math.max(startPoint.x, currentPoint.x), right) - startX;
            const height = Math.min(Math.max(startPoint.y, currentPoint.y), bottom) - startY;
            return {
                topLeft: { x: startX - left, y: startY - top },
                width,
                height,
            };
        }
        else {
            // clear selectBox
            return null;
        }
    }
    const onMultiselectDragEnd = (draggableData) => {
        updateMultitoolPositions({ draggableData, viewportDimensions });
        setSelectBox(prev => (Object.assign(Object.assign({}, prev), { topLeft: {
                x: prev.topLeft.x + draggableData.x,
                y: prev.topLeft.y + draggableData.y,
            } })));
    };
    useEffect(() => {
        // whenever the parent component passes a finalContainerBorder, use as the selectBox
        if (dragSelectBoxData.finalContainerBorders) {
            const { top, bottom, right, left } = dragSelectBoxData.finalContainerBorders;
            setSelectBox({
                topLeft: {
                    x: left - viewportDimensions.x,
                    y: top - viewportDimensions.y,
                },
                width: right - left,
                height: bottom - top,
            });
            return;
        }
        // otherwise, the user is actively dragging to form a selectBox
        // disable pointer events for tools in viewport while drag select is being used
        document.querySelectorAll("#tourial-viewport > .react-draggable").forEach((e) => {
            if (!(isScreenComponent(e) && isScreenLocked)) {
                e.style.pointerEvents = dragSelectBoxData.mouseDown ? "none" : "auto";
            }
        });
        setSelectBox(calculateStartPointAndSize());
    }, [
        dragSelectBoxData.startPoint,
        dragSelectBoxData.currentPoint,
        dragSelectBoxData.mouseDown,
        dragSelectBoxData.finalContainerBorders,
    ]);
    return (React.createElement("div", { id: "drag-select-box", style: {
            outline: selectBox ? "1px solid #2a73e8" : "none",
            position: "relative",
            top: `${((_a = selectBox === null || selectBox === void 0 ? void 0 : selectBox.topLeft) === null || _a === void 0 ? void 0 : _a.y) || 0}px`,
            left: `${((_b = selectBox === null || selectBox === void 0 ? void 0 : selectBox.topLeft) === null || _b === void 0 ? void 0 : _b.x) || 0}px`,
            width: `${(selectBox === null || selectBox === void 0 ? void 0 : selectBox.width) || 0}px`,
            height: `${(selectBox === null || selectBox === void 0 ? void 0 : selectBox.height) || 0}px`,
            zIndex: 1500,
        }, onClick: (e) => {
            // checking/setting wasDragged to false here because component registers click after dragStop
            if (!wasDragged)
                findReactDraggable(e);
            setWasDragged(false);
        }, onMouseEnter: () => {
            if (dragSelectBoxData.finalContainerBorders)
                setInsideSelectBox(true);
        }, onMouseLeave: () => {
            if (dragSelectBoxData.finalContainerBorders)
                setInsideSelectBox(false);
        } }, dragSelectBoxData.finalContainerBorders && (React.createElement(Rnd, { size: { width: `100%`, height: `100%` }, style: { outline: "1px solid #2a73e8" }, position: {
            x: 0,
            y: 0,
        }, enableResizing: false, onDragStop: (e, draggableData) => {
            e.stopPropagation();
            e.preventDefault();
            if (draggableData.x || draggableData.y) {
                setWasDragged(true);
                onMultiselectDragEnd(draggableData);
            }
        }, bounds: "#tourial-viewport" }))));
}
// simulate click on first tool inside the select box
function findReactDraggable(e) {
    const clickedElements = document.elementsFromPoint(e.clientX, e.clientY);
    // skip the first instance, it is the draggable selectbox
    let foundFirst = false;
    const secondReactDraggable = clickedElements.find(e => {
        if (e.className.includes("react-draggable") && !foundFirst) {
            foundFirst = true;
            return false;
        }
        else if (isScreenComponent(e)) {
            return false;
        }
        else {
            return e.className.includes("react-draggable");
        }
    });
    if (secondReactDraggable) {
        const event = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
            shiftKey: e.shiftKey,
        });
        secondReactDraggable.firstChild.dispatchEvent(event);
    }
}
