import { ActionType } from "../actions/redux-actions";
export function tourialThemeReducer(prevState = {}, action) {
    switch (action.type) {
        case ActionType.SET_TOURIAL_THEME: {
            return { tourialTheme: action.tourialTheme };
        }
        default:
            return prevState;
    }
}
