export var AccessLevel;
(function (AccessLevel) {
    /** "public" is internal only and should not exist in mongo */
    AccessLevel["PUBLIC"] = "public";
    AccessLevel["VIEWER"] = "view";
    AccessLevel["EDITOR"] = "edit";
    AccessLevel["COLLABORATOR"] = "collaborator";
    AccessLevel["ADMIN"] = "admin";
    AccessLevel["SUPERADMIN"] = "superAdmin";
    AccessLevel["ENGINEER"] = "engineer";
})(AccessLevel || (AccessLevel = {}));
export function getReadableAuthNameFor(accessLevel) {
    const names = {
        [AccessLevel.PUBLIC]: "Public",
        [AccessLevel.VIEWER]: "Viewer",
        [AccessLevel.EDITOR]: "Editor",
        [AccessLevel.COLLABORATOR]: "Collaborator",
        [AccessLevel.ADMIN]: "Administrator",
        [AccessLevel.SUPERADMIN]: "Tourial Superadmin",
        [AccessLevel.ENGINEER]: "Tourial Engineer",
    };
    return names[accessLevel];
}
export const accessLevelHierarchy = {
    [AccessLevel.PUBLIC]: 0,
    [AccessLevel.VIEWER]: 1,
    [AccessLevel.EDITOR]: 2,
    [AccessLevel.COLLABORATOR]: 3,
    [AccessLevel.ADMIN]: 4,
    [AccessLevel.SUPERADMIN]: 5,
    [AccessLevel.ENGINEER]: 6,
};
