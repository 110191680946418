import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import { useState } from "react";
import React from "react";
import { useHTUpdateValue } from "../hooks/ht-hooks";
import { EDITING_Z_INDEX, EDITOR_STEP_NAME, EDITOR_TAB_HEIGHT, MOBILE_TOOLTIP_HEIGHT, NAV_HEIGHT } from "../config";
import { HTMode } from "../../../../../../shared/types/dom-tour";
import { BorderShape, ShirtSize, TextStyle } from "../../../../../../shared/types/tool-data-types/common";
import ReactQuill from "react-quill";
export const Header = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
`;
export const H2 = styled.h2 `
  font: ${newTheme.fonts.header};
`;
export const Icon = styled.img `
  cursor: pointer;
`;
export const EditorContainer = styled.div `
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - ${NAV_HEIGHT + EDITOR_TAB_HEIGHT + EDITOR_STEP_NAME}px);
  overflow-y: scroll;
  padding: 16px;
  gap: 16px;
  background: ${newTheme.colors.grey100};
  > label {
    width: 100%;
  }
`;
export const TextArea = styled.textarea `
  width: 100%;
  font: ${newTheme.fonts.medium};
  background-color: ${newTheme.colors.white};
  border-color: ${newTheme.colors.grey400};
  color: ${newTheme.colors.black};
  border-style: solid;
  border-width: 1px;
  width: 100%;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  &:focus {
    color: ${newTheme.colors.blue400};
    border-color: ${newTheme.colors.blue400};
    box-shadow: ${newTheme.shadows.textInputSelect};
  }
`;
export const Select = styled.select `
  width: 100%;
  font: ${newTheme.fonts.medium};
  background-color: ${newTheme.colors.white};
  border-color: ${newTheme.colors.grey400};
  color: ${newTheme.colors.black};
  border-style: solid;
  border-width: 1px;
  padding: 4px 8px 4px 8px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  appearance: none;
  &:focus {
    color: ${newTheme.colors.blue400};
    border-color: ${newTheme.colors.blue400};
    box-shadow: ${newTheme.shadows.textInputSelect};
  }
`;
export const Number = styled.input `
  width: 100%;
  font: ${newTheme.fonts.medium};
  background-color: ${newTheme.colors.white};
  border-color: ${newTheme.colors.grey400};
  color: ${newTheme.colors.black};
  border-style: solid;
  border-width: 1px;
  padding: 4px 8px 4px 8px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  appearance: none;
  &:focus {
    color: ${newTheme.colors.blue400};
    border-color: ${newTheme.colors.blue400};
    box-shadow: ${newTheme.shadows.textInputSelect};
  }
`;
export const htHeadlineFontSizes = {
    [ShirtSize.XXSMALL]: 21,
    [ShirtSize.XSMALL]: 24,
    [ShirtSize.SMALL]: 27,
    [ShirtSize.MEDIUM]: 33,
    [ShirtSize.LARGE]: 39,
};
export const htHeadlineFontSizeDropdownOptions = [
    { value: ShirtSize.XXSMALL, label: "14px", fontSize: `${htHeadlineFontSizes[ShirtSize.XXSMALL]}px` },
    { value: ShirtSize.XSMALL, label: "16px", fontSize: `${htHeadlineFontSizes[ShirtSize.XSMALL]}px` },
    { value: ShirtSize.SMALL, label: "18px", fontSize: `${htHeadlineFontSizes[ShirtSize.SMALL]}px` },
    { value: ShirtSize.MEDIUM, label: "22px", fontSize: `${htHeadlineFontSizes[ShirtSize.MEDIUM]}px` },
    { value: ShirtSize.LARGE, label: "26px", fontSize: `${htHeadlineFontSizes[ShirtSize.LARGE]}px` },
];
export const htOverlayHeadlineFontSizes = {
    [ShirtSize.XXSMALL]: 32,
    [ShirtSize.XSMALL]: 40,
    [ShirtSize.SMALL]: 48,
    [ShirtSize.MEDIUM]: 56,
    [ShirtSize.LARGE]: 64,
};
export const htOverlayHeadlineFontSizeDropdownOptions = [
    { value: ShirtSize.XXSMALL, label: "21px", fontSize: `${htOverlayHeadlineFontSizes[ShirtSize.XXSMALL]}px` },
    { value: ShirtSize.XSMALL, label: "26px", fontSize: `${htOverlayHeadlineFontSizes[ShirtSize.XSMALL]}px` },
    { value: ShirtSize.SMALL, label: "31px", fontSize: `${htOverlayHeadlineFontSizes[ShirtSize.SMALL]}px` },
    { value: ShirtSize.MEDIUM, label: "37px", fontSize: `${htOverlayHeadlineFontSizes[ShirtSize.MEDIUM]}px` },
    { value: ShirtSize.LARGE, label: "42px", fontSize: `${htOverlayHeadlineFontSizes[ShirtSize.LARGE]}px` },
];
export const htBodyFontSizes = {
    [ShirtSize.XSMALL]: 18,
    [ShirtSize.SMALL]: 21,
    [ShirtSize.MEDIUM]: 24,
};
export const htBodyFontSizeDropdownOptions = [
    { value: ShirtSize.XSMALL, label: "12px", fontSize: `${htBodyFontSizes[ShirtSize.XSMALL]}px` },
    { value: ShirtSize.SMALL, label: "14px", fontSize: `${htBodyFontSizes[ShirtSize.SMALL]}px` },
    { value: ShirtSize.MEDIUM, label: "16px", fontSize: `${htBodyFontSizes[ShirtSize.MEDIUM]}px` },
];
// backdrop-filter doesnt work in production democenter Firefox so remove for intro/outro
export const BaseOverlay = styled.div `
  background-color: rgba(${({ theme }) => { var _a; return (((_a = theme.overlay) === null || _a === void 0 ? void 0 : _a.backgroundColor) === "white" ? "255,255,255" : "0,0,0"); }}, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${EDITING_Z_INDEX};
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;
export const Overlay = styled(BaseOverlay) `
  backdrop-filter: blur(12.5px);
`;
export const htTooltipBorderRadii = {
    [BorderShape.RECTANGLE]: 0,
    [BorderShape.ROUNDED]: 8,
    [BorderShape.SQUIRCLE]: 12,
};
export const htButtonBorderRadii = {
    [BorderShape.RECTANGLE]: 0,
    [BorderShape.ROUNDED]: 8,
    [BorderShape.SQUIRCLE]: 30,
};
export const ModalHeader = styled.h1 `
  font-family: ${({ theme }) => theme.overlay.headline.font};
  color: ${({ theme }) => theme.overlay.headline.color};
  font-weight: ${({ theme }) => (theme.overlay.headline.style === TextStyle.BOLD ? "bold" : "normal")};
  text-decoration: ${({ theme }) => (theme.overlay.headline.style === TextStyle.UNDERLINE ? "underline" : "none")};
  font-style: ${({ theme }) => (theme.overlay.headline.style === TextStyle.ITALIC ? "italic" : "normal")};
  font-size: ${({ theme }) => htOverlayHeadlineFontSizes[theme.overlay.headline.size]}px;
  text-align: center;
  width: 100%;
  padding: 0px 24px;
`;
export const ModalBody = styled.div `
  font-family: ${({ theme }) => theme.overlay.body.font};
  color: ${({ theme }) => theme.overlay.body.color};
  font-size: ${({ theme }) => htBodyFontSizes[theme.overlay.body.size]}px;
  text-align: center;
  width: 100%;
  padding: 0px 24px;
`;
export const HTButton = styled.button `
  height: 60px;
  padding: 0px 24px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.button.body.font};
  color: ${({ theme }) => theme.button.body.color};
  font-size: ${({ theme }) => htBodyFontSizes[theme.button.body.size]}px;
  background-color: ${({ theme }) => theme.button.backgroundColor};
  border-radius: ${({ theme }) => htButtonBorderRadii[theme.button.shape]}px;
  transition: all 0.1s ease-in-out;
  box-shadow:
    2px 2px 10px rgba(33, 34, 41, 0.05),
    inset 0px 0px 1px rgba(33, 34, 41, 0.4);
  &:hover {
    transform: scale(105%);
    box-shadow:
      0px 0px 1px rgba(48, 49, 51, 0.05),
      0px 8px 16px rgba(48, 49, 51, 0.1);
  }
`;
export const HTModal = styled.div `
  margin: 0 auto;
  position: absolute;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  z-index: ${EDITING_Z_INDEX + 1};
  gap: 30px;
`;
const Label = styled.label `
  font: ${newTheme.fonts.normal};
  user-select: none;
  width: 100%;
`;
const Input = styled.input `
  width: 100%;
  font: ${newTheme.fonts.normal};
  background-color: ${newTheme.colors.white};
  border-color: ${newTheme.colors.grey600};
  color: ${newTheme.colors.grey600};
  border-style: solid;
  border-width: 1px;
  width: 100%;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  &:focus {
    color: ${newTheme.colors.blue400};
    border-color: ${newTheme.colors.blue400};
    box-shadow: ${newTheme.shadows.textInputSelect};
  }
`;
export function DraftInput(props) {
    const [value, setValue] = useState(props.value);
    useHTUpdateValue(props.value, setValue);
    let shouldCommitOnBlur = true;
    const handleSlugRestrictions = (e) => {
        const slug = e.target.value.replace(/[^a-zA-Z0-9\-]/g, "");
        return slug;
    };
    return (React.createElement(Input, { className: "focusable-input", placeholder: props === null || props === void 0 ? void 0 : props.placeholder, style: props === null || props === void 0 ? void 0 : props.style, value: value, onChange: e => {
            var _a;
            if ((_a = props === null || props === void 0 ? void 0 : props.options) === null || _a === void 0 ? void 0 : _a.applySlugRestrictions) {
                setValue(handleSlugRestrictions(e));
            }
            else {
                setValue(e.target.value);
            }
        }, onBlur: () => {
            var _a;
            (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props);
            if (shouldCommitOnBlur) {
                props.commit(value);
            }
            else {
                setValue(props.value);
            }
        }, onFocus: () => {
            var _a;
            (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props);
        }, onKeyDown: e => {
            if (e.code === "Enter") {
                e.stopPropagation();
                e.preventDefault();
                shouldCommitOnBlur = true;
                //@ts-expect-error
                e.target.blur();
            }
            else if (e.code === "Escape") {
                e.preventDefault();
                e.stopPropagation();
                shouldCommitOnBlur = false;
                //@ts-expect-error
                e.target.blur();
            }
        } }));
}
export function HTLabelledTextInput(props) {
    const { value: initialValue, name, commit, placeholder, id } = props;
    const [labelColor, setLabelColor] = useState(newTheme.colors.grey700);
    return (React.createElement(Label, { style: { color: labelColor }, id: id },
        React.createElement("span", null, name),
        React.createElement(DraftInput, { onFocus: () => setLabelColor(newTheme.colors.blue400), onBlur: () => setLabelColor(newTheme.colors.grey700), value: initialValue, commit: commit, placeholder: placeholder })));
}
export function HTDraftTextArea(props) {
    const [value, setValue] = useState(props.value);
    useHTUpdateValue(props.value, setValue);
    return (React.createElement(ReactQuill, { key: props.id + "-quill-editor", theme: "snow", value: value, onChange: value => setValue(value), style: {
            backgroundColor: newTheme.colors.white,
        }, modules: {
            toolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
        }, onBlur: () => props.commit(value) }));
}
export const HTMobileTextContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const HTMobileButtonContainer = styled.div `
  display: flex;
  gap: 8px;
`;
export const HTMobileRoot = styled.div `
  z-index: ${({ isEditing }) => (isEditing ? EDITING_Z_INDEX : "auto")};
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: ${({ viewportData, mode }) => viewportData.screenHeight - MOBILE_TOOLTIP_HEIGHT + (mode === HTMode.LIVE ? 0 : viewportData.top)}px;
  left: ${({ viewportData, mode }) => (mode === HTMode.LIVE ? 0 : viewportData.left)}px;
  width: ${({ viewportData }) => viewportData.screenWidth}px;
  height: ${MOBILE_TOOLTIP_HEIGHT}px;
  background-color: ${({ theme }) => theme.tooltip.backgroundColor};
`;
export const HTMobileHeader = styled.h3 `
  font-family: ${({ theme }) => theme.overlay.headline.font};
  font-weight: ${({ theme }) => (theme.overlay.headline.style === TextStyle.BOLD ? "bold" : "normal")};
  text-decoration: ${({ theme }) => (theme.overlay.headline.style === TextStyle.UNDERLINE ? "underline" : "none")};
  font-style: ${({ theme }) => (theme.overlay.headline.style === TextStyle.ITALIC ? "italic" : "normal")};
  font-size: 16px;
  color: ${({ theme }) => theme.overlay.headline.color};
`;
export const HTMobileBody = styled.p `
  font-family: ${({ theme }) => theme.overlay.body.font};
  font-size: 12px;
  color: ${({ theme }) => theme.overlay.body.color};
`;
export const HTMobileButton = styled.button `
  cursor: pointer;
  padding: 10px;
  background-color: ${({ theme }) => theme.button.backgroundColor};
  color: ${({ theme }) => theme.button.textColor};
  font-size: 12px;
  border-radius: ${({ theme }) => htButtonBorderRadii[theme.button.shape]}px;
  transition: all 0.1s ease-in-out;
  box-shadow:
    2px 2px 10px rgba(33, 34, 41, 0.05),
    inset 0px 0px 1px rgba(33, 34, 41, 0.4);
  &:hover {
    transform: scale(105%);
    box-shadow:
      0px 0px 1px rgba(48, 49, 51, 0.05),
      0px 8px 16px rgba(48, 49, 51, 0.1);
  }
`;
