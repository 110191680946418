import React from "react";
import { withToolWrapper } from "../with-tool-wrapper";
import { EditableTextarea, getTextAreaCursor } from "./editable-text-area-view";
import { ViewportMode } from "../../../types/definitions";
import { useEditingTextToolId, useViewportMode } from "../../../redux/selectors/redux-selectors";
function TourialTextbox(props) {
    const viewportMode = useViewportMode();
    const isToolEditingText = useEditingTextToolId(props.toolData.id);
    const { animation, animationDuration } = props.toolData;
    return (React.createElement("div", { className: "_test_textbox-viewtool", style: {
            width: `100%`,
            height: `100%`,
            top: "0px",
            position: "absolute",
            display: "inline",
            lineHeight: "normal",
            animation: viewportMode !== ViewportMode.EDIT && animation,
            animationDuration: viewportMode !== ViewportMode.EDIT && animationDuration,
            overflow: "clip",
            cursor: getTextAreaCursor(viewportMode, isToolEditingText),
        }, id: props.toolData.id },
        React.createElement(EditableTextarea, { toolId: props.toolData.id, viewportDimensions: props.viewportDimensions })));
}
export const Textbox = React.memo(withToolWrapper(TourialTextbox));
