var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Rnd } from "react-rnd";
import { useIsToolHidden, useIsToolHoveredOrSelected, usePushHistory } from "../../../redux/selectors/redux-selectors";
import { resizeHandles } from "../../../legacy/components/builder/resize-handle";
import { SelectionWrapper } from "../edit/selection-wrapper";
import { percentToPx } from "../../../helpers/tooltip-v2-size-and-position-helpers";
import { isEqual } from "lodash";
import { useIsToolSelected } from "../../../redux/selectors/builder-selectors";
function getWrapperSize(size, isStatic) {
    return isStatic
        ? {
            width: `${size.width}px`,
            height: `${size.height}px`,
        }
        : { width: `${size.width}%`, height: `${size.height}%` };
}
function getWrapperPosition(position, isStatic, viewportSize) {
    return {
        x: percentToPx(position.x, viewportSize.width),
        y: percentToPx(position.y, viewportSize.height),
    };
}
export const withFlexibleToolWrapper = (ToolView, traits, properties, overloads) => (_a) => {
    var _b, _c;
    var props = __rest(_a, []);
    const { toolData, viewportDimensions } = props;
    const isHidden = useIsToolHidden(toolData.id);
    const isToolHoveredOrSelected = useIsToolHoveredOrSelected(toolData.id);
    const isSelected = useIsToolSelected(toolData.id);
    const pushHistory = usePushHistory();
    const { width: viewportWidth, height: viewportHeight } = viewportDimensions;
    if (isHidden || !viewportWidth || !viewportHeight)
        return null;
    return (React.createElement(Rnd, { className: `rnd-tool-${toolData.id}`, default: Object.assign(Object.assign({}, getWrapperPosition(properties.position, traits.isStaticSize, {
            width: viewportWidth,
            height: viewportHeight,
        })), getWrapperSize(properties.size, traits.isStaticSize)), resizeHandleComponent: resizeHandles(!traits.isResizable || !traits.isEditing || !isToolHoveredOrSelected), style: {
            overflow: "visible",
            zIndex: traits.isEditing && isSelected ? "100" : "auto",
            pointerEvents: traits.isAutoTool && !traits.isEditing ? "none" : "auto",
        }, bounds: properties.bounds, size: getWrapperSize(properties.size, traits.isStaticSize), minHeight: ((_b = properties.minSize) === null || _b === void 0 ? void 0 : _b.height) || 0, minWidth: ((_c = properties.minSize) === null || _c === void 0 ? void 0 : _c.width) || 0, position: getWrapperPosition(properties.position, traits.isStaticSize, {
            width: viewportWidth,
            height: viewportHeight,
        }), disableDragging: !traits.isEditing || !traits.isDraggable, lockAspectRatio: traits.isAspectRatioLocked, enableResizing: traits.isEditing && traits.isResizable, onDragStart: () => {
            pushHistory();
        }, onDrag: (e, draggableData) => {
            const nextPosition = {
                x: (draggableData.x / props.viewportDimensions.width) * 100,
                y: (draggableData.y / props.viewportDimensions.height) * 100,
            };
            if (isEqual(nextPosition, properties.position))
                return;
            overloads.onDrag({
                x: (draggableData.x / props.viewportDimensions.width) * 100,
                y: (draggableData.y / props.viewportDimensions.height) * 100,
            });
        }, onDragStop: (e, draggableData) => {
            const nextPosition = {
                x: (draggableData.x / props.viewportDimensions.width) * 100,
                y: (draggableData.y / props.viewportDimensions.height) * 100,
            };
            if (isEqual(nextPosition, properties.position))
                return;
            overloads.onDragStop({
                x: (draggableData.x / props.viewportDimensions.width) * 100,
                y: (draggableData.y / props.viewportDimensions.height) * 100,
            });
        }, onResizeStart: () => {
            pushHistory();
        }, onResize: (e, _direction, ref, resizableDelta, position) => {
            const nextPosition = {
                x: (position.x / props.viewportDimensions.width) * 100,
                y: (position.y / props.viewportDimensions.height) * 100,
            };
            const nextSize = {
                width: (ref.offsetWidth / ref.parentElement.clientWidth) * 100,
                height: (ref.offsetHeight / ref.parentElement.clientHeight) * 100,
            };
            if (isEqual(nextPosition, properties.position && isEqual(nextSize, properties.size)))
                return;
            overloads.onResize({
                width: (ref.offsetWidth / ref.parentElement.clientWidth) * 100,
                height: (ref.offsetHeight / ref.parentElement.clientHeight) * 100,
            }, {
                x: (position.x / props.viewportDimensions.width) * 100,
                y: (position.y / props.viewportDimensions.height) * 100,
            });
        }, onResizeStop: (e, _direction, ref, resizableDelta, position) => {
            const nextPosition = {
                x: (position.x / props.viewportDimensions.width) * 100,
                y: (position.y / props.viewportDimensions.height) * 100,
            };
            const nextSize = {
                width: (ref.offsetWidth / ref.parentElement.clientWidth) * 100,
                height: (ref.offsetHeight / ref.parentElement.clientHeight) * 100,
            };
            if (isEqual(nextPosition, properties.position && isEqual(nextSize, properties.size)))
                return;
            overloads.onResizeStop({
                width: (ref.offsetWidth / ref.parentElement.clientWidth) * 100,
                height: (ref.offsetHeight / ref.parentElement.clientHeight) * 100,
            }, {
                x: (position.x / props.viewportDimensions.width) * 100,
                y: (position.y / props.viewportDimensions.height) * 100,
            });
        } }, traits.isEditing ? (React.createElement(SelectionWrapper, { toolId: toolData.id, toolName: toolData.name, traits: {
            showTab: traits.shouldShowSelectionTab,
            selectable: traits.isSelectable,
            dashedOutline: traits.isResizable,
        } },
        React.createElement(ToolView, Object.assign({}, props)))) : (React.createElement(ToolView, Object.assign({}, props)))));
};
