import { InteractionName } from "../../../shared/types/tool-data-types/interactions";
import { TriggerDispatchEvent, } from "../../../shared/legacy/legacy-tool-data";
export function getInteractionNearestTriggerListener(toolId, interaction) {
    var _a;
    const base = {
        listenerId: null,
        toolId,
        triggeredByAction: interaction.type,
    };
    switch (interaction.name) {
        case InteractionName.NEXT_STEP:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.NEXT_ZOOM });
        case InteractionName.NEXT_PAGE:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.NEXT_PAGE });
        case InteractionName.PREVIOUS_STEP:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.PREVIOUS_ZOOM });
        case InteractionName.PREVIOUS_PAGE:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.PREVIOUS_PAGE });
        case InteractionName.JUMP_TO_STEP:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.CHANGE_ZOOM });
        case InteractionName.JUMP_TO_PAGE:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.CHANGE_PAGE });
        case InteractionName.SHOW_TOOLTIP_BUBBLE_AND_SPOTLIGHT:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.DISPLAY_TOOL, displayToolId: toolId });
        case InteractionName.HIDE_TOOLTIP_BUBBLE_AND_SPOTLIGHT:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.HIDE_TOOL, displayToolId: toolId });
        case InteractionName.TOGGLE_TOOLTIP_BUBBLE_AND_SPOTLIGHT:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.TOGGLE_TOOL, displayToolId: toolId, toggles: true });
        case InteractionName.EXTERNAL_LINK:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.EXTERNAL_LINK, href: interaction.target.targetExternalLink });
        case InteractionName.SUBMIT_FORM:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.SUBMIT_FORM, formId: toolId });
        case InteractionName.CLOSE_FORM:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.HIDE_TOOL, displayToolId: toolId });
        case InteractionName.OPEN_FORM:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.DISPLAY_TOOL, displayToolId: toolId });
        case InteractionName.TRIGGER_FORM:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.TRIGGER_FORM, formId: (_a = interaction.target) === null || _a === void 0 ? void 0 : _a.targetFormId });
        case InteractionName.CLOSE_MODAL:
            return Object.assign(Object.assign({}, base), { dispatchEvent: TriggerDispatchEvent.HIDE_TOOL, displayToolId: toolId });
        default:
            throw new Error(`attempt to convert unimplemented interaction to its nearest trigger listener: ${interaction.name}`);
    }
}
