import React from "react";
import { DCButton } from "../components/dc-button";
import { useDCClickEvent } from "../hooks/dc-event-hooks";
export const DCTrackedButton = ({ config, disabled, onClick }) => {
    const clickEvent = useDCClickEvent();
    const clickHandler = () => {
        clickEvent(config);
        onClick === null || onClick === void 0 ? void 0 : onClick();
    };
    return React.createElement(DCButton, { config: config, disabled: disabled, onClick: clickHandler });
};
