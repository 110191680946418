import { ViewportMode } from "../types/definitions";
import { useHotkeys } from "react-hotkeys-hook";
import { useCopySelectedTool, useDeleteSelectedTool, useDeselect, useGotoNextStep, useGotoPreviousStep, usePasteToolFromCopyBuffer, useToggleViewportMode, useHandleArrowHotkeysInEditMode, } from "../redux/selectors/builder-selectors";
import { useRedo, useUndo, useViewportMode } from "../redux/selectors/redux-selectors";
import { Direction } from "../../../shared/types/tool-data-types/common";
export function useBuilderHotkeys() {
    const goToPreviousStep = useGotoPreviousStep(false);
    const goToNextStep = useGotoNextStep();
    const undo = useUndo();
    const redo = useRedo();
    const copySelectedTool = useCopySelectedTool();
    const pasteToolFromCopyBuffer = usePasteToolFromCopyBuffer();
    const deleteSelectedTool = useDeleteSelectedTool();
    const handleArrowHotkeysInEditMode = useHandleArrowHotkeysInEditMode();
    const viewportMode = useViewportMode();
    const deselect = useDeselect();
    const toggleViewportMode = useToggleViewportMode();
    const isEditMode = viewportMode === ViewportMode.EDIT;
    const isPreviewMode = viewportMode === ViewportMode.PREVIEW;
    useHotkeys("up", () => {
        handleArrowHotkeysInEditMode(Direction.UP);
    }, { enabled: isEditMode });
    useHotkeys("down", () => {
        handleArrowHotkeysInEditMode(Direction.DOWN);
    }, { enabled: isEditMode });
    useHotkeys("left", () => {
        handleArrowHotkeysInEditMode(Direction.LEFT);
    }, { enabled: isEditMode });
    useHotkeys("right", () => {
        handleArrowHotkeysInEditMode(Direction.RIGHT);
    }, { enabled: isEditMode });
    useHotkeys("left", () => {
        deselect();
        goToPreviousStep();
    }, { enabled: isPreviewMode });
    useHotkeys("right", () => {
        deselect();
        goToNextStep();
    }, { enabled: isPreviewMode });
    useHotkeys("ctrl+z", undo, { enabled: isEditMode });
    useHotkeys("command+z", undo, { enabled: isEditMode });
    useHotkeys("ctrl+shift+z", redo, { enabled: isEditMode });
    useHotkeys("command+shift+z", redo, { enabled: isEditMode });
    useHotkeys("ctrl+c", copySelectedTool, { enabled: isEditMode });
    useHotkeys("command+c", copySelectedTool, { enabled: isEditMode });
    useHotkeys("ctrl+v", pasteToolFromCopyBuffer, { enabled: isEditMode });
    useHotkeys("command+v", pasteToolFromCopyBuffer, { enabled: isEditMode });
    useHotkeys("del", deleteSelectedTool, { enabled: isEditMode });
    useHotkeys("backspace", deleteSelectedTool, { enabled: isEditMode });
    useHotkeys("esc", deselect, { enabled: isEditMode });
    useHotkeys("alt+p", toggleViewportMode, { enabled: true });
    useHotkeys("esc", toggleViewportMode, { enabled: isPreviewMode });
}
