import { cloneDeep } from "lodash";
import React from "react";
import { FormField } from "../subcomponents/styled-main-view";
import { StyledLabel } from "./styled-label";
import { StyledFormTextarea } from "./styled-form-textarea";
export function TextareaField(props) {
    const { setFormState, theme, field, fieldIndex, formState, labelText } = props;
    return (React.createElement(FormField, null,
        React.createElement(StyledLabel, { labelText: labelText },
            field.label,
            React.createElement(StyledFormTextarea, { theme: theme, name: field.role, onChange: e => {
                    if (setFormState) {
                        const nextState = cloneDeep(formState);
                        nextState[fieldIndex].value = e.target.value;
                        setFormState(nextState);
                    }
                } }))));
}
