import React from "react";
import TButton from "../../../styled/t-button";
import { rgbaToHex } from "../../../../../helpers/misc";
import TrashIcon from "../../../../../../assets/images/no-box-grey-trash-can.svg";
import ArrowUp from "../../../../../../assets/images/box-arrow-up.svg";
import ArrowDown from "../../../../../../assets/images/box-arrow-down.svg";
import CloseButtonIcon from "../../../../../../assets/images/close-button.svg";
import { fieldTypeToImageMap, inputStyle, subLabelStyle, tSelectStyle } from "./editor";
import TSelect from "../../../styled/t-select";
import TTextInput from "../../../styled/t-text-input";
import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { cloneDeep } from "lodash";
import { theme } from "../../../../../../../shared/legacy/legacy-tool-data";
export var FieldDataTypes;
(function (FieldDataTypes) {
    FieldDataTypes["PHONE"] = "Phone";
    FieldDataTypes["DROPDOWN"] = "Dropdown";
    FieldDataTypes["TEXT"] = "Text";
    FieldDataTypes["EMAIL"] = "Email";
})(FieldDataTypes || (FieldDataTypes = {}));
export default function FormFieldModal({ onClose, show, toolData, fieldIndex }) {
    const updateTool = useUpdateTool();
    const fieldData = toolData.fields[fieldIndex];
    const isEmailType = (fieldData === null || fieldData === void 0 ? void 0 : fieldData.type) === FieldDataTypes.EMAIL;
    if (!show) {
        return null;
    }
    return (React.createElement("div", { style: {
            position: "fixed",
            bottom: 0,
            right: 0,
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            zIndex: 5,
            backgroundColor: rgbaToHex(0, 0, 0, 0),
        }, onClick: onClose },
        React.createElement("div", { id: "modal-content", style: {
                width: 240,
                height: 364,
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginRight: 240,
                padding: 8,
            }, onClick: e => e.stopPropagation() },
            React.createElement("div", { id: "modal-header", style: {
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    paddingBottom: 8,
                } },
                React.createElement(TButton, { style: {
                        width: 80,
                        height: 23,
                        marginRight: 8,
                    }, onClick: () => {
                        const newTool = cloneDeep(toolData);
                        if (newTool.fields[fieldIndex].role !== "Email") {
                            newTool.fields = newTool.fields.filter((f, i) => i !== fieldIndex);
                            updateTool(newTool);
                        }
                        onClose();
                    }, disabled: isEmailType },
                    React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { margin: "-12.5px -20px 0px -20px", color: theme.colors.white }) }, "Remove Field")),
                React.createElement("img", { style: {
                        position: "relative",
                        left: 0,
                        top: 0,
                        width: 16.5,
                        cursor: "pointer",
                    }, src: CloseButtonIcon, onClick: onClose })),
            React.createElement("div", { id: "modal-body", className: "hide-scrollbar", style: {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flexGrow: 1,
                    overflowY: "scroll",
                } },
                React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "100%", margin: 0 }) },
                    "Input Label",
                    React.createElement(TTextInput, { value: fieldData.role, onChange: e => {
                            const newTool = cloneDeep(toolData);
                            newTool.fields[fieldIndex].role = e.target.value;
                            updateTool(newTool);
                        }, style: Object.assign(Object.assign({}, inputStyle), { paddingLeft: 4 }) })),
                React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "100%", margin: "0px 0px 4px" }) },
                    "Input Type",
                    React.createElement(TSelect, { disabled: isEmailType, style: Object.assign({ width: "100%", margin: 0 }, tSelectStyle), value: fieldData.type, image: fieldTypeToImageMap[fieldData.type], onChange: e => {
                            const newTool = cloneDeep(toolData);
                            newTool.fields[fieldIndex].type = e.target.value;
                            if (e.target.value === FieldDataTypes.DROPDOWN) {
                                newTool.fields[fieldIndex].options = [];
                            }
                            updateTool(newTool);
                        } },
                        !fieldData.type && React.createElement("option", { value: "" }, "- "),
                        isEmailType && React.createElement("option", { value: FieldDataTypes.EMAIL }, FieldDataTypes.EMAIL),
                        Object.values(FieldDataTypes)
                            .filter(t => t !== FieldDataTypes.EMAIL)
                            .map((t, i) => (React.createElement("option", { key: i, value: t }, t))))),
                fieldData.type === FieldDataTypes.DROPDOWN && (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "100%", marginBottom: 0 }) }, "Options"),
                    fieldData.options.map((f, i) => {
                        return (React.createElement("div", { key: i, style: {
                                display: "flex",
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: 4,
                            } },
                            React.createElement("div", { style: { display: "flex" } },
                                React.createElement("img", { src: ArrowUp, width: 24, style: { cursor: "pointer" }, onClick: () => {
                                        const newTool = cloneDeep(toolData);
                                        const newToolOptions = newTool.fields[fieldIndex].options;
                                        if (i >= 1) {
                                            const tmp = newToolOptions[i];
                                            newToolOptions[i] = newToolOptions[i - 1];
                                            newToolOptions[i - 1] = tmp;
                                            updateTool(newTool);
                                        }
                                    } }),
                                React.createElement("img", { src: ArrowDown, width: 24, style: { cursor: "pointer" }, onClick: () => {
                                        const newTool = cloneDeep(toolData);
                                        const newToolOptions = newTool.fields[fieldIndex].options;
                                        if (i < newToolOptions.length - 1) {
                                            const tmp = newToolOptions[i];
                                            newToolOptions[i] = newToolOptions[i + 1];
                                            newToolOptions[i + 1] = tmp;
                                            updateTool(newTool);
                                        }
                                    } })),
                            React.createElement(TTextInput, { style: Object.assign(Object.assign({}, subLabelStyle), { flexGrow: 1, margin: "0px 4px", paddingLeft: 4 }), value: fieldData.options[i], onChange: e => {
                                    const newTool = cloneDeep(toolData);
                                    newTool.fields[fieldIndex].options[i] = e.target.value;
                                    updateTool(newTool);
                                } }),
                            React.createElement("img", { src: TrashIcon, width: 16, style: { cursor: "pointer" }, onClick: () => {
                                    const newTool = cloneDeep(toolData);
                                    newTool.fields[fieldIndex].options = newTool.fields[fieldIndex].options.filter((_o, j) => j !== i);
                                    updateTool(newTool);
                                } })));
                    }),
                    React.createElement(TButton, { style: {
                            width: 110,
                            height: 23,
                            marginRight: 8,
                        }, onClick: () => {
                            const newTool = cloneDeep(toolData);
                            newTool.fields[fieldIndex].options.push("");
                            updateTool(newTool);
                        } },
                        React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { margin: "-12.5px -15px 0px -15px", color: theme.colors.white }) }, "Add New Option"))))))));
}
