import React, { createContext } from "react";
import styled from "styled-components";
import { TLoadingSpinner } from "../../../styled/t-loading-spinner";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { useFetchDemoCenterByIdForContext } from "../hooks/dc-hooks";
import { DCBuilderHeader } from "./dc-builder-header";
import { DCContentManager } from "./dc-content-manager";
import { DCStylesEditor } from "./dc-styles-editor";
import { DCOverview } from "./dc-overview";
import { MainAppPagePaths } from "../../../../../../shared/types/app-events";
import { useDCBuilderResetOnExit, useDCBuilderWarningMonitor, useDCCMHomePath, useDCRedirectOnEditModeBlocked, } from "../hooks/dc-builder-hooks";
import { DCLivePreview } from "./dc-live-preview";
import { DCWizard } from "./dc-wizard";
import { useDCBuilderMode } from "../../../../redux/selectors/demo-center-selectors";
import { DCBuilderMode } from "../../../../../../shared/types/demo-center";
export const DemoCenterDraftContext = createContext(null);
const DCBuilder = () => {
    const id = useParams().id;
    const { demoCenter, error, fsDemoCenter } = useFetchDemoCenterByIdForContext(id);
    const mode = useDCBuilderMode();
    const showHeader = mode !== DCBuilderMode.WIZARD;
    useDCBuilderResetOnExit();
    if (!id || error)
        return React.createElement(Redirect, { to: "/" });
    if (!fsDemoCenter)
        return React.createElement(TLoadingSpinner, null);
    return demoCenter ? (React.createElement(DemoCenterDraftContext.Provider, { value: fsDemoCenter },
        React.createElement(BuilderContainer, null,
            showHeader && React.createElement(DCBuilderHeader, null),
            React.createElement(DCBuilderBody, { className: showHeader ? "show-header" : "" },
                React.createElement(DCBuilderRouter, null))))) : (React.createElement(TLoadingSpinner, null));
};
const DCBuilderRouter = () => {
    useDCBuilderWarningMonitor();
    useDCRedirectOnEditModeBlocked();
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id`, component: DCCMHome }),
        React.createElement(Route, { path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/wizard`, component: DCWizard }),
        React.createElement(Route, { path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/manage`, component: DCContentManager }),
        React.createElement(Route, { path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/module`, component: DCContentManager }),
        React.createElement(Route, { path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/preview`, component: DCLivePreview }),
        React.createElement(Route, { path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/styles`, component: DCStylesEditor }),
        React.createElement(Route, { path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/styles/:view`, component: DCStylesEditor, exact: false }),
        React.createElement(Route, { path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/overview`, component: DCOverview })));
};
export const DCCMHome = () => {
    const id = useParams().id;
    const homePath = useDCCMHomePath();
    return React.createElement(Redirect, { to: `${MainAppPagePaths.DEMOCENTER_BUILDER}/${id}/${homePath}` });
};
export default DCBuilder;
const BuilderContainer = styled.div `
  display: flex;
`;
const DCBuilderBody = styled.div `
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  &.show-header {
    margin-top: 56px;
    height: calc(100vh - 56px);
  }
`;
