import React from "react";
import { cloneDeep } from "lodash";
import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { withFlexibleToolWrapper } from "../../../../renderer/wrapper/with-flexible-tool-wrapper";
import { WrapperChild } from "../../../../renderer/wrapper/wrapper-child";
import { getScaledBorderRadiusPx, positionEq, sizeEq, } from "../../../../../helpers/tooltip-v2-size-and-position-helpers";
import styled from "styled-components";
import { hexToRGB } from "../../../../../helpers/misc";
import { InteractionName, InteractionType } from "../../../../../../../shared/types/tool-data-types/interactions";
import { useInteraction } from "../../../../../redux/selectors/interaction-selectors";
import { TourialEvent } from "../../../../../../../shared/types/events";
import { clickzoneAnimationBuilder } from "../../../../../helpers/dom-helpers";
import { TooltipTriggerComponent } from "../../../../../../../shared/types/tool-data-types/tooltip-v2-data";
export function Clickzone(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    function drag(position) {
        position = {
            x: position.x + tool.trigger.clickzone.size.width / 2,
            y: position.y + tool.trigger.clickzone.size.height / 2,
        };
        if (!positionEq(tool.trigger.position, position)) {
            const t = cloneDeep(tool);
            t.trigger.position = position;
            updateTool(t);
        }
    }
    function resize(size, position) {
        position = {
            x: position.x + tool.trigger.clickzone.size.width / 2,
            y: position.y + tool.trigger.clickzone.size.height / 2,
        };
        if (!positionEq(tool.trigger.position, position) || !sizeEq(tool.trigger.clickzone.size, size)) {
            const t = cloneDeep(tool);
            t.trigger.position = position;
            t.trigger.clickzone.size = size;
            updateTool(t);
        }
    }
    // make trigger.position.x & y center of trigger
    const position = {
        x: tool.trigger.position.x - tool.trigger.clickzone.size.width / 2,
        y: tool.trigger.position.y - tool.trigger.clickzone.size.height / 2,
    };
    return withFlexibleToolWrapper(Inner, {
        isEditing: props.isInEditViewport,
        isSelectable: true,
        isResizable: true,
        isDraggable: true,
        isAspectRatioLocked: false,
        isStaticSize: false,
        shouldShowSelectionTab: false,
    }, {
        bounds: ".tourial-viewport",
        minSize: { width: 16, height: 16 },
        size: tool.trigger.clickzone.size,
        position,
    }, {
        onDrag: drag,
        onDragStop: drag,
        onResize: resize,
        onResizeStop: resize,
    })(props);
}
function Inner(props) {
    const { toolData: tool, disableAnimations, viewportDimensions } = props;
    const interaction = useInteraction(props, tool.trigger.interaction);
    const isSpotlightEnabled = tool.trigger.triggerComponent === TooltipTriggerComponent.CLICKZONE && tool.trigger.clickzone.isSpotlightEnabled;
    return (React.createElement(Div, { id: `${tool.id}-clickzone`, onMouseOver: () => {
            if (tool.trigger.interaction.type === InteractionType.HOVER) {
                interaction(TourialEvent.HOVER_IN);
            }
        }, onMouseOut: () => {
            if (tool.trigger.interaction.type === InteractionType.HOVER &&
                tool.trigger.interaction.name !== InteractionName.SHOW_TOOLTIP_BUBBLE_AND_SPOTLIGHT) {
                interaction(TourialEvent.HOVER_OUT);
            }
        }, onClick: () => {
            if (tool.trigger.interaction.type === InteractionType.CLICK) {
                interaction();
            }
        }, style: {
            boxShadow: isSpotlightEnabled
                ? `0px 0px 0px 10000px rgba(0, 0, 0, ${tool.trigger.clickzone.spotlightOpacity})`
                : "none",
            borderStyle: tool.trigger.clickzone.isBorderEnabled ? "solid" : props.isInEditViewport ? "dashed" : "none",
            borderColor: tool.trigger.clickzone.color,
            borderRadius: getScaledBorderRadiusPx(tool.trigger.clickzone.shape, viewportDimensions.width),
        } },
        React.createElement(InnerDiv, { disableAnimations: shouldDisableAnimations(disableAnimations, tool.trigger.clickzone.isPulseEnabled), pulseColor: hexToRGB(tool.trigger.clickzone.color) || { r: 0, g: 0, b: 0 }, style: {
                borderRadius: getScaledBorderRadiusPx(tool.trigger.clickzone.shape, viewportDimensions.width),
            } })));
}
const shouldDisableAnimations = (disableAnimations, isPulseEnabled) => {
    if (disableAnimations) {
        return true;
    }
    else if (isPulseEnabled || isPulseEnabled === undefined) {
        return false;
    }
    else {
        return true;
    }
};
const Div = styled(WrapperChild) `
  cursor: pointer;
  border-width: 2px;
`;
const InnerDiv = styled.div `
  cursor: inherit;
  animation: ${props => (props.disableAnimations ? "null" : clickzoneAnimationBuilder(props.pulseColor))};
  width: 100%;
  height 100%;
  pointer-events: none;
`;
