import React, { useState } from "react";
import styled from "styled-components";
import Slider from "rc-slider";
import TransparentBackground from "../../../../../../assets/images/transparent-checker-background.png";
import { newTheme } from "../../../../styled/theme";
export const OpacitySlider = (props) => {
    const { onChange, value, min, max } = props;
    const [opacity, setOpacity] = useState(value);
    const handleChange = (e) => {
        const normalized = e / 100;
        setOpacity(normalized);
        onChange(normalized);
    };
    return (React.createElement("div", { style: { display: "flex" } },
        React.createElement(OpacitySliderContainer, null,
            React.createElement(Slider, { min: min !== null && min !== void 0 ? min : 0, max: max || 100, onChange: handleChange, value: value * 100, railStyle: {
                    position: "absolute",
                    height: "6px",
                    borderRadius: "4px",
                    border: `1px solid ${newTheme.colors.grey600}`,
                    backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, ${opacity})), url(${TransparentBackground})`,
                    backgroundPosition: "center",
                    width: "100%",
                }, trackStyle: {
                    position: "absolute",
                    height: "6px",
                    borderRadius: "4px",
                }, handleStyle: {
                    cursor: "grab",
                    height: "14px",
                    width: "14px",
                    marginTop: "-4px",
                    borderRadius: "7px",
                    border: `1px solid ${newTheme.colors.grey300}`,
                    backgroundColor: newTheme.colors.grey500,
                    position: "absolute",
                    opacity: 1,
                    boxShadow: "none",
                } })),
        React.createElement(PercentContainer, null,
            Math.floor(opacity * 100),
            "%")));
};
const PercentContainer = styled.div `
  padding-top: 6px;
  width: 35px;
`;
const OpacitySliderContainer = styled.div `
  position: relative;
  margin: 16px;
  width: 143px;
`;
