import React, { useState } from "react";
import { StoryboardMiniStub } from "./storyboard-mini-stub";
import AddScreenModal, { AddScreenModalVariants } from "./add-screen-modal";
import { StoryboardMiniContents } from "./storyboard-mini-contents";
import { useActivePageId } from "../../../../redux/selectors/redux-selectors";
export function StoryboardMini({ sidebarWidth, storyboardHeight, editPanelWidth, setStoryboardHeight, }) {
    const [isAddScreenModalOpen, setIsAddScreenModalOpen] = useState(false);
    const [isSwapScreenModalOpen, setIsSwapScreenModalOpen] = useState(false);
    const pageId = useActivePageId();
    return (React.createElement(React.Fragment, null,
        React.createElement(StoryboardMiniStub, { editPanelWidth: editPanelWidth, setIsAddScreenModalOpen: setIsAddScreenModalOpen, sidebarWidth: sidebarWidth, storyboardHeight: storyboardHeight, setStoryboardHeight: setStoryboardHeight }),
        React.createElement(StoryboardMiniContents, { editPanelWidth: editPanelWidth, sidebarWidth: sidebarWidth, storyboardHeight: storyboardHeight, setIsAddScreenModalOpen: setIsAddScreenModalOpen, setIsSwapScreenModalOpen: setIsSwapScreenModalOpen }),
        isAddScreenModalOpen && (React.createElement(AddScreenModal, { onClose: () => setIsAddScreenModalOpen(false), variant: AddScreenModalVariants.ADD_SCREEN })),
        isSwapScreenModalOpen && (React.createElement(AddScreenModal, { onClose: () => setIsSwapScreenModalOpen(false), variant: AddScreenModalVariants.SWAP_SCREEN, pageId: pageId }))));
}
