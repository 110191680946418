import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { BorderShape } from "../../../../../../../shared/types/tool-data-types/common";
export const ShapeSelector = (props) => {
    const { onChange, shape, square } = props;
    const [selectedShape, setSelectedShape] = useState(BorderShape.RECTANGLE);
    useEffect(() => {
        setSelectedShape(shape);
    }, [shape]);
    const handleClick = (shape) => {
        setSelectedShape(shape);
        onChange(shape);
    };
    return (React.createElement(ShapeContainer, null,
        React.createElement(StyledShape, { isSquare: square, selected: selectedShape === BorderShape.RECTANGLE, style: {
                marginRight: "16px",
            }, onClick: () => {
                handleClick(BorderShape.RECTANGLE);
            } }),
        React.createElement(StyledShape, { isSquare: square, selected: selectedShape === BorderShape.ROUNDED, style: {
                marginRight: "16px",
                borderRadius: "4px",
            }, onClick: () => {
                handleClick(BorderShape.ROUNDED);
            } }),
        React.createElement(StyledShape, { isSquare: square, selected: selectedShape === BorderShape.SQUIRCLE, style: {
                borderRadius: "12px",
            }, onClick: () => {
                handleClick(BorderShape.SQUIRCLE);
            } })));
};
const ShapeContainer = styled.div `
  display: flex;
`;
const StyledShape = styled.div `
  height: 24px;
  width: ${p => (p.isSquare ? "24px" : "48px")};
  background-color: ${props => (props.selected ? newTheme.colors.blue100 : newTheme.colors.white)};
  border: ${props => (props.selected ? "2px" : "1px")} solid
    ${props => (props.selected ? newTheme.colors.blue : newTheme.colors.grey500)};
  box-shadow: 0px 0px 4px ${props => (props.selected ? newTheme.colors.blue300 : newTheme.colors.grey100)};
  cursor: pointer;
`;
