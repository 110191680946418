const getBaseUrlClient = () => {
    let url = "http://localhost:5050";
    if (process.env.DAR_ENV === "staging") {
        url = "https://staging.tourial.com";
    }
    else if (process.env.DAR_ENV === "production") {
        url = "https://app.tourial.com";
    }
    return url;
};
export const BASE_URL_CLIENT = getBaseUrlClient();
