import { getBaseMedia } from "../../../../helpers/video-trimming";
export function getDataForThumbnail(screen, pageId, media) {
    var _a, _b;
    const screenCopy = JSON.parse(JSON.stringify(screen || {}));
    delete screenCopy.name;
    delete screenCopy.id;
    delete screenCopy.type;
    delete screenCopy.isLocked;
    delete screenCopy.shouldFadeIn;
    delete screenCopy.perPageVideoControls;
    const screenSrc = (_a = screenCopy === null || screenCopy === void 0 ? void 0 : screenCopy.media) === null || _a === void 0 ? void 0 : _a[pageId];
    delete screenCopy.media;
    const mediaSize = ((_b = getBaseMedia(media, screenSrc)) === null || _b === void 0 ? void 0 : _b.size) || { width: 0, height: 0 };
    const screenData = {
        background: {},
        screen: Object.assign({ src: screenSrc }, screenCopy),
        mediaSize,
    };
    return { firstPageData: screenData };
}
export function getExpandedCardTitleWidth(zoomLength) {
    const cardPadding = 16, iconWidth = 90, addStep = 40, thumbnail = 176.15, actionIcons = 77, screenTypeIcon = 24;
    const cardWidth = thumbnail + cardPadding * 2 + addStep + (zoomLength - 1) * iconWidth;
    return cardWidth - actionIcons - 4 - screenTypeIcon || 139;
}
export function checkAndScrollStepIntoViewport(elementId) {
    const elementToFind = document.getElementById(elementId);
    if (!elementToFind)
        return;
    const container = document.getElementById("storyboard-container");
    const elementToFindRect = elementToFind.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    const rightSideIn = elementToFindRect.right - 50 < containerRect.right;
    const leftSideIn = elementToFindRect.left > containerRect.left;
    const isFullyInView = rightSideIn && leftSideIn;
    if (!isFullyInView) {
        elementToFind.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    }
}
