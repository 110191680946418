import React, { useState } from "react";
import styled from "styled-components";
import { isImageFileExtension } from "../../../../helpers/media";
import TButton from "../../../styled/t-button";
import TModal, { TModalButtonsContainer } from "../../../styled/t-modal";
import { newTheme } from "../../../styled/theme";
import { getVideoThumbnail } from "../../../../../../shared/functions/helpers";
import { usePages, useScreenMedia, useTourialName, useUpdatePages, useUpdateTourialPartial, } from "../../../../redux/selectors/redux-selectors";
import TInput from "../../../styled/t-input";
import TrashIcon from "../../../../../assets/images/v3/trash-grey-icon.svg";
import ImageIcon from "../../../../../assets/images/v3/capture-screen-grey-icon.svg";
import VideoIcon from "../../../../../assets/images/v3/video-capture-grey-icon.svg";
import { cloneDeep } from "lodash";
export default function WorkflowCaptureRenameModal({ closeModal }) {
    const pages = usePages();
    const [updatedPages, setUpdatedPages] = useState(cloneDeep(pages));
    const media = useScreenMedia();
    const updatePages = useUpdatePages();
    const tourialName = useTourialName();
    const [newName, setNewName] = useState(tourialName);
    const updateTourialPartial = useUpdateTourialPartial();
    const handleSubmit = () => {
        updateTourialPartial({ name: newName });
        updatePages(updatedPages);
        closeModal();
    };
    const handlePageDeletion = (index) => {
        const tempPages = cloneDeep(updatedPages);
        tempPages.splice(index, 1);
        setUpdatedPages(tempPages);
    };
    const disableDeletion = updatedPages.length <= 1;
    return (React.createElement(TModal, { title: "Review workflow pages", onClose: closeModal },
        React.createElement(React.Fragment, null,
            React.createElement(TitleInput, { value: newName, onChange: e => {
                    setNewName(e.target.value);
                }, type: "text", name: "name-page", required: true }),
            React.createElement(MediaCardsContainer, null, updatedPages.map((page, i) => {
                const pageMedia = media[page.id];
                const isImage = isImageFileExtension(pageMedia);
                return (React.createElement(MediaCardContainer, { key: page.id },
                    React.createElement(Thumbnail, null, isImage ? React.createElement(Img, { src: pageMedia }) : React.createElement(VideoOverlay, { src: pageMedia })),
                    React.createElement(MediaTypeIcon, { src: isImage ? ImageIcon : VideoIcon }),
                    React.createElement(PageNameInput, { value: page.name, onChange: e => {
                            const tempPages = cloneDeep(updatedPages);
                            tempPages[i] = Object.assign(Object.assign({}, tempPages[i]), { name: e.target.value });
                            setUpdatedPages(tempPages);
                        }, type: "text", name: "name-page", required: true }),
                    React.createElement(DeleteIcon, { src: TrashIcon, onClick: () => {
                            if (disableDeletion)
                                return;
                            handlePageDeletion(i);
                        }, disabled: disableDeletion })));
            })),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { onClick: handleSubmit }, "Complete")))));
}
export function VideoOverlay(props) {
    return React.createElement(VideoOverlayContainer, { style: { backgroundImage: `url(${getVideoThumbnail(props.src)})` } });
}
const DeleteIcon = styled.img `
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  width: 18px;
`;
const MediaTypeIcon = styled.img `
  position: absolute;
  z-index: 2;
  top: 8px;
  left: 70px;
`;
const TitleInput = styled(TInput) `
  height: 32px;
  width: calc(100% - 48px);
  background: ${newTheme.colors.white};
  margin: 24px 24px 0px;
`;
const PageNameInput = styled(TInput) `
  padding-left: 32px;
  flex: 1;
  margin-right: 8px;
  background: ${newTheme.colors.white};
`;
const Img = styled.img `
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;
const VideoOverlayContainer = styled.div `
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MediaCardsContainer = styled.div `
  margin: 24px 24px 0px;
  height: 258px;
  width: 552px;
  background-color: ${newTheme.colors.grey100};
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  overflow-y: scroll;
`;
const MediaCardContainer = styled.div `
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
`;
const Thumbnail = styled.div `
  width: 53.33px;
  height: 30px;
  border: 1px solid ${newTheme.colors.grey300};
  box-shadow: inset 0px 0px 1px rgba(33, 34, 41, 0.4);
  border-radius: 4px;
  margin-right: 5.24px;
  overflow: hidden;
  box-sizing: content-box;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font: ${newTheme.fonts.extraSmall};
  color: ${newTheme.colors.grey800};
`;
