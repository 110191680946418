import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { EditorAccordion } from "../../../../pages/builder/editor/editor-accordion";
import { cloneDeep } from "lodash";
import React from "react";
import styled from "styled-components";
import BodyIcon from "../../../../../../assets/images/v3/editor-accordian-body-icon.svg";
import HeadlineIcon from "../../../../../../assets/images/v3/editor-accordian-headline-icon.svg";
import LogoIcon from "../../../../../../assets/images/v3/editor-accordian-logo-icon.svg";
import ButtonIcon from "../../../../../../assets/images/v3/editor-accordian-button-icon.svg";
import SpotlightIcon from "../../../../../../assets/images/v3/editor-accordian-spotlight-icon.svg";
import { ModalType } from "../../../../../../../shared/types/tool-data-types/modal-data";
export function ModalConfigureSection(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    const minButtonEnabled = [
        tool.buttons.button1.isEnabled,
        tool.buttons.button2.isEnabled,
        tool.buttons.button3.isEnabled,
        tool.buttons.button4.isEnabled,
    ].reduce((acc, enabled) => acc + (enabled ? 1 : 0), 0) === 1;
    return (React.createElement(Section, { id: "_test_editor-advanced-section" },
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: LogoIcon, title: "Logo", isEnabled: tool.logo.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.logo.isEnabled = !t.logo.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: HeadlineIcon, title: "Headline", isEnabled: tool.headline.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.headline.isEnabled = !t.headline.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: BodyIcon, title: "Body", isEnabled: tool.body.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.body.isEnabled = !t.body.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { icon: ButtonIcon, title: `${tool.modalType === ModalType.PERSONAS ? "Persona" : "Button"} 1`, isAlwaysOpen: true, isEnabled: tool.buttons.button1.isEnabled, isDisabled: minButtonEnabled && tool.buttons.button1.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.buttons.button1.isEnabled = !t.buttons.button1.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { icon: ButtonIcon, title: `${tool.modalType === ModalType.PERSONAS ? "Persona" : "Button"} 2`, isAlwaysOpen: true, isEnabled: tool.buttons.button2.isEnabled, isDisabled: minButtonEnabled && tool.buttons.button2.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.buttons.button2.isEnabled = !t.buttons.button2.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { icon: ButtonIcon, title: `${tool.modalType === ModalType.PERSONAS ? "Persona" : "Button"} 3`, isAlwaysOpen: true, isEnabled: tool.buttons.button3.isEnabled, isDisabled: minButtonEnabled && tool.buttons.button3.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.buttons.button3.isEnabled = !t.buttons.button3.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { icon: ButtonIcon, title: `${tool.modalType === ModalType.PERSONAS ? "Persona" : "Button"} 4`, isAlwaysOpen: true, isEnabled: tool.buttons.button4.isEnabled, isDisabled: minButtonEnabled && tool.buttons.button4.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.buttons.button4.isEnabled = !t.buttons.button4.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: SpotlightIcon, title: "Overlay", isEnabled: tool.overlay.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.overlay.isEnabled = !t.overlay.isEnabled;
                updateTool(t);
            } })));
}
const Section = styled.div `
  height: calc(100% - 31px);
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
