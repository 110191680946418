import { useEffect } from "react";
// See https://medium.com/@kevinfelisilda/click-outside-element-event-using-react-hooks-2c540814b661
// Takes multiple refs objects to ignore
export function useOutsideClick(refs, callback) {
    const handleClick = (e) => {
        if (refs.every(ref => ref.current && !ref.current.contains(e.target))) {
            callback();
        }
    };
    // See https://stackoverflow.com/questions/72315874/react-click-outside-event-happens-right-after-click-to-open-preventing-the-mod
    // React 18 event propagation changes require setTimeout to avoid unnecessary initial call
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            document.addEventListener("click", handleClick);
        }, 0);
        return () => {
            clearTimeout(timeoutId);
            document.removeEventListener("click", handleClick);
        };
    });
}
