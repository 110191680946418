var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { datadogRum } from "@datadog/browser-rum";
import { useDispatch, useSelector } from "react-redux";
import { SET_LOADING_BOOL, SET_SESSION_ID, SET_TOURIAL_AUTH_DATA, ADD_TOAST, REMOVE_TOAST, SET_LATEST_TOURIAL_VIEW_EVENT, SET_WORKSPACE_ID, SET_SHOW_CHROME_EXTENSION_MODAL, } from "./actions/legacy-action-types";
import { ToolType } from "../../../shared/types/tool-data-types/common";
import { ActionType } from "../redux/actions/redux-actions";
import { useDefaultFontFamily } from "../redux/selectors/redux-selectors";
import { getViewToolsAndToolsInActiveView } from "../helpers/redux";
import { isEqual } from "lodash";
import { sprig } from "@sprig-technologies/sprig-browser";
import { PersonType } from "../../../shared/types/app-events";
const getSprigEnvironmentId = () => {
    const isProd = window.location.host === "app.tourial.com";
    const sprigProdId = "XC2Q4Re9lJwU";
    const sprigDevId = "zo77qBukUQdt";
    return isProd ? sprigProdId : sprigDevId;
};
export const Sprig = sprig.configure({
    environmentId: getSprigEnvironmentId(),
});
export const useFetchAndSetTourialAuthData = () => {
    const dispatch = useDispatch();
    return (ignoreAnalytics = false) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const userResponse = yield axios.get(`/users/tourialAuthData`);
            const { user, account, accountId } = userResponse.data;
            const { username, userAccess, viewingAccount, email, _id } = user;
            const viewingAccountName = viewingAccount.account;
            const loggedInAccount = user.account;
            const { isActive, subscription, featureFlags, brandColors } = account;
            // Add userId and accountId to datadog payloads
            datadogRum.setUser({
                email,
                accountId,
            });
            // handles setting user info in various analytics platforms
            if (!ignoreAnalytics) {
                if (window.FS) {
                    // @ts-expect-error
                    window.FS.identify(_id, {
                        displayName: username || email,
                        email,
                        personType: email.includes("@tourial.com") ? PersonType.TOURIAL_EMPLOYEE : PersonType.TOURIAL_CUSTOMER,
                        userAccess,
                        loggedInAccount,
                    });
                    // @ts-expect-error
                    window.FS.setUserVars({
                        freeTrial: !!(featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.freeTrial),
                    });
                }
                // SEGMENT Client Identify User
                const impersonatingCustomer = !!((_a = user.viewingAccount) === null || _a === void 0 ? void 0 : _a.accountId);
                // @ts-expect-error
                if (window.analytics && !impersonatingCustomer) {
                    // @ts-expect-error
                    analytics.identify(_id || user.id, {
                        displayName: username || email,
                        email,
                        company: {
                            name: account.companyName,
                            id: accountId,
                        },
                        personType: email.includes("@tourial.com") ? PersonType.TOURIAL_EMPLOYEE : PersonType.TOURIAL_CUSTOMER,
                        userAccess,
                        isActive,
                        subscription,
                        featureFlags,
                    });
                    // @ts-expect-error
                    analytics.group(accountId, {
                        name: account.companyName,
                        subdomain: account.subdomain,
                        isActive: account.isActive,
                        whiteLabelDomain: account.whiteLabelDomain,
                        featureFlags: account.featureFlags || {},
                    });
                }
                const sprigAttributes = {
                    accountId,
                    accountCreatedAt: account.createdAt,
                    companyName: account.companyName,
                    userCreatedAt: user.createdAt,
                    userAccess,
                    userName: username,
                };
                Sprig.setUserId(user._id);
                Sprig.setEmail(user.email);
                // @ts-expect-error
                Sprig.setAttributes(sprigAttributes);
            }
            dispatch({
                type: SET_TOURIAL_AUTH_DATA,
                username,
                email,
                userId: user._id,
                userAccess,
                accountId,
                loggedInAccount: userAccess === "superAdmin" || userAccess === "engineer" ? viewingAccountName : account.companyName,
                viewingAccount,
                account,
                token: "TODO_DEPRICATED_BUT_USED_AS_AUTH_TOGGLE",
                isActive,
                isLoading: false,
                subscription,
                featureFlags,
                brandColors,
                showChromeExtensionModal: user.showChromeExtensionModal,
            });
        }
        catch (_b) {
            dispatch({
                type: SET_LOADING_BOOL,
                isLoading: false,
            });
        }
    });
};
export const useAccount = () => useSelector(state => state.loginReducer.account);
export const useIsLoading = () => useSelector(state => state.loginReducer.isLoading);
export const useIsActive = () => useSelector(state => state.loginReducer.isActive);
export const useToasts = () => useSelector(state => state.loginReducer.toasts);
export const useUserData = () => useSelector(state => ({
    userName: state.loginReducer.username,
    userId: state.loginReducer.userId,
    email: state.loginReducer.email,
    userAccess: state.loginReducer.userAccess,
    loggedInAccount: state.loginReducer.loggedInAccount,
    accountId: state.loginReducer.accountId,
    isActive: state.loginReducer.isActive,
    showChromeExtensionModal: state.loginReducer.showChromeExtensionModal,
}));
export const useSetShowChromeExtensionModal = () => {
    const dispatch = useDispatch();
    return (show) => dispatch({
        type: SET_SHOW_CHROME_EXTENSION_MODAL,
        show,
    });
};
export const useSetIsLoadingBool = () => {
    const dispatch = useDispatch();
    return (bool) => dispatch({
        type: SET_LOADING_BOOL,
        isLoading: bool,
    });
};
export const useAddToast = () => {
    const dispatch = useDispatch();
    return (toast) => dispatch({
        type: ADD_TOAST,
        toast,
    });
};
export const useRemoveToast = () => {
    const dispatch = useDispatch();
    return (id) => dispatch({
        type: REMOVE_TOAST,
        id,
    });
};
export const useSetSessionId = () => {
    const dispatch = useDispatch();
    return (sessionId) => dispatch({
        type: SET_SESSION_ID,
        sessionId,
    });
};
export const useSetWorkspaceId = () => {
    const dispatch = useDispatch();
    return (workspaceId) => dispatch({
        type: SET_WORKSPACE_ID,
        workspaceId,
    });
};
export const useWorkspaceId = () => useSelector(state => state.loginReducer.workspaceId);
export const useAddTool = () => {
    const dispatch = useDispatch();
    const defaultFontFamily = useDefaultFontFamily();
    return (tool) => {
        // Apply default font family
        if (tool.type === ToolType.Button || tool.type === ToolType.Textbox) {
            tool.textData.fontFamily = defaultFontFamily;
        }
        dispatch({ type: ActionType.ADD_TOOL, tool });
    };
};
export const useEditModeVisibleToolIds = () => useSelector(state => { var _a; return (_a = getViewToolsAndToolsInActiveView(state.tourialRootReducer)) === null || _a === void 0 ? void 0 : _a.map(vt => { var _a; return (_a = vt === null || vt === void 0 ? void 0 : vt.tool) === null || _a === void 0 ? void 0 : _a.id; }); }, (a, b) => isEqual(a, b));
export const useLatestTourialViewEvent = () => useSelector(state => state.loginReducer.latestTourialViewEvent);
export const useSetLatestTourialViewEvent = () => {
    const dispatch = useDispatch();
    return (latestTourialViewEvent) => dispatch({
        type: SET_LATEST_TOURIAL_VIEW_EVENT,
        latestTourialViewEvent,
    });
};
