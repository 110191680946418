import React, { useEffect, useState } from "react";
import { DragSelect } from "../builder/drag-select";
import { calculateContainedToolIdsAndBorders } from "../../../helpers/misc";
import { ViewportMode } from "../../../types/definitions";
import { useActivePageId, useActiveZoomId, useAddMultiselectToolId, useMultiselectToolIds, useRemoveMultiselectToolId, useScreen, useUpdateBuilder, useViewportMode, useViewToolsAndToolsInActiveView, } from "../../../redux/selectors/redux-selectors";
import { ToolType } from "../../../../../shared/types/tool-data-types/common";
const defaultDragSelectBoxData = {
    mouseDown: false,
    startPoint: null,
    currentPoint: null,
    finalContainerBorders: null,
};
export const isScreenComponent = (e) => e.className.includes("rnd-tool-screen");
export function TourialBackground(props) {
    const activePageId = useActivePageId();
    const activeZoomId = useActiveZoomId();
    const updateBuilder = useUpdateBuilder();
    const viewportMode = useViewportMode();
    const viewToolsAndTools = useViewToolsAndToolsInActiveView();
    const addMultiselectToolId = useAddMultiselectToolId();
    const multiselectToolIds = useMultiselectToolIds();
    const [prevMultiselectCount, setPrevMultiselectCount] = useState(-1); // used to track previous count for shift+cmd add/remove
    const removeMultiselectToolId = useRemoveMultiselectToolId();
    const [insideSelectBox, setInsideSelectBox] = useState(false);
    const [dragSelectBoxData, setDragSelectBoxData] = useState(defaultDragSelectBoxData);
    const screenData = useScreen();
    const { colorPrimary, colorSecondary, src } = props.toolData.backgrounds[props.pageId || activePageId] || {
        colorPrimary: "white",
    };
    const imageBackgroundStyle = {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: `url("${src}")`,
        backgroundRepeat: "no-repeat",
    };
    const nonImageBackgroundStyle = {
        background: `linear-gradient(135deg, ${colorPrimary || colorSecondary} 0%, ${colorSecondary || colorPrimary} 100%)`,
    };
    // check that pageId doesn't exist, indicates primary background(not one of the sidebar atomic-pages)
    const isMainEditBackground = viewportMode === ViewportMode.EDIT && !props.pageId;
    useEffect(() => {
        if (isMainEditBackground) {
            if (multiselectToolIds.length && prevMultiselectCount > -1) {
                // if the lengths differ, a shift+cmd event took place
                // determine new finalContainerBorders
                if (prevMultiselectCount !== multiselectToolIds.length) {
                    const [, finalContainerBorders] = calculateContainedToolIdsAndBorders(viewToolsAndTools, props.viewportDimensions, multiselectToolIds);
                    setDragSelectBoxData(prev => (Object.assign(Object.assign({}, prev), { finalContainerBorders })));
                }
            }
            setPrevMultiselectCount(multiselectToolIds.length);
            if (!multiselectToolIds.length)
                setInsideSelectBox(false);
        }
    }, [multiselectToolIds]);
    // clear the multiselect box and selected tools whenever the view/mode changes
    useEffect(() => {
        if (isMainEditBackground) {
            removeMultiselectToolId();
            setDragSelectBoxData(defaultDragSelectBoxData);
        }
    }, [activePageId, activeZoomId, viewportMode]);
    function detectScreenClick(e) {
        const clickCoordinate = { clientX: e.clientX, clientY: e.clientY };
        // programmatically detect click because pointer-events are turned off
        // elementsFromPoint will not detect and cannot toggle pointer-events due to required re-render
        const tourialViewport = document.querySelector("#tourial-viewport");
        const screen = tourialViewport.querySelector(".rnd-tool-screen");
        const screenClicked = detectClick(screen === null || screen === void 0 ? void 0 : screen.getBoundingClientRect(), clickCoordinate);
        const spotlight = tourialViewport.querySelector('[class^="spotlight"]');
        const spotlightClicked = detectClick(spotlight === null || spotlight === void 0 ? void 0 : spotlight.getBoundingClientRect(), clickCoordinate);
        const addScreen = tourialViewport.querySelector(".add-screen");
        const addScreenClicked = detectClick(addScreen === null || addScreen === void 0 ? void 0 : addScreen.getBoundingClientRect(), clickCoordinate);
        if (screenClicked) {
            if (spotlightClicked) {
                updateBuilder({
                    editDetailsId: spotlight.className.split("spotlight-")[1],
                });
                return true;
            }
            updateBuilder({ editDetailsId: "screen" });
            if (addScreenClicked) {
                updateBuilder({ isDrawerOpen: true });
            }
            return true;
        }
        return false;
    }
    return (React.createElement("div", { style: Object.assign({ width: "100%", height: "100%", cursor: isMainEditBackground && dragSelectBoxData.mouseDown && dragSelectBoxData.currentPoint
                ? "crosshair"
                : "default" }, (src ? imageBackgroundStyle : nonImageBackgroundStyle)), onClick: e => {
            if (isMainEditBackground && screenData.isLocked && !multiselectToolIds.length && detectScreenClick(e)) {
                // since we're clicking the background, reset editingTextToolId
                updateBuilder({ editingTextToolId: "" });
                // when background is clicked (or group is clicked out of focus), check to see if there are any groups
                const hasGroup = !!viewToolsAndTools.find(vtt => { var _a; return ((_a = vtt === null || vtt === void 0 ? void 0 : vtt.tool) === null || _a === void 0 ? void 0 : _a.type) === ToolType.Group; });
                if (hasGroup)
                    updateBuilder({ hiddenGroupId: "", editDetailsId: "screen" });
                return;
            }
            if (!insideSelectBox)
                updateBuilder({ hiddenGroupId: "", editDetailsId: "background" });
        }, onMouseDown: e => {
            if (!isMainEditBackground)
                return;
            if (insideSelectBox)
                return;
            if (multiselectToolIds.length)
                removeMultiselectToolId();
            // handles non left-click events
            if (e.button !== 0) {
                e.preventDefault();
                if (dragSelectBoxData.mouseDown) {
                    setDragSelectBoxData(defaultDragSelectBoxData);
                }
                return;
            }
            // avoids warning: accessing released/nullified synthetic event
            const { clientX, clientY } = e;
            setDragSelectBoxData({
                currentPoint: null,
                startPoint: { x: clientX, y: clientY },
                mouseDown: true,
                finalContainerBorders: null,
            });
        }, onMouseMove: e => {
            if (isMainEditBackground && dragSelectBoxData.mouseDown) {
                const { clientX, clientY } = e;
                setDragSelectBoxData(prev => (Object.assign(Object.assign({}, prev), { currentPoint: { x: clientX, y: clientY } })));
            }
        }, onMouseUp: () => {
            if (isMainEditBackground && dragSelectBoxData.mouseDown) {
                const [containedToolIds, finalContainerBorders] = calculateContainedToolIdsAndBorders(viewToolsAndTools, props.viewportDimensions);
                addMultiselectToolId(containedToolIds);
                setDragSelectBoxData(prev => (Object.assign(Object.assign({}, prev), { mouseDown: false, finalContainerBorders })));
            }
            // reenable pointerEvents for tools
            document.querySelectorAll("#tourial-viewport > .react-draggable").forEach((e) => {
                if (!(isScreenComponent(e) && screenData.isLocked)) {
                    e.style.pointerEvents = "auto";
                }
            });
        } }, isMainEditBackground && (dragSelectBoxData.mouseDown || (multiselectToolIds === null || multiselectToolIds === void 0 ? void 0 : multiselectToolIds.length) > 0) && (React.createElement(DragSelect, Object.assign({}, {
        dragSelectBoxData,
        setInsideSelectBox,
        viewportDimensions: props.viewportDimensions,
        isScreenLocked: screenData.isLocked,
    })))));
}
export const Background = TourialBackground;
function detectClick(targetBoundingRect, clickCoordinate) {
    if (!targetBoundingRect || !clickCoordinate)
        return false;
    const { clientX, clientY } = clickCoordinate;
    const { x, y, width, height } = targetBoundingRect;
    const xContained = x <= clientX && x + width >= clientX;
    const yContained = y <= clientY && y + height >= clientY;
    return xContained && yContained;
}
