import React, { useEffect, useState } from "react";
import { useAsync } from "react-async";
import styled from "styled-components";
import { InteractionName } from "../../../../../../../shared/types/tool-data-types/interactions";
import { TourialApiCalls } from "../../../../../helpers/tourial-api-calls";
import { usePages } from "../../../../../redux/selectors/redux-selectors";
import { ColorPicker } from "../../../../pages/builder/editor/atomic/color-picker";
import { TextInput } from "../../../../pages/builder/editor/atomic/text-input";
import { ControlLabel } from "../../../../styled/editor-components";
import { TDropdownSelect } from "../../../../styled/t-dropdown-select";
import { getTertiaryInteractionSelectorComponentType, getTertiarySelectorOptions, } from "../../../tooltip-v2/editor/components/trigger-interaction-selector";
export const GlobalButtonSection = ({ updateFn, button }) => {
    var _a, _b, _c;
    const pages = usePages();
    const [stepOptions, setStepOptions] = useState(getTertiarySelectorOptions(button.interaction.name, pages));
    const [formOptions, setFormOptions] = useState([]);
    const { isLoading: isFormListLoading } = useAsync({
        promiseFn: TourialApiCalls.Forms.getForms,
        onResolve: (forms) => {
            setFormOptions(forms.map(f => {
                return { label: f.name, value: f._id };
            }));
        },
    });
    useEffect(() => {
        setStepOptions(getTertiarySelectorOptions(button.interaction.name, pages));
    }, [button.interaction.name]);
    return (React.createElement("div", null,
        React.createElement(ControlContainer, null,
            React.createElement(ControlLabel, null, "Button Text"),
            React.createElement(TextInput, { style: { width: "178px" }, value: button.text, onChange: (e) => {
                    updateFn(Object.assign(Object.assign({}, button), { text: e.target.value }));
                } })),
        React.createElement(ControlContainer, null,
            React.createElement(ControlLabel, null, "On Click"),
            React.createElement(TDropdownSelect, { isSearchable: false, style: { width: "178px" }, selectedValue: button.interaction.name, onChange: (e) => {
                    updateFn(Object.assign(Object.assign({}, button), { interaction: Object.assign(Object.assign({}, button.interaction), { name: e.value }) }));
                }, options: [
                    { label: "Next page", value: InteractionName.NEXT_PAGE },
                    { label: "Jump to page", value: InteractionName.JUMP_TO_PAGE },
                    { label: "Next step", value: InteractionName.NEXT_STEP },
                    { label: "Previous step", value: InteractionName.PREVIOUS_STEP },
                    { label: "Jump to step", value: InteractionName.JUMP_TO_STEP },
                    { label: "External link", value: InteractionName.EXTERNAL_LINK },
                    { label: "Trigger form", value: InteractionName.TRIGGER_FORM },
                ] })),
        getTertiaryInteractionSelectorComponentType(button.interaction.name) === "selector" && (React.createElement(TDropdownSelect, { isSearchable: true, style: { marginTop: "16px" }, options: stepOptions, selectedValue: (_a = stepOptions.find(so => {
                var _a, _b, _c, _d;
                return so.value.pageId === ((_b = (_a = button.interaction.target) === null || _a === void 0 ? void 0 : _a.targetView) === null || _b === void 0 ? void 0 : _b.pageId) &&
                    so.value.zoomId === ((_d = (_c = button.interaction.target) === null || _c === void 0 ? void 0 : _c.targetView) === null || _d === void 0 ? void 0 : _d.zoomId);
            })) === null || _a === void 0 ? void 0 : _a.value, onChange: (e) => {
                updateFn(Object.assign(Object.assign({}, button), { interaction: Object.assign(Object.assign({}, button.interaction), { target: { targetView: e.value } }) }));
            } })),
        getTertiaryInteractionSelectorComponentType(button.interaction.name) === "input" && (React.createElement(TextInput, { style: { marginTop: "16px" }, placeholder: "https://", value: ((_b = button.interaction.target) === null || _b === void 0 ? void 0 : _b.targetExternalLink) || "", onChange: (e) => {
                updateFn(Object.assign(Object.assign({}, button), { interaction: Object.assign(Object.assign({}, button.interaction), { target: { targetExternalLink: e.target.value } }) }));
            } })),
        getTertiaryInteractionSelectorComponentType(button.interaction.name) === "form" && !isFormListLoading && (React.createElement(TDropdownSelect, { isSearchable: true, style: { marginTop: "16px" }, selectedValue: (_c = button.interaction.target) === null || _c === void 0 ? void 0 : _c.targetFormId, options: formOptions, onChange: (e) => {
                updateFn(Object.assign(Object.assign({}, button), { interaction: Object.assign(Object.assign({}, button.interaction), { target: { targetFormId: e.value } }) }));
            } })),
        React.createElement(ControlContainer, null,
            React.createElement(ControlLabel, null, "Text Color"),
            React.createElement(ColorPicker, { color: button.textColor, onChange: color => {
                    updateFn(Object.assign(Object.assign({}, button), { textColor: color }));
                } })),
        React.createElement(ControlContainer, null,
            React.createElement(ControlLabel, null, "Background Color"),
            React.createElement(ColorPicker, { color: button.backgroundColor, onChange: color => {
                    updateFn(Object.assign(Object.assign({}, button), { backgroundColor: color }));
                } })),
        React.createElement(ControlContainer, null,
            React.createElement(ControlLabel, null, "Border Color"),
            React.createElement(ColorPicker, { color: button.borderColor, onChange: color => {
                    updateFn(Object.assign(Object.assign({}, button), { borderColor: color }));
                } }))));
};
const ControlContainer = styled.div `
  display: flex;
  margin-top: 16px;
  height: 32px;
  align-items: center;
`;
