import React from "react";
import TLabel from "../styled/t-label";
import TToggle from "../styled/t-toggle";
import { EditorSelectGroup } from "./editor-select-group";
import { useEditViewToolsByToolId, useToolVisibility, useToggleShowOnMultipleViews, useTool, useUpdateTool, useActiveView, useViewTools, useTimer, useSetTimer, } from "../../../redux/selectors/redux-selectors";
import TSelect from "../styled/t-select";
import { TAccordion } from "../styled/t-accordion";
import TTextInput from "../styled/t-text-input";
import TH6 from "../styled/t-h6";
import cameraIcon from "../../../../assets/images/camera.svg";
import timerIcon from "../../../../assets/images/timer-icon.svg";
import TNumberInput from "../styled/t-number-input";
import { labelStyle } from "./tools/form-v2/editor";
import { EditorUngroupButton } from "./editor-ungroup-button";
import { theme, TimerTrigger } from "../../../../../shared/legacy/legacy-tool-data";
import { ToolType } from "../../../../../shared/types/tool-data-types/common";
export var VisibilityOptions;
(function (VisibilityOptions) {
    VisibilityOptions["Visible"] = "Visible";
    VisibilityOptions["Triggerable"] = "Triggerable";
    VisibilityOptions["Removed"] = "Removed";
})(VisibilityOptions || (VisibilityOptions = {}));
function VisibilityCard(props) {
    var _a, _b;
    const { pageZoom } = props;
    const editViewTool = useEditViewToolsByToolId();
    const dropdownOptions = [VisibilityOptions.Visible, VisibilityOptions.Triggerable];
    if (pageZoom.toolType !== ToolType.FormV2 && pageZoom.toolType !== ToolType.Breadcrumbs) {
        dropdownOptions.push(VisibilityOptions.Removed);
    }
    const currentValue = pageZoom.viewTool
        ? ((_a = pageZoom.viewTool) === null || _a === void 0 ? void 0 : _a.visibleOnLoad)
            ? VisibilityOptions.Visible
            : VisibilityOptions.Triggerable
        : VisibilityOptions.Removed;
    return (React.createElement(TSelect, { style: Object.assign({ border: `1px solid ${theme.colors.grey.light}`, color: theme.colors.grey.darkest, height: 28, fontSize: "14px", padding: 3, display: "inline", width: "100px", margin: "8px 0px" }, props.style), key: (_b = pageZoom.viewTool) === null || _b === void 0 ? void 0 : _b.toolId, value: currentValue, onChange: e => {
            const visibilityOption = e.target.value;
            const { toolId, pageId, zoomId } = pageZoom;
            editViewTool({ toolId, pageId, zoomId, visibilityOption });
        } }, dropdownOptions.map((option, i) => (React.createElement("option", { key: i, value: option }, option)))));
}
export function TEditorBreak() {
    return (React.createElement("div", { style: {
            width: "100%",
            borderTop: `1px solid ${theme.colors.grey.light}`,
            marginTop: 22,
            marginBottom: 22,
        } }));
}
export function VisibilitySection({ toolId }) {
    const tool = useTool(toolId);
    const updateTool = useUpdateTool();
    const activeView = useActiveView();
    const vts = useViewTools();
    const timer = useTimer(tool.id, activeView);
    const setTimer = useSetTimer();
    const toggleShowOnMultipleViews = useToggleShowOnMultipleViews(toolId);
    const { pageSections, viewToolCount } = useToolVisibility(toolId);
    const isDefaultGlobalTool = () => {
        return tool.type === ToolType.Breadcrumbs || tool.type === ToolType.FormV2;
    };
    return (React.createElement("div", { key: `EditorToolDisplayByPage_${toolId}` },
        React.createElement(TTextInput, { disabled: tool.type === ToolType.Screen || tool.type === ToolType.Breadcrumbs || tool.type === ToolType.Background, style: {
                borderColor: theme.colors.grey.light,
                boxShadow: "none",
                width: "100%",
                borderWidth: 1,
                padding: 5,
                height: 28,
                marginTop: 10,
            }, value: tool.name, onChange: e => updateTool(Object.assign(Object.assign({}, tool), { name: e.target.value })) }),
        React.createElement(TEditorBreak, null),
        React.createElement(TH6, null, "Visibility"),
        React.createElement(EditorUngroupButton, { toolId: toolId }),
        React.createElement(EditorSelectGroup, { toolId: toolId }),
        React.createElement("div", null,
            React.createElement("div", { style: {
                    display: "inline-flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                } },
                React.createElement(TH6, { style: {
                        maxWidth: "120px",
                        color: theme.colors.grey.darkest,
                    } },
                    activeView.pageName,
                    " \u00B7 ",
                    activeView.zoomName),
                React.createElement(VisibilityCard, { pageZoom: {
                        pageId: activeView.pageId,
                        pageName: activeView.pageName,
                        zoomId: activeView.zoomId,
                        zoomName: activeView.zoomId,
                        toolType: tool.type,
                        toolId,
                        viewTool: vts.find(vt => vt.pageId === activeView.pageId && vt.zoomId === activeView.zoomId && vt.toolId === toolId),
                    }, style: { margin: 0 } }))),
        React.createElement(TEditorBreak, null),
        !tool.groupToolId && (React.createElement(TLabel, { style: { marginLeft: 0 } },
            React.createElement(React.Fragment, null,
                React.createElement("img", { style: {
                        width: 20,
                    }, src: cameraIcon }),
                React.createElement("span", { style: { fontSize: 14 } }, "Show on multiple views")),
            React.createElement(TToggle, { value: isDefaultGlobalTool() || viewToolCount > 1, onClick: () => {
                    if (!isDefaultGlobalTool()) {
                        toggleShowOnMultipleViews(viewToolCount <= 1);
                    }
                }, style: { opacity: isDefaultGlobalTool() ? 0.5 : 1 } }))),
        !tool.groupToolId &&
            (isDefaultGlobalTool() || viewToolCount > 1) &&
            pageSections.map((pzSection, p) => (React.createElement(TAccordion, { title: pzSection.pageName, isInitiallyOpen: false, key: p, hideBorder: true, alignItems: "left", iconLeft: true, leftGreyBar: true }, pzSection.pageZooms.map((pageZoom, z) => (React.createElement("div", { key: z, style: {
                    display: "inline-flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                } },
                React.createElement("span", { style: { maxWidth: 95 } }, pageZoom.zoomName),
                React.createElement(VisibilityCard, { pageZoom: pageZoom }))))))),
        tool.type === ToolType.FormV2 && (React.createElement("div", null,
            React.createElement(TEditorBreak, null),
            React.createElement(TLabel, { style: { marginLeft: 0 } },
                React.createElement("div", { style: { display: "flex" } },
                    React.createElement("img", { style: {
                            width: 20,
                        }, src: timerIcon }),
                    React.createElement("span", { style: { fontSize: 14, marginLeft: 8 } }, "Add timer")),
                React.createElement(TToggle, { value: timer.isEnabled, onClick: () => {
                        const newTimer = Object.assign({}, timer);
                        newTimer.isEnabled = !timer.isEnabled;
                        setTimer(newTimer);
                    } })),
            React.createElement("div", { style: Object.assign(Object.assign({}, labelStyle), { width: "100%", marginBottom: 0 }) }, "Timer Trigger"),
            React.createElement(TSelect, { style: {
                    border: `1px solid ${theme.colors.grey.light}`,
                    color: theme.colors.grey.darkest,
                    height: 24,
                    fontSize: "14px",
                    padding: 0,
                    display: "inline",
                    width: "100%",
                    margin: "8px 0px",
                }, value: timer.trigger, onChange: e => {
                    const newTimer = Object.assign({}, timer);
                    newTimer.trigger = e.target.value;
                    setTimer(newTimer);
                } },
                React.createElement("option", { value: TimerTrigger.SessionStart }, "First Tourial activity"),
                React.createElement("option", { value: TimerTrigger.View }, "After view enter")),
            timer.trigger === TimerTrigger.View && (React.createElement(React.Fragment, null,
                React.createElement("div", { style: Object.assign(Object.assign({}, labelStyle), { width: "100%", marginBottom: 0 }) }, "Select View"),
                React.createElement(TSelect, { style: {
                        border: `1px solid ${theme.colors.grey.light}`,
                        color: theme.colors.grey.darkest,
                        height: 24,
                        fontSize: "14px",
                        padding: 0,
                        display: "inline",
                        width: "100%",
                        margin: "8px 0px",
                    }, value: `${timer.view.pageId},${timer.view.zoomId}`, onChange: e => {
                        const newTimer = Object.assign({}, timer);
                        const [pageId, zoomId] = e.target.value.split(",");
                        newTimer.view = { pageId, zoomId };
                        setTimer(newTimer);
                    } }, pageSections.map(pzSection => pzSection.pageZooms.map((pageZoom, i) => (React.createElement("option", { key: i, value: `${pageZoom.pageId},${pageZoom.zoomId}` }, pageZoom.pageName + ": " + pageZoom.zoomName))))))),
            React.createElement("div", { style: Object.assign(Object.assign({}, labelStyle), { width: "100%", marginBottom: 0 }) }, "Duration (in seconds)"),
            React.createElement(TNumberInput, { min: 0, max: 600, value: timer.durationSecs, onChange: e => {
                    const newTimer = Object.assign({}, timer);
                    newTimer.durationSecs = +e.target.value;
                    setTimer(newTimer);
                } })))));
}
