import React, { useEffect, useRef, useState } from "react";
import { useRefDimensions } from "../../../hooks/viewport-hooks";
import styled from "styled-components";
import { useDeselect, useShouldRenderForm } from "../../../redux/selectors/builder-selectors";
import { newTheme } from "../../styled/theme";
import { useTools } from "../../../redux/selectors/redux-selectors";
import { RenderTools } from "../render-tools";
import { ScreenV2View } from "../../tools/screen/screen-v2-view";
import { SelectionWrapper } from "./selection-wrapper";
import { useSelectionWrapperStyle } from "../../../hooks/use-selection-wrapper-style";
import { TourFormV3 } from "../fetch-and-render-form-v3";
export function EditViewport({ setBuilderViewportDimensions, }) {
    const viewportRef = useRef(null);
    const viewportDimensions = useRefDimensions(viewportRef);
    const tools = useTools();
    const deselect = useDeselect();
    const shouldRenderForm = useShouldRenderForm();
    const baseViewProps = {
        viewportDimensions,
        disableInteractions: true,
        disableAnimations: true,
        disableEventTracking: true,
        isInEditViewport: true,
    };
    useEffect(() => {
        setBuilderViewportDimensions(viewportDimensions);
    }, [viewportDimensions.x, viewportDimensions.y, viewportDimensions.width, viewportDimensions.height]);
    const [viewportStyle, setViewportStyle] = useState({});
    useSelectionWrapperStyle(tools.screen.id, setViewportStyle, false);
    return (React.createElement("div", { style: { width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" } },
        React.createElement(Div, { id: "tourial-builder-viewport", onClick: e => e.stopPropagation(), onDragOver: e => e.preventDefault(), className: "tourial-viewport", ref: viewportRef, style: Object.assign({ height: viewportDimensions.height ? viewportDimensions.height : "auto", width: viewportDimensions.width ? viewportDimensions.width : "auto" }, viewportStyle) },
            React.createElement("div", { style: { position: "absolute", width: "100%", height: "100%" }, onClick: deselect }),
            React.createElement(SelectionWrapper, { toolId: tools.screen.id, toolName: tools.screen.name, traits: { showTab: false, selectable: true, dashedOutline: false }, overloads: { setStyle: setViewportStyle } },
                React.createElement(ScreenV2View, Object.assign({}, Object.assign(Object.assign({}, baseViewProps), { toolData: tools.screen })))),
            React.createElement(RenderTools, { baseViewProps: baseViewProps }),
            shouldRenderForm && React.createElement(TourFormV3, { baseViewProps: baseViewProps }))));
}
const Div = styled.div `
  background-color: ${newTheme.colors.grey100};
  position: absolute;
  overflow: hidden;
  ${newTheme.shadows.editPanel};
`;
