import { shallowEqual, useDispatch } from "react-redux";
import { swapInPlace } from "../helpers/misc";
import { ActionType } from "../redux/actions/redux-actions";
import { useActiveVariantName, useSortedViewTools, useSortedViewToolsInActiveView, useTools, useTourial, } from "../redux/selectors/redux-selectors";
import { ToolType } from "../../../shared/types/tool-data-types/common";
export function swap(arr, ai, bi) {
    const newArr = [...arr];
    if (bi >= 0 && bi < newArr.length) {
        const tmp = newArr[ai];
        newArr[ai] = newArr[bi];
        newArr[bi] = tmp;
    }
    return newArr;
}
export const groupChildrenWithParent = (
// this doesn't work across multiple pages that display the same group tool.
viewTools, tourial, activeVariantName) => {
    const groupTools = viewTools
        .map(vt => tourial[activeVariantName].tools[vt.toolId])
        .filter(t => (t === null || t === void 0 ? void 0 : t.type) === ToolType.Group);
    let sortedViewTools = [...viewTools];
    groupTools.forEach(groupTool => {
        const children = sortedViewTools.filter(vt => { var _a; return ((_a = tourial[activeVariantName].tools[vt.toolId]) === null || _a === void 0 ? void 0 : _a.groupToolId) === groupTool.id; });
        // Take children out of sorted tools
        sortedViewTools = sortedViewTools.filter(vt => { var _a; return !(((_a = tourial[activeVariantName].tools[vt.toolId]) === null || _a === void 0 ? void 0 : _a.groupToolId) === groupTool.id); });
        // Figure out where to put children
        const groupParentIndex = sortedViewTools.findIndex(vt => vt.toolId === groupTool.id);
        // Put children back
        sortedViewTools.splice(groupParentIndex, 0, ...children);
    });
    return sortedViewTools;
};
export const useEditToolZIndex = () => {
    const dispatch = useDispatch();
    const tourial = useTourial();
    const viewTools = useSortedViewTools();
    const viewToolsInView = useSortedViewToolsInActiveView();
    const tools = useTools();
    const activeVariantName = useActiveVariantName();
    const editToolZIndex = (toolId, direction) => {
        const firstViewToolInViewIndex = viewTools.findIndex(vt => shallowEqual(vt, viewToolsInView[0]));
        const lastViewToolInViewIndex = firstViewToolInViewIndex + viewToolsInView.length - 1;
        const toolToMoveIndex = viewToolsInView.findIndex(vt => vt.toolId === toolId);
        function addChildrenToViewTools(parentViewTools, childViewTools) {
            const combinedViewTools = [...parentViewTools];
            let lastGroupId = "";
            for (const child of childViewTools) {
                if (lastGroupId !== tools[child.toolId].groupToolId) {
                    lastGroupId = tools[child.toolId].groupToolId;
                }
                const indexOfParent = combinedViewTools.findIndex(vt => vt.toolId === lastGroupId);
                combinedViewTools.splice(indexOfParent, 0, child);
            }
            return combinedViewTools;
        }
        function calculateGroupSize(groupId) {
            if (tools[groupId].type !== ToolType.Group)
                return 0;
            let sum = 0;
            for (const vt of viewToolsInView) {
                if (tools[vt.toolId].groupToolId === groupId) {
                    sum++;
                }
            }
            return sum;
        }
        function checkIfAtBack() {
            return viewToolsInView.findIndex(vt => vt.toolId === toolId) - calculateGroupSize(toolId) === 0;
        }
        function checkIfAtFront() {
            return viewToolsInView.findIndex(vt => vt.toolId === toolId) === viewToolsInView.length - 1;
        }
        function moveParent(dir) {
            const parentViewToolsInView = viewToolsInView.filter(vt => !tools[vt.toolId].groupToolId);
            const childViewToolsInView = viewToolsInView.filter(vt => tools[vt.toolId].groupToolId);
            const toolIndexAmongParents = parentViewToolsInView.findIndex(vt => vt.toolId === toolId);
            const nextIndex = toolIndexAmongParents + (dir === "backward" ? -1 : +1);
            swapInPlace(parentViewToolsInView, toolIndexAmongParents, nextIndex);
            return addChildrenToViewTools(parentViewToolsInView, childViewToolsInView);
        }
        function moveChild(dir) {
            const siblingToolIndices = viewToolsInView
                .map((vt, i) => (tools[vt.toolId].groupToolId === tools[toolId].groupToolId ? i : -1))
                .filter(vti => vti >= 0);
            if (viewToolsInView.findIndex(vt => vt.toolId === toolId) ===
                (dir === "backward" ? siblingToolIndices[0] : siblingToolIndices[siblingToolIndices.length - 1])) {
                return viewToolsInView;
            }
            const toolIndexAmongSiblings = siblingToolIndices[siblingToolIndices.findIndex(pti => pti === toolToMoveIndex)];
            const nextIndex = siblingToolIndices[siblingToolIndices.findIndex(pti => pti === toolToMoveIndex) + (dir === "backward" ? -1 : 1)];
            return swap(viewToolsInView, toolIndexAmongSiblings, nextIndex);
        }
        let newViewToolsInView = [...viewToolsInView];
        if (direction === "moveBackward") {
            if (checkIfAtBack()) {
                return;
            }
            if (tools[toolId].groupToolId) {
                // Tool is the child of a group
                newViewToolsInView = moveChild("backward");
            }
            else {
                newViewToolsInView = moveParent("backward");
            }
        }
        else if (direction === "bringForward") {
            if (checkIfAtFront()) {
                return;
            }
            if (tools[toolId].groupToolId) {
                // Tool is the child of a group
                newViewToolsInView = moveChild("forward");
            }
            else {
                newViewToolsInView = moveParent("forward");
            }
        }
        // Merge altered array of viewTools in the current view back into the global viewTools array
        const mergedViewTools = [
            ...viewTools.slice(0, firstViewToolInViewIndex),
            ...newViewToolsInView,
            ...viewTools.slice(lastViewToolInViewIndex + 1),
        ];
        dispatch({
            type: ActionType.SET_LOCAL_TOURIAL,
            tourial: Object.assign(Object.assign({}, tourial), { [activeVariantName]: Object.assign(Object.assign({}, tourial[activeVariantName]), { viewTools: mergedViewTools }) }),
        });
    };
    return editToolZIndex;
};
