import React from "react";
import styled from "styled-components";
import { useDCItem } from "../hooks/dc-item-hooks";
import { useDCGoToFeaturedContent } from "../hooks/dc-navigation-hooks";
export const DCFeaturedContent = ({ id }) => {
    const goToFeaturedContent = useDCGoToFeaturedContent();
    const item = useDCItem(id);
    return item ? React.createElement(FeaturedContentThumbnail, { src: item.thumbnail, onClick: () => goToFeaturedContent() }) : null;
};
const FeaturedContentThumbnail = styled.img `
  width: 680px;
  cursor: pointer;
`;
