import React from "react";
import EmptyStoryboardBackground from "../../../../../assets/images/v3/storyboard-background.svg";
import { newTheme } from "../../../styled/theme";
import styled from "styled-components";
export function StoryboardMiniContentsEmpty() {
    return (React.createElement(React.Fragment, null,
        React.createElement(EmptyStoryBoard, { backgroundImage: EmptyStoryboardBackground },
            React.createElement(HelperText, null,
                React.createElement("span", { style: { font: newTheme.fonts.header, textAlign: "center" } }, "Start telling your product story"),
                React.createElement("span", { style: { font: newTheme.fonts.medium, textAlign: "center" } },
                    `Don't know where to start? Check out `,
                    React.createElement("a", { target: "_blank", rel: "noreferrer", href: "https://www.tourial.com/customer-gallery", style: { textDecoration: "underline", color: newTheme.colors.blue500 } }, "our gallery"),
                    " ",
                    "for inspiration.")))));
}
const EmptyStoryBoard = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-image: url(${props => props.backgroundImage});
`;
const HelperText = styled.div `
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 52px;
  left: 0px;
`;
