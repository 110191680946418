import { cloneDeep } from "lodash";
import React from "react";
import { useUpdateTool } from "../../../redux/selectors/redux-selectors";
import { EditorTitlebar } from "../../pages/builder/editor/editor-titlebar";
export function SandboxEditor(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    return (React.createElement("div", { id: "_test_modal-editor", style: { height: "100%" } },
        React.createElement(EditorTitlebar, { title: tool.name, toolId: tool.id, tool: tool }),
        React.createElement("textarea", { style: { height: "90%", width: "90%", margin: 10, border: "1px solid black" }, value: tool.innerHtml, onChange: (e) => {
                const newTool = cloneDeep(tool);
                newTool.innerHtml = e.target.value;
                updateTool(newTool);
            } })));
}
