import React from "react";
import styled from "styled-components";
import { TLoadingSpinner } from "../../styled/t-loading-spinner";
export function BuilderLoading() {
    return (React.createElement(Div, { id: "_test_builder-loading" },
        React.createElement(TLoadingSpinner, null)));
}
const Div = styled.div `
  height: 100vh;
`;
