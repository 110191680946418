import React from "react";
import styled from "styled-components";
import { DCCMSection } from "./components/dc-cm-section";
import { DCCMPageType, DCIconType } from "../../../../../../shared/types/demo-center";
import { useDCConfig, useDCSetSelfGuidedButtonText, useDCSetUpfrontQuestionsConfig, useDCSetUpfrontQuestionsIsEnabled, useDCSetWelcomeConfig, useDCSetWelcomePageIsEnabled, } from "../hooks/dc-hooks";
import { newTheme } from "../../../styled/theme";
import { cloneDeep } from "lodash";
import { TToggleSwitch } from "../../../styled/t-toggle-switch";
import { useDCFirstModule, useWelcomeModuleFirstItemId } from "../hooks/dc-module-hooks";
import { TMessageBox, TMessageBoxVariants } from "../../../styled/t-message-box";
import { DCCMTextEditor } from "./components/dc-cm-text-editor";
import { useDCBuilderWarning, useDCLivePreviewHelper } from "../hooks/dc-builder-hooks";
export const DCCMIntroPageEditor = () => {
    const { welcome, upfrontQuestions } = useDCConfig();
    const firstModule = useDCFirstModule();
    const welcomeModuleFirstItemId = useWelcomeModuleFirstItemId();
    const { showFeaturedContent, isEnabled } = welcome;
    const setWelcomeConfig = useDCSetWelcomeConfig();
    const setUpfrontQuestionsConfig = useDCSetUpfrontQuestionsConfig();
    const setDemoButtonText = useDCSetSelfGuidedButtonText();
    const warning = useDCBuilderWarning(DCCMPageType.INTRO_PAGE);
    useDCLivePreviewHelper(DCCMPageType.INTRO_PAGE);
    const updateTitle = (e) => {
        const w = cloneDeep(welcome);
        w.title.text = e.target.value;
        setWelcomeConfig(w);
    };
    const updateMessage = (e) => {
        const w = cloneDeep(welcome);
        w.message.text = e.target.value;
        setWelcomeConfig(w);
    };
    const updateShowPreview = (checked) => {
        const w = cloneDeep(welcome);
        w.featuredContentId = checked ? welcomeModuleFirstItemId : null;
        w.showFeaturedContent = checked;
        setWelcomeConfig(w);
    };
    const updateButtonText = (e) => {
        setDemoButtonText(e.target.value);
    };
    const updateUpfrontHeaderText = (e) => {
        const uq = cloneDeep(upfrontQuestions);
        uq.headerText = e.target.value;
        setUpfrontQuestionsConfig(uq);
    };
    const updateUpfrontButtonText = (e) => {
        const uq = cloneDeep(upfrontQuestions);
        uq.buttonText = e.target.value;
        setUpfrontQuestionsConfig(uq);
    };
    const setWelcomePageIsEnabled = useDCSetWelcomePageIsEnabled();
    const setUpfrontQuestionsIsEnabled = useDCSetUpfrontQuestionsIsEnabled();
    return (React.createElement(DCCMSection, { icon: DCIconType.HAND_WAVE, title: DCCMPageType.INTRO_PAGE, description: "Introductions are a customizable part of your Demo Center. Choose to add a welcome screen, show all questions upfront, or both." },
        React.createElement(ToggleContainer, null,
            React.createElement(TextAndToggle, null,
                React.createElement("div", null, "Welcome screen"),
                React.createElement(TToggleSwitch, { onChange: () => setWelcomePageIsEnabled(!isEnabled), enabled: isEnabled })),
            React.createElement(SubText, null, "Orient visitors with a friendly welcome message.")),
        isEnabled && (React.createElement(React.Fragment, null,
            React.createElement(DCCMTextEditor, { title: "Intro header", value: welcome.title.text, disabled: !isEnabled, onChange: updateTitle }),
            React.createElement(DCCMTextEditor, { title: "Subheader", value: welcome.message.text, disabled: !isEnabled, onChange: updateMessage, textarea: true }),
            React.createElement(DCCMTextEditor, { title: "Demo button text", value: welcome.demoButton.text, disabled: !isEnabled, onChange: updateButtonText }),
            React.createElement(DCCMSettingToggleContainer, null,
                React.createElement(DCCMToggleLabel, null, "Show preview of welcome content"),
                React.createElement(TToggleSwitch, { onChange: updateShowPreview, enabled: showFeaturedContent, disabled: !isEnabled })),
            showFeaturedContent && (React.createElement(TMessageBox, { variant: TMessageBoxVariants.SECONDARY, size: "small", message: `This setting renders the thumbnail image of the first piece of content in the '${(firstModule === null || firstModule === void 0 ? void 0 : firstModule.title) || "Welcome"}' content track.` })),
            warning && React.createElement(TMessageBox, { variant: TMessageBoxVariants.WARNING, message: warning, size: "medium" }))),
        React.createElement(ToggleContainer, null,
            React.createElement(TextAndToggle, null,
                React.createElement("div", null, "Show all questions as form"),
                React.createElement(TToggleSwitch, { onChange: setUpfrontQuestionsIsEnabled, enabled: upfrontQuestions.isEnabled })),
            React.createElement(SubText, null, "Create a friendly first impression with a custom welcome message.")),
        upfrontQuestions.isEnabled && (React.createElement(React.Fragment, null,
            React.createElement(DCCMTextEditor, { title: "Form header", value: upfrontQuestions.headerText, disabled: !upfrontQuestions.isEnabled, onChange: updateUpfrontHeaderText }),
            React.createElement(DCCMTextEditor, { title: "Form button text", value: upfrontQuestions.buttonText, disabled: !upfrontQuestions.isEnabled, onChange: updateUpfrontButtonText })))));
};
export const DCCMSettingToggleContainer = styled.div `
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const DCCMToggleLabel = styled.div `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
`;
const ToggleContainer = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
  flex-direction: column;
`;
const TextAndToggle = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  font: ${newTheme.fonts.mediumBold};
`;
const SubText = styled.div `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey600};
`;
