import React from "react";
import styled from "styled-components";
import { CtaInteraction } from "../../../../../shared/types/product-page";
import { useRestartTour } from "../../../redux/selectors/interaction-selectors";
import { useSetPageForm } from "../../../redux/selectors/product-page-selectors";
import { useTourialProductTourPage } from "../../../redux/selectors/redux-selectors";
import { usePostEvent } from "../../../helpers/events";
import { PageFormV3 } from "../fetch-and-render-form-v3";
import { ensureHttpsInUrl, openUrlInNewTab } from "../../../../../shared/functions/helpers";
export const ViewportHeader = ({ style }) => {
    const productPage = useTourialProductTourPage();
    const { ctaPrimary, ctaSecondary, ctaTertiary, headerColor, headerBorderWidth, headerBorderColor, logo } = productPage || {};
    return (React.createElement(HeaderContainer, { id: "live-viewport-header", style: Object.assign(Object.assign({}, style), { backgroundColor: headerColor, borderBottom: `${headerBorderWidth}px solid ${headerBorderColor}` }) },
        React.createElement(Logo, { src: logo }),
        React.createElement(ButtonContainer, null,
            ((ctaPrimary === null || ctaPrimary === void 0 ? void 0 : ctaPrimary.isEnabled) || (ctaPrimary === null || ctaPrimary === void 0 ? void 0 : ctaPrimary.isEnabled) === undefined) && React.createElement(CtaWithTracking, { cta: ctaPrimary }),
            (ctaSecondary === null || ctaSecondary === void 0 ? void 0 : ctaSecondary.isEnabled) && React.createElement(CtaWithTracking, { cta: ctaSecondary }),
            (ctaTertiary === null || ctaTertiary === void 0 ? void 0 : ctaTertiary.isEnabled) && React.createElement(CtaWithTracking, { cta: ctaTertiary })),
        React.createElement(PageFormV3, null)));
};
const CtaWithTracking = ({ cta }) => {
    const postEvent = usePostEvent();
    const setPageForm = useSetPageForm();
    const { type, href, formId } = (cta === null || cta === void 0 ? void 0 : cta.interaction) || {};
    const restartTour = useRestartTour();
    return (React.createElement(Cta, Object.assign({ id: "_text_CTA-PRIMARY", onClick: () => {
            void postEvent({
                eventType: "EMBED_HEADER_CTA_CLICK",
                productPageCtaInteraction: cta === null || cta === void 0 ? void 0 : cta.interaction,
            });
            switch (type) {
                case CtaInteraction.RESTART_TOUR:
                    restartTour();
                    return;
                case CtaInteraction.OPEN_FORM_V3:
                    setPageForm({ formId, isEnabled: true, isGated: false, isCTA: true });
                    return;
                case CtaInteraction.EXTERNAL_LINK_NEW_TAB:
                case CtaInteraction.EXTERNAL_LINK_SAME_WINDOW: // deprecated EXTERNAL_LINK_SAME_WINDOW... which never worked in embedded anyway <.<
                case CtaInteraction.OPEN_HUBSPOT_SCHEDULER:
                case CtaInteraction.OPEN_CALENDLY_SCHEDULER:
                    openUrlInNewTab(ensureHttpsInUrl(href));
                    return;
            }
        } }, cta), cta === null || cta === void 0 ? void 0 : cta.text));
};
const HeaderContainer = styled.div `
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;
const ButtonContainer = styled.div `
  display: flex;
  justify-content: right;
`;
const Cta = styled.button `
  background-color: ${p => p.backgroundColor};
  color: ${p => p.color};
  font-family: ${p => p.fontFamily};
  padding: 10px;
  border-radius: ${p => p.borderRadius}px;
  border: ${p => p.borderWidth}px solid ${p => p.borderColor};
  margin-left: 16px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
const Logo = styled.img `
  max-height: 40px;
`;
