import React from "react";
import TButton from "../styled/t-button";
import { useToolData, useUpdateTool } from "../../../redux/selectors/redux-selectors";
export function EditorUngroupButton({ toolId }) {
    var _a;
    const tool = useToolData(toolId);
    const groupToolName = (_a = useToolData(tool.groupToolId)) === null || _a === void 0 ? void 0 : _a.name;
    const updateTool = useUpdateTool();
    if (!tool.groupToolId)
        return null;
    return (React.createElement(TButton, { invertColors: true, style: {
            padding: 3,
            width: "100%",
            margin: "10px 0px",
        }, onClick: () => {
            const copyOfTool = Object.assign(Object.assign({}, tool), { groupToolId: null });
            delete copyOfTool.animation;
            delete copyOfTool.animationDuration;
            updateTool(copyOfTool);
        } },
        "Ungroup from ",
        groupToolName));
}
