import React, { useState } from "react";
import { ContentContainer } from "./workspace-create-modal";
import { TToggleSwitch } from "../../styled/t-toggle-switch";
import { v4 as uuidv4 } from "uuid";
import TInput from "../../styled/t-input";
import styled from "styled-components";
import { newTheme } from "../../styled/theme";
import { useFeatureFlags } from "../../../redux/selectors/redux-selectors";
export function ShareLinkPassword({ password, setPassword }) {
    const { passwordProtect } = useFeatureFlags();
    const [origPassword] = useState(password);
    if (!passwordProtect) {
        return null;
    }
    return (React.createElement(ContentContainer, null,
        React.createElement(PasswordProtectContainer, null,
            React.createElement("h3", null, "Password protection"),
            React.createElement(TToggleSwitch, { enabled: typeof password === "string", size: "small", onChange: enabled => {
                    if (enabled)
                        setPassword(origPassword || uuidv4().split("-").pop());
                    else
                        setPassword(null);
                }, id: "_test_share_link_password_protect_toggle" })),
        React.createElement(PasswordProtectDescription, null, "Visitors must enter password before entering the Demo Center."),
        typeof password === "string" && (React.createElement(TInput, { value: password || "", onChange: e => {
                setPassword(e.target.value);
            }, id: "_test_share_link_password_protect_input" }))));
}
const PasswordProtectContainer = styled.div `
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  //height: 24px;
  > h3 {
    font: ${newTheme.fonts.bold};
    white-space: nowrap;
  }
`;
const PasswordProtectDescription = styled.div `
  display: block;
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey600};
`;
