import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { DCItemType, DC_ROOT } from "../../../../../../shared/types/demo-center";
import styled from "styled-components";
import { DCRenderPlayer } from "./dc-render-player";
import { DCHeader } from "./dc-header";
import { useDCActivateItem, useDCItem, useDCItemSubNavigationMonitor, useDCItemViewTimer, } from "../hooks/dc-item-hooks";
import { useDCItemLoadEvent } from "../hooks/dc-event-hooks";
import { useDCActivateModule, useDCGetModule } from "../hooks/dc-module-hooks";
import { useDCConfig, useDCIsLiveMode, useDCScreenSizeMonitor, useDCSidebar, useDCSlug } from "../hooks/dc-hooks";
import { DCControls } from "./dc-controls";
import { useDCConfigGatingFormID, useDCShouldShowUpfrontQuestions, } from "../../../../redux/selectors/demo-center-selectors";
import { wasFormSubmitted } from "../../../../helpers/form-helpers";
import { useSetPageForm } from "../../../../redux/selectors/product-page-selectors";
import UpfrontQuestions from "./dc-upfront-questions";
export const DCItemView = (props) => {
    const { id, module: moduleSlug } = useParams();
    const slug = useDCSlug();
    const { autoplay } = useDCConfig();
    const item = useDCItem(props.id || id);
    const isPdf = (item === null || item === void 0 ? void 0 : item.type) === DCItemType.PDF;
    const isLiveMode = useDCIsLiveMode();
    const isCollapsed = useDCScreenSizeMonitor();
    const shouldShowUpfrontQuestions = useDCShouldShowUpfrontQuestions();
    useDCItemSubNavigationMonitor(item);
    useDCItemLoadEvent(item);
    useDCItemViewTimer(id);
    useDCActivateItem(id);
    useDCActivateModule(moduleSlug);
    useDCSidebar();
    // check form submitted status on direct links to topics in gated modules
    const setPageForm = useSetPageForm();
    const getModule = useDCGetModule();
    const module = getModule(moduleSlug);
    const gatingFormId = useDCConfigGatingFormID();
    useEffect(() => {
        if (module === null || module === void 0 ? void 0 : module.isGated) {
            if (!wasFormSubmitted(gatingFormId)) {
                setPageForm({
                    formId: gatingFormId,
                    isGated: false,
                    isEnabled: true,
                    isCTA: false,
                });
            }
        }
    }, [module]);
    if (!item && isLiveMode) {
        return React.createElement(Redirect, { to: `/${DC_ROOT}/${slug}` });
    }
    return (React.createElement(React.Fragment, null,
        shouldShowUpfrontQuestions && React.createElement(UpfrontQuestions, null),
        React.createElement(DCHeader, { showBrowseButton: true, transparent: true }),
        React.createElement(DCItemViewContainer, null,
            React.createElement(ScrollContainer, null,
                React.createElement(PlayerWrapper, { className: isLiveMode ? "live" : "", isCollapsed: isCollapsed },
                    React.createElement(DCRenderPlayer, { item: item, autoplay: autoplay && isLiveMode }),
                    !isPdf && React.createElement(DCControls, null))),
            isPdf && (React.createElement(FloatingControlsContainer, null,
                React.createElement(DCControls, null))))));
};
const DCItemViewContainer = styled.div `
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 348px;
`;
const ScrollContainer = styled.div `
  margin: 0 24px 0 0;
`;
const PlayerWrapper = styled.div `
  margin: 0 auto;
  &.live {
    max-width: min(calc(((100vh - 214px) * (16 / 9))), 1920px);
  }
`;
const FloatingControlsContainer = styled.div `
  z-index: 2;
  position: fixed;
  bottom: 24px;
  left: 0;
  width: 100%;
  padding: 0 48px 0 372px;
`;
