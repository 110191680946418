import React, { useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import HomeGreyIcon from "../../../../../assets/images/v3/home-grey.svg";
import ClockIcon from "../../../../../assets/images/v3/clock-icon.svg";
import { DCBuilderMode } from "../../../../../../shared/types/demo-center";
import { useDCBuilderMode } from "../../../../redux/selectors/demo-center-selectors";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { useDCName, useDCSetName, useDCStatus, useDCDiscardChanges, usePublishDemoCenter } from "../hooks/dc-hooks";
import { useDCBuilderGoToPage, useDCBuilderHasChangesToPublish, useDCBuilderToggleLivePreviewMode, useDCGoToTourialHome, } from "../hooks/dc-builder-hooks";
import { NameInput } from "../../builder/navbar/name-input";
import TStatusIndicator from "../../../styled/t-status-indicator";
import { TourialStatus } from "../../../../../../shared/types/tourial-model";
import { DCPublishOrDiscardModal } from "./modals/dc-publish-modal";
import { usePostAppEvent } from "../../../../hooks/use-post-app-event";
import { TourialAppEventTypes } from "../../../../../../shared/types/app-events";
import { useDCIsEditModeBlocked } from "../../../../hooks/use-is-collaborator-blocked";
import { EditModeDisabled } from "../../builder/navbar/edit-mode-disabled";
import { useUserAccess } from "../../../../redux/selectors/redux-selectors";
import { AccessLevel } from "../../../../../../shared/types/auth-types";
export const DCBuilderHeader = () => {
    const goToTourialHome = useDCGoToTourialHome();
    const name = useDCName();
    const status = useDCStatus();
    const setName = useDCSetName();
    const mode = useDCBuilderMode();
    const isPreviewMode = mode === DCBuilderMode.PREVIEW;
    return (React.createElement(HeaderContainer, null,
        React.createElement(HomeSection, null,
            React.createElement(HomeButton, { onClick: goToTourialHome },
                React.createElement("img", { draggable: "false", src: HomeGreyIcon, alt: "back button" })),
            React.createElement(NameInput, { name: name, setName: setName, disabled: isPreviewMode }),
            React.createElement(TStatusIndicator, { status: status })),
        React.createElement(TabBar, { mode: mode }),
        React.createElement(Controls, { mode: mode })));
};
const TabBar = ({ mode }) => {
    const goToPage = useDCBuilderGoToPage();
    const tabs = [
        {
            name: "Manage Content",
            mode: DCBuilderMode.MANAGE,
        },
        {
            name: "Edit Styles",
            mode: DCBuilderMode.STYLES,
        },
        {
            name: "Demo Center Overview",
            mode: DCBuilderMode.OVERVIEW,
        },
    ];
    return (React.createElement(OuterTabContainer, null,
        React.createElement(TabContainer, null, mode !== DCBuilderMode.PREVIEW &&
            tabs.map(t => (React.createElement(Tab, { key: t.mode, onClick: () => goToPage(`${t.mode}`), className: mode === t.mode ? "selected" : null }, t.name))))));
};
const Controls = ({ mode }) => {
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
    const discardDemoCenterChanges = useDCDiscardChanges();
    const publishDemoCenter = usePublishDemoCenter();
    const status = useDCStatus();
    const hasChangesToPublish = useDCBuilderHasChangesToPublish();
    const postAppEvent = usePostAppEvent();
    const isPreviewMode = mode === DCBuilderMode.PREVIEW;
    const toggleLivePreviewMode = useDCBuilderToggleLivePreviewMode();
    const isEditModeBlocked = useDCIsEditModeBlocked();
    const userAccess = useUserAccess();
    const getPublishButtonText = () => {
        if (status === TourialStatus.PENDING_CHANGES) {
            return "Republish";
        }
        else if (status === TourialStatus.LIVE) {
            return "Published";
        }
        else {
            return "Publish";
        }
    };
    const publishButtonText = getPublishButtonText();
    return (React.createElement(React.Fragment, null,
        isEditModeBlocked ? (React.createElement(EditModeDisabled, { style: { marginRight: 24 } })) : (React.createElement(ControlContainer, null,
            hasChangesToPublish && !isPreviewMode && status !== TourialStatus.DRAFT && (React.createElement(PublishContainer, { id: "_test_publish-section", onClick: () => setIsDiscardModalOpen(true) },
                React.createElement("img", { src: ClockIcon, alt: "clock" }),
                React.createElement(UnpublishedChangesText, null, "Unpublished changes"))),
            isPreviewMode && (React.createElement(TButton, { size: "medium", variant: TButtonVariants.DANGER, onClick: toggleLivePreviewMode }, "Exit Preview")),
            !isPreviewMode && userAccess !== AccessLevel.COLLABORATOR && (React.createElement(TButton, { size: "medium", onClick: () => setIsPublishModalOpen(true), disabled: !(hasChangesToPublish || status !== TourialStatus.LIVE) }, publishButtonText)))),
        isPublishModalOpen && (React.createElement(DCPublishOrDiscardModal, { title: `${publishButtonText} Demo Center`, message: `Are you sure you want to ${publishButtonText.toLowerCase()} your changes?`, close: () => setIsPublishModalOpen(false), confirm: () => {
                void publishDemoCenter();
                setIsPublishModalOpen(false);
            }, confirmVariant: TButtonVariants.DEFAULT, confirmText: publishButtonText, showWarnings: true })),
        isDiscardModalOpen && (React.createElement(DCPublishOrDiscardModal, { title: "Discard Unpublished Changes", message: "Are you sure you want to discard unsaved changes?", close: () => setIsDiscardModalOpen(false), confirm: () => {
                void discardDemoCenterChanges();
                postAppEvent(TourialAppEventTypes.DEMOCENTER_DISCARD_CHANGES);
                setIsDiscardModalOpen(false);
            }, confirmVariant: TButtonVariants.DANGER, confirmText: "DANGER - Discard Changes" }))));
};
const HomeSection = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
  width: 400px;
`;
const HomeButton = styled.button `
  flex-shrink: 0;
  width: 48px;
  height: 56px;
  cursor: pointer;
`;
const HeaderContainer = styled.div `
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
  background-color: ${newTheme.colors.white};
  border-bottom: 1px solid ${newTheme.colors.grey300};
  box-shadow:
    2px 2px 8px 0px rgba(48, 49, 51, 0.02),
    0px 0px 20px 0px rgba(48, 49, 51, 0.05);
`;
const OuterTabContainer = styled.div `
  display: flex;
  flex: 1;
  justify-content: center;
`;
const TabContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
const Tab = styled.div `
  display: flex;
  color: ${newTheme.colors.grey500};
  font: ${newTheme.fonts.normal};
  align-items: center;
  height: 56px;
  padding: 4px 0;
  &.selected {
    color: ${newTheme.colors.black};
    border-bottom: 4px solid ${newTheme.colors.blue500};
    padding-bottom: 0;
    font-weight: bold;
  }
  cursor: pointer;
`;
const ControlContainer = styled.div `
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding-right: 16px;
`;
const PublishContainer = styled.span `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  cursor: pointer;
  animation: ${newTheme.animations.fadeIn};
  animation-duration: 0.3s;
  animation-timing-function: linear;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;
const UnpublishedChangesText = styled.p `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  > span {
    color: ${newTheme.colors.grey500};
    text-decoration: underline;
  }
`;
