import { css, keyframes } from "styled-components";
export const newTheme = {
    colors: {
        white: "#FFFFFF",
        black: "#1C1C1C",
        // Grey
        grey: "#E4E5E9",
        grey100: "#FAFAFA",
        grey200: "#EFF0F3",
        grey300: "#E4E5E9",
        grey400: "#C9CACF",
        grey500: "#90939D",
        grey600: "#747681",
        grey700: "#62636A",
        grey800: "#4D5056",
        grey900: "#303136",
        editModeGrey: "#DDDDDD",
        // Red
        red50: "#FFECED",
        red100: "#FCD3E0",
        red200: "#FABACE",
        red300: "#F790B1",
        red400: "#F35889",
        red500: "#D5104F",
        red600: "#BE0E46",
        red700: "#980B38",
        red800: "#640725",
        red900: "#3E0517",
        // Yellow
        yellow50: "#FFFAEA",
        yellow100: "#FFF6D5",
        yellow200: "#FFEBAB",
        yellow300: "#FFDD81",
        yellow400: "#FFCF62",
        yellow500: "#FFB92E",
        yellow600: "#DB9621",
        yellow700: "#B77617",
        yellow800: "#93590E",
        yellow900: "#7A4408",
        // Green
        green50: "#F3FEEB",
        green100: "#E8FDD7",
        green200: "#CBFCAF",
        green300: "#A7F786",
        green400: "#83EF66",
        green500: "#4EE537",
        green600: "#30C428",
        green700: "#1BA41F",
        green800: "#11841D",
        green900: "#0A6D1C",
        // Blue
        blue: "#0055FF",
        blue25: "#F4F9FF",
        blue50: "#E1EFFF",
        blue100: "#CCE3FF",
        blue200: "#94BBF8",
        blue300: "#3F86FF",
        blue400: "#266FFF",
        blue500: "#0055FF",
        blue600: "#0041DB",
        blue700: "#0030B7",
        blue800: "#002293",
        blue900: "#00187A",
        storyboardSelectBlue: "#D1DDFF",
        // Dark Blue
        darkblue50: "#EBF2FD",
        darkblue100: "#C9DEFB",
        darkblue200: "#94BBF8",
        darkblue300: "#5D90EA",
        darkblue400: "#356AD6",
        darkblue500: "#0038BB",
        darkblue600: "#002BA0",
        darkblue700: "#002086",
        darkblue800: "#00166C",
        darkblue900: "#000F59",
        // Cyan
        cyan50: "#E5FFFB",
        cyan100: "#CCFFF7",
        cyan200: "#99FFF8",
        cyan300: "#66FCFF",
        cyan400: "#3FEDFF",
        cyan500: "#00D4FF",
        cyan600: "#00A5DB",
        cyan700: "#007CB7",
        cyan800: "#005993",
        cyan900: "#00407A",
        // Purple
        purple200: "#F3E5FF",
        purple500: "#8C75FF",
        // Fuscia
        fuscia50: "#FFECED",
        fuscia100: "#FFDADB",
        fuscia200: "#FFB5BF",
        fuscia300: "#FF90AA",
        fuscia400: "#FF75A3",
        fuscia500: "#FF4797",
        fuscia600: "#DB338D",
        fuscia700: "#B72381",
        fuscia800: "#931672",
        fuscia900: "#7A0D68",
    },
    fonts: {
        extraSmall: "normal normal 400 10px/12px Lato, Arial, sans-serif",
        small: "normal normal 400 12px/14px Lato, Arial, sans-serif",
        smallItalic: "italic normal 400 12px/14px Lato, Arial, sans-serif",
        smallBold: "normal normal 600 12px/16px Lato, Arial, sans-serif",
        medium: "normal normal 400 14px/20px Lato, Arial, sans-serif",
        mediumItalic: "italic normal 400 14px/24px Lato, Arial, sans-serif",
        mediumBold: "normal normal 600 14px/24px Lato, Arial, sans-serif",
        normal: "normal normal 400 16px/24px Lato, Arial, sans-serif",
        large: "normal normal 600 24px/29px Lato, Arial, sans-serif",
        largeBold: "normal normal 700 24px/40px Lato, Arial, sans-serif",
        header: "normal normal 400 20px/28px Lato, Arial, sans-serif",
        headerBold: "normal normal 700 20px/28px Lato, Arial, sans-serif",
        bold: "normal normal 700 16px/24px Lato, Arial, sans-serif",
        option: "normal normal 400 14px/20px Inter, Lato, Arial, sans-serif",
    },
    patterns: {
        polkaDot: css `
      background-image: radial-gradient(#e5e5e5 15%, transparent 15%);
      background-size: 20px 20px;
      background-color: #fafafa;
    `,
    },
    transitions: {
        layoutShift: "0.2s ease-in-out",
    },
    animations: {
        fadeOut: keyframes `
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    `,
        fadeIn: keyframes `
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    `,
        bounce: keyframes `
    0% {
      transform: scale(0.3) translate3d(0, 0, 0);
    }
    50% {
      transform: scale(1.3);
    }
    80% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1) translate3d(0, 0, 0);
    }
    `,
    },
    builder: {
        hoveredToolStyle: {
            outline: `2px solid #99D5FF`,
        },
        selectedToolStyle: {
            outline: `2px solid #33A9FF`,
        },
        resizableStyle: {
            boxShadow: "0 0 0 1px white",
            outline: "1px dashed black",
            outlineWidth: "thin",
        },
    },
    shadows: {
        outside80: "0px 8px 16px rgba(48, 49, 51, 0.1), 0px 0px 1px rgba(48, 49, 51, 0.05)",
        outside60: "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1)",
        inside60: "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1), inset 0px 0px 2px rgba(48, 49, 51, 0.5)",
        demoCenter: "0px 0px 1px 0px rgba(33, 34, 41, 0.4) inset, 2px 2px 8px 0px rgba(33, 34, 41, 0.02), 0px 0px 20px 0px rgba(48, 49, 51, 0.05);",
        editPanel: css `
      filter: drop-shadow(0px 0px 1px rgba(48, 49, 51, 0.05)) drop-shadow(0px 8px 16px rgba(48, 49, 51, 0.1));
    `,
        textInputSelect: "0 0 4px #3F86FF",
        dcTourViewport: css `
      box-shadow: 0px 0px 1px 0px rgba(33, 34, 41, 0.5) inset;
      filter: drop-shadow(1px 1px 12px rgba(33, 34, 41, 0.12)) drop-shadow(-2px -2px 32px rgba(48, 49, 51, 0.02));
    `,
    },
};
