import { useEffect, useState } from "react";
import { ViewportMode } from "../types/definitions";
import { useActiveVariantName, useIsEmbedHeaderEnabled, useIsMobileVariantEnabled, useSetActiveVariant, useStoryboardHeight, useViewportMode, useViewportPresetAsNumber, } from "../redux/selectors/redux-selectors";
import { viewportAspectRatio } from "../types/viewport-aspect-ratio";
import { ActiveVariantName } from "../../../shared/types/tool-data-types/common";
import { useTourialVersion } from "../redux/selectors/live-selectors";
import { isEmbedded } from "../helpers/live";
export const useRefDimensions = (myRef) => {
    const [width, setWidth] = useState(0);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const activeVariantName = useActiveVariantName(); // 'variantDesktop' or 'variantMobile'
    const mode = useViewportMode();
    const setActiveVariant = useSetActiveVariant();
    const tourialVersion = useTourialVersion();
    const storyboardHeight = useStoryboardHeight();
    const viewportPreset = useViewportPresetAsNumber();
    const hasMobile = useIsMobileVariantEnabled();
    const hasNavbar = useIsEmbedHeaderEnabled();
    useEffect(() => {
        const handleResize = () => {
            // Dimensions for desktop and mobile.
            const browserWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            if (mode !== ViewportMode.LIVE && tourialVersion === 3) {
                const width = getInnerViewportWidth(browserWidth, activeVariantName, mode, storyboardHeight, viewportPreset, hasNavbar);
                setWidth(width);
                const availableWidth = browserWidth - 96 - 40 - 320;
                const centerX = 40 + 48 + availableWidth / 2;
                const x = centerX - Math.min(activeVariantName !== ActiveVariantName.VARIANT_MOBILE ? viewportPreset : 400, width) / 2;
                setX(x);
                setY(76); // static
            }
            else {
                // legacy
                const myRefRect = myRef.current.getBoundingClientRect();
                setWidth(myRef.current.offsetWidth);
                setY(myRefRect.y);
                setX(myRefRect.x);
            }
            if (isEmbedded() && !hasMobile) {
                setActiveVariant(ActiveVariantName.VARIANT_DESKTOP);
            }
            else if (mode === ViewportMode.LIVE) {
                if (browserWidth <= 600) {
                    setActiveVariant(ActiveVariantName.VARIANT_MOBILE);
                }
                else {
                    setActiveVariant(ActiveVariantName.VARIANT_DESKTOP);
                }
            }
        };
        handleResize(); // Set initial size
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [myRef, activeVariantName, storyboardHeight, viewportPreset]);
    return {
        width,
        height: width *
            (viewportAspectRatio[activeVariantName === ActiveVariantName.VARIANT_DESKTOP ? "desktop" : "mobile"].height /
                viewportAspectRatio[activeVariantName === ActiveVariantName.VARIANT_DESKTOP ? "desktop" : "mobile"].width),
        x,
        y,
    };
};
export const useMicrositePreviewRefDimensions = (myRef) => {
    const [width, setWidth] = useState(0);
    const activeVariantName = useActiveVariantName(); // 'variantDesktop' or 'variantMobile'
    const setActiveVariant = useSetActiveVariant();
    const hasMobile = useIsMobileVariantEnabled();
    useEffect(() => {
        const handleResize = () => {
            // Dimensions for desktop and mobile.
            setWidth(myRef.current.offsetWidth);
            if (myRef.current.offsetWidth <= 350 && hasMobile) {
                setActiveVariant(ActiveVariantName.VARIANT_MOBILE);
            }
            else {
                setActiveVariant(ActiveVariantName.VARIANT_DESKTOP);
            }
        };
        handleResize(); // Set initial size
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [myRef]);
    return {
        width,
        height: width *
            (viewportAspectRatio[activeVariantName === ActiveVariantName.VARIANT_DESKTOP ? "desktop" : "mobile"].height /
                viewportAspectRatio[activeVariantName === ActiveVariantName.VARIANT_DESKTOP ? "desktop" : "mobile"].width),
        x: 0,
        y: 0,
    };
};
function getInnerViewportWidth(browserWidth, activeVariantName, viewportMode, storyboardHeight, viewportPreset, navbar) {
    let availableHeight = 0, availableWidth = 0;
    const browserHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    if (viewportMode === ViewportMode.EDIT) {
        // net minPadding, sidebar, toolbar
        availableWidth = browserWidth - 96 - 40 - 320;
        // net headerNav, minPadding, storyboard w/stepPanel
        availableHeight = browserHeight - storyboardHeight - 40 - 96;
    }
    else {
        availableWidth = browserWidth - 96;
        availableHeight = browserHeight - 96 - 60 - (navbar ? 50 : 0);
    }
    if (activeVariantName !== ActiveVariantName.VARIANT_MOBILE) {
        const expand = availableHeight / availableWidth < 9 / 16 ? "height" : "width";
        return Math.min(expand === "height" ? (16 * availableHeight) / 9 : availableWidth, viewportPreset);
    }
    else {
        const expand = availableHeight / availableWidth < 16 / 9 ? "height" : "width";
        return Math.min(expand === "height" ? (9 * availableHeight) / 16 : availableWidth, 400);
    }
}
export const useWindowSize = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return { width, height };
};
export const useRefSize = (myRef, storyboardHeight) => {
    var _a, _b;
    const [width, setWidth] = useState(((_a = myRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 0);
    const [height, setHeight] = useState(((_b = myRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight) || 0);
    useEffect(() => {
        const handleWindowResize = () => {
            var _a, _b;
            setWidth(((_a = myRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 0);
            setHeight(((_b = myRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight) || 0);
        };
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [myRef, storyboardHeight]);
    return { width, height };
};
