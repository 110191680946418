import React from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
export const DCWizardModulePicker = ({ modules, selectedModules, onModuleSelect }) => {
    const isSelected = (module) => !!selectedModules.find(m => (module === null || module === void 0 ? void 0 : module.title) === m.title);
    return (React.createElement(ModulePicker, null, modules.map(module => {
        const name = module.title || "+ Add Your Own";
        return (React.createElement(Module, { key: name, className: isSelected(module) ? "selected" : "", onClick: () => onModuleSelect(module) }, name));
    })));
};
const ModulePicker = styled.div `
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px 8px;
  margin-top: 32px;
`;
const Module = styled.div `
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  border: 1px solid ${newTheme.colors.blue25};
  background-color: ${newTheme.colors.blue25};
  color: ${newTheme.colors.blue500};
  font: ${newTheme.fonts.medium};
  cursor: pointer;
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  &:hover {
    background-color: ${newTheme.colors.blue50};
    border-color: ${newTheme.colors.blue50};
  }
  &.selected {
    color: ${newTheme.colors.white};
    background-color: ${newTheme.colors.blue500};
    border-color: ${newTheme.colors.blue500};
  }
`;
