import React from "react";
import { getBorderStylesFromProps } from "../../../helpers/misc";
import { ViewportMode } from "../../../types/definitions";
import { useEditingTextToolId, useViewportMode } from "../../../redux/selectors/redux-selectors";
import { EditableTextarea } from "./editable-text-area-view";
import { withToolWrapper } from "../with-tool-wrapper";
function TourialButton(props) {
    const viewportMode = useViewportMode();
    const isToolEditingText = useEditingTextToolId(props.toolData.id);
    function getCursorType() {
        if (isToolEditingText)
            return "text";
        if (viewportMode === ViewportMode.LIVE || viewportMode === ViewportMode.PREVIEW)
            return "pointer";
        return "move";
    }
    const { backgroundColor, animation, animationDuration, enableBackground } = props.toolData;
    return (React.createElement("button", { className: "reset-children-style _test_button-viewtool", style: Object.assign(Object.assign({ width: `100%`, height: `100%`, position: "absolute", cursor: getCursorType() }, getBorderStylesFromProps(props)), { backgroundColor: enableBackground ? backgroundColor : null, animation: viewportMode !== ViewportMode.EDIT && animation, animationDuration: viewportMode !== ViewportMode.EDIT && animationDuration }), id: props.toolData.id },
        React.createElement(EditableTextarea, { toolId: props.toolData.id, isButton: true, viewportDimensions: props.viewportDimensions })));
}
export const Button = React.memo(withToolWrapper(TourialButton));
