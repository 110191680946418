import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactPortal from "../../helpers/react-portal";
import { useRemoveToast, useToasts } from "../../legacy/selectors";
import { autoCloseInMs, toastWidth, PAGE_HEADER_Z_INDEX } from "../../types/definitions";
import { newTheme } from "../styled/theme";
export default function ToastPortal() {
    const toasts = useToasts();
    const removeToast = useRemoveToast();
    const [removingId, setRemovingId] = useState("");
    useEffect(() => {
        if (toasts.length) {
            const toastId = toasts[0].id;
            setTimeout(() => setRemovingId(toastId), autoCloseInMs);
        }
    }, [toasts === null || toasts === void 0 ? void 0 : toasts.length]);
    useEffect(() => {
        if (removingId) {
            removeToast(removingId);
            setRemovingId("");
        }
    }, [removingId]);
    if (!toasts.length)
        return null;
    return (React.createElement(ReactPortal, { wrapperId: "tourial-portal-toast" },
        React.createElement(ToastContainer, { mode: toasts[0].mode },
            React.createElement(ToastMessage, { className: "text-ellipsis" }, toasts[0].message),
            React.createElement(ToastCloseButton, { mode: toasts[0].mode, onClick: () => removeToast(toasts[0].id) }, "\u2715"))));
}
const ToastBackgroundColors = {
    INFO: newTheme.colors.blue200,
    SUCCESS: newTheme.colors.green300,
    WARNING: newTheme.colors.yellow400,
    ERROR: newTheme.colors.red300,
};
const ToastTextColors = {
    INFO: newTheme.colors.blue900,
    SUCCESS: newTheme.colors.green900,
    WARNING: newTheme.colors.yellow900,
    ERROR: newTheme.colors.red900,
};
const ToastContainer = styled.div `
  font: ${newTheme.fonts.medium};
  position: fixed;
  left: calc(50% - ${toastWidth / 2}px);
  top: 8px;
  width: ${toastWidth}px;
  height: 50px;
  box-shadow: ${newTheme.shadows.outside60};
  background-color: ${props => ToastBackgroundColors[props.mode]};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${PAGE_HEADER_Z_INDEX + 100};
`;
const ToastMessage = styled.div `
  margin: 0px 20px;
`;
const ToastCloseButton = styled.button `
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0px;
  right: 0px;
  color: ${props => ToastTextColors[props.mode]};
  cursor: pointer;
`;
