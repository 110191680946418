import React from "react";
import styled from "styled-components";
import { DCIcon } from "../../components/dc-icon";
import { DCIconType } from "../../../../../../../shared/types/demo-center";
import { newTheme } from "../../../../styled/theme";
import { DCCMEditableText } from "./dc-cm-editable-text";
import { useDCBuilderIsCollapsed } from "../../../../../redux/selectors/demo-center-selectors";
export const DCCMSection = ({ children, icon, title, subtitle, description, toolbar, onTitleChange, onTitleCommit, onDelete, titlePlaceholder, isEditingTitle, noTitle, }) => {
    const isCollapsed = useDCBuilderIsCollapsed();
    return (React.createElement(SectionContainer, null,
        React.createElement(Margin, { className: isCollapsed ? "collapsed" : null },
            React.createElement(IconBox, null,
                React.createElement(DCIcon, { type: icon }))),
        React.createElement(Content, null,
            !noTitle && (React.createElement(SectionHeader, null,
                React.createElement(TitleContainer, null,
                    React.createElement(DCCMEditableText, { value: title, onChange: onTitleChange, disabled: !onTitleChange, onCommit: onTitleCommit, placeholder: titlePlaceholder, style: {
                            fontFamily: "Lato, sans-serif",
                            fontSize: "20px",
                            lineHeight: "30px",
                            color: newTheme.colors.black,
                        }, selected: isEditingTitle }),
                    subtitle && React.createElement(Subtitle, null, subtitle),
                    toolbar,
                    !!onDelete && (React.createElement(IconButton, { onClick: onDelete },
                        React.createElement(DCIcon, { type: DCIconType.TRASH })))),
                description && React.createElement(Description, null, description))),
            children)));
};
export const DCCMSectionSpacer = () => {
    const isCollapsed = useDCBuilderIsCollapsed();
    return React.createElement(Spacer, { className: isCollapsed ? "collapsed" : null });
};
const Spacer = styled.div `
  height: 0px;
  width: 513px;
  margin-left: 174px;
  border-bottom: 1px solid ${newTheme.colors.grey400};
  &.collapsed {
    margin-left: 72px;
  }
`;
const SectionContainer = styled.div `
  display: flex;
  align-items: top;
  gap: 24px;
  flex-direction: row;
`;
const IconBox = styled.div `
  position: absolute;
  display: flex;
  top: 5px;
  right: 0;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
  }
`;
const Margin = styled.div `
  position: relative;
  width: 150px;
  &.collapsed {
    width: 48px;
  }
`;
const Content = styled.div `
  width: 513px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const SectionHeader = styled.div `
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 8px;
`;
const TitleContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Subtitle = styled.div `
  font-family: "Lato", sans-serif;
  font-size: 20px;
  color: ${newTheme.colors.grey500};
  border-left: 1px solid ${newTheme.colors.grey300};
  padding-left: 8px;
`;
const Description = styled.div `
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: ${newTheme.colors.black};
`;
const IconButton = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: ${newTheme.colors.red500};
  border-radius: 4px;
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background-color: ${newTheme.colors.grey300};
  }
  transition: color 0.2s ease-in-out;
`;
