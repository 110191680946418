import React, { useEffect } from "react";
import { DCModuleType, DCView } from "../../../../../../shared/types/demo-center";
import { DCItemView } from "../live/dc-item-view";
import styled from "styled-components";
import { DCWelcome } from "../live/dc-welcome";
import { useDCConfig, useDCSidebar, useDCTheme } from "../hooks/dc-hooks";
import { DCBodyWrapper } from "../live/dc-body-wrapper";
import { DCHeader } from "../live/dc-header";
import { useRefSize } from "../../../../hooks/viewport-hooks";
import { newTheme } from "../../../styled/theme";
import { useDCActivateModule, useDCConfirmTrackSelectionsForModule, useDCModules } from "../hooks/dc-module-hooks";
import { DCGallery } from "../components/dc-gallery";
import { useDCGetItemsForAllModuleTracks } from "../hooks/dc-item-hooks";
import { DCModuleViewContainer } from "../live/dc-module-view";
import { DCTrackSelector } from "../live/dc-track-selector";
export const DCPreview = ({ view, itemId, module, scaledDown: scaled }) => {
    const previewRef = React.useRef(null);
    const { width } = useRefSize(previewRef);
    useEffect(() => {
        window.dispatchEvent(new Event("resize"));
    }, [width]);
    return (React.createElement(ResizeWrapper, { width: width, className: scaled ? "scaled-down" : null },
        React.createElement(PreviewContainer, { ref: previewRef, width: width },
            React.createElement(DCBodyWrapper, null,
                React.createElement(DCHeader, { showBrowseButton: view === DCView.MODULE, showDemoButton: view === DCView.GALLERY_VIEW, transparent: view !== DCView.GALLERY_VIEW }),
                React.createElement(RenderDCView, { view: view, module: module, id: itemId })))));
};
const RenderDCView = ({ view, id, module }) => {
    switch (view) {
        case DCView.WELCOME:
            return React.createElement(DCWelcome, { browseAction: () => { }, setShouldShowDemoButton: () => { } });
        case DCView.ITEM:
            return id ? React.createElement(DCItemView, { id: id }) : null;
        case DCView.GALLERY_VIEW:
            return React.createElement(GalleryView, null);
        case DCView.MODULE:
            return React.createElement(ModuleView, { module: module });
        default:
            return null;
    }
};
const GalleryView = () => {
    const modifiedConfig = useDCConfig();
    const modules = useDCModules();
    const theme = useDCTheme();
    const getItemsForAllModuleTracks = useDCGetItemsForAllModuleTracks();
    return (React.createElement(DCGallery, { theme: theme, modules: modules, config: modifiedConfig, getItemsForModule: getItemsForAllModuleTracks, style: { paddingTop: "24px" } }));
};
const ModuleView = ({ module }) => {
    const { moduleDetails } = useDCConfig();
    const modules = useDCModules();
    const theme = useDCTheme();
    const firstModuleWithPrompt = modules.find(m => m.type !== DCModuleType.NO_PROMPT);
    const previewModule = module || firstModuleWithPrompt;
    const confirmTrackSelectionsForModule = useDCConfirmTrackSelectionsForModule();
    useDCActivateModule(previewModule === null || previewModule === void 0 ? void 0 : previewModule.slug);
    useEffect(() => {
        var _a;
        if (previewModule) {
            confirmTrackSelectionsForModule(previewModule.slug, [(_a = previewModule.tracks[0]) === null || _a === void 0 ? void 0 : _a.slug], null);
        }
    }, [previewModule]);
    useDCSidebar();
    return previewModule ? (React.createElement(ModulePreviewContainer, null,
        React.createElement(DCModuleViewContainer, { config: moduleDetails, theme: theme },
            React.createElement(DCTrackSelector, { module: previewModule, onSelectionConfirm: () => { }, onSkip: () => { } })))) : (React.createElement(ModulePreviewContainer, null));
};
const PreviewContainer = styled.div `
  position: relative;
  pointer-events: none;
  user-select: none;
  border: 1px solid ${newTheme.colors.grey300};
  box-shadow: ${newTheme.shadows.demoCenter};
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const ResizeWrapper = styled.div `
  &.scaled-down {
    height: calc((${p => p.width}px * (9 / 16)) * 0.25);
    ${PreviewContainer} {
      width: 400%;

      height: calc(${p => p.width}px * (9 / 16));
      transform: scale(25%) translate(-150%, -150%);
    }
  }
  overflow: hidden;
  height: 100%;
`;
const ModulePreviewContainer = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - 238px);
  margin: 0px 24px 24px 348px;
  position: relative;
`;
