import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { EditorAccordion } from "../../../../pages/builder/editor/editor-accordion";
import React from "react";
import styled from "styled-components";
import LogoIcon from "../../../../../../assets/images/v3/editor-accordian-logo-icon.svg";
import HeadlineIcon from "../../../../../../assets/images/v3/editor-accordian-headline-icon.svg";
import BodyIcon from "../../../../../../assets/images/v3/editor-accordian-body-icon.svg";
import { cloneDeep } from "lodash";
export function AutoTooltipConfigureSection(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    return (React.createElement(Section, null,
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: LogoIcon, title: "Logo", isEnabled: tool.bubble.logo.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.bubble.logo.isEnabled = !t.bubble.logo.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: HeadlineIcon, title: "Headline", isEnabled: tool.bubble.headline.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.bubble.headline.isEnabled = !t.bubble.headline.isEnabled;
                updateTool(t);
            } }),
        React.createElement(EditorAccordion, { isAlwaysOpen: true, icon: BodyIcon, title: "Body", isEnabled: tool.bubble.body.isEnabled, onToggle: () => {
                const t = cloneDeep(tool);
                t.bubble.body.isEnabled = !t.bubble.body.isEnabled;
                updateTool(t);
            } })));
}
const Section = styled.div `
  height: calc(100% - 31px);
  overflow: auto;
`;
