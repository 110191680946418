export var BrandingType;
(function (BrandingType) {
    BrandingType["TOOLTIP"] = "Tooltips";
    BrandingType["TYPEWRITER"] = "Typewriter";
    BrandingType["MOUSE"] = "Mouse";
    BrandingType["MODAL"] = "Modals";
    BrandingType["OVERLAY"] = "Overlay";
    BrandingType["NAV"] = "Nav & progress bar";
    BrandingType["PAGE"] = "Page styles";
    BrandingType["FORM"] = "Forms";
})(BrandingType || (BrandingType = {}));
export var ApplyThemeTargetType;
(function (ApplyThemeTargetType) {
    ApplyThemeTargetType["TOURS"] = "Tours";
    ApplyThemeTargetType["TOUR_PLAYERS"] = "TourPlayers";
    ApplyThemeTargetType["TOURS_AND_TOUR_PLAYERS"] = "Tours and TourPlayers";
    ApplyThemeTargetType["FORMS"] = "Forms";
})(ApplyThemeTargetType || (ApplyThemeTargetType = {}));
