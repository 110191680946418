import { useLocation } from "react-router-dom";
/**
 * Custom hook to get the value of a query parameter by its key
 *
 * @param {string} key - The key of the query parameter
 * @returns {string | null} The value of the query parameter or null if not found
 */
export function useQueryParam(key) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    return params.get(key);
}
export function usePathname() {
    const location = useLocation();
    return location.pathname;
}
