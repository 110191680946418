import React from "react";
import { getBorderStylesFromProps } from "../../../helpers/misc";
import { ViewportMode } from "../../../types/definitions";
import { useViewportMode } from "../../../redux/selectors/redux-selectors";
import { withToolWrapper } from "../with-tool-wrapper";
import { userShadows } from "../builder/tools/shape/editor";
function TourialShape(props) {
    const viewportMode = useViewportMode();
    const { backgroundColor, shadowStyle, enableBackground, enableShadow, animation, animationDuration } = props.toolData;
    return (React.createElement("div", { className: "_test_shape-viewtool", style: Object.assign(Object.assign({ width: `100%`, height: `100%`, position: "absolute", display: "inline" }, getBorderStylesFromProps(props)), { backgroundColor: enableBackground ? backgroundColor : null, boxShadow: enableShadow ? userShadows[shadowStyle] : null, animation: viewportMode !== ViewportMode.EDIT && animation, animationDuration: viewportMode !== ViewportMode.EDIT && animationDuration }), id: props.toolData.id }));
}
export const Shape = React.memo(withToolWrapper(TourialShape));
