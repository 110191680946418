import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHTGotoNextStep, useHTGotoPrevStep, useHTGotoStep, useHTMode, useHTStep, useHTStepIndex, useHTSteps, useHTTheme, } from "../../../../../redux/selectors/ht-selectors";
import { useHTFetchCaptureThumbnails, useHTViewportData } from "../../hooks/ht-hooks";
import { HTIconTypes, HTMode } from "../../../../../../../shared/types/dom-tour";
import { modifyHTModeForDCLivePreview } from "../../helpers/dom-tour-helpers";
import { PROGRESS_BAR_HEIGHT } from "../../config";
import { HTIcon } from "../atomic/ht-icon";
import { newTheme } from "../../../../styled/theme";
export function HTProgressBar() {
    var _a, _b;
    const theme = useHTTheme();
    const viewportData = useHTViewportData();
    const currMode = useHTMode();
    const mode = modifyHTModeForDCLivePreview(currMode);
    const steps = useHTSteps();
    const step = useHTStep();
    const stepIndex = useHTStepIndex();
    const goToNextStep = useHTGotoNextStep();
    const goToPrevStep = useHTGotoPrevStep();
    const goToStep = useHTGotoStep();
    const thumbnails = useHTFetchCaptureThumbnails();
    const [hoverIndex, setHoverIndex] = useState();
    const [visitedIndices, setVisitedIndice] = useState([]);
    useEffect(() => {
        setVisitedIndice(mode === HTMode.EDIT ? [] : [stepIndex]);
    }, [mode]);
    useEffect(() => {
        // this is here to prevent a react update on an unmounted component
        let isMounted = true;
        if (isMounted && mode !== HTMode.EDIT && !visitedIndices.includes(stepIndex))
            setVisitedIndice([...visitedIndices, stepIndex]);
        return () => {
            isMounted = false;
        };
    }, [stepIndex]);
    return (React.createElement(ProgressBarContainer, { theme: theme, viewportData: viewportData, mode: mode, stepLength: steps.length },
        React.createElement(HTIcon, { type: HTIconTypes.PROGRESS_LEFT_ARROW, fill: ((_a = theme.progressBar) === null || _a === void 0 ? void 0 : _a.arrowColor) || "#E4E5E9", onClick: goToPrevStep, style: { marginBottom: -5 } }),
        steps.map((s, i) => {
            var _a, _b, _c;
            return (React.createElement("div", { key: s.id, style: { flex: 1, position: "relative", height: "100%" } },
                React.createElement(Thumbnail, { isHovered: hoverIndex === i },
                    React.createElement(ThumbnailImg, { src: (_c = (_b = (_a = thumbnails.data) === null || _a === void 0 ? void 0 : _a.find) === null || _b === void 0 ? void 0 : _b.call(_a, t => t.id === s.captureId)) === null || _c === void 0 ? void 0 : _c.thumbnail }),
                    React.createElement(ThumbnailName, { theme: theme }, s.name)),
                React.createElement(ProgressBarPortion, { isVisited: visitedIndices.includes(i), onMouseEnter: () => setHoverIndex(i), onMouseLeave: () => setHoverIndex(undefined), id: `ht-progress-portion-${i}`, isSelected: s.id === step.id, theme: theme, onClick: () => goToStep(i) })));
        }),
        React.createElement(HTIcon, { type: HTIconTypes.PROGRESS_LEFT_ARROW, fill: ((_b = theme.progressBar) === null || _b === void 0 ? void 0 : _b.arrowColor) || "#E4E5E9", style: { transform: "rotate(180deg)", marginBottom: -5 }, onClick: goToNextStep })));
}
const ProgressBarContainer = styled.div `
  height: ${PROGRESS_BAR_HEIGHT}px;
  background: ${({ theme }) => theme.overlay.backgroundColor === "white"
    ? "linear-gradient(360deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%)"
    : "linear-gradient(360deg, rgba(0, 0, 0, 0.4) 0%, rgba(48, 49, 54, 0) 100%)"};
  position: absolute;
  top: ${({ viewportData, mode }) => viewportData.screenHeight - PROGRESS_BAR_HEIGHT + (mode === HTMode.LIVE ? 0 : viewportData.top) + 1}px;
  left: ${({ viewportData, mode }) => (mode === HTMode.LIVE ? 0 : viewportData.left)}px;
  width: ${({ viewportData }) => viewportData.screenWidth}px;
  display: flex;
  padding: 0px 64px 12px;
  align-items: end;
  justify-content: space-between;
  gap: 6px;
  #ht-progress-portion-0 {
    border-radius: 12px 0px 0px 12px;
  }
  #ht-progress-portion-${({ stepLength }) => stepLength - 1} {
    border-radius: 0px 12px 12px 0px;
  }
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  transition: opacity ${newTheme.transitions.layoutShift};
`;
const ProgressBarPortion = styled.div `
  cursor: pointer;
  height: ${({ isSelected }) => (isSelected ? 16 : 10)}px;
  &:hover {
    height: 16px;
  }
  transition: height ${newTheme.transitions.layoutShift};
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: ${({ theme, isVisited, isSelected }) => {
    var _a, _b;
    return isVisited || isSelected
        ? ((_a = theme.progressBar) === null || _a === void 0 ? void 0 : _a.progressColor) || "#0055FF"
        : ((_b = theme.progressBar) === null || _b === void 0 ? void 0 : _b.backgroundColor) || "#000000";
}};
`;
const Thumbnail = styled.div `
  pointer-events: none;
  width: 160px;
  height: 114px;
  position: absolute;
  top: -118px;
  left: calc(50% - 80px);
  display: flex;
  opacity: ${({ isHovered }) => (isHovered ? "1" : "0")};
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  transition: opacity ${newTheme.transitions.layoutShift};
  box-shadow:
    1px 1px 12px 0px rgba(33, 34, 41, 0.12),
    -2px -2px 32px 0px rgba(48, 49, 51, 0.02),
    0px 0px 1px 0px rgba(33, 34, 41, 0.5) inset;
`;
const ThumbnailImg = styled.img `
  width: 100%;
  height: 90px;
  object-fit: contain;
  background: #90939d;
`;
const ThumbnailName = styled.div `
  background-color: ${({ theme }) => theme.tooltip.backgroundColor};
  font: ${newTheme.fonts.normal};
  color: ${({ theme }) => theme.tooltip.body.color};
  font-family: ${({ theme }) => theme.tooltip.body.font};
  height: 24px;
  width: 100%;
  padding: 0px 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
