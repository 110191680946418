import { TextInput } from "../../pages/builder/editor/atomic/text-input";
import React, { useState } from "react";
import TButton, { TButtonVariants } from "../../styled/t-button";
import styled from "styled-components";
import { newTheme } from "../../styled/theme";
import { useTourialSlug, useUpdateTourialPartial } from "../../../redux/selectors/redux-selectors";
import { ToastModes } from "../../../types/definitions";
import { useAddToast } from "../../../legacy/selectors";
export function TourialSlugEditor({ baseUrlWithSubdomain }) {
    const addToast = useAddToast();
    const patchTourial = useUpdateTourialPartial();
    const [isEditing, setIsEditing] = useState(false);
    const tourialSlug = useTourialSlug();
    const [editSlug, setEditSlug] = useState(tourialSlug);
    const addEmbedCodeHasChangedToast = () => {
        addToast({
            message: "Your embed code has changed.",
            mode: ToastModes.SUCCESS,
        });
    };
    const liveLink = `${baseUrlWithSubdomain}pages/${tourialSlug}`;
    return (React.createElement(React.Fragment, null, isEditing ? (React.createElement(LinkContainer, { style: { marginTop: 8, gap: 12 } },
        React.createElement(EditContainer, null,
            React.createElement("span", null,
                baseUrlWithSubdomain,
                "pages/"),
            React.createElement(TextInput, { value: editSlug, autoFocus: true, onFocus: e => e.target.select(), onChange: (e) => {
                    const slug = e.target.value.replace(/[^a-zA-Z0-9\-]/g, "");
                    setEditSlug(slug);
                } })),
        React.createElement(ButtonsContainer, null,
            React.createElement(TButton, { variant: TButtonVariants.OUTLINE, size: "small", disabled: !editSlug.length, onClick: () => {
                    patchTourial({ slug: editSlug });
                    setIsEditing(false);
                    addEmbedCodeHasChangedToast();
                } }, "Save")))) : (React.createElement(LinkContainer, { id: "_test_publish-link-container", style: { marginTop: 8, gap: 12 } },
        React.createElement("a", { style: { width: "100%" }, href: liveLink, target: "_blank" }, liveLink),
        React.createElement(ButtonsContainer, null,
            React.createElement(TButton, { variant: TButtonVariants.OUTLINE, size: "small", onClick: () => {
                    setIsEditing(true);
                } }, "Edit slug"),
            React.createElement(TButton, { variant: TButtonVariants.OUTLINE, size: "small", onClick: () => {
                    var _a;
                    (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(liveLink).then(() => {
                        addToast({
                            message: "link copied",
                            mode: ToastModes.SUCCESS,
                        });
                    }).catch(() => console.error("failed to copy link"));
                } }, "Copy link"))))));
}
export const LinkContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 16px;
  gap: 16px;
  font: ${newTheme.fonts.medium};
  > a {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${newTheme.colors.blue500};
    background-color: ${newTheme.colors.blue100};
    border: 1px solid ${newTheme.colors.blue500};
    padding: 0px 4px;
    line-height: 32px;
    border-radius: 4px;
  }
  > img {
    margin-right: 8px;
  }
`;
export const ButtonsContainer = styled.div `
  display: flex;
  gap: 8px;
`;
export const EditContainer = styled.div `
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`;
