import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { BorderShape, ShirtSize, TextStyle } from "../../../../../../../shared/types/tool-data-types/common";
import { FormLayout } from "../../../../../../../shared/types/form";
import { bodyFontSizes, headlineFontSizes, textAlignments } from "../../../../../helpers/text-helpers";
export var FormTab;
(function (FormTab) {
    FormTab["MAIN"] = "Form";
    FormTab["CONFIRMATION"] = "Confirmation";
})(FormTab || (FormTab = {}));
export const FormWrapper = styled.div `
  padding: 24px;
  background-color: ${p => p.theme.backgroundColor};
  border: 1px solid ${p => p.theme.borderColor};
  box-shadow: ${p => (p.theme.shadow ? newTheme.shadows.outside80 : "none")};
  width: ${p => (p.layout === FormLayout.HORIZONTAL ? (p.twoColumn ? 702 : 456) : p.twoColumn ? 526 : 280)}px;
  height: ${p => (p.tab === FormTab.CONFIRMATION ? (p.layout === FormLayout.HORIZONTAL ? "302px" : "384px") : "auto")};
  border-radius: ${p => (p.theme.shape === BorderShape.ROUNDED ? "24px" : "0px")};
`;
export const InnerWrapper = styled.div `
  display: flex;
  padding-top: 8px;
  flex-direction: ${p => (p.layout === FormLayout.HORIZONTAL ? "row" : "column")};
`;
export const LogoContainer = styled.div `
  width: ${p => (p.layout === FormLayout.HORIZONTAL ? "152px" : "100%")};
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: ${p => (p.layout === FormLayout.VERTICAL ? "0px auto" : "")};
  margin-bottom: ${p => (p.layout === FormLayout.VERTICAL ? "24px" : "0")};
`;
export const FormContainer = styled.div `
  flex: 1;
  margin-left: ${p => (p.layout === FormLayout.HORIZONTAL ? "24px" : 0)};
`;
export const FormFieldContainer = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 16px;
  width: ${p => (p.twoColumn ? "476px" : "230px")};
`;
export const FormField = styled.div `
  width: 230px;
`;
export const LogoSizes = {
    [ShirtSize.SMALL]: "60px",
    [ShirtSize.MEDIUM]: "80px",
    [ShirtSize.LARGE]: "100px",
};
export const Logo = styled.div `
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${p => LogoSizes[p.size]};
  height: ${p => LogoSizes[p.size]};
  overflow: hidden;
  img {
    max-height: ${p => LogoSizes[p.size]};
    max-width: ${p => LogoSizes[p.size]};
  }
  border-radius: ${p => (p.shape === BorderShape.SQUIRCLE ? "100px" : p.shape === BorderShape.ROUNDED ? "8px" : "0")};
`;
export const Headline = styled.div `
  width: 100%;
  font-size: ${p => headlineFontSizes[p.size]}px;
  line-height: ${p => headlineFontSizes[p.size] * 1.5}px;
  text-align: ${p => textAlignments[p.align]};
  font-family: ${p => p.font};
  text-decoration: ${p => (p.textStyle === TextStyle.UNDERLINE ? "underline" : "none")};
  font-weight: ${p => (p.textStyle === TextStyle.BOLD ? "bold" : "normal")};
  font-style: ${p => (p.textStyle === TextStyle.ITALIC ? "italic" : "normal")};
  color: ${p => p.color};
`;
export const Description = styled.div `
  width: 100%;
  font-size: ${p => bodyFontSizes[p.size]}px;
  line-height: ${p => bodyFontSizes[p.size] * 1.5}px;
  text-align: ${p => textAlignments[p.align]};
  font-family: ${p => p.font};
  text-decoration: ${p => (p.textStyle === TextStyle.UNDERLINE ? "underline" : "none")};
  font-weight: ${p => (p.textStyle === TextStyle.BOLD ? "bold" : "normal")};
  font-style: ${p => (p.textStyle === TextStyle.ITALIC ? "italic" : "normal")};
  color: ${p => p.color};
`;
export const SubmitButtonContainer = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${p => textAlignments[p.align]};
  padding-top: 8px;
`;
export const SubmitButton = styled.button `
  cursor: pointer;
  padding: 8px 16px;
  font-size: ${p => bodyFontSizes[p.size]}px;
  line-height: 24px;
  font-family: ${p => p.font};
  text-decoration: ${p => (p.textStyle === TextStyle.UNDERLINE ? "underline" : "none")};
  font-weight: ${p => (p.textStyle === TextStyle.BOLD ? "bold" : "normal")};
  font-style: ${p => (p.textStyle === TextStyle.ITALIC ? "italic" : "normal")};
  color: ${p => p.color};
  background-color: ${p => p.theme.backgroundColor};
  border: 1px solid ${p => p.theme.borderColor};
  border-radius: ${p => p.theme.shape === BorderShape.SQUIRCLE ? "100px" : p.theme.shape === BorderShape.ROUNDED ? "8px" : "0"};
`;
