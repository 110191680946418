import React, { useState } from "react";
import CopyWhiteIcon from "../../../../../assets/images/copy-white.svg";
import MobileSelectedIcon from "../../../../../assets/images/mobile-selected.svg";
import { useIsMobileVariantEnabled, useMobileEmbedButton, useTourialSlug, useUpdateBuilder, useUpdateTourialPartial, useTourial, } from "../../../../redux/selectors/redux-selectors";
import TP from "../../styled/t-p";
import TA from "../../styled/t-a";
import TH5 from "../../styled/t-h5";
import TModal from "../../styled/t-modal";
import { CopyToClipboardButton } from "../copy-to-clipboard-button";
import { EnableMobileToggle } from "../enable-mobile-toggle";
import TLabel from "../../styled/t-label";
import TSelect from "../../styled/t-select";
import TNumberInput from "../../styled/t-number-input";
import { EmbedButtonColorPicker } from "../embed-button-color-picker";
import TToggle from "../../styled/t-toggle";
import TTextInput from "../../styled/t-text-input";
import { toTitleCase } from "../../../../helpers/misc";
import { HelperBubble } from "../helper-bubble";
import { useEffect } from "react";
import { NavModal } from "../../../../types/definitions";
import { getBaseUrlWithSubdomainLegacy, objectToArray } from "../../../../../../shared/functions/helpers";
import { fontFamilyOptions, googleFonts, theme, } from "../../../../../../shared/legacy/legacy-tool-data";
import { fontWeightOptions } from "../../../../types/tools";
import { useIsMobileButtonDisabled } from "../../../../redux/selectors/builder-selectors";
var FontFamily;
(function (FontFamily) {
    FontFamily["CenturyGothic"] = "Open Sans";
})(FontFamily || (FontFamily = {}));
export function EmbedModal() {
    const tourialSlug = useTourialSlug();
    const tourial = useTourial();
    const baseUrlWithSubdomain = getBaseUrlWithSubdomainLegacy(tourial.subdomain, tourial.whiteLabelDomain);
    const updateBuilder = useUpdateBuilder();
    const isMobileVariantEnabled = useIsMobileVariantEnabled();
    return (React.createElement(TModal, { isVisible: true, onClose: () => updateBuilder({ activeNavModal: NavModal.NULL }) },
        React.createElement(EmbedModalContents, { baseUrlWithSubdomain: baseUrlWithSubdomain, tourialSlug: tourialSlug, isMobileVariantEnabled: isMobileVariantEnabled })));
}
function EmbedModalContents({ baseUrlWithSubdomain, tourialSlug, isMobileVariantEnabled }) {
    // const [isHelpHovered, setIsHelpHovered] = useState(false);
    const [wasCopied, setWasCopied] = useState(false);
    const [isLightboxEnabled, setIsLightboxEnabled] = useState(false);
    const mobileEmbedButton = useMobileEmbedButton();
    const isMobileButtonDisabled = useIsMobileButtonDisabled();
    const baseTextStyle = Object.assign(Object.assign({}, theme.typography.h5), { fontFamily: "Lato", fontSize: 13, color: theme.colors.grey.darkest, textTransform: "none", width: "100%", margin: "10px 0px" });
    const [maxHeight, setMaxHeight] = useState((window === null || window === void 0 ? void 0 : window.innerHeight) - 64);
    const updateMaxHeight = () => setMaxHeight((window === null || window === void 0 ? void 0 : window.innerHeight) - 64);
    useEffect(() => {
        window.addEventListener("resize", updateMaxHeight);
        return () => window.removeEventListener("resize", updateMaxHeight);
    }, []);
    return (React.createElement("div", { className: "hide-scrollbar", style: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            height: "calc(100% - 24px)",
            maxHeight: maxHeight || "none",
            minHeight: isLightboxEnabled ? (isMobileVariantEnabled ? 674 : 510) : "auto",
            overflowX: "scroll",
            padding: "20px 40px 20px 40px",
        } },
        React.createElement("div", { style: { width: "100%" } },
            React.createElement(TH5, { style: {
                    fontWeight: "normal",
                    color: theme.colors.grey.darkest,
                    marginBottom: 10,
                } }, "Add Tourial to Website"),
            React.createElement(TSelect, { value: JSON.stringify(typeof isLightboxEnabled === "boolean" ? isLightboxEnabled : false), style: { marginLeft: 0, width: 185 }, onChange: () => {
                    setIsLightboxEnabled(!isLightboxEnabled);
                } },
                React.createElement("option", { value: "false" }, "Embed in Page"),
                React.createElement("option", { value: "true" }, "Tourial Lightbox"))),
        isLightboxEnabled && (React.createElement(React.Fragment, null,
            React.createElement(TP, { style: baseTextStyle }, "Add the class name below on the HTML tag that will trigger the lightbox."),
            React.createElement("div", { style: {
                    overflow: "scroll",
                    height: 40,
                    width: "100%",
                    border: `1px solid ${theme.colors.grey.medium}`,
                    borderRadius: 4,
                    padding: 5,
                    backgroundColor: theme.colors.grey.lightest,
                    flexShrink: 0,
                } },
                React.createElement("code", { style: {
                        fontSize: 10,
                        lineHeight: "0.8em",
                    } },
                    "tourial-lightbox-trigger-",
                    tourialSlug),
                wasCopied && React.createElement(HelperBubble, { left: 567, top: 148, text: "Copied!" }),
                React.createElement("div", { style: {
                        width: 25,
                        height: 25,
                        backgroundColor: theme.colors.cobalt.medium,
                        position: "absolute",
                        left: 526,
                        top: 159,
                        borderRadius: 4,
                    } },
                    React.createElement("img", { src: CopyWhiteIcon, style: {
                            cursor: "pointer",
                            width: "100%",
                            padding: 4,
                        }, onClick: () => {
                            var _a;
                            (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(`tourial-lightbox-trigger-${tourialSlug}`).then(function () {
                                setWasCopied(true);
                                setTimeout(() => setWasCopied(false), 2000);
                            }, function (err) {
                                console.error("Failed to copy text to clipboard: ", err);
                            });
                        } }))))),
        React.createElement(TP, { style: baseTextStyle }, isLightboxEnabled
            ? "Copy and paste the code below at the end of the body of your HTML document."
            : "Copy and paste the code below into your webpage where you want the Tourial to display."),
        React.createElement("div", { style: {
                overflow: "scroll",
                height: 80,
                width: "100%",
                border: `1px solid ${theme.colors.grey.medium}`,
                borderRadius: 4,
                padding: 5,
                backgroundColor: theme.colors.grey.lightest,
                flexShrink: 0,
            } },
            React.createElement("code", { style: {
                    fontSize: 10,
                    lineHeight: "0.8em",
                } }, makeEmbedCode(baseUrlWithSubdomain, tourialSlug, isMobileVariantEnabled, isLightboxEnabled, mobileEmbedButton, isMobileButtonDisabled))),
        React.createElement("div", { style: {
                width: "100%",
            } },
            React.createElement(EnableMobileToggle, null)),
        isMobileVariantEnabled && (React.createElement(React.Fragment, null,
            React.createElement(TP, { style: Object.assign(Object.assign({}, baseTextStyle), { fontWeight: "bold" }) }, "Selecting Mobile Tourial Enabled will NOT automatically generate a Mobile version of your Desktop Tourial."),
            React.createElement(TP, { style: Object.assign(Object.assign({}, baseTextStyle), { margin: 0 }) },
                "To maximize engagement across devices, we recommend that you first click",
                " ",
                React.createElement("img", { style: {
                        verticalAlign: "middle",
                        marginTop: -10,
                        marginBottom: -10,
                    }, src: MobileSelectedIcon }),
                " ",
                "on the upper panel and create your Mobile Tourial from scratch."),
            React.createElement(TP, { style: Object.assign(Object.assign({}, baseTextStyle), { margin: 0 }) }, "If you select Display Desktop Version Only, the desktop version will display regardless of device."))),
        !isLightboxEnabled && React.createElement(MobileEmbedButtonCustomizationEditor, null),
        React.createElement(CopyToClipboardButton, { textToCopy: makeEmbedCode(baseUrlWithSubdomain, tourialSlug, isMobileVariantEnabled, isLightboxEnabled, mobileEmbedButton, isMobileButtonDisabled), buttonText: "Copy Embed Code", isLightboxEnabled: isLightboxEnabled }),
        React.createElement("div", { style: {
                width: "100%",
                marginTop: 20,
                marginBottom: 10,
                borderBottom: `1px solid ${theme.colors.grey.medium}`,
            } }),
        React.createElement(TA, { href: "https://tourial.gitbook.io/tourial-customer-product-documentation/sharing/embeds", target: "_blank", style: { color: theme.colors.grey.dark } }, "View support article")));
}
export function makeEmbedCode(baseUrlWithSubdomain, tourialSlug, isMobileVariantEnabled, isLightboxEnabled, mobileEmbedButton, isEmbedHeaderEnabled, isMobileButtonDisabled) {
    function getFontLinks(fontFamily) {
        if (googleFonts.includes(fontFamily)) {
            return `<link
      href="https://fonts.googleapis.com/css2?family=${fontFamily}:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
      rel="stylesheet"/>`;
        }
        return "";
    }
    const styledButton = mobileEmbedButton || getDefaultMobileButton();
    const embedScriptSrc = `${baseUrlWithSubdomain}embed.js`;
    const lightboxScriptSrc = `${baseUrlWithSubdomain}lightbox.js`;
    const mobileButton = `<a href="${baseUrlWithSubdomain}${tourialSlug}" target="_blank">
  <button id="tourial-mobile-button" style="font-family: ${styledButton.fontFamily}; font-weight: ${styledButton.fontWeight}; font-size: ${styledButton.fontSize}px; color: ${styledButton.fontColor}; background-color: ${styledButton.enableBackground ? styledButton.backgroundColor : null}; border-style: ${styledButton.enableBorder ? "solid" : null}; border-radius: ${styledButton.borderRadius}px; border-width: ${styledButton.borderWidth}px; border-color: ${styledButton.borderColor}; width: ${styledButton.width}px; height: ${styledButton.height}px; box-shadow: ${userShadows[styledButton.shadowType]}; padding: 0; margin: 0; cursor: pointer;">${styledButton.text}</button>
  </a>`;
    if (isLightboxEnabled) {
        return `
      <script 
        type="text/javascript" 
        src="${lightboxScriptSrc}" 
        tourialSlug="${tourialSlug}"
        isMobileVariantEnabled="${isMobileVariantEnabled}"
        baseUrlWithSubdomain="${baseUrlWithSubdomain}" 
        viewportHeader="${isEmbedHeaderEnabled}" 
        id="tourial-lightbox-script"
      ></script>
    `;
    }
    return `
      <script type="text/javascript" src="${embedScriptSrc}" tourialSlug="${tourialSlug}" ${isMobileVariantEnabled ? ` hasMobile="true"` : ""} ${isEmbedHeaderEnabled ? ` viewportHeader="true"` : ""}></script>
      ${getFontLinks(styledButton.fontFamily)}
      <div
        style="position: relative; width: 100%; padding: 0; padding-bottom: 56.25%"
        id="tourial-${tourialSlug}"
      >
        <div id="tourial-mobile-button-container" style="justify-content: center; align-items: center; height: 100%; width: 100%; display: none; padding: 0; margin: 0;">
          ${isMobileVariantEnabled && !isMobileButtonDisabled ? mobileButton : ""}
        </div>
        <iframe
          allowfullscreen
          style="display: block; position: absolute; min-height: 100%; min-width: 100%; padding: 0; margin: 0;"
          frameborder="0"
          scrolling="no"
          id="tourial-desktop-iframe"
        ></iframe>
          <script>
        document.getElementById('tourial-${tourialSlug}').getElementsByTagName('iframe')[0].src = \`${baseUrlWithSubdomain}${tourialSlug}?\$\{window.location.search.slice(1)\}&isEmbedded=true${isEmbedHeaderEnabled ? "&viewportHeader=true" : ""}\`
      </script>
      </div>
      `;
}
var MobileEmbedEditorTabs;
(function (MobileEmbedEditorTabs) {
    MobileEmbedEditorTabs[MobileEmbedEditorTabs["FONT"] = 0] = "FONT";
    MobileEmbedEditorTabs[MobileEmbedEditorTabs["STYLE"] = 1] = "STYLE";
    MobileEmbedEditorTabs[MobileEmbedEditorTabs["SIZE"] = 2] = "SIZE";
})(MobileEmbedEditorTabs || (MobileEmbedEditorTabs = {}));
function MobileEmbedButtonCustomizationEditor() {
    const updateTourial = useUpdateTourialPartial();
    const button = useMobileEmbedButton() || getDefaultMobileButton();
    const setButton = (button) => {
        updateTourial({ mobileEmbedButton: button });
    };
    const isMobileVariantEnabled = useIsMobileVariantEnabled();
    const [activeTab, setActiveTab] = useState(MobileEmbedEditorTabs.FONT);
    const activeTabStyle = {
        color: theme.colors.cobalt.dark,
        cursor: "pointer",
        fontWeight: "bold",
        padding: 5,
    };
    const inactiveTabStyle = Object.assign(Object.assign({}, activeTabStyle), { color: theme.colors.grey.dark });
    if (!isMobileVariantEnabled)
        return React.createElement("div", null);
    return (React.createElement("div", { style: { width: "100%" } },
        React.createElement(TP, { style: Object.assign(Object.assign({}, theme.typography.h5), { fontSize: 15, color: theme.colors.grey.darkest, textTransform: "uppercase", textAlign: "center" }) }, "BUTTON CUSTOMIZATION"),
        React.createElement("div", { style: { display: "flex" } },
            React.createElement("button", { style: activeTab === MobileEmbedEditorTabs.FONT ? activeTabStyle : inactiveTabStyle, onClick: () => setActiveTab(MobileEmbedEditorTabs.FONT) }, "Font"),
            React.createElement("button", { style: activeTab === MobileEmbedEditorTabs.STYLE ? activeTabStyle : inactiveTabStyle, onClick: () => setActiveTab(MobileEmbedEditorTabs.STYLE) }, "Style"),
            React.createElement("button", { style: activeTab === MobileEmbedEditorTabs.SIZE ? activeTabStyle : inactiveTabStyle, onClick: () => setActiveTab(MobileEmbedEditorTabs.SIZE) }, "Size")),
        React.createElement("div", { style: { display: "flex" } },
            React.createElement("div", { style: { width: 200, height: 200 } },
                activeTab === MobileEmbedEditorTabs.FONT && React.createElement(FontTab, { button: button, setButton: setButton }),
                activeTab === MobileEmbedEditorTabs.STYLE && React.createElement(StyleTab, { button: button, setButton: setButton }),
                activeTab === MobileEmbedEditorTabs.SIZE && React.createElement(SizeTab, { button: button, setButton: setButton })),
            React.createElement(MobileEmbedButtonPreview, { button: button }))));
}
function MobileEmbedButtonPreview({ button }) {
    return (React.createElement("div", { style: {
            backgroundColor: theme.colors.grey.medium,
            width: "100%",
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
        } },
        React.createElement(MobileEmbedButtonComponent, { button: button })));
}
function MobileEmbedButtonComponent({ button }) {
    return (React.createElement("button", { style: {
            fontFamily: button.fontFamily,
            fontWeight: button.fontWeight,
            fontSize: button.fontSize + "px",
            color: button.fontColor,
            backgroundColor: button.enableBackground ? button.backgroundColor : null,
            borderStyle: "solid",
            borderWidth: button.enableBorder ? button.borderWidth + "px" : null,
            borderColor: button.enableBorder ? button.borderColor : null,
            borderRadius: button.borderRadius + "px",
            boxShadow: userShadows[button.shadowType],
            width: button.width + "px",
            height: button.height + "px",
        } }, button.text));
}
function FontTab({ button, setButton }) {
    return (React.createElement("div", { style: { paddingRight: 20, width: 200 } },
        React.createElement(TLabel, { style: { width: "100%" } },
            "Text",
            React.createElement(TTextInput, { style: {
                    fontSize: 12,
                    width: 120,
                    margin: 0,
                    padding: 5,
                    borderColor: theme.colors.sky.light,
                    boxShadow: theme.shadows.shallowest,
                }, value: button.text, onChange: e => {
                    const newButton = Object.assign(Object.assign({}, button), { text: e.target.value });
                    setButton(newButton);
                }, onBlur: e => {
                    const newButton = Object.assign(Object.assign({}, button), { text: e.target.value });
                    setButton(newButton);
                } })),
        React.createElement(TLabel, { style: { width: "100%" } },
            "Font",
            React.createElement(TSelect, { style: { width: 120, margin: 0, boxShadow: theme.shadows.shallowest }, value: button.fontFamily, onChange: e => {
                    const fontFamily = e.target.value;
                    const newButton = Object.assign(Object.assign({}, button), { fontFamily });
                    setButton(newButton);
                } }, fontFamilyOptions.map((fontFamilyOption, i) => (React.createElement("option", { key: i, value: fontFamilyOption }, fontFamilyOption))))),
        React.createElement(TLabel, { style: { width: "100%" } },
            "Weight",
            React.createElement(TSelect, { style: { width: 120, margin: 0, boxShadow: theme.shadows.shallowest }, value: `${button.fontWeight}`, onChange: e => {
                    const fontWeight = e.target.value;
                    const newButton = Object.assign(Object.assign({}, button), { fontWeight });
                    //@ts-expect-error
                    setButton(newButton);
                } }, fontWeightOptions.map((fontWeightValue, i) => (React.createElement("option", { key: i, value: fontWeightValue }, fontWeightValue))))),
        React.createElement(TLabel, { style: {
                width: "100%",
            } },
            "Size",
            React.createElement(TNumberInput, { min: 1, max: 100, step: 1, value: button.fontSize, onChange: e => {
                    const fontSize = e.target.value;
                    const newButton = Object.assign(Object.assign({}, button), { fontSize });
                    setButton(newButton);
                }, style: {
                    color: theme.colors.grey.darkest,
                    borderColor: theme.colors.sky.light,
                    boxShadow: theme.shadows.shallowest,
                } })),
        React.createElement(EmbedButtonColorPicker, { label: "Color", property: "fontColor", button: button, setButton: setButton })));
}
function StyleTab({ button, setButton, }) {
    return (React.createElement("div", { style: { paddingRight: 20, width: 200 } },
        React.createElement(TLabel, { style: { width: "100%" } },
            "Background",
            React.createElement(TToggle, { value: button.enableBackground, onClick: () => {
                    const newButton = Object.assign(Object.assign({}, button), { enableBackground: !button.enableBackground });
                    setButton(newButton);
                } })),
        React.createElement("div", { style: { marginLeft: 20 } },
            React.createElement(EmbedButtonColorPicker, { label: "Color", property: "backgroundColor", button: button, setButton: setButton })),
        React.createElement(TLabel, { style: { width: "100%" } },
            "Border",
            React.createElement(TToggle, { value: button.enableBorder, onClick: () => {
                    const newButton = Object.assign(Object.assign({}, button), { enableBorder: !button.enableBorder });
                    setButton(newButton);
                } })),
        React.createElement("div", { style: { marginLeft: 20 } },
            React.createElement(TLabel, { style: { width: "100%" } },
                "Radius",
                React.createElement(TNumberInput, { min: 0, max: 100, step: 1, value: button.borderRadius, onChange: e => {
                        const borderRadius = e.target.value;
                        const newButton = Object.assign(Object.assign({}, button), { borderRadius });
                        setButton(newButton);
                    }, style: {
                        color: theme.colors.grey.darkest,
                        borderColor: theme.colors.sky.light,
                        boxShadow: theme.shadows.shallowest,
                    } }))),
        React.createElement("div", { style: { marginLeft: 20 } },
            React.createElement(TLabel, { style: { width: "100%" } },
                "Width",
                React.createElement(TNumberInput, { min: 0, max: 100, step: 1, value: button.borderWidth, onChange: e => {
                        const borderWidth = e.target.value;
                        const newButton = Object.assign(Object.assign({}, button), { borderWidth });
                        setButton(newButton);
                    }, style: {
                        color: theme.colors.grey.darkest,
                        borderColor: theme.colors.sky.light,
                        boxShadow: theme.shadows.shallowest,
                    } }))),
        React.createElement("div", { style: { marginLeft: 20 } },
            React.createElement(EmbedButtonColorPicker, { label: "Color", property: "borderColor", button: button, setButton: setButton })),
        React.createElement(TLabel, { style: { width: "100%" } },
            "Shadow",
            React.createElement(TSelect, { style: {
                    width: 120,
                    margin: 0,
                    marginLeft: 20,
                    boxShadow: theme.shadows.shallowest,
                }, value: button.shadowType, onChange: e => {
                    const shadowType = e.target.value;
                    const newButton = Object.assign(Object.assign({}, button), { shadowType });
                    setButton(newButton);
                } }, objectToArray(userShadows).map((shadowType, i) => (React.createElement("option", { key: i, value: shadowType.key }, toTitleCase(shadowType.key))))))));
}
function SizeTab({ button, setButton }) {
    return (React.createElement("div", { style: { paddingRight: 20, width: 200 } },
        React.createElement(TLabel, { style: { width: "100%" } },
            "Width",
            React.createElement(TNumberInput, { min: 0, max: 500, step: 1, value: button.width, onChange: e => {
                    const width = e.target.value;
                    const newButton = Object.assign(Object.assign({}, button), { width });
                    setButton(newButton);
                }, style: {
                    color: theme.colors.grey.darkest,
                    borderColor: theme.colors.sky.light,
                    boxShadow: theme.shadows.shallowest,
                } })),
        React.createElement(TLabel, { style: { width: "100%" } },
            "Height",
            React.createElement(TNumberInput, { min: 0, max: 500, step: 1, value: button.height, onChange: e => {
                    const height = e.target.value;
                    const newButton = Object.assign(Object.assign({}, button), { height });
                    setButton(newButton);
                }, style: {
                    color: theme.colors.grey.darkest,
                    borderColor: theme.colors.sky.light,
                    boxShadow: theme.shadows.shallowest,
                } }))));
}
function getDefaultMobileButton() {
    return {
        text: "Insert Text",
        fontFamily: "Open Sans",
        fontWeight: null,
        fontSize: 15,
        fontColor: "black",
        enableBackground: true,
        backgroundColor: "white",
        enableBorder: true,
        borderWidth: 2,
        borderColor: "black",
        borderRadius: 4,
        width: 150,
        height: 50,
        shadowType: "none",
    };
}
export const userShadows = {
    none: "0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0)",
    shallowest: "0px 1px 1.5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.24)",
    shallow: "0px 3px 3px rgba(0, 0, 0, 0.16), 0px 3px 3px rgba(0, 0, 0, 0.23)",
    medium: "0px 10px 10px rgba(0, 0, 0, 0.19), 0px 6px 3px rgba(0, 0, 0, 0.23)",
    deep: "0px 14px 14px rgba(0, 0, 0, 0.25), 0px 10px 5px rgba(0, 0, 0, 0.22)",
    deepest: "0px 19px 19px rgba(0, 0, 0, 0.3), 0px 15px 6px rgba(0, 0, 0, 0.22)",
};
