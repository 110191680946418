import React, { useState } from "react";
import CollapseArrowIcon from "../../../../../assets/images/v3/collapse-arrow.svg";
import TooltipIcon from "../../../../../assets/images/v3/tooltip-toolpanel-icon.svg";
import HotspotIcon from "../../../../../assets/images/v3/hotspot-toolpanel-icon.svg";
import ClickzoneIcon from "../../../../../assets/images/v3/clickzone-toolpanel-icon.svg";
// import CompassIcon from "../../../../../assets/images/v3/compass-toolpanel-icon.svg";
import MouseIcon from "../../../../../assets/images/v3/auto-mouse-icon.svg";
import AutoTooltipIcon from "../../../../../assets/images/v3/auto-tooltip-icon.svg";
import TypewriterIcon from "../../../../../assets/images/v3/auto-typewriter-icon.svg";
import ModalIcon from "../../../../../assets/images/v3/modal-toolpanel-icon.svg";
import TooltipDisabledIcon from "../../../../../assets/images/v3/tooltip-toolpanel-icon-disabled.svg";
import HotspotDisabledIcon from "../../../../../assets/images/v3/hotspot-toolpanel-icon-disabled.svg";
import ClickzoneDisabledIcon from "../../../../../assets/images/v3/clickzone-toolpanel-icon-disabled.svg";
// import CompassDisabledIcon from "../../../../../assets/images/v3/compass-toolpanel-icon-disabled.svg";
import MouseDisabledIcon from "../../../../../assets/images/v3/auto-mouse-icon-disabled.svg";
import AutoTooltipDisabledIcon from "../../../../../assets/images/v3/auto-tooltip-icon-disabled.svg";
import TypewriterDisabledIcon from "../../../../../assets/images/v3/auto-typewriter-icon-disabled.svg";
import ModalDisabledIcon from "../../../../../assets/images/v3/modal-toolpanel-icon-disabled.svg";
import styled from "styled-components";
import { useAddTool } from "../../../../redux/selectors/builder-selectors";
import { getTooltipV2Template } from "../../../tools/tooltip-v2/templates/tooltip-v2-default-template";
import { v4 as uuidv4 } from "uuid";
import { newTheme } from "../../../styled/theme";
import { getTooltipV2BubbleHotspotTemplate } from "../../../tools/tooltip-v2/templates/tooltip-v2-bubble-hotspot-template";
import { getTooltipV2BubbleHotspotTemplateMobile } from "../../../tools/tooltip-v2/templates/tooltip-v2-bubble-hotspot-template-mobile";
import { getTooltipV2BubbleClickzoneTemplate } from "../../../tools/tooltip-v2/templates/tooltip-v2-bubble-clickzone-template";
import { getTooltipV2BubbleClickzoneTemplateMobile } from "../../../tools/tooltip-v2/templates/tooltip-v2-bubble-clickzone-template-mobile";
import { TooltipArrowPosition } from "../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import { ActiveVariantName, ToolType } from "../../../../../../shared/types/tool-data-types/common";
import { getDefaultModalTemplate } from "../../../tools/modal/templates/modal-default-template";
import { getMobileModalTemplate } from "../../../tools/modal/templates/modal-default-template-mobile";
import { useActiveVariantName, useFeatureFlags, useIsAutomatedScreen, useIsEmptyTourial, useIsEngineer, useIsScreenTypeUnassigned, 
// usePages,
useSetEditDetailsId, useUpdateBuilder, } from "../../../../redux/selectors/redux-selectors";
import { handleDragEnd } from "./sidebar-helpers";
// import { getDefaultGlobalButtonsTemplate } from "../../../tools/global-buttons/templates/global-buttons-default-template";
// import { GlobalButtonsData } from "../../../../../../shared/types/tool-data-types/global-buttons-data";
// import { Page } from "../../../../../../shared/legacy/legacy-tool-data";
import TTooltip from "../../../styled/t-tooltip";
import { getAutoTooltipTemplate } from "../../../tools/auto-tooltip/templates/auto-tooltip-template";
import { getAutoTypewriteTemplate } from "../../../tools/auto-typewriter/templates/auto-typewriter-template";
import { getAutoMouseTemplate } from "../../../tools/auto-mouse/templates/auto-mouse-template";
import { useFetchTheme } from "../../../../hooks/use-fetch-theme";
var SidebarTabs;
(function (SidebarTabs) {
    SidebarTabs["TOOLTIP"] = "Tooltip";
    SidebarTabs["HOTSPOT"] = "Hotspot";
    SidebarTabs["CLICKZONE"] = "Clickzone";
    SidebarTabs["GLOBALBUTTONS"] = "Navigation";
    SidebarTabs["MODALS"] = "Modal";
    SidebarTabs["LAYERS"] = "Layers";
    SidebarTabs["VIDEO"] = "Video";
    SidebarTabs["IMAGE"] = "Image";
    SidebarTabs["NONE"] = "";
    SidebarTabs["SANDBOX"] = "SuperAdmin Sandbox";
    SidebarTabs["AUTO_TOOLTIP"] = "Timed Tooltip";
    SidebarTabs["AUTO_MOUSE"] = "Timed Mouse";
    SidebarTabs["AUTO_TYPEWRITER"] = "Timed Typewriter";
})(SidebarTabs || (SidebarTabs = {}));
var DisabledTooltips;
(function (DisabledTooltips) {
    DisabledTooltips["INTERACTIVE"] = "Interactive tools disabled on screens with animations.";
    DisabledTooltips["AUTOMATED"] = "Timed Tools disabled on screens with interactions.";
    DisabledTooltips["MISSING_TIMED_TOOLS_FEATURE_FLAG"] = "Interested in the new Timed Tools? Reach out to your Customer Success Manager for details.";
})(DisabledTooltips || (DisabledTooltips = {}));
export function Sidebar({ width, setWidth, viewportDimensions }) {
    const [tab, setTab] = useState(SidebarTabs.NONE);
    const { theme } = useFetchTheme();
    const addTool = useAddTool();
    const isEmptyTourial = useIsEmptyTourial();
    const updateBuilder = useUpdateBuilder();
    const openEditPanel = useSetEditDetailsId();
    // const pages = usePages();
    const isScreenTypeUnassigned = useIsScreenTypeUnassigned();
    const isAutomatedScreen = useIsAutomatedScreen();
    const isDesktopVariant = useActiveVariantName() === ActiveVariantName.VARIANT_DESKTOP;
    const isEngineer = useIsEngineer();
    const { automation } = useFeatureFlags();
    const addToolAndOpenEditor = (tool) => {
        addTool(tool);
        updateBuilder({ selectedToolId: tool.id });
        openEditPanel(tool.id);
    };
    const addTooltip = (templateFn, position) => {
        const tool = templateFn({ id: uuidv4() }, theme);
        if (position) {
            const minY = (tool.bubble.size.height / viewportDimensions.height) * 100;
            tool.trigger.position = position;
            if (position.y < minY) {
                tool.bubble.arrow.position = TooltipArrowPosition.TOP_CENTER;
            }
        }
        addToolAndOpenEditor(tool);
    };
    const addAutoTooltip = (templateFn, position) => {
        const tool = templateFn({ id: uuidv4() }, theme);
        if (position) {
            const minY = (tool.bubble.size.height / viewportDimensions.height) * 100;
            tool.position = position;
            if (position.y < minY) {
                tool.bubble.arrow.position = TooltipArrowPosition.TOP_CENTER;
            }
        }
        addToolAndOpenEditor(tool);
    };
    const addAutoTypewriter = (templateFn, position) => {
        const tool = templateFn({ id: uuidv4() }, theme);
        if (position)
            tool.position = position;
        addToolAndOpenEditor(tool);
    };
    const addAutoMouse = (templateFn) => {
        const tool = templateFn({ id: uuidv4() }, theme);
        addToolAndOpenEditor(tool);
    };
    const addModal = (templateFn) => {
        const tool = templateFn({ id: uuidv4(), size: { width: isDesktopVariant ? 480 : 300, height: 0 } }, theme);
        addToolAndOpenEditor(tool);
    };
    const addSandbox = (templateFn) => {
        const tool = templateFn({ id: uuidv4() }, theme);
        addToolAndOpenEditor(tool);
    };
    // const addGlobalButtons = (
    //   templateFn: (fields: Partial<GlobalButtonsData>, theme: TourialTheme, pages: Page[]) => GlobalButtonsData,
    //   position?: Position
    // ) => {
    //   const tool = templateFn({ id: uuidv4() }, theme, pages);
    //   if (position) {
    //     tool.position = position;
    //   }
    //   addToolAndOpenEditor(tool);
    // };
    const closePanel = () => {
        setWidth(0);
        setTab(SidebarTabs.NONE);
    };
    // NOTE: keeping this for when we add layers or another panel that opens
    // const openPanel = (selectedTab: SidebarTabs) => {
    //   if (width === 280 && selectedTab === tab) {
    //     closePanel();
    //   } else {
    //     setWidth(280);
    //     setTab(selectedTab);
    //   }
    // };
    const interactiveOptions = [
        {
            name: SidebarTabs.TOOLTIP,
            icon: TooltipIcon,
            disabledIcon: TooltipDisabledIcon,
            onClick: (position) => {
                addTooltip(getTooltipV2Template, position);
            },
            hideOnMobile: true,
        },
        {
            name: SidebarTabs.HOTSPOT,
            icon: HotspotIcon,
            disabledIcon: HotspotDisabledIcon,
            onClick: (position) => {
                addTooltip(isDesktopVariant ? getTooltipV2BubbleHotspotTemplate : getTooltipV2BubbleHotspotTemplateMobile, position);
            },
        },
        {
            name: SidebarTabs.CLICKZONE,
            icon: ClickzoneIcon,
            disabledIcon: ClickzoneDisabledIcon,
            onClick: (position) => {
                addTooltip(isDesktopVariant ? getTooltipV2BubbleClickzoneTemplate : getTooltipV2BubbleClickzoneTemplateMobile, position);
            },
        },
        {
            name: SidebarTabs.MODALS,
            icon: ModalIcon,
            disabledIcon: ModalDisabledIcon,
            onClick: () => {
                addModal(isDesktopVariant ? getDefaultModalTemplate : getMobileModalTemplate);
            },
            hideOnMobile: !!isAutomatedScreen,
        },
        {
            name: SidebarTabs.SANDBOX,
            icon: ClickzoneIcon,
            disabledIcon: ClickzoneDisabledIcon,
            onClick: () => {
                addSandbox(() => ({
                    innerHtml: `<div style="background-color:black;color:white">Hello World</div>`,
                    type: ToolType.Sandbox,
                    id: uuidv4(),
                    name: SidebarTabs.SANDBOX,
                    sizeAndPosition: { x: 0, y: 0, width: 10, height: 10 },
                    size: { width: 400, height: 60 },
                    position: { x: 5, y: 5 },
                }));
            },
            engineerOnly: true,
        },
    ];
    const automatedOptions = [
        {
            name: SidebarTabs.AUTO_TOOLTIP,
            icon: AutoTooltipIcon,
            disabledIcon: AutoTooltipDisabledIcon,
            onClick: (position) => {
                addAutoTooltip(getAutoTooltipTemplate, position);
            },
            automatedScreenOnly: true,
        },
        {
            name: SidebarTabs.AUTO_MOUSE,
            icon: MouseIcon,
            disabledIcon: MouseDisabledIcon,
            onClick: () => {
                addAutoMouse(getAutoMouseTemplate);
            },
            automatedScreenOnly: true,
        },
        {
            name: SidebarTabs.AUTO_TYPEWRITER,
            icon: TypewriterIcon,
            disabledIcon: TypewriterDisabledIcon,
            onClick: (position) => {
                addAutoTypewriter(getAutoTypewriteTemplate, position);
            },
            automatedScreenOnly: true,
        },
    ];
    const globalOptions = [
    // {
    //   name: SidebarTabs.GLOBALBUTTONS,
    //   icon: CompassIcon,
    //   disabledIcon: CompassDisabledIcon,
    //   onClick: (position?) => {
    //     addGlobalButtons(getDefaultGlobalButtonsTemplate, position);
    //   },
    // },
    ];
    const ToolButton = ({ name, icon, disabledIcon, automatedScreenOnly, onClick }) => {
        let disabled = false;
        // if empty, disable all tools
        if (isEmptyTourial) {
            disabled = true;
        }
        // if assigned, enable applicable tools
        else if (!isScreenTypeUnassigned) {
            disabled = !!automatedScreenOnly !== !!isAutomatedScreen;
        }
        let disabledTooltip = isAutomatedScreen ? DisabledTooltips.INTERACTIVE : DisabledTooltips.AUTOMATED;
        if (!automation && automatedScreenOnly) {
            disabled = true;
            disabledTooltip = DisabledTooltips.MISSING_TIMED_TOOLS_FEATURE_FLAG;
        }
        return (React.createElement(TTooltip, { content: disabled ? disabledTooltip : name, key: name, position: "right" },
            React.createElement(SidebarButton, { style: { pointerEvents: disabled ? "none" : "auto" }, onClick: () => {
                    onClick();
                }, onDragEnd: e => {
                    handleDragEnd(e, (x, y) => {
                        onClick({ x, y });
                    });
                } },
                React.createElement("img", { src: disabled ? disabledIcon : icon, alt: `${name} button` }))));
    };
    return (React.createElement("div", null,
        React.createElement(SidebarStubContainer, null,
            React.createElement(SidebarButtonsContainer, { style: { cursor: isEmptyTourial ? "not-allowed" : "auto" } },
                interactiveOptions
                    .filter(sidebarOption => isDesktopVariant || !sidebarOption.hideOnMobile)
                    .filter(sidebarOption => isEngineer || !sidebarOption.engineerOnly)
                    .map(ToolButton),
                React.createElement(SidebarSpacer, null),
                React.createElement(React.Fragment, null,
                    automatedOptions.map(ToolButton),
                    React.createElement(SidebarSpacer, null)),
                globalOptions.map(ToolButton))),
        React.createElement(SidebarContainer, { style: { width } },
            React.createElement(PanelContainer, { style: { opacity: width > 0 ? 1 : 0 } },
                React.createElement(PanelHeaderContainer, null,
                    React.createElement(PanelHeader, null, tab),
                    React.createElement(CollapseButton, { onClick: closePanel },
                        React.createElement(CollapseArrowContainer, null,
                            React.createElement(CollapseArrowImg, { width: 12, src: CollapseArrowIcon, alt: "collapse sidebar button" })))),
                tab === SidebarTabs.LAYERS && React.createElement(LayersPanel, null, "layers..."),
                tab === SidebarTabs.IMAGE && React.createElement(ImagePanel, null, "image..."),
                tab === SidebarTabs.VIDEO && React.createElement(VideoPanel, null, "video...")))));
}
const CollapseButton = styled.button `
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
const PanelHeader = styled.h3 `
  font: ${newTheme.fonts.normal};
  padding: 16px 0;
  cursor: default;
  color: ${newTheme.colors.grey900};
  user-select: none;
`;
const PanelHeaderContainer = styled.span `
  border-bottom: 1px solid ${newTheme.colors.grey};
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  margin: 0px 16px;
`;
const CollapseArrowImg = styled.img `
  transform: rotate(90deg);
  position: relative;
  top: 4px;
  right: 1px;
`;
const CollapseArrowContainer = styled.span `
  padding-left: 4px;
  border-left: 2px solid ${newTheme.colors.grey900};
  position: relative;
  bottom: 2px;
`;
const SidebarButtonsContainer = styled.span `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;
const SidebarButton = styled.button `
  cursor: pointer;
  padding: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: ${props => (props.isOpen ? `1px solid ${newTheme.colors.grey}` : "none")};
  background-color: ${props => (props.isOpen ? newTheme.colors.grey100 : "none")};
  margin-right: ${props => (props.isOpen ? "-2px" : "0")};
  &:hover {
    background-color: ${props => (props.isOpen ? newTheme.colors.grey100 : newTheme.colors.grey200)};
  }
`;
const SidebarSpacer = styled.div `
  border-bottom: 1px solid ${newTheme.colors.grey200};
  margin: 16px 8px;
  width: 20px;
`;
const SidebarContainer = styled.div `
  background-color: ${newTheme.colors.grey100};
  position: fixed;
  float: left;
  height: 100%;
  left: 40px;
  transition: width ${newTheme.transitions.layoutShift};
  border-right: ${props => (Number(props.style.width) > 0 ? `1px solid ${newTheme.colors.grey}` : "none")};
  overflow: hidden;
`;
const SidebarStubContainer = styled.div `
  background-color: ${newTheme.colors.white};
  position: fixed;
  left: 0;
  float: left;
  width: 40px;
  height: 100%;
  border-right: 1px solid ${newTheme.colors.grey};
`;
const PanelContainer = styled.div `
  transition: opacity ${newTheme.transitions.layoutShift};
`;
const LayersPanel = styled.div `
  padding: 16px;
`;
const ImagePanel = styled.div `
  padding: 16px;
`;
const VideoPanel = styled.div `
  padding: 16px;
`;
// const PanelSeparator = styled.div`
//   margin: 0 16px;
//   border-bottom: 1px solid ${newTheme.colors.grey};
// `;
