import { TourialAppEventTypes } from "../../../shared/types/app-events";
import { TourialApiCalls } from "../helpers/tourial-api-calls";
import { useTourialId } from "../redux/selectors/redux-selectors";
import { useDCId } from "../components/pages/demo-center/hooks/dc-hooks";
export const postAppEvent = (e) => {
    TourialApiCalls.AppEvents.postAppEvent(e);
};
export function usePostAppEvent() {
    const tourialId = useTourialId();
    const democenterId = useDCId();
    return (appEventType, uniqueProps) => {
        switch (appEventType) {
            case TourialAppEventTypes.EMBED_CODE_COPIED:
            case TourialAppEventTypes.LIGHTBOX_CODE_COPIED:
                postAppEvent({
                    appEventType,
                    tourialId: (uniqueProps === null || uniqueProps === void 0 ? void 0 : uniqueProps.tourialId) || tourialId,
                    demoCenterId: uniqueProps === null || uniqueProps === void 0 ? void 0 : uniqueProps.demoCenterId,
                });
                break;
            case TourialAppEventTypes.BUILDER_ENTERED:
                postAppEvent({
                    appEventType,
                    tourialId: uniqueProps.tourialId,
                });
                break;
            case TourialAppEventTypes.LIVE_URL_COPIED:
                postAppEvent({
                    appEventType,
                    tourialId,
                    liveUrl: uniqueProps.liveUrl,
                });
                break;
            case TourialAppEventTypes.APP_PAGE_VIEWED:
                postAppEvent({
                    appEventType,
                    tourialId,
                    pageName: uniqueProps.pageName,
                    pagePath: uniqueProps.pagePath,
                });
                break;
            case TourialAppEventTypes.DEMOCENTER_DISCARD_CHANGES:
                postAppEvent({
                    appEventType,
                    demoCenterId: democenterId,
                });
                break;
            case TourialAppEventTypes.PROJECT_ROOM_LINK_CLICKED:
                postAppEvent({
                    appEventType,
                    // @ts-expect-error - why is this flagged
                    projectRoomLink: uniqueProps.projectRoomLink,
                });
                break;
            default:
                postAppEvent({ appEventType });
                break;
        }
    };
}
