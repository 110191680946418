import styled, { css } from "styled-components";
import { BorderShape, TextAlign } from "../../../../../../../shared/types/tool-data-types/common";
import { TooltipButtonStyle } from "../../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import { textAlignments } from "../../../../../helpers/text-helpers";
// flexbox - easier to static position an item between two growing items
// grid - ignores content width when considering rate of growth(i.e. 2 colliding buttons stop at 50%))
export const BubbleButtonContainer = styled.span `
  display: flex;
  grid-auto-flow: column;
  justify-content: ${p => buttonContainerJustifyContent[p.buttonAlign]};
  flex-wrap: nowrap;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  margin-top: 12px;
  gap: 12px;
`;
const buttonContainerJustifyContent = {
    [TextAlign.CENTER]: "space-between",
    [TextAlign.LEFT]: "flex-start",
    [TextAlign.RIGHT]: "flex-end",
};
export const BubbleButtonWrapper = styled.div `
  display: flex;
  position: relative;
  max-width: 100%;
  min-width: 26px;
  white-space: nowrap;
  overflow: hidden;
  ${props => (props.shouldFillSpace ? "flex: 1;" : "")}
  ${props => (props.isButtonEnabled ? "height: 26px;" : "display: none;")}
  button {
    max-width: 100%;
    min-width: 26px;
    height: 26px;
    padding: 0px 12px;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    display: flex;
    position: ${props => (props.shouldFillSpace ? "absolute" : "initial")};
    align-items: center;
    font-size: 12px;
  }
`;
export const BubbleHeadlineContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: ${p => (p.config.isEnabled ? textAlignments[p.config.align] : "center")};
`;
const logoBorderRadii = {
    [BorderShape.RECTANGLE]: "0px",
    [BorderShape.ROUNDED]: "8px",
    [BorderShape.SQUIRCLE]: "20px",
};
export const BubbleHeadlineLogo = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  border-radius: ${p => logoBorderRadii[p.shape]};
  min-width: 40px;
  min-height: 40px;
  > img {
    max-width: 40px;
    max-height: 40px;
  }
`;
export const bubbleBorderRadii = {
    [BorderShape.RECTANGLE]: 4,
    [BorderShape.ROUNDED]: 24,
    [BorderShape.SQUIRCLE]: 56,
};
export const bubbleContainerBase = css `
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  height: min-content;
`;
export const bubbleBoxShadow = "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1), inset 0px 0px 2px rgba(48, 49, 51, 0.5)";
export const bubbleButtonBorderRadii = {
    [BorderShape.RECTANGLE]: 1,
    [BorderShape.ROUNDED]: 8,
    [BorderShape.SQUIRCLE]: 20,
};
export const BubbleButtonIconContainer = styled.div `
  margin-top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
`;
export const bubbleButtonSidePadding = {
    [TooltipButtonStyle.TEXT_AND_ARROWS]: 4,
    [TooltipButtonStyle.TEXT]: 12,
    [TooltipButtonStyle.ARROWS]: 6,
};
