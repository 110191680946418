import { useEffect, useState } from "react";
import { useIsLastStep } from "./use-is-last-step";
import { useHTStepCount, useHTStepIndex } from "../redux/selectors/ht-selectors";
import { useFetchFormOnStep } from "./use-fetch-form";
export const useHasTourEnded = () => {
    const [isOnLastStep, setIsOnLastStep] = useState(false);
    const [formIsOpen, setFormIsOpen] = useState(false);
    const { formOnStep } = useFetchFormOnStep();
    const tourHasEnded = !formIsOpen && isOnLastStep;
    useIsLastStep(setIsOnLastStep);
    useEffect(() => {
        if (!!(formOnStep === null || formOnStep === void 0 ? void 0 : formOnStep.formId) && !!(formOnStep === null || formOnStep === void 0 ? void 0 : formOnStep.isEnabled)) {
            setFormIsOpen(true);
        }
        else {
            setFormIsOpen(false);
        }
        return () => setFormIsOpen(false);
    }, [formOnStep]);
    return tourHasEnded;
};
export const useHTHasTourEnded = () => {
    const [hasTourEnded, setHasTourEnded] = useState(false);
    const index = useHTStepIndex();
    const count = useHTStepCount();
    useEffect(() => {
        if (index === count - 1)
            setHasTourEnded(true);
        return () => setHasTourEnded(false);
    }, [index, count]);
    return hasTourEnded;
};
