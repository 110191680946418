import { useEffect, useRef, useState } from "react";
import { useHTScale, useHTIsEditingHtml } from "../../../../redux/selectors/ht-selectors";
import { makeSelector, selectScreen } from "../helpers/dom-tour-helpers";
export var HTClassesAndIds;
(function (HTClassesAndIds) {
    HTClassesAndIds["SELECTED_CLASS"] = "ht-selected-element";
    HTClassesAndIds["VIEWING_CLASS"] = "ht-viewing";
    HTClassesAndIds["BLURRED_CLASS"] = "ht-blurred";
    HTClassesAndIds["REMOVED_CLASS"] = "ht-removed";
    HTClassesAndIds["HIDDEN_CLASS"] = "ht-hidden";
    HTClassesAndIds["STYLE_ID"] = "ht-style";
    HTClassesAndIds["BREADCRUMB_CLASS"] = "ht-breadcrumb";
})(HTClassesAndIds || (HTClassesAndIds = {}));
export function useHTSelectedElementData() {
    const isEditing = useHTIsEditingHtml();
    const scale = useHTScale();
    const selectedElement = useRef();
    const [selectedElementSelector, setSelectedElementSelector] = useState("");
    const [selectedElementTagName, setSelectedElementTagName] = useState("");
    const [selectedElementIsBlurred, setSelectedElementIsBlurred] = useState(false);
    const [selectedElementIsHidden, setSelectedElementIsHidden] = useState(false);
    const [selectedElementIsRemoved, setSelectedElementIsRemoved] = useState(false);
    const [viewIsHidden, setViewIsHidden] = useState(false);
    const [viewIsRemoved, setViewIsRemoved] = useState(false);
    const [hiddenCount, setHiddenCount] = useState(0);
    const [removedCount, setRemovedCount] = useState(0);
    useEffect(getAndSetClassCounts, []);
    useEffect(() => {
        if (!isEditing) {
            selectedElement.current = null;
            setSelectedElementSelector("");
            setSelectedElementTagName("");
            setSelectedElementIsBlurred(false);
            setSelectedElementIsHidden(false);
            setSelectedElementIsRemoved(false);
            return;
        }
        const { element: iframe } = selectScreen();
        addHTStyleTag(iframe);
        const handleClickInsideIframe = event => {
            if (iframe) {
                const x = event.clientX * scale;
                const y = event.clientY * scale;
                const element = iframe.contentDocument.elementFromPoint(x / scale, y / scale);
                const prevSelectedElements = iframe.contentDocument.querySelectorAll(`.${HTClassesAndIds.SELECTED_CLASS}, .${HTClassesAndIds.BREADCRUMB_CLASS}`);
                prevSelectedElements.forEach(pse => {
                    pse.classList.remove(HTClassesAndIds.SELECTED_CLASS);
                    pse.classList.remove(HTClassesAndIds.BREADCRUMB_CLASS);
                });
                selectedElement.current = element;
                setSelectedElementSelector(makeSelector(element));
                setSelectedElementTagName(element.tagName);
                setIsBlurred();
                setIsHidden();
                setIsRemoved();
                selectedElement.current.classList.add(HTClassesAndIds.SELECTED_CLASS);
            }
        };
        if (iframe) {
            iframe.contentDocument.addEventListener("click", handleClickInsideIframe);
        }
        return () => {
            if (iframe) {
                iframe.contentDocument.removeEventListener("click", handleClickInsideIframe);
            }
        };
    }, [isEditing]);
    function clearTemporaryClasses() {
        const { element: iframe } = selectScreen();
        const prevSelectedElements = iframe.contentDocument.querySelectorAll(`.${HTClassesAndIds.SELECTED_CLASS}, .${HTClassesAndIds.BREADCRUMB_CLASS}, .${HTClassesAndIds.VIEWING_CLASS}`);
        prevSelectedElements.forEach(pse => {
            pse.classList.remove(HTClassesAndIds.SELECTED_CLASS);
            pse.classList.remove(HTClassesAndIds.BREADCRUMB_CLASS);
            pse.classList.remove(HTClassesAndIds.VIEWING_CLASS);
        });
        setViewIsHidden(false);
        setViewIsRemoved(false);
    }
    function addHTStyleTag(iframe) {
        if (iframe.contentDocument.querySelector(`#${HTClassesAndIds.STYLE_ID}`)) {
            return;
        }
        const styleElement = iframe.contentDocument.createElement("style");
        styleElement.id = HTClassesAndIds.STYLE_ID;
        const styles = `
        .${HTClassesAndIds.SELECTED_CLASS} {
          outline: 2px solid #00B2FF !important;
          box-shadow: 0px 0px 1px 4px rgba(0, 178, 255, 0.20) !important;
        }
        .${HTClassesAndIds.BLURRED_CLASS} {
          filter: blur(5px) !important;
        }
        .${HTClassesAndIds.HIDDEN_CLASS}:not(.${HTClassesAndIds.VIEWING_CLASS}) {
          visibility: hidden !important;
        }
        .${HTClassesAndIds.REMOVED_CLASS}:not(.${HTClassesAndIds.VIEWING_CLASS}) {
          display: none !important;
        }
        .${HTClassesAndIds.VIEWING_CLASS} {
          opacity: .5 !important;
        }
      `;
        styleElement.appendChild(iframe.contentDocument.createTextNode(styles));
        iframe.contentDocument.head.appendChild(styleElement);
    }
    function setIsBlurred(on = !!Array.from(selectedElement.current.classList).includes(HTClassesAndIds.BLURRED_CLASS)) {
        if (on) {
            selectedElement.current.classList.add(HTClassesAndIds.BLURRED_CLASS);
            setSelectedElementSelector(makeSelector(selectedElement.current));
            setSelectedElementIsBlurred(true);
        }
        else {
            selectedElement.current.classList.remove(HTClassesAndIds.BLURRED_CLASS);
            setSelectedElementSelector(makeSelector(selectedElement.current));
            setSelectedElementIsBlurred(false);
        }
    }
    function setIsHidden(on = !!Array.from(selectedElement.current.classList).includes(HTClassesAndIds.HIDDEN_CLASS)) {
        if (on) {
            selectedElement.current.classList.add(HTClassesAndIds.HIDDEN_CLASS);
            setSelectedElementSelector(makeSelector(selectedElement.current));
            setSelectedElementIsHidden(true);
            if (viewIsHidden)
                selectedElement.current.classList.add(HTClassesAndIds.VIEWING_CLASS);
        }
        else {
            selectedElement.current.classList.remove(HTClassesAndIds.HIDDEN_CLASS);
            setSelectedElementSelector(makeSelector(selectedElement.current));
            setSelectedElementIsHidden(false);
            if (viewIsHidden)
                selectedElement.current.classList.remove(HTClassesAndIds.VIEWING_CLASS);
        }
        getAndSetClassCounts();
    }
    function setIsRemoved(on = !!Array.from(selectedElement.current.classList).includes(HTClassesAndIds.REMOVED_CLASS)) {
        if (on) {
            selectedElement.current.classList.add(HTClassesAndIds.REMOVED_CLASS);
            setSelectedElementSelector(makeSelector(selectedElement.current));
            setSelectedElementIsRemoved(true);
            if (viewIsHidden)
                selectedElement.current.classList.add(HTClassesAndIds.VIEWING_CLASS);
        }
        else {
            selectedElement.current.classList.remove(HTClassesAndIds.REMOVED_CLASS);
            setSelectedElementSelector(makeSelector(selectedElement.current));
            setSelectedElementIsRemoved(false);
            if (viewIsHidden)
                selectedElement.current.classList.remove(HTClassesAndIds.VIEWING_CLASS);
        }
        getAndSetClassCounts();
    }
    function setImageSrc(src) {
        var _a;
        if (((_a = selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.current) === null || _a === void 0 ? void 0 : _a.tagName) === "IMG") {
            selectedElement.current.src = src;
        }
    }
    function toggleViewIsHidden(on) {
        const { element: iframe } = selectScreen();
        const hiddenElements = iframe.contentDocument.querySelectorAll(`.${HTClassesAndIds.HIDDEN_CLASS}`);
        setViewIsHidden(on);
        if (on) {
            hiddenElements.forEach(he => {
                he.classList.add(HTClassesAndIds.VIEWING_CLASS);
            });
        }
        else {
            hiddenElements.forEach(he => {
                he.classList.remove(HTClassesAndIds.VIEWING_CLASS);
            });
        }
    }
    function toggleViewIsRemoved(on) {
        const { element: iframe } = selectScreen();
        const removedElements = iframe.contentDocument.querySelectorAll(`.${HTClassesAndIds.REMOVED_CLASS}`);
        setViewIsRemoved(on);
        if (on) {
            removedElements.forEach(he => {
                he.classList.add(HTClassesAndIds.VIEWING_CLASS);
            });
        }
        else {
            removedElements.forEach(he => {
                he.classList.remove(HTClassesAndIds.VIEWING_CLASS);
            });
        }
    }
    function getAndSetClassCounts() {
        const { element: iframe } = selectScreen();
        setHiddenCount(iframe.contentDocument.querySelectorAll(`.${HTClassesAndIds.HIDDEN_CLASS}`).length);
        setRemovedCount(iframe.contentDocument.querySelectorAll(`.${HTClassesAndIds.REMOVED_CLASS}`).length);
    }
    return {
        selectedElement,
        selectedElementSelector,
        setSelectedElementSelector,
        selectedElementTagName,
        setSelectedElementTagName,
        selectedElementIsBlurred,
        setIsBlurred,
        selectedElementIsHidden,
        setIsHidden,
        selectedElementIsRemoved,
        setIsRemoved,
        toggleViewIsHidden,
        toggleViewIsRemoved,
        hiddenCount,
        removedCount,
        viewIsHidden,
        viewIsRemoved,
        clearTemporaryClasses,
        setImageSrc,
    };
}
