var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import { ToastModes, ViewportMode } from "../../../types/definitions";
import { Sidebar } from "./sidebar/sidebar";
import { useActiveVariantName, useHasActiveVariantNameBeenSet, useIsEmptyTourial, useIsPublished, useSetIsPublishModalOpen, useSetStoryboardHeight, useSetViewportMode, useStoryboardHeight, useTourialHasChangesToSave, useTourialId, useTourialProductTourPage, useViewportMode, } from "../../../redux/selectors/redux-selectors";
import styled from "styled-components";
import { Four04Page } from "../404-page";
import { useAsync } from "react-async";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import { getInitialTourialRootState } from "../../../redux/reducers/tourial-root-reducer";
import { BuilderLoading } from "./loading";
import { useSetAndClearRootState } from "../../../hooks/use-set-and-clear-root-state";
import { EditViewport } from "../../renderer/edit/edit-viewport";
import { useDeselect } from "../../../redux/selectors/builder-selectors";
import { PreviewViewport } from "../../renderer/preview/preview-viewport";
import { newTheme } from "../../styled/theme";
import { useBuilderHotkeys } from "../../../hooks/use-hotkeys";
import EmptyEditViewport from "../../renderer/edit/empty-edit-viewport";
import { EditMenuContainer } from "../../renderer/edit/edit-menu-container";
import { TPrompt } from "../../styled/t-prompt";
import { useRefSize } from "../../../hooks/viewport-hooks";
import { PublishedExitPrompt } from "./modals/published-exit-prompt";
import { StoryboardMini } from "./storyboard-mini/storyboard-mini";
import { maxStoryboardMiniHeight } from "./storyboard-mini/storyboard-mini-stub";
import { NavbarMini } from "./navbar/navbar-mini";
import { VerticalFrameSelector } from "./vertical-frame-selector";
import { useAddToast } from "../../../legacy/selectors";
import { ActiveVariantName } from "../../../../../shared/types/tool-data-types/common";
import { VideoTrimModal } from "./modals/video-trim-modal";
import WorkflowCaptureRenameModal from "./modals/workflow-capture-rename-modal";
import { useIsEditModeBlocked } from "../../../hooks/use-is-collaborator-blocked";
const getFetchRootStateFn = ({ tourialId }) => __awaiter(void 0, void 0, void 0, function* () {
    if (!tourialId)
        throw new Error("no tourial id provided");
    const { tourial, account } = yield TourialApiCalls.Tourials.getTourial(tourialId);
    return getInitialTourialRootState(tourial, ViewportMode.EDIT, account);
});
export default function BuilderPageFetcher() {
    const tourialIdInRedux = useTourialId();
    const urlParams = new URLSearchParams(window.location.search);
    const tourialIdInURL = urlParams.get("tourId");
    const { data, error } = useAsync({
        promiseFn: getFetchRootStateFn,
        tourialId: tourialIdInURL,
    });
    useSetAndClearRootState(data);
    if (error) {
        return React.createElement(Four04Page, null);
    }
    if (data && tourialIdInRedux) {
        return React.createElement(BuilderPageComponent, null);
    }
    return React.createElement(BuilderLoading, null);
}
export const NAVBAR_HEIGHT = 48;
export const STORYBOARD_STUB_HEIGHT = 32;
function BuilderPageComponent() {
    const searchParams = new URLSearchParams(window.location.search);
    const [isWorkflowCaptureOpened, setIsWorkflowCaptureOpened] = useState(searchParams.get("workflowCapture") === "true");
    const variant = useActiveVariantName();
    const wasVariantSet = useHasActiveVariantNameBeenSet();
    const addToast = useAddToast();
    const [sidebarWidth, setSidebarWidth] = useState(0);
    const storyboardHeight = useStoryboardHeight();
    const setStoryboardHeight = useSetStoryboardHeight();
    const viewportMode = useViewportMode();
    const deselect = useDeselect();
    const setIsPublishModalOpen = useSetIsPublishModalOpen();
    const hasUnsavedChanges = useTourialHasChangesToSave();
    const isPublished = useIsPublished();
    const productTourPage = useTourialProductTourPage();
    const editPanelWidth = 320;
    useBuilderHotkeys();
    const [builderViewportDimensions, setBuilderViewportDimensions] = useState({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    });
    const isEmptyTourial = useIsEmptyTourial();
    const editViewportContainerRef = useRef(null);
    const previewViewportContainerRef = useRef(null);
    const viewportContainerDimensions = useRefSize(viewportMode === ViewportMode.EDIT ? editViewportContainerRef : previewViewportContainerRef, storyboardHeight);
    const isEditModeBlocked = useIsEditModeBlocked();
    const setViewportMode = useSetViewportMode();
    useEffect(() => {
        if (isEditModeBlocked) {
            setViewportMode(ViewportMode.PREVIEW);
        }
    }, [isEditModeBlocked]);
    useEffect(() => {
        if (viewportMode === ViewportMode.PREVIEW) {
            setSidebarWidth(0);
        }
    }, [viewportMode]);
    useEffect(() => {
        setStoryboardHeight(maxStoryboardMiniHeight);
    }, []);
    // info toast when variant changes
    useEffect(() => {
        if (wasVariantSet) {
            const message = variant === ActiveVariantName.VARIANT_DESKTOP ? "Editing desktop version." : "Editing mobile version.";
            addToast({
                message,
                mode: ToastModes.WARNING,
            });
        }
    }, [variant]);
    return (React.createElement(RootContainer, { id: "_test_builder-page", viewportMode: viewportMode },
        isPublished ? (React.createElement(PublishedExitPrompt, { isEnabled: hasUnsavedChanges && !isEditModeBlocked, onCancelButtonClick: () => setIsPublishModalOpen(true) })) : (React.createElement(TPrompt, { isEnabled: hasUnsavedChanges, headerText: "Exit without saving?", bodyText: "You have unpublished changes in this tour. You will lose these changes unless you save them.", cancelButtonText: "Save Changes", continueButtonText: "Discard changes", onCancelButtonClick: () => setIsPublishModalOpen(true) })),
        React.createElement(NavContainer, null,
            React.createElement(NavbarMini, null)),
        viewportMode === ViewportMode.EDIT ? (React.createElement(React.Fragment, null,
            React.createElement(OuterViewportContainer, { style: { width: `calc(100% - ${isEmptyTourial ? 0 : 360}px)` } },
                React.createElement(BackgroundPattern, { onClick: deselect, style: {
                        width: `calc(100% - ${isEmptyTourial ? 0 : 360}px)`,
                        height: `calc(100% - ${NAVBAR_HEIGHT}px - ${STORYBOARD_STUB_HEIGHT}px - ${storyboardHeight}px)`,
                        backgroundImage: (productTourPage === null || productTourPage === void 0 ? void 0 : productTourPage.backgroundImage) ? `url(${productTourPage.backgroundImage})` : "none",
                        backgroundColor: (productTourPage === null || productTourPage === void 0 ? void 0 : productTourPage.bodyColor) || "#E4E5E9",
                    } }),
                React.createElement(InnerViewportContainer, { ref: editViewportContainerRef, style: {
                        width: `calc(100% - ${isEmptyTourial ? 0 : 360}px)`,
                        height: `calc(100% - ${NAVBAR_HEIGHT}px - ${STORYBOARD_STUB_HEIGHT}px - ${storyboardHeight}px)`,
                    }, onClick: deselect }, isEmptyTourial ? (React.createElement(EmptyEditViewport, null)) : (React.createElement(EditViewport, { setBuilderViewportDimensions: setBuilderViewportDimensions })))),
            React.createElement(EditElementsContainer, null,
                React.createElement(Sidebar, { width: sidebarWidth, setWidth: setSidebarWidth, viewportDimensions: builderViewportDimensions }),
                React.createElement(StoryboardMini, { editPanelWidth: isEmptyTourial ? 0 : editPanelWidth, sidebarWidth: sidebarWidth, storyboardHeight: storyboardHeight, setStoryboardHeight: setStoryboardHeight }),
                !isEmptyTourial && React.createElement(EditMenuContainer, { viewportDimensions: builderViewportDimensions })),
            isWorkflowCaptureOpened && (React.createElement(WorkflowCaptureRenameModal, { closeModal: () => {
                    searchParams.delete("workflowCapture");
                    const newSearch = searchParams.toString();
                    const newURL = `${window.location.pathname}?${newSearch}`;
                    window.history.replaceState(null, "", newURL);
                    setIsWorkflowCaptureOpened(false);
                } })),
            React.createElement(VideoTrimModal, null))) : (React.createElement("div", { ref: previewViewportContainerRef, style: {
                position: "fixed",
                width: "100%",
                height: "calc(100% - 135px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            } },
            React.createElement(PreviewViewport, null))),
        React.createElement(VerticalFrameSelector, { viewportContainerDimensions: viewportContainerDimensions })));
}
const EditElementsContainer = styled.div `
  animation: ${newTheme.animations.fadeIn} ${newTheme.transitions.layoutShift};
`;
const BackgroundPattern = styled.div `
  background-size: 100% 100%;
  opacity: 1;
  float: unset;
  height: 100%;
  width: 100%;
  position: fixed;
`;
const OuterViewportContainer = styled.div `
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  left: 40px;
`;
const InnerViewportContainer = styled.div `
  height: 100%;
  position: fixed;
  display: flex;
  align-items: flex-start;
  padding: 20px 48px;
  justify-content: center;
`;
const NavContainer = styled.div `
  overflow: hidden;
  background-color: ${newTheme.colors.white};
  width: 100%;
  border-bottom: 1px solid ${newTheme.colors.grey};
`;
const RootContainer = styled.div `
  background-color: ${({ viewportMode }) => viewportMode === ViewportMode.PREVIEW ? newTheme.colors.grey700 : newTheme.colors.editModeGrey};
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  overflow: auto;
`;
