import React, { useState, useEffect } from "react";
import TButton, { TButtonVariants } from "../../styled/t-button";
import styled from "styled-components";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import TModal, { TModalButtonsContainer } from "../../styled/t-modal";
import { newTheme } from "../../styled/theme";
import { ToastModes } from "../../../types/definitions";
import { TDropdownSelect } from "../../styled/t-dropdown-select";
export default function DuplicateTourialToWorkspace({ tourialData, setIsDuplicateToWorkspaceModalOpen, reload, addToast, setIsDropdownOpen, }) {
    const [workspaces, setWorkspaces] = useState([]);
    const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(tourialData.workspaceId || "");
    useEffect(() => {
        TourialApiCalls.Workspaces.listWorkspaces()
            .then(data => {
            setWorkspaces(data.map(workspace => {
                return {
                    label: workspace.name,
                    value: workspace._id,
                };
            }));
        })
            .catch(e => {
            console.error(e);
        });
    }, []);
    return (React.createElement(TModal, { title: `Duplicating to workspace`, onClose: () => {
            setIsDuplicateToWorkspaceModalOpen(false);
            setIsDropdownOpen(false);
        } },
        React.createElement(React.Fragment, null,
            React.createElement(Span, null,
                React.createElement("div", { style: { marginBottom: 10 } }, "Where do you want to duplicate your demo?"),
                React.createElement(TDropdownSelect, { options: workspaces, onChange: e => setSelectedWorkspaceId(e.value), selectedValue: selectedWorkspaceId, isSearchable: false, placeholder: "Select workspace" })),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => {
                        setIsDuplicateToWorkspaceModalOpen(false);
                        setIsDropdownOpen(false);
                    } }, "Cancel"),
                React.createElement(TButton, { variant: TButtonVariants.DEFAULT, onClick: () => {
                        TourialApiCalls.Tourials.postTourialClone(tourialData.tourId, "", selectedWorkspaceId)
                            .then(() => {
                            reload();
                            setIsDuplicateToWorkspaceModalOpen(false);
                            setIsDropdownOpen(false);
                            addToast({ message: "Duplicate successful", mode: ToastModes.SUCCESS });
                        })
                            .catch(e => {
                            console.error(e);
                            addToast({ message: "Duplicate failed", mode: ToastModes.ERROR });
                        });
                    } }, "Create copy")))));
}
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  width: 100%;
`;
