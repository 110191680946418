import React, { useEffect, useState } from "react";
import TButton, { TButtonVariants } from "../../styled/t-button";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import TModal, { TModalButtonsContainer } from "../../styled/t-modal";
import { newTheme } from "../../styled/theme";
import { ToastModes } from "../../../types/definitions";
import { useHistory } from "react-router-dom";
import { useAddToast } from "../../../legacy/selectors";
import { ContentContainer } from "./workspace-create-modal";
import { TDropdownSelect } from "../../styled/t-dropdown-select";
export default function DeleteWorkspace({ workspace, onClose }) {
    const history = useHistory();
    const addToast = useAddToast();
    const [workspaces, setWorkspaces] = useState([]);
    const [relocationWorkspaceId, setRelocationWorkspaceId] = useState("");
    useEffect(() => {
        // Fetch workspaces on modal open
        TourialApiCalls.Workspaces.listWorkspaces()
            .then(data => {
            setWorkspaces(data.map(w => {
                return {
                    label: w.name,
                    value: w._id,
                };
            }));
        })
            .catch(e => {
            console.error(e);
        });
    }, []);
    return (React.createElement(TModal, { title: "Deleting workspace", onClose: onClose },
        React.createElement(React.Fragment, null,
            React.createElement(ContentContainer, null,
                React.createElement("div", null,
                    React.createElement("div", { style: { marginBottom: 4 } }, "Where do you want to move your demos?"),
                    React.createElement(TDropdownSelect, { options: workspaces.filter(w => w.value !== workspace._id), onChange: e => setRelocationWorkspaceId(e.value), selectedValue: relocationWorkspaceId, isSearchable: false, placeholder: "Select workspace" }))),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: onClose }, "Cancel"),
                React.createElement(TButton, { style: { color: newTheme.colors.red500 }, variant: TButtonVariants.OUTLINE, onClick: () => {
                        TourialApiCalls.Workspaces.deleteWorkspace(workspace._id, relocationWorkspaceId)
                            .then(() => {
                            addToast({ message: "Workspace deleted successfully", mode: ToastModes.SUCCESS });
                            onClose();
                            history.push("/?reload-list=true&reload-tourial-list=true"); // Tells the main component to reload workspaces and demo lists
                        })
                            .catch(e => {
                            console.error(e);
                            addToast({ message: "Delete workspace failed", mode: ToastModes.ERROR });
                        });
                    } }, "Delete Workspace")))));
}
