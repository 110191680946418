import { validateEmail } from "../../../../../helpers/misc";
import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { StyledFormInput } from "./styled-form-input";
import { FormField } from "../subcomponents/styled-main-view";
import { StyledLabel } from "./styled-label";
export function EmailField(props) {
    const { setFormState, theme, field, fieldIndex, formState, labelText, nongeneric } = props;
    const [errorStyle, setErrorStyle] = useState({});
    function validate(v) {
        return !validateEmail(v, nongeneric);
    }
    return (React.createElement(FormField, null,
        React.createElement(StyledLabel, { labelText: labelText },
            field.label,
            React.createElement(StyledFormInput, { theme: theme, type: "text", name: field.role, onChange: e => {
                    if (setFormState) {
                        const err = validate(e.target.value);
                        if (err) {
                            setErrorStyle({ border: "1px solid red" });
                        }
                        else {
                            setErrorStyle({});
                        }
                        const nextState = cloneDeep(formState);
                        nextState[fieldIndex].isValid = !err;
                        nextState[fieldIndex].value = e.target.value;
                        setFormState(nextState);
                    }
                }, style: Object.assign({}, errorStyle) }))));
}
