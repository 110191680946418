import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { useHTGotoNextStep, useHTGotoPrevStep, useHTIsEditingHtml, useHTMode, useHTStep, useHTTheme, } from "../../../../../redux/selectors/ht-selectors";
import { DTStepType, HTIconTypes, HTMode } from "../../../../../../../shared/types/dom-tour";
import { MOBILE_TOOLTIP_HEIGHT } from "../../config";
import { useHTIconMode } from "../../hooks/ht-hooks";
import { TextStyle } from "../../../../../../../shared/types/tool-data-types/common";
import { isMarkupTextEmpty } from "../../../../../helpers/dom-helpers";
import tinycolor from "tinycolor2";
import { HTIcon } from "../atomic/ht-icon";
import { modifyHTModeForDCLivePreview } from "../../helpers/dom-tour-helpers";
export function MobileTool({ dimensions, scale }) {
    var _a;
    const { type, tooltip: { header, body } = {} } = useHTStep();
    const theme = useHTTheme();
    const currMode = useHTMode();
    const isEditing = useHTIsEditingHtml();
    const goToPreviousStep = useHTGotoPrevStep();
    const goToNextStep = useHTGotoNextStep();
    const iconMode = useHTIconMode();
    const textRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [hasScroll, setHasScroll] = useState(false);
    const mode = modifyHTModeForDCLivePreview(currMode);
    useEffect(() => {
        if (textRef.current && textRef.current.scrollHeight > MOBILE_TOOLTIP_HEIGHT) {
            setHasScroll(true);
        }
        else {
            setHasScroll(false);
        }
    }, [(_a = textRef === null || textRef === void 0 ? void 0 : textRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight]);
    useEffect(() => {
        if (type !== DTStepType.TOOLTIP)
            setIsExpanded(false);
    }, [type]);
    const isBodyEmpty = isMarkupTextEmpty(body);
    return (React.createElement(Root, { dimensions: dimensions, theme: theme, isEditing: isEditing, scale: scale },
        React.createElement(MobileTooltipContainer, { theme: theme, isExpanded: isExpanded },
            hasScroll && type === DTStepType.TOOLTIP && (React.createElement(ReadMoreTab, { theme: theme, onClick: () => setIsExpanded(!isExpanded) },
                React.createElement(HTIcon, { type: HTIconTypes.DOWN_CHEVRON, mode: iconMode, style: {
                        cursor: "pointer",
                        transform: `rotate(-${isExpanded ? 0 : 180}deg)`,
                        transition: "transform 0.2s ease-in-out",
                    } }))),
            React.createElement(ContentContainer, { shouldFade: !isExpanded && hasScroll },
                type !== DTStepType.INTRO ? (React.createElement(HTIcon, { type: HTIconTypes.LEFT_ARROW, mode: iconMode, style: { height: 30, cursor: "pointer", padding: "0px 20px 0px 16px" }, onClick: handlePrevStep })) : (React.createElement("div", { style: { flexGrow: 1 } })),
                type === DTStepType.TOOLTIP && (React.createElement(ContentTextContainer, { ref: textRef, isExpanded: isExpanded },
                    !!header && (React.createElement(Header, { theme: theme, isBodyEmpty: isBodyEmpty }, header)),
                    !isBodyEmpty && (React.createElement(Body, { isExpanded: isExpanded, theme: theme }, parse(body))))),
                type !== DTStepType.OUTRO && (React.createElement(HTIcon, { type: HTIconTypes.RIGHT_ARROW, mode: iconMode, style: { height: 30, cursor: "pointer", padding: "0px 16px 0px 20px" }, onClick: handleNextStep }))))));
    function handlePrevStep() {
        if (mode === HTMode.EDIT)
            return;
        goToPreviousStep();
    }
    function handleNextStep() {
        if (mode === HTMode.EDIT)
            return;
        goToNextStep();
    }
}
const ContentTextContainer = styled.div `
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  overflow-y: scroll;
  height: ${({ isExpanded }) => (isExpanded ? "fit-content" : MOBILE_TOOLTIP_HEIGHT + "px")};
  flex: 1;
  transition: height 0.2s ease-in;
`;
const ContentContainer = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  mask-image: ${({ shouldFade }) => shouldFade ? "linear-gradient(to bottom, black calc(100% - 20px), transparent 100%)" : "none"};
`;
const ReadMoreTab = styled.div `
  width: 30px;
  height: 20px;
  border-radius: 4px 4px 0px 0px;
  background-color: ${({ theme }) => theme.tooltip.backgroundColor};
  color: ${({ theme }) => (tinycolor(theme.tooltip.backgroundColor).isLight() ? "black" : "white")};
  top: -20px;
  left: calc(50% - 15px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const MobileTooltipContainer = styled.div `
  width: 100%;
  height: ${({ isExpanded }) => (isExpanded ? "fit-content" : `${MOBILE_TOOLTIP_HEIGHT}px`)};
  position: absolute;
  bottom: 0px;
  background-color: ${({ theme }) => theme.tooltip.backgroundColor};
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
`;
const Root = styled.div `
  z-index: 3;
  position: absolute;
  top: ${({ dimensions, scale }) => dimensions.height * scale}px;
  left: 0px;
  width: ${({ dimensions, scale }) => dimensions.width * scale}px;
  height: ${MOBILE_TOOLTIP_HEIGHT}px;
  background-color: ${({ theme }) => theme.tooltip.backgroundColor};
`;
const Header = styled.h3 `
  font-family: ${({ theme }) => theme.tooltip.headline.font};
  font-weight: ${({ theme }) => (theme.tooltip.headline.style === TextStyle.BOLD ? "bold" : "normal")};
  text-decoration: ${({ theme }) => (theme.tooltip.headline.style === TextStyle.UNDERLINE ? "underline" : "none")};
  font-style: ${({ theme }) => (theme.tooltip.headline.style === TextStyle.ITALIC ? "italic" : "normal")};
  color: ${({ theme }) => theme.tooltip.headline.color};
  font-size: 18px;
  line-height: 26px;
  margin-bottom: ${({ isBodyEmpty }) => (isBodyEmpty ? 0 : 4)}px;
`;
const Body = styled.div `
  font-family: ${({ theme }) => theme.tooltip.body.font};
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.tooltip.body.color};
  ul,
  ol {
    margin-left: 15px;
  }
`;
