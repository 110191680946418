import React from "react";
import { ViewportMode } from "../../../types/definitions";
import { useTransitioningVisibleToolIds, useViewportIsTransitioning, useViewportMode, useLiveAndPreviewVisibleToolIds, } from "../../../redux/selectors/redux-selectors";
import { RenderLiveTool } from "./render-live-tool";
import { RenderBuilderTool } from "./render-builder-tool";
import { useEditModeVisibleToolIds } from "../../selectors";
export function RenderPrimaryViewportTools({ viewportDimensions }) {
    const viewportMode = useViewportMode();
    const viewportIsTransitioning = useViewportIsTransitioning();
    const transitioningVisibleToolIds = useTransitioningVisibleToolIds();
    const liveAndPreviewVisibleToolIds = useLiveAndPreviewVisibleToolIds();
    const editModeVisibleToolIds = useEditModeVisibleToolIds();
    let toolIds = viewportMode === ViewportMode.EDIT ? editModeVisibleToolIds : liveAndPreviewVisibleToolIds;
    const RenderTool = viewportMode === ViewportMode.LIVE ? RenderLiveTool : RenderBuilderTool;
    if (viewportIsTransitioning) {
        toolIds = transitioningVisibleToolIds;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(RenderTool, { key: "background", toolId: "background", viewportDimensions: viewportDimensions }),
        React.createElement(RenderTool, { key: "screen", toolId: "screen", viewportDimensions: viewportDimensions }),
        toolIds.map(toolId => (React.createElement(RenderTool, { key: toolId, toolId: toolId, viewportDimensions: viewportDimensions })))));
}
