import React from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { demoCenterModuleTemplates } from "../../../../../../../shared/objects/default-demo-center";
export const DCCMModuleTemplateChooser = ({ onClick }) => {
    return (React.createElement(TemplateContainer, null,
        React.createElement(Title, null, "Templates"),
        React.createElement(List, null, demoCenterModuleTemplates.map(t => (React.createElement(Template, { key: t.title, onMouseDown: () => onClick(t) }, t.title))))));
};
const TemplateContainer = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: -16px;
  gap: 8px;
  padding: 4px 16px 16px 16px;
  border-radius: 4px;
  background: ${newTheme.colors.grey100};
  box-shadow: ${newTheme.shadows.demoCenter};
`;
const Title = styled.div `
  display: flex;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey600};
  padding-left: 8px;
  border-bottom: 1px solid ${newTheme.colors.grey300};
`;
const List = styled.ul `
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0px 2px;
  flex-shrink: 0;
  flex-wrap: wrap;
`;
const Template = styled.li `
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  width: 158px;
  padding: 8px;
  cursor: pointer;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.black};
  &:hover {
    background: ${newTheme.colors.grey200};
  }
`;
