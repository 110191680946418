import axios from "axios";
import { MediaType, ViewportMode } from "../types/definitions";
import { isImageFileExtension } from "./media";
import { cloneDeep, sortBy } from "lodash";
import { getDefaultVisibleToolIds } from "../redux/reducers/tourial-root-reducer";
import { TimerTrigger, TriggerDispatchEvent, } from "../../../shared/legacy/legacy-tool-data";
import { HiddenFieldDataOptions } from "../../../shared/types/integrations";
import { ActiveVariantName, Direction, ToolType } from "../../../shared/types/tool-data-types/common";
import { startTimers } from "./timers";
import { ToolTypes } from "../components/pages/builder/editor/atomic/tool-panel-icon";
export function submitAllIntegrationsForForm(data) {
    axios
        .post(`/integrations/submitAllForForm`, data)
        .then(r => console.log("submitted all integrations for form", r.data))
        .catch(err => console.error("error submitting all integrations for form", err));
}
export const getViewToolsInView = (state) => {
    var _a;
    const { viewport } = state;
    const activeVariantName = getActiveVariantName(state);
    return (_a = state.tourial[activeVariantName]) === null || _a === void 0 ? void 0 : _a.viewTools.filter(tool => tool.pageId === viewport.activeView.pageId && tool.zoomId === viewport.activeView.zoomId);
};
export const getViewToolsInPage = (state) => {
    var _a;
    const { viewport } = state;
    const activeVariantName = getActiveVariantName(state);
    return (_a = state.tourial[activeVariantName]) === null || _a === void 0 ? void 0 : _a.viewTools.filter(tool => tool.pageId === viewport.activeView.pageId);
};
export const getViewTools = (state) => {
    var _a;
    const activeVariantName = getActiveVariantName(state);
    return (_a = state.tourial[activeVariantName]) === null || _a === void 0 ? void 0 : _a.viewTools;
};
export const getSortedViewToolsInView = (state) => {
    // These are sorted alphabetically by page and zoom id combination and z-index, this does not respect page or view/zoom order
    const { viewport } = state;
    return getSortedViewTools(state).filter(tool => tool.pageId === viewport.activeView.pageId && tool.zoomId === viewport.activeView.zoomId);
};
export const getSortedViewTools = (state) => {
    // These are sorted alphabetically by page and zoom id combination and z-index, this does not respect page or view/zoom order
    const activeVariantName = getActiveVariantName(state);
    const { viewTools } = state.tourial[activeVariantName];
    return sortBy(viewTools, ["pageId", "zoomId"]);
};
export const getActiveVariantName = (state) => {
    var _a;
    return (_a = state.viewport.activeVariantName) !== null && _a !== void 0 ? _a : ActiveVariantName.VARIANT_DESKTOP;
};
export const getActiveVariant = (state) => {
    var _a;
    return (_a = state.tourial) === null || _a === void 0 ? void 0 : _a[getActiveVariantName(state)];
};
export const getViewToolsAndToolsInActiveView = (state) => {
    const { viewport } = state;
    const activeVariant = getActiveVariant(state);
    return activeVariant.viewTools
        .filter(vt => vt.pageId === viewport.activeView.pageId && vt.zoomId === viewport.activeView.zoomId)
        .map(viewTool => {
        return {
            viewTool,
            tool: activeVariant.tools[viewTool.toolId],
        };
    });
};
export const getStepToolTipIconData = (state) => {
    const { viewport } = state;
    const activeVariant = getActiveVariant(state);
    const { pages } = activeVariant;
    const activePage = pages.find(p => p.id === viewport.activeView.pageId);
    const activePageViewTools = activeVariant.viewTools.filter(vt => vt.pageId === activePage.id);
    return activePageViewTools.reduce((acc, apvt) => {
        const tool = activeVariant.tools[apvt.toolId];
        if (tool.type === ToolType.Modal) {
            acc[apvt.zoomId] = {
                type: ToolType.Modal,
                subType: null,
            };
        }
        else if (tool.type === ToolType.TooltipV2) {
            if (acc[apvt.zoomId]) {
                if (typeof acc[apvt.zoomId].subType === "number") {
                    acc[apvt.zoomId].subType += 1;
                }
                else {
                    acc[apvt.zoomId].subType = 2;
                    acc[apvt.zoomId].type = ToolTypes.TOOLTIP;
                }
            }
            else {
                acc[apvt.zoomId] = {
                    type: tool.bubble.isEnabled ? ToolTypes.TOOLTIP : ToolTypes[tool.trigger.triggerComponent],
                    subType: tool.bubble.isEnabled ? ToolTypes[tool.trigger.triggerComponent] : null, // "NONE" | "HOTSPOT" : "CLICKZONE"
                };
            }
        }
        return acc;
    }, {});
};
export function submitFormV2(validateFn, tourialId, tourialName, tourialSlug, tourialAccountId, tool, activePageId, activeZoomId, viewportMode, formId, activeVariantName, sessionId, userId, isSessionStarted, setIsSessionStarted, timers, setTimer, showTool, postEvent, hutk, munchkinCookie) {
    var _a;
    const searchParams = new URLSearchParams(window.location.search);
    // TODO(Cody): Fix the HiddenFieldData Type to make TOURIAL_SOURCE optional
    const hiddenFieldData = {
        [HiddenFieldDataOptions.tourialSlug]: tourialSlug,
        [HiddenFieldDataOptions.sessionId]: sessionId,
        [HiddenFieldDataOptions.tourialName]: tourialName,
        [HiddenFieldDataOptions.parentUrl]: "",
        [HiddenFieldDataOptions.referringUrl]: "",
        [HiddenFieldDataOptions.utm_campaign]: searchParams.get(HiddenFieldDataOptions.utm_campaign) || "",
        [HiddenFieldDataOptions.utm_source]: searchParams.get(HiddenFieldDataOptions.utm_source) || "",
        [HiddenFieldDataOptions.utm_medium]: searchParams.get(HiddenFieldDataOptions.utm_medium) || "",
        [HiddenFieldDataOptions.utm_term]: searchParams.get(HiddenFieldDataOptions.utm_term) || "",
        [HiddenFieldDataOptions.utm_content]: searchParams.get(HiddenFieldDataOptions.utm_content) || "",
        [HiddenFieldDataOptions.email]: searchParams.get(HiddenFieldDataOptions.email) || "",
    };
    const submission = {
        tourId: tourialId,
        formId: formId,
        fieldData: tool.fields,
        hiddenFieldData: hiddenFieldData,
        hutk,
        munchkinCookie,
    };
    for (const f of tool.fields) {
        // Early exit if any form fields are invalid
        if (!validateFn(f))
            return false;
    }
    if (viewportMode === ViewportMode.LIVE) {
        if (!isSessionStarted) {
            startTimers(TimerTrigger.SessionStart, timers, setTimer, activePageId, activeZoomId, showTool, postEvent);
        }
        void postEvent({ eventType: "FORM_SUBMIT", toolId: formId });
        submitAllIntegrationsForForm(submission);
        const submitLeadData = {
            fieldData: tool.fields,
            accountId: tourialAccountId,
            tourId: tourialId,
            tourName: tourialName,
            tourUrl: tourialSlug,
            emailAddressAlert: ((_a = tool === null || tool === void 0 ? void 0 : tool.notificationEmailAddresses) === null || _a === void 0 ? void 0 : _a[0]) || "",
        };
        axios
            .post(`/integrations/form/submit`, submitLeadData)
            .catch((e) => console.error("failed to submit form integrations", e));
    }
    return true;
}
export const checkIsChildOfSelectedGroup = (state, toolId, editDetailsId) => {
    const activeVariantName = getActiveVariantName(state);
    const currentTool = state.tourial[activeVariantName].tools[toolId];
    return (currentTool === null || currentTool === void 0 ? void 0 : currentTool.groupToolId) && (currentTool === null || currentTool === void 0 ? void 0 : currentTool.groupToolId) === editDetailsId;
};
export const checkIsSiblingOfSelectedChild = (state, toolId, editDetailsId) => {
    const activeVariantName = getActiveVariantName(state);
    const currentTool = state.tourial[activeVariantName].tools[toolId];
    function getParent() {
        var _a;
        const editDetailsParentGroupId = (_a = state === null || state === void 0 ? void 0 : state.tourial[activeVariantName].tools[editDetailsId]) === null || _a === void 0 ? void 0 : _a.groupToolId;
        return editDetailsParentGroupId !== null && editDetailsParentGroupId !== void 0 ? editDetailsParentGroupId : "";
    }
    const editDetailsParentGroupId = getParent();
    return editDetailsParentGroupId && editDetailsParentGroupId === currentTool.groupToolId;
};
// function that returns cloudinary urls for all linked activeViews given the current activeView
export const getAllLinkedCloudinaryUrls = (activeView, activeVariant, viewTools, isInit) => {
    const linkedActiveViews = getLinkedActiveViews(activeView, activeVariant, viewTools);
    // include initial view in the list so all media is prefetched including hidden viewTools
    if (isInit)
        linkedActiveViews.push(activeView);
    return getCloudinaryUrlsFromLinkedActiveViews(activeVariant, linkedActiveViews);
};
function getLinkedActiveViews(activeView, activeVariant, viewTools) {
    const currPageIndex = activeVariant.pages.findIndex(p => p.id === activeView.pageId);
    const hasNextPage = currPageIndex < activeVariant.pages.length - 1;
    const currZoomIndex = activeVariant.pages[currPageIndex].zooms.findIndex(z => z.id === activeView.zoomId);
    const hasNextZoom = currZoomIndex < activeVariant.pages[currPageIndex].zooms.length - 1;
    const TriggersThatChangeActiveView = [
        ...(hasNextPage
            ? [TriggerDispatchEvent.NEXT_PAGE, TriggerDispatchEvent.NEXT_ZOOM]
            : hasNextZoom
                ? [TriggerDispatchEvent.NEXT_ZOOM]
                : []),
        TriggerDispatchEvent.CHANGE_PAGE,
        TriggerDispatchEvent.CHANGE_ZOOM,
    ];
    return activeVariant.triggerListeners.reduce((views, tl) => {
        const tlInActiveView = viewTools.find(vt => vt.toolId === tl.toolId);
        const tlChangesActiveView = TriggersThatChangeActiveView.includes(tl.dispatchEvent);
        if (tlInActiveView && tlChangesActiveView) {
            if (tl.dispatchEvent === TriggerDispatchEvent.NEXT_ZOOM) {
                views.push({
                    pageId: hasNextZoom ? activeView.pageId : activeVariant.pages[currPageIndex + 1].id,
                    zoomId: hasNextZoom ? activeVariant.pages[currPageIndex].zooms[currZoomIndex + 1].id : "MAIN",
                });
            }
            else {
                views.push({
                    pageId: tl.dispatchEvent === TriggerDispatchEvent.CHANGE_PAGE ||
                        tl.dispatchEvent === TriggerDispatchEvent.CHANGE_ZOOM
                        ? tl.view.pageId
                        : activeVariant.pages[currPageIndex + 1].id,
                    zoomId: tl.dispatchEvent === TriggerDispatchEvent.CHANGE_ZOOM ? tl.view.zoomId : "MAIN",
                });
            }
        }
        return views;
    }, []);
}
function getCloudinaryUrlsFromLinkedActiveViews(activeVariant, linkedActiveViews) {
    // get image/video urls for viewTools on linked views
    const linkedCloudinaryUrls = activeVariant === null || activeVariant === void 0 ? void 0 : activeVariant.viewTools.reduce((urls, vt) => {
        const currView = { pageId: vt.pageId, zoomId: vt.zoomId };
        const containsCurrView = !!linkedActiveViews.find(lav => JSON.stringify(lav) === JSON.stringify(currView));
        if (containsCurrView) {
            const currTool = activeVariant.tools[vt.toolId];
            if ((currTool.type === ToolType.Image || currTool.type === ToolType.Video) && !urls[currTool.src]) {
                urls[currTool.src] = currTool.type === ToolType.Image ? MediaType.IMG : MediaType.VIDEO;
            }
        }
        return urls;
    }, {});
    // get background/screen urls for linked views
    linkedActiveViews.forEach((activeView) => {
        var _a;
        const viewScreen = activeVariant.tools.screen.media[activeView.pageId];
        const viewBackground = (_a = activeVariant.tools.background.backgrounds[activeView.pageId]) === null || _a === void 0 ? void 0 : _a.src;
        if (viewScreen && !linkedCloudinaryUrls[viewScreen]) {
            linkedCloudinaryUrls[viewScreen] = isImageFileExtension(viewScreen) ? MediaType.IMG : MediaType.VIDEO;
        }
        if (viewBackground && !linkedCloudinaryUrls[viewBackground]) {
            linkedCloudinaryUrls[viewBackground] = MediaType.IMG;
        }
    });
    return linkedCloudinaryUrls;
}
const removeVariantOrphanedListeners = (variant) => {
    return Object.assign(Object.assign({}, variant), { triggerListeners: variant.triggerListeners.filter(tl => {
            var _a, _b;
            // Check page & zoom exist
            if (tl.dispatchEvent === TriggerDispatchEvent.CHANGE_PAGE ||
                tl.dispatchEvent === TriggerDispatchEvent.CHANGE_ZOOM) {
                return (tl.view.pageId &&
                    tl.view.zoomId &&
                    ((_b = (_a = variant.pages.find(p => p.id === tl.view.pageId)) === null || _a === void 0 ? void 0 : _a.zooms) === null || _b === void 0 ? void 0 : _b.find(z => z.id === tl.view.zoomId)));
            }
            // Check tool exists
            if (tl.dispatchEvent === TriggerDispatchEvent.DISPLAY_TOOL ||
                tl.dispatchEvent === TriggerDispatchEvent.HIDE_TOOL ||
                tl.dispatchEvent === TriggerDispatchEvent.TOGGLE_TOOL) {
                return tl.displayToolId && variant.tools[tl.displayToolId];
            }
            // Check href exists
            if (tl.dispatchEvent === TriggerDispatchEvent.EXTERNAL_LINK) {
                return tl.href;
            }
            // Check form exists
            if (tl.dispatchEvent === TriggerDispatchEvent.SUBMIT_FORM) {
                return tl.formId && variant.tools[tl.formId];
            }
            return true;
        }) });
};
const removeFormDataFromVariant = (variant) => {
    variant.viewTools.forEach(vt => {
        if (variant.tools[vt.toolId].type == ToolType.FormV2) {
            const formTool = variant.tools[vt.toolId];
            formTool.fields.forEach(field => {
                field.value = "";
            });
        }
    });
    return variant;
};
const ensurePerPageVideoControls = (variant) => {
    var _a, _b;
    for (const page in (_b = (_a = variant === null || variant === void 0 ? void 0 : variant.tools) === null || _a === void 0 ? void 0 : _a.screen) === null || _b === void 0 ? void 0 : _b.media) {
        if (!isImageFileExtension(variant.tools.screen.media[page]) && !variant.tools.screen.perPageVideoControls[page]) {
            variant.tools.screen.perPageVideoControls[page] = {
                isMuted: true,
                autoPlay: false,
                controls: true,
            };
        }
    }
    return variant;
};
export const pruneTourial = (tourial) => {
    const prune = function (variant) {
        return removeFormDataFromVariant(removeVariantOrphanedListeners(ensurePerPageVideoControls(variant)));
    };
    const r = Object.assign(Object.assign({}, tourial), { variantDesktop: prune(tourial.variantDesktop) });
    if (r.variantMobile) {
        r.variantMobile = prune(tourial.variantMobile);
    }
    return r;
};
export const getPreviousActiveView = (prevViewport, tourial, activeVariantName) => {
    const { pageId: prevPageId, zoomId: prevZoomId } = prevViewport.activeView;
    // "prev" refers to the old state, while "previous" refers to the view before the old view
    const prevPages = tourial[activeVariantName].pages;
    let prevPageIndex = -1;
    let previousActiveViewHasAutostart = false;
    let previousActiveView = { pageId: undefined, zoomId: undefined };
    function getLastViewOfPreviousPage() {
        const previousPageId = prevPages[prevPageIndex - 1].id;
        const previousPageZooms = prevPages[prevPageIndex - 1].zooms;
        return {
            pageId: previousPageId,
            zoomId: previousPageZooms[previousPageZooms.length - 1].id,
        };
    }
    // find the previous zoom
    const prevZoomIndex = prevPages
        .find(p => {
        prevPageIndex++;
        if (p.id === prevPageId && (p === null || p === void 0 ? void 0 : p.autoStartZoomId) === prevZoomId)
            previousActiveViewHasAutostart = true;
        return p.id === prevPageId;
    })
        .zooms.findIndex(z => z.id === prevZoomId);
    if (prevZoomIndex > 0) {
        const previousZoomId = prevPages[prevPageIndex].zooms[prevZoomIndex - 1].id;
        // go to the last view of the previous page if the previous view is MAIN & previousActiveViewHasAutostart is true
        if (previousZoomId === "MAIN" && previousActiveViewHasAutostart) {
            previousActiveView = prevPageIndex > 0 ? getLastViewOfPreviousPage() : previousActiveView;
        }
        else {
            previousActiveView = {
                pageId: prevPageId,
                zoomId: previousZoomId,
            };
        }
    }
    // otherwise if the prev view is MAIN, go to the last view of previous page
    else if (prevZoomIndex === 0 && prevPageIndex > 0) {
        previousActiveView = getLastViewOfPreviousPage();
    }
    return previousActiveView;
};
export function getValidViewport(prevViewport, nextTourial) {
    var _a, _b;
    const { pageId: prevPageId, zoomId: prevZoomId } = prevViewport.activeView;
    const { activeVariantName: prevActiveVariantName } = prevViewport;
    // if last variant/page/zoom still exists
    if ((_b = (_a = nextTourial[prevActiveVariantName]) === null || _a === void 0 ? void 0 : _a.pages) === null || _b === void 0 ? void 0 : _b.find(p => p.id === prevPageId && p.zooms.find(z => z.id === prevZoomId))) {
        return prevViewport;
    }
    else {
        const activeVariantName = nextTourial[prevActiveVariantName]
            ? prevActiveVariantName
            : ActiveVariantName.VARIANT_DESKTOP;
        const activeView = Object.assign(Object.assign({}, prevViewport.activeView), { pageId: nextTourial[activeVariantName].pages[0].id, zoomId: "MAIN" });
        return Object.assign(Object.assign({}, prevViewport), { previousView: null, activeVariantName,
            activeView, visibleToolIds: getDefaultVisibleToolIds(nextTourial[activeVariantName].viewTools, activeView, prevViewport.mode) });
    }
}
export function getLiveAndPreviewVisibleToolIds(activeVariant, viewport) {
    return activeVariant.viewTools
        .filter(vt => vt.pageId === viewport.activeView.pageId &&
        vt.zoomId === viewport.activeView.zoomId &&
        viewport.visibleToolIds[vt.toolId])
        .map(vt => vt.toolId);
}
export function deleteTool(toolId, prevState) {
    if (toolId === "screen")
        return prevState;
    const variant = getActiveVariantName(prevState);
    const activePageId = prevState.viewport.activeView.pageId;
    const activePage = prevState.tourial[variant].pages.find(p => p.id === activePageId);
    const isAutomatedAssigned = typeof activePage.isAutomated === "boolean";
    const tools = cloneDeep(prevState.tourial[variant].tools);
    tools === null || tools === void 0 ? true : delete tools[toolId];
    const viewTools = cloneDeep(prevState.tourial[variant].viewTools).filter(vt => vt.toolId !== toolId);
    const viewToolsInView = viewTools.filter(tool => tool.pageId === prevState.viewport.activeView.pageId);
    const hasNoTools = !viewToolsInView.length;
    let pages = prevState.tourial[variant].pages;
    if (isAutomatedAssigned && hasNoTools) {
        pages = cloneDeep(prevState.tourial[variant].pages);
        delete pages.find(p => p.id === activePageId).isAutomated;
    }
    return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [variant]: Object.assign(Object.assign({}, prevState.tourial[variant]), { pages,
                tools,
                viewTools }) }) });
}
export function nudgeTool(toolId, direction, prevState) {
    if (!toolId)
        return prevState;
    const variant = getActiveVariantName(prevState);
    const tools = cloneDeep(prevState.tourial[variant].tools);
    const newTool = tools[toolId];
    const incrememnt = 0.1;
    switch (newTool.type) {
        case ToolType.TooltipV2:
            switch (direction) {
                case Direction.UP:
                    newTool.trigger.position.y -= incrememnt;
                    break;
                case Direction.DOWN:
                    newTool.trigger.position.y += incrememnt;
                    break;
                case Direction.LEFT:
                    newTool.trigger.position.x -= incrememnt;
                    break;
                case Direction.RIGHT:
                    newTool.trigger.position.x += incrememnt;
                    break;
            }
            break;
        case ToolType.GlobalButtons:
            switch (direction) {
                case Direction.UP:
                    newTool.position.y -= incrememnt;
                    break;
                case Direction.DOWN:
                    newTool.position.y += incrememnt;
                    break;
                case Direction.LEFT:
                    newTool.position.x -= incrememnt;
                    break;
                case Direction.RIGHT:
                    newTool.position.x += incrememnt;
                    break;
            }
    }
    return Object.assign(Object.assign({}, prevState), { tourial: Object.assign(Object.assign({}, prevState.tourial), { [variant]: Object.assign(Object.assign({}, prevState.tourial[variant]), { tools: Object.assign(Object.assign({}, prevState.tourial[variant].tools), { [newTool.id]: newTool }) }) }) });
}
