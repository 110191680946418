import React from "react";
import styled from "styled-components";
import { DCIconType, DCModuleType } from "../../../../../../shared/types/demo-center";
import { DCStyledTitleText } from "./dc-styled-text";
import { DCIcon } from "./dc-icon";
import { newTheme } from "../../../styled/theme";
export const DCCardButton = ({ config, theme, label, imageUrl, isSelected, type, index, onCardClick, }) => {
    const { labelStyle } = config;
    return (React.createElement(CardContainer, { theme: theme, config: config, isSelected: isSelected, onClick: onCardClick, className: type === DCModuleType.MULTIPLE ? "multi" : type === DCModuleType.BOOLEAN ? "boolean" : "single" },
        type === DCModuleType.MULTIPLE && React.createElement(Checkbox, { theme: theme, config: config, isSelected: isSelected }),
        imageUrl ? (React.createElement(Image, { src: imageUrl })) : (React.createElement(React.Fragment, null,
            type === DCModuleType.BOOLEAN && (React.createElement(ThumbIcon, { theme: theme, isSelected: isSelected, index: index, cardButtonConfig: config })),
            type === DCModuleType.SCALE && (React.createElement(ScaleIcon, { theme: theme, isSelected: isSelected, index: index, cardButtonConfig: config })))),
        React.createElement(DCStyledTitleText, { config: labelStyle, style: !label ? { fontStyle: "italic", color: newTheme.colors.grey500 } : null }, label || "Option Name"),
        type !== DCModuleType.MULTIPLE && React.createElement(RadioButton, { theme: theme, config: config, isSelected: isSelected })));
};
const Checkbox = ({ isSelected, config, theme }) => {
    return (React.createElement(CheckboxContainer, { config: config, theme: theme, className: isSelected ? "selected" : null },
        React.createElement(DCIcon, { type: DCIconType.CHECK_CIRCLE })));
};
const RadioButton = ({ isSelected, config, style, theme }) => {
    return (React.createElement(RadioButtonContainer, { config: config, theme: theme, className: isSelected ? "selected" : null, style: style },
        React.createElement(OuterCircle, { className: "outer-circle" },
            React.createElement(InnerCircle, { className: "inner-circle" }))));
};
const ThumbIcon = ({ index, isSelected, cardButtonConfig, theme }) => {
    const { activeColor } = cardButtonConfig;
    const style = {
        color: isSelected ? activeColor : theme.neutralSecondary,
        transition: "color, 0.2s ease-in",
    };
    return index === 0 ? (React.createElement(DCIcon, { type: DCIconType.THUMBS_UP, style: style })) : (React.createElement(DCIcon, { type: DCIconType.THUMBS_DOWN, style: style }));
};
const ScaleIcon = ({ index, isSelected, cardButtonConfig, theme }) => {
    const { activeColor } = cardButtonConfig;
    const style = {
        color: isSelected ? activeColor : theme.neutralSecondary,
        transition: "color, 0.2s ease-in",
    };
    const icons = [DCIconType.SCALE_HIGH, DCIconType.SCALE_MEDIUM, DCIconType.SCALE_LOW];
    return React.createElement(DCIcon, { type: icons[index], style: style });
};
const CardContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 244px;
  padding: 32px 12px 12px 12px;
  background-color: ${p => p.theme.neutralPrimary};
  border-color: ${p => (p.isSelected ? p.config.activeColor : p.theme.neutralPrimary)};
  border-style: solid;
  border-width: 1px;
  border-radius: ${p => p.config.borderRadius}px;
  transition: border-color 0.1s linear;
  ${p => (p.config.shadow ? p.theme.shadow : "")};
  &:hover {
    border-color: ${p => p.config.activeColor};
  }
  cursor: pointer;
  &.multi {
    flex-direction: row;
    width: 334px;
    padding: 12px 16px;
    ${DCStyledTitleText} {
      flex: 1;
      text-align: left;
      line-height: 1.25;
    }
  }
  &.boolean {
    padding: 32px 12px 24px 12px;
  }
`;
const Image = styled.img `
  max-width: 40px;
  max-height: 40px;
  object-fit: contain;
`;
const CheckboxContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: ${p => p.theme.neutralSecondary};
  transition: color 0.2s ease-in;
  &.selected {
    color: ${p => p.config.activeColor};
  }
`;
const RadioButtonContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  .outer-circle {
    border-color: ${p => p.theme.neutralSecondary};
  }
  .inner-circle {
    background-color: ${p => p.theme.neutralSecondary};
  }
  &.selected {
    .outer-circle {
      border-color: ${p => p.config.activeColor};
    }
    .inner-circle {
      background-color: ${p => p.config.activeColor};
      width: 16px;
      height: 16px;
      opacity: 1;
    }
  }
`;
const OuterCircle = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-width: 2px;
  border-style: solid;
  border-radius: 28px;
  transition: border-color 0.2s ease-in;
`;
const InnerCircle = styled.div `
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 16px;
  opacity: 0;
  transition:
    background-color 0.2s ease-in,
    opacity 0.2s ease-in,
    width 0.2s ease-in,
    height 0.2s ease-in;
`;
