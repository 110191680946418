import React from "react";
import styled from "styled-components";
import { dcFadeIn } from "../helpers/dc-style-helpers";
import { DCGallerySection } from "./dc-gallery-section";
export const DCGallery = React.forwardRef(({ modules, config, getItemsForModule, onCardSelect, onFilter, style, theme }, ref) => {
    const { gallerySection, itemCard, tag } = config;
    return (React.createElement(ModuleContainer, { ref: ref, style: style }, modules.length > 0 &&
        modules.map(m => (React.createElement(DCGallerySection, { key: `module-${m.slug}`, gallerySectionConfig: gallerySection, itemCardConfig: itemCard, tagConfig: tag, theme: theme, module: m, items: getItemsForModule(m), onCardSelect: onCardSelect, onFilter: selectedFilters => onFilter(m, selectedFilters) })))));
});
const ModuleContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 106px 24px 80px 24px;
  animation: ${dcFadeIn} 0.2s ease-in;
`;
