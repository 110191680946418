import { useActiveVariantName, useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { cloneDeep } from "lodash";
import { LabelledTextInput } from "../../../../pages/builder/editor/atomic/labelled-text-input";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import TemplateIcon from "../../../../../../assets/images/v3/editor-accordian-template-icon.svg";
import ModalIcon from "../../../../../../assets/images/v3/editor-accordian-modal-icon.svg";
import BodyIcon from "../../../../../../assets/images/v3/editor-accordian-body-icon.svg";
import HeadlineIcon from "../../../../../../assets/images/v3/editor-accordian-headline-icon.svg";
import LogoIcon from "../../../../../../assets/images/v3/editor-accordian-logo-icon.svg";
import ButtonIcon from "../../../../../../assets/images/v3/editor-accordian-button-icon.svg";
import SpotlightIcon from "../../../../../../assets/images/v3/editor-accordian-spotlight-icon.svg";
import { ModalTypeSelector } from "../../../../pages/builder/editor/atomic/modal-type-selector";
import { ShapeSelector } from "../../../../pages/builder/editor/atomic/shape-selector";
import { GraphicType } from "../../../../../../../shared/types/tool-data-types/common";
import { ModalButtonType, ModalType, } from "../../../../../../../shared/types/tool-data-types/modal-data";
import { InteractionName, InteractionType, makeInteraction, } from "../../../../../../../shared/types/tool-data-types/interactions";
import { RichTextEditorContainer } from "../../../tooltip-v2/editor/components/style-section";
import { EditorAccordion } from "../../../../pages/builder/editor/editor-accordion";
import { ShapeSelectorLarge } from "../../../../pages/builder/editor/atomic/shape-selector-large";
import { ColorPicker } from "../../../../pages/builder/editor/atomic/color-picker";
import { LogoUploadSection } from "./logo-upload-section";
import { GraphicUploadSection } from "./graphic-upload-section";
import { FontSelector } from "../../../tooltip-v2/editor/components/font-selector";
import { getBodyFontSizeDropdownOptions, getHeadlineFontSizeDropdownOptions, } from "../../../../../helpers/dropdown-options";
import { OpacitySlider } from "../../../../pages/builder/editor/atomic/opacity-slider";
import { ModalButtonSection, READABLE_BUTTON_OPTIONS } from "./modal-button-section";
import { TourialEditorFocusFields } from "../../../../../types/definitions";
import { useFocusTourialEditorField } from "../../../../../hooks/use-focus-field";
import { TCheckbox } from "../../../../styled/t-checkbox";
import { THorizontalSlider } from "../../../../styled/t-horizontal-slider";
import { AdditionalControls, ControlContainer, ControlLabel } from "../../../../styled/editor-components";
import { RichTextEditor } from "../../../../../helpers/rich-text-editor";
export function ModalStyleSection(props) {
    var _a, _b, _c;
    const activeVariantName = useActiveVariantName();
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    const reactQuillRef = useRef();
    const [openId, setOpenId] = useState("template-accordion");
    useFocusTourialEditorField(setOpenId);
    return (React.createElement(Section, null,
        React.createElement(EditorAccordion, { id: "template-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: TemplateIcon, title: "Template", isEnabled: true },
            React.createElement(ModalTypeSelector, { type: tool.modalType, onChange: e => {
                    const t = cloneDeep(tool);
                    t.modalType = e;
                    switch (e) {
                        case ModalType.TEXT:
                            t.buttons.type = ModalButtonType.NORMAL;
                            t.body.isEnabled = true;
                            t.headline.isEnabled = true;
                            t.featureGraphic.isEnabled = false;
                            t.buttons.button1.text = "Back";
                            t.buttons.button2.text = "Next";
                            t.buttons.button1.interaction = makeInteraction(InteractionType.CLICK, InteractionName.PREVIOUS_STEP);
                            t.buttons.button2.interaction = makeInteraction(InteractionType.CLICK, InteractionName.NEXT_STEP);
                            t.buttons.number = 2;
                            t.buttons.button1.isEnabled = true;
                            t.buttons.button2.isEnabled = true;
                            t.buttons.button3.isEnabled = false;
                            t.buttons.button4.isEnabled = false;
                            break;
                        case ModalType.PERSONAS:
                            t.buttons.type = ModalButtonType.JUMBO;
                            t.body.isEnabled = true;
                            t.headline.isEnabled = true;
                            t.featureGraphic.isEnabled = false;
                            t.buttons.button1.text = "Persona 1";
                            t.buttons.button2.text = "Persona 2";
                            t.buttons.button1.interaction = makeInteraction(InteractionType.CLICK, InteractionName.NULL);
                            t.buttons.button2.interaction = makeInteraction(InteractionType.CLICK, InteractionName.NULL);
                            t.buttons.number = 2;
                            t.buttons.button1.isEnabled = true;
                            t.buttons.button2.isEnabled = true;
                            t.buttons.button3.isEnabled = false;
                            t.buttons.button4.isEnabled = false;
                            break;
                        case ModalType.VIDEO:
                            t.buttons.type = ModalButtonType.NORMAL;
                            t.body.isEnabled = false;
                            t.headline.isEnabled = false;
                            t.featureGraphic.isEnabled = true;
                            t.featureGraphic.type = GraphicType.VIDEO;
                            t.featureGraphic.videoControls = { autoPlay: true };
                            t.buttons.button1.text = "Learn More";
                            t.buttons.button2.text = "Button";
                            t.buttons.button1.interaction = makeInteraction(InteractionType.CLICK, InteractionName.NULL);
                            t.buttons.button2.interaction = makeInteraction(InteractionType.CLICK, InteractionName.NULL);
                            t.buttons.number = 1;
                            t.buttons.button1.isEnabled = true;
                            t.buttons.button2.isEnabled = false;
                            t.buttons.button3.isEnabled = false;
                            t.buttons.button4.isEnabled = false;
                            break;
                        case ModalType.IMAGE:
                            t.buttons.type = ModalButtonType.NORMAL;
                            t.body.isEnabled = true;
                            t.headline.isEnabled = false;
                            t.featureGraphic.isEnabled = true;
                            t.featureGraphic.type = GraphicType.IMAGE;
                            t.buttons.button1.text = "Back";
                            t.buttons.button2.text = "Next";
                            t.buttons.button1.interaction = makeInteraction(InteractionType.CLICK, InteractionName.PREVIOUS_STEP);
                            t.buttons.button2.interaction = makeInteraction(InteractionType.CLICK, InteractionName.NEXT_STEP);
                            t.buttons.number = 2;
                            t.buttons.button1.isEnabled = true;
                            t.buttons.button2.isEnabled = true;
                            t.buttons.button3.isEnabled = false;
                            t.buttons.button4.isEnabled = false;
                            break;
                    }
                    updateTool(t);
                } })),
        React.createElement(EditorAccordion, { id: "modal-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: ModalIcon, title: "Modal", isEnabled: true },
            React.createElement(ShapeSelectorLarge, { shape: tool.background.shape, disableSquircle: true, onChange: e => {
                    const t = cloneDeep(tool);
                    t.background.shape = e;
                    updateTool(t);
                } }),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Background Color"),
                React.createElement(ColorPicker, { color: tool.background.backgroundColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.background.backgroundColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Border Color"),
                React.createElement(ColorPicker, { color: tool.background.borderColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.background.borderColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Button Shape"),
                React.createElement(ShapeSelector, { shape: tool.buttons.shape, onChange: (shape) => {
                        const t = cloneDeep(tool);
                        t.buttons.shape = shape;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Modal Width"),
                React.createElement(THorizontalSlider, { min: 300, max: 700, value: ((_a = tool.size) === null || _a === void 0 ? void 0 : _a.width) || 480, onChange: e => {
                        const t = cloneDeep(tool);
                        if (!t.size) {
                            t.size = { width: 480, height: 0 };
                        }
                        t.size.width = e;
                        updateTool(t);
                    } }))),
        tool.buttons.type === ModalButtonType.NORMAL && (React.createElement(EditorAccordion, { id: "feature-graphic-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: LogoIcon, title: tool.modalType === ModalType.VIDEO ? "Video" : "Feature image", isEnabled: tool.featureGraphic.isEnabled, isHidden: tool.modalType === ModalType.TEXT || tool.modalType === ModalType.PERSONAS },
            React.createElement(AdditionalControls, null,
                React.createElement(GraphicUploadSection, { modalType: tool.modalType, graphic: tool.featureGraphic, updateFn: e => {
                        const t = cloneDeep(tool);
                        t.featureGraphic = e;
                        updateTool(t);
                    } }),
                tool.modalType === ModalType.VIDEO && (React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                    React.createElement("div", { style: { width: "auto" } }, "Autoplay video"),
                    React.createElement(TCheckbox, { checked: (_c = (_b = tool.featureGraphic.videoControls) === null || _b === void 0 ? void 0 : _b.autoPlay) !== null && _c !== void 0 ? _c : false, onChange: checked => {
                            const t = cloneDeep(tool);
                            t.featureGraphic.videoControls = Object.assign(Object.assign({}, tool.featureGraphic.videoControls), { autoPlay: checked });
                            updateTool(t);
                        } })))))),
        React.createElement(EditorAccordion, { id: "logo-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: LogoIcon, title: "Logo", isEnabled: tool.logo.isEnabled, isHidden: !tool.logo.isEnabled },
            React.createElement(LogoUploadSection, { logo: tool.logo, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.logo = e;
                    updateTool(t);
                } })),
        React.createElement(EditorAccordion, { id: TourialEditorFocusFields.MODAL_HEADLINE_TEXT, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: HeadlineIcon, title: "Headline", isEnabled: tool.headline.isEnabled, isHidden: !tool.headline.isEnabled },
            React.createElement(LabelledTextInput, { name: "Headline text", value: tool.headline.text, style: { marginBottom: 8 }, onChange: (e) => {
                    const t = cloneDeep(tool);
                    t.headline.text = e.target.value;
                    updateTool(t);
                } }),
            React.createElement(FontSelector, { text: tool.headline, sizeOptions: getHeadlineFontSizeDropdownOptions(activeVariantName), updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.headline = e;
                    updateTool(t);
                } })),
        React.createElement(EditorAccordion, { id: TourialEditorFocusFields.MODAL_BODY_TEXT, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: BodyIcon, title: "Body", isEnabled: tool.body.isEnabled, isHidden: !tool.body.isEnabled },
            React.createElement(RichTextEditorContainer, null,
                React.createElement(ControlLabel, null, "Body text"),
                React.createElement(RichTextEditor, { id: tool.id, value: tool.body.text, onChange: value => {
                        const t = cloneDeep(tool);
                        t.body.text = value;
                        updateTool(t);
                    }, ref: reactQuillRef })),
            React.createElement(FontSelector, { text: tool.body, sizeOptions: getBodyFontSizeDropdownOptions(activeVariantName), hideTextStyle: true, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.body = e;
                    updateTool(t);
                } })),
        tool.buttons.button1.isEnabled && (React.createElement(EditorAccordion, { id: TourialEditorFocusFields.MODAL_BUTTON_1, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: ButtonIcon, title: `${tool.modalType === ModalType.PERSONAS ? "Persona" : "Button"} 1 (${getReadableButtonAction(tool.buttons.button1.interaction.name)})`, isEnabled: tool.buttons.button1.isEnabled },
            React.createElement(ModalButtonSection, { button: tool.buttons.button1, type: tool.buttons.type, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.buttons.button1 = e;
                    updateTool(t);
                } }))),
        tool.buttons.button2.isEnabled && (React.createElement(EditorAccordion, { id: TourialEditorFocusFields.MODAL_BUTTON_2, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: ButtonIcon, title: `${tool.modalType === ModalType.PERSONAS ? "Persona" : "Button"} 2 (${getReadableButtonAction(tool.buttons.button2.interaction.name)})`, isEnabled: tool.buttons.button2.isEnabled },
            React.createElement(ModalButtonSection, { button: tool.buttons.button2, type: tool.buttons.type, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.buttons.button2 = e;
                    updateTool(t);
                } }))),
        tool.buttons.button3.isEnabled && (React.createElement(EditorAccordion, { id: TourialEditorFocusFields.MODAL_BUTTON_3, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: ButtonIcon, title: `${tool.modalType === ModalType.PERSONAS ? "Persona" : "Button"} 3 (${getReadableButtonAction(tool.buttons.button3.interaction.name)})`, isEnabled: tool.buttons.button3.isEnabled },
            React.createElement(ModalButtonSection, { button: tool.buttons.button3, type: tool.buttons.type, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.buttons.button3 = e;
                    updateTool(t);
                } }))),
        tool.buttons.button4.isEnabled && (React.createElement(EditorAccordion, { id: TourialEditorFocusFields.MODAL_BUTTON_4, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: ButtonIcon, title: `${tool.modalType === ModalType.PERSONAS ? "Persona" : "Button"} 4 (${getReadableButtonAction(tool.buttons.button4.interaction.name)})`, isEnabled: tool.buttons.button4.isEnabled },
            React.createElement(ModalButtonSection, { button: tool.buttons.button4, type: tool.buttons.type, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.buttons.button4 = e;
                    updateTool(t);
                    console.log(t);
                } }))),
        React.createElement(EditorAccordion, { id: "overlay-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: SpotlightIcon, title: "Overlay", isEnabled: tool.overlay.isEnabled, isHidden: !tool.overlay.isEnabled },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Opacity"),
                React.createElement(OpacitySlider, { value: tool.overlay.opacity, onChange: (e) => {
                        const t = cloneDeep(tool);
                        t.overlay.opacity = e;
                        updateTool(t);
                    } })))));
    function getReadableButtonAction(name) {
        return !name || name === "NULL" ? "No action" : READABLE_BUTTON_OPTIONS.get(name);
    }
}
const Section = styled.div `
  height: calc(100% - 31px);
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
