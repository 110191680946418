export var MicrositeEditorMode;
(function (MicrositeEditorMode) {
    MicrositeEditorMode["EDIT"] = "EDIT";
    MicrositeEditorMode["PUBLISH"] = "PUBLISH";
})(MicrositeEditorMode || (MicrositeEditorMode = {}));
export var MicrositeEditorLayout;
(function (MicrositeEditorLayout) {
    MicrositeEditorLayout["DESKTOP"] = "DESKTOP";
    MicrositeEditorLayout["MOBILE"] = "MOBILE";
})(MicrositeEditorLayout || (MicrositeEditorLayout = {}));
