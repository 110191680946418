export function convertEventToPublicEvent(e) {
    var _a;
    return {
        eventType: e.eventType,
        tourId: e.tourId,
        stepId: `${e.pageId}_${e.zoomId}`,
        activeVariantName: e.activeVariantName,
        interaction: (_a = e.triggerListenerInteraction) === null || _a === void 0 ? void 0 : _a.triggerListener,
    };
}
export function convertDCEventToPublicEvent(e) {
    return {
        eventType: e.eventType,
        demoCenterId: e.democenterId,
        moduleId: e.democenterModuleId,
        contentId: e.democenterContentId,
        contentProgress: e.democenterContentProgress,
    };
}
