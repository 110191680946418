import React, { useState } from "react";
import TButton from "../../styled/t-button";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import TModal from "../../styled/t-modal";
import Thumbnail from "./thumbnail";
import styled from "styled-components";
import { newTheme } from "../../styled/theme";
import TInput from "../../styled/t-input";
import ThumbnailViewport from "../../renderer/thumbnail/thumbnail-viewport";
import { ToastModes } from "../../../types/definitions";
export default function RenameModal({ tourialData, setIsDropdownOpen, setIsRenameModalOpen, reload, addToast, }) {
    const [name, setName] = useState(tourialData.tourName);
    return (React.createElement(TModal, { onClose: () => setIsRenameModalOpen(false), title: "Rename Tourial" },
        React.createElement(React.Fragment, null,
            React.createElement(ThumbnailContainer, null, tourialData.version === 3 ? (React.createElement(ThumbnailViewport, { tourialData: tourialData, isLink: false, scale: 1.3 })) : (React.createElement(Thumbnail, { tourialData: tourialData, isLink: false, scale: 1.3 }))),
            React.createElement(InputContainer, null,
                React.createElement(TInput, { placeholder: "New Tourial Name", value: name, onChange: e => setName(e.target.value), style: { width: 335 }, autoFocus: true, onFocus: e => e.target.select() }),
                React.createElement(TButton, { onClick: () => {
                        TourialApiCalls.Tourials.postTourialName(tourialData.tourId, name)
                            .then(() => {
                            setIsRenameModalOpen(false);
                            setIsDropdownOpen(false);
                            addToast({ message: "Rename successful", mode: ToastModes.SUCCESS });
                            reload();
                        })
                            .catch(e => {
                            console.error(e);
                            addToast({ message: "Rename failed", mode: ToastModes.ERROR });
                        });
                    } }, "Save")))));
}
const ThumbnailContainer = styled.div `
  ${newTheme.patterns.polkaDot}
  width: 100%;
  height: 249px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${newTheme.colors.grey300};
`;
const InputContainer = styled.div `
  height: 96px;
  width: 100%;
  font: ${newTheme.fonts.medium};
  display: flex;
  gap: 24px;
  padding: 24px;
  align-items: center;
`;
