import { roundToHundredths } from "../../../shared/functions/helpers";
const videoTrimRegex = /\/upload\/(so_|du_)([\d.]+),(so_|du_)([\d.]+)\//;
export function untrimVideo(trimmedSrc) {
    if (!videoTrimRegex.test(trimmedSrc))
        return trimmedSrc;
    const match = videoTrimRegex.exec(trimmedSrc);
    return trimmedSrc.replace(match[0], "/upload/");
}
export function isVideoTrimmed(src) {
    return videoTrimRegex.test(src);
}
export function getTrimmedVideoDuration(trimmedSrc) {
    if (!videoTrimRegex.test(trimmedSrc))
        return 0;
    const match = videoTrimRegex.exec(trimmedSrc);
    for (let i = 0; i < match.length; i++) {
        if (match[i] === "du_") {
            return parseFloat(match[i + 1]);
        }
    }
    return 0;
}
export function getTrimmedVideoStartOffset(trimmedSrc) {
    if (!videoTrimRegex.test(trimmedSrc))
        return 0;
    const match = videoTrimRegex.exec(trimmedSrc);
    for (let i = 0; i < match.length; i++) {
        if (match[i] === "so_") {
            return parseFloat(match[i + 1]);
        }
    }
    return 0;
}
export function trimVideoForThumbnail(src, startOffset) {
    src = untrimVideo(src);
    startOffset = roundToHundredths(startOffset);
    const transformation = `so_${startOffset}/f_webp`;
    return src.replace("/upload/", `/upload/${transformation}/`);
}
export function getBaseMedia(media, src) {
    return media.find(m => m.src === untrimVideo(src));
}
export function getBaseMediaIndex(media, src) {
    return media.findIndex(m => m.src === untrimVideo(src));
}
export function getVideoDuration(media, src) {
    var _a;
    if (isVideoTrimmed(src)) {
        return getTrimmedVideoDuration(src);
    }
    return ((_a = getBaseMedia(media, src)) === null || _a === void 0 ? void 0 : _a.duration) || 0;
}
