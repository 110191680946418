import styled from "styled-components";
import { newTheme } from "../styled/theme";
const TInput = styled.input `
  font: ${newTheme.fonts.medium};
  color: ${props => (props.value && !props.disabled ? newTheme.colors.black : newTheme.colors.grey600)};
  background-color: ${newTheme.colors.grey100};
  border: 1px solid ${newTheme.colors.grey400};
  border-radius: 4px;
  height: 32px;
  width: auto;
  padding-left: 12px;
  cursor: ${props => (props.disabled ? "not-allowed" : "text")};
`;
export default TInput;
