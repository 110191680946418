import { ToolType } from "../../../../shared/types/tool-data-types/common";
import { TooltipV2View, TooltipV2ViewBubble, TooltipV2ViewSpotlight } from "../tools/tooltip-v2/view/tooltip-v2-view";
import React from "react";
import { ModalView } from "../tools/modal/view/modal-view";
import { SandboxView } from "../tools/sandbox/sandbox-view";
import { GlobalButtonsView } from "../tools/global-buttons/view/global-buttons-view";
import { useIsAutomatedScreen, useShouldAutoStart, useTools, useViewportIsTransitioning, useViewToolsAndToolsInActiveView, } from "../../redux/selectors/redux-selectors";
import { useScreenDidLoad } from "../../redux/selectors/builder-selectors";
import { AutoMouse } from "../tools/auto-mouse/view/auto-mouse-view";
import { AutoTypewriter } from "../tools/auto-typewriter/view/auto-typewriter-view";
import { AutoTooltip } from "../tools/auto-tooltip/view/auto-tooltip-view";
export function RenderTools({ baseViewProps }) {
    const tools = useTools();
    const toolIds = useViewToolsAndToolsInActiveView().map(vtat => vtat.tool.id);
    const isTransitioning = useViewportIsTransitioning();
    const shouldAutostart = useShouldAutoStart();
    const screenDidLoad = useScreenDidLoad();
    const isAutomated = useIsAutomatedScreen();
    const showOnlyGlobalButtons = isTransitioning || shouldAutostart || (!baseViewProps.isInEditViewport && !screenDidLoad);
    // don't wait for screen zooms on automated pages
    if (isAutomated && (baseViewProps.isInEditViewport || screenDidLoad)) {
        return [
            ...toolIds.map((id, i) => {
                const t = tools[id];
                if (!t)
                    return null;
                switch (t.type) {
                    case ToolType.AutoMouse: {
                        return React.createElement(AutoMouse, Object.assign({ key: `${t.id}-auto-mouse` }, Object.assign(Object.assign({}, baseViewProps), { toolData: t, index: i + 1 })));
                    }
                    case ToolType.AutoTypewriter: {
                        return (React.createElement(AutoTypewriter, Object.assign({ key: `${t.id}-auto-typewriter` }, Object.assign(Object.assign({}, baseViewProps), { toolData: t, index: i + 1 }))));
                    }
                    case ToolType.AutoTooltip: {
                        return (React.createElement(AutoTooltip, Object.assign({ key: `${t.id}-auto-tooltip` }, Object.assign(Object.assign({}, baseViewProps), { toolData: t, index: i + 1 }))));
                    }
                    case ToolType.GlobalButtons: {
                        return null;
                    }
                    default: {
                        throw new Error(`builder attempted to render unimplemented view for tool of type ${t.type}`);
                    }
                }
            }),
            // render global buttons
            Object.keys(tools).map(k => {
                const t = tools[k];
                if (t.type === ToolType.GlobalButtons) {
                    return React.createElement(GlobalButtonsView, Object.assign({ key: `${t.id}-main` }, Object.assign(Object.assign({}, baseViewProps), { toolData: t })));
                }
            }),
        ];
    }
    if (showOnlyGlobalButtons && !isAutomated) {
        return [
            // render global buttons
            Object.keys(tools).map(k => {
                const t = tools[k];
                if (t.type === ToolType.GlobalButtons) {
                    return React.createElement(GlobalButtonsView, Object.assign({ key: `${t.id}-main` }, Object.assign(Object.assign({}, baseViewProps), { toolData: t })));
                }
            }),
        ];
    }
    if (!isAutomated && !showOnlyGlobalButtons) {
        return [
            // render tooltip spotlights
            ...toolIds.map(id => {
                const t = tools[id];
                if (!t)
                    return null;
                switch (t.type) {
                    case ToolType.TooltipV2: {
                        return React.createElement(TooltipV2ViewSpotlight, Object.assign({ key: `${t.id}-spotlight` }, Object.assign(Object.assign({}, baseViewProps), { toolData: t })));
                    }
                    default:
                        return null;
                }
            }),
            // render all tools except for the spotlight and tooltip bubble
            // these will be affected by re-ordering via the layers panel in the builder UI
            ...toolIds.map(id => {
                const t = tools[id];
                if (!t)
                    return null;
                switch (t.type) {
                    case ToolType.TooltipV2: {
                        return React.createElement(TooltipV2View, Object.assign({ key: `${t.id}-main` }, Object.assign(Object.assign({}, baseViewProps), { toolData: t })));
                    }
                    case ToolType.Modal: {
                        return null;
                    }
                    case ToolType.GlobalButtons: {
                        return null;
                    }
                    case ToolType.Sandbox: {
                        return React.createElement(SandboxView, Object.assign({}, Object.assign(Object.assign({}, baseViewProps), { toolData: t })));
                    }
                    default: {
                        throw new Error(`builder attempted to render unimplemented view for tool of type ${t.type}`);
                    }
                }
            }),
            // render global buttons
            Object.keys(tools).map(k => {
                const t = tools[k];
                if (t.type === ToolType.GlobalButtons) {
                    return React.createElement(GlobalButtonsView, Object.assign({ key: `${t.id}-main` }, Object.assign(Object.assign({}, baseViewProps), { toolData: t })));
                }
            }),
            // render tooltip bubbles
            ...toolIds.map(id => {
                const t = tools[id];
                if (!t)
                    return null;
                switch (t.type) {
                    case ToolType.TooltipV2: {
                        return React.createElement(TooltipV2ViewBubble, Object.assign({ key: `${t.id}-bubble` }, Object.assign(Object.assign({}, baseViewProps), { toolData: t })));
                    }
                    default:
                        return null;
                }
            }),
            // render modals
            ...toolIds.map(id => {
                const t = tools[id];
                if (!t)
                    return null;
                switch (t.type) {
                    case ToolType.Modal: {
                        return React.createElement(ModalView, Object.assign({ key: `${t.id}-main` }, Object.assign(Object.assign({}, baseViewProps), { toolData: t })));
                    }
                    default:
                        return null;
                }
            }),
        ];
    }
    return [];
}
