import TModal from "../../../styled/t-modal";
import React, { useEffect, useState } from "react";
import { useActivePageId, usePushHistory, useScreenMedia, useTourialMedia, } from "../../../../redux/selectors/redux-selectors";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import { useIsVideoTrimModalOpen, useSetIsVideoTrimModalOpen, useSetScreenMedia, useSetTrimTimer, } from "../../../../redux/selectors/builder-selectors";
import { getBaseMedia, getTrimmedVideoStartOffset, getVideoDuration, isVideoTrimmed, } from "../../../../helpers/video-trimming";
import { ToastModes } from "../../../../types/definitions";
import { TRangeHorizontalSlider } from "../../../styled/t-range-horizontal-slider";
import { useAddToast } from "../../../../legacy/selectors";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
import { TMessageBox, TMessageBoxVariants } from "../../../styled/t-message-box";
import { modifyVideoFormatForSafari } from "../../../../helpers/misc";
import { roundToHundredths } from "../../../../../../shared/functions/helpers";
export function VideoTrimModal() {
    const pushHistory = usePushHistory();
    const addToast = useAddToast();
    const setScreenMedia = useSetScreenMedia();
    const isOpen = useIsVideoTrimModalOpen();
    const setIsOpen = useSetIsVideoTrimModalOpen();
    const media = useTourialMedia();
    const pageId = useActivePageId();
    const screenMedia = useScreenMedia();
    const screenSrc = screenMedia[pageId];
    const baseVideo = getBaseMedia(media, screenSrc);
    const [start, setStart] = useState(getTrimmedVideoStartOffset(screenSrc));
    const [end, setEnd] = useState(getTrimmedVideoStartOffset(screenSrc) + getVideoDuration(media, screenSrc));
    const [currentFrame, setCurrentFrame] = useState(start);
    const setTrimTimer = useSetTrimTimer();
    // wait an arbitrary amount of time for the video to finish processing on cloudinary's end
    const setTimer = (src) => {
        //@ts-expect-error typescript libraries are full of incorrect typings
        const timeoutId = setTimeout(() => {
            setTrimTimer(src, 0);
        }, 20000);
        setTrimTimer(src, timeoutId);
    };
    useEffect(() => {
        setStart(getTrimmedVideoStartOffset(screenSrc));
        setEnd(getTrimmedVideoStartOffset(screenSrc) + getVideoDuration(media, screenSrc));
        setCurrentFrame(getTrimmedVideoStartOffset(screenSrc));
    }, [screenSrc, isOpen]);
    if (!isOpen)
        return null;
    return (React.createElement(TModal, { title: "Trim your video", onClose: () => {
            setIsOpen(false);
        } }, (baseVideo === null || baseVideo === void 0 ? void 0 : baseVideo.src) && (React.createElement(React.Fragment, null,
        React.createElement(Div, null,
            React.createElement("div", { style: { width: 600, marginBottom: 12 } },
                React.createElement(TMessageBox, { message: "After trimming, it may take a few minutes for your video to play back correctly.", variant: TMessageBoxVariants.WARNING })),
            React.createElement(VideoToTrim, { src: modifyVideoFormatForSafari(baseVideo.src), currentFrame: currentFrame }),
            React.createElement(TRangeHorizontalSlider, { style: { width: 675, marginTop: 30 }, step: 0.25, min: 0, max: baseVideo.duration, value: [start, end], onChange: (value) => {
                    const v1 = roundToHundredths(value[0]);
                    const v2 = roundToHundredths(value[1]);
                    if (v1 !== start) {
                        setStart(v1);
                        setCurrentFrame(v1);
                    }
                    else if (v2 !== end) {
                        setCurrentFrame(v2);
                        setEnd(v2);
                    }
                } })),
        React.createElement(HeelContainer, null,
            React.createElement(TButton, { size: "medium", disabled: !isVideoTrimmed(screenSrc), variant: TButtonVariants.DANGER, onClick: () => {
                    setIsOpen(false);
                    pushHistory();
                    setScreenMedia(pageId, baseVideo.src);
                    addToast({
                        message: "Using original video",
                        mode: ToastModes.INFO,
                    });
                } }, "Untrim"),
            React.createElement(ButtonContainer, null,
                React.createElement(TButton, { size: "medium", onClick: () => {
                        setIsOpen(false);
                    }, variant: TButtonVariants.SECONDARY }, "Cancel"),
                React.createElement(TButton, { size: "medium", disabled: isTrimButtonDisabled(), onClick: () => {
                        setIsOpen(false);
                        pushHistory();
                        addToast({
                            message: "Uploading trimmed video",
                            mode: ToastModes.INFO,
                        });
                        TourialApiCalls.Tourials.postTrimVideo(baseVideo.src, start, end - start)
                            .then(trimmedSrc => {
                            setTimer(trimmedSrc);
                            setScreenMedia(pageId, trimmedSrc);
                            addToast({
                                message: "Your trimmed video is being prepared for playback",
                                mode: ToastModes.SUCCESS,
                            });
                        })
                            .catch(e => {
                            console.error(e);
                            addToast({
                                message: "Failed to trim video. Please try again later.",
                                mode: ToastModes.ERROR,
                            });
                        });
                    } }, "Trim")))))));
    function isTrimButtonDisabled() {
        return ((start === getTrimmedVideoStartOffset(screenSrc) &&
            end === getTrimmedVideoStartOffset(screenSrc) + getVideoDuration(media, screenSrc)) ||
            (start === 0 && end === getVideoDuration(media, baseVideo.src)));
    }
}
function VideoToTrim({ src, currentFrame }) {
    useEffect(() => {
        const el = document.getElementById("tourial-video-to-trim");
        if (el) {
            el.currentTime = currentFrame;
        }
    }, [currentFrame]);
    return React.createElement(Video, { id: "tourial-video-to-trim", src: src, controls: false, preload: "auto" });
}
const Video = styled.video `
  height: 380px;
  box-shadow: ${newTheme.shadows.outside80};
`;
const Div = styled.div `
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${newTheme.colors.grey100};
`;
const HeelContainer = styled.div `
  display: flex;
  height: 55px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-top: 1px solid ${newTheme.colors.grey300};
  background-color: ${newTheme.colors.white};
  width: 100%;
  padding: 15px;
`;
const ButtonContainer = styled.div `
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 15px;
`;
