import React, { useEffect } from "react";
import TButton from "../styled/t-button";
import { isImageFileExtension } from "../../../helpers/media";
import { ViewportMode } from "../../../types/definitions";
import { getBorderStylesFromProps, modifyVideoFormatForSafari } from "../../../helpers/misc";
import { userShadows } from "../builder/tools/shape/editor";
import { Spotlight } from "./spotlight-view";
import { withToolWrapper } from "../with-tool-wrapper";
import { useActivePageId, useActiveVariantName, useActiveViewSpotlights, useActiveZoom, useActiveZoomId, useLegacyChangeView, useCurrentPage, useHasActiveVariantNameBeenSet, usePages, useScreen, useSetTimer, useShowTool, useTimers, useUpdateBuilder, useUpdateViewport, useViewportIsTransitioning, useViewportMode, } from "../../../redux/selectors/redux-selectors";
import { TimerTrigger } from "../../../../../shared/legacy/legacy-tool-data";
import { startTimers } from "../../../helpers/timers";
import { usePostEvent } from "../../../helpers/events";
export function checkHasMedia(media, pageId) {
    return !!media[pageId];
}
export function TourialScreen(props) {
    var _a, _b, _c, _d;
    const updateBuilder = useUpdateBuilder();
    const screen = useScreen();
    const activeZoom = useActiveZoom();
    const updateViewport = useUpdateViewport();
    const spotlights = useActiveViewSpotlights(!!props.pageId);
    const currentPage = useCurrentPage();
    const viewportMode = useViewportMode();
    const isTransitioning = useViewportIsTransitioning();
    const changeView = useLegacyChangeView();
    const pages = usePages();
    const activePageId = useActivePageId();
    const activeZoomId = useActiveZoomId();
    const activeVariantName = useActiveVariantName();
    const hasActiveVariantNameBeenSet = useHasActiveVariantNameBeenSet();
    const timers = useTimers();
    const setTimer = useSetTimer();
    const showTool = useShowTool();
    const postEvent = usePostEvent();
    const relevantZoom = props.zoomId
        ? pages.find(p => p.id === props.pageId).zooms.find(z => z.id === props.zoomId)
        : activeZoom;
    // HANDLE AUTOZOOM
    useEffect(() => {
        if ((hasActiveVariantNameBeenSet || viewportMode !== ViewportMode.LIVE) && // If in live mode, make sure activeVariant has been set
            viewportMode !== ViewportMode.EDIT &&
            activeZoomId === "MAIN" &&
            !isTransitioning &&
            currentPage.autoStartZoomId &&
            currentPage.zooms.find(z => z.id === currentPage.autoStartZoomId) // ensure target zoom still exists
        ) {
            const nextView = { pageId: activePageId, zoomId: activeZoomId };
            nextView.zoomId = currentPage.autoStartZoomId;
            changeView(nextView);
        }
    }, [activePageId, activeZoomId, hasActiveVariantNameBeenSet, activeVariantName]);
    // start view timers
    useEffect(() => {
        if (viewportMode === ViewportMode.LIVE && !isTransitioning) {
            startTimers(TimerTrigger.View, timers, setTimer, activePageId, activeZoomId, showTool, postEvent);
        }
    }, [activePageId, activeZoomId, isTransitioning]);
    const propsWithoutScreenData = Object.assign(Object.assign({}, props), { toolData: null });
    function makeCSSTransition(t) {
        return {
            transitionProperty: "transform, left, top",
            transitionDuration: `${t.durationSecs}s, ${t.durationSecs}s, ${t.durationSecs}s`,
            transitionTimingFunction: t.timingFunction,
            transitionDelay: `${Math.max(t.delaySecs, 0.15)}s`,
            WebkitTransitionProperty: "transform, left, top",
            WebkitTransitionDuration: `${t.durationSecs}s, ${t.durationSecs}s, ${t.durationSecs}s`,
            WebkitTransitionTimingFunction: t.timingFunction,
            WebkitTransitionDelay: `${Math.max(t.delaySecs, 0.15)}s`,
        };
    }
    const zoom = props.zoomId
        ? relevantZoom
        : props.pageId
            ? (_a = pages.find(p => p.id === props.pageId)) === null || _a === void 0 ? void 0 : _a.zooms[0]
            : activeZoom;
    const transition = viewportMode === ViewportMode.EDIT ? {} : makeCSSTransition(zoom.transition);
    const hasImagesOnPage = checkHasMedia(screen.media, props.pageId || activePageId);
    if (!hasImagesOnPage && viewportMode !== ViewportMode.EDIT)
        return null;
    const src = screen.media[props.pageId || activePageId];
    const fadeInAnimationProps = screen.shouldFadeIn && viewportMode !== ViewportMode.EDIT
        ? {
            opacity: 0,
            animation: "fadeIn 0.5s",
            animationTimingFunction: "ease",
            animationDelay: "0s",
            animationIterationCount: 1,
            animationDirection: "normal",
            animationFillMode: "forwards",
            animationPlayState: "running",
        }
        : {};
    if (props.pageId && !hasImagesOnPage)
        return null;
    return (React.createElement("div", { style: Object.assign(Object.assign(Object.assign({ position: "absolute", width: "100%", height: "100%", overflow: "hidden" }, getBorderStylesFromProps(props)), { backgroundColor: props.toolData.enableBackground ? props.toolData.backgroundColor : null, boxShadow: props.toolData.enableShadow ? userShadows[props.toolData.shadowStyle] : null }), fadeInAnimationProps), id: screen.id },
        hasImagesOnPage ? (isImageFileExtension(src) ? (React.createElement("img", { src: src, style: Object.assign({ 
                // Don't ask me why, but full-monitor width chrome zoom transitions bug out when exactly 100% here
                width: "100.01%", transform: `scale(${zoom === null || zoom === void 0 ? void 0 : zoom.z})`, position: "relative", top: `${zoom === null || zoom === void 0 ? void 0 : zoom.y}%`, left: `${zoom === null || zoom === void 0 ? void 0 : zoom.x}%` }, transition), onClick: () => {
                updateBuilder({ editDetailsId: props.toolData.id });
            }, draggable: false, onTransitionEnd: () => updateViewport({ isTransitioning: false }) })) : (React.createElement("video", { src: modifyVideoFormatForSafari(src), style: Object.assign({ width: "100%", transform: `scale(${zoom === null || zoom === void 0 ? void 0 : zoom.z})`, position: "relative", top: `${zoom === null || zoom === void 0 ? void 0 : zoom.y}%`, left: `${zoom === null || zoom === void 0 ? void 0 : zoom.x}%` }, transition), onClick: () => {
                updateBuilder({ editDetailsId: props.toolData.id });
            }, autoPlay: props.disableAnimations ? false : (_b = props.toolData.perPageVideoControls[activePageId]) === null || _b === void 0 ? void 0 : _b.autoPlay, muted: (_c = props.toolData.perPageVideoControls[activePageId]) === null || _c === void 0 ? void 0 : _c.isMuted, controls: props.disableAnimations ? false : (_d = props.toolData.perPageVideoControls[activePageId]) === null || _d === void 0 ? void 0 : _d.controls, loop: false, draggable: false, onTransitionEnd: () => updateViewport({ isTransitioning: false }), playsInline: true }))) : (React.createElement("div", { style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
            }, onClick: () => {
                updateBuilder({ editDetailsId: props.toolData.id });
            } },
            React.createElement(TButton, { className: "add-screen", onClick: () => updateBuilder({ isDrawerOpen: true }) }, "Add Screen"))),
        !isTransitioning &&
            (!props.pageId || (props.pageId === activePageId && props.zoomId === activeZoomId)) &&
            (spotlights === null || spotlights === void 0 ? void 0 : spotlights.map((s, i) => (React.createElement(Spotlight, Object.assign({}, propsWithoutScreenData, { toolData: s, viewportDimensions: props.viewportDimensions, key: i })))))));
}
export const Screen = React.memo(withToolWrapper(TourialScreen, true));
