import React from "react";
import styled from "styled-components";
import ReactPortal from "../../../../helpers/react-portal";
import { DropdownContainer, DropdownOption } from "../../home-page/tourial-list";
import DeleteScreenIcon from "../../../../../assets/images/v3/dropdown-delete-screen.svg";
import DuplicateScreenIcon from "../../../../../assets/images/v3/dropdown-duplicate-screen.svg";
export function StoryboardMiniStepContextMenu({ params, onClose }) {
    const { id, x, y, onDuplicate, onDelete } = params;
    return (React.createElement(ReactPortal, { wrapperId: "tourial-portal-contextmenu-container" },
        React.createElement(ContextMenuOverlay, { id: "t-overlay", onMouseDown: e => {
                if (document.elementFromPoint(e.clientX, e.clientY).id === "t-overlay")
                    onClose();
            } },
            React.createElement(ContextMenuContainer, { style: { left: x, top: y - (onDelete ? 90 : 40) } },
                React.createElement(DropdownContainer, { className: "_test_tourial-context-menu", onClick: () => { } },
                    React.createElement(DropdownOption, { onClick: () => {
                            onDuplicate(id);
                            onClose();
                        } },
                        React.createElement(Icon, { src: DuplicateScreenIcon }),
                        " ",
                        "Duplicate"),
                    onDelete && (React.createElement(DropdownOption, { onClick: () => {
                            onDelete(id);
                            onClose();
                        } },
                        React.createElement(Icon, { src: DeleteScreenIcon }),
                        " ",
                        "Delete")))))));
}
const Icon = styled.img `
  display: inline;
  margin: 8px 8px 8px 16px;
`;
const ContextMenuOverlay = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;
const ContextMenuContainer = styled.div `
  position: absolute;
`;
