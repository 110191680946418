import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import CollapseArrowIcon from "../../../../../assets/images/v3/collapse.svg";
import ExpandArrowIcon from "../../../../../assets/images/v3/expand.svg";
import FolderIcon from "../../../../../assets/images/v3/folder-icon.svg";
import React from "react";
import { useIsEmptyTourial } from "../../../../redux/selectors/redux-selectors";
import { useDeselect } from "../../../../redux/selectors/builder-selectors";
import { StoryboardMiniStubStepPanel } from "./storyboard-mini-stub-step-panel";
export const maxStoryboardMiniHeight = 102;
export function StoryboardMiniStub({ sidebarWidth, storyboardHeight, editPanelWidth, setStoryboardHeight, setIsAddScreenModalOpen, }) {
    const isEmptyTourial = useIsEmptyTourial();
    const collapseClickHandler = () => setStoryboardHeight(storyboardHeight === 0 ? maxStoryboardMiniHeight : 0);
    const deselect = useDeselect();
    return (React.createElement(StoryboardStubContainer, { style: {
            left: 40 + sidebarWidth,
            bottom: storyboardHeight,
            width: `calc(100% - 40px - ${sidebarWidth}px - ${editPanelWidth}px)`,
        } },
        React.createElement(StubPanelButton, { onClick: collapseClickHandler, style: { marginLeft: 8, color: newTheme.colors.black } },
            React.createElement(StubPanelIcon, { src: storyboardHeight > 0 ? CollapseArrowIcon : ExpandArrowIcon }),
            storyboardHeight > 0 ? "Collapse storyboard " : "Expand storyboard"),
        !isEmptyTourial && (React.createElement(React.Fragment, null,
            React.createElement(StoryboardMiniStubStepPanel, null),
            React.createElement(StubPanelButton, { onClick: () => {
                    setIsAddScreenModalOpen(true);
                    // we deselect here so that the zoom controls show after adding a screen
                    deselect();
                }, style: { marginRight: 8, color: newTheme.colors.black } },
                React.createElement(StubPanelIcon, { src: FolderIcon }),
                "Media library")))));
}
const StoryboardStubContainer = styled.div `
  display: flex;
  align-items: center;
  align-content: center;
  background-color: ${newTheme.colors.white};
  position: fixed;
  height: 32px;
  transition:
    left ${newTheme.transitions.layoutShift},
    bottom ${newTheme.transitions.layoutShift},
    width ${newTheme.transitions.layoutShift};
  border-top: 1px solid ${newTheme.colors.grey};
  border-bottom: 1px solid ${newTheme.colors.grey};
`;
export const StubPanelIcon = styled.img `
  width: 16px;
`;
export const StubPanelButton = styled.button `
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding: 0 6px 0 6px;
  cursor: pointer;
  font: ${newTheme.fonts.small};
  width: auto;
  height: 26px;
  visibility: ${({ disabled }) => (disabled ? "hidden" : "inherit")};
  background-color: transparent;
  color: ${newTheme.colors.black};
  border-radius: 4px;
  &:hover {
    background-color: ${newTheme.colors.grey100};
  }
`;
