import XIcon from "../../../../../../assets/images/v3/x.svg";
import React from "react";
import styled from "styled-components";
export function FormV3CloseButton({ onClick }) {
    return (React.createElement(CloseButtonContainer, null,
        React.createElement(CloseButton, { onClick: onClick },
            React.createElement("img", { src: XIcon, alt: "close" }))));
}
const CloseButtonContainer = styled.div `
  display: flex;
  justify-content: right;
`;
const CloseButton = styled.div `
  width: 24px;
  height: 24px;
  img {
    width: 24px;
    height: 24px;
  }
  cursor: pointer;
`;
