import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BlueCaretDown from "../../../assets/images/v3/blue-caret-down.svg";
import SpacerIcon from "../../../assets/images/v3/chevron-greater-than-spacer.svg";
import { newTheme } from "./theme";
import { useOutsideClick } from "../../hooks/use-outside-click";
export var TBreadcrumbType;
(function (TBreadcrumbType) {
    TBreadcrumbType["LINK"] = "LINK";
    TBreadcrumbType["DROPDOWN"] = "DROPDOWN";
})(TBreadcrumbType || (TBreadcrumbType = {}));
export const TBreadcrumbs = ({ items, children }) => {
    return (React.createElement(BreadcrumbsContainer, null,
        items.map((b, i) => {
            return (React.createElement(Crumb, { key: `crumb-${i}` },
                getBreadcrumbForItem(b),
                i !== items.length - 1 && (React.createElement(Spacer, null,
                    React.createElement("img", { src: SpacerIcon })))));
        }),
        children));
};
const getBreadcrumbForItem = (props) => {
    const { type, to, name } = props;
    switch (type) {
        case TBreadcrumbType.LINK:
            return React.createElement(BreadcrumbLink, { to: to }, name);
        case TBreadcrumbType.DROPDOWN:
            return React.createElement(BreadcrumbDropdown, Object.assign({}, props));
        default:
            return null;
    }
};
const BreadcrumbDropdown = ({ options, selected }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef();
    useOutsideClick([dropdownRef], () => {
        if (isDropdownOpen)
            setIsDropdownOpen(false);
    });
    return (React.createElement(DropdownContainer, null,
        React.createElement(DropdownButton, { onClick: () => setIsDropdownOpen(!isDropdownOpen) },
            React.createElement("span", null, selected),
            React.createElement("img", { src: BlueCaretDown })),
        isDropdownOpen && (React.createElement(DropdownList, { ref: dropdownRef }, options.map(o => (React.createElement("li", { key: o.name },
            React.createElement(Link, { to: o.to, onClick: () => setIsDropdownOpen(false) }, o.name))))))));
};
const Spacer = styled.div `
  display: flex;
  align-items: center;
  padding: 0px 12px;
`;
const BreadcrumbsContainer = styled.div `
  display: flex;
  height: 32px;
  align-items: center;
`;
const Crumb = styled.div `
  display: flex;
`;
const BreadcrumbLink = styled(Link) `
  display: flex;
  font: ${newTheme.fonts.normal};
  color: ${newTheme.colors.grey600};
  text-decoration: underline;
  cursor: pointer;
  &:visited {
    color: ${newTheme.colors.grey600};
  }
  &:hover {
    color: ${newTheme.colors.grey400};
  }
`;
const DropdownButton = styled.button `
  display: flex;
  align-items: center;
  height: 34px;
  border: 1px solid ${newTheme.colors.grey200};
  background-color: ${newTheme.colors.grey100};
  padding: 4px 8px;
  font: ${newTheme.fonts.normal};
  color: ${newTheme.colors.grey800};
  cursor: pointer;
  border-radius: 4px;
  img {
    margin-left: 16px;
  }
  &:hover {
    border: 1px solid ${newTheme.colors.grey300};
    background-color: ${newTheme.colors.blue50};
  }
`;
const DropdownContainer = styled.div `
  position: relative;
`;
const DropdownList = styled.ul `
  position: absolute;
  z-index: 10;
  top: 34px;
  left: 0;
  width: 236px;
  margin: 8px 0;
  background-color: ${newTheme.colors.white};
  border: 1px solid ${newTheme.colors.grey200};
  box-shadow: ${newTheme.shadows.outside60};
  list-style: none;
  li {
    font: ${newTheme.fonts.medium};
    &:hover {
      background-color: ${newTheme.colors.blue50};
    }
    a {
      display: flex;
      padding: 8px;
      color: ${newTheme.colors.grey900};
      &:hover {
        color: ${newTheme.colors.grey900};
      }
    }
  }
`;
