import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useActiveView, useBuilderFocusField } from "../../../../redux/selectors/redux-selectors";
import { newTheme } from "../../../styled/theme";
export function EditorTabs({ tabs, initiallyOpenTab = 0 }) {
    var _a;
    const [tabIndex, setTabIndex] = useState(initiallyOpenTab);
    const activeView = useActiveView();
    const builderFocusField = useBuilderFocusField();
    useEffect(() => {
        if (builderFocusField)
            setTabIndex(0);
    }, [builderFocusField]);
    useEffect(() => setTabIndex(0), [activeView.zoomId, activeView.pageId]);
    return (React.createElement(TabAndContentContainer, null,
        React.createElement(TabButtonsContainer, null, tabs.map((t, i) => (React.createElement(TabButton, { key: i, isSelected: tabIndex === i, onClick: () => setTabIndex(i), disabled: !!t.disabled }, t.title)))), (_a = tabs[tabIndex]) === null || _a === void 0 ? void 0 :
        _a.contents));
}
const TabAndContentContainer = styled.div `
  height: calc(100% - 40px);
  div {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TabButtonsContainer = styled.span `
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${newTheme.colors.grey200};
  padding: 0px 16px;
`;
const TabButton = styled.button `
  border-bottom: ${props => (props.isSelected ? `4px solid ${newTheme.colors.blue300}` : "none")};
  color: ${props => (props.isSelected ? newTheme.colors.black : newTheme.colors.grey500)};
  font: ${props => (props.isSelected ? newTheme.fonts.bold : newTheme.fonts.normal)};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  height: 30px;
  margin-right: 32px;
`;
