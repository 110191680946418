import React from "react";
import styled from "styled-components";
import { useCurrentPage, useScreen, useUpdateTool } from "../../../redux/selectors/redux-selectors";
import { newTheme } from "../../styled/theme";
import { TCheckbox } from "../../styled/t-checkbox";
import { cloneDeep } from "lodash";
import { TDropdownSelect } from "../../styled/t-dropdown-select";
export default function VideoSettings() {
    var _a, _b, _c, _d, _e, _f;
    const activePage = useCurrentPage();
    const screen = useScreen();
    const updateTool = useUpdateTool();
    const videoControls = ((_a = screen === null || screen === void 0 ? void 0 : screen.perPageVideoControls) === null || _a === void 0 ? void 0 : _a[activePage.id]) || {};
    return (React.createElement(ContentContainer, null,
        React.createElement(AdditionalControls, null,
            React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                React.createElement("div", { style: { width: "auto" } }, "Autoplay video"),
                React.createElement(TCheckbox, { checked: (_b = videoControls.autoPlay) !== null && _b !== void 0 ? _b : true, onChange: () => {
                        var _a;
                        const s = cloneDeep(screen);
                        s.perPageVideoControls = Object.assign(Object.assign({}, s.perPageVideoControls), { [activePage.id]: Object.assign(Object.assign({}, videoControls), { autoPlay: !((_a = videoControls.autoPlay) !== null && _a !== void 0 ? _a : true) }) });
                        updateTool(s);
                    } })),
            React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                React.createElement("div", { style: { width: "auto" } }, "Show video controls"),
                React.createElement(TCheckbox, { checked: (_c = videoControls.controls) !== null && _c !== void 0 ? _c : false, onChange: () => {
                        var _a;
                        const s = cloneDeep(screen);
                        s.perPageVideoControls = Object.assign(Object.assign({}, s.perPageVideoControls), { [activePage.id]: Object.assign(Object.assign({}, videoControls), { controls: !((_a = videoControls.controls) !== null && _a !== void 0 ? _a : true) }) });
                        updateTool(s);
                    } })),
            React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                React.createElement("div", { style: { width: "auto" } }, "Mute"),
                React.createElement(TCheckbox, { checked: (_d = videoControls.isMuted) !== null && _d !== void 0 ? _d : false, onChange: () => {
                        var _a;
                        const s = cloneDeep(screen);
                        s.perPageVideoControls = Object.assign(Object.assign({}, s.perPageVideoControls), { [activePage.id]: Object.assign(Object.assign({}, videoControls), { isMuted: !((_a = videoControls.isMuted) !== null && _a !== void 0 ? _a : false) }) });
                        updateTool(s);
                    } })),
            React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                React.createElement("div", { style: { width: "auto", lineHeight: "32px" } }, "Playback speed"),
                React.createElement(TDropdownSelect, { selectedValue: (_e = videoControls.playbackSpeed) !== null && _e !== void 0 ? _e : 1, options: [
                        { label: "0.5", value: 0.5 },
                        { label: "0.75", value: 0.75 },
                        { label: "1", value: 1 },
                        { label: "1.25", value: 1.25 },
                        { label: "1.5", value: 1.5 },
                        { label: "1.75", value: 1.75 },
                        { label: "2", value: 2 },
                        { label: "3", value: 3 },
                        { label: "4", value: 4 },
                    ], style: { margin: 0 }, isSearchable: false, onChange: ({ value }) => {
                        const s = cloneDeep(screen);
                        s.perPageVideoControls = Object.assign(Object.assign({}, s.perPageVideoControls), { [activePage.id]: Object.assign(Object.assign({}, videoControls), { playbackSpeed: value }) });
                        updateTool(s);
                    } })),
            React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                React.createElement("div", { style: { width: "auto", lineHeight: "32px" } }, "At video end"),
                React.createElement(TDropdownSelect, { selectedValue: (_f = videoControls.autoJump) !== null && _f !== void 0 ? _f : true, options: [
                        { label: "jump to next step", value: true },
                        { label: "manual interaction", value: false },
                    ], style: { marginTop: 0 }, isSearchable: false, onChange: ({ value }) => {
                        const s = cloneDeep(screen);
                        s.perPageVideoControls = Object.assign(Object.assign({}, s.perPageVideoControls), { [activePage.id]: Object.assign(Object.assign({}, videoControls), { autoJump: value }) });
                        updateTool(s);
                    } })),
            !videoControls.autoJump && (React.createElement("div", { style: { font: newTheme.fonts.smallBold, marginTop: -16 } }, "Make sure you have a tool on this step so your Tourial doesn't get stuck.")))));
}
const ContentContainer = styled.div `
  height: calc(100% - 31px);
  overflow-y: scroll;
  padding: 16px;
  background-color: ${newTheme.colors.grey100};
`;
const AdditionalControls = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${newTheme.colors.grey700};
`;
