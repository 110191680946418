import React from "react";
import styled from "styled-components";
import Slider from "rc-slider";
import { newTheme } from "./theme";
export const TVerticalSlider = (props) => {
    const { onChange, min, max, defaultValue, reverse, disabled, value } = props;
    const handleChange = (e) => {
        onChange(e);
    };
    return (React.createElement(SliderContainer, null,
        React.createElement(Slider, { min: min, max: max, onChange: handleChange, defaultValue: defaultValue || 0, value: value, vertical: true, reverse: reverse || false, disabled: disabled || false, style: {
                height: "100%",
            }, railStyle: {
                position: "absolute",
                width: "2px",
                height: "100%",
                backgroundColor: disabled ? newTheme.colors.grey300 : newTheme.colors.blue200,
                backgroundPosition: "center",
                marginLeft: "8px",
            }, trackStyle: {
                position: "absolute",
                width: "2px",
            }, handleStyle: {
                cursor: disabled ? "not-allowed" : "grab",
                height: "18px",
                width: "18px",
                marginTop: "-8px",
                borderRadius: "9px",
                backgroundColor: disabled ? newTheme.colors.grey500 : newTheme.colors.blue,
                position: "absolute",
                boxShadow: newTheme.shadows.outside80,
            } })));
};
const SliderContainer = styled.div `
  position: relative;
  margin: 16px;
  height: 112px;
`;
