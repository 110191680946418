import { TourialStatus } from "../types/tourial-model";
import { DCButtonAction, DCButtonIconPlacement, DCButtonType, DCCardInfoPlacement, DCIconType, DCModuleType, DCThemeType, } from "../types/demo-center";
import { ShirtSize, TextAlign, TextStyle } from "../types/tool-data-types/common";
import { dcLightTheme } from "../../client/src/components/pages/demo-center/helpers/dc-style-helpers";
export const defaultDemoCenter = {
    name: "Demo Center",
    slug: "democenter",
    status: TourialStatus.DRAFT,
    logo: "https://res.cloudinary.com/dim9has1z/image/upload/v1691692021/t54gmpmysfdrnyt23t4n.svg",
    logoUrl: "",
    items: [],
    tags: [],
    modules: [],
    config: {
        theme: DCThemeType.LIGHT,
        header: {
            cta: {
                isEnabled: true,
                text: "Contact Sales",
                action: DCButtonAction.LINK,
                url: "",
                style: {
                    backgroundColor: "#FFFFFF",
                    borderColor: "#000000",
                    borderRadius: 5,
                    font: "Lato",
                    size: ShirtSize.MEDIUM,
                    color: "#ffffff",
                    style: TextStyle.NORMAL,
                    align: TextAlign.LEFT,
                },
            },
            demoButton: {
                isEnabled: true,
                text: "Self guided demo",
                action: DCButtonAction.SELF_GUIDED_DEMO,
                icon: DCIconType.POINTER,
                iconPlacement: DCButtonIconPlacement.LEFT,
                style: {
                    backgroundColor: "#FFFFFF",
                    borderColor: "#000000",
                    borderRadius: 5,
                    font: "Lato",
                    size: ShirtSize.MEDIUM,
                    color: "#000000",
                    style: TextStyle.NORMAL,
                    align: TextAlign.LEFT,
                },
            },
            browseButton: {
                isEnabled: true,
                text: "Browse all content",
                action: DCButtonAction.LIBRARY,
                icon: DCIconType.LIBRARY,
                iconPlacement: DCButtonIconPlacement.LEFT,
                style: {
                    backgroundColor: "#FFFFFF",
                    borderColor: "#000000",
                    borderRadius: 5,
                    font: "Lato",
                    size: ShirtSize.MEDIUM,
                    color: "#000000",
                    style: TextStyle.NORMAL,
                    align: TextAlign.LEFT,
                },
            },
        },
        moduleDetails: {
            backgroundColor: "#FFFFFF",
            borderRadius: 8,
            titleTextStyle: {
                font: "Lato",
                size: ShirtSize.MEDIUM,
                color: "#000000",
                style: TextStyle.BOLD,
                align: TextAlign.CENTER,
            },
            promptTextStyle: {
                font: "Lato",
                size: ShirtSize.LARGE,
                color: "#000000",
                style: TextStyle.NORMAL,
                align: TextAlign.CENTER,
            },
            descriptionTextStyle: {
                font: "Lato",
                size: ShirtSize.MEDIUM,
                color: "#000000",
                style: TextStyle.NORMAL,
                align: TextAlign.CENTER,
            },
            continueButton: {
                isEnabled: true,
                text: "Continue",
                action: DCButtonAction.CONFIRM_SELECTION,
                icon: DCIconType.RIGHT_ARROW,
                iconPlacement: DCButtonIconPlacement.RIGHT,
                style: {
                    backgroundColor: "#FF4797",
                    borderColor: "#FF4797",
                    borderRadius: 5,
                    font: "Lato",
                    size: ShirtSize.MEDIUM,
                    color: "#FFFFFF",
                    style: TextStyle.NORMAL,
                    align: TextAlign.LEFT,
                },
            },
            skipButton: {
                text: "Skip",
                style: {
                    font: "Lato",
                    size: ShirtSize.MEDIUM,
                    color: "#747681",
                    style: TextStyle.UNDERLINE,
                    align: TextAlign.LEFT,
                    hoverColor: "#FF4797",
                    backgroundColor: "",
                    borderColor: "",
                    borderRadius: 0,
                },
                isEnabled: true,
                action: DCButtonAction.SKIP,
                type: DCButtonType.BORDERLESS,
            },
        },
        share: {
            backgroundColor: "#FFFFFF",
            inputStyle: {
                color: "#000000",
                backgroundColor: "#FFFFFF",
                borderColor: "#C9CACF",
                font: "Lato",
                size: ShirtSize.MEDIUM,
            },
            copyButtonColor: "#FF4797",
            title: {
                text: "Your custom link",
                style: {
                    font: "Lato",
                    size: ShirtSize.MEDIUM,
                    color: "#212229",
                    style: TextStyle.BOLD,
                    align: TextAlign.LEFT,
                },
            },
            description: {
                text: "This custom link is unique to you. Your progress will be saved so that you can resume your experience at a later time!",
                style: {
                    font: "Lato",
                    size: ShirtSize.MEDIUM,
                    color: "#90939D",
                    style: TextStyle.NORMAL,
                    align: TextAlign.LEFT,
                },
            },
        },
        welcome: {
            isEnabled: false,
            title: {
                text: "Welcome to the Demo Center",
                style: {
                    font: "Lato",
                    size: ShirtSize.XXLARGE,
                    color: "#212229",
                    style: TextStyle.BOLD,
                    align: TextAlign.LEFT,
                },
            },
            message: {
                text: "Learn what Interactive Tours are by watching the video on the right.",
                style: {
                    font: "Lato",
                    size: ShirtSize.MEDIUM,
                    color: "#212229",
                    style: TextStyle.NORMAL,
                    align: TextAlign.LEFT,
                },
            },
            featuredContentId: "",
            showFeaturedContent: true,
            demoButton: {
                type: DCButtonType.LARGE,
                isEnabled: true,
                text: "Self guided demo",
                action: DCButtonAction.SELF_GUIDED_DEMO,
                style: {
                    backgroundColor: "#FF4797",
                    borderColor: "",
                    borderRadius: 12,
                    font: "Lato",
                    size: ShirtSize.XLARGE,
                    color: "#FFFFFF",
                    style: TextStyle.BOLD,
                    align: TextAlign.LEFT,
                },
            },
            browseButton: {
                type: DCButtonType.BORDERLESS,
                isEnabled: true,
                text: "Or browse all content",
                action: DCButtonAction.LIBRARY,
                icon: DCIconType.DOWN_CHEVRON_CIRCLED,
                iconPlacement: DCButtonIconPlacement.LEFT,
                style: {
                    backgroundColor: "#FFFFFF",
                    borderColor: "",
                    borderRadius: 0,
                    font: "Lato",
                    size: ShirtSize.MEDIUM,
                    color: "#4D5056",
                    style: TextStyle.NORMAL,
                    align: TextAlign.LEFT,
                },
            },
        },
        upfrontQuestions: {
            isEnabled: false,
            headerText: "Welcome to the Demo Center",
            buttonText: "Enter",
        },
        quickView: {
            closeButton: {
                text: "Close",
                style: {
                    font: "Lato",
                    size: ShirtSize.MEDIUM,
                    color: "#ffffff",
                    style: TextStyle.UNDERLINE,
                    align: TextAlign.RIGHT,
                },
            },
        },
        body: {
            backgroundColor: "#FFFFFF",
            backgroundImage: "https://res.cloudinary.com/dim9has1z/image/upload/v1691692254/uoud8yyyhazzw95shhvo.png",
        },
        itemCard: {
            size: ShirtSize.MEDIUM,
            borderColor: "#cccccc",
            borderRadius: 4,
            activeBorderColor: "#aaaaff",
            backgroundColor: "#FFFFFF",
            shadow: true,
            activeShadow: true,
            label: {
                font: "Lato",
                size: ShirtSize.SMALL,
                color: "#90939D",
                style: TextStyle.BOLD,
                align: TextAlign.LEFT,
                isUppercase: true,
            },
            title: {
                font: "Lato",
                size: ShirtSize.MEDIUM,
                color: "#212229",
                style: TextStyle.NORMAL,
                align: TextAlign.LEFT,
            },
            description: {
                font: "Lato",
                size: ShirtSize.MEDIUM,
                color: "#62636A",
                style: TextStyle.NORMAL,
                align: TextAlign.LEFT,
            },
            viewButton: {
                isEnabled: true,
                text: "View",
                icon: DCIconType.RIGHT_ARROW,
                iconPlacement: DCButtonIconPlacement.RIGHT,
                action: DCButtonAction.VIEW,
                style: {
                    backgroundColor: "#FF4797",
                    borderColor: "#FF4797",
                    borderRadius: 5,
                    font: "Lato",
                    size: ShirtSize.SMALL,
                    color: "#FFFFFF",
                    style: TextStyle.NORMAL,
                    align: TextAlign.LEFT,
                    isUppercase: true,
                },
            },
            infoPlacement: DCCardInfoPlacement.OUTSIDE,
        },
        tag: {
            activeBackgroundColor: "#0055FF",
            activeBorderColor: "#00187A",
            borderRadius: 30,
            text: {
                font: "Lato",
                size: ShirtSize.MEDIUM,
                color: "#747681",
                style: TextStyle.NORMAL,
                align: TextAlign.CENTER,
                activeColor: "#FFFFFF",
            },
        },
        badge: {
            text: {
                font: "Lato",
                size: ShirtSize.SMALL,
                color: "#747681",
                style: TextStyle.NORMAL,
                align: TextAlign.CENTER,
                isUppercase: true,
            },
            defaultBackgroundColor: "#EFF0F3",
        },
        sidebar: {
            borderRadius: 8,
            activeModuleShadow: true,
            displayCTAWhileEmbedded: true,
            activeIconColor: "#F35889",
            inactiveIconColor: "#90939D",
            shareButton: {
                isEnabled: true,
                text: "Save & share",
                action: DCButtonAction.SHARE,
                icon: DCIconType.BOOKMARK,
                iconPlacement: DCButtonIconPlacement.LEFT,
                type: DCButtonType.LARGE,
                style: {
                    font: "Lato",
                    size: ShirtSize.LARGE,
                    color: "#FFFFFF",
                    style: TextStyle.NORMAL,
                    align: TextAlign.LEFT,
                    backgroundColor: "#505BFA",
                    backgroundColor2: "#FF4797",
                    borderColor: "",
                    borderRadius: 12,
                },
            },
            moduleTitleStyle: {
                font: "Lato",
                size: ShirtSize.MEDIUM,
                color: "#212229",
                style: TextStyle.BOLD,
                align: TextAlign.LEFT,
            },
            emptyStateTextStyle: {
                font: "Lato",
                size: ShirtSize.MEDIUM,
                color: "#90939D",
                style: TextStyle.ITALIC,
                align: TextAlign.LEFT,
            },
            itemTitleStyle: {
                font: "Lato",
                size: ShirtSize.MEDIUM,
                color: "#90939D",
                activeColor: "#212229",
                style: TextStyle.NORMAL,
                align: TextAlign.LEFT,
            },
            itemTypeStyle: {
                font: "Lato",
                size: ShirtSize.SMALL,
                color: "#90939D",
                style: TextStyle.NORMAL,
                align: TextAlign.LEFT,
                isUppercase: false,
            },
            itemPlaceholderText: "No content yet - answer prompt for personalized experience.",
            displayEstimatedDuration: false,
        },
        controls: {
            titleStyle: {
                font: "Lato",
                size: ShirtSize.MEDIUM,
                color: "#000000",
                style: TextStyle.NORMAL,
                align: TextAlign.LEFT,
            },
            typeStyle: {
                font: "Lato",
                size: ShirtSize.MEDIUM,
                color: "#000000",
                style: TextStyle.NORMAL,
                align: TextAlign.LEFT,
                isUppercase: true,
            },
            labelStyle: {
                font: "Lato",
                size: ShirtSize.SMALL,
                color: "#000000",
                style: TextStyle.BOLD,
                align: TextAlign.LEFT,
            },
            next: {
                text: "Next",
                style: {
                    font: "Lato",
                    size: ShirtSize.SMALL,
                    color: "#000000",
                    style: TextStyle.BOLD,
                    align: TextAlign.LEFT,
                    isUppercase: true,
                },
            },
            placeholder: {
                text: "Customizing demo...",
                style: {
                    font: "Lato",
                    size: ShirtSize.MEDIUM,
                    color: "#000000",
                    style: TextStyle.NORMAL,
                    align: TextAlign.LEFT,
                },
            },
            activeBackgroundColor: "#FF4797",
            activeColor: "#FFFFFF",
        },
        cardButton: {
            borderRadius: 4,
            activeColor: "#FF4797",
            shadow: true,
            labelStyle: {
                font: "Lato",
                size: ShirtSize.SMALL,
                color: "#212229",
                style: TextStyle.NORMAL,
                align: TextAlign.CENTER,
            },
        },
        gallerySection: {
            isEnabled: false,
            borderRadius: 8,
            titleStyle: {
                font: "Lato",
                size: ShirtSize.MEDIUM,
                color: "#212229",
                style: TextStyle.BOLD,
                align: TextAlign.LEFT,
            },
            toggleStyle: {
                font: "Lato",
                size: ShirtSize.LARGE,
                color: "#303136",
                style: TextStyle.UNDERLINE,
                align: TextAlign.RIGHT,
            },
        },
        autoplay: false,
        autoAdvance: false,
    },
    styles: {
        highlightColor: "#FF4797",
        gradientColor1: "#505BFA",
        gradientColor2: "#FF4797",
        gradientPercent: 24,
        cardBorderRadius: 4,
        containerBorderRadius: 8,
        buttonBorderRadius: 5,
        largeButtonBorderRadius: 12,
        customTheme: dcLightTheme,
    },
};
export const demoCenterModuleTemplates = [
    {
        title: "Learn the Basics",
        prompt: "Would you like a quick rundown of the essentials to get started?",
        type: DCModuleType.BOOLEAN,
    },
    {
        title: "Features",
        prompt: "Select the features you are the most curious about.",
        type: DCModuleType.MULTIPLE,
    },
    {
        title: "Use Cases",
        prompt: "Which scenario most resonates with how you envision using our product?",
        type: DCModuleType.SINGLE,
    },
    {
        title: "Integrations",
        prompt: "Let us know which of your tools you most want to sync with.",
        type: DCModuleType.MULTIPLE,
    },
    {
        title: "Solutions",
        prompt: "Facing a specific challenge? Let's see which solution might fit your needs.",
        type: DCModuleType.SINGLE,
    },
    {
        title: "Industry",
        prompt: "Can we tailor your experience to your industry? Which is most like your organization?",
        type: DCModuleType.SINGLE,
    },
    {
        title: "Setup and Onboarding",
        prompt: "How important is hands-on onboarding for your team?",
        type: DCModuleType.SCALE,
    },
    {
        title: "Data & Security",
        prompt: "How crucial is data security for your organization?",
        type: DCModuleType.SCALE,
    },
    {
        title: "Support",
        prompt: "How important is support from our team when you need it?",
        type: DCModuleType.SCALE,
    },
    {
        title: "Customer Testimonials",
        prompt: "Are you interested in hearing stories from our users?",
        type: DCModuleType.BOOLEAN,
    },
    {
        title: "Plans & Packages",
        prompt: "Which plan would best suit your needs?",
        type: DCModuleType.SINGLE,
    },
];
