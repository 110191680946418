import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { BaseOverlay, HTButton, HTModal, ModalBody, ModalHeader } from "../shared";
import { useHTDimensions, useHTGotoStep, useHTIsMobile, useHTMode, useHTScale, useHTSetPageForm, useHTStep, useHTTheme, } from "../../../../../redux/selectors/ht-selectors";
import { HTMode, DTInteraction, DTGenericInteraction } from "../../../../../../../shared/types/dom-tour";
import { isMarkupTextEmpty } from "../../../../../helpers/dom-helpers";
import { useHTFetchCaptureThumbnail, useHTHandleClickEvent } from "../../hooks/ht-hooks";
import { useSetBuilderFocusField } from "../../../../../redux/selectors/redux-selectors";
import { TourialEditorFocusFields } from "../../../../../types/definitions";
import { MIN_SCALE_WIDTH } from "../../config";
import { Img } from "./intro";
export function Outro() {
    const setBuilderFocusField = useSetBuilderFocusField();
    const goToStep = useHTGotoStep();
    const setDTPageForm = useHTSetPageForm();
    const step = useHTStep();
    const thumbnail = useHTFetchCaptureThumbnail();
    const theme = useHTTheme();
    const mode = useHTMode();
    const htHandleClickEvent = useHTHandleClickEvent();
    const { width } = useHTDimensions();
    const scale = useHTScale();
    const isMobile = useHTIsMobile();
    const scaleOffset = isMobile ? Math.max(MIN_SCALE_WIDTH, width * scale) / (width * scale) : 1;
    return (React.createElement(Root, null,
        React.createElement(BaseOverlay, { theme: theme }),
        React.createElement(HTModal, { style: { transform: `scale(${scaleOffset})` } },
            !!step.outro.header && (React.createElement(ModalHeader, { onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.HT_OVERLAY_HEADLINE_TEXT), theme: theme }, step.outro.header)),
            !isMarkupTextEmpty(step.outro.body) && (React.createElement(ModalBody, { onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.HT_OVERLAY_BODY_TEXT), theme: theme }, parse(step.outro.body))),
            React.createElement(ButtonsContainer, null, step.outro.buttons.map((b, i) => {
                return (React.createElement(HTButton, { onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields[`HT_OVERLAY_BUTTON_${i + 1}_TEXT`]), key: i, theme: theme, onClick: () => handleClick(b) }, b.text));
            }))),
        React.createElement(Img, { src: thumbnail.data })));
    function handleClick(button) {
        if (mode === HTMode.EDIT)
            return;
        switch (button.action) {
            case DTInteraction.LINK:
                htHandleClickEvent({ clickAction: "EXTERNAL_LINK", href: button.href });
                window.open(`https://${button.href.replace(/^https?:\/\//, "")}`, "_blank");
                break;
            case DTInteraction.FORM:
                htHandleClickEvent({ clickAction: "TRIGGER_FORM", formId: button.formId });
                setDTPageForm({ formId: button.formId, isEnabled: true, isGated: false });
                break;
            case DTGenericInteraction.RESTART:
                htHandleClickEvent({ clickAction: "RESTART_TOUR" });
                goToStep(0);
                break;
            default:
                break;
        }
    }
}
const Root = styled.div `
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const ButtonsContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
