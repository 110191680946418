import { FieldDataType } from "../../../shared/types/form";
import { getFromStorage, setInStorage } from "./storage";
export function setFormWasDismissed(formId, wasDismissed) {
    var _a;
    const localStorage = getFromStorage("tourialLive");
    const newLocalStorage = Object.assign({}, localStorage);
    if ((_a = newLocalStorage === null || newLocalStorage === void 0 ? void 0 : newLocalStorage.formStates) === null || _a === void 0 ? void 0 : _a[formId]) {
        newLocalStorage.formStates[formId].wasDismissed = wasDismissed;
        setInStorage("tourialLive", newLocalStorage);
    }
}
export function setFormWasSubmitted(formId, wasSubmitted) {
    var _a;
    const localStorage = getFromStorage("tourialLive");
    const newLocalStorage = Object.assign({}, localStorage);
    if ((_a = newLocalStorage === null || newLocalStorage === void 0 ? void 0 : newLocalStorage.formStates) === null || _a === void 0 ? void 0 : _a[formId]) {
        newLocalStorage.formStates[formId].wasSubmitted = wasSubmitted;
        setInStorage("tourialLive", newLocalStorage);
    }
}
export function initLocalStorageFormState(formId) {
    var _a;
    const localStorage = getFromStorage("tourialLive");
    if (localStorage) {
        if (!((_a = localStorage === null || localStorage === void 0 ? void 0 : localStorage.formStates) === null || _a === void 0 ? void 0 : _a[formId])) {
            setInStorage("tourialLive", Object.assign(Object.assign({}, localStorage), {
                formStates: Object.assign(Object.assign({}, localStorage.formStates), { [formId]: { wasDismissed: false, wasSubmitted: false } }),
            }));
        }
    }
}
export function wasFormSubmitted(formId) {
    var _a, _b;
    const localStorage = getFromStorage("tourialLive");
    if (localStorage) {
        return (_b = (_a = localStorage === null || localStorage === void 0 ? void 0 : localStorage.formStates) === null || _a === void 0 ? void 0 : _a[formId]) === null || _b === void 0 ? void 0 : _b.wasSubmitted;
    }
    return false;
}
export function formHasMoreThanSixVisibleFields(fields) {
    return fields.filter(f => f.type !== FieldDataType.HIDDEN).length > 6;
}
