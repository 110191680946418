import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { ViewportMode } from "../../../../../../types/definitions";
import { useViewportMode, useUpdateTool } from "../../../../../../redux/selectors/redux-selectors";
import { extractTextStyle } from "../../../../tool-views/form-v2-view";
export function BodyText({ toolData, viewportDimensions, isConfirmation, customStyle, }) {
    const { bodyText } = toolData.text;
    const [textData, setTextData] = useState(isConfirmation ? bodyText.confirmationTextData : bodyText.textData); // stored in toolData
    const mode = useViewportMode();
    const viewportMode = useViewportMode();
    const updateTool = useUpdateTool();
    const style = Object.assign(Object.assign(Object.assign({ width: "85%", maxHeight: "100%", left: 0, top: 0, textAlign: "left", overflow: "hidden", position: "static", display: "block", verticalAlign: "top", cursor: viewportMode === ViewportMode.EDIT ? "text" : "inherit" }, extractTextStyle(bodyText)), { color: bodyText.descriptionColor, fontSize: `${(viewportDimensions.height * bodyText.fontSize) / 100}px` }), customStyle);
    return (React.createElement("textarea", { style: style, value: textData, readOnly: mode !== ViewportMode.EDIT, onChange: e => {
            setTextData(e.target.value);
            const newTool = cloneDeep(toolData);
            if (isConfirmation) {
                newTool.text.bodyText.confirmationTextData = e.target.value;
            }
            else {
                newTool.text.bodyText.textData = e.target.value;
            }
            updateTool(newTool);
        } }));
}
