import { cloneDeep, isEqual } from "lodash";
export function pushPast(prevState) {
    const maxPast = 100;
    const past = prevState.history.pastIndex + 1 === maxPast
        ? [
            ...prevState.history.past,
            {
                tourial: cloneDeep(prevState.tourial),
                viewport: cloneDeep(prevState.viewport),
            },
        ].slice(1)
        : [
            ...prevState.history.past,
            {
                tourial: cloneDeep(prevState.tourial),
                viewport: cloneDeep(prevState.viewport),
            },
        ];
    const pastIndex = Math.min(maxPast - 1, prevState.history.pastIndex + 1);
    return { past, pastIndex };
}
export function pushHistory(prevState) {
    // TODO this is dumb. we should not be storing the entire tourial, it should patch diffs like sockets MAYBE (but probably not) or at least have a fairly low max before it starts overwriting. that's a lot of data stored for a long session!
    const { past, pastIndex } = pushPast(prevState);
    const lastHistory = prevState.history.past[prevState.history.past.length - 1];
    const currentHistory = {
        tourial: prevState.tourial,
        viewport: prevState.viewport,
    };
    if (isEqual(lastHistory, currentHistory))
        return prevState.history;
    return {
        past,
        future: [],
        pastIndex,
        futureIndex: -1,
    };
}
export function undoHistory(prevState) {
    return {
        past: prevState.history.past.slice(0, prevState.history.pastIndex),
        future: [
            ...prevState.history.future,
            {
                tourial: cloneDeep(prevState.tourial),
                viewport: cloneDeep(prevState.viewport),
            },
        ],
        pastIndex: prevState.history.pastIndex - 1,
        futureIndex: prevState.history.futureIndex + 1,
    };
}
export function redoHistory(prevState) {
    const { past, pastIndex } = pushPast(prevState);
    return {
        past,
        future: prevState.history.future.slice(0, prevState.history.futureIndex),
        pastIndex,
        futureIndex: prevState.history.futureIndex - 1,
    };
}
