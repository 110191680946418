import React from "react";
import { cloneDeep } from "lodash";
import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { withFlexibleToolWrapper } from "../../../../renderer/wrapper/with-flexible-tool-wrapper";
import { WrapperChild } from "../../../../renderer/wrapper/wrapper-child";
import styled from "styled-components";
import { pxToPercent } from "../../../../../helpers/tooltip-v2-size-and-position-helpers";
import { ShirtSize } from "../../../../../../../shared/types/tool-data-types/common";
import { hexToRGB } from "../../../../../helpers/misc";
import { useInteraction } from "../../../../../redux/selectors/interaction-selectors";
import { InteractionName, InteractionType } from "../../../../../../../shared/types/tool-data-types/interactions";
import { TourialEvent } from "../../../../../../../shared/types/events";
import { hotspotAnimationBuilder } from "../../../../../helpers/dom-helpers";
export function Hotspot(props) {
    const { toolData: tool, viewportDimensions } = props;
    const updateTool = useUpdateTool();
    const containerSize = dotContainerSizes[tool.trigger.hotspot.size ? tool.trigger.hotspot.size : ShirtSize.LARGE];
    function drag(position) {
        const t = cloneDeep(tool);
        position = {
            x: position.x + pxToPercent(containerSize, viewportDimensions.width) / 2,
            y: position.y + pxToPercent(containerSize, viewportDimensions.height) / 2,
        };
        t.trigger.position = position;
        updateTool(t);
    }
    const position = {
        x: tool.trigger.position.x - pxToPercent(containerSize, viewportDimensions.width) / 2,
        y: tool.trigger.position.y - pxToPercent(containerSize, viewportDimensions.height) / 2,
    };
    return withFlexibleToolWrapper(Inner, {
        isEditing: props.isInEditViewport,
        isSelectable: true,
        isResizable: false,
        isDraggable: true,
        isAspectRatioLocked: true,
        isStaticSize: true,
        shouldShowSelectionTab: false,
    }, {
        bounds: ".tourial-viewport",
        size: { width: containerSize, height: containerSize },
        position,
    }, {
        onDrag: drag,
        onDragStop: drag,
        onResize: () => { },
        onResizeStop: () => { },
    })(props);
}
function Inner(props) {
    const { toolData: tool, disableAnimations } = props;
    const interaction = useInteraction(props, tool.trigger.interaction);
    return (React.createElement(Div, { id: `${tool.id}-hotspot`, className: "_test_hotspot-view", onMouseOver: () => {
            if (tool.trigger.interaction.type === InteractionType.HOVER) {
                interaction(TourialEvent.HOVER_IN);
            }
        }, onMouseOut: () => {
            if (tool.trigger.interaction.type === InteractionType.HOVER &&
                tool.trigger.interaction.name !== InteractionName.SHOW_TOOLTIP_BUBBLE_AND_SPOTLIGHT) {
                interaction(TourialEvent.HOVER_OUT);
            }
        }, onClick: () => {
            if (tool.trigger.interaction.type === InteractionType.CLICK) {
                interaction();
            }
        } },
        React.createElement(Dot, { style: { backgroundColor: tool.trigger.hotspot.color }, disableAnimations: disableAnimations, pulseColor: hexToRGB(tool.trigger.hotspot.color) || { r: 0, g: 0, b: 0 }, size: tool.trigger.hotspot.size ? tool.trigger.hotspot.size : ShirtSize.LARGE })));
}
const dotContainerSizes = {
    [ShirtSize.SMALL]: 16,
    [ShirtSize.MEDIUM]: 24,
    [ShirtSize.LARGE]: 34,
};
const dotSizes = {
    [ShirtSize.SMALL]: "12px",
    [ShirtSize.MEDIUM]: "18px",
    [ShirtSize.LARGE]: "24px",
};
const Dot = styled.div `
  border-radius: 50%;
  width: ${props => dotSizes[props.size]};
  height: ${props => dotSizes[props.size]};
  animation: ${props => (props.disableAnimations ? "null" : hotspotAnimationBuilder(props.pulseColor))};
  pointer-events: none;
`;
const Div = styled(WrapperChild) `
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
