import React from "react";
import styled from "styled-components";
import { DCCMPageType, DCIconType, DC_DEFAULT_MODULE_NAME, } from "../../../../../../../shared/types/demo-center";
import { newTheme } from "../../../../styled/theme";
import { DragDropContext, Droppable, Draggable, } from "react-beautiful-dnd";
import { DCIcon } from "../../components/dc-icon";
import TTooltip from "../../../../styled/t-tooltip";
import { dcGenerateSlugFromText } from "../../helpers/dc-helpers";
import { TToggleSwitch } from "../../../../styled/t-toggle-switch";
import { useDCConfigGatingFormID } from "../../../../../redux/selectors/demo-center-selectors";
import { useDCConfig, useDCSetConfigGatingFormId } from "../../hooks/dc-hooks";
import { useFeatureFlags, useIsSuperAdmin } from "../../../../../redux/selectors/redux-selectors";
export const DCCMMenu = ({ modules, activePage, getWarning, onModuleClick, onPageClick, onAddButtonClick, onDragEnd, }) => {
    const gatingFormId = useDCConfigGatingFormID();
    const setGatingFormId = useDCSetConfigGatingFormId();
    const { welcome } = useDCConfig();
    const isSuperAdmin = useIsSuperAdmin();
    const { dcAdvancedSettings } = useFeatureFlags();
    const idPrefix = "dc-cm-page-";
    const pages = [
        {
            title: DCCMPageType.NAVIGATION,
            icon: DCIconType.NAVBAR,
        },
        {
            title: DCCMPageType.INTRO_PAGE,
            icon: DCIconType.HAND_WAVE,
            isEnabled: welcome.isEnabled,
        },
        {
            title: DCCMPageType.GATED_MODULES,
            icon: DCIconType.PADLOCK,
            // Toggling on doesn't really do much, its just a way to clear the form id
            onToggle: () => setGatingFormId(""),
            isEnabled: !!gatingFormId,
        },
        {
            title: DCCMPageType.ADVANCED_SETTINGS,
            icon: DCIconType.GEAR,
            permission: isSuperAdmin || dcAdvancedSettings,
        },
    ];
    const handleAddButtonClick = () => {
        onAddButtonClick();
        window.setTimeout(() => {
            var _a;
            const element = document.getElementById(`${idPrefix}${(_a = modules[modules.length - 1]) === null || _a === void 0 ? void 0 : _a.slug}`);
            if (element)
                element.scrollIntoView();
        }, 100);
    };
    return (React.createElement(MenuContainer, null,
        React.createElement(MenuHeader, null,
            React.createElement("span", null, "Interactive Topics"),
            React.createElement(AddButton, { onClick: handleAddButtonClick },
                React.createElement(DCIcon, { type: DCIconType.PLUS }))),
        React.createElement(DragDropContext, { onDragEnd: onDragEnd },
            React.createElement(Droppable, { droppableId: "modules" }, provided => (React.createElement(Menu, Object.assign({ className: "droppable-modules" }, provided.droppableProps, { ref: provided.innerRef }),
                modules.map((m, i) => (React.createElement(Draggable, { key: `module-${m.slug}`, draggableId: `module-${m.slug}`, index: i, isDragDisabled: m.slug === DCCMPageType.WELCOME, disableInteractiveElementBlocking: true }, provided => (React.createElement(MenuItem, { id: `${idPrefix}${m.slug}`, ref: provided.innerRef, draggableProps: provided.draggableProps, dragHandleProps: provided.dragHandleProps, onClick: () => onModuleClick(m), icon: m.slug === DCCMPageType.WELCOME ? DCIconType.PIN : DCIconType.DRAG_HANDLE, title: m.title, isActive: activePage === m.slug, warning: getWarning(m.slug) }))))),
                provided.placeholder)))),
        React.createElement(MenuHeader, null,
            React.createElement("span", null, `Navigation & format`)),
        React.createElement(Menu, null, pages.map(p => {
            if (p.permission !== undefined && !p.permission)
                return null;
            return (React.createElement(MenuItem, { key: p.title, title: p.title, icon: p.icon, onClick: () => onPageClick(p.title), isActive: activePage === dcGenerateSlugFromText(p.title), warning: getWarning(p.title), onToggle: p.onToggle, isEnabled: p.isEnabled }));
        }))));
};
const MenuItem = React.forwardRef(({ draggableProps, dragHandleProps, title, onClick, icon, isActive, warning, id, onToggle, isEnabled, }, ref) => {
    return (React.createElement("li", Object.assign({ ref: ref }, draggableProps, { onClick: onClick, id: id }),
        React.createElement(MenuItemContainer, { className: isActive ? "active" : null },
            React.createElement(IconBox, Object.assign({}, dragHandleProps),
                React.createElement(DCIcon, { type: icon })),
            React.createElement(MenuItemTitle, { style: !title ? { fontStyle: "italic", color: newTheme.colors.grey600 } : null }, title || DC_DEFAULT_MODULE_NAME),
            warning && (!onToggle || (!!onToggle && isEnabled)) && (React.createElement(TTooltip, { content: warning, position: "right" },
                React.createElement(WarningIcon, null))),
            !!onToggle && React.createElement(TToggleSwitch, { onChange: onToggle, enabled: isEnabled }))));
});
const MenuContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
const MenuHeader = styled.div `
  display: flex;
  align-items: center;
  height: 32px;
  font: ${newTheme.fonts.mediumBold};
  span {
    flex: 1;
  }
`;
const AddButton = styled.div `
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background-color: ${newTheme.colors.grey200};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.grey300};
  }
`;
const Menu = styled.ul `
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  width: 100%;
  list-style: none;
  li {
    list-style: none;
    width: 100%;
    margin-bottom: 16px;
  }
`;
const MenuItemContainer = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  border-radius: 6px;
  background: ${newTheme.colors.grey100};
  border: 1px solid ${newTheme.colors.grey100};
  gap: 12px;
  &:hover,
  &.active {
    border: 1px solid ${newTheme.colors.grey300};
    background: ${newTheme.colors.grey200};
  }
`;
const MenuItemTitle = styled.span `
  display: flex;
  flex: 1;
  align-items: center;
  font: ${newTheme.fonts.normal};
`;
const IconBox = styled.div `
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WarningIcon = styled.div `
  background-color: ${newTheme.colors.yellow500};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;
