import React from "react";
import TLabel from "../../../styled/t-label";
import TToggle from "../../../styled/t-toggle";
import { EditorToolSizeAndPosition } from "../../editor-tool-size-and-position";
import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { ToolColorPicker } from "../../editor-tool-color-picker";
import { BorderControl } from "../../editor-border-control";
export function Shape({ toolData }) {
    const updateTool = useUpdateTool();
    return (React.createElement("div", { style: { padding: 8 } },
        React.createElement(EditorToolSizeAndPosition, { toolId: toolData.id, x: toolData.sizeAndPosition.x, y: toolData.sizeAndPosition.y, width: toolData.sizeAndPosition.width, height: toolData.sizeAndPosition.height }),
        React.createElement(TLabel, null,
            "Background",
            React.createElement(TToggle, { value: toolData.enableBackground, onClick: () => {
                    updateTool(Object.assign(Object.assign({}, toolData), { enableBackground: !toolData.enableBackground }));
                } })),
        React.createElement(ToolColorPicker, { toolId: toolData.id, property: "backgroundColor", label: "Background color" }),
        React.createElement(BorderControl, { toolData: toolData }),
        React.createElement(TLabel, null,
            "Shadow",
            React.createElement(TToggle, { value: toolData.enableShadow, onClick: () => {
                    updateTool(Object.assign(Object.assign({}, toolData), { enableShadow: !toolData.enableShadow }));
                } }))));
}
export const userShadows = {
    default: "rgba(0, 0, 0, 0.5) 5px 5px 10px 0px",
};
