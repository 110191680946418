import React from "react";
import { getBorderStylesFromProps } from "../../../helpers/misc";
import { withToolWrapper } from "../with-tool-wrapper";
import { ViewportMode } from "../../../types/definitions";
function TourialClickZone(props) {
    const { viewportMode } = props;
    const { enableAnimation, animationColor } = props.toolData;
    const { disableAnimations } = props;
    return (React.createElement("div", { className: "_test_clickzone-viewtool", style: {
            width: `100%`,
            height: `100%`,
            position: "absolute",
            outline: viewportMode === ViewportMode.EDIT ? "1px dashed black" : null,
        } },
        React.createElement("div", { id: props.toolData.id, style: Object.assign(Object.assign({ display: "inline", width: `100%`, height: `100%`, position: "absolute" }, getBorderStylesFromProps(props)), { boxShadow: `${animationColor} 0px 0px 0px 0px`, animationDuration: "1s", animationTimingFunction: "cubic-bezier(0.66, 0, 0, 1)", animationDelay: "0s", animationIterationCount: "infinite", animationFillMode: "none", animationPlayState: "running", animationName: disableAnimations || !enableAnimation || viewportMode === ViewportMode.EDIT ? "none" : "pulsing", cursor: viewportMode === ViewportMode.EDIT ? "move" : "pointer" }) })));
}
export const ClickZone = React.memo(withToolWrapper(TourialClickZone));
