import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { useSavedColors, useToolColor, useToolData, useUpdateTool } from "../../../redux/selectors/redux-selectors";
import { checkNested, reactColorRgbaToString, rgbaToHex } from "../../../helpers/misc";
import { inputStyle, subLabelStyle } from "./tools/form-v2/editor";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
export function ToolColorPickerV2({ toolId, property, propertyParentName, propertyGrandParentName, label, backgroundPageId, style, }) {
    const tool = useToolData(toolId);
    const updateTool = useUpdateTool();
    const toolColor = useToolColor({
        toolId,
        propertyParentName,
        propertyGrandParentName,
        backgroundPageId,
        property,
    });
    const savedColors = useSavedColors();
    function parseHexString() {
        if (!toolColor)
            return { r: 54, g: 79, b: 167, a: 1 };
        const rgba = toolColor
            .substring(5, toolColor.length - 1)
            .replace(/ /g, "")
            .split(",");
        return {
            r: +rgba[0],
            g: +rgba[1],
            b: +rgba[2],
            a: +rgba[3],
        };
    }
    const [color, setColor] = useState(parseHexString);
    const [hex, setHex] = useState(rgbaToHex(color.r, color.g, color.b, color.a).slice(1, 7).toUpperCase());
    const [isOpen, setIsOpen] = useState(false);
    const colorPickerRef = useRef(null);
    useEffect(() => {
        if (!isOpen)
            document.removeEventListener("click", handleClickOutside);
        if (isOpen)
            document.addEventListener("click", handleClickOutside, { capture: true });
        return () => document.removeEventListener("click", handleClickOutside);
    }, [isOpen]);
    function handleClickOutside(event) {
        if ((colorPickerRef === null || colorPickerRef === void 0 ? void 0 : colorPickerRef.current) && !colorPickerRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                display: "flex",
                flexDirection: "column",
            } },
            React.createElement("div", { style: subLabelStyle }, label),
            React.createElement("div", { style: {
                    display: "flex",
                    marginBottom: 8,
                } },
                React.createElement("button", { style: {
                        width: 30,
                        height: 30,
                        backgroundColor: toolColor,
                        cursor: "pointer",
                        border: `1px solid ${theme.colors.grey.dark}`,
                    }, onClick: () => setIsOpen(!isOpen) }),
                React.createElement("div", { style: Object.assign(Object.assign({}, inputStyle), { color: theme.colors.black, marginLeft: 4, width: 55, marginRight: 16, lineHeight: "30px" }) }, hex))),
        isOpen && (React.createElement("div", { style: Object.assign({ margin: "0 auto" }, style), ref: colorPickerRef },
            React.createElement(SketchPicker, { presetColors: savedColors, color: color, onChange: ({ rgb, hex }) => {
                    setHex(hex.split("#")[1].toUpperCase());
                    setColor(rgb);
                    if (backgroundPageId) {
                        // We are updating BACKGROUND color
                        const backgroundTool = tool;
                        const newBackgroundData = Object.assign(Object.assign({}, backgroundTool), { backgrounds: Object.assign(Object.assign({}, backgroundTool.backgrounds), { [backgroundPageId]: Object.assign(Object.assign({}, backgroundTool.backgrounds[backgroundPageId]), { [property]: reactColorRgbaToString(rgb), src: "" }) }) });
                        return updateTool(newBackgroundData);
                    }
                    updateTool(addColorToTool(tool, rgb, property, propertyParentName, propertyGrandParentName));
                } })))));
}
const addColorToTool = (tool, rgbColor, property, propertyParentName, propertyGrandParentName) => {
    const updatedTool = cloneDeep(tool);
    const colorString = reactColorRgbaToString(rgbColor);
    if (propertyGrandParentName && checkNested(updatedTool, propertyGrandParentName, propertyParentName, property)) {
        updatedTool[propertyGrandParentName][propertyParentName][property] = colorString;
    }
    else if (propertyParentName) {
        updatedTool[propertyParentName] = updatedTool[propertyParentName] || {};
        updatedTool[propertyParentName][property] = colorString;
    }
    else {
        updatedTool[property] = colorString;
    }
    return updatedTool;
};
