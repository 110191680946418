var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { cloneDeep } from "lodash";
import { getTrimmedVideoStartOffset, trimVideoForThumbnail } from "../../client/src/helpers/video-trimming";
import { BorderShape } from "../types/tool-data-types/common";
import { TourialStatus } from "../types/tourial-model";
export function objectToArray(obj) {
    return Object.keys(obj).map(key => ({ key, value: obj[key] }));
}
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export function getEnumKeyByEnumValue(tourialEnum, enumValue) {
    let keys = Object.keys(tourialEnum).filter(x => tourialEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
}
const getOrdinalNum = function (d) {
    if (d > 3 && d < 21)
        return d + "th";
    switch (d % 10) {
        case 1:
            return d + "st";
        case 2:
            return d + "nd";
        case 3:
            return d + "rd";
        default:
            return d + "th";
    }
};
/**
 *
 * @param timestamp - date string
 * @returns a string in this format => "May 30th - 9:20am"
 */
export function getDateStringWithTime(timestamp) {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const date = new Date(timestamp);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes().toString().padStart(2, "0");
    const ampm = hour >= 12 ? "pm" : "am";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${month} ${getOrdinalNum(day)} - ${formattedHour}:${minute}${ampm}`;
}
export function getDateStringWithOrdinalNum(isoString) {
    let isoStringToDateString = new Date(isoString).toDateString();
    isoStringToDateString = isoStringToDateString.split(" ");
    isoStringToDateString.shift();
    isoStringToDateString[1] = getOrdinalNum(+isoStringToDateString[1]);
    return isoStringToDateString.join(" ");
}
export function dateToYMD(date) {
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
}
export function getVideoThumbnail(src) {
    let uploadIndex = src === null || src === void 0 ? void 0 : src.indexOf("/upload/");
    if (!src || typeof uploadIndex !== "number")
        return "";
    return trimVideoForThumbnail(src, getTrimmedVideoStartOffset(src));
}
export function transformImageToWebp(src) {
    let uploadIndex = src === null || src === void 0 ? void 0 : src.indexOf("/upload/");
    if (!src || typeof uploadIndex !== "number")
        return "";
    uploadIndex += 8;
    return src.substring(0, uploadIndex) + "b_white/f_webp/" + src.substring(uploadIndex);
}
export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export function validateIpAddress(ip) {
    const re = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return re.test(ip);
}
export function roundToHundredths(n) {
    return Math.round(n * 100) / 100;
}
export function roundToPrecision(n, precision) {
    return Math.round(n * precision) / precision;
}
export function getBaseUrlWithSubdomainLegacy(subdomain, whiteLabelDomain) {
    return whiteLabelDomain
        ? `https://${whiteLabelDomain}/`
        : location.href.includes("localhost")
            ? `http://${subdomain}.localhost:5050/`
            : `https://${subdomain}.tourial.com/`;
}
export function getBaseUrlWithSubdomainServer(subdomain, whiteLabelDomain) {
    return whiteLabelDomain
        ? `https://${whiteLabelDomain}/`
        : process.env.DAR_ENV === "development"
            ? `http://${subdomain}.localhost:5050/`
            : `https://${subdomain}.tourial.com/`;
}
export function prettyPrint(it) {
    console.log(JSON.stringify(it, null, 2));
}
export const getShapeFromRadius = (radius) => {
    switch (radius) {
        case 0:
            return BorderShape.RECTANGLE;
        case 8:
            return BorderShape.ROUNDED;
        case 100:
            return BorderShape.SQUIRCLE;
        default:
            return BorderShape.RECTANGLE;
    }
};
export const getRadiusFromShape = (shape) => {
    switch (shape) {
        case BorderShape.RECTANGLE:
            return 0;
        case BorderShape.ROUNDED:
            return 8;
        case BorderShape.SQUIRCLE:
            return 100;
    }
};
export const moveArrayElement = (array, startIndex, endIndex) => {
    if (startIndex < 0 || startIndex >= array.length)
        throw new Error("start index is out of bounds");
    if (endIndex < 0 || endIndex >= array.length)
        throw new Error("end index is out of bounds");
    const arrayCopy = cloneDeep(array);
    const stepToMove = arrayCopy.splice(startIndex, 1)[0];
    arrayCopy.splice(endIndex, 0, stepToMove);
    return arrayCopy;
};
export const ensureHttpsInUrl = (url) => {
    if (!url)
        return "";
    return `https://${url.replace(/^https?:\/\//, "")}`;
};
export const openUrlInNewTab = (url) => {
    window.open(url, "_blank");
};
export const tourialStatusReadable = {
    [TourialStatus.LIVE]: "Live",
    [TourialStatus.DRAFT]: "Draft",
    [TourialStatus.UNPUBLISHED]: "Unpublished",
    [TourialStatus.ARCHIVED]: "Archived",
    [TourialStatus.PENDING_CHANGES]: "Unpublished Changes",
};
export function stripQueryParams(url) {
    try {
        const parsedUrl = new URL(url);
        parsedUrl.search = "";
        return parsedUrl.toString();
    }
    catch (_a) {
        return url;
    }
}
export function isITourial(obj) {
    return "media" in obj && "variantDesktop" in obj;
}
export function isDomTour(obj) {
    return "theme" in obj && "steps" in obj;
}
export function appendParam(history, paramName, paramValue) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(paramName, paramValue);
    history.push({
        pathname: window.location.pathname,
        search: searchParams.toString(),
    });
}
export function removeParam(history, paramName) {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.delete(paramName);
    history.replace({
        pathname: window.location.pathname,
        search: searchParams.toString(),
    });
}
export function fetchWithRetry(url, retries = 3, backoff = 300) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield fetch(url);
            if (!response.ok && retries > 0 && response.status === 500) {
                console.log(`Retrying in ${backoff}ms...`);
                yield sleep(backoff);
                // double the backoff on each retry
                return fetchWithRetry(url, retries - 1, backoff * 2);
            }
            return response;
        }
        catch (error) {
            console.error(error);
        }
    });
}
export function formatDollarAmount(amount) {
    if (isNaN(amount)) {
        console.error("cannot format invalid amount");
        return amount;
    }
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
}
export function formatDateToMMDDYYYY(date) {
    try {
        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };
        return new Intl.DateTimeFormat("en-US", options).format(date);
    }
    catch (_a) {
        console.log("cannot format invalid date");
    }
}
