var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TourialApiCalls } from "./tourial-api-calls";
import { TriggerDispatchEvent } from "../../../shared/legacy/legacy-tool-data";
import { useActiveVariantName, useActiveZoomId, useActivePageId, useLoadEvent, useSessionId, useSetLoadEvent, useLiveModeAccountId, useTourialId, useViewport, useVisitorId, useIsSessionStarted, useSetIsSessionStarted, useIsMicrositeSessionStarted, useSetIsMicrositeSessionStarted, useIsDCSessionStarted, useSetIsDCSessionStarted, useShareLinkId, useShareLink, } from "../redux/selectors/redux-selectors";
import { useTourialVersion } from "../redux/selectors/live-selectors";
import { ViewportMode } from "../types/definitions";
import { getDeviceWidth, isEmbedded, isInIframe, isInLightbox, isInProductPage, isOgImage } from "./live";
import { CURRENT_EVENT_VERSION, TourialEvent, tourialParams, utmParams, } from "../../../shared/types/events";
import { useMicrositeAccountId, useMicrositeActiveTourId, useMicrositeId, useMicrositeSessionId, useMicrositeVersion, } from "../redux/selectors/microsite-selectors";
import { cloneDeep } from "lodash";
import { useLatestTourialViewEvent, useSetLatestTourialViewEvent } from "../legacy/selectors";
import { convertDCEventToPublicEvent, convertEventToPublicEvent } from "../../../shared/functions/events";
import { useDCMode, useDemoCenter, useDCSessionId, useDCActiveModuleSlug, } from "../redux/selectors/demo-center-selectors";
import { DCMode } from "../../../shared/types/demo-center";
import { HTMode } from "../../../shared/types/dom-tour";
import { useHTId, useHTMode, useHTSessionId, useHTStep } from "../redux/selectors/ht-selectors";
export function getShareLinkByPathname(pathname, tourialId, accountId) {
    return __awaiter(this, void 0, void 0, function* () {
        const pathnameParts = pathname.split("/").filter(p => p.length && p !== "pages");
        const slug = pathnameParts[1];
        if (slug) {
            try {
                const l = yield TourialApiCalls.ShareLinks.getShareLink({ tourialId, accountId, slug });
                if (!l)
                    return undefined;
                return l;
            }
            catch (e) {
                console.error("failed to get shareLink", e);
            }
        }
    });
}
export function getDTShareLinkByPathname(slug, tourialId, accountId) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const l = yield TourialApiCalls.ShareLinks.getShareLink({ tourialId, accountId, slug });
            if (!l)
                return undefined;
            return l;
        }
        catch (e) {
            console.error("failed to get shareLink", e);
        }
    });
}
function getReferringUrl(url) {
    const href = url.searchParams.get("tourialReferring");
    if (href)
        return href;
    if (isInIframe() && canAccessParent()) {
        return window.parent.document.referrer;
    }
    return document.referrer || null;
}
function getParentUrl(url) {
    const href = url.searchParams.get("parentUrl");
    if (href)
        return href;
    if (isInIframe() && canAccessParent()) {
        return window.parent.location.href;
    }
    return null;
}
function getDeviceWidthUrl(url) {
    const deviceWidth = url.searchParams.get("deviceWidth");
    if (deviceWidth) {
        console.log("retrieved deviceWidth from params");
        return +deviceWidth;
    }
    return null;
}
function getParams(url) {
    const r = { customUtm: [] };
    url.searchParams.forEach((v, k) => {
        if (utmParams.includes(k)) {
            r[k] = v; // dedupe for latest value
        }
        else if (!tourialParams.includes(k)) {
            r.customUtm = [...r.customUtm, { utmName: k, utmValue: v }];
        }
    });
    return r;
}
function canAccessParent() {
    try {
        if (typeof window.parent.location.search === "string" &&
            typeof window.parent.document.referrer === "string" &&
            typeof window.parent.location.href === "string") {
            return true;
        }
    }
    catch (e) {
        // This is expected to happen in a lot of cases with embed/iframe
        // console.error("could not access parent", e);
        return false;
    }
    return false;
}
function startMicrositeSession(e, isStarted, setIsStarted) {
    return __awaiter(this, void 0, void 0, function* () {
        if (e.eventType === "MICROSITE_SESSION_START") {
            setIsStarted(true);
        }
        else if (e.micrositeId && !isStarted && eventShouldTriggerMicrositeSessionStart()) {
            const micrositeSessionStartEvent = cloneDeep(e);
            micrositeSessionStartEvent.eventType = "MICROSITE_SESSION_START";
            console.log(`posting microsite event: ${micrositeSessionStartEvent.eventType}`, micrositeSessionStartEvent);
            yield TourialApiCalls.Events.postEvent(micrositeSessionStartEvent);
            setIsStarted(true);
        }
        function eventShouldTriggerMicrositeSessionStart() {
            const eventsThatDoNotTriggerMicrositeSessionStart = [
                "MICROSITE_LOAD",
                "MICROSITE_SESSION_START",
                "MICROSITE_SESSION_END",
                "MICROSITE_CONTENT_LOAD",
                "TOURIAL_LOAD",
                "TOURIAL_VIEW",
                "SESSION_START",
                "SESSION_END",
            ];
            return !eventsThatDoNotTriggerMicrositeSessionStart.includes(e.eventType);
        }
    });
}
function startDemocenterSession(e, isStarted, setIsStarted) {
    return __awaiter(this, void 0, void 0, function* () {
        if (e.eventType === "DEMOCENTER_SESSION_START") {
            setIsStarted(true);
        }
        else if (e.democenterId && !isStarted && eventShouldTriggerDemocenterSessionStart()) {
            const democenterSessionStartEvent = cloneDeep(e);
            democenterSessionStartEvent.eventType = "DEMOCENTER_SESSION_START";
            console.log(`posting democenter event: ${democenterSessionStartEvent.eventType}`, democenterSessionStartEvent);
            yield TourialApiCalls.Events.postEvent(democenterSessionStartEvent);
            setIsStarted(true);
        }
        function eventShouldTriggerDemocenterSessionStart() {
            const eventsThatDoNotTriggerDemocenterSessionStart = [
                "MICROSITE_LOAD",
                "MICROSITE_SESSION_START",
                "MICROSITE_SESSION_END",
                "DEMOCENTER_LOAD",
                "DEMOCENTER_CONTENT_LOAD",
                "DEMOCENTER_MODULE_LOAD",
                "DEMOCENTER_SESSION_START",
                "DEMOCENTER_SESSION_END",
                "TOURIAL_LOAD",
                "TOURIAL_VIEW",
                "SESSION_START",
                "SESSION_END",
                "DEMOCENTER_USER",
            ];
            return !eventsThatDoNotTriggerDemocenterSessionStart.includes(e.eventType);
        }
    });
}
function validateMicrositeEventPartial(e, isMicrositeSessionStarted) {
    if (!e.micrositeId) {
        console.error("microsite post event requires a microsite");
        return false;
    }
    if (!e.eventType) {
        console.error("must pass eventType to microsite post event");
        return false;
    }
    if (!e.eventType.startsWith("MICROSITE_")) {
        console.error("invalid microsite event type");
        return false;
    }
    if (!e.micrositeSessionId) {
        return false;
    }
    if (isOgImage())
        return false;
    if (e.eventType === "MICROSITE_SESSION_START") {
        if (isMicrositeSessionStarted) {
            console.error("microsite session is already started");
            return false;
        }
    }
    return true;
}
export const usePostMicrositeEvent = () => {
    const micrositeActiveTourId = useMicrositeActiveTourId();
    const micrositeId = useMicrositeId();
    const micrositeSessionId = useMicrositeSessionId();
    const userId = useVisitorId();
    const deviceWidth = getDeviceWidth();
    const isMicrositeSessionStarted = useIsMicrositeSessionStarted();
    const setIsMicrositeSessionStarted = useSetIsMicrositeSessionStarted();
    const micrositeVersion = useMicrositeVersion();
    const accountId = useMicrositeAccountId();
    const sessionId = useSessionId();
    return (eventPartial) => __awaiter(void 0, void 0, void 0, function* () {
        const url = new URL(window.location.href);
        let e = Object.assign({ 
            // ...(loadEvent || {}),
            userId,
            accountId,
            deviceWidth,
            micrositeId,
            micrositeSessionId,
            micrositeVersion, isEmbedded: false, isInIframe: false, isInLightbox: false, isInProductPage: false, isInMicrosite: true, eventVersion: CURRENT_EVENT_VERSION }, eventPartial);
        if (micrositeActiveTourId) {
            e.tourId = micrositeActiveTourId;
        }
        if (sessionId) {
            e.sessionId = sessionId;
        }
        if (!validateMicrositeEventPartial(e, isMicrositeSessionStarted)) {
            console.error(`Invalid microsite event: ${e.eventType}`, e);
            return;
        }
        e = appendReferrerAndParams(e, url);
        yield startMicrositeSession(e, isMicrositeSessionStarted, setIsMicrositeSessionStarted);
        console.log(`posting microsite event: ${e.eventType}`, e);
        yield TourialApiCalls.Events.postEvent(e);
    });
};
function validateDCEventPartial(e, isDemocenterSessionStarted) {
    if (!e.democenterId) {
        console.error("democenter post event requires a democenterId");
        return false;
    }
    if (!e.eventType) {
        console.error("must pass eventType to democenter post event");
        return false;
    }
    if (!e.eventType.startsWith("DEMOCENTER_")) {
        console.error("invalid democenter event type");
        return false;
    }
    if (!e.democenterSessionId) {
        return false;
    }
    if (isOgImage())
        return false;
    if (e.eventType === "DEMOCENTER_SESSION_START") {
        if (isDemocenterSessionStarted) {
            console.error("democenter session is already started");
            return false;
        }
    }
    return true;
}
export const usePostDCEvent = () => {
    const democenter = useDemoCenter();
    const democenterSessionId = useDCSessionId();
    const isDCSessionStarted = useIsDCSessionStarted();
    const setIsDCSessionStarted = useSetIsDCSessionStarted();
    const moduleSlug = useDCActiveModuleSlug();
    const userId = useVisitorId();
    const shareLinkId = useShareLinkId();
    return (eventPartial) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            const url = new URL(window.location.href);
            const democenterModule = democenter.modules.find(m => m.slug === moduleSlug);
            const democenterModuleId = eventPartial.democenterModuleId || (democenterModule === null || democenterModule === void 0 ? void 0 : democenterModule.id);
            const democenterModuleFilters = democenterModule &&
                ((_a = eventPartial === null || eventPartial === void 0 ? void 0 : eventPartial.democenterModuleFilters) === null || _a === void 0 ? void 0 : _a.map(f => { var _a; return (_a = democenterModule.tracks.find(t => t.slug === f)) === null || _a === void 0 ? void 0 : _a.id; }));
            const democenterTrackSelections = (_b = eventPartial === null || eventPartial === void 0 ? void 0 : eventPartial.democenterTrackSelections) === null || _b === void 0 ? void 0 : _b.map(s => {
                const module = democenter.modules.find(m => m.slug === s.module);
                return {
                    module: module === null || module === void 0 ? void 0 : module.id,
                    tracks: s.tracks.map(t => { var _a; return (_a = module.tracks.find(mt => mt.slug === t)) === null || _a === void 0 ? void 0 : _a.id; }),
                };
            });
            let e = Object.assign(Object.assign({ userId, accountId: democenter.accountId, democenterId: democenter._id, democenterSessionId,
                shareLinkId }, eventPartial), { democenterModuleId,
                democenterModuleFilters,
                democenterTrackSelections, eventVersion: CURRENT_EVENT_VERSION });
            if (!validateDCEventPartial(e, isDCSessionStarted)) {
                console.error(`Invalid democenter event: ${e.eventType}`, e);
                return;
            }
            e = appendReferrerAndParams(e, url);
            try {
                const eventsToPostToEmbed = [
                    TourialEvent.DEMOCENTER_BUTTON_CLICK,
                    TourialEvent.DEMOCENTER_SESSION_START,
                    TourialEvent.DEMOCENTER_CONTENT_LOAD,
                    TourialEvent.FORM_SUBMIT,
                    TourialEvent.DEMOCENTER_CONTENT_PROGRESS,
                ];
                if (eventsToPostToEmbed.includes(e.eventType)) {
                    window.parent.postMessage({ type: "TOURIAL_EVENT", payload: convertDCEventToPublicEvent(e) }, "*");
                }
            }
            catch (e) {
                console.error("ERROR POSTING MESSAGE");
                console.error(e);
            }
            yield startDemocenterSession(e, isDCSessionStarted, setIsDCSessionStarted);
            console.log(`posting democenter event: ${e.eventType}`, e);
            yield TourialApiCalls.Events.postEvent(e);
        }
        catch (_c) {
            console.error("failed to post DC event");
        }
    });
};
function validateEventPartial(e, loadEvent, viewportMode, isSessionStarted, dcMode, htMode) {
    if (viewportMode !== ViewportMode.LIVE && dcMode !== DCMode.LIVE && htMode !== HTMode.LIVE) {
        console.error("attempt to post event outside of live mode");
        return false;
    }
    if (!e.eventType) {
        console.error("must pass eventType to postEvent");
        return false;
    }
    if (e.micrositeId && !e.micrositeSessionId) {
        return false;
    }
    if (!(e.sessionId || e.micrositeSessionId || e.democenterSessionId)) {
        console.error("no sessionId");
        return false;
    }
    if (isOgImage())
        return;
    if (e.eventType === "TOURIAL_LOAD") {
        if (loadEvent && loadEvent.sessionId === e.sessionId) {
            console.error("tourial load event already sent");
            return false;
        }
    }
    if (e.eventType === "SESSION_START") {
        if (isSessionStarted) {
            console.error("session is already started");
            return false;
        }
    }
    return true;
}
function startSession(e, isStarted, setIsStarted) {
    return __awaiter(this, void 0, void 0, function* () {
        if (e.eventType === "SESSION_START") {
            setIsStarted(true);
            window.parent.postMessage({ type: "TOURIAL_EVENT", payload: convertEventToPublicEvent(e) }, "*");
        }
        else if (!isStarted && eventShouldTriggerSessionStart()) {
            const sessionStartEvent = cloneDeep(e);
            sessionStartEvent.eventType = "SESSION_START";
            console.log(`posting event: ${sessionStartEvent.eventType}`, sessionStartEvent);
            yield TourialApiCalls.Events.postEvent(sessionStartEvent);
            window.parent.postMessage({ type: "TOURIAL_EVENT", payload: convertEventToPublicEvent(sessionStartEvent) }, "*");
            setIsStarted(true);
        }
        function eventShouldTriggerSessionStart() {
            if (e.eventType.startsWith("MICROSITE") && e.eventType !== "MICROSITE_CTA_CLICK") {
                return false;
            }
            const eventsThatDoNotTriggerSessionStart = [
                "PRODUCT_PAGE_LOAD",
                "TOURIAL_LOAD",
                "TOURIAL_LOAD_UPDATE",
                "TOURIAL_VIEW",
                "SESSION_START",
                "SESSION_END",
                "INTEGRATION_LOADED",
                "TOURIAL_USER",
            ];
            return !eventsThatDoNotTriggerSessionStart.includes(e.eventType);
        }
    });
}
function endSession(e, isStarted, setIsStarted) {
    if (e.eventType === "SESSION_END") {
        if (!isStarted) {
            console.error("cannot end session that has not started");
            return;
        }
        setIsStarted(false);
    }
}
function storeLoadEvent(e, loadEvent, setLoadEvent) {
    if (e.eventType === "TOURIAL_LOAD") {
        if ((loadEvent === null || loadEvent === void 0 ? void 0 : loadEvent.sessionId) === e.sessionId) {
            // don't overwrite load event if it has the same tourial session
        }
        else {
            setLoadEvent(e);
        }
    }
    else if (e.eventType === "TOURIAL_LOAD_UPDATE") {
        setLoadEvent(Object.assign(Object.assign({}, loadEvent), appendReferrerAndParams(e, new URL(window.location.href))));
    }
}
export const usePostEvent = () => {
    const micrositeActiveTourId = useMicrositeActiveTourId();
    const micrositeId = useMicrositeId();
    const demoCenter = useDemoCenter();
    const micrositeSessionId = useMicrositeSessionId();
    const micrositeVersion = useMicrositeVersion();
    const isMicrositeSessionStarted = useIsMicrositeSessionStarted();
    const setIsMicrositeSessionStarted = useSetIsMicrositeSessionStarted();
    const democenterSessionId = useDCSessionId();
    const sessionId = useSessionId();
    const htSessionId = useHTSessionId();
    const userId = useVisitorId();
    const activeVariantName = useActiveVariantName();
    const tourId = useTourialId();
    const htId = useHTId();
    const pageId = useActivePageId();
    const zoomId = useActiveZoomId();
    const viewport = useViewport();
    const accountId = useLiveModeAccountId();
    const version = useTourialVersion();
    const deviceWidth = getDeviceWidth();
    const loadEvent = useLoadEvent();
    const setLoadEvent = useSetLoadEvent();
    const isSessionStarted = useIsSessionStarted();
    const setIsSessionStarted = useSetIsSessionStarted();
    const latestTourialViewEvent = useLatestTourialViewEvent();
    const setLatestTourialViewEvent = useSetLatestTourialViewEvent();
    const dcMode = useDCMode();
    const htMode = useHTMode();
    const isInMicrosite = !!micrositeId;
    const isInDemocenter = !!demoCenter;
    const demoCenterMode = useDCMode();
    const htStep = useHTStep();
    const dcshareLinkId = useShareLinkId();
    const shareLink = useShareLink();
    return (eventPartial) => __awaiter(void 0, void 0, void 0, function* () {
        const url = new URL(window.location.href);
        let e = Object.assign(Object.assign(Object.assign({}, loadEvent), { userId, sessionId: sessionId || htSessionId, democenterSessionId, tourId: micrositeActiveTourId || tourId || htId, version, pageId: (htStep === null || htStep === void 0 ? void 0 : htStep.id) || pageId, htVersion: htId && 1, zoomId,
            activeVariantName,
            accountId,
            deviceWidth, isEmbedded: isEmbedded(), isInIframe: isInIframe(), isInLightbox: isInLightbox(), isInProductPage: isInProductPage(), isInMicrosite,
            isInDemocenter, eventVersion: CURRENT_EVENT_VERSION }), eventPartial);
        if (!validateEventPartial(e, loadEvent, viewport.mode, isSessionStarted, dcMode, htMode)) {
            return;
        }
        if (isInDemocenter && demoCenterMode !== DCMode.LIVE)
            return;
        if (!loadEvent) {
            if (micrositeId) {
                e.micrositeId = micrositeId;
            }
            if (micrositeVersion) {
                e.micrositeVersion = micrositeVersion;
            }
            if (micrositeSessionId) {
                e.micrositeSessionId = micrositeSessionId;
            }
            if (demoCenter) {
                e.democenterId = demoCenter._id;
                e.democenterSessionId = democenterSessionId;
            }
            if (!isInMicrosite && !isInDemocenter) {
                const shareLinkId = shareLink === null || shareLink === void 0 ? void 0 : shareLink._id;
                if (shareLinkId) {
                    e.shareLinkId = shareLinkId;
                }
            }
            else if (isInDemocenter && dcshareLinkId) {
                e.shareLinkId = dcshareLinkId;
            }
            e = appendReferrerAndParams(e, url);
        }
        if (e.eventType === "TOURIAL_VIEW") {
            setLatestTourialViewEvent(e);
        }
        try {
            const eventsToPostToEmbed = [
                TourialEvent.CLICK,
                TourialEvent.FORM_SUBMIT,
                TourialEvent.SESSION_START,
                TourialEvent.TOURIAL_VIEW,
            ];
            if (eventsToPostToEmbed.includes(e.eventType)) {
                window.parent.postMessage({ type: "TOURIAL_EVENT", payload: convertEventToPublicEvent(e) }, "*");
            }
        }
        catch (e) {
            console.error("ERROR POSTING MESSAGE");
            console.error(e);
        }
        yield startMicrositeSession(e, isMicrositeSessionStarted, setIsMicrositeSessionStarted);
        yield endSessionIfSwitchedWithinMicrosite(e, loadEvent, latestTourialViewEvent, setIsSessionStarted);
        yield endSessionIfSwitchedWithinDemoCenter(e, loadEvent, latestTourialViewEvent, setIsSessionStarted);
        storeLoadEvent(e, loadEvent, setLoadEvent);
        yield startSession(e, isSessionStarted, setIsSessionStarted);
        endSession(e, isSessionStarted, setIsSessionStarted);
        if (process.env.DAR_ENV !== "production")
            console.log(`posting event: ${e.eventType}`, e);
        yield TourialApiCalls.Events.postEvent(e);
    });
};
function endSessionIfSwitchedWithinMicrosite(e, loadEvent, latestTourialViewEvent, setIsStarted) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!e.isInMicrosite || !latestTourialViewEvent)
            return;
        if (e.eventType === "TOURIAL_LOAD") {
            if ((loadEvent === null || loadEvent === void 0 ? void 0 : loadEvent.tourId) !== e.tourId) {
                const sessionEndEvent = cloneDeep(latestTourialViewEvent);
                sessionEndEvent.eventType = "SESSION_END";
                console.log(`posting event: ${sessionEndEvent.eventType}`, sessionEndEvent);
                yield TourialApiCalls.Events.postEvent(sessionEndEvent);
                setIsStarted(false);
            }
        }
    });
}
function endSessionIfSwitchedWithinDemoCenter(e, loadEvent, latestTourialViewEvent, setIsStarted) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!e.isInDemocenter || !latestTourialViewEvent)
            return;
        if (e.eventType === "TOURIAL_LOAD") {
            if ((loadEvent === null || loadEvent === void 0 ? void 0 : loadEvent.tourId) !== e.tourId) {
                const sessionEndEvent = cloneDeep(latestTourialViewEvent);
                sessionEndEvent.eventType = "SESSION_END";
                console.log(`posting event: ${sessionEndEvent.eventType}`, sessionEndEvent);
                yield TourialApiCalls.Events.postEvent(sessionEndEvent);
                setIsStarted(false);
            }
        }
    });
}
function appendReferrerAndParams(e, url) {
    const referringUrl = e.referringUrl || getReferringUrl(url);
    if (referringUrl) {
        e.referringUrl = referringUrl;
    }
    let params = getParams(url);
    const parentUrl = e.parentUrl || getParentUrl(url);
    if (parentUrl) {
        e.parentUrl = parentUrl;
        const parentParams = getParams(new URL(parentUrl));
        params = Object.assign(Object.assign(Object.assign({}, params), parentParams), { customUtm: [...params.customUtm, ...parentParams.customUtm] });
    }
    if (params) {
        if (!params.customUtm.length) {
            delete params.customUtm;
        }
        e = Object.assign(Object.assign({}, e), params);
    }
    const deviceWidth = e.deviceWidth || getDeviceWidthUrl(url);
    if (deviceWidth) {
        e.deviceWidth = deviceWidth;
    }
    return e;
}
export const getPostEventDataForTriggerListener = (baseEventData, triggerListener, nextPageId, nextZoomId, isLiveToolVisible, getToolTypeById, groupHasFormInputs) => {
    if (triggerListener.dispatchEvent === TriggerDispatchEvent.CHANGE_PAGE ||
        triggerListener.dispatchEvent === TriggerDispatchEvent.CHANGE_ZOOM) {
        return Object.assign(Object.assign({}, baseEventData), { eventType: "TRIGGER_LISTENER_INTERACTION_TRIGGERED", triggerListenerInteraction: {
                triggerListener,
                targetView: triggerListener.view,
            } });
    }
    else if (triggerListener.dispatchEvent === TriggerDispatchEvent.NEXT_PAGE) {
        return Object.assign(Object.assign({}, baseEventData), { eventType: "TRIGGER_LISTENER_INTERACTION_TRIGGERED", triggerListenerInteraction: {
                triggerListener,
                targetView: {
                    pageId: nextPageId,
                    zoomId: "MAIN",
                },
            } });
    }
    else if (triggerListener.dispatchEvent === TriggerDispatchEvent.NEXT_ZOOM ||
        triggerListener.dispatchEvent === TriggerDispatchEvent.PREVIOUS_ZOOM) {
        return Object.assign(Object.assign({}, baseEventData), { eventType: "TRIGGER_LISTENER_INTERACTION_TRIGGERED", triggerListenerInteraction: {
                triggerListener,
                targetView: {
                    pageId: nextZoomId ? baseEventData.pageId : nextPageId,
                    zoomId: nextZoomId || "MAIN",
                },
            } });
    }
    else if (triggerListener.dispatchEvent === TriggerDispatchEvent.HIDE_TOOL ||
        triggerListener.dispatchEvent === TriggerDispatchEvent.DISPLAY_TOOL) {
        return Object.assign(Object.assign({}, baseEventData), { eventType: "TRIGGER_LISTENER_INTERACTION_TRIGGERED", triggerListenerInteraction: {
                triggerListener,
                targetToolId: triggerListener.displayToolId,
                targetToolType: getToolTypeById(triggerListener.displayToolId),
                targetIsAForm: groupHasFormInputs(triggerListener.displayToolId),
                targetToolIsVisibleAfterTrigger: triggerListener.dispatchEvent === TriggerDispatchEvent.DISPLAY_TOOL,
            } });
    }
    else if (triggerListener.dispatchEvent === TriggerDispatchEvent.TOGGLE_TOOL) {
        return Object.assign(Object.assign({}, baseEventData), { eventType: "TRIGGER_LISTENER_INTERACTION_TRIGGERED", triggerListenerInteraction: {
                triggerListener,
                targetToolId: triggerListener.displayToolId,
                targetToolType: getToolTypeById(triggerListener.displayToolId),
                targetIsAForm: groupHasFormInputs(triggerListener.displayToolId),
                targetToolIsVisibleAfterTrigger: !isLiveToolVisible(triggerListener.displayToolId),
            } });
    }
    else if (triggerListener.dispatchEvent === TriggerDispatchEvent.EXTERNAL_LINK) {
        return Object.assign(Object.assign({}, baseEventData), { eventType: "TRIGGER_LISTENER_INTERACTION_TRIGGERED", triggerListenerInteraction: {
                triggerListener,
                targetExternalLink: triggerListener.href,
            } });
    }
    else if (triggerListener.dispatchEvent === TriggerDispatchEvent.SUBMIT_FORM) {
        return Object.assign(Object.assign({}, baseEventData), { eventType: "TRIGGER_LISTENER_INTERACTION_TRIGGERED", triggerListenerInteraction: {
                triggerListener,
                targetFormId: triggerListener.formId,
            } });
    }
};
