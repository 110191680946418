import React from "react";
import { isImageFileExtension } from "../../../helpers/media";
import { Link } from "react-router-dom";
import PlaceholderThumbnail from "../../../../assets/images/v3/placeholder-thumbnail.svg";
import { mobileThumbnailWidth, thumbnailHeight, thumbnailWidth as thumbnailWidthImported, } from "../../styled/editor-components";
import { newTheme } from "../../styled/theme";
import styled from "styled-components";
import { getBuilderUrl } from "../../../helpers/tourial-helpers/tourial-version-helpers";
import { useActiveVariantName } from "../../../redux/selectors/redux-selectors";
import { ActiveVariantName } from "../../../../../shared/types/tool-data-types/common";
import { getDimensionToGrow, getScreenTransform } from "../../../helpers/tourial-helpers/tourial-viewport-helpers";
import { getVideoThumbnail, transformImageToWebp } from "../../../../../shared/functions/helpers";
export default function ThumbnailViewport({ tourialData, isLink = false, scale = 1, style, }) {
    const { screen, zoom: activeZoom, mediaSize } = tourialData.firstPageData;
    const activeVariantName = useActiveVariantName();
    const srcSize = mediaSize;
    const isDesktop = (activeVariantName || ActiveVariantName.VARIANT_DESKTOP) === ActiveVariantName.VARIANT_DESKTOP;
    const dimensionToGrow = getDimensionToGrow(srcSize.width, srcSize.height, activeVariantName);
    const dimensionToGrowStyle = Object.assign(Object.assign({}, ((dimensionToGrow === null || dimensionToGrow === void 0 ? void 0 : dimensionToGrow.includes("x")) && { width: "100.01%" })), ((dimensionToGrow === null || dimensionToGrow === void 0 ? void 0 : dimensionToGrow.includes("y")) && { height: "100.01%" }));
    const vpw = isDesktop ? thumbnailWidthImported : mobileThumbnailWidth;
    const vph = thumbnailHeight;
    const transform = getScreenTransform({ vph, vpw, dimensionToGrow, srcSize, activeZoom });
    const mediaStyle = Object.assign(Object.assign({ display: "flex", justifyContent: "center", alignItems: "center" }, dimensionToGrowStyle), { transform });
    const src = isImageFileExtension(screen.src) ? transformImageToWebp(screen.src) : getVideoThumbnail(screen.src);
    return (React.createElement(ThumbnailContainer, { scale: scale, style: style },
        React.createElement(Link, { to: getBuilderUrl(tourialData.tourId, tourialData.version), style: {
                opacity: 0.85,
                pointerEvents: isLink ? "auto" : "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: isDesktop ? "100%" : `${mobileThumbnailWidth}px`,
                height: "100%",
                margin: "auto",
                overflow: "hidden",
            } }, src ? (React.createElement("div", { style: mediaStyle },
            React.createElement("img", { src: src, style: Object.assign({ objectFit: "cover" }, dimensionToGrowStyle), draggable: false }))) : (React.createElement("img", { className: "_test_placeholder-thumbnail", src: PlaceholderThumbnail, style: { width: "100%", height: "100%" }, draggable: false })))));
}
const ThumbnailContainer = styled.div `
  background-color: ${newTheme.colors.grey300};
  width: ${thumbnailWidthImported}px;
  height: ${thumbnailHeight}px;
  margin: 15.45px 34.45px;
  border-radius: 5px;
  overflow: hidden;
  transform: ${props => `scale(${props.scale})`};
  box-sizing: content-box;
`;
