import { useEffect } from "react";
import { useBuilderFocusField, useSetBuilderFocusField } from "../redux/selectors/redux-selectors";
import { useMicrositeEditorFocusField, useSetMicrositeEditorFocusField } from "../redux/selectors/microsite-selectors";
export function useFocusTourialEditorField(setOpenId) {
    const field = useBuilderFocusField();
    const setField = useSetBuilderFocusField();
    useEffect(() => {
        if (field) {
            setOpenId === null || setOpenId === void 0 ? void 0 : setOpenId(field);
            setFocusTimeout(field);
            setField(null);
        }
    }, [field]);
}
export function useFocusMicrositeEditorField(setOpenId) {
    const field = useMicrositeEditorFocusField();
    const setField = useSetMicrositeEditorFocusField();
    useEffect(() => {
        if (field) {
            setOpenId(field);
            setFocusTimeout(field);
            setField(null);
        }
    }, [field]);
}
function setFocusTimeout(field) {
    setTimeout(() => {
        var _a;
        const inputElement = document.querySelector(`div#${field} .focusable-input, div#${field} .ql-editor, label#${field} .focusable-input`);
        if ((_a = inputElement === null || inputElement === void 0 ? void 0 : inputElement.className) === null || _a === void 0 ? void 0 : _a.includes("ql-editor")) {
            /** reference: https://stackoverflow.com/questions/1125292/how-to-move-cursor-to-end-of-contenteditable-entity */
            let range, selection;
            if (document.createRange) {
                range = document.createRange();
                range.selectNodeContents(inputElement);
                range.collapse(false);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            }
            else {
                // @ts-expect-error
                inputElement === null || inputElement === void 0 ? void 0 : inputElement.focus();
            }
        }
        else {
            // @ts-expect-error
            inputElement === null || inputElement === void 0 ? void 0 : inputElement.focus();
        }
    }, 0);
}
