import React from "react";
import { checkNested, rgbaToHex } from "../../../../../helpers/misc";
import { Divider, inputStyle, labelStyle, subLabelStyle, tSelectStyle } from "./editor";
import TSelect from "../../../styled/t-select";
import { useActiveVariantName, useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { cloneDeep } from "lodash";
import TNumberInput from "../../../styled/t-number-input";
import { theme } from "../../../../../../../shared/legacy/legacy-tool-data";
import CloseButtonIcon from "../../../../../../assets/images/close-button.svg";
import { ActiveVariantName } from "../../../../../../../shared/types/tool-data-types/common";
export default function FormImageModal({ onClose, show, toolData }) {
    var _a, _b, _c, _d, _e;
    const updateTool = useUpdateTool();
    const activeVariantName = useActiveVariantName();
    const isDesktop = activeVariantName === ActiveVariantName.VARIANT_DESKTOP;
    if (!show) {
        return null;
    }
    return (React.createElement("div", { style: {
            position: "fixed",
            bottom: 0,
            right: 0,
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "end",
            alignItems: "start",
            zIndex: 5,
            backgroundColor: rgbaToHex(0, 0, 0, 0),
        }, onClick: onClose },
        React.createElement("div", { id: "modal-content", style: {
                width: 240,
                height: 299,
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginRight: 240,
                marginTop: 188,
                padding: 8,
            }, onClick: e => e.stopPropagation() },
            React.createElement("div", { id: "modal-header", style: {
                    width: "calc(100% + 16px)",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 8px",
                    backgroundColor: theme.colors.grey.lightest,
                    margin: "-8px -8px 0px -8px",
                } },
                React.createElement("div", { style: labelStyle }, "Logo Properties"),
                React.createElement("img", { style: {
                        position: "relative",
                        left: 0,
                        top: 0,
                        width: 16.5,
                        cursor: "pointer",
                    }, src: CloseButtonIcon, onClick: onClose })),
            React.createElement("div", { id: "modal-body", className: "hide-scrollbar", style: {
                    width: "100%",
                    display: "flex",
                    flexFlow: "row wrap",
                    alignItems: "center",
                    flexGrow: 1,
                    overflowY: "scroll",
                } },
                React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "100%", margin: "0px 0px 4px" }) },
                    "Shape",
                    React.createElement(TSelect, { style: Object.assign({ width: "100%", margin: 0 }, tSelectStyle), value: (_a = toolData === null || toolData === void 0 ? void 0 : toolData.logo) === null || _a === void 0 ? void 0 : _a.shape, onChange: e => {
                            const newTool = cloneDeep(toolData);
                            if (checkNested(newTool, "logo", "shape")) {
                                newTool.logo.shape = e.target.value;
                                updateTool(newTool);
                            }
                        } },
                        React.createElement("option", { value: "circle" }, "Circle"),
                        React.createElement("option", { value: "rectangle" }, "Rectangle"))),
                React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "calc(50% - 4px)", margin: "0px 8px 0px 0px" }) },
                    "X Offset",
                    React.createElement(TNumberInput, { value: ((_b = toolData === null || toolData === void 0 ? void 0 : toolData.logo) === null || _b === void 0 ? void 0 : _b.xOffset) || 0, onChange: e => {
                            const newTool = cloneDeep(toolData);
                            if (checkNested(newTool, "logo", "xOffset")) {
                                newTool.logo.xOffset = e.target.value;
                                updateTool(newTool);
                            }
                        }, style: inputStyle })),
                React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "calc(50% - 4px)", margin: 0 }) },
                    "Y Offset",
                    React.createElement(TNumberInput, { value: ((_c = toolData === null || toolData === void 0 ? void 0 : toolData.logo) === null || _c === void 0 ? void 0 : _c.yOffset) || 0, onChange: e => {
                            const newTool = cloneDeep(toolData);
                            if (checkNested(newTool, "logo", "yOffset")) {
                                newTool.logo.yOffset = e.target.value;
                                updateTool(newTool);
                            }
                        }, style: inputStyle })),
                React.createElement(Divider, { style: { marginBottom: "-8px" } }),
                React.createElement("div", { style: Object.assign(Object.assign({}, subLabelStyle), { width: "100%", margin: 0 }) },
                    "Size",
                    React.createElement(TNumberInput, { min: 1, max: 50, value: Math.round((((_e = (_d = toolData === null || toolData === void 0 ? void 0 : toolData.logo) === null || _d === void 0 ? void 0 : _d.srcDimensions) === null || _e === void 0 ? void 0 : _e.width) || 0) * 100) / 100, onChange: e => {
                            const newTool = cloneDeep(toolData);
                            if (checkNested(newTool, "logo", "srcDimensions", "width") &&
                                checkNested(newTool, "logo", "srcDimensions", "height")) {
                                newTool.logo.srcDimensions.width = e.target.value;
                                newTool.logo.srcDimensions.height = e.target.value * (isDesktop ? 16 / 9 : 9 / 16);
                                updateTool(newTool);
                            }
                        }, style: inputStyle }))))));
}
