import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { DCIcon } from "../../components/dc-icon";
import { DCIconType, DCThemeType } from "../../../../../../../shared/types/demo-center";
import TTooltip from "../../../../styled/t-tooltip";
export const DCStylesThemeSelector = ({ onChange, themeType }) => {
    const [selectedTheme, setSelectedTheme] = useState(themeType);
    const icons = {
        [DCThemeType.CUSTOM]: DCIconType.SWATCHES,
        [DCThemeType.LIGHT]: DCIconType.SUN,
        [DCThemeType.DARK]: DCIconType.MOON,
    };
    const handleClick = (t) => {
        setSelectedTheme(t);
        onChange(t);
    };
    useEffect(() => {
        setSelectedTheme(themeType);
    }, [themeType]);
    return (React.createElement(ButtonContainer, null, [DCThemeType.CUSTOM, DCThemeType.DARK, DCThemeType.LIGHT].map(t => (React.createElement(TTooltip, { key: t, content: t, placement: "bottom", className: "tooltip" },
        React.createElement(Button, { className: selectedTheme === t ? "selected" : "", onClick: () => handleClick(t) },
            React.createElement(DCIcon, { type: icons[t] })))))));
};
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  .tooltip:first-child {
    button {
      border-radius: 4px 0 0 4px;
    }
  }
  .tooltip:last-child {
    button {
      border-radius: 0 4px 4px 0;
      border-right-width: 1px;
    }
  }
`;
const Button = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${newTheme.colors.grey500};
  background-color: ${newTheme.colors.grey100};
  border: 1px solid ${newTheme.colors.grey600};
  border-right-width: 0;
  width: 32px;
  height: 32px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.grey200};
    color: ${newTheme.colors.grey900};
  }
  &.selected {
    background-color: ${newTheme.colors.white};
    color: ${newTheme.colors.black};
  }
  svg {
  }
`;
