import React from "react";
import styled from "styled-components";
import { DCFileUploader } from "../dc-file-uploader";
import { LogoContainer, RemoveImage, ReplaceLogoContainer, } from "../../../../tools/modal/editor/components/logo-upload-section";
import DeleteIcon from "../../../../../../assets/images/v3/red-trashcan.svg";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import { newTheme } from "../../../../styled/theme";
import { DCStylesDropdown } from "./dc-styles-dropdown";
import { DCIconType } from "../../../../../../../shared/types/demo-center";
export const DCStylesLogoEditor = ({ logo, onChange }) => {
    return (React.createElement(DCStylesDropdown, { label: "Logo", icon: DCIconType.IMAGE },
        React.createElement(DropdownContainer, null,
            React.createElement(DCFileUploader, { showSpinner: true, onUploadComplete: onChange }, logo ? (React.createElement(LogoContainer, null,
                React.createElement("img", { src: logo, alt: "logo" }),
                React.createElement(ReplaceLogoContainer, null,
                    React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY }, "Replace Logo")))) : (React.createElement(TButton, { size: "small", variant: TButtonVariants.SECONDARY }, "Add Logo"))),
            React.createElement(Recommended, null, "Recommended size: 64 x 64px"),
            logo && (React.createElement(RemoveImage, { onClick: () => onChange("") },
                React.createElement("img", { src: DeleteIcon }),
                "Remove Image")))));
};
const DropdownContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 250px;
`;
const Recommended = styled.span `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
`;
