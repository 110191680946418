var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Rnd } from "react-rnd";
import { useState } from "react";
import { ViewportMode } from "../../types/definitions";
import { useEditingTextToolId, useIsAnyToolDragging, useIsGroupHidden, useIsToolHidden, useIsToolHoveredOrSelected, useIsToolMultiselected, useAddMultiselectToolId, useRemoveMultiselectToolId, useUpdateTool, useUpdateToolSizeAndPosition, useIsActiveViewSpotlightSelected, } from "../../redux/selectors/redux-selectors";
import { ResizeHandle } from "./builder/resize-handle";
import { getNewTooltipPosition } from "../../helpers/misc";
import { ToolType } from "../../../../shared/types/tool-data-types/common";
export const withToolWrapper = (ToolView, disableTriggerTracking) => (_a) => {
    var props = __rest(_a, []);
    const { viewportMode, toolOnClick, toolOnMouseEnter, toolOnMouseLeave, toolOnHover, builderStyle, updateBuilder, setEditDetailsId, toolData, } = props;
    const updateToolSizeAndPosition = useUpdateToolSizeAndPosition(toolData.id);
    const updateTool = useUpdateTool();
    const isToolEditingText = useEditingTextToolId(toolData.id);
    const isAnyToolDragging = useIsAnyToolDragging();
    const [isDragging, setIsDragging] = useState(false);
    const [isArrowDragging, setIsArrowDragging] = useState(false);
    const isHidden = useIsToolHidden(toolData.id);
    const isWithinHiddenGroup = useIsGroupHidden(toolData.groupToolId);
    const isToolHoveredOrSelected = useIsToolHoveredOrSelected(toolData.id);
    const addMultiselectToolId = useAddMultiselectToolId();
    const removeMultiselectToolId = useRemoveMultiselectToolId();
    const isToolMultiselected = useIsToolMultiselected(toolData.id);
    const isToolMultiselectable = !(toolData.groupToolId || toolData.id === "screen");
    let isLocked, isActiveViewSpotlightSelected;
    if (props.toolData.type === ToolType.Screen) {
        isLocked = props.toolData.isLocked;
        isActiveViewSpotlightSelected = useIsActiveViewSpotlightSelected();
    }
    const { sizeAndPosition } = toolData;
    const { x, y, width, height } = sizeAndPosition;
    const { width: viewportWidth, height: viewportHeight } = props.viewportDimensions;
    const isEditing = viewportMode === ViewportMode.EDIT;
    const onTourialDrag = (draggableData) => {
        const { x, y } = draggableData;
        updateToolSizeAndPosition(Object.assign(Object.assign({}, toolData.sizeAndPosition), { x: (x / viewportWidth) * 100, y: (y / viewportHeight) * 100 }), {
            viewportDimensions: props.viewportDimensions,
            draggableData,
        });
    };
    function shouldEnableResizing(isEditing, isWithinHiddenGroup, toolType, groupToolId, isToolMultiselected, isLocked) {
        if (!isEditing)
            return false;
        if (toolType === ToolType.Group || toolType === ToolType.FormV2)
            return false;
        if (isToolMultiselected)
            return false;
        if (isLocked)
            return false;
        if (groupToolId)
            return isWithinHiddenGroup;
        return true;
    }
    if (isHidden)
        return null;
    return (React.createElement(Rnd, { className: `rnd-tool-${toolData.id}`, resizeHandleComponent: {
            top: React.createElement(ResizeHandle, { dir: "top" }),
            bottom: React.createElement(ResizeHandle, { dir: "bottom" }),
            left: React.createElement(ResizeHandle, { dir: "left" }),
            right: React.createElement(ResizeHandle, { dir: "right" }),
        }, resizeHandleStyles: isEditing && toolData.type !== ToolType.Group && toolData.type !== ToolType.FormV2 && isToolHoveredOrSelected
            ? {}
            : {
                top: { display: "none" },
                bottom: { display: "none" },
                left: { display: "none" },
                right: { display: "none" },
            }, style: Object.assign(Object.assign(Object.assign({}, builderStyle), { overflow: "visible" }), (isLocked && !isActiveViewSpotlightSelected && isEditing && { pointerEvents: "none" })), bounds: "parent", size: { width: `${width}%`, height: `${height}%` }, position: {
            x: (x * viewportWidth) / 100,
            y: (y * viewportHeight) / 100,
        }, disableDragging: !isEditing ||
            isToolEditingText ||
            isArrowDragging ||
            isToolMultiselected ||
            isLocked ||
            toolData.type === ToolType.FormV2, lockAspectRatio: toolData.type === ToolType.Hotspot || toolData.type === ToolType.Image || toolData.type === ToolType.Video, enableResizing: shouldEnableResizing(isEditing, isWithinHiddenGroup, toolData.type, toolData.groupToolId, isToolMultiselected, isLocked), 
        // onDragStop sets new tool position for all tools
        onDragStop: (e, draggableData) => {
            e.stopPropagation();
            e.preventDefault();
            onTourialDrag(draggableData);
            setIsDragging(false);
            updateBuilder({ isAnyToolDragging: false });
        }, 
        // onDrag needs to track every drag movement for groups only
        // onDrag={(e, draggableData) => {
        //   e.stopPropagation();
        //   e.preventDefault();
        //   if (toolData.type !== ToolType.Group) return;
        //   onTourialDrag(draggableData, false);
        // }}
        onResizeStart: () => {
            if (toolData.type === ToolType.Group)
                return;
            updateBuilder({
                hoveredToolId: toolData.id,
                editDetailsId: toolData.id,
            });
        }, onResize: (_e, dir, _refToElement, delta, position) => {
            if (toolData.type === ToolType.Tooltip && toolData.enableArrow) {
                updateBuilder({
                    resizeDetails: {
                        resizeToolId: toolData.id,
                        delta,
                        dir,
                        position,
                    },
                });
            }
        }, onResizeStop: (e, _direction, ref, resizableDelta, position) => {
            if (toolData.type === ToolType.Group)
                return;
            e.stopPropagation();
            e.preventDefault();
            const parentWidth = ref.parentElement.clientWidth;
            const parentHeight = ref.parentElement.clientHeight;
            if (toolData.type === ToolType.Tooltip && toolData.enableArrow) {
                const newTooltipPosition = getNewTooltipPosition(toolData, position, viewportWidth, viewportHeight);
                if (newTooltipPosition) {
                    updateTool(Object.assign(Object.assign({}, toolData), { tooltipPosition: newTooltipPosition }));
                }
            }
            const newSizeAndPosition = {
                x: (position.x / viewportWidth) * 100,
                y: (position.y / viewportHeight) * 100,
                width: (ref.offsetWidth / parentWidth) * 100,
                height: (ref.offsetHeight / parentHeight) * 100,
            };
            updateToolSizeAndPosition(newSizeAndPosition, {
                viewportDimensions: props.viewportDimensions,
                resizableDelta,
            });
            updateBuilder({ isAnyToolDragging: false, resizeDetails: null });
        }, onDragStart: e => {
            updateBuilder({ editingTextToolId: "" });
            e.stopPropagation();
            e.preventDefault();
            // ignore drag start if shift used for multiselect
            const isMultiselectAction = e.shiftKey;
            if (isEditing && isMultiselectAction && isToolMultiselectable)
                return;
            setIsDragging(true);
            updateBuilder({
                isAnyToolDragging: true,
                hoveredToolId: toolData.id,
                editDetailsId: toolData.id,
            });
        }, onMouseEnter: () => {
            if (isAnyToolDragging)
                return;
            if (isEditing) {
                updateBuilder({ hoveredToolId: toolData.id });
                if (isDragging)
                    return;
            }
            else if (!disableTriggerTracking) {
                toolOnMouseEnter(toolData.id);
                toolOnHover("HOVER_IN");
            }
        }, onMouseLeave: () => {
            if (isDragging || isAnyToolDragging)
                return;
            if (isEditing) {
                updateBuilder({ hoveredToolId: "" });
            }
            else if (!disableTriggerTracking) {
                toolOnMouseLeave(toolData.id);
                toolOnHover("HOVER_OUT");
            }
        } },
        React.createElement("div", { onClick: e => {
                if (toolData.type === ToolType.FormV2 && isEditing) {
                    const lowestElement = document.elementFromPoint(e.clientX, e.clientY);
                    if (lowestElement.id === toolData.id || lowestElement.id === toolData.id + "tabs") {
                        setEditDetailsId("background");
                    }
                }
                const isMultiselectAction = e.shiftKey;
                if (isEditing && isMultiselectAction && isToolMultiselectable) {
                    isToolMultiselected ? removeMultiselectToolId([toolData.id]) : addMultiselectToolId([toolData.id]);
                    setEditDetailsId("background");
                    return;
                }
                else if (isEditing && isToolMultiselected) {
                    // ignore regular clicks on multiselected tools
                    return;
                }
                else if (isEditing && (!isMultiselectAction || !isToolMultiselectable)) {
                    // regular clicks on non-multiselected tools and any click on non-multiselectable tools
                    // should clear out multiselectToolIds and resume onClick logic
                    removeMultiselectToolId();
                }
                // Note(Dar): Disable for screen/spotlight in wrapper, because screen has nested spotlights
                // The onClicks for those tools are handled within the view of each tool, respectively
                if (!isWithinHiddenGroup) {
                    updateBuilder({ hiddenGroupId: "" });
                }
                if (toolData.type !== ToolType.Screen &&
                    toolData.type !== ToolType.Spotlight &&
                    toolData.type !== ToolType.FormV2 &&
                    isEditing) {
                    setEditDetailsId(toolData.id);
                }
                else {
                    toolOnClick(toolData.id);
                }
            }, onDoubleClick: () => {
                if (toolData.type === ToolType.Group) {
                    updateBuilder({ hiddenGroupId: toolData.id });
                }
            } }, toolData.type === ToolType.Tooltip && toolData.enableArrow ? (React.createElement(ToolView, Object.assign({}, props, { setIsArrowDragging: setIsArrowDragging }))) : (React.createElement(ToolView, Object.assign({}, props))))));
};
