import React from "react";
import styled from "styled-components";
import { newTheme } from "../../styled/theme";
import { TourialStatus } from "../../../../../shared/types/tourial-model";
import { TourialCard } from "./tourial-card";
import { TourialCardV2 } from "./tourial-card-v2";
import { useAsync } from "react-async";
import { getCurrentUser } from "../../../helpers/misc";
export const TOURIAL_LIST_CARD_GAP = 16;
const sortByDate = (a, b, sortDescending) => {
    if (sortDescending) {
        return new Date(a.updatedAt || a.createdAt).getTime() > new Date(b.updatedAt || b.createdAt).getTime() ? -1 : 1;
    }
    else {
        return new Date(a.updatedAt || a.createdAt).getTime() < new Date(b.updatedAt || b.createdAt).getTime() ? -1 : 1;
    }
};
export function TourialList({ tourialListItems: tourialList, reload, isV3, sortDescending, filterBy, showArchived, isListView, }) {
    const { data: baseUrlWithSubdomain } = useAsync({ promiseFn: getCurrentUser });
    return (React.createElement(TourialCardsContainer, { isV3AndListView: isV3 && isListView, style: Object.assign({}, (isV3
            ? {
                width: "100%",
                justifyContent: "left",
                gap: `${isListView ? 0 : TOURIAL_LIST_CARD_GAP}px`,
                padding: "18px 0px",
            }
            : {})) }, tourialList
        .filter(t => (isV3 ? t.version === 3 : t.version !== 3))
        .filter(t => {
        if (filterBy === TourialStatus.LIVE)
            return t.isPublished;
        if (filterBy === TourialStatus.DRAFT)
            return !t.isPublished && !t.interactedAt;
        if (filterBy === TourialStatus.UNPUBLISHED)
            return !t.isPublished && t.interactedAt;
        return true;
    })
        .filter(t => {
        if (!showArchived)
            return !t.archivedAt;
        return true;
    })
        .sort((a, b) => sortByDate(a, b, sortDescending))
        .map(t => isV3 && isListView ? (React.createElement(TourialCardV2, { key: t.tourId, tourialData: t, reload: reload })) : (React.createElement(TourialCard, { key: t.tourId, tourialData: t, reload: reload, baseUrlWithSubdomain: baseUrlWithSubdomain })))));
}
const TourialCardsContainer = styled.div `
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  flex-grow: 1;
  ${({ isV3AndListView }) => isV3AndListView
    ? `
        div.tourial-card-v2:first-of-type {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        div.tourial-card-v2:last-of-type {
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          border-bottom: 1px solid ${newTheme.colors.grey200};
        }
        `
    : ""}
`;
export const DropdownContainer = styled.div `
  width: 157px;
  background: ${newTheme.colors.white};
  box-shadow: ${newTheme.shadows.outside80};
  border-radius: 5px;
  overflow: hidden;
`;
export const DropdownOption = styled.p `
  font: ${newTheme.fonts.medium};
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${newTheme.colors.grey200};
  }
`;
