import React from "react";
import styled from "styled-components";
import { useHTGotoNextStep, useHTMode, useHTStep } from "../../../../../redux/selectors/ht-selectors";
import { BorderShape } from "../../../../../../../shared/types/tool-data-types/common";
import { HTMode } from "../../../../../../../shared/types/dom-tour";
import { clickzoneAnimationBuilder } from "../../../../../helpers/dom-helpers";
import { hexToRGB } from "../../../../../helpers/misc";
import { useHTHandleClickEvent } from "../../hooks/ht-hooks";
export default function HTClickzone({ size }) {
    const step = useHTStep();
    const mode = useHTMode();
    const gotoNextStep = useHTGotoNextStep();
    const htHandleClickEvent = useHTHandleClickEvent();
    const { shape, color, isBorderEnabled, isPulseEnabled, isSpotlightEnabled, spotlightOpacity } = step.tooltip.clickzone;
    const isEditMode = mode === HTMode.EDIT;
    return (React.createElement("div", { onClick: handleClick, style: {
            width: size.width,
            height: size.height,
            boxShadow: isSpotlightEnabled ? `0px 0px 0px 10000px rgba(0, 0, 0, ${spotlightOpacity})` : "none",
            borderRadius: borderRadii[shape],
            borderWidth: isEditMode || isBorderEnabled ? "2px" : "0px",
            borderStyle: isEditMode && !isBorderEnabled ? "dashed" : isBorderEnabled ? "solid" : "none",
            borderColor: color,
            cursor: "inherit",
        } },
        React.createElement(InnerDiv, { style: {
                borderRadius: borderRadii[shape],
                borderColor: color,
            }, animation: isEditMode || !isPulseEnabled ? "null" : clickzoneAnimationBuilder(hexToRGB(color) || { r: 0, g: 0, b: 0 }) })));
    function handleClick() {
        if (mode === HTMode.EDIT)
            return;
        htHandleClickEvent({ clickAction: "NEXT_STEP" });
        gotoNextStep();
    }
}
const InnerDiv = styled.div `
  cursor: inherit;
  animation: ${({ animation }) => animation}};
  width: 100%;
  height 100%;
  pointer-events: none;
`;
const borderRadii = {
    [BorderShape.RECTANGLE]: "2px",
    [BorderShape.ROUNDED]: "8px",
    [BorderShape.SQUIRCLE]: "50px",
};
