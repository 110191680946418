import React, { useContext } from "react";
import styled from "styled-components";
import { EDIT_PANEL_WIDTH } from "../../config";
import { newTheme } from "../../../../styled/theme";
import { NAVBAR_HEIGHT } from "../../../builder/builder-page";
import { EditorTabs } from "../../../builder/editor/editor-tabs";
import { StepEditor } from "./step-editor";
import { StyleEditor } from "./style-editor";
import { Header, Icon } from "../shared";
import RedTrashIcon from "../../../../../../assets/images/v3/red-trashcan.svg";
import { StepNameInput } from "../../../../tools/screen/step-name-input";
import { cloneDeep } from "lodash";
import { DraftContext } from "../dom-tour-builder-page";
import { useHTIsEditingHtml, useHTStep, useHTStepIndex, useHTSteps } from "../../../../../redux/selectors/ht-selectors";
import { useHTDeleteStep } from "../../hooks/ht-hooks";
import { HTHtmlEditor } from "./ht-html-editor";
import { DTStepType } from "../../../../../../../shared/types/dom-tour";
export function EditPanel() {
    const draft = useContext(DraftContext);
    const steps = useHTSteps();
    const stepIndex = useHTStepIndex();
    const deleteStep = useHTDeleteStep();
    const step = useHTStep();
    const isEditing = useHTIsEditingHtml();
    const stepTabTitle = {
        [DTStepType.TOOLTIP]: "Tooltip",
        [DTStepType.INTRO]: "Intro overlay",
        [DTStepType.OUTRO]: "Outro overlay",
    };
    const tabs = [
        { title: stepTabTitle[step.type], contents: React.createElement(StepEditor, null), disabled: isEditing },
        ...(step.type === DTStepType.TOOLTIP ? [{ title: "HTML", contents: React.createElement(HTHtmlEditor, null) }] : []),
        { title: "Tour styles", contents: React.createElement(StyleEditor, null), disabled: isEditing },
    ];
    return (React.createElement(Root, null,
        React.createElement(Header, { style: { borderBottom: `1px solid ${newTheme.colors.grey200}` } },
            React.createElement(StepNameInput, { name: step.name, setName: setName }),
            React.createElement(Icon, { draggable: "false", src: RedTrashIcon, onClick: () => deleteStep(draft) })),
        React.createElement(EditorTabs, { key: step.id, tabs: tabs })));
    function setName(newName) {
        const stepsCopy = cloneDeep(steps);
        stepsCopy[stepIndex].name = newName;
        void draft.update({ steps: stepsCopy });
    }
}
const Root = styled.div `
  position: absolute;
  right: 0;
  top: ${NAVBAR_HEIGHT}px;
  width: ${EDIT_PANEL_WIDTH}px;
  height: 100%;
  background-color: ${newTheme.colors.white};
  border-left: 1px solid ${newTheme.colors.grey200};
`;
