import { useDispatch } from "react-redux";
import { ActionType } from "../actions/redux-actions";
import { useActivePageId, useActiveZoomId, useIsFullyAutomated, usePages, useTourialLoop } from "./redux-selectors";
import { InteractionName } from "../../../../shared/types/tool-data-types/interactions";
import { getInteractionNearestTriggerListener } from "../../helpers/interactions";
import { getAllStepsAsActiveViews, getNextScreen, getNextStep, getPreviousScreen, getPreviousStep, } from "../../helpers/tourial-helpers/tourial-viewport-helpers";
import { makeSubtoolId, TooltipSubtools } from "../../components/tools/tooltip-v2/view/tooltip-v2-view";
import { usePostEvent } from "../../helpers/events";
import { useSetPageForm } from "./product-page-selectors";
import { ensureHttpsInUrl, openUrlInNewTab } from "../../../../shared/functions/helpers";
export const useInteraction = (toolProps, interaction, forceNullInteraction = false) => {
    const postEvent = usePostEvent();
    const pages = usePages();
    const pageId = useActivePageId();
    const zoomId = useActiveZoomId();
    const setPageForm = useSetPageForm();
    const dispatch = useDispatch();
    const { disableEventTracking, disableInteractions } = toolProps;
    const { id: toolId, type: toolType } = toolProps.toolData;
    const eventType = interaction.type;
    return (direction) => {
        if (forceNullInteraction || interaction.name === InteractionName.NULL)
            return () => { };
        if (!disableEventTracking && !disableInteractions) {
            const e = {
                eventType: direction || eventType,
                toolId,
                toolType,
                triggerListenerInteraction: Object.assign({ triggerListener: getInteractionNearestTriggerListener(toolId, interaction) }, interaction.target),
            };
            void postEvent(e);
        }
        if (!disableInteractions) {
            switch (interaction.name) {
                case InteractionName.NEXT_STEP:
                    return dispatch({ type: ActionType.CHANGE_STEP, nextStep: getNextStep(pages, { pageId, zoomId }) });
                case InteractionName.FIRST_STEP:
                    return dispatch({ type: ActionType.CHANGE_STEP, nextStep: { pageId: pages[0].id, zoomId: "MAIN" } });
                case InteractionName.NEXT_PAGE:
                    return dispatch({ type: ActionType.CHANGE_STEP, nextStep: getNextScreen(pages, { pageId, zoomId }) });
                case InteractionName.PREVIOUS_STEP:
                    return dispatch({ type: ActionType.CHANGE_STEP, nextStep: getPreviousStep(pages, { pageId, zoomId }) });
                case InteractionName.PREVIOUS_PAGE:
                    return dispatch({ type: ActionType.CHANGE_STEP, nextStep: getPreviousScreen(pages, { pageId, zoomId }) });
                case InteractionName.JUMP_TO_STEP:
                    return dispatch({ type: ActionType.CHANGE_STEP, nextStep: interaction.target.targetView });
                case InteractionName.JUMP_TO_PAGE:
                    return dispatch({ type: ActionType.CHANGE_STEP, nextStep: interaction.target.targetView });
                case InteractionName.SHOW_TOOLTIP_BUBBLE_AND_SPOTLIGHT:
                    return dispatch({
                        type: ActionType.SHOW_TOOLS,
                        hiddenToolIds: [
                            makeSubtoolId(toolId, TooltipSubtools.BUBBLE),
                            makeSubtoolId(toolId, TooltipSubtools.SPOTLIGHT),
                        ],
                    });
                case InteractionName.HIDE_TOOLTIP_BUBBLE_AND_SPOTLIGHT:
                    return dispatch({
                        type: ActionType.HIDE_TOOLS,
                        hiddenToolIds: [
                            makeSubtoolId(toolId, TooltipSubtools.BUBBLE),
                            makeSubtoolId(toolId, TooltipSubtools.SPOTLIGHT),
                        ],
                    });
                case InteractionName.TOGGLE_TOOLTIP_BUBBLE_AND_SPOTLIGHT:
                    return dispatch({
                        type: ActionType.TOGGLE_HIDDEN_TOOLS,
                        hiddenToolIds: [
                            makeSubtoolId(toolId, TooltipSubtools.BUBBLE),
                            makeSubtoolId(toolId, TooltipSubtools.SPOTLIGHT),
                        ],
                    });
                case InteractionName.EXTERNAL_LINK:
                    return (() => {
                        openUrlInNewTab(ensureHttpsInUrl(interaction.target.targetExternalLink));
                    })();
                case InteractionName.SUBMIT_FORM:
                    // SUBMIT_FORM is handled in the view itself, but we use this selector for event tracking
                    return () => { };
                case InteractionName.CLOSE_FORM:
                    // CLOSE_FORM is handled in the view itself, but we use this selector for event tracking
                    return () => { };
                case InteractionName.OPEN_FORM:
                    // OPEN_FORM is handled in the view itself, but we use this selector for event tracking
                    return () => { };
                case InteractionName.TRIGGER_FORM:
                    return (() => {
                        setPageForm({ formId: interaction.target.targetFormId, isEnabled: true, isGated: false });
                    })();
                case InteractionName.CLOSE_MODAL:
                    return dispatch({
                        type: ActionType.HIDE_TOOLS,
                        hiddenToolIds: [toolId],
                    });
                default:
                    throw new Error(`attempt to trigger unimplemented interaction: ${interaction.name}`);
            }
        }
    };
};
export const useRestartTour = () => {
    const dispatch = useDispatch();
    const pages = usePages();
    const steps = getAllStepsAsActiveViews(pages);
    const firstStepView = { pageId: steps[0].pageId, zoomId: steps[0].zoomId };
    return () => {
        dispatch({ type: ActionType.CHANGE_STEP, nextStep: firstStepView });
    };
};
export const useAutoJump = () => {
    const dispatch = useDispatch();
    const pages = usePages();
    const pageId = useActivePageId();
    const zoomId = useActiveZoomId();
    const loop = useTourialLoop();
    const isFullyAutomated = useIsFullyAutomated();
    return () => {
        dispatch({
            type: ActionType.CHANGE_STEP,
            nextStep: getNextStep(pages, { pageId, zoomId }, loop && isFullyAutomated),
        });
    };
};
