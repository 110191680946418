import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { SplitText } from "gsap/SplitText";
import { getPositionInPx } from "../components/tools/auto-mouse/view/auto-mouse-view";
import { ToolType } from "../../../shared/types/tool-data-types/common";
import { useActiveZoom, useIsAutomatedScreen, useTools, useTourialMediaDurationAndType, useTourialPerPageVideoControls, useViewToolsAndToolsInActiveView, } from "../redux/selectors/redux-selectors";
import { useAutoJump } from "../redux/selectors/interaction-selectors";
import { TooltipArrowPosition } from "../../../shared/types/tool-data-types/tooltip-v2-data";
import { useScreenDidLoad } from "../redux/selectors/builder-selectors";
gsap.registerPlugin(TextPlugin, SplitText);
var ListType;
(function (ListType) {
    ListType["UL"] = "ul";
    ListType["OL"] = "ol";
})(ListType || (ListType = {}));
const appendSpanMarker = (i, listItemElement, type) => {
    const spanMarker = document.createElement("span");
    spanMarker.innerHTML = type === ListType.OL ? `${i + 1}. ` : "&#x2022; ";
    spanMarker.style.position = "static";
    spanMarker.style.textAlign = "right";
    spanMarker.style.whiteSpace = "nowrap";
    spanMarker.style.display = "inline-flex";
    spanMarker.style.justifyContent = "right";
    spanMarker.style.paddingRight = ".6em";
    listItemElement.insertAdjacentElement("beforebegin", spanMarker);
};
const checkForListsAndAppendMarkers = (element, type) => {
    const listElements = element.getElementsByTagName(type);
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < listElements.length; i++) {
        const listElement = listElements[i];
        const listItems = listElement.getElementsByTagName("li");
        for (let j = 0; j < listItems.length; j++) {
            appendSpanMarker(j, listItems[j], type);
        }
    }
};
const initializeTextTimeline = ({ element, duration, clearOnComplete, }) => {
    const textTimeline = gsap.timeline();
    checkForListsAndAppendMarkers(element, ListType.OL);
    checkForListsAndAppendMarkers(element, ListType.UL);
    new SplitText(element, {
        type: "chars, words",
        charsClass: "char",
        wordsClass: "word",
    });
    element.innerHTML = element.innerHTML.replace(/(<\/div> <div)/gi, `</div><div class="char" style="position: relative; display: inline-block">&nbsp;</div><div`);
    const newChars = element.getElementsByClassName("char");
    const stagger = duration / newChars.length;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < newChars.length; i++) {
        textTimeline.fromTo(newChars[i], { visibility: "hidden" }, {
            visibility: "visible",
            duration: stagger,
            ease: "power2.easeInOut",
        });
    }
    if (clearOnComplete) {
        textTimeline.to(element, { display: "none", duration: 0, ease: "power2.easeInOut" });
    }
    return textTimeline;
};
const initializeMouseTimeline = ({ element, duration, delay, start, end, hasClickAnimation, masterTimeline, }) => {
    const mouseTimeline = masterTimeline !== null && masterTimeline !== void 0 ? masterTimeline : gsap.timeline();
    const { x: x1, y: y1 } = start;
    const { x: x2, y: y2 } = end;
    if (hasClickAnimation) {
        const clickDuration = 0.2;
        mouseTimeline
            .to(element, { opacity: 1 }, delay)
            .to(element, { x: x2 - x1, y: y2 - y1, duration: duration - clickDuration }, delay)
            .to(element, {
            scale: 0.7,
            ease: "power2.easeInOut",
            repeat: 1,
            yoyo: true,
            transformOrigin: "center center",
            duration: clickDuration,
        }, delay + duration - clickDuration)
            .to(element, { opacity: 0 }, delay + duration);
    }
    else {
        mouseTimeline
            .to(element, { opacity: 1 }, delay)
            .to(element, { x: x2 - x1, y: y2 - y1, duration: duration }, delay)
            .to(element, { opacity: 0 }, delay + duration);
    }
    return mouseTimeline;
};
const useTimeline = ({ baseViewProps }) => {
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [progressTime, setProgressTime] = useState(0);
    const toolIds = useViewToolsAndToolsInActiveView().map(vtat => vtat.tool.id);
    const screenDidLoad = useScreenDidLoad();
    const { duration } = useTourialMediaDurationAndType();
    const videoControls = useTourialPerPageVideoControls();
    const showOnlyGlobalButtons = !baseViewProps.isInEditViewport && !screenDidLoad;
    const timeline = useRef(gsap.timeline());
    const isAutomatedScreen = useIsAutomatedScreen();
    const activeZoom = useActiveZoom();
    const tools = useTools();
    const autoJump = useAutoJump();
    useEffect(() => {
        timeline.current.kill();
        if (!isAutomatedScreen || showOnlyGlobalButtons || baseViewProps.isInEditViewport)
            return;
        timeline.current = gsap.timeline();
        if (videoControls === null || videoControls === void 0 ? void 0 : videoControls.playbackSpeed)
            timeline.current.timeScale(videoControls === null || videoControls === void 0 ? void 0 : videoControls.playbackSpeed);
        toolIds.forEach(toolId => {
            switch (tools[toolId].type) {
                case ToolType.AutoTypewriter: {
                    const element = document.getElementById(toolId + "-auto-typewriter");
                    const tool = tools[toolId];
                    if (element) {
                        const textTimeline = initializeTextTimeline({
                            element,
                            duration: tool.duration,
                            clearOnComplete: tool.clearOnComplete,
                        });
                        timeline.current.add(textTimeline, tool.delay);
                    }
                    break;
                }
                case ToolType.AutoTooltip: {
                    const element = document.getElementById(toolId + "-auto-tooltip");
                    const tool = tools[toolId];
                    if (element) {
                        const direction = getAnimationDirection(tool.bubble.arrow);
                        timeline.current
                            .fromTo(element, Object.assign({}, (direction && { [direction]: "30px" })), Object.assign(Object.assign({}, (direction && { [direction]: "0px" })), { opacity: 1, duration: 0.5 }), tool.delay)
                            .to(element, { opacity: 0, duration: 0.5 }, tool.delay + tool.duration + 0.5);
                    }
                    break;
                }
                case ToolType.AutoMouse: {
                    const element = document.getElementById(toolId + "-auto-mouse-start");
                    const tool = tools[toolId];
                    if (element) {
                        const { duration, delay, hasClickAnimation } = tool;
                        const end = getPositionInPx(tool.positionEnd, baseViewProps.viewportDimensions);
                        const start = getPositionInPx(tool.positionStart, baseViewProps.viewportDimensions);
                        initializeMouseTimeline({
                            element,
                            start,
                            end,
                            duration,
                            delay,
                            hasClickAnimation,
                            masterTimeline: timeline.current,
                        });
                    }
                    break;
                }
            }
        });
        timeline.current.set({}, {}, duration); // create empty tween to ensure total timeline
        timeline.current.eventCallback("onComplete", autoJump);
        const updateProgress = () => {
            setProgressPercentage(timeline.current.progress());
            setProgressTime(timeline.current.time());
        };
        timeline.current.eventCallback("onUpdate", updateProgress);
        return () => {
            timeline.current.kill();
        };
    }, [showOnlyGlobalButtons, baseViewProps.isInEditViewport, activeZoom]);
    return {
        progress: n => {
            timeline.current.progress(n);
        },
        play: () => {
            timeline.current.play();
        },
        pause: () => {
            timeline.current.pause();
        },
        progressPercentage,
        progressTime,
    };
};
export const useSimpleTextTimeline = (id, clearOnComplete) => {
    const timeline = useRef(gsap.timeline());
    useEffect(() => {
        timeline.current.kill();
        timeline.current = gsap.timeline();
        const element = document.getElementById(id);
        if (element) {
            const textTimeline = initializeTextTimeline({
                element,
                duration: 3,
                clearOnComplete: clearOnComplete,
            });
            timeline.current.add(textTimeline);
            timeline.current.repeat(-1);
        }
        return () => {
            timeline.current.kill();
        };
    }, []);
};
export const useSimpleMouseTimeline = (id, start, end, hasClickAnimation) => {
    const timeline = useRef(gsap.timeline());
    useEffect(() => {
        timeline.current.kill();
        timeline.current = gsap.timeline();
        const element = document.getElementById(id);
        if (element) {
            timeline.current.to(element, { x: 0, y: 0, duration: 0 });
            const textTimeline = initializeMouseTimeline({
                element,
                start,
                end,
                duration: 3,
                delay: 1,
                hasClickAnimation,
            });
            timeline.current.add(textTimeline);
            timeline.current.repeat(-1);
        }
        return () => {
            timeline.current.kill();
        };
    }, [hasClickAnimation]);
};
const getAnimationDirection = (arrow) => {
    if (arrow.isEnabled) {
        switch (arrow.position) {
            case TooltipArrowPosition.BOTTOM_CENTER:
            case TooltipArrowPosition.BOTTOM_LEFT:
            case TooltipArrowPosition.BOTTOM_RIGHT:
                return "bottom";
            case TooltipArrowPosition.TOP_CENTER:
            case TooltipArrowPosition.TOP_LEFT:
            case TooltipArrowPosition.TOP_RIGHT:
                return "top";
            case TooltipArrowPosition.LEFT_BOTTOM:
            case TooltipArrowPosition.LEFT_CENTER:
            case TooltipArrowPosition.LEFT_TOP:
                return "left";
            case TooltipArrowPosition.RIGHT_BOTTOM:
            case TooltipArrowPosition.RIGHT_CENTER:
            case TooltipArrowPosition.RIGHT_TOP:
                return "right";
        }
    }
    else {
        return "";
    }
};
export default useTimeline;
