import React from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
export function NumberInput(props) {
    const { value, unit, disabled } = props;
    return (React.createElement(Container, null,
        React.createElement(Input, Object.assign({}, props, { value: value, type: "number", disabled: disabled })),
        unit && (React.createElement(Unit, { className: "unit" },
            React.createElement("div", { style: { visibility: "hidden" } }, value),
            React.createElement("span", null, unit)))));
}
const Container = styled.div `
  display: flex;
  height: 32px;
  margin: auto 0px;
  position: relative;
  &:focus-within {
    .unit {
      color: ${newTheme.colors.blue400};
    }
  }
`;
const Unit = styled.div `
  font: ${newTheme.fonts.normal};
  color: ${newTheme.colors.grey600};
  display: flex;
  position: absolute;
  padding: 4px 9px;
  pointer-events: none;
`;
const Input = styled.input `
  font: ${newTheme.fonts.normal};
  background-color: ${newTheme.colors.white};
  border-color: ${newTheme.colors.grey600};
  color: ${newTheme.colors.grey600};
  border-style: solid;
  border-width: 1px;
  width: 100%;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  &:focus {
    color: ${newTheme.colors.blue400};
    border-color: ${newTheme.colors.blue400};
    box-shadow: ${newTheme.shadows.textInputSelect};
  }
  cursor: ${p => (p.disabled ? "not-allowed" : "default")};
`;
