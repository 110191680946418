import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Popover } from "@blueprintjs/core";
import { newTheme } from "../../styled/theme";
import { getDateStringWithOrdinalNum } from "../../../../../shared/functions/helpers";
import VerticalDots from "../../../../assets/images/v3/vertical-dots.svg";
import BluePencilIcon from "../../../../assets/images/v3/blue-pencil.svg";
import BlueCopyIcon from "../../../../assets/images/v3/blue-copy.svg";
import MoveToWorkspaceIcon from "../../../../assets/images/v3/move-to-workspace.svg";
import BluePauseIcon from "../../../../assets/images/v3/blue-pause.svg";
import BluePublishIcon from "../../../../assets/images/v3/blue-publish.svg";
import BlueShareIcon from "../../../../assets/images/v3/share-icon-blue.svg";
import RedTrashcanIcon from "../../../../assets/images/v3/red-trashcan.svg";
import ExternalCloneIcon from "../../../../assets/images/v3/external-clone.svg";
import AnalyticsIcon from "../../../../assets/images/v3/analytics-outline.svg";
import ArchiveIcon from "../../../../assets/images/v3/archive-icon.svg";
import { useOutsideClick } from "../../../hooks/use-outside-click";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import DeleteModal from "./delete-modal";
import RenameModal from "./rename-modal";
import PauseModal from "./pause-modal";
import MoveTourialToWorkspace from "./workspace-move-to-modal";
import { useUserAccess, useFeatureFlags } from "../../../redux/selectors/redux-selectors";
import ExternalCloneModal from "./external-clone-modal";
import TStatusIndicator from "../../styled/t-status-indicator";
import ThumbnailViewport from "../../renderer/thumbnail/thumbnail-viewport";
import TourShareModal from "./tour-share-modal";
import { useAddToast } from "../../../legacy/selectors";
import { ToastModes } from "../../../types/definitions";
import { useHistory } from "react-router-dom";
import { isAuthorized } from "../../../../../shared/functions/auth-functions";
import { AccessLevel } from "../../../../../shared/types/auth-types";
import ArchiveModal from "./archive-modal";
import { TourialIcons } from "../../shared/tourial-icons";
import { DropdownContainer, DropdownOption } from "./tourial-list";
import { getTourialStatus } from "../../../helpers/tourial";
import DuplicateTourialToWorkspace from "./duplicate-modal";
import { DropdownOptions } from "./tourial-card";
import { MainAppPagePaths } from "../../../../../shared/types/app-events";
export function TourialCardV2({ tourialData, reload }) {
    const formattedDate = getDateStringWithOrdinalNum(`${tourialData.updatedAt || tourialData.createdAt}`);
    const { workspaces } = useFeatureFlags();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
    const [isMoveToWorkspaceModalOpen, setIsMoveToWorkspaceModalOpen] = useState(false);
    const [isDuplicateToWorkspaceModalOpen, setIsDuplicateToWorkspaceModalOpen] = useState(false);
    const [isExternalCloneModalOpen, setIsExternalCloneModalOpen] = useState(false);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const userAccess = useUserAccess();
    const history = useHistory();
    const addToast = useAddToast();
    const dropdownRef = useRef();
    useOutsideClick([dropdownRef], () => {
        if (isDropdownOpen && !isDeleteModalOpen && !isRenameModalOpen && !isPauseModalOpen && !isExternalCloneModalOpen)
            setIsDropdownOpen(false);
    });
    const dropdownOptionsMap = new Map();
    const isEditor = isAuthorized(AccessLevel.EDITOR, userAccess);
    if (isEditor && !tourialData.archivedAt) {
        dropdownOptionsMap.set(DropdownOptions.RENAME, {
            src: BluePencilIcon,
            label: "Rename",
            onClick: () => setIsRenameModalOpen(true),
        });
    }
    if (tourialData.isPublished) {
        dropdownOptionsMap.set(DropdownOptions.SHARE, {
            src: BlueShareIcon,
            label: "Share",
            onClick: () => setIsShareModalOpen(true),
        });
    }
    if (userAccess !== AccessLevel.COLLABORATOR && isEditor && !tourialData.archivedAt) {
        dropdownOptionsMap.set(DropdownOptions.PUBLISH_UNPUBLISH, {
            src: tourialData.isPublished ? BluePauseIcon : BluePublishIcon,
            label: tourialData.isPublished ? "Unpublish" : "Publish",
            onClick: () => {
                if (tourialData.isPublished) {
                    if (tourialData.isInMicrosite) {
                        addToast({ message: "Cannot unpublish a tour featured in a TourPlayer", mode: ToastModes.ERROR });
                        return;
                    }
                    setIsPauseModalOpen(true);
                }
                else {
                    TourialApiCalls.Tourials.postTourialPublish(tourialData.tourId, !tourialData.isPublished)
                        .then(() => {
                        reload();
                        setIsDropdownOpen(false);
                        addToast({ message: "Publish successful", mode: ToastModes.SUCCESS });
                    })
                        .catch(e => {
                        console.error(e);
                        addToast({ message: "Publish failed", mode: ToastModes.ERROR });
                    });
                }
            },
        });
    }
    if (tourialData.isPublished || tourialData.interactedAt || tourialData.archivedAt) {
        dropdownOptionsMap.set(DropdownOptions.ANALYTICS, {
            src: AnalyticsIcon,
            label: "Analytics",
            onClick: () => {
                history.push(`/analytics?tourId=${tourialData.tourId}`);
            },
        });
    }
    if (isEditor) {
        dropdownOptionsMap.set(DropdownOptions.DUPLICATE, {
            src: BlueCopyIcon,
            label: "Duplicate",
            onClick: () => {
                if (workspaces) {
                    setIsDuplicateToWorkspaceModalOpen(true);
                }
                else {
                    TourialApiCalls.Tourials.postTourialClone(tourialData.tourId)
                        .then(() => {
                        reload();
                        setIsDropdownOpen(false);
                        addToast({ message: "Duplicate successful", mode: ToastModes.SUCCESS });
                    })
                        .catch(e => {
                        console.error(e);
                        addToast({ message: "Duplicate failed", mode: ToastModes.ERROR });
                    });
                }
            },
        });
    }
    if (isEditor && workspaces) {
        dropdownOptionsMap.set(DropdownOptions.MOVE_TO_WORKSPACE, {
            src: MoveToWorkspaceIcon,
            label: "Move to Workspace",
            onClick: () => {
                setIsMoveToWorkspaceModalOpen(true);
            },
        });
    }
    if (isEditor && !tourialData.isPublished && tourialData.interactedAt && !tourialData.archivedAt) {
        dropdownOptionsMap.set(DropdownOptions.ARCHIVE, {
            src: ArchiveIcon,
            label: "Archive",
            onClick: () => {
                if (tourialData.isInMicrosite) {
                    addToast({ message: "Cannot archive a tour featured in a TourPlayer", mode: ToastModes.ERROR });
                    return;
                }
                setIsArchiveModalOpen(true);
            },
        });
    }
    if (isEditor && !tourialData.isPublished) {
        dropdownOptionsMap.set(DropdownOptions.DELETE, {
            src: RedTrashcanIcon,
            label: "Delete",
            style: { color: newTheme.colors.red500 },
            onClick: () => setIsDeleteModalOpen(true),
        });
    }
    if (isEditor && isAuthorized(AccessLevel.SUPERADMIN, userAccess)) {
        dropdownOptionsMap.set(DropdownOptions.EXTERNAL_CLONE, {
            src: ExternalCloneIcon,
            label: "External clone",
            style: {
                background: newTheme.colors.grey100,
                borderTop: `1px solid ${newTheme.colors.grey300}`,
            },
            onClick: () => setIsExternalCloneModalOpen(true),
        });
    }
    const dropdownOptions = [...dropdownOptionsMap.values()];
    const tourialStatus = getTourialStatus(tourialData);
    const handleCardClick = () => {
        history.push(`${MainAppPagePaths.V3_BUILDER}?tourId=${tourialData.tourId}`);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TourialCardContainer, { className: "tourial-card-v2 _test_tourial-card" },
            React.createElement(Thumbnail, { onClick: handleCardClick },
                React.createElement(ThumbnailViewport, { tourialData: tourialData, isLink: false, style: { margin: 0, transformOrigin: "top left", borderRadius: 0 }, scale: 0.4985 })),
            React.createElement(TitleAndDate, { onClick: handleCardClick },
                React.createElement("div", { style: { flexGrow: 1 } },
                    React.createElement(TitleSpan, { className: "text-ellipsis" }, tourialData.tourName)),
                React.createElement(DateSpan, null, tourialData.updatedAt ? `edited on ${formattedDate}` : `created on ${formattedDate}`)),
            React.createElement(StatusAndIntegrations, { onClick: handleCardClick },
                React.createElement(TourialIcons, { isInMicrosite: tourialData.isInMicrosite, hasMobileVariant: tourialData.hasMobileVariant, formIntegrations: tourialData.formIntegrations, isHomePage: true, isInDemoCenter: false }),
                React.createElement(TStatusIndicator, { status: tourialStatus })),
            React.createElement(DropdownMenuVerticalDots, null,
                React.createElement(Popover, { minimal: true, isOpen: isDropdownOpen, position: "bottom-right", popoverClassName: "tourial-card-dropdown", content: React.createElement(DropdownContainer, { className: "_test_tourial-card-dropdown", onClick: () => setIsDropdownOpen(!isDropdownOpen), style: { width: 180 } }, dropdownOptions.map(option => {
                        const { src, label, style, onClick } = option;
                        return (React.createElement(DropdownOption, { key: label, style: style, onClick: onClick },
                            React.createElement("img", { src: src, style: { margin: "8px 8px 8px 16px", display: "inline" } }),
                            " ",
                            label));
                    })) },
                    React.createElement("img", { style: { minWidth: 24, height: 24, cursor: "pointer" }, src: VerticalDots })),
                React.createElement(DropdownClickzone, { className: "_test_vertical-dots", onClick: () => setIsDropdownOpen(!isDropdownOpen), ref: dropdownRef }))),
        isDeleteModalOpen && (React.createElement(DeleteModal, { setIsDeleteModalOpen: setIsDeleteModalOpen, onConfirmation: () => {
                TourialApiCalls.Tourials.deleteTourial(tourialData.tourId)
                    .then(() => {
                    setIsDeleteModalOpen(false);
                    setIsDropdownOpen(false);
                    addToast({ message: "Delete successful", mode: ToastModes.SUCCESS });
                    reload();
                })
                    .catch(e => {
                    console.error(e);
                    addToast({ message: "Delete failed", mode: ToastModes.ERROR });
                });
            } })),
        isRenameModalOpen && (React.createElement(RenameModal, { reload: reload, setIsRenameModalOpen: setIsRenameModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData, addToast: addToast })),
        isShareModalOpen && (React.createElement(TourShareModal, { setIsShareModalOpen: setIsShareModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData })),
        isPauseModalOpen && (React.createElement(PauseModal, { reload: reload, setIsPauseModalOpen: setIsPauseModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData, addToast: addToast })),
        isExternalCloneModalOpen && (React.createElement(ExternalCloneModal, { reload: reload, setIsExternalCloneModalOpen: setIsExternalCloneModalOpen, setIsDropdownOpen: setIsDropdownOpen, originalId: tourialData.tourId, addToast: addToast, cloneType: "tourial" })),
        isArchiveModalOpen && (React.createElement(ArchiveModal, { reload: reload, setIsArchiveModalOpen: setIsArchiveModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData, addToast: addToast })),
        isMoveToWorkspaceModalOpen && (React.createElement(MoveTourialToWorkspace, { reload: reload, setIsMoveToWorkspaceModalOpen: setIsMoveToWorkspaceModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData, addToast: addToast })),
        isDuplicateToWorkspaceModalOpen && (React.createElement(DuplicateTourialToWorkspace, { reload: reload, setIsDuplicateToWorkspaceModalOpen: setIsDuplicateToWorkspaceModalOpen, setIsDropdownOpen: setIsDropdownOpen, tourialData: tourialData, addToast: addToast }))));
}
export const TourialCardContainer = styled.div `
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid ${newTheme.colors.grey200};
  border-right: 1px solid ${newTheme.colors.grey200};
  border-left: 1px solid ${newTheme.colors.grey200};
  height: 76px;
  overflow: hidden;
  cursor: pointer;
`;
const Thumbnail = styled.div `
  width: 135.1px;
  height: 100%;
`;
export const TitleAndDate = styled.div `
  padding: 13px 10px 13px 16px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  width: calc(100% - 314px);
  flex: 1;
`;
export const StatusAndIntegrations = styled.div `
  display: flex;
  gap: 8px;
  height: 76px;
  align-items: center;
`;
export const DropdownMenuVerticalDots = styled.div `
  padding-right: 16px;
  padding-left: 10px;
  margin-top: 4px;
  position: relative;
  cursor: pointer;
`;
const TitleSpan = styled.span `
  display: block;
  font: ${newTheme.fonts.medium};
`;
const DateSpan = styled.span `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey700};
  height: 14px;
  display: block;
  margin: 4px 0 7px;
`;
export const DropdownClickzone = styled.div `
  height: 76px;
  width: 51px;
  position: absolute;
  top: -26px;
  left: 0px;
`;
