import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAsync } from "react-async";
import { TourialApiCalls } from "../../helpers/tourial-api-calls";
import styled from "styled-components";
import { TLoadingSpinner } from "../styled/t-loading-spinner";
import { debounce } from "lodash";
import { newTheme } from "../styled/theme";
import SearchIcon from "../../../assets/images/v3/search-black.svg";
import CloseIcon from "../../../assets/images/close-button.svg";
import { useOutsideClick } from "../../hooks/use-outside-click";
import { PAGE_HEADER_Z_INDEX } from "../../types/definitions";
import SearchCard from "./search-card";
export default function SearchBar() {
    const [searchTerm, setSearchTerm] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [results, setResults] = useState(null);
    const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
    const searchContainerRef = useRef(null);
    useOutsideClick([searchContainerRef], handleClose);
    const { error, isLoading, run } = useAsync({
        deferFn: ([searchTerm]) => TourialApiCalls.Tourials.getSearchedProjects({ searchTerm }),
        onResolve(data) {
            if (data) {
                const flatMap = [];
                for (const keys in data) {
                    flatMap.push(...data[keys]);
                }
                flatMap.sort((a, b) => a.name.localeCompare(b.name));
                setResults(flatMap);
            }
            else
                setResults([]);
        },
    });
    const debouncedSearch = useCallback(debounce(term => {
        setIsTyping(false);
        if (term)
            run(term); // do not trim since project names can have leading space
    }, 2000), []);
    const handleInputChange = e => {
        setSearchTerm(e.target.value);
        setIsTyping(true);
        debouncedSearch(e.target.value);
    };
    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);
    useEffect(() => {
        var _a;
        if (isSearchBarOpen) {
            (_a = document.getElementById("tourial-search-bar-input")) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isSearchBarOpen]);
    function SearchButton({ style }) {
        return (React.createElement(Img, { style: style, src: SearchIcon, isSearchBarOpen: isSearchBarOpen, onClick: () => {
                var _a, _b;
                if (isSearchBarOpen)
                    return;
                setIsSearchBarOpen(true);
                (_b = (_a = document.getElementById("tourial-page-content")) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.setProperty("z-index", `${PAGE_HEADER_Z_INDEX + 2}`);
            } }));
    }
    function handleClose() {
        var _a, _b;
        setResults(null);
        setIsSearchBarOpen(false);
        setSearchTerm("");
        (_b = (_a = document.getElementById("tourial-page-content")) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.removeProperty("z-index");
    }
    const hasNoResults = (results === null || results === void 0 ? void 0 : results.length) === 0;
    const isSearching = isLoading || isTyping;
    const searchTermIsTooShort = (searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.length) < 3 && !isTyping;
    const isNotResults = isSearching || error || searchTermIsTooShort || hasNoResults;
    function renderContent() {
        if (isSearching) {
            return React.createElement(TLoadingSpinner, null);
        }
        if (searchTermIsTooShort) {
            return React.createElement(React.Fragment, null, "Please enter at least 3 characters.");
        }
        if (error) {
            return React.createElement(React.Fragment, null, "Oops...something went wrong.");
        }
        if (hasNoResults) {
            return React.createElement(React.Fragment, null, "Oops...we can't find any projects containing this phrase.");
        }
        if (results === null || results === void 0 ? void 0 : results.length) {
            return (React.createElement(React.Fragment, null, results.map(md => {
                return React.createElement(SearchCard, { key: md._id, data: md });
            })));
        }
        return null;
    }
    return (React.createElement(React.Fragment, null, isSearchBarOpen ? (React.createElement(React.Fragment, null,
        React.createElement(Overlay, null),
        React.createElement(SearchBarContainer, { ref: searchContainerRef },
            React.createElement(SearchButton, { style: { margin: "17.5px 8px" } }),
            React.createElement(CloseButton, { src: CloseIcon, onClick: handleClose }),
            React.createElement(Input, { id: "tourial-search-bar-input", value: searchTerm, onChange: handleInputChange }),
            searchTerm && (React.createElement(DropdownContainer, { style: {
                    justifyContent: isNotResults ? "center" : "normal",
                }, hasScroll: !isNotResults && (results === null || results === void 0 ? void 0 : results.length) > 8 }, renderContent()))))) : (React.createElement(SearchButton, null))));
}
const SEARCH_BAR_WIDTH = 611;
const CloseButton = styled.img `
  width: 27px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  padding: 0px 8px;
  margin-top: 17.5px;
`;
const Img = styled.img `
  width: 22px;
  height: 22px;
  cursor: pointer;
  pointer-events: ${p => (p.isSearchBarOpen ? "none" : "auto")};
  position: ${p => (p.isSearchBarOpen ? "absolute" : "inherit")};
`;
const Overlay = styled.div `
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;
const Input = styled.input `
  width: 100%;
  border: 1px solid ${newTheme.colors.blue500};
  margin: 12.5px 0px 4px;
  background-color: ${newTheme.colors.grey100};
  height: 32px;
  border-radius: 4px;
  padding: 0px 32px;
`;
const SearchBarContainer = styled.div `
  min-width: ${SEARCH_BAR_WIDTH}px;
  width: ${SEARCH_BAR_WIDTH}px;
  height: 100%;
  z-index: 2;
  position: relative;
`;
const DropdownContainer = styled.div `
  ${p => p.hasScroll
    ? `
    ::-webkit-scrollbar {
      background-color: ${newTheme.colors.grey100};
      border-radius: 10px;
      width: 7px;
      height: 2px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${newTheme.colors.grey500};
      border-radius: 10px;
    }
    `
    : `
      overflow: hidden !important;
      `}
  box-shadow:
    0px 0px 20px 0px rgba(48, 49, 51, 0.05),
    2px 2px 8px 0px rgba(33, 34, 41, 0.02),
    0px 0px 1px 0px rgba(33, 34, 41, 0.4) inset;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid ${newTheme.colors.blue500};
  width: 100%;
  min-height: 67.5px;
  max-height: 600px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
