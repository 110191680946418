import React from "react";
import ArrowUp from "../../../../assets/images/box-arrow-up.svg";
import ArrowDown from "../../../../assets/images/box-arrow-down.svg";
import ArrowLeft from "../../../../assets/images/box-arrow-left.svg";
import ArrowRight from "../../../../assets/images/box-arrow-right.svg";
export function Cursors({ updateFn, toolId, xOffset, yOffset, cursorIntervalId, setCursorIntervalId, style, inverted, }) {
    function Cursor({ src, row, col, dir }) {
        function adjustZoomOffset() {
            if (inverted) {
                switch (dir) {
                    case "UP":
                        updateFn(toolId, { y: --yOffset });
                        break;
                    case "DOWN":
                        updateFn(toolId, { y: ++yOffset });
                        break;
                    case "LEFT":
                        updateFn(toolId, { x: --xOffset });
                        break;
                    case "RIGHT":
                        updateFn(toolId, { x: ++xOffset });
                        break;
                }
            }
            else {
                switch (dir) {
                    case "UP":
                        updateFn(toolId, { y: ++yOffset });
                        break;
                    case "DOWN":
                        updateFn(toolId, { y: --yOffset });
                        break;
                    case "LEFT":
                        updateFn(toolId, { x: ++xOffset });
                        break;
                    case "RIGHT":
                        updateFn(toolId, { x: --xOffset });
                        break;
                }
            }
        }
        return (React.createElement("img", { src: src, width: 24, draggable: false, style: { gridRow: row, gridColumn: col, cursor: "pointer" }, onMouseDown: () => {
                setCursorIntervalId(setInterval(adjustZoomOffset, 80));
            }, onMouseUp: () => {
                setCursorIntervalId(clearInterval(cursorIntervalId));
            }, onMouseLeave: () => {
                setCursorIntervalId(clearInterval(cursorIntervalId));
            }, onMouseOut: () => {
                setCursorIntervalId(clearInterval(cursorIntervalId));
            } }));
    }
    return (React.createElement("div", { style: Object.assign({ margin: "0 auto", display: "inline-grid", gridTemplateRows: "24px 24px 24px", gridTemplateColumns: "24px 24px 24px" }, style) },
        React.createElement(Cursor, { src: ArrowUp, col: 2, row: 1, dir: "UP" }),
        React.createElement(Cursor, { src: ArrowDown, col: 2, row: 3, dir: "DOWN" }),
        React.createElement(Cursor, { src: ArrowLeft, col: 1, row: 2, dir: "LEFT" }),
        React.createElement(Cursor, { src: ArrowRight, col: 3, row: 2, dir: "RIGHT" })));
}
