import React from "react";
import { ShirtSize, TextStyle } from "../../../../../../../shared/types/tool-data-types/common";
import { getHeadlineFontSize, textAlignments } from "../../../../../helpers/text-helpers";
export function BubbleHeadlineText({ headline, onDoubleClick, viewportDimensions }) {
    const { width } = viewportDimensions || {};
    const style = {
        fontFamily: headline.font,
        fontSize: getHeadlineFontSize(width, headline.size),
        textAlign: textAlignments[headline.align],
        textDecoration: headline.style === TextStyle.UNDERLINE ? "underline" : "none",
        fontStyle: headline.style === TextStyle.ITALIC ? "italic" : "normal",
        fontWeight: headline.style === TextStyle.BOLD ? "bold" : 400,
        color: headline.color,
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        overflowY: "hidden",
        lineHeight: `${getHeadlineFontSize(width, headline.size) * 1.5}px`,
    };
    return (React.createElement(H, { size: headline.size, style: style, onDoubleClick: onDoubleClick }, headline.text));
}
const H = (props) => {
    switch (props.size) {
        case ShirtSize.LARGE:
            return React.createElement("h1", Object.assign({}, props));
        case ShirtSize.MEDIUM:
            return React.createElement("h2", Object.assign({}, props));
        case ShirtSize.SMALL:
            return React.createElement("h3", Object.assign({}, props));
        case ShirtSize.XSMALL:
            return React.createElement("h4", Object.assign({}, props));
        case ShirtSize.XXSMALL:
            return React.createElement("h5", Object.assign({}, props));
    }
};
