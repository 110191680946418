import React, { useRef, useEffect, useState } from "react";
import { ViewportMode } from "../../../types/definitions";
import { useActivePageId, useActiveZoomId, usePrefetchAndSetCloudinaryUrls, useViewportMode, } from "../../../redux/selectors/redux-selectors";
import TourialWatermark from "../tool-views/watermark-view";
import { RenderPrimaryViewportTools } from "./primary-tools";
import { useRefDimensions } from "../../../hooks/viewport-hooks";
import { theme } from "../../../../../shared/legacy/legacy-tool-data";
import { usePostLoadEvent, usePostSessionEndOnUnload, usePostViewEvent } from "../../../hooks/live-hooks";
const PrefetchCloudinaryUrls = () => {
    const viewportMode = useViewportMode();
    const pageId = useActivePageId();
    const zoomId = useActiveZoomId();
    const prefetchAndSetCloudinaryUrls = usePrefetchAndSetCloudinaryUrls(viewportMode);
    const [prefetchedCloudinaryUrls, setPrefetchedCloudinaryUrls] = useState({});
    const [isInit, setIsInit] = useState(true);
    useEffect(() => {
        prefetchAndSetCloudinaryUrls({ pageId, zoomId }, prefetchedCloudinaryUrls, setPrefetchedCloudinaryUrls, isInit);
        if (isInit)
            setIsInit(false);
    }, [pageId, zoomId]);
    return React.createElement(React.Fragment, null);
};
export const LiveViewportV2 = React.memo(() => {
    const viewportRef = useRef(null);
    const viewportDimensions = useRefDimensions(viewportRef);
    const viewportMode = useViewportMode();
    usePostLoadEvent();
    usePostViewEvent();
    usePostSessionEndOnUnload();
    return (React.createElement("div", { id: "tourial-viewport", ref: viewportRef, onDragOver: e => e.preventDefault(), style: {
            left: 0,
            right: 0,
            height: viewportDimensions.height,
            boxShadow: viewportMode === ViewportMode.EDIT ? theme.shadows.medium : null,
            backgroundColor: theme.colors.white,
            position: "absolute",
            overflow: "hidden",
        } },
        React.createElement(RenderPrimaryViewportTools, { viewportDimensions: viewportDimensions }),
        React.createElement(TourialWatermark, { key: "tourial_watermark", viewportDimensions: viewportDimensions }),
        React.createElement(PrefetchCloudinaryUrls, null)));
});
