import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactPortal from "../../../../helpers/react-portal";
import { newTheme } from "../../../styled/theme";
import GlobeIcon from "../../../../../assets/images/v3/publish-globe-icon.svg";
import ShareLinkIcon from "../../../../../assets/images/v3/share-link-blue.svg";
import EmbedIcon from "../../../../../assets/images/v3/embed-icon-blue.svg";
import InfoIcon from "../../../../../assets/images/v3/blue-tooltip-info.svg";
import { TToggleSwitch } from "../../../styled/t-toggle-switch";
import { useFeatureFlags, useIsEmbedHeaderEnabled, useIsMobileVariantEnabled, useIsPublished, useMobileEmbedButton, useTourialSlug, useTourialStatus, useTourId, } from "../../../../redux/selectors/redux-selectors";
import TStatusIndicator from "../../../styled/t-status-indicator";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { useAsync } from "react-async";
import { debounce } from "lodash";
import { TDropdownSelect } from "../../../styled/t-dropdown-select";
import { EmbedButtonContainer, EmbedTextarea } from "./embed-tab";
import { makeEmbedCode } from "../../../../legacy/components/builder/navbar/embed-modal";
import { usePostAppEvent } from "../../../../hooks/use-post-app-event";
import { TourialAppEventTypes } from "../../../../../../shared/types/app-events";
import { EmailTab } from "./email-tab";
import { ShareLinkViewTab } from "../../../share-links/share-links-viewer/share-link-view-tab";
import { useIsMobileButtonDisabled } from "../../../../redux/selectors/builder-selectors";
import { TourialSlugEditor } from "../../../share-links/share-links-editor/tourial-slug-editor";
import { useHandlePublish } from "../navbar/publish-and-share-button";
import { getCurrentUser } from "../../../../helpers/misc";
var ModalTabs;
(function (ModalTabs) {
    ModalTabs["PUBLIC_SHARING"] = "Public sharing";
    ModalTabs["EMAIL"] = "Email";
    ModalTabs["ADDITIONAL_LINKS"] = "1:1 share links";
})(ModalTabs || (ModalTabs = {}));
export default function SaveModal({ onClose, setIsSettingsModalOpen }) {
    const { outboundEmailLinks, shareLinks } = useFeatureFlags();
    const isPublished = useIsPublished();
    const postAppEvent = usePostAppEvent();
    const [isLightboxEnabled, setIsLightboxEnabled] = useState(false);
    const mobileEmbedButton = useMobileEmbedButton();
    const isMobileVariantEnabled = useIsMobileVariantEnabled();
    const isEmbedHeaderEnabled = useIsEmbedHeaderEnabled();
    const tourId = useTourId();
    const tabs = [ModalTabs.PUBLIC_SHARING];
    const [selectedTab, setSelectedTab] = useState(ModalTabs.PUBLIC_SHARING);
    const [embedCodeWasCopied, setEmbedCodeWasCopied] = useState(false);
    const tourialSlug = useTourialSlug();
    const tourialStatus = useTourialStatus();
    const isMobileButtonDisabled = useIsMobileButtonDisabled();
    const [viewCode, setViewCode] = useState(false);
    const handlePublish = useHandlePublish();
    const { data, error } = useAsync({
        promiseFn: getCurrentUser,
    });
    if (outboundEmailLinks) {
        tabs.push(ModalTabs.EMAIL);
    }
    if (shareLinks) {
        tabs.push(ModalTabs.ADDITIONAL_LINKS);
    }
    if (error) {
        console.error("Error getting current user", error);
    }
    const baseUrlWithSubdomain = data;
    useEffect(() => {
        const closeOnEscapeKey = e => (e.key === "Escape" ? onClose() : null);
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [onClose]);
    const copyEmbedCodeToClipboard = debounce(() => {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(makeEmbedCode(baseUrlWithSubdomain, tourialSlug, isMobileVariantEnabled, isLightboxEnabled, mobileEmbedButton, isEmbedHeaderEnabled, isMobileButtonDisabled)).then(function () {
            postAppEvent(isLightboxEnabled ? TourialAppEventTypes.LIGHTBOX_CODE_COPIED : TourialAppEventTypes.EMBED_CODE_COPIED);
            setEmbedCodeWasCopied(true);
        }, function (err) {
            console.error("Failed to copy text to clipboard: ", err);
        });
    }, 500);
    return (React.createElement(ReactPortal, { wrapperId: "tourial-portal-modal-container" },
        React.createElement(Overlay, { id: "t-overlay", onMouseDown: e => {
                if (document.elementFromPoint(e.clientX, e.clientY).id === "t-overlay")
                    onClose();
            } },
            React.createElement(SaveModalContainer, null,
                React.createElement(Header, null,
                    React.createElement(HeaderIcon, null,
                        React.createElement("img", { src: GlobeIcon })),
                    React.createElement(HeaderText, null, "Publish & share"),
                    React.createElement(TToggleSwitch, { id: "_test_publish-toggle-switch", enabled: isPublished, onChange: e => {
                            handlePublish(e);
                            if (!e)
                                setSelectedTab(ModalTabs.PUBLIC_SHARING);
                        } }),
                    React.createElement("div", { style: { marginLeft: "16px", width: "auto" } },
                        React.createElement(TStatusIndicator, { status: tourialStatus }))),
                isPublished && (React.createElement(TabContainer, null, tabs.map((tab, i) => (React.createElement(Tab, { className: selectedTab === tab ? "selected" : null, id: `_test_modal-tab-${i}`, key: `tab-${i}-${tab}`, onClick: () => {
                        setSelectedTab(tab);
                    } }, tab))))),
                selectedTab === ModalTabs.PUBLIC_SHARING && (React.createElement(PublicLinkTabContent, null,
                    !isPublished ? (React.createElement(BodyText, null, "Use the toggle to set the Tourial live again.")) : (React.createElement(PublicLinkContainer, null,
                        React.createElement("div", { style: { display: "flex", gap: 8 } },
                            React.createElement("img", { src: ShareLinkIcon }),
                            React.createElement("div", { style: { font: newTheme.fonts.medium } }, "Share link:")),
                        React.createElement(TourialSlugEditor, { baseUrlWithSubdomain: baseUrlWithSubdomain }),
                        React.createElement(SupportText, { style: { top: 115 } }, "Anyone with this link may view."))),
                    isPublished && (React.createElement(SectionContainer, null,
                        React.createElement("div", { style: { display: "flex", gap: 8 } },
                            React.createElement("img", { src: EmbedIcon }),
                            React.createElement("div", null, "Website embed:"),
                            React.createElement("div", { style: {
                                    marginLeft: "auto",
                                    textDecoration: "underline",
                                    font: newTheme.fonts.small,
                                    color: newTheme.colors.grey600,
                                    cursor: "pointer",
                                }, onClick: () => setViewCode(!viewCode) }, viewCode ? "Hide code" : "View code")),
                        React.createElement(TDropdownSelect, { isSearchable: false, selectedValue: isLightboxEnabled, style: { width: "100%" }, onChange: (e) => {
                                setIsLightboxEnabled(e.value);
                            }, options: [
                                { value: false, label: "Embed on page" },
                                { value: true, label: "Tourial lightbox" },
                            ] }),
                        isLightboxEnabled && (React.createElement(React.Fragment, null,
                            React.createElement(Span, null, "Add the class name below on the HTML tag that will trigger the lightbox."),
                            React.createElement(EmbedTextarea, { style: { height: 35, margin: "8px 0px" } },
                                React.createElement("code", null,
                                    "tourial-lightbox-trigger-",
                                    tourialSlug)))),
                        viewCode && (React.createElement(React.Fragment, null,
                            React.createElement(Span, null, "Paste into your webpage where you want the Tourial to display:"),
                            React.createElement(EmbedTextarea, null,
                                React.createElement("code", null, makeEmbedCode(baseUrlWithSubdomain, tourialSlug, isMobileVariantEnabled, isLightboxEnabled, mobileEmbedButton, isEmbedHeaderEnabled, isMobileButtonDisabled))))),
                        React.createElement(EmbedButtonContainer, null,
                            embedCodeWasCopied && React.createElement(Span, { id: "_test_embed-code-copied" }, "Copied!"),
                            React.createElement(TButton, { id: "_test_embed-code-copy-button", variant: TButtonVariants.OUTLINE, size: "small", onClick: () => {
                                    copyEmbedCodeToClipboard();
                                    setEmbedCodeWasCopied(true);
                                } }, "Copy code")),
                        React.createElement(SupportText, { style: { bottom: 90 } }, "Select embed or lightbox, then copy code."))),
                    React.createElement(SectionContainer, null,
                        React.createElement("div", { style: { display: "flex", gap: 8 } },
                            React.createElement("img", { src: InfoIcon }),
                            React.createElement("div", null, "Mobile version:"),
                            React.createElement("div", { style: { fontWeight: "bold" } }, isMobileVariantEnabled ? "ON" : "OFF"),
                            React.createElement("div", { style: {
                                    marginLeft: "auto",
                                    textDecoration: "underline",
                                    font: newTheme.fonts.small,
                                    color: newTheme.colors.grey600,
                                    cursor: "pointer",
                                }, onClick: () => {
                                    onClose();
                                    setIsSettingsModalOpen(true);
                                } }, "Settings"))))),
                selectedTab === ModalTabs.EMAIL && React.createElement(EmailTab, { baseUrlWithSubdomain: baseUrlWithSubdomain }),
                selectedTab === ModalTabs.ADDITIONAL_LINKS && (React.createElement(ShareLinkViewTab, { tourialId: tourId, tourialSlug: tourialSlug, baseUrlWithSubdomain: baseUrlWithSubdomain }))))));
}
const Span = styled.span `
  font: ${newTheme.fonts.medium};
`;
const PublicLinkContainer = styled.div `
  border-radius: 4px;
  background-color: ${newTheme.colors.blue25};
  padding: 8px;
`;
const SupportText = styled.div `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey700};
  position: absolute;
`;
const Overlay = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const SaveModalContainer = styled.div `
  position: absolute;
  top: 57px;
  right: 5px;
  width: 480px;
  background-color: ${newTheme.colors.white};
  box-shadow: ${newTheme.shadows.outside60};
`;
const Header = styled.div `
  display: flex;
  padding: 16px;
  align-items: center;
`;
const HeaderIcon = styled.div `
  margin: 0 4px 0 0;
  width: 32px;
  height: 32px;
  img {
    width: 32px;
    height: 32px;
  }
`;
const BodyText = styled.div `
  font: ${newTheme.fonts.normal};
  color: ${newTheme.colors.grey800};
  width: 100%;
  text-align: center;
`;
const HeaderText = styled.div `
  display: flex;
  flex: 1;
  font: ${newTheme.fonts.bold};
  color: ${newTheme.colors.grey800};
`;
const TabContainer = styled.ul `
  list-style: none;
  width: 100%;
  display: flex;
  padding: 0 12px;
`;
const Tab = styled.li `
  cursor: pointer;
  margin: 0 12px;
  padding-bottom: 4px;
  font: ${newTheme.fonts.normal};
  color: ${newTheme.colors.grey600};
  &.selected {
    color: ${newTheme.colors.black};
    border-bottom: 4px solid ${newTheme.colors.blue300};
    font-weight: bold;
  }
`;
const PublicLinkTabContent = styled.div `
  padding: 24px 16px;
  background-color: white;
  border-bottom: 1px solid ${newTheme.colors.grey300};
  border-top: 1px solid ${newTheme.colors.grey300};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const SectionContainer = styled.div `
  border-radius: 4px;
  padding: 8px;
  background-color: ${newTheme.colors.grey100};
  font: ${newTheme.fonts.medium};
`;
