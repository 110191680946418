import styled from "styled-components";
import { TextStyle } from "../../../../../../../shared/types/tool-data-types/common";
import { bodyFontSizes, textAlignments } from "../../../../../helpers/text-helpers";
export const StyledLabel = styled.label `
  display: block;
  font-family: ${p => p.labelText.font};
  font-size: ${p => bodyFontSizes[p.labelText.size]}px;
  text-align: ${p => textAlignments[p.labelText.align]};
  font-family: ${p => p.labelText.font};
  text-decoration: ${p => (p.labelText.style === TextStyle.UNDERLINE ? "underline" : "none")};
  font-weight: ${p => (p.labelText.style === TextStyle.BOLD ? "bold" : "normal")};
  font-style: ${p => (p.labelText.style === TextStyle.ITALIC ? "italic" : "normal")};
  color: ${p => p.labelText.color};
`;
