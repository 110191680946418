import { useEffect } from "react";
import { useClearTourialRootState, useSetInitialTourialRootState } from "../redux/selectors/redux-selectors";
export function useSetAndClearRootState(data) {
    const setInitialTourialRootState = useSetInitialTourialRootState();
    const clearTourialRootState = useClearTourialRootState();
    useEffect(() => {
        if (data)
            setInitialTourialRootState(data);
        return () => {
            clearTourialRootState();
        };
    }, [data]);
}
