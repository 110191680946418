import React from "react";
import { Description, Headline, InnerWrapper, Logo, LogoContainer } from "./subcomponents/styled-confirmation-view";
export const FormConfirmationView = (props) => {
    const { layout, text, logo } = props.toolData;
    const { confirmHeaderText, confirmBodyText } = text;
    return (React.createElement(InnerWrapper, { layout: layout },
        React.createElement(LogoContainer, { layout: layout },
            logo.src && (React.createElement(Logo, { shape: logo.shape, size: logo.size },
                React.createElement("img", { src: logo.src, alt: "logo" }))),
            React.createElement(Headline, { font: confirmHeaderText.font, textStyle: confirmHeaderText.style, align: confirmHeaderText.align, size: confirmHeaderText.size, color: confirmHeaderText.color }, confirmHeaderText.text),
            React.createElement(Description, { font: confirmBodyText.font, textStyle: confirmBodyText.style, align: confirmBodyText.align, size: confirmBodyText.size, color: confirmBodyText.color }, confirmBodyText.text))));
};
