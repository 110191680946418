import { newTheme } from "../components/styled/theme";
import { useEffect } from "react";
import { useEditDetailsId, useIsToolHovered, useSetEditDetailsId } from "../redux/selectors/redux-selectors";
import { useIsToolSelected } from "../redux/selectors/builder-selectors";
export function useSelectionWrapperStyle(toolId, setStyle, dashedOutline) {
    const isHovered = useIsToolHovered(toolId);
    const isSelected = useIsToolSelected(toolId);
    const editDetailsId = useEditDetailsId();
    const setEditDetailsId = useSetEditDetailsId();
    useEffect(() => {
        let style = {};
        if (isHovered) {
            style = newTheme.builder.hoveredToolStyle;
        }
        if (isSelected) {
            style = newTheme.builder.selectedToolStyle;
        }
        else if (editDetailsId === toolId) {
            setEditDetailsId("");
        }
        if ((isHovered || isSelected) && dashedOutline) {
            style = newTheme.builder.resizableStyle;
        }
        setStyle(style);
    }, [isHovered, isSelected]);
}
