import React from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { DCIcon } from "../../components/dc-icon";
import { dcGetItemDurationLabel, dcGetReadableItemType } from "../../helpers/dc-helpers";
import { DCBadge } from "../../components/dc-badge";
import { DCBadgeType, DCIconType, DCItemType, } from "../../../../../../../shared/types/demo-center";
import { DCFileUploader } from "../dc-file-uploader";
import { DCCMInfoEditor } from "./dc-cm-info-editor";
import { DCCMEditableText } from "./dc-cm-editable-text";
import { cloneDeep } from "lodash";
import { DCCMSettingToggleContainer, DCCMToggleLabel } from "../dc-cm-intro-page-editor";
import { TToggleSwitch } from "../../../../styled/t-toggle-switch";
import { DCStyledBodyText } from "../../components/dc-styled-text";
export const DCCMTrackItem = React.forwardRef(({ item, setActiveItemId, setItem, activeItemId, badgeConfig, sidebarConfig, dragHandleProps, draggableProps, preview, onDelete, onItemCommit, }, ref) => {
    const [isEditing, setIsEditing] = React.useState(false);
    const { id, title, type, duration, thumbnail, description, config } = item;
    const expanded = id === activeItemId;
    const rotate = { transform: expanded ? "rotate(-180deg)" : null, transition: "transform 0.1s ease-in" };
    const updateItemConfig = (config) => {
        const i = cloneDeep(item);
        i.config = config;
        setItem(i);
    };
    const toggleContainer = () => {
        setActiveItemId === null || setActiveItemId === void 0 ? void 0 : setActiveItemId(expanded ? null : id);
    };
    const handleEdit = (e) => {
        e.stopPropagation();
        setIsEditing(true);
        if (!expanded)
            toggleContainer();
    };
    const handleDelete = (e) => {
        e.stopPropagation();
        onDelete === null || onDelete === void 0 ? void 0 : onDelete(item);
    };
    return (React.createElement(ItemContainer, Object.assign({ className: expanded ? "active" : null }, draggableProps, { ref: ref }),
        React.createElement(ItemHeader, { onClick: toggleContainer },
            !preview && (React.createElement(DCCMDragHandle, Object.assign({}, dragHandleProps),
                React.createElement(DCIcon, { type: DCIconType.DRAG_HANDLE }))),
            React.createElement(ItemInfo, null,
                React.createElement(DCCMEditableText, { value: title, onChange: title => setItem(Object.assign(Object.assign({}, item), { title })), disabled: !expanded, style: {
                        font: newTheme.fonts.normal,
                        color: newTheme.colors.grey900,
                    }, selected: isEditing, onEditChange: setIsEditing, onCommit: () => onItemCommit(item) }),
                React.createElement(ItemSubtitle, null,
                    React.createElement(DCBadge, { config: badgeConfig, type: DCBadgeType.ITEM_TYPE, content: dcGetReadableItemType(type) }),
                    duration && (React.createElement(DCStyledBodyText, { config: sidebarConfig.itemTypeStyle }, dcGetItemDurationLabel(duration))))),
            React.createElement(EditControls, null,
                !isEditing && (React.createElement(IconButton, { className: "edit", onClick: handleEdit },
                    React.createElement(DCIcon, { type: DCIconType.PENCIL }))),
                React.createElement(IconButton, { className: "delete", onClick: handleDelete },
                    React.createElement(DCIcon, { type: DCIconType.TRASH }))),
            !preview && React.createElement(DCIcon, { type: DCIconType.DOWN_CHEVRON, style: rotate })),
        expanded && (React.createElement(React.Fragment, null,
            React.createElement(Spacer, null),
            React.createElement(ItemDetails, null,
                React.createElement(LeftColumn, null,
                    React.createElement(DCCMThumbnail, null, thumbnail && React.createElement("img", { src: thumbnail })),
                    React.createElement(DCFileUploader, { onUploadComplete: url => setItem(Object.assign(Object.assign({}, item), { thumbnail: url })) },
                        React.createElement(DCCMUploadLink, null, "Change Thumbnail"))),
                React.createElement(RightColumn, null,
                    React.createElement(DCCMInfoEditor, { textarea: true, value: description, icons: [DCIconType.ADD_TEXT, DCIconType.TEXT], onChange: description => setItem(Object.assign(Object.assign({}, item), { description })), placeholder: "Add a summary for the gallery view (optional)" }),
                    React.createElement(RenderItemConfig, { type: type, config: config, onChange: updateItemConfig })))))));
});
const RenderItemConfig = ({ type, config, onChange }) => {
    const updatePagination = (pagination) => onChange(Object.assign(Object.assign({}, config), { pagination }));
    const { pagination } = config || {};
    switch (type) {
        case DCItemType.PDF:
            return (React.createElement(DCCMSettingToggleContainer, { style: { marginTop: 8 } },
                React.createElement(DCIcon, { type: DCIconType.DOCUMENT, style: { width: 24, height: 24 } }),
                React.createElement(DCCMToggleLabel, null, "Enable Pagination"),
                React.createElement(TToggleSwitch, { onChange: updatePagination, enabled: pagination, size: "small" })));
        default:
            return null;
    }
};
const ItemContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid transparent;
  &.active {
    border: 1px solid ${newTheme.colors.grey300};
    background-color: ${newTheme.colors.grey100};
  }
  transition:
    border 0.1s ease-in-out,
    background-color 0.1s ease-in-out;
`;
const Spacer = styled.div `
  height: 0px;
  border-bottom: 1px solid ${newTheme.colors.grey300};
  width: 100%;
`;
const EditControls = styled.div `
  display: flex;
  align-items: center;
  opacity: 0;
  gap: 4px;
  transition: opacity 0.1s ease-in-out;
`;
const ItemHeader = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 12px;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }
  &:hover {
    ${EditControls} {
      opacity: 1;
    }
  }
`;
const IconButton = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }
  &.delete:hover {
    color: ${newTheme.colors.red500};
  }
  &.edit:hover {
    color: ${newTheme.colors.blue500};
  }
`;
export const DCCMDragHandle = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
`;
const ItemInfo = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
`;
const ItemSubtitle = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${newTheme.colors.grey500};
  font: ${newTheme.fonts.small};
  span {
    text-transform: uppercase;
  }
`;
const ItemDetails = styled.div `
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
`;
const LeftColumn = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;
export const DCCMThumbnail = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 64px;
  border-radius: 4px;
  background: var(--brand-greys-300, #e4e5e9);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  overflow: hidden;
`;
const RightColumn = styled.div `
  flex: 1;
  padding: 12px 0 0 12px;
`;
export const DCCMUploadLink = styled.span `
  color: ${newTheme.colors.grey600};
  font: ${newTheme.fonts.medium};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${newTheme.colors.blue500};
  }
`;
