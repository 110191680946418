import React, { useEffect } from "react";
import styled from "styled-components";
import { useDCSetBuilderMode } from "../../../../redux/selectors/demo-center-selectors";
import { DCBuilderMode, DCIconType, DCView } from "../../../../../../shared/types/demo-center";
import { DCStylesToolbar } from "./dc-styles-toolbar";
import { newTheme } from "../../../styled/theme";
import { DCStylesFloatingMenu } from "./components/dc-styles-floating-menu";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDCBuilderGoToPage, useDCLivePreviewHelper } from "../hooks/dc-builder-hooks";
import { DCPreview } from "../preview/dc-preview";
import { MainAppPagePaths } from "../../../../../../shared/types/app-events";
import { useDCConfig, useDCId } from "../hooks/dc-hooks";
export const DCStylesEditor = () => {
    const [activeView, setActiveView] = React.useState(DCView.WELCOME);
    const { welcome, gallerySection } = useDCConfig();
    const setMode = useDCSetBuilderMode();
    const goToPage = useDCBuilderGoToPage();
    useDCLivePreviewHelper("");
    useEffect(() => {
        const currentView = window.location.pathname.split("/").pop();
        setMode(DCBuilderMode.STYLES);
        setActiveView(currentView);
    }, []);
    const getName = (page) => {
        switch (page) {
            case DCView.WELCOME:
                return "Intro Page";
            case DCView.GALLERY_VIEW:
                return "Gallery View";
            case DCView.MODULE:
                return "Topic View";
        }
    };
    const buttons = [
        {
            name: getName(DCView.MODULE),
            icon: DCIconType.CHECKLIST,
            onClick: () => goToPage(`styles/${DCView.MODULE}`),
        },
        {
            name: getName(DCView.WELCOME),
            icon: DCIconType.HAND_WAVE,
            onClick: () => goToPage(`styles/${DCView.WELCOME}`),
            disabled: !welcome.isEnabled,
        },
        {
            name: getName(DCView.GALLERY_VIEW),
            icon: DCIconType.LIBRARY,
            onClick: () => goToPage(`styles/${DCView.GALLERY_VIEW}`),
            disabled: !gallerySection.isEnabled,
        },
    ];
    return (React.createElement(StylesContainer, null,
        React.createElement(DCStylesToolbar, null),
        React.createElement(DCStylesFloatingMenu, { buttons: buttons, activeButtonName: getName(activeView) }),
        React.createElement(PreviewContainer, null,
            React.createElement(DCStylesRouter, null))));
};
const DCStylesRouter = () => {
    const democenterId = useDCId();
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/styles`, component: () => (React.createElement(Redirect, { to: `${MainAppPagePaths.DEMOCENTER_BUILDER}/${democenterId}/styles/${DCView.MODULE}` })) }),
        React.createElement(Route, { exact: true, path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/styles/${DCView.WELCOME}`, component: () => React.createElement(DCPreview, { view: DCView.WELCOME }) }),
        React.createElement(Route, { exact: true, path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/styles/${DCView.GALLERY_VIEW}`, component: () => React.createElement(DCPreview, { view: DCView.GALLERY_VIEW }) }),
        React.createElement(Route, { exact: true, path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id/styles/${DCView.MODULE}`, component: () => React.createElement(DCPreview, { view: DCView.MODULE }) })));
};
const StylesContainer = styled.div `
  ${newTheme.patterns.polkaDot}
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  height: 100dvh;
  padding-top: calc(48px + 56px);
`;
const PreviewContainer = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  padding: 132px 32px 32px 72px;
  z-index: 1;
  overflow-y: scroll;
`;
