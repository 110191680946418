import React, { useState } from "react";
import styled from "styled-components";
import { useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { EditorAccordion } from "../../../../pages/builder/editor/editor-accordion";
import ArrowIcon from "../../../../../../assets/images/v3/cursor-style-arrow.svg";
import AnimationIcon from "../../../../../../assets/images/v3/animation-settings-icon.svg";
import { cloneDeep } from "lodash";
import { ColorPicker } from "../../../../pages/builder/editor/atomic/color-picker";
import { ShirtSizeSelector } from "../../../../pages/builder/editor/atomic/shirt-size-selector";
import { OpacitySlider } from "../../../../pages/builder/editor/atomic/opacity-slider";
import { TCheckbox } from "../../../../styled/t-checkbox";
import { TDropdownSelect } from "../../../../styled/t-dropdown-select";
import { MouseShape } from "../../../../../../../shared/types/tool-data-types/auto-mouse-data";
import { ControlContainer, ControlLabel } from "../../../../styled/editor-components";
export function AutoMouseStyleSection(props) {
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    const [openId, setOpenId] = useState("auto-mouse");
    return (React.createElement(Section, null,
        React.createElement(EditorAccordion, { id: "cursor-style", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: ArrowIcon, title: "Cursor style", isEnabled: true, isHidden: false },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Shape"),
                React.createElement(TDropdownSelect, { selectedValue: tool.shape, options: [
                        { label: "circle", value: MouseShape.CIRCLE },
                        { label: "pointer", value: MouseShape.POINTER },
                        { label: "arrow", value: MouseShape.ARROW },
                    ], style: { width: 102 }, isSearchable: false, onChange: (option) => {
                        const t = cloneDeep(tool);
                        t.shape = option.value;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Cursor Size"),
                React.createElement(ShirtSizeSelector, { size: tool.size, onChange: (size) => {
                        const t = cloneDeep(tool);
                        t.size = size;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Fill Color"),
                React.createElement(ColorPicker, { color: tool.color, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.color = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Border Color"),
                React.createElement(ColorPicker, { color: tool.borderColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.borderColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Opacity"),
                React.createElement(OpacitySlider, { value: tool.opacity, onChange: (e) => {
                        const t = cloneDeep(tool);
                        t.opacity = e;
                        updateTool(t);
                    } }))),
        React.createElement(EditorAccordion, { id: "animation-style", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: AnimationIcon, title: "Animation settings", isEnabled: true, isHidden: false },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Click Animation"),
                React.createElement(TCheckbox, { checked: tool.hasClickAnimation, onChange: e => {
                        const t = cloneDeep(tool);
                        t.hasClickAnimation = e;
                        updateTool(t);
                    } })))));
}
const Section = styled.div `
  max-height: 65vh;
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
