export var InteractionType;
(function (InteractionType) {
    InteractionType["CLICK"] = "CLICK";
    InteractionType["HOVER_IN"] = "HOVER_IN";
    InteractionType["HOVER_OUT"] = "HOVER_OUT";
    InteractionType["HOVER"] = "HOVER";
    InteractionType["STEP_ENTER"] = "STEP_ENTER";
    InteractionType["FORM_SUBMIT"] = "FORM_SUBMIT";
    // header interactions
    InteractionType["PRODUCT_PAGE_CTA_CLICK"] = "PRODUCT_PAGE_CTA_CLICK";
    InteractionType["EMBED_HEADER_CTA_CLICK"] = "EMBED_HEADER_CTA_CLICK";
    InteractionType["MICROSITE_CTA_CLICK"] = "MICROSITE_CTA_CLICK";
    InteractionType["MICROSITE_FORM_SUBMIT"] = "MICROSITE_FORM_SUBMIT";
    InteractionType["DEMOCENTER_FORM_SUBMIT"] = "DEMOCENTER_FORM_SUBMIT";
})(InteractionType || (InteractionType = {}));
export var InteractionName;
(function (InteractionName) {
    InteractionName["JUMP_TO_STEP"] = "JUMP_TO_STEP";
    InteractionName["JUMP_TO_PAGE"] = "JUMP_TO_PAGE";
    InteractionName["NEXT_STEP"] = "NEXT_STEP";
    InteractionName["NEXT_PAGE"] = "NEXT_PAGE";
    InteractionName["PREVIOUS_STEP"] = "PREVIOUS_STEP";
    InteractionName["PREVIOUS_PAGE"] = "PREVIOUS_PAGE";
    InteractionName["SHOW_TOOLTIP_BUBBLE_AND_SPOTLIGHT"] = "SHOW_TOOLTIP_BUBBLE_AND_SPOTLIGHT";
    InteractionName["HIDE_TOOLTIP_BUBBLE_AND_SPOTLIGHT"] = "HIDE_TOOLTIP_BUBBLE_AND_SPOTLIGHT";
    InteractionName["TOGGLE_TOOLTIP_BUBBLE_AND_SPOTLIGHT"] = "TOGGLE_TOOLTIP_BUBBLE_AND_SPOTLIGHT";
    InteractionName["EXTERNAL_LINK"] = "EXTERNAL_LINK";
    InteractionName["SUBMIT_FORM"] = "SUBMIT_FORM";
    InteractionName["CLOSE_FORM"] = "CLOSE_FORM";
    InteractionName["OPEN_FORM"] = "OPEN_FORM";
    InteractionName["TRIGGER_FORM"] = "TRIGGER_FORM";
    InteractionName["CLOSE_MODAL"] = "CLOSE_MODAL";
    InteractionName["NULL"] = "NULL";
    InteractionName["FIRST_STEP"] = "FIRST STEP";
})(InteractionName || (InteractionName = {}));
export function makeInteraction(type, name, target) {
    return { type, name, target: target || null };
}
