export function isInIframe() {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
}
export function isEmbedded() {
    return !!new URLSearchParams(window.location.search).get("isEmbedded");
}
export function isInLightbox() {
    return !!new URLSearchParams(window.location.search).get("isLightbox");
}
export function isInProductPage() {
    return !!document.getElementById("product-page");
}
export function isOgImage() {
    return !!new URLSearchParams(window.location.search).get("isOgImage");
}
export function getDeviceWidth() {
    return Math.max(document.body.scrollWidth, document.documentElement.scrollWidth, document.body.offsetWidth, document.documentElement.offsetWidth, document.documentElement.clientWidth, window.screen.width // this is either the parent screen dims or the iframe dims if CORS blocks
    );
}
export function getBrowserWidth() {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
}
