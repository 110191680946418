import React, { useContext } from "react";
import { HTMode } from "../../../../../../../shared/types/dom-tour";
import { useHTMode, useHTName } from "../../../../../redux/selectors/ht-selectors";
import { NameInput } from "../../../builder/navbar/name-input";
import { DraftContext } from "../dom-tour-builder-page";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import TStatusIndicator from "../../../../styled/t-status-indicator";
import { useHTStatus } from "../../hooks/ht-hooks";
export function Name() {
    const name = useHTName();
    const mode = useHTMode();
    const draft = useContext(DraftContext);
    return (React.createElement(TourialNameInputContainer, null,
        React.createElement(NameInput, { disabled: mode !== HTMode.EDIT, name: name, setName: (name) => {
                void draft.update({ name });
            } }),
        React.createElement(BuilderIsLiveBadge, null)));
}
export function BuilderIsLiveBadge() {
    const status = useHTStatus();
    return (React.createElement(Div, null,
        React.createElement(TStatusIndicator, { status: status })));
}
const Div = styled.div `
  padding-bottom: 4px;
`;
const TourialNameInputContainer = styled.span `
  font: ${newTheme.fonts.medium};
  width: 300px;
  gap: 4px;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
`;
