import React from "react";
import { EditorTitlebar } from "../../../pages/builder/editor/editor-titlebar";
import { EditorTabs } from "../../../pages/builder/editor/editor-tabs";
import { AutoTooltipStyleSection } from "./components/auto-tooltip-style-section";
import { AutoTooltipConfigureSection } from "./components/auto-tooltip-config-section";
export function AutoTooltipEditor(props) {
    const { toolData: tool } = props;
    return (React.createElement("div", { style: { height: "100%" } },
        React.createElement(EditorTitlebar, { title: tool.name, toolId: tool.id, tool: tool }),
        React.createElement(EditorTabs, { tabs: [
                { title: "Style", contents: React.createElement(AutoTooltipStyleSection, Object.assign({}, props)) },
                { title: "Configure", contents: React.createElement(AutoTooltipConfigureSection, Object.assign({}, props)) },
            ] })));
}
