import React from "react";
import { EditorTitlebar } from "../../../pages/builder/editor/editor-titlebar";
import { EditorTabs } from "../../../pages/builder/editor/editor-tabs";
import { AutoTypewriterStyleSection } from "./components/auto-typewriter-style-section";
export function AutoTypewriterEditor(props) {
    const { toolData: tool } = props;
    return (React.createElement("div", { id: "_test_auto-typewriter-editor" },
        React.createElement(EditorTitlebar, { title: tool.name, toolId: tool.id, tool: tool }),
        React.createElement(EditorTabs, { tabs: [
                { title: "Style", contents: React.createElement(AutoTypewriterStyleSection, Object.assign({}, props)) },
                //   { title: "Configure", contents: <AutoTypewriterConfigureSection {...props} /> },
            ] })));
}
