import { GET_ALL_ACCOUNTS, GET_ALL_USERS_AND_ACCOUNTS, RENDER_SUPER_ADMIN, RESET_SUPER_ADMIN, SET_DEMO_CHATBOT_BACKGROUND_IMAGE, SET_DEMO_CHATBOT_URL, SET_PROJECT_ROOM_LINK, } from "../../../actions/legacy-action-types";
import axios from "axios";
const initialState = {
    allAccounts: [],
    allUsers: [],
    isLoading: true,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ACCOUNTS: {
            const accounts = action.allAccounts.slice();
            return Object.assign(Object.assign({}, state), { allAccounts: accounts, isLoading: false });
        }
        case GET_ALL_USERS_AND_ACCOUNTS:
            return Object.assign(Object.assign({}, state), { allUsers: action.allUsers, allAccounts: action.allAccounts, isLoading: false });
        case RENDER_SUPER_ADMIN:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case RESET_SUPER_ADMIN:
            return Object.assign(Object.assign({}, state), { allAccounts: [], allUsers: [], isLoading: true });
        case SET_PROJECT_ROOM_LINK: {
            const { projectRoomLink, accountId } = action;
            const data = {
                projectRoomLink,
                accountId,
            };
            axios.post(`/accounts/update/projectRoomLink`, data).catch((e) => {
                console.error("error updating account project room link", e);
            });
            return Object.assign(Object.assign({}, state), { projectRoomLink });
        }
        case SET_DEMO_CHATBOT_URL: {
            const { demoChatbotUrl, accountId, clientId } = action;
            const data = {
                demoChatbotUrl,
                accountId,
                clientId,
            };
            axios.post(`/accounts/update/demoChatbotUrl`, data).catch((e) => {
                console.error("error updating account demo chatbot url", e);
            });
            return state;
        }
        case SET_DEMO_CHATBOT_BACKGROUND_IMAGE: {
            const { backgroundImage, accountId, clientId } = action;
            const data = {
                backgroundImage,
                accountId,
                clientId,
            };
            axios.post(`/accounts/update/demoChatbotBackgroundImage`, data).catch((e) => {
                console.error("error updating account demo chatbot background image", e);
            });
            return state;
        }
        default:
            return state;
    }
}
