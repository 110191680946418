import React from "react";
import styled from "styled-components";
import { NAV_HEIGHT } from "../../config";
import { Back } from "./back";
import { newTheme } from "../../../../styled/theme";
import { Name } from "./name";
import { Preview } from "./preview";
import SettingsModal from "./settings-modal";
import { HTDeviceSelector } from "./ht-device-selector";
import { useHTMode } from "../../../../../redux/selectors/ht-selectors";
import { HTMode } from "../../../../../../../shared/types/dom-tour";
import { Share } from "./share-modal";
import { EditModeDisabled } from "../../../builder/navbar/edit-mode-disabled";
import { useHTIsEditModeBlocked } from "../../../../../hooks/use-is-collaborator-blocked";
export function Navbar() {
    const isEditModeBlocked = useHTIsEditModeBlocked();
    const mode = useHTMode();
    const isEditMode = mode === HTMode.EDIT;
    return (React.createElement(Root, null,
        React.createElement(Container, null,
            React.createElement(Back, null),
            React.createElement(Name, null)),
        React.createElement(Container, null,
            React.createElement(HTDeviceSelector, null)),
        isEditModeBlocked ? (React.createElement(EditModeDisabled, null)) : (React.createElement(Container, null,
            isEditMode && React.createElement(Share, null),
            React.createElement(Preview, null),
            isEditMode && React.createElement(SettingsModal, null)))));
}
const Root = styled.div `
  width: 100%;
  height: ${NAV_HEIGHT}px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
  background-color: ${newTheme.colors.white};
  border-bottom: 1px solid ${newTheme.colors.grey200};
`;
const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;
