import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDCSetBuilderMode } from "../../../../redux/selectors/demo-center-selectors";
import { DCBuilderMode, DCModuleType } from "../../../../../../shared/types/demo-center";
import { newTheme } from "../../../styled/theme";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import RightArrowIcon from "../../../../../assets/images/v3/arrow-right-white.svg";
import LeftArrowIcon from "../../../../../assets/images/v3/arrow-left-blue.svg";
import { DCWizardModulePicker } from "./components/dc-wizard-module-picker";
import { demoCenterModuleTemplates } from "../../../../../../shared/objects/default-demo-center";
import { useDCCreateWelcomeModule, useDCGetNewModule, useDCSetModules } from "../hooks/dc-module-hooks";
import { useDCGoToBuilder } from "../hooks/dc-builder-hooks";
import { DCWizardModuleEditor } from "./components/dc-wizard-module-editor";
import { DCWizardModulePreview } from "./components/dc-wizard-module-preview";
import { DCConfirmDeleteModal } from "./modals/dc-confirm-delete-modal";
const steps = [
    {
        title: "Start by creating topics",
        description: "Topics should reflect a common point of evaluation along your buyer’s journey.",
    },
    {
        title: "Edit your prompts and add choices",
        description: "Click through the topics you selected and add choices for your visitors to select from. ",
    },
];
const templates = [
    ...demoCenterModuleTemplates,
    {
        title: "",
        prompt: "",
        type: DCModuleType.SINGLE,
    },
];
export const DCWizard = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
    const [selectedModules, setSelectedModules] = useState([]);
    const [nextButtonIsDisabled, setNextButtonIsDisabled] = useState(false);
    const [exitModal, setExitModal] = useState(null);
    const goToBuilder = useDCGoToBuilder();
    const getNewModule = useDCGetNewModule();
    const createWelcomeModule = useDCCreateWelcomeModule();
    const setModules = useDCSetModules();
    const setMode = useDCSetBuilderMode();
    const isFirstStep = currentStep === 0;
    const isLastStep = currentStep === steps.length - 1;
    const isFirstModule = currentModuleIndex === 0;
    const isLastModule = currentModuleIndex === selectedModules.length - 1 || !selectedModules.length;
    const readyToFinish = isLastStep && isLastModule;
    const module = selectedModules[currentModuleIndex];
    const { title, description } = steps[currentStep];
    const prev = () => {
        if (!isFirstModule)
            setCurrentModuleIndex(currentModuleIndex - 1);
        if (isFirstModule && !isFirstStep)
            setCurrentStep(currentStep - 1);
    };
    const next = () => {
        if (!isLastStep) {
            setCurrentStep(currentStep + 1);
        }
        else if (!isLastModule) {
            setCurrentModuleIndex(currentModuleIndex + 1);
        }
        if (readyToFinish) {
            createNewModules();
            goToBuilder();
        }
    };
    const createNewModules = (skip) => {
        const newModules = [];
        newModules.push(createWelcomeModule());
        if (!skip)
            selectedModules.forEach(m => newModules.push(getNewModule(m)));
        setModules(newModules);
    };
    const onModuleSelect = (module) => {
        const newModules = [...selectedModules];
        const index = newModules.findIndex(m => m.title === module.title);
        if (index > -1) {
            newModules.splice(index, 1);
        }
        else {
            newModules.push(module);
        }
        setSelectedModules(newModules);
    };
    const updateModule = (module) => {
        const newModules = [...selectedModules];
        newModules[currentModuleIndex] = module;
        setSelectedModules(newModules);
    };
    const askToExitWizard = () => {
        setExitModal({
            title: "Exit Wizard",
            message: "Are you sure you want to exit the wizard?",
            confirmText: "Yes, Exit Wizard",
            close: () => setExitModal(null),
            confirm: () => {
                createNewModules(true);
                goToBuilder();
            },
        });
    };
    useEffect(() => setMode(DCBuilderMode.WIZARD), []);
    useEffect(() => {
        var _a;
        const { tracks, title } = module || {};
        const hasTracks = (tracks === null || tracks === void 0 ? void 0 : tracks.length) > 1 || ((tracks === null || tracks === void 0 ? void 0 : tracks.length) === 1 && !!((_a = tracks === null || tracks === void 0 ? void 0 : tracks[0]) === null || _a === void 0 ? void 0 : _a.title));
        setNextButtonIsDisabled((!isFirstStep && (!hasTracks || !title)) || (isFirstStep && !selectedModules.length));
    }, [module, selectedModules, currentStep]);
    return (React.createElement(WizardContainer, null,
        React.createElement(Sidebar, null,
            React.createElement(StepCounter, null,
                "Step ",
                currentStep + 1,
                "/",
                steps.length),
            React.createElement(Title, { "data-cy": "dc-wizard-title" }, title),
            React.createElement(Description, null, description),
            isFirstStep ? (React.createElement(DCWizardModulePicker, { modules: templates, selectedModules: selectedModules, onModuleSelect: onModuleSelect })) : (!!module && React.createElement(DCWizardModuleEditor, { module: module, onChange: updateModule })),
            React.createElement(SidebarButtonContainer, null,
                !isFirstStep && (React.createElement(TButton, { onClick: prev, variant: TButtonVariants.TRANSPARENT, icon: LeftArrowIcon }, "Back")),
                React.createElement(TButton, { onClick: next, icon: !nextButtonIsDisabled ? RightArrowIcon : null, iconPlacement: "right", disabled: nextButtonIsDisabled }, readyToFinish ? "Finish" : "Next"),
                isFirstStep && (React.createElement(SkipButton, { onClick: askToExitWizard, "data-cy": "dc-wizard-skip" }, "Skip wizard")))),
        React.createElement(Preview, null, isFirstStep ? (React.createElement(Tour, { src: "https://tourialinproduct.tourial.com/4cbccffa-87ba-4e3b-a09f-3f0b6f3e462b" })) : (React.createElement(DCWizardModulePreview, { module: module }))),
        exitModal && React.createElement(DCConfirmDeleteModal, Object.assign({}, exitModal))));
};
const WizardContainer = styled.div `
  display: flex;
  flex-direction: row;
  height: 100%;
`;
const Sidebar = styled.div `
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  background-color: ${newTheme.colors.white};
  padding: 32px;
  gap: 8px;
`;
const StepCounter = styled.div `
  font: ${newTheme.fonts.smallBold};
  color: ${newTheme.colors.grey500};
`;
const Title = styled.div `
  font: ${newTheme.fonts.header};
  color: ${newTheme.colors.black};
`;
const Description = styled.div `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey900};
`;
const Preview = styled.div `
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-left: 1px solid ${newTheme.colors.grey400};
  background-color: ${newTheme.colors.grey100};
`;
const SidebarButtonContainer = styled.div `
  width: 100%;
  justify-content: flex-start;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
const Tour = styled.iframe `
  width: 712px;
  height: 400px;
  box-shadow: ${newTheme.shadows.demoCenter};
  border-radius: 8px;
  overflow: hidden;
`;
const SkipButton = styled.button `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey600};
  text-decoration: underline;
  padding: 8px 10px;
  cursor: pointer;
  &:hover {
    color: ${newTheme.colors.blue300};
  }
`;
