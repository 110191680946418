import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalType } from "../../../../../../../shared/types/tool-data-types/modal-data";
import { newTheme } from "../../../../styled/theme";
import { ModalPanelIcon } from "./modal-panel-icon";
export const ModalTypeSelector = (props) => {
    const { onChange, type } = props;
    const [selectedModalType, setSelectedModalType] = useState(ModalType.TEXT);
    useEffect(() => {
        setSelectedModalType(type);
    }, [type]);
    const handleClick = (type) => {
        setSelectedModalType(type);
        onChange(type);
    };
    const modalTypes = [ModalType.TEXT, ModalType.VIDEO, ModalType.IMAGE, ModalType.PERSONAS];
    return (React.createElement(ModalTypeLayoutContainer, null, modalTypes.map(m => (React.createElement(ModalTypeButton, { key: m, selected: selectedModalType === m, onClick: () => {
            handleClick(m);
        } },
        React.createElement(ModalPanelIcon, { type: m }),
        React.createElement("span", null, m))))));
};
const ModalTypeLayoutContainer = styled.div `
  display: flex;
  flex-wrap: wrap;
`;
const ModalTypeButton = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.selected ? newTheme.colors.blue100 : newTheme.colors.grey200)};
  cursor: pointer;
  width: 132px;
  margin: 8px;
  border-radius: 4px;
  padding: 8px;
  &:hover {
    background-color: ${props => (props.selected ? newTheme.colors.blue100 : newTheme.colors.blue50)};
  }
  > span {
    font: ${newTheme.fonts.normal};
    color: ${newTheme.colors.grey600};
    padding-top: 4px;
  }
`;
