import React, { useEffect, useState } from "react";
class TextWidthCalculator {
    constructor(font) {
        this.canvasId = "global-text-width-calculator-canvas";
        this.canvas = document.getElementById(this.canvasId) || document.createElement("canvas");
        this.canvas.setAttribute("id", this.canvasId);
        this.context = this.canvas.getContext("2d");
        this.context.font = font;
    }
    getTextWidth(text) {
        return this.context.measureText(text).width;
    }
}
export function DynamicWidthInput(props) {
    const [calculator] = useState(new TextWidthCalculator(props.style.font));
    const [width, setWidth] = useState(calculator.getTextWidth(props.value));
    let cursor = props.style.cursor || "inherit";
    if (props.disabled)
        cursor = "not-allowed";
    useEffect(() => {
        setWidth(Math.ceil(calculator.getTextWidth(`${props.value}  `)));
    }, [props.value]);
    return React.createElement("input", Object.assign({}, props, { style: Object.assign(Object.assign({}, props.style), { width, cursor }) }));
}
