import React from "react";
import styled from "styled-components";
import { DCSidebar } from "./dc-sidebar";
import { useDCConfig, useDCIsLiveMode } from "../hooks/dc-hooks";
import { isEmbedded } from "../../../../helpers/live";
export const DCBodyWrapper = ({ children }) => {
    const config = useDCConfig().body;
    const isLiveMode = useDCIsLiveMode();
    return (React.createElement(DemoCenterContainer, { config: config, isLiveMode: isLiveMode, className: isEmbedded() ? "embedded" : null },
        React.createElement(DCSidebar, null),
        children));
};
const DemoCenterContainer = styled.div `
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: ${p => (p.isLiveMode ? "100vh" : "100%")};
  padding-top: 82px;
  background-color: ${p => p.config.backgroundColor};
  ${p => p.config.backgroundImage
    ? `{
    background-image: url(${p.config.backgroundImage});
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
  }`
    : ""}
  &.embedded {
    padding-top: 24px;
  }
`;
