var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { useOutsideClick } from "../../../../../hooks/use-outside-click";
import { SketchPicker } from "react-color";
import { ColorSwatch } from "../../../branding/components/color-swatch";
import { useBrandColors } from "../../../../../redux/selectors/redux-selectors";
import { THorizontalSlider } from "../../../../styled/t-horizontal-slider";
import { dcGradientBackground } from "../../helpers/dc-style-helpers";
import { CustomEyeDropperButton } from "../../../branding/components/empty-color-swatch";
var SelectedColor;
(function (SelectedColor) {
    SelectedColor[SelectedColor["Color1"] = 0] = "Color1";
    SelectedColor[SelectedColor["Color2"] = 1] = "Color2";
})(SelectedColor || (SelectedColor = {}));
export const DCStylesGradientPicker = ({ disabled, onChange, gradient, onOpen, onClose, }) => {
    const { color1, color2, percent } = gradient;
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [activeColor, setActiveColor] = useState(SelectedColor.Color1);
    const [localColor, setLocalColor] = useState(color1);
    const defaultColors = useBrandColors();
    const handleUpdateColor = (color) => {
        if (activeColor === SelectedColor.Color1) {
            onChange({ color1: color, color2, percent });
        }
        else {
            onChange({ color1, color2: color, percent });
        }
    };
    const handleSliderChange = (value) => {
        onChange({ color1, color2, percent: value });
    };
    useOutsideClick([dropdownRef, buttonRef], () => {
        if (isOpen) {
            setIsOpen(false);
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
    });
    useEffect(() => {
        setLocalColor(activeColor === SelectedColor.Color1 ? color1 : color2);
    }, [activeColor]);
    const [eyedropperAPIEnabled, setEyedropperAPIEnabled] = useState(false);
    const activateEyedropperAPI = (selectedColor) => __awaiter(void 0, void 0, void 0, function* () {
        const handleUpdateColorEyedropper = (color) => {
            if (selectedColor === SelectedColor.Color1) {
                onChange({ color1: color, color2, percent });
            }
            else {
                onChange({ color1, color2: color, percent });
            }
        };
        // @ts-expect-error
        const eyeDropper = new EyeDropper();
        try {
            const { sRGBHex } = yield eyeDropper.open();
            if (sRGBHex) {
                setLocalColor(sRGBHex);
                handleUpdateColorEyedropper(sRGBHex);
            }
        }
        catch (err) {
            console.log("User Cancelled Color Select");
        }
    });
    useEffect(() => {
        if ("EyeDropper" in window) {
            setEyedropperAPIEnabled(true);
        }
    }, []);
    return (React.createElement(Wrapper, null,
        React.createElement(ColorPickerButton, { disabled: disabled, onClick: e => {
                e.stopPropagation();
                isOpen ? onClose === null || onClose === void 0 ? void 0 : onClose() : onOpen === null || onOpen === void 0 ? void 0 : onOpen();
                setIsOpen(!isOpen);
            }, ref: buttonRef },
            React.createElement(GradientCircle, { color1: color1, color2: color2 })),
        isOpen && (React.createElement(DropdownContainer, { ref: dropdownRef },
            React.createElement(Title, null, "Gradient"),
            React.createElement(SketchPicker, { disableAlpha: true, presetColors: defaultColors, color: localColor, onChange: color => setLocalColor(color.hex), onChangeComplete: color => handleUpdateColor(color.hex) }),
            React.createElement(Spacer, null),
            React.createElement(SliderContainer, { gradient: gradient },
                React.createElement(THorizontalSlider, { value: percent, onChange: handleSliderChange, min: 0, max: 100, style: { width: "200px" } })),
            React.createElement(GradientPickerFooter, null,
                React.createElement(GradientColorContainer, null,
                    React.createElement(ColorSwatch, { color: activeColor === SelectedColor.Color1 ? localColor : color1, isActive: activeColor === SelectedColor.Color1, onClick: () => setActiveColor(SelectedColor.Color1) }),
                    eyedropperAPIEnabled && (React.createElement(CustomEyeDropperButton, { onClick: () => {
                            setActiveColor(SelectedColor.Color1);
                            void activateEyedropperAPI(SelectedColor.Color1);
                        } }))),
                React.createElement(GradientColorContainer, null,
                    React.createElement(ColorSwatch, { color: activeColor === SelectedColor.Color2 ? localColor : color2, isActive: activeColor === SelectedColor.Color2, onClick: () => setActiveColor(SelectedColor.Color2) }),
                    eyedropperAPIEnabled && (React.createElement(CustomEyeDropperButton, { onClick: () => {
                            setActiveColor(SelectedColor.Color2);
                            void activateEyedropperAPI(SelectedColor.Color2);
                        } }))))))));
};
const Wrapper = styled.div `
  position: relative;
`;
const ColorPickerButton = styled.button `
  border-color: ${newTheme.colors.grey600};
  background-color: ${p => (p.disabled ? newTheme.colors.grey200 : newTheme.colors.white)};
  border-style: solid;
  border-width: 1px;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  &:hover:not(:disabled) {
    background-color: ${newTheme.colors.grey200};
  }
  &:disabled {
    > div {
      opacity: 0.5;
    }
    cursor: not-allowed;
  }
`;
const GradientCircle = styled.div `
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: linear-gradient(81deg, ${p => p.color1} 23.44%, ${p => p.color2} 100%);
  border: 1px solid ${newTheme.colors.grey300};
  margin: 3px;
`;
const DropdownContainer = styled.div `
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${newTheme.colors.grey400};
  background-color: ${newTheme.colors.grey100};
  box-shadow: ${newTheme.shadows.demoCenter};
  .sketch-picker {
    background: none !important;
    box-shadow: none !important;
    padding: 16px !important;
  }
`;
const Title = styled.div `
  margin-top: 8px;
  display: flex;
  font: ${newTheme.fonts.medium};
  justify-content: center;
`;
const Spacer = styled.div `
  margin: 0;
  height: 1px;
  background-color: ${newTheme.colors.grey400};
`;
const GradientPickerFooter = styled.div `
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const SliderContainer = styled.div `
  width: 200px;
  .rc-slider-rail {
    ${p => dcGradientBackground(p.gradient)}
  }
  .rc-slider-handle {
    background-color: ${newTheme.colors.white} !important;
    border: 1px solid ${newTheme.colors.grey400};
  }
`;
const GradientColorContainer = styled.div `
  display: flex;
  gap: 2px;
`;
