import { FormInputBoxStyle, FormLayout } from "../types/form";
import { MouseShape } from "../types/tool-data-types/auto-mouse-data";
import { BorderShape, ShirtSize, TextAlign, TextStyle } from "../types/tool-data-types/common";
import { TooltipButtonStyle } from "../types/tool-data-types/tooltip-v2-data";
const OPEN_SANS = "Open Sans";
const LATO = "Lato";
export const Colors = {
    PRIMARY: "#0055FF",
    SECONDARY: "#FF4797",
    BLACK: "#000000",
    WHITE: "#FFFFFF",
    OFF_WHITE: "#FAFAFA",
    LIGHT_GREY: "#C9CACF",
    MEDIUM_GREY: "#DDDDDD",
    GREY: "#4D5056",
    DARK_GREY: "#62636A",
    TRANSPARENT: "transparent",
};
export const defaultTheme = {
    colors: {
        primary: Colors.PRIMARY,
        secondary: Colors.SECONDARY,
        background: Colors.WHITE,
        text: Colors.BLACK,
    },
    font: {
        headline: {
            family: OPEN_SANS,
            size: ShirtSize.MEDIUM,
        },
        body: {
            family: OPEN_SANS,
            size: ShirtSize.MEDIUM,
        },
    },
    bubble: {
        background: {
            backgroundColor: Colors.WHITE,
            borderColor: Colors.DARK_GREY,
            shape: BorderShape.RECTANGLE,
            isShadowEnabled: true,
        },
        headline: {
            isEnabled: false,
            size: ShirtSize.MEDIUM,
            font: OPEN_SANS,
            color: Colors.BLACK,
            align: TextAlign.LEFT,
            style: TextStyle.NORMAL,
        },
        logo: {
            isEnabled: false,
            src: "",
            shape: BorderShape.RECTANGLE,
        },
        body: {
            isEnabled: true,
            size: ShirtSize.MEDIUM,
            font: OPEN_SANS,
            color: Colors.BLACK,
            align: TextAlign.LEFT,
            style: TextStyle.NORMAL,
        },
        arrow: {
            size: ShirtSize.MEDIUM,
        },
        nextButton: {
            isEnabled: true,
            backgroundColor: Colors.PRIMARY,
            borderColor: Colors.PRIMARY,
            shape: BorderShape.ROUNDED,
            text: "Next",
            style: TooltipButtonStyle.TEXT_AND_ARROWS,
            textColor: Colors.WHITE,
        },
        previousButton: {
            isEnabled: true,
            backgroundColor: Colors.WHITE,
            borderColor: Colors.PRIMARY,
            shape: BorderShape.ROUNDED,
            text: "Back",
            style: TooltipButtonStyle.TEXT_AND_ARROWS,
            textColor: Colors.PRIMARY,
        },
        width: 288,
        stepCounter: {
            isEnabled: true,
            color: Colors.DARK_GREY,
        },
        buttonAlign: TextAlign.CENTER,
    },
    hotspot: {
        color: Colors.SECONDARY,
        size: ShirtSize.LARGE,
    },
    clickzone: {
        color: Colors.SECONDARY,
        shape: BorderShape.RECTANGLE,
        isBorderEnabled: false,
        isPulseEnabled: true,
        isSpotlightEnabled: false,
        spotlightOpacity: 0.4,
    },
    typewriter: {
        backgroundColor: Colors.WHITE,
        body: {
            size: 14,
            font: OPEN_SANS,
            color: Colors.BLACK,
            align: TextAlign.LEFT,
        },
        clearOnComplete: false,
    },
    mouse: {
        shape: MouseShape.ARROW,
        size: ShirtSize.MEDIUM,
        color: Colors.PRIMARY,
        borderColor: Colors.TRANSPARENT,
        opacity: 1,
        hasClickAnimation: true,
    },
    modal: {
        background: {
            backgroundColor: Colors.WHITE,
            borderColor: Colors.DARK_GREY,
            shape: BorderShape.RECTANGLE,
            isShadowEnabled: true,
        },
        headline: {
            isEnabled: true,
            size: ShirtSize.MEDIUM,
            font: OPEN_SANS,
            color: Colors.BLACK,
            align: TextAlign.LEFT,
            style: TextStyle.NORMAL,
        },
        logo: {
            isEnabled: false,
            src: "",
            shape: BorderShape.RECTANGLE,
        },
        body: {
            isEnabled: true,
            size: ShirtSize.MEDIUM,
            font: OPEN_SANS,
            color: Colors.BLACK,
            align: TextAlign.LEFT,
            style: TextStyle.NORMAL,
        },
        button1: {
            isEnabled: true,
            backgroundColor: Colors.WHITE,
            borderColor: Colors.PRIMARY,
            textColor: Colors.PRIMARY,
        },
        button2: {
            isEnabled: true,
            backgroundColor: Colors.PRIMARY,
            borderColor: Colors.PRIMARY,
            textColor: Colors.WHITE,
        },
        width: 480,
        buttonShape: BorderShape.ROUNDED,
        overlay: {
            isEnabled: true,
            opacity: 0.5,
        },
    },
    page: {
        primaryCta: {
            isEnabled: true,
            backgroundColor: Colors.SECONDARY,
            color: Colors.WHITE,
            shape: BorderShape.ROUNDED,
            size: ShirtSize.MEDIUM,
            style: TextStyle.NORMAL,
            borderColor: Colors.SECONDARY,
            text: "Request Demo",
            font: LATO,
        },
        secondaryCta: {
            isEnabled: false,
            backgroundColor: Colors.WHITE,
            color: Colors.PRIMARY,
            shape: BorderShape.RECTANGLE,
            size: ShirtSize.MEDIUM,
            style: TextStyle.NORMAL,
            borderColor: Colors.PRIMARY,
            text: "Learn More",
            font: LATO,
        },
        logo: "",
        navbar: {
            backgroundColor: Colors.WHITE,
            borderColor: Colors.WHITE,
            shadow: true,
        },
        progressBar: {
            isEnabled: true,
            barColor: Colors.SECONDARY,
            backgroundColor: Colors.GREY,
            arrowColor: Colors.GREY,
        },
        body: {
            backgroundImage: "",
            backgroundColor: Colors.MEDIUM_GREY,
        },
        defaultCardStyle: {
            shape: BorderShape.RECTANGLE,
            backgroundColor: Colors.OFF_WHITE,
            borderColor: Colors.LIGHT_GREY,
            shadow: true,
            font: LATO,
            size: ShirtSize.MEDIUM,
            color: Colors.BLACK,
            style: TextStyle.NORMAL,
            align: TextAlign.LEFT,
        },
        activeCardStyle: {
            isEnabled: true,
            shape: BorderShape.RECTANGLE,
            backgroundColor: Colors.WHITE,
            borderColor: Colors.GREY,
            shadow: true,
            font: LATO,
            size: ShirtSize.MEDIUM,
            color: Colors.BLACK,
            style: TextStyle.NORMAL,
            align: TextAlign.LEFT,
        },
        cardIndicatorStyle: {
            isEnabled: true,
            activeColor: Colors.SECONDARY,
            inactiveColor: Colors.DARK_GREY,
        },
    },
    form: {
        layout: FormLayout.VERTICAL,
        enableConfirmation: true,
        logo: {
            src: "",
            shape: BorderShape.RECTANGLE,
            size: ShirtSize.MEDIUM,
        },
        headerText: {
            isEnabled: true,
            font: LATO,
            size: ShirtSize.MEDIUM,
            style: TextStyle.NORMAL,
            align: TextAlign.CENTER,
            color: Colors.BLACK,
        },
        bodyText: {
            isEnabled: true,
            font: LATO,
            size: ShirtSize.SMALL,
            style: TextStyle.NORMAL,
            align: TextAlign.CENTER,
            color: Colors.BLACK,
        },
        confirmHeaderText: {
            isEnabled: true,
            font: LATO,
            size: ShirtSize.MEDIUM,
            style: TextStyle.NORMAL,
            align: TextAlign.CENTER,
            color: Colors.BLACK,
        },
        confirmBodyText: {
            isEnabled: true,
            font: LATO,
            size: ShirtSize.SMALL,
            style: TextStyle.NORMAL,
            align: TextAlign.CENTER,
            color: Colors.BLACK,
        },
        buttonText: {
            isEnabled: true,
            font: LATO,
            size: ShirtSize.LARGE,
            style: TextStyle.NORMAL,
            align: TextAlign.CENTER,
            color: Colors.WHITE,
        },
        labelText: {
            isEnabled: true,
            font: LATO,
            size: ShirtSize.LARGE,
            style: TextStyle.NORMAL,
            align: TextAlign.LEFT,
            color: Colors.BLACK,
        },
        background: {
            backgroundColor: Colors.WHITE,
            borderColor: Colors.DARK_GREY,
            shadow: true,
            shape: BorderShape.RECTANGLE,
        },
        inputs: {
            backgroundColor: Colors.WHITE,
            borderColor: Colors.BLACK,
            shape: BorderShape.RECTANGLE,
            color: Colors.BLACK,
            boxStyle: FormInputBoxStyle.BOX,
        },
        buttons: {
            backgroundColor: Colors.PRIMARY,
            borderColor: Colors.PRIMARY,
            shape: BorderShape.RECTANGLE,
        },
    },
    overlay: {
        backgroundColor: "white",
        headline: {
            font: OPEN_SANS,
            size: ShirtSize.SMALL,
            style: TextStyle.NORMAL,
            color: Colors.BLACK,
        },
        body: {
            font: OPEN_SANS,
            size: ShirtSize.MEDIUM,
            color: Colors.BLACK,
        },
    },
};
