import React, { useState } from "react";
import styled from "styled-components";
import { imageTypes, otherTypes, uploadMedia, videoTypes } from "../../../../helpers/media";
import { newTheme } from "../../../styled/theme";
import { TLoadingSpinner } from "../../../styled/t-loading-spinner";
export const DCFileUploader = ({ children, onUploadComplete, onError: setError, showSpinner, disabled, }) => {
    const [uploading, setUploading] = useState(false);
    const inputFile = React.useRef(null);
    const handleClick = () => {
        var _a;
        if (disabled)
            return;
        (_a = inputFile === null || inputFile === void 0 ? void 0 : inputFile.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { multiple: false, type: "file", accept: `${[...imageTypes, ...videoTypes, ...otherTypes].join("|")}`, ref: inputFile, style: { display: "none" }, onChange: e => {
                setUploading(true);
                setError === null || setError === void 0 ? void 0 : setError("");
                void uploadMedia(e.target.files, (media, error) => {
                    var _a, _b, _c;
                    setUploading(false);
                    setError === null || setError === void 0 ? void 0 : setError(error);
                    onUploadComplete((_a = media[0]) === null || _a === void 0 ? void 0 : _a.src, (_c = (_b = inputFile.current) === null || _b === void 0 ? void 0 : _b.files[0]) === null || _c === void 0 ? void 0 : _c.name);
                });
            } }),
        uploading ? (React.createElement(LoadingWrapper, null, showSpinner ? React.createElement(TLoadingSpinner, null) : "Uploading...")) : (React.createElement(UploadWrapper, { onClick: handleClick, style: { cursor: disabled ? "arrow" : "pointer" } }, children))));
};
const UploadWrapper = styled.div `
  display: flex;
`;
const LoadingWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${newTheme.colors.grey500};
  font: ${newTheme.fonts.medium};
`;
