import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ViewportModeToggle } from "./viewport-mode-toggle";
import { BackButton } from "./back-button";
import { useIsMobileVariantEnabled, useIsPublished, useSaveTourial, useTourialHasChangesToSave, useUserAccess, useViewportMode, } from "../../../../redux/selectors/redux-selectors";
import { BuilderIsLiveBadge } from "./builder-is-live-badge";
import { getPublishedStatus, PublishedStatus } from "../../../../helpers/misc";
import { ToastModes, ViewportMode } from "../../../../types/definitions";
import { UndoRedoButtons } from "./undo-redo-buttons";
import { TourNameInput } from "./tour-name-input";
import { PublishAndShareButton } from "./publish-and-share-button";
import { newTheme } from "../../../styled/theme";
import { VariantSelectorMini } from "../editor/atomic/variant-selector-mini";
import { VersionsButton } from "./versions-button";
import { isAuthorized } from "../../../../../../shared/functions/auth-functions";
import { AccessLevel } from "../../../../../../shared/types/auth-types";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import UpdateLiveVersionModal from "../modals/update-live-version-modal";
import { useAddToast } from "../../../../legacy/selectors";
import GearIcon from "../../../../../assets/images/v3/gear-outline.svg";
import SettingsModal from "../modals/settings-modal";
import { EditModeDisabled } from "./edit-mode-disabled";
import { useIsEditModeBlocked } from "../../../../hooks/use-is-collaborator-blocked";
export function NavbarMini() {
    const isEditModeBlocked = useIsEditModeBlocked();
    const viewportMode = useViewportMode();
    const tourialHasChangesToSave = useTourialHasChangesToSave();
    const isLive = useIsPublished();
    const userAccess = useUserAccess();
    const canVersion = isAuthorized(AccessLevel.EDITOR, userAccess);
    const publishedStatus = getPublishedStatus(isLive, tourialHasChangesToSave);
    const [canPublish, setCanPublish] = useState(publishedStatus === PublishedStatus.BEHIND);
    const hasMobile = useIsMobileVariantEnabled();
    const saveTourial = useSaveTourial();
    const isEdit = viewportMode === ViewportMode.EDIT;
    const [isUpdateLiveVersionModalOpen, setIsUpdateLiveVersionModalOpen] = useState(false);
    const addToast = useAddToast();
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    // show/hide "unpublished changes" notification
    useEffect(() => {
        setCanPublish(getPublishedStatus(isLive, tourialHasChangesToSave) === PublishedStatus.BEHIND);
    }, [isLive, tourialHasChangesToSave]);
    return (React.createElement(NavContainer, null,
        React.createElement(BackButtonContainer, null,
            React.createElement(BackButton, null)),
        React.createElement(TourialNameInputContainer, null,
            React.createElement(TourNameInput, null),
            React.createElement(BuilderIsLiveBadge, null)),
        React.createElement(VariantSelectorContainer, null,
            isEdit && (React.createElement(UndoRedoContainer, null,
                React.createElement(UndoRedoButtons, null))),
            hasMobile && React.createElement(VariantSelectorMini, null)),
        isEditModeBlocked ? (React.createElement(EditModeDisabled, null)) : (React.createElement(RightNavComponentsContainer, null,
            canVersion && React.createElement(VersionsButton, null),
            !isLive && tourialHasChangesToSave && isEdit && (React.createElement(TButton, { size: "medium", variant: TButtonVariants.SECONDARY, onClick: handleSave }, "Save changes")),
            isLive && canPublish && isEdit && (React.createElement(React.Fragment, null,
                React.createElement(TButton, { size: "medium", variant: TButtonVariants.SECONDARY, onClick: () => setIsUpdateLiveVersionModalOpen(true) }, "Publish changes"),
                isUpdateLiveVersionModalOpen && (React.createElement(UpdateLiveVersionModal, { setIsUpdateLiveVersionModalOpen: setIsUpdateLiveVersionModalOpen, saveTourial: handleSave })))),
            isEdit && userAccess !== AccessLevel.COLLABORATOR && (React.createElement(PublishAndShareButton, { setIsSettingsModalOpen: setIsSettingsModalOpen })),
            React.createElement(ViewportModeToggleContainer, null,
                React.createElement(ViewportModeToggle, null)),
            isEdit && (React.createElement(TButton, { id: "_test_settings-button", onClick: () => {
                    setIsSettingsModalOpen(true);
                }, size: "medium", variant: TButtonVariants.OUTLINE, icon: GearIcon })),
            isSettingsModalOpen && React.createElement(SettingsModal, { setIsSettingsModalOpen: setIsSettingsModalOpen })))));
    function handleSave() {
        saveTourial();
        addToast({ mode: ToastModes.SUCCESS, message: "Changes saved." });
    }
}
const NavContainer = styled.nav `
  width: 100%;
  height: 48px;
  padding-right: 8px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;
const BackButtonContainer = styled.span `
  height: 24px;
  width: 24px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;
const ViewportModeToggleContainer = styled.span `
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;
const TourialNameInputContainer = styled.span `
  font: ${newTheme.fonts.medium};
  width: 300px;
  gap: 4px;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
`;
const VariantSelectorContainer = styled.span `
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
const RightNavComponentsContainer = styled.span `
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;
const UndoRedoContainer = styled.span `
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
