import React from "react";
import styled from "styled-components";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import TModal, { TModalButtonsContainer } from "../../../styled/t-modal";
import { newTheme } from "../../../styled/theme";
import DecoratedUpdateLiveTourIcon from "../../../../../assets/images/v3/decorated-update-live-tour-icon.svg";
import { useBlockNavigation } from "../../../../hooks/use-block-navigation";
export function PublishedExitPrompt({ isEnabled, onCancelButtonClick }) {
    const { shouldRender, onContinue, onClose, onCancel } = useBlockNavigation(isEnabled, onCancelButtonClick);
    if (!shouldRender)
        return null;
    return (React.createElement(TModal, { title: "Update your live tour.", onClose: onClose },
        React.createElement("div", null,
            React.createElement(Div, null,
                React.createElement("img", { src: DecoratedUpdateLiveTourIcon })),
            React.createElement(Span, null,
                "You need to press the 'Update live version' button when you save or ",
                React.createElement("b", null, "your changes will be lost.")),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: onContinue }, "Discard changes"),
                React.createElement(TButton, { onClick: onCancel }, "Take me there")))));
}
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  height: 68px;
  width: 510px;
`;
const Div = styled.div `
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${newTheme.colors.grey200};
  width: 100%;
  min-width: 600px;
  border-bottom: 1px solid ${newTheme.colors.grey300};
`;
