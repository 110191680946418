import React from "react";
import styled from "styled-components";
import { LoadingPlaceholderDropdown, TDropdownSelect } from "../../../../styled/t-dropdown-select";
import { TourialApiCalls } from "../../../../../helpers/tourial-api-calls";
import { ConnectLink } from "../../../builder/modals/product-page-cta-configuration";
import { Async } from "react-async";
import { newTheme } from "../../../../styled/theme";
export const DCCMHubspotSelector = ({ cta, onChange }) => {
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Hubspot Link"),
        React.createElement(Async, { promiseFn: TourialApiCalls.Integrations.getHubspotMeetings }, ({ data, error, isPending }) => {
            if (isPending)
                return React.createElement(LoadingPlaceholderDropdown, null);
            if (error)
                return `Something went wrong: ${error.message}`;
            return data.length > 0 ? (React.createElement(TDropdownSelect, { isSearchable: true, selectedValue: cta === null || cta === void 0 ? void 0 : cta.url, options: data.map(l => ({ label: l.name, value: l.link })), onChange: (e) => onChange(e.value) })) : (React.createElement(ConnectLink, { href: "/integrations", target: "_blank" }, "Connect Calendly"));
        })));
};
const Title = styled.div `
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey600};
  margin-bottom: 4px;
`;
const Container = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
`;
