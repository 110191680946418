export const DC_SETTINGS_KEY = "demoCenter";
export const DC_ROOT = "dc";
export const DC_LIVE_PREVIEW_ROOT = "dc-preview";
export const DC_SHARE_LINK_ROOT = "dcc";
export const DC_DEFAULT_MODULE_NAME = "New Module";
export var DCItemType;
(function (DCItemType) {
    DCItemType["TOUR"] = "TOUR";
    DCItemType["VIDEO"] = "VIDEO";
    DCItemType["YOUTUBE"] = "YOUTUBE";
    DCItemType["VIMEO"] = "VIMEO";
    DCItemType["LOOM"] = "LOOM";
    DCItemType["WISTIA"] = "WISTIA";
    DCItemType["VIDYARD"] = "VIDYARD";
    DCItemType["VOCALVIDEO"] = "VOCALVIDEO";
    DCItemType["PDF"] = "DOCUMENT";
    DCItemType["HTML"] = "HTML";
    DCItemType["IMAGE"] = "IMAGE";
    DCItemType["DOM_TOUR"] = "DOM_TOUR";
})(DCItemType || (DCItemType = {}));
export var DCModuleType;
(function (DCModuleType) {
    DCModuleType["SINGLE"] = "SINGLE";
    DCModuleType["MULTIPLE"] = "MULTIPLE";
    DCModuleType["BOOLEAN"] = "BOOLEAN";
    DCModuleType["NO_PROMPT"] = "NO_PROMPT";
    DCModuleType["SCALE"] = "SCALE";
})(DCModuleType || (DCModuleType = {}));
export var DCModuleStatus;
(function (DCModuleStatus) {
    DCModuleStatus["AWAITING_SELECTION"] = "AWAITING_SELECTION";
    DCModuleStatus["IN_PROGRESS"] = "IN_PROGRESS";
    DCModuleStatus["COMPLETE"] = "COMPLETE";
    DCModuleStatus["SKIPPED"] = "SKIPPED";
    DCModuleStatus["REJECTED"] = "REJECTED";
    DCModuleStatus["LOCKED"] = "LOCKED";
})(DCModuleStatus || (DCModuleStatus = {}));
export var DCCardInfoPlacement;
(function (DCCardInfoPlacement) {
    DCCardInfoPlacement["INSIDE"] = "INSIDE";
    DCCardInfoPlacement["OUTSIDE"] = "OUTSIDE";
})(DCCardInfoPlacement || (DCCardInfoPlacement = {}));
export var DCButtonAction;
(function (DCButtonAction) {
    DCButtonAction["LINK"] = "LINK";
    DCButtonAction["SHARE"] = "SHARE";
    DCButtonAction["COPY"] = "COPY";
    DCButtonAction["VIEW"] = "VIEW";
    DCButtonAction["LIBRARY"] = "LIBRARY";
    DCButtonAction["CALENDLY_SCHEDULER"] = "CALENDLY_SCHEDULER";
    DCButtonAction["HUBSPOT_SCHEDULER"] = "HUBSPOT_SCHEDULER";
    DCButtonAction["SKIP"] = "SKIP";
    DCButtonAction["FORM"] = "FORM";
    DCButtonAction["LOGO"] = "LOGO";
    DCButtonAction["CONFIRM_SELECTION"] = "CONFIRM_SELECTION";
    DCButtonAction["SELF_GUIDED_DEMO"] = "SELF_GUIDED_DEMO";
    DCButtonAction["PREVIOUS"] = "PREVIOUS";
    DCButtonAction["NEXT"] = "NEXT";
    DCButtonAction["COPY_MOBILE_LINK"] = "COPY_MOBILE_LINK";
})(DCButtonAction || (DCButtonAction = {}));
export var DCButtonType;
(function (DCButtonType) {
    DCButtonType["DEFAULT"] = "DEFAULT";
    DCButtonType["LARGE"] = "LARGE";
    DCButtonType["BORDERLESS"] = "BORDERLESS";
})(DCButtonType || (DCButtonType = {}));
export var DCButtonIconPlacement;
(function (DCButtonIconPlacement) {
    DCButtonIconPlacement["LEFT"] = "LEFT";
    DCButtonIconPlacement["RIGHT"] = "RIGHT";
})(DCButtonIconPlacement || (DCButtonIconPlacement = {}));
export var DCIconType;
(function (DCIconType) {
    DCIconType["DOWN_CHEVRON"] = "DOWN_CHEVRON";
    DCIconType["DOWN_CHEVRON_CIRCLED"] = "DOWN_CHEVRON_CIRCLED";
    DCIconType["RIGHT_ARROW"] = "RIGHT_ARROW";
    DCIconType["LEFT_ARROW"] = "LEFT_ARROW";
    DCIconType["BACK_ARROW"] = "BACK_ARROW";
    DCIconType["SHARE"] = "SHARE";
    DCIconType["COLLAPSE"] = "COLLAPSE";
    DCIconType["DOCUMENT"] = "DOCUMENT";
    DCIconType["TOUR"] = "TOUR";
    DCIconType["VIDEO"] = "VIDEO";
    DCIconType["CHECKMARK"] = "CHECKMARK";
    DCIconType["LIBRARY"] = "LIBRARY";
    DCIconType["EMPTY_ITEM"] = "EMPTY_ITEM";
    DCIconType["ACTIVE_ITEM"] = "ACTIVE_ITEM";
    DCIconType["BOOKMARK"] = "BOOKMARK";
    DCIconType["COPY"] = "COPY";
    DCIconType["CHECK_CIRCLE"] = "CHECK_CIRCLE";
    DCIconType["MINI_CHECK_CIRCLE"] = "MINI_CHECK_CIRCLE";
    DCIconType["THUMBS_UP"] = "THUMBS_UP";
    DCIconType["THUMBS_DOWN"] = "THUMBS_DOWN";
    DCIconType["MINI_THUMBS_UP"] = "MINI_THUMBS_UP";
    DCIconType["MINI_THUMBS_DOWN"] = "MINI_THUMBS_DOWN";
    DCIconType["SCALE_LOW"] = "SCALE_LOW";
    DCIconType["SCALE_MEDIUM"] = "SCALE_MEDIUM";
    DCIconType["SCALE_HIGH"] = "SCALE_HIGH";
    DCIconType["MINI_SCALE_LOW"] = "MINI_SCALE_LOW";
    DCIconType["MINI_SCALE_MEDIUM"] = "MINI_SCALE_MEDIUM";
    DCIconType["MINI_SCALE_HIGH"] = "MINI_SCALE_HIGH";
    DCIconType["PADLOCK"] = "PADLOCK";
    DCIconType["POINTER"] = "POINTER";
    DCIconType["DRAG_HANDLE"] = "DRAG_HANDLE";
    DCIconType["PIN"] = "PIN";
    DCIconType["HAND_WAVE"] = "HAND_WAVE";
    DCIconType["NAVBAR"] = "NAVBAR";
    DCIconType["PLUS"] = "PLUS";
    DCIconType["PLUS_LARGE"] = "PLUS_LARGE";
    DCIconType["PLUS_CIRCLE"] = "PLUS_CIRCLE";
    DCIconType["REMOVE_CIRCLE"] = "REMOVE_CIRCLE";
    DCIconType["IMAGE"] = "IMAGE";
    DCIconType["MEGAPHONE"] = "MEGAPHONE";
    DCIconType["NETWORK"] = "NETWORK";
    DCIconType["ELLIPSES"] = "ELLIPSES";
    DCIconType["PROMPT"] = "PROMPT";
    DCIconType["ADD_PROMPT"] = "ADD_PROMPT";
    DCIconType["TEXT"] = "TEXT";
    DCIconType["ADD_TEXT"] = "ADD_TEXT";
    DCIconType["PENCIL"] = "PENCIL";
    DCIconType["TRASH"] = "TRASH";
    DCIconType["UPLOAD"] = "UPLOAD";
    DCIconType["ADD_IMAGE"] = "ADD_IMAGE";
    DCIconType["CHECKLIST"] = "CHECKLIST";
    DCIconType["YES_NO"] = "YES_NO";
    DCIconType["MULTI_SELECT"] = "MULTI_SELECT";
    DCIconType["SINGLE_SELECT"] = "SINGLE_SELECT";
    DCIconType["NO_PROMPT"] = "NO_PROMPT";
    DCIconType["SWATCHES"] = "SWATCHES";
    DCIconType["MOON"] = "MOON";
    DCIconType["SUN"] = "SUN";
    DCIconType["CIRCLE_IN_PROGRESS"] = "CIRCLE_IN_PROGRESS";
    DCIconType["FORWARD_ARROW"] = "FORWARD_ARROW";
    DCIconType["MODULE_COMPLETE"] = "MODULE_COMPLETE";
    DCIconType["SPEEDOMETER"] = "SPEEDOMETER";
    DCIconType["GEAR"] = "GEAR";
    DCIconType["COLOR_PALETTE"] = "COLOR_PALETTE";
})(DCIconType || (DCIconType = {}));
export var DCBadgeType;
(function (DCBadgeType) {
    DCBadgeType["ITEM_TYPE"] = "ITEM_TYPE";
    DCBadgeType["DURATION"] = "DURATION";
})(DCBadgeType || (DCBadgeType = {}));
export var DCMode;
(function (DCMode) {
    DCMode["IN_APP_PREVIEW"] = "IN_APP_PREVIEW";
    DCMode["LIVE_PREVIEW"] = "LIVE_PREVIEW";
    DCMode["LIVE"] = "LIVE";
})(DCMode || (DCMode = {}));
export var DCThemeType;
(function (DCThemeType) {
    DCThemeType["LIGHT"] = "Light";
    DCThemeType["DARK"] = "Dark";
    DCThemeType["CUSTOM"] = "Custom";
})(DCThemeType || (DCThemeType = {}));
export var DCView;
(function (DCView) {
    DCView["WELCOME"] = "welcome";
    DCView["ITEM"] = "item";
    DCView["GALLERY_VIEW"] = "gallery";
    DCView["HEADER_ONLY"] = "header";
    DCView["MODULE"] = "module";
})(DCView || (DCView = {}));
export var DCBuilderMode;
(function (DCBuilderMode) {
    DCBuilderMode["MANAGE"] = "manage";
    DCBuilderMode["STYLES"] = "styles";
    DCBuilderMode["OVERVIEW"] = "overview";
    DCBuilderMode["PREVIEW"] = "preview";
    DCBuilderMode["WIZARD"] = "wizard";
})(DCBuilderMode || (DCBuilderMode = {}));
export var DCCMPageType;
(function (DCCMPageType) {
    DCCMPageType["INTRO_PAGE"] = "Intro";
    DCCMPageType["NAVIGATION"] = "Navigation";
    DCCMPageType["WELCOME"] = "welcome";
    DCCMPageType["GATED_MODULES"] = "Gated topics";
    DCCMPageType["ADVANCED_SETTINGS"] = "Advanced Settings";
})(DCCMPageType || (DCCMPageType = {}));
