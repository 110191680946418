import React from "react";
import TP from "../../legacy/components/styled/t-p";
import OhNoImg from "../../../assets/images/failed-media-upload.svg";
import { imageTypes, videoTypes } from "../../helpers/media";
import { theme } from "../../../../shared/legacy/legacy-tool-data";
import { ToolType } from "../../../../shared/types/tool-data-types/common";
export const wording = (yTool, xTool, toolType, failed) => {
    // if the upload has failed
    if (failed) {
        let fontSize, supportListText, suggestionText;
        // calculate font size based on the tool's
        const magicNumberY = yTool / 15000;
        const magicNumberX = xTool / 15000;
        const fontSizeY = magicNumberY * 16;
        const fontSizeX = magicNumberX * 16;
        // determine if tool is wide or tall.
        const isVertical = yTool < xTool;
        //if its tall use smaller font
        if (isVertical) {
            fontSize = fontSizeY;
        }
        else {
            fontSize = fontSizeX;
        }
        // set supported file formats
        if (toolType === ToolType.Image) {
            supportListText = "";
            let i = 0;
            for (i; i < imageTypes.length - 1; i++) {
                supportListText += imageTypes[i].replace("image/", "") + ", ";
            }
            supportListText += "and" + imageTypes[i].replace("image/", "");
            suggestionText = "a smaller image";
        }
        if (toolType === ToolType.Video) {
            supportListText = "";
            let i = 0;
            for (i; i < videoTypes.length - 1; i++) {
                supportListText += videoTypes[i].replace("video/", "") + ", ";
            }
            supportListText += "and " + videoTypes[i].replace("video/", "");
            suggestionText = "a shorter video";
        }
        const uploadFailedText = (React.createElement("span", { style: { fontSize: `${fontSize + 5}px`, color: theme.colors.error.medium } }, "Upload Failed"));
        const supportText = (React.createElement("span", { style: { fontSize: `${fontSize - 5}px`, color: theme.colors.cobalt.dark } },
            "Try uploading ",
            suggestionText,
            ". Tourial only supports ",
            supportListText,
            "."));
        // if the tool is medium, render with upload failed
        if (fontSize < 12) {
            return React.createElement("div", { style: { whiteSpace: "pre-line" } }, uploadFailedText);
        }
        // Else show all the text.
        return (React.createElement("div", { style: { whiteSpace: "pre-line" } },
            uploadFailedText,
            " ",
            React.createElement("br", null),
            supportText));
    }
    return React.createElement(TP, { style: { fontSize: "0.7vw", color: theme.colors.cobalt.dark } }, "Uploading Media...");
};
export const display = (id, failed) => {
    if (failed) {
        return (React.createElement("img", { id: `${id}_loading_indicator_img`, draggable: false, src: OhNoImg, style: {
                margin: "5px 0px 0px 5px",
                marginTop: "5px",
                width: `25%`,
                height: `25%`,
                position: "relative",
            } }));
    }
    return (React.createElement("i", { className: "fas fa-spinner fa-spin", draggable: false, style: {
            color: theme.colors.cobalt.dark,
            marginRight: 10,
            margin: "5px 0px 0px 5px",
        } }));
};
export function FileLoadingIndicator(props) {
    const toolHeight = props.toolHeight;
    const toolWidth = props.toolWidth;
    const viewportWidth = props.viewportWidth;
    const viewportHeight = props.viewportHeight;
    const id = props.id;
    const failed = props.uploadFailed;
    const toolType = props.toolType;
    // Is the tool a square, vertical rectangle or horizontal rectangle
    const yTool = toolHeight * viewportHeight;
    const xTool = toolWidth * viewportWidth;
    const mTool = yTool / xTool;
    return (React.createElement("div", { style: {
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: `${mTool < 1 ? "row" : "column"}`,
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            outline: `1px dashed ${theme.colors.cobalt.dark}`,
            outlineOffset: "0px",
        } },
        display(id, failed),
        wording(yTool, xTool, toolType, failed)));
}
