import { useEffect } from "react";
import { useActiveView, usePages } from "../redux/selectors/redux-selectors";
export function useIsFirstStep(setIsFirstStep) {
    const pages = usePages();
    const activeView = useActiveView();
    useEffect(() => {
        if (pages.findIndex(p => p.id === activeView.pageId) === 0) {
            if (pages.find(p => p.id === activeView.pageId).zooms.findIndex(z => z.id === activeView.zoomId) === 0) {
                setIsFirstStep(true);
            }
            else {
                setIsFirstStep(false);
            }
        }
        else {
            setIsFirstStep(false);
        }
    }, [activeView.pageId, activeView.zoomId]);
}
