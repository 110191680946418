import React, { useRef, useState } from "react";
import styled from "styled-components";
import { DCButtonAction, DCIconType } from "../../../../../../shared/types/demo-center";
import { dcBodyFontSizes } from "../helpers/dc-style-helpers";
import { useOutsideClick } from "../../../../hooks/use-outside-click";
import { DCStyledBodyText } from "../components/dc-styled-text";
import { DCIcon } from "../components/dc-icon";
import { useDCConfig } from "../hooks/dc-hooks";
import { useDCSessionId } from "../../../../redux/selectors/demo-center-selectors";
import { useDCClickEvent } from "../hooks/dc-event-hooks";
import { newTheme } from "../../../styled/theme";
export const DCShareDialog = ({ onClose, style }) => {
    const dcSessionId = useDCSessionId();
    const clickEvent = useDCClickEvent();
    const [hasCopied, setHasCopied] = useState(false);
    const shareRef = useRef(null);
    const { backgroundColor, inputStyle, title, description, copyButtonColor } = useDCConfig().share;
    const url = window.location.href + `&shareSessionId=${dcSessionId}`;
    useOutsideClick([shareRef], onClose);
    const copyText = () => {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(url).then(() => {
            clickEvent({ action: DCButtonAction.COPY, url });
            setHasCopied(true);
            window.setTimeout(() => {
                onClose();
                setHasCopied(false);
            }, 1000);
        }, () => {
            console.error("error copying text to clipboard");
        });
    };
    return (React.createElement(ShareContainer, { backgroundColor: backgroundColor, ref: shareRef, style: style },
        React.createElement(DCStyledBodyText, { config: title.style }, hasCopied ? "Copied!" : title.text),
        React.createElement(InputContainer, { config: inputStyle },
            React.createElement("input", { autoFocus: true, value: url, readOnly: true, onFocus: e => e.target.select() }),
            React.createElement(DCIcon, { type: DCIconType.COPY, style: { color: copyButtonColor, marginLeft: 8, cursor: "pointer" }, onClick: copyText })),
        React.createElement(DCStyledBodyText, { config: description.style }, description.text)));
};
const ShareContainer = styled.div `
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  width: 300px;
  background-color: ${p => p.backgroundColor};
  padding: 16px 16px 24px 16px;
  border-radius: 4px;
  box-shadow: ${newTheme.shadows.demoCenter};
`;
const InputContainer = styled.div `
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 8px 12px 8px 8px;
  margin: 24px 0;
  border: 1px solid ${p => p.config.borderColor};
  background-color: ${p => p.config.backgroundColor};
  > input {
    flex: 1;
    padding: 0;
    color: ${p => p.config.color};
    background-color: ${p => p.config.backgroundColor};
    font-family: ${p => p.config.font};
    font-size: ${p => dcBodyFontSizes[p.config.size]}px;
  }
`;
