import { useHistory } from "react-router-dom";
import { useDCConfig, useDCCreateUrl } from "./dc-hooks";
import { useDCGetModule, useDCGetPreviousModule, useDCModules } from "./dc-module-hooks";
import { useDCGetItemsForSelectedTracks, useDCGetLastItemFromModule, useDCGetNextItem, useDCGetPreviousItem, useDCItems, } from "./dc-item-hooks";
import { useDCActiveItemId, useDCActiveItemSubNavigation, useDCActiveModuleSlug, useDCSetActiveItemSubNavigation, } from "../../../../redux/selectors/demo-center-selectors";
import { DCModuleType } from "../../../../../../shared/types/demo-center";
export const useDCGoToHome = () => {
    const history = useHistory();
    const createUrl = useDCCreateUrl();
    return () => {
        history.push(createUrl("/"));
    };
};
export const useDCGoToGallery = () => {
    const history = useHistory();
    const createUrl = useDCCreateUrl();
    return () => {
        history.push(createUrl("/gallery"));
    };
};
export const useDCGoToSelfDemo = () => {
    const history = useHistory();
    const createUrl = useDCCreateUrl();
    return () => {
        history.push(createUrl("/demo"));
    };
};
export const useDCGoToModule = () => {
    const history = useHistory();
    const createUrl = useDCCreateUrl();
    return (slug) => {
        history.push(createUrl(`/${slug}`));
    };
};
export const useDCGoToItem = () => {
    const history = useHistory();
    const createUrl = useDCCreateUrl();
    return (itemId, slug) => {
        history.push(createUrl(`/${slug ? `${slug}/` : ""}view/${itemId}`));
    };
};
export const useDCGoToNextModule = () => {
    const modules = useDCModules();
    const goToModule = useDCGoToModule();
    const activeModule = useDCActiveModuleSlug();
    return () => {
        const i = modules.findIndex(m => m.slug === activeModule);
        if (i >= 0 && i < modules.length - 1) {
            goToModule(modules[i + 1].slug);
        }
        else {
            goToModule(modules[0].slug);
        }
    };
};
export const useDCGoToNextItem = () => {
    const goToNextModule = useDCGoToNextModule();
    const goToItem = useDCGoToItem();
    const getNextItem = useDCGetNextItem();
    const activeModule = useDCActiveModuleSlug();
    return () => {
        const nextItem = getNextItem();
        if (nextItem) {
            goToItem(nextItem.id, activeModule);
        }
        else {
            goToNextModule();
        }
    };
};
export const useDCGoToNextPage = () => {
    const subNavigation = useDCActiveItemSubNavigation();
    const { currentPage, totalPages } = subNavigation;
    const setSubNavigation = useDCSetActiveItemSubNavigation();
    return () => {
        if (currentPage < totalPages) {
            setSubNavigation(Object.assign(Object.assign({}, subNavigation), { currentPage: currentPage + 1 }));
        }
    };
};
export const useDCGoToPreviousPage = () => {
    const subNavigation = useDCActiveItemSubNavigation();
    const { currentPage } = subNavigation;
    const setSubNavigation = useDCSetActiveItemSubNavigation();
    return () => {
        if (currentPage > 1) {
            setSubNavigation(Object.assign(Object.assign({}, subNavigation), { currentPage: currentPage - 1 }));
        }
    };
};
export const useDCGoToPreviousItem = () => {
    var _a;
    const getModule = useDCGetModule();
    const goToModule = useDCGoToModule();
    const goToItem = useDCGoToItem();
    const getPreviousItem = useDCGetPreviousItem();
    const activeItemId = useDCActiveItemId();
    const getItemsFromModuleSlug = useDCGetItemsForSelectedTracks();
    const activeModule = useDCActiveModuleSlug();
    const items = getItemsFromModuleSlug(activeModule);
    const getPreviousModule = useDCGetPreviousModule();
    const getLastItemFromModule = useDCGetLastItemFromModule();
    const isOnFirstItemInModule = ((_a = items[0]) === null || _a === void 0 ? void 0 : _a.id) === activeItemId;
    return () => {
        const module = getModule(activeModule);
        const previousItem = getPreviousItem();
        const previousModule = getPreviousModule();
        const lastItemInPreviousModule = getLastItemFromModule(previousModule);
        if (previousItem) {
            goToItem(previousItem.id, activeModule);
        }
        else if (!previousItem && isOnFirstItemInModule && module.type !== DCModuleType.NO_PROMPT) {
            goToModule(activeModule);
        }
        else if (lastItemInPreviousModule) {
            goToItem(lastItemInPreviousModule.id, previousModule.slug);
        }
        else {
            goToModule(previousModule.slug);
        }
    };
};
export const useDCOpenQuickView = () => {
    const history = useHistory();
    const createUrl = useDCCreateUrl();
    return (id) => {
        history.push(createUrl(`/gallery/quick-view/${id}`));
    };
};
export const useDCGoToFeaturedContent = () => {
    var _a;
    const history = useHistory();
    const createUrl = useDCCreateUrl();
    const modules = useDCModules();
    const items = useDCItems();
    const firstModule = modules[0];
    const firstItem = (_a = firstModule === null || firstModule === void 0 ? void 0 : firstModule.tracks[0]) === null || _a === void 0 ? void 0 : _a.items[0];
    const { welcome } = useDCConfig();
    const { featuredContentId } = welcome;
    return () => {
        if (firstItem && firstModule && firstItem === featuredContentId) {
            history.push(createUrl(`/${firstModule.slug}/view/${featuredContentId}`));
        }
        else if (featuredContentId && !!items.find(i => i.id === featuredContentId)) {
            history.push(createUrl(`/view/${featuredContentId}`));
        }
        else {
            history.push(createUrl("/"));
        }
    };
};
