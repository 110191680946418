import React from "react";
import TModal, { TModalButtonsContainer } from "../../../../styled/t-modal";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
export function UpdateLiveVersionModal({ setIsUpdateLiveVersionModalOpen, saveHtmlTour, isDiscard = false, }) {
    const headerText = isDiscard ? "Discard unpublished changes?" : "Your live tour will change";
    const bodyText = isDiscard
        ? "You have unpublished changes to your tour. If you didn’t mean to do this, you can undo your changes and revert the tour to the previous live version."
        : "You will not be able to access the previous live version of your tour once you do this.";
    return (React.createElement(TModal, { title: headerText, onClose: () => setIsUpdateLiveVersionModalOpen(false) },
        React.createElement(React.Fragment, null,
            React.createElement(Span, null, bodyText),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: () => {
                        setIsUpdateLiveVersionModalOpen(false);
                    } }, isDiscard ? "Keep changes" : "Cancel"),
                React.createElement(TButton, { variant: isDiscard ? TButtonVariants.DANGER : TButtonVariants.DEFAULT, onClick: () => {
                        saveHtmlTour(true);
                        setIsUpdateLiveVersionModalOpen(false);
                    } }, isDiscard ? "Undo changes" : "Publish Changes")))));
}
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  height: 68px;
  width: 510px;
`;
