import React from "react";
import ImageBoxIcon from "../../../../../../../assets/images/image-with-box.svg";
export function Logo({ toolData, viewportDimensions, }) {
    let { shape, src, srcDimensions, xOffset, yOffset, originalDimensions } = toolData.logo;
    let logoWidth, logoHeight, diameter;
    if (!src)
        originalDimensions = { width: 50, height: 50 };
    if (originalDimensions) {
        const { width, height } = originalDimensions;
        if (width >= height) {
            logoWidth = srcDimensions.width;
            logoHeight = srcDimensions.height * (height / width);
            diameter = (logoHeight / 100) * viewportDimensions.height;
        }
        else {
            logoHeight = srcDimensions.height;
            logoWidth = srcDimensions.width * (width / height);
            diameter = (logoWidth / 100) * viewportDimensions.width;
        }
    }
    return (React.createElement("div", { style: Object.assign(Object.assign({}, (shape === "circle" &&
            src && {
            width: `${diameter}px`,
            height: `${diameter}px`,
            alignItems: "center",
        })), { display: "inline-flex", justifyContent: "center" }) },
        React.createElement("div", { style: Object.assign({ display: "inline" }, (shape === "circle" &&
                src && {
                height: "100%",
                width: "100%",
                borderRadius: "50%",
                overflow: "hidden",
            })) },
            React.createElement("img", { id: `${toolData.id}_img`, draggable: false, src: src || ImageBoxIcon, style: Object.assign({ margin: "auto", width: `${(logoWidth / 100) * viewportDimensions.width}px`, minWidth: `${(logoWidth / 100) * viewportDimensions.width}px`, height: `${(logoHeight / 100) * viewportDimensions.height}px`, position: "relative", display: "block" }, (src && { top: `${yOffset}%`, left: `${xOffset}%` })) }))));
}
