import React from "react";
import { BodyText } from "../subcomponents/body-text";
import { HeaderText } from "../subcomponents/header-text";
import { Inputs } from "../subcomponents/inputs";
import { Logo } from "../subcomponents/logo";
import { SubmitButton } from "../subcomponents/submit-button";
export function Layout4({ toolData, viewportDimensions, runAfterSubmissionFn }) {
    return (React.createElement("div", { style: {
            padding: `${toolData.isDismissable ? "3.5%" : "8%"} 7% 8% 7%`,
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "start",
            height: "100%",
        } },
        React.createElement("div", { style: {
                width: "28%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                height: "100",
            } },
            toolData.logo.isVisible && (React.createElement("div", { style: {
                    maxHeight: "50%",
                    maxWidth: "100%",
                } },
                React.createElement(Logo, { toolData: toolData, viewportDimensions: viewportDimensions }))),
            React.createElement(HeaderText, { toolData: toolData, viewportDimensions: viewportDimensions }),
            React.createElement("div", { style: {
                    flexGrow: 1,
                } },
                React.createElement(BodyText, { toolData: toolData, viewportDimensions: viewportDimensions, customStyle: { height: "100%" } }))),
        React.createElement("div", { style: {
                marginLeft: "1%",
                width: "72%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
            } },
            React.createElement("div", { className: "hide-scrollbar", style: {
                    marginBottom: "2%",
                    overflowY: "scroll",
                } },
                React.createElement(Inputs, { toolData: toolData, viewportDimensions: viewportDimensions })),
            React.createElement("div", { style: {
                    marginTop: "1%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    margin: 0,
                    width: "fit-content",
                    maxWidth: "95%",
                    height: "15%",
                    minHeight: "15%",
                } },
                React.createElement(SubmitButton, { toolData: toolData, viewportDimensions: viewportDimensions, runAfterSubmissionFn: runAfterSubmissionFn, customStyle: { width: "100%" } })))));
}
